<template>
    <div v-if="show"
         id="walkthroughContainer">
        <walkthrough-header :is-shared-link-view="isSharedLinkView"
                            @close="end"
                            @exit="exit"
                            @save="save"
                            @logo-click="onOWLogoClick" />

        <walkthrough-page-container :is-shared-link-view="isSharedLinkView"
                                    @toggle-title-nav-visibility="toggleTitleNavVisibility"
                                    @add-new-page="addNewPage"
                                    @select-page="selectPage"
                                    @duplicate-page="duplicatePage"
                                    @request-reorder-page-prompt="requestReOrderPagePrompt"
                                    @delete-page="deletePage" />
    </div>
</template>

<script lang="ts">
    import cloneDeep from 'lodash.clonedeep'
    import omit from 'lodash.omit'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import WalkthroughHeader from '@/components/walkthrough/walkthrough-header.vue'
    import WalkthroughPageContainer from '@/components/walkthrough/walkthrough-page-container.vue'
    import { Route } from '@/enums/route.enum'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import {
        AUTH_LOGOUT,
    } from '@/store/modules/auth-oidc/types'
    import {
        LINK_SHARE_CLIENT_EXIT_AUTH_USER_SHARED_LINK_VIEW,
        LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
    } from '@/store/modules/link-share-client/types'
    import {
        MAP_EMPHASISE_NPS_COVERAGE,
        MAP_SHOW_HIGHWAY_MAINTENANCE,
        MAP_SHOW_NPS_FREEHOLDS,
        MAP_SHOW_NPS_LEASEHOLDS,
        MAP_SHOW_PUBLIC_RIGHTS_OF_WAY,
        MAP_UPDATE_SIZE,
    } from '@/store/modules/map/types'
    import {
        MATTER_CREATE_LINK_SHARE,
    } from '@/store/modules/matter/types'
    import { TITLE_MUTATE_SELECTED_SUMMARY_TITLE } from '@/store/modules/titles/types'
    import {
        WALKTHROUGH_ADD_NEW_PAGE,
        WALKTHROUGH_LOAD_BY_ID,
        WALKTHROUGH_MUTATE_DELETE_PAGE,
        WALKTHROUGH_MUTATE_ENABLED,
        WALKTHROUGH_MUTATE_SELECTED_PAGE,
        WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT,
        WALKTHROUGH_RESET,
        WALKTHROUGH_SAVE,
    } from '@/store/modules/walkthrough/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_MUTATE_SHOW_MAP_TITLES_NAV,
    } from '@/store/mutation-types'

    export default {
        name: 'WalkthroughContainer',

        components: {
            WalkthroughHeader,
            WalkthroughPageContainer,
        },

        mixins: [
            FeatureFlagsMixin,
        ],

        emits: [
            'clear-titles',
        ],

        data() {
            return {
                // Store the state of the app pre-walkthrough to resume afterwards.
                preWalkthroughState: {
                    interactions: [],
                },
                waitingForMapToLoad: false,
            }
        },

        computed: {
            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            ...mapState({
                matterId: state => state.matter.currentMatter.id,
                currentMatter: state => state.matter.currentMatter,
                currentMatterWalkthroughId: state => state.matter.currentMatter.walkthroughId,
                walkthrough: state => state.walkthrough.walkthrough, // the walkthrough metadata
                selectedPage: state => state.walkthrough.selectedPage,
                selectedTitleNumber: state => state.title.selectedTitleNumber,
                showMapTitlesNav: state => state.user.showMapTitlesNav,
                map: state => state.map.map,
                walkthroughLoading: state => state.walkthrough.loading,
                matterTitlesLayer: state => state.matter.titlesLayer,
                pages: state => state.walkthrough.walkthrough?.pages ?? [],
                mapLoaded: state => state.map.loaded,
                currentLinkShare: state => state.matter.currentMatter.linkShare,
                isMatterLinkShareUser: state => state.linkShareClient.isMatterLinkShareUser,
            }),

            isShareableLinkCreated() {
                return Boolean(this.currentLinkShare)
            },

            isWalkthroughRoute() {
                return this.$route.name === Route.MatterMapWalkthrough
            },

            show() {
                return !this.walkthroughLoading &&
                    this.mapLoaded &&
                    this.isWalkthroughRoute
            },
        },

        watch: {
            async isWalkthroughRoute(val) {
                if (val) {
                    await this.init()
                } else {
                    this.end()
                }
            },

            async matterId(val) {
                if (val !== null) {
                    await this.init()
                }
            },

            async mapLoaded(val) {
                if (val === true && this.waitingForMapToLoad === true) {
                    await this.init()
                }
            },

        },

        async mounted() {
            await this.init()
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                loadWalkthroughById: WALKTHROUGH_LOAD_BY_ID,
                saveWalkthrough: WALKTHROUGH_SAVE,
                logout: AUTH_LOGOUT,
                updateMapSize: MAP_UPDATE_SIZE,
                createShareableLink: MATTER_CREATE_LINK_SHARE,

                // Actions to show/hide layers on the map
                showFreeholds: MAP_SHOW_NPS_FREEHOLDS,
                showLeaseholds: MAP_SHOW_NPS_LEASEHOLDS,
                emphasiseNPSCoverage: MAP_EMPHASISE_NPS_COVERAGE,
                showPublicRightsOfWay: MAP_SHOW_PUBLIC_RIGHTS_OF_WAY,
                showHighwaysMaintenance: MAP_SHOW_HIGHWAY_MAINTENANCE,
                resetWalkthroughs: WALKTHROUGH_RESET,
                addNewPage: WALKTHROUGH_ADD_NEW_PAGE,
            }),

            ...mapActions('linkShareClient', {
                exitAuthUserLinkSharedView: LINK_SHARE_CLIENT_EXIT_AUTH_USER_SHARED_LINK_VIEW,
            }),

            ...mapMutations({
                setWalkthroughEnabled: WALKTHROUGH_MUTATE_ENABLED,
                setTitleNavVisibility: USER_MUTATE_SHOW_MAP_TITLES_NAV,
                setSelectedPage: WALKTHROUGH_MUTATE_SELECTED_PAGE,
                setSelectedWalkthroughTitle: TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
                showReorderPagePrompt: WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT,
                doDeletePage: WALKTHROUGH_MUTATE_DELETE_PAGE,
            }),

            async init() {
                // Has the main map area loaded?
                if (this.mapLoaded === true) {
                    // Has the matter loaded and we're in walkthrough mode?
                    if (this.matterId !== null && this.isWalkthroughRoute) {
                        // Load the walkthrough associated with the matter.
                        this.setWalkthroughEnabled(true)
                        await this.loadWalkthroughById(this.currentMatterWalkthroughId)

                        // Store part of current state to restore later, probably not essential, could be annoying; consider removing.
                        this.preWalkthroughState = {
                            selectedTitleNumber: this.selectedTitleNumber,
                            // showSketchesLayer:
                        }

                        // Clear any content not required for walkthroughs.
                        this.$emit('clear-titles')
                        this.setTitleNavVisibility(false)
                        // eslint-disable-next-line no-unused-expressions
                        this.matterTitlesLayer?.setVisible(false)

                        // Hide LR/OS layers not supported by the walkthrough.
                        this.showFreeholds(false)
                        this.showLeaseholds(false)
                        this.emphasiseNPSCoverage(false)
                        this.showHighwaysMaintenance(false)
                        this.showPublicRightsOfWay(false)

                        // Trigger the map to update as elements will have resized.
                        setTimeout(() => {
                            this.updateMapSize()
                        }, 50)
                    }
                } else {
                    // Try again later.
                    this.waitingForMapToLoad = true
                }
            },

            end() {
                this.resetWalkthroughs()
                this.setWalkthroughEnabled(false)
                this.setSelectedWalkthroughTitle(null)

                // Titles nav visible.
                this.setTitleNavVisibility(true)

                if (!this.isSharedLinkView) {
                    if (this.preWalkthroughState.selectedTitleNumber) {
                        this.$router.push({
                            name: Route.MatterMapTitle,
                            params: {
                                titleNumber: this.preWalkthroughState.selectedTitleNumber,
                            },
                        })
                    } else {
                        this.$router.push({ name: Route.MatterMap })
                    }
                }

                // Sketches layer visible / hidden
                // if (this.preWalkthroughState.showSketchesLayer === true) {
                //     // eslint-disable-next-line no-unused-expressions
                //     this.sketchesLayer?.setVisible(true)
                // }

                // Show the matter title boundary layer after it was initially hidden when displaying the walkthrough.
                // eslint-disable-next-line no-unused-expressions
                this.matterTitlesLayer?.setVisible(true)
                // Re-enable interactions
                this.reEnableMapInteractions()

                this.logHeapEvent({
                    type: 'Walkthrough: Close Walkthrough',
                    metadata: {
                        matterId: this.currentMatter.id,
                        walkthroughId: this.currentMatter.walkthroughId,
                        isUserMatterOwner: this.currentMatter.createdByMe,
                    },
                })
            },

            selectPage(page) {
                this.setSelectedPage(page)
                this.setSelectedWalkthroughTitle(null)
            },

            toggleTitleNavVisibility() {
                this.setTitleNavVisibility(!this.showMapTitlesNav)
            },

            duplicatePage(page) {
                // Create a copy of the page.
                const newPage = cloneDeep(omit(page, ['layer']))

                if (newPage.name !== null) {
                    newPage.name = `${ newPage.name } copy`
                }

                // Remove any Ids from the duplicate page so as to treat them as new.
                newPage.id = null

                // Add it after the original.
                this.addNewPage({
                    newPage,
                    afterPage: page,
                })

                // Select it as the current page.
                this.selectPage(newPage)
            },

            requestReOrderPagePrompt() {
                this.showReorderPagePrompt(true)
            },

            deletePage(page) {
                if (this.walkthrough?.pages.length > 1) {
                    const pageIndex = this.pages.indexOf(page)

                    // Select the next/previous page if deleting the currently selected one.
                    if (page === this.selectedPage) {
                        if (pageIndex < this.pages.length - 1) {
                            this.selectPage(this.pages[pageIndex + 1])
                        } else {
                            this.selectPage(this.pages[pageIndex - 1])
                        }
                    }

                    // Delete the walkthrough page assuming it isn't the only one.
                    this.doDeletePage(page)
                }
            },

            reEnableMapInteractions() {
                this.map.getInteractions().getArray().forEach(interaction => {
                    if (this.preWalkthroughState.interactions?.includes(interaction.constructor.name)) {
                        interaction.setActive(true)
                    }
                })
            },

            async save(closeDialog) {
                await this.saveWalkthrough()
                if (!this.isShareableLinkCreated) {
                    await this.createShareableLink({
                        enabled: true,
                        isWalkthroughShare: true,
                    })
                }
                if (closeDialog) {
                    closeDialog()
                    this.end()
                }
            },

            exit() {
                if (this.isMatterLinkShareUser) {
                    this.logout()
                } else {
                    this.exitAuthUserLinkSharedView()
                }
            },

            onOWLogoClick() {
                this.$router.push({
                    name: Route.LinkShareLanding,
                    params: {
                        linkGuid: this.currentLinkShare.linkGuid,
                    },
                })
            },
        },
    }
</script>

<style lang="scss">
    @import 'walkthrough-container.scss';
</style>
