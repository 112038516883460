// import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { getSearchesApiUri } from '@/utils/environment.utils'
import httpClient from './http-client'
import {
    ISearchProductPreferenceList,
    SearchOrder,
    SearchOrderDetails,
    SearchProductQuote,
    SearchProvider,
    SearchesHazard,
    SearchesProduct,
} from '@/store/modules/searches/types'
import {
    PropertyType,
} from '@/enums/searches.enum'

export interface IGetSearchProvidersResponse {
    [key: string]: SearchProvider[],
}

export interface IInitialiseContextRequest {
    providerId: number
    locationInformation: LocationInformation
    matterDetails: MatterDetails
    projectType: string
    propertySectorType: string,
    draftSearchOrderId?: string
    projectReference?: string
}

export interface LocationInformation {
    address: StructuredAddress
    titleNumber?: string
    uprn?: string
    geoJson?: string
    geoLoc: GeoLocation
}

export interface GeoLocation {
    easting: string
    northing: string
}

export interface MatterDetails {
    matterName: string
    clientCode: string
    matterCode?: string
}

//TODO: move sector out of this. Afaict it's only there bc of the enter address dialog
export interface StructuredAddress
{
    organisationName?: string
    buildingNumber?: string
    thoroughfareName?: string
    postTown?: string
    postcode?: string
    sector?: string
}

export interface IGetSearchesProductsRequest {
    contextId: string,
    propertySectorType: PropertyType,
    providerId: number,
    projectType: string,
}

export interface IGetSearchesProductsResponse {
    hazards: SearchesHazard[],
    products: SearchesProduct[],
}

export interface IGetSearchesQuoteRequestProduct {
    productId: string,
    productOptions?: string
}

export interface IGetSearchesQuoteRequest {
    contextId: string,
    products: IGetSearchesQuoteRequestProduct[],
    productIds: string[],
    numberOfParcels: number,
    providerId: number,
    projectType?: number,
    propertyType: PropertyType,
}

export interface IGetAllSearchesOrdersRequest {
    matterId: string,
}

export interface IGetAllSearchesOrdersResponse {
    orders: SearchOrder[],
}

//TODO: consider deleting this, and start using StructuredAddress
export interface ICreateSearchOrderRequestAddress {     //TODO: Remove this when we start storing addresses on initContext call
    organisationName?: string,
    buildingNumber?: string,
    thoroughfareName?: string,
    postTown?: string,
    postcode?: string,
}

export interface ICreateSearchOrderRequestProduct {
    productId?: string | number,
    productName?: string,
    supplierName?: string,
    totalGrossFee: number,
    totalVAT: number,
    estimatedCompletionDate?: string,
    productOptions?: string[],
}

export interface ICreateSearchOrderRequest {
    contextId: string,
    providerId: number,
    titleNumber?: string,
    matterId: string,
    uprn?: string,
    siteType: string,
    products?: ICreateSearchOrderRequestProduct[],
    numberOfParcels: number,
    address: ICreateSearchOrderRequestAddress,
    geoJson?: string,
    associatedTitles?: string[],
    projectType?: string,
    templateId?: number
}

export interface IUpdateDraftOrderRequest extends ICreateSearchOrderRequest {
    id: string
}

export interface ICreateSearchOrderResponse {
    clientReference: string
    requestId: string
}

export interface IGetSearchOrderDetailsRequest {
    id: string,
}

export interface IGetSearchDraftOrderDetailsRequest {
    id: string,
}

export interface IGetSearchOrderItemDocumentRequest {
    orderId: string,
    orderItemId: string,
    titleNumber: string,
}

export interface IDownloadSearchesDocumentsRequest {
    documents: { [key: string]: string[] }, // Key: OrderId, Value: List of filenames for order id
}

export interface IGetSearchDocumentSasTokensRequest {
    documents: { [key: string]: string[] }, // Key: OrderId, Value: List of filenames for order id
}

export interface IGetUprnAddressesForTitleNumbersRequest {
    titleNumbers: {
        titleNumber: string
        address: string
    }[]
}

export interface IGetUprnForLocationResponse {
    uprns: string[],
    errorMessage?: string,
    ok: boolean,
}

export interface IGetUprnForLocationRequest {
    titleNumber?: string,
    address?: string,
    geoJson?: string,
}

export interface INotificationConfigUser {
    id: string
    email: string
    name: string
    providerUserId?: string
    isSubscribedToProviderEmailNotification: boolean
}

export interface IGetNotificationUsersResponse {
    users?: INotificationConfigUser[]
}

export interface IUpdateNotificationUsersResponse {
    users?: INotificationConfigUser[]
}

export interface IGetOrganisationSettingsResponse {
    projectMask?: string
}

/**
 * Represents the SearchesApi class that provides methods for interacting with the searches API.
 */
export default class SearchesApi {
    static END_POINT = getSearchesApiUri()
    static controller = null
    static updateController: Record<number, AbortController> = {}

    /**
     * Initialises the context for the basket. (Required so that we can have one TMG project throughout the flow)
     * @param request - The data required to initialise the context
     * @returns A promise that resolves to an HTTP response containing a context id string
     */
    static async initialiseContext(request: IInitialiseContextRequest): Promise<IHttpClientResponse<string>> {
        return httpClient.post(`${ this.END_POINT }/context/initialise`, request)
    }

    /**
     * Retrieves all searches orders.
     * @param request - The request object containing the matterId.
     * @returns A promise that resolves to an HTTP response containing the list of searches orders.
     */
    static async getAllSearchesOrders(request: IGetAllSearchesOrdersRequest): Promise<IHttpClientResponse<IGetAllSearchesOrdersResponse>> {
        return httpClient.get(`${ this.END_POINT }/order/list?matterId=${ request.matterId }`)
    }

    /**
     * Retrieves the search providers.
     * @returns A promise that resolves to an HTTP response containing the search providers.
     */
    static async getSearchProviders(): Promise<IHttpClientResponse<IGetSearchProvidersResponse>> {
        return httpClient.get(`${ this.END_POINT }/providers`)
    }

    /**
     * Retrieves a uprn for a given location
     * NOTE: not used at the moment, since we moved away from using UPRN. May be retired soon.
     * @param request - The location to get the uprn for
     * @returns A promise that resolves to an HTTP response containing a list of UPRNs for a given location
     */
    static async getUprnForLocation(request: IGetUprnForLocationRequest): Promise<IHttpClientResponse<IGetUprnForLocationResponse>> {
        return await httpClient.post(`${ this.END_POINT }/location/uprn`, request)
    }

    /**
     * Retrieves the searches products
     * @param request - The request object containing the title number, property type and provider ID.
     * @returns A promise that resolves to an HTTP response containing the searches products.
     */
    static async getSearchesProducts(request: IGetSearchesProductsRequest): Promise<IHttpClientResponse<IGetSearchesProductsResponse>> {
        const { propertySectorType, providerId, contextId } = request
        return httpClient.get(`${ this.END_POINT }/product/list?propertySectorType=${ propertySectorType }&providerId=${ providerId }&contextId=${ contextId }`)
    }

    /**
     * Retrieves the searches quote.
     * @param request - The request object containing the title number, product ID and number of parcels.
     * @returns A promise that resolves to an HTTP response containing the searches quote.
     */
    static async getSearchesQuote(request: IGetSearchesQuoteRequest): Promise<IHttpClientResponse<SearchProductQuote>> {
        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()
        return httpClient.post(`${ this.END_POINT }/product/quote`, {
            ...request,
        }, {
            signal: this.controller.signal,
        })
    }

    /**
     * Creates a search order.
     * @param request - The request object containing the title number, product ID and number of parcels.
     * @returns A promise that resolves to an HTTP response containing the searches quote.
     */
    static async createSearchOrder(request: ICreateSearchOrderRequest): Promise<IHttpClientResponse<ICreateSearchOrderResponse>> {
        return httpClient.post(`${ this.END_POINT }/order/create`, request)
    }

    /**
     * Retrieves the search order details.
     * @param request - The request object containing the search order ID.
     * @returns A promise that resolves to an HTTP response containing the search order details.
     */
    static async getSearchOrderDetails(request: IGetSearchOrderDetailsRequest): Promise<IHttpClientResponse<SearchOrderDetails>> {
        return httpClient.get(`${ this.END_POINT }/order/${ request.id }`)
    }

    /**
     * Retrieves the search order details.
     * @param request - The request object containing the search order ID.
     * @returns A promise that resolves to an HTTP response containing the search order details.
     */
    static async getDraftOrderDetails(request: IGetSearchDraftOrderDetailsRequest): Promise<IHttpClientResponse<SearchOrderDetails>> {
        return httpClient.get(`${ this.END_POINT }/order/draft/${ request.id }`)
    }

    /**
     * Get all search documents for the specified matter
     * @param matterId - The matter id to get the documents for
     */
    static async getByMatterId(matterId: number): Promise<IHttpClientResponse> {
        const uri = `${ this.END_POINT }/documents/list`

        return httpClient.get(uri, { params: { matterId } })
    }

    /**
     * Get all search documents for the specified matter
     * @param documents - The download documents request
     */
    static async downloadDocuments(documents: IDownloadSearchesDocumentsRequest): Promise<void> {
        const uri = `${ this.END_POINT }/documents/zip`

        const response = await httpClient.post(uri, { documents: documents.documents }, { responseType: 'blob' })
        if (!response) {
            throw new Error('Failed to download the document.')
        }
        const blob = new Blob([response.data], { type: 'application/zip' })
        window.saveAs(blob, 'searches-documents.zip')
    }

    /**
     * Get all search documents for the specified matter
     * @param documents - The sas token documents request
     */
    static async getSasToken(documents: IGetSearchDocumentSasTokensRequest): Promise<IHttpClientResponse> {
        const uri = `${ this.END_POINT }/documents`

        return await httpClient.post(uri, { documents: documents.documents })
    }

    /**
     * Get every Search Template (Product Preference List) for the specified organisation
     * @param orgId - The organisation id to get the preferences for
     */
    static async getProductPreference(orgId: string): Promise<IHttpClientResponse<ISearchProductPreferenceList>> {
        const uri = `${ this.END_POINT }/preference/${ orgId }`
        return await httpClient.get(uri)
    }

    /**
     * Create a draft order
     * @param request - The request object containing the order details
     * @returns A promise that resolves to the guid of the created draft order
     */
    static async createDraftOrder(request: ICreateSearchOrderRequest): Promise<IHttpClientResponse<string>> {
        return httpClient.post(`${ this.END_POINT }/order/draft`, request)
    }

    /**
     * Updates a draft order
     * @param request - The request object containing the order details
     * @returns A promise that resolves to the guid of the created draft order
     */
    static async updateDraftOrder(request: IUpdateDraftOrderRequest): Promise<IHttpClientResponse<string>> {
        return httpClient.put(`${ this.END_POINT }/order/draft`, request)
    }

    /**
     * Submits a draft order to the provider, turning it to be a live order
     * @param request - The request object containing the order details
     * @returns A promise that resolves to the OW guid id of the order (same as what was sent in inside the request)
     */
    static async submitDraftOrder(request: IUpdateDraftOrderRequest): Promise<IHttpClientResponse<string>> {
        return httpClient.post(`${ this.END_POINT }/order/draft/submit`, request)
    }

    /**
     * Retrieves the notification users for a given order ID.
     * @param orderId - The ID of the order.
     * @returns A promise that resolves to an HTTP response containing the notification users.
     */
    static async getNotificationUsers(orderId: string): Promise<IHttpClientResponse<IGetNotificationUsersResponse>> {
        const uri = `${ this.END_POINT }/notification/${ orderId }`
        return await httpClient.get(uri)
    }

    /**
     * Updates the notification users for a specific order.
     *
     * @param orderId - The ID of the order.
     * @param users - An array of notification configuration users.
     * @returns A promise that resolves to an `IHttpClientResponse` object.
     */
    static async updateNotificationUsers(orderId: string, users: INotificationConfigUser[]): Promise<IHttpClientResponse<IUpdateNotificationUsersResponse>> {
        const uri = `${ this.END_POINT }/notification/${ orderId }`
        return await httpClient.post(uri, { users })
    }

    /**
     * Gets organisation settings
     *
     * @returns A promise that resolves to an `IHttpClientResponse` object.
     */
    static async getOrganisationSettings(): Promise<IHttpClientResponse<IGetOrganisationSettingsResponse>> {
        const uri = `${ this.END_POINT }/settings`
        return await httpClient.get(uri)
    }
}
