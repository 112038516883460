<template>
    <div class="d-flex flex-column planning-container-item-results"
         data-test="planning-results-list">
        <planning-applications-result-item v-for="(result, index) in filteredResults"
                                           :key="index"
                                           :result="result"
                                           :scroll-element-container="scrollElementContainer" />
    </div>
</template>

<script lang="ts">
    import { mapState } from 'vuex'

    import PlanningApplicationsResultItem from '@/components/title-panel/v2/planning/planning-application-result-item.vue'

    export default {
        name: 'Planning',

        components: {
            PlanningApplicationsResultItem,
        },

        props: {
            scrollElementContainer: {
                type: String,
                required: true,
            },
        },

        computed: {
            ...mapState({
                filteredResults: state => state.planning.filteredResults,
            }),
        },
    }
</script>
<style scoped lang="scss">
    @import 'planning-results';
</style>
