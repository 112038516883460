export enum FindNearbyType{
    MatterTitles,
    CorporateOwners,
}
export class FindNearbySettings {
    public type: FindNearbyType
    public defaultRadiusMetres: number
    public radiusStepMetres: number
    public maximumRadiusMetres: number
    public minimumRadiusMetres: number

    // Formats the text on the slider thumb
    public thumbLabelFormatter?: Function

    // The key used to link features and result items, eg title_no, avoids use of magic strings.
    public featureKey: string
}
