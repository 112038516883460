<template>
    <ow-full-page-lock v-model="showLock"
                       :feature-id="FeatureId.Nearby"
                       class="pa-6 full-width full-height"
                       is-card>
        <find-nearby-container-with-map :current-matter-id="currentMatterId"
                                        :selected-title-numbers="selectedTitleNumbers"
                                        :is-loading="isLoading"
                                        :tab-controller="tabController" />
    </ow-full-page-lock>
</template>

<script lang="ts">
    import {
        inject,
        onActivated,
        onBeforeMount,
        onDeactivated,
        onMounted,
        PropType,
        ref,
        watch,
    } from "vue"

    import OwFullPageLock from "@/components/core/ow-full-page-lock.vue"
    import FindNearbyContainerWithMap from "@/components/title-panel/v2/find-nearby/find-nearby-container-with-map.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import { ITitlePanelTabController } from "@/composables/use-title-panel"
    import { inject as titlePanelProvider } from "@/composables/use-title-panel"
    import { inject as userProvider } from "@/composables/use-user"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"

    export default {
        name: 'FindNearbyTab',

        components: { FindNearbyContainerWithMap, OwFullPageLock },

        props: {
            selectedTitleNumbers: {
                type: Array,
                required: true,
            },

            currentMatterId: {
                type: Number,
                required: true,
            },

            tabController: {
                type: Object as PropType<ITitlePanelTabController>,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            const showLock = ref()
            const { hasAccessToFindNearby } = userProvider()

            const { activeTabId, forceTitlePanelFullScreen } = titlePanelProvider()

            onBeforeMount(async () => {
                const hasAccess = await hasAccessToFindNearby()
                showLock.value = !hasAccess
            })

            watch(activeTabId, (newVal) => {
                if (newVal === TitlePanelTabName.FindNearby) {
                    forceTitlePanelFullScreen()
                }
            }, { immediate: true })

            return {
                FeatureId,
                showLock,
            }
        },
    }
</script>
