import {
    TitleOrganiserResult,
    TitleOrganiserResultGroup,
    TitleOrganiserResultTitle,
} from '@/store/modules/matter/title-organiser/title-organiser-result.type'
import { BoundaryColourPaletteUsage } from '@/enums/boundary-colour-palette-usage'
import { BoundaryStyle } from '@/models/styles/boundary-style'
import { DefaultBoundaryColourPalette } from '@/models/styles/default-boundary-colour-palette'
import { IMatterGroup } from '@/interfaces/matter-group.interface'
import { ITitleInMatterReference } from '@/interfaces/title-in-matter.reference'
import { ITitleOrganiserService } from '@/store/modules/matter/title-organiser/title-organiser-service.interface'
import { sortBySortOrderFn } from '@/utils/array-utils'

export abstract class TitleOrganiserBaseService implements ITitleOrganiserService {
    public id = 'base'
    public i18nReference = 'default'
    public colourPalette = new DefaultBoundaryColourPalette(BoundaryColourPaletteUsage.TitleOrganiser)

    public async getMatterContents(_matterId: string, currentTitles: Array<ITitleInMatterReference>, currentGroups: Array<IMatterGroup>,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        applyStyling: boolean, _selectedField: string = null): Promise<TitleOrganiserResult> {
        currentTitles.sort(sortBySortOrderFn)
        currentGroups.sort(sortBySortOrderFn)

        if (!applyStyling) {
            return Promise.resolve(new TitleOrganiserResult(currentTitles.map(t => new TitleOrganiserResultTitle(t)), currentGroups))
        }

        let colourPaletteIndex = 0
        const resultTitles = []

        // Apply the same style to titles in the same group.
        currentGroups.forEach((group: IMatterGroup) => {
            const titlesInGroup = currentTitles.filter((title) => title.matterGroupId === group.id && title.boundaryAvailable)
            if (titlesInGroup.length > 0) {
                titlesInGroup.forEach((title) => {
                    const paletteItem = this.colourPalette.getEntryForIndex(colourPaletteIndex)
                    const resultTitle = new TitleOrganiserResultTitle(title)
                    this.applyPaletteItemToTitle(resultTitle, paletteItem)
                    resultTitles.push(resultTitle)
                })
                colourPaletteIndex++
            }
        })
        // Ungrouped titles should continue rotating through the remainder of the colour palette.
        currentTitles.forEach((title: ITitleInMatterReference) => {
            if (title.matterGroupId === null && title.boundaryAvailable) {
                const paletteItem = this.colourPalette.getEntryForIndex(colourPaletteIndex)
                const resultTitle = new TitleOrganiserResultTitle(title)
                this.applyPaletteItemToTitle(resultTitle, paletteItem)
                resultTitles.push(resultTitle)
                colourPaletteIndex++
            }
        })

        return Promise.resolve(new TitleOrganiserResult(
            resultTitles,
            currentGroups.map((group) => new TitleOrganiserResultGroup(group)),
        ))
    }

    public applyPaletteItemToTitle(title: TitleOrganiserResultTitle, paletteItem: BoundaryStyle) {
        title.boundaryStyle.alias = paletteItem.alias
        title.boundaryStyle.colour = paletteItem.colour
        title.boundaryStyle.labelColour = paletteItem.labelColour
        title.boundaryStyle.show = true
        title.boundaryStyle.fillOpacity = paletteItem.fillOpacity
        title.boundaryStyle.hatch = paletteItem.hatch
        title.boundaryStyle.strokeWidth = paletteItem.strokeWidth
        title.boundaryStyle.fillColour = paletteItem.fillColour ?? paletteItem.colour
        title.boundaryStyle.dashed = paletteItem.dashed
        title.boundaryStyle.fillColour = paletteItem.fillColour
        title.boundaryStyle.fill = paletteItem.fillColour != undefined || paletteItem.fillOpacity > 0
    }

    public init(): Promise<void> {
        return Promise.resolve()
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public isAvailable(featureFlags: Record<string, boolean>): boolean {
        return true
    }

    public onInit(): void {
        // Do nothing.
    }

    isLoading = false
    quickStylingByDefault = false
    hasAppliedChanges = false
}
