// Getters

// Mutations
export const DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS = 'DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS'
export const DOCUMENT_ORDERING_MUTATE_SUCCESSFUL_ORDERS = 'DOCUMENT_ORDERING_MUTATE_SUCCESSFUL_ORDERS'
export const DOCUMENT_ORDERING_MUTATE_ERROR_ORDERS = 'DOCUMENT_ORDERING_MUTATE_ERROR_ORDERS'
export const DOCUMENT_ORDERING_MUTATE_ORDER_STATUS = 'DOCUMENT_ORDERING_MUTATE_ORDER_STATUS'
export const DOCUMENT_ORDERING_MUTATE_PROCESSING_ORDER = 'DOCUMENT_ORDERING_MUTATE_PROCESSING_ORDER'
export const DOCUMENT_ORDERING_MUTATE_ERROR = 'DOCUMENT_ORDERING_MUTATE_ERROR'
export const DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS = 'DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS'
export const DOCUMENT_ORDERING_REMOVE_ORDER_FROM_ERROR_ORDERS = 'DOCUMENT_ORDERING_REMOVE_ORDER_FROM_ERROR_ORDERS'

// Actions
export const ORDER_DOCUMENTS = 'ORDER_DOCUMENTS'
export const UPDATE_DOCUMENT_ORDER_STATUS = 'UPDATE_DOCUMENT_ORDER_STATUS'
export const UPDATE_DOCUMENT_ORDER_STATUS_DATA = 'UPDATE_DOCUMENT_ORDER_STATUS_DATA'
export const FIND_ORDER_FROM_STATE_BY_ORDER_ID = 'FIND_ORDER_FROM_STATE_BY_ORDER_ID'
