import { PlanningColours } from '@/consts/planning'

export const getColourForDecision = (decisionEnumInt) => PlanningColours.decision[decisionEnumInt]

/**
 * Returns a SVG as a string for use as a map icon.
 * @param {any} colour - a valid colour to be used as a fill.
 */
export const getMarkerPinSVG = (colour) => encodeURIComponent(

    /* eslint-disable max-len */
    `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
        <svg
           fill="${ colour }"
           version="1.1"
           width="26"
           height="27"
           viewBox="0 0 26.196112 27.139446"
           id="svg4"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:svg="http://www.w3.org/2000/svg">
          <defs>
            <filter
               style="color-interpolation-filters:sRGB"
               id="dsFilter"
               x="-0.058596165"
               y="-0.065212497"
               width="1.1171923"
               height="1.130425">
              <feGaussianBlur
                 stdDeviation="0.4 0.3"
                 result="blur" />
            </filter>
          </defs>
          <path
             d="m 13.986526,14.479586 3.266891,-4.416332 h 0.96966 L 19.039799,8.959171 H 9.343193 l -0.816722,1.104083 h 0.96966 L 6.22924,14.479586 3.4731962,15.583669 2.656474,16.687752 H 7.698709 L 5.248541,20 H 6.8 l 2.450168,-3.312248 h 5.042234 l 0.816722,-1.104083 z"
             style="opacity:0.35;fill:#000000;stroke-width:0.731637;filter:url(#dsFilter)"
             transform="scale(1.3098188)" />
          <g
             transform="translate(-6,4.4194457)" />
          <g
             transform="translate(-6,4.4194457)">
            <path
               d="M 19.098188,8.6787424 V -1.7998081 h 1.309819 V -4.4194457 H 7.3098188 v 2.6196376 H 8.6196376 V 8.6787424 L 6,11.29838 v 2.619638 h 6.811058 v 7.858913 h 2.09571 v -7.858913 h 6.811058 V 11.29838 Z"
               style="stroke-width:1.30982" />
          </g>
        </svg>
`)
/* eslint-enable max-len */

/**
 * Returns a boolean indicating whether the comma-separated list of values for two feature arrays are equal given a
 * property name. May be useful elsewhere, consider moving.
 * @param {any} features1 - array of ol/Feature.
 * @param {any} features2 - another array of ol/Feature to compare against.
 * @param {string} property - property name.
 */
export const featureArraysAreEqualByProperty = (features1, features2, property) =>
    features1.map(f => f.get(property)).sort().toString() === features2.map(f => f.get(property)).sort().toString()
