<template>
    <div data-test="tab-owner-results">
        <no-search-results v-if="count === 0"
                           context="CorporateOwner" />
        <v-list v-else
                class="owner-list"
                density="compact"
                light>
            <div class="body-copy"
                 data-test="tab-owner-results-count">
                {{ count }} results found
            </div>
            <div class="content__small-print">
                {{ corporateOwnershipText }}
            </div>

            <owners-results-header :is-all-disabled="allOwnersSelected"
                                   :is-none-disabled="noOwnersSelected"
                                   :owner-sort-options="ownerSortOptions"
                                   :include-divider="true"
                                   @is-all-selected="selectOwners"
                                   @sort-by="sortResults" />

            <div class="owner-result-table-header">
                <span class="content__small-print">Name</span>
                <span class="content__small-print">Number</span>
                <span class="content__small-print">No. of titles</span>
            </div>

            <div class="owner-results">
                <owners-results-item v-for="(owner, index) in localCopyOfOwners"
                                     :key="`${ owner.name }-${ index }`"
                                     :owner="owner"
                                     :data-test="`tab-owner-results-item-${ index }`"
                                     :data-owner="owner.text"
                                     data-track="SEARCH - Corporate owner result"
                                     @selection-changed="updateSelectionState" />
            </div>

            <div class="d-flex flex-row justify-end selected-actions">
                <div class="selected-actions__text">
                    {{ selectedOwnersText }}
                </div>
                <v-btn :disabled="selectedOwners.length === 0"
                       class="selected-actions__button"
                       color="primary"
                       size="large"
                       data-test="search-by-owner-next-button"
                       data-track="SEARCH - Corporate owner selection next button"
                       @click.prevent="onOwnersNextClick()">
                    Next
                </v-btn>
            </div>
        </v-list>
    </div>
</template>

<script>
    import {
        mapState,
    } from 'vuex'

    import NoSearchResults from '@/components/map/search/no-search-results.vue'
    import { dynamicSort } from '@/utils/array-utils'
    import { getDayBefore } from '@/utils/date-utils'

    import OwnersResultsHeader from './tab-owner-results-header.vue'
    import OwnersResultsItem from './tab-owner-results-item.vue'

    export default {
        name: 'TabOwnerResults',

        components: {
            OwnersResultsHeader,
            OwnersResultsItem,
            NoSearchResults,
        },

        props: {
            // Companies/owners are interchangeable.
            owners: {
                type: Array,
                required: true,
            },

            count: {
                type: Number,
                required: true,
            },
        },

        emits: [
            'selected',
            'selection-changed',
        ],

        data() {
            return {
                ownerSortOptions: [{
                    id: 'text',
                    label: 'Company name',
                }, {
                    id: '-companyRegistrationNumber',
                    label: 'Company number',
                }, {
                    // The '-' sorts descending, so biggest number at the top
                    id: '-titleCount',
                    label: 'Title count',
                }],
                selectedSortOption: 'text',
                localCopyOfOwners: [],
                allOwnersSelected: false,
                noOwnersSelected: true,
                selectedOwners: [],
            }
        },

        computed: {
            ...mapState({
                monthlyHMLRDataVersion: (state) => state.config.settings.monthlyHMLRDataVersion,
            }),

            selectedOwnersText() {
                let text = `${ this.selectedOwners.length } owner`
                if (this.selectedOwners.length !== 1) {
                    text += 's'
                }
                text += ' selected'
                return text
            },

            corporateOwnershipText() {
                return this.$t('searchBar.corporateOwnershipUpdated', { date: getDayBefore(this.monthlyHMLRDataVersion) })
            },
        },

        watch: {
            owners: {
                handler(val) {
                    this.localCopyOfOwners = [...val].sort(dynamicSort(this.selectedSortOption))
                    this.updateGlobalSelectionState()
                },
                deep: true,
            },
        },

        mounted() {
            this.localCopyOfOwners = [...this.owners]
            this.sortResults(this.ownerSortOptions[0].id)
        },

        methods: {
            sortResults(by) {
                this.selectedSortOption = by
                this.localCopyOfOwners.sort(dynamicSort(by))
            },

            selectOwners(isAllSelected) {
                if (isAllSelected) {
                    this.selectAllOwners()
                } else {
                    this.selectNoOwners()
                }
            },

            selectAllOwners() {
                this.localCopyOfOwners = this.owners.map(o => ({
                    ...o,
                    selected: true,
                })).sort(dynamicSort(this.selectedSortOption))
                this.selectedOwners = this.localCopyOfOwners
                this.updateGlobalSelectionState()
            },

            selectNoOwners() {
                this.localCopyOfOwners = this.owners.map(o => ({
                    ...o,
                    selected: false,
                })).sort(dynamicSort(this.selectedSortOption))
                this.selectedOwners = []
                this.updateGlobalSelectionState()
            },

            updateSelectionState(item) {
                const selectedItem = this.localCopyOfOwners.find(o => o.sort === item.owner.sort)
                selectedItem.selected = item.selected

                if (item.selected) {
                    this.selectedOwners.push(selectedItem)
                } else {
                    this.selectedOwners = this.selectedOwners.filter(o => o.sort !== selectedItem.sort)
                }

                this.updateGlobalSelectionState()
            },

            updateGlobalSelectionState() {
                this.allOwnersSelected = this.localCopyOfOwners.every(o => o.selected === true)
                this.noOwnersSelected = this.localCopyOfOwners.every(o => o.selected === false)
                this.$emit('selection-changed', this.selectedOwners)
            },

            async onOwnersNextClick() {
                const data = this.selectedOwners.map(owner => ({
                    proprietorName: owner.text,
                    titleNumbers: owner.titleNumbers,
                }))

                this.$emit('selected', data)
                this.selectNoOwners()
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './tab-owner-results';
</style>
