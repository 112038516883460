<template>
    <header>
        <div class="searches-documents-summary">
            <div v-if="!isLoading"
                 class="body-copy searches-documents-summary__label">
                <span class="body-regular searches-documents-summary__label--text">
                    <span>{{ $t('label.showing') }}</span>
                    <!-- Total document count -->
                    <span data-test="searches-documents-summary-total-count">
                        {{ $t('documents.library.documentsCount', documentsCount) }}
                    </span>
                    <span v-if="groupByProducts"
                          data-test="searches-documents-summary-group-count">
                        {{ $t('documents.library.productsCount', groupsCount) }}
                    </span>
                </span>
            </div>
            <div class="searches-documents-summary__actions">
                <ow-button v-if="showResetButton"
                           v-t="'action.clearFilter'"
                           is-primary
                           data-test="searches-documents-summary-clear-filter-btn"
                           data-track="DOCUMENTS - Clear filter"
                           @click="reset" />
                <ow-button v-t="'action.refresh'"
                           is-secondary
                           data-test="searches-documents-summary-refresh-searches-btn"
                           data-track="DOCUMENTS - Refresh searches documents"
                           @click="refreshDocuments" />
            </div>
        </div>
        <!-- eslint-disable vuetify/no-deprecated-events -->
        <!-- TODO: v-checkbox still uses the @change event even if it's marked as deprecated -->
        <v-checkbox v-model="groupByProducts"
                    color="primary"
                    data-test="searches-documents-summary-group-by-product"
                    data-track="DOCUMENTS - Group searches by product"
                    hide-details
                    label="Group by product"
                    class="mb-4" />
        <!--eslint-enable-->
    </header>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useRoute,
             useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import { DOCUMENTS_FETCH_SEARCHES_DOCUMENTS } from '@/store/modules/documents/documents-types'

    const props = defineProps<{
        isLoading?: boolean
        documentsCount: number,
        groupsCount: number,
        matterId: number
    }>()

    const emit = defineEmits<{
        (e: 'group-by-products-changed', args: boolean): void
    }>()

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const refreshDocuments = () => {
        store.dispatch(DOCUMENTS_FETCH_SEARCHES_DOCUMENTS, props.matterId)
    }

    const showResetButton = computed(() => route?.params?.orderId)

    const reset = () => {
        router.push({
            name: 'documents-library',
            params: {
                id: props.matterId,
                orderId: '',
            },
            hash: '#searches',
        })
    }

    const groupByProducts = ref<Boolean>(false)

    watch(groupByProducts, (newVal, oldVal) => {
        emit('group-by-products-changed', newVal)
    })

    defineExpose({
        groupByProducts,
        route,
        router,
    })

</script>

<style scoped lang="scss">
    @import "searches-documents-summary";
</style>
