<template>
    <div class="matter-share-button">
        <v-btn class="matter-share-button__button"
               variant="flat"
               @click="onClick">
            <v-icon v-if="matter.sharedWithOrganisation || matter.sharedWithSpecificUsers"
                    class="matter-share-button__icon-person"
                    data-test="matter-share-button-people-alt-icon">
                $matter-shared
            </v-icon>
            <v-icon v-else
                    class="matter-share-button__icon-person"
                    :class="{'is-not-shared': !matter.sharedWithSpecificUsers && !matter.sharedWithOrganisation && !matter.linkShare}"
                    data-test="matter-share-button-person-icon">
                $matter-sharing
            </v-icon>
            Share
            <v-icon v-if="matter.linkShare"
                    class="matter-share-button__icon-public"
                    :class="{'is-disabled': !matter.linkShare.enabled}"
                    data-test="matter-share-button-public-icon">
                $matter-link-share
            </v-icon>
        </v-btn>
        <ow-tooltip activator="parent"
                    :position="OwTooltipPosition.Right">
            <span class="matter-share-button__share-button-tooltip">
                {{ tooltip }}
            </span>
        </ow-tooltip>
    </div>
</template>

<script>
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'

    export default {
        components: {
            OwTooltip,
        },

        props: {
            matter: {
                type: Object,
                required: true,
            },
        },

        emits: [
            'on-click',
        ],

        data() {
            return {
                OwTooltipPosition,
            }
        },

        computed: {
            tooltip() {
                if (this.matter.sharedWithOrganisation) {
                    if (this.matter.linkShare?.enabled) {
                        return 'Shared with the whole organisation and externally'
                    }
                    if (this.matter.linkShare?.enabled === false) {
                        return 'Shared with the whole organisation, external sharing disabled'
                    }
                    return 'Shared with the whole organisation'
                }

                if (this.matter.sharedWithSpecificUsers) {
                    if (this.matter.linkShare?.enabled) {
                        return 'Shared with specific users and externally'
                    }
                    if (this.matter.linkShare?.enabled === false) {
                        return 'Shared with specific users, external sharing disabled'
                    }
                    return 'Shared with specific users'
                }

                if (this.matter.linkShare?.enabled) {
                    return 'Private matter, shared externally'
                }
                if (this.matter.linkShare?.enabled === false) {
                    return 'Private matter, external sharing disabled'
                }
                return 'Private matter'
            },
        },

        methods: {
            onClick() {
                this.$emit('on-click')
            },
        },
    }
</script>

<style lang="scss">
    @import 'matter-share-button.scss'
</style>
