import i18n from '@/plugins/i18n'
import { useActionColumn } from '@/components/core/ow-grid/columns/use-action-column'
import BasketItemModel from '../models/BasketItemModel'
import { CostColumn,
    ExpectedDateColumn,
    HazardsColumn,
    ProductColumn} from '@/components/matter-searches/columns'

export const useColumns = ({
    on,
}: {
    on: (event: string, column: string, record: BasketItemModel) => void
}) => {

    const ActionColumn = useActionColumn({
        width: 175,
        onEvent: on,
        actions: [{
            id: 'swap',
            visible: () => true,
            style: 'link',
            primary: true,
            title: i18n.global.t('searches.swapProduct').toString(),
        }, {
            id: 'remove',
            className: 'remove-button mdi mdi-delete-outline',
            style: 'icon',
        }],
    })

    return {
        gridColumns: [
            ProductColumn,
            HazardsColumn,
            ExpectedDateColumn,
            CostColumn,
            ActionColumn,
        ],
    }
}
