/**
 * User types
 */
// user actions
export const USER_HIDE_POPUP = 'USER_HIDE_POPUP'
export const USER_INITIATE_STATUS_UPDATES = 'USER_INITIATE_STATUS_UPDATES'
export const USER_IS_ADMIN = 'USER_IS_ADMIN'
export const USER_IS_IN_ROLE = 'USER_IS_IN_ROLE'
export const USER_IS_ORGANISATION_ADMIN = 'USER_IS_ORGANISATION_ADMIN'
export const USER_REQUEST_PROFILE = 'USER_REQUEST_PROFILE'
export const USER_CREATE_PRODUCTBOARD_TOKEN = 'USER_CREATE_PRODUCTBOARD_TOKEN'
export const USER_SAVE_WORKING_FROM_HOME_PREFERENCES = 'USER_SAVE_WORKING_FROM_HOME_PREFERENCES'
export const USER_SHOW_POPUP = 'USER_SHOW_POPUP' // with request object, see implementation

// user getters
export const USER_GET_BASIC_INFO = 'USER_GET_BASIC_INFO'
export const USER_GET_DOCUMENT_CHARGE = 'USER_GET_DOCUMENT_CHARGE '
export const USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG = 'USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG'

// user mutations
export const USER_MUTATE_LOADING = 'USER_MUTATE_LOADING'
export const USER_MUTATE_LOGIN = 'USER_MUTATE_LOGIN'
export const USER_MUTATE_LOGOUT = 'USER_MUTATE_LOGOUT'
export const USER_MUTATE_PROFILE = 'USER_MUTATE_PROFILE'
export const USER_MUTATE_PRODUCTBOARD_TOKEN = 'USER_MUTATE_PRODUCTBOARD_TOKEN'
export const USER_MUTATE_PROFILE_USES_CLIENT_CODE = 'USER_MUTATE_PROFILE_USES_CLIENT_CODE'
export const USER_MUTATE_SHOW_MAP_TITLES_NAV = 'USER_MUTATE_SHOW_MAP_TITLES_NAV' // with boolean
export const USER_MUTATE_POPUP_PROPERTIES = 'USER_MUTATE_POPUP_PROPERTIES'
export const USER_MUTATE_USER_PREFERENCES = 'USER_MUTATE_USER_PREFERENCES'

// Matter  - drawing related
export const MATTER_DRAW_GET_WKT = 'MATTER_DRAW_GET_WKT'
export const MATTER_DRAW_ANNOTATION_ENABLE = 'MATTER_DRAW_ANNOTATION_ENABLE'
export const MATTER_DRAW_ANNOTATION_SET_MODE = 'MATTER_DRAW_ANNOTATION_SET_MODE'
export const MATTER_DRAW_ANNOTATION_CLEAR_FEATURES = 'MATTER_DRAW_ANNOTATION_CLEAR_FEATURES'
export const MATTER_DRAW_ANNOTATION_END = 'MATTER_DRAW_ANNOTATION_END'

/**
 * Logging types
 */
export const LOGGING_DATADOG_INITIALISE = 'LOGGING_DATADOG_INITIALISE'
export const LOGGING_DATADOG_LOG_INFO_EVENT = 'LOGGING_DATADOG_LOG_INFO_EVENT'
export const LOGGING_HEAP_INITIALISE = 'LOGGING_HEAP_INITIALISE'
export const LOGGING_HEAP_RESET_IDENTITY = 'LOGGING_HEAP_RESET_IDENTITY'
export const LOGGING_HEAP_TRACK_EVENT = 'LOGGING_HEAP_TRACK_EVENT'
export const LOGGING_INITIALISE_ALL = 'LOGGING_INITIALISE_ALL'
export const LOGGING_INTERCOM_INITIALISE = 'LOGGING_INTERCOM_INITIALISE'
export const LOGGING_INTERCOM_TRACK_EVENT = 'LOGGING_INTERCOM_TRACK_EVENT'
export const LOGGING_INTERCOM_UPDATE = 'LOGGING_INTERCOM_UPDATE'
export const LOGGING_LOG_AXIOS_ERROR = 'LOGGING_LOG_AXIOS_ERROR'
export const LOGGING_LOG_FEATURE_USAGE = 'LOGGING_LOG_FEATURE_USAGE'
export const LOGGING_OSMAPS_INITIALISE = 'LOGGING_OSMAPS_INITIALISE'
export const LOGGING_OW_INITIALISE = 'LOGGING_OW_INITIALISE'
export const LOGGING_REMOVE_STATE_PROPERTY = 'LOGGING_REMOVE_STATE_PROPERTY'
export const LOGGING_SENTRY_INITIALISE = 'LOGGING_SENTRY_INITIALISE'
export const LOGGING_SET_STATE_PROPERTIES = 'LOGGING_SET_STATE_PROPERTIES'
export const LOGGING_SET_STATE_PROPERTY = 'LOGGING_SET_STATE_PROPERTY' // request - {property: "Title", value: "TGL50538"}

/**
 * Miscellaneous types
 */
export const OPEN_WINDOW = 'OPEN_WINDOW' // open a new tab with url - Defined in user module for some reason
export const HIDE_POPUP_BLOCKED_WARNING = 'HIDE_POPUP_BLOCKED_WARNING' // Defined in user module for some reason
