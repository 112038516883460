import {
    Fill,
    Style,
} from 'ol/style'
import { LayerNames } from '@/consts/map-layers'
import {
    BaseMvtDataLayer,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { MapBrowserEvent } from 'ol'
import Feature, { FeatureLike } from 'ol/Feature'
import { StyleLike } from 'ol/style/Style'
import {
    FloodZoneLayerParams,
    RiskSortOrder,
    getFloodZoneRolloverOptions,
} from '@/store/modules/map/layers/flood-layers-common'
import i18n from '@/plugins/i18n'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { unique } from '@/utils/array-utils'

import { FloodRiskFeaturesType } from './flood-layers-common'

const Risk = {
    2: 'High',
    1: 'Medium',
    0: 'Low',
} as const

enum RiskColours {
    'High' = 'rgba(25, 111, 61, 0.6)',
    'Medium' = 'rgba(82, 190, 128, 0.7)',
    'Low' = 'rgba(115, 198, 182, 0.7)',
    'Very Low' = 'rgba(163, 228, 215, 0.7)',
}

enum RiskIndex {
    'High' = 340,
    'Medium' = 335,
    'Low' = 330,
    'Very Low' = 320,
}

// This layer is currently updated manually. Note the attribution text is hard-coded.
export class FloodRisksSurfaceWaterLayer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: FloodZoneLayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:vflood_risk_surface_water_2024'
        this.name = LayerNames.FloodRiskSurfaceWater
        this.attributionText = [i18n.global.t('map.options.flood.attribution.englandAndWales')]
        this.rolloverOptions = getFloodZoneRolloverOptions(LayerNames.FloodRiskSurfaceWater, this.style)
        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'Toggle Flood Risk - Surface Water layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                const textArray = features.length > 0
                    ? FloodRisksSurfaceWaterLayer.getHoverTextArray(features.map(x => x.getProperties() as FloodRiskFeaturesType))
                    : []
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public name: string = LayerNames.ConservationAreas

    public style:StyleLike = this.getStyleForFeature.bind(this)

    private getStyleForFeature(feature: Feature): Style {
        const properties: FloodRiskFeaturesType = feature.getProperties() as FloodRiskFeaturesType

        const risk = Risk[properties.risk]
        const colour = RiskColours[risk]

        const styleHash = `${ risk }-${ colour }`
        const result = this.styleCache.get(styleHash)

        if (result) {
            return result
        }

        const style = new Style({
            zIndex: Number(RiskIndex[risk]),
            fill: new Fill({ color: colour }),
        })

        this.styleCache.set(styleHash, style)
        return style
    }

    public static getHoverTextArray(propertiesArray: Array<FloodRiskFeaturesType>):string[] {
        if (propertiesArray.length <= 0) {
            return []
        }

        const risks = []
        const layerType = new Set()
        const source = new Set()

        propertiesArray.forEach(properties => {
            risks.push(properties.risk)
            layerType.add(properties.type)
            source.add(properties.source)
        })

        const risk = Math.max(...risks)

        const textArray = [
            `${ i18n.global.t('map.options.flood_risks.hover.risk') }: ${ Risk[risk] }`,
            `${ i18n.global.t('map.options.flood_risks.hover.type') }: ${ Array.from(layerType).join(', ') }`,
            `${ i18n.global.t('map.options.flood_risks.hover.source') }: ${ Array.from(source).join(', ') }`,
        ]
        return textArray
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        return unique(this.layer
            // Get features in extent
            .getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
            // Get distinct risk values
            .map(x => Risk[x.get('risk')]))
            // Sort
            .sort((a, b) => RiskSortOrder.get(b) - RiskSortOrder.get(a))
            // Map to key items
            .map(x => {
                return {
                    id: `${ this.name }-${ x }`,
                    label: `${ i18n.global.t('map.options.flood_risks.text_surface_water') } - ${ x }`,
                    style: {
                        fillColour: RiskColours[x],
                        strokeColour: 'transparent',
                    },
                }
            })
    }
}
