<template>
    <div :class="{
        'sub-types-container': isFlexContainer
    }">
        <div v-for="row in rows"
             :key="row.data.notificationSubType">
            <component :is="row.component"
                       :item="row.data"
                       @area-difference="handleBoundaryArea"
                       @count-of-updates="emit('count-of-updates', $event)" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        markRaw,
        PropType,
        ref,
        watch,
    } from "vue"

    import {IAssetMonitoringNotificationDetail} from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import BoundaryUpdatedItem from '@/components/asset-monitoring/grid/sub-type-items/boundary-updated.vue'
    import CompletedPendingApplicationItem from "@/components/asset-monitoring/grid/sub-type-items/completed-pending-application.vue"
    import Oc2DocumentUpdatedItem from '@/components/asset-monitoring/grid/sub-type-items/oc2-document-updated.vue'
    import OwnershipChangeItem from '@/components/asset-monitoring/grid/sub-type-items/ownership-change.vue'
    import {NotificationSubType} from "@/components/asset-monitoring/notification-sub-type.enum"

    const props = defineProps({
        notificationDetails: {
            type: Object as PropType<Array<IAssetMonitoringNotificationDetail>>,
            required: true,
        },
        titleNumber: {
            type: String,
            required: true,
        },
        notificationId: {
            type: String,
            required: true,
        },
    })

    const emit = defineEmits<{
        (e: 'area-difference', value: number),
        (e: 'count-of-updates', value: number),
    }>()

    const rows = ref([])
    const isFlexContainer = ref(false)

    const getNotificationSubTypeComponent = (notificationSubType: NotificationSubType) => {
        switch (notificationSubType) {
            case NotificationSubType.CompletedPendingApplication:
                return markRaw(CompletedPendingApplicationItem)

            case NotificationSubType.NewDocumentInOCDA:
                return markRaw(Oc2DocumentUpdatedItem)

            case NotificationSubType.OwnershipChange:
                return markRaw(OwnershipChangeItem)

            case NotificationSubType.BoundaryChange:
                isFlexContainer.value = true
                return markRaw(BoundaryUpdatedItem)
        }
    }

    watch(() => props.notificationDetails, () => {
        rows.value = props.notificationDetails?.map((item) => {
            let data = item
            data.titleNumber = props.titleNumber
            data.notificationId = props.notificationId
            return {
                component: getNotificationSubTypeComponent(item.notificationSubType),
                data: data,
            }
        })
    }, { immediate: true })

    const handleBoundaryArea = (area: number) => {
        emit('area-difference', area)
    }
</script>

<style scoped lang="scss">
    @import './sub-type-renderer.scss';
</style>
