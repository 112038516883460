import {
    ISearchResult,
    ISearchResultAddress,
    ISearchResultOwner,
    ISearchResultPlace,
    ISearchResultPostcode,
    ISearchResultTitleNumber,
} from '@/store/modules/search/types/search-result-interface'
import { readGeoFeature } from '@/utils/map-utils'

export const searchResultHandler = (searchResults: ISearchResult) => {
    if (!searchResults || !Array.isArray(searchResults)) {
        return []
    }

    let items = []
    searchResults.forEach((result, index) => {
        const data = result?.data ? result.data : []

        // The first item in the array is always companies
        // as there's no way to identify a company search.
        // This order is set in api/search.api.js
        if (index === 0 && result.length > 0) {
            items = items.concat(handleCompanies(result))
        }

        if (data.titleNumbers) {
            items = items.concat(handleTitleNumbers(data.titleNumbers))
        }

        if (data.places) {
            items = items.concat(handlePlaces(data.places))
        }

        if (data.addresses) {
            items = items.concat(handleAddresses(data.addresses))
        }

        if (data.postcodes) {
            items = items.concat(handlePostcodes(data.postcodes))
        }
    })

    return items.sort((a, b) => (a.sort - b.sort))
}

const handleTitleNumbers = (titles: ISearchResultTitleNumber[]) => {
    return titles.map((item, index) => {
        let tenure
        if (item.tenure && item.tenure.toUpperCase().includes('FREEHOLD')) {
            tenure = 'Freehold title'
        } else if (item.tenure === 'No tenure information found') {
            tenure = 'Tenure N/A'
        } else {
            if (item.tenure && item.tenure.toUpperCase().includes('LEASEHOLD')) {
                tenure = 'Leasehold title'
            } else {
                tenure = 'Other'
            }
        }

        return {
            sort: 100 + index,
            value: item.number,
            type: 'titlenumber',
            text: `${ item.number } (${ tenure })`,
            bbox: item.bbox ? readGeoFeature(item.bbox) : null,
            point: null,
            number: item.number,
            tenure,
        }
    })
}

const handlePlaces = (places: ISearchResultPlace[]) => {
    return places.map((item, index) => {
        return {
            sort: 300 + index,
            value: item.name,
            type: 'place',
            text: item.name + ' (' + item.type + ')',
            bbox: readGeoFeature(item.bbox),
            point: null,
            name: item.name,
            typeOfPlace: item.type,
        }
    })
}

const handleAddresses = (addresses: ISearchResultAddress[]) => {
    return addresses.map((item, index) => {
        return {
            sort: 1 + index,
            value: item.name,
            type: 'address',
            text: item.name,
            bbox: null,
            coordinates: item.coordinates,
            uprn: item.uprn,
        }
    })
}

const handlePostcodes = (postcodes: ISearchResultPostcode[]) => {
    return postcodes.map((item, index) => {
        return {
            sort: 400 + index,
            value: item.name,
            type: 'postcode',
            text: item.name,
            bbox: null,
            coordinates: item.coordinates,
        }
    })
}

const handleCompanies = (companies: ISearchResultOwner[]) => {
    return companies.map((item, index) => ({
        sort: 500 + index,
        value: item.proprietorName,
        type: 'company',
        text: item.proprietorName,
        bbox: null,
        companyRegistrationNumbers: item.companyRegistrationNumbers,
        titleCount: item.titleData.length,
        titleData: item.titleData,
        titleNumbers: item.titleData.map((title) => title.titleNumber),
    }))
}
