<template>
    <div ref="gridRef">
        <ow-data-grid :headers="headers"
                      :height="gridHeight"
                      :items="documents"
                      :no-data-text="$t('documents.export.table.noData')"
                      :sort-by="sortBy"
                      class="exported-documents-table"
                      has-pagination
                      hover
                      item-key="documentId"
                      @items-selected="onItemSelection">
            <template #[`item.fileName`]="{ item }">
                <td :title="item.filename"
                    class="body-regular exported-documents-table__filename">
                    <v-icon>$exported-file</v-icon>
                    <span class="exported-documents-table__filename--text">{{ item.filename }}</span>
                </td>
            </template>
            <template #[`item.documentDateSortable`]="{ item }">
                <td class="caption-regular exported-documents-table__item">
                    {{ (item.documentDate) }}
                </td>
            </template>
            <template #[`item.actions`]="{ item }">
                <td class="caption-regular exported-documents-table__item exported-documents-table__actions text-no-wrap">
                    <span>
                        <v-btn class="document-order-view-link__button-download title-lr-button"
                               color="primary"
                               data-test="document-order-view-link-download"
                               data-track="DOCS - Download document"
                               icon="$download"
                               variant="text"
                               @click="downloadDocument(item.documentId)" />

                        <remove-document-button :document="item" />
                    </span>
                </td>
            </template>
        </ow-data-grid>
    </div>
</template>
<script lang="ts">
    import { ref } from 'vue'

    import DocumentsApi from '@/api/documents.api'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import RemoveDocumentButton from '@/components/documents/remove-document-button.vue'
    import useWindowResize from '@/composables/use-window-resize'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin.js'

    const EVENTS = {
        downloadDoc: 'download',
        selected: 'selected-documents-changed',
    }

    export default {
        name: 'ExportedDocumentsTable',

        components: {
            OwDataGrid,
            RemoveDocumentButton,
        },

        mixins: [ FlagsMixin ],

        props: {
            documents: {
                type: Array,
                required: true,
            },
        },

        emits: Object.values(EVENTS),

        setup() {
            const gridHeight = ref('0')
            const gridRef = ref(null)
            useWindowResize({
                onResize({ clientHeight }) {
                    const copilotHeight = document.querySelector('.cta-copilot')?.clientHeight ?? 80
                    gridHeight.value = ((clientHeight - gridRef.value.getBoundingClientRect().top) - copilotHeight - 60).toString()
                },
            })
            return {
                gridHeight,
                gridRef,
            }
        },

        data() {
            return {
                selected: [],
                sortBy: ref([ { key: 'fileName', order: 'asc' } ]),
            }
        },

        computed: {
            headers() {
                return [
                    {
                        title: this.$t('documents.export.table.headings.document'),
                        align: 'left',
                        key: 'filename',
                    },
                    {
                        title: this.$t('documents.export.table.headings.date'),
                        align: 'left',
                        key: 'documentDateSortable',
                    },
                    {
                        title: this.$t('documents.export.table.headings.actions'),
                        align: 'center',
                        key: 'actions',
                        sortable: false,
                    },
                ]
            },
        },

        watch: {
            selected(val) {
                this.$emit('selected-documents-changed', val)
            },
        },

        methods: {
            async downloadDocument(documentId) {
                try {
                    await DocumentsApi.downloadDocumentByTypeAndId('Spreadsheet', documentId)
                } catch (error) {
                    await this.showPopup({
                        title: 'Error',
                        contentHTML: 'An error was encountered when downloading the document',
                    })
                    console.error(error)
                }
            },

            onItemSelection(items) {
                this.selected = this.documents.filter(({ documentId }) => items.includes(documentId))
            },

        },

    }
</script>

<style lang="scss">
    @import './exported-documents-table';
</style>
