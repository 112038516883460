<template>
    <div class="title-organiser-item__container"
         @mouseenter="onMouseEnter"
         @mouseleave="onMouseLeave">
        <div :id="`title-organiser-item__${service.id}`"
             class="title-organiser-item"
             data-test="title-organiser-item"
             :data-track="'TITLE ORGANISER - Group by ' + serviceName"
             :class="{'title-organiser-item--slotted': $slots.default && selectedField && serviceColumns?.length, 'title-organiser-item--selected': selected}"

             @click="onClick">
            <v-radio class="flex-shrink-1"
                     :inline="true"
                     data-test="title-organiser-item-radio"
                     :disabled="serviceColumns?.length && !selectedField"
                     :value="serviceId" />
            <div class="d-flex flex-grow-1 flex-column">
                <div class="title-organiser-item__name caption-highlight">
                    {{ serviceName }}
                </div>
                <div class="title-organiser-item__description accents-small">
                    {{ serviceDescription }}
                </div>
                <div v-if="$slots.default"
                     class="title-organiser-item__slot accents-highlight">
                    <slot />
                </div>
            </div>
            <v-progress-circular v-if="service.isLoading"
                                 data-test="title-organiser-item-loading"
                                 indeterminate
                                 :width="2"
                                 :size="18"
                                 color="primary" />
            <v-icon v-else-if="serviceColumns?.length"
                    class="title-organiser-item__arrow">
                $chevron-right
            </v-icon>
        </div>
        <transition name="fade">
            <title-organiser-options-panel v-if="serviceColumns?.length && menuOpen"
                                           :columns="serviceColumns ?? []"
                                           :groups="serviceGroups ?? []"
                                           :anchor-element-selector="'.title-organiser-panel__items'"
                                           :anchor-offset="[-166,0,0,0]"
                                           @select-field="onSelectField" />
        </transition>
    </div>
</template>

<script lang="ts">
    import { debounce } from 'lodash'
    import { PropType } from 'vue'

    import TitleOrganiserOptionsPanel from '@/components/title-organiser/title-organiser-options-panel.vue'
    import { ITitleOrganiserService } from '@/store/modules/matter/title-organiser/title-organiser-service.interface'

    export default {
        name: 'TitleOrganiserItem',
        components: { TitleOrganiserOptionsPanel },
        props: {
            service: {
                type: Object as PropType<ITitleOrganiserService>,
                required: true,
            },
            selectedField: {
                type: String,
                default: null,
            },
            selected: {
                type: Boolean,
                default: false,
                required: true,
            },
        },
        emits: ['select', 'select-field'],
        data() {
            return {
                menuOpen: false,
                menuTimeout: null,
            }
        },
        computed: {
            serviceName():string {
                const value = this.service.i18nReference
                const ref = `${ value }.name`
                return this.$t(ref)
            },

            serviceId():string {
                return this.service.id
            },

            serviceDescription():string {
                const value = this.service.i18nReference
                const ref = `${ value }.description`
                return this.$t(ref)
            },

            serviceColumns(): any[] {
                return this.service?.options?.columns
            },

            serviceGroups(): any[] {
                return this.service?.options?.groups
            },
            debouncedOnMouseMove() {
                return debounce(this.onMouseMove, 500)
            },
        },

        mounted() {
            document.addEventListener('mousemove', this.debouncedOnMouseMove)
        },

        beforeUnmount() {
            document.removeEventListener('mousemove', this.debouncedOnMouseMove)
        },

        methods: {
            onClick() {
                if (this.service?.options?.columns && !this.selectedField) {
                    return
                }
                this.$emit('select', this.service)
            },

            onSelectField(field: string) {
                this.menuOpen = false
                this.$emit('select-field', { field, service: this.service })
            },

            onMouseEnter() {
                if (this.service?.options?.columns) {
                    this.showMenuWithDelay()
                }
            },

            onMouseLeave(e) {
                this.hideMenuWithDelay(e)
            },

            showMenuWithDelay() {
                clearTimeout(this.menuTimeout)
                setTimeout(() => {
                    this.menuOpen = true
                }, 200)
            },

            hideMenuWithDelay(e, delay = 1000) {
                clearTimeout(this.menuTimeout)
                this.menuTimeout = setTimeout(() => {
                    if (e?.relatedTarget?.className.includes('app-dropdown-menu-item')) {
                        return
                    }
                    this.menuOpen = false
                }, delay)
            },

            onMouseMove(e) {
                clearTimeout(this.menuTimeout)
                if (!e.target.className) {
                    this.hideMenuWithDelay(e)
                }
            },
        },
    }
</script>
<style lang="scss">
    @import './title-organiser-item.scss';
</style>
