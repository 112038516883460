import {
    CHECKOUT_MUTATE_ADD_DOWNLOADED_REPORT,
    CHECKOUT_MUTATE_BASIC_MATTER_CODES,
    CHECKOUT_MUTATE_CART_ITEMS_ADD,
    CHECKOUT_MUTATE_CART_ITEMS_EMPTY,
    CHECKOUT_MUTATE_ENTRY_POINT,
    CHECKOUT_MUTATE_ORDER_CONFIRMED,
} from './types'
import { IBasicMatter } from '@/interfaces/basic-matter.interface'
import { ICheckoutItemBase } from '@/interfaces/checkout/checkout-item-base.interface'
import { ICheckoutState } from '@/interfaces/store/checkout/checkout-state.interface'
import { IReportDownloadItem } from '@/interfaces/checkout/report-download-item.interface'

export default {
    [CHECKOUT_MUTATE_CART_ITEMS_EMPTY](state: ICheckoutState): void {
        state.items = []
        state.downloadedReports = []
        state.entryPoint = null
        state.orderConfirmed = false
        state.basicMatterCodes = {}
    },

    [CHECKOUT_MUTATE_CART_ITEMS_ADD](state: ICheckoutState, item: ICheckoutItemBase): void {
        state.items.push(item)
    },

    [CHECKOUT_MUTATE_ORDER_CONFIRMED](state: ICheckoutState, confirmed: boolean): void {
        state.orderConfirmed = confirmed
    },

    [CHECKOUT_MUTATE_ENTRY_POINT](state: ICheckoutState, entryPoint: string): void {
        state.entryPoint = entryPoint
    },

    [CHECKOUT_MUTATE_BASIC_MATTER_CODES](state: ICheckoutState, basicMatterCodes: IBasicMatter): void {
        state.basicMatterCodes = basicMatterCodes
    },

    [CHECKOUT_MUTATE_ADD_DOWNLOADED_REPORT](state: ICheckoutState, reportToAdd: IReportDownloadItem): void {
        state.downloadedReports.push(reportToAdd)
    },
}
