<template>
    <ow-modal v-model="show"
              persistent
              title="Product Configuration"
              content-class="matter-searches-con29-product-config-dialog">
        <div class="matter-searches-con29-product-config-dialog__content">
            <v-fade-transition>
                <matter-searches-product-config-error v-if="!valid && additionalEnquiriesConfig?.some((enq) => enq.value)"
                                                      :basket-item="selectedBasketItem" />
            </v-fade-transition>
            <matter-searches-product-config-header v-model="standardEnquiriesChecked"
                                                   data-test-id="con29-product-config-standard-enquiries-header"
                                                   :title="$t('searches.productConfig.con29.standardEnquiries.title')">
                <matter-searches-con29-product-config-road-search :road-config="roadConfig"
                                                                  data-test-id="road-search" />
            </matter-searches-product-config-header>
            <v-divider class="my-2 mt-4" />
            <matter-searches-product-config-header v-model="additionalEnquiriesChecked"
                                                   data-test-id="con29-product-config-additional-enquiries-header"
                                                   :title="$t('searches.productConfig.con29.additionalEnquiries.title')">
                <div class="matter-searches-con29-product-config-dialog__enq-grid px-5 gc-1 gr-1">
                    <ow-checkbox v-for="(item, index) in additionalEnquiriesConfig"
                                 :id="`additional-enquiries-checkbox-${item.name}`"
                                 :key="index"
                                 v-model="item.value"
                                 :label="$t(`searches.productConfig.con29.additionalEnquiries.indicators.${item.name}`)"
                                 hide-details />
                </div>
                <v-divider class="mt-4 mx-5" />
                <matter-searches-con29-product-config-additional-questions v-model:checked="additionalQuestionsChecked"
                                                                           :additional-question-config="additionalQuestionConfig" />
            </matter-searches-product-config-header>
        </div>
        <template #actions>
            <ow-card-actions has-secondary-button
                             no-padding
                             primary-button-data-test="con29-product-config-confirm"
                             secondary-button-data-test="con29-product-config-cancel"
                             :primary-button-text="$t('action.save')"
                             :primary-button-disabled="!dirty || !valid"
                             @primary-button-click="handleConfirm"
                             @secondary-button-click="handleCancel" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        computed,
        nextTick,
        ref,
        toRaw,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import MatterSearchesCon29ProductConfigAdditionalQuestions from '@/components/matter-searches/matter-searches-con29-product-config-additional-questions.vue'
    import MatterSearchesCon29ProductConfigRoadSearch from '@/components/matter-searches/matter-searches-con29-product-config-road-search.vue'
    import MatterSearchesProductConfigError from '@/components/matter-searches/matter-searches-product-config-error.vue'
    import MatterSearchesProductConfigHeader from '@/components/matter-searches/matter-searches-product-config-header.vue'
    import {
        Con29AdditionalEnquiryItems,
        Con29ProductConfig,
        RoadConfig,
    } from '@/components/matter-searches/models/BasketItemTMGCon29Model'
    import { SEARCHES_GET_SELECTED_BASKET_ITEM,
             SEARCHES_GET_TMG_CON29_CONFIG,
             SEARCHES_GET_TMG_CON29_CONFIG_SHOW,
             SEARCHES_MUTATE_BASKET_DIRTY,
             SEARCHES_MUTATE_PRODUCT_CONFIG,
             SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW,
             SEARCHES_QUOTE } from '@/store/modules/searches/types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    import BasketItemModel from './models/BasketItemModel'


    const store = useStore()

    const selectedBasketItem = computed<BasketItemModel>(() => store.getters[SEARCHES_GET_SELECTED_BASKET_ITEM])

    const show = computed<boolean>({
        get: () => store.getters[SEARCHES_GET_TMG_CON29_CONFIG_SHOW],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW, {
                model: selectedBasketItem.value,
                show: val,
            })
        },
    })

    const config = computed<Con29ProductConfig>({
        get: () => store.getters[SEARCHES_GET_TMG_CON29_CONFIG],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG, {
                model: selectedBasketItem.value,
                config: val,
            })
        },
    })

    const erroredItems = computed<{
        errorCode: string
        errorMessage: string
        propertyName: string
    }[]>(() => selectedBasketItem.value?.errorItems ?? [])

    const roadConfig = ref<RoadConfig[]>(config?.value?.roadConfig)
    const additionalEnquiriesConfig = ref<{
        value: boolean
        name: string
    }[]>(Con29AdditionalEnquiryItems.map((item) => ({
        name: item,
        value: false,
    })))

    const additionalQuestionConfig = ref<{
        value: string
        name: string
    }[]>([])
    let defaultConfig: Con29ProductConfig = null
    const dirty = ref(false)
    const valid = ref(false)

    const checkShowStandardEnquiries = () => {
        const hasRoadData = roadConfig.value?.length && (roadConfig.value.length > 0 && !!roadConfig.value[0]?.name && !!roadConfig.value[0]?.usrn)
        return hasRoadData || config?.value.standardEnquiries
    }

    const checkShowAdditionalEnquiries = () => {
        if (!additionalEnquiriesConfig.value?.length) {
            return false
        }
        const hasChecked = additionalEnquiriesConfig.value.some((enq) => enq.value)
        return hasChecked || config?.value.additionalEnquiries
    }

    const standardEnquiriesChecked = ref<boolean>()
    const additionalEnquiriesChecked = ref<boolean>()
    const additionalQuestionsChecked = ref<boolean>()
    const initialising = ref(true)

    const handleCancel = () => {
        show.value = false
    }

    const validateConfig = () => {
        // check all roads have a name and usrn, except the last road
        const roadsValid = roadConfig?.value?.slice(0, -1).every((road) => !!road.name) ?? true
        const atleastOneSection = standardEnquiriesChecked.value || additionalEnquiriesChecked.value

        // check if at least one additional setting is checked
        let atleastOneAdditionalSetting = true
        if (additionalEnquiriesChecked.value) {
            const someAdditionalEnquiriesChecked = additionalEnquiriesConfig.value.some((enq) => enq.value)
            const someAdditionalQuestionsChecked = additionalQuestionsChecked.value && additionalQuestionConfig.value.length && additionalQuestionConfig.value?.[0].value !== ''
            atleastOneAdditionalSetting = someAdditionalEnquiriesChecked || someAdditionalQuestionsChecked
        }

        // check if all additional enquiries are valid
        const validAdditionalEnquiries = !additionalEnquiriesConfig.value.some((enq) => enq.value && erroredItems.value.some((error) => error.propertyName === enq.name))

        // set the valid flag
        valid.value = roadsValid && atleastOneSection && validAdditionalEnquiries && atleastOneAdditionalSetting
    }

    const handleConfirm = () => {
        if (!valid.value) {
            return
        }

        // emit the roads array
        config.value = {
            additionalEnquiries: additionalEnquiriesChecked.value,
            standardEnquiries: standardEnquiriesChecked.value,
            roadConfig: standardEnquiriesChecked.value ? roadConfig.value.filter((road) => !!road.name) : [],
            additionalEnquiriesConfig: additionalEnquiriesChecked.value ? additionalEnquiriesConfig.value.filter((enq) => enq.value).map((enq) => enq.name) : [],
            additionalQuestionsConfig: additionalEnquiriesChecked.value && additionalQuestionsChecked.value ? additionalQuestionConfig.value.filter(question => question.value).map((question) => question.value) : [],
        }
        show.value = false
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, true)
        store.dispatch(SEARCHES_QUOTE)
    }

    const reset = () => {
        standardEnquiriesChecked.value = false
        additionalEnquiriesChecked.value = false
        additionalQuestionsChecked.value = false
        roadConfig.value = []
        additionalEnquiriesConfig.value = Con29AdditionalEnquiryItems.map((item) => ({
            name: item,
            value: false,
        }))
        additionalQuestionConfig.value = []
    }

    watch(() => roadConfig.value, (val) => {
        if (!val?.length || initialising.value) {
            return
        }
        // check if the roads array has changed
        if (!dirty.value) {
            const comparedRoadConfig = val.filter((road) => !!road.name)
            const defaultRoadConfig = defaultConfig?.roadConfig?.filter((road) => !!road.name)
            const equal = JSON.stringify(toRaw(comparedRoadConfig)) === JSON.stringify(defaultRoadConfig)
            dirty.value = !equal
        }
        validateConfig()
    }, {
        deep: true,
    })

    watch(() => additionalQuestionConfig.value, (val) => {
        if (!val?.length || initialising.value) {
            return
        }
        // check if the questions array has changed
        if (!dirty.value) {
            const compared = val.filter(question => question.value).map((question) => question.value)
            const config = defaultConfig?.additionalQuestionsConfig
            const equal = JSON.stringify(toRaw(compared)) === JSON.stringify(config)
            dirty.value = !equal
        }
        validateConfig()
    }, {
        deep: true,
    })

    watch(() => additionalEnquiriesConfig.value, (val) => {
        if (!val?.length || initialising.value) {
            return
        }
        // check if the roads array has changed
        if (!dirty.value) {
            const comparison = val.filter(enq => enq.value).map((enq) => enq.name)
            const additionEnqConfig = defaultConfig?.additionalEnquiriesConfig
            const equal = JSON.stringify(toRaw(comparison)) === JSON.stringify(additionEnqConfig)
            dirty.value = !equal
        }
        validateConfig()
    }, {
        deep: true,
    })

    watch(() => standardEnquiriesChecked.value, (val) => {
        if (initialising.value) {
            return
        }
        // check if the standard enquiries checkbox has changed
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.standardEnquiries
        }
        validateConfig()
    })

    watch(() => additionalEnquiriesChecked.value, (val) => {
        if (initialising.value) {
            return
        }
        // check if the additional enquiries checkbox has changed
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.additionalEnquiries
        }
        validateConfig()
    })

    watch(() => additionalQuestionsChecked.value, (val) => {
        if (initialising.value) {
            return
        }
        validateConfig()
    })

    watch(() => show.value, (val) => {
        if (!val) {
            return reset()
        }

        if (!isNullOrEmpty(config?.value.additionalEnquiriesConfig)) {
            config?.value.additionalEnquiriesConfig.forEach((enq) => {
                const index = additionalEnquiriesConfig.value.findIndex((item) => item.name === enq)
                if (index > -1) {
                    additionalEnquiriesConfig.value[index].value = true
                }
            })
        }

        if (!isNullOrEmpty(config?.value.additionalQuestionsConfig)) {
            additionalQuestionConfig.value = config?.value.additionalQuestionsConfig.map((question, index) => {
                return {
                    value: question,
                    name: index.toString(),
                }
            })
        }

        // update the roads array when the config prop changes
        standardEnquiriesChecked.value = checkShowStandardEnquiries()
        additionalEnquiriesChecked.value = checkShowAdditionalEnquiries()
        roadConfig.value = config?.value.roadConfig ?? []

        // set the default config
        const roadConfigValue = roadConfig?.value ?? []
        const additionalEnquiriesConfigValue = additionalEnquiriesConfig?.value ?? [].filter(enq => enq.value).map((enq) => enq.name)
        const additionalEnquiriesValue = additionalEnquiriesChecked.value ?? false
        const standardEnquiriesValue = standardEnquiriesChecked.value ?? false
        const additionalQuestionsConfigValue = additionalQuestionConfig?.value ?? [].map((question) => question.value)

        defaultConfig = JSON.parse(JSON.stringify({
            roadConfig: toRaw(roadConfigValue),
            additionalEnquiriesConfig: toRaw(additionalEnquiriesConfigValue),
            additionalEnquiries: toRaw(additionalEnquiriesValue),
            standardEnquiries: toRaw(standardEnquiriesValue),
            additionalQuestionsConfig: toRaw(additionalQuestionsConfigValue),
        }))
        dirty.value = false

        nextTick(() => {
            initialising.value = false
        })

    }, {
        immediate: true,
    })
</script>

<style lang="scss">
@import './matter-searches-con29-product-config-dialog.scss';
</style>
