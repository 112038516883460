<template>
    <button class="style-option"

            :class="{ 'is-selected': isSelected, 'is-disabled': disabled, [`sketch-type-${sketchTypeName.toLowerCase()}`]: sketchType, [`stroke-${getStrokeWidth}`]: getStrokeWidth}"
            @click="emit('click')">
        <div v-if="sketchType === SketchType.Line && isStylePreset"
             class="style-option__line">
            <svg width="30"
                 height="30">
                <defs>
                    <marker :id="`arrow-${owStyle.strokeColour}`"
                            viewBox="0 0 10 10"
                            refX="5"
                            refY="5"
                            markerWidth="6"
                            markerHeight="6"
                            :fill="owStyle.strokeColour"
                            orient="auto-start-reverse">
                        <path d="M 0 0 L 10 5 L 0 10 z" />
                    </marker>
                </defs>
                <line x1="0"
                      y1="30"
                      x2="30"
                      y2="0"
                      :stroke="owStyle.strokeColour"
                      :stroke-width="(owStyle.strokeWidth / 2) + 0.7"
                      :stroke-dasharray="owStyle.dash ? [4,4] : 0"
                      :fill="owStyle.strokeColour"
                      :marker-end="arrowHeadsEnabled && (owStyle.arrowhead === Arrowhead.End || owStyle.arrowhead === Arrowhead.Both) ? `url(#${(`arrow-${owStyle.strokeColour}`)})` : null"
                      :marker-start="arrowHeadsEnabled && (owStyle.arrowhead === Arrowhead.Both) ? `url(#${(`arrow-${owStyle.strokeColour}`)})` : null" />
            </svg>
        </div>
        <div v-else-if="sketchType === SketchType.Marker && isStylePreset"
             v-dompurify-html:svg="getSketchMarkerPinSVG(owStyle.strokeColour, 1, false)"
             class="style-option__button style-option__marker" />
        <div v-else-if="sketchType === SketchType.Text && isStylePreset"
             class="style-option__button style-option__text"
             :style="!isTextSize && isTextBlockPreset ? computedStyle : null">
            <p v-if="isTextSize">
                {{ textSizeText }}
            </p>
            <p v-else-if="isTextBlockPreset">
                {{ textStyleText }}
            </p>
            <svg v-else-if="isTextStrokePreset"
                 viewBox="0 0 60 60">
                <text :style="{'stroke': owStyle.strokeColour, 'fill': owStyle.fillColour, paintOrder: 'stroke' }"
                      dominant-baseline="middle"
                      text-anchor="middle"
                      x="50%"
                      y="50%">{{ textStyleText }}</text>
            </svg>
        </div>
        <div v-else
             class="style-option__button"
             :style="computedStyle">
            <slot />
        </div>
        <div v-if="withChevron">
            <v-icon v-if="!isExpanded">
                $chevron-down
            </v-icon>
            <v-icon v-if="isExpanded">
                $chevron-up
            </v-icon>
        </div>
    </button>
</template>

<script lang="ts" setup>

    import {
        computed,
        useSlots,
    } from 'vue'
    import { useStore } from "vuex"

    import {
        Arrowhead,
        SketchType,
    } from '@/enums/sketches-enums'
    import { IOwStyle } from '@/store/modules/sketches/types/style'
    import { getSketchMarkerPinSVG } from '@/utils/sketch-utils'
    import { getCSSStyleForOWStyleDefinition } from '@/utils/style-utils'

    const store = useStore()

    interface Props {
        owStyle: Partial<IOwStyle>
        strokeWidth?: number
        sketchType?: SketchType
        isSelected?: boolean
        withChevron?: boolean
        isExpanded?: boolean
        isStylePreset?: boolean
        disabled?: boolean
    }
    const props = withDefaults(defineProps<Props>(), {
        isSelected: false,
        withChevron: false,
        isExpanded: false,
        isStylePreset: false,
        sketchType: null,
        strokeWidth: null,
        disabled: false,
    })
    const slots = useSlots()
    const emit = defineEmits<{
        (e: 'click'): void
    }>()

    const computedStyle = computed(() => {
        if (slots.default) {
            return null
        }
        return getCSSStyleForOWStyleDefinition(props.owStyle, props.sketchType)
    })

    const sketchTypeName = computed(() => {
        return SketchType[props?.sketchType] ?? ''
    })

    const getStrokeWidth = computed(() => {
        return props?.strokeWidth ?? props.owStyle?.strokeWidth
    })

    const isTextSize = computed(() => {
        return props?.strokeWidth
    })

    const textStyleText = computed(() => {
        return 'Ab'
    })

    const textSizeText = computed(() => {
        return 'A'
    })

    const isTextBlockPreset = computed(() => {
        return props.owStyle.fillColour && props.owStyle.fillOpacity !== 0
    })

    const isTextStrokePreset = computed(() => {
        return props.owStyle.fillColour && props.owStyle.fillOpacity === 0
    })

    const arrowHeadsEnabled = computed(() => store.state.config.featureFlags?.sketchesLineArrowsEnabled)

</script>

<style lang="scss">
@import 'style-option.scss';
</style>
