import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import { IDocumentOrderingState } from '@/interfaces/store/document-ordering/document-ordering-state.interface'

const state: IDocumentOrderingState = {
    currentOrders: [],
    successfulOrders: [],
    errorOrders: [],
    isProcessingOrders: false,
    error: null,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
