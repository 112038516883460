import { unique } from '@/utils/array-utils'

import {
    MUTATE_ADD_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS,
    MUTATE_REMOVE_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS,
    MUTATE_SET_TITLE_COMPLEXITY,
} from './types.js'

export default {
    [MUTATE_ADD_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS](state, titleNumbers) {
        state.loadingTitleComplexityForTitleNumbers = unique(state.loadingTitleComplexityForTitleNumbers.concat(titleNumbers))
    },

    [MUTATE_REMOVE_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS](state, titleNumbers) {
        state.loadingTitleComplexityForTitleNumbers = state.loadingTitleComplexityForTitleNumbers.filter(function(item) {
            return titleNumbers.indexOf(item) === -1
        })
    },

    [MUTATE_SET_TITLE_COMPLEXITY](state, titleComplexity) {
        const existingIndex = state.titleComplexityResults.findIndex(r => r.titleNumber === titleComplexity.titleNumber)
        if (existingIndex) {
            state.titleComplexityResults.splice(existingIndex, 1, titleComplexity)
        } else {
            state.titleComplexityResults.push(titleComplexity)
        }
    },
}
