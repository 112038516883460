<template>
    <div class="map-key-config">
        <draggable v-model="config.items"
                   item-key="id"
                   handle=".drag-handle">
            <template #item="{ element, index }">
                <map-key-config-item :config-item="element"
                                     class="draggable-item"
                                     @remove="removeItem(index)" />
            </template>
        </draggable>
        <div v-if="showActions"
             class="key-actions">
            <ow-button is-secondary
                       data-track="snapshots-map-key-add-colours"
                       data-test="snapshots-map-key-add-colours"
                       @click="addColoursFromMap">
                {{ t('snapshots.map.key.addColoursFromMap') }}
            </ow-button>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import draggable from 'vuedraggable'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import {
        KeyConfigItemModel,
        KeyConfigModel,
    } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
    import MapKeyConfigItem from '@/components/snapshots/map-snapshots/config-components/map-key-config-item.vue'
    import { owStyleDefinitionsAreEqual } from '@/utils/style-utils'

    interface Props {
        config: KeyConfigModel,
        suggestedItems: Array<KeyConfigItemModel>,
        showActions?: boolean,
    }

    const props = defineProps<Props>()
    const emit = defineEmits<{
        (e: 'item-removed', payload: KeyConfigItemModel): void,
    }>()
    const { t } = useI18n()
    const addColoursFromMap = () => {
        const usedStyles = props.config.items.map(item => item.style)
        props.suggestedItems
            .filter(item => !usedStyles.some(usedStyle => owStyleDefinitionsAreEqual(usedStyle, item.style)))
            .forEach(item => {
                props.config.items.push({
                    label: item.label,
                    style: item.style,
                })
            })
    }

    const removeItem = (index: number) => {
        const item = props.config.items[index]
        props.config.items.splice(index, 1)
        emit('item-removed', item)
    }
</script>

<style scoped lang="scss">
@import './map-key-config.scss';
</style>
