import {
    Model,
    TemplateColumnConfig,
} from '@bryntum/grid'
import ProductItemModel from '../models/ProductItemModel'
import { useI18n } from 'vue-i18n'
import { useActionColumn } from '@/components/core/ow-grid/columns/use-action-column'
import BasketItemModel from '../models/BasketItemModel'
import { CostColumn,
    ExpectedDateColumn,
    HazardsColumn } from '.'

export const useColumns = ({
    on,
}: {
    on: (event: string, column: string, record: BasketItemModel) => void
}) => {
    const { t } = useI18n()
    const ProductColumn = ({
        field: 'description',
        text: t('searches.columns.name') as string,
        cls: 'column-name',
        flex: 1,
        type: 'template',
        autoHeight: true,
        template: ({ record }: { record: Model }) => {
            const productItem = record as ProductItemModel
            const productDetail = `
            <div class="d-flex flex-row py-1">
                <span class="product-icon mdi mdi-information-outline mr-2"></span>
                <div class="product-column__container">
                        <span class="body-highlight product-column" title="${ productItem.description }">${ productItem.description }</span>
                        <div class="caption-regular" title="${ productItem.productSupplierType }">${ productItem.productSupplierType }</div>
                    </div>
                </div>
                `

            return `
                <div class='d-flex flex-column product-column__container'>
                    ${ productDetail ? `<div>${ productDetail }</div>` : '' }
                </div>
            `
        },
    }) as Partial<TemplateColumnConfig>


    const ActionColumn = useActionColumn({
        width: 175,
        onEvent: on,
        actions: [{
            id: 'add',
            visible: () => true,
            style: 'icon',
            className: 'add-button mdi mdi-plus-circle',
        }],
    })

    return {
        gridColumns: [
            ProductColumn,
            HazardsColumn,
            ExpectedDateColumn,
            CostColumn,
            ActionColumn,
        ],
    }
}
