import { computed } from 'vue'

/**
 * This is a wrapper for the v-model directive.
 * @param props
 * @param emit
 * @param name - default to `modelValue` which is the default name for v-model
 * @example
 *  import { useModelWrapper } from '@/hooks/useModelWrapper'
 *  ...
 *  isVisible: useModelWrapper(props, emit)
 *  ...
 *  <element v-model="isVisible"/>
 */
export function useModelWrapper(props, emit, name = 'modelValue') {
    return computed({
        get: () => {
            return props[name]
        },
        set: (value) => {
            emit(`update:${ name }`, value)
        },
    })
}
