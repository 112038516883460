import WMTSTileGrid from 'ol/tilegrid/WMTS'

import { CoordinateSystemCode } from '@/enums/coordinate-systems'

/** Returns the tile grid used by layers held in GeoServer in the EPSG:27700 projection */
export const geoserver27700TileGrid = new WMTSTileGrid({
    projection: CoordinateSystemCode.EPSG27700,
    tileSize: [512, 512],
    extent: [
        -238375.0000149319,
        0,
        900000.00000057,
        1376256.0000176653,
    ],
    maxZoom: 20,
    minZoom: 0,
    resolutions: [
        895.9999998588976,
        447.9999999294488,
        223.9999999647244,
        111.9999999823622,
        55.9999999911811,
        27.99999999559055,
        13.999999997795275,
        6.999999998897637,
        3.4999999994488187,
        1.7499999997244093,
        0.8749999998622047,
        0.4374999999311023,
        0.2187499999655512,
        0.1093749999827756,
        0.0546874999913878,
        0.0273437499956939,
        0.0136718749978469,
        0.0068359374989235,
        0.0034179687494617,
        0.0017089843747309,
        0.0008544921873654,
    ],
    sizes: [
        [3, 3],
        [5, 6],
        [10, 12],
        [20, 24],
        [40, 48],
        [80, 96],
        [159, 192],
        [318, 384],
        [635, 768],
        [1271, 1536],
        [2542, 3072],
        [5083, 6144],
        [10166, 12288],
        [20329, 24576],
        [40657, 49152],
        [81313, 98304],
        [162625, 196608],
        [325250, 393215],
        [650500, 786432],
        [1301000, 1572864],
        [2602000, 3145728],
    ],

})
