<template>
    <div class="px-5 py-3">
        <ow-checkbox id="additional-questions"
                     v-model="checkboxModel"
                     :label="$t('searches.productConfig.con29.additionalQuestions.title')"
                     hide-details />
        <div v-if="checkboxModel"
             class="pa-3 pt-4">
            <div class="v-row pa-0">
                <div v-t="'searches.productConfig.con29.additionalQuestions.question'"
                     class="caption-regular v-col pa-0" />
            </div>
            <div v-for="(question, index) in additionalQuestionConfig"
                 :key="index"
                 class="v-row pa-0 gc-2 pb-2"
                 :data-test="`Question-${index}`">
                <ow-textfield v-model="question.value"
                              class="v-col pa-0"
                              :data-test="`Question-${index}-text-field`"
                              :placeholder="$t('searches.productConfig.con29.additionalQuestions.placeholder', {
                                  index: index + 1,
                              })"
                              @blur="checkForEmptyQuestions" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted,
             watchEffect } from 'vue'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import owTextfield from '@/components/core/ow-textfield.vue'

    const checkboxModel = defineModel<boolean>('checked')

    const props = defineProps<{
        additionalQuestionConfig: {
            value: string
            name: string
        }[]
    }>()

    onMounted(() => {
        const additionalQuestionData = props.additionalQuestionConfig
        // check if the additional questions checkbox should be checked
        checkboxModel.value = additionalQuestionData.length > 0 && !!additionalQuestionData?.[0].value
    })

    // add a new question if the last question has been filled
    const addQuestion = () => {
        props.additionalQuestionConfig.push({
            value: '',
            name: `Question ${ props.additionalQuestionConfig.length + 1 }`,
        })
    }

    // remove the last question if the last two questions are empty
    const removeQuestion = (index: number) => {
        props.additionalQuestionConfig.splice(index, 1)
    }

    // check if the last question is empty and add a new question if it is
    // also remove the last question if the last two questions are empty
    const checkForEmptyQuestions = () => {
        const additionalQuestionData = props.additionalQuestionConfig
        const lastQuestion = additionalQuestionData[additionalQuestionData.length - 1]

        if (!additionalQuestionData.length || lastQuestion.value) {
            addQuestion()
        } else if (additionalQuestionData.length > 1 && !lastQuestion.value && !additionalQuestionData[additionalQuestionData.length - 2].value) {
            removeQuestion(additionalQuestionData.length - 1)
        }
    }

    watchEffect(() => {
        checkForEmptyQuestions()
    })


</script>
