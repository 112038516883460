<template>
    <ow-clipboard :text="content"
                  class="register-record-entry"
                  scrollable
                  @clicked="logClip(entryNumber)"
                  @scrolled="handleScroll">
        <ow-text-highlight :queries="searchQueries"
                           class="body-regular"
                           data-test="register-record-entry-text"
                           @match-count-changed="matchCount = $event">
            {{ content }}
        </ow-text-highlight>
    </ow-clipboard>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import { useCompromise } from '@/composables/use-compromise'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    const emit = defineEmits<{
        (e: 'match-count-changed', value: number),
        (e: 'scrolled'): void,
    }>()

    const props = defineProps({
        entryNumber: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            required: true,
        },

        searchFilter: {
            type: String,
            required: true,
        },

        highlightDates: {
            type: Boolean,
            required: false,
        },
    })

    const { compromise } = useCompromise()
    const store = useStore()

    const matchCount = ref<number>(0)
    watch(matchCount, (value) => {
        emit('match-count-changed', value)
    })

    const dateFilters = computed<string[]>(() => {
        const doc: any = compromise(props.content)
        const dateTerms: string[] = doc
            .dates()
            .json()
            .map(({ text }) => text)

        return dateTerms
    })

    const searchQueries = computed<string[]>(() => {
        if (props.highlightDates) {
            return [
                props.searchFilter,
                ...dateFilters.value,
            ]
        }
        return [ props.searchFilter ]
    })

    const logClip = async (entryNr: string): Promise<void> => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'REVIEW-ASSISTANT - Copy Register Entry',
            metadata: { content: entryNr },
        })
    }

    const handleScroll = () => {
        emit('scrolled')
    }
</script>

<style lang="scss">
    @import 'register-record-entry.scss';
</style>
