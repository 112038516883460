<template>
    <div class="ow-sidebar d-flex flex-column"
         :class="{
             'ow-sidebar--collapsed': collapsed,
             'ow-sidebar--hide-divider': hideDivider,
         }"
         :data-test="dataTestId">
        <ow-sidebar-header v-if="!hideHeader"
                           :title="collapsed ? '' : title"
                           :header-data-test="`${dataTestId}-header`"
                           :button-data-test="`${dataTestId}-collapse-button`"
                           :collapsed="collapsed"
                           :subtitle="collapsed ? '' : subtitle"
                           :hide-divider="collapsed ? true : hideDivider"
                           :hide-collapse-button="hideCollapseButton"
                           @toggle="collapsed => emit('toggle', collapsed)">
            <template #headerIcon>
                <slot v-if="!collapsed"
                      name="headerIcon" />
            </template>
            <template #headerActions>
                <div class="d-flex flex-column pa-3">
                    <slot v-if="!collapsed"
                          name="headerActions" />
                </div>
            </template>
        </ow-sidebar-header>
        <div v-else
             class="d-flex flex-column pa-3">
            <slot v-if="!collapsed"
                  name="headerActions" />
        </div>
        <div v-if="!collapsed"
             class="ow-sidebar__divider" />
        <div v-if="!collapsed"
             class="ow-sidebar__content d-flex flex-grow-1">
            <slot />
        </div>
        <div v-if="!collapsed"
             class="ow-sidebar__divider" />
        <div v-if="!collapsed"
             class="ow-sidebar__footer py-3 px-3 d-flex flex-column flex-shrink-1">
            <slot name="footer" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import OwSidebarHeader from '@/components/core/ow-sidebar-header.vue'

    withDefaults(defineProps<{
        dataTestId?: string,
        title?: string,
        subtitle?: string,
        collapsed?: boolean,
        hideCollapseButton?: boolean,
        hideDivider?: boolean,
        hideHeader?: boolean,
    }>(), {
        dataTestId: 'ow-sidebar',
        hideCollapseButton: false,
        hideDivider: false,
        hideHeader: false,
    })

    const emit = defineEmits<{
        (e: 'toggle', collapsed: boolean),
    }>()
</script>

<style lang="scss">
    @import './ow-sidebar.scss';
</style>
