<template>
    <ow-page-layout header-icon="$buy"
                    header-title="Checkout"
                    page-name="checkout-review">
        <checkout-process-order :basic-matter-codes="basicMatterCodes"
                                :items="cartItems"
                                @matter-created="addMatterId" />
    </ow-page-layout>
</template>

<script lang="ts">
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import CheckoutProcessOrder from '@/components/checkout/checkout-process-order.vue'
    import OwPageLayout from '@/components/core/layout/page.vue'
    import { Route } from '@/enums/route.enum'
    import { ICheckoutState } from '@/interfaces/store/checkout/checkout-state.interface'
    import { CHECKOUT_MUTATE_BASIC_MATTER_CODES } from '@/store/modules/checkout/types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export default {
        name: 'CheckoutProcessing',

        components: {
            CheckoutProcessOrder,
            OwPageLayout,
        },

        computed: {
            ...mapState('checkout', {
                cartItems: (state: ICheckoutState) => state.items,
                basicMatterCodes: (state: ICheckoutState) => state.basicMatterCodes,
            }),
        },

        async mounted() {
            if (isNullOrEmpty(this.cartItems)) {
                await this.$router.push({ name: Route.Homepage })
            }
        },

        methods: {
            ...mapMutations('checkout', {
                setBasicMatterCodes: CHECKOUT_MUTATE_BASIC_MATTER_CODES,
            }),

            addMatterId(matterId: string) {
                this.setBasicMatterCodes({
                    ...this.basicMatterCodes,
                    id: matterId,
                })
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './checkout-processing';
</style>
