<template>
    <ow-lock v-model="showLock"
             :feature-id="featureId"
             :offset-x="offsetX ?? ''"
             class="pa-6 full-width full-height"
             is-card>
        <slot v-bind="{ $attrs }" />
    </ow-lock>
</template>

<script lang="ts" setup>
    import OwLock from '@/components/core/ow-lock.vue'
    import { FeatureId } from '@/composables/use-licence-controller'

    defineProps<{
        featureId?: FeatureId
        offsetX?: string,
    }>()

    const showLock = defineModel<boolean>({
        default: false,
    })
</script>
