<template>
    <div class="split-zoom-control">
        <ow-button :disabled="zoomInDisabled"
                   :icon="true"
                   class="split-zoom-control__zoom-in"
                   data-test="map-zoom-in-button"
                   data-track="MAP - Zoom in button"
                   @mousedown="onZoomInButtonPressStart"
                   @mouseleave="onZoomInButtonPressEnd"
                   @mouseup="onZoomInButtonPressEnd"
                   @touchcancel="onZoomInButtonPressEnd"
                   @touchend="onZoomInButtonPressEnd"
                   @touchstart="onZoomInButtonPressStart">
            <v-icon>$zoom-in</v-icon>
            <ow-tooltip activator="parent"
                        :disabled="zoomInDisabled || disableTooltips"
                        :position="OwTooltipPosition.Left">
                <span>
                    {{ $t('map.zoom.zoomIn') }}
                    <span v-if="!isSiteVisitEnabled">
                        - {{ $t('map.zoom.pressAndHold', { direction: 'zoom' }) }}
                    </span>
                </span>
            </ow-tooltip>
        </ow-button>

        <ow-button :disabled="zoomOutDisabled"
                   :icon="true"
                   class="split-zoom-control__zoom-out"
                   data-test="map-zoom-out-button"
                   data-track="MAP - Zoom out button"
                   @mousedown="onZoomOutButtonPressStart"
                   @mouseleave="onZoomOutButtonPressEnd"
                   @mouseup="onZoomOutButtonPressEnd"
                   @touchcancel="onZoomOutButtonPressEnd"
                   @touchend="onZoomOutButtonPressEnd"
                   @touchstart="onZoomOutButtonPressStart">
            <v-icon>$zoom-out</v-icon>
            <ow-tooltip activator="parent"
                        :position="OwTooltipPosition.Left"
                        :disabled="zoomOutDisabled || disableTooltips">
                <span>
                    {{ $t('map.zoom.zoomOut') }}
                    <span v-if="!isSiteVisitEnabled">
                        - {{ $t('map.zoom.pressAndHold', { direction: 'zoom out' }) }}
                    </span>
                </span>
            </ow-tooltip>
        </ow-button>
    </div>
</template>

<script lang="ts">
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'

    export default {
        name: 'SplitZoomControl',

        components: {
            OwButton,
            OwTooltip,
        },

        props: {
            isSiteVisitEnabled: {
                type: Boolean,
                required: false,
            },

            zoomInDisabled: {
                type: Boolean,
                required: false,
            },

            zoomOutDisabled: {
                type: Boolean,
                required: false,
            },

            disableTooltips: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'click',
            'zoom-in-start',
            'zoom-in-end',
            'zoom-out-start',
            'zoom-out-end',
        ],

        data() {
            return {
                OwTooltipPosition,
            }
        },

        methods: {
            onZoomInButtonPressStart(): void {
                this.$emit('zoom-in-start')
            },

            onZoomInButtonPressEnd(): void {
                this.$emit('zoom-in-end')
            },

            onZoomOutButtonPressStart(): void {
                this.$emit('zoom-out-start')
            },

            onZoomOutButtonPressEnd(): void {
                this.$emit('zoom-out-end')
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './split-zoom-control';
</style>
