<template>
    <v-card v-if="loaded"
            class="manage-addresses">
        <v-card-title class="content__subheading">
            Postal address for documents
            <span class="manage-addresses__title--icon"
                  data-test="settings-address-edit"
                  data-track="ACCOUNT - Toggle edit address"
                  @click="toggleEditable">
                <v-icon v-if="!isEditable">$edit</v-icon>
                <v-icon v-if="isEditable">$close</v-icon>
            </span>
        </v-card-title>
        <v-card-text class="manage-addresses__text">
            <p class="body-copy">
                Select where you would like to receive HMLR documents that are sent in the post.
                Changes of address must be made before ordering a document.
            </p>
            <p v-if="!isEditable"
               class="body-copy">
                <label class="content__label">Current Address:</label>
                <span data-test="settings-address-current">{{ currentAddress }}</span>
            </p>
            <div v-else>
                <ul class="manage-addresses__text--choose-type">
                    <li>
                        <ow-radio id="org"
                                  v-model="addressType"
                                  name="addressType"
                                  :label="organisationAddress"
                                  color="primary"
                                  data-test="settings-address-organisation"
                                  data-track="ACCOUNT - Use organisation address"
                                  hide-details
                                  value="org"
                                  @update:model-value="checkForEdits" />
                    </li>
                    <li>
                        <ow-radio id="alt"
                                  v-model="addressType"
                                  name="addressType"
                                  color="primary"
                                  data-test="settings-address-user"
                                  data-track="ACCOUNT - Use alternate address"
                                  hide-details
                                  label="Use alternate address"
                                  value="alt"
                                  @update:model-value="checkForEdits" />
                        <addresses-form :address="preferences"
                                        :form-is-enabled="formIsEnabled"
                                        class="new-address-form"
                                        @updated="updatedForm"
                                        @form-is-valid="buttonIsDisabled = !$event" />
                    </li>
                </ul>
            </div>
        </v-card-text>
        <ow-card-actions v-if="isEditable"
                         justify="start"
                         :primary-button-disabled="buttonIsDisabled"
                         :primary-button-text="$t('action.update')"
                         primary-button-data-test="btn-update-address"
                         primary-button-data-track="ACCOUNT - Update address"
                         @primary-button-click="update" />
    </v-card>
</template>

<script>
    import { mapActions } from 'vuex'

    import UserApi from '@/api/user.api'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwRadio from '@/components/core/ow-radio.vue'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { formatProfileAddress } from '@/utils/user.utils'

    import AddressesForm from './addresses-form.vue'

    export default {
        name: 'Addresses',

        components: {
            AddressesForm,
            OwCardActions,
            OwRadio,
        },

        props: {
            orgAddress: {
                type: Object,
                required: true,
            },
            preferences: {
                type: Object,
                required: true,
            },
        },

        emits: ['update', 'loaded'],

        data() {
            return {
                addressType: '',
                originalAddressType: '',
                isEditable: false,
                hasEdits: false,
                form: {},
                buttonIsDisabled: true,
                loaded: false,
                currentSectionAddress: '',
            }
        },

        computed: {
            organisationAddress() {
                return this.displayAddress(this.currentSectionAddress)
            },

            currentAddress() {
                if (!this.preferences.useCustomPostalAddress) {
                    return this.displayAddress(this.currentSectionAddress)
                }

                return this.displayAddress(this.preferences)
            },

            formIsEnabled() {
                return this.addressType !== 'org'
            },
        },

        async mounted() {
            this.setAddressType()
            await this.setCurrentSectionAddress()
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            async setCurrentSectionAddress() {
                const response = await UserApi.getUserProfile()
                this.currentSectionAddress = response.ok
                    ? formatProfileAddress(response.data.organisationAddress)
                    : this.orgAddress
                this.loaded = true
                this.$emit('loaded', true)
            },

            toggleEditable() {
                this.isEditable = !this.isEditable
                this.checkForEdits()
            },

            checkForEdits() {
                this.hasEdits = this.addressType !== this.originalAddressType
                this.disableButton()
                if (this.hasEdits) {
                    this.logHeapEvent({
                        type: 'ACCOUNT - Address type change',
                        metadata: {
                            addressType: this.addressType,
                        },
                    })
                }
            },

            update() {
                if (this.addressType === 'org') {
                    this.$emit('update', {
                        type: 'org',
                        address: {},
                    })
                } else {
                    this.$emit('update', {
                        type: 'alt',
                        address: this.form,
                    })
                }

                this.isEditable = false
                this.hasEdits = false
                this.originalAddressType = this.addressType
                this.form = {}
                this.buttonIsDisabled = true
            },

            setAddressType() {
                this.addressType = this.preferences.useCustomPostalAddress ? 'alt' : 'org'
                this.originalAddressType = this.addressType
            },

            updatedForm(newAddress) {
                this.form = newAddress
                this.buttonIsDisabled = false
            },

            disableButton() {
                // update button is always disabled when switching to the alt address
                // The checkbox being checked will enable the button
                this.buttonIsDisabled = true

                // If switching to org address, then need to enable the button
                if (this.addressType !== this.originalAddressType && this.addressType === 'org') {
                    this.buttonIsDisabled = false
                }
            },

            displayAddress(address) {
                const output = []

                if (address.addressHouseNumber) {
                    output.push(address.addressHouseNumber)
                }

                if (address.addressStreet) {
                    output.push(address.addressStreet)
                }

                if (address.addressLocality) {
                    output.push(address.addressLocality)
                }

                if (address.addressTown) {
                    output.push(address.addressTown)
                }

                if (address.addressPostcode) {
                    output.push(address.addressPostcode)
                }

                return output.join(', ')
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './addresses.scss';
</style>
