<template>
    <div class="find-nearby-container">
        <ow-card :subtitle="subtitleText"
                 :title="titleText">
            <find-nearby-header :available-options="nearbyOptions"
                                @distance="handleDistanceUpdated"
                                @selected-option="setSelectedOption" />

            <div v-if="!hasError">
                <component :is="selectedNearbyService.displayComponentName"
                           v-if="filteredResults"
                           class="find-nearby-container__results"
                           :is-loading="loading"
                           :service="selectedNearbyService"
                           :records="filteredResults.records"
                           :highlight-results="highlightResults"
                           @mouse-over-item="handleMouseOverItem" />
            </div>
            <div v-else-if="hasError && !loading"
                 v-t="'titlePanel.findNearby.error'"
                 class="find-nearby-container__error" />
        </ow-card>
    </div>
</template>

<script lang="ts">
    import throttle from 'lodash.throttle'
    import { MapBrowserEvent } from 'ol'
    import Feature from 'ol/Feature'
    import { Layer } from 'ol/layer'
    import olMap from 'ol/Map'
    import {
        mapActions,
        mapGetters,
    } from 'vuex'

    import OwCard from '@/components/core/ow-card.vue'
    import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
    import FindNearbyHeader from '@/components/find-nearby/find-nearby-header.vue'
    import {
        FindNearbyHighlightLayer,
        IFindNearbyHighlightLayerParams,
    } from '@/components/find-nearby/highlight-layer'
    import {
        IFindNearbyResults,
        IFindNearbyService,
    } from '@/components/find-nearby/implementations/_common/find-nearby-service.interface'
    import CorporateOwnerResults from '@/components/find-nearby/implementations/corporate-owners/corporate-owners-results.vue'
    import { FindNearbyCorporateOwnerService } from '@/components/find-nearby/implementations/corporate-owners/corporate-owners-service'
    import MatterTitleResults from '@/components/find-nearby/implementations/matter-titles/matter-titles-results.vue'
    import { FindNearbyMatterTitlesService } from '@/components/find-nearby/implementations/matter-titles/matter-titles-service'
    import { INearbyMatterTitle } from '@/components/find-nearby/implementations/matter-titles/nearby-matter-title.interface'
    import {
        FindNearbyResultsLayer,
        IFindNearbyResultsLayerParams,
    } from '@/components/find-nearby/results-layer'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { MATTER_ADD_MULTIPLE_TITLES } from '@/store/modules/matter/types'
    import {
        NPS_GET_FEATURES_BY_TITLE_NUMBERS,
        NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
    } from '@/store/modules/nps/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import {
        equalsIgnoreOrder,
        isNullOrEmpty,
    } from '@/utils/array-utils'
    import { layerEquals } from '@/utils/map-utils'

    import {
        FindNearbyBufferLayer,
        IFindNearbyBufferLayerParams,
    } from './buffer-layer'

    export default {
        name: 'FindNearbyContainer',
        components: {
            FindNearbyHeader,
            MatterTitleResults,
            OwCard,
            CorporateOwnerResults,
        },

        mixins: [flagsMixin],

        props: {
            selectedTitleNumbers: {
                type: Array,
                required: true,
            },
            currentMatterId: {
                type: Number,
                required: true,
            },
            targetMap: {
                type: olMap,
                required: false,
            },
        },

        data() {
            return {
                selectedNearbyService: null as IFindNearbyService,
                bufferLayer: null as FindNearbyBufferLayer,
                resultsLayer: null as FindNearbyResultsLayer,
                highlightLayer: null as FindNearbyHighlightLayer,
                highlightResults: [],
                overFeatureIds: [] as Array<Feature>,
                throttledHeapLogModifyRadius: throttle(this._heapLogModifyRadius, 2000),
                // selectedTitleNumbers: ['TGL50538', 'WA858410', 'BK375158'], // Uncomment to test support for multiple titles.
                loading: true,
                selectedDistance: 0,
                selectedTitleFeatures: null as Array<Feature>,
                filteredResults: null as IFindNearbyResults,
                hasError: false,
                isLoadingResults: false,
            }
        },

        computed: {
            ...mapGetters({
                getFeaturesByTitlesNumbers: NPS_GET_FEATURES_BY_TITLE_NUMBERS,
            }),

            subtitleText(): string {
                return this.selectedNearbyService?.getSubtitleText({
                    filteredResults: this.filteredResults?.records ?? [],
                    selectedDistanceInMetres: this.selectedDistance,
                    selectedTitleNumbers: this.selectedTitleNumbers,
                })
            },

            titleText(): string {
                return this.selectedNearbyService?.getTitleText()
            },

            nearbyOptions(): Array<IFindNearbyService> {
                // Defaults
                const options = [
                    new FindNearbyMatterTitlesService({
                        getMapFeaturesFn: this.getTitleNumberFeatures,
                    }),
                ] as Array<IFindNearbyService>

                // Additional options based on feature flags
                if (this.isFindNearbyCorporateOwnersEnabled) {
                    options.push(new FindNearbyCorporateOwnerService({
                        getMapFeaturesFn: this.getTitleNumberFeatures,
                        onAddTitlesToMatterFn: this.onAddTitlesToMatter,
                    }))
                }
                return options
            },
        },

        watch: {
            async selectedTitleNumbers(): Promise<void> {
                await this.loadNearbyResults()
            },

            selectedDistance(newRadius: number): void {
                this.bufferLayer?.setRadius(newRadius)
                this.updateFilteredResults()
                this.zoomToBufferLayerExtent()
            },

            async targetMap(newMap: olMap): Promise<void> {
                if (newMap) {
                    await this.initialiseLayers()
                }
            },

            async selectedNearbyService(newService: IFindNearbyService, oldService: IFindNearbyService): Promise<void> {
                oldService?.reset()
                this.selectedNearbyService = newService
                await this.loadNearbyResults()
            },
        },

        async mounted() {
            await this.initialiseLayers()
        },

        beforeUnmount() {
            this.resultsLayer?.dispose()
            this.highlightLayer?.dispose()
            this.bufferLayer?.dispose()
            this.targetMap?.un('pointermove', this.handlePointerMove)
            this.highlightResults = []
            this.selectedNearbyService.reset()
        },

        methods: {
            ...mapActions({
                // TODO: Move logging into service too
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                // TODO: Move the NPS store into a service
                loadFeaturesForTitleNumbers: NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
                addTitlesToMatter: MATTER_ADD_MULTIPLE_TITLES,
            }),

            initialiseBufferLayer(): void {
                const params: IFindNearbyBufferLayerParams = {
                    targetMap: this.targetMap,
                }
                this.bufferLayer = new FindNearbyBufferLayer(params)
            },

            initialiseResultsLayer(): void {
                const params: IFindNearbyResultsLayerParams = {
                    targetMap: this.targetMap,
                }

                this.resultsLayer = new FindNearbyResultsLayer(params)

                // Listen for roll-over events.
                this.targetMap.on('pointermove', this.handlePointerMove)
            },

            handlePointerMove(e: MapBrowserEvent<UIEvent>): void {
                if (this.resultsLayer?.layer.getVisible()) {
                    const pixel = this.targetMap.getEventPixel(e.originalEvent)
                    const newHighlightedFeatures = this.targetMap
                        .getFeaturesAtPixel(pixel, {
                            layerFilter: currentLayer => layerEquals(this.resultsLayer.layer, currentLayer),
                            hitTolerance: 5,
                        })

                    const featureIds = newHighlightedFeatures.map(x => x.get(this.selectedNearbyService.settings.featureKey))
                    if (equalsIgnoreOrder(this.overFeatureIds, featureIds)) {
                        return
                    }
                    this.overFeatureIds = featureIds
                    this.highlightLayer.setHighlightFeatures(newHighlightedFeatures)
                    this.highlightResults = this.selectedNearbyService.getRecordsFromFeatureIds(featureIds)

                    if (newHighlightedFeatures.length) {
                        this.targetMap.getTargetElement().classList?.add('ow-over-layer-nearby')
                    } else {
                        this.targetMap.getTargetElement().classList?.remove('ow-over-layer-nearby')
                    }
                }
            },

            initialiseHighlightLayer() {
                const params: IFindNearbyHighlightLayerParams = {
                    targetMap: this.targetMap,
                }

                this.highlightLayer = new FindNearbyHighlightLayer(params)
            },

            async initialiseLayers(): Promise<void> {
                this.loading = true
                if (this.targetMap) {
                    this.initialiseBufferLayer()
                    this.initialiseResultsLayer()
                    this.initialiseHighlightLayer()
                }

                await this.setSelectedOption(this.nearbyOptions[0])
                if (this.targetMap) {
                    this.bufferLayer.setSelectedTitleFeatures(this.selectedTitleFeatures)
                }
            },

            handleDistanceUpdated(distanceMetres: number): void {
                this.highlightLayer.setHighlightFeatures([])
                if (this.selectedDistance !== distanceMetres) {
                    this.selectedDistance = distanceMetres
                }
                this.throttledHeapLogModifyRadius()
            },

            _heapLogModifyRadius(): void {
                if (this.loading) {
                    return
                }
                this.logHeapEvent({
                    type: 'NEARBY TAB - Modify radius',
                    metadata: {
                        distanceMetres: this.selectedDistance,
                        visibleResults: this.filteredResults.records?.length ?? 0,
                    },
                })
            },

            handleMouseOverItem(item: INearbyMatterTitle): void {
                const featuresToHighlight = this.selectedNearbyService.getFeaturesForRecords([item], this.selectedDistance)
                this.highlightLayer.setHighlightFeatures(featuresToHighlight)
            },

            async getTitleNumberFeatures(titleNumbers: number[]): Promise<Feature[]> {
                await this.loadFeaturesForTitleNumbers(titleNumbers)
                const features = await this.getFeaturesByTitlesNumbers(titleNumbers)
                return features
            },

            async loadNearbyResults() {
                if (!this.isLoadingResults && this.selectedNearbyService && this.selectedTitleNumbers) {
                    this.isLoadingResults = true
                    this.hasError = false
                    this.loading = true

                    if (!isNullOrEmpty(this.selectedTitleNumbers) && this.bufferLayer) {
                        this.selectedNearbyService.results = null
                        this.selectedTitleFeatures = await this.getTitleNumberFeatures(this.selectedTitleNumbers)
                        this.bufferLayer.setSelectedTitleFeatures(this.selectedTitleFeatures)
                        this.selectedDistance = this.selectedNearbyService.settings.defaultRadiusMetres

                        try {
                            await this.selectedNearbyService.handleRequest({
                                titleNumbers: this.selectedTitleNumbers,
                                matterIds: [ this.currentMatterId ],
                            })
                            if (this.selectedNearbyService.results) {
                                this.resultsLayer.setResultFeatures(this.selectedNearbyService.results.features)
                                this.updateFilteredResults()
                                this.loading = false
                            }
                        } catch (error) {
                            console.warn('Error loading nearby results', error)
                            this.hasError = true
                        }
                    }

                    this.isLoadingResults = false
                }
            },

            updateFilteredResults(): void {
                this.filteredResults = this.selectedNearbyService.getFilteredResults({ distanceMetres: this.selectedDistance })
                this.resultsLayer.setVisibleFeatures(this.filteredResults.features)
            },

            async setSelectedOption(service: IFindNearbyService) {
                this.filteredResults = null
                this.selectedNearbyService = service
                this.highlightLayer?.reset()
                this.resultsLayer?.setFeatureIdKey(this.selectedNearbyService.settings.featureKey)
                await this.loadNearbyResults()
                this.bufferLayer?.setRadius(this.selectedNearbyService.settings.defaultRadiusMetres)
                this.zoomToBufferLayerExtent()
            },

            zoomToBufferLayerExtent() :void {
                if (this.targetMap) {
                    const extent = this.bufferLayer.bufferFeature?.getGeometry().getExtent()
                    if (extent) {
                        this.targetMap.getView().fit(extent, {
                            maxZoom: FindNearbyDefaults.BUFFER_DEFAULT_MAX_ZOOM,
                            padding: FindNearbyDefaults.BUFFER_DEFAULT_VIEW_PADDING,
                        })
                    }
                }
            },

            async onAddTitlesToMatter(titleNumbers: Array<string>) {
                await this.addTitlesToMatter({
                    showPopup: true,
                    titleNumbers,
                })
            },
        },
    }
</script>

<style lang="scss">
@import './find-nearby-container';
</style>
