<template>
    <div class="matter-list-results-container"
         :class="{'matter-list-results-container--no-results': !items.length}">
        <div v-if="isLoading"
             class="matter-list-results-container__loading"
             data-test="matter-list-results-container-loading-skeletons">
            <ow-loading-skeleton v-for="index in 6"
                                 :key="index"
                                 height="60px" />
        </div>
        <v-list v-else>
            <v-list-item v-for="item in items"
                         :key="item.id"
                         :data-test="`matter-list-results-container-item-${ item.code }`"
                         class="matter-list-results-container__item">
                <matters-list-item :is-newly-shared="Boolean(!item.isMatterRead && isSharedTabActive)"
                                   :matter="item"
                                   data-test="matters-list-item"
                                   @select-matter="$emit('select-matter', item)" />
            </v-list-item>
            <v-list-item v-if="moreDataAvailable && hasMattersToDisplay"
                         v-intersect="loadNextPage"
                         class="d-flex justify-center matter-list-results-container__loading"
                         data-test="matter-list-results-container-loader">
                <v-progress-circular color="primary"
                                     indeterminate
                                     size="50" />
            </v-list-item>
            <v-list-item v-if="!hasMattersToDisplay"
                         class="content__heading"
                         data-test="matter-list-no-matters-text">
                {{ $t('noMatters') }}
            </v-list-item>
        </v-list>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import MattersListItem from '@/components/matters-list/matters-list-item.vue'
    import { IMatter } from '@/interfaces/matter.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export default {
        name: 'MatterListContainerWithPagination',

        components: {
            MattersListItem,
            OwLoadingSkeleton,
        },

        props: {
            items: {
                type: Array as PropType<Array<IMatter>>,
                required: true,
                default: () => [],
            },
            moreDataAvailable: {
                type: Boolean,
                required: false,
            },
            isLoading: {
                type: Boolean,
                default: true,
            },
            isSharedTabActive: {
                type: Boolean,
                default: false,
            },
        },

        emits: [
            'load-data',
            'select-matter',
        ],

        computed: {
            hasMattersToDisplay(): boolean {
                return !isNullOrEmpty(this.items)
            },
        },

        methods: {
            async loadNextPage(intersects: boolean) {
                if (intersects && this.moreDataAvailable) {
                    this.$emit('load-data', false)
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './matter-list-container-with-pagination.scss';
</style>
