<template>
    <v-btn :block="block"
           :color="color"
           :disabled="disabled"
           :loading="isLoading"
           :size="size"
           :variant="variant"
           data-test="ow-button"
           @click.stop="click">
        <slot />
    </v-btn>
</template>

<script lang="ts">
    import { OwButtonColor } from '@/enums/ow-button-color'

    /**
     * @deprecated Use the DS ow-button-ds instead
     */
    export default {
        name: 'OwButton',

        props: {
            block: {
                type: Boolean,
                required: false,
            },
            color: {
                type: String as () => OwButtonColor,
                required: false,
                default: OwButtonColor.Primary,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            outlined: {
                type: Boolean,
                required: false,
            },
            small: {
                type: Boolean,
                required: false,
            },
            large: {
                type: Boolean,
                required: false,
            },
            text: {
                type: Boolean,
                required: false,
            },
        },

        emits: ['click'],

        computed:{
            variant(){
                if(this.outlined){
                    return 'outlined'
                } else if(this.text){
                    return 'text'
                }
                return 'flat'
            },

            size(){
                if(this.small){
                    return 'small'
                }
                if(this.large){
                    return 'large'
                }
                return 'default'
            },
        },

        methods: {
            click() {
                this.$emit('click')
            },
        },
    }
</script>
