/// See https://www.figma.com/file/3OmijfkDNaxNK8qIqka4bk/OW-Design-System?node-id=749%3A10006&t=7qFoVG0CYo9FxwnK-0
import { BoundaryStyle } from '@/models/styles/boundary-style'
export class HazardColourPalette {
    private readonly _palette: Array<BoundaryStyle>
    constructor() {
        this._palette = [
            new BoundaryStyle('Coal', '#4f5254', '#ffffff'),
            new BoundaryStyle('HS2', '#e40c7f', '#ffffff'),
            new BoundaryStyle('Crossrail', '#ba5803', '#ffffff'),
            new BoundaryStyle('EnergyExploration', '#4b90be', '#ffffff'),
            new BoundaryStyle('WindFarms', '#9846f6', '#ffffff'),
            new BoundaryStyle('SolarFarms', '#03817d', '#ffffff'),
            new BoundaryStyle('RenewablePowerPlants', '#c91ed2', '#ffffff'),
            new BoundaryStyle('Transport', '#ea0b12', '#ffffff'),
            new BoundaryStyle('Flood', '#0753bc', '#ffffff'),
            new BoundaryStyle('BathStone', '#98cdf8', '#ffffff'),
            new BoundaryStyle('Metalliferous', '#fcaa65', '#ffffff'),
            new BoundaryStyle('Kaolin', '#f3cf15', '#ffffff'),
            new BoundaryStyle('Limestone', '#006075', '#ffffff'),
            new BoundaryStyle('Brine', '#63a7ea', '#fff'),
            new BoundaryStyle('GroundStability', '#ff6c50', '#ffffff'),
            new BoundaryStyle('NonCoalMining', '#8a8d8f', '#ffffff'),
            new BoundaryStyle('GroundMovement', '#66053d', '#ffffff'),
            new BoundaryStyle('Mineral', '#078703', '#ffffff'),
            new BoundaryStyle('Environmental', '#0d5f2e', '#ffffff'),
            new BoundaryStyle('Radon', '#ffd500', '#000'),
            new BoundaryStyle('Utilities', '#EDB482', '#ffffff'),
            new BoundaryStyle('Chancel', '#9b79ce', '#ffffff'),
            new BoundaryStyle('Highways', '#02216d', '#ffffff'),
            new BoundaryStyle('Planning', '#73c5e6', '#ffffff'),
            new BoundaryStyle('HMLR', '#d7f8d0', '#000'),
            new BoundaryStyle('Other', '#eceff2', '#000'),
            new BoundaryStyle('Energy&Infrastructure', '#b81545', '#ffffff'),
            new BoundaryStyle('Drainage&Water', '#bd794a', '#ffffff'),
            new BoundaryStyle('LocalAuthority', '#1d73ad', '#ffffff'),
        ]
    }

    getPalette(): Array<BoundaryStyle> {
        return this._palette
    }

    getEntryForName(alias: string): BoundaryStyle {
        return this._palette.find((item) => item.alias === alias)
    }

    getEntryForIndex(index: number): BoundaryStyle {
        return this._palette?.[index] ?? this._palette[0]
    }
}
