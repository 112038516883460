<template>
    <ul class="quick-actions">
        <li>
            <ow-quick-action-card :icon="OwIconTemplate"
                                  :title="t('quickActions.applyTemplate.title')"
                                  :description="t('quickActions.applyTemplate.description')"
                                  data-track-attribute="TAM: Apply template quick action card"
                                  data-test-attribute="quick-action-card-apply-template"
                                  @click="emit('click', 'applyTemplate')" />
        </li>
        <li>
            <ow-quick-action-card :icon="OwIconDownload"
                                  :title="t('quickActions.exportCurrentView.title')"
                                  :description="t('quickActions.exportCurrentView.description')"
                                  data-track-attribute="TAM: Apply template quick action card"
                                  data-test-attribute="quick-action-card-export-data"
                                  @click="emit('click', 'exportCurrentView')" />
        </li>
        <li>
            <ow-quick-action-card :icon="OwIconDownload"
                                  :is-disabled="!hasPurchasedTitles"
                                  :disabled-tooltip="t('quickActions.downloadTAR.disabled')"
                                  :title="t('quickActions.downloadTAR.title')"
                                  :description="t('quickActions.downloadTAR.description')"
                                  data-track-attribute="TAM: Apply template quick action card"
                                  data-test-attribute="quick-action-card-download-tar"
                                  @click="emit('click', 'downloadTAR')" />
        </li>
    </ul>
</template>

<script setup lang="ts">
    import { useI18n } from 'vue-i18n'

    import OwIconDownload from '@/components/core/icons/ow-icon-download.vue'
    import OwIconTemplate from '@/components/core/icons/ow-icon-template.vue'
    import OwQuickActionCard from '@/components/core/ow-quick-action-card.vue'

    defineProps<{
        hasPurchasedTitles: boolean
    }>()

    const { t } = useI18n()

    const emit = defineEmits<{
        (e: 'click', type: string): void;
    }>()
</script>

<style scoped lang="scss">
    @import './quick-actions';
</style>
