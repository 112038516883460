import uniqueId from "lodash/uniqueId"

import { HttpClient } from "@/api/http-client"
import SecurityService from "@/api/security"
export async function* getIterableStream(
    body: ReadableStream<Uint8Array>,
): AsyncIterable<string> {
    const reader = body.getReader()
    const decoder = new TextDecoder()

    while (true) {
        const { value, done } = await reader.read()
        if (done) {
            break
        }
        const decodedChunk = decoder.decode(value, { stream: true })
        yield decodedChunk
    }
}

export interface JsonStreamPayload<T> {
    resultType: number
    payload: T
}

export const jsonStreamReader = async <T>({
    url,
    responseBody,
    onChunkPayload,
}: {
    url?: string,
    responseBody?: ReadableStream<Uint8Array>,
    onChunkPayload: (payload: JsonStreamPayload<T>) => void
}) => {
    const token = await SecurityService.getAccessToken()
    if (!token) {
        return null
    }

    const authHeaders = {
        Authorization: `Bearer ${ token }`,
    }
    try {
        const body = responseBody ?? await fetch(url, {
            headers: authHeaders,
        }).then(response => response.body)

        for await (const chunk of getIterableStream(body)) {
            chunk.toString().split('\n').forEach(line => {
                if (line) {
                    try {
                        const data = JSON.parse(line)
                        onChunkPayload({
                            payload: data.Payload,
                            resultType: data.ResultType,
                        })
                    } catch (e) {
                        console.error('Error parsing JSON:', e, line)
                    }
                }
            })
        }
    } catch (error) {
        console.error('Error in jsonStreamReader:', error)
    }
}