import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { LayerNames } from '@/consts/map-layers'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import {
    Vector as VectorLayer,
} from 'ol/layer'
import olMap from 'ol/Map'
import { Vector as VectorSource } from 'ol/source'
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'

import { Colors } from '@/enums/colors.enum'
import { hexToRGBArray } from '@/utils/colour-utils'
import { Feature } from 'ol'
import {
    Geometry,
    GeometryCollection,
} from 'ol/geom'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { bufferFeatures,
    layerEquals } from '@/utils/map-utils'
import GeoJSON from 'ol/format/GeoJSON'
export type SearchesLayerSelectionLayerParams = {
    getTitlesDataFn: () => any
    geoJson?: any
    snapshotConfig?: LayerSnapshotModel
    overFeaturesClassName?: string
    targetMap: olMap
    interactive: boolean
    onSearchBoundaryRemoveFn: () => void
}

export class SearchesLayer implements IMapSnapshotLayer {
    name: string = LayerNames.SearchesLayer
    private readonly layer: VectorLayer<VectorSource<Feature<Geometry>>>
    private readonly args: SearchesLayerSelectionLayerParams
    private readonly overFeaturesClassName: string = 'remove-search-boundary'
    private readonly geoJson: any
    private readonly onSearchBoundaryRemoveFn: () => void
    private getTitlesDataFn: () => any
    private interactive: boolean = false
    private map: olMap

    constructor(args: SearchesLayerSelectionLayerParams) {
        this.args = args
        this.getTitlesDataFn = args.getTitlesDataFn
        this.overFeaturesClassName = args.overFeaturesClassName
        this.interactive = args.interactive
        this.onSearchBoundaryRemoveFn = args.onSearchBoundaryRemoveFn
        this.geoJson = args.geoJson
        this.layer = new VectorLayer({
            zIndex: 13,
            source: new VectorSource(),
            style: this.getStyle,
        })
        this.layer.set('name', this.name)
        this.layer.set('getOwLayer', () => this)
    }

    getStyle(): Style | Style[] {
        const style = new Style({
            zIndex: 1,
            stroke: new Stroke({
                color: hexToRGBArray(Colors.RedBoundary),
                width: 3,
            }),
            fill: new Fill({
                color: `rgba(${ hexToRGBArray(Colors.RedBoundary) },0.2)`,
            }),
        })
        return style
    }

    getIsLoading(): boolean {
        return this.layer.getSource().getFeatures().length === 0
    }

    getLayer(): VectorLayer<VectorSource> {
        return this.layer
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return null
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        return []
    }

    getVisible(): boolean {
        return this.layer.getVisible()
    }

    setInteractive(interactive: boolean): void {
        this.interactive = interactive
    }

    setVisible(visible: boolean, targetMap?: olMap): Promise<void> {
        this.layer.setVisible(visible)
        if (targetMap && !targetMap.getLayers().getArray().includes(this.layer)) {
            targetMap.addLayer(this.layer)
        }
        return Promise.resolve()
    }

    updateSearchesLayer(availableFeatures: Feature<Geometry>[]): Feature<Geometry> {
        let selectedFeatures = []
        this.getLayer()?.getSource()?.clear()
        if (this?.geoJson) {
            selectedFeatures = new GeoJSON().readFeatures(this.geoJson, {
                featureProjection: CoordinateSystemCode.EPSG27700,
                dataProjection: CoordinateSystemCode.EPSG4326,
            }) as Feature<Geometry>[]
            this.getLayer()?.getSource()?.addFeature(selectedFeatures[0])
            this.getLayer()?.getSource()?.changed()
            return selectedFeatures[0]
        } else {
            selectedFeatures = this.getTitlesDataFn()?.filter(t => t.selected).flatMap((titleData) => {
                const titleNumber = (f) => {
                    return f.get('titleNumber') || f.get('title_no')
                }
                const features = availableFeatures.filter(f => titleNumber(f) === titleData.titleNumber)
                return features
            })

            if (!selectedFeatures?.length) {
                return
            }

            const newSelectedFeatures = selectedFeatures.flatMap(feature => {
                const newFeature: GeometryCollection = feature.getGeometry() as GeometryCollection
                if (newFeature.getType() !== 'GeometryCollection') {
                    return new Feature(newFeature)
                }
                return newFeature.getGeometries().map((geometry) => {
                    const newFeature = new Feature(geometry)
                    newFeature.set('titleNumber', feature.get('titleNumber'))
                    return newFeature
                })
            })
            selectedFeatures = newSelectedFeatures
        }

        const unionSelectedFeature: any = (bufferFeatures(selectedFeatures, 0) as any).getGeometry().transform(CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700)
        const unionFeature = new Feature(unionSelectedFeature)
        this.getLayer()?.getSource()?.addFeature(unionFeature)
        this.getLayer()?.getSource()?.changed()

        return unionFeature
    }

    public getMap(): olMap {
        return this.map
    }

    // Sets the target map on to which the layer should be added and related map events observed.
    public setMap(map: olMap): void {
        this.map = map

        map.on('pointermove', (e) => {
            if (!this.interactive) {
                this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
                return
            }
            const pixel = map.getEventPixel(e.originalEvent)
            let isHoveringOverTitle = false
            map.forEachFeatureAtPixel(pixel, () => {
                this.map.getTargetElement().classList.add(this.overFeaturesClassName)
                isHoveringOverTitle = true
            }, {
                layerFilter: layer => layerEquals(layer, this.layer),
            })
            if (!isHoveringOverTitle) {
                this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
            }
        })
        map.on('singleclick', () => {
            if (!this.layer.getVisible() || !this.interactive) {
                return
            }
            this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
            if (this.onSearchBoundaryRemoveFn) {
                this.onSearchBoundaryRemoveFn()
            }
        })
    }
}
