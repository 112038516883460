export const waitForExpression = async (expression: () => boolean, timeout = 10000, errorOnTimeout = false): Promise<void> => {
    return new Promise((resolve, reject) => {
        const start = Date.now()
        const intervalId = setInterval(() => {
            if (expression()) {
                clearInterval(intervalId)
                resolve()
            } else if (Date.now() - start > timeout) {
                clearInterval(intervalId)
                if(errorOnTimeout) {
                    reject(new Error(`Timeout waiting for expression to be true ${ expression }`))
                } else {
                    resolve()
                }
            }
        }, 10)
    })
}
