import * as LDClient from 'launchdarkly-js-client-sdk'
import camelCase from 'lodash.camelcase'

import ConfigurationApi from '@/api/configuration.api'
import {
    createLdUser,
    initLDClient,
    ldClient,
} from '@/feature-flags'
import { LOGGING_SET_STATE_PROPERTIES } from '@/store/mutation-types'

import {
    INIT_FEATURE_FLAGS,
    MUTATE_FEATURE_FLAGS,
    MUTATE_LOADED,
    MUTATE_SETTINGS,
    RELOAD,
} from './types'

export default {
    async [RELOAD]({ commit }) {
        const response = await ConfigurationApi.getSettings()

        if (response.ok) {
            commit(MUTATE_SETTINGS, response.data)
        }

        commit(MUTATE_LOADED, true)
    },

    async [INIT_FEATURE_FLAGS]({ commit, dispatch, state }, user) {
        // don't want to initialise Launch Darkly for the e2e test users as they eat
        // into our monthly MAU allowance

        const ldUser = createLdUser(user)
        const options = {}
        if (window?.Cypress || window?.isUnderTest) {
            options.logger = LDClient.basicLogger({ level: 'none' })
        }
        const flags = await initLDClient(ldUser, options)

        commit(MUTATE_FEATURE_FLAGS, flags)
        await dispatch(LOGGING_SET_STATE_PROPERTIES, flags, { root: true })

        // Register so that we get flag updates
        ldClient.on('change', async changes => {
            const flattened = {}
            for (const key in changes) {
                flattened[camelCase(key)] = changes[key].current
            }
            const flags = { ...state.featureFlags, ...flattened }
            commit(MUTATE_FEATURE_FLAGS, flags)

            const flagsToLog = { ...flags }
            for (const prop in flagsToLog) {
                if (flagsToLog[prop] === false) {
                    delete flagsToLog[prop]
                }
            }
            await dispatch(LOGGING_SET_STATE_PROPERTIES, flagsToLog, { root: true })
        })
    },

}
