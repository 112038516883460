import gql from 'graphql-tag'

import {
    isNullOrEmpty,
    unique,
} from '@/utils/array-utils'

const GET_TITLES_QUERY_BY_COLUMN_SELECTION = (selectedColumns = []) => {
    // If outside of a matter, or TAM hasn't been loaded, then there will be no selected columns
    if (isNullOrEmpty(selectedColumns)) {
        return undefined
    }

    let propertyQuery = ''

    // Properties are based either on column fields passed directly to the graphql endpoint,
    // or one or more fields, some of which may be nested.
    // Determine properties paths to be used in the query
    let propertyPaths = []
    selectedColumns.forEach(c => {
        if (!c.computed) {
            if (c.requires) {
                propertyPaths.push(...c.requires)
            } else {
                propertyPaths.push(c.field)
            }
        }
    })
    propertyPaths = unique(propertyPaths).sort().map(p => p.split('.'))

    const nested = []

    const appendQueryPart = (part, indent) => {
        propertyQuery += `${ '\t'.repeat(indent) }${ part }\n`
    }

    const addQueryFields = (data, depth) => {
        data.forEach(item => {
            const propString = item.slice(0, depth + 1).toString()
            if (!nested.includes(propString)) {
                const relatedFields = unique(propertyPaths.filter(p => {
                    if (p.length > depth + 1) {
                        return propString == p.slice(0, depth + 1).toString()
                    }
                    return false
                }))

                if (relatedFields.length > 0) {
                    if (!nested.includes(propString)) {
                        appendQueryPart(`${ item[depth] } {`, depth + 1)
                    }
                    addQueryFields(relatedFields, depth + 1)
                    if (!nested.includes(propString)) {
                        appendQueryPart('}', depth + 1)
                    }
                } else {
                    appendQueryPart(item[depth], depth + 1)
                }
                nested.push(propString)
            }
        })
    }

    addQueryFields(propertyPaths, 0)

    const query = `query titleRegisters(
            $matterId: Long!
            $titleNumbers: [String!]
        ) {
            titleRegisters(
                matterId: $matterId,
                titleNumbers: $titleNumbers
            ) {
                ${ propertyQuery }
            }
        }`
    return gql`${ query }`
}

export { GET_TITLES_QUERY_BY_COLUMN_SELECTION }
