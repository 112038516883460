<template>
    <section class="map-snapshot-scale-text">
        <p class="map-snapshot-scale-text__value">
            <span v-t="'snapshots.map.scale.title'" />
            <strong>1:{{ formatNumber(scaleRatio) }}</strong>
        </p>
        <p class="map-snapshot-scale-text__disclaimer">
            {{ disclaimerText }}
        </p>
    </section>
</template>
<script setup lang="ts">
    import {
        computed,
        ref,
    } from 'vue'

    import {
        PageLayouts,
        SnapshotOrientation,
        SnapshotPrintSizeMarginsMm,
    } from '@/components/snapshots/common/snapshot-enums-consts'
    import { getScaleForMap } from '@/components/snapshots/common/snapshot-utils'
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'
    import i18n from '@/plugins/i18n'
    import { formatNumber } from '@/utils/number-utils'

    interface Props {
        service: MapSnapshotService
    }

    const props = defineProps<Props>()
    const currentLayout = computed(() => {
        return PageLayouts[props.service.snapshotModel.renderData.layoutId]
    })
    const scaleRatio = ref<number>(0)

    const updateScaleText = () => {
        const dpi = currentLayout.value.dpi
        if (!dpi) {
            console.error('No DPI value found for layout')
            return i18n.global.t('snapshots.map.scale.unknown') as string
        }
        scaleRatio.value = getScaleForMap(props.service.targetMap, dpi)
    }

    props.service.targetMap.on('moveend', () => {
        if (!currentLayout.value.usesRealWorldUnitsMm) {
            return
        }
        updateScaleText()
    })

    const disclaimerText = computed<string>(() => {
        const isLandscape = props.service.snapshotModel.renderData.orientation === SnapshotOrientation.Landscape
        const layout = PageLayouts[props.service.snapshotModel.renderData.layoutId]
        const width = Math.round((isLandscape ? layout.height : layout.width) - (SnapshotPrintSizeMarginsMm * 2))
        const height = Math.round((isLandscape ? layout.width : layout.height) - (SnapshotPrintSizeMarginsMm * 2))
        return i18n.global.t('snapshots.map.scale.disclaimer', {
            dpi: layout.dpi,
            width,
            height,
        }) as string
    })

    defineExpose({
        disclaimerText,
        scaleRatio,
    })

</script>
<style  lang="scss">
    @import 'map-snapshot-scale-text.scss';
</style>
