<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 32 32"
                  desc="Sketch icon">
        <g clip-path="url(#clip0_1627_3264)">
            <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                  fill="#84DBFF" />
            <path d="M22.4317 13.7949H9.56836V17.4892H22.4317V13.7949Z"
                  fill="white" />
            <path d="M22.4317 13.7949H9.56836L10.2717 1.05822C12.046 0.373861 13.9787 0 16 0C18.0214 0 19.9541 0.373861 21.7284 1.05822L22.4317 13.7949Z"
                  fill="#324A5E" />
            <path d="M9.56836 17.4883C9.56836 17.4883 12.8444 19.1738 13.5858 23.1089H18.408C19.1493 19.1738 22.4254 17.4883 22.4254 17.4883H9.56836Z"
                  fill="#E6E9EE" />
            <path d="M18.4208 25.2892L13.5796 27.8999V23.1094H18.4208V25.2892Z"
                  fill="#FFD05B" />
        </g>
        <defs>
            <clipPath id="clip0_1627_3264">
                <rect width="32"
                      height="32"
                      fill="white" />
            </clipPath>
        </defs>
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwSketchHintIcon',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '30px',
            },
            height: {
                type: String,
                required: false,
                default: '16px',
            },
        },
    }
</script>
