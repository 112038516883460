<template>
    <header>
        <!-- Header - default appearance-->
        <div v-if="!renamingPage && !isSharedLinkView"
             class="walkthrough-page-container-header">
            <ow-dropdown-menu :is-button-disabled="false"
                              button-data-track="WALKTHROUGH - Page actions menu"
                              icon="$menu-more-vertical"
                              theme="icon">
                <ow-dropdown-menu-item data-track="WALKTHROUGH - Rename page"
                                       text="Rename"
                                       @click="onRenamePageClick()" />
                <ow-dropdown-menu-item data-track="WALKTHROUGH - Duplicate page"
                                       text="Duplicate"
                                       @click="$emit('duplicate-page', selectedPage)" />
                <ow-dropdown-menu-item data-track="WALKTHROUGH - Reorder pages"
                                       text="Re-order"
                                       @click="$emit('request-reorder-page-prompt', selectedPage)" />
                <ow-dropdown-menu-item :disabled="hasOnlyOnePage"
                                       data-track="WALKTHROUGH - Delete page"
                                       text="Delete"
                                       @click="$emit('delete-page', selectedPage)" />
            </ow-dropdown-menu>
            <div class="content__subheading"
                 data-test="walkthrough-page-container-header-page-name-readonly"
                 :title="selectedPageName"
                 @click="onRenamePageClick">
                {{ selectedPageName }}
            </div>
            <v-spacer />
            <v-btn :disabled="selectedPage === firstPage"
                   data-test="walkthrough-page-container-header-previous-page"
                   data-track="WALKTHROUGH - Go to previous page"
                   icon
                   variant="text"
                   @click="onPreviousClick">
                <v-icon>$chevron-left</v-icon>
            </v-btn>
            <v-btn :disabled="selectedPage === lastPage"
                   data-test="walkthrough-page-container-header-next-page"
                   data-track="WALKTHROUGH - Go to next page"
                   icon
                   variant="text"
                   @click="onNextClick">
                <v-icon>$chevron-right</v-icon>
            </v-btn>
            <v-btn color="primary"
                   data-test="walkthrough-page-container-header-add-page"
                   data-track="WALKTHROUGH - Add a new page"
                   variant="text"
                   @click="onAddNewPageClick">
                <v-icon style="margin-right:5px;">
                    $plus
                </v-icon>
                Add page
            </v-btn>
        </div>

        <!-- Header - when renaming page -->
        <div v-if="renamingPage && !isSharedLinkView"
             class="walkthrough-page-container-header">
            <v-text-field ref="renamePageInput"
                          v-model="newPageName"
                          data-test="walkthrough-page-container-header-page-name-field"
                          data-track="WALKTHROUGH - Rename page title input"
                          hide-details
                          label="Page name"
                          style="padding-left: 15px; width: 365px;"
                          @keyup.enter="onRenamePageConfirmClick" />
            <v-spacer />
            <v-btn data-track="WALKTHROUGH - Cancel renaming the page"
                   icon
                   variant="text"
                   @click="onRenamePageCancelClick">
                <v-icon>$close</v-icon>
            </v-btn>
            <v-btn data-test="walkthrough-page-container-header-save-title"
                   data-track="WALKTHROUGH - Save page name changes"
                   icon
                   variant="text"
                   @click="onRenamePageConfirmClick">
                <v-icon>$check</v-icon>
            </v-btn>
        </div>

        <!-- Header - read-only view -->
        <div v-if="isSharedLinkView">
            <div class="walkthrough-page-container-header">
                <div class="content__subheading"
                     style="padding:15px;">
                    {{ selectedPageName }}
                </div>
            </div>
            <div v-if="pages.length > 1"
                 class="d-flex flex-row justify-space-between"
                 style="padding:15px;">
                <v-btn :disabled="selectedPage === firstPage"
                       color="primary"
                       data-test="walkthrough-page-container-header-button-previous-page"
                       data-track="WALKTHROUGH - Go to previous page in client link share"
                       size="large"
                       @click="onPreviousClick">
                    Previous Page
                </v-btn>
                <v-btn :disabled="selectedPage === lastPage"
                       color="primary"
                       data-test="walkthrough-page-container-header-button-next-page"
                       data-track="WALKTHROUGH - Go to next page in client link share"
                       size="large"
                       @click="onNextClick">
                    Next Page
                </v-btn>
            </div>
        </div>
    </header>
</template>

<script lang="ts">

    import {
        mapGetters,
        mapState,
    } from 'vuex'

    import owDropdownMenu from '@/components/core/ow-dropdown-menu.vue'
    import owDropdownMenuItem from '@/components/core/ow-dropdown-menu-item.vue'
    import {
        WALKTHROUGH_GET_FIRST_PAGE,
        WALKTHROUGH_GET_LAST_PAGE,
    } from '@/store/modules/walkthrough/types'
    import WalkthroughUtils from '@/store/modules/walkthrough/walkthrough-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'WalkthroughPageContainerHeader',

        components: {
            owDropdownMenu,
            owDropdownMenuItem,
        },

        props: {
            isSharedLinkView: {
                type: Boolean,
                required: false,
            },
        },
        emits: ['duplicate-page', 'request-reorder-page-prompt', 'delete-page', 'select-page', 'add-new-page'],

        data() {
            return {
                renamingPage: false,
                newPageName: null,
            }
        },

        computed: {

            ...mapGetters({
                firstPage: WALKTHROUGH_GET_FIRST_PAGE,
                lastPage: WALKTHROUGH_GET_LAST_PAGE,
            }),

            ...mapState({
                walkthrough: state => state.walkthrough.walkthrough,
                selectedPage: state => state.walkthrough.selectedPage,
            }),

            pages() {
                return this.walkthrough?.pages
                    .sort((a, b) => a.pageNumber - b.pageNumber) ?? []
            },

            selectedPageName() {
                return WalkthroughUtils.getNameForPage(this.selectedPage)
            },

            hasOnlyOnePage() {
                return this.walkthrough?.pages?.length === 1
            },

        },

        methods: {

            onNextClick() {
                const nextPage = this.pages[this.pages.indexOf(this.selectedPage) + 1]
                this.$emit('select-page', nextPage)
            },

            onPreviousClick() {
                const previousPage = this.pages[this.pages.indexOf(this.selectedPage) - 1]
                this.$emit('select-page', previousPage)
            },

            onAddNewPageClick() {
                const newPage = {
                    name: null,
                    contentItems: [],
                    description: null,
                }

                // Add the new page.
                this.$emit('add-new-page', {
                    newPage,
                    afterPage: this.selectedPage,
                })

                // Assume it should then be selected.
                this.$emit('select-page', newPage)
            },

            onRenamePageClick() {
                this.renamingPage = true
                // Base the name on the current one.
                this.newPageName = this.selectedPageName

                // Focus and select the text.
                setTimeout(() => {
                    const inputElement = this.$refs.renamePageInput.$el.querySelector('input')
                    inputElement.select()
                }, 150)
            },

            onRenamePageConfirmClick() {
                this.selectedPage.name = this.newPageName
                if (isNullOrWhitespace(this.selectedPage.name)) {
                    // If a page name is empty, make it null.
                    this.selectedPage.name = null
                }
                this.renamingPage = false
            },

            onRenamePageCancelClick() {
                this.renamingPage = false
            },

        },
    }
</script>
<style lang="scss">
    @import 'walkthrough-page-container-header.scss';
</style>
