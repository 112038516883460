import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

const state = {
    isSharedLinkView: false,

    // Indicates that the user account is being used to access the read-only view
    // associated with a matter link share.
    isMatterLinkShareUser: false,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
