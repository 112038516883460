<template>
    <ow-checkbox :id="productName"
                 v-model="productChecked"
                 color="primary"
                 hide-details
                 :data-test="`product-checkbox-${productName.replace(' ','-').toLowerCase()}`"
                 :label="productName"
                 density="compact" />

    <section v-if="licencingActive && productName === $t('products.witness') && !witnessLicencingDisabled"
             class="pb-4 pt-1">
        <label id="section-id-label"
               class="caption-regular"
               for="section-id">
            {{ t('licencing.licenceType') }}
        </label>
        <v-select v-model="licenceType"
                  :items="licenceTypeOptions"
                  data-test="licence-type-dropdown"
                  item-value="id"
                  item-title="label"
                  variant="solo"
                  hide-details />
    </section>
</template>

<script setup lang="ts">
    import {
        computed,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import { inject as licenceProvider } from "@/composables/use-licence-controller"
    import { LicenceType } from "@/enums/licenceType"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const props = defineProps<{
        productName: string
        accountActive: boolean
        maxPremiumUsers: number
    }>()

    const { t } = useI18n()

    const productChecked = defineModel()
    const licenceType = defineModel('licenceType')

    const {active: licencingActive, licenceTypeOptionsWithoutMatterLinkShare} = licenceProvider()
    const witnessLicencingDisabled = computed(() => {
        // If the account is not active or the product is not checked, disable the licence type dropdown
        if (!props.accountActive || !productChecked.value) {
            return true
        }

        // If the licence type is None, show the dropdown to select Standard or None
        if (licenceType.value === LicenceType.None) {
            return false
        }

        // If the max premium users is 0, disable the licence type dropdown
        return props.maxPremiumUsers === 0
    })

    const licenceTypeOptions = computed(() => {
        if (isNullOrEmpty(licenceTypeOptionsWithoutMatterLinkShare.value)) {
            return []
        }

        // If the licence type is None and the max premium users is 0, only show the Standard and None options
        // This allows the user to be reactivated with a Standard licence (when premium user count is reset)
        if (licenceType.value === LicenceType.None && props.maxPremiumUsers === 0) {
            return licenceTypeOptionsWithoutMatterLinkShare.value.filter((option) => option.id === LicenceType.Standard || option.id === LicenceType.None)
        } else {
            return licenceTypeOptionsWithoutMatterLinkShare.value
        }
    })
</script>
