<template>
    <base-monitoring-preference-card :card-type="title"
                                     :card-type-description="description"
                                     :class="['preference-details-card', { 'preference-details-card__disabled': disabled }]">
        <ul class="preference-details-card__options">
            <li v-for="pref in computedItems"
                :class="{'is-selected': pref.option}">
                <div class="preference-details-card__options--details">
                    <h4 class="caption-highlight">
                        {{ pref.title }}
                    </h4>
                    <p class="caption-regular">
                        {{ pref.description }}
                    </p>
                </div>
                <ow-toggle v-model="pref.option"
                           :is-loading="isLoading"
                           :data-track="`Asset monitoring: Toggle ${pref.title}`"
                           :data-test="`toggle ${pref.title}`"
                           class="preference-details-card__toggle"
                           :disabled="disabled" />
            </li>
        </ul>
    </base-monitoring-preference-card>
</template>

<script lang="ts" setup>
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import {INotificationSettings,
            IUserEmailPreferences} from '@/api/notifications.api'
    import BaseMonitoringPreferenceCard from "@/components/asset-monitoring/monitoring-preferences/base-monitoring-preference-card.vue"
    import {IPreferenceDetailsModel} from "@/components/asset-monitoring/monitoring-preferences/preference-details-model-interface"
    import OwToggle from '@/components/core/ow-toggle.vue'

    const { t } = useI18n()

    onMounted(() => {
        setComputedItems()
    })

    const emit = defineEmits<{
        (event: 'update', value: INotificationSettings): void
    }>()

    const props = defineProps<{
        settings: INotificationSettings | IUserEmailPreferences,
        isLoading: boolean,
        preferenceProperties: Array<IPreferenceDetailsModel>,
        title: string,
        description: string,
        disabled?: boolean
    }>()

    const computedItems = ref()

    watch(() => props.preferenceProperties, () => {
        setComputedItems()
    })

    const setComputedItems = () => {
        computedItems.value = props.preferenceProperties.map((property) => {
            return {
                option: computed<boolean>({
                    get: () => props.settings[property.value],
                    set: (value: boolean) => {
                        const updated = {
                            ...props.settings,
                            [property.value]: value,
                        }
                        emit('update', updated)
                    },
                }),
                title: t(`assetMonitoring.preferences.cards.preferenceDetails.${ property.value }Title`),
                description: t(`assetMonitoring.preferences.cards.preferenceDetails.${ property.value }Description`),
            }
        })
    }

</script>

<style lang="scss" scoped>
    @import './preference-details-card';
</style>
