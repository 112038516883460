<template>
    <v-container fill-height
                 fluid
                 class="admin-settings__container">
        <v-card>
            <v-card-title class="admin-settings__title">
                <h3 v-t="'admin.messages.nightlyCharges'" />
            </v-card-title>
            <v-card-text class="admin-settings__text">
                <v-text-field v-model="testEmails.to"
                              :hint="$t('form.hint.singleEmail')"
                              :label="$t('form.label.sendTo')"
                              style="width:400px;" />
                <p class="admin-settings__charges-date">
                    {{ $t('form.label.chargesDate') }}
                </p>
                <v-date-picker v-model="testEmails.mockDate"
                               header=""
                               max-height="450"
                               color="primary" />
                <v-btn :disabled="!validTestEmailsForm"
                       :loading="testEmails.loading"
                       color="primary"
                       size="large"
                       style="margin-top:16px;"
                       @click="requestEmailTest">
                    {{ $t('action.send') }}
                </v-btn>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    import { mapActions } from 'vuex'

    import OrganisationApi from '@/api/organisations.api'
    import { USER_SHOW_POPUP } from '@/store/mutation-types'

    export default {
        name: 'AdminSettings',

        data() {
            return {
                testEmails: {
                    to: null,
                    mockDate: null,
                    loading: false,
                },

            }
        },

        computed: {
            validTestEmailsForm() {
                if (this.testEmails.to != null) {
                    if (this.testEmails.to.length > 0) {
                        if (this.testEmails.mockDate != null) {
                            return true
                        }
                    }
                }

                return false
            },

        },

        methods: {
            ...mapActions({
                showPopup: USER_SHOW_POPUP,
            }),

            async requestEmailTest() {
                const data = {
                    request: {
                        toEmail: this.testEmails.to,
                        mockDate: new Date(this.testEmails.mockDate).toISOString(),
                    },
                }

                this.loading = true
                try {
                    const response = await OrganisationApi.addEmailForNightlyChargesDigest(data)
                    if (response.ok) {
                        await this.showPopup({
                            title: 'Email request sent',
                            icon: '$info',
                            contentHTML: `<p>${ this.$t('admin.messages.emailSentSuccess') }</p>`,
                        })
                    } else {
                        await this.showPopup({
                            title: 'Error sending emails',
                            icon: '$info',
                            contentHTML: `<p>${ this.$t('admin.messages.emailSentError') }</p><p>${ response.message } (${ response.status })</p>`,
                        })
                    }
                } catch (error) {
                    await this.showPopup({
                        title: 'Error sending emails',
                        icon: '$info',
                        contentHTML: `<p>${ this.$t('admin.messages.emailSentError') }</p>`,
                    })
                }
                this.loading = false
            },

        },
    }
</script>

<style lang="scss">
    @import './admin-settings'
</style>
