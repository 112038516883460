export default {
    data() {
        return {
            windowHeight: 0,
            windowWidth: 0,
            maxPageWidth: 1264, // 1264px is the breakpoint set by Vuetify for md (tablet)
        }
    },

    created() {
        window.addEventListener('resize', this.windowResize)
        this.windowResize()
    },

    unmounted() {
        window.removeEventListener('resize', this.windowResize)
    },

    methods: {
        windowResize() {
            this.windowHeight = window.innerHeight
            this.windowWidth = window.innerWidth
        },
    },
}
