<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 24 24"
                  desc="Warning icon">
        <path d="M16.1111 2H7.88889L2 7.88889V16.2222L7.88889 22H16.2222L22.1111 16.1111V7.88889L16.1111 2ZM12 17.8889C11.2222 17.8889 10.5556 17.2222 10.5556 16.4444C10.5556 15.6667 11.2222 15 12 15C12.7778 15 13.4444 15.6667 13.4444 16.4444C13.4444 17.2222 12.7778 17.8889 12 17.8889ZM13.1111 13.1111H10.8889V6.44444H13.1111V13.1111Z"
              fill="white" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconWarning',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '24px',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
        },
    }
</script>
