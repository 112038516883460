<template>
    <section class="bulk-options-panel"
             data-test="bulk-options-panel"
             :style="componentStyle">
        <header class="bulk-options-panel__panel-header">
            <h3 v-t="'matter.selection.selectionOptions'"
                class="content__subheading" />
            <v-icon class="bulk-options-panel__panel-header--close-icon"
                    data-test="bulk-options-panel-close-btn"
                    @click="$emit('dismiss')">
                $close
            </v-icon>
        </header>
        <div class="bulk-options-panel__divider" />

        <!-- Count of selection -->
        <div class="bulk-options-panel__item bulk-options-panel__item--info caption-highlight"
             data-test="bulk-options-panel-selection-text">
            {{ $t('sketches.selection.sketchesSelected', selectedSketches.length) }}
        </div>

        <!-- Hide / show sketches -->
        <div class="bulk-options-panel__item"
             :class="{disabled: !canShowSelectedSketches}"
             data-track="SKETCHES-LIST - Bulk action show sketches"
             data-test="bulk-options-panel-show-sketches-btn"
             @click="$emit('show-selection')">
            <label>
                {{ $t('sketches.selection.showSelectedSketches', selectedSketches.length) }}
            </label>
        </div>
        <div :class="{disabled: !canHideSelectedSketches}"
             data-test="bulk-options-panel-hide-sketches-btn"
             data-track="SKETCHES-LIST - Bulk action hide sketches"
             class="bulk-options-panel__item"
             @click="$emit('hide-selection')">
            <label>
                {{ $t('sketches.selection.hideSelectedSketches', selectedSketches.length) }}
            </label>
        </div>
        <div :class="{disabled: !canShowLabels}"
             data-test="bulk-options-panel-show-labels-btn"
             data-track="SKETCHES-LIST - Bulk action show labels"
             class="bulk-options-panel__item"
             @click="$emit('show-labels')">
            <label>
                {{ $t('sketches.selection.showLabels', selectedSketches.length) }}
            </label>
        </div>
        <div :class="{disabled: !canHideLabels}"
             data-test="bulk-options-panel-hide-labels-btn"
             data-track="SKETCHES-LIST - Bulk action hide labels"
             class="bulk-options-panel__item"
             @click="$emit('hide-labels')">
            <label>
                {{ $t('sketches.selection.hideLabels', selectedSketches.length) }}
            </label>
        </div>
        <!-- Other options -->
        <div :class="{disabled: selectedSketches.length !== 1}"
             class="bulk-options-panel__item"
             data-test="bulk-options-panel-change-label-btn"
             data-track="SKETCHES-LIST - change label and description"
             @click="$emit('change-label')">
            <label v-t="'sketches.selection.changeLabelAndDescription'" />
        </div>

        <!-- Import titles -->
        <div v-if="isSketchesImportTitlesEnabled"
             :class="{disabled: !canImportTitles}"
             class="bulk-options-panel__item"
             data-test="bulk-options-panel-import-titles-btn"
             data-track="SKETCHES-LIST - import titles"
             @click="$emit('import-titles')">
            <label v-t="'sketches.selection.importTitles'" />
        </div>

        <!-- Export as -->
        <div :class="{'bulk-options-panel__item--active': showExportAsMenuValue, disabled: !canExportAs}"
             class="bulk-options-panel__item bulk-options-panel__item--with-menu"
             data-test="sketches-export-as-btn"
             @mouseenter="showExportAsMenu"
             @mouseleave="hideExportAsMenu">
            <label v-t="'matter.selection.exportAs'" />
            <ow-icon icon="ow-icon-chevron-right" />
        </div>
        <section v-if="showExportAsMenuValue"
                 class="bulk-options-panel__sub-menu bulk-options-panel__sub-menu--export-as"
                 data-test="bulk-options-panel-export-as-menu"
                 @mouseenter="showExportAsMenu"
                 @mouseleave="hideExportAsMenu">
            <div class="bulk-options-panel-group-menu">
                <ow-lock v-model="shapefileExportLocked"
                         :class="{
                             locked: shapefileExportLocked
                         }"
                         :feature-id="FeatureId.ShapefileExport"
                         :tooltip-position="OwTooltipPosition.Right"
                         class="bulk-options-panel__item"
                         hide-background>
                    <template #label>
                        <label>Shapefile (.zip)</label>
                    </template>
                    <div class="bulk-options-panel__item"
                         data-test="sketches-export-shapefile"
                         data-track="sketches-export-shapefile"
                         @click="exportAs(SketchExportFormat.Shapefile)">
                        <label>Shapefile (.zip)</label>
                    </div>
                </ow-lock>
            </div>
            <div class="bulk-options-panel-group-menu">
                <div data-track="sketches-export-geojson"
                     class="bulk-options-panel__item"
                     data-test="sketches-export-geojson"
                     @click="exportAs(SketchExportFormat.GeoJSON)">
                    <label>GeoJSON (.json)</label>
                </div>
            </div>
        </section>
        <div class="bulk-options-panel__divider"
             style="margin: 16px 16px;" />

        <div class="bulk-options-panel__item bulk-options-panel__item--danger"
             data-test="bulk-options-panel-remove-from-matter-btn"
             data-track="MAT-TITLE-LIST - Bulk actions remove sketches from matter"
             @click="$emit('delete-sketches')">
            <label>
                {{ $t('sketches.selection.deleteSketches', selectedSketches.length) }}
            </label>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        inject,
        onMounted,
        ref,
    } from 'vue'
    import { useStore } from "vuex"

    import OwIcon from '@/components/core/ow-icon.vue'
    import OwLock from "@/components/core/ow-lock.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import { inject as userProvider } from "@/composables/use-user"
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"
    import {
        SketchExportFormat,
        SketchType,
    } from '@/enums/sketches-enums'
    import { ISketch } from '@/store/modules/sketches/types/sketch'

    const { hasAccessToShapefileExport } = userProvider()
    const shapefileExportLocked = ref()

    onMounted(async () => {
        const hasAccess = await hasAccessToShapefileExport()
        shapefileExportLocked.value = !hasAccess
    })

    const store = useStore()
    interface Props {
        selectedSketches: Array<ISketch>
        anchorElementSelector?: string,
        anchorOffset?: Array<number>,
    }
    const props = defineProps<Props>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,func-call-spacing
    const emit = defineEmits<{
        (e: 'dismiss'): void
        (e: 'show-selection'): void
        (e: 'hide-selection'): void
        (e: 'change-label'): void
        (e: 'delete-sketches'): void
        (e: 'export-as', type): void
        (e: 'show-labels'): void
        (e: 'hide-labels'): void
        (e: 'import-titles'): void
    }>()

    const showExportMenuTimeout = ref(null)
    const showExportAsMenuValue = ref(false)

    const isSketchesImportTitlesEnabled = computed(() => store.state.config.featureFlags?.sketchesImportTitles ?? false)

    // Appearance and validation.
    const componentStyle = computed(() => {
        if (!props.anchorElementSelector) {
            return null
        }
        const anchorElementRect = document.querySelector(props.anchorElementSelector)?.getBoundingClientRect()
        if (!anchorElementRect) {
            return null
        }
        const left = anchorElementRect.left + anchorElementRect.width
        let style = {} as any
        if (props.anchorOffset?.length == 4) {
            style = {
                marginTop: `${ props.anchorOffset[0] }px`,
                marginRight: `${ props.anchorOffset[1] }px`,
                marginBottom: `${ props.anchorOffset[2] }px`,
                marginLeft: `${ props.anchorOffset[3] }px`,
            }
        }
        style.left = `${ left }px`
        return style
    })
    const canShowSelectedSketches = computed(() => {
        return props.selectedSketches.some(x => {
            return !x.visible
        })
    })
    const canHideSelectedSketches = computed(() => {
        return props.selectedSketches.some(x => {
            return x.visible
        })
    })
    const canShowLabels = computed(() => {
        return props.selectedSketches.some(x => {
            return !x.showLabel
        })
    })

    const canHideLabels = computed(() => {
        return props.selectedSketches.some(x => {
            return x.showLabel
        })
    })

    // Export
    const hideExportAsMenu = () => {
        showExportMenuTimeout.value = setTimeout(() => {
            showExportAsMenuValue.value = false
        }, 500)
    }
    const showExportAsMenu = () => {
        clearTimeout(showExportMenuTimeout.value)
        showExportAsMenuValue.value = true
    }
    const canExportAs = computed(() => {
        return props.selectedSketches.some(x => {
            return x.features.length > 0
        })
    })
    const canImportTitles = computed(() => {
        return props.selectedSketches.length === 1 &&
            (props.selectedSketches[0].sketchType === SketchType.Line ||
                props.selectedSketches[0].sketchType === SketchType.Area)
    })
    const exportAs = (type: SketchExportFormat) => {
        showExportAsMenuValue.value = false
        emit('export-as', type)
        emit('dismiss')
    }

</script>

<style lang="scss">
@import "sketches-options-panel.scss";
</style>
