<template>
    <v-tabs class="ow-tabs"
            v-bind="$attrs">
        <slot />
    </v-tabs>
</template>

<script lang="ts" setup>
</script>


<style lang="scss">
@import "./ow-tabs.scss";
</style>
