<template>
    <component :is="iconComponent"
               v-bind="$props" />
</template>
<script lang="ts" setup>
    import { defineAsyncComponent } from 'vue'

    interface Props {
        icon: string
    }

    const props = defineProps<Props>()

    const iconComponent = defineAsyncComponent(() =>
        import(`@/components/core/icons/${ props.icon }.vue`),
    )
</script>
