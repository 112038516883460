<template>
    <ow-modal id="title-import-modal"
              v-model="show"
              content-class="sketches-title-import-modal"
              :title="$t('sketches.titleImport')"
              :actions-padding="false">
        <div class="sketches-title-import-modal__content">
            <div v-if="showLineBuffer"
                 class="mt-3 sketches-title-import-modal__content-buffer">
                <ow-slider v-model="bufferSlider"
                           :max="500"
                           :min="1"
                           label="Line buffer in meters"
                           data-track="SKETCHES - Line buffer title import slider"
                           data-test="sketches-title-import-modal-buffer-slider"
                           :step="1"
                           force-precision-rounding
                           unit="m" />
                <ow-textfield v-model="bufferSlider"
                              :step="1"
                              data-track="SKETCHES - Line buffer title import text field"
                              data-test="sketches-title-import-modal-buffer-text-field"
                              type="number">
                    <template #iconSuffix>
                        m
                    </template>
                </ow-textfield>
            </div>
            <div v-else>
                <ow-checkbox id="import-only-within"
                             v-model="importOnlyWithinTitles"
                             :label="$t('matter.addTitlesPanel.uploadShapeFile.onlyWithin')"
                             data-test="matter-add-titles-within-checkbox"
                             style="margin-bottom: 10px" />
                <ow-tooltip activator="parent"
                            :position="OwTooltipPosition.Right">
                    {{ $t('matter.addTitlesPanel.uploadShapeFile.onlyWithinTooltip') }}
                </ow-tooltip>
            </div>
            <add-to-group v-model="selectedGroup"
                          select-data-test-attribute="sketches-add-titles-modal-add-to-group" />
        </div>
        <template #actions>
            <ow-card-actions has-secondary-button
                             no-padding
                             :primary-button-text="$t('action.import')"
                             primary-button-data-test="sketches-title-import-modal-button-done"
                             secondary-button-data-test="sketches-title-import-modal-button-cancel"
                             primary-button-data-track="sketches-title-import-modal-button-done"
                             secondary-button-data-track="sketches-title-import-modal-button-cancel"
                             @primary-button-click="handleImport"
                             @secondary-button-click="handleCancel" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    // @ts-ignore
    // eslint-disable-next-line
    import {
        computed, ref,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import OwModal from '@/components/core/ow-modal.vue'
    import OwSlider from '@/components/core/ow-slider.vue'
    import OwTextfield from "@/components/core/ow-textfield.vue"
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import AddToGroup, {GroupItem} from "@/components/matter/titles/matter-side-panel-add-titles/add-to-group.vue"
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"
    import { SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL } from '@/store/modules/sketches/types'

    const store = useStore()
    const emit = defineEmits<{
        (e: 'import', { buffer, groupId }: {
            buffer: number,
            importOnlyWithinTitles: boolean
            groupId?: number,
        }),
    }>()
    const bufferSlider = ref<number>(1)
    const selectedGroup = ref<GroupItem | undefined>()
    const importOnlyWithinTitles = ref<boolean>(false)
    interface Props {
        showLineBuffer?: boolean
    }
    const props = withDefaults(defineProps<Props>(), {
        showLineBuffer: false,
    })

    const show = computed({
        get() {
            return store.state.sketches.showImportTitlesModal ?? false
        },
        set(value) {
            store.commit(SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL, value)
        },
    })

    const handleCancel = async () => {
        show.value = false
    }

    const handleImport = async () => {
        emit('import', {
            buffer: bufferSlider.value,
            importOnlyWithinTitles: importOnlyWithinTitles.value,
            groupId: selectedGroup.value?.id,
        })
        show.value = false
    }
</script>

<style lang="scss">
@import "sketches-title-import-modal.scss";
</style>
