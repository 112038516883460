<template>
    <div ref="gridRef">
        <ow-data-grid :headers="headers"
                      :height="gridHeight"
                      :items="documents"
                      :no-data-text="$t('documents.upload.table.noData')"
                      :sort-by="sortBy"
                      class="uploaded-documents-table"
                      has-pagination
                      hover
                      item-key="id"
                      @items-selected="onItemSelection">
            <template #[`item.fileName`]="{ item }">
                <td :title="item.fileName"
                    class="body-regular uploaded-documents-table__filename">
                    <v-icon>$uploaded-file</v-icon>
                    <span class="uploaded-documents-table__filename--text">{{ item.fileName }}</span>
                </td>
            </template>
            <template #[`item.documentType`]="{ item }">
                <td class="caption-regular uploaded-documents-table__item text-no-wrap">
                    {{ documentTypeText(item) }}
                </td>
            </template>
            <template #[`item.titleNumber`]="{ item }">
                <td :class="{clickable: Boolean(item.titleNumber)}"
                    class="caption-regular uploaded-documents-table__item"
                    data-test="uploaded-documents-table-title-number"
                    @click="onTitleNumberCellClick(item)">
                    {{ item.titleNumber }}
                </td>
            </template>
            <template #[`item.updatedOn`]="{ item }">
                <td class="caption-regular uploaded-documents-table__item">
                    {{ formatDateShort(item.updatedOn) }}
                </td>
            </template>
            <template #[`item.userName`]="{ item }">
                <td :title="item.userName"
                    class="caption-regular uploaded-documents-table__item body-regular uploaded-documents-table__user">
                    {{ item.userName }}
                </td>
            </template>
            <template #[`item.status`]="{ item }">
                <td class="caption-regular uploaded-documents-table__item text-no-wrap">
                    <ow-colored-label :theme="statusLabelColour(item)"
                                      is-extra-small>
                        {{ labelText(item) }}
                    </ow-colored-label>
                    <span v-if="!isItemUploadSuccessful(item)">{{ statusText(item) }}</span>
                </td>
            </template>
            <template #[`item.actions`]="{ item }">
                <td :class="['caption-regular', 'uploaded-documents-table__item',
                             'uploaded-documents-table__actions',
                             'text-no-wrap',
                             isItemUploadSuccessful(item) ? '' : 'show-only-remove-button']">
                    <span v-if="isItemUploadSuccessful(item)">
                        <span v-if="!isDocumentAvailable(item)">{{ getDocumentStatusText(item) }}</span>
                        <a v-if="isDocumentAvailable(item)"
                           :data-track-document-id="item.id"
                           :data-track-matter-id="currentMatterId"
                           class="body-regular uploaded-documents-table__item--link"
                           data-test="uploaded-documents-table-view-doc-btn"
                           data-track="DOCUMENTS - view an uploaded file"
                           href="#"
                           @click.prevent="view(item)">
                            {{ $t('action.view') }}
                        </a>
                        <v-icon :data-track-document-id="item.id"
                                :data-track-matter-id="currentMatterId"
                                :disable="isDownloading"
                                class="body-regular uploaded-documents-table__item--icon"
                                data-test="uploaded-documents-table-download-doc-btn"
                                data-track="DOCUMENTS - download an uploaded file"
                                @click="download(item)">
                            $download
                        </v-icon>
                    </span>
                    <remove-document-button :document="item" />
                </td>
            </template>
        </ow-data-grid>
    </div>
</template>
<script lang="ts">
    import {
        PropType,
        ref,
    } from 'vue'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import RemoveDocumentButton from '@/components/documents/remove-document-button.vue'
    import useDates from '@/composables/use-dates'
    import useWindowResize from '@/composables/use-window-resize'
    import {
        uploadDocumentFailureResponses,
        UploadedDocumentStatus,
    } from '@/enums/uploaded-document-status.enum'
    import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const EVENTS = {
        downloadDoc: 'download',
        viewDoc: 'view',
        titleNumberSelected: 'title-number-selected',
        selected: 'selected-documents-changed',
    }

    export default {
        name: 'UploadedDocumentsTable',

        components: {
            OwColoredLabel,
            OwDataGrid,
            RemoveDocumentButton,
        },

        mixins: [ FlagsMixin ],

        props: {
            documents: {
                type: Array as PropType<Array<IUploadedDocument>>,
                required: true,
            },
            currentMatterId: {
                type: Number,
                required: true,
            },
            isDownloading: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        setup() {
            const gridHeight = ref('0')
            const gridRef = ref(null)
            useWindowResize({
                onResize({ clientHeight }) {
                    const copilotHeight = document.querySelector('.cta-copilot')?.clientHeight ?? 80
                    gridHeight.value = ((clientHeight - gridRef.value.getBoundingClientRect().top) - copilotHeight - 60).toString()
                },
            })
            return {
                gridHeight,
                gridRef,
            }
        },

        data() {
            return {
                selected: [],
                sortBy: ref([ { key: 'fileName', order: 'asc' } ]),
            }
        },

        computed: {
            headers() {
                return [
                    {
                        title: this.$t('documents.upload.table.headings.document'),
                        align: 'left',
                        key: 'fileName',
                    },
                    {
                        title: this.$t('table.label.type'),
                        align: 'left',
                        key: 'documentType',
                    },
                    {
                        title: this.$t('table.label.title'),
                        align: 'left',
                        key: 'titleNumber',
                    },
                    {
                        title: this.$t('documents.upload.table.headings.date'),
                        align: 'left',
                        key: 'updatedOn',
                    },
                    {
                        title: this.$t('documents.upload.table.headings.user'),
                        align: 'left',
                        key: 'userName',
                    },
                    {
                        title: this.$t('documents.upload.table.headings.status'),
                        align: 'left',
                        key: 'status',
                    },
                    {
                        title: this.$t('documents.upload.table.headings.actions'),
                        align: 'center',
                        key: 'actions',
                        sortable: false,
                    },
                ]
            },
        },

        watch: {
            selected(val) {
                this.$emit('selected-documents-changed', val)
            },
        },

        methods: {
            /**
             * Return any error text based on the status. An empty string  is returned if upload successful.
             * @param item
             */
            statusText(item: IUploadedDocument): string | null {
                switch (item.status) {
                    case UploadedDocumentStatus.duplicate: {
                        return this.$t('documents.upload.status.duplicate')
                    }

                    case UploadedDocumentStatus.failedAntiVirusCheck: {
                        return this.$t('documents.upload.status.virus')
                    }

                    case UploadedDocumentStatus.invalidFileContent: {
                        return this.$t('documents.upload.status.corrupted')
                    }

                    case UploadedDocumentStatus.invalidFileType: {
                        return this.$t('documents.upload.status.invalid')
                    }

                    case UploadedDocumentStatus.pending: {
                        return this.$t('documents.upload.status.pending')
                    }

                    case UploadedDocumentStatus.scanningFailed: {
                        return this.$t('documents.upload.status.virus')
                    }

                    case UploadedDocumentStatus.complete: {
                        return this.$t('documents.upload.status.success')
                    }

                    default: {
                        // User doesn't care about scanning, extracting/parsing/...
                        return this.$t('documents.upload.status.processing')
                    }
                }
            },

            labelText(item: IUploadedDocument): string {
                return this.isItemUploadSuccessful(item)
                    ? this.$t('documents.upload.status.success')
                    : this.$t('documents.upload.status.failed')
            },

            documentTypeText(item: IUploadedDocument): string {
                if (uploadDocumentFailureResponses.includes(item.status)) {
                    return '-'
                }

                switch (item.type) {
                    case UploadedDocumentType.TitleRegister:
                        return this.$t('documents.upload.documentType.titleRegister')
                    case UploadedDocumentType.TitlePlan:
                        return this.$t('documents.upload.documentType.titlePlan')
                    case UploadedDocumentType.OfficialCopy:
                        return this.$t('documents.upload.documentType.officialCopy')
                    case UploadedDocumentType.Other:
                        return this.$t('documents.upload.documentType.other')
                    case UploadedDocumentType.Detecting:
                        return this.$t('documents.upload.documentType.detecting')
                    case UploadedDocumentType.CautionTitle:
                        return this.$t('documents.upload.documentType.cautionTitle')
                    case UploadedDocumentType.ScotLISTitleSheet:
                        return this.$t('documents.upload.documentType.scotLISTitleSheet')
                }
            },

            isItemUploadSuccessful(item: IUploadedDocument): boolean {
                return !uploadDocumentFailureResponses.includes(item.status)
            },

            isDocumentAvailable(item: IUploadedDocument): boolean {
                return (item.type !== UploadedDocumentType.OfficialCopy) || (item.status === UploadedDocumentStatus.uploaded && (item.externalId === null || !isNullOrWhitespace(item.augmentedFilename)))
            },

            getDocumentStatusText(item: IUploadedDocument): string {
                if (uploadDocumentFailureResponses.includes(item.status)) {
                    return 'Error'
                }
                if (item.status !== UploadedDocumentStatus.uploaded) {
                    return 'Processing'
                }
                return 'Enhancing...'
            },

            statusLabelColour(item: IUploadedDocument): string {
                return this.isItemUploadSuccessful(item) ? 'success' : 'warning'
            },

            async download(item: IUploadedDocument): Promise<void> {
                this.$emit(EVENTS.downloadDoc, item)
            },

            async view(item: IUploadedDocument): Promise<void> {
                this.$emit(EVENTS.viewDoc, item)
            },

            onTitleNumberCellClick(item: IUploadedDocument) {
                if (item.titleNumber) {
                    this.$emit('title-number-selected', item.titleNumber)
                }
            },
            onItemSelection(items) {
                this.selected = this.documents.filter(({ id }) => items.includes(id))
            },

            formatDateShort(value: Date): string {
                const { formatDateShort } = useDates()
                return formatDateShort(value)
            },

        },

    }
</script>

<style lang="scss">
    @import './uploaded-documents-table';
</style>
