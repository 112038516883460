import {
    Column,
    GridBaseConfig,
    GridConfig,
    Model,
    Store,
} from '@bryntum/grid'

export const defaultGridConfig: Partial<GridBaseConfig> = ({
    features: {
        cellEdit: false,
        columnPicker: false,
        columnReorder: false,
        columnResize: false,
        columnAutoWidth: true,
        cellMenu: false,
        headerMenu: false,
        group: false,
        rowCopyPaste: false, // Allow using [Ctrl/CMD + C/X] and [Ctrl/CMD + V] to copy/cut and paste rows
        sort: false,
        stripe: true,
        cellTooltip: {
            hoverDelay: 1000,
            y: -50,
        },
    },

    animateRemovingRows: true, // Rows will slide out on removal
    autoHeight: true, // Grid needs to have a height supplied through CSS (strongly recommended) or by specifying `height`
    columnLines: true, // Themes might override it to hide lines anyway
    emptyText: 'No rows to display',
    enableTextSelection: false, // Not allowed to select text in cells by default,
    fillLastColumn: true, // By default the last column is stretched to fill the grid
    fullRowRefresh: true, // Refreshes entire row when a cell value changes
    loadMask: 'Loading...',
    resizeToFitIncludesHeader: true, // Also measure header when auto resizing columns
    rowHeight: 48, // Determined using CSS, it will measure rowHeight
    showDirty: false, // No indicator for changed cells,
    hideHeaders: false,
    transitionDuration: 100,
})

export type GridConfigColumns = (args: { on: (event: string, column: string, record: Model) => void, customArgs?: any }) => { gridColumns: Partial<Column>[]}

export interface IGridConfig {
    on?: (event: string, column: string, record: Model) => void
    customArgs?: any
    overrides?: Partial<GridBaseConfig>
    columns: GridConfigColumns,
    store: Store
}

export const useGridConfig = ({
    on,
    customArgs,
    overrides,
    columns,
    store,
}: IGridConfig) => {
    const { gridColumns } = columns({ on, customArgs })

    return {
        ...defaultGridConfig,
        ...overrides,
        store,
        columns: gridColumns,
        on,
    } as Partial<GridConfig>
}
