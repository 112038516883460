<template>
    <div :class="{ 'ow-data-grid--narrow': narrow, [`ow-data-grid--${theme}`]: true }"
         class="ow-data-grid">
        <div v-if="isCustomSelectionSlotPresent && modelValue?.length"
             class="ow-data-grid__custom-selection-header"
             data-test="ow-data-grid-custom-selection-header">
            <slot name="customSelectionHeader" />
        </div>
        <v-data-table ref="dataTable"
                      v-model="modelValue"
                      :custom-sort="customSort"
                      :density="dense ? 'compact' : 'default'"
                      :disable-pagination="!hasPagination"
                      :fixed-header="fixedHeader"
                      :group-by="groupBy"
                      :headers="headers"
                      :height="height"
                      :item-value="itemKey"
                      :items="items"
                      :items-per-page="itemsPerPage"
                      :item-selectable="itemSelectable"
                      :loading="isLoading"
                      :no-data-text="noDataText"
                      :page="activePage"
                      :show-select="allowSelection"
                      :single-select="singleSelect"
                      :select-strategy="singleSelect ? 'single' : undefined"
                      :sort-by="sortBy"
                      :hover="hover"
                      hide-default-footer
                      loading-text="Loading... Please wait"
                      mobile-breakpoint="0"
                      :row-props="colorRowItem"
                      @click:row="onRowClick"
                      @update:current-items="onCurrentItemsChange"
                      @update:options="onSortUpdate">
            <template v-for="field in Object.keys(slots)"
                      #[field]="{item}">
                <slot :item="item"
                      :name="field" />
            </template>

            <template #bottom>
                <ow-pagination v-if="isPaginationVisible"
                               :include-all="hasPaginationAllOption"
                               :result-count="items.length"
                               :disable-items-per-page-select="disableItemsPerPageSelect"
                               class="ow-data-grid__pagination"
                               @page-change="setActivePage"
                               @page-size-change="setItemsPerPage" />
            </template>
        </v-data-table>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onMounted,
        ref,
        useSlots,
        watch,
    } from 'vue'

    import OwPagination from '@/components/core/ow-pagination.vue'
    import { INITIAL_ITEMS_PER_PAGE } from '@/consts/pagination'
    import { dynamicSort,
             isNullOrEmpty } from '@/utils/array-utils'

    const props = defineProps({
        items: {
            type: Array,
            required: true,
        },
        preselectedItems: {
            type: Array,
            default: () => [],
        },
        headers: {
            type: Array,
            required: true,
        },
        itemKey: {
            type: String,
            required: false,
            default: 'id',
        },
        allowSelection: {
            type: Boolean,
            default: true,
        },
        customSort: {
            type: Function,
            required: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        narrow: {
            type: Boolean,
            default: false,
            required: false,
        },
        noDataText: {
            type: String,
            required: false,
            default: 'No data available',
        },
        hasPagination: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasPaginationAllOption: {
            type: Boolean,
            default: false,
        },
        fixedHeader: {
            type: Boolean,
            required: false,
        },
        height: {
            type: String,
            required: false,
            default: null,
        },
        isLoading: {
            type: Boolean,
            required: false,
        },
        sortBy: {
            type: Array,
            required: false,
            default: null,
        },
        hover: {
            type: Boolean,
            required: false,
        },
        singleSelect: {
            type: Boolean,
            required: false,
        },
        groupBy: {
            type: Array,
            required: false,
        },
        disableItemsPerPageSelect: {
            type: Boolean,
            required: false,
            default: false,
        },
        itemSelectable: {
            type: String,
            required: false,
        },
        isRowCheckable: {
            type: Boolean,
            required: false,
            default: false,
        },
        theme: {
            type: String,
            required: false,
            default: 'default',
            validator: (value) => {
                return ['default', 'light'].includes(value)
            },
        },
    })

    const emit = defineEmits(['items-selected', 'current-items', 'update-data-sorted', 'row-click', 'sort'])

    const modelValue = defineModel({
        default: () => [],
    })
    const activePage = ref(1)
    const itemsPerPage = ref(!props.hasPagination ? Number.MAX_SAFE_INTEGER : INITIAL_ITEMS_PER_PAGE)
    const minItemsPerPage = ref(!props.hasPagination ? Number.MAX_SAFE_INTEGER : INITIAL_ITEMS_PER_PAGE)
    const slots = useSlots()

    const isCustomSelectionSlotPresent = computed(() => Boolean(slots.customSelectionHeader))
    const isPaginationVisible = computed(() => props.hasPagination && props.items.length > minItemsPerPage.value)

    onMounted(() => {
        if (props.preselectedItems.length) {
            modelValue.value = props.preselectedItems
        }
    })

    watch(modelValue, (val) => {
        emit('items-selected', val)
    })

    watch(() => props.items?.length, () => {
        setActivePage(1)
    })

    const setActivePage = (page) => {
        activePage.value = page
    }

    const setItemsPerPage = (items) => {
        itemsPerPage.value = items
    }

    function onCurrentItemsChange(items) {
        emit('current-items', items)
    }

    const onSortUpdate = (event) => {
        const sortByEvent = event.sortBy[0]
        if (sortByEvent && !isNullOrEmpty(props.items)) {
            const keyAndOrder = event.sortBy[0].order === 'asc' ? event.sortBy[0].key : `-${ event.sortBy[0].key }`
            const sorted = props.items.sort(dynamicSort(keyAndOrder))
            emit('update-data-sorted', sorted, keyAndOrder)
        }
    }

    const clearSelection = () => {
        modelValue.value = []
    }

    const onRowClick = (event, args) => {
        if (props.isRowCheckable) {
            const index = modelValue.value.indexOf(args.item[props.itemKey])
            if (index > -1) {
                modelValue.value = modelValue.value.filter((_, i) => i !== index)
            } else {
                modelValue.value = [...modelValue.value, args.item[props.itemKey]]
            }
        } else if (props.singleSelect) {
            modelValue.value = [args.item[props.itemKey]]
        }
        emit('row-click', args.item)
    }

    const colorRowItem = (row) => {
        if (isNullOrEmpty(modelValue.value)) {
            return {}
        }
        if (modelValue.value.includes(row.item.id)) {
            return {
                'data-selected': true,
            }
        }
    }

    defineExpose({
        clearSelection,
    })
</script>

<style lang="scss">
@import './ow-data-grid.scss';
</style>