<template>
    <div :class="`${pageName}`"
         class="ow-page-container">
        <div v-if="headerTitle"
             class="page__header">
            <header class="page__header--title">
                <h1 class="content__heading">
                    <v-icon>{{ headerIcon }}</v-icon>
                    {{ headerTitle }}
                </h1>
            </header>
        </div>

        <nav v-if="hasNavigationSlotContent"
             class="page__sub-navigation">
            <slot name="navigation" />
        </nav>

        <section class="page__content">
            <slot />
        </section>
    </div>
</template>

<script lang="ts">
    export default {
        name: 'PageLayout',

        props: {
            pageName: {
                type: String,
                required: true,
            },

            headerIcon: {
                type: String,
                required: false,
            },

            headerTitle: {
                type: String,
                required: false,
            },
        },

        computed: {
            hasNavigationSlotContent(): boolean {
                return Boolean(this.$slots.navigation)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './page';
</style>
