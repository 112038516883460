<template>
    <div class="matter-title-adding-container-new">
        <h2 v-t="'matter.addTitlesPanel.heading'"
            class="matter-title-adding-container-new__title headers-h2" />
        <p v-t="'matter.addTitlesPanel.prompt'"
           class="mb-5" />
        <div class="matter-title-adding-container-new__options">
            <enter-title-numbers v-model="importMethod" />

            <upload-spreadsheet v-model="importMethod" />

            <draw-on-map v-model="importMethod" />

            <upload-shape-file v-model="importMethod" />

            <upload-title-registers v-if="isUploadTitleRegisterParsingEnabled"
                                    v-model="importMethod" />
        </div>
        <a v-t="'matter.addTitlesPanel.faq'"
           class="matter-title-adding-container-new__faq"
           href="https://intercom.help/orbital-witness/en/articles/3346774-how-do-i-add-titles-to-my-matter"
           target="_blank" />
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    import DrawOnMap from './draw-on-map.vue'
    import EnterTitleNumbers from './enter-title-numbers.vue'
    import UploadShapeFile from './upload-shape-file.vue'
    import UploadSpreadsheet from './upload-spreadsheet.vue'
    import UploadTitleRegisters from './upload-title-registers.vue'

    const store = useStore()

    const isUploadTitleRegisterParsingEnabled = computed(() => store.state.config.featureFlags?.uploadTitleRegisterParsing)

    const importMethod = ref('')

    watch(importMethod, async (val) => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'MAT-TITLE-LIST - Add titles to matter',
            metadata: {
                value: val,
            },
        })
    })
</script>

<style lang="scss">
    @import 'matter-side-panel-add-titles';
</style>
