<template>
    <div v-show="showPopup"
         id="searchPopupContainer"
         class="ol-popup"
         data-test="map-search-popup">
        <a class="ol-popup-closer"
           @click="setPopupVisibility(false)" />
        <strong v-dompurify-html="popupHtml" />
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import {
        SEARCH_MUTATE_SHOW_POPUP,
    } from '@/store/modules/search/types'

    export default {
        name: 'AddressPopup',

        data() {
            return {}
        },

        computed: {
            ...mapState('search', {
                popupHtml: (state) => state.popupHTML,
                showPopup: (state) => state.showPopup,
            }),
        },

        methods: {
            ...mapMutations('search', {
                setPopupVisibility: SEARCH_MUTATE_SHOW_POPUP,
            }),
        },
    }
</script>
