<template>
    <title-panel-card divider>
        <div v-if="loading"
             class="d-flex flex-column gr-2">
            <div v-for="n in 3"
                 :key="n">
                <ow-loading-skeleton data-test="title-details-panel-loading" />
            </div>
        </div>
        <template v-else>
            <copies-filed-document-table :documents="documents"
                                         :is-full-screen="isFullScreen"
                                         :is-ordering-allowed="isOrderingAllowed"
                                         :official-copies-availability="selectedTitle?.officialCopiesAvailability"
                                         :selected-title-number="selectedTitleNumber"
                                         :single-document-heap-metadata="singleDocumentHeapMetadata"
                                         data-test-attribute="copies-filed-table-matched-documents"
                                         @retry-official-copies="retryOfficialCopies"
                                         @order-documents="updateCopiesFiledOrderRequests" />

            <copies-filed-document-table v-if="unMatchedOcdaDocument.length > 0"
                                         :documents="unMatchedOcdaDocument"
                                         :is-full-screen="isFullScreen"
                                         :is-ordering-allowed="false"
                                         :is-previously-ordered-document-view="true"
                                         :selected-title-number="selectedTitleNumber"
                                         :single-document-heap-metadata="singleDocumentHeapMetadata"
                                         data-test-attribute="copies-filed-table-unmatched-documents"
                                         @order-documents="updateCopiesFiledOrderRequests" />
        </template>
    </title-panel-card>

    <copies-filed-popup :order-details="orderDetails"
                        :show="showOrderConfirmationPopup"
                        @close-popup="showOrderConfirmationPopup = false"
                        @order-docs="orderDocuments" />
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import TitlePanelCard from "@/components/title-panel/v2/cards/title-panel-card.vue"
    import CopiesFiledDocumentTable from '@/components/title-panel/v2/copies-filed-tab/copies-filed-document-table.vue'
    import CopiesFiledPopup from '@/components/title-panel/v2/copies-filed-tab/copies-filed-popup.vue'
    import { useSelectedTitle } from "@/composables/use-selected-title"
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import { MUTATE_DOCUMENT_ORDER_ERROR } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import { TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_SHOW_POPUP,
    } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { hasDocumentBeenOrdered } from '@/utils/document-utils'

    const props = defineProps<{
        isFullScreen: boolean,
        isOrderingAllowed: boolean,
        isLoading: boolean,
    }>()

    const store = useStore()

    const showOrderConfirmationPopup = ref(false)
    const orderDetails = ref({})
    const documentsToOrderRequests = ref([])

    const { selectedTitleNumber, selectedTitle, isRegisterPurchased, officialCopiesAvailability } = useSelectedTitle()
    const currentMatterId = computed<number>(() => parseInt(store.state.matter.currentMatter.id))
    const orderErrors = computed(() => store.state.documents.orderErrors)
    const titleSummaryCharge = computed(() => store.state.user.titleSummaryCharge)

    const documents = computed(() => {
        if (!selectedTitle.value.officialCopiesAvailability?.results?.referredToDocuments) {
            return []
        }
        return selectedTitle.value.officialCopiesAvailability?.results?.referredToDocuments
            .filter((doc) => doc.matchedAgainstOcda === true) ?? []
    })

    const unMatchedOcdaDocument = computed(() => {
        if (!selectedTitle.value.officialCopiesAvailability?.results?.referredToDocuments) {
            return []
        }
        return selectedTitle.value.officialCopiesAvailability?.results?.referredToDocuments
            .filter((doc) => hasDocumentBeenOrdered(doc) && doc.matchedAgainstOcda === false && doc.documentType !== '') ?? []
    })

    const userHasVtsCharge = computed(() => {
        return titleSummaryCharge.value !== null
    })

    const loading = computed(() => {
        return props.isLoading || selectedTitle.value.officialCopiesAvailability?.loading
    })

    const singleDocumentHeapMetadata = computed(() => {
        return {
            type: 'DOC-ORDERING - Order copies filed document',
            metadata: {
                matterId: currentMatterId.value,
                hasOrderedRegister: isRegisterPurchased.value,
            },
        }
    })

    const retryOfficialCopies = async () => {
        await store.dispatch(TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY, {
            titleNumber: selectedTitleNumber.value,
            matterId: currentMatterId.value,
        })
    }

    const createOrderRequestObject = (docsToOrder) => {
        if (isNullOrEmpty(docsToOrder)) {
            return {}
        }

        const countOfDocsToOrder = docsToOrder.length
        return {
            countOfDocsToOrder,
            totalCost: countOfDocsToOrder * titleSummaryCharge.value,
        }
    }

    /**
     * Order the documents.
     * @param data {Array}
     */
    const updateCopiesFiledOrderRequests = (data) => {
        orderDetails.value = createOrderRequestObject(data.documents)
        documentsToOrderRequests.value = data.documents.map(document =>
            DocumentOrderRequest.createCopiesFiledRequest(selectedTitleNumber.value, currentMatterId.value, document),
        )
        showOrderConfirmationPopup.value = true
        let heapTypeSuffix = ''
        if (data.isSelectedDocuments) {
            heapTypeSuffix = 'Order selected copies filed documents'
        }
        if (data.isAllDocuments) {
            heapTypeSuffix = 'Order all copies filed documents'
        }
        if (data.isDocumentsInGroup) {
            heapTypeSuffix = 'Order all copies filed documents in group'
        }
        store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: `TITLE-DETAILS-PANEL - ${ heapTypeSuffix }`,
            metadata: {
                hasOrderedRegister: isRegisterPurchased.value,
                matterId: currentMatterId.value,
            },
        })
    }

    /**
     * Actually orders the documents in the previously set request.
     * Shows any errors that may have arisen during the ordering process.
     * @see documentOrderRequest
     */
    const orderDocuments = async () => {
        await store.dispatch(MATTER_ADD_TITLE, selectedTitleNumber.value)
        await store.dispatch(`documentOrdering/${ ORDER_DOCUMENTS }`, documentsToOrderRequests.value)
        orderDetails.value = {}
        documentsToOrderRequests.value = []

        if (!isNullOrEmpty(orderErrors.value)) {
            await store.dispatch(USER_SHOW_POPUP, {
                title: 'Order Documents',
                icon: '$info',
                contentHTML: orderErrors.value.join(''),
            })
            store.commit(MUTATE_DOCUMENT_ORDER_ERROR, null)
        }
    }

</script>

<style lang="scss"
       scoped>
@import './copies-filed-tab';
</style>
