import VueCookies from 'vue3-cookies'

import MatterHubPlugin from '@/plugins/matter-hub'
import OrganisationHubPlugin from '@/plugins/organisation-hub'
import drag from 'v-drag'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import HotkeyPlugin from 'v-hotkey3'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import apolloProvider from '@/graphql/apollo'
import { createPinia } from 'pinia'

export const VueDOMPurifyHTMLOptions = {
    default: {
        USE_PROFILES: {
            html: true,
        },
        ADD_ATTR: ['target'],
    },
    namedConfigurations: {
        svg: {
            USE_PROFILES: {
                svg: true,
                svgFilters: true,
                html: false,
            },
        },
    },
}

const pinia = createPinia()

export const initPlugins = (app: any) => {
    app.use(HotkeyPlugin)
    app.use(MatterHubPlugin)
    app.use(OrganisationHubPlugin)
    app.use(VueCookies)
    app.use(drag, {})
    app.use(VueDOMPurifyHTML, VueDOMPurifyHTMLOptions)
    app.use(i18n)
    app.use(vuetify)
    app.use(apolloProvider)
    app.use(pinia)
}
