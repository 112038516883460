<template>
    <div ref="owButtonMenu"
         class="ow-button-menu">
        <v-menu :model-value="modelValue"
                :close-on-content-click="closeOnContentClick"
                :persistent="persistent"
                @update:model-value="$emit('update:modelValue', $event)">
            <template #activator="{ props: activatorProps }">
                <ow-button v-bind="mergedProps(activatorProps)"
                           :class="{
                               'ow-button-menu--active': modelValue,
                           }"
                           :data-test="buttonDataTestAttribute"
                           :data-track="buttonDataTrackAttribute"
                           :full-height="fullHeight"
                           :full-width="fullWidth"
                           :is-borderless="isBorderless"
                           :is-primary="isPrimary"
                           :is-secondary="isSecondary"
                           :small="small"
                           :disabled="disabled">
                    {{ buttonText }}
                    <template #iconSuffix>
                        <v-icon class="ow-button-menu__open-icon">
                            {{ modelValue ? '$menu-up' : '$menu-down' }}
                        </v-icon>
                    </template>
                </ow-button>
            </template>
            <div class="ow-button-menu__container">
                <div class="ow-button-menu__links">
                    <slot name="links" />
                </div>

                <hr v-if="hasActionsContent"
                    class="ow-button-menu__divider" />

                <div v-if="hasActionsContent"
                     class="ow-button-menu__actions">
                    <slot name="actions" />
                </div>
            </div>
        </v-menu>
    </div>
</template>

<script lang="ts">
    import { mergeProps } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'

    export default {
        name: 'OwButtonMenu',

        components: {
            OwButton,
        },

        props: {
            modelValue: {
                type: Boolean,
                required: true,
            },
            // These button properties are just bound to the ow-button and not specifically used.
            // eslint-disable-next-line vue/no-unused-properties
            isBorderless: {
                type: Boolean,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            isPrimary: {
                type: Boolean,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            isSecondary: {
                type: Boolean,
                default: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            disabled: {
                type: Boolean,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            small: {
                type: Boolean,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            fullWidth: {
                type: Boolean,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            fullHeight: {
                type: Boolean,
                required: false,
            },
            buttonText: {
                type: String,
                required: true,
            },
            buttonDataTestAttribute: {
                type: String,
                required: false,
                default: 'ow-button-menu-the-button',
            },
            buttonDataTrackAttribute: {
                type: String,
                required: false,
                default: 'Ow Button Menu - The button',
            },
            persistent: {
                type: Boolean,
                required: false,
                default: false,
            },
            closeOnContentClick: {
                type: Boolean,
                required: false,
                default: true,
            },
        },

        emits: [
            'update:modelValue',
        ],

        computed: {
            hasActionsContent(): boolean {
                return Boolean(this.$slots.actions)
            },
        },

        methods: {
            mergedProps(activatorProps): Record<string, unknown> {
                return mergeProps(this.$props, activatorProps)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
@import './ow-button-menu';
</style>
