<template>
    <div class="feature-toggle">
        <label v-if="leftLabel">{{ leftLabel }}</label>
        <ow-toggle v-model="isEnabled"
                   :data-test="dataTest"
                   :data-track="dataTrack"
                   :class="toggleClass"
                   :is-loading="isLoading"
                   :label="rightLabel" />
    </div>
</template>
<script lang="ts" setup>

    import { computed,
             onMounted,
             ref } from 'vue'

    import FeaturesApi from '@/api/features-api'

    import OwToggle from './ow-toggle.vue'

    const props = defineProps<{
        dataTest?: string,
        dataTrack?: string,
        toggleClass?: string,
        leftLabel?: string,
        rightLabel?: string
        featureKey?: string,
        featureEnabled?: boolean,
    }>()

    const isEnabledInternal = ref<boolean>(false)
    const isLoading = ref<boolean>(false)

    const isEnabled = computed<boolean>({
        get() {
            return isEnabledInternal.value
        },
        async set(value) {
            isEnabledInternal.value = value
            isLoading.value = true
            value
                ? FeaturesApi.enableFeature(props.featureKey)
                : FeaturesApi.disableFeature(props.featureKey)
            isLoading.value = false
        },
    })

    onMounted(() => {
        isEnabledInternal.value = props.featureEnabled
    })

</script>

<style lang="scss">
    @import './ow-feature-toggle.scss';
</style>