<template>
    <ow-page-layout class="matters-create"
                    page-name="matters-create">
        <div class="matters-create__content">
            <header class="matters-create__content--header">
                <h1 class="content--heading">
                    Create a matter
                </h1>
                <p v-if="hasTitlesToAdd"
                   data-test="matters-create-titles-to-add-text">
                    {{ titlesToAddText }}
                </p>
                <p v-if="step === 1">
                    A matter is used to organise the work you do in Orbital Witness and for your organisation
                    to correctly allocate any associated charges.
                </p>
                <p v-else>
                    You can collaborate in the matter with other users in your organisation. Choose how you
                    would like to share the matter internally. You can also change these settings later.
                </p>
            </header>
            <v-form ref="form"
                    :disabled="loading"
                    data-test="matter-create-form"
                    @submit.prevent="createMatter">
                <div v-if="step === 1"
                     class="matters-create__details">
                    <!-- Matter & client code-->
                    <matter-client-code-inputs ref="clientCodeInputs"
                                               :client-code="newMatter.clientCode"
                                               :matter-code="newMatter.code"
                                               client-code-data-test-attribute="matters-create-client-code"
                                               matter-code-data-test-attribute="matter-create-code"
                                               @change="onMatterClientCodeChanged" />

                    <!-- Matter name-->
                    <fieldset>
                        <v-text-field v-model="newMatter.name"
                                      :maxlength="matterNameMaxLength"
                                      :rules="[rules.matterName]"
                                      class="matters-create__fieldset--name"
                                      counter
                                      variant="underlined"
                                      data-test="matter-create-name"
                                      label="Matter name"
                                      required />
                    </fieldset>

                    <!-- Matter type-->
                    <fieldset>
                        <v-select v-model="newMatter.type"
                                  :items="types"
                                  :rules="[rules.type]"
                                  :clearable="true"
                                  variant="underlined"
                                  data-test="matter-create-type"
                                  hide-details
                                  label="Transaction type"
                                  class="matters-create__fieldset--transaction"
                                  style="margin-bottom: 36px" />
                    </fieldset>

                    <!-- Actions -->
                    <div class="d-flex justify-space-between"
                         style="width:100%"
                         @click="$refs.form.validate()">
                        <v-btn data-test="matter-create-cancel"
                               size="large"
                               variant="outlined"
                               @click="cancel">
                            Cancel
                        </v-btn>
                        <v-btn :disabled="!isValid || loading || hasError"
                               :loading="loading"
                               color="primary"
                               data-test="matter-create-next-button"
                               size="large"
                               @click="goToMatterSharingStep">
                            Next
                        </v-btn>
                    </div>
                </div>
                <div v-else>
                    <permissions :current-user-id="currentUser.id"
                                 :is-new-matter="true"
                                 :loading="isCreateAndShareSubmitting"
                                 :permissions="permissions"
                                 data-test="matter-permissions"
                                 @cancel="cancel"
                                 @update="createAndShareMatter" />
                    <div v-if="isMatterCreateErrorState"
                         class="error-text">
                        Unexpected error happened. Please refresh the page and try again.
                    </div>
                </div>
            </v-form>
        </div>
    </ow-page-layout>
</template>

<script lang="ts">
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OrganisationApi from '@/api/organisations.api'
    import OwPageLayout from '@/components/core/layout/page.vue'
    import MatterClientCodeInputs from '@/components/matter/matter-client-code-inputs.vue'
    import Permissions from '@/components/matter/settings/permissions.vue'
    import { MATTER_MAX_NAME_LENGTH } from '@/consts/matter'
    import { Route } from '@/enums/route.enum'
    import {
        MATTER_ADD_MULTIPLE_TITLES,
        MATTER_CREATE,
        MATTER_GET_TYPES,
        MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE,
        MATTER_UPDATE_PERMISSIONS,
    } from '@/store/modules/matter/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'MattersCreate',

        components: {
            Permissions,
            MatterClientCodeInputs,
            OwPageLayout,
        },

        beforeRouteEnter(_, from, next) {
            next((vm: any) => {
                vm.createMatterEntryPoint = from.name
            })
        },

        data() {
            return {
                loading: false,
                newMatter: {
                    name: null,
                    code: null,
                    clientCode: null,
                    type: null,
                },
                isClientCodeInputsValid: false,
                createMatterEntryPoint: Route.MattersList,
                isMatterCreateErrorState: false,
                matterNameMaxLength: MATTER_MAX_NAME_LENGTH,
                rules: {
                    matterName: value => {
                        const hasName = !isNullOrWhitespace(value)
                        const nameLengthIsValid = value?.length <= MATTER_MAX_NAME_LENGTH
                        if (hasName && nameLengthIsValid) {
                            return true
                        } else if (!hasName) {
                            return 'Required'
                        }
                        return 'Name is too long'
                    },
                    type: (value) => Boolean(value) || 'Required',
                },
                isCreateAndShareSubmitting: false,
                step: 1,
                permissions: [],
                hasError: false,
            }
        },

        computed: {

            ...mapGetters({
                types: MATTER_GET_TYPES,
            }),

            ...mapState({
                currentUser: (state: any) => state.user,
                titlesToAddAfterMatterCreate: (state: any) => state.matter.titlesToAddAfterMatterCreate,
            }),

            hasTitlesToAdd(): boolean {
                return Boolean(this.titlesToAddAfterMatterCreate?.length)
            },

            titlesToAddText(): string | null {
                if (this.titlesToAddAfterMatterCreate?.length === 1) {
                    return `Your matter will be created with Title ${ this.titlesToAddAfterMatterCreate[0] }.`
                }
                if (this.titlesToAddAfterMatterCreate?.length > 1) {
                    return `Your matter will be created with ${ this.titlesToAddAfterMatterCreate.length } Titles.`
                }
                return null
            },

            isValid(): boolean {
                if (isNullOrWhitespace(this.newMatter.name)) {
                    return false
                }

                if (isNullOrWhitespace(this.newMatter.type)) {
                    return false
                }

                return this.isClientCodeInputsValid
            },
        },

        watch: {
            '$route.hash'() {
                switch (this.$route.hash) {
                    case '#details':
                        this.step = 1
                        break
                }
            },

            $route(to, from) {
                if (to.name !== Route.MattersCreate && from.name === Route.MattersCreate) {
                    this.setTitlesToAddAfterMatterCreate(null)
                    this.reset()
                }
            },
        },

        methods: {
            ...mapActions({
                createMatterAction: MATTER_CREATE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                updatePermissions: MATTER_UPDATE_PERMISSIONS,
                matterAddMultipleTitles: MATTER_ADD_MULTIPLE_TITLES,
            }),

            ...mapMutations({
                setTitlesToAddAfterMatterCreate: MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE,
            }),

            async createMatter(): Promise<void> {
                const matter = {
                    name: this.newMatter.name,
                    code: this.newMatter.code,
                    clientCode: this.newMatter.clientCode,
                    type: this.newMatter.type,
                    isCurrent: true,
                }

                this.loading = true
                let newMatter
                try {
                    newMatter = await this.createMatterAction(matter)
                } catch (e) {
                    console.error(e)
                    this.isMatterCreateErrorState = true
                    this.loading = false
                    this.reset()
                    return null
                }
                this.loading = false

                this.logHeapEvent({
                    type: 'MAT-CREATE - Matter created type',
                    metadata: {
                        matterId: newMatter.id,
                        type: this.newMatter.type,
                        isUsingClientCode: !isNullOrWhitespace(this.newMatter.clientCode),
                    },
                })

                return newMatter
            },

            cancel(): void {
                this.reset()
                this.$router.push({ name: this.createMatterEntryPoint })
            },

            async goToMatterSharingStep(): Promise<void> {
                this.step = 2
                await this.$router.push({
                    name: Route.MattersCreate,
                    hash: '#sharing',
                })
                await this.getPermissions()
            },

            async getPermissions(): Promise<void> {
                const response = await OrganisationApi.getOrganisationUserListSummary()
                if (response.ok) {
                    const users = response.data.data
                    this.permissions = users
                        .filter(user => user.isActive === true)
                        .map(user => {
                            return {
                                userId: user.id,
                                userName: `${ user.firstName } ${ user.lastName }`,
                                isOwner: false,
                                shared: false,
                            }
                        })
                        .sort((a, b) => a.userName.localeCompare(b.userName))
                }
            },

            async createAndShareMatter(permissionsData: any): Promise<void> {
                this.isCreateAndShareSubmitting = true
                let titlesToAddAfterMatterCreate = null

                const newMatter = await this.createMatter()
                if (!newMatter) {
                    this.isCreateAndShareSubmitting = false
                    return
                }

                await this.setPermissions(newMatter.id, permissionsData)

                if (this.titlesToAddAfterMatterCreate) {
                    titlesToAddAfterMatterCreate = this.titlesToAddAfterMatterCreate
                    await this.matterAddMultipleTitles({
                        matterId: newMatter.id,
                        titleNumbers: this.titlesToAddAfterMatterCreate,
                    })
                    this.setTitlesToAddAfterMatterCreate(null)
                }

                this.logHeapEvent({
                    type: 'MAT-CREATE - Matter permissions',
                    metadata: {
                        shareType: permissionsData.shareType,
                    },
                })

                await this.$router.push({
                    name: titlesToAddAfterMatterCreate ? Route.MatterMapTitle : Route.MatterMap,
                    params: {
                        titleNumber: titlesToAddAfterMatterCreate ? titlesToAddAfterMatterCreate[0] : undefined,
                        matterId: newMatter.id,
                    },
                })
                this.isCreateAndShareSubmitting = false
            },

            async setPermissions(matterId, permissionsData) {
                if (permissionsData.shareType !== 'none') {
                    await this.updatePermissions({
                        matterId,
                        shareWithOrganisation: permissionsData.shareType === 'all',
                        userIds: permissionsData.userIds,
                    })
                }
            },

            reset(): void {
                this.newMatter = {
                    name: null,
                    code: null,
                    clientCode: null,
                    type: null,
                },
                this.hasError = false,
                this.step = 1
            },

            onMatterClientCodeChanged(matterClientCode: {
                clientCode: string | null,
                matterCode: string | null,
                matterCodeError: boolean
            } | null): void {
                this.isClientCodeInputsValid = matterClientCode !== null
                this.newMatter.clientCode = matterClientCode?.clientCode ?? null
                this.newMatter.code = matterClientCode?.matterCode ?? null
                this.hasError = matterClientCode?.matterCodeError ?? false
            },
        },
    }
</script>
<style lang="scss">
    @import './matters-create';
</style>
