import {
    ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER,
    ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER_AND_STATUS,
} from '@/store/modules/organisation-hub/types'
import {
    TrackedTitle,
    TrackedTitleListener,
} from '@/interfaces/store/organisation-hub/tracked-title'
import { IOrganisationHubState } from '@/interfaces/store/organisation-hub/organisation-hub-state.interface'

import { DocumentOrderStatus } from '@/consts/document-order-status'
import { IOrganisationHubMessage } from '@/interfaces/store/organisation-hub/organisation-hub-message.interface'

export default {
    [ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER]: (state: IOrganisationHubState) => (listener: TrackedTitleListener) => {
        const titles = state.trackedTitles.filter((trackedTitle: TrackedTitle) => trackedTitle.listeners.includes(listener))
        return titles
    },

    [ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER_AND_STATUS]: (state: IOrganisationHubState) =>
        (listener: TrackedTitleListener, status: DocumentOrderStatus) => {
            return state.trackedTitles.filter((trackedTitle: TrackedTitle) =>
                trackedTitle.listeners.includes(listener) &&
                trackedTitle.updates.some((update: IOrganisationHubMessage) => update.message.status === status))
        },
}
