<template>
    <div class="map-north-arrow"
         data-test="map-north-arrow"
         :style="style">
        <ow-icon-north-arrow />
    </div>
</template>
<script lang="ts" setup>
    import OlMap from 'ol/Map'
    import {
        onMounted,
        ref,
        watch,
    } from 'vue'

    import OwIconNorthArrow from '@/components/core/icons/ow-icon-north-arrow.vue'

    interface Props {
        targetMap?: OlMap
    }
    const props = defineProps<Props>()
    const style = ref<Partial<CSSStyleDeclaration>>({ transform: 'none' })

    onMounted(() => {
        if (props.targetMap) {
            addMapEventListener()
        }
    })

    const addMapEventListener = () => {
        props.targetMap.getView().addEventListener('change:rotation', onMapRotationChange)
    }

    const onMapRotationChange = (e) => {
        const rotation = e.target.getRotation()
        style.value.transform = `rotate(${ rotation }rad)`
    }

    watch(() => props.targetMap, (newValue) => {
        if (newValue) {
            addMapEventListener()
        }
    })

    defineExpose({
        onMapRotationChange,
        style,
    })

</script>
<style lang="scss">
    @import 'map-north-arrow.scss';
</style>
