import {
    DocumentOrderStatus,
    getDocumentOrderStatusText,
} from '@/consts/document-order-status'
import {
    generateOrderKeyValueForOC2Document,
    isDocumentDownloadable,
    isDocumentOrderable,
    isDocumentOrderFailure,
} from '@/utils/document-ordering-utils'
import { DocumentCode } from '@/consts/document-code'
import httpClient from '@/api/http-client'
import { StatusCode } from '@/consts/status-code'

export default class CopiesAvailabilityApi {
    static controller?: AbortController
    static sharedController?: AbortController

    static END_POINT = 'title'

    /**
     * Get all the copies files documents from the server for a title.
     */
    static officialCopiesAvailability(titleNumber: string, matterId?: string, abortController?: AbortController): Promise<any> {
        let resultUrl = `${ this.END_POINT }/${ titleNumber }/official-copies`
        if (matterId) {
            resultUrl += `?matterId=${ matterId }`
        }

        if (!abortController) {
            // If an abort controller is not provided, use the global one.
            if (this.controller) {
                this.controller.abort()
            }

            this.controller = new AbortController()
            abortController = this.controller
        }

        return httpClient
            .get(resultUrl, {
                signal: abortController.signal,
            })
            .then(response => {
                if (response) { // when cancelled response can be undefined?
                    if (response?.status === StatusCode.TOO_MANY_REQUESTS) {
                        return {
                            ok: false,
                            handleHmlrLimitError: true,
                        }
                    }

                    // Add properties not yet assigned neatly by the API.
                    // Some of these checks e.g. for 'orderAvailable' are to accommodate legacy data.
                    // TODO: Assign these properties on the API and remove all this.
                    response.data.titleRegister.failedOrder = isDocumentOrderFailure(response.data.titleRegister.status)
                    response.data.titleRegister.loadingOrder = response.data.titleRegister.status === DocumentOrderStatus.ORDERING
                    response.data.referredToDocuments.forEach(item => {
                        item.documentTypeField = DocumentCode[item.documentTypeValue]
                        item.filedUnderField = item.filedUnder
                        item.loadingOrder = item.orderStatus === DocumentOrderStatus.ORDERING
                        item.keyValue = generateOrderKeyValueForOC2Document(titleNumber, item.filedUnder, item.documentDate, item.entryNumbers)
                        item.orderStatusText = item.currentVersionHasBeenSentByPost
                            ? getDocumentOrderStatusText(DocumentOrderStatus.SENT_IN_POST)
                            : getDocumentOrderStatusText(item.orderStatus)
                        item.downloadAvailable = isDocumentDownloadable(item.orderStatus) &&
                            item.currentVersionHasBeenSentByPost === false
                        item.viewAvailable = item.augmentedFilename !== null &&
                            item.currentVersionHasBeenSentByPost === false ||
                            (item.filename !== null && item.orderStatus === DocumentOrderStatus.AUGMENTATION_FAILED)
                        item.orderAvailable = isDocumentOrderable(item.orderStatus, item.hmlrAvailabilityCode) &&
                            item.documentId === null &&
                            item.orderStatus !== DocumentOrderStatus.UNAVAILABLE
                    })

                    return response.data
                }
            })
    }

    /**
     * Get all copies files documents for multiple titles.
     */
    static officialCopiesAvailabilities(titleNumbers: string[], matterId?: string, abortController?: AbortController): Promise<any[]> {
        if (!abortController) {
            if (this.sharedController) {
                this.sharedController.abort()
            }

            this.sharedController = new AbortController()
            abortController = this.sharedController
        }

        const officialCopiesAvailabilityPromises = titleNumbers.map(titleNumber => this.officialCopiesAvailability(titleNumber, matterId, new AbortController()))

        return Promise
            .all(officialCopiesAvailabilityPromises)
            .then(response => response.includes(undefined) ? [] : response)
            .finally(() => {
                this.sharedController = null
            })
    }

    /**
     * Aborts all ongoing requests
     */
    static abortAllRequests() {
        if (this.controller) {
            this.controller.abort()
        }

        if (this.sharedController) {
            this.sharedController.abort()
        }
    }
}
