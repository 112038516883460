<template>
    <teleport to="body">
        <ow-modal v-model="show"
                  content-class="dialog-group"
                  :title="$t('sketches.nameDescriptionPrompt.title')">
            <div class="dialog-group__content">
                <ow-textfield ref="name"
                              v-model="name"
                              enter
                              data-test="sketches-name-description-modal-name"
                              :label="$t('sketches.nameDescriptionPrompt.name').toString()"
                              @enter="onEnterKey"
                              @input="handleInput" />
                <ow-textfield v-model="description"
                              data-test="sketches-name-description-modal-description"
                              :label="$t('sketches.nameDescriptionPrompt.description').toString()"
                              @input="handleInput"
                              @enter="onEnterKey" />
            </div>
            <template #actions>
                <div class="dialog-group__actions">
                    <ow-button data-test="sketches-name-description-modal-button-cancel"
                               @click="handleCancel">
                        {{ $t('action.cancel') }}
                    </ow-button>
                    <ow-button data-test="sketches-name-description-modal-button-done"
                               is-primary
                               :disabled="!valid"
                               @click="handleDone">
                        {{ $t('action.done') }}
                    </ow-button>
                </div>
            </template>
        </ow-modal>
    </teleport>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL,
        SKETCHES_SET_CURRENT_SKETCH_STYLE,
        SKETCHES_UPDATE_SKETCHES,
    } from '@/store/modules/sketches/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'
    // @ts-ignore

    export default {
        name: 'SketchesNameDescriptionModal',
        components: {
            OwButton,
            OwModal,
            OwTextfield,
        },
        data() {
            return {
                name: null,
                description: null,
                originalName: null,
                originalDescription: null,
            }
        },
        computed: {
            ...mapState({
                showNameDescriptionModal: (state: IState) => state.sketches.showNameDescriptionModal,
                sketch: (state: IState) => state.sketches.nameDescriptionModalSketch ?? state.sketches.currentSketch,
            }),
            show: {
                get() {
                    return this.showNameDescriptionModal
                },
                set(value) {
                    this.setShowNameDescriptionModal({ show: value })
                    this.$refs.name?.content?.focus()
                },
            },
            valid() {
                return !isNullOrWhitespace(this.name)
            },
        },
        watch: {
            async sketch(val) {
                this.name = val?.name ?? null
                this.description = val?.comment ?? null
                await this.$nextTick()
                this.$refs.name?.$refs.content?.focus()
                this.$refs.name?.$refs?.content?.select()
            },

            async show(val) {
                if (val) {
                    this.originalName = this.name
                    this.originalDescription = this.description

                    await this.$nextTick()
                    this.$refs.name?.$refs.content?.focus()
                    this.$refs.name?.$refs?.content?.select()
                }
            },
        },
        created() {
            if (this.sketch) {
                this.name = this.sketch.name
                this.description = this.sketch.comment

                this.originalName = this.name
                this.originalDescription = this.description
            }
        },
        methods: {
            ...mapActions({
                updateSketches: SKETCHES_UPDATE_SKETCHES,
                updateSketchStyle: SKETCHES_SET_CURRENT_SKETCH_STYLE,
            }),
            ...mapMutations({
                setShowNameDescriptionModal: SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL,
            }),

            async handleCancel() {
                this.sketch.name = this.originalName
                this.sketch.comment = this.originalDescription
                await this.updateSketches([this.sketch])
                await this.updateSketchStyle(this.sketch)
                this.show = false
            },

            async handleDone() {
                this.show = false
            },

            async handleInput() {
                this.sketch.name = this.name
                this.sketch.comment = this.description
                await this.updateSketches([this.sketch])
            },

            onEnterKey() {
                if (this.valid) {
                    this.handleDone()
                }
            },
        },
    }
</script>
