import qs from 'qs'

import httpClient from '@/api/http-client'
import { StatusCode } from '@/consts/status-code'

export default class MatterLinkShareApi {
    static END_POINT = 'matters'

    /**
     * Get metadata associated with the shareable link if avialable
     * for the given matter.
     * @param matterId {Number} The ID of the matter.
     * @returns {Promise<T | *>}
     */
    static getLinkShare(matterId) {
        const uri = `matters/${ matterId }/linkshare`
        return httpClient
            .get(uri, {}, false)
    }

    /**
     * Determine if an OW user can access a link share associated with a matter
     * or whether they need to log in as a matter link share user.
     * @param linkGuid {String} The Link GUID associated with the shareable link.
     * @returns {Promise<T | *>}
     */
    static getUserCanAccessMatterByIdAsync(linkGuid) {
        const uri = `linkshare/check-access/${ linkGuid }`
        try {
            return httpClient
                .get(uri)
                .then(response => {
                    return response?.status === StatusCode.SUCCESS
                })
        } catch (error) {
            if (error?.response.status === StatusCode.UNAUTHORIZED ||
                error?.response.status === StatusCode.FORBIDDEN) {
                return Promise.resolve(false)
            }
        }
    }

    /**
     * Create metadata associated with a shareable link
     * for the given matter.
     * @param matterId {Number} The ID of the matter.
     * @param request {Object} The options for the given link.
     * See MatterLinkShareRequest in MatterLinkShare.cs
     * @returns {Promise<T | *>}
     */
    static createLinkShare(matterId, request) {
        const uri = `matters/${ matterId }/linkshare`
        return httpClient
            .post(uri, qs.stringify(request), false)
    }

    /**
     * Update metadata associated with a shareable link
     * for the given matter.
     * @param matterId {Number} The ID of the matter.
     * @param request {Object} The options for the given link.
     * See MatterLinkShareRequest in MatterLinkShare.cs
     * @returns {Promise<T | *>}
     */
    static updateLinkShare(matterId, request) {
        const uri = `matters/${ matterId }/linkshare/update`
        return httpClient
            .post(uri, qs.stringify(request))
    }

    /**
     * Set a shareable link as enabled or disabled.
     * @param matterId {Number} The ID of the matter.
     * @param val {Boolean} Enabled / disabled.
     * @returns {Promise<T | *>}
     */
    static enableLinkShare(matterId, val) {
        const uri = `matters/${ matterId }/linkshare/${ val }`
        return httpClient
            .post(uri, {})
    }

    /**
     * Get metadata associated with the shareable link if available
     * for the given matter.
     * @param linkGuid {String} The GUID associated with the link share.
     * @returns {Promise<T | *>}
     */
    static getLinkSharePublicMetadata(linkGuid) {
        const uri = `/linkshare/${ linkGuid }`
        return httpClient
            .get(uri, {}, false)
    }

    /**
     * Get metadata associated with the shareable link if available
     * for the given matter.
     * @param linkGuid {String} The GUID associated with the link share.
     * @param password {String} The (optional) password associated with the link
     *     share.
     * @param returnUrl {String} The URL to return to.
     * @returns {Promise<T | *>}
     */
    static getMatterLinkShareQuickLoginURL(linkGuid, password, returnUrl) {
        const request = {
            linkGuid,
            password,
            returnUrl,
        }

        const uri = `/linkshare/${ linkGuid }`
        return httpClient
            .post(uri, qs.stringify(request))
    }
}
