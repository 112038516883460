// Getters
export const GET_LIBRARY_DOCUMENTS = 'GET_LIBRARY_DOCUMENTS'
export const GET_LIBRARY_EXPORTED_DOCUMENTS = 'GET_LIBRARY_EXPORTED_DOCUMENTS'
export const GET_LIBRARY_UPLOADED_DOCUMENTS = 'GET_LIBRARY_UPLOADED_DOCUMENTS'
export const GET_LIBRARY_SEARCH_DOCUMENTS = 'GET_LIBRARY_SEARCH_DOCUMENTS'
export const GET_LIBRARY_PENDING_DOCUMENTS = 'GET_LIBRARY_PENDING_DOCUMENTS'

export const GET_UPLOAD_DOCUMENTS_DIALOG_STATE = 'GET_UPLOAD_DOCUMENTS_DIALOG_STATE'

// Mutations / Setters
export const SET_LIBRARY_PROPERTIES = 'SET_LIBRARY_PROPERTIES'
export const SET_UPDATED_DOCUMENTS = 'SET_UPDATED_DOCUMENTS'
export const SET_LIBRARY_LOADING = 'SET_LIBRARY_LOADING'
export const DOCUMENTS_MUTATE_LOADING_STATE = 'DOCUMENTS_MUTATE_LOADING_STATE'
export const DOCUMENTS_MUTATE_UPLOADED_DOCS_LOADING = 'DOCUMENTS_MUTATE_UPLOADED_DOCS_LOADING'
export const SET_NEW_LIBRARY_DOCUMENTS = 'SET_NEW_LIBRARY_DOCUMENTS'
export const SET_VIEWER_LOGGING_PROPERTIES = 'SET_VIEWER_LOGGING_PROPERTIES'
export const MUTATE_DOCUMENT_ORDER_ERROR = 'MUTATE_DOCUMENT_ORDER_ERROR'
export const DOCUMENT_VIEWER_MUTATE_ENTRY_POINT = 'DOCUMENT_VIEWER_MUTATE_ENTRY_POINT'
export const DOCUMENTS_MUTATE_ADD_DOCUMENT = 'DOCUMENTS_MUTATE_ADD_DOCUMENT'
export const DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST = 'DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST'
export const DOCUMENTS_MUTATE_REMOVE_TITLE_ANALYSIS_REPORT_REQUEST = 'DOCUMENTS_MUTATE_REMOVE_TITLE_ANALYSIS_REPORT_REQUEST'
export const DOCUMENTS_MUTATE_LIBRARY_DOCUMENTS = 'DOCUMENTS_MUTATE_LIBRARY_DOCUMENTS'
export const DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS = 'DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS'
export const DOCUMENTS_MUTATE_MOCK_BEHAVIOUR = 'DOCUMENTS_MUTATE_MOCK_BEHAVIOUR'
export const DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_STATUS = 'DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_STATUS'
export const DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_FROM_MESSAGE = 'DOCUMENTS_MUTATE_UPLOADED_DOCUMENTS_FROM_MESSAGE'

export const SET_UPLOAD_DOCUMENTS_DIALOG_STATE = 'SET_UPLOAD_DOCUMENTS_DIALOG_STATE'

// Actions
export const GENERATE_DOCX_AND_DOWNLOAD = 'GENERATE_DOCX_AND_DOWNLOAD'
export const GENERATE_REPORT_DOCX_AND_DOWNLOAD = 'GENERATE_REPORT_DOCX_AND_DOWNLOAD'
export const REFRESH_DOCUMENTS = 'REFRESH_DOCUMENTS'
export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT'
export const POLL_FOR_UPDATES = 'POLL_FOR_UPDATES'
export const ORDER_COPY_FILED_DOCUMENT = 'ORDER_COPY_FILED_DOCUMENT'
export const ORDER_MULTIPLE_COPY_FILED_DOCUMENTS = 'ORDER_MULTIPLE_COPY_FILED_DOCUMENTS'
export const DOWNLOAD_MANY = 'DOWNLOAD_MANY'
export const HANDLE_ORDER_RESPONSE = 'HANDLE_ORDER_RESPONSE'
export const ADD_DOCUMENT_TO_MATTER = 'ADD_DOCUMENT_TO_MATTER'
export const CANCEL_LAST_DOWNLOAD_REQUEST = 'CANCEL_LAST_DOWNLOAD_REQUEST'
export const DOCUMENTS_FETCH_UPLOADED_DOCUMENTS = 'DOCUMENTS_FETCH_UPLOADED_DOCUMENTS'
export const DOCUMENTS_REPLACE_UPLOADED_DOCUMENTS = 'DOCUMENTS_REPLACE_UPLOADED_DOCUMENTS'
export const DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT = 'DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT'
export const DOCUMENTS_UPLOAD_STARTED = 'DOCUMENTS_UPLOAD_STARTED'
export const DOCUMENTS_FETCH_UPLOAD_STATUS = 'DOCUMENTS_FETCH_UPLOAD_STATUS'
export const DOCUMENTS_CONTINUE_POLLING_UPLOADED_DOCUMENTS = 'DOCUMENTS_CONTINUE_POLLING_UPLOADED_DOCUMENTS'
export const DOCUMENTS_UPLOAD_FILES = 'DOCUMENTS_UPLOAD_FILES'
export const DOCUMENTS_ASYNC_GENERATE_TAR = 'DOCUMENTS_ASYNC_GENERATE_TAR'
export const DOCUMENTS_ASYNC_TAR_COMPLETE = 'DOCUMENTS_ASYNC_TAR_COMPLETE'
export const DOCUMENTS_FETCH_SEARCHES_DOCUMENTS = 'DOCUMENTS_FETCH_SEARCHES_DOCUMENTS'
export const DOCUMENTS_REPLACE_SEARCHES_DOCUMENTS = 'DOCUMENTS_REPLACE_SEARCHES_DOCUMENTS'
export const REMOVE_SINGLE_DOCUMENT_FROM_MATTER = 'REMOVE_SINGLE_DOCUMENT_FROM_MATTER'
export const REMOVE_SINGLE_UPLOADED_DOCUMENT_FROM_MATTER = 'REMOVE_SINGLE_UPLOADED_DOCUMENT_FROM_MATTER'
export const DOCUMENTS_REMOVE_DOCUMENT = 'DOCUMENTS_REMOVE_DOCUMENT'
export const DOCUMENTS_REMOVE_UPLOADED_DOCUMENT = 'DOCUMENTS_REMOVE_UPLOADED_DOCUMENT'
