<template>
    <component :is="renderComponent?.component"
               :key="renderComponent?.key"
               ref="register"
               :show="show"
               :class="{
                   'has-warning': isRegisterUploaded,
               }"
               :is-loading="isLoading"
               :is-ordering-allowed="isOrderingAllowed"
               :hide-flags="renderComponent?.data?.hideFlags"
               @title-number-selected="$emit('title-number-selected')" />
</template>

<script setup lang="ts">
    import { computed,
             markRaw,
             ref,
             watch} from 'vue'

    import Register from '@/components/title-panel/v2/register/register.vue'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'

    const props = defineProps<{
        isLoading: boolean,
        isRegisterUploaded: boolean,
        isOrderingAllowed: boolean,
        selectedTitle: any,
    }>()

    defineEmits(['title-number-selected'])

    const show = ref(true)
    const renderComponent = ref()
    const key = ref(0)

    const documentSource = computed(() => props.selectedTitle?.record?.source)
    const render = () => {
        const scottishDocumentSource = documentSource.value === DOCUMENT_SOURCE.SCOTLAND
        return {
            key: key.value++,
            component: markRaw(Register),
            data: {
                hideFlags: scottishDocumentSource,
            },
        }
    }

    watch(() => documentSource.value, () => {
        renderComponent.value = render()
    }, { immediate: true })


</script>

<style scoped>

</style>
