<template>
    <div class="ow-chip"
         :class="{'is-small': small, 'is-success': color === 'success', 'is-warning': color === 'warning', 'is-error': color === 'error', 'is-default': color === 'default', 'is-dark': color === 'dark' }"
         :style="{ background: color }"
         @click="handleClick">
        <span class="body-regular-small"
              :class="{
                  'label-caps-xx-small': isCaps,
              }">
            {{ label }}
        </span>
        <v-icon v-if="!hideCloseButton">
            $close
        </v-icon>
    </div>
</template>

<script>
    export default {
        props: {
            label: {
                type: String,
                required: true,
            },
            color: {
                type: String,
                required: false,
            },
            hideCloseButton: {
                type: Boolean,
                required: false,
                default: false,
            },
            small: {
                type: Boolean,
                required: false,
                default: false,
            },
            isCaps: {
                type: Boolean,
                required: false,
                default: true,
            },
        },

        emits: [
            'click',
        ],

        methods: {
            handleClick() {
                this.$emit('click')
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-chip.scss';
</style>
