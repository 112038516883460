<template>
    <p class="body-copy--greyout mt-3">
        Other information
    </p>
    <ow-data-grid :allow-selection="false"
                  :headers="epcAdditionalInfoDataGridHeaders"
                  :items="epcAdditionalInfoDataGridItems"
                  :sort-by="additionalSortBy"
                  class="epc-table-additional"
                  dense
                  hover
                  theme="light">
        <template #item.type="{ item } : { item: IRow}">
            <td :title="item.address"
                class="body-copy">
                {{ item.type }}
            </td>
        </template>
        <template #item.details="{ item } : { item: IRow}">
            <td class="body-copy">
                {{ item.details }}
            </td>
        </template>
    </ow-data-grid>
</template>

<script lang="ts" setup>

    import {
        ref,
        watch,
    } from "vue"

    import OwDataGrid from "@/components/core/ow-data-grid.vue"
    import { IEpcTableRow } from "@/components/title-panel/v2/epc/epc-table.vue"

    const additionalSortBy = ref([ { key: 'type', order: 'desc' } ])

    const props = defineProps<{
        selectedItem: IEpcTableRow
    }>()

    interface IRow {
        details: string
        type: string
        address?: string
    }

    const epcAdditionalInfoDataGridItems = ref<IRow[]>()

    const epcAdditionalInfoDataGridHeaders = ref([
        { title: 'Type', key: 'type', class: 'type' },
        { title: 'Details', key: 'details' },
    ])

    const populateAdditionalInfo = (): void => {
        const { selectedItem } = props
        epcAdditionalInfoDataGridItems.value = []
        if (selectedItem.totalFloorArea) {
            epcAdditionalInfoDataGridItems.value.push({
                type: 'Floor area',
                details: `${ selectedItem.totalFloorArea } m2`,
            })
        }
        if (selectedItem.propertyType) {
            epcAdditionalInfoDataGridItems.value.push({
                type: 'Property type',
                details: selectedItem.propertyType,
            })
        }
        if (selectedItem.uprn) {
            epcAdditionalInfoDataGridItems.value.push({
                type: 'UPRN',
                details: selectedItem.uprn,
            })
        }
    }

    watch(() => props.selectedItem, (newValue) => {
        if (!newValue) {
            return
        }

        populateAdditionalInfo()
    }, {
        immediate: true,
        deep: true,
    })
</script>
