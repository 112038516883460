import { useStore } from "vuex"

import { NOTIFICATIONS_DISPLAY_NOTIFICATION } from "@/store/modules/notifications/types"

export const useToaster = () => {
    const store = useStore()

    const showToast = async ({
        isInProgress = false,
        isSuccess = false,
        isError = false,
        isWarning = false,
        heading,
        message,
    }) => {
        await store.dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, {
            toaster: {
                isInProgress,
                isSuccess,
                isError,
                isWarning,
                heading,
                message,
            },
        })
    }

    const success = async (message: string, heading?: string) => {
        await showToast({
            isSuccess: true,
            heading,
            message,
        })
    }

    const error = async (message: string, heading?: string) => {
        await showToast({
            isError: true,
            heading,
            message,
        })
    }

    const warning = async (message: string, heading?: string) => {
        await showToast({
            isWarning: true,
            heading,
            message,
        })
    }

    const inProgress = async (message: string, heading?: string) => {
        await showToast({
            isInProgress: true,
            heading,
            message,
        })
    }

    return {
        showToast,
        success,
        error,
        warning,
        inProgress,
    }
}