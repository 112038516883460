import {
    Column,
    ColumnConfig,
    Model,
} from '@bryntum/grid'
import {
    calculatingMarkup,
    dateFormat,
} from '.'

export interface IDateColumnProps extends Partial<ColumnConfig> {
    className?: string
    calculating?: (record: Model) => boolean
}

export const useDateColumn = (args: IDateColumnProps): Partial<Column> => ({
    ...args,
    type: 'template',
    align: 'right',
    width: args.width ?? 140,
    template: ({ value, record }: any) => {
        const calculating = args.calculating && args.calculating(record)
        if (calculating) {
            return calculatingMarkup
        }
        return value && value !== '0001-01-01T00:00:00'
            ? `<span class='${ args.className }'>${ dateFormat(value) }</span>`
            : '-'
    },
} as Partial<Column>)
