<template>
    <div class="global-nav-loading-skeleton">
        <ow-loading-skeleton v-for="i in tabCount"
                             :key="i"
                             :circle="true"
                             :is-primary-color="true"
                             height="32px"
                             margin-top="16"
                             width="32" />
    </div>
</template>

<script lang="ts">
    import owLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'

    export default {
        components: {
            owLoadingSkeleton,
        },

        mixins: [
            FeatureFlagsMixin,
        ],

        computed: {
            tabCount() {
                return 6
            },
        },
    }
</script>

<style lang="scss">
    @import './global-nav-loading-skeleton.scss';
</style>
