<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 36 44"
                  desc="Title plan icon">
        <defs>
            <clipPath id="c">
                <path d="M0 0h36v43H0z" />
            </clipPath>
            <filter id="a"
                    filterUnits="objectBoundingBox"
                    x="0%"
                    y="0%"
                    width="100%"
                    height="100%">
                <feColorMatrix in="SourceGraphic"
                               values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
            </filter>
            <image id="b"
                   width="36"
                   height="43"
                   xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAArCAYAAAAQVipNAAAABmJLR0QA/wD/AP+gvaeTAAAAkklEQVRYhWNgGAWjYBSMglEwCkbBKBgFowAfYMQnycxYMYllpj0bNS0Utoi5+kzv7WRc8iz4NAdpreJ6f7KfnZoOis9dzhHLEIRTnomallEDjDqIEBh1ECEw6iBCYNRBhMCogwiBUQcRAqMOIgQGnYPwthgfRepyLX2ojVcNqaA9YfKfT2miP6lp5igYBaOAFAAA9BQTz/oQ1McAAAAASUVORK5CYII=" /><mask id="e">
                       <g filter="url(#a)">
                           <use xlink:href="#b" />
                       </g>
                   </mask><g id="d"
                             clip-path="url(#c)">
                       <path style="stroke:none;fill-rule:evenodd;fill:#185d8c;fill-opacity:1"
                             d="m9.828 36.754 11.766-20.625 2.277 1.266L12.105 38.02l-2.277-1.262Zm0 0" />
                       <path style="stroke:none;fill-rule:evenodd;fill:#185d8c;fill-opacity:1"
                             d="m28.754 26.969-9.152-3.867 1.03-2.372 9.15 3.868Zm-22.43-1.047 7.844 3.867-1.172 2.305-7.844-3.867 1.172-2.309Zm0 0" />
                   </g>
        </defs>
        <path style="stroke:none;fill-rule:evenodd;fill:#185d8c;fill-opacity:1"
              d="M0 0h28.46l7.302 7.328V43H.004Zm3.633 3.582.004 35.836h28.492V8.797l-5.191-5.215Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#185d8c;fill-opacity:1"
              d="M24.984 0h3.637v7.168h7.106v3.582H24.984Zm0 0" />
        <path style="stroke:none;fill-rule:nonzero;fill:#62b0e4;fill-opacity:1"
              d="M7.043 18.05h21.676v18.048H7.043Zm0 0" />
        <path style="stroke:none;fill-rule:nonzero;fill:#185d8c;fill-opacity:1"
              d="M24.04 27.074c-1.446 0-2.618 1.153-2.618 2.578 0 .57.191 1.094.508 1.52l1.687 2.883c.012.02.02.039.035.058l.004.004a.465.465 0 0 0 .364.192.461.461 0 0 0 .375-.164l.003.003.02-.027c.023-.031.043-.07.062-.105l1.653-2.828a2.54 2.54 0 0 0 .52-1.536c0-1.425-1.173-2.578-2.614-2.578Zm-.028 3.883a1.28 1.28 0 0 1-1.266-1.02 1.262 1.262 0 0 1 .774-1.421c.48-.2 1.035-.09 1.402.273s.48.91.281 1.387c-.2.472-.672.781-1.191.781Zm0 0" /><use xlink:href="#d"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                mask="url(#e)" />
        <path style="stroke:none;fill-rule:nonzero;fill:#185d8c;fill-opacity:1"
              d="M16.969 7.012H9.297v7.125h4.848l2.824-2.79Zm0 0" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconTitlePlan',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '36px',
            },
            height: {
                type: String,
                required: false,
                default: '44px',
            },
        },
    }
</script>
