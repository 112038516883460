<template>
    <div class="map-key-config-item">
        <div class="drag-handle">
            ☰
        </div>
        <div :style="style"
             class="color-box" />

        <ow-textfield v-model="configItem.label"
                      :placeholder="$t('snapshots.map.key.labelPlaceholder').toString()"
                      class="label-input"
                      clearable
                      data-test="map-key-config-item-label"
                      data-track="Export Assistant - Key label"
                      @click:clear="$emit('remove')"
                      @focus="$event.target.select()" />
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'

    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
    import { getCSSStyleForOWStyleDefinition } from '@/utils/map-utils'

    defineEmits<{
        (event: 'remove'): void
    }>()

    interface Props {
        configItem: KeyConfigItemModel,
    }

    const props = defineProps<Props>()

    const style = computed(() => {
        return getCSSStyleForOWStyleDefinition(props.configItem.style)
    })
</script>
<style lang="scss" scoped>
    @import './map-key-config-item.scss';
</style>
