<template>
    <ow-sidebar data-test-id="matter-searches-titles-list"
                :collapsed="collapsed"
                hide-header>
        <template #headerActions>
            <span v-t="'searches.titleList.subtitle'"
                  class="caption-regular pb-2" />
            <ow-button full-width
                       :is-primary="isDrawing || hasFeatures"
                       :disabled="!filteredTitles?.length || loading"
                       :is-loading="loading"
                       @click="onDrawBoundaryClick">
                {{ drawButtonText }}
            </ow-button>
            <v-checkbox :model-value="isSelected"
                        :disabled="disabled"
                        class="matter-searches-titles-list__checkbox py-3 pb-0 mt-0"
                        data-test="matter-searches-titles-list-checkbox"
                        color="primary"
                        hide-details
                        :indeterminate="localTitles?.some(title => title.selected) && !isSelected"
                        :label="$t('searches.titleList.filter', {
                            count: localTitles?.length,
                            selected: localTitles?.filter(title => title.selected).length,
                        })"
                        @update:model-value="onSelectionChange" />
        </template>
        <div class="matter-searches-titles-list__titles matter-side-panel-titles pa-3">
            <template v-if="groups?.length">
                <matter-side-panel-group-item v-for="group in groups"
                                              :key="group.id"
                                              :has-menu="false"
                                              :has-expanded="false"
                                              :has-visibility-button="false"
                                              :has-edit-button="false"
                                              scroll-observer-element-id=".matter-searches-titles-list__titles"
                                              :filtered-titles="getFilteredTitlesForGroup(group)"
                                              class="group-item matter-searches-titles-list__group-item"
                                              :data-groupid="group.id"
                                              :data-sortorder="group.sortOrder"
                                              :group="group"
                                              :disabled="disabled"
                                              disable-expand-collapse
                                              clickable-area="whole-item"
                                              @selection-changed="onGroupSelectionChange"
                                              @title-click="onTitleClick"
                                              @title-mouse-over="onTitleMouseOver"
                                              @title-mouse-out="onTitleMouseOut" />
            </template>
            <matter-side-panel-title-item v-for="(title, i) in filteredTitlesWithoutGroups"
                                          :key="title.titleNumber"
                                          scroll-observer-element-id=".matter-searches-titles-list__titles"
                                          :data-sortorder="title.sortOrder"
                                          :data-titlenumber="title.titleNumber"
                                          :title="title"
                                          :is-expanded="false"
                                          :has-menu="false"
                                          :has-expanded="false"
                                          :has-visibility-button="false"
                                          :has-light-background="i % 2 === 1"
                                          :disabled="disabled"
                                          clickable-area="whole-item"
                                          disable-expand-collapse
                                          @title-click="onTitleClick"
                                          @title-mouse-over="onTitleMouseOver"
                                          @title-mouse-out="onTitleMouseOut" />
        </div>
        <template #footer>
            <div class="matter-searches-titles-list__footer d-flex justify-space-between">
                <ow-colored-label :background-color="Colors.RedBoundary"
                                  class="matter-searches-titles-list__key caption-highlight">
                    {{ $t('searches.titleList.searchesBoundary') }}
                </ow-colored-label>
                <div class="matter-searches-titles-list__area caption-regular">
                    {{ $t('searches.titleList.area', {
                        area: area ?? 0,
                    }) }}
                </div>
            </div>
            <ow-alert v-if="showAlert">
                {{ $t('searches.titleList.alert') }}
            </ow-alert>
        </template>
    </ow-sidebar>
</template>

<script setup lang="ts">
    import {
        computed,
        provide,
        ref,
        watch,
    } from 'vue'
    import {
        TranslateResult,
        useI18n,
    } from 'vue-i18n'

    import OwAlert from '@/components/core/ow-alert.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import owColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwSidebar from '@/components/core/ow-sidebar.vue'
    import MatterSidePanelGroupItem from '@/components/matter/titles/matter-side-panel-group-item.vue'
    import MatterSidePanelTitleItem from '@/components/matter/titles/matter-side-panel-title-item.vue'
    import { Colors } from '@/enums/colors.enum'

    const filterTitlesText = ref('')
    provide('FILTER_PROVIDER', filterTitlesText)

    const props = defineProps<{
        groups?,
        filteredTitles?: any[],
        showAlert?: boolean,
        area?,
        collapsed?: boolean,
        disabled?: boolean,
        isDrawing?: boolean,
        hasFeatures?: boolean,
        loading?: boolean,
    }>()

    const localTitles = ref()
    const { t } = useI18n()

    const emit = defineEmits<{
        (e: 'titles-changed', v: any[])
        (e: 'title-click', v: string)
        (e: 'draw-boundary-click')
    }>()

    const isSelected = computed<boolean>(() => {
        return localTitles.value?.every(title => title.selected)
    })

    const drawButtonText = computed<TranslateResult>(() => {
        if (props.isDrawing && props.hasFeatures) {
            return t('searches.map.stopDrawing')
        }
        if (props.hasFeatures) {
            return t('searches.map.editBoundary')
        }
        return t('searches.map.drawBoundary')
    })

    const onSelectionChange = (value: boolean) => {
        localTitles.value.forEach(title => {
            title.selected = value
        })
    }

    const onGroupSelectionChange = (group, value: boolean) => {
        localTitles.value.forEach(title => {
            if (title.matterGroupId === group.id) {
                title.selected = value
            }
        })
    }

    const onTitleClick = (title) => {
        emit('title-click', title.titleNumber)
    }

    const onDrawBoundaryClick = () => {
        emit('draw-boundary-click')
    }

    const getFilteredTitlesForGroup = (group) => {
        if (!localTitles.value) {
            return []
        }
        return localTitles.value
            .filter(title => group.id === title.matterGroupId)
            .sort((a, b) => a.sortOrder - b.sortOrder)
    }

    const filteredTitlesWithoutGroups = computed(() => {
        if (!localTitles.value) {
            return []
        }
        return localTitles.value
            .filter(title => title.matterGroupId == null)
            .sort((a, b) => a.sortOrder - b.sortOrder)
    })

    const onTitleMouseOver = (title) => {
        const matchingTitle = localTitles.value.find(t => t.titleNumber === title.titleNumber)
        if (matchingTitle) {
            matchingTitle.highlight = true
        }
    }

    const onTitleMouseOut = (title) => {
        const matchingTitle = localTitles.value.find(t => t.titleNumber === title.titleNumber)
        if (matchingTitle) {
            matchingTitle.highlight = false
        }
    }

    watch(() => localTitles, () => {
        emit('titles-changed', localTitles.value)
    }, {
        deep: true,
    })

    watch(() => props.filteredTitles, () => {
        localTitles.value = props.filteredTitles
    }, {
        immediate: true,
    })
</script>

<style lang="scss">
    @import './matter-searches-titles-list.scss';
</style>
