<template>
    <div id="settings-account-page"
         class="settings-account-page">
        <div class="fixed-header">
            <header>
                <h1 class="content__heading">
                    <v-icon>$account-circle</v-icon>
                    Account
                </h1>
            </header>
        </div>
        <div class="settings-account-page__content">
            <section class="settings-account-page__content--basic-info">
                <app-basic-info :join-date="basicInfo.joinDate"
                                :name="basicInfo.name"
                                :organisation="basicInfo.organisation"
                                :user-type="userType()" />
            </section>
            <section v-show="addressesLoaded"
                     class="settings-account-page__content--items">
                <ul>
                    <li>
                        <app-addresses :org-address="organisationAddress"
                                       :preferences="userPreferences"
                                       @update="updateAddress"
                                       @loaded="addressLoaded" />
                    </li>
                    <li>
                        <app-help-center />
                    </li>
                    <li>
                        <debug-mode-option v-if="shouldAllowDebugMode"
                                           data-test="debug-mode-options" />
                    </li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import Addresses from '@/components/settings/basic-info/addresses.vue'
    import DebugModeOption from '@/components/settings/basic-info/debug-mode-option.vue'
    import HelpCenter from '@/components/settings/basic-info/help-center.vue'
    import BasicInfo from '@/components/settings/basic-info/name.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import {
        USER_GET_BASIC_INFO,
        USER_IS_ADMIN,
        USER_IS_ORGANISATION_ADMIN,
        USER_SAVE_WORKING_FROM_HOME_PREFERENCES,
    } from '@/store/mutation-types'

    export default {
        name: 'Account',

        components: {
            appAddresses: Addresses,
            appBasicInfo: BasicInfo,
            appHelpCenter: HelpCenter,
            debugModeOption: DebugModeOption,
        },

        mixins: [FeatureFlagsMixin],

        data() {
            return {
                addressesLoaded: false,
            }
        },

        computed: {
            ...mapGetters({
                userIsAdmin: USER_IS_ADMIN,
                userIsOrgAdmin: USER_IS_ORGANISATION_ADMIN,
                basicInfo: USER_GET_BASIC_INFO,
            }),

            ...mapState({
                organisationAddress: state => state.user.organisationAddress,
                userPreferences: state => {
                    return {
                        ...state.user.preferences,
                    }
                },
            }),
        },

        methods: {
            ...mapActions({
                saveWorkingFromHomePreferences: USER_SAVE_WORKING_FROM_HOME_PREFERENCES,
            }),

            userType() {
                if (this.userIsAdmin && this.userIsOrgAdmin) {
                    return 'System & Organisation Administrator'
                }

                if (this.userIsOrgAdmin) {
                    return 'Organisation Administrator'
                }

                if (this.userIsAdmin) {
                    return 'System Administrator'
                }

                return 'User'
            },

            addressLoaded() {
                this.addressesLoaded = true
            },

            async updateAddress(update) {
                const preferences = {
                    useCustomPostalAddress: false,
                    addressHouseNumber: null,
                    addressStreet: null,
                    addressLocality: null,
                    addressTown: null,
                    addressPostcode: null,
                }

                if (update.type === 'alt') {
                    Object.assign(preferences, update.address)
                    preferences.useCustomPostalAddress = true
                }

                await this.saveWorkingFromHomePreferences(preferences)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './account.scss';
</style>
