<template>
    <div class="matter-searches-map">
        <slot />
        <v-progress-circular v-if="loading"
                             class="matter-searches-map__loading"
                             :model-value="percentRemaining"
                             color="primary"
                             size="32" />
        <core-map v-show="!loading"
                  class="matter-searches-map"
                  :initial-view="initialView"
                  :layers="layers"
                  :name="'matter-searches-map'"
                  :show-map-scale="true"
                  :interactions="interactions"
                  :show-watermark="true"
                  :watermark-position="OwTooltipPosition.Bottom"
                  data-test="matter-searches-map"
                  @loading="$emit('loading')"
                  @map-initialised="onMapInitialised" />
    </div>
</template>

<script lang="ts" setup>
    import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
    import {polygon} from "@turf/helpers"
    import {
        isEmpty,
    } from 'ol/extent'
    import GeoJSON from 'ol/format/GeoJSON'
    import OlMap from 'ol/Map'
    import {
        Fill,
        Stroke,
        Style,
        Text,
    } from 'ol/style'
    import View from 'ol/View'
    import {
        computed,
        ref,
        shallowRef,
        watch,
    } from 'vue'

    import CoreMap from '@/components/core/maps/core-map.vue'
    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import {
        BASE_LAYERS,
        MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
    } from '@/consts/map'
    import { SUPPORTED_MAP_PROJECTIONS } from '@/consts/map-projections'
    import { Colors } from '@/enums/colors.enum'
    import { CoordinateSystemCode } from '@/enums/coordinate-systems'
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"
    import store from '@/store'
    import { OsImageTileLayer } from '@/store/modules/map/layers/os-image-tile-layer'
    import { SearchesLayer } from '@/store/modules/map/layers/searches-layer'
    import { BoundaryLayer } from '@/store/modules/map/layers/title-boundary-layer/boundary-layer'
    import { TitleBoundaryLayerSettings } from '@/store/modules/map/layers/title-boundary-layer/settings'
    import {
        SEARCHES_GET_SELECTED_TITLE_NUMBERS,
        SEARCHES_SKETCH_INITIALISE,
    } from '@/store/modules/searches/types'
    import { hexToRGBArray } from '@/utils/colour-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const props = withDefaults(defineProps<{
        filteredTitles?: any[]
        enableMapInteractions?: boolean
        activeTitleNumber?: string
        loading?: boolean
    }>(), {
        enableMapInteractions: true,
    })

    const interactions = computed<CoreMapInteractions>(() => {
        if (props.enableMapInteractions) {
            return {
                doubleClickZoom: true,
                dragPan: true,
                keyboard: true,
                mouseWheelZoom: true,
            }
        }

        return {}
    })

    const layers = ref<any[]>([])
    const map = ref<OlMap>()
    const titlesLayer = shallowRef<BoundaryLayer>()
    const searchesLayer = shallowRef<SearchesLayer>()

    const titleNumbers = computed(() => store.getters[SEARCHES_GET_SELECTED_TITLE_NUMBERS])
    const percentRemaining = ref(0)
    const initialView = ref({})

    const emit = defineEmits<{
        (e: 'map-initialised', args: {
            targetMap: OlMap
            boundaryLayer: BoundaryLayer,
            searchesLayer: SearchesLayer,
        })
        (e: 'update-titles', titles: any[])
        (e: 'update-title', title: any)
        (e: 'update-area')
        (e: 'update-geojson', geojson: string)
        (e: 'loading')
    }>()

    const onMapInitialised = async (loadedMap: OlMap) => {
        map.value = loadedMap
        store.dispatch(SEARCHES_SKETCH_INITIALISE, {
            targetMap: loadedMap,
            boundaryLayer: titlesLayer.value,
            searchesLayer: searchesLayer.value,
        })
        await setLayers(loadedMap)
        titleNumbers.value.forEach((titleNumber) => {
            const title = props.filteredTitles.find((title) => title.titleNumber === titleNumber)
            if (title) {
                title.selected = true
                emit('update-titles', props.filteredTitles)
            }
        })
        updateSearchesLayer()

        emit('map-initialised', {
            targetMap: loadedMap,
            boundaryLayer: titlesLayer.value,
            searchesLayer: searchesLayer.value,
        })

        // TODO: work out why this is needed??!
        // This is a hack to ensure the map is correctly initialised
        setTimeout(() => {
            initialiseView()
            updateArea()
        }, 1000)
    }

    const initialiseView = () => {
        const loadedMap = map.value
        loadedMap.setView(new View({
            projection: SUPPORTED_MAP_PROJECTIONS.EPSG27700.code,
            zoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
        }))
        loadedMap.getView().fit(getExtentFit(), {
            maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
            duration: 500,
        })
    }

    const setLayers = async (loadedMap: OlMap) => {
        const baseTileLayer = new OsImageTileLayer({
            layerName: BASE_LAYERS.OS_MAP,
            getTargetMapFn: () => undefined,
        }).getLayer()

        searchesLayer.value = new SearchesLayer({
            targetMap: loadedMap,
            getTitlesDataFn: () => props.filteredTitles,
            interactive: true,
            overFeaturesClassName: 'searches-boundary-over',
            onSearchBoundaryRemoveFn() {
                updateSearchesLayer()
                updateArea()
            },
        })

        titlesLayer.value = new BoundaryLayer({
            getTitlesDataFn: () => props.filteredTitles,
            onTitleBoundaryClickFn: (titleNumber: string[]) => {
                titleNumber.forEach((titleNumber) => {
                    const title = props.filteredTitles.find((title) => title.titleNumber === titleNumber)
                    title.selected = !title.selected
                    emit('update-title', title)
                })
                updateSearchesLayer()
                updateArea()
            },
            onLoadingTitleBoundaryFn: (totalTitleNumbersToLoad: number, remaining: number) => {
                if (totalTitleNumbersToLoad < 50) {
                    percentRemaining.value = 100
                    return
                }
                percentRemaining.value = 100 - Math.round((remaining / totalTitleNumbersToLoad) * 100)
            },
            overFeaturesClassName: 'title-boundary-over',
            interactive: true,
        })
        titlesLayer.value.layer.setStyle((feature) => {
            const titleNumber = feature.get('titleNumber')
            const titleData = props.filteredTitles.find((title) => title.titleNumber === titleNumber)
            if (!titleData) {
                return null
            }

            if (titleData?.highlight || titlesLayer.value.getHighlightedTitleNumber() === titleNumber) {
                const style = TitleBoundaryLayerSettings.getHighlightStyle(titleData)
                style.setText(new Text({
                    font: '12px \'Roboto\', Helvetica, Arial, sans-serif',
                    overflow: true,
                    fill: new Fill({
                        color: hexToRGBArray(Colors.Darks700),
                    }),
                    backgroundFill: new Fill({
                        color: hexToRGBArray(Colors.Lights0),
                    }),
                    padding: [3, 2, 1, 2],
                }))

                // Label the boundary
                let labelText = null
                if (!isNullOrWhitespace(titleData.label)) {
                    labelText = titleData.label
                    if (titleData.showTitleNumber === true) {
                        labelText = `${ labelText } (${ titleData.titleNumber })`
                    }
                } else if (titleData.showTitleNumber === true) {
                    labelText = titleData.titleNumber
                }

                style.getText().setText(labelText)
                return style
            }

            if (titleData?.selected) {
                const style = new Style({
                    fill: new Fill({
                        color: `rgba(${ hexToRGBArray(Colors.RedBoundary) },0.1)`,
                    }),
                    stroke: new Stroke({
                        color: `rgba(${ hexToRGBArray(Colors.RedBoundary) },0.1)`,
                        width: 2,
                    }),
                    text: new Text({
                        font: '12px \'Roboto\', Helvetica, Arial, sans-serif',
                        overflow: true,
                        fill: new Fill({
                            color: hexToRGBArray(Colors.Lights0),
                        }),
                        backgroundFill: new Fill({
                            color: hexToRGBArray(Colors.RedBoundary),
                        }),
                        padding: [3, 2, 1, 2],
                    }),
                })

                // Label the boundary
                let labelText = null
                if (!isNullOrWhitespace(titleData.label)) {
                    labelText = titleData.label
                    if (titleData.showTitleNumber === true) {
                        labelText = `${ labelText } (${ titleData.titleNumber })`
                    }
                } else if (titleData.showTitleNumber === true) {
                    labelText = titleData.titleNumber
                }

                style.getText().setText(labelText)

                return style
            }

            if (titleData?.highlight || titlesLayer.value.getHighlightedTitleNumber() === titleNumber) {
                return TitleBoundaryLayerSettings.getHighlightStyle(titleData)
            } else {
                const style = new Style({
                    stroke: new Stroke({
                        color: hexToRGBArray(Colors.BlueBoundary),
                        width: 2,
                        lineDash: [3, 3],
                    }),
                    fill: new Fill({
                        color: 'rgba(0, 0, 255, 0.1)',
                    }),
                    text: new Text({
                        font: '12px \'Roboto\', Helvetica, Arial, sans-serif',
                        overflow: true,
                        fill: new Fill({
                            color: hexToRGBArray(Colors.Lights0),
                        }),
                        backgroundFill: new Fill({
                            color: hexToRGBArray(Colors.BlueBoundary),
                        }),
                        padding: [3, 2, 1, 2],
                    }),
                    zIndex: titleData?.zIndex,
                })

                // Label the boundary
                let labelText = null
                if (!isNullOrWhitespace(titleData.label)) {
                    labelText = titleData.label
                    if (titleData.showTitleNumber === true) {
                        labelText = `${ labelText } (${ titleData.titleNumber })`
                    }
                } else if (titleData.showTitleNumber === true) {
                    labelText = titleData.titleNumber
                }

                style.getText().setText(labelText)

                return style
            }
        })

        layers.value = [
            baseTileLayer,
            titlesLayer.value.layer,
            searchesLayer.value.getLayer(),
        ]

        titlesLayer.value.setMap(loadedMap)
        searchesLayer.value.setMap(loadedMap)
        await titlesLayer.value.reload(true)
    }

    const updateSearchesLayer = () => {
        const union = searchesLayer.value.updateSearchesLayer(titlesLayer.value.layer.getSource().getFeatures())
        titlesLayer.value.layer.getSource().changed()
        if (!union) {
            emit('update-geojson', null)
            return
        }

        try {
            // NOTE: Eliminate any interior rings if they are contained within the first feature
            const unionSelectedFeature = union.getGeometry().getCoordinates()
            const isMultiPolygon = union.getGeometry().getType() === 'MultiPolygon'
            const firstFeature = isMultiPolygon ? unionSelectedFeature[0] : [unionSelectedFeature[0]]
            const firstPolygon = polygon(firstFeature)
            for (let i= 1; i<unionSelectedFeature.length; i++) {
                const feature = isMultiPolygon ? unionSelectedFeature[i][0] : unionSelectedFeature[i]
                const allInFirstFeature = feature.map(singleCoordinate => {
                    return booleanPointInPolygon(singleCoordinate, firstPolygon)
                })
                if (allInFirstFeature.every(Boolean)) {
                    // Only save the first feature without doing the union
                    union.getGeometry().setCoordinates(firstFeature)
                }
            }
        } catch (e) {
            console.error('Error trying to remove interior rings', e)
        }

        const geoJson:string = new GeoJSON().writeFeatures([union], {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        })
        emit('update-geojson', geoJson)
    }

    const updateArea = () => {
        emit('update-area')
    }

    const getExtentFit = () => {
        if (titleNumbers.value?.length) {
            const extent = searchesLayer.value.getLayer().getSource().getExtent()
            return extent
        } else {
            return titlesLayer.value.layer.getSource().getExtent()
        }
    }

    watch(() => props.filteredTitles, () => {
        if (!titlesLayer.value) {
            return
        }

        updateSearchesLayer()
        updateArea()
    }, {
        deep: true,
    })

    watch(() => props.activeTitleNumber, () => {
        const title = props.filteredTitles.find((title) => title.titleNumber === props.activeTitleNumber)
        if (title) {
            const view = map.value?.getView()
            view?.setCenter(title.centrePoint)
            view?.setZoom(MAX_ZOOM_TO_TITLE_ZOOM_LEVEL)
            const feature = titlesLayer.value.layer.getSource().getFeatures().find((feature) => {
                return feature.get('titleNumber') === props.activeTitleNumber
            })

            if (!feature || isEmpty(feature.getGeometry().getExtent())) {
                return
            }
            const extent = feature.getGeometry().getExtent()
            view?.fit(extent, {
                maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
                duration: 500,
            })
        }
    })

</script>

<style lang="scss">
    @import './matter-searches-map.scss';
</style>
