import { debounce } from "lodash"
import {
    nextTick,
    onMounted,
    onUnmounted,
} from "vue"

/**
 * Composable function to handle window resize event with debouncing
 * @param onResize - callback function to be called when the window is resized
 */
const onWindowResize = ({
    onResize,
    debounceInterval = 100,
}: {
    onResize: ({offsetWidth, offsetHeight, clientHeight, clientWidth}) => void
    debounceInterval?: number
}) => {
    // Resize handler
    const handleResize = () => {
        const { offsetWidth, offsetHeight, clientHeight, clientWidth } = document.documentElement
        onResize({ offsetWidth, offsetHeight, clientHeight, clientWidth })
    }

    // Debounce resize handler
    const debouncedResizeHandler = debounce(() => {
        handleResize()
    }, debounceInterval)

    // Initial resize
    onMounted(async () => {
        window.addEventListener('resize', debouncedResizeHandler)
        await nextTick()
        handleResize()
    })

    // Cleanup
    onUnmounted(() => {
        window.removeEventListener('resize', debouncedResizeHandler)
    })

    return {
        debouncedResize: debouncedResizeHandler,
    }
}

export default onWindowResize
