<template>
    <div ref="dropZone"
         :data-active="isDropZoneActive"
         class="ow-file-uploader-drop-zone"
         :class="{'ow-file-uploader-drop-zone__active': isDropZoneActive}"
         data-test="ow-file-uploader-drop-zone"
         @dragenter.prevent="setActive"
         @dragover.prevent="setActive"
         @dragleave.prevent="setInactive"
         @drop.prevent="onDrop">
        <input ref="fileUploader"
               :accept="inputAccept"
               class="hidden"
               data-test="ow-file-uploader-drop-zone-hidden-file-input"
               :multiple="multiple"
               type="file"
               @change="onDrop">
        <p v-if="multiple || files.length === 0"
           v-dompurify-html="t(i18nPathForDropZoneText)"
           class="body-regular"
           @click.prevent="openFileExplorer" />
        <div v-else-if="hasTooManyFiles"
             class="too-many-files shake file-item-wrapper__error">
            <p>
                {{ t(i18nPathForTooManyFiles) }}
            </p>
        </div>

        <ul>
            <li v-for="(file, index) in files"
                :key="index"
                :class="{
                    'file-item-wrapper__error': !file.isValid || !file.isFileSizeValid,
                    'shake': !file.isValid || !file.isFileSizeValid,
                }"
                class="file-item-wrapper"
                data-test="ow-file-uploader-drop-zone-file-item"
                :title="file.filename">
                <v-icon data-test="ow-file-uploader-remove-item"
                        data-track="DOCUMENTS - remove file from the file upload dialog"
                        @click.prevent="removeFile(file)">
                    $failure-circle
                </v-icon>
                <p class="file-item-wrapper__metadata">
                    <span class="file-item-wrapper__metadata--name">{{ file.filename }}</span>
                    <span class="file-item-wrapper__metadata--size">({{ file.size }})</span>
                </p>
                <p v-if="!file.isFileSizeValid"
                   data-test="ow-file-uploader-file-too-large"
                   class="file-item-wrapper__error">
                    {{ t('documents.upload.fileTooLarge', {maxFileSize: calculateFileSize(props.maxFileSize)}) }}
                </p>
                <p v-else-if="!file.isValid"
                   data-test="ow-file-uploader-file-invalid-type"
                   class="file-item-wrapper__error">
                    {{ t(i18nPathForAcceptedFileTypesError) }}
                </p>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        ComputedRef,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { FORM_DATA_KEY } from '@/api/document-upload.api'
    import { IFile } from '@/components/core/types/ow-file.interface'
    import {
        UploadFileExtensions,
        UploadFileTypes,
    } from '@/enums/upload-file-types.enum'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const { t } = useI18n()

    interface Props {
        i18nPathForAcceptedFileTypesError: string
        i18nPathForTooManyFiles: string,
        i18nPathForDropZoneText: string,
        acceptedFileTypes: Array<UploadFileTypes>,
        acceptedFileExtensions: Array<UploadFileExtensions>,
        multiple?: boolean,
        minFileSize?: number,
        maxFileSize?: number,
        clearFiles?: number,
    }
    const props = withDefaults(defineProps<Props>(), {
        multiple: false,
        minFileSize: 1024, // 1KB
        maxFileSize: 31_457_280, // 30MB is the max that the server can handle currently.
        clearFiles: 0,
    })

    const emit = defineEmits<{
        (e: 'files', v: any),
    }>()

    const fileUploader = ref<HTMLInputElement>(null)
    const files = ref<Array<IFile>>([])
    const formData = ref(new FormData())

    const isDropZoneActive = ref(false)
    let inActiveTimeout = null
    const setActive = () => {
        isDropZoneActive.value = true
        clearTimeout(inActiveTimeout)
    }
    const setInactive = () => {
        inActiveTimeout = setTimeout(() => {
            isDropZoneActive.value = false
        }, 50)
    }

    const validateFileExtension = (file: File): boolean => {
        const fileExtension = `.${ file.name.split('.').pop() }`
        return props.acceptedFileTypes.includes(file.type as UploadFileTypes) ||
            props.acceptedFileExtensions.includes(fileExtension as UploadFileExtensions)
    }

    const hasTooManyFiles = computed((): boolean => {
        return !props.multiple && files.value.length > 1
    })

    const isDuplicateFile = (file: File): boolean => {
        return !isNullOrEmpty(files.value) && files.value.filter((item: any) => item.filename === file.name).length > 0
    }

    const calculateFileSize = (sizeInBytes: number): string => {
        if (sizeInBytes === 0) {
            return '0 Bytes'
        }

        const inMB: number = (sizeInBytes / (1024 * 1024))
        if (inMB > 0.5) {
            return `${ Math.ceil(inMB) } MB`
        }

        const inKB: number = (sizeInBytes / 1024)
        return `${ Math.ceil(inKB) } KB`
    }

    const onDrop = (e) => {
        setInactive()
        const filesToProcess = e.dataTransfer?.files || fileUploader?.value?.files
        for (let i = 0; i <= filesToProcess.length - 1; i++) {
            const file = filesToProcess[i]
            if (!isDuplicateFile(file)) {
                const isValid = validateFileExtension(file)
                const isFileSizeValid = calculateIsFileSizeValid(file.size)
                if (isValid && isFileSizeValid) {
                    formData.value.append(FORM_DATA_KEY, file)
                }

                files.value.push({
                    filename: file.name,
                    size: calculateFileSize(file.size),
                    isValid,
                    isFileSizeValid,
                })
            }
        }

        if (!hasTooManyFiles.value) {
            emitFileUpdates()
        }
    }

    const inputAccept: ComputedRef<string> = computed(() => {
        const combined = [...props.acceptedFileExtensions, ...props.acceptedFileTypes]
        return Object.keys(combined).map((key: string) => combined[key]).join(',')
    })

    const openFileExplorer = (): void => {
        fileUploader.value.click()
    }

    watch(() => props.clearFiles, () => {
        files.value = []
        formData.value = new FormData()
        resetFileInputElement()
    })

    const resetFileInputElement = (): void => {
        const emptyFile = document.createElement('input')
        emptyFile.type = 'file'
        fileUploader.value.files = emptyFile.files
    }

    const removeFile = (file: any): void => {
        removeFileFromFormData(file)
        removeFileFromUI(file)
        emitFileUpdates()
    }

    const removeFileFromUI = (file: any): void => {
        const fileIndex = files.value.findIndex((item: any) => item.filename === file.filename)
        files.value.splice(fileIndex, 1)
    }

    const removeFileFromFormData = (file: any): void => {
        const originalFormDataFiles = [...formData.value.entries()]
        formData.value.delete(FORM_DATA_KEY)
        originalFormDataFiles.forEach((item: any) => {
            if (item[1].name !== file.filename) {
                // make new formData without this item
                formData.value.append(FORM_DATA_KEY, item[1])
            }
        })

        if (formData.value.getAll(FORM_DATA_KEY).length === 0) {
            resetFileInputElement()
        }
    }

    const calculateIsFileSizeValid = (filesize: number): boolean => {
        return filesize >= props.minFileSize && filesize <= props.maxFileSize
    }

    const emitFileUpdates = () => {
        emit(FORM_DATA_KEY, {
            formData: formData.value,
            files: files.value,
        })
    }

    defineExpose({
        calculateFileSize,
        files,
        formData,
    })
</script>

<style scoped lang="scss">
@import './ow-file-uploader-drop-zone';
</style>
