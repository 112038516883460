import {
    DocumentOrderStatus,
    getDocumentOrderStatusText,
} from '@/consts/document-order-status'
import i18n from '@/plugins/i18n'
import { differenceInMonths } from '@/utils/date-utils'

function isIssuedOverThreeMonthsAgo(registerResponseDate) {
    const responseDateValue = new Date(registerResponseDate)
    return differenceInMonths(new Date(), responseDateValue) >= 3
}

export const titleNumberColumnRenderer = ({ isExport, cellElement, record, value }) => {
    if (!isExport) {
        if (value) {
            // Assign classes, in part based on record properties.
            const classes = [
                'layout',
                'row',
            ]

            // Colour based on tenure
            if (record.tenure) {
                if (record.tenure.includes('Freehold')) {
                    classes.push('freehold')
                } else if (record.tenure.includes('Leasehold')) {
                    classes.push('leasehold')
                } else {
                    classes.push('other')
                }
            }

            const issuedOverThreeMonthsAgo = isIssuedOverThreeMonthsAgo(record.registerResponseDate)
            const trOwnerMismatch = record.trOwnerMatchesPublicOwner === false
            const classList = [
                'document-source',
                issuedOverThreeMonthsAgo ? 'document-source--warning' : '',
                trOwnerMismatch ? 'document-source--critical' : '',
            ].join(' ')

            // Has the digital register been downloaded or uploaded? - display icon and tip text.
            let digitalRegisterTipIconHTML = ''
            let digitalRegisterTipHTML = ''

            if (record.isRegisterOrdered) {
                digitalRegisterTipIconHTML = `<svg class="${ classList }" viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" style="width:16px;height:16px;"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 2h8l6 6v12a2 2 0 0 1-2 2H5.99A1.99 1.99 0 0 1 4 20l.01-16c0-1.105.885-2 1.99-2zm4.182 15.636-4.546-4.545 1.273-1.273 3.273 3.273 6.909-6.91 1.273 1.274-8.182 8.181z" fill="currentColor"/></svg>`
                digitalRegisterTipHTML = `${ i18n.global.t('documents.registerPurchased') }${ issuedOverThreeMonthsAgo ? i18n.global.t('documents.oldRegister') : '' } ${ trOwnerMismatch ? i18n.global.t('documents.registerOwnerMismatchesPublicData') : '' }`
            } else if (record.isRegisterUploaded) {
                digitalRegisterTipIconHTML = `<i aria-hidden="true" class="${ classList } mdi mdi-cloud-upload"></i>`
                digitalRegisterTipHTML = `${ i18n.global.t('documents.registerUploaded') }${ issuedOverThreeMonthsAgo ? i18n.global.t('documents.oldRegister') : '' } ${ trOwnerMismatch ? i18n.global.t('documents.registerOwnerMismatchesPublicData') : '' }`
            } else {
                cellElement.classList.add('not-downloaded')

                const isProcessing = [
                    DocumentOrderStatus.ORDERING,
                    DocumentOrderStatus.RETRYING,
                    DocumentOrderStatus.COME_BACK_LATER,
                    DocumentOrderStatus.UNDER_INVESTIGATION,
                    DocumentOrderStatus.ORDERED,
                    DocumentOrderStatus.DERIVING,
                    DocumentOrderStatus.AUGMENTING,
                    DocumentOrderStatus.PROCESSING,
                ].includes(record.documentOrderStatus)

                if (isProcessing) {
                    digitalRegisterTipIconHTML = '<span aria-hidden="true" class="document-order-status processing"/>'
                    digitalRegisterTipHTML = getDocumentOrderStatusText(record.documentOrderStatus)
                }
            }

            return `
                    <div class="${ classes.join(' ') }" title="${ digitalRegisterTipHTML }">
                        <span class="body-copy clickable"
                        data-track="TAM - Open title from analysis table"
                        data-test="title-analysis-table-title-number">${ value }</span>
                        ${ digitalRegisterTipIconHTML }
                    </div>
                `
        } else {
            // Probably shouldn't happen
            return null
        }
    }

    return value
}
