export const RunUtilityFunctions = function() {
    if (!Array.prototype.equals) {

        // attach the .equals method to Array's prototype to call it on any array
        Array.prototype.equals = (array) => {
        // if the other array is a falsy value, return
            if (!array) {
                return false
            }

            // compare lengths - can save a lot of time
            if (this.length !== array.length) {
                return false
            }

            for (let i = 0, l = this.length; i < l; i++) {
            // Check if we have nested arrays
                if (this[i] instanceof Array && array[i] instanceof Array) {
                // recurse into the nested arrays
                    if (!this[i].equals(array[i])) {
                        return false
                    }
                } else if (this[i] !== array[i]) {
                // Warning - two different object instances will never be
                // equal: {x:20} != {x:20}
                    return false
                }
            }
            return true
        }
        // Hide method from for-in loops
        Object.defineProperty(Array.prototype, 'equals', { enumerable: false })
    }
    // https://tc39.github.io/ecma262/#sec-array.prototype.find
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            value: function(predicate) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" is null or not defined')
                }

                const o = Object(this)

                // 2. Let len be ? ToLength(? Get(O, "length")).
                const len = o.length >>> 0

                // 3. If IsCallable(predicate) is false, throw a TypeError
                // exception.
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function')
                }

                // 4. If thisArg was supplied, let T be thisArg; else let T be
                // undefined.
                const thisArg = arguments[1]

                // 5. Let k be 0.
                let k = 0

                // 6. Repeat, while k < len
                while (k < len) {
                    // a. Let Pk be ! ToString(k).
                    // b. Let kValue be ? Get(O, Pk).
                    // c. Let testResult be ToBoolean(? Call(predicate, T, «
                    // kValue, k, O »)). d. If testResult is true, return
                    // kValue.
                    const kValue = o[k]
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return kValue
                    }
                    // e. Increase k by 1.
                    k++
                }

                // 7. Return undefined.
                return undefined
            },
            configurable: true,
            writable: true,
        })
    }

    // Production steps of ECMA-262, Edition 5, 15.4.4.17
    // Reference: http://es5.github.io/#x15.4.4.17
    if (!Array.prototype.some) {
        Array.prototype.some = function(fun, thisArg) {
            'use strict'

            if (this == null) {
                throw new TypeError('Array.prototype.some called on null or undefined')
            }

            if (typeof fun !== 'function') {
                throw new TypeError()
            }

            const t = Object(this)
            const len = t.length >>> 0

            for (let i = 0; i < len; i++) {
                if (i in t && fun.call(thisArg, t[i], i, t)) {
                    return true
                }
            }

            return false
        }
    }
}
