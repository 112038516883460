<template>
    <div class="d-flex flex-column">
        <matter-searches-order-details-loading-skeleton v-if="loading" />
        <div v-else>
            <div class="d-flex flex-row matter-searches-order-details">
                <div class="matter-searches-order-details__map">
                    <matter-searches-boundary-preview :title-numbers="titleNumbers"
                                                      :map-options="mapOptions"
                                                      :geo-json="geoJson"
                                                      :show-edit-button="showEditButton"
                                                      width="240px"
                                                      height="184px"
                                                      @edit-button-click="$emit('map-edit', titleNumbers[0])" />
                </div>
                <div v-if="hasDefaultSlot">
                    <slot />
                </div>
                <div v-else
                     class="matter-searches-order-details__form d-flex flex-column">
                    <div v-if="showTitleNumbers"
                         class="d-flex">
                        <label for="title-number"
                               class="mr-2 caption-regular">
                            {{ `${$t('searches.createOrder.fields.titleNumber')}:` }}
                        </label>
                        <div id="title-number"
                             class="matter-searches-order-details__title-number caption-highlight">
                            {{ titleNumbers.length > 0 ? titleNumbers[0] : '' }}
                        </div>
                    </div>
                    <div v-if="address"
                         class="d-flex">
                        <matter-searches-enter-address-form :model-value="address"
                                                            full-width
                                                            disabled
                                                            class="mt-2" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        useSlots,
    } from 'vue'

    import { StructuredAddress } from '@/api/searches.api'
    import MatterSearchesOrderDetailsLoadingSkeleton from '@/components/loading-skeletons/matter-searches-order-details-loading-skeleton.vue'
    import MatterSearchesBoundaryPreview from '@/components/matter-searches/matter-searches-boundary-preview.vue'
    import MatterSearchesEnterAddressForm from '@/components/matter-searches/matter-searches-enter-address-form.vue'

    withDefaults(defineProps<{
        titleNumbers?: string[]
        geoJson?: string
        address?: StructuredAddress
        mapOptions: {
            boundaryColour: string;
            npsLayer: string
            layerAttribution: string
            osDataHubApiKey: string
        }
        loading?: boolean
        showEditButton?: boolean
        showTitleNumbers?: boolean
    }>(), {
        showTitleNumbers: true,
    })

    const emit = defineEmits<{
        (e: 'map-edit', v: string)
    }>()

    const slots = useSlots()
    const hasDefaultSlot = computed(() => !!slots.default && !!slots.default().length)
</script>

<style lang="scss">
    @import './matter-searches-order-details.scss';
</style>
