<template>
    <div id="mapPage"
         :class="mapClass">
        <walkthrough-container @clear-titles="clearTitles" />

        <!-- Left level 1 -->
        <matter-titles v-if="showMatterTitles"
                       :collapsed="collapseTitleList || streetViewEnabled"
                       @clear-titles="clearTitles"
                       @show-bulk-actions="updateMapDisabled" />
        <multi-title-panel @clear-titles="clearTitles" />
        <overlays-panel v-if="mountOverlaysPanel"
                        v-show="showOverlaysPanel"
                        ref="overlaysPanel"
                        :collapsed="collapsedOverlaysPanel"
                        :matter-id="currentMatter.id"
                        :matter-name="currentMatter.name"
                        :target-map="currentMap"
                        @collapse="collapsedOverlaysPanel = $event" />
        <div v-if="showSketchesPanel">
            <sketches-tool-panel :anchor-element-selector="'#sketchesList'" />
            <sketches-list id="sketchesList"
                           :matter-id="currentMatter.id" />
        </div>
        <top-nav v-if="isTopNavVisible"
                 :actions="topNavActions"
                 :items="topNavItems"
                 :side-nav-collapsed="sideListNavCollapsed"
                 :value="$route.name"
                 @input="toggleSidebar"
                 @action-click="handleActionClick" />
        <!-- Left level 2 -->
        <div class="map-page__overlay-area">
            <title-panel-v2 v-if="isTitlePanelV2Enabled"
                            :key="selectedTitleNumber"
                            :is-ordering-allowed="true"
                            :is-pinned="isPinned"
                            :selected-title-number="selectedTitleNumber"
                            :matter-id="matterId"
                            @clear-titles="clearTitles"
                            @pin-title="pinTitle"
                            @toggle-title-visibility="toggleTitleVisibility"
                            @change-tab="changeTab"
                            @title-number-selected="redirectToTitleNumber" />
            <title-panel v-else
                         :is-always-shown="isAlwaysShown"
                         :is-ordering-allowed="true"
                         :is-pinned="isPinned"
                         :loading-pin-or-show-button="loadingPinOrShowButton"
                         :selected-title-number="selectedTitleNumber"
                         @clear-titles="clearTitles"
                         @pin-title="pinTitle"
                         @toggle-title-visibility="toggleTitleVisibility"
                         @change-tab="changeTab"
                         @title-number-selected="redirectToTitleNumber" />
            <title-summary-panel />
            <search-by-owner-titles />
            <matter-title-style />
            <!-- Center -->
            <div id="mainMap"
                 :style="sideListNavCollapsed && !(isSiteVisitEnabled || isWalkthrough) ? 'margin-left: 2em' : 'margin-left: 0' "
                 class="hide-in-percy elevation-0"
                 data-test="map-main-map">
                <map-watermark id="mapWatermark" />
            </div>
            <ow-overlay v-if="!isWalkthrough"
                        :show="isMapDisabled" />
            <map-street-view />
            <map-north-arrow v-if="currentMap !== null"
                             v-show="false"
                             :target-map="currentMap" />
            <map-rollover-v2 :is-disabled="false"
                             :target-map="currentMap" />

            <!-- Right level 1 -->
            <map-search-with-owner v-if="showSearch" />
            <map-options :open-layer-panel="isLayerPanelOpen"
                         @update-layers-panel="setLayersPanel" />

            <!-- Right level 2 -->
            <map-layers-panel @close="setLayersPanel" />
        </div>

        <!-- Top Nav Actions -->
        <map-download-site-plan v-if="isTopNavVisible"
                                :show="showDownloadSnapshotDialog"
                                hide-button
                                @input="val => showDownloadSnapshotDialog = val" />

        <ow-dialog v-model="showMatterShareDialog"
                   :reset-form-on-close="true"
                   :title="$t('matter.share.title')">
            <matter-share v-if="showMatterShareDialog"
                          :initial-active-tab="0"
                          reset-form-on-modal-close
                          @log-heap-event="logHeapEventForMatterShare" />
        </ow-dialog>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import MatterApi from '@/api/matter.api'
    import SnapshotApi from '@/api/snapshot.api'
    import OwDialog from '@/components/core/ow-dialog.vue'
    import OwOverlay from '@/components/core/ow-overlay.vue'
    import MapDownloadSitePlan from '@/components/map/map-download-site-plan.vue'
    import MapLayersPanel from '@/components/map/map-layers-panel.vue'
    import MapNorthArrow from '@/components/map/map-north-arrow.vue'
    import MapOptions from '@/components/map/map-options.vue'
    import MapStreetView from '@/components/map/map-streetview.vue'
    import MapWatermark from '@/components/map/map-watermark.vue'
    import MultiTitlePanel from '@/components/map/multi-title-panel/multi-title-panel.vue'
    import OverlaysPanel from '@/components/map/overlays/overlays-panel.vue'
    import MapSearchWithOwner from '@/components/map/search/map-search-with-owner.vue'
    import MapRolloverV2 from '@/components/map-rollover/map-rollover.vue'
    import MatterShare from '@/components/matter/matter-share.vue'
    import MatterTitleStyle from '@/components/matter/matter-title-style.vue'
    import SearchByOwnerTitles from '@/components/matter/search-by-owner-titles/search-by-owner-titles.vue'
    import MatterTitles from '@/components/matter/titles/matter-titles.vue'
    import SketchesList from '@/components/sketches/sketches-list.vue'
    import SketchesToolPanel from '@/components/sketches/sketches-tool-panel.vue'
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'
    import TitlePanel from '@/components/title-panel/title-panel.vue'
    import TitleSummaryPanel from '@/components/title-panel/title-summary-panel.vue'
    import TitlePanelV2 from '@/components/title-panel/v2/title-panel.vue'
    import TopNav from '@/components/top-nav/top-nav.vue'
    import WalkthroughContainer from '@/components/walkthrough/walkthrough-container.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { Route } from '@/enums/route.enum'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from '@/interfaces/store/state.interface'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import {
        MAP_EMPHASISE_NPS_COVERAGE,
        MAP_INITIALISE,
        MAP_MUTATE_DISABLED,
        MAP_MUTATE_INITIAL_TITLE,
        MAP_MUTATE_LAUNCH_SEARCH_RESULT,
        MAP_MUTATE_NPS_OVER_IDS,
        MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS,
        MAP_SHOW_NPS_FREEHOLDS,
        MAP_SHOW_NPS_LEASEHOLDS,
        MAP_SHOW_UNREGISTERED_LAND,
        MAP_UPDATE_SIZE,
        MAP_VALIDATE_NPS_DISPLAY,
    } from '@/store/modules/map/types'
    import {
        MATTER_ADD_TITLE,
        MATTER_GET_MATTER_CONTENTS,
        MATTER_HAS_TITLE_NUMBER,
        MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG,
        MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
        MATTER_REMOVE_TITLES,
        MATTER_SET_BOUNDARY_HIGHLIGHT_VISIBLE,
        MATTER_SHOW_BOUNDARIES,
        MATTER_UPDATE_BOUNDARY_LAYER,
        MATTER_UPDATE_CURRENT_MATTER_CHARGES,
        MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT,
    } from '@/store/modules/matter/types'
    import { PLANNING_CLEAR } from '@/store/modules/planning/types'
    import { SELECT_SEARCH_RESULT } from '@/store/modules/search/types'
    import { SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER } from '@/store/modules/site-visit/types'
    import {
        SKETCHES_MUTATE_COLLAPSE_LIST,
        SKETCHES_MUTATE_ENABLE_MAP_SELECTION,
    } from '@/store/modules/sketches/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import {
        TITLE_CLEAR,
        TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER,
        TITLE_GET_SHOW_MULTI_TITLE_PANEL,
        TITLE_LOOKUP_AND_ZOOM_TO_TITLE,
        TITLE_LOOKUP_TITLE,
        TITLE_MUTATE_ACTIVE_TITLE_PANEL,
        TITLE_MUTATE_COLLAPSE_PANEL,
        TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
        TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
        TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY,
    } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_INTERCOM_UPDATE,
        USER_MUTATE_SHOW_MAP_TITLES_NAV,
    } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'MapView',

        components: {
            MapWatermark,
            OverlaysPanel,
            SketchesToolPanel,
            SketchesList,
            MapLayersPanel,
            MapSearchWithOwner,
            MatterTitles,
            SearchByOwnerTitles,
            TitlePanel,
            TitlePanelV2,
            TitleSummaryPanel,
            WalkthroughContainer,
            MapNorthArrow,
            MapOptions,
            MapStreetView,
            MatterTitleStyle,
            MultiTitlePanel,
            OwOverlay,
            OwDialog,
            MapRolloverV2,
            TopNav,
            MapDownloadSitePlan,
            MatterShare,
        },

        mixins: [ FeatureFlagsMixin ],

        setup() {
            const { isTopNavVisible, topNavActions, topNavItems } = useMapTopNav()
            return {
                isTopNavVisible,
                topNavActions,
                topNavItems,
            }
        },

        data() {
            return {
                loadingPinOrShowButton: false,
                mapResizeTimer: null,
                collapseTitleList: false,
                isLayerPanelOpen: true,
                showDownloadSnapshotDialog: false,
                showMatterShareDialog: false,
                collapsedOverlaysPanel: false,
            }
        },

        computed: {
            ...mapGetters({
                isMultiTitlePanelShown: TITLE_GET_SHOW_MULTI_TITLE_PANEL,
                matterHasTitle: MATTER_HAS_TITLE_NUMBER,
                matterContents: MATTER_GET_MATTER_CONTENTS,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            ...mapState({
                currentMap: (state: IState) => state.map.map,
                isMapDisabled: (state: IState) => state.map.isMapDisabled,
                currentMatter: (state: IState) => state.matter.currentMatter,
                currentTitleNumber: (state: IState) => state.title.selectedTitleNumber,
                hasSelectedTitle: (state: IState) => state.title.selectedTitleNumber != null,
                isConfigLoaded: (state: IState) => state.config.loaded,
                isUserProfileLoaded: (state: IState) => state.user.profileLoaded,
                isMatterPanelHidden: (state: IState) => state.user.preferences.hiddenMatterPanel,
                inStreetView: (state: IState) => state.siteVisit.enabled,
                isTitlePanelExpanded: (state: IState) => state.user.preferences.expandedTitlePanel,
                launchSearchResult: (state: IState) => state.map.launchSearchResult,
                matterId: (state: IState) => state.matter.currentMatter.id,
                isMapLoaded: (state: IState) => state.map.loaded,
                selectedTitle: (state: IState) => state.title.selectedTitle,
                showTitlesNav: (state: IState) => state.user.showMapTitlesNav,
                streetViewSelectingStartLocation: (state: IState) => state.siteVisit.enabled === true && state.siteVisit.selectingStartLocation === true,
                takingSnapshot: (state: IState) => state.map.snapshot.takingSnapshot,
                hasPendingSharingChanges: (state: IState) => state.matter.pendingChangesToLinkShareSettings,
                walkthroughInitialising: (state: IState) => !state.walkthrough.hasZoomedToInitialPageExtent,
                hasCollapsedTitlePanel: (state: IState) => state.title.collapsePanel,
                streetViewEnabled: (state: IState) => state.siteVisit.enabled,
                collapsedSketchesList: (state: IState) => state.sketches.collapsedList,
                isSketching: (state: IState) => state.sketches.currentSketch != null,
                activeTitlePanel: state => state.title.activeTitlePanel,
                isSiteVisitEnabled: state => state.siteVisit.enabled,
            }),

            showSketchesPanel() {
                return this.routeGlobalNavName === 'sketches'
            },

            sideListNavCollapsed() {
                if (this.routeUsesOverlays) {
                    return !this.showOverlaysPanel
                } else if (this.showSketchesPanel) {
                    return this.collapsedSketchesList
                }
                return !this.showTitlesNav
            },

            sideListNavExpanded() {
                if (this.routeUsesOverlays) {
                    return this.showOverlaysPanel
                } else if (this.showSketchesPanel) {
                    return !this.collapsedSketchesList
                }
                return this.showTitlesNav
            },

            mapClass() {
                return {
                    streetview: this.inStreetView,
                    'streetview-selecting-start-location': this.streetViewSelectingStartLocation,
                    takingsnapshot: this.takingSnapshot,
                    'title-selection': this.hasSelectedTitle,
                    'title-selection-collapsed': this.hasCollapsedTitlePanel,
                    'with-expanded-title-panel': this.isTitlePanelExpanded && this.selectedTitleNumber != null,
                    'with-expanded-layers-panel': this.isLayerPanelOpen,
                    'side-list-nav': this.sideListNavExpanded,
                    'side-list-nav-collapsed': this.sideListNavCollapsed,
                    'multi-titles-panel-shown': this.isMultiTitlePanelShown,
                    'view-walkthrough-initialising': this.walkthroughInitialising && this.isWalkthrough,
                    'link-share-client': this.isSharedLinkView,
                    'view-walkthrough': this.isWalkthrough,
                    'planning-applications': this.$route.hash === `#${ TitlePanelTabName.Planning }`,
                    'is-sketching': this.isSketching,
                    'top-nav-visible': this.isTopNavVisible,
                    'overlays-visible': !this.collapsedOverlaysPanel && this.routeUsesOverlays,
                    'overlays-collapsed': this.collapsedOverlaysPanel && this.routeUsesOverlays,
                }
            },

            showingTitleDetailsPanel() {
                return typeof this.routeTitleNumber !== 'undefined'
            },

            selectedTitleNumber: {
                get() {
                    return this.currentTitleNumber
                },
                set(val: string) {
                    this.clearTitle()
                        .then(() => {
                            this.lookupTitle(val)

                            if (this.isSharedLinkView) {
                                this.lookupSummaryTitleDetails(val)
                            }
                        })
                },
            },

            isAlwaysShown() {
                const title = this.currentMatter.selectedTitles.find(item => {
                    return this.selectedTitleNumber === item.titleNumber
                })
                if (title != null) {
                    return title.show
                }
                return false
            },

            isPinned() {
                return this.matterHasTitle(this.selectedTitleNumber)
            },

            isWalkthrough() {
                return this.routeName === Route.MatterMapWalkthrough
            },

            prerequisitesLoaded() {
                return this.isConfigLoaded &&
                    this.isUserProfileLoaded &&
                    this.isFeatureFlagsLoaded
            },

            boundaryAvailable() {
                return this.selectedTitle?.bboxGeom !== null
            },

            showSearch() {
                return this.routeName !== Route.MatterSketches
            },
            showMatterTitles() {
                return !this.showSketchesPanel && !this.showOverlaysPanel
            },
            showOverlaysPanel() {
                return this.routeUsesOverlays && this.currentMap !== null
            },
            mountOverlaysPanel() {
                return Boolean(this.matterId && this.currentMap !== null)
            },
            routeName() {
                return this.$route.name
            },
            routeUsesOverlays() {
                return (this.$route.meta?.uses || []).includes('overlays')
            },
            routeGlobalNavName() {
                return this.$route.meta?.globalNavName
            },
            routeTitleNumber() {
                return this.$route.params.titleNumber
            },
        },

        watch: {
            prerequisitesLoaded() {
                this.initialiseMap()
                if (!this.showingTitleDetailsPanel && !this.isWalkthrough && !this.routeUsesOverlays) {
                    // When viewing the map, with a couple of exceptions, zoom to the extent of the visible titles.
                    this.setInitialExtent()
                }
            },

            isMapLoaded() {
                if (typeof this.routeTitleNumber !== 'undefined') {
                    this.onMapLoad()
                }

                if (this.routeName === Route.MatterMap || this.routeName === Route.MatterMapTitle) {
                    this.setBoundaryHighlightVisibility(true)
                } else if (this.routeName === Route.MatterSketches || this.routeUsesOverlays) {
                    this.setBoundaryHighlightVisibility(false)
                }
            },

            'routeTitleNumber': {
                async handler() {
                    const newTitleNumber = this.routeTitleNumber
                    if (isNullOrWhitespace(newTitleNumber)) {
                        return
                    }

                    this.enableSketchesMapSelection(false)

                    switch (this.routeName) {
                        case Route.MatterMap:
                        case Route.MatterMapTitle:

                            if (!this.isSharedLinkView) {
                                this.enableSketchesMapSelection(true)
                            }

                            // refresh map?
                            if (this.launchSearchResult != null) {
                                this.setLocationFromSearch()
                            }
                            this.updateMapSizeAfterLayoutChange()

                            // Force map update if title was selected on a different tab
                            // and therefore, map size was not set accordingly
                            if (this.routeName === Route.MatterMapTitle) {
                                await this.lookupTitleAndZoom(newTitleNumber)

                                if (this.boundaryAvailable) {
                                    await this.zoomToTitleAndShowBoundary(newTitleNumber)
                                    await this.updateMapSize()
                                }
                            }

                            // show sharing settings?
                            if (this.hasPendingSharingChanges) {
                                this.showSharingSettingsDialog(true)
                            }

                            if (this.currentMatter.selectedTitles.find(title => title.titleNumber === newTitleNumber)) {
                                this.setExpandedTitleNumber(newTitleNumber)
                            }
                            break
                        case Route.MatterSketches:
                            this.enableSketchesMapSelection(true)
                            break
                        default:
                            await this.setLayersPanel(false)
                            if (this.activeTitlePanel === TitlePanels.ADD && !isNullOrEmpty(this.matterContents.titles)) {
                                this.setActiveTitlePanel(TitlePanels.LIST)
                            }
                            break
                    }
                    if (this.routeUsesOverlays || this.routeName === Route.MatterSketches) {
                        await this.setBoundaryHighlightVisibility(false)
                    } else {
                        await this.setBoundaryHighlightVisibility(true)
                    }

                    // When changing the route, planning applications should be reset.
                    if (this.planningData) {
                        await this.clearPlanningApplications()
                    }
                },
                immediate: false,
            },

            isMatterPanelHidden() {
                this.updateMapSizeAfterLayoutChange()
            },

            isTitlePanelExpanded() {
                this.updateMapSizeAfterLayoutChange()
            },

            showTitlesNav() {
                this.updateMapSizeAfterLayoutChange()
            },

            matterId(val) {
                if (!this.showingTitleDetailsPanel && Boolean(val)) {
                    this.setInitialExtent()
                }
            },

            inStreetView(val) {
                if (!val) {
                    this.collapseTitlePanel(false)
                }
                this.updateMapSizeAfterLayoutChange()
            },

            async currentTitleNumber(val) {
                this.updateMapSizeAfterLayoutChange()

                const matterId = this.currentMatter.id
                if (matterId && (this.routeName === Route.MatterMapTitle || this.routeName === Route.MatterMap)) {
                    if (val == null) {
                        await this.$router.push({
                            name: Route.MatterMap,
                            params: {
                                matterId,
                            },
                        })
                    } else {
                        await this.$router.push({
                            name: Route.MatterMapTitle,
                            params: {
                                matterId,
                                titleNumber: val,
                            },
                            hash: this.$route.hash,
                            query: { ...this.$route.query },
                        })
                    }

                    this.updateNpsOverIds([])
                    this.validateNpsDisplay()
                }
            },

            'currentMatter.id': {
                handler(newVal, oldVal): void {
                    if (oldVal != null) {
                        // We want to close the title panel on behalf of the user when switching matters.
                        this.clearTitles()

                        // Reset map layers
                        this.showFreeholds(false)
                        this.showLeaseholds(false)
                        this.showNpsCoverage(false)
                        this.showUnregisteredLand(false)
                    } else {
                        this.isLayerPanelOpen = isNullOrEmpty(this.currentMatter.selectedTitles)
                    }
                },
                immediate: true,
            },
        },

        async mounted() {
            this.setExpandedTitleNumber(this.routeTitleNumber)
            if (this.routeName === Route.MatterSketches || this.routeName === Route.MatterMap) {
                if (!this.isSharedLinkView) {
                    this.enableSketchesMapSelection(true)
                }
            }

            if (this.prerequisitesLoaded) {
                await this.initialiseMap()
                if (!this.showingTitleDetailsPanel) {
                    this.setInitialExtent()
                }
            }

            onresize = () => {
                this.updateMapSizeAfterLayoutChange()
            }

            if (this.chargeUpdateTimer === null) {
                this.chargeUpdateTimer = setInterval(() => {
                    this.refreshCharges()
                }, 120000)
            }
        },

        methods: {
            ...mapActions('search', {
                selectSearchResult: SELECT_SEARCH_RESULT,
            }),

            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapActions({
                addTitleToMatter: MATTER_ADD_TITLE,
                clearTitle: TITLE_CLEAR,
                initialiseMap: MAP_INITIALISE,
                lookupTitle: TITLE_LOOKUP_TITLE,
                lookupSummaryTitleDetails: TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER,
                lookupTitleAndZoom: TITLE_LOOKUP_AND_ZOOM_TO_TITLE,
                refreshCharges: MATTER_UPDATE_CURRENT_MATTER_CHARGES,
                removeTitleFromMatter: MATTER_REMOVE_TITLES,
                showMatterBoundaries: MATTER_SHOW_BOUNDARIES,
                updateMapSize: MAP_UPDATE_SIZE,
                updateStreetViewTitleBoundaryLayer: SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER,
                validateNpsDisplay: MAP_VALIDATE_NPS_DISPLAY,
                zoomToMatterExtent: MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT,
                zoomToTitleAndShowBoundary: TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY,
                clearPlanningApplications: PLANNING_CLEAR,
                updateBoundaryLayer: MATTER_UPDATE_BOUNDARY_LAYER,
                showFreeholds: MAP_SHOW_NPS_FREEHOLDS,
                showLeaseholds: MAP_SHOW_NPS_LEASEHOLDS,
                showUnregisteredLand: MAP_SHOW_UNREGISTERED_LAND,
                showNpsCoverage: MAP_EMPHASISE_NPS_COVERAGE,
                updateIntercom: LOGGING_INTERCOM_UPDATE,
                setBoundaryHighlightVisibility: MATTER_SET_BOUNDARY_HIGHLIGHT_VISIBLE,
            }),

            ...mapMutations({
                collapseTitlePanel: TITLE_MUTATE_COLLAPSE_PANEL,
                setMapLaunchSearchResult: MAP_MUTATE_LAUNCH_SEARCH_RESULT,
                setInitialMapTitle: MAP_MUTATE_INITIAL_TITLE,
                updateNpsClickedTitleNumbers: MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS,
                updateNpsOverIds: MAP_MUTATE_NPS_OVER_IDS,
                showSharingSettingsDialog: MATTER_MUTATE_SHOW_LINK_SHARE_SETTINGS_DIALOG,
                setExpandedTitleNumber: TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
                updateMapDisabled: MAP_MUTATE_DISABLED,
                enableSketchesMapSelection: SKETCHES_MUTATE_ENABLE_MAP_SELECTION,
                setActiveTitlePanel: TITLE_MUTATE_ACTIVE_TITLE_PANEL,
                setShowMapTitlesNav: USER_MUTATE_SHOW_MAP_TITLES_NAV,
                setCollapseSketchesList: SKETCHES_MUTATE_COLLAPSE_LIST,
                setTitleOrganiserEnabled: MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
            }),

            setLocationFromSearch() {
                const l = this.launchSearchResult
                if (l.center != null && this.extent != null && this.title != null && this.zoom != null) {
                    window.setTimeout(() => {
                        this.selectSearchResult(l)
                        this.setMapLaunchSearchResult(null)
                    }, 1500)
                }
            },

            async onMapLoad() {
                if (!this.currentMatter.id) {
                    await MatterApi.setCurrentMatter(this.$route.params.matterId)
                }

                this.setInitialMapTitle(this.routeTitleNumber)
                await this.lookupTitleAndZoom(this.routeTitleNumber)
                await this.updateBoundaryLayer()

                if (this.isSharedLinkView) {
                    await this.lookupSummaryTitleDetails(this.routeTitleNumber)
                }
            },

            updateMapSizeAfterLayoutChange() {
                if (this.mapResizeTimer != null) {
                    clearTimeout(this.mapResizeTimer)
                }

                this.mapResizeTimer = setTimeout(() => {
                    this.updateMapSize()
                }, 10)
            },

            async clearTitles() {
                this.updateNpsClickedTitleNumbers([])
                await this.clearTitle()
                await this.validateNpsDisplay()
                await this.updateStreetViewTitleBoundaryLayer()
            },

            async toggleTitleVisibility() {
                if (this.isPinned) {
                    this.toggleTitleBoundary()
                } else {
                    // Add to current matter and show
                    this.loadingPinOrShowButton = true

                    await this.addTitleToMatter({
                        titleNumber: this.selectedTitleNumber,
                        show: true,
                    })

                    this.toggleTitleBoundary(true)

                    this.loadingPinOrShowButton = false
                }
            },

            async pinTitle() {
                if (this.isPinned) {
                    await this.removeTitleFromMatter([ this.selectedTitleNumber ])
                } else {
                    // Add to current matter
                    this.loadingPinOrShowButton = true
                    await this.addTitleToMatter({
                        titleNumber: this.selectedTitleNumber,
                        show: false,
                    })
                    this.setExpandedTitleNumber(this.selectedTitleNumber)
                    this.loadingPinOrShowButton = false
                }
            },

            toggleTitleBoundary(isShown = null) {
                const title = this.currentMatter.selectedTitles.find(t => t.titleNumber === this.selectedTitleNumber)
                const show = isShown === null ? !title.show : isShown
                if (title) {
                    this.showMatterBoundaries({
                        titles: [ title ],
                        show,
                    })
                }
            },

            changeTab(val) {
                switch (val) {
                    case TitlePanelTabName.Planning:
                    case TitlePanelTabName.FindNearby:
                        this.collapseTitleList = true
                        break
                    default:
                        this.collapseTitleList = false
                        break
                }
            },

            async setLayersPanel(show = false) {
                this.isLayerPanelOpen = show

                // TODO: (DOBO) Hardcoded get-value(sizes, map, layers-panel, width) + get-value(map, options, margin-right)
                //       Update loader to reuse SCSS variables
                requestAnimationFrame(async () => {
                    try {
                        await this.updateIntercom({ marginRight: show ? 316 : 0 })
                        await this.updateMapSize()
                    } catch {
                    }
                })
            },

            redirectToTitleNumber(titleNumber: string) {
                if (titleNumber !== this.selectedTitleNumber) {
                    this.$router.push({
                        name: Route.MatterMapTitle,
                        params: {
                            id: this.currentMatterId,
                            titleNumber,
                        },
                    })
                }
            },

            async handleActionClick({ title }) {
                switch (title) {
                    case 'Download Snapshot': {
                        const snapshotRequest = MapSnapshotService.createNewSnapshotApiModel(this.currentMap, this.currentMatter.id)
                        const configId = await SnapshotApi.createSnapshot(snapshotRequest)

                        await this.$router.push({
                            name: Route.SnapshotAssistant,
                            params: {
                                configId,
                            },
                        })
                        break
                    }
                    case 'Share':
                        this.showMatterShareDialog = true
                        break
                    case 'Overlays':
                        this.showOverlaysPanel = true
                        break
                }
            },

            logHeapEventForMatterShare() {
                this.logHeapEvent({
                    type: 'Map: Enter Preview',
                    metadata: {
                        matterId: this.currentMatter.id,
                        isUserMatterOwner: this.currentMatter.createdByMe,
                    },
                })
            },

            toggleSidebar() {
                switch (this.routeName) {
                    case Route.MatterMap:
                    case Route.MatterMapTitle:
                        this.setShowMapTitlesNav(!this.showTitlesNav)

                        this.setTitleOrganiserEnabled(false)
                        this.setCollapseSketchesList(false)
                        this.collapsedOverlaysPanel = false
                        break
                    case Route.MatterSketches:
                        this.setCollapseSketchesList(true)

                        this.setTitleOrganiserEnabled(false)
                        this.setShowMapTitlesNav(true)
                        this.collapsedOverlaysPanel = false
                        break
                    case Route.OverlaysList:
                        this.collapsedOverlaysPanel = true

                        this.setShowMapTitlesNav(true)
                        this.setCollapseSketchesList(false)
                        break
                }
            },

            setInitialExtent() {
                switch (this.routeName) {
                    case Route.MatterMap:
                    case Route.MatterMapTitle:
                    case Route.MatterSketches: // TODO: zoom to sketches instead
                        this.zoomToMatterExtent()
                        break
                    default:
                        break
                }
            },
        },
    }
</script>

<style lang="scss">
@import 'map.scss';
</style>
