import { CheckoutItemBase } from '@/models/checkout/checkout-item-base.model'
import { CheckoutItemType } from '@/enums/checkout-item-type'
import { IBasicTitleInformation } from '@/interfaces/basic-title-information.interface'
import { ICheckoutItemDocumentOrder } from '@/interfaces/checkout/checkout-item-document-order.interface'
import { IDocumentOrder } from '@/interfaces/store/document-ordering/document-order.interface'

/**
 * Implementation for a document order checkout item.
 * @extends CheckoutItemBase
 * @implements ICheckoutItemDocumentOrder
 */
export class CheckoutItemDocumentOrder extends CheckoutItemBase implements ICheckoutItemDocumentOrder {
    readonly title: IBasicTitleInformation
    readonly document: IDocumentOrder
    readonly isOrderedForReport: boolean

    /**
     * Instantiates a new instance of the CheckoutItemDocumentOrder class.
     * @param displayName
     * @param title
     * @param document
     * @param isOrderedForReport
     * @param cost - defaults to £3 per document as that is the price for all users. Can be overriden.
     */
    constructor(
        displayName: string,
        title: IBasicTitleInformation,
        document: IDocumentOrder,
        isOrderedForReport = false,
        cost = 3) {
        super(displayName, cost, CheckoutItemType.Document)

        this.title = title
        this.document = document
        this.isOrderedForReport = isOrderedForReport
    }

    /**
     * Just for debugging purposes
     */
    toJSON(): ICheckoutItemDocumentOrder {
        return {
            ...super.toJSON(),
            title: this.title,
            document: this.document,
            isOrderedForReport: this.isOrderedForReport,
        }
    }
}
