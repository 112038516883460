//TODO: this and consts\data-provider.ts need to be merged
// and will need a rethink once scottish uploads are to be supported

const SCOTLIS = 'ScotLIS'

export const DOCUMENT_SOURCE = {
    UPLOADED: 'Upload',
    HMLR: 'HMLR',
    SCOTLAND: 'LIS',
}

export const getDataProviderText = (dataProvider: string) => {
    switch (dataProvider) {
        case DOCUMENT_SOURCE.HMLR:
        case DOCUMENT_SOURCE.UPLOADED:
            return DOCUMENT_SOURCE.HMLR
        case DOCUMENT_SOURCE.SCOTLAND:
            return SCOTLIS
    }
}

export const getDataProviderFromNumber = (dataProvider: number) => {
    switch (dataProvider) {
        case 0:
            return DOCUMENT_SOURCE.HMLR
        case 1:
            return SCOTLIS
    }
}