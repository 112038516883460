<template>
    <core-map v-if="loaded"
              :initial-view="initialView"
              :interactions="interactions"
              :layers="layers"
              :name="name"
              :show-map-scale="false"
              :show-layer-attribution="showLayerAttribution"
              :layer-attribution="layerAttribution"
              data-test="ow-title-boundary-map"
              @loaded="$emit('loaded')"
              @loading="$emit('loading')"
              @map-initialised="handleMapInitialised" />
    <ow-title-boundary-map-loading-skeleton v-else />
</template>

<script lang="ts">
    import { Feature } from 'ol'
    import { isEmpty } from 'ol/extent'
    import GeoJSON from 'ol/format/GeoJSON'
    import { Geometry } from 'ol/geom'
    import { Vector as VectorLayer } from 'ol/layer'
    import Map from 'ol/Map'
    import { Vector as VectorSource } from 'ol/source'
    import {
        Stroke,
        Style,
    } from 'ol/style'

    import HttpClient from '@/api/http-client'
    import LandRegistryApi from '@/api/land-registry.api'
    import MapApi from '@/api/map.api'
    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import OwTitleBoundaryMapLoadingSkeleton
        from '@/components/loading-skeletons/ow-title-boundary-map-loading-skeleton.vue'
    import {
        BASE_LAYERS,
        MAX_ZOOM,
        MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
        MIN_ZOOM,
        PADDING,
    } from '@/consts/map'
    import { OsImageTileLayer } from '@/store/modules/map/layers/os-image-tile-layer'
    import { SearchesLayer } from '@/store/modules/map/layers/searches-layer'
    import { isNullOrEmpty } from '@/utils/array-utils'

    import CoreMap from './core-map.vue'

    export default {
        name: 'OwTitleBoundaryMap',

        components: {
            CoreMap,
            OwTitleBoundaryMapLoadingSkeleton,
        },

        props: {
            titleNumbers: {
                type: Array,
                default: () => [],
            },
            geoJson: {
                type: String,
                default: null,
            },
            geoserverNpsLayerName: {
                type: String,
                required: false,
                default: 'ow:nps202112',
            },
            layerAttribution: {
                type: String,
                required: false,
            },
            enableMapInteractions: {
                type: Boolean,
                default: false,
            },
            baseLayer: {
                type: String,
                default: BASE_LAYERS.OS_MAP,
                required: false,
            },
            boundaryColour: {
                type: String,
                required: false,
                default: '#f44336',
            },
            boundaryWidth: {
                type: Number,
                required: false,
                default: 5,
            },
            name: {
                type: String,
                required: false,
                default: 'search-boundary-map',
            },

            /**
             * Used for previewing maps where screen space is limited.
             */
            showLayerAttribution: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        emits: [
            'loaded',
            'loading',
        ],
        data() {
            return {
                titles: null,
                titlesLoaded: false,
                layers: [],
                baseTileLayer: null,
                titleLayers: [],
                searchesLayer: null,
            }
        },

        computed: {
            interactions(): CoreMapInteractions {
                if (this.enableMapInteractions) {
                    return {
                        doubleClickZoom: true,
                        dragPan: true,
                        keyboard: true,
                        mouseWheelZoom: true,
                    }
                }

                return {}
            },

            initialView(): CoreMapView {
                return {
                    minZoom: MIN_ZOOM,
                    maxZoom: MAX_ZOOM,
                    paddingWithinMap: [24, 24, 24, 24],
                }
            },

            loaded(): Boolean {
                return this.layersSet && this.titlesLoaded
            },

            layersSet(): Boolean {
                return !isNullOrEmpty(this.layers)
            },
        },

        watch: {
            baseLayer(): void {
                this.setLayers()
            },

            boundaryColour(): void {
                this.setLayers()
            },

            boundaryWidth(): void {
                this.setLayers()
            },

            async titleNumbers(newVal: string[]): Promise<void> {
                if (!this.geoJson) {
                    await this.loadTitleDetails(newVal)
                }
            },

            geoJson: {
                handler(): void {
                    this.setLayers()
                },
                immediate: true,
            },
        },

        async mounted() {
            if (this.geoJson) {
                this.titlesLoaded = true
                this.setLayers()
                return
            }
            await this.loadTitleDetails(this.titleNumbers)
        },

        methods: {
            async loadTitleDetails(titleNumbers: string[]): Promise<void> {
                const requests = []
                this.titles = []
                this.titleGeometries = []
                for (const titleNumber of titleNumbers) {
                    requests.push(this.getTitleDetail(titleNumber))
                    requests.push(this.getTitleGeometry(titleNumber))
                }
                await HttpClient.getAllByPromise(requests)
                this.setLayers()
                this.titlesLoaded = true
            },

            async getTitleDetail(titleNumber: string): Promise<void> {
                this.titlesLoaded = false
                const response: any = await LandRegistryApi.getTitleByTitleNumber(titleNumber)
                if (response.ok) {
                    this.titles.push({
                        ...response.titleNumbers[0],
                        titleNumber: response?.titleNumbers?.[0]?.number,
                        selected: true,
                    })
                }
            },

            async getTitleGeometry(titleNumber: string): Promise<void> {
                const response: any = await MapApi.getGeometryForTitleNumber(titleNumber, this.geoserverNpsLayerName)
                if (response) {
                    this.titleGeometries.push(response)
                }
            },

            setLayers(): void {
                this.baseTileLayer = new OsImageTileLayer({
                    layerName: this.baseLayer,
                    getTargetMapFn: () => undefined,
                }).getLayer()
                this.initialiseTitleLayers()

                this.searchesLayer = new SearchesLayer({
                    getTitlesDataFn: () => this.titles,
                    geoJson: this.geoJson,
                    targetMap: null,
                    interactive: false,
                })
                this.layers = [
                    this.baseTileLayer,
                    this.searchesLayer.getLayer(),
                ]
            },

            initialiseTitleLayers(): void {
                if (!this.titleGeometries) {
                    return
                }
                this.titleLayers = []

                this.titleGeometries.forEach(titleGeometry => {
                    if (titleGeometry?.features) {
                        const titlesBbox: Feature<Geometry>[] = (new GeoJSON().readFeatures(titleGeometry) as Feature<Geometry>[])
                        const source = new VectorSource<Feature<Geometry>>({
                            features: titlesBbox,
                            attributions: this.layerAttribution,
                        })
                        this.titleLayers.push(new VectorLayer({
                            zIndex: 900,
                            source,
                            style: () => new Style({
                                zIndex: 100,
                                stroke: new Stroke({
                                    color: this.boundaryColour,
                                    width: this.boundaryWidth,
                                }),
                            }),

                        }))
                    }
                })
            },

            handleMapInitialised(map: Map): void {
                this.searchesLayer.setVisible(true, map)
                const features = []
                this.titleLayers.forEach(layer => {
                    const layerFeatures = layer.getSource().getFeatures()
                    features.push(...layerFeatures)
                })
                this.searchesLayer.updateSearchesLayer(features)
                const view = map.getView()
                const extent = this.searchesLayer.getLayer().getSource().getExtent()
                if (isEmpty(extent)) {
                    return
                }
                view.fit(this.searchesLayer.getLayer().getSource().getExtent(), {
                    maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
                    duration: 200,
                    padding: PADDING,
                })
            },
        },
    }
</script>
