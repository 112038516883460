<template>
    <div class="ow-loading-logo__container d-flex flex-column justify-center align-center gr-2">
        <img src="../../media/orbital-witness-logo.svg"
             alt="Orbital Witness logo"
             height="100px" />
        <v-progress-linear :indeterminate="true"
                           rounded-bar
                           height="8px"
                           rounded
                           color="primary" />
    </div>
</template>

<style scoped lang="scss">
    @import './ow-loading-logo';
</style>
