import { format } from '@/utils/date-utils'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'

export default function useDates() {
    /**
     * Formats a date to a string as "31 October 2023".
     * @param value
     */
    const formatDate = (value: Date): string => {
        return format(value, 'dd MMMM yyyy')
    }

    /**
     * Formats a date to a string as "31-10-2023".
     * @param value
     */
    const formatDateShort = (value: Date): string => {
        return format(value, 'dd-MM-yyyy')
    }

    /**
     * Formats a date to a string as "October 2023".
     * @param value
     */
    const formatAsMonthYear = (value: Date): string => {
        return format(value, 'MMMM yyyy')
    }

    /**
     * Formats a date to a string as "31-10-2023 12:00".
     * Assumes the timezone is Europe/London.
     * @param value
     */
    const formatDateTime = (value: Date): string => {
        return formatInTimeZone(value, 'Europe/London', 'dd-MM-yyyy HH:mm')
    }

    return {
        formatAsMonthYear,
        formatDate,
        formatDateShort,
        formatDateTime,
    }
}
