import { IOrganisationHubMessage } from '@/interfaces/store/organisation-hub/organisation-hub-message.interface'

/**
 *
 */
export enum TrackedTitleListener{
    TitlePanel,
    TitleAnalysis,
    Checkout,
    BulkExport,
}

/**
 * A request to track a title on behalf of a particular listener.
 */
export class TrackTitleRequest {
    titleNumber: string
    listener: TrackedTitleListener

    constructor(titleNumber: string, listener: TrackedTitleListener) {
        this.titleNumber = titleNumber
        this.listener = listener
    }
}

/**
 * A title being tracked for notifications.
 */
export class TrackedTitle {
    readonly titleNumber: string
    listeners: TrackedTitleListener[]
    updates: IOrganisationHubMessage[]

    constructor(titleNumber: string, listener: TrackedTitleListener) {
        this.titleNumber = titleNumber
        this.listeners = [listener]
        this.updates = new Array<IOrganisationHubMessage>()
    }
}
