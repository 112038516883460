<!-- Basic implementation of a combo box. Takes an array of values. Only currently supports a single selected value -->
<template>
    <div class="ow-combo-box"
         :class="{
             'ow-combo-box--small': small,
         }">
        <label>{{ label ? label : '' }}
            <input ref="inputRef"
                   data-test="ow-combo-box-input"
                   :aria-label="label"
                   :value="inputValue"
                   @input="onInput"
                   @keyup.enter="onEnterKey"
                   @keyup.up="onUpKey"
                   @keyup.esc="showOptions = false"
                   @keyup.down="onDownKey"
                   @click="onInputClick" />
            <ul v-if="showOptions"
                ref="listRef"
                data-test="ow-combo-box-list">
                <li v-for="option in options"
                    :key="option"
                    :aria-label="option.toString()"
                    :data-value="option"
                    data-test="ow-combo-box-option"
                    :class="{ selected: option == inputValue}"
                    @click="selectOption($event, option)">
                    {{ getOptionText(option) }}
                </li>
            </ul>
            <span v-if="showChevronIcon"
                  class="icon show">⌄</span>
            <span v-if="showClearIcon"
                  class="icon clear"
                  @click="onClearClick">x</span>
        </label>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'

    // Element refs.
    const inputRef = ref<HTMLInputElement | null>(null)
    const listRef = ref<HTMLUListElement | null>(null)

    // Props and defaults.
    const defaultValueInternal = ref<string | number>(undefined)
    const props = withDefaults(defineProps<{
        modelValue: any
        options: Array<string | number>
        label?: string
        defaultValue?: string | number
        small?: boolean,
    }>(), {
        options: () => [],
        label: null,
        modelValue: null,
        small: false,
    })

    const emit = defineEmits<{
        (e: 'update:model-value', v: any)
    }>()

    watch(() => props.modelValue, (newValue) => {
        inputValue.value = newValue
    })

    // Internal state.
    const showOptions = ref(false)
    const inputValue = ref<string | number>(defaultValueInternal.value)
    watch(inputValue, () => {
        emitValue()
        scrollValueIntoView()
    })
    onMounted(() => {
        defaultValueInternal.value = props.defaultValue
        if (!defaultValueInternal.value) {
            defaultValueInternal.value = props.options.length ? props.options[0] : null
        }
        inputValue.value = props.modelValue ?? props.defaultValue
    })

    const showChevronIcon = computed(() => {
        return !showOptions.value || inputValue.value?.toString().length === 0
    })

    const showClearIcon = computed(() => {
        return showOptions.value && inputValue.value?.toString().length > 0
    })

    const selectOption = ($event: Event, option: string | number) => {
        $event.preventDefault()
        inputValue.value = option
        showOptions.value = false
    }

    // Input handling.
    const onEnterKey = () => {
        showOptions.value = false
        emitValue()
    }
    const onInputClick = () => {
        showOptions.value = !showOptions.value
    }
    const onUpKey = () => {
        showOptions.value = true
        const currentIndex = props.options.indexOf(inputValue.value)
        if (currentIndex > 0) {
            inputValue.value = props.options[currentIndex - 1]
        } else {
            inputValue.value = props.options[props.options.length - 1]
        }
    }
    const onDownKey = () => {
        showOptions.value = true
        const currentIndex = props.options.indexOf(inputValue.value)
        if (currentIndex < props.options.length - 1) {
            inputValue.value = props.options[currentIndex + 1]
        } else {
            inputValue.value = props.options[0]
        }
    }
    const onClearClick = () => {
        inputValue.value = defaultValueInternal.value
    }
    const onInput = () => {
        inputValue.value = inputRef.value.value
    }

    // Misc functions.
    const scrollValueIntoView = () => {
        const el = document.querySelector(`[data-value='${ inputValue.value }']`)
        if (el) {
            el.scrollIntoView()
        }
    }
    const getOptionText = (value) => {
        if (typeof value === 'number') {
            return value.toLocaleString()
        } else if (typeof value === 'string') {
            return value
        }
    }

    const emitValue = () => {
        emit('update:model-value', inputValue.value)
    }

</script>

<style>
    @import 'ow-combo-box.scss';
</style>
