<template>
    <div class="register-card-entries">
        <register-record v-for="(register, registerIndex) in registers"
                         :key="register.prefix"
                         :entry-types="selectedEntryTypes"
                         :highlight-dates="highlightDates"
                         :register="register"
                         :search-string="filter"
                         :data-section="`entry-${registerIndex+1}`"
                         @view-link-clicked="emit('view-link-clicked', $event)"
                         @entry-clicked="onRegisterEntryClicked"
                         @match-count-changed="registerSearchMatchCount[registerIndex] = $event"
                         @view-plan-clicked="$emit('view-plan-clicked', $event)" />

        <schedule-of-lease-record v-if="!isNullOrEmpty(scheduleOfLeases)"
                                  :highlight-dates="highlightDates"
                                  data-section="entry-leases"
                                  :schedule-of-leases="scheduleOfLeases"
                                  :search-string="filter"
                                  :selected-entry-number="selectedEntryNumber"
                                  @entry-clicked="onSonolEntryClicked"
                                  @match-count-changed="sonolSearchMatchCount = $event" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'

    import RegisterRecord from '@/components/title-panel/v2/register/register-record.vue'
    import ScheduleOfLeaseRecord from '@/components/title-panel/v2/register/schedule-of-lease-record.vue'
    import { useSelectedTitle } from "@/composables/use-selected-title"
    import { ITitlePanelTabController } from "@/composables/use-title-panel"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'
    import { ISimplifiedRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'
    import { IRegisterEntry } from '@/interfaces/documents/register-entry.interface'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const props = defineProps({
        registers: {
            type: Array as PropType<IRegister[]>,
            required: true,
        },
        scheduleOfLeases: {
            type: Array as PropType<IScheduleOfLeaseEntry[]>,
            required: false,
        },
        highlightDates: {
            type: Boolean,
            required: false,
        },
        selectedEntryTypes: {
            type: Array as PropType<ISimplifiedRegisterLinkedIndicator[]>,
            required: true,
        },
        searchString: {
            type: String,
            required: false,
        },
        tabController: {
            type: Object as PropType<ITitlePanelTabController>,
            required: true,
        },
    })

    const emit = defineEmits<{
        (e: 'title-number-selected'): void
        (e: 'scroll-to-entry', text: string): void
        (e: 'view-link-clicked', document: ICopiesFiledDocument): void
        (e: 'entry-clicked', text: string): void
        (e: 'view-plan-clicked'): void
        (e: 'match-count-changed', count: number): void
    }>()

    const onViewPlanClicked = (): void => {
        emit('view-plan-clicked')
    }

    const { isRegisterPurchased } = useSelectedTitle()
    const selectedEntryNumber = ref<string>('')
    const onRegisterEntryClicked = (entry: IRegisterEntry): void => {
        selectedEntryNumber.value = entry.nr
        emit('entry-clicked', entry.displayString.substring(0, 55))
    }

    const onSonolEntryClicked = (entry: IScheduleOfLeaseEntry, index: string) => {
        selectedEntryNumber.value = index
        emit('entry-clicked', entry.entryText[0])
    }

    const registerSearchMatchCount = ref<number[]>(new Array(props.registers.length).fill(0))
    const sonolSearchMatchCount = ref<number>(0)

    const filter = computed<string>(() => props.searchString?.trim()?.toLowerCase().replace(/\W/g, ' ') ?? '')

    const totalSearchMatchCount = computed(() => {
        return registerSearchMatchCount.value.reduce((acc: number, v: number) => acc + v, 0) +
            sonolSearchMatchCount.value
    })

    watch(() => totalSearchMatchCount.value, (value) => {
        emit('match-count-changed', value)
    })

    watch (() => isRegisterPurchased.value, () => {
        let registerIndex = 1
        for (const register of props.registers) {
            if (isNullOrEmpty(register.entries)) {
                registerIndex++
                continue
            }
            props.tabController.addTabSection(TitlePanelTabName.Register, {
                title: register.displayName,
                id: `entry-${ registerIndex }`,
                order: registerIndex++,
            })
        }
    }, {
        immediate: true,
    })

    watch(() => props.scheduleOfLeases, () => {
        if (isRegisterPurchased.value && !isNullOrEmpty(props.scheduleOfLeases)) {
            props.tabController.addTabSection(TitlePanelTabName.Register, {
                title: 'Schedule of Leases',
                id: `entry-leases`,
                order: 100,
            })
        }
    }, {
        immediate: true,
    })
</script>

<style scoped>
@import './register-card-entries.scss';
</style>
