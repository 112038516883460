import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { AppVersion } from '@/models/app-version.model'
import {
    LOGGING_DATADOG_INITIALISE,
    LOGGING_DATADOG_LOG_INFO_EVENT,
} from '@/store/mutation-types'
import { format } from '@/utils/date-utils'
import {
    getDatadogConfig,
    isLocalDevEnvironment,
} from '@/utils/environment.utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

let sessionId = null

export const getDatadogSessionUrl = () => {
    if (window?.DD_RUM && isNullOrWhitespace(sessionId)) {
        const rumContext = window.DD_RUM.getInternalContext()
        sessionId = rumContext?.session_id

        return !isNullOrWhitespace(sessionId) ? `https://app.datadoghq.eu/rum/replay/sessions/${ sessionId }` : null
    }
    return null
}

export const actions = {
    async [LOGGING_DATADOG_INITIALISE]({ state, rootState }) {
        if (state.initialised.datadog === false) {
            state.initialised.datadog = true
            if (!isLocalDevEnvironment()) {
                if (window?.owvars?.underTest !== true) {
                    const config = getDatadogConfig()
                    if (config) {
                        const appVersion = await AppVersion.getLatestAppVersion()
                        datadogRum.init({
                            applicationId: config.applicationId,
                            clientToken: config.clientToken,
                            site: config.site,
                            service: config.serviceName,
                            env: config.environment,
                            version: appVersion,
                            sessionSampleRate: 100,
                            sessionReplaySampleRate: 100,
                            startSessionReplayRecordingManually: true,
                            trackUserInteractions: true,
                            trackFrustrations: true,
                            trackResources: true,
                            trackLongTasks: true,
                            defaultPrivacyLevel: 'mask-user-input',
                            actionNameAttribute: 'data-track',
                            enableExperimentalFeatures: ['feature_flags'],
                        })

                        const user = rootState.user
                        const accountCreated = format(user.accountCreatedAt, 'yyyy-MM-dd')

                        datadogRum.setUser({
                            id: user.id,
                            name: `${ user.firstName } ${ user.lastName }`,
                            email: user.email,
                            organisation: user.organisation,
                            pricingModel: user.pricingModelName,
                            organisationIndustry: user.organisationIndustry,
                            organisationStatus: user.organisationStatus,
                            hasTitleSummaryCharge: Boolean(user.titleSummaryCharge),
                            accountCreatedAt: accountCreated,
                            screenWidth: window.screen.width,
                            screenHeight: window.screen.height,
                            devicePixelRatio: window.devicePixelRatio,
                            appVersion,
                            isMatterLinkShareUser: rootState.linkShareClient.isMatterLinkShareUser,
                        })

                        window.DD_RUM.startSessionReplayRecording()

                        // Initialise Datadog logging.
                        datadogLogs.init({
                            clientToken: config.clientToken,
                            site: config.site,
                            forwardErrorsToLogs: true,
                            sampleRate: 100,
                            version: appVersion,
                            forwardConsoleLogs: 'all',
                            service: config.serviceName,
                        })
                        datadogLogs.setGlobalContextProperty('environment', config.environment)
                        datadogLogs.setGlobalContextProperty('user_id', user.id)
                        datadogLogs.setGlobalContextProperty('organisation_id', user.organisationId)
                        datadogLogs.setGlobalContextProperty('host', window.location.host)

                        state.active.datadog = true
                    }
                }
            }
        }
    },

    [LOGGING_DATADOG_LOG_INFO_EVENT](_, { message, properties }) {
        datadogLogs.logger.info(message, properties)
    },
}
