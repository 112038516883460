<template>
    <div class="lease-hierarchy-debug__controls d-flex w-100 justify-space-between">
        <!-- These controls are intended for testing only -->
        <ow-button small
                   @click="onShowLogClick">
            <span v-if="showLog">Hide log</span>
            <span v-else>Show log</span>
        </ow-button>
        <ow-button v-if="isLoading"
                   small
                   @click="onStopClick">
            Stop
        </ow-button>
        <ow-button v-else
                   small
                   @click="onRefreshClick">
            Refresh
        </ow-button>
    </div>
    <section v-if="showLog">
        <ul class="lease-hierarchy-debug__log">
            <li v-for="message in logMessages">
                {{ message.timestamp }}: {{ message.message }}
            </li>
        </ul>
        <p v-if="!isLoaded && hasHierarchyNodes">
            OCDA calls made: {{ ocdaCallsCount }},
            Hierarchy depth: {{ hierarchyDepth }}
        </p>
    </section>
</template>

<script lang="ts" setup>
    import { ref } from "vue"

    import OwButton from "@/components/core/ow-button-ds.vue"

    const showLog = ref(false)

    defineProps<{
        isLoading: boolean,
        isLoaded: boolean,
        logMessages: { timestamp: string, message: string }[],
        hasHierarchyNodes: boolean,
        ocdaCallsCount: number,
        hierarchyDepth: number,
    }>()

    const emit = defineEmits<{
        (e: 'refresh'): void,
        (e: 'stop'): void,
    }>()

    const onShowLogClick = () => {
        showLog.value = !showLog.value
    }

    const onRefreshClick = async () => {
        emit('refresh')
    }

    const onStopClick = () => {
        emit('stop')
    }
</script>

<style lang="scss" scoped>
@import 'lease-hierarchy-debug.scss';
</style>

