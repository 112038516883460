<template>
    <v-card class="copilot-autosync h-100 elevation-3">
        <v-card-title class="content__heading w-100 d-flex flex-row justify-space-between align-center">
            {{ $t('matterSettings.copilotAutoSyncDialog.title') }}
            <ow-icon-copilot width="32px"
                             height="32px" />
        </v-card-title>
        <v-card-text>
            <p v-t="'matterSettings.copilotAutoSyncDialog.description'" />
        </v-card-text>
        <ow-card-actions :primary-button-text="$t('action.imInterested')"
                         @click="showRegisterInterestModel = true" />

        <teleport to="body">
            <dialog-register-interest v-model="showRegisterInterestModel"
                                      icon="ow-icon-copilot"
                                      :title="$t('matterSettings.copilotAutoSyncDialog.title')"
                                      heap-track-type="Matter Settings - Copilot Autosync" />
        </teleport>
    </v-card>
</template>

<script setup lang="ts">
    import owIconCopilot from '@/components/core/icons/ow-icon-copilot.vue'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import DialogRegisterInterest from '@/components/dialogs/dialog-register-interest.vue'
    const showRegisterInterestModel = defineModel<boolean>()
</script>

<style scoped>

</style>
