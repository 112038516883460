<template>
    <header class="exported-documents-summary">
        <div v-if="!isLoading"
             class="body-copy exported-documents-summary__label">
            <span class="body-regular exported-documents-summary__label--text">
                <span>{{ $t('label.showing') }}</span>
                <!-- Filtered document count-->
                <span data-test="exported-documents-summary-total-count">
                    {{ $t('documents.library.documentsCount', totalDocumentsCount) }}
                </span>
            </span>
        </div>
        <div class="exported-documents-summary__actions">
            <ow-button :disabled="!hasSelectedDocuments"
                       :is-loading="loadingBulkDownload"
                       :outlined="true"
                       class="documents-summary__reporting-button"
                       data-test="document-summary-download-as-pdf"
                       @click="downloadXlsx">
                <v-icon>$download</v-icon>
                {{ $t('action.downloadAsXlsx') }} {{ hasSelectedDocuments ? `(${selectedDocuments.length})` : '' }}
            </ow-button>
            <ow-button v-t="'action.refresh'"
                       is-secondary
                       data-test="exported-documents-summary-refresh-exports-btn"
                       data-track="DOCUMENTS - Refresh exported documents"
                       @click="refreshDocuments" />
        </div>
    </header>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'

    const EVENTS = {
        downloadAsXlsx: 'download-as-xlsx-clicked',
        refreshDocuments: 'refresh-documents',
    }

    export default {
        name: 'ExportedDocumentsSummary',

        components: {
            OwButton,
        },

        props: {
            documents: {
                type: Array,
                required: true,
            },
            selectedDocuments: {
                type: Array,
                required: false,
                default: () => [],
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            loadingBulkDownload: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        emits: Object.values(EVENTS),

        computed: {
            hasSelectedDocuments(): boolean {
                return this.selectedDocuments.length > 0
            },
            totalDocumentsCount(): number {
                return this.documents.length
            },
        },

        methods: {
            refreshDocuments(): void {
                this.$emit(EVENTS.refreshDocuments)
            },
            downloadXlsx() {
                this.$emit('download-as-xlsx-clicked')
            },
        },
    }
</script>

<style lang="scss">
    @import './exported-documents-summary';
</style>
