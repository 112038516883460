import { IOrganisationHubState } from '@/interfaces/store/organisation-hub/organisation-hub-state.interface'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { TrackedTitle } from '@/interfaces/store/organisation-hub/tracked-title'

const state: IOrganisationHubState = {
    isSubscribed: false,
    trackedTitles: new Array<TrackedTitle>(),
    hub: null,
    messages: [],
    error: null,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
