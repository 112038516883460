<template>
    <ow-clipboard :text="content"
                  scrollable
                  class="review-assistant-register-record-entry"
                  @clicked="logClip(entryNumber)"
                  @scrolled="handleScroll">
        <ow-text-highlight :queries="searchQueries"
                           class="body-regular"
                           data-test="review-assistant-register-record-entry-text"
                           @match-count-changed="matchCount = $event">
            {{ content }}
        </ow-text-highlight>
    </ow-clipboard>
</template>

<script lang="ts">
    import { mapActions } from 'vuex'

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import { useCompromise } from '@/composables/use-compromise'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    const EVENTS = {
        MATCH_COUNT_CHANGED: 'match-count-changed',
        SCROLLED: 'scrolled',
    }

    export default {
        components: {
            OwClipboard,
            OwTextHighlight,
        },

        props: {
            entryNumber: {
                type: String,
                required: true,
            },

            content: {
                type: String,
                required: true,
            },

            searchFilter: {
                type: String,
                required: true,
            },

            highlightDates: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        setup() {
            const { compromise } = useCompromise()
            return {
                compromise,
            }
        },

        data() {
            return {
                matchCount: 0,
            }
        },

        computed: {
            dateFilters(): string[] {
                const doc: any = this.compromise(this.content)
                const dateTerms: string[] = doc
                    .dates()
                    .json()
                    .map(({ text }) => text)

                return dateTerms
            },

            searchQueries(): string[] {
                if (this.highlightDates) {
                    return [
                        this.searchFilter,
                        ...this.dateFilters,
                    ]
                }

                return [this.searchFilter]
            },
        },

        watch: {
            matchCount(value) {
                this.$emit(EVENTS.MATCH_COUNT_CHANGED, value)
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            logClip(entryNr: string) {
                this.logHeapEvent({
                    type: 'REVIEW-ASSISTANT - Copy Register Entry',
                    metadata: { content: entryNr },
                })
            },

            handleScroll() {
                this.$emit(EVENTS.SCROLLED)
            },
        },
    }
</script>

<style lang="scss">
    @import 'register-record-entry.scss';
</style>
