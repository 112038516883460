import {
    AUTH_MUTATE_ERROR,
    AUTH_MUTATE_LOADING,
    AUTH_MUTATE_USER,
} from '@/store/modules/auth-oidc/types'

export default {
    [AUTH_MUTATE_USER](state, user) {
        if (user) {
            state.isAuthenticated = true
            state.user = user
        } else {
            state.isAuthenticated = false
            state.user = null
        }
    },

    [AUTH_MUTATE_LOADING](state, isLoading) {
        state.loading = isLoading
    },

    [AUTH_MUTATE_ERROR](state, errorMessage) {
        state.error = errorMessage
    },
}
