import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { LayerNames } from '@/consts/map-layers'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { Vector as VectorLayer } from 'ol/layer'
import olMap from 'ol/Map'
import { Vector as VectorSource } from 'ol/source'
import {
    Stroke,
    Style,
} from 'ol/style'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import Geometry from 'ol/geom/Geometry'
import { getOWStyleFromOLStyle } from '@/utils/map-utils'
import { MatterTitleBoundaryLayerGroup } from '@/store/modules/map/layers/title-boundary-layer/layer-group'

export type TitleSelectionLayerParams = {
    getSelectedTitleFeaturesFn: () => Array<Feature<Geometry>>
    snapshotConfig?: LayerSnapshotModel
    targetMap: olMap
}

/**
 * Used to indicate selected titles on the map.
 * Consider refactoring the titles store module to move more layer and map logic here.
 */
export class TitleSelectionLayer implements IMapSnapshotLayer {
    public readonly getSelectedTitleFeaturesFn: () => Array<Feature<Geometry>>
    name: string = LayerNames.TitleSelectionLayer
    private readonly layer: VectorLayer<VectorSource>
    private readonly args: TitleSelectionLayerParams

    constructor(args: TitleSelectionLayerParams) {
        this.args = args
        if (args.snapshotConfig) {
            const geoJson = JSON.parse(args.snapshotConfig.configJson).geoJson
            const features = new GeoJSON().readFeatures(geoJson, {
                featureProjection: CoordinateSystemCode.EPSG27700,
                dataProjection: CoordinateSystemCode.EPSG4326,
            })
            this.getSelectedTitleFeaturesFn = () => {
                return features
            }
        } else {
            this.getSelectedTitleFeaturesFn = args.getSelectedTitleFeaturesFn
        }
        this.layer = new VectorLayer({
            zIndex: 13, // Match @/store/modules/matter/actions.js#L800
            source: new VectorSource(),
            style: feature => {
                if (this.args.snapshotConfig) {
                    return this.style
                }
                const titleNumber = feature.get('title_no')
                const selectedTitleFeatures = args.getSelectedTitleFeaturesFn()
                const highlightedTitleNumber = selectedTitleFeatures[selectedTitleFeatures.length - 1]?.get('title_no')
                if (titleNumber !== highlightedTitleNumber) {
                    return null
                } else {
                    return this.style
                }
            },
        })
        if (this.args.snapshotConfig) {
            this.layer.getSource().addFeatures(this.getSelectedTitleFeaturesFn())
        }
        this.layer.set('name', this.name)
        this.layer.set('getOwLayer', () => this)
    }

    private style:Style = new Style({
        zIndex: 1,
        stroke: new Stroke({
            color: '#ff0000',
            width: 3,
        }),
    })

    getIsLoading(): boolean {
        return this.layer.getSource().getFeatures().length === 0
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        const titleNumbers = this.getSelectedTitleFeaturesFn().map(feature => feature.get('title_no'))
        if (titleNumbers.length === 0) {
            return []
        }
        return [{
            id: 'title-selection',
            label: [...new Set(titleNumbers)].join(', '),
            style: getOWStyleFromOLStyle(this.style),
        }]
    }

    getLayer(): VectorLayer<VectorSource> {
        return this.layer
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        if (this.getSelectedTitleFeaturesFn().length === 0 ||
            !this.layer.getVisible() ||
            this.layer.getSource().getFeatures().length === 0) {
            return null
        }

        // Assume we only want the last added title - the current implementation of the title selection layer (see title actions) is old and needs replacing.
        const titleNumber = this.getSelectedTitleFeaturesFn().map(feature => feature.get('title_no')).pop()

        /**
         * If the selected title is present on the map as part of the matter titles layer
         * (yes, another reason this title selection layer needs to go), then we don't need to
         * add it to the snapshot config, assuming it's visible.
         */
        const matterTitlesLayer = this.args.targetMap.getLayers().getArray()
            .find(layer => layer.get('name') === LayerNames.MatterTitles)
        if (matterTitlesLayer) {
            const mtLayer = matterTitlesLayer.get('getOwLayer')() as MatterTitleBoundaryLayerGroup
            if (mtLayer.hasVisibleTitleNumber(titleNumber)) {
                return null
            }
        }

        const features = this.getSelectedTitleFeaturesFn().filter(feature => feature.get('title_no') === titleNumber)

        const featureGeoJson = new GeoJSON().writeFeatures(features, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        })
        const titleNumbers = this.getSelectedTitleFeaturesFn().map(feature => feature.get('title_no'))
        if (titleNumbers.length === 0) {
            return null
        }
        const result = {
            id: 'title-selection',
            name: this.name,
            configJson: JSON.stringify({
                geoJson: featureGeoJson,
            }),
        }
        return result
    }

    getVisible(): boolean {
        return this.layer.getVisible()
    }

    setVisible(visible: boolean, targetMap?: olMap): Promise<void> {
        this.layer.setVisible(visible)
        if (targetMap && !targetMap.getLayers().getArray().includes(this.layer)) {
            targetMap.addLayer(this.layer)
        }
        return Promise.resolve()
    }
}
