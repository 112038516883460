import {
    NOTIFICATIONS_MUTATE_ADD_NOTIFICATION,
    NOTIFICATIONS_MUTATE_CLEAR_TOASTER,
} from '@/store/modules/notifications/types'
import { INotification } from '@/store/modules/notifications/types/notification.interface'
import { INotificationState } from '@/store/modules/notifications/types/notification-state.interface'

export default {
    [NOTIFICATIONS_MUTATE_ADD_NOTIFICATION](state: INotificationState, notification: INotification) {
        if (state.toaster) {
            state.toaster.isVisible = false
        }
        setTimeout(() => {
            state.notifications.push(notification)
            state.toaster = {
                ...notification.toaster,
                isVisible: true,
            }
        }, 300)
    },

    [NOTIFICATIONS_MUTATE_CLEAR_TOASTER](state: INotificationState) {
        if (state.toaster) {
            state.toaster.isVisible = false
        }
        setTimeout(() => {
            state.toaster = null
        }, 300)
    },
}
