<template>
    <div :class="{ 'is-full-screen': isFullScreen }"
         class="copies-filed-document-table__container">
        <div v-if="!isPreviouslyOrderedDocumentView"
             class="copies-filed-document-table__title-wrapper--with-ordering">
            <h2 class="copies-filed-document-table__title label-caps-medium">
                Documents referred to in the register
            </h2>

            <div class="copies-filed-document-table__html-limit-error">
                <p v-if="shouldRetry">
                    {{ retryText }}
                </p>
                <ow-button v-if="shouldRetry"
                           is-text
                           is-flat
                           :disabled="disableRetryButton"
                           :is-loading="officialCopiesAvailability.loading"
                           data-test="copies-filed-document-table-retry-button"
                           data-track="TITLE-DETAILS-PANEL - OCDA Retry"
                           @click="onRetryClick">
                    Retry
                </ow-button>
            </div>
            <ow-button v-if="unorderedDocumentCountForActiveTab && !isPreviouslyOrderedDocumentView && isOrderingAllowed"
                       class="copies-filed-document-table__order-button"
                       data-test="copies-filed-document-table-order-button"
                       is-text
                       is-flat
                       @click="orderAllDocumentsInTab">
                Order {{ unorderedDocumentCountForActiveTab }} {{ pluralisedDocumentText }} (£{{
                    unorderedDocumentCountForActiveTab * titleSummaryCharge
                }})
            </ow-button>
        </div>
        <p v-if="showHmlrClosedMessage"
           class="error-text">
            The HMLR portal is open from 6.30am until 11.00pm. Please order documents within this time window.
        </p>
        <div v-if="isPreviouslyOrderedDocumentView"
             class="copies-filed-document-table__title-wrapper--without-ordering d-flex align-center gc-2">
            <h6 class="copies-filed-document-table__title label-caps-small">
                Documents you own that can not be reconciled against copies filed at HMLR
            </h6>
            <span class="copies-filed-document-table__info-icon">
                <v-icon>$info</v-icon>
                <ow-tooltip :position="OwTooltipPosition.Top"
                            activator="parent"
                            max-width="400">
                    <span>
                        These are documents you have previously ordered on Orbital Witness.
                        HMLR has recently enriched the data we receive on documents referred in the register,
                        these documents below may be a duplicate of the documents above, but we cannot
                        definitively match them due to the additional data we now receive.
                    </span>
                </ow-tooltip>
            </span>
        </div>

        <div :data-test="dataTestAttribute">
            <ow-tabs v-if="visibleOrderableDocuments.length"
                     class="bg-white"
                     color="primary"
                     show-arrows>
                <v-tab v-for="(tab, i) in tabs"
                       :key="`${ tab.documentType }${ i }`"
                       :data-test="`copies-filed-document-table-tab-${ tab.documentType }`"
                       @click="setActiveTab(tab.documentType)">
                    {{ tab.documentType }} ({{ tab.count }})
                </v-tab>
            </ow-tabs>
            <ow-data-grid :allow-selection="!isPreviouslyOrderedDocumentView && isOrderingAllowed"
                          class="copies-filed-document-table__grid"
                          data-test="copies-filed-document-table"
                          :custom-sort="sortDocuments"
                          :headers="headers"
                          :items="visibleOrderableDocuments"
                          :no-data-text="shouldRetry ? retryText : $t('officialCopiesEmptyStateText')"
                          :sort-by="sortBy"
                          hover
                          item-key="virtualId"
                          fixed-header
                          height="100%"
                          narrow
                          theme="light"
                          @items-selected="handleSelectedDocumentsChange">
                <template v-if="isOrderingAllowed"
                          #customSelectionHeader>
                    <span class="body-copy ml-3">{{ selectedUnorderedDocuments.length }} {{ pluralisedSelectedItemName }} selected</span>
                    <ow-button :disabled="Boolean(selectedUnorderedDocuments.length === 0)"
                               class="copies-filed-document-table__order-button"
                               data-test="copies-filed-document-table-order-button"
                               is-text
                               is-flat
                               @click="orderSelectedDocuments">
                        Order {{ selectedUnorderedDocuments.length }} (£{{
                            selectedUnorderedDocuments.length * titleSummaryCharge
                        }})
                    </ow-button>
                </template>
                <template #item.documentType="{ item }">
                    <td :title="item.documentType"
                        class="copies-filed-document-table__columns--type">
                        {{ item.documentType }}
                    </td>
                </template>
                <template #item.entryNumbers="{ item }">
                    <td :title="item.entryNumbers && item.entryNumbers.join(', ')"
                        class="body-copy copies-filed-document-table__columns--entry-no">
                        {{ item.entryNumbers && item.entryNumbers.join(', ') }}
                    </td>
                </template>
                <template #item.documentDate="{ item }">
                    <td class="body-copy copies-filed-document-table__columns--date hide-in-percy">
                        {{ item.documentDate }}
                    </td>
                </template>
                <template #item.filedUnderField="{ item }">
                    <td class="body-copy copies-filed-document-table__columns--filed-under">
                        <router-link v-if="item.filedUnderField"
                                     :to="filedUnderLinkTo(item)"
                                     class="copies-filed-document-table__filed-under-button"
                                     data-track="TITLE-DETAILS-PANEL - View related title from copies filed tab">
                            {{ item.filedUnderField }}
                        </router-link>
                    </td>
                </template>
                <template #item.status="{ item }">
                    <td class="body-copy copies-filed-document-table__columns--status">
                        <document-ordering :document="item"
                                           :document-type="highLevelDocumentType"
                                           :heap-metadata="singleDocumentHeapMetadata"
                                           :is-ordering-allowed="isOrderingAllowed"
                                           :is-small="true"
                                           :title-number="selectedTitleNumber" />
                        <ow-tooltip :disabled="isOrderButtonTooltipDisabled(item)"
                                    :position="OwTooltipPosition.Top"
                                    activator="parent">
                            <span>{{ orderButtonTooltip(item) }}</span>
                        </ow-tooltip>
                    </td>
                </template>
                <template #item.availability="{ item }">
                    <td class="body-copy copies-filed-document-table__columns--availability">
                        <ow-document-availability-label :hmlr-availability-code="item.hmlrAvailabilityCode"
                                                        :is-ordered="Boolean(item.downloadUrl)" />
                        <div v-if="item.orderStatus === DocumentOrderStatus.AUGMENTATION_FAILED">
                            <span class="product-icon mdi mdi-information-outline mr-2 product-icon">
                                <ow-tooltip :position="OwTooltipPosition.Top"
                                            activator="parent">
                                    <span>{{ $t('officialCopiesAugmentingFailedText') }}</span>
                                </ow-tooltip>
                            </span>
                        </div>
                    </td>
                </template>
            </ow-data-grid>
        </div>
    </div>
</template>

<script lang="ts">
    import pluralize from 'pluralize'
    import {
        PropType,
        ref,
    } from 'vue'
    import { mapState } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OwTabs from '@/components/core/ow-tabs.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import DocumentOrdering from '@/components/documents/document-ordering.vue'
    import {
        HmlDocumentAvailabilityTextForCode,
        HmlrDocumentAvailabilityCode,
    } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DocumentOrderStatus } from "@/consts/document-order-status"
    import { StatusCode } from '@/consts/status-code'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isWithinDateRange } from '@/utils/date-utils'
    import { isDocumentOrderable } from '@/utils/document-ordering-utils'

    export default {
        name: 'CopiesFiledDocumentTable',

        components: {
            DocumentOrdering,
            OwButton,
            OwDataGrid,
            OwDocumentAvailabilityLabel,
            OwTooltip,
            OwTabs,
        },

        mixins: [ FlagsMixin ],

        props: {
            documents: {
                type: Array,
                required: true,
            },
            selectedTitleNumber: {
                type: null as unknown as PropType<string | null>,
                required: true,
            },
            singleDocumentHeapMetadata: {
                type: Object,
                required: false,
                default: null,
            },
            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },
            // Shows the view about previously ordered documents you own that can not be reconciled against copies filed at HMLR
            isPreviouslyOrderedDocumentView: {
                type: Boolean,
                required: false,
                default: false,
            },
            officialCopiesAvailability: {
                type: Object,
                required: false,
            },
            isFullScreen: {
                type: Boolean,
                default: false,
            },
            dataTestAttribute: {
                type: String,
                required: false,
            },
        },

        emits: [
            'order-documents',
            'retry-official-copies',
        ],

        data() {
            return {
                dataGridHeaders: [
                    { title: 'Type', key: 'documentType', class: 'document-type' },
                    { title: 'Entry No.', key: 'entryNumbers' },
                    { title: 'Date', key: 'documentDate', class: 'date' },
                    { title: 'Filed Under', key: 'filedUnderField', class: 'filed-under' },
                    { title: 'Availability', key: 'availability', class: 'availability' },
                    { title: 'Status', key: 'status', class: 'status', sortable: false },
                ],
                selectedDocuments: [],
                activeTab: 'All',
                htmlErrorCountdownValue: 5,
                highLevelDocumentType: HighLevelDocumentType.OC2Document,
                OwTooltipPosition,
                retryInterval: null,
                sortBy: ref([ { key: 'documentType', order: 'asc' } ]),
            }
        },

        computed: {
            DocumentOrderStatus() {
                return DocumentOrderStatus
            },
            ...mapState({
                titleSummaryCharge: (state: any) => state.user.titleSummaryCharge,
                currentMatter: (state: any) => state.matter.currentMatter,
            }),
            pluralisedDocumentText() {
                return pluralize('document', this.unorderedDocumentCountForActiveTab)
            },
            pluralisedSelectedItemName() {
                return pluralize('document', this.selectedUnorderedDocuments.length)
            },
            selectedUnorderedDocuments() {
                return this.filterUnorderedOrderableDocuments(this.selectedDocuments)
            },
            visibleOrderableDocuments() {
                return this.groupedDocuments[this.activeTab]
            },
            groupedDocuments() {
                let index = 0
                return Object.assign(
                    { All: this.documents.filter(a => a.documentType !== '') }
                    , this.documents.reduce((groups, doc) => {
                        const group = (groups[doc.documentType] || [])
                        doc.virtualId = index
                        doc.isSelectable = isDocumentOrderable(doc.orderStatus, doc.hmlrAvailabilityCode) // controls vuetify checkbox disabled state.
                        index++
                        group.push(doc)
                        groups[doc.documentType] = group
                        return groups
                    }, {}))
            },
            tabs() {
                return Object.keys(this.groupedDocuments)
                    .map(documentType => {
                        return {
                            documentType,
                            count: this.groupedDocuments[documentType].length,
                        }
                    })
            },
            unorderedDocumentCountForActiveTab() {
                return this.filterUnorderedOrderableDocuments(this.visibleOrderableDocuments).length
            },
            headers() {
                if (!this.isPreviouslyOrderedDocumentView) {
                    return this.dataGridHeaders
                }
                return this.dataGridHeaders.filter(header => header.key !== 'availability')
            },
            retryText() {
                if (this.htmlErrorCountdownValue > 0) {
                    if (this.hmlrTimeout) {
                        return `The request to HLMR timed out, please retry in ${ this.htmlErrorCountdownValue } seconds.`
                    }
                    return `The HMLR server is busy, please retry in ${ this.htmlErrorCountdownValue } seconds.`
                }
                if (this.hmlrTimeout) {
                    return 'The request to HLMR timed out, please retry.'
                }
                return 'The HMLR server was busy, please retry.'
            },

            rateLimited() {
                return this.officialCopiesAvailability?.results.hmlrDocumentAvailabilityStatusRateLimited || false
            },

            hmlrTimeout() {
                return this.officialCopiesAvailability?.results.hmlrDocumentAvailabilityStatusCode === StatusCode.GATEWAY_TIMEOUT || false
            },

            shouldRetry() {
                return this.rateLimited || this.hmlrTimeout
            },

            disableRetryButton() {
                return this.htmlErrorCountdownValue > 0
            },

            showHmlrClosedMessage() {
                // Use the browser's internationalisation API
                // Using `en-US` so that the date comes out in `MM/dd/yyy` format which new Date() can parse.
                const intlDateObj = new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'short',
                    timeStyle: 'short',
                    timeZone: 'Europe/London',
                })
                const now = new Date()
                const openingTimes = {
                    opens: new Date(new Date().setHours(6, 30, 0, 0)),
                    closes: new Date(new Date().setHours(23, 0, 0, 0)),
                }
                const londonTimeNow = new Date(intlDateObj.format(now))
                const openingTime = new Date(intlDateObj.format(openingTimes.opens))
                const closingTime = new Date(intlDateObj.format(openingTimes.closes))
                const isOpen = isWithinDateRange(londonTimeNow, openingTime, closingTime)

                return !isOpen
            },
        },

        watch: {
            shouldRetry(val) {
                if (val) {
                    this.startRetryCountdown()
                }
            },
        },

        mounted() {
            if (this.shouldRetry) {
                this.startRetryCountdown()
            }
        },

        methods: {
            handleSelectedDocumentsChange(selectedDocumentsIndices: any[]) {
                this.selectedDocuments = this.documents.filter((doc, idx) => selectedDocumentsIndices.includes(idx))
            },

            filedUnderLinkTo(item: any) {
                const mapRoute = {
                    name: Route.MatterMapTitle,
                    params: {
                        titleNumber: item.filedUnderField,
                        matterId: this.currentMatter.id,
                    },
                }
                const titleDetailsRoute = {
                    name: Route.TitleDetails,
                    params: {
                        titleNumber: item.filedUnderField,
                    },
                }
                const documentLibraryRoute = {
                    name: Route.DocumentsLibraryTitleDetails,
                    params: {
                        titleNumber: item.filedUnderField,
                        matterId: this.currentMatter.id,
                    },
                }
                const tamRoute = {
                    name: Route.AnalysisTitleDetails,
                    params: {
                        titleNumber: item.filedUnderField,
                        matterId: this.currentMatter.id,
                    },
                }

                switch (this.$route.name) {
                    case Route.DocumentsLibraryTitleDetails:
                        return documentLibraryRoute
                    case Route.AnalysisTitleDetails:
                        return tamRoute
                    case Route.MatterMapTitle:
                        return mapRoute
                    default:
                        return titleDetailsRoute
                }
            },
            filterUnorderedOrderableDocuments(documents?: any[]) {
                if (isNullOrEmpty(documents)) {
                    return []
                }
                return documents.filter(doc => isDocumentOrderable(doc.orderStatus, doc.hmlrAvailabilityCode))
            },
            isOrderButtonTooltipDisabled(item: any) {
                if ((item.hmlrAvailabilityCode === HmlrDocumentAvailabilityCode.Available && this.isOrderingAllowed) || item.filename || item.downloadUrl) {
                    return true
                }

                return this.isOrderingAllowed
            },
            orderButtonTooltip(item: any) {
                if (!this.isOrderingAllowed) {
                    return 'Please add this title to a matter in order to purchase copies filed documents'
                }

                if (item.hmlrAvailabilityCode === HmlrDocumentAvailabilityCode.NotImmediatelyAvailable) {
                    return 'The following documents will require manual processing and may be sent electronically or by post. This will incur no extra charge.'
                }

                if (item.hmlrAvailabilityCode === HmlrDocumentAvailabilityCode.Unavailable) {
                    return 'The document is currently unavailable. Please try again later.'
                }

                return ''
            },
            setActiveTab(documentType: string) {
                this.activeTab = documentType
            },
            orderSelectedDocuments() {
                this.$emit('order-documents', {
                    documents: this.selectedUnorderedDocuments,
                    isSelectedDocuments: true,
                })
            },
            orderAllDocumentsInTab() {
                this.$emit('order-documents', {
                    documents: this.filterUnorderedOrderableDocuments(this.visibleOrderableDocuments),
                    isAllDocuments: this.activeTab === 'All',
                    isDocumentsInGroup: this.activeTab !== 'All',
                })
            },
            documentAvailabilityText(documentAvailabilityCode: string) {
                return HmlDocumentAvailabilityTextForCode[documentAvailabilityCode]
            },
            sortDocuments(items, index, isDescending) {
                items.sort((a, b) => {
                    const sortBy = index[0]
                    if (sortBy === 'documentDate') {
                        if (isDescending[0]) {
                            return new Date(a.documentDateValue).getTime() - new Date(b.documentDateValue).getTime()
                        } else {
                            return new Date(b.documentDateValue).getTime() - new Date(a.documentDateValue).getTime()
                        }
                    } else if (sortBy === 'entryNumbers') {
                        const entryNumberA = a.entryNumbers.join(',')
                        const entryNumberB = b.entryNumbers.join(',')
                        if (isDescending[0]) {
                            return entryNumberA.toLowerCase().localeCompare(entryNumberB)
                        } else {
                            return entryNumberB.toLowerCase().localeCompare(entryNumberA)
                        }
                    } else if (sortBy === 'availability') {
                        const codeA = !(a.filename || a.downloadUrl) ? this.documentAvailabilityText(a.hmlrAvailabilityCode) : 'Ordered'
                        const codeB = !(b.filename || b.downloadUrl) ? this.documentAvailabilityText(b.hmlrAvailabilityCode) : 'Ordered'
                        if (isDescending[0]) {
                            return codeA.toLowerCase().localeCompare(codeB)
                        } else {
                            return codeB.toLowerCase().localeCompare(codeA)
                        }
                    } else {
                        const valueA = a[sortBy]
                        const valueB = b[sortBy]
                        if (isDescending[0]) {
                            return valueA?.toLowerCase().localeCompare(valueB)
                        } else {
                            return valueB?.toLowerCase().localeCompare(valueA)
                        }
                    }
                })

                return items
            },
            onRetryClick() {
                this.$emit('retry-official-copies')
                this.startRetryCountdown()
            },
            startRetryCountdown() {
                this.htmlErrorCountdownValue = 5
                this.retryInterval = setInterval(() => {
                    if (this.htmlErrorCountdownValue === 0) {
                        this.clearRetryInterval()
                    }
                    this.htmlErrorCountdownValue--
                }, 1000)
            },
            clearRetryInterval(): void {
                if (this.retryInterval) {
                    clearInterval(this.retryInterval)
                }
            },
        },
    }
</script>

<style scoped lang="scss">
@import './copies-filed-document-table';
</style>
