/* eslint-disable */

import olMap from 'ol/Map'
import VectorLayer from 'ol/layer/Vector'
import Point from 'ol/geom/Point'
import VectorSource from 'ol/source/Vector'
import { Translate } from 'ol/interaction'
import Feature from 'ol/Feature'
import { TranslateEvent } from 'ol/interaction/Translate'
import { OverlayModel } from '@/components/map/overlays/overlays-types'
import {
    calculateCentrePoint,
    getCoordinatesFromExtent,
    getGeoImage,
    getRectangleFeatureFromCoordinates,
    midpoint,
    rotateCoordinates,
    rotateCoordinatesAroundCentre,
    rotateExtentAroundCentre,
    rotateExtentAroundFixedCentre,
    rotatePoint,
    scaleRectangleKeepPointFixed,
    validBoundingBox,
    validCentrePoint,
} from '@/utils/map-utils'
import GeoJSON from 'ol/format/GeoJSON'
import { GeoImage } from '@/store/modules/map/layers/geo-image/geo-image-layer'
import { OverlaysCropLayer } from './overlays-crop-layer'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { toRaw } from 'vue'
import { degreesToRadians } from '@turf/helpers'
import { drawImageMask } from '@/utils/overlays-utils'
import { Polygon } from 'ol/geom'

export interface OverlayingPlansServiceParams {
    targetMap: olMap
    overlay: OverlayModel,
    updateCallback: () => void
    imageLoadedCallback: () => void
}

enum ExtentPoint {
    TopLeft,
    TopRight,
    BottomRight,
    BottomLeft,
}
const ExtentPoints = [
    ExtentPoint.TopLeft,
    ExtentPoint.TopRight,
    ExtentPoint.BottomRight,
    ExtentPoint.BottomLeft,
]

export class OverlaysService {
    public initialised = false
    public overlay: OverlayModel
    private targetMap: olMap
    public imageExtent: number[]
    public imageLayer: GeoImage
    public cropLayer: OverlaysCropLayer;
    public cropLayerRotation: number;
    public interactionLayer: VectorLayer<VectorSource>
    public hintLayer: VectorLayer<VectorSource>
    public translateInteraction: Translate
    public image: HTMLImageElement
    public aspectRatio: number
    public updateCallback: () => void
    public imageLoadedCallback: () => void
    private cachedImage: Record<string, Blob> = {}
    private cropExtent: any
    private originalImageExtent: any

    constructor(params: OverlayingPlansServiceParams) {
        this.overlay = params.overlay
        this.targetMap = params.targetMap
        this.updateCallback = params.updateCallback
        this.imageLoadedCallback = params.imageLoadedCallback
    }

    public async initialise() {
        // Load the image and calculate extent etc.
        this.image = new Image()
        this.image.onload = async () => {
            this.imageExtent = this.calculateImageExtent()
            this.cropExtent = this.imageExtent
            this.originalImageExtent = this.imageExtent
            this.cropLayerRotation = this.overlay.rotation
            this.aspectRatio = this.calculateAspectRatio(this.imageExtent)

            // Image layer
            this.imageLayer = getGeoImage(
                this.overlay.sourceImageUrl,
                this.imageExtent,
                this.overlay.opacity,
                this.overlay.rotation,
                this.overlay.id)

            if (this.overlay.visibleAreaGeoJson !== null) {
                try {
                    const feature = this.getCropExtentFeature()

                    this.cropExtent = feature.getGeometry().getExtent()
                    this.imageLayer.getSource().setCropExtent(this.cropExtent, this.overlay.rotation)
                    this.imageLayer.getSource().setUseCropExtent(true)
                }
                catch {
                    console.error('error reading geojson')
                }
            }

            this.imageLayer.on('sourceready', () => this.imageLoadedCallback())

            this.imageLayer.on('postrender', (e) => this.drawOverlayImageMask(e))

            this.targetMap.addLayer(this.imageLayer)

            // Interaction layer
            this.interactionLayer = new VectorLayer({
                source: new VectorSource(),
                zIndex: 350,
                style: {
                    'fill-color': 'rgba(255, 255, 255, 0.01)',
                    'circle-radius': 12,
                    'circle-fill-color': 'rgba(255, 255, 255, 0.5)',
                    'circle-stroke-color': 'rgba(0, 0, 0, 0.7)',
                },
            })

            this.targetMap.addLayer(this.interactionLayer)
            this.targetMap.on('pointermove', (e) => {
                const features = this.targetMap.getFeaturesAtPixel(e.pixel)
                if (features && features.length > 0) {
                    this.targetMap.getTargetElement().style.cursor = 'pointer'
                } else {
                    this.targetMap.getTargetElement().style.cursor = ''
                }
            })

            // Modify interaction - drag the invisible points, based on the location, update the hint ones
            // so as to maintain aspect ratio.
            this.setTranslateInteraction()

            this.updateImageSource(this.imageExtent)
            // Initialise vector layers for the given image.
            this.resetVectorLayers()
        }

        this.image.src = this.overlay.sourceImageUrl
        this.initialised = true
    }

    private setTranslateInteraction = () => {
        this.translateInteraction = new Translate({
            layers: [toRaw(this.interactionLayer)],
        })
        this.targetMap.addInteraction(this.translateInteraction)
        this.translateInteraction.setActive(true)
        this.translateInteraction.on('translating', this.onTranslateImageFromMove.bind(this))

    }

    private calculateAspectRatio(extent: Array<number>): number {
        const width = extent[2] - extent[0]
        const height = extent[3] - extent[1]
        return width / height
    }

    public updateFromScaleRatio() {
        if (!this.imageExtent) {
            return
        }
        // Keep original width for scale ratio calculation
        const originalExtent = this.imageExtent
        // Crop centre is centre of scaling transformation
        const centre = this.getRotatedCropExtentMidpoint()
        // Calculate scale using centre point of image to obtain ratio
        this.imageExtent = this.calculateImageExtent()
        const ratio = (this.imageExtent[2] - this.imageExtent[0]) / (originalExtent[2] - originalExtent[0])
        // Transform around new centre point
        this.imageExtent = scaleRectangleKeepPointFixed(originalExtent, ratio, centre)
        this.updateImageSource(this.imageExtent)
        this.updateCropExtentFromScaleRatio(ratio, centre)
        this.resetVectorLayers()
    }

    private async updateImageSource(imageExtent: number[]) {
        if (!this.imageLayer) {
            return
        }
        this.imageExtent = imageExtent
        this.originalImageExtent = imageExtent
        this.imageLayer.getSource().setImageExtent(imageExtent)
        this.overlay.boundingBox = imageExtent
        this.updateCallback()
    }

    public crop = () => {
        this.translateInteraction.setActive(false)
        this.interactionLayer.getSource().clear()

        this.updateExtentsAfterRotation()

        this.imageLayer.getSource().setUseCropExtent(false)

        this.cropLayer = new OverlaysCropLayer({
            targetMap: this.targetMap,
            overlay: this.overlay,
            cropExtent: this.cropExtent,
            originalImageExtent: this.imageExtent,
            cb: this.onCropEnd.bind(this)
        })

        this.cropLayer.initialise()
    }

    public croppingComplete = () => {
        if (!this.cropLayer) {
            return
        }

        this.cropExtent = this.cropLayer.getCropExtent()

        this.imageLayer.getSource().setCropExtent(this.cropExtent, this.overlay.rotation)
        this.imageLayer.getSource().setUseCropExtent(true)

        this.cropLayerRotation = this.overlay.rotation
        this.onCropEnd()
        this.cropLayer.dispose()
        this.cropLayer = null
        this.translateInteraction.setActive(true)
        this.interactionLayer.setVisible(true)
        this.resetVectorLayers()
        this.setTranslateInteraction()
        this.imageLayer.getSource().changed()
    }

    public onCropEnd() {
        if (!this.cropLayer) {
            return
        }
        const feat = this.cropLayer.getCropFeatureUnrotated()

        const geoJson = new GeoJSON().writeFeature(feat, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        })

        this.overlay.visibleAreaGeoJson = geoJson

        this.updateCallback()
    }


    public resetCropExtent() {
        this.cropExtent = this.originalImageExtent
        this.imageLayer.getSource().setCropExtent(this.cropExtent, this.overlay.rotation)

        const rotatedImageExtent = getCoordinatesFromExtent(this.cropExtent)
        const rectangle = getRectangleFeatureFromCoordinates(rotatedImageExtent)
        const geoJson = new GeoJSON().writeFeature(rectangle, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        })

        this.overlay.visibleAreaGeoJson = geoJson

        this.cropLayer.dispose()
        this.cropLayer = null
        this.translateInteraction.setActive(true)
        this.interactionLayer.setVisible(true)
        this.resetVectorLayers()
        this.updateCallback()
    }

    public translateCropExtentToNewLocation(currentExtent, newExtent) {
        let centerX1 = (currentExtent[0] + currentExtent[2]) / 2;
        let centerY1 = (currentExtent[1] + currentExtent[3]) / 2;

        let centerX2 = (newExtent[0] + newExtent[2]) / 2;
        let centerY2 = (newExtent[1] + newExtent[3]) / 2;

        let deltaX = centerX2 - centerX1;
        let deltaY = centerY2 - centerY1;

        this.cropExtent = [this.cropExtent[0] + deltaX, this.cropExtent[1] + deltaY, this.cropExtent[2] + deltaX, this.cropExtent[3] + deltaY]

        this.setNewCropGeoJson()

        this.imageLayer.getSource().setCropExtent(this.cropExtent, this.overlay.rotation)
    }

    private updateCropExtentFromScaleRatio(ratio, centre) {
        const [centreX, centreY] = centre

        const newMinX = centreX + (this.cropExtent[0] - centreX) * ratio
        const newMinY = centreY + (this.cropExtent[1] - centreY) * ratio
        const newMaxX = centreX + (this.cropExtent[2] - centreX) * ratio
        const newMaxY = centreY + (this.cropExtent[3] - centreY) * ratio

        this.cropExtent = [newMinX, newMinY, newMaxX, newMaxY]

        this.setNewCropGeoJson()

        this.imageLayer.getSource().setCropExtent(this.cropExtent, this.overlay.rotation)
    }

    public rotate = (angle) => {
        if (this.imageLayer) {
            this.overlay.rotation = angle
            this.imageLayer.getSource().setRotation(degreesToRadians(angle))
            this.resetVectorLayers()
        }
    }

    public resetVectorLayers(): void {
        if (!this.interactionLayer) {
            return
        }

        const [bl, br, tl, tr] = rotateExtentAroundFixedCentre(
            this.cropExtent,
            calculateCentrePoint(this.imageExtent),
            -degreesToRadians(this.cropLayerRotation))

        const cropPoints = [tl, tr, br, bl]

        // Interaction layer
        this.interactionLayer.getSource().clear()
        ExtentPoints.forEach((x: ExtentPoint) => {
            const feature = new Feature({
                geometry: new Point(cropPoints[x])
            })
            feature.setId(x)
            this.interactionLayer.getSource().addFeature(feature)
        })

        // Extent has been rotated since cropping
        if (this.overlay.rotation !== this.cropLayerRotation) {
            this.updateExtentsAfterRotation()
        }

        // Add a rectangle corresponding to the extent of the image.
        this.addImageRectangle()
    }

    public setTransparency(opacity: number) {
        this.imageLayer?.setOpacity(opacity)
    }

    public onTranslateImageFromMove(e: TranslateEvent) {
        const geom = e.features.getArray()[0].getGeometry()
        if (geom.getType() === 'Polygon') {
            // Rectangle has been repositioned, relocate the image.
            const currentExtent = this.imageExtent
            const extent = geom.getExtent()
            this.updateImageSource([extent[0], extent[1], extent[2], extent[3]])
            this.translateCropExtentToNewLocation(currentExtent, extent)
            this.updateImagePoints()
        } else {

            const movedPoint: ExtentPoint = e.features.getArray()[0].getId() as ExtentPoint
            let pointCoordinates = (e.features.getArray()[0].getGeometry() as Point).getCoordinates()

            let [minX, minY, maxX, maxY] = this.cropExtent
            const [imageExtentMinX, imageExtentMinY, imageExtentMaxX, imageExtentMaxY] = this.imageExtent

            const centerX = (minX + maxX) / 2
            const centerY = (minY + maxY) / 2

            const imageExtentCenterX = (imageExtentMinX + imageExtentMaxX) / 2
            const imageExtentCenterY = (imageExtentMinY + imageExtentMaxY) / 2

            const originalWidth = maxX - minX
            const imageExtentWidth = imageExtentMaxX - imageExtentMinX

            if (this.overlay.rotation !== 0) {
                pointCoordinates = rotatePoint(
                    [pointCoordinates[0], pointCoordinates[1]],
                    [imageExtentCenterX, imageExtentCenterY],
                    degreesToRadians(this.overlay.rotation))
            }

            const cropToImageExtentRatio = originalWidth / imageExtentWidth

            let newWidth: number
            switch (movedPoint) {
                case ExtentPoint.TopLeft:
                case ExtentPoint.BottomLeft:
                    newWidth = 2 * (centerX - pointCoordinates[0])
                    break

                case ExtentPoint.TopRight:
                case ExtentPoint.BottomRight:
                    newWidth = 2 * (pointCoordinates[0] - centerX)
                    break
            }

            const scaleFactor = newWidth / originalWidth
            const newHeight = (maxY - minY) * scaleFactor

            minX = centerX - newWidth / 2
            maxX = centerX + newWidth / 2
            minY = centerY - newHeight / 2
            maxY = centerY + newHeight / 2

            const imageWidth = maxX - minX
            const scaleRatio = imageWidth / this.overlay.sourceImageWidthmm * 1000

            const actualRatio = scaleRatio / cropToImageExtentRatio

            this.overlay.scale = Math.round(actualRatio)

            const originalExtent = this.imageExtent

            const centre = this.getRotatedCropExtentMidpoint()

            this.imageExtent = scaleRectangleKeepPointFixed(originalExtent, scaleFactor, centre)
            this.updateImageSource(this.imageExtent)
            this.updateCropExtentFromScaleRatio(scaleFactor, centre)
            this.resetVectorLayers()
        }
    }

    public updateImagePoints() {
        const [bl, br, tl, tr] = this.getCropExtentRotatedAroundImageExtentCentre()
        const cropPoints = [tl, tr, br, bl]

        ExtentPoints.forEach((x: ExtentPoint) => {
            const feature = this.interactionLayer.getSource().getFeatureById(x)
            feature.setGeometry(new Point(cropPoints[x]))
        })
    }

    public addImageRectangle() {
        let rectangle = getRectangleFeatureFromCoordinates(
            getCoordinatesFromExtent(this.imageExtent))

        this.interactionLayer.getSource().addFeature(rectangle)
    }

    public calculateImageExtent(useMapCentre: boolean = false): Array<number> {
        // If we have a bounding box stored with the overlay already, use that.
        // Or use a centre point for the overlay, or override and use the map centre point.
        let centrePoint = this.targetMap.getView().getCenter()
        if (!useMapCentre) {
            // ensure centre point is up to date
            const calculatedCentrePoint = this.calculateCentrePoint()
            if (validCentrePoint(calculatedCentrePoint)) {
                centrePoint = calculatedCentrePoint
            } else if (validBoundingBox(this.overlay.boundingBox)) {
                return this.overlay.boundingBox
            }
            else if(this.overlay.centrePoint) {
                centrePoint = this.overlay.centrePoint
            }
             else {
                console.error('Failed to calculate overlay image extent')
            }
        }

        // Convert mm to meters
        const imageWidthInMeters = this.overlay.sourceImageWidthmm / 1000
        const imageHeightInMeters = this.overlay.sourceImageHeightmm / 1000

        // Calculate pixel size for width and height
        const widthPixelSize = imageWidthInMeters / this.image.width
        const heightPixelSize = imageHeightInMeters / this.image.height

        // For now, take an average of the two
        const pixelSizeMetres = (widthPixelSize + heightPixelSize) / 2

        const realWidthInMetres = this.image.width * pixelSizeMetres * this.overlay.scale
        const realHeightInMetres = this.image.height * pixelSizeMetres * this.overlay.scale

        // Calculate bottom left and top right coordinates based on the center point
        const bottomLeftCoord = [
            centrePoint[0] - realWidthInMetres / 2,
            centrePoint[1] - realHeightInMetres / 2,
        ]
        const topRightCoord = [
            centrePoint[0] + realWidthInMetres / 2,
            centrePoint[1] + realHeightInMetres / 2,
        ]

        this.overlay.centrePoint = centrePoint

        return [bottomLeftCoord[0], bottomLeftCoord[1],
        topRightCoord[0], topRightCoord[1]]
    }

    private calculateCentrePoint(): number[] {
        const [minX, minY, maxX, maxY] = this.overlay.boundingBox
        const centerX = (minX + maxX) / 2
        const centerY = (minY + maxY) / 2
        return [centerX, centerY]
    }

    private getRotatedCropExtentMidpoint() {
        const coordinates = this.getCropExtentRotatedAroundImageExtentCentre()
        return midpoint(coordinates[0], coordinates[3])
    }

    private getCropExtentRotatedAroundImageExtentCentre(): any {
        return rotateExtentAroundFixedCentre(
            this.cropExtent,
            calculateCentrePoint(this.imageExtent),
            -degreesToRadians(this.cropLayerRotation))
    }

    private updateExtentsAfterRotation() {
        const newImageExtent = this.getCorrectExtentPositions()

        this.translateCropExtentToNewLocation(this.imageExtent, newImageExtent)

        this.setNewCropGeoJson()

        this.updateImageSource(newImageExtent)
        this.imageLayer.getSource().setCropExtent(this.cropExtent, this.overlay.rotation)
        this.imageLayer.getSource().setUseCropExtent(true)
        this.cropLayerRotation = this.overlay.rotation
        this.imageLayer.getSource().changed()
        this.updateCallback()
    }

    // When rotating around cropped area, the centre point is moved
    // We need to correct the locations of the extents
    private getCorrectExtentPositions = () => {
        const currentCropCentre = calculateCentrePoint(this.cropExtent)
        const cropRotationRadians = degreesToRadians(this.cropLayerRotation)
        const imageExtentRotatioRadians = degreesToRadians(this.overlay.rotation)

        // get the current crop centre point
        // rotate around the current image extent centre
        // this gives the fixed point of rotation for the image extent
        const centreOfRotatedCropExtent = rotatePoint(
            [currentCropCentre[0], currentCropCentre[1]],
            calculateCentrePoint(this.imageExtent),
            -cropRotationRadians)

        const newImageExtent = this.getNewImageExtentPosition(
            cropRotationRadians,
            imageExtentRotatioRadians,
            centreOfRotatedCropExtent)

        return newImageExtent
    }

    private getNewImageExtentPosition = (
        cropRotationRadians,
        imageExtentRotationRadians,
        centreOfRotatedCropExtent) => {

        // rotate image extent around rotated crop centre
        const coordinatesRotatedIntoCorrectLocation = rotateCoordinatesAroundCentre(
            rotateExtentAroundCentre(this.imageExtent, -cropRotationRadians),
            centreOfRotatedCropExtent,
            cropRotationRadians - imageExtentRotationRadians)

        const imageExtentRotatedAroundOwnCentre = rotateCoordinates(
            coordinatesRotatedIntoCorrectLocation,
            imageExtentRotationRadians)

        const correctedImageExtentFeature = getRectangleFeatureFromCoordinates(imageExtentRotatedAroundOwnCentre)

        return correctedImageExtentFeature.getGeometry().getExtent()
    }

    private drawOverlayImageMask = (e) => {
        try {
            if (!this.overlay.visibleAreaGeoJson
                && !this.cropLayer) {
                return
            }

            const feature = this.cropLayer
                ?  this.cropLayer.getCropFeature()
                : this.getRotatedCropFeature()

            if (feature) {
                drawImageMask(e, feature)
            }
        }
        catch {
            console.error('error drawing overlay mask')
        }
    }

    private getRotatedCropFeature = () => {
        const rotatedImageExtent = rotateExtentAroundFixedCentre(
            this.getCropExtentFeature().getGeometry().getExtent(),
            calculateCentrePoint(this.imageExtent),
            -degreesToRadians(this.overlay.rotation))

        return getRectangleFeatureFromCoordinates(rotatedImageExtent)
    }

    private setNewCropGeoJson = () => {
        const rotatedImageExtent = getCoordinatesFromExtent(this.cropExtent)
        const rectangle =  getRectangleFeatureFromCoordinates(rotatedImageExtent)
        const geoJson = new GeoJSON().writeFeature(rectangle, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        })
        this.overlay.visibleAreaGeoJson = geoJson
    }

    private getCropExtentFeature(): Feature<Polygon> {
        return new GeoJSON().readFeature(this.overlay.visibleAreaGeoJson, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326
        }) as Feature<Polygon>
    }

    public dispose() {
        this.initialised = false
        this.targetMap.removeLayer(this.imageLayer)
        this.targetMap.removeLayer(this.interactionLayer)
        this.targetMap.removeInteraction(this.translateInteraction)
        Object.keys(this.cachedImage).forEach((key) => {
            URL.revokeObjectURL(key)
        })
    }

    public highlightOverlay(overlayId: string): void {
        console.info(`Highlighting overlay ${overlayId}`)
        // TODO: implement
    }
}
