import httpClient from "@/api/http-client"
import DocumentsApi from "@/api/documents.api"

enum ReportType {
    Default,
    WithoutExternalData
}

export default class ReportingApi {

    static END_POINT = 'reporting'

    /**
     * Save the bulk download blob to the user's device.
     * @param matterId {number}
     * @param titleNumbers
     */
    static async downloadTitleAnalysisReport(matterId: number, titleNumbers, shouldMergeCellsByTitleNumber: boolean = true) : Promise<any> {
        const resultUrl = `${ this.END_POINT }/excel/titleanalysis`
        const response = await httpClient.post(resultUrl, {matterId, titleNumbers, mergeCellsByTitleNumber: shouldMergeCellsByTitleNumber, reportType: ReportType.Default})
        if (response.status === 201) {
            const documentId = response.data
            await DocumentsApi.downloadDocumentByTypeAndId('Spreadsheet', documentId)
        } else if (response.status === 202) {
            return response.data
        }
    }
}
