<template>
    <v-dialog :model-value="modelValue"
              content-class="reporting-page__exporting-menu-dialog"
              @update:model-value="closeModal">
        <ow-card class="exporting-menu"
                 data-test="exporting-menu">
            <div class="exporting-menu__description">
                <h4 class="exporting-menu__name">
                    {{ template.name }}
                </h4>
                <div v-dompurify-html="template.description"
                     class="body-copy" />
                <exporting-icons :document-icon="documentIcon" />
                <hr class="divider" />
                <div class="exporting-menu__titles">
                    <div class="exporting-menu__titles-heading">
                        <strong>Titles</strong>
                        <div class="exporting-menu__titles-heading-actions">
                            <span class="body-copy mr-2">Showing</span>
                            <ow-checkbox id="exporting-menu-show-purchased"
                                         v-model="showPurchased"
                                         class="mr-2"
                                         label="Purchased"
                                         data-test="exporting-menu-show-purchased-checkbox"
                                         data-track="exporting-menu-show-purchased-checkbox" />
                            <ow-checkbox id="exporting-menu-show-unpurchased"
                                         v-model="showUnpurchased"
                                         label="Unpurchased"
                                         data-test="exporting-menu-show-unpurchased-checkbox"
                                         data-track="exporting-menu-show-unpurchased-checkbox" />
                        </div>
                    </div>
                    <ow-data-grid :headers="dataGridHeaders"
                                  :items="selectableTitles"
                                  :preselected-items="selectedTitleNumbers"
                                  :sort-by="sortBy"
                                  dense
                                  item-key="titleNumber"
                                  hover
                                  item-selectable="isReportingSupported"
                                  @items-selected="handleSelectedTitlesChange">
                        <template #item.titleNumber="{ item }">
                            <span :class="item.isAvailable && item.isReportingSupported ? 'selectable' : 'unselectable'"
                                  :data-test="`exporting-menu__${ item.isSelectable ? 'selectable' : 'unselectable'}-title`">
                                {{ item.titleNumber }}
                            </span>
                        </template>
                        <template #item.addresses="{ item }">
                            <template v-if="item.isAvailable === true">
                                {{ mergeAddresses(item.addresses) }}
                            </template>
                            <div v-else-if="!item.isReportingSupported"
                                 class="unselectable exporting-menu__unselectable-title">
                                {{ mergeAddresses(item.addresses) }}
                                <i aria-hidden="true"
                                   class="material-icons">info_outline
                                    <ow-tooltip activator="parent"
                                                :position="OwTooltipPosition.Right"
                                                nudge-right="4px"
                                                nudge-top="5px">
                                        {{ $t('report.modals.exporting.unsupportedDataProviderTooltipText',
                                              [getDataProviderText(item.dataProvider)]) }}
                                    </ow-tooltip>
                                </i>
                            </div>
                            <div v-else-if="item.isAvailable === false"
                                 class="unselectable exporting-menu__unselectable-title">
                                {{ mergeAddresses(item.addresses) }}
                                <strong>
                                    Order Register
                                </strong>
                            </div>
                            <ow-loading-skeleton v-else
                                                 class="exporting-menu__loading-skeleton"
                                                 data-test="exporting-menu__loading-title"
                                                 height="100%" />
                        </template>
                    </ow-data-grid>
                </div>
            </div>
            <div class="exporting-menu__exports">
                <h6>
                    <strong>Exports</strong>
                </h6>
                <hr class="divider" />
                <div class="exporting-menu__exports-list body-copy">
                    <div v-if="!isNullOrEmpty(selectedAvailableTitleNumbers)"
                         class="exporting-menu__exports-list-selectable">
                        <div v-for="item in selectedAvailableTitleNumbers"
                             :key="item">
                            <div class="flex-space-between">
                                <div>
                                    {{ item }}
                                </div>
                                <div data-test="exporting-menu__charge">
                                    Free
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="!isNullOrEmpty(selectedTitleNumbersToOrder)">
                        <h6>Order from HMLR</h6>
                        <div class="exporting-menu__exports-list-unselectable">
                            <div v-for="item in selectedTitleNumbersToOrder"
                                 :key="item">
                                <div class="flex-space-between">
                                    <div>
                                        {{ item }}
                                    </div>
                                    <div data-test="exporting-menu__charge">
                                        £{{ titleSummaryCharge }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <hr class="divider" />
                <div class="exporting-menu__exports-total">
                    <div class="flex-space-between">
                        <strong>Total</strong>
                        <strong data-test="exporting-menu__total-charge">{{ totalCharge }}</strong>
                    </div>
                    <div class="exporting-menu__exports-buttons">
                        <ow-button data-test="btn-export-cancel"
                                   data-track="REPORTING - Cancel export"
                                   is-secondary
                                   class="exporting-menu__exports-cancel"
                                   @click="closeModal">
                            Cancel
                        </ow-button>
                        <ow-button :disabled="selectedTitles.length === 0 || exporting"
                                   :is-loading="exporting"
                                   is-primary
                                   :class="{'elevation-2': selectedTitles.length > 0}"
                                   data-test="btn-export-confirm"
                                   data-track="REPORTING - Confirm export"
                                   @click="handleExport">
                            Export {{ !isNullOrEmpty(selectedTitles) ? `(${totalCharge})` : '' }}
                        </ow-button>
                    </div>
                </div>
            </div>
        </ow-card>
    </v-dialog>
</template>

<script>
    import { ref } from 'vue'

    import OwButton from '@/components/core/ow-button-ds'
    import OwCard from '@/components/core/ow-card'
    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import OwDataGrid from '@/components/core/ow-data-grid'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton'
    import ExportingIcons from '@/components/reporting/exporting-icons'
    import { getDataProviderText } from '@/consts/data-provider'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { DOC_TYPE_ENUM } from "@/enums/reporting-api.enum"
    import {isNullOrEmpty} from '@/utils/array-utils'

    import OwTooltip from '../core/ow-tooltip.vue'

    const EVENTS = {
        CANCEL: 'cancel',
        EXPORT: 'export',
        UPDATE: 'update:modelValue',
    }

    export default {
        name: 'ExportingMenu',
        components: {
            OwCheckbox,
            OwTooltip,
            ExportingIcons,
            OwButton,
            OwCard,
            OwDataGrid,
            OwLoadingSkeleton,
        },
        props: {
            modelValue: {
                type: Boolean,
                required: true,
            },
            template: {
                type: Object,
                required: true,
            },
            titles: {
                type: Array,
                required: true,
            },
            preselectedTitleNumbers: {
                type: Array,
                default: () => [],
                required: false,
            },
            exporting: {
                type: Boolean,
                default: false,
                required: false,
            },
            titleSummaryCharge: {
                type: Number,
                default: 3,
                required: false,
            },
            documentIcon: {
                type: String,
                required: false,
                default: DOC_TYPE_ENUM.DOC,
            },
        },
        emits: Object.values(EVENTS),

        data() {
            return {
                dataGridHeaders: [
                    {
                        title: 'Select All',
                        key: 'titleNumber',
                        width: '88px',
                    },
                    {
                        key: 'addresses',
                    },
                ],

                selectedTitles: this.titles.filter(title => this.preselectedTitleNumbers.includes(title.titleNumber)),
                sortBy: ref([{key: 'titleNumber', order: 'asc'}]),
                showPurchased: false,
                showUnpurchased: false,
                OwTooltipPosition,
            }
        },
        computed: {
            selectedTitleNumbers() {
                return isNullOrEmpty(this.selectedTitles) ? undefined : this.selectedTitles.map(t => t.titleNumber)
            },
            selectedAvailableTitleNumbers() {
                return this.titles
                    .filter(title => title.isAvailable === true)
                    .filter(({titleNumber}) => this.selectedTitles.find(selectedTitle => selectedTitle.titleNumber === titleNumber))
                    .map(title => title.titleNumber)
            },
            selectedTitleNumbersToOrder() {
                return this.titles
                    .filter(title => title.isAvailable === false)
                    .filter(({titleNumber}) => this.selectedTitles.find(selectedTitle => selectedTitle.titleNumber === titleNumber))
                    .map(title => title.titleNumber)
            },
            selectableTitles() {
                const allItems = this.titles.map(title => ({
                    ...title,
                    isSelectable: title.isAvailable !== undefined && title.isReportingSupported,
                }))
                if (this.showPurchased && !this.showUnpurchased) {
                    return allItems.filter(title => title.isAvailable === true && title.isReportingSupported)
                }
                if (this.showUnpurchased && !this.showPurchased) {
                    return allItems.filter(title => title.isAvailable !== true && title.isReportingSupported)
                }
                return allItems
            },
            totalCharge() {
                const cost = this.selectedTitleNumbersToOrder.length * this.titleSummaryCharge

                return cost === 0 ? 'Free' : `£${ cost }`
            },
        },
        watch: {
            preselectedTitleNumbers(titleNumbers) {
                this.selectedTitles = this.titles.filter(title => titleNumbers.includes(title.titleNumber))
            },
        },
        methods: {
            isNullOrEmpty,
            getDataProviderText,
            handleSelectedTitlesChange(selectedTitles) {
                this.selectedTitles = isNullOrEmpty(selectedTitles) ? selectedTitles : this.titles.filter(({ titleNumber }) => selectedTitles.includes(titleNumber))
            },

            async handleExport() {
                this.$emit(EVENTS.EXPORT, [...this.selectedAvailableTitleNumbers, ...this.selectedTitleNumbersToOrder])
            },

            closeModal() {
                this.$emit(EVENTS.UPDATE, false)
                this.$emit(EVENTS.CANCEL)
                this.clearSelection()
            },

            clearSelection() {
                this.selectedTitles = []
            },

            mergeAddresses(addresses) {
                if (isNullOrEmpty(addresses)) {
                    return 'N/A'
                }

                return addresses[0]
            },
        },
    }
</script>

<style lang="scss">
@import 'exporting-menu.scss';
</style>
