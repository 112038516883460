<template>
    <section class="title-panel-card overview"
             data-section="overview">
        <div class="title-panel-card__content">
            <div class="overview__header-container">
                <h2 v-t="'titlePanel.cards.basicInformation.title'"
                    class="label-caps-medium overview__content--title" />

                <ow-feature-toggle v-if="isFullScreenToggleVisible && !toggleTitlePanelWidthButton"
                                   class="label-caps-medium overview__content--toggle"
                                   :data-test="'title-panel-v2-toggle-fullscreen'"
                                   :data-track="'TITLE-PANEL: toggle v2 panel off'"
                                   :feature-key="titlePanelV2Key"
                                   :feature-enabled="isTitlePanelV2Enabled"
                                   :left-label="$t('titlePanel.cards.overview.fullscreenToggle')" />
            </div>

            <ul class="card-content overview__content">
                <li class="card-content__row">
                    <span v-t="'label.titleNumber'"
                          class="label-caps-smallest-strong card-content__row--label" />
                    <span class="body-highlight title-number card-content__row--value">
                        <OwClipboard :text="selectedTitleNumber"
                                     data-test="title-details-panel-overview-title-number"
                                     data-track="TITLE-DETAILS-PANEL - Copy title number info">
                            {{ selectedTitleNumber }}
                        </OwClipboard>
                    </span>
                </li>

                <li class="card-content__row">
                    <span v-t="'label.address'"
                          class="label-caps-smallest-strong card-content__row--label" />
                    <span class="body-regular address card-content__row--value">
                        <ow-loading-skeleton v-if="isLoading"
                                             data-test="title-details-panel-loading"
                                             height="24px"
                                             width="100%" />
                        <template v-else>
                            <span v-if="hasAddresses">
                                <OwClipboard :text="addressCopyText"
                                             data-test="title-details-panel-overview-address"
                                             data-track="TITLE-DETAILS-PANEL - Copy Address info">
                                    <ul>
                                        <li v-for="address in allAddresses"
                                            :key="address">
                                            {{ address }}
                                        </li>
                                    </ul>
                                </OwClipboard>
                            </span>
                            <span v-else
                                  v-t="'titlePanel.cards.basicInformation.noAddress'"
                                  class="body-regular-small"
                                  data-test="title-details-panel-overview-address" />
                        </template>
                    </span>
                </li>

                <li class="card-content__row">
                    <span v-t="'label.tenure'"
                          class="label-caps-smallest-strong card-content__row--label" />
                    <span class="body-regular tenure card-content__row--value">
                        <ow-loading-skeleton v-if="isLoading"
                                             data-test="title-details-panel-loading"
                                             height="24px"
                                             width="100%" />
                        <OwClipboard v-else
                                     :text="tenureCopyText"
                                     class="tenure"
                                     data-test="title-details-panel-overview-tenure"
                                     data-track="TITLE-DETAILS-PANEL - Copy tenure info">
                            <div :class="{
                                     'leasehold': isLeasehold,
                                     'freehold': isFreehold,
                                     'other': isOtherTenure,
                                 }"
                                 class="tenure-type" />
                            {{ tenure }}
                            <span v-if="estateInterest">
                                - {{ estateInterest }}
                            </span>
                        </OwClipboard>
                    </span>
                </li>

                <li v-if="hasLeaseTerm"
                    class="card-content__row">
                    <span v-t="'label.leaseTerm'"
                          class="label-caps-smallest-strong card-content__row--label" />
                    <span class="body-regular-small lease-term card-content__row--value">
                        <ow-loading-skeleton v-if="isLoading"
                                             data-test="title-details-panel-loading"
                                             height="24px"
                                             width="100%" />
                        <OwClipboard v-else
                                     :text="leaseTermCopyText"
                                     data-test="title-details-panel-overview-lease-term"
                                     data-track="TITLE-DETAILS-PANEL - Copy lease term info">
                            <ul>
                                <li v-for="term in leaseTermText"
                                    :key="term">
                                    {{ term }}
                                </li>
                            </ul>
                        </OwClipboard>
                    </span>
                </li>
            </ul>

            <p v-if="showUnregisteredLandWarning"
               class="unregistered">
                <span v-t="'titlePanel.cards.basicInformation.intersectsUnregisteredLand'"
                      class="label-caps-smallest-strong" />
                &nbsp;
                <span v-if="intersectsUnregisteredLand"
                      v-t="'label.yes'"
                      class="label-caps-smallest unregistered--error" />
                <span v-else
                      v-t="'label.no'"
                      class="label-caps-smallest unregistered--ok" />
            </p>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useRoute } from 'vue-router'
    import { useStore } from 'vuex'

    import TitleInformationApi from '@/api/title-information.api'
    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import owFeatureToggle from '@/components/core/ow-feature-toggle.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { ITitlePanelTabController } from '@/composables/use-title-panel'
    import { titlePanelV2Key } from '@/consts/feature-keys'
    import { Route } from '@/enums/route.enum'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import { checkFlag } from '@/feature-flags'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const props = defineProps<{
        isLoading?: boolean,
        tabController: ITitlePanelTabController
    }>()

    props.tabController.addTabSection(TitlePanelTabName.Summary, {
        id: 'overview',
        order: 0,
    })

    const {
        selectedTitleNumber,
        allAddresses,
        tenure,
        isLeasehold,
        isFreehold,
        isOtherTenure,
        estateInterest,
        leaseTermText,
    } = useSelectedTitle()

    const store = useStore()

    const isTitlePanelV2Enabled = computed(() => store.state.config.featureFlags?.titlePanelV2)
    const isTitlePanelV2ToggleEnabled = computed(() => store.state.config.featureFlags?.showTitlePanelToggle)
    const toggleTitlePanelWidthButton = computed( () => checkFlag('toggle-title-panel-width', false))

    const tenureCopyText = computed(() => {
        return `${ tenure } ${ estateInterest ? ` - ${ estateInterest }` : '' }`
    })

    const hasLeaseTerm = computed(() => !isNullOrEmpty(leaseTermText.value))
    const leaseTermCopyText = computed<string>(() => hasLeaseTerm.value ? leaseTermText.value?.join(', ') : '')

    const hasAddresses = computed(() => !isNullOrEmpty(allAddresses.value))
    const addressCopyText = computed(() => {
        return allAddresses.value?.join(', ')
    })

    const isUnregisteredLandIntersectionEnabled = computed<boolean>(() => checkFlag('unregistered-land-intersection', false))
    const showUnregisteredLandWarning = computed(() => {
        return isUnregisteredLandIntersectionEnabled.value
            && intersectsUnregisteredLand.value !== null
    })

    const intersectsUnregisteredLand = ref<boolean | null>(null)
    const getUnregisteredIntersection = async () => {
        if (isNullOrWhitespace(selectedTitleNumber.value)) {
            return
        }
        intersectsUnregisteredLand.value = await TitleInformationApi.getTitleUnregisteredLandIntersection(selectedTitleNumber.value)
    }
    const route = useRoute()
    const isFullScreenToggleVisible = computed(() => isTitlePanelV2ToggleEnabled.value && route.name === Route.MatterMapTitle)

    watch(() => selectedTitleNumber.value, async () => {
        await getUnregisteredIntersection()
    }, {
        immediate: true,
    })
</script>

<style lang="scss">
    @import './overview';
</style>
