// National Polygon Service - title boundaries used in various places in the app. Handle requesting and caching them here.
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {

    // Array of {titleNumber: 'TGL50538', feature: OlFeature}
    features: [],
    loadingFeaturesForTitleNumbers: [],
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
