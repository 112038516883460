<template>
    <div class="sketches-tool-panel-item"
         :class="{'is-disabled-coming-soon': isDisabledComingSoon, 'is-loading': isLoading}">
        <img :src="image"
             class="preview"
             :alt="title" />
        <img :src="gif"
             :alt="title"
             class="preview-gif" />
        <div v-if="isDisabledComingSoon"
             v-t="'comingSoon'"
             class="coming-soon" />
        <ow-progress v-if="isLoading"
                     class="sketches-tool-panel-item__loading-spinner" />
        <div class="sketches-tool-panel-item__content">
            <component :is="currentIcon"
                       v-if="currentIcon !== ''"
                       class="sketches-icon" />
            <div class="sketches-tool-panel-item__text">
                <label class="caption-highlight">
                    {{ title }}
                </label>
                <div class="accents-small">
                    {{ description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { shallowRef } from 'vue'

    import OwProgress from '@/components/core/ow-progress.vue'
    interface Props {
        title: string,
        description: string,
        iconName: string,
        image: string,
        gif: string,
        isDisabledComingSoon?: boolean,
        isLoading: boolean,
    }
    const props = withDefaults(defineProps<Props>(), {
        isDisabledComingSoon: false,
        isLoading: false,
    })
    const currentIcon = shallowRef('')

    import(`@/components/core/icons/${ props.iconName }.vue`).then(val => {
        currentIcon.value = val.default
    })
</script>

<style lang="scss">
@import 'sketches-tool-panel-item.scss';
</style>
