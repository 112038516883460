<template>
    <div class="result-item"
         :class="{
             [tenure]: tenure,
             'has-owner': owner,
             'has-address': address
         }"
         data-test="result-item"
         data-track="SEARCH - results list item click"
         @click="onClick">
        <div class="result-item__wrapper-left">
            <div class="result-item__title-number"
                 data-test="result-item-title-number">
                {{ titleNumber }}
            </div>
            <div class="result-item__tenure"
                 data-test="result-item-tenure">
                {{ tenureType }}
            </div>
        </div>
        <div class="result-item__wrapper-centre">
            <ow-icon-text v-if="address"
                          :text="address"
                          icon="$map-marker-outline"
                          class="result-item__address"
                          data-test="result-item-address" />
            <ow-icon-text v-if="owner"
                          icon="$account-circle-outline"
                          class="result-item__owner"
                          :text="owner"
                          data-test="result-item-owner" />
        </div>
        <div class="result-item__actions">
            <ow-button :outlined="true"
                       data-test="result-item-add-to-matter-button"
                       @click.prevent.stop.capture="addToMatter">
                Add to matter
            </ow-button>
        </div>
    </div>
</template>

<script lang="ts">
    import owButton from '@/components/core/ow-button.vue'
    import owIconText from '@/components/core/ow-icon-text.vue'
    import { TenureType } from '@/enums/tenure-type'

    export default {
        name: 'ResultItem',

        components: {
            owButton,
            owIconText,
        },

        props: {
            titleNumber: {
                type: String,
                required: true,
            },
            tenure: {
                type: String,
                required: true,
                validator: (value: TenureType) => {
                    return Object.values(TenureType).includes(value)
                },
            },
            address: {
                type: String,
                required: false,
                default: null,
            },
            owner: {
                type: String,
                required: false,
                default: null,
            },
        },
        emits: ['click', 'add-to-matter'],

        computed: {
            tenureType(): string {
                return this.tenure === TenureType.Freehold ? 'Freehold' : 'Leasehold'
            },
        },

        methods: {
            onClick(): void {
                this.$emit('click')
            },

            addToMatter(): void {
                this.$emit('add-to-matter', this.titleNumber)
            },
        },
    }
</script>

<style lang="scss">
    @import './result-item.scss';
</style>
