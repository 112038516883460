<template>
    <section class="base-sub-type-item oc2-document-updated">
        <h3 class="caption-highlight expansion-panel__toggle"
            data-test="oc2-toggle"
            data-track="Asset monitoring: toggle details panel"
            data-track-type="Official Copies Updated"
            @click="isShowingDetailsPanel = !isShowingDetailsPanel">
            {{ isShowingDetailsPanel ? t('label.hideDetails') : t('label.showDetails') }}
            <v-icon>{{ isShowingDetailsPanel ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
        </h3>
        <div v-if="isShowingDetailsPanel"
             class="expansion-panel__content">
            <table class="base-sub-type-item__table">
                <thead>
                    <tr>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('label.type') }}
                        </th>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('label.document') }}
                        </th>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('label.updated') }}
                        </th>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('label.entries') }}
                        </th>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('label.filedUnder') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="itm in allItems"
                        data-test="oc2-change-row">
                        <td class="caption-regular"
                            data-test="oc2-change-row-type">
                            {{ itm.type }}
                        </td>
                        <td class="caption-highlight"
                            data-test="oc2-change-row-document">
                            {{ itm?.oc2Type }}
                        </td>
                        <td class="caption-regular new-owner"
                            data-test="oc2-change-row-updated">
                            {{ itm.date }}
                        </td>
                        <td class="caption-regular new-owner"
                            data-test="oc2-change-row-entries">
                            {{ itm.entryNumbers }}
                        </td>
                        <td class="caption-regular new-owner"
                            data-test="oc2-change-row-filed-under">
                            {{ itm.filedUnder }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { OcdaDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'
    import useDates from '@/composables/use-dates'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const props = defineProps({
        item: {
            type: Object as PropType<IAssetMonitoringNotificationDetail>,
            required: true,
        },
    })

    const emit = defineEmits<{
        (e: 'count-of-updates', value: number),
    }>()

    const { t } = useI18n()
    const { formatDateShort } = useDates()
    const isShowingDetailsPanel = ref<boolean>(false)

    const diff = computed<Difference<OcdaDatapoint>>(() => {
        if (props.item) {
            return JSON.parse(props.item?.difference)
        }

        return null
    })

    // Get the added, removed, and updated items
    const addedItems = ref<Array<OcdaDatapoint>>([])
    const hasAddedItems = ref<boolean>(false)
    const removedItems = ref<Array<OcdaDatapoint>>([])
    const hasRemovedItems = ref<boolean>(false)
    const updatedItems = ref<Array<UpdatedProperty<OcdaDatapoint>>>([])
    const hasUpdatedItems = ref<boolean>(false)
    const allItems = computed<Array<any>>(() => {
        return [].concat(
            addedItems.value.map((item) => ({
                type: t('label.added'),
                oc2Type: item.type,
                date: formatDateShort(item.date),
                filedUnder: item.filedUnder,
                entryNumbers: item.entryNumbers.join(', '),
                availability: item.availability,
            })),
            removedItems.value.map((item) => ({
                type: t('label.removed'),
                oc2Type: item.type,
                date: formatDateShort(item.date),
                filedUnder: item.filedUnder,
                entryNumbers: item.entryNumbers.join(', '),
                availability: item.availability,
            })),
            updatedItems.value.map((item) => ({
                type: t('label.updated'),
                oc2Type: item.after?.type,
                date: formatDateShort(item.after?.date),
                filedUnder: item.after?.filedUnder,
                entryNumbers: item.after?.entryNumbers.join(', '),
                availability: item.after?.availability,
            })),
        )
    })

    watch(allItems, () => {
        emit('count-of-updates', allItems.value.length)
    }, { immediate: true })

    watch(diff, (newVal: Difference<OcdaDatapoint>) => {
        addedItems.value = newVal?.added ?? newVal?.added
        hasAddedItems.value = !isNullOrEmpty(newVal?.added)
        removedItems.value = newVal?.removed
        hasRemovedItems.value = !isNullOrEmpty(newVal?.removed)
        updatedItems.value = newVal?.updated
        hasUpdatedItems.value = !isNullOrEmpty(newVal?.updated)
    }, { immediate: true })
</script>

<style lang="scss" scoped>
    @import "oc2-document-updated.scss";
</style>
