// Getters

// Mutations
export const CHECKOUT_MUTATE_ADD_DOWNLOADED_REPORT = 'CHECKOUT_MUTATE_ADD_DOWNLOADED_REPORT'
export const CHECKOUT_MUTATE_BASIC_MATTER_CODES = 'CHECKOUT_MUTATE_BASIC_MATTER_CODES'
export const CHECKOUT_MUTATE_CART_ITEMS_ADD = 'CHECKOUT_MUTATE_CART_ITEMS_ADD'
export const CHECKOUT_MUTATE_CART_ITEMS_EMPTY = 'CHECKOUT_MUTATE_CART_ITEMS_EMPTY'
export const CHECKOUT_MUTATE_ENTRY_POINT = 'CHECKOUT_MUTATE_ENTRY_POINT'
export const CHECKOUT_MUTATE_ORDER_CONFIRMED = 'CHECKOUT_MUTATE_ORDER_CONFIRMED'

// Actions
export const CLEAR_CART = 'CLEAR_CART'
export const ADD_ITEMS_TO_CART = 'ADD_ITEMS_TO_CART'
