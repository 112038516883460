import { IOverlaysState } from "@/store/modules/overlays/index"
import {
    OverlayListItemModel,
    OverlayModel,
} from "@/components/map/overlays/overlays-types"
import { OVERLAYS_MUTATE_SHOW_NAME_MODAL } from "@/store/modules/overlays/types"

export default {
    [OVERLAYS_MUTATE_SHOW_NAME_MODAL](state: IOverlaysState, { show, overlay } : {show: boolean, overlay: OverlayListItemModel | OverlayModel}): void {
        state.showNameEditModal = show
        state.nameEditModalOverlay = overlay ?? null
        if (!show && !overlay) {
            state.nameEditModalOverlay = null
        }
    },
}
