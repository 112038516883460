<template>
    <div class="documents-library-header">
        <div class="documents-library-header__fixed-header">
            <header class="header-content"
                    data-test="document-options-wrapper">
                <h1 class="content__heading">
                    <v-icon>$document</v-icon>
                    {{ $t('label.documents') }}
                </h1>
                <!-- <v-spacer /> -->
                <div class="d-flex content__layout">
                    <v-text-field v-model.trim="localSearchTextValue"
                                  class="documents-library-header__search-box"
                                  clearable
                                  data-test="doc-library-search"
                                  data-track="DOCS - Search documents"
                                  density="compact"
                                  :disabled="isLoading || isDisabled"
                                  hide-details
                                  label="Search documents"
                                  prepend-inner-icon="$search"
                                  variant="solo" />

                    <v-btn id="filter-activator"
                           color="white"
                           :disabled="isLoading || isDisabled"
                           data-test="doc-library-filter-button"
                           data-track="DOCS - Filter documents"
                           size="large">
                        <v-icon>$filter</v-icon>
                        <span class="hidden-md-and-down">Filter</span>
                    </v-btn>
                    <v-menu :close-on-content-click="false"
                            content-class="document-library-filter-menu"
                            offset="bottom"
                            activator="#filter-activator">
                        <v-list v-if="statusOptions.length > 0"
                                density="compact"
                                height="350">
                            <div class="d-flex flex-row">
                                <v-list-subheader>Status</v-list-subheader>
                                <v-spacer />
                                <v-btn v-if="hasStatusFilterApplied"
                                       class="clear-filter-button"
                                       color="primary"
                                       data-test="doc-library-status-filter-clear"
                                       data-track="DOCS - Clear all status filters"
                                       size="small"
                                       variant="text"
                                       @click="clearStatusesHandler">
                                    {{ $t('action.clear') }}
                                </v-btn>
                            </div>
                            <v-list-item v-for="(status, index) in statusOptions"
                                         :key="index">
                                <v-list-item-title>
                                    <v-checkbox v-model="status.selected"
                                                :label="status.title"
                                                color="primary"
                                                class="documents-library-header__filter-checkbox"
                                                data-test="doc-library-status-option"
                                                data-track="DOCS - Filter by status option"
                                                hide-details />
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider v-if="typeOptions.length > 0" />
                            <div class="d-flex flex-row">
                                <v-list-subheader>{{ $t('label.type') }}</v-list-subheader>
                                <v-spacer />
                                <v-btn v-if="hasTypeFilterApplied"
                                       class="clear-filter-button"
                                       color="primary"
                                       data-test="doc-library-doc-type-filter-clear"
                                       data-track="DOCS - Clear all type filters"
                                       size="small"
                                       variant="text"
                                       @click="clearTypesHandler">
                                    {{ $t('action.clear') }}
                                </v-btn>
                            </div>
                            <v-list-item v-for="(type, index) in typeOptions"
                                         :key="index"
                                         :data-doc-library-filter-type="type.title"
                                         @click="logFilterOpen">
                                <v-list-item-title>
                                    <v-checkbox v-model="type.selected"
                                                :label="type.title"
                                                color="primary"
                                                class="documents-library-header__filter-checkbox"
                                                data-test="doc-library-doc-type-option"
                                                data-track="DOCS - Filter by type option"
                                                hide-details />
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider v-if="hasFilterApplied" />
                            <v-list-item v-if="hasFilterApplied"
                                         class="list-button">
                                <v-list-item-title>
                                    <v-btn color="primary"
                                           data-test="doc-library-filter-clear-all"
                                           data-track="DOCS - Clear all filters"
                                           style="width: 100%"
                                           variant="text"
                                           @click="clearFiltersHandler">
                                        {{ $t('action.clearAll') }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <upload-documents-button v-if="isUploadDocumentsEnabled"
                                             :current-matter-id="currentMatterId"
                                             data-test="uploaded-documents-summary-upload-documents-btn"
                                             data-track="DOCUMENTS - Upload documents"
                                             is-primary
                                             @upload-started="uploadStarted" />
                </div>
            </header>
        </div>
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue'

    import UploadDocumentsButton from '@/components/shared/upload-documents-button.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IOptionItem } from '@/interfaces/option-item.interface'

    const EVENTS = {
        SearchTextUpdated: 'search-text-updated',
        StatusFilterUpdated: 'status-filter-updated',
        TypeFilterUpdated: 'types-filter-updated',
        UploadStarted: 'upload-started',
    }

    export default {
        name: 'DocumentsLibraryHeader',

        components: {
            UploadDocumentsButton,
        },

        mixins: [FeatureFlagsMixin],

        props: {
            searchText: {
                type: String,
                required: true,
            },
            statusOptions: {
                type: Array as PropType<Array<IOptionItem>>,
                required: true,
            },
            typeOptions: {
                type: Array as PropType<Array<IOptionItem>>,
                required: true,
            },
            currentMatterId: {
                type: Number,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            isDisabled: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                EVENTS,
            }
        },

        computed: {
            localSearchTextValue: {
                get(): string {
                    return this.searchText
                },
                set(text: string): void {
                    this.$emit(this.EVENTS.SearchTextUpdated, text ?? '')
                },
            },

            hasStatusFilterApplied(): boolean {
                return this.statusOptions.some(option => option.selected)
            },

            hasTypeFilterApplied(): boolean {
                return this.typeOptions.some(option => option.selected)
            },

            hasFilterApplied(): boolean {
                return this.hasTypeFilterApplied || this.hasStatusFilterApplied
            },
        },

        watch: {
            typeOptions: {
                handler(updatedTypes: Array<IOptionItem>): void {
                    this.$emit(this.EVENTS.TypeFilterUpdated, updatedTypes)
                },
                deep: true,
            },
            statusOptions: {
                handler(updatedStatus: Array<IOptionItem>): void {
                    this.$emit(this.EVENTS.StatusFilterUpdated, updatedStatus)
                },
                deep: true,
            },
        },

        methods: {
            clearFiltersHandler() {
                this.localSearchTextValue = ''
                this.clearTypesHandler()
                this.clearStatusesHandler()
            },

            clearTypesHandler() {
                this.typeOptions.forEach(typeOption => {
                    typeOption.selected = false
                })
            },

            clearStatusesHandler() {
                this.statusOptions.forEach(statusOption => {
                    statusOption.selected = false
                })
            },

            uploadStarted(): void {
                this.$emit(this.EVENTS.UploadStarted)
            },

            logFilterOpen(): void {
                // NOTE: Due to a bug in vuetify the list focuses on the first item
                // On scroll and click it will make the list scroll up
                // Using the click event seems to fix it
                console.info('filterListOpened')
            },
        },
    }
</script>
<style lang="scss">
    @import './documents-library-header';
</style>
