import { BoundaryStyle } from '@/models/styles/boundary-style'
import { IMatterGroup } from '@/interfaces/matter-group.interface'
import { ITitleInMatterReference } from '@/interfaces/title-in-matter.reference'

export class TitleOrganiserResultTitle {
    titleNumber: string
    matterGroupId: number
    boundaryStyle: BoundaryStyle
    sortOrder: number
    constructor(existingTitle: ITitleInMatterReference) {
        this.titleNumber = existingTitle.titleNumber
        this.matterGroupId = existingTitle.matterGroupId
        this.sortOrder = existingTitle.sortOrder
        this.boundaryStyle = new BoundaryStyle()
    }
}

export class TitleOrganiserResultGroup {
    id: number
    name: string
    sortOrder: number
    expanded: boolean

    constructor(existingGroup: IMatterGroup) {
        this.name = existingGroup.name
        this.id = existingGroup.id
        this.sortOrder = existingGroup.sortOrder
        this.expanded = true
    }
}

export class TitleOrganiserResult {
    titles: Array<TitleOrganiserResultTitle>
    groups: Array<TitleOrganiserResultGroup>

    constructor(titles: Array<TitleOrganiserResultTitle>, groups: Array<TitleOrganiserResultGroup>) {
        this.titles = titles
        this.groups = groups
    }
}
