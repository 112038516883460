<template>
    <ow-modal v-model="show"
              content-class="dialog-select-searches-product"
              :title="titleText"
              persistent
              @update:model-value="emit('close')">
        <div class="dialog-select-searches-product__content d-flex">
            <div class="caption-regular flex-grow-0 flex-shrink-0">
                Please select from the available products below.
            </div>
            <div class="d-flex justify-space-between align-center pb-2 flex-grow-0 flex-shrink-0">
                <ow-text-field v-model="searchText"
                               class="matter-searches-filter__text pr-4"
                               data-test="matter-searches-filter-search-text"
                               :max-length="1000"
                               :placeholder="$t('searches.filterProducts').toString()"
                               small
                               clearable
                               @click:clear="searchText = ''">
                    <template #iconPrefix>
                        <v-icon>$search</v-icon>
                    </template>
                </ow-text-field>
                <div class="d-flex align-center">
                    <label class="caption-regular mr-2"
                           for="show-matching-hazards">
                        {{ $t('searches.showMatchingHazards') }}
                    </label>
                    <v-checkbox id="show-matching-hazards"
                                v-model="showMatchingHazards"
                                hide-details
                                class="dialog-select-searches-product__checkbox" />
                </div>
            </div>
            <div class="dialog-select-searches-product__grid-container flex-grow-1">
                <matter-searches-product-table class="dialog-select-searches-product__grid"
                                               :store="store"
                                               :multi-select="multiSelect"
                                               :config="{
                                                   emptyText: $t('searches.noProductsAvailable')
                                               }" />
            </div>
        </div>
        <template #actions>
            <ow-card-actions has-secondary-button
                             no-padding
                             :primary-button-text="primaryButtonText"
                             :primary-button-disabled="selectedProducts?.length === 0"
                             primary-button-data-test="dialog-select-searches-product__controls--confirm"
                             secondary-button-data-test="dialog-select-searches-product__controls--cancel"
                             :secondary-button-text="$t('action.cancel').toString()"
                             @primary-button-click="handleConfirm"
                             @secondary-button-click="emit('close')" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        Store,
    } from '@bryntum/grid'
    import {
        computed,
        onMounted,
        reactive,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwTextField from '@/components/core/ow-textfield.vue'
    import MatterSearchesProductTable from '@/components/matter-searches/matter-searches-product-table.vue'
    import ProductItemModel from '@/components/matter-searches/models/ProductItemModel'
    import {
        Product,
        SearchCategoryWithProducts,
        SearchProductsFilter,
    } from '@/store/modules/searches/types'
    import { dynamicSort,
             isNullOrEmpty } from '@/utils/array-utils'

    import BasketItemModel from '../matter-searches/models/BasketItemModel'
    import CategoryItemModel from '../matter-searches/models/CategoryItemModel'

    const { t } = useI18n()

    const props = defineProps<{
        selectedCategory?: CategoryItemModel
        selectedBasketItem?: BasketItemModel
        categories: SearchCategoryWithProducts[]
        multiSelect: boolean
    }>()

    const searchText = ref('')
    const show = ref(true)
    const showMatchingHazards = ref<boolean>()

    const store = reactive(new Store({
        modelClass: ProductItemModel,
        data: [],
        autoCommit: true,
    }))

    const emit = defineEmits<{
        (e: 'close'): void
        (e: 'add', data: {
            category: CategoryItemModel
            products: ProductItemModel[]
        }): void
    }>()

    const primaryButtonText = computed(() => {
        if (!props.multiSelect && isNullOrEmpty(props.selectedCategory?.products)) {
            return `${ t('action.swapProduct').toString() }`
        }
        return `${ t('action.addProducts').toString() } (${ selectedProducts.value?.length })`
    })

    const titleText = computed(() => {
        if (!props.multiSelect && isNullOrEmpty(props.selectedCategory?.products)) {
            return t('action.swapProduct').toString()
        }
        return t('action.addProducts').toString()
    })

    const selectedProducts = computed<ProductItemModel[]>(() => {
        return store.allRecords.filter((product: ProductItemModel) => product.selected) as ProductItemModel[]
    })

    const handleConfirm = () => {
        emit('add', {
            category: props.selectedCategory,
            products: selectedProducts.value,
        })
        emit('close')
    }

    onMounted(() => {
        const products = props.selectedCategory?.products || [props.selectedBasketItem?.productId]
        if (!isNullOrEmpty(products)) {
            showMatchingHazards.value = true
        } else {
            store.data = []
            showMatchingHazards.value = false
        }
    })

    watch(() => searchText.value, (value) => {
        store.filter({
            replace: true,
            filters: [
                {
                    filterBy: (product: ProductItemModel) => {
                        const matchesOnDescription = product.description.toLowerCase().includes(value.toLowerCase())
                        let matchesOnHazard = false
                        if (product.hazards) {
                            matchesOnHazard = product.hazards.some((hazard) => hazard.toLowerCase().includes(value.toLowerCase()))
                        }
                        return matchesOnDescription || matchesOnHazard
                    },
                },
            ],
        })
    }, {
        immediate: true,
    })

    const getMatchingProductsByBasketItem = (existingCheckedProducts) => {
        // const get all categories that have products
        // get all matching products from all categories that match the hazard
        const matchingProductIds = new Set()
        props.categories.forEach((category: CategoryItemModel) => {
            category.products.forEach((product) => {
                product.hazards.forEach((hazard) => {
                    if (props.selectedBasketItem?.hazards?.includes(hazard) || props.selectedBasketItem?.categoryIds?.includes(hazard)) {
                        if (!matchingProductIds.has(product.productId)) {
                            matchingProductIds.add(product.productId)
                        }
                    }
                })
            })
        })

        // create an array of all products
        const products = props.categories.map((category: CategoryItemModel) => category.products).flat().filter((product) => matchingProductIds.has(product.productId))

        // distinct products
        const matchingProducts = Array.from(new Set(products.map((product) => product.productId))).map((productId) => products.find((product) => product.productId === productId))

        const data = matchingProducts.map((product: ProductItemModel) => {
            return {
                ...product,
                selected: existingCheckedProducts.some((checkedProduct: ProductItemModel) => checkedProduct.name === product.name),
            }
        })
        return data
    }

    const getMatchingProductsBySelectedCategory = (existingCheckedProducts) => {
        return props.selectedCategory.products.map((product: Product) => {
            return {
                ...product,
                selected: existingCheckedProducts.some((checkedProduct: ProductItemModel) => checkedProduct.name === product.name),
            }
        })
    }

    const getUniqueProducts = (existingCheckedProducts) => {
        const data = new Set()
        props.categories.forEach((category: CategoryItemModel) => {
            category.products.forEach((product) => {
                if (!data.has(product)) {
                    data.add(product)
                }
            })
        })
        return Array.from(data).map((product: ProductItemModel) => {
            return {
                ...product,
                selected: existingCheckedProducts.some((checkedProduct: ProductItemModel) => checkedProduct.name === product.name),
            }
        })
    }

    const updateMatchingHazards = (showMatching: boolean) => {
        const existingCheckedProducts = store.allRecords.filter((product: ProductItemModel) => product.selected)
        let data = []
        if (showMatching) {
            data = props.selectedCategory ?
                getMatchingProductsBySelectedCategory(existingCheckedProducts) :
                getMatchingProductsByBasketItem(existingCheckedProducts)
        } else {
            data = getUniqueProducts(existingCheckedProducts)
        }
        // sort by name
        data.sort(dynamicSort('description'))
        store.data = data
    }

    watch(() => showMatchingHazards.value, (value) => {
        updateMatchingHazards(value)
    })

</script>

<style lang="scss">
    @import './dialog-select-searches-product';
</style>
