<template>
    <div v-if="isWalkthrough"
         class="matter-side-panel-title-item"
         :data-test="`matter-side-panel-title-item-walkthrough-${ title.titleNumber }`"
         :class="{ 'in-group': titleIsGrouped }">
        <addable-title-details-list-item :title-number="title.titleNumber"
                                         :addresses="title.addresses"
                                         :tenure="mappedTenure"
                                         :is-highlighted="isIncludedInSelectedWalkthroughPage"
                                         :is-selected="isIncludedInSelectedWalkthroughPage"
                                         :is-visible="title.show"
                                         :custom-name="title.label"
                                         :label-colour="showLegendGraphic ? title.labelColour || title.colour : null"
                                         :has-light-background="hasLightBackground"
                                         :is-register-ordered="title.isRegisterOrdered"
                                         :is-register-uploaded="title.isRegisterUploaded"
                                         on-add-data-track="WALKTHROUGH - Add title to walk through"
                                         on-remove-data-track="WALKTHROUGH - Remove title from walk through"
                                         @click="onTitleItemClick" />
    </div>
    <div v-else
         class="matter-side-panel-title-item"
         :class="{ 'in-group': titleIsGrouped }"
         data-test="matter-side-panel-title-item"
         @mouseleave="mouseOver = false"
         @mouseover="mouseOver = true">
        <expandable-matter-title-details-list-item v-if="show"
                                                   :key="title.titleNumber"
                                                   :title-number="title.titleNumber"
                                                   :addresses="title.addresses"
                                                   :tenure="mappedTenure"
                                                   :is-visible="title.show"
                                                   :is-selected="title.selected"
                                                   :is-selectable="showSelectionCheckbox"
                                                   :is-highlighted="expandedTitleNumber === title.titleNumber"
                                                   :disabled="disabled"
                                                   :has-dropdown-menu="hasMenu && showMenu"
                                                   :clickable-area="clickableArea ? clickableArea : title.titleNumber === selectedTitleNumber ? 'none' : undefined"
                                                   :custom-name="title.label"
                                                   :is-matter-link-share-user="isMatterLinkShareUser"
                                                   :is-register-ordered="title.isRegisterOrdered"
                                                   :is-register-uploaded="title.isRegisterUploaded"
                                                   :register-response-date="title.registerResponseDate"
                                                   :is-expanded="hasExpanded && expandedTitleNumber === title.titleNumber"
                                                   :has-no-boundary="!title.boundaryAvailable"
                                                   :label-colour="showLegendGraphic ? title.labelColour || title.colour : null"
                                                   :disable-visibility-button="disableVisibilityButton"
                                                   :has-light-background="hasLightBackground"
                                                   :has-visibility-button="hasVisibilityButton"
                                                   :scroll-observer-element-id="scrollObserverElementId"
                                                   see-details-button-data-track="MAT-TITLE-LIST - See details button clicked"
                                                   :disable-expand-collapse="disableExpandCollapse"
                                                   @click="onTitleItemClick"
                                                   @selection-change="onSelectionChange"
                                                   @visibility-button-click="onTitleVisibilityButtonClick"
                                                   @on-expand="onExpand"
                                                   @on-collapse="onCollapse"
                                                   @title-click="onTitleItemClick"
                                                   @title-mouse-over="$emit('title-mouse-over', title)"
                                                   @clear-titles="$emit('clear-titles')"
                                                   @title-mouse-out="$emit('title-mouse-out', title)">
            <template v-if="hasMenu"
                      #dropdownMenuItems>
                <ow-dropdown-menu-item data-track-attribute="MAT-TITLE-LIST - Open title details panel"
                                       icon="$info"
                                       :text="$t('buttons.openTitle')"
                                       @click="onTitleItemClick()" />
                <ow-dropdown-menu-item data-test-attribute="matter-side-panel-title-item-style-change"
                                       data-track-attribute="MAT-TITLE-LIST - Style the title"
                                       icon="$styling"
                                       text="Change style and name"
                                       @click="onTitleStyleClick()" />
                <ow-dropdown-menu :button-text="title.matterGroupId ? 'Move to group' : 'Add to group'"
                                  :is-submenu="true"
                                  alignment="right"
                                  button-data-test="matter-side-panel-title-item-span-add-to-group"
                                  button-data-track="MAT-TITLE-LIST - Move title to group"
                                  icon="$move-to-group"
                                  theme="submenu">
                    <ow-dropdown-menu-item v-for="(group, index) in filteredCurrentMatterGroups"
                                           :key="index"
                                           :text="`${ group.name } (${ getTitlesInGroup(group).length } titles)`"
                                           data-track-attribute="MAT-TITLE-LIST - Add to group from title menu"
                                           @click="onAddToGroupClick(group)" />
                    <ow-dropdown-menu-item data-test-attribute="matter-side-panel-title-item-create-new-group"
                                           icon="$add"
                                           data-track-attribute="MAT-TITLE-LIST - Create new group from title menu"
                                           text="Create a new group"
                                           @click="onAddTitleToNewGroupClick()" />
                </ow-dropdown-menu>
                <ow-dropdown-menu-item v-if="title.matterGroupId"
                                       data-track-attribute="MAT-TITLE-LIST - Remove title from group"
                                       icon="$close"
                                       text="Remove from group"
                                       @click="onRemoveFromGroupClick()" />
                <ow-dropdown-menu-item data-track-attribute="MAT-TITLE-LIST - Remove title from matter"
                                       icon="$delete"
                                       text="Remove from matter"
                                       @click="onRemoveTitleClick()" />
            </template>
        </expandable-matter-title-details-list-item>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import owDropdownMenu from '@/components/core/ow-dropdown-menu.vue'
    import owDropdownMenuItem from '@/components/core/ow-dropdown-menu-item.vue'
    import ExpandableMatterTitleDetailsListItem from '@/components/matter/titles/expandable-matter-title-details-list-item.vue'
    import { inject as titlePanelProvider } from '@/composables/use-title-panel'
    import { Route } from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { sortIntervalIncrement } from '@/store/modules/matter/group.utils'
    import {
        MATTER_HIGHLIGHT_BOUNDARY,
        MATTER_MUTATE_STYLE_PROMPT,
        MATTER_REMOVE_HIGHLIGHT_BOUNDARY,
        MATTER_REMOVE_TITLES,
        MATTER_REMOVE_TITLES_FROM_GROUP,
        MATTER_SHOW_BOUNDARIES,
        MATTER_UPDATE_TITLE_SORT,
    } from '@/store/modules/matter/types'
    import { PLANNING_CLEAR } from '@/store/modules/planning/types'
    import {
        TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER,
        TITLE_LOOKUP_AND_ZOOM_TO_TITLE,
        TITLE_MUTATE_COLLAPSE_PANEL,
        TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
        TITLE_SHOW_TITLE_BOUNDARY,
        TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY,
        TITLE_ZOOM_TO_TITLE_USING_UPRN,
    } from '@/store/modules/titles/types'
    import {
        WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE,
        WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE,
    } from '@/store/modules/walkthrough/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import AddableTitleDetailsListItem from './addable-title-details-list-item.vue'

    export default {
        name: 'MatterSidePanelTitleItem',

        components: {
            AddableTitleDetailsListItem,
            ExpandableMatterTitleDetailsListItem,
            owDropdownMenu,
            owDropdownMenuItem,
        },

        mixins: [flagsMixin],

        props: {
            title: {
                type: Object,
                required: true,
            },
            hasLightBackground: {
                type: Boolean,
                default: false,
            },
            scrollObserverElementId: {
                type: String,
                default: '.matter-side-panel-titles__content',
            },
            hasMenu: {
                type: Boolean,
                default: true,
            },
            hasExpanded: {
                type: Boolean,
                default: true,
            },
            hasVisibilityButton: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            clickableArea: {
                type: String,
                default: 'content',
                required: false,
                validator(val: string) {
                    return ['content', 'whole-item', 'none'].includes(val)
                },
            },
            disableExpandCollapse: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['add-title-to-group', 'clear-titles', 'on-expand', 'on-collapse', 'title-click', 'title-mouse-over', 'title-mouse-out'],
        setup() {
            const { isTitlePanelVisible, isSummaryTitlePanelVisible } = titlePanelProvider()
            return {
                isTitlePanelVisible,
                isSummaryTitlePanelVisible,
            }
        },
        data() {
            return {
                mouseOver: false,
                element: null, // A reference to the component element determined on mounted().
                scrollVisibility: false, // Used to determine whether an element should be visible based on it's scroll position.
                scrollElement: null, // The parent scrollable element.
                scrollObserver: null, // The Intersection Observer used to detect visibility changes.
            }
        },

        computed: {
            ...mapState({
                createGroupPromptProperties: state => state.matter.prompts.createGroup,
                currentMatter: state => state.matter.currentMatter,
                isWalkthrough: state => state.walkthrough.enabled,
                selectedTitleNumber: state => state.title.selectedTitleNumber,
                expandedTitleNumber: state => state.title.expandedTitleNumber,
                titleOrganiserEnabled: state => state.matter.titleOrganiser.enable,
                isMatterLinkShareUser: state => state.linkShareClient.isMatterLinkShareUser,
            }),

            ...mapGetters({
                getTitleIsIncludedInCurrentPage: WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            mappedTenure() {
                if (this.title.tenure?.includes('F')) {
                    return 'freehold'
                }
                if (this.title.tenure?.includes('L')) {
                    return 'leasehold'
                }
                return 'unknown'
            },

            showTitleLabel() {
                return !isNullOrWhitespace(this.title.label)
            },

            filteredCurrentMatterGroups() {
                return (this.currentMatter?.groups || []).filter((group) => group.id !== this.title.matterGroupId)
            },

            showSelectionCheckbox() {
                return !this.isSharedLinkView && !this.isWalkthrough && !this.titleOrganiserEnabled
            },

            showMenu() {
                return this.hasMenu && !this.isSharedLinkView && !this.isWalkthrough && !this.titleOrganiserEnabled
            },

            disableVisibilityButton() {
                return !this.title.boundaryAvailable || this.titleOrganiserEnabled
            },

            isIncludedInSelectedWalkthroughPage() {
                return this.getTitleIsIncludedInCurrentPage(this.title.titleNumber)
            },

            showLegendGraphic() {
                return this.title.show && this.title.boundaryAvailable
            },

            show() {
                return this.title !== null &&
                    this.scrollVisibility
            },

            titleIsGrouped() {
                return this.title.matterGroupId !== null
            },
        },

        watch: {
            mouseOver(val) {
                if (this.title.boundaryAvailable) {
                    if (val === true) {
                        // if has title mouse over event, call it
                        if (this.$attrs['title-mouse-over']) {
                            this.$emit('title-mouse-over', this.title)
                        } else {
                            this.highlightBoundary(this.title.titleNumber)
                        }
                    } else {
                        if (this.$attrs['title-mouse-out']) {
                            this.$emit('title-mouse-out', this.title)
                        } else {
                            this.removeBoundaryHighlights()
                        }
                    }
                }
            },

            '$route.name'(val) {
                if (val.includes('map')) {
                    this.initialiseIntersectionObserver()
                }
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.initialiseIntersectionObserver()
            })
        },

        methods: {
            ...mapActions({
                getSummaryTitleDetails: TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER,
                highlightBoundary: MATTER_HIGHLIGHT_BOUNDARY,
                removeBoundaryHighlights: MATTER_REMOVE_HIGHLIGHT_BOUNDARY,
                removeFromGroup: MATTER_REMOVE_TITLES_FROM_GROUP,
                removeTitles: MATTER_REMOVE_TITLES,
                showBoundaries: MATTER_SHOW_BOUNDARIES,
                lookupAndZoomToTitle: TITLE_LOOKUP_AND_ZOOM_TO_TITLE,
                updateTitleSort: MATTER_UPDATE_TITLE_SORT,
                clearPlanningApplications: PLANNING_CLEAR,
                zoomToTitleAndShowBoundary: TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY,
                zoomToTitleUsingUprn: TITLE_ZOOM_TO_TITLE_USING_UPRN,
                showTitleBoundary: TITLE_SHOW_TITLE_BOUNDARY,
            }),

            ...mapMutations({
                styleTitles: MATTER_MUTATE_STYLE_PROMPT,
                toggleInclusionInSelectedWalkthroughPage: WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE,
                setExpandedTitleNumber: TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
                collapseTitlePanel: TITLE_MUTATE_COLLAPSE_PANEL,
            }),

            initialiseIntersectionObserver() {
                // Listen for changes to the position of this component within it's parent scrollable element
                if (this.scrollObserver === null) {
                    this.element = this.$el
                    this.scrollElement = document.querySelector(this.scrollObserverElementId)
                    if (this.scrollElement) {
                        const options = {
                            root: this.scrollElement,
                            rootMargin: '100px',
                            threshold: 1.0,
                        }

                        this.scrollObserver = new IntersectionObserver((entries: any) => {
                            entries.forEach((entry: any) => {
                                if (entry.target.dataset.titlenumber === this.title.titleNumber) {
                                    this.scrollVisibility = entry.intersectionRatio > 0
                                }
                            })
                        }, options)
                        this.scrollObserver.observe(this.element)
                    }
                }
            },

            onTitleVisibilityButtonClick() {
                this.showBoundaries({
                    titles: [this.title],
                    show: !this.title.show,
                })
            },

            async onTitleItemClick() {
                if (this.isSharedLinkView) {
                    await this.getSummaryTitleDetails({
                        matterId: this.currentMatter.id,
                        titleNumber: this.title.titleNumber,
                        titleLabel: this.showTitleLabel ? this.title.label : this.title.titleNumber,
                    })
                    await this.lookupAndZoomToTitle(this.title.titleNumber)
                } else if (this.isWalkthrough) {
                    // ... unless in walkthrough mode, in which case...
                    this.toggleInclusionInSelectedWalkthroughPage(this.title)
                } else if (this.disableExpandCollapse) {
                    // Don't navigate away from the searches page...
                    this.$emit('title-click', this.title)
                    return
                } else {
                    // Ordinarily show the title information...
                    this.collapseTitlePanel(false)
                    this.hideMenus()
                    this.$router.push({
                        name: Route.MatterMapTitle,
                        params: {
                            matterId: this.currentMatter.id,
                            titleNumber: this.title.titleNumber,
                        },
                    })
                }
            },

            onTitleStyleClick() {
                this.hideMenus()
                this.showBoundaries({
                    titles: [this.title],
                    show: true,
                    skipStyleUpdate: true,
                }) // show title when selecting to style it
                this.styleTitles({
                    titles: [this.title],
                    updateMainMap: true,
                })
            },

            onAddToGroupClick(group) {
                this.$emit('add-title-to-group', this.title, group.id)
                this.hideMenus()
                this.title.selected = false
            },

            onExpand(titleNumber: string): void {
                // if title panel is open
                // click the title
                if (this.disableExpandCollapse) {
                    return
                } else if (this.isTitlePanelVisible || this.isSummaryTitlePanelVisible) {
                    this.setExpandedTitleNumber(titleNumber)
                    this.$emit('title-click', titleNumber)
                    this.$emit('on-expand', titleNumber)
                } else {
                    this.setExpandedTitleNumber(titleNumber)
                    if (this.title.boundaryAvailable) {
                        this.zoomToTitleAndShowBoundary(titleNumber)
                    } else if (!isNullOrEmpty(this.title.uprns)) {
                        this.zoomToTitleUsingUprn(this.title.uprns[0])
                    }

                    if (titleNumber !== this.selectedTitleNumber) {
                        this.clearPlanningApplications()
                        this.$emit('clear-titles', titleNumber)
                    }

                    this.$emit('on-expand', titleNumber)
                }
            },

            onCollapse(titleNumber) {
                this.$emit('on-collapse', titleNumber)
                this.setExpandedTitleNumber(null)
                this.showTitleBoundary(null)
            },

            onRemoveFromGroupClick() {
                this.hideMenus()

                // Sort to end of ungrouped section
                const titlesInUnGrouped = this.currentMatter.selectedTitles.filter(t => {
                    return t.matterGroupId === null
                })
                const lastIndex = titlesInUnGrouped.length > 0
                    ? Math.max(...titlesInUnGrouped.map(x => x.sortOrder))
                    : 0
                this.updateTitleSort([{ titleNumber: this.title.titleNumber, sortOrder: lastIndex + sortIntervalIncrement }])

                this.removeFromGroup({
                    matterGroupId: this.title.matterGroupId,
                    titles: [this.title],
                })
                this.title.selected = false
            },

            onRemoveTitleClick() {
                this.hideMenus()
                this.removeTitles([this.title.titleNumber])
            },

            onAddTitleToNewGroupClick() {
                this.hideMenus()
                this.createGroupPromptProperties.show = true
                this.createGroupPromptProperties.titles = [this.title]
            },

            getTitlesInGroup(group) {
                return this.currentMatter.selectedTitles.filter(function(title) {
                    return group.id === title.matterGroupId
                })
            },

            hideMenus() {
                this.title.showOptionsMenu = false
                this.title.showGroupMenu = false
            },

            onSelectionChange(val) {
                this.title.selected = val
            },
        },
    }

</script>
<style lang="scss">
    @import 'matter-side-panel-title-item';
</style>
