import {
    Column,
    ColumnConfig,
    Model,
} from '@bryntum/grid'
import { calculatingMarkup } from '.'

export interface IFieldColumnProps extends Partial<ColumnConfig> {
    calculating?: (record: Model) => boolean
    format?: (data: any) => string
}

export const useCalculatingColumn = (args: IFieldColumnProps): Partial<Column> => ({
    ...args,
    type: 'template',
    template: ({ value, record }: any) => {
        const calculating = args.calculating && args.calculating(record)
        if (calculating) {
            return calculatingMarkup
        }
        return args.format ? args.format({ value, record }) : value
    },
} as Partial<Column>)
