import {
    Fill,
    Stroke,
    Style,
    Text,
} from 'ol/style'
import Feature from 'ol/Feature'
import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
import Geometry from 'ol/geom/Geometry'
import { isNullOrEmpty } from '@/utils/array-utils'
import Map from 'ol/Map'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

export interface IFindNearbyHighlightLayerParams {

    // The OpenLayers map that be the target of the buffer layer.
    targetMap: Map
}

// TODO: hide/show layer depending on whether there's any data

export class FindNearbyHighlightLayer {
    // The OpenLayers map that be the target of the integration.
    private targetMap: Map

    // A layer that will display the 'nearby' area - a buffer around one or more titles.
    private readonly layer: VectorLayer<VectorSource<Geometry>>

    private readonly highlightStyle = new Style({
        fill: new Fill({ color: FindNearbyDefaults.HIGHLIGHT_DEFAULT_FILL_COLOUR }),
        stroke: new Stroke({
            color: FindNearbyDefaults.HIGHLIGHT_DEFAULT_STROKE_COLOUR,
            width: 3,
        }),
        text: new Text({
            font: '14px Calibri,sans-serif',
            overflow: true,
            fill: new Fill({ color: FindNearbyDefaults.HIGHLIGHT_TEXT_FILL_COLOUR }),
            stroke: new Stroke({
                color: FindNearbyDefaults.HIGHLIGHT_TEXT_STROKE_COLOUR,
                width: 8,
            }),
        }),
    })

    constructor(params: IFindNearbyHighlightLayerParams) {
        // Initialise the map layer.
        this.layer = new VectorLayer({
            source: new VectorSource(),
            zIndex: 850,
            style: (feature: Feature) => {
                // TODO: Consider highlight is managed so as not to conflict with other layers (such as the eye on
                //  in the list)
                const titleText: string = feature.get('title_no')

                this.highlightStyle.getText().setText(titleText)
                return this.highlightStyle
            },
        })

        this.setTargetMap(params.targetMap)
    }

    public setTargetMap(map: Map): void {
        this.targetMap = map

        if (this.targetMap) {
            this.targetMap.addLayer(this.layer)
        }
    }

    public setHighlightFeatures(features: Feature[]): void {
        this.layer.getSource().clear()

        if (!isNullOrEmpty(features)) {
            this.layer.getSource().addFeatures(features)
        }
    }

    // Resets the current buffer layer.
    public reset():void {
        this.layer.getSource().clear()
    }

    public dispose(): void {
        this.reset()
        if (this.targetMap) {
            this.targetMap.removeLayer(this.layer)
        }
        this.layer.dispose()
    }
}
