<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 24 24"
                  desc="Searches icon">
        <path id="Union"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6491 8.32505C16.6491 3.7346 12.9145 0 8.32407 0C3.73362 0 -0.000976562 3.7346 -0.000976562 8.32505C-0.000976562 12.9155 3.73362 16.6501 8.32407 16.6501C12.9145 16.6501 16.6491 12.9155 16.6491 8.32505ZM8.32407 14.6679C4.82667 14.6679 1.98118 11.8224 1.98118 8.32505C1.98118 4.82765 4.82667 1.98215 8.32407 1.98215C11.8215 1.98215 14.667 4.82765 14.667 8.32505C14.667 11.8224 11.8215 14.6679 8.32407 14.6679ZM22.0018 19.593C21.9974 19.4492 21.9639 19.3078 21.9031 19.1774C21.8424 19.0469 21.7558 18.9302 21.6486 18.8342L15.5666 13.4116C14.9768 14.2485 14.2476 14.9778 13.4106 15.5675L18.8333 21.6495C19.2172 22.0981 19.9607 22.1196 20.3699 21.6936L21.6926 20.3708C21.7942 20.2689 21.8739 20.1474 21.9271 20.0137C21.9803 19.88 22.0057 19.7369 22.0018 19.593ZM5.70905 5.00526C6.40268 4.31162 7.34312 3.92142 8.32407 3.92026C9.30502 3.92142 10.2455 4.31162 10.9391 5.00526C11.6327 5.69889 12.0229 6.63933 12.0241 7.62028C12.0241 8.63171 11.4449 9.78805 10.3027 11.0572C9.79382 11.6209 9.24069 12.143 8.64857 12.6185C8.55579 12.6906 8.44161 12.7298 8.32407 12.7298C8.20653 12.7298 8.09235 12.6906 7.99957 12.6185C7.40744 12.143 6.85432 11.6209 6.34548 11.0572C5.20319 9.78805 4.62405 8.63171 4.62405 7.62028C4.62521 6.63933 5.01541 5.69889 5.70905 5.00526ZM7.3452 9.08526C7.63495 9.27886 7.97559 9.38219 8.32407 9.38219C8.7912 9.38167 9.23904 9.19587 9.56935 8.86556C9.89966 8.53525 10.0855 8.08741 10.086 7.62028C10.086 7.27181 9.98265 6.93116 9.78905 6.64141C9.59545 6.35167 9.32027 6.12584 8.99832 5.99248C8.67638 5.85913 8.32211 5.82424 7.98034 5.89222C7.63856 5.9602 7.32462 6.12801 7.07821 6.37442C6.8318 6.62083 6.66399 6.93477 6.59601 7.27655C6.52803 7.61832 6.56292 7.97259 6.69627 8.29453C6.82963 8.61648 7.05546 8.89166 7.3452 9.08526Z"
              fill="#0E437C" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconSearches',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '24px',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
        },
    }
</script>
