<template>
    <ow-select :selected-value="inputVal"
               data-track="MAT-TITLE-LIST - Add titles to a group"
               :data-test="selectDataTestAttribute"
               :items="groups"
               :label="$t('matter.addTitlesPanel.addToGroup')"
               is-dense
               hide-details
               is-outlined
               item-text="name"
               item-value="id"
               :clearable="clearable"
               :disabled="disabled"
               @clear="emit('clear')"
               @change="onChange" />
</template>

<script setup lang="ts">
    import {
        computed,
        PropType,
    } from 'vue'
    import { useStore } from "vuex"

    import OwSelect from '@/components/core/ow-select.vue'
    import AddToGroup, { GroupItem } from '@/components/matter/titles/matter-side-panel-add-titles/add-to-group.vue'
    import { MATTER_MUTATE_CREATE_GROUP_PROMPT } from '@/store/modules/matter/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    export interface GroupItem {
        id: number;
        name: string;
        sortOrder: number;
    }

    const store = useStore()
    const props = defineProps({
        modelValue: Object as PropType<GroupItem>,
        selectDataTestAttribute: { type: String, default: 'matter-side-panel-add-titles-add-to-group' },
        clearable: Boolean,
        disabled: { type: Boolean, default: false },
    })

    const emit = defineEmits<{
        (e: 'update:modelValue', value: GroupItem),
        (e: 'clear'),
    }>()

    const inputVal = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            emit('update:modelValue', value)
        },
    })

    const groups = computed(() => {
        const groups = [...store.state.matter.currentMatter.groups]
        groups.push({
            id: -1,
            name: 'Add New Group...',
            sortOrder: Infinity,
        })
        return groups
    })

    const onChange = (event: any) => {
        inputVal.value = event

        if (event.id === -1) {
            store.commit(MATTER_MUTATE_CREATE_GROUP_PROMPT, {
                show: true,
                callback: (newGroup) => {
                    inputVal.value = newGroup
                },
            })

            store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MAT-TITLE-LIST - Add titles to a group',
                metadata: {
                    groupName: event.id === -1 ? 'Created New Group' : event,
                },
            })
        }
    }
</script>
