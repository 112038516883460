import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { getNotificationHubUri } from "@/utils/environment.utils"

export type IUserEmailPreferences = {
    optedIn: boolean
}

export default class UserEmailPreferencesApi {
    static emailController: AbortController = null

    static END_POINT = `${ getNotificationHubUri() }/api/email`

    /**
     * Retrieves the user's email preferences for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     *
     * @return {Promise<IHttpClientResponse<IUserEmailPreferences>>} - A Promise that resolves to the HTTP response containing the notification settings.
     */
    static getUserEmailPreferences(matterId: number): Promise<IHttpClientResponse<IUserEmailPreferences>> {
        return httpClient.get(`${ this.END_POINT }/${ matterId }`)
    }

    /**
     * Updates the user email preferences for a given matter.
     *
     * @param {number} matterId - The ID of the matter to update the notification settings for.
     * @param {IUserEmailPreferences} request - The new preferences to be applied.
     *
     * @returns {Promise<IHttpClientResponse<IUserEmailPreferences>>} A promise that resolves to the updated settings.
     */
    static updateUserEmailPreferences(matterId: number, request: IUserEmailPreferences): Promise<IHttpClientResponse<IUserEmailPreferences>> {
        if (this.emailController) {
            this.emailController.abort()
        }

        this.emailController = new AbortController()
        return httpClient.put(`${ this.END_POINT }/${ matterId }`, request)
    }
}
