import {
    CreateOverlayRequest,
    CreateOverlayResponse,
    DetectOverlaysRequest,
    DetectOverlaysResponse,
    OverlayListItemModel,
    OverlayModel,
    UpdateOverlayRequest,
    UpdateOverlayResponse,
} from '@/components/map/overlays/overlays-types'
import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { getOverlaysApiUri } from '@/utils/environment.utils'

export default class OverlaysApi {
    static END_POINT = `${ getOverlaysApiUri() }/overlays`

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static async detectOverlays(request: DetectOverlaysRequest):Promise<DetectOverlaysResponse> {
        // TODO: replace with actual implementation
        return Promise.resolve({
            detectedOverlays: [],
        })
    }

    static async createOverlay(request: CreateOverlayRequest):Promise<IHttpClientResponse<CreateOverlayResponse>> {
        return httpClient.post(this.END_POINT, request)
    }

    static async getOverlays(matterId: number):Promise<IHttpClientResponse<Array<OverlayListItemModel>>> {
        return httpClient.get(`${ this.END_POINT }/list?matterId=${ matterId }`, { headers: { 'Cache-Control': 'no-cache, no-store' } })
    }

    static async getOverlay(overlayId: string, matterId?: number):Promise<IHttpClientResponse<OverlayModel>> {
        return httpClient.get(`${ this.END_POINT }/${ overlayId }${ (matterId ? `?matterId=${ matterId }` : '') }`, { headers: { 'Cache-Control': 'no-cache, no-store' } })
    }

    static async removeOverlay(overlayId: string):Promise<IHttpClientResponse> {
        return httpClient.delete(`${ this.END_POINT }/${ overlayId }`)
    }

    static async updateOverlay(overlayId: string, overlay: UpdateOverlayRequest):Promise<IHttpClientResponse<UpdateOverlayResponse>> {
        return httpClient.put(`${ this.END_POINT }/${ overlayId }`, overlay)
    }
}
