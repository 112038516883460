<template>
    <div class="exported-documents">
        <div class="exported-documents--label">
            <ow-colored-label class="accents-small"
                              is-beta-label
                              is-extra-small />
            <span v-t="'documents.export.betaLabel'"
                  class="accents-italic" />
        </div>
        <exported-documents-summary :is-loading="isLoading"
                                    :loading-bulk-download="loadingBulkDownload"
                                    :documents="documents"
                                    :selected-documents="selectedDocuments"
                                    @refresh-documents="$emit('refresh-documents')"
                                    @download-as-xlsx-clicked="handleDownloadClicked" />

        <exported-documents-table :documents="documents"
                                  @download="$emit('download', $event)"
                                  @selected-documents-changed="setSelectedDocuments" />
    </div>
</template>

<script lang="ts">
    import { mapActions,
             mapGetters } from 'vuex'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import ExportedDocumentsSummary from '@/components/documents/library/exported-documents/exported-documents-summary.vue'
    import ExportedDocumentsTable from '@/components/documents/library/exported-documents/exported-documents-table.vue'
    import { Route } from '@/enums/route.enum'
    import {DOWNLOAD_MANY} from "@/store/modules/documents/documents-types"
    import {LOGGING_HEAP_TRACK_EVENT} from "@/store/mutation-types"

    const EVENTS = {
        download: 'download',
        view: 'view',
        refresh: 'refresh-documents',
        selectDocuments: 'select-documents',
    }

    export default {
        name: 'ExportedDocuments',

        components: {
            OwColoredLabel,
            ExportedDocumentsTable,
            ExportedDocumentsSummary,
        },

        props: {
            currentMatterId: {
                type: [String, Number],
                required: true,
            },
            documents: {
                type: Array,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                selectedDocuments: [],
                loadingBulkDownload: false,
                tabHash: '#exported',
            }
        },

        computed: {

            ...mapGetters({
            }),

            routeName() {
                return this.$route.name
            },
        },

        watch: {
            routeName(newRouteName, oldRouteName) {
                if (newRouteName !== oldRouteName ) {
                    const onThisTab = this.tabHash == this.$route.hash
                    if (!onThisTab) {
                        return
                    }
                    switch (newRouteName) {
                        case Route.DocumentsLibrary:
                            this.clearSelectedTitle()
                            break
                    }
                }
            },
        },

        methods: {

            ...mapActions({
                bulkDownload: DOWNLOAD_MANY,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            setSelectedDocuments(val) {
                this.selectedDocuments = val
                this.$emit(EVENTS.selectDocuments, val)
            },

            async handleDownloadClicked() {
                this.loadingBulkDownload = true
                const request = this.selectedDocuments.map(document => {
                    return {
                        documentType: document.documentType,
                        documentId: document.documentId,
                        responseType: 'blob',
                    }
                })
                await this.bulkDownload(request)
                await this.logHeapEvent({
                    type: 'DOCS - Export as XLSX',
                    metadata: {
                        option: 'Separate .xlsx files',
                        numberOfTitles: this.selectedDocuments.length,
                        matterId: this.currentMatterId,
                    },
                })
                this.loadingBulkDownload = false
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './exported-documents';
</style>
