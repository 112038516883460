<template>
    <ow-card v-if="!searchesDisabled"
             title="Searches"
             :loading="!selectedTitle?.record"
             :loading-skeleton="{ circle: true }"
             class="property-searches-card">
        <button data-test="property-searches-card-button"
                class="property-searches-card__button d-flex justify-space-between align-center py-4 px-4"
                :class="{
                    'property-searches-card__button--disabled': loading,
                }"
                @click="onButtonClick">
            <ow-icon-searches class="property-searches-card__icon" />
            <div class="property-searches-card__text d-flex flex-grow-1 flex-column justify-start px-4">
                <span v-t="'searches.orderSearches'"
                      class="body-highlight d-flex justify-start" />
                <span v-t="'searches.orderSearchesDescription'"
                      class="caption-regular d-flex justify-start"
                      style="opacity: 0.6;" />
            </div>
            <div class="property-searches-card__arrow">
                <ow-icon icon="ow-icon-chevron-right" />
            </div>
        </button>
    </ow-card>
</template>

<script setup lang="ts">
    import { computed,
             ref } from 'vue'
    import { useRoute,
             useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OwIconSearches from '@/components/core/icons/ow-icon-searches-intro.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwIcon from '@/components/core/ow-icon.vue'
    import { Route } from '@/enums/route.enum'
    import {
        getStepName,
        SearchesSteps,
    } from '@/enums/searches.enum'
    import { MATTER_ADD_MULTIPLE_TITLES } from '@/store/modules/matter/types'
    import {
        SEARCHES_MUTATE_MAP_KEY,
    } from '@/store/modules/searches/types'
    const router = useRouter()
    const route = useRoute()
    const props = defineProps<{
        titleNumber?: string
        matterId?: number
        selectedTitle?: any
    }>()
    const store = useStore()

    const searchesDisabled = computed<boolean>(() => route?.name === Route.TitleDetails)
    const loading = ref(false)

    const onButtonClick = async () => {
        loading.value = true
        try {
            if (searchesDisabled.value) {
                return
            }

            // is title number in the matter
            const title = store.state.currentMatter?.selectedTitleNumbers?.find((t) => t.titleNumber === props?.titleNumber)
            if (!title) {
                // add title number to the matter
                await store.dispatch(MATTER_ADD_MULTIPLE_TITLES, {
                    matterId: props?.matterId,
                    titleNumbers: [props?.titleNumber],
                    showPopup: false,
                })
            }
            await router.push({
                name: Route.MatterSearchesCreate,
                params: {
                    args: props?.titleNumber,
                    matterId: props?.matterId?.toString(),
                    step: getStepName(SearchesSteps.DefinePolygon),
                },
            })
            store.commit(SEARCHES_MUTATE_MAP_KEY)
        } finally {
            loading.value = false
        }
    }

    defineExpose({
        router,
        searchesDisabled,
    })
</script>

<style lang="scss">
    @import './property-searches-card.scss';
</style>
