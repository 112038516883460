<template>
    <v-tooltip :disabled="!tooltipText"
               location="left">
        <template #activator="{ props }">
            <div :class="{ disabled: disabled }"
                 :data-test="dataTestAttribute || 'app-dropdown-menu-item'"
                 :data-track="dataTrackAttribute"
                 class="app-dropdown-menu-item"
                 v-bind="props"
                 @click="onClick">
                <div class="app-dropdown-menu-item__content">
                    <v-icon v-if="icon"
                            class="app-dropdown-menu-item__icon">
                        {{ icon }}
                    </v-icon>
                    <span class="app-dropdown-menu-item__content-text"
                          data-test="app-dropdown-menu-item-text">{{ text }}</span>
                    <div v-if="hasBetaLabel"
                         class="beta-label"
                         data-test="app-dropdown-menu-item-beta-label">
                        NEW
                    </div>
                </div>
                <div v-if="count"
                     class="app-dropdown-menu-item__count"
                     data-test="app-dropdown-menu-item-count">
                    {{ count }}
                </div>
            </div>
        </template>
        <span :data-test="`${ dataTestAttribute || 'app-dropdown-menu-item' }-tooltip-text`">{{ tooltipText }}</span>
    </v-tooltip>
</template>

<script>
    export default {
        props: {
            count: {
                type: Number,
                required: false,
                default: null,
            },
            dataTestAttribute: {
                type: String,
                required: false,
                default: null,
            },
            dataTrackAttribute: {
                type: String,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            hasBetaLabel: {
                type: Boolean,
                required: false,
            },
            icon: {
                type: String,
                required: false,
                default: null,
            },
            text: {
                type: String,
                required: true,
            },
            tooltipText: {
                type: String,
                required: false,
                default: null,
            },
        },

        emits: ['click'],

        methods: {
            onClick(val) {
                if (!this.disabled) {
                    this.$emit('click', val)
                }
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './ow-dropdown-menu-item'
</style>
