<template>
    <div data-test="tab-title-number-results">
        <no-search-results v-if="count === 0"
                           context="TitleNumber" />
        <v-list v-else
                density="compact"
                light>
            <v-list-subheader>
                <span class="body-copy"
                      data-test="tab-title-number-results-count">
                    {{ count }} results found
                </span>
            </v-list-subheader>
            <div class="title-number-results">
                <div v-for="(title, index) in titleNumbers"
                     :key="`${ title.number }-${ index }`"
                     class="tab-title-number-results__search-result"
                     data-test="tab-title-number-results-search-result"
                     data-track="SEARCH - Title number search result"
                     @click="$emit('selected', title)">
                    <div class="tab-title-number-results__title-wrapper">
                        <v-icon>$organisation</v-icon>
                        <span class="body-copy">{{ title.number }}</span>
                    </div>
                    <div class="tab-title-number-results__title-type">
                        <span class="body-copy--greyout">{{ title.tenure }}</span>
                    </div>
                </div>
            </div>
        </v-list>
    </div>
</template>

<script>
    import NoSearchResults from '@/components/map/search/no-search-results.vue'

    export default {
        name: 'TabTitleNumberResults',
        components: {
            NoSearchResults,
        },

        props: {
            count: {
                type: Number,
                required: true,
            },

            titleNumbers: {
                type: Array,
                required: true,
            },
        },

        emits: [
            'selected',
        ],

        data() {
            return {}
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './tab-title-number-results'
</style>
