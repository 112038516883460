<template>
    <v-switch v-model="modelValue"
              v-bind="props"
              color="primary"
              :loading="isLoading"
              hide-details />
</template>

<script setup lang="ts">
    const modelValue = defineModel()
    const props = defineProps<{
        label?: string,
        isLoading?: boolean,
    }>()
</script>
