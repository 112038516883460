<template>
    <div class="ow-lock-card">
        <div class="ow-lock-card__content d-flex">
            <ow-icon-padlock />
            <div class="d-flex pl-6 flex-column gr-2">
                <section>
                    <div class="headers-display">
                        {{ title ?? header }}
                    </div>
                </section>
                <section v-if="!title"
                         class="ow-lock-card__message d-flex flex-column">
                    <div class="body-regular">
                        {{ t('licencing.cardText', { licence: licenceType }) }}
                    </div>
                </section>
                <ow-button-ds class="ow-lock-card__support mt-4"
                              is-primary
                              @click="onSupportBtnClick">
                    {{ t('buttons.support') }}
                </ow-button-ds>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

    import { computed } from "vue"
    import { useI18n } from "vue-i18n"
    import { useStore } from "vuex"

    import OwIconPadlock from "@/components/core/icons/ow-icon-padlock.vue"
    import OwButtonDs from "@/components/core/ow-button-ds.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import { LicenceType } from "@/enums/licenceType"
    import { LOGGING_HEAP_TRACK_EVENT } from "@/store/mutation-types"

    const props = defineProps<{
        title?: string
        featureId?: FeatureId
        licenceType?: LicenceType
    }>()
    const { t } = useI18n()
    const store = useStore()

    const onSupportBtnClick = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: `Premium feature request`,
            metadata: {
                feature: props.featureId,
            },
        })
        if (window.Intercom) {
            window.Intercom('showNewMessage')
        }
    }

    const header = computed(() => {
        let friendlyFeature = t(`licencing.features.${ props?.featureId }`)
        if (!friendlyFeature) {
            friendlyFeature = props?.featureId
        }
        return t('licencing.tooltipTitle', { feature: friendlyFeature })
    })
</script>

<style lang="scss">
@import './ow-lock-card.scss';
</style>
