<template>
    <core-map v-if="isLoaded"
              :initial-view="initialView"
              :interactions="interactions"
              :layer-attribution="layerAttribution"
              :layers="layers"
              :name="name"
              :show-layer-attribution="showLayerAttribution"
              :show-map-scale="false"
              :show-watermark="true"
              data-test="ow-title-boundary-map"
              @loaded="$emit('loaded')"
              @loading="$emit('loading')"
              @map-initialised="handleMapInitialised" />
    <ow-title-boundary-map-loading-skeleton v-else />
</template>

<script lang="ts" setup>
    import {createEmpty,
            extend} from "ol/extent"
    import GeoJSON from 'ol/format/GeoJSON'
    import { Vector as VectorLayer } from 'ol/layer'
    import Map from 'ol/Map'
    import { Vector as VectorSource } from 'ol/source'
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'

    import {BoundaryMapLayer} from "@/components/core/maps/boundary-map-layer"
    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import OwTitleBoundaryMapLoadingSkeleton from '@/components/loading-skeletons/ow-title-boundary-map-loading-skeleton.vue'
    import {
        BASE_LAYERS,
        MAX_ZOOM,
        MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
        MIN_ZOOM,
        PADDING,
    } from '@/consts/map'
    import { OsImageTileLayer } from '@/store/modules/map/layers/os-image-tile-layer'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import {getOLStyleForOWStyleDefinition} from "@/utils/map-utils"

    import CoreMap from './core-map.vue'

    const props = defineProps({
        layerAttribution: {
            type: String,
            required: false,
        },
        enableMapInteractions: {
            type: Boolean,
            default: false,
        },
        baseLayer: {
            type: String,
            default: BASE_LAYERS.OS_MAP,
            required: false,
        },
        name: {
            type: String,
            required: false,
            default: 'boundary-map',
        },
        layerCollection: {
            type: Array<BoundaryMapLayer>,
            required: true,
        },

        /**
         * Used for previewing maps where screen space is limited.
         */
        showLayerAttribution: {
            type: Boolean,
            required: false,
            default: true,
        },

        padding: {
            type: Array<number>,
            required: false,
            default: PADDING,
        },
    })

    const emit = defineEmits<{
        (e: 'loaded'),
        (e: 'loading'),
    }>()

    const geometryLoaded = ref<boolean>(false)
    const baseTileLayer = ref<any>(null)
    const extent = ref<any>(createEmpty())
    const map = ref<Map | null>(null) //null until initialised

    const interactions = computed((): CoreMapInteractions => {
        if (props.enableMapInteractions) {
            return {
                doubleClickZoom: true,
                dragPan: true,
                keyboard: true,
                mouseWheelZoom: true,
            }
        }
        return {}
    })

    const initialView = computed((): CoreMapView => {
        return {
            minZoom: MIN_ZOOM,
            maxZoom: MAX_ZOOM,
            paddingWithinMap: [ 24, 24, 24, 24 ],
        }
    })

    const layers = ref<Array<any>>([])
    const layersSet = computed((): Boolean => {
        return !isNullOrEmpty(layers.value)
    })

    function getFeatureCollection(geoJson: string){
        let parsedGeoJson = JSON.parse(geoJson)

        if (parsedGeoJson.type === 'FeatureCollection') {
            return parsedGeoJson
        }

        if (parsedGeoJson.type === 'Feature') {
            return {
                type: 'FeatureCollection',
                features: [parsedGeoJson],
            }
        }

        // If it's a Geometry, wrap it in a Feature first
        return {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: parsedGeoJson,
                },
            ],
        }
    }

    function getGeometryLayer(source: BoundaryMapLayer) {
        let vectorLayer = new VectorLayer({
            zIndex: 16,
            source: new VectorSource(),
            updateWhileAnimating: false,
            updateWhileInteracting: false,
            style: getOLStyleForOWStyleDefinition(source),
        })
        const format = new GeoJSON()
        const featureCollection = getFeatureCollection(source.geoJson)
        const features = format.readFeatures(featureCollection)
        vectorLayer.getSource().addFeatures(features)
        return vectorLayer
    }

    const setLayers = () => {
        baseTileLayer.value = new OsImageTileLayer({
            layerName: props.baseLayer,
            getTargetMapFn: () => undefined,
        }).getLayer()

        let tempLayers: Array<any> = []

        if(!isNullOrEmpty(props.layerCollection)){
            extent.value = createEmpty()
            props.layerCollection.forEach((itm) => {
                let vectorLayer = getGeometryLayer(itm)
                //Get largest extent
                extend(extent.value, vectorLayer.getSource().getExtent())
                tempLayers.push(vectorLayer)
            })
        }

        layers.value = [
            baseTileLayer.value,
            ...tempLayers,
        ]
        geometryLoaded.value = true
        setMapExtent()
    }

    watch(() => props.baseLayer, (): void => {
        setLayers()
    })
    watch(() => props.layerCollection, (): void => {
        setLayers()
    })

    onMounted(() => {
        setLayers()
    })

    const isLoaded = computed((): Boolean => {
        return layersSet.value &&
            geometryLoaded.value
    })

    const handleMapInitialised = (mapObj: Map): void => {
        map.value = mapObj
        setMapExtent()
    }

    const setMapExtent = (): void => {
        if (!isNullOrEmpty(layers.value) && map.value != null) {
            const view = map.value.getView()
            view.fit(extent.value, {
                maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
                duration: 200,
                padding: props.padding,
            })
        }
    }
</script>
