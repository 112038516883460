import { getDocumentUploadingApiUri } from '@/utils/environment.utils'
import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { isNullOrWhitespace } from '@/utils/string-utils'
import { StatusCode } from '@/consts/status-code'
import _ from 'lodash'
import axios from 'axios'
import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'

export const FORM_DATA_KEY = 'files'

export default class DocumentUploadApi {
    static END_POINT = '/uploads'

    /**
     * Upload files to the server in parallel in batches of a specified size
     * @static
     * @param matterId - associated matter Id
     * @param formData - form data containing the files to upload
     * @param batchSize - maximum number of files to upload in each request
     * @param matterGroupId - the associated matter group Id
     * @returns {Promise<IHttpClientResponse>}
     */
    static async uploadInBatches(
        matterId: number,
        formData: FormData,
        batchSize = 10,
        matterGroupId: number = null): Promise<{ id: string, data: { documents: Array<IUploadedDocument> }}> {
        const allFiles = formData.getAll(FORM_DATA_KEY)
        const fileBatches = _.chunk(allFiles, batchSize)
        const forms = fileBatches.map(files => files.reduce((formData, file) => {
            formData.append(FORM_DATA_KEY, file)
            if (matterGroupId != null) {
                formData.append('matterGroupId', matterGroupId.toString())
            }
            return formData
        }, new FormData()))

        const response = await axios.all(forms.map(files => this.upload(matterId, files)))
        if (response) {
            const id = response?.at(-1).data?.id
            const documents: Array<IUploadedDocument> = response.map(body => body?.data?.documents).flat()

            return {
                id,
                data: { documents },
            }
        }

        return null
    }

    /**
     * Upload files to the server.
     * @static
     * @param matterId - the associated matter Id.
     * @param formData
     * @returns {Promise<IHttpClientResponse>}
     */
    static upload(matterId: number, formData: FormData): Promise<IHttpClientResponse> {
        const uri = `${ getDocumentUploadingApiUri() }${ this.END_POINT }/${ matterId }`

        return httpClient
            .post(uri, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
    }

    /**
     * Get all documents uploaded to the specified matter
     * @param matterId
     */
    static getByMatterId(matterId: number): Promise<IHttpClientResponse> {
        const uri = `${ getDocumentUploadingApiUri() }${ this.END_POINT }/${ matterId }`

        return httpClient.get(uri)
    }

    /**
     * Get the uri,that includes the token used to access the blob storage, for the uploaded document.
     * @param matterId
     * @param documentId
     * @param isDownload - Set to true if requiring the download url, false to view the document
     */
    static async getDocumentUrlWithToken(
        matterId: number,
        documentId: number,
        isDownload = false,
    ): Promise<IHttpClientResponse> {
        const uri = `${ getDocumentUploadingApiUri() }${ this.END_POINT }/${ matterId }/${ documentId }`

        let options = {}
        if (isDownload) {
            options = {
                params: {
                    isDownload: true,
                },
            }
        }
        return httpClient.get(uri, options)
    }

    /**
     * Trigger the download of the specified document
     * @param matterId
     * @param documentId
     */
    static async downloadDocumentById(
        matterId: number,
        documentId: number,
    ): Promise<IHttpClientResponse> {
        try {
            const response = await this.getDocumentUrlWithToken(matterId, documentId, true)
            if (response.ok) {
                const filename = this.getFilenameFromDisposition(response.data.header.value)
                const headers = {
                    'Response-Type': 'blob',
                }
                headers[response.data.header.key] = response.data.header.value

                const pdfBlob = await fetch(
                    response.data.uri,
                    {
                        headers,
                    })

                const blob = new Blob([await pdfBlob.blob()], { type: 'application/pdf' })
                window.saveAs(blob, filename)

                return {
                    ok: true,
                    status: StatusCode.SUCCESS,
                } as IHttpClientResponse
            }
        } catch (e) {
            const message = `Unable to get SASS token to download/view uploaded doc: matterId: ${ matterId }, documentId: ${ documentId }`
            console.error(message, e)
            return {
                ok: false,
                status: StatusCode.INTERNAL_SERVER_ERROR,
                message,
            } as IHttpClientResponse
        }
    }

    static getFilenameFromDisposition(contentDisposition: string): string {
        if (isNullOrWhitespace(contentDisposition)) {
            return ''
        }

        let filename = contentDisposition
            .split('filename=')[1]
            .split(';')[0]

        if (filename.includes('"')) {
            filename = filename.replace(/"/g, '')
        }

        return filename
    }

    /**
     * remove an uploaded document from a matter
     * @static
     * @param document an uploaded document
     * @param matterId the associated matter Id.
     * @returns {Promise<T | *>}
     */
    static removeUploadedDocumentFromMatter(matterId, documentId) {
        const uri = `${ getDocumentUploadingApiUri() }${ this.END_POINT }/${ matterId }`
        return httpClient.delete(uri, { data: [documentId] })
            .then(response => response.data)
    }
}
