<template>
    <!-- Google street view related -->
    <div v-show="show"
         data-test="map-streetview">
        <img id="gsvPanoMarker"
             alt="marker to show the place that was clicked on the map"
             src="../../media/svmarker.png"
             style="display:none" />
        <div id="googleMapStreetViewTip"
             :class="{'initial-prompt': selectingStartLocation, 'title-selection-prompt': showTitleSelectionPrompt}"
             data-test="map-streetview-tip">
            <span v-if="selectingStartLocation">Click on a blue line to set the initial Google Streetview location.</span>
            <span v-else>Click on a blue line to update the Google Streetview location.</span>
        </div>

        <div id="googleMap"
             :class="{selecting: selectingStartLocation}"
             data-test="map-streetview-google-map" />

        <div id="googleStreetViewPanorama" />
    </div>
</template>

<script>
    import Feature from 'ol/Feature'
    import Point from 'ol/geom/Point'
    import { Vector as VectorLayer } from 'ol/layer'
    import { transformExtent } from 'ol/proj'
    import { Vector as VectorSource } from 'ol/source'
    import {
        Circle as CircleStyle,
        Fill,
        Icon,
        Stroke,
        Style,
    } from 'ol/style'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import { CoordinateSystemCode } from '@/enums/coordinate-systems'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { MAP_UPDATE_SIZE } from '@/store/modules/map/types'
    import {
        SITE_VISIT_MUTATE_BOUNDS,
        SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER,
    } from '@/store/modules/site-visit/types'

    export default {
        name: 'SiteVisit',

        mixins: [FeatureFlagsMixin],

        data() {
            return {
                layer: null,
                source: null,
                cursorFeature: null,
                panoramaFeature: null,
                showTitleSelectionPrompt: false,
            }
        },

        computed: {
            ...mapState({
                enabled: state => state.siteVisit.enabled,
                initialized: state => state.siteVisit.initialized,
                owMap: state => state.map.map,
                selectingStartLocation: state => state.siteVisit.selectingStartLocation,
                panoramaHeading: state => state.siteVisit.panoramaHeading,
                panoramaLocation: state => state.siteVisit.panoramaLocation,
                currentMapCentre: state => state.map.currentMapCentre,
                viewStoppedMovingAt: state => state.map.viewStoppedMovingAt,
                pointerLocation: state => state.siteVisit.pointerLocation,
                selectedTitleNumber: state => state.title.selectedTitleNumber,
                currentZoomLevel: state => state.map.currentZoomLevel,
            }),

            heading() {
                return this.panoramaHeading * Math.PI / 180
            },

            show() {
                return this.enabled && this.initialized
            },
        },

        watch: {
            initialized(val) {
                this.updateMapSize()

                this.updateStreetViewMapLocation()

                if (val === true) {
                    // add cursor and panorama layer
                    this.cursorFeature = new Feature({
                        geometry: new Point([0, 0]),
                        size: 10,
                    })
                    this.panoramaFeature = new Feature({
                        geometry: new Point([0, 0]),
                        size: 10,
                    })
                    this.panoramaFeature.setStyle(new Style({
                        image: new Icon(({
                            anchor: [0.5, 46],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                            src: document.getElementById('gsvPanoMarker').src,
                            scale: 0.5,
                        })),
                    }))

                    this.source = new VectorSource({
                        features: [this.cursorFeature, this.panoramaFeature],
                        wrapX: false,
                    })
                    this.layer = new VectorLayer({
                        source: this.source,
                        zIndex: 15,
                        style: new Style({
                            image: new CircleStyle({
                                radius: 5,
                                fill: new Fill({ color: '#ffffff' }),
                                stroke: new Stroke({
                                    color: '#000000',
                                    width: 1,
                                }),
                            }),
                        }),
                    })
                    this.owMap.addLayer(this.layer)

                    setTimeout(function() {
                        this.showTip = false
                    }, 5000)
                }
            },

            currentMapCentre() {
                this.updateStreetViewMapLocation()
            },

            currentZoomLevel() {
                this.updateStreetViewMapLocation()
            },

            viewStoppedMovingAt() {
                this.updateStreetViewMapLocation()
            },

            pointerLocation(val) {
                if (this.layer !== null) {
                    if (val === null) {
                        this.cursorFeature.setGeometry(null)
                    } else {
                        this.cursorFeature.setGeometry(new Point(val))
                    }
                }
            },

            panoramaLocation(val) {
                if (this.layer !== null) {
                    if (val === null) {
                        this.panoramaFeature.setGeometry(null)
                    } else {
                        this.panoramaFeature.setGeometry(new Point(val))
                    }
                }
            },

            enabled(val) {
                if (val === false && this.panoramaFeature !== null) {
                    this.panoramaFeature.setGeometry(null)
                }
                if (this.layer !== null) {
                    this.layer.setVisible(val)
                }
            },

            heading(val) {
                if (this.layer !== null) {
                    if (val !== null) {
                        this.panoramaFeature.getStyle().getImage().setRotation(this.heading)
                    }
                }
            },

            selectingStartLocation(val) {
                if (this.layer !== null) {
                    if (val !== null) {
                        this.panoramaFeature.getStyle().getImage().setRotation(val * Math.PI / 180)
                    }
                }
            },

            selectedTitleNumber: function(val) {
                // If the selected title is cleared, update the layer
                if (val === null) {
                    this.updateTitleBoundaryLayer()
                }
                if (Boolean(val) && this.enabled) {
                    this.showTitleSelectionPrompt = true
                    setTimeout(() => {
                        this.showTitleSelectionPrompt = false
                    }, 5000)
                }
            },
        },

        mounted() {
            // NOTE: Give access to site visit component if the window is an E2E test
            if (window.Cypress) {
                window.siteVisit = this
            }
        },

        methods: {
            ...mapActions({
                updateMapSize: MAP_UPDATE_SIZE,
                updateTitleBoundaryLayer: SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER,
            }),

            ...mapMutations({
                setStreetviewBounds: SITE_VISIT_MUTATE_BOUNDS,
            }),

            updateStreetViewMapLocation() {
                if (this.enabled && this.initialized) {
                    const mainMapExtent = transformExtent(this.owMap.getView()
                        .calculateExtent(), CoordinateSystemCode.EPSG27700, CoordinateSystemCode.EPSG4326)
                    this.setStreetviewBounds(mainMapExtent)
                }
            },
        },
    }

</script>

<style lang="scss">
    @import 'map-streetview';
</style>
