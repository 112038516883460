export const mapToCommonTitleInformation = (source: any, useV2PropertySources: boolean = false) => {
    if (source === null) {
        return null
    }

    const root = { ...source.titleInformation }

    return useV2PropertySources
        ? mapV2Properties(root, source)
        : mapLegacyProperties(root, source.titleInformation)
}

const mapV2Properties = (root: any, source: any) => {
    const mapped = {
        ...root,
        proprietorships: source.commonTitleInformation.proprietorships,
        editionDate: source.commonTitleInformation.editionDate,
        addresses: source.commonTitleInformation.addresses,
        source: source.commonTitleInformation.source,
        titleMetadata: source.commonTitleInformation.titleMetadata,
    }

    // registers
    if (source.commonTitleInformation.sections) {
        mapped.bgProprietorshipData.registers = source.commonTitleInformation.sections.map((section: any) => {
            return {
                displayName: section.title,
                prefix: section.code,
                show: true,
                searchCount: 0,
                highlightText: [],
                highlightEntryNumbers: [],
                showEntries: [],
                showAllEntries: true,
                filterCounts: [],
                expandPanel: true,
                entries: section.entries.map((entry: any) => ({
                    nr: entry.entryNumber,
                    code: entry.entryNumber.toString(),
                    displayString: entry.text,
                })),
            }
        })
        mapped.titleMetadata.bgDataLoaded = true
    }

    return mapped
}

const mapLegacyProperties = (root: any, source: any) => {
    let editionDate = source.proprietorshipData?.editionDate
    if (editionDate !== null) {
        editionDate = editionDate.split('-').reverse().join('-')
    }
    const mapped = {
        ...root,
        proprietorships: source.proprietorshipData.owners,
        editionDate: editionDate,
        addresses: source.addresses,
        source: source.source,
        titleMetadata: {
            ...source.titleMetadata,
            isCCOD: source.proprietorshipData.metadata.isCCOD,
            isOCOD: source.proprietorshipData.metadata.isOCOD,
            lastUpdated: source.proprietorshipData.lastUpdated,
        },
    }
    return mapped
}
