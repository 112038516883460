<template>
    <ow-card :loading="loading || isLoading || isEpcChartFakeLoading"
             :loading-skeleton="{ rows: 3, columns: 1, reverse: true, expandRows: false }"
             :info-content="owCardInfoContent"
             class="epc"
             :subtitle="$t('titlePanel.cards.epc.source', {date: lastUpdated})"
             title="Energy Performance Certificate (EPC)"
             @mouseleave="onMouseLeave"
             @mouseover="onMouseOver">
        <template #actions>
            <ow-button data-track="TITLE-DETAILS-PANEL - EPC - Export table"
                       outlined
                       @click="download">
                <img alt="Click to download the original register as a PDF"
                     class="epc__excel-icon"
                     src="../../../../media/icon-excel.png"
                     height="16"
                     width="16"
                     title="Export as CSV" />
                {{ $t('action.exportToExcel') }}
            </ow-button>
        </template>
        <div>
            <p class="epc__disclaimer">
                EPCs matched ({{ epcTableInitialDataGridItems.length }}): We match our EPCs on UPRN data, there may be
                more EPCs for this title which cannot be matched via UPRN, please search on the
                <a data-track="TITLE-DETAILS-PANEL - EPC - gov.uk link click"
                   href="https://www.gov.uk/find-energy-certificate"
                   rel="noopener noreferrer"
                   target="_blank">gov.uk</a>
                EPC register.
            </p>
            <ow-data-grid :allow-selection="false"
                          :fixed-header="epcTableHasFixedHeader"
                          :headers="epcTableDataGridHeaders"
                          :height="epcTableHasFixedHeader? '300' : null"
                          :items="epcTableInitialDataGridItems"
                          :hover="true"
                          :sort-by="sortBy"
                          class="epc__table mt-4"
                          narrow
                          no-data-text="No EPCs matched via UPRN for this title."
                          @current-items="onCurrentItemsChange"
                          @update-data-sorted="onCurrentItemsChange">
                <template #item.address="{ item }">
                    <td :class="{ 'is-selected': item.id === selectedItemId }"
                        :title="item.address"
                        class="body-copy address">
                        {{ item.address }}
                    </td>
                </template>
                <template #item.source="{ item }">
                    <td class="body-copy">
                        {{ capitaliseWord(item.source) }}
                    </td>
                </template>
                <template #item.energyBand="{ item }">
                    <td class="body-copy epc-rating">
                        <ow-coloured-chevron-label :label="item.energyBand"
                                                   :theme="item.theme" />
                    </td>
                </template>
                <template #item.expiryDate="{ item }">
                    <td :class="{'expired': item.expired === true}"
                        class="body-copy expiry-date">
                        {{ getExpiryDate(item.expiryDate) }}
                    </td>
                </template>
                <template #item.viewChart="{ item }">
                    <td>
                        <ow-button text
                                   @click="viewChart(item)">
                            View
                        </ow-button>
                    </td>
                </template>
            </ow-data-grid>
            <template v-if="chart.isVisible">
                <p class="body-copy--greyout epc__epc-chart-address">
                    EPC Address
                </p>
                <p>{{ chart.address }}</p>
                <div class="epc__dates">
                    <div class="epc__date-wrapper">
                        <p class="body-copy--greyout epc__epc-chart-label">
                            Issued on
                        </p>
                        <p>{{ formatDate(chart.lodgementDate) }}</p>
                    </div>
                    <div class="epc__date-wrapper">
                        <p class="body-copy--greyout epc__epc-chart-label">
                            Expiry date
                        </p>
                        <div v-if="showExpiryInformation"
                             class="epc__epc-chart-expiry"
                             data-test="epc-chart-expiry">
                            <p data-test="epc-chart-expiry-value">
                                {{ getExpiryDate(chart.expiryDate) }}
                            </p>
                            <ow-colored-label v-if="chart.expired"
                                              is-small
                                              theme="expired"
                                              data-test="epc-chart-expiry-expired">
                                Expired
                            </ow-colored-label>
                        </div>
                    </div>
                </div>
                <domestic-epc-chart v-if="chart.source === EPCSource.Domestic"
                                    :energy-band="chart.energyBand"
                                    :energy-rating="chart.energyRating"
                                    :potential-energy-band="chart.potentialEnergyBand"
                                    :potential-energy-rating="chart.potentialEnergyRating" />
                <non-domestic-epc-chart v-else-if="chart.source === EPCSource.NonDomestic"
                                        :energy-band="chart.energyBand"
                                        :energy-rating="chart.energyRating" />
                <dec-epc-chart v-else-if="chart.source === EPCSource.Display"
                               :energy-band="chart.energyBand"
                               :energy-rating="chart.energyRating" />
                <p v-else>
                    There's no EPC chart for this title.
                </p>
                <p class="body-copy--greyout epc__epc-chart-additional-info">
                    Other information
                </p>
                <ow-data-grid :allow-selection="false"
                              :headers="epcAdditionalInfoDataGridHeaders"
                              :items="epcAdditionalInfoDataGridItems"
                              :sort-by="additionalSortBy"
                              hover
                              class="epc__additional-table"
                              dense>
                    <template #item.type="{ item }">
                        <td :title="item.address"
                            class="body-copy">
                            {{ item.type }}
                        </td>
                    </template>
                    <template #item.details="{ item }">
                        <td class="body-copy">
                            {{ item.details }}
                        </td>
                    </template>
                </ow-data-grid>
            </template>
        </div>
    </ow-card>
</template>
<script lang="ts">
    import { ref } from 'vue'
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import EpcApi from '@/api/epc.api'
    import OwButton from '@/components/core/ow-button.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwColouredChevronLabel from '@/components/core/ow-coloured-chevron-label.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import DecEpcChart from '@/components/epc/dec-epc-chart.vue'
    import DomesticEpcChart from '@/components/epc/domestic-epc-chart.vue'
    import NonDomesticEpcChart from '@/components/epc/non-domestic-epc-chart.vue'
    import useDates from '@/composables/use-dates'
    import { EPCSource } from '@/enums/epc.enum'
    import { IInfoContentItem } from '@/interfaces/core-components/ow-card-info-content-item.interface'
    import {
        IEpcData,
        IEpcResponse,
    } from '@/interfaces/epc.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import { EPC } from '@/models/epc.model'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { exportAsCsv } from '@/utils/csv-utils'
    import { format } from '@/utils/date-utils'
    import {
        capitalise,
        isNullOrWhitespace,
    } from '@/utils/string-utils'

    export default {
        name: 'Epc',

        components: {
            DecEpcChart,
            DomesticEpcChart,
            NonDomesticEpcChart,
            OwButton,
            OwCard,
            OwColouredChevronLabel,
            OwColoredLabel,
            OwDataGrid,
        },

        props: {
            selectedTitleNumber: {
                type: String || null,
                default: null,
            },
            selectedTitle: {
                type: Object,
                default: null,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                loading: true,
                isEpcChartFakeLoading: false,
                epcTableInitialDataGridItems: [],
                epcTableDataGridHeaders: [
                    { title: 'EPC address', key: 'address', class: 'epc__epc-table-header-epc-address' },
                    { title: 'EPC type', key: 'source', class: 'type epc__epc-table-header-epc-type' },
                    { title: 'Rating', key: 'energyBand', class: 'rating epc__epc-table-header-epc-rating' },
                    { title: 'Expiry date', key: 'expiryDate', class: 'date epc__epc-table-header-epc-date' },
                    { title: 'Action', key: 'viewChart', sortable: false },
                ],
                epcAdditionalInfoDataGridItems: [],
                epcAdditionalInfoDataGridHeaders: [
                    { title: 'Type', key: 'type', class: 'type' },
                    { title: 'Details', key: 'details' },
                ],
                EPCSource,
                chart: {},
                selectedItemId: null,
                epcTableCurrentDataGridItems: null,
                hoverTimeout: null,
                hasHeapHoverEventBeenSent: false,
                sortBy: ref([{key: 'address', order: 'desc'}]),
                additionalSortBy: ref([{key: 'type', order: 'desc'}]),
            }
        },

        computed: {
            ...mapState({
                epcDataReleaseDate: (state: IState) => state.config.settings.epcDataReleaseDate,
            }),
            epcTableHasFixedHeader() {
                return this.epcTableInitialDataGridItems.length > 5
            },
            owCardInfoContent(): IInfoContentItem[] {
                const domesticItemsBelowEnergyThresholdCount = this.epcTableInitialDataGridItems.reduce((acc, item) => {
                    if (item.source === EPCSource.Domestic && (item.energyBand === 'F' || item.energyBand === 'G')) {
                        return acc + 1
                    }
                    return acc
                }, 0)
                const nonDomesticItemsBelowEnergyThresholdCount = this.epcTableInitialDataGridItems.reduce((acc, item) => {
                    if (item.source === EPCSource.NonDomestic && (item.energyBand === 'F' || item.energyBand === 'G')) {
                        return acc + 1
                    }
                    return acc
                }, 0)
                const expiredNonDomesticCount = this.epcTableInitialDataGridItems.filter(x => x.expired === true && x.source === EPCSource.NonDomestic).length
                const expiredDomesticCount = this.epcTableInitialDataGridItems.filter(x => x.expired === true && x.source === EPCSource.Domestic).length
                const expiredDisplayCount = this.epcTableInitialDataGridItems.filter(x => x.expired === true && x.source === EPCSource.Display).length

                return this.generateOwCardInfoContentArray(domesticItemsBelowEnergyThresholdCount, nonDomesticItemsBelowEnergyThresholdCount,
                                                           expiredNonDomesticCount, expiredDomesticCount, expiredDisplayCount)
            },
            showExpiryInformation() {
                return !isNullOrWhitespace(this.chart?.expiryDate)
            },
            lastUpdated() {
                return format(new Date(this.epcDataReleaseDate), 'dd-MM-yyyy')
            },
            selectedTitleRecord() {
                return this.selectedTitle?.record
            },
        },

        watch: {
            selectedTitleRecord: {
                async handler(val) {
                    try {
                        if (val) {
                            const epcData: IEpcResponse[] = await EpcApi.byTitleNumbers([this.selectedTitleNumber])
                            const epc = new EPC(epcData)
                            this.epcTableInitialDataGridItems = epc.data
                            this.epcTableCurrentDataGridItems = epc.data
                        }
                    } catch (e) {
                        console.error(e)
                    } finally {
                        this.loading = false
                    }
                },
                immediate: true,
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),
            exportAsCsv,
            capitaliseWord(word: string): string {
                return capitalise(word)
            },
            viewChart(item: any): void {
                // We're adding this fake loading skeleton because it's hard to see that the chart got updated
                // The differences are so small that it looks like nothing happened
                this.isEpcChartFakeLoading = true
                const fakeLoadingSkeletonTimeout = Math.random() * 200 + 400 // 0.4 to 0.6 seconds

                setTimeout(() => {
                    this.chart = {
                        isVisible: true,
                        source: item.source,
                        energyBand: item.energyBand,
                        energyRating: item.energyRating,
                        expiryDate: item.expiryDate,
                        lodgementDate: item.lodgementDateTime,
                        expired: item.expired,
                        potentialEnergyBand: item.potentialEnergyBand,
                        potentialEnergyRating: item.potentialEnergyRating,
                        address: item.address,
                    }
                    this.selectedItemId = item.id
                    this.logHeapEvent({
                        type: 'TITLE-DETAILS-PANEL - EPC - View chart',
                        metadata: {
                            source: item.source,
                            address: item.address,
                            uprn: item.uprn,
                        },
                    })
                    this.populateAdditionalInfo(item)
                    this.isEpcChartFakeLoading = false
                }, fakeLoadingSkeletonTimeout)
            },
            download(): Array<Array<string | number>> {
                const rows = []
                const headers = ['EPC Address', 'EPC Type', 'Energy Rating', 'Energy Score', 'Potential Energy Rating', 'Potential Energy Score', 'Issued on', 'Expiry date', 'Floor Area (m2)', 'Property Type', 'UPRN']
                const data = this.getDataForCsvExport(this.epcTableCurrentDataGridItems)

                rows.push(headers, ...data)
                const filename = `epc-data-export-${ format(new Date(), 'dd-MM-yyyy') }-${ this.selectedTitleNumber }.csv`
                this.exportAsCsv(rows, filename)

                return rows
            },
            populateAdditionalInfo(item: any): void {
                this.epcAdditionalInfoDataGridItems = []
                if (item.totalFloorArea) {
                    this.epcAdditionalInfoDataGridItems.push({
                        type: 'Floor area',
                        details: `${ item.totalFloorArea } m2`,
                    })
                }
                if (item.propertyType) {
                    this.epcAdditionalInfoDataGridItems.push({
                        type: 'Property type',
                        details: item.propertyType,
                    })
                }
                if (item.uprn) {
                    this.epcAdditionalInfoDataGridItems.push({
                        type: 'UPRN',
                        details: item.uprn,
                    })
                }
            },
            onCurrentItemsChange(items: Array<IEpcData>) {
                this.epcTableCurrentDataGridItems = items
            },
            generateOwCardInfoContentArray(domesticRatingCount: number, nonDomesticRatingCount: number, expiredNonDomesticCount: number, expiredDomesticCount: number, expiredDisplayCount: number): IInfoContentItem[] {
                const infoContentArray = []

                if (expiredNonDomesticCount > 0) {
                    infoContentArray.push({
                        text: this.$t('titlePanel.cards.epc.expiredNonDomesticCount', expiredNonDomesticCount),
                    })
                }

                if (expiredDomesticCount > 0) {
                    infoContentArray.push({
                        text: this.$t('titlePanel.cards.epc.expiredDomesticCount', expiredDomesticCount),
                    })
                }

                if (expiredDisplayCount > 0) {
                    infoContentArray.push({
                        text: this.$t('titlePanel.cards.epc.expiredDisplayCount', expiredDisplayCount),
                    })
                }

                if (nonDomesticRatingCount > 0) {
                    infoContentArray.push({
                        text: this.$t('titlePanel.cards.epc.epcTooLowTextNonDomestic', nonDomesticRatingCount),
                    })
                }

                if (domesticRatingCount > 0) {
                    infoContentArray.push({
                        text: this.$t('titlePanel.cards.epc.epcTooLowTextDomestic', domesticRatingCount),
                    })
                }

                return infoContentArray
            },
            getDataForCsvExport(tableRows: Array<IEpcData>): Array<Array<string | number>> {
                return tableRows.map((item: IEpcData) => {
                    const formattedAddress = item.address.replace(/,/g, ' ')
                    const formattedLodgementDateTime = format(new Date(item.lodgementDateTime), 'dd-MM-yyyy')
                    const formattedExpiryDate = this.getExpiryDate(item.expiryDate)
                    const formattedPotentialEnergyBand = item.source !== EPCSource.Domestic ? 'N/A' : item.potentialEnergyBand
                    const formattedPotentialEnergyRating = item.source !== EPCSource.Domestic ? 'N/A' : item.potentialEnergyRating

                    return [formattedAddress, item.source, item.energyBand, item.energyRating, formattedPotentialEnergyBand, formattedPotentialEnergyRating, formattedLodgementDateTime, formattedExpiryDate, item.totalFloorArea, item.propertyType, item.uprn]
                })
            },

            onMouseOver() {
                // Don't want to send the heap event multiple times, so only sending once each time the component is displayed
                // Changing titles reloads the component, so will trigger the event again
                if (this.hoverTimeout || this.hasHeapHoverEventBeenSent) {
                    return
                }

                this.hoverTimeout = setTimeout(() => {
                    this.logHeapEvent({
                        type: 'TITLE-DETAILS-PANEL - EPC - Interact with EPC',
                        metadata: {
                            titleNumber: this.selectedTitleNumber,
                            isCardOpen: this.chart?.isVisible,
                        },
                    })
                    this.hasHeapHoverEventBeenSent = true
                }, 3000)
            },

            onMouseLeave() {
                if (this.hoverTimeout) {
                    clearTimeout(this.hoverTimeout)
                }
            },

            getExpiryDate(date: string): string {
                if (isNullOrWhitespace(date) || date === 'N/A') {
                    return 'N/A'
                }

                return this.formatDate(new Date(date))
            },

            formatDate(value: Date): string {
                const { formatDateShort } = useDates()
                return formatDateShort(value)
            },
        },
    }
</script>

<style lang="scss">
    @import './epc-card.scss';
</style>
