<template>
    <div class="admin-layout">
        <nav>
            <div class="nav-container"
                 data-test="account-navigation">
                <div>
                    <ul>
                        <router-link v-slot="{ navigate }"
                                     :to="{ name: Route.SettingsAccountPage }"
                                     custom>
                            <li role="link"
                                class="content__subheading"
                                :class="{ selected: $route.name === Route.SettingsAccountPage }"
                                data-track="ACCOUNT-NAV - Basic info"
                                @click="navigate"
                                @keypress.enter="navigate">
                                <span>Basic Info</span>
                            </li>
                        </router-link>
                    </ul>

                    <ul v-if="userIsOrgAdmin">
                        <router-link v-slot="{ navigate }"
                                     :to="{ name: Route.OrganisationMattersPage}"
                                     custom>
                            <li role="link"
                                class="content__subheading"
                                :class="{ selected: $route.name === Route.OrganisationMattersPage }"
                                data-track="ACCOUNT-NAV - Org admin matters"
                                @click="navigate"
                                @keypress.enter="navigate">
                                <span>Matters</span>
                            </li>
                        </router-link>
                        <router-link v-slot="{ navigate }"
                                     :to="{ name: Route.OrganisationChargesPage}"
                                     custom>
                            <li role="link"
                                class="content__subheading"
                                :class="{ selected: $route.name === Route.OrganisationChargesPage }"
                                data-track="ACCOUNT-NAV - Org admin charges"
                                @click="navigate"
                                @keypress.enter="navigate">
                                <span>Charges</span>
                            </li>
                        </router-link>
                    </ul>

                    <ul v-if="userIsOrgAdmin || userIsAdmin">
                        <router-link v-slot="{ navigate }"
                                     :to="{ name: Route.UsersPage}"
                                     custom>
                            <li role="link"
                                class="content__subheading"
                                :class="{ selected: $route.name === Route.UsersPage }"
                                data-track="ACCOUNT-NAV - users"
                                data-test="layout-system-users"
                                @click="navigate"
                                @keypress.enter="navigate">
                                <span>Users</span>
                            </li>
                        </router-link>
                    </ul>
                    <ul v-if="userIsAdmin">
                        <router-link v-slot="{ navigate }"
                                     :to="{ name: Route.AdminOrganisationsPage }"
                                     custom>
                            <li role="link"
                                class="content__subheading"
                                :class="{ selected: $route.name === Route.AdminOrganisationsPage }"
                                data-test="layout-organisation-nav"
                                data-track="ACCOUNT-NAV - System admin organisations"
                                @click="navigate"
                                @keypress.enter="navigate">
                                <span>Organisations</span>
                            </li>
                        </router-link>
                        <router-link v-slot="{ navigate }"
                                     :to="{ name: Route.AdminSettingsPage }"
                                     custom>
                            <li role="link"
                                class="content__subheading"
                                :class="{ selected: $route.name === Route.AdminSettingsPage }"
                                data-track="ACCOUNT-NAV - Settings"
                                @click="navigate"
                                @keypress.enter="navigate">
                                <span>Settings</span>
                            </li>
                        </router-link>
                        <router-link v-slot="{ navigate }"
                                     :to="{ name: Route.AdminLogPage }"
                                     custom>
                            <li role="link"
                                class="content__subheading"
                                :class="{ selected: $route.name === Route.AdminLogPage }"
                                data-track="ACCOUNT-NAV - Log"
                                @click="navigate"
                                @keypress.enter="navigate">
                                <span>Log</span>
                            </li>
                        </router-link>
                    </ul>
                </div>
                <v-btn id="logOutButton"
                       class="content__subheading"
                       data-test="btn-account-logout"
                       data-track="ACCOUNT-NAV - Logout"
                       size="large"
                       variant="text"
                       @click="doLogout">
                    Logout
                    <v-icon>$logout</v-icon>
                </v-btn>
            </div>
        </nav>
        <div class="content-container">
            <router-view />
        </div>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import { Route } from '@/enums/route.enum'
    import {
        AUTH_GET_USER,
        AUTH_LOGOUT,
    } from '@/store/modules/auth-oidc/types'
    import * as types from '@/store/mutation-types'

    export default {
        name: 'Layout',

        data() {
            return {
                Route,
            }
        },

        computed: {
            ...mapGetters({
                userIsAdmin: types.USER_IS_ADMIN,
                userIsOrgAdmin: types.USER_IS_ORGANISATION_ADMIN,
            }),
        },

        methods: {
            ...mapActions({
                logout: AUTH_LOGOUT,
            }),

            doLogout() {
                this.logout()
            },
        },
    }
</script>

<style lang="scss">
    @import './layout';
</style>
