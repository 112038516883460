<template>
    <v-btn color="primary"
           data-test="remove-document-from-matter-button"
           data-track="DOCS - remove document from matter"
           variant="text"
           icon="$delete"
           @click="showRemoveDocumentConfirmation()" />
</template>

<script setup lang="ts">
    import {
        computed,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import { MATTER_GET_REMOVE_DOCUMENT_PROMPT_STATE } from '@/store/modules/matter/types'

    const { t } = useI18n()
    const store = useStore()

    const props = defineProps({
        document: {
            type: Object,
            required: true,
        },
    })

    const currentMatterId = computed((): number => parseInt(store.state.matter.currentMatter.id))
    const removeDocumentsFromMatterDialog = computed(() => store.getters[MATTER_GET_REMOVE_DOCUMENT_PROMPT_STATE])

    const showRemoveDocumentConfirmation = () => {
        removeDocumentsFromMatterDialog.value.matterId = currentMatterId
        removeDocumentsFromMatterDialog.value.documentToRemove = props.document
        removeDocumentsFromMatterDialog.value.show = true
    }

</script>