import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import {
    Layer,
    Tile as TileLayerCanvas,
} from 'ol/layer'
import TileLayer from 'ol/layer/WebGLTile'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import olMap from 'ol/Map'
import { LayerNames } from '@/consts/map-layers'
import { WMTS } from 'ol/source'
import { bluesky27700TileGrid } from './bluesky-27700-tilegrid'

type BlueskyAerialLayerOptions = {
    getTargetMapFn?: () => olMap,
    snapshotConfig?: LayerSnapshotModel
}

export class BlueskyAerialLayer implements IMapSnapshotLayer {
    public name: string = LayerNames.BlueskyAerial
    private layer: TileLayer | TileLayerCanvas<WMTS>
    private options: BlueskyAerialLayerOptions
    private loadingTilesCount = 0

    constructor(args: BlueskyAerialLayerOptions, settings: any) {
        this.options = args
        if (args.snapshotConfig) {
            this.initialiseFromSnapshotConfig()
        } else {
            this.layer = this.getBlueskyTileLayer()
        }
        this.layer.set('getOwLayer', () => this)
        this.layer.set('name', LayerNames.BlueskyAerial)
        this.layer.getSource().on('tileloadstart', () => {
            this.loadingTilesCount++
        })
        this.layer.getSource().on(['tileloadend', 'tileloaderror'], () => {
            this.loadingTilesCount--
        })
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        return undefined
    }

    getLayer(): Layer {
        return this.layer
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: LayerNames.BlueskyAerial,
            configJson: JSON.stringify({
                layerName: LayerNames.BlueskyAerial,
            }),
        }
    }

    initialiseFromSnapshotConfig(): void {
        this.layer = this.getBlueskyTileLayer()
    }

    getBlueskyTileLayer = () => {
        // Bluesky https://mapping3.net/.wmts?user=orbitalwitness&pwd=smsa0358&service=WMTS&REQUEST=GetCapabilities&VERSION=1.0.0
        const layerOptions = {
            name: 'blueskyAerial',
            source: new WMTS({
                url: `https://mapping3.net/.wmts?user=orbitalwitness&pwd=smsa0358&service=WMTS`,
                attributions: `RGB Aerial Photography – © Bluesky International`,
                layer: 'ap12.5cm',
                format: 'image/jpeg',
                projection: CoordinateSystemCode.EPSG27700,
                tileGrid: bluesky27700TileGrid,
                style: 'Default',
                wrapX: true,
                crossOrigin: 'anonymous',
                matrixSet: 'TileMatrixSetIdentifier12.5cm',
            }),
            tileOptions: { crossOriginKeyword: 'anonymous' },
            visible: true,
            zIndex: 0,
            preload: 0,
        }

        // TODO: Switch this for the WebGL Tile Layer when it is fixed - at the moment using it as the base layer causes issues with webgl context being lost
        return new TileLayerCanvas(layerOptions)
    }

    async setVisible(visible: boolean): Promise<void> {
        this.layer?.setVisible(visible)
        return Promise.resolve()
    }

    getVisible(): boolean {
        return this.layer?.getVisible()
    }

    public getIsLoading(): boolean {
        return this.loadingTilesCount > 0
    }
}
