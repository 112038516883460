<template>
    <div class="d-flex flex-row justify-start align-start owner-result-titles-item">
        <div class="title-item-tenure-indicator"
             :class="{
                 freehold: title.tenure.includes('F'),
                 leasehold: title.tenure.includes('L'),
             }" />
        <div class="d-flex owner-result-titles-item__title">
            <v-checkbox v-model="isChecked"
                        :ripple="false"
                        class="owner-name-label"
                        :data-test="`owner-result-title-item-checkbox-${ title.titleNumber }`"
                        color="primary"
                        data-track="SEARCH - Corporate owner title checkbox"
                        hide-details>
                <template #label>
                    <div class="d-flex flex-column">
                        <div class="content__small-print">
                            {{ title.titleNumber }}
                            <span v-if="title.district">({{ title.district }})</span>
                        </div>
                        <div class="content__small-print owner-result-titles-item__title--address"
                             :title="title.address">
                            {{ title.address }}
                        </div>
                    </div>
                </template>
            </v-checkbox>
        </div>
        <div class="d-flex content__small-print owner-result-titles-item__owner">
            {{ title.owner }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SearchByOwnerTitlesItem',

        props: {
            title: {
                type: Object,
                required: true,
            },
        },

        emits: [
            'selection-changed',
        ],

        data() {
            return {}
        },

        computed: {
            isChecked: {
                get() {
                    return this.title.selected
                },
                set(val) {
                    this.$emit('selection-changed', {
                        title: this.title,
                        selected: val,
                    })
                },
            },
        },
    }
</script>

<style lang="scss">
    @import './search-by-owner-titles-item'
</style>
