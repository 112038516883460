import { isNullOrEmpty } from '@/utils/array-utils'
import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

// TODO: This (or the original, once everything has move to the new endpoint)
//  needs to be updated to work with the new title data
export const titleDataMixin = {
    computed: {
        /**
         * Returns the type of the tenure, either freehold, leasehold or other
         * @returns {string}
         */
        tenureType() {
            if (this.title?.tenure) {
                const tenure = this.title.tenure.toUpperCase()

                if (tenure.includes('FREEHOLD')) {
                    return 'freehold'
                }

                if (tenure.includes('LEASEHOLD')) {
                    return 'leasehold'
                }

                return 'other'
            }

            return 'other' // not sure if this should be null, or whether this would ever happen
        },

        /**
         * Is there any lease term text to display?
         * @return {boolean} - true if there is lease term text, false otherwise
         */
        hasLeaseTermText() {
            return !isNullOrWhitespace(this.title?.leaseTermText)
        },

        /**
         * Are there any owners listed for the title
         * @return {[{proprietorship: *, correspondanceAddress: string, name: string, companyRegNumber: *, countryIncorporated: *, dateAdded: *}]|[{proprietorship: string, correspondanceAddress: string, name: string, companyRegNumber: null, countryIncorporated: string, dateAdded: string}]|*|null}
         */
        titleOwners() {
            return this.title?.lrProprietorshipData?.owners ?? null
        },

        /**
         * Is there an owner name?
         * @return {boolean} true if there are names, false otherwise.
         */
        hasOwnerNames() {
            return !isNullOrEmpty(this.title.proprietorNames)
        },

        /**
         * Are company registration numbers included.
         * @return {boolean} true if there are company registration numbers, false otherwise.
         */
        hasCompanyNumbers() {
            return !isNullOrEmpty(this.title?.companyRegNumbers)
        },
    },

    methods: {
        /**
         * Get the link to go to Companies House for the company number
         * provided.
         * @param number {Number}
         * @return {string}
         */
        getCompaniesHouseLink(number) {
            return getCompaniesHouseLinkFromCompanyRegistrationNumber(number)
        },
    },
}
