<template>
    <v-container :class="{ active: isPanelOpen, 'top-nav-visible': isTopNavVisible }"
                 align-start
                 class="search-by-owner-titles"
                 column
                 data-test="search-by-owner-titles-panel"
                 justify-start>
        <div class="d-flex flex-row header">
            <h2 class="content__heading">
                Corporate Owner Titles
            </h2>
            <v-spacer />
            <div class="d-flex shrink">
                <v-btn class="header__close-icon"
                       data-track="SEARCH - Close corporate owner title results panel"
                       icon
                       size="small"
                       variant="text"
                       @click="onCancelClick()">
                    <v-icon>$close</v-icon>
                </v-btn>
            </div>
        </div>

        <div class="search-by-owner-titles__content">
            <div v-if="titlesLoading"
                 class="d-flex align-center justify-center">
                <v-progress-linear class="loading-bar"
                                   color="primary"
                                   indeterminate />
            </div>

            <p v-if="titles.length === 0 && !titlesLoading"
               class="no-titles-found">
                No title records found
            </p>

            <div v-if="!titlesLoading && titles.length > 0">
                <v-list class="titles-list"
                        density="compact"
                        light>
                    <v-list-subheader>
                        <div class="body-copy">
                            {{ titles.length }} results found ({{ uniqueTitlesCount }} unique titles)
                            <span class="ml-1">
                                <v-icon v-if="titles.length > TITLE_LIMIT()">
                                    $info
                                </v-icon>
                                <ow-tooltip activator="parent"
                                            :position="OwTooltipPosition.Right">
                                    <span>Map title selection is not available if there are more than {{ TITLE_LIMIT() }} titles.</span>
                                </ow-tooltip>
                            </span>
                        </div>
                        <div class="content__small-print">
                            Confirm which titles you'd like to add to your matter.
                        </div>
                    </v-list-subheader>
                </v-list>

                <div class="search-filter-actions">
                    <v-text-field v-model="titleFilterText"
                                  autocomplete="new-password"
                                  clearable
                                  data-test="search-by-owner-titles-filter"
                                  data-track="SEARCH - Filter corporate owner title results"
                                  hide-details
                                  label="Search available titles..." />
                    <v-select id="importByOwnerLAFilter"
                              v-model="selectedLocalAuthorities"
                              :items="localAuthorities"
                              class="search-filter-actions__sort-by-owner"
                              clearable
                              data-track="SEARCH - Filter corporate owner title results by local authority"
                              hide-details
                              item-title="text"
                              label="Filter by Local Authority..."
                              multiple>
                        <template #selection="{ item, index }">
                            <span v-if="index === 0 && selectedLocalAuthorities.length > 1">
                                {{ item.raw.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}
                                (+{{ selectedLocalAuthorities.length - 1 }} more)
                            </span>
                            <span v-if="index === 0 && selectedLocalAuthorities.length === 1">
                                {{ item.raw.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }}
                            </span>
                        </template>
                        <template #item="{ props, item }">
                            <v-list-item v-slot="{ active }"
                                         v-bind="props"
                                         title="">
                                <v-list-item-action>
                                    <v-checkbox :model-value="active">
                                        <template #label>
                                            <span class="search-filter-actions__sort-by-owner--option">{{
                                                item.raw.toLowerCase()
                                                    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                                            }}</span>
                                        </template>
                                    </v-checkbox>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-select>
                </div>

                <div class="titles-list">
                    <owners-results-header :include-divider="false"
                                           :is-all-disabled="allTitlesSelected"
                                           :is-none-disabled="noTitlesSelected"
                                           :owner-sort-options="titleSortOptions"
                                           @is-all-selected="selectTitles"
                                           @sort-by="sortResults" />

                    <div :class="{ active: selectedTitles.length > 0 }"
                         class="d-flex flex-column align-start justify-start shrink title-results">
                        <div class="d-flex flex-row align-start justify-start shrink title-results__table-header">
                            <div class="d-flex title-results__table-header--title">
                                <span class="content__small-print">Title</span>
                            </div>
                            <div class="d-flex title-results__table-header--owner">
                                <span class="content__small-print">Owner</span>
                            </div>
                        </div>

                        <div class="d-flex flex-column justify-start align-start shrink">
                            <owner-titles-item v-for="(title, index) in filteredTitles"
                                               :key="index"
                                               :title="title"
                                               @selection-changed="updateSelectionState" />
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-row search-by-owner-titles__footer">
                    <v-spacer />
                    <ow-select v-model="selectedGroup"
                               :clearable="true"
                               :items="groupOptions"
                               class="search-by-owner-titles__footer--groups"
                               data-test="search-by-owner-titles-groups-select"
                               data-track="SEARCH - Corporate owner add titles to group button"
                               hide-details
                               is-dense
                               is-outlined
                               item-title="name"
                               item-value="id"
                               label="Add to group" />

                    <!--<v-btn color="primary" @click="onAddGroupClick()" style="min-width: 55px;">New</v-btn>-->
                    <v-btn :disabled="uniqueSelectedTitles.length === 0 || titlesLoading"
                           :loading="titlesLoading"
                           color="primary"
                           data-test="search-by-owner-titles-import-button"
                           data-track="SEARCH - Corporate owner import titles to matter button"
                           size="large"
                           @click="onImportClick()">
                        {{ importButtonText() }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
    import { mapActions,
             mapMutations,
             mapState,
    } from 'vuex'

    import OwSelect from '@/components/core/ow-select.vue'
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import OwnersResultsHeader from '@/components/map/search/tab-owner-results-header.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import {TITLE_LIMIT} from "@/consts/title-limits"
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import {
        MATTER_ADD_MULTIPLE_TITLES,
        MATTER_MUTATE_CREATE_GROUP_PROMPT,
    } from '@/store/modules/matter/types'
    import {
        SEARCH_ADD_OWNER_TITLES_SHORTLIST_TO_MAP,
        SEARCH_CLEAR_ALL,
        SEARCH_INITIALISE_SEARCH_RESULTS_LAYER,
        SEARCH_MUTATE_IS_ACTIVE,
        SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN,
        SEARCH_MUTATE_OWNER_TITLES,
    } from '@/store/modules/search/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import { TITLE_MUTATE_ACTIVE_TITLE_PANEL } from '@/store/modules/titles/types'
    import { LOGGING_INTERCOM_TRACK_EVENT,
             LOGGING_LOG_FEATURE_USAGE,
             USER_MUTATE_SHOW_MAP_TITLES_NAV,
    } from '@/store/mutation-types'
    import { dynamicSort,
             isNullOrEmpty,
             unique,
    } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import OwnerTitlesItem from './search-by-owner-titles-item.vue'

    export default {
        name: 'MatterSidePanelSearchByOwnerTitles',

        components: {
            OwTooltip,
            OwSelect,
            OwnersResultsHeader,
            OwnerTitlesItem,
        },

        mixins: [ FeatureFlagsMixin ],

        setup() {
            const { isTopNavVisible } = useMapTopNav()
            return {
                isTopNavVisible,
            }
        },

        data() {
            return {
                allTitlesSelected: false,
                filteredTitles: [],
                localAuthorities: [],
                localCopyOfTitles: [],
                noTitlesSelected: true,
                selectedGroup: null,
                selectedLocalAuthorities: [],
                selectedSortOption: 'titleNumber',
                selectedTitles: [],
                titleFilterText: null,
                titleSortOptions: [
                    {
                        id: 'titleNumber',
                        label: 'Title Number',
                    },
                    {
                        id: 'district',
                        label: 'Local Authority',
                    },
                    {
                        id: 'tenure',
                        label: 'Tenure',
                    },
                ],
            }
        },

        computed: {
            OwTooltipPosition() {
                return OwTooltipPosition
            },
            ...mapState({
                currentMatter: state => state.matter.currentMatter,
            }),

            ...mapState('search', {
                titles: state => state.ownerTitles,
                titlesLoading: state => state.loadingTitles,
                isPanelOpen: state => state.isOwnerTitlesPanelOpen,
            }),

            uniqueTitlesCount: {
                get() {
                    return unique(this.localCopyOfTitles.map(t => t.titleNumber)).length
                },
            },

            uniqueSelectedTitles: {
                get() {
                    return unique(this.selectedTitles.map(t => t.titleNumber))
                },
            },

            uniqueFilteredTitleNumbers: {
                get() {
                    return unique(this.filteredTitles.map(t => t.titleNumber))
                },
            },

            uniqueSelectedFilteredTitles: {
                get() {
                    return this.uniqueFilteredTitleNumbers
                        .filter(titleNumber => this.selectedTitles
                            .map(t => t.titleNumber)
                            .includes(titleNumber),
                        )
                },
            },

            groupOptions: {
                get() {
                    const result = [ ...this.currentMatter.groups ]
                    result.push({
                        id: -1,
                        name: 'Add New Group...',
                        sortOrder: Number.MAX_SAFE_INTEGER,
                    })
                    return result.sort()
                },
            },
        },

        watch: {
            titles: {
                handler(val) {
                    this.localAuthorities = [ ...new Set(this.titles.map(title => title.district)) ].sort()
                    this.localCopyOfTitles = val
                    this.updateFilteredTitles()
                },
                deep: true,
            },

            selectedLocalAuthorities() {
                this.updateFilteredTitles()
            },

            titleFilterText() {
                this.updateFilteredTitles()
            },

            selectedGroup(val) {
                this.handleGroupSelection(val)
            },
        },

        methods: {
            TITLE_LIMIT() {
                return TITLE_LIMIT
            },

            ...mapMutations({
                mutateShowMapTitlesNav: USER_MUTATE_SHOW_MAP_TITLES_NAV,
                mutateActiveTitlePanel: TITLE_MUTATE_ACTIVE_TITLE_PANEL,
                mutateShowCreateGroupPrompt: MATTER_MUTATE_CREATE_GROUP_PROMPT,
            }),

            ...mapMutations('search', {
                mutateIsSearchActive: SEARCH_MUTATE_IS_ACTIVE,
                mutateOwnerTitles: SEARCH_MUTATE_OWNER_TITLES,
                mutateOwnerTitlesPanelOpen: SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN,
            }),

            ...mapActions({
                addMultipleTitles: MATTER_ADD_MULTIPLE_TITLES,
                trackEvent: LOGGING_INTERCOM_TRACK_EVENT,
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
            }),

            ...mapActions('search', {
                clearSearch: SEARCH_CLEAR_ALL,
                addShortlistCompaniesToMap: SEARCH_ADD_OWNER_TITLES_SHORTLIST_TO_MAP,
                addCompaniesToMap: SEARCH_INITIALISE_SEARCH_RESULTS_LAYER,
            }),

            async onImportClick() {
                const numTitles = this.uniqueSelectedFilteredTitles.length
                const request = {
                    titleNumbers: this.uniqueSelectedFilteredTitles,
                    source: 'owner',
                    matterGroupId: null,
                }
                if (this.selectedGroup != null) {
                    request.matterGroupId = this.selectedGroup.id
                }

                await this.addMultipleTitles(request)

                this.mutateShowMapTitlesNav(true)
                this.mutateActiveTitlePanel(TitlePanels.LIST)

                this.clearSearch()
                this.onCancelClick()

                await this.trackEvent({
                    type: 'search-by-owner',
                    metadata: {},
                })

                await this.logFeatureUsage({
                    type: 'search-owner-titles-select',
                    description: numTitles,
                })
            },

            sortResults(by) {
                this.selectedSortOption = by
                this.updateFilteredTitles()
            },

            selectTitles(isAllSelected) {
                if (isAllSelected) {
                    this.selectAllTitles()
                } else {
                    this.selectNoTitles()
                }
                this.updateGlobalSelectionState()
                this.updateFilteredTitles()
            },

            selectAllTitles() {
                this.localCopyOfTitles = this.titles.map(o => ({
                    ...o,
                    selected: true,
                }))
                this.selectedTitles = this.localCopyOfTitles
            },

            selectNoTitles() {
                this.localCopyOfTitles = this.titles.map(o => ({
                    ...o,
                    selected: false,
                }))
                this.selectedTitles = []
            },

            onCancelClick() {
                this.mutateOwnerTitles([])
                this.mutateOwnerTitlesPanelOpen(false)
                this.mutateIsSearchActive(true)
                this.addCompaniesToMap([])
                this.resetSearch()
            },

            updateSelectionState(item) {
                const selectedItem = this.localCopyOfTitles
                    .find(o => o.sort === item.title.sort)
                selectedItem.selected = item.selected

                if (item.selected) {
                    this.selectedTitles.push(selectedItem)
                } else {
                    this.selectedTitles = this.selectedTitles
                        .filter(o => o.sort !== item.title.sort)
                }
                this.updateGlobalSelectionState()
                this.updateFilteredTitles()
            },

            updateGlobalSelectionState() {
                this.allTitlesSelected = this.localCopyOfTitles.every(o => o.selected === true)
                this.noTitlesSelected = this.localCopyOfTitles.every(o => o.selected === false)
            },

            updateFilteredTitles() {
                this.filteredTitles = this.localCopyOfTitles.filter(title => {
                    // Local authority filter
                    if (!isNullOrEmpty(this.selectedLocalAuthorities)) {
                        if (!this.selectedLocalAuthorities.includes(title.district)) {
                            return false
                        }
                    }

                    // Text filter
                    if (isNullOrWhitespace(this.titleFilterText)) {
                        return true
                    } else {
                        const searchText = this.titleFilterText.toLowerCase()
                        return title.titleNumber.toLowerCase().includes(searchText) ||
                            title.address.toLowerCase().includes(searchText)
                    }
                }).sort(dynamicSort(this.selectedSortOption))

                this.selectedTitles = this.localCopyOfTitles.filter(t => t.selected)
                // NOTE: Only allow selection rendering if titles are under limit
                if (this.filteredTitles.length <= TITLE_LIMIT) {
                    this.addShortlistCompaniesToMap(this.filteredTitles)
                }
            },

            importButtonText() {
                let text = `Add ${ this.uniqueSelectedFilteredTitles.length } title`
                if (this.uniqueSelectedFilteredTitles.length !== 1) {
                    text += 's'
                }
                text += ' to matter'
                return text
            },

            resetSearch() {
                this.allTitlesSelected = false
                this.filteredTitles = []
                this.localAuthorities = []
                this.localCopyOfTitles = []
                this.noTitlesSelected = true
                this.selectedGroup = null
                this.selectedLocalAuthorities = []
                this.selectedSortOption = 'titleNumber'
                this.selectedTitles = []
                this.titleFilterText = null
            },

            handleGroupSelection(selected) {
                if (selected &&
                    selected.id === -1) {
                    this.selectedGroup = null
                    this.mutateShowCreateGroupPrompt({
                        show: true,
                        callback: newGroup => {
                            this.selectedGroup = newGroup
                        },
                    })
                }
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './search-by-owner-titles';
</style>
