<template>
    <div data-test="tab-place-results">
        <no-search-results v-if="count === 0"
                           context="Place" />
        <v-list v-else
                density="compact"
                light>
            <v-list-subheader>
                <span class="body-copy"
                      data-test="tab-place-results-count">
                    {{ count }} results found
                </span>
            </v-list-subheader>
            <div class="place-results">
                <div v-for="(place, index) in places"
                     :key="`${ place.text }-${ index }`"
                     :data-test="`tab-place-results-item-${ index }`"
                     class="tab-place-results__result-item"
                     data-track="SEARCH - Places search result"
                     @click="$emit('selected', place)">
                    <v-icon>$map-marker</v-icon>
                    <span class="body-copy">{{ place.text }}</span>
                </div>
            </div>
        </v-list>
    </div>
</template>

<script>
    import NoSearchResults from '@/components/map/search/no-search-results.vue'

    export default {
        name: 'TabPlaceResults',

        components: {
            NoSearchResults,
        },

        props: {
            count: {
                type: Number,
                required: true,
            },

            places: {
                type: Array,
                required: true,
            },
        },

        emits: [
            'selected',
        ],

        data() {
            return {}
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './tab-place-results';
</style>
