<template>
    <article :class="{ 'template-card__non-interactive': !interactive, 'template-card--copilot-banner': template?.copilotBanner }"
             class="template-card">
        <div v-if="template?.copilot && template?.copilotBanner"
             class="template-card__copilot-banner">
            <img width="100%"
                 :src="copilotBanner"
                 height="120px"
                 alt="Copilot banner" />
        </div>
        <h4 class="template-card__container--title headers-h1-page-title"
            :class="{'pt-0': template?.copilotBanner}">
            <span :title="template.name">{{ template.name }}</span>
            <exporting-icons v-if="interactive && !template?.customIcon && !template?.copilot"
                             :document-icon="documentIcon" />
            <ow-icon v-else-if="interactive && template?.copilot && template?.customIcon"
                     class="template-card__copilot-report-icon"
                     :icon="template.customIcon" />
        </h4>
        <div v-if="!thumbnail"
             class="template-card__container body-copy caption-regular">
            <slot />
        </div>
        <div class="template-card__container stretch" />
        <div v-if="!thumbnail && interactive"
             class="template-card__container template-card__footer">
            <div v-if="!template.is_available"
                 class="template-card__row justify-center">
                <v-icon class="mr-2">
                    $lock
                </v-icon>
                <span class="caption-regular">{{ $t('report.card.noAccess') }}</span>
            </div>
        </div>
        <div v-if="interactive"
             class="template-card__container">
            <open-copilot-button v-if="template?.copilot && !template?.copilotBanner"
                                 :title="selectText"
                                 show-all-documents
                                 is-primary
                                 full-width
                                 :show-tooltip="false"
                                 track-position="Reporting"
                                 data-test="reporting-open-copilot"
                                 data-track="REPORTING - Open Copilot" />
            <ow-button v-else-if="template.is_available"
                       :data-test="`btn-export-${ template.name.toLowerCase().replaceAll(' ', '-') }`"
                       :data-track="`REPORTING - Select template ${ template.name.toLowerCase() }`"
                       :is-loading="loading"
                       full-width
                       large
                       :is-primary="!isSecondaryButton"
                       :is-secondary="isSecondaryButton"
                       @click="handleExport">
                {{ selectText }}
                <template v-if="template?.copilotBanner"
                          #iconSuffix>
                    <span class="mdi mdi-open-in-new" />
                </template>
            </ow-button>
            <ow-button v-else-if="template.registered_interest_at === null"
                       :data-test="`btn-interest-${ template.name.toLowerCase().replaceAll(' ', '-') }`"
                       :data-track="`REPORTING - Register interest in template ${ template.name.toLowerCase() }`"
                       :is-loading="loading"
                       :is-secondary="isSecondaryButton"
                       full-width
                       @click="handleRegisterInterest">
                {{ $t('report.card.registerInterest') }}
            </ow-button>
            <div v-else
                 class="text-center body-copy caption-highlight template-card__interest"
                 data-test="text-interest">
                <span class="caption-highlight">
                    {{ $t('report.card.interestRegistered') }}
                </span>
            </div>
        </div>
        <div v-if="template.copilot && template.copilotBanner">
            <span class="caption-regular d-flex justify-center mt-2">{{ $t('report.card.selectCopilotReport') }}</span>
        </div>
        <div v-else-if="!thumbnail && interactive"
             class="template-card__container template-card__container__footer text-center caption-regular mt-2">
            {{ $t('report.card.whatIsThis') }}

            <a :href="template?.preview_url ?? '#'"
               :title="!template?.preview_url ? 'Coming soon...' : 'Click to view / download report'"
               class="caption-highlight"
               data-track="REPORTING - Download preview report">{{ template?.copilot ? $t('report.card.viewPreview') : $t('report.card.downloadPreview') }}</a>
        </div>
    </article>
</template>

<script lang="ts">
    import { defineComponent,
             PropType } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwIcon from '@/components/core/ow-icon.vue'
    import ExportingIcons from '@/components/reporting/exporting-icons.vue'
    import { CopilotReport } from '@/enums/copilot-report.enum'
    import {DOC_TYPE_ENUM} from "@/enums/reporting-api.enum"
    import { IExportTemplateOptions } from '@/interfaces/export-template-options.interface'
    import CopilotIntegration from '@/media/copilot-banner.png'

    import OpenCopilotButton from '../copilot/open-copilot-button.vue'

    export default defineComponent({
        name: 'TemplateCard',
        components: {
            ExportingIcons,
            OwButton,
            OwIcon,
            OpenCopilotButton,
        },
        props: {
            template: {
                type: Object as PropType<IExportTemplateOptions>,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
                required: false,
            },
            thumbnail: {
                type: Boolean,
                default: false,
                required: false,
            },
            interactive: {
                type: Boolean,
                default: true,
                required: false,
            },
            isSecondaryButton: {
                type: Boolean,
                required: false,
            },
            documentIcon: {
                type: String,
                required: false,
                default: DOC_TYPE_ENUM.DOC,
            },
        },

        emits: ['export', 'register-interest'],
        computed: {
            copilotBanner() {
                return CopilotIntegration
            },
            selectText() {
                if (this.template?.copilot && this.template?.copilotBanner) {
                    return this.$t('report.card.goToCopilot')
                }
                return this.$t('action.select')
            },
        },

        methods: {
            handleExport() {
                this.$emit('export', this.template)
            },

            handleRegisterInterest() {
                this.$emit('register-interest', this.template)
            },
        },
    })
</script>

<style lang="scss">
    @import 'template-card.scss';
</style>
