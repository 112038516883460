<template>
    <div class="title-organiser-options-panel"
         data-test="title-organiser-options-panel"
         :style="componentStyle">
        <header class="title-organiser-options-panel__panel-header">
            <h3 v-t="'matter.titleOrganiser.optionsPanel.header'"
                class="content__subheading" />
        </header>
        <div class="title-organiser-options-panel__divider" />
        <div class="title-organiser-options-panel__filter">
            <ow-textfield ref="filter"
                          v-model="localFilter"
                          data-test="title-organiser-options-panel-filter"
                          small
                          borderless
                          clearable
                          placeholder="Filter...">
                <template #iconPrefix>
                    <v-icon>$filter</v-icon>
                </template>
            </ow-textfield>
        </div>
        <div class="title-organiser-options-panel__divider" />

        <!-- Add to group -->
        <div data-test="title-organiser-options-panel-group-menu"
             class="d-flex flex-column title-organiser-options-panel__group-menu">
            <template v-if="localFilter.length === 0">
                <ow-dropdown-menu v-for="group in availableGroups"
                                  :key="group.id"
                                  theme="submenu"
                                  :button-text="group.label"
                                  :nudge-left="7"
                                  :nudge-top="10"
                                  :close-on-content-click="false"
                                  :open-on-hover="true"
                                  :menu-delay="200"
                                  alignment="right">
                    <div class="app-dropdown-menu__content">
                        <ow-dropdown-menu-item v-for="column in columnsForGroup(group.id)"
                                               :key="column.id"
                                               :text="column.text"
                                               @click="onSelectField(column.field)" />
                    </div>
                </ow-dropdown-menu>
            </template>
            <template v-else-if="columnsMatchingFilter().length">
                <div class="app-dropdown-menu__filter-content">
                    <ow-dropdown-menu-item v-for="column in columnsMatchingFilter()"
                                           :key="column.id"
                                           :text="column.text"
                                           @click="onSelectField(column.field)" />
                </div>
            </template>
            <template v-else>
                <div class="d-flex flex-column align-center justify-center app-dropdown-menu__filter-not-found">
                    <div class="accents-highlight">
                        {{ $t('error.noResultsFound') }}
                    </div>
                    <div class="accents-italic">
                        {{ $t('action.tryAnotherTerm') }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import { PropType,
             StyleValue } from 'vue'

    import OwDropdownMenu from '@/components/core/ow-dropdown-menu.vue'
    import OwDropdownMenuItem from '@/components/core/ow-dropdown-menu-item.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IMatterGroup } from '@/interfaces/matter-group.interface'

    export default {
        name: 'TitleOrganiserOptionsPanel',
        components: {
            OwDropdownMenu,
            OwDropdownMenuItem,
            OwTextfield,
        },
        mixins: [
            FeatureFlagsMixin,
        ],
        props: {
            groups: {
                type: Array as PropType<Array<IMatterGroup>>,
                default: () => [],
                required: true,
            },
            columns: {
                type: Array,
                default: () => [],
                required: true,
            },
            anchorElementSelector: {
                type: String,
                required: false,
                default: () => {},
            },
            anchorOffset: {
                type: Array as PropType<Array<number>>,
                required: false,
                default: () => [0, 0, 0, 0],
            },
        },
        emits: ['select-field'],
        data() {
            return {
                localFilter: '',
            }
        },
        computed: {
            componentStyle(): StyleValue {
                if (!this.anchorElementSelector) {
                    return null
                }
                const anchorElementRect = document.querySelector(this.anchorElementSelector)?.getBoundingClientRect()
                if (!anchorElementRect) {
                    return null
                }
                const left = anchorElementRect.left + anchorElementRect.width
                let style = {} as any
                if (this.anchorOffset?.length == 4) {
                    style = {
                        marginTop: `${ this.anchorOffset[0] }px`,
                        marginRight: `${ this.anchorOffset[1] }px`,
                        marginBottom: `${ this.anchorOffset[2] }px`,
                        marginLeft: `${ this.anchorOffset[3] }px`,
                    }
                }
                style.left = `${ left }px`
                return style
            },
            availableGroups() {
                return this.groups.filter(g => this.columnsForGroup(g.id).length > 0)
            },
        },

        mounted() {
            this.$nextTick(() => this.$refs.filter.focus())
        },

        methods: {
            columnsForGroup(groupId) {
                return this.columns.filter(c => c.group === groupId && c.field !== 'titleNumber')
            },
            columnsMatchingFilter() {
                return this.columns.filter(c => c.field !== 'titleNumber' && (this.localFilter ? c.text.toLowerCase().includes(this.localFilter.toLowerCase()) : true))
            },
            onSelectField(field) {
                this.$emit('select-field', field)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import './title-organiser-options-panel';
</style>
