import TitleInformationApi from '@/api/title-information.api'
import { camelCaseToSentenceCase } from '@/utils/string-utils'

import {
    FETCH_COMPLEXITY_FOR_TITLES,
    MUTATE_ADD_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS,
    MUTATE_REMOVE_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS,
    MUTATE_SET_TITLE_COMPLEXITY,
} from './types.js'

export default {
    /// options = { matterId, titleNumbers = [], debugMode = false }
    async [FETCH_COMPLEXITY_FOR_TITLES](context, options) {
        if (!options?.matterId) {
            return
        }

        // Set the titles requested as having their complexity pending
        context.commit(MUTATE_ADD_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS, options.titleNumbers)

        const data = await TitleInformationApi.getComplexityByTitleNumbers(options.matterId, options.titleNumbers)

        // Parse the result

        const results = data.map(r => {
            let result
            if (r.complexity !== null) {
                result = {
                    score: r.complexity,
                    categoryText: camelCaseToSentenceCase(r.complexity.category),
                    titleNumber: r.titleNumber,
                    indicators: null,
                }

                const complexityIndicators = r.titleComplexityRegisterIndicators
                if (complexityIndicators && options.debugMode === true) {
                    result.indicators = []
                    for (const indicator in complexityIndicators) {
                        if (indicator !== '__typename') {
                            result.indicators.push({
                                id: indicator,
                                text: camelCaseToSentenceCase(indicator),
                                value: complexityIndicators[indicator],
                            })
                        }
                    }
                }
            }
            return result
        }).filter(r => r !== undefined)

        // Store the complexity results against each title
        results.forEach(r => context.commit(MUTATE_SET_TITLE_COMPLEXITY, r))

        // Determine that the complexity is no longer pending for the specified titles
        context.commit(MUTATE_REMOVE_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS, options.titleNumbers)

        // Return the results
        return results
    },
}
