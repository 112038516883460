<template>
    <div class="order-documents-card__container"
         :class="{
             'order-documents-card__container--no-padding': noPadding }">
        <ow-card class="order-documents-card"
                 :class="{ 'order-documents-card--border': selectedTitle?.record && isOrderingAllowed }"
                 :loading="isLoading"
                 :no-padding="selectedTitle?.record ? true : false"
                 :loading-skeleton="{ rows: 1, columns: 1, expandRows: true, reverse: false, ctas: 0 }">
            <div>
                <template v-for="row in rows"
                          :key="row.id">
                    <component :is="row.component"
                               class="order-documents-card__row"
                               v-bind="row.data"
                               :title="row.data.title"
                               :selected-title="selectedTitle"
                               :selected-title-number="selectedTitleNumber"
                               :user-has-vts-charge="userHasVtsCharge"
                               :current-matter-id="currentMatterId"
                               :is-ordering-allowed="isOrderingAllowed"
                               :use-review-assistant="useReviewAssistant"
                               :order-with-checkout="orderWithCheckout" />
                    <v-divider />
                </template>
            </div>
            <ow-icon-renderer :record-source="selectedTitle?.record?.source" />
        </ow-card>
    </div>
</template>

<script setup lang="ts">
    import { computed,
             markRaw,
             ref,
             watch} from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwIconRenderer from '@/components/core/icons/ow-icon-renderer.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OrderTitlePlanRow from '@/components/title-panel/tabs/summary-tab/order-documents-card/sub-type-renderer/order-title-plan-row.vue'
    import OrderTitleRegisterRow from '@/components/title-panel/tabs/summary-tab/order-documents-card/sub-type-renderer/order-title-register-row.vue'
    import TitlePlanRow from '@/components/title-panel/tabs/summary-tab/order-documents-card/sub-type-renderer/title-plan-row.vue'
    import TitleRegisterRow from '@/components/title-panel/tabs/summary-tab/order-documents-card/sub-type-renderer/title-register-row.vue'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import {              TITLE_GET_IS_PLAN_PURCHASED,
                          TITLE_GET_IS_REGISTER_PURCHASED } from '@/store/modules/titles/types'

    const props = defineProps<{
        selectedTitle: any,
        selectedTitleNumber?: string,
        userHasVtsCharge?: boolean,
        currentMatterId?: number,
        isOrderingAllowed: boolean,
        orderWithCheckout?: boolean,
        officialCopies: any,
        noPadding?: boolean,
        hideTitlePlan?: boolean,
        isLoading: boolean,
    }>()

    const store = useStore()
    const isTitleRegisterPurchased = computed<boolean>(() => store.getters[TITLE_GET_IS_REGISTER_PURCHASED])
    const isTitlePlanPurchased = computed<boolean>(() => store.getters[TITLE_GET_IS_PLAN_PURCHASED])
    const documentSource = computed<string>(() => props.selectedTitle?.record?.source)
    const { t } = useI18n()
    const titlePlanData = computed(() => {
        const titlePlan = props.officialCopies?.titlePlan
        return {
            currentVersionHasBeenSentByPost: titlePlan?.currentVersionHasBeenSentByPost,
            hmlrAvailabilityDescription: titlePlan?.hmlrAvailabilityDescription,
            responseDateValue: titlePlan?.responseDateValue,
            documentId: titlePlan?.documentId,
        }
    })
    const titleRegisterData = computed(() => {
        const titleRegister = props.officialCopies?.titleRegister
        return {
            currentVersionHasBeenSentByPost: titleRegister?.currentVersionHasBeenSentByPost,
            currentVersionIsBackdated: titleRegister?.currentVersionIsBackdated,
            hmlrAvailabilityDescription: titleRegister?.hmlrAvailabilityDescription,
            responseDateValue: titleRegister?.responseDateValue,
            failedOrder: titleRegister?.failedOrder,
        }
    })

    type RenderResult = {
        id?: string,
        component?: any,
        data?: {
            title?: string,
            icon?: string,
            primary?: boolean,
        },
    }

    const rows = ref<RenderResult[]>([])

    const icon = computed(() => {
        switch (documentSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND:
                return 'ow-icon-lis'
            default:
                return 'ow-icon-hmlr'
        }
    })

    const showIconText = computed(() => {
        return documentSource.value !== DOCUMENT_SOURCE.SCOTLAND
    })

    const useReviewAssistant = computed(() => {
        return documentSource.value !== DOCUMENT_SOURCE.SCOTLAND
    })

    const componentsToRender = () => {
        let result: RenderResult[] = []

        const addComponent = (id: string, component: any, title: string, icon?: string, primary?: boolean) => {
            result.push({
                id,
                component,
                data: {
                    title,
                    icon,
                    ...(primary ? { primary } : {}),
                },
            })
        }

        const titleRegisterLabel = documentSource.value === DOCUMENT_SOURCE.SCOTLAND ? t('documents.titleSheet') : t('documents.titleRegister')
        const titlePlanLabel = t('documents.titlePlan')

        switch( documentSource.value) {
            case DOCUMENT_SOURCE.SCOTLAND: {
                if (isTitlePlanPurchased.value || isTitleRegisterPurchased.value) {
                    if (isTitleRegisterPurchased.value) {
                        addComponent(`title-register-${ documentSource.value }`, TitleRegisterRow, titleRegisterLabel, '$register', true)
                    }
                    if (isTitlePlanPurchased.value && !props.hideTitlePlan) {
                        addComponent(`title-plan-${ documentSource.value }`, TitlePlanRow, titlePlanLabel, '$titleSheet')
                    }
                } else {
                    addComponent(`title-sheet-title-plan-${ documentSource.value }`, OrderTitleRegisterRow, t('documents.titleSheetTitlePlan'))
                }
                break
            }
            default: {
                if (isTitleRegisterPurchased.value) {
                    addComponent(`title-register-${ documentSource.value }`, TitleRegisterRow, titleRegisterLabel, '$register', true)
                } else {
                    addComponent(`title-register-order-${ documentSource.value }`, OrderTitleRegisterRow, titleRegisterLabel)
                }

                if (isTitlePlanPurchased.value && !props.hideTitlePlan) {
                    addComponent(`title-plan-${ documentSource.value }`, TitlePlanRow, titlePlanLabel, '$titleSheet')
                } else if (!props.hideTitlePlan) {
                    addComponent(`title-plan-order-${ documentSource.value }`, OrderTitlePlanRow, titlePlanLabel)
                }
            }
        }

        return result
    }

    const renderComponents = () => {
        rows.value = componentsToRender()
            .map((item) => {
                return {
                    ...item,
                    component: markRaw(item.component),
                }
            })
    }

    watch(() => documentSource.value, () => {
        renderComponents()
    }, {
        immediate: true,
    })

    // watch for changes in official copies to re-render the components
    watch(() => titleRegisterData.value, () => {
        renderComponents()
    }, {
        deep: true,
    })

    // watch for changes in official copies to re-render the components
    watch(() => titlePlanData.value, () => {
        renderComponents()
    }, {
        deep: true,
    })
</script>

<style lang="scss">
    @import './order-documents-card.scss';
</style>
