import httpClient from './http-client'

export default class ConfigurationApi {
    static END_POINT = 'configuration'
    static promise = null

    /**
     * Get the application settings from the server.
     * @static
     * @returns {null|Promise<T|*>}
     */
    static getSettings() {
        if (this.promise) {
            return this.promise
        }

        return httpClient
            .get(`${ this.END_POINT }?nocache=${ Math.random() }`)
    }

    static getSnapshotSettings() {
        if (this.promise) {
            return this.promise
        }
        return httpClient
            .get(`${ this.END_POINT }/snapshot`)
    }

    /**
     * Calls an endpoint that is mocked in the end-2-end tests for setting feature flags.
     * @returns {null|Promise<T|*>}
     */
    static getFlagsForE2ETesting() {
        return httpClient.get('/e2e-testing/get-flags')
    }
}
