import {
    add as dateFnsAdd,
    differenceInDays as dateFnsDifferenceInDays,
    differenceInMonths as dateFnsDifferenceInMonths,
    differenceInYears as dateFnsDifferenceInYears,
    format as dateFnsFormat,
    isWithinInterval,
} from 'date-fns'

export type DateOptions = {
    years?: number,
    months?: number,
    weeks?: number,
    days?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
}

/**
 * Check if a date is valid
 * @param date
 */
export const isValidDate = (date: Date | string): boolean => {
    return isFinite(+(date instanceof Date ? date : new Date(date)))
}

/**
 * Ensure a date string/object is awlays a Date object.
 * @param date
 */
export const parseDate = (date: string | Date): Date => {
    const parsedDate = date instanceof Date ? date : new Date(date)

    if (!isValidDate(parsedDate)) {
        return null
    }

    return parsedDate
}

/**
 * Format date
 * For pattern see: https://date-fns.org/v2.30.0/docs/format
 * @param date
 * @param pattern
 */
export const format = (date: string | Date, pattern: string = 'dd-MM-yyyy'): string => {
    const parsedDate: Date = parseDate(date)

    if (parsedDate !== null) {
        return dateFnsFormat(parsedDate, pattern)
    }

    return ''
}

/**
 * Get the unix time of a date
 * @param date
 */
export const getUnixTime = (date: Date | string): number => {
    let unixTime: number
    const parsedDate = parseDate(date)
    if (!parsedDate) {
        return null
    }

    try {
        unixTime = Math.round(parsedDate.getTime() / 1000)
    } catch (e) {
        console.error(e)
    }
    return unixTime
}

/**
 * Get the difference in days between two dates
 * @param startDate
 * @param endDate
 */
export const differenceInDays = (startDate: Date | string, endDate: Date | string): number => {
    const parsedStartDate = parseDate(startDate)
    const parsedEndDate = parseDate(endDate)
    if (!parsedStartDate || !parsedEndDate) {
        return null
    }

    return dateFnsDifferenceInDays(parsedStartDate, parsedEndDate)
}

/**
 * Get the difference in months between two dates
 * @param startDate
 * @param endDate
 */
export const differenceInMonths = (startDate: Date, endDate: Date): number => {
    const parsedStartDate = parseDate(startDate)
    const parsedEndDate = parseDate(endDate)
    if (!parsedStartDate || !parsedEndDate) {
        return null
    }

    return dateFnsDifferenceInMonths(parsedStartDate, parsedEndDate)
}

/**
 * Get the difference in years between two dates
 * @param startDate
 * @param endDate
 */
export const differenceInYears = (startDate: Date | string, endDate: Date | string): number => {
    const parsedStartDate = parseDate(startDate)
    const parsedEndDate = parseDate(endDate)
    if (!parsedStartDate || !parsedEndDate) {
        return null
    }

    return dateFnsDifferenceInYears(parsedStartDate, parsedEndDate)
}

/**
 * Add period of time to a date
 * @param date
 * @param options
 */
export const add = (date: string, options: DateOptions): Date => {
    const parsedDate = parseDate(date)
    if (!parsedDate) {
        return null
    }

    return dateFnsAdd(new Date(date), options)
}

/**
 * Get the day before a date
 * @param date
 */
export const getDayBefore = (date: string): string => {
    const parsedDate = parseDate(date)
    if (!parsedDate) {
        return null
    }

    const dayBefore = dateFnsAdd(parsedDate, { days: -1 })
    return format(dayBefore)
}

/**
 * Get today's date
 * @param format - has default of 'dd-MM-yyyy HH:mm'.
 */
export const getToday = (format = 'dd-MM-yyyy HH:mm'): string => {
    return dateFnsFormat(new Date(), format)
}

/**
 * Add the time options to the provided date
 * @param date
 * @param options
 */
export const addTimeToDate = (date: Date | string, options: DateOptions) => {
    const parsedDate = parseDate(date)
    if (!parsedDate) {
        return null
    }
    return dateFnsAdd(parsedDate, options)
}

/**
 * Check if a date is within a date range
 * @param date
 * @param startDate
 * @param endDate
 */
export const isWithinDateRange = (date: Date, startDate: Date, endDate: Date) => {
    return isWithinInterval(date, {
        start: startDate,
        end: endDate,
    })
}
