import {
    StyleLike,
    StyleFunction,
} from 'ol/style/Style'
import { LayerNames } from '@/consts/map-layers'
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import Feature, { FeatureLike } from 'ol/Feature'
import { MapBrowserEvent } from 'ol'
import i18n from '@/plugins/i18n'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { IOwSketchOrStyleProps } from '@/store/modules/sketches/types/style'

export type ConservationAreasLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void
}

export type ConservationAreasFeaturesType = {
    name: string,
    lpa: string,
    // eslint-disable-next-line camelcase
    designation_date: string,
    // eslint-disable-next-line camelcase
    update_date: string,
    // eslint-disable-next-line camelcase
    no_data: boolean,
    source: string,
}

const conservationAreaStyle: Partial<IOwSketchOrStyleProps> = {
    fillColour: 'rgba(195, 155, 211, 0.6)',
    strokeColour: '#000000',
    strokeWidth: 0.4,
}

export class ConservationAreasLayer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: ConservationAreasLayerParams, settings: any) {
        super(params, settings)
        this.layerName = settings.conservationAreasLayer
        this.name = LayerNames.ConservationAreas
        this.attributionText = [`Conservation Areas - © Historic England & Cadw - released ${ settings.conservationAreasReleaseDate }`]
        this.rolloverOptions = {
            showPrimary: true,
            category: i18n.global.t(`map.rollover.${ LayerNames.ConservationAreas }.category`),
            getStyle: (feature) => (this.style as StyleFunction)(feature, null) as Style,
            getPrimary: (features) => {
                const properties = features[0].getProperties() as ConservationAreasFeaturesType
                return properties.name
            },
            getSource(features) {
                const properties = features[0].getProperties() as ConservationAreasFeaturesType
                return properties.source
            },
            getExtended(features) {
                const properties = features[0].getProperties() as ConservationAreasFeaturesType
                return [
                    {
                        name: i18n.global.t(`map.rollover.${ LayerNames.ConservationAreas }.extended.lpa`),
                        value: properties.lpa,
                    },
                    {
                        name: i18n.global.t(`map.rollover.${ LayerNames.ConservationAreas }.extended.designationDate`),
                        value: properties.designation_date,
                    },
                    {
                        name: i18n.global.t(`map.rollover.${ LayerNames.ConservationAreas }.extended.updatedDate`),
                        value: properties.update_date,
                    },
                ]
            },
        }

        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Toggle Conservation Areas layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                const propertiesArray: Array<ConservationAreasFeaturesType> = []
                if (features.length > 0) {
                    propertiesArray.push(...features
                        .filter(x => x.getProperties().name)
                        .map(x => x.getProperties() as ConservationAreasFeaturesType))
                }

                const textArray = ConservationAreasLayer.getHoverTextArray(propertiesArray)
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public name: string = LayerNames.ConservationAreas

    public style:StyleLike = (feature: Feature) => {
        return this.getStyleForFeature(feature)
    }

    private getStyleForFeature(feature: Feature): Style {
        const properties: ConservationAreasFeaturesType = feature.getProperties() as ConservationAreasFeaturesType
        const colour = properties.no_data ? 'rgba(178, 186, 187, 0.8)' : conservationAreaStyle.fillColour
        const zIndex = properties.no_data ? 302 : 310

        const styleHash = properties.no_data ? 'no_data' : 'ca'
        const result = this.styleCache.get(styleHash)
        if (result) {
            return result
        }
        const style = new Style({
            zIndex,
            fill: new Fill({ color: colour }),
            stroke: new Stroke({
                color: '#000000',
                width: 0.4,
            }),
        })
        this.styleCache.set(styleHash, style)
        return style
    }

    public static getHoverTextArray(propertiesArray: Array<ConservationAreasFeaturesType>):string[] {
        if (propertiesArray.length <= 0) {
            return []
        }

        const featureWithData = propertiesArray.find(element => element.no_data === false)

        const properties = featureWithData === undefined ? propertiesArray[0] : featureWithData

        const textArray = [
            `${ i18n.global.t('map.options.conservationAreas.hover.name') }: ${ properties.name }`,
            `${ i18n.global.t('map.options.conservationAreas.hover.lpa') }: ${ properties.lpa }`,
            `${ i18n.global.t('map.options.conservationAreas.hover.designationDate') }: ${ properties.designation_date }`,
            `${ i18n.global.t('map.options.conservationAreas.hover.updatedDate') }: ${ properties.update_date }`,
            `${ i18n.global.t('map.options.conservationAreas.hover.source') }: ${ properties.source }`,
        ]
        return textArray
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        const featuresInExtent = this.layer.getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
            .filter(x => x.get('no_data') === false)

        if (!featuresInExtent.length) {
            return []
        }
        return [{
            id: this.name,
            label: i18n.global.t('map.options.conservationAreas.conservationArea') as string,
            style: conservationAreaStyle,
        }]
    }
}
