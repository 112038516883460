<template>
    <div :class="{
             'title-complexity-rating--loading': isLoading,
         }"
         class="title-complexity-rating">
        <h2 class="label-caps-smallest title-complexity-rating__heading">
            {{ t('titlePanel.cards.complexity.labels.rating') }}
            <span>
                <v-icon>
                    $info
                </v-icon>
                <ow-tooltip max-width="600px"
                            theme="light"
                            activator="parent">
                    <div class="pa-1 title-complexity-rating__tooltip">
                        <p class="title-complexity-category-text">
                            {{ t('titlePanel.cards.complexity.labels.ratingInfo1') }}
                        </p>
                        <ul style="font-weight: 500; margin: 7px 15px;">
                            <li class="title-complexity-category-extremelysimple">
                                {{ t('matter.titleComplexity.extremelySimple') }}
                            </li>
                            <li class="title-complexity-category-verysimple">
                                {{ t('matter.titleComplexity.verySimple') }}
                            </li>
                            <li class="title-complexity-category-fairlysimple">
                                {{ t('matter.titleComplexity.fairlySimple') }}
                            </li>
                            <li class="title-complexity-category-fairlycomplex">
                                {{ t('matter.titleComplexity.fairlyComplex') }}
                            </li>
                            <li class="title-complexity-category-verycomplex">
                                {{ t('matter.titleComplexity.veryComplex') }}
                            </li>
                            <li class="title-complexity-category-extremelycomplex">
                                {{ t('matter.titleComplexity.extremelyComplex') }}
                            </li>
                        </ul>
                        <p>
                            {{ t('titlePanel.cards.complexity.labels.ratingInfo2') }}
                        </p>
                        <!-- List of complexity indicators to present in debug mode-->
                        <ul v-if="isDebugModeEnabled"
                            class="title-complexity-indicator-list"
                            data-test="title-complexity-indicator-list">
                            <li v-for="indicator in complexityIndicators"
                                :key="indicator.id">
                                <strong>{{ indicator.text }}:</strong> {{ indicator.value }}
                            </li>
                        </ul>
                    </div>
                </ow-tooltip>
            </span>
        </h2>

        <ow-loading-skeleton v-if="isLoading"
                             data-test="title-details-panel-loading"
                             height="24px"
                             width="100%" />

        <div v-else
             :class="complexityCSSClass"
             class="title-complexity-rating__data"
             data-test="title-complexity-score-category-text">
            <component :is="complexityIcon" />
            <span :class="complexityCSSClass"
                  class="body-highlight">{{ complexityScoreCategoryText }}</span>

            <span v-if="isDebugModeEnabled"
                  class="body-regular-small"
                  data-test="title-complexity-score-value">({{ complexityScoreValue }})</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        markRaw,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwIconTitleComplexityExtremelyComplex from '@/components/core/icons/ow-icon-title-complexity-extremely-complex.vue'
    import OwIconTitleComplexityExtremelySimple from '@/components/core/icons/ow-icon-title-complexity-extremely-simple.vue'
    import OwIconTitleComplexityFairlyComplex from '@/components/core/icons/ow-icon-title-complexity-fairly-complex.vue'
    import OwIconTitleComplexityFairlySimple from '@/components/core/icons/ow-icon-title-complexity-fairly-simple.vue'
    import OwIconTitleComplexityVeryComplex from '@/components/core/icons/ow-icon-title-complexity-very-complex.vue'
    import OwIconTitleComplexityVerySimple from '@/components/core/icons/ow-icon-title-complexity-very-simple.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { checkFlag } from '@/feature-flags'
    import { ITitleComplexity } from '@/interfaces/title/complexity.interface'
    import {
        FETCH_COMPLEXITY_FOR_TITLES,
        GET_TITLE_COMPLEXITY,
    } from '@/store/modules/complexity/types'
    import { COMPLEXITY } from '@/utils/title-enums'

    const props = defineProps<{
        isLoading?: boolean,
        matterId: Number,
        selectedTitleNumber: String,
    }>()

    const store = useStore()
    const { t } = useI18n()

    const isDebugModeEnabled = computed<boolean>(() => checkFlag('allow-debug-mode', false))

    // TITLE COMPLEXITY SCORE
    const titleComplexity = ref<ITitleComplexity>(null)
    const getTitleComplexity = async () => {          // Request the latest complexity data for the title.
        await store.dispatch(`complexity/${ FETCH_COMPLEXITY_FOR_TITLES }`, {
            matterId: props.matterId,
            titleNumbers: [ props.selectedTitleNumber ],
            debugMode: isDebugModeEnabled.value,
        })
        titleComplexity.value = store.getters[`complexity/${ GET_TITLE_COMPLEXITY }`](props.selectedTitleNumber)
    }

    const complexityScoreCategory = computed<string>(() => {
        return titleComplexity.value?.score?.category ?? ''

    })
    const complexityCSSClass = computed<string>(() => {
        if (!props.isLoading) {
            return `title-complexity-rating__category--${ complexityScoreCategory.value?.toLowerCase() }`
        }
        return ''
    })
    const complexityScoreCategoryText = computed(() => {
        switch (complexityScoreCategory.value?.toLowerCase()) {
            case ('extremelysimple'):
                return t('matter.titleComplexity.extremelySimple')

            case ('verysimple'):
                return t('matter.titleComplexity.verySimple')

            case ('fairlysimple'):
                return t('matter.titleComplexity.fairlySimple')

            case ('fairlycomplex'):
                return t('matter.titleComplexity.fairlyComplex')

            case ('verycomplex'):
                return t('matter.titleComplexity.veryComplex')

            case ('extremelycomplex'):
                return t('matter.titleComplexity.extremelyComplex')

            default:
                return ''

        }
    })
    const complexityScoreValue = computed<string>(() => {
        if (titleComplexity.value?.score?.value) {
            return `${ titleComplexity.value?.score.value }/${ COMPLEXITY.MAX_SCORE }`
        }
        return ''
    })
    const complexityIcon = computed<any>(() => {
        switch (complexityScoreCategory.value?.toLowerCase()) {
            case ('extremelysimple'):
                return markRaw(OwIconTitleComplexityExtremelySimple)

            case ('verysimple'):
                return markRaw(OwIconTitleComplexityVerySimple)

            case ('fairlysimple'):
                return markRaw(OwIconTitleComplexityFairlySimple)

            case ('fairlycomplex'):
                return markRaw(OwIconTitleComplexityFairlyComplex)

            case ('verycomplex'):
                return markRaw(OwIconTitleComplexityVeryComplex)

            case ('extremelycomplex'):
                return markRaw(OwIconTitleComplexityExtremelyComplex)

            default:
                return null
        }
    })
    const complexityIndicators = computed(() => {
        return titleComplexity.value?.indicators ?? []
    })

    onMounted(() => {
        getTitleComplexity()
    })
</script>

<style lang="scss" scoped>
    @import './title-complexity-rating';
</style>
