<template>
    <div class="search-document-viewer-page ow-page-container">
        <loading-skeleton v-if="isLoading" />
        <div v-else>
            <p v-if="failedAccessingDocument">
                Sorry, we encountered problems accessing this document. Please contact support.
            </p>
            <div v-else
                 class="pdf-viewer">
                <searches-document-viewer-header />
                <ow-pdf-viewer fit-to="auto"
                               :document-url="documentUrl"
                               :document-metadata="documentMetadata"
                               is-full-width
                               data-test="search-document-viewer-page-pdf-viewer" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        onMounted,
        ref,
        watch,
    } from 'vue'
    import {
        useRoute,
    } from 'vue-router'

    import SearchesApi, {
        IGetSearchDocumentSasTokensRequest,
    } from '@/api/searches.api'
    import OwPdfViewer from '@/components/core/ow-pdf-viewer.vue'
    import LoadingSkeleton from '@/components/documents/library/loading-skeleton.vue'
    import SearchesDocumentViewerHeader
        from '@/components/documents/library/searches-documents/searches-document-viewer-header.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { Route } from '@/enums/route.enum'
    import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'

    const route = useRoute()

    const documentUrl = ref('')
    const isLoading = ref(true)
    const failedAccessingDocument = ref(false)

    const documentMetadata = ref({ documentType: HighLevelDocumentType.Searches })

    onMounted(() => {
        loadDocument()
    })

    watch(() => route.name, (val) => {
        if (val === Route.SearchesDocument) {
            loadDocument()
        }
    })

    const loadDocument = async () => {
        const orderId = route.params.orderId
        const fileName = route.query.fileName
        isLoading.value = true

        const request: IGetSearchDocumentSasTokensRequest = {
            documents: { [orderId]: [fileName] },
        }

        const response: IHttpClientResponse = await SearchesApi.getSasToken(request)

        if (!response.ok || response.data.length === 0) {
            failedAccessingDocument.value = true
            return
        }

        documentUrl.value = response.data[0] // Only 1 doc should be returned from the call
        failedAccessingDocument.value = false
        isLoading.value = false
    }

    defineExpose({
        documentUrl,
        isLoading,
        failedAccessingDocument,
        documentMetadata,
    })
</script>
