<template>
    <ow-card class="checkout-item-document-order content">
        <section class="checkout-item-document-order__header">
            <div class="checkout-item-document-order__header--title">
                <h3 class="content___subheading">
                    {{ item.displayName }}
                </h3>

                <ow-colored-label v-if="item.title.tenure"
                                  :theme="item.title.tenureLabelTheme"
                                  is-small>
                    {{ item.title.simpleTenure }}
                </ow-colored-label>
            </div>
            <div class="checkout-item-document-order__header--address">
                <ow-icon-text v-if="item.title.address"
                              :text="item.title.address"
                              :title="item.title.address"
                              class="content__subheading--disabled"
                              icon="$map-marker-outline" />
            </div>
        </section>

        <section class="checkout-item-document-order__description">
            <div class="checkout-item-document-order__description--document">
                <ow-colored-label v-if="hasAvailabilityText"
                                  :theme="availabilityTheme">
                    {{ availabilityText }}
                </ow-colored-label>
                <span>
                    {{ documentTypeText }}
                </span>
            </div>
            <div class="checkout-item-document-order__description--cost">
                <span v-if="item.isOrderedForReport"
                      class="checkout-item-document-order__description--for-report">(required for report)</span>
                <ow-icon-hmlr height="16"
                              width="16" />
                <span>{{ item.costText }}</span>
            </div>
        </section>
    </ow-card>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import {
        HmlDocumentAvailabilityTextForCode,
        HmlrDocumentAvailabilityCode,
    } from '@/consts/document-availability'
    import { DocumentType } from '@/consts/document-type'
    import { ICheckoutItemDocumentOrder } from '@/interfaces/checkout/checkout-item-document-order.interface'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'CheckoutItemDocumentOrder',

        components: {
            OwCard,
            OwColoredLabel,
            OwIconHmlr,
            OwIconText,
        },

        props: {
            item: {
                type: Object as PropType<ICheckoutItemDocumentOrder>,
                required: true,
            },
        },

        data() {
            return {}
        },

        computed: {
            availabilityText(): string {
                return HmlDocumentAvailabilityTextForCode[this.item.document.hmlrAvailabilityCode]
            },

            hasAvailabilityText(): boolean {
                return !isNullOrWhitespace(this.availabilityText)
            },

            availabilityTheme(): string {
                if (this.availabilityText) {
                    switch (this.item?.document.hmlrAvailabilityCode) {
                        case HmlrDocumentAvailabilityCode.Available:
                            return 'success'

                        case HmlrDocumentAvailabilityCode.NotImmediatelyAvailable:
                            return 'warning'

                        default:
                            return 'danger'
                    }
                }
                return null
            },

            documentTypeText(): string {
                switch (this.item.document.request.documentType) {
                    case DocumentType.REGISTER:
                        return 'Title register'

                    default:
                        return this.item?.document?.request?.documentType
                }
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './checkout-item-document-order';
</style>
