import axios from 'axios'
import { createApp } from 'vue'

import App from './App.vue'
import { AppVersion } from '@/models/app-version.model'
import SecurityApi from '@/api/security'

// Fonts
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Needed for Bryntum Grid
import 'typeface-roboto'
import 'typeface-ibm-plex-sans'

// CSS
import 'vuetify/styles'
import 'vuetify/dist/vuetify.min.css' // all the css for components
import '@bryntum/grid/grid.classic-light.min.css'
import 'ol/ol.css'
import './App.scss'

// Lib / utility
import * as util from '@/utils/global.js'

// Mixins/Plugins
import router from '@/router/index.js'
import store from './store'
import { initPlugins } from '@/plugins'
import Watchers from '@/mixins/watchers'

import compromise from 'compromise'
import nlpDates from 'compromise-dates'
import nlpNumbers from 'compromise-numbers'
compromise.plugin(nlpNumbers)
compromise.plugin(nlpDates)

// Handle tab visibility event
const handleVisibilityChange = async() => {
    const timeInactiveKey = 'tab-inactive'
    const timeNow = new Date()
    if (document.visibilityState === 'visible') {
        // If coming back to a tab after 30+ mins of inactivity reload the app
        const timeInactive = window.localStorage.getItem(timeInactiveKey)
        const thirtyMins = 1_800_000
        const time30MinsAgo = timeNow.getTime() - thirtyMins
        if (timeInactive && Date.parse(timeInactive) <= time30MinsAgo) {
            window.location.reload()
        }

        // Still need this in case the user's token expired,
        // but it's not been 30 mins to trigger the refresh
        const user = await SecurityApi.getUser()
        if (user) {
            if (user.expired) {
                await SecurityApi.signInSilent()
            }
        } else {
            await SecurityApi.signIn()
        }
    } else {
        // set a timestamp when tab goes inactive
        window.localStorage.setItem(timeInactiveKey, timeNow.toString())
    }
}

declare global {
    interface Window {
        app: any,
        Cypress: any,
        owvars: any,
        IS_STORYBOOK: boolean,
        Intercom: any,
    }
}

// Reload if the app has been updated
AppVersion.reloadToLatestAppVersion()

util.RunUtilityFunctions()

document.addEventListener('visibilitychange', async() => {
    await handleVisibilityChange()
})

const initApp = async() => {
    const app = createApp(App)

    initPlugins(app)

    app.config.globalProperties.$http = axios
    app.config.globalProperties.productionTip = false
    app.config.globalProperties.devtools = true

    // Expose compromise to the app
    // We do this as it was causing a memory leak when imported in the component
    app.provide('compromise', compromise)

    app.mixin(Watchers)

    app.use(store)
    app.use(router)

    // Handy for testing
    global.app = app

    app.mount('#app')
}

initApp()

type E2ETestWindow = Window & typeof globalThis & { Cypress: any; isUnderTest: any; store: any };
const testWindow = window as E2ETestWindow

if (testWindow.Cypress || testWindow.isUnderTest) {
    testWindow.store = store
}
