<template>
    <div class="find-nearby-header">
        <span v-t="'titlePanel.findNearby.radiusLabel'"
              class="body-copy px-1 find-nearby-header__label"
              data-test="find-nearby-header-distance-label" />
        <ow-textfield v-model="distance"
                      class="find-nearby-header__distance-textbox"
                      data-test="find-nearby-header-distance-textbox"
                      :max="selectedOptionInternal.settings.maximumRadiusMetres"
                      :min="selectedOptionInternal.settings.minimumRadiusMetres"
                      :step="selectedOptionInternal.settings.radiusStepMetres"
                      type="number">
            <template #iconSuffix>
                m
            </template>
        </ow-textfield>
        <ow-slider v-model="distance"
                   :max="selectedOptionInternal.settings.maximumRadiusMetres"
                   :min="selectedOptionInternal.settings.minimumRadiusMetres"
                   :step="selectedOptionInternal.settings.radiusStepMetres"
                   data-test="find-nearby-header-distance-slider"
                   unit="m"
                   simple />
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import OwSlider from '@/components/core/ow-slider.vue'
    import OwTextfield from "@/components/core/ow-textfield.vue"
    import { IFindNearbyService } from '@/components/title-panel/v2/find-nearby/implementations/_common/find-nearby-service.interface'

    export default {
        name: 'FindNearbyHeader',

        components: {
            OwTextfield,
            OwSlider,
        },

        props: {
            availableOptions: {
                type: Array as PropType<Array<IFindNearbyService>>,
                required: true,
            },
        },

        emits: ['selected-option', 'distance'],

        data() {
            return {
                selectedOptionInternal: this.availableOptions[0] as IFindNearbyService,
                distanceInternal: 0,
            }
        },

        computed: {
            distance: {
                get(): number {
                    return this.distanceInternal
                },
                set(updatedDistance: number): void {
                    this.$emit('distance', updatedDistance)
                    this.distanceInternal = updatedDistance
                },
            },
        },

        watch: {
            selectedOptionInternal: {
                handler(newVal: IFindNearbyService): void {
                    this.$emit('selected-option', newVal)
                    this.distanceInternal = this.selectedOptionInternal.settings.defaultRadiusMetres
                },
                immediate: true,
            },
        },
    }
</script>

<style scoped lang="scss">
    @import './find-nearby-header';
</style>
