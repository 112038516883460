export enum EPCColours {
    GreenAPlus = 'green-a-plus',
    GreenA = 'green-a',
    GreenB = 'green-b',
    GreenC = 'green-c',
    YellowD = 'yellow-d',
    OrangeE = 'orange-e',
    OrangeF = 'orange-f',
    RedG = 'red-g',
}

export enum EPCSource {
    Domestic = 'domestic',
    NonDomestic = 'non-domestic',
    Display = 'display',
}

export enum EnergyBand {
    APlus= 'A+',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G',
}
