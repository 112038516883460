<template>
    <div class="d-flex flex-column">
        <div :class="{
                 selected: isSelectedTitle,
                 freehold: isFreehold,
                 leasehold: isLeasehold,
                 'is-sublease': depth > 0, }"
             class="d-flex flex-column hierarchy-item"
             data-test="lease-hierarchy-panel">
            <div class="d-flex flex-column item-content"
                 style="padding: 10px;"
                 @click="onItemClick">
                <!-- Title number and address -->
                <header>
                    <ow-checkbox :id="`title-panel-lease-hierarchy-select-title-${ item.titleNumber }`"
                                 v-model="selected"
                                 data-test="lease-hierarchy-item-select"
                                 data-track="TITLE-DETAILS-PANEL - Select title item from lease hierarchy" />
                    <router-link :data-test="`title-panel-leases-select-title-${ item.titleNumber }`"
                                 :to="titleNumberLinkTo(item)"
                                 class="title-panel-leases-select-title"
                                 data-track="TITLE-DETAILS-PANEL - Open title from lease hierarchy"
                                 style="font-weight:bold;">
                        {{ item.titleNumber }}
                    </router-link>

                    <span v-for="address in item.addresses"
                          v-if="item.addresses.length"
                          style="color: #6E6C6C; padding-left: 10px; font-size: 12px; vertical-align: text-bottom;">
                        {{ address }}
                    </span>
                </header>

                <p v-if="item.skipped"
                   class="mb-0">
                    <strong>
                        This title appears elsewhere in the hierarchy.
                    </strong>
                </p>

                <!-- Owner(s) -->
                <div v-if="item.ownerNames.length > 0"
                     style="margin-bottom: 5px">
                    <span v-if="item.ownerNames.length === 1 ">Owner: </span>
                    <span v-else>Owners: </span>
                    <span v-for="name in item.ownerNames">{{ name }}</span>
                </div>
            </div>

            <!-- Expand hierarchy toggle-->
            <div v-if="item.items.length > 0"
                 class="d-flex hierarchy-toggle"
                 data-test="lease-hierarchy-expander"
                 data-track="TITLE-DETAILS-PANEL - Expand lease hierarchy"
                 @click="toggleHierarchy()">
                <div style="font-size: 12px;">
                    <strong class="leasehold-count"
                            data-test="lease-hierarchy-count">
                        <!-- eslint-disable-next-line vue/no-unused-vars -->
                        <span v-for="i in depth">Sub-</span><span>Leaseholds</span> ({{ item.items.length }})
                    </strong>
                    <v-icon v-if="!showHierarchy && !isSelectedTitle"
                            class="expand-icon">
                        $chevron-down
                    </v-icon>
                    <v-icon v-if="showHierarchy && !isSelectedTitle"
                            class="expand-icon">
                        $chevron-up
                    </v-icon>
                </div>
            </div>
        </div>

        <!-- Hierarchy -->
        <div v-if="hasHierarchy"
             v-show="showHierarchy">
            <lease-hierarchy-item v-for="(child, i) in item.items"
                                  :key="getUniqueKey(child, i)"
                                  :depth="hierarchyDepth"
                                  :item="child"
                                  class="hierarchy-item"
                                  :selected-title-numbers="selectedTitleNumbers"
                                  @title-selected="$emit('title-selected', $event)" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        reactive,
        ref,
        watch,
    } from 'vue'
    import { useRoute } from 'vue-router'
    import { useStore } from 'vuex'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import type { LeaseHierarchyItem } from '@/components/lease-hierarchy/lease-hierarchy-types'
    import { Route } from '@/enums/route.enum'
    import { isNullOrEmpty } from "@/utils/array-utils"

    interface Props {
        item: LeaseHierarchyItem,
        depth: number,
        selectedTitleNumbers: string[],
    }
    const props = defineProps<Props>()
    const emit = defineEmits<{
        (e: 'title-selected', value: string): void
    }>()
    const store = useStore()
    const route = useRoute()
    const showHierarchy = ref(true)

    const currentMatterId = computed(() => store.state.matter.currentMatter.id)
    const selectedTitleNumber = computed(() => store.state.title.selectedTitleNumber)
    const isSelectedTitle = computed(() => props.item.titleNumber === selectedTitleNumber.value)
    const selected = computed({
        get() {
            return props.selectedTitleNumbers.includes(props.item.titleNumber)
        },
        set() {
            emit('title-selected', props.item.titleNumber)
        },
    })
    const isFreehold = computed(() => {
        if (props.item.tenure == null) {
            return false
        }
        return props.item.tenure.includes('Freehold')
    })
    const isLeasehold = computed(() => {
        if (props.item.tenure == null) {
            return false
        }
        return props.item.tenure.includes('Leasehold')
    })
    const hasHierarchy = computed(() => {
        return !isNullOrEmpty(props.item.items)
    })
    const hierarchyDepth = computed(() => props.depth + 1)
    const getUniqueKey = (child: LeaseHierarchyItem, index: number) => {
        return `${ child.titleNumber }-${ index }-${ child.parentTitleNumber }-${ Date.now().toString() }`
    }
    const toggleHierarchy = () => {
        if (hasHierarchy.value) {
            showHierarchy.value = !showHierarchy.value
        }
    }
    const titleNumberLinkTo = (item) => {
        const titleDetailsRoute = {
            name: Route.TitleDetails,
            params: {
                titleNumber: item.titleNumber,
            },
        }
        const mapTitleRoute = {
            name: Route.MatterMapTitle,
            params: {
                matterId: currentMatterId.value,
                titleNumber: item.titleNumber,
            },
        }

        return route.name === Route.TitleDetails ? titleDetailsRoute : mapTitleRoute
    }

    const onItemClick = (event) => {
        if (['INPUT', 'A'].includes(event.target.tagName)) {
            return
        }
        emit('title-selected', props.item.titleNumber)
    }

</script>

<style lang="scss"
       scoped>
@import 'lease-hierarchy-item.scss';
</style>
