export default {
    enabled: false,
    initialized: false,
    mapDiv: null,
    map: null,
    panorama: null,
    panoramaLocation: null,
    panoramaHeading: null,
    pointerLocation: null,
    pointerLocationOLMapLayer: null,
    vertical: true,
    selectingStartLocation: false,
}
