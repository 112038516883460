import {
    ISnapshotModel,
    ISnapshotService,
} from '@/components/snapshots/common/snapshot-interfaces'
import {
    BaseSnapshotModel,
    SnapshotApiModel,
} from '@/components/snapshots/common/snapshot-models'
import {
    nextTick,
    reactive,
    ref,
} from 'vue'
import {
    PageLayouts,
    SnapshotDefaultBrowserDpi,
    SnapshotExportFormat,
    SnapshotOrientation,
    SnapshotPrintSizeMarginsMm,
} from '@/components/snapshots/common/snapshot-enums-consts'
import i18n from '@/plugins/i18n'
import * as htmlToImage from 'html-to-image'
import { jsPDF as JsPDF } from 'jspdf'
import * as piexif from 'piexifjs'

export abstract class SnapshotBaseService implements ISnapshotService {
    public exporting = reactive({ value: false })

    // Updated according to whether the rendered content is loading or not
    public isLoadingContent = reactive({ value: false })

    protected constructor(config: SnapshotApiModel) {
        this.exporting.value = false
        this.snapshotModel = reactive(new BaseSnapshotModel())
        this.snapshotModel.configId = config.configId
        this.snapshotModel.configVersion = config.configVersion
        this.snapshotModel.type = config.type
    }

    public snapshotModel: ISnapshotModel
    abstract getSnapshotApiRequest(): SnapshotApiModel

    public async saveCurrentSnapshot(format: SnapshotExportFormat, callback: (format: SnapshotExportFormat) => Promise<void> | null): Promise<void> {
        this.exporting.value = true
        const currentLayout = PageLayouts[this.snapshotModel.renderData.layoutId]
        const dpi = currentLayout.dpi ?? SnapshotDefaultBrowserDpi
        await nextTick()
        const fileName = `${ i18n.global.t('snapshots.actions.defaultFilename') }.${ format }`
        const container = document.querySelector('.renderer-container') as HTMLElement

        // Build JPEG
        // This is a workaround for a bug in html-to-image where it sometimes returns a blank image in the first attempt for Safari
        // In all other browsers it will only call buildJpg once
        const buildJpg = async (container) => {
            let dataUrl = ''
            const minDataLength = 500000 // 500kb
            let i = 0
            const maxAttempts = 10

            while (dataUrl.length < minDataLength && i < maxAttempts) {
                dataUrl = await htmlToImage.toJpeg(container, {
                    width: container.getBoundingClientRect().width,
                    height: container.getBoundingClientRect().height,
                    canvasWidth: container.getBoundingClientRect().width,
                    canvasHeight: container.getBoundingClientRect().height,
                    pixelRatio: 1,
                })
                // console.trace(`Attempt ${ i } to build JPEG: ${ dataUrl.length } bytes`)
                i += 1
            }

            return dataUrl
        }

        requestAnimationFrame(async () => {
            buildJpg(container)
                .then(async dataUrl => {
                    const newDataUrl = this.setJpegMetadata(dataUrl, dpi)
                    if (format === SnapshotExportFormat.JPEG) {
                        // JPEG
                        window.saveAs(newDataUrl, fileName)
                    } else {
                        // PDF
                        const doc = await this.getPdfDocument(container, fileName, newDataUrl)
                        doc.save(fileName)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(async () => {
                    this.exporting.value = false
                    if (callback) {
                        await callback(format)
                    }
                    return Promise.resolve()
                })
        })
        return Promise.resolve()
    }

    public getPdfDocument(container:HTMLElement, fileName: string, imageDataUrl: string): Promise<JsPDF> {
        const pageOrientation = container.clientWidth > container.clientHeight ? 'l' : 'p'
        const layout = PageLayouts[this.snapshotModel.renderData.layoutId]
        let doc, margin, layoutWidth, layoutHeight
        margin = 0

        if (layout.usesRealWorldUnitsMm) {
            // Deduct margin as this was shown in the preview to illustrate what it would look like on a page/printed. Not including the margin in the
            // image itself as (1) there's nothing in it, (2) it would double up the margins if added to Word or set via print settings, and
            // (3) discourage the user from shrinking the image to fit, or removing and then scaling it up etc; which would invalidate the scale.
            margin = SnapshotPrintSizeMarginsMm
            const orientation = this.snapshotModel.renderData.orientation
            layoutWidth = orientation === SnapshotOrientation.Landscape ? layout.height : layout.width
            layoutHeight = orientation === SnapshotOrientation.Landscape ? layout.width : layout.height
            layoutWidth -= (margin * 2) // remove left and right margins
            layoutHeight -= (margin * 2) // remove top and bottom margins
            doc = new JsPDF(pageOrientation, 'mm', [layoutWidth, layoutHeight], true)
        } else {
            layoutWidth = container.clientWidth
            layoutHeight = container.clientHeight
            doc = new JsPDF(pageOrientation, 'px', [layoutWidth, layoutHeight], true)
        }
        doc.addImage(imageDataUrl, 'JPEG', 0, 0, layoutWidth, layoutHeight, '', 'FAST')
        return doc
    }

    public setJpegMetadata = (dataUrl:string, dpi:number) => {
        // Extract Base64 data from Data URL
        const jpegData = dataUrl.split(',')[1]

        // Decode Base64 to binary
        const jpegBytes = atob(jpegData)

        // Parse Exif data
        const exifObj = piexif.load(jpegBytes)

        // Set DPI & Software
        exifObj['0th'][piexif.ImageIFD.XResolution] = [dpi, 1]
        exifObj['0th'][piexif.ImageIFD.YResolution] = [dpi, 1]
        exifObj['0th'][piexif.ImageIFD.Software] = i18n.global.t('name.orbitalWitness')

        // Generate Exif bytes and insert them back
        const exifBytes = piexif.dump(exifObj)
        const newJpegBytes = piexif.insert(exifBytes, jpegBytes)

        // Re-encode to Base64 and create a new Data URL
        const newJpegData = btoa(newJpegBytes)
        const newDataUrl = 'data:image/jpeg;base64,' + newJpegData

        return newDataUrl
    }

    getContentHint(): string {
        return ''
    }
}
