<template>
    <article v-if="selectedPage"
             :class="{ 'is-preview': showReadOnlyDescription }"
             class="d-flex flex flex-column walkthrough-page">
        <div v-if="selectedPage.description || !isSharedLinkView"
             class="walkthrough-page__description">
            <ow-rich-text-editor v-model="descriptionText"
                                 :is-read-only="showReadOnlyDescription"
                                 data-test="walkthrough-page-description"
                                 data-track="WALKTHROUGH - Add page description"
                                 placeholder="Description of the page..." />
        </div>

        <ul class="d-flex flex flex-column justify-start pl-0 walkthrough-page__title-container">
            <draggable :disabled="disableDraggable"
                       :list="selectedPage.contentItems"
                       item-key="titleNumber"
                       class="dragArea"
                       style="min-height:20px;"
                       v-bind="dragOptions">
                <template #item="{ element }">
                    <app-walkthrough-page-content-item :key="element.titleNumber"
                                                       :content="element"
                                                       :is-shared-link-view="isSharedLinkView"
                                                       @updated="onTitleCommentUpdated" />
                </template>
            </draggable>
        </ul>
    </article>
</template>

<script lang="ts">
    import debounce from 'lodash.debounce'
    import { easeOut } from 'ol/easing'
    import draggable from 'vuedraggable'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwRichTextEditor from '@/components/core/ow-rich-text-editor.vue'
    import WalkthroughPageContentItem from '@/components/walkthrough/walkthrough-page-content-item.vue'
    import { TITLE_MUTATE_SELECTED_SUMMARY_TITLE } from '@/store/modules/titles/types'
    import {
        WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT,
        WALKTHROUGH_MUTATE_SELECTED_PAGE_DESCRIPTION,
        WALKTHROUGH_MUTATE_SELECTED_PAGE_TITLE_COMMENT,
        WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER,
    } from '@/store/modules/walkthrough/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    interface ITitleCommentUpdate {
        titleNumber: string,
        comment: string,
    }

    export default {
        name: 'WalkthroughPage',

        components: {
            appWalkthroughPageContentItem: WalkthroughPageContentItem,
            draggable,
            OwRichTextEditor,
        },

        props: {
            isSharedLinkView: {
                type: Boolean,
                required: false,
            },
        },

        data() {
            return {

                // Options for the draggable container.
                dragOptions: {
                    animation: 100,
                    ghostClass: 'ghost',
                },

                pageExtent: null,
                debounceZoomToPageExtent: debounce(() => {
                    this.zoomToPageExtent()
                }, 100),
                debounceUpdateTitleComment: debounce((update: ITitleCommentUpdate) => {
                    this.updateTitleComment(update)
                }, 750),
                debounceUpdatePageDescription: debounce((newDescription: string) => {
                    this.updatePageDescription(newDescription)
                }, 750),
            }
        },

        computed: {

            ...mapState({
                selectedPage: state => state.walkthrough.selectedPage,
                targetMap: state => state.map.map,
                mapView: state => state.map.map.getView(),
                featureChange: state => state.walkthrough.featureChangeTrackingRevisionCounter,
                hasZoomedToInitialPageExtent: state => state.walkthrough.hasZoomedToInitialPageExtent,
            }),

            showReadOnlyDescription() {
                return this.isSharedLinkView
            },

            disableDraggable() {
                return this.isSharedLinkView
            },

            mapSize() {
                return this.targetMap?.getSize() ?? []
            },

            descriptionText: {
                get() {
                    const description = this.selectedPage?.description
                    if (isNullOrWhitespace(description)) {
                        return ''
                    }

                    if (description.startsWith('<p>')) {
                        return description
                    }

                    return `<p>${ description }</p>`
                },
                set(val) {
                    this.debounceUpdatePageDescription(val)
                },
            },
        },

        watch: {

            async 'selectedPage.contentItems'() {
                await this.updateMapLayer()
            },

            async 'selectedPage.pageNumber'() {
                await this.updatePageExtent()
            },

            async featureChange() {
                await this.updatePageExtent()
            },

            pageExtent() {
                // Currently force the view to the extent of the page items, later consider an option for the page extent defined by the user.
                this.debounceZoomToPageExtent()
            },

            mapSize() {
                // Relocate the map view when the map size changes
                this.updatePageExtent()
            },

        },

        mounted() {
            this.updatePageExtent()
        },

        unmounted() {
            this.setSummaryTitleDetails(null)
        },

        methods: {

            ...mapActions({
                updateMapLayer: WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER,
            }),

            ...mapMutations({
                setHasZoomedToInitialPageExtent: WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT,
                setSummaryTitleDetails: TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
                updateTitleComment: WALKTHROUGH_MUTATE_SELECTED_PAGE_TITLE_COMMENT,
                updatePageDescription: WALKTHROUGH_MUTATE_SELECTED_PAGE_DESCRIPTION,
            }),

            async updatePageExtent() {
                if (this.selectedPage?.layer) {
                    await this.$nextTick()
                    if (this.selectedPage?.layer.getSource() !== null) {
                        // Assign page extent based on the extent of features in the layer.
                        this.pageExtent = this.selectedPage.layer.getSource().getExtent()
                    }
                }
            },

            zoomToPageExtent() {
                if (this.pageExtent?.length > 0 && !this.pageExtent.includes(Infinity)) {
                    const duration = this.hasZoomedToInitialPageExtent ? 500 : 1
                    this.mapView.fit(this.pageExtent, {
                        duration,
                        padding: [50, 50, 50, 50],
                        easing: easeOut,
                    })
                    if (!this.hasZoomedToInitialPageExtent) {
                        setTimeout(() => {
                            this.setHasZoomedToInitialPageExtent(true)
                        }, duration)
                    }
                } else if (this.selectedPage.contentItems.length === 0) {
                    this.setHasZoomedToInitialPageExtent(true)
                } else {
                    this.updatePageExtent()
                }
            },

            onTitleCommentUpdated(update: ITitleCommentUpdate) {
                this.debounceUpdateTitleComment(update)
            },
        },
    }
</script>
<style lang="scss">
    @import 'walkthrough-page.scss';
</style>
