import {
    clickFirstPolygonPointCondition,
    getDrawingModeForSketchType,
    getDrawSketchStyle,
    getHighlightStyle,
    getStyleTargetForSketchType,
    sketchInProgressStyleFn,
} from '@/utils/sketch-utils'
import { Draw,
    Modify,
    Snap } from 'ol/interaction'
import { exportOpenLayersFeaturesToGeoJSON,
    exportOpenLayersFeaturesToShapefile } from '@/utils/spatial-io'
import { LOGGING_HEAP_TRACK_EVENT,
    LOGGING_LOG_FEATURE_USAGE } from '@/store/mutation-types'
import {
    SKETCHES_ACTIVATE_SKETCH,
    SKETCHES_CLEAR_CURRENT_SKETCH,
    SKETCHES_EXPORT_SPATIAL_FILE,
    SKETCHES_IMPORT_TITLES,
    SKETCHES_INIT_DRAW_INTERACTION,
    SKETCHES_INITIALISE,
    SKETCHES_LOAD_BY_MATTER_ID,
    SKETCHES_MUTATE_ADD_SKETCH,
    SKETCHES_MUTATE_ADD_SKETCH_ID_PENDING_SAVE,
    SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES,
    SKETCHES_MUTATE_CURRENT_MODE,
    SKETCHES_MUTATE_CURRENT_SKETCH,
    SKETCHES_MUTATE_CURRENT_SKETCH_STYLE,
    SKETCHES_MUTATE_DRAW_LAYER_WITH_SKETCH,
    SKETCHES_MUTATE_HIGHLIGHT_SKETCH,
    SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD,
    SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX,
    SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL,
    SKETCHES_MUTATE_IS_DRAWING,
    SKETCHES_MUTATE_LOADING_SKETCHES,
    SKETCHES_MUTATE_MAKING_UNDO_REDO_CHANGES,
    SKETCHES_MUTATE_REMOVE_SKETCH,
    SKETCHES_MUTATE_REMOVE_SKETCH_ID_PENDING_SAVE,
    SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL,
    SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS,
    SKETCHES_MUTATE_SKETCH,
    SKETCHES_MUTATE_SKETCH_TO_ACTIVATE_ON_LOAD,
    SKETCHES_MUTATE_SKETCHES,
    SKETCHES_MUTATE_SNAPPING_MODE,
    SKETCHES_MUTATE_ZOOM_TO_SKETCH,
    SKETCHES_NEW_SKETCH,
    SKETCHES_REMOVE_SKETCH,
    SKETCHES_RESET,
    SKETCHES_SAVE_CHANGES,
    SKETCHES_SELECT_BY_PIXEL,
    SKETCHES_SET_CURRENT_SKETCH_STYLE,
    SKETCHES_SET_MODE,
    SKETCHES_SET_SKETCHES_LABEL_VISIBILITY,
    SKETCHES_SET_SKETCHES_VISIBILITY,
    SKETCHES_SET_SNAPPING_MODE,
    SKETCHES_TOGGLE_SKETCH_VISIBILITY,
    SKETCHES_UNDO,
    SKETCHES_UPDATE_SKETCHES,
} from '@/store/modules/sketches/types'
import { SketchExportFormat,
    SketchGeometryType,
    SketchMode,
    SketchType,
    SnappingMode } from '@/enums/sketches-enums'
import { never,
    primaryAction,
    shiftKeyOnly,
    singleClick } from 'ol/events/condition'
import { areEqualExcludingWhitespace } from '@/utils/string-utils'
import Collection from 'ol/Collection'
import debounce from 'lodash.debounce'
import Feature from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'
import i18n from '@/plugins/i18n'
import { IOwStyle } from '@/store/modules/sketches/types/style'
import { ISketch } from '@/store/modules/sketches/types/sketch'
import olMap from 'ol/Map'
import { Pixel } from 'ol/pixel'
import SketchesApi from '@/api/sketches.api'
import { SketchesFactory } from '@/store/modules/sketches/types/sketches-factory'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { waitForExpression } from '@/utils/app-utils'
import { getOLStyleForOWStyleDefinition } from '@/utils/style-utils'
import { MatterTitleBoundaryLayerGroup } from '@/store/modules/map/layers/title-boundary-layer/layer-group'
import { Route } from '@/enums/route.enum'
import router from '@/router'

import { getTitleNumbersAndTenuresForVectorTileFeature } from '@/store/modules/map/layers/hmlr'
import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '../link-share-client/types'
import { SketchesLayer } from '@/store/modules/map/layers/sketches-layer'
import { bufferFeatures,
    layerEquals } from '@/utils/map-utils'
import { BoundaryLayer } from '../map/layers/title-boundary-layer/boundary-layer'
import WKT from 'ol/format/WKT'
import { MATTER_IMPORT_FROM_WKT_AREA } from '@/store/modules/matter/types'

export default {
    async [SKETCHES_LOAD_BY_MATTER_ID]({ commit, dispatch, rootState, state }, matterId): Promise<void> {
        // Wait for the map and matter to load, both of which are intrinsically linked to sketches.
        await waitForExpression(() =>
            rootState.map.map !== null &&
            rootState.matter.titlesLayer !== null,
        )

        // Initialise and reset the sketches module; layers interactions etc.
        await dispatch(SKETCHES_INITIALISE, { targetMap: rootState.map.map })
        await dispatch(SKETCHES_RESET)

        // Load the sketches for the matter.
        commit(SKETCHES_MUTATE_LOADING_SKETCHES, true)
        const response = await SketchesApi.getSketchesByMatterId(matterId)
        if (response?.data) {
            const sketches: Array<ISketch> = [...response.data.map(record => SketchesFactory.sketchFromRecord(record))]
            commit(SKETCHES_MUTATE_SKETCHES, sketches)
        }

        // add a slight timeout to fix the no sketches found message flashing up
        commit(SKETCHES_MUTATE_LOADING_SKETCHES, false)

        if (state?.sketchToActivateOnLoad) {
            const sketch = state.sketches.find(s => s.id === state.sketchToActivateOnLoad)
            dispatch(SKETCHES_ACTIVATE_SKETCH, sketch)
            commit(SKETCHES_MUTATE_SKETCH_TO_ACTIVATE_ON_LOAD, null)
        }
    },

    async [SKETCHES_TOGGLE_SKETCH_VISIBILITY]({ commit, dispatch }, sketch: ISketch): Promise<void> {
        sketch.visible = !sketch.visible
        await dispatch(SKETCHES_UPDATE_SKETCHES, [sketch])
        commit(SKETCHES_MUTATE_SKETCH, sketch)
    },

    async [SKETCHES_SET_SKETCHES_VISIBILITY]({ dispatch }, { sketches, visible }: { sketches: Array<ISketch>, visible: boolean }): Promise<void> {
        sketches.forEach(s => {
            s.visible = visible
        })
        await dispatch(SKETCHES_UPDATE_SKETCHES, sketches)
    },

    async [SKETCHES_SET_SKETCHES_LABEL_VISIBILITY]({ dispatch }, { sketches, visible }: { sketches: Array<ISketch>, visible: boolean }): Promise<void> {
        sketches.forEach(s => {
            s.showLabel = visible
            if (visible && !s.visible) {
                s.visible = true
            }
        })
        await dispatch(SKETCHES_UPDATE_SKETCHES, sketches)
    },

    async [SKETCHES_UPDATE_SKETCHES]({ commit, dispatch, state }, sketches: ISketch[]) {
        sketches.forEach(s => {
            state.styleCache.delete(s.id)
            state.highlightStyleCache.delete(s.id)
            commit(SKETCHES_MUTATE_ADD_SKETCH_ID_PENDING_SAVE, s.id)
        })
        await dispatch(SKETCHES_SAVE_CHANGES)
        state.debouncedOnSketchChange()
        state.layer.getSource().changed()
        if (state.drawLayer) {
            state.drawLayer.getSource().changed()
        }
    },

    async [SKETCHES_NEW_SKETCH]({ state, commit, dispatch }, options: { matterId: number, type?: SketchType, sketch?: ISketch }): Promise<ISketch> {
        let newSketch:ISketch
        // Create a new sketch or use the one passed in.
        if (options.sketch) {
            newSketch = options.sketch
        } else {
            newSketch = SketchesFactory.newSketch(options.type)
            newSketch.visible = true
            newSketch.name = `${ SketchType[options.type].toString() } ${ state.sketches.length + 1 }`
        }
        newSketch.sortOrder = state.sketches.length ? state.sketches[state.sketches.length - 1].sortOrder + 100 : 100
        const response = await SketchesApi.createSketch(options.matterId, newSketch)
        const createdSketch = SketchesFactory.sketchFromRecord(response.data)
        commit(SKETCHES_MUTATE_ADD_SKETCH, createdSketch)

        await dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Sketch Created',
            metadata: {
                type: options.type,
            },
        })
        await dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'sketch-created',
            description: options.type,
        })
        return Promise.resolve(createdSketch)
    },

    async [SKETCHES_INITIALISE]({ state, commit, dispatch, rootState, rootGetters }, { targetMap }:{ targetMap: olMap }): Promise<void> {
        if (!state.layer && !state.initialising) {
            // Everything needs initialising.
            state.targetMap = targetMap
            if (!state.targetMap) {
                return Promise.resolve()
            }

            const linkShareClient = rootGetters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]

            // Sketches layer, where the existing sketches will be displayed.
            state.layer = new SketchesLayer({
                targetMap,
                getSketchesFn: () => state.sketches,
                getSketchesStyleFn: (feature) => {
                    const sketch = state.sketches.find(x => x.id === feature.get('sketchId'))
                    if (sketch) {
                        const routeName = router.currentRoute.value.name
                        const sketchesVisibleMap = rootState.matter.currentMatter?.sketchesVisibleMap
                        if (!sketchesVisibleMap && routeName !== Route.MatterSketches) {
                            return null
                        }
                        if (sketch === state.highlightedSketch && !linkShareClient) {
                            // Highlighted sketch.
                            const cachedStyle = state.highlightStyleCache.get(sketch.id)
                            if (cachedStyle) {
                                return cachedStyle
                            }
                            const result = getHighlightStyle(sketch)
                            state.highlightStyleCache.set(sketch.id, result)
                            return result
                        }
                        if (sketch === state.currentSketch) {
                            // We don't want to draw the current sketch, it will be added to the drawing layer instead.
                            return null
                        }
                        if (!sketch.visible) {
                            // No need to draw invisible sketches.
                            return null
                        }

                        const cachedStyle = state.styleCache.get(sketch.id)
                        if (cachedStyle) {
                            return cachedStyle
                        }
                        const styleTarget = getStyleTargetForSketchType(sketch.sketchType)
                        const result = getOLStyleForOWStyleDefinition(sketch, styleTarget)
                        state.styleCache.set(sketch.id, result)
                        return result
                    }
                    return null
                },
            }).getLayer()
            state.targetMap?.addLayer(state.layer)

            // Drawing layer where the creating/editing will happen.
            state.drawLayer = new VectorLayer({
                source: new VectorSource(),
                visible: true,
                zIndex: 400,
                updateWhileAnimating: true,
                updateWhileInteracting: true,
                style: (feature) => {
                    const sketch = state.sketches.find(x => x.id === feature.get('sketchId'))
                    if (!sketch) {
                        return
                    }
                    return getDrawSketchStyle(feature, sketch)
                },
            })
            state.drawLayer.setProperties({ name: 'sketches-draw-layer' })
            state.targetMap.addLayer(state.drawLayer)
            state.debouncedOnSketchChange = debounce(async (addToHistory = true) => {
                if (!state.currentSketch) {
                    return
                }
                const drawFeatures = state.drawLayer.getSource().getFeatures()
                const allFeaturesRemoved = drawFeatures.length === 0 && state.currentSketch.features.length > 0
                if (allFeaturesRemoved || (drawFeatures.length &&
                    (drawFeatures[0].getProperties().sketchId === state.currentSketch.id || !drawFeatures[0].getProperties().sketchId))) {
                    const geoJson = SketchesFactory.geoJsonFromFeatures(drawFeatures)
                    if (!areEqualExcludingWhitespace(state.currentSketch.geoJson, geoJson)) {
                        drawFeatures.forEach(x => x.setProperties({ sketchId: state.currentSketch.id }))
                        if (!state.makingChangesWithUndoRedo && addToHistory) {
                            commit(SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD,
                                state.currentSketch.features.length ? state.currentSketch.geoJson : null)
                        }
                        state.currentSketch.geoJson = geoJson
                        state.currentSketch.features = drawFeatures
                        commit(SKETCHES_MUTATE_DRAW_LAYER_WITH_SKETCH, state.currentSketch)
                        await dispatch(SKETCHES_UPDATE_SKETCHES, [state.currentSketch])
                    }
                }
                state.styleCache.delete(state.currentSketch.id)
                state.highlightStyleCache.delete(state.currentSketch.id)
            }, 100)

            const onMapClick = async (e) => {
                const pixel = state.targetMap?.getEventPixel(e.originalEvent) ?? null
                const drawingAddTitle = rootState.matterDraw.interactions.draw
                if (pixel && !drawingAddTitle) {
                    await dispatch(SKETCHES_SELECT_BY_PIXEL, pixel)
                }
            }
            const onMapPointerMove = async (e) => {
                const drawingAddTitle = rootState.matterDraw.interactions.draw
                if (!drawingAddTitle) {
                    let sketch = null
                    const pixel = state.targetMap?.getEventPixel(e.originalEvent) ?? null
                    if ((state.currentMode !== SketchMode.None)) {
                        sketch = null
                    } else {
                        const features = state.targetMap?.getFeaturesAtPixel(pixel, {
                            layerFilter: currentLayer => layerEquals(state.layer, currentLayer),
                        }) ?? null
                        if (features?.length) {
                            sketch = state.sketches.find(s => s.id === features[0].get('sketchId'))
                        }
                    }
                    document.body.style.cursor = sketch && !linkShareClient ? 'pointer' : ''

                    const tooltip = sketch
                        ? [`Sketch: ${ sketch.name }`]
                        : []
                    commit(SKETCHES_MUTATE_HIGHLIGHT_SKETCH, { sketch, tooltip })
                }
            }

            state.targetMap.on('click', onMapClick)
            state.targetMap.on('pointermove', onMapPointerMove)

            // When a feature is added or removed, update the current sketch.
            state.drawLayer.getSource().on('addfeature', async () => await state.debouncedOnSketchChange())
            state.drawLayer.getSource().on('removefeature', async () => await state.debouncedOnSketchChange())

            let boundaryLayer: BoundaryLayer = null
            if (rootState.matter.titlesLayer) {
                boundaryLayer = (rootState.matter.titlesLayer as MatterTitleBoundaryLayerGroup).boundaryLayer
            }

            // Support modifying existing sketches.
            state.modifyInteraction = new Modify({
                source: state.drawLayer.getSource(),
                pixelTolerance: 20,
                deleteCondition: (e) => {
                    return shiftKeyOnly(e) && singleClick(e)
                },
            })
            state.targetMap.addInteraction(state.modifyInteraction)
            state.modifyInteraction.setActive(true)

            state.modifyInteraction.on('modifystart', async () => {
                if (boundaryLayer) {
                    boundaryLayer.setInteractive(false)
                }
            })

            // When a feature is modified, update the current sketch.
            state.modifyInteraction.on('modifyend', async () => {
                if (boundaryLayer) {
                    boundaryLayer.setInteractive(true)
                }
                commit(SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD, state.currentSketch.geoJson)
                await state.debouncedOnSketchChange()
                await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                    type: 'Sketch Modified',
                    metadata: {
                        type: state.currentSketch.sketchType,
                        id: state.currentSketch.id,
                    },
                })
            })
        }
        return Promise.resolve()
    },

    async [SKETCHES_SET_SNAPPING_MODE]({ state, rootState, commit }, mode: SnappingMode): Promise<void> {
        // Reset.
        if (state.snapInteraction) {
            state.snapInteraction.setActive(false)
            state.targetMap.removeInteraction(state.snapInteraction)
        }
        commit(SKETCHES_MUTATE_SNAPPING_MODE, mode)
        // Initialise.
        if (mode === SnappingMode.None) {
            return Promise.resolve()
        }
        const snappingFeatures: Collection<Feature<Geometry>> = new Collection<Feature<Geometry>>()

        if (mode === SnappingMode.Sketches) {
            state.layer.getSource().getFeatures().forEach(x => snappingFeatures.push(x))
            state.drawLayer.getSource().getFeatures().forEach(x => {
                if (!snappingFeatures.getArray().includes(x)) {
                    snappingFeatures.push(x)
                }
            })
        } else if (mode === SnappingMode.TitleBoundaries) {
            let traceSource: VectorSource
            let titleNumberProperty: string
            traceSource = (rootState.matter.titlesLayer as MatterTitleBoundaryLayerGroup).boundaryLayer.layer.getSource()
            titleNumberProperty = 'titleNumber'
            const expandedTitle = rootState.title.expandedTitleNumber
            const titlesToShow = rootState.matter.currentMatter.selectedTitles
                .filter(x => x.show || x.titleNumber === expandedTitle)
                .map(x => x.titleNumber)

            traceSource.getFeatures()
                .filter(x => titlesToShow.includes(x.get(titleNumberProperty)))
                .forEach(x => snappingFeatures.push(x))

            // Add selected title as it may not exist in the matter
            if (rootState.title?.selectedTitleFeatures) {
                rootState.title.selectedTitleFeatures
                    .filter(x => x.get(titleNumberProperty) && !titlesToShow.includes(x.get(titleNumberProperty)))
                    .forEach(x => snappingFeatures.push(x))
            }
        }
        state.snapInteractionFeatures = snappingFeatures
        state.snapInteraction = new Snap({
            features: snappingFeatures,
        })
        state.targetMap.addInteraction(state.snapInteraction)

        state.snapInteraction.setActive(true)

        // Enable tracing on the same layer as snapping.
        state.drawInteraction?.setProperties({
            trace: true,
            traceSource: new VectorSource({ features: snappingFeatures }),
        })

        return Promise.resolve()
    },

    async [SKETCHES_SET_MODE]({ state, dispatch, commit }, mode:SketchMode): Promise<void> {
        // Stop any drawings in progress.
        state.drawLayer?.setVisible(false)
        state.snapInteraction?.setActive(false)
        state.modifyInteraction?.setActive(false)
        state.drawInteraction?.finishDrawing()

        if (mode !== SketchMode.None) {
            let drawType
            switch (mode) {
                case SketchMode.Line:
                    drawType = SketchGeometryType.MULTI_LINE_STRING
                    break
                case SketchMode.Marker:
                    drawType = SketchGeometryType.MULTI_POINT
                    break
                case SketchMode.Text:
                    drawType = SketchGeometryType.POINT
                    break
                case SketchMode.Area:
                    drawType = SketchGeometryType.POLYGON
            }
            await dispatch(SKETCHES_INIT_DRAW_INTERACTION, drawType)
            state.modifyInteraction.setActive(true)
            state.drawLayer.setVisible(true)
            state.drawInteraction.setActive(true)
            state.modifyInteraction.setActive(true)
            // (re)-apply snapping
            dispatch(SKETCHES_SET_SNAPPING_MODE, state.snappingMode)
        } else {
            state.drawInteraction?.setActive(false)
        }
        commit(SKETCHES_MUTATE_CURRENT_MODE, mode)
        return Promise.resolve()
    },

    async [SKETCHES_REMOVE_SKETCH]({ commit, state, dispatch }, { matterId, sketch } :{ matterId: number, sketch: ISketch }): Promise<void> {
        await SketchesApi.deleteSketch(matterId, sketch.id)
        commit(SKETCHES_MUTATE_REMOVE_SKETCH, sketch)
        if (sketch === state.currentSketch) {
            await dispatch(SKETCHES_SET_MODE, SketchMode.None)
        }
        await dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Sketch Deleted',
            metadata: {
                type: sketch.sketchType,
            },
        })
        await dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'sketch-deleted',
            description: sketch.sketchType,
        })
    },

    async [SKETCHES_ACTIVATE_SKETCH]({ commit, dispatch }, sketch: ISketch): Promise<void> {
        commit(SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS, true)
        commit(SKETCHES_MUTATE_ZOOM_TO_SKETCH, sketch)
        commit(SKETCHES_MUTATE_CURRENT_SKETCH, sketch)
        commit(SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL)
        await dispatch(SKETCHES_SET_MODE, getDrawingModeForSketchType(sketch.sketchType))
    },

    async [SKETCHES_CLEAR_CURRENT_SKETCH]({ state, commit, dispatch }): Promise<void> {
        commit(SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD, state.currentSketch.geoJson)
        state.drawInteraction?.finishDrawing()
        state.drawLayer?.getSource().clear()
        state.currentSketch.geoJson = null
        state.currentSketch.features = []
        commit(SKETCHES_MUTATE_DRAW_LAYER_WITH_SKETCH, state.currentSketch)
        commit(SKETCHES_MUTATE_ADD_SKETCH_ID_PENDING_SAVE, state.currentSketch.id)
        await dispatch(SKETCHES_SAVE_CHANGES)
    },

    async [SKETCHES_SAVE_CHANGES]({ state, rootState, commit }): Promise<void> {
        if (state.debouncedSaveChanges === null) {
            state.debouncedSaveChanges = debounce(async () => {
                await Promise.all(state.sketchIdsWithPendingChanges.map(async sketchId => {
                    const sketch = state.sketches.find(s => s.id === sketchId)
                    if (sketch) {
                        await SketchesApi.updateSketch(rootState.matter.currentMatter.id, sketch)
                        commit(SKETCHES_MUTATE_REMOVE_SKETCH_ID_PENDING_SAVE, sketchId)
                    }
                }))
            }, 1000)
        }
        state.debouncedSaveChanges()
    },

    async [SKETCHES_SET_CURRENT_SKETCH_STYLE]({ state, commit, dispatch }, style: IOwStyle): Promise<void> {
        if (!state.currentSketch) {
            return Promise.resolve()
        }
        commit(SKETCHES_MUTATE_CURRENT_SKETCH_STYLE, style)
        await dispatch(SKETCHES_UPDATE_SKETCHES, [state.currentSketch])
    },

    async [SKETCHES_RESET]({ commit, dispatch, state }): Promise<void> {
        commit(SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES)
        commit(SKETCHES_MUTATE_CURRENT_SKETCH, null)
        commit(SKETCHES_MUTATE_SKETCHES, [])
        await dispatch(SKETCHES_SET_MODE, SketchMode.None)
        state.drawLayer?.getSource().clear()
        state.layer?.getSource().clear()
        state.styleCache.clear()
        state.highlightStyleCache.clear()
    },

    async [SKETCHES_INIT_DRAW_INTERACTION]({ state, commit, rootState }, type :any): Promise<void> {
        // When changing the type of sketch (i.e. point, line, polygon) we need to re-initialise the draw interaction.
        if (state.drawInteraction) {
            state.drawInteraction.setActive(false)
            state.targetMap.removeInteraction(state.drawInteraction)
            delete state.drawInteraction
        }

        let traceSource = null
        let boundaryLayer: BoundaryLayer = null
        if (rootState.matter.titlesLayer) {
            boundaryLayer = (rootState.matter.titlesLayer as MatterTitleBoundaryLayerGroup).boundaryLayer
            traceSource = boundaryLayer.layer.getSource()
        }

        state.drawInteraction = new Draw({
            source: state.drawLayer.getSource(),
            type,
            style: (feature) => {
                return sketchInProgressStyleFn(feature, state.currentSketch.sketchType)
            },
            trace: true,
            stopClick: true,
            freehandCondition: never,
            snapTolerance: 6,
            traceSource,
            condition: (e) => primaryAction(e),
            finishCondition: e => clickFirstPolygonPointCondition(e, state.drawInteraction, state?.targetMap),
        })
        state.drawInteraction.on('drawstart', async () => {
            if (boundaryLayer) {
                boundaryLayer.setInteractive(false)
            }
            commit(SKETCHES_MUTATE_IS_DRAWING, true)
        })
        state.drawInteraction.on('drawend', async () => {
            if (boundaryLayer) {
                boundaryLayer.setInteractive(true)
            }
            commit(SKETCHES_MUTATE_IS_DRAWING, false)
            state.modifyInteraction.setActive(true)

            // If we are in text mode and there are no features, show the name/description modal.
            if (state.currentMode === SketchMode.Text && state.currentSketch.features.length === 0) {
                commit(SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL, {
                    show: true,
                })
            }
        })
        state.targetMap.addInteraction(state.drawInteraction)
        return Promise.resolve()
    },

    async [SKETCHES_SELECT_BY_PIXEL]({ state, dispatch, commit, rootState }, pixel: Pixel): Promise<void> {
        const routeToSketches = async (sketchId) => {
            commit(SKETCHES_MUTATE_SKETCH_TO_ACTIVATE_ON_LOAD, sketchId)
            await router.push({ name: Route.MatterSketches, params: { matterId: rootState.matter.currentMatter.id } })
        }

        const titleNumbers = []
        rootState.map.map.forEachFeatureAtPixel(pixel, (feature: any) => {
            titleNumbers.push(...getTitleNumbersAndTenuresForVectorTileFeature(feature))
        })

        // If we have a title number / tenure, we don't want to select a sketch.
        if (titleNumbers.length || !state.enableSketchesMapSelection || state.isDrawing) {
            return
        }

        const features = state.targetMap?.getFeaturesAtPixel(pixel, {
            layerFilter: currentLayer => layerEquals(state.layer, currentLayer),
        }) ?? null
        if (!features.length) {
            return
        }
        const sketch = state.sketches.find(s => s.id === features?.[0]?.get('sketchId'))
        if (sketch) {
            const routeName = router.currentRoute.value.name
            routeName === Route.MatterSketches
                ? dispatch(SKETCHES_ACTIVATE_SKETCH, sketch)
                : routeToSketches(sketch.id)
        }
    },

    async [SKETCHES_UNDO]({ state, commit }): Promise<void> {
        // Depending on the context, the implementation of 'undo' is different.

        // If we are in the middle of drawing a sketch, we want to undo the last point.
        if (state.currentSketch && state.drawInteraction.getOverlay().getSource().getFeatures().length > 1) {
            state.drawInteraction.removeLastPoint()
        } else if (state.currentSketch && state.modifyInteraction.getActive() === true) {
            // If we are editing a sketch, we want to undo the last change
            commit(SKETCHES_MUTATE_MAKING_UNDO_REDO_CHANGES, true)
            commit(SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX, state.history.currentSketchGeoJsonIndex - 1)
            commit(SKETCHES_MUTATE_MAKING_UNDO_REDO_CHANGES, false)
            await state.debouncedOnSketchChange(false)
        }
    },
    async [SKETCHES_EXPORT_SPATIAL_FILE](_, { format, sketches }: { format: SketchExportFormat, sketches: ISketch[] }): Promise<void> {
        const features = sketches.flatMap(x => x.features)
            .map(x => x.clone())
        // Remove unused properties and add a default.
        features.forEach((x:Feature, index: number) => {
            const sketch = sketches.find(s => s.id === x.get('sketchId'))
            x.set('sketchType', undefined)
            x.set('sketchId', undefined)
            x.set('id', index)
            x.set('name', sketch.name)
            x.set('desc', sketch.comment)
        })
        const fileName = i18n.global.t('sketches.exportSpatialFilename', sketches.length, { name: sketches[0].name, count: sketches.length }).toString()
        switch (format) {
            case SketchExportFormat.Shapefile:
                await exportOpenLayersFeaturesToShapefile(features, fileName)
                break
            case SketchExportFormat.GeoJSON:
                await exportOpenLayersFeaturesToGeoJSON(features, fileName)
                break
            default:
                console.error(`Unknown export format: ${ format }`)
        }
    },
    async [SKETCHES_IMPORT_TITLES]({ dispatch }, { sketch, matterId, onlyWithin, matterGroupId, buffer } : { sketch: ISketch, matterId: number, onlyWithin: boolean, matterGroupId?: string, buffer?:number }): Promise<void> {
        const format = new WKT()
        let wkt: string
        if (buffer) {
            // NOTE: For debugging
            // const oldWkt = format.writeFeatures(sketch.features)
            const buffered = bufferFeatures(sketch.features, buffer)
            wkt = format.writeFeature(buffered, {
                featureProjection: 'EPSG:4326',
                dataProjection: 'EPSG:27700',
            })
        } else {
            wkt = format.writeFeatures(sketch.features)
        }
        const request = {
            geometries: [wkt],
            matterId,
            matterGroupId,
            onlyWithin,
        }
        await dispatch(MATTER_IMPORT_FROM_WKT_AREA, request)
    },
}
