<template>
    <div class="sidebar-header__container">
        <div class="sidebar-header d-flex align-center justify-content-between"
             :class="{
                 'sidebar-header--collapsed': collapsed,
                 'sidebar-header--divider': !subtitle && !collapsed && !hideDivider,
                 'pl-0': hasIconSlot,
             }">
            <h1 :data-test="headerDataTest"
                class="headers-h1-page-title hide-in-percy d-flex flex-grow-1 align-center mx-3">
                <div v-if="hasIconSlot"
                     class="sidebar-header__icon mr-2 d-flex align-center">
                    <slot name="headerIcon" />
                </div>
                <div v-if="!collapsed"
                     class="sidebar-header__title">
                    {{ title }}
                </div>
            </h1>
            <div class="d-flex align-center flex-shrink-1">
                <slot />
                <div v-if="hasCopilotRole && !isWalkthrough">
                    <ow-button-ds icon
                                  is-flat
                                  small
                                  :class="{ 'd-none': collapsed }"
                                  @click="showRegisterInterestModel = true">
                        <ow-icon-copilot height="24px"
                                         width="24px" />
                    </ow-button-ds>
                    <div v-if="subtitle"
                         class="sidebar-header__subtitle d-flex caption-regular">
                        {{ subtitle }}
                    </div>
                </div>
                <ow-button-ds v-if="!hideCollapseButton"
                              icon
                              is-flat
                              small
                              class="sidebar-header__collapse-button"
                              :data-test="buttonDataTest"
                              :data-track="buttonDataTrack"
                              @click.stop="emit('toggle', !props.collapsed)">
                    <v-icon>
                        $chevron-double-left
                    </v-icon>
                </ow-button-ds>
            </div>
        </div>
        <div v-if="hasHeaderActionsSlot"
             class="sidebar-header__actions flex justify-space-between align-center pb-1 pt-3">
            <slot name="headerActions" />
        </div>
    </div>
    <teleport to="body">
        <dialog-register-interest v-model="showRegisterInterestModel"
                                  icon="ow-icon-copilot"
                                  :title="$t('matterSettings.copilotAutoSyncDialog.title')"
                                  :preamble="$t('matterSettings.copilotAutoSyncDialog.description')"
                                  :send-heap-event-on-open="false"
                                  heap-track-type="Sidebar - Copilot Autosync" />
    </teleport>
</template>

<script setup lang="ts">
    import {
        computed,
        useSlots,
    } from 'vue'
    import {
        useRoute,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import owIconCopilot from '@/components/core/icons/ow-icon-copilot.vue'
    import OwButtonDs from '@/components/core/ow-button-ds.vue'
    import DialogRegisterInterest from '@/components/dialogs/dialog-register-interest.vue'
    import { ApplicationRoles } from '@/enums/application-roles.enum'
    import { Route } from '@/enums/route.enum'

    const props = withDefaults(defineProps<{
        title?: string;
        subtitle?: string;
        headerDataTest?: string;
        buttonDataTest?: string;
        buttonDataTrack?: string;
        collapsed?: boolean;
        hideCollapseButton?: boolean;
        hideDivider?: boolean;
    }>(), {
        title: '',
        subtitle: '',
        headerDataTest: '',
        buttonDataTest: '',
        buttonDataTrack: '',
        collapsed: false,
        hideCollapseButton: false,
        hideDivider: false,
    })

    const emit = defineEmits<{
        (e: 'toggle', collapsed: boolean),
    }>()

    const slots = useSlots()
    const store = useStore()
    const showRegisterInterestModel = defineModel<boolean>()
    const route = useRoute()

    const hasIconSlot = computed(() => slots?.headerIcon && slots.headerIcon())
    const hasHeaderActionsSlot = computed(() => slots?.headerActions && slots.headerActions())
    const hasCopilotRole = computed(() => store.state.user?.roles?.includes(ApplicationRoles.Copilot))

    const isWalkthrough = computed(() => route.name === Route.MatterMapWalkthrough)
</script>

<style lang="scss">
    @import './ow-sidebar-header';
</style>
