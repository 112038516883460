<template>
    <header class="title-details-panel-header">
        <div class="title-details-panel-header__title-number">
            <h1 class="headers-h1-page-title"
                data-test="title-panel-header-title">
                {{ selectedTitleNumber }}
            </h1>
            <ow-register-status-icon v-if="isRegisterPurchased || isRegisterUploaded"
                                     :is-register-ordered="isRegisterPurchased"
                                     :is-register-uploaded="isRegisterUploaded"
                                     :response-date-value="responseDateValue"
                                     :tr-owner-mismatch="trOwnershipMismatch"
                                     should-pulse
                                     is-small />
        </div>
        <div class="title-details-panel-header__actions">
            <div class="title-details-panel-header__actions--primary-buttons">
                <!-- Primary buttons, only 1 shown at a time -->
                <open-copilot-button v-if="!isLoading && titleInMatter && showCopilotButton"
                                     :send-to-copilot="bypassModal"
                                     :is-disabled="noDocumentsAvailable"
                                     :disabled-tooltip-text="$t('titlePanel.owCopilotBlocked.noDocumentsToSend')"
                                     :title="$t('action.sendToOrbitalCopilot')" />

                <ow-button v-if="!isLoading && !titleInMatter"
                           data-test="title-panel-add-to-matter"
                           data-track="TITLE-DETAILS-PANEL - Add to matter"
                           is-secondary
                           @click="$emit(EVENTS.TOGGLE_PIN)">
                    <span v-t="'titlePanel.header.actions.addToMatter'" />
                </ow-button>
                <ow-button v-else-if="!showCopilotButton && isRegisterAvailable"
                           :disabled="!isRegisterViewable || isRegisterOrdering"
                           :icon="isRegisterOrdering"
                           data-test="title-details-panel-open-register"
                           data-track="TITLE-DETAILS-PANEL - Open register"
                           is-secondary
                           @click="$emit(EVENTS.OPEN_REGISTER)">
                    <v-progress-circular v-if="isRegisterOrdering"
                                         :size="20"
                                         :width="3"
                                         color="primary"
                                         indeterminate />
                    <span v-if="!isRegisterOrdering"
                          v-t="'titlePanel.header.actions.openRegister'" />
                </ow-button>

                <order-register-button v-else-if="!isLoading && titleInMatter"
                                       :current-matter-id="currentMatterId"
                                       :is-ordering-allowed="isOrderingAllowed"
                                       :selected-title-number="selectedTitleNumber"
                                       data-test="title-details-panel-order-register"
                                       data-track="TITLE-DETAILS-PANEL - Add to matter" />
            </div>

            <!-- Full height secondary buttons -->
            <ow-button v-if="false"
                       class="title-details-panel-header__actions--secondary-button"
                       data-test="title-details-panel-more-actions-btn"
                       data-track="TITLE-DETAILS-PANEL - Header panel more options"
                       full-height
                       icon
                       is-borderless
                       large>
                <v-icon>$menu-more</v-icon>
            </ow-button>

            <ow-tooltip position="bottom">
                <template #activator="{ props }">
                    <span class="download-button-tooltip-wrapper"
                          v-bind="props">
                        <ow-button v-if="isRegisterAvailable"
                                   :disabled="isDownloadingRegister"
                                   class="title-details-panel-header__actions--secondary-button"
                                   data-test="title-details-panel-download-btn"
                                   data-track="TITLE-DETAILS-PANEL - Header panel download register"
                                   full-height
                                   icon
                                   is-borderless
                                   is-secondary
                                   large
                                   @click="$emit(EVENTS.DOWNLOAD_REGISTER)">
                            <v-progress-circular v-if="isDownloadingRegister"
                                                 :size="20"
                                                 :width="3"
                                                 color="primary"
                                                 indeterminate />
                            <v-icon v-else>
                                $download
                            </v-icon>
                        </ow-button>
                    </span>
                </template>
                <span v-t="'documents.message.downloadPdf'" />
            </ow-tooltip>
            <export-button-menu v-if="isRegisterAvailable"
                                :current-matter-id="currentMatterId"
                                :selected-title-numbers="[selectedTitleNumber]"
                                button-height="63px"
                                class="title-details-panel-header__actions--secondary-button title-details-panel-header__actions--export"
                                data-test="title-panel-export-as-button"
                                data-track="TITLE-DETAILS-PANEL - Export title as report"
                                heap-tracker-prefix="TITLE_PANEL_HEADER"
                                is-borderless
                                is-full-height />
            <ow-button :data-track="collapsePanelDataTrack"
                       class="title-details-panel-header__actions--secondary-button"
                       data-test="title-details-panel-toggle-collapse-panel"
                       full-height
                       icon
                       is-borderless
                       large
                       @click="$emit(EVENTS.COLLAPSE_CLICK)">
                <v-icon v-if="isPanelCollapsed">
                    $chevron-down
                </v-icon>
                <v-icon v-else>
                    $chevron-up
                </v-icon>
            </ow-button>
            <ow-button class="title-details-panel-header__actions--secondary-button"
                       data-test="title-details-panel-close-btn"
                       data-track="TITLE-DETAILS-PANEL - Close panel"
                       full-height
                       icon
                       is-borderless
                       large
                       @click="$emit(EVENTS.CLOSE_CLICK)">
                <v-icon>$close</v-icon>
            </ow-button>
        </div>
    </header>
</template>

<script lang="ts">
    import TitleInformationApi from "@/api/title-information.api"
    import OpenCopilotButton from '@/components/copilot/open-copilot-button.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import ExportButtonMenu from '@/components/reporting/export-button-menu.vue'
    import OrderRegisterButton from '@/components/title-panel/order-register-button.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import OwRegisterStatusIcon from '../core/ow-register-status-icon.vue'

    const EVENTS = {
        COLLAPSE_CLICK: 'collapse',
        CLOSE_CLICK: 'close',
        TOGGLE_PIN: 'pin',
        OPEN_REGISTER: 'open-register',
        DOWNLOAD_REGISTER: 'download-register',
    }
    export default {
        name: 'TitlePanelHeader',

        components: {
            ExportButtonMenu,
            OrderRegisterButton,
            OwButton,
            OwTooltip,
            OpenCopilotButton,
            OwRegisterStatusIcon,
        },
        mixins: [FeatureFlagsMixin],
        props: {
            currentMatterId: {
                type: [Number, String],
                required: false,
            },
            isDownloadingRegister: {
                type: Boolean,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
                default: false,
            },
            isOrderingAllowed: {
                type: Boolean,
                required: false,
            },
            isPanelCollapsed: {
                type: Boolean,
                required: false,
            },
            isRegisterOrdering: {
                type: Boolean,
                required: false,
            },
            isRegisterPurchased: {
                type: Boolean,
                required: false,
            },
            isRegisterUploaded: {
                type: Boolean,
                required: false,
            },
            isRegisterViewable: {
                type: Boolean,
                required: false,
            },
            responseDateValue: {
                type: String,
                required: false,
            },
            selectedTitleNumber: {
                type: String,
                required: false,
                default: '',
            },
            selectedTitle: {
                type: Object,
                required: true,
            },
            titleInMatter: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            EVENTS.COLLAPSE_CLICK,
            EVENTS.CLOSE_CLICK,
            EVENTS.DOWNLOAD_REGISTER,
            EVENTS.OPEN_REGISTER,
            EVENTS.TOGGLE_PIN,
        ],

        data() {
            return {
                EVENTS,
                trOwnershipMismatch: false,
                noDocumentsAvailable: false,
            }
        },

        computed: {
            collapsePanelDataTrack(): string {
                return this.isPanelCollapsed
                    ? 'TITLE-DETAILS-PANEL - Show panel over the map'
                    : 'TITLE-DETAILS-PANEL - Collapse panel to show the map'
            },
            isRegisterAvailable(): boolean {
                return !this.isLoading && this.titleInMatter && (this.isRegisterPurchased || this.isRegisterUploaded)
            },
            showCopilotButton(): boolean {
                return this.isCopilotEnabled
            },
            bypassModal(): boolean {
                return this.isCopilotModalBypassEnabled
            },
            officialCopiesAvailabilityResults(): any {
                return this.selectedTitle?.officialCopiesAvailability?.results
            },
        },

        watch: {
            async selectedTitleNumber() {
                if (this.selectedTitleNumber) {
                    const trOwnershipFlag = await TitleInformationApi.checkOwnerMatchWithCcodOcodByTitleNumber(this.selectedTitleNumber)
                    this.trOwnershipMismatch = trOwnershipFlag === false
                }
            },

            officialCopiesAvailabilityResults: {
                handler(results: any) {
                    if (results) {
                        const docs = []
                        const { titlePlan, titleRegister, referredToDocuments } = results
                        if (titlePlan?.documentId || (titlePlan?.downloadUrl || titlePlan?.documentDownloadUrl)) {
                            docs.push(titlePlan)
                        }
                        if (titleRegister?.documentId || (titleRegister?.downloadUrl || titleRegister?.documentDownloadUrl)) {
                            docs.push(titleRegister)
                        }
                        if (referredToDocuments) {
                            referredToDocuments.forEach((doc: IDocumentMetadata) => {
                                if (!isNullOrWhitespace(doc.filename) || (doc.downloadUrl || doc.documentDownloadUrl)) {
                                    docs.push(doc)
                                }
                            })
                        }
                        this.noDocumentsAvailable = !docs.length
                    }
                },
                immediate: true,
                deep: true,
            },
        },
    }
</script>

<style lang="scss"
       scoped>
@import './title-panel-header';
</style>
