<template>
    <ow-clipboard :text="textEntry"
                  scrollable
                  @clicked="logClip"
                  @scrolled="handleScroll">
        <div class="schedule-of-lease-record-entry"
             data-test="review-assistant-register-record-entry-text">
            <div class="schedule-of-lease-record-entry__data">
                <div v-for="([key, value], index) of parsedEntry"
                     :key="key+value"
                     class="schedule-of-lease-record-entry__section">
                    <ow-text-highlight class="body-highlight"
                                       :queries="searchQueries"
                                       @match-count-changed="keySearchMatchCount[index] = $event">
                        {{ key }}:
                    </ow-text-highlight>
                    <ow-text-highlight class="body-regular"
                                       :queries="searchQueries"
                                       @match-count-changed="valueSearchMatchCount[index] = $event">
                        {{ value }}
                    </ow-text-highlight>
                </div>
            </div>
            <div v-if="entry.notes.length"
                 class="schedule-of-lease-record-entry__notes">
                <ow-text-highlight v-for="(note, index) of entry.notes"
                                   :key="`note-${index}`"
                                   class="body-italic"
                                   :queries="searchQueries"
                                   @match-count-changed="noteSearchMatchCount[index] = $event">
                    {{ note }}
                </ow-text-highlight>
            </div>
        </div>
    </ow-clipboard>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { mapActions } from 'vuex'

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import { useCompromise } from '@/composables/use-compromise'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    const EVENTS = {
        MATCH_COUNT_CHANGED: 'match-count-changed',
        SCROLLED: 'scrolled',
    }

    export default {
        components: {
            OwClipboard,
            OwTextHighlight,
        },

        props: {
            entry: {
                type: Object as PropType<IScheduleOfLeaseEntry>,
                required: true,
            },

            searchFilter: {
                type: String,
                required: true,
            },

            highlightDates: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        setup() {
            const { compromise } = useCompromise()
            return {
                compromise,
            }
        },

        data() {
            return {
                keySearchMatchCount: new Array(5).fill(0),
                valueSearchMatchCount: new Array(5).fill(0),
                noteSearchMatchCount: new Array(this.entry.notes.length).fill(0),
            }
        },

        computed: {
            dateFilters(): string[] {
                const doc: any = this.compromise(this.textEntry)
                const dateTerms: string[] = doc
                    .dates()
                    .json()
                    .map(({ text }) => text)

                return dateTerms
            },

            searchQueries(): string[] {
                if (this.highlightDates) {
                    return [
                        this.searchFilter,
                        ...this.dateFilters,
                    ]
                }

                return [this.searchFilter]
            },

            parsedEntry(): [string, string][] {
                const result = []

                if (this.entry.registrationDateAndPlanRef?.length) {
                    result.push([this.$t('titles.sonol.regDateAndPlan'), this.entry.registrationDateAndPlanRef])
                }

                if (this.entry.propertyDescription?.length) {
                    result.push([this.$t('titles.sonol.propertyDescription'), this.entry.propertyDescription])
                }

                if (this.entry.dateOfLease?.length) {
                    result.push([this.$t('titles.sonol.dateOfLease'), this.entry.dateOfLease])
                }

                if (this.entry.term?.length) {
                    result.push([this.$t('titles.sonol.term'), this.entry.term])
                }

                if (this.entry.lesseesTitle?.length) {
                    result.push([this.$t('titles.sonol.lesseesTitle'), this.entry.lesseesTitle])
                }

                return result
            },

            textEntry() {
                const entriesText = this.parsedEntry.map(line => line.join(': ')).join('\n')
                const notesText = this.entry.notes.length ? `\n\n${ this.entry.notes.join('\n') }` : ''

                return entriesText + notesText
            },

            totalSearchMatchCount(): number {
                return this.keySearchMatchCount.reduce((acc: number, v: number) => acc + v, 0) +
                    this.valueSearchMatchCount.reduce((acc: number, v: number) => acc + v, 0) +
                    this.noteSearchMatchCount.reduce((acc: number, v: number) => acc + v, 0)
            },
        },

        watch: {
            totalSearchMatchCount(value: number) {
                this.$emit(EVENTS.MATCH_COUNT_CHANGED, value)
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            logClip() {
                this.logHeapEvent({
                    type: 'REVIEW-ASSISTANT - Copy Register Entry',
                    metadata: { content: 'sonol' },
                })
            },

            handleScroll() {
                this.$emit(EVENTS.SCROLLED)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'schedule-of-lease-record-entry.scss';
</style>
