import JSZip, { JSZipObject } from 'jszip'
import {
    SKETCH_IMPORT_SUPPORTED_FILE_EXTENSIONS,
    SKETCH_IMPORT_SUPPORTED_FILE_TYPES,
} from '@/consts/sketch-consts'
import {
    UploadFileExtensions,
    UploadFileTypes,
} from '@/enums/upload-file-types.enum'
import { Coordinate } from 'ol/coordinate'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import Feature from 'ol/Feature'
import { fileExtensionFromFile } from '@/utils/file-utils'
import FileSaver from 'file-saver'
import GeoJSON from 'ol/format/GeoJSON'
import { KML } from 'ol/format'
import { LineString } from 'ol/geom'
import MultiPolygon from 'ol/geom/MultiPolygon'
import Point from 'ol/geom/Point'
import shp from 'shpjs'
import { writeShapeFile } from '@/utils/shape-file.utils'
import { unique } from '@/utils/array-utils'

/***
 * Exports OpenLayers features to a shapefile and prompts the download of it as a zip file.
 * If multiple geometry types are present, multiple shapefiles will be created within the zip.
 * @param features - the OpenLayers features to export
 * @param fileName - the name of the zip file to be downloaded. The geometry type will be appended to the name in the case of multiple geometry types.
 */
export const exportOpenLayersFeaturesToShapefile = async (features: Array<Feature>, fileName: string) => {
    global.offset = null
    // Get feature properties to populate shapefile dbf.
    const featureData: Array<any> = features.map((feature, featureIndex) => {
        const data = feature.getProperties()
        data.inspire_id = data.tenure?.toLowerCase().includes('freehold') ? data.ogc_fid.toString() : null
        data.ogc_fid = featureIndex
        return data
    })

    // Create a zip file to hold the shapefile files.
    const zip = new JSZip()

    // Get feature geometries to populate one or more shapefiles accordingly.
    const geometryTypes = unique(features.map(feature => feature.getGeometry().getType()))
    geometryTypes.forEach(geometryType => {
        const shapefileTypes = {
            Polygon: { shapefileType: 'POLYGON', label: 'Polygons' },
            MultiPolygon: { shapefileType: 'POLYGON', label: 'Polygons' },
            LineString: { shapefileType: 'POLYLINE', label: 'Lines' },
            MultiLineString: { shapefileType: 'POLYLINE', label: 'Lines' },
            Point: { shapefileType: 'POINT', label: 'Points' },
            MultiPoint: { shapefileType: 'POINT', label: 'Points' },
        }
        // Get feature geometries to populate shapefile shp.
        const featuresWithGeometryType = features.filter(feature => feature.getGeometry().getType() === geometryType)
        let featureGeom:Array<Coordinate[][][]> | Array<Coordinate[][]> | Array<Coordinate[]> | Coordinate[] = []
        if (geometryType === 'Polygon' || geometryType === 'MultiPolygon') {
            featureGeom = featuresWithGeometryType.map((feature: Feature) => (feature.getGeometry() as MultiPolygon).getCoordinates())
        } else if (geometryType === 'LineString' || geometryType === 'MultiLineString') {
            featureGeom = featuresWithGeometryType.map((feature: Feature) => (feature.getGeometry() as LineString).getCoordinates())
        } else if (geometryType === 'Point' || geometryType === 'MultiPoint') {
            featureGeom = featuresWithGeometryType.map((feature: Feature) => (feature.getGeometry() as Point).getFlatCoordinates())
        }

        // Append to the file name a label for the geometry type if there are multiple geometries.
        let fileNameWithGeomType = fileName
        if (geometryTypes.length > 1) {
            fileNameWithGeomType = `${ fileName } - ${ shapefileTypes[geometryType].label }`
        }

        // Write the data to a shapefile .zip.
        writeShapeFile(featureData, shapefileTypes[geometryType].shapefileType, featureGeom, (error:string, files: { shp: any, dbf: any, shx: any }) => {
            if (error) {
                console.error(error)
                return
            }
            zip.file(`${ fileNameWithGeomType }.shp`, files.shp.buffer, { binary: true })
            zip.file(`${ fileNameWithGeomType }.shx`, files.shx.buffer, { binary: true })
            zip.file(`${ fileNameWithGeomType }.dbf`, files.dbf.buffer, { binary: true })

            // Use the ESRI WKT projection string for the British National Grid, store it in the .prj file. See https://epsg.io/27700.
            zip.file(`${ fileNameWithGeomType }.prj`, 'PROJCS["British_National_Grid",GEOGCS["GCS_OSGB_1936",DATUM["D_OSGB_1936",SPHEROID["Airy_1830",6377563.396,299.3249646],EXTENSION["PROJ4_GRIDS","OSTN15_NTv2_OSGBtoETRS.gsb"]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",400000.0],PARAMETER["False_Northing",-100000.0],PARAMETER["Central_Meridian",-2.0],PARAMETER["Scale_Factor",0.9996012717],PARAMETER["Latitude_Of_Origin",49.0],UNIT["Meter",1.0]]')
        })
    })

    // Download the zip.
    const generateOptions: any = {
        compression: 'STORE',
        type: 'blob',
    }
    const blob: Blob = await zip.generateAsync(generateOptions) as Blob
    FileSaver.saveAs(blob, `${ fileName }.zip`)
}

/***
 * Exports OpenLayers features to a GeoJSON file and prompts the download of it.
 * GeoJSON
 * @param features
 * @param fileName
 */
export const exportOpenLayersFeaturesToGeoJSON = async (features: Array<Feature>, fileName: string) => {
    const geoJson:String = new GeoJSON().writeFeatures(features, {
        featureProjection: CoordinateSystemCode.EPSG27700,
        dataProjection: CoordinateSystemCode.EPSG4326,
    })
    const blob = new Blob([geoJson as any], { type: 'application/json;charset=utf-8' })
    FileSaver.saveAs(blob, `${ fileName }.geojson`)
}

/***
 * Converts a shapefile to OpenLayers features.
 * @param file - the file to convert
 * If not provided, all attribute names will be removed to avoid conflicts with internal properties.
 */
export const openLayersFeaturesFromShapefile = async (file:File): Promise<Array<Feature>> => {
    const geoJson = await shp(await file.arrayBuffer()) as shp.FeatureCollectionWithFilename[] as any

    let result = []
    if (geoJson.features) {
        result = new GeoJSON({
            dataProjection: CoordinateSystemCode.EPSG4326,
            featureProjection: CoordinateSystemCode.EPSG27700,
        }).readFeatures(geoJson)
    } else if (geoJson.length) {
        geoJson.forEach(x => {
            result.push(...new GeoJSON({
                dataProjection: CoordinateSystemCode.EPSG4326,
                featureProjection: CoordinateSystemCode.EPSG27700,
            }).readFeatures(x))
        })
    } else {
        console.error('Unexpected GeoJSON output from shp.js')
    }
    return result
}

/***
 * Converts a GeoJSON file to OpenLayers features.
 * @param file
 */
export const openLayersFeaturesFromGeoJSON = async (file:File): Promise<Array<Feature>> => {
    const geoJson = await file.text()
    const result = new GeoJSON({
        dataProjection: CoordinateSystemCode.EPSG4326,
        featureProjection: CoordinateSystemCode.EPSG27700,
    }).readFeatures(geoJson)
    return result
}

/***
 * Converts a KML file to OpenLayers features.
 * @param file
 */
export const openLayersFeaturesFromKML = async (file:File): Promise<Array<Feature>> => {
    const kmlText: string = await file.text()
    return openLayersFeaturesFromKMLText(kmlText)
}

/***
 * Converts a KML string to OpenLayers features.
 * @param file
 */
export const openLayersFeaturesFromKMZ = async (file:File): Promise<Array<Feature>> => {
    // Unzip the KMZ
    const zip: JSZip = new JSZip()
    await zip.loadAsync(file)
    const kmlFile: JSZipObject = zip.file(/\.kml$/i)[0]
    if (!kmlFile) {
        console.error('No KML file found in KMZ')
        return []
    }
    const kmlText: string = await kmlFile.async('text')
    return openLayersFeaturesFromKMLText(kmlText)
}

export const openLayersFeaturesFromKMLText = (text: string): Array<Feature> => {
    const format: KML = new KML()
    return format.readFeatures(text, {
        featureProjection: 'EPSG:27700',
    })
        .filter(feature => Boolean(feature.getGeometry()))
}

export const isSupportedImportFile = (file: File): boolean => {
    const fileExtension = fileExtensionFromFile(file)
    return SKETCH_IMPORT_SUPPORTED_FILE_TYPES.includes(file.type as UploadFileTypes) || SKETCH_IMPORT_SUPPORTED_FILE_EXTENSIONS.includes(fileExtension as UploadFileExtensions)
}
