<template>
    <ow-card class="checkout-item-report-order">
        <section class="checkout-item-report-order__content">
            <div class="checkout-item-report-order__content--title">
                <ow-icon-ow-logo data-test="checkout-item-report-order-icon"
                                 height="35px"
                                 width="35px" />
                <div>
                    <h3 class="content___subheading">
                        {{ item.displayName }}
                    </h3>

                    <div :title="item.selectedTitleNumbers.join(', ')"
                         class="body-copy--greyout checkout-item-report-order__titles">
                        {{ pluralizeTitlesText }}
                        <span class="checkout-item-report-order__titles--numbers">
                            : {{ item.selectedTitleNumbers.join(', ') }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="content__subheading checkout-item-report-order__content--cost">
                {{ item.costText }}
            </div>
        </section>
    </ow-card>
</template>

<script lang="ts">
    import pluralize from 'pluralize'
    import { PropType } from 'vue'

    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import { ICheckoutItemReportOrder } from '@/interfaces/checkout/checkout-item-report-order.interface'

    export default {
        name: 'CheckoutItemReportOrder',

        components: {
            OwCard,
            OwIconOwLogo,
        },

        props: {
            item: {
                type: Object as PropType<ICheckoutItemReportOrder>,
                required: true,
            },
        },

        computed: {
            pluralizeTitlesText(): string {
                return pluralize('title', this.item.selectedTitleNumbers.length, true)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './checkout-item-report-order';
</style>
