<template>
    <div class="d-flex flex-column day-list">
        <day-list-card :selected-title="selectedTitle"
                       :show-view-link="false"
                       :class="{ 'day-list__container--card': showTable }" />

        <div v-if="showTable"
             id="day-list-data"
             class="d-flex pa-4 pb-0 flex-column day-list-data">
            <div class="day-list__table--header-labels-wrapper">
                <div class="day-list__table--header-labels">
                    <div class="label-caps-small">
                        Application ID
                    </div>
                    <div class="label-caps-small ml-4">
                        Type
                    </div>
                    <div class="label-caps-small">
                        Priority Date
                    </div>
                </div>
                <div class="empty-item" />
            </div>

            <v-expansion-panels v-model="activePanel"
                                variant="accordion"
                                multiple
                                class="day-list__table--expansion-panels">
                <v-expansion-panel v-for="(enquiry, index) in daylistApplications"
                                   :key="index"
                                   class=" title-panel-subtitle mb-0 day-list__table--expansion-panel"
                                   data-track="TITLE-DETAILS-PANEL - Expand daylist application"
                                   style="margin-bottom:10px; flex-direction: column;"
                                   xs12>
                    <v-expansion-panel-title expand-icon=""
                                             collapse-icon="">
                        <div class="day-list__table--expansion-panel-header">
                            <div v-if="enquiry.applicationReference"
                                 class="day-list__table--expansion-panel-header--id">
                                <strong>{{ enquiry.applicationReference }}
                                    <v-icon>{{ activePanel.includes(index) ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon></strong>
                            </div>
                            <div v-if="enquiry.applicationType"
                                 class="daylist-enquiry__application-type ml-4">
                                {{ enquiry.applicationType }}
                            </div>
                            <div v-if="enquiry.priorityDate">
                                {{ enquiry.priorityDate }}
                            </div>
                        </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div v-if="documentSource !== DOCUMENT_SOURCE.SCOTLAND"
                             class="d-flex flex-column day-list__table--expanded-content">
                            <div v-for="item in getEnquiryItems(enquiry)"
                                 :key="item.title"
                                 class="day-list__table--expanded-content-item">
                                <span class="label-caps-small">{{ item.title }}</span>
                                <p class="ml-4">
                                    {{ item.value }}
                                </p>
                            </div>
                        </div>
                        <div v-else
                             class="flex-column day-list__table--expanded-content-warning">
                            <span class="body-copy mb-3">{{ $t('titlePanel.cards.daylist.scottishOrderingWarning') }}</span>
                            <div>
                                <ow-button-ds is-secondary
                                              small
                                              data-track="TITLE PANEL - Scottish Pending Application registered interest">
                                    Register Interest
                                </ow-button-ds>
                            </div>
                        </div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <ow-icon-renderer hide-attribution-text
                              no-padding
                              :record-source="selectedTitle?.record?.source" />
        </div>
    </div>
</template>

<script lang="ts">
    import {mapActions,
            mapState} from 'vuex'

    import OwIconRenderer from "@/components/core/icons/ow-icon-renderer.vue"
    import OwButtonDs from "@/components/core/ow-button-ds.vue"
    import DayListCard from '@/components/title-panel/tabs/day-list-card.vue'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { IState } from '@/interfaces/store/state.interface'
    import { LOGGING_HEAP_TRACK_EVENT } from "@/store/mutation-types"
    import { isNullOrEmpty } from '@/utils/array-utils'
    import {isNullOrWhitespace} from "@/utils/string-utils"


    export default {
        name: 'DaylistEnquiry',

        components: {
            OwButtonDs,
            DayListCard,
            OwIconRenderer,
        },

        data() {
            const hmlrFields = [
                { key: 'priorityDate', title: 'Priority Date' },
                { key: 'priorityTime', title: 'Priority Time' },
                { key: 'lodgedBy', title: 'Logged By' },
                { key: 'applicant', title: 'Applicant' },
                { key: 'propertyDescription', title: 'Property Description' },
                { key: 'customerReference', title: 'Customer Reference' },
                { key: 'depositReason', title: 'Deposit Reason' },
                { key: 'searchCertificateNumber', title: 'Search Certificate Number' },
                { key: 'searchInterest', title: 'Search Interest' },
                { key: 'oLALodgedFor', title: 'OLA Logged For' },
            ]
            // TODO: Integrate backend properties
            const scotlandFields = [
                { key: 'mockField', title: 'Mock until integration' },
            ]
            return {
                activePanel: [],
                hmlrFields,
                scotlandFields,
            }
        },

        computed: {
            DOCUMENT_SOURCE() {
                return DOCUMENT_SOURCE
            },
            ...mapState({
                selectedTitle: (state: IState) => state.title?.selectedTitle,
            }),

            daylistApplications(): any[] {
                if (this.selectedTitle?.daylist?.application) {
                    return this.selectedTitle.daylist.data
                }

                return []
            },

            showTable() {
                return !this.selectedTitle?.loadingDaylist &&
                    !isNullOrEmpty(this.selectedTitle.daylist.data)
            },

            documentSource() {
                return this.selectedTitle?.record?.source
            },
        },

        methods: {
            getEnquiryItems(enquiry: any): any[] {
                switch (this.documentSource) {
                    case DOCUMENT_SOURCE.SCOTLAND:
                        return this.getScotlandEnquiryItems(enquiry)
                    default:
                        return this.getHmlrEnquiryItems(enquiry)
                }
            },

            getScotlandEnquiryItems(enquiry: any): any[] {
                return this.scotlandFields
                    .filter((field: any) => !isNullOrWhitespace(enquiry[field.key]) && enquiry.hasOwnProperty(field.key))
                    .map((field: any) => ({
                        title: field.title,
                        value: enquiry[field.key],
                    }))
            },

            getHmlrEnquiryItems(enquiry: any): any[] {
                const rows = this.hmlrFields
                    .filter((field: any) => !isNullOrWhitespace(enquiry[field.key]) && enquiry.hasOwnProperty(field.key))
                    .map((field: any) => ({
                        title: field.title,
                        value: enquiry[field.key],
                    }))
                // NOTE: Treat the only deep property differently
                if (enquiry.applicationProgress && enquiry.applicationProgress.description) {
                    rows.push({
                        title: 'Application Progress - Description',
                        value: enquiry.applicationProgress.description,
                    })
                }
                return rows
            },
        },
    }
</script>

<style lang="scss">
    @import './daylist-enquiry';
</style>
