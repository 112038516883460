import OrderDetailItemModel from "@/components/matter-searches/models/OrderDetailItemModel"

export const documentMarkup = (orderDetail: OrderDetailItemModel) => {
    if (!orderDetail.availableDocuments || orderDetail.availableDocuments.length === 1) {
        return ''
    }
    const itemMarkup = orderDetail.availableDocuments.sort().map((document) => {
        return /*html*/`
            <a class="caption-highlight ml-4 document-link" data-test="document-${ document.toLowerCase() }" data-id="${ orderDetail.id }" data-document-id="${ document }">${ document }</a>
        `
    }).join('')

    return /*html*/`
        <div class="d-flex flex-column mb-2" data-test="documents-${ orderDetail.productName.toLowerCase() }">
            ${ itemMarkup }
        </div
    `

}
