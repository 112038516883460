import WalkthroughsApi from '@/api/walkthroughs.api'
import { getBoundaryDataAttribution } from '@/store/modules/map/layers/hmlr'
import {
    MATTER_MUTATE_WALKTHROUGH_ID,
    MATTER_UPDATE_CURRENT_MATTER,
} from '@/store/modules/matter/types'
import {
    NPS_GET_FEATURES_BY_TITLE_NUMBERS,
    NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
} from '@/store/modules/nps/types'
import {
    WALKTHROUGH_ADD_NEW_PAGE,
    WALKTHROUGH_GET_BY_MATTER_ID,
    WALKTHROUGH_GET_FIRST_PAGE,
    WALKTHROUGH_LOAD_BY_ID,
    WALKTHROUGH_MUTATE_CONTENT_ITEM_BOUNDARY_AVAILABLE,
    WALKTHROUGH_MUTATE_FEATURE_TRACKING_REVISION_COUNTER,
    WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT,
    WALKTHROUGH_MUTATE_LOADING,
    WALKTHROUGH_MUTATE_PAGES,
    WALKTHROUGH_MUTATE_SELECTED_PAGE,
    WALKTHROUGH_MUTATE_WALKTHROUGH,
    WALKTHROUGH_RESET,
    WALKTHROUGH_SAVE,
    WALKTHROUGH_SET_PAGES,
    WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER,
} from '@/store/modules/walkthrough/types'
import { LOGGING_LOG_AXIOS_ERROR } from '@/store/mutation-types'

import WalkthroughUtils from './walkthrough-utils'

export default {

    async [WALKTHROUGH_LOAD_BY_ID]({
        state,
        rootState,
        commit,
        dispatch,
        getters,
    }, walkthroughId) {
        commit(WALKTHROUGH_MUTATE_LOADING, true)

        // Clear any current walkthrough state.
        dispatch(WALKTHROUGH_RESET)

        let result = null
        if (walkthroughId === null) {
            // Create a new walkthrough
            result = {
                description: null,
                isEnabled: true,
                name: null,
                pages: [{
                    name: null,
                    description: null,
                    boundingBox: null,
                    pageNumber: 1,
                    pageTitles: [],
                    pageSketches: [],
                }],
            }
        } else {
            // Get the walkthroughs available for the matter.
            const response = await WalkthroughsApi.getWalkthroughById(walkthroughId)
            result = response.data
        }

        // Determine the map to display walkthrough content on
        state.targetMap = rootState.map.map

        // Prepare pages for use in the walkthrough.
        result.pages.forEach(page => {
            // Merge sketches and titles into a common array for each page,
            // they are treated the same on the front-end, but not by the API.
            page.contentItems = page.pageTitles.concat(page.pageSketches)
            page.contentItems.forEach(ci => {
                ci.boundaryAvailable = false
            })
            delete page.pageTitles
            delete page.pageSketches

            // Create a layer to hold the content items - titles, sketches.
            page.layer = WalkthroughUtils.getNewPageLayer(
                page,
                state.targetMap,
                () => commit(WALKTHROUGH_MUTATE_FEATURE_TRACKING_REVISION_COUNTER),
                getBoundaryDataAttribution(rootState.config.settings.monthlyHMLRDataVersion))
        })

        // Update the walkthrough metadata in the state.
        commit(WALKTHROUGH_MUTATE_WALKTHROUGH, result)

        // Select the first page.
        const firstPage = getters[WALKTHROUGH_GET_FIRST_PAGE]
        commit(WALKTHROUGH_MUTATE_SELECTED_PAGE, firstPage)
        await dispatch(WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER)

        commit(WALKTHROUGH_MUTATE_LOADING, false)

        return result
    },

    [WALKTHROUGH_RESET]({
        state,
        rootState,
        commit,
    }) {
        // Dispose of the titles/sketches layers.
        if (state.walkthrough?.pages) {
            state.walkthrough.pages.forEach(page => {
                if (page.layer) {
                    rootState.map.map.removeLayer(page.layer)
                }
                page.layer.dispose()
            })
        }
        commit(WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT, false)
        commit(WALKTHROUGH_MUTATE_WALKTHROUGH, null)
    },

    [WALKTHROUGH_ADD_NEW_PAGE]({
        state,
        rootState,
        commit,
    }, {
        newPage,
        afterPage,
    }) {
        // Initialise a layer on the new page
        newPage.layer = WalkthroughUtils.getNewPageLayer(newPage,
            state.targetMap,
            () => commit(WALKTHROUGH_MUTATE_FEATURE_TRACKING_REVISION_COUNTER),
            getBoundaryDataAttribution(rootState.config.settings.monthlyHMLRDataVersion))

        const pageIndex = state.walkthrough.pages.indexOf(afterPage)
        const pages = [...state.walkthrough.pages]
        pages.splice(pageIndex + 1, 0, newPage)
        pages.forEach((page, index) => {
            page.pageNumber = index + 1
        })

        state.walkthrough.pages = pages
    },

    /**
     * Update the selected page layer to add/remove any features based on the
     * content items for the page.
     */
    async [WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER]({
        state,
        commit,
        dispatch,
        getters,
    }) {
        // Debounce the layer update logic so as to not worry about calling it
        // excessively from multiple places.

        const titleNumbersInPage = state.selectedPage.contentItems
            .filter(item => item.titleNumber !== undefined)
            .map(item => item.titleNumber)

        // Determine which title numbers already have boundaries loaded and
        // visible on the layer...
        const titleNumbersWithFeatures = []
        state.selectedPage.layer.getSource().getFeatures().forEach(feature => {
            const titleNumber = feature.get('title_no')
            titleNumbersWithFeatures.push(titleNumber)

            // ...and remove features no longer corresponding to title numbers
            // in page.
            if (!titleNumbersInPage.includes(titleNumber)) {
                state.selectedPage.layer.getSource().removeFeature(feature)
                titleNumbersWithFeatures.push(titleNumber)
            }
        })

        // Get title boundaries not yet on the layer.
        const titleNumbersWithoutFeatures = titleNumbersInPage.filter(titleNumber => !titleNumbersWithFeatures.includes(titleNumber))

        await dispatch(NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS, titleNumbersWithoutFeatures)
        const newFeatures = getters[NPS_GET_FEATURES_BY_TITLE_NUMBERS](titleNumbersWithoutFeatures)

        if (newFeatures.length > 0) {
            // Add features to the layer, we don't need to track this change.
            // Use a clone of each feature so that they can remain independent
            // of one another if added on multiple pages.
            state.selectedPage.layer.getSource()
                .addFeatures(newFeatures.map(feature => {
                    feature.setStyle(null)
                    return feature
                }))
        }
        state.selectedPage.layer.getSource().changed()

        // Determine which titles do not have boundary data available and add a prop to indicate as much.
        const addedFeaturesForTitleNumbers = newFeatures.map(t => t.get('title_no'))
        const newTitleNumbersWithoutFeatures = titleNumbersWithoutFeatures.filter(t => !addedFeaturesForTitleNumbers.includes(t))

        state.selectedPage.contentItems
            .forEach(item => commit(WALKTHROUGH_MUTATE_CONTENT_ITEM_BOUNDARY_AVAILABLE, {
                item,
                val: !newTitleNumbersWithoutFeatures.includes(item.titleNumber),
            }))
    },

    /**
     * Takes the current walkthrough and saves the whole thing to the server,
     * assumes the only part of the server response needed is the walkthrough
     * Id in the case of creating a new one, so as to be able to update it.
     */
    async [WALKTHROUGH_SAVE]({
        commit,
        state,
        dispatch,
        rootState,
    }) {
        // Create the request object
        const request = {
            matterId: rootState.matter.currentMatter.id,
            name: null, // We don't allow the user to name walkthroughs yet...
            description: null, // nor provide a description.
            isEnabled: true,
            pages: state.walkthrough.pages
                .map(page => {
                    return {
                        id: page.id ?? null,
                        pageNumber: page.pageNumber,
                        name: page.name !== null ? WalkthroughUtils.getNameForPage(page) : null,
                        description: page.description,
                        boundingBox: null, // currently can not be set by the
                        // user, is calculated by the client
                        // on the fly.
                        pageTitles: page.contentItems
                            .filter(item => item.titleNumber !== undefined)
                            .map((pageTitle, pageTitleIndex) => {
                                return {
                                    titleNumber: pageTitle.titleNumber,
                                    label: pageTitle.label,
                                    comment: pageTitle.comment,
                                    sortOrder: pageTitleIndex,
                                    walkthroughPageId: page.id ?? null,
                                    colour: pageTitle.colour,
                                    fill: pageTitle.fill,
                                    fillOpacity: pageTitle.fillOpacity,
                                    strokeWidth: pageTitle.strokeWidth,
                                    dashed: pageTitle.dashed,
                                    hatch: pageTitle.hatch,
                                    showTitleNumber: pageTitle.showTitleNumber,
                                    show: pageTitle.show,
                                }
                            }) ?? [],
                        pageSketches: [], // not yet implemented.
                    }
                }),
        }

        // If it's a new walkthrough, create it, otherwise update it.
        if (state.walkthrough.id === undefined) {
            // Send the request
            const response = await WalkthroughsApi.saveWalkthrough(request)

            if (response?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            } else {
                // Use the response object to set the new walkthrough id
                commit(WALKTHROUGH_MUTATE_WALKTHROUGH, {
                    ...state.walkthrough, ...{ id: response.data.walkthroughId },
                })
                dispatch(MATTER_UPDATE_CURRENT_MATTER, { walkthroughId: response.data.walkthroughId })
            }
        } else {
            request.id = state.walkthrough.id

            // Send the request
            const response = await WalkthroughsApi.updateWalkthrough(request)
            if (response?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            } else {
                // Re-apply the current walkthrough - assumes we don't need any
                // generated keys (e.g. walkthrough page ID) for now.
                // NOTE: If we send the mutate it breaks the walkthrough map with OL 9.1
                commit(WALKTHROUGH_MUTATE_WALKTHROUGH, state.walkthrough)
            }
        }
    },

    [WALKTHROUGH_SET_PAGES]({ state, rootState, commit }, pages) {
        pages.forEach((page, index) => {
            page.pageNumber = index + 1
            page.layer = WalkthroughUtils.getNewPageLayer(
                page,
                state.targetMap,
                () => state.featureChangeTrackingRevisionCounter++,
                getBoundaryDataAttribution(rootState.config.settings.monthlyHMLRDataVersion))
        })
        commit(WALKTHROUGH_MUTATE_PAGES, pages)
    },

    async [WALKTHROUGH_GET_BY_MATTER_ID]({ commit, dispatch }, matterId) {
        try {
            const walkthroughIdResponse = await WalkthroughsApi.getWalkthroughIdByMatterId(matterId)
            commit(MATTER_MUTATE_WALKTHROUGH_ID, walkthroughIdResponse.data)
            await dispatch(WALKTHROUGH_LOAD_BY_ID, walkthroughIdResponse.data)
        } catch (err) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, err)
        }
    },
}
