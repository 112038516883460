import {
    INearbyMatterTitle,
} from '@/components/title-panel/v2/find-nearby/implementations/matter-titles/nearby-matter-title.interface'

export class FindNearbyDefaults {
    public static readonly BUFFER_COLOUR = '#0d437c'
    public static readonly BUFFER_DEFAULT_RADIUS = 200
    public static readonly BUFFER_DEFAULT_MAX_RADIUS = 1000
    public static readonly BUFFER_DEFAULT_MIN_RADIUS = 0
    public static readonly BUFFER_DEFAULT_STEP = 5
    public static readonly BUFFER_DEFAULT_VIEW_PADDING = [20, 20, 20, 20]
    public static readonly RESULTS_DEFAULT_STROKE_COLOUR = '#838b91'
    public static readonly RESULTS_DEFAULT_FILL_COLOUR = 'rgba(131, 139, 145, 0.1)'
    public static readonly HIGHLIGHT_DEFAULT_STROKE_COLOUR = '#1d73ad'
    public static readonly HIGHLIGHT_DEFAULT_FILL_COLOUR = 'rgba(29, 115, 173, 0.2)'
    public static readonly HIGHLIGHT_TEXT_STROKE_COLOUR = '#1d73ad'
    public static readonly HIGHLIGHT_TEXT_FILL_COLOUR = '#FFFFFF'
    public static readonly BUFFER_DEFAULT_MAX_ZOOM = 19

    public static getTitleDisplayText = (item: INearbyMatterTitle): string => {
        return item.titleLabel ? `${ item.titleLabel } (${ item.titleNumber })` : item.titleNumber
    }
}
