<template>
    <div class="title-alerts-preferences d-flex flex-column gr-4">
        <div v-if="!hideHeader"
             class="monitoring-preferences__instructions">
            <p v-t="'assetMonitoring.preferences.instructions'"
               class="body-regular" />
        </div>

        <section class="monitoring-preferences__content">
            <preference-presets-card :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     data-track="Asset Monitoring: Orbital recommended preference card"
                                     data-test="orbital-recommended-preference-card"
                                     @update="updateSettings" />

            <preference-details-card :is-loading="isUpdating"
                                     :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     :preference-properties="preferenceProperties"
                                     :title="t('assetMonitoring.preferences.cards.preferenceDetails.cardTitle')"
                                     :description="t('assetMonitoring.preferences.cards.preferenceDetails.cardDescription')"
                                     @update="updateSettings" />
        </section>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'

    import { INotificationSettings } from '@/api/notifications.api'
    import PreferenceDetailsCard from "@/components/asset-monitoring/monitoring-preferences/preference-details-card.vue"
    import {IPreferenceDetailsModel} from "@/components/asset-monitoring/monitoring-preferences/preference-details-model-interface"
    import PreferencePresetsCard from "@/components/asset-monitoring/monitoring-preferences/preference-presets-card.vue"
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'

    const { t } = useI18n()
    const route = useRoute()
    const store = useAssetMonitoringStore()
    const currentMatterId = computed<number>(() => Number(route.params.matterId))
    const settings = computed<INotificationSettings>(() => store.notificationSettings)
    const isUpdating = computed<boolean>(() => store.isUpdatingSettings)

    defineProps<{
        hideHeader?: boolean
    }>()

    onMounted(() => {
        if (!store.areNotificationSettingsLoaded) {
            store.getNotificationSettings(currentMatterId.value)
        }
    })

    const preferenceProperties: Array<IPreferenceDetailsModel> = [
        {
            value: 'trackDaylist',
        },
        {
            value: 'trackOwnership',
        },
        {
            value: 'trackBoundary',
        },
        {
            value: 'trackCompaniesHouse',
        },
    ]

    const updateSettings = async (request: INotificationSettings) => {
        await store.updateNotificationSettings(currentMatterId.value, request)
    }
</script>

<style lang="scss" scoped>
    @import './title-alerts-preferences';
</style>
