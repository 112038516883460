import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
    Text,
} from 'ol/style'
import Feature from 'ol/Feature'

import { isNullOrWhitespace } from '@/utils/string-utils'
import { hexToRGBArray } from '@/utils/colour-utils'
import { Colors } from '@/enums/colors.enum'
import { hatchStyleFunction } from '@/utils/map-utils'

export abstract class TitleBoundaryLayerSettings {
    // When the user pans the map and new title points appear on screen, we will load the boundaries if there are
    // this many or fewer.
    static readonly MAX_BOUNDARIES_TO_LOAD_AT_ONCE: number = 200

    // What should be considered a small number of total titles such that we can do away with optimisations and just load all the boundaries?
    static SMALL_NUMBER_OF_TOTAL_TITLES: number = 400

    // Increased number of total titles to load when the user is zoomed out. Tied to the Increased point rendering limit feature flag.
    static readonly INCREASED_NUMBER_OF_TOTAL_TITLES: number = 900

    // What should be considered a small enough number of titles to be included in a snapshot without restricting the pan-able extent?
    static readonly SMALL_NUMBER_OF_TOTAL_TITLES_FOR_SNAPSHOT: number = 100

    // What should be considered a small number of total titles such that we can do away with optimisations and just load everything?
    static readonly ZOOM_LEVEL_BEYOND_WHICH_BOUNDARIES_SHOULD_ALWAYS_BE_SHOWN: number = 16

    // What should be considered a small number of total titles such that we can do away with optimisations and just load everything?
    static readonly ZOOM_LEVEL_ABOVE_WHICH_BOUNDARIES_CAN_BE_HIDDEN_FOR_PERFORMANCE: number = 15

    // TBD if this is needed - current implementation shows points for titles not set to visible, for which a colour may not be set.
    // If points are only shown for visible titles, which should already have a colour set, then this is not needed.
    static readonly DEFAULT_POINT_COLOUR = '#f44e3b'

    // Helper method to return the title number for a feature.
    static getTitleNumberFromFeature = (feature: Feature, key = 'titleNumber'): string => {
        return feature.get(key)
    }

    // Returns a highlight style for a title, taking into account the label text that may be applied.
    static getHighlightStyle = (titleData: any, includeMarker = false, titleOffset = 0): Style => {
        const result = TitleBoundaryLayerSettings.highlightStyle(titleData)
        let labelText = null
        if (!isNullOrWhitespace(titleData.label)) {
            labelText = titleData.label
            if (titleData.showTitleNumber === true) {
                labelText = `${ labelText } (${ titleData.titleNumber })`
            }
        } else if (titleData.showTitleNumber === true) {
            labelText = titleData.titleNumber
        }
        result.getText().setText(labelText)
        result.getText().setOffsetY(titleOffset)

        if (includeMarker) {
            result.setImage(TitleBoundaryLayerSettings.getHighlightMarker())
        }

        return result
    }

    // The base highlight style for a title.
    private static highlightStyle = (titleData: any) => new Style({
        zIndex: 9999,
        fill: new Fill({
            color: titleData?.hatch ? hatchStyleFunction(titleData.colour) : titleData?.fillOpacity ? [...hexToRGBArray(titleData.colour), titleData?.fillOpacity] : 'transparent',
        }),
        stroke: new Stroke({
            color: hexToRGBArray(Colors.Lights0),
            width: 5,
        }),
        text: new Text({
            font: '14px \'Roboto\', Helvetica, Arial, sans-serif',
            overflow: true,
            fill: new Fill({
                color: hexToRGBArray(Colors.Darks700),
            }),
            stroke: new Stroke({
                color: hexToRGBArray(Colors.Lights0),
                width: 8,
            }),
        }),
    })

    private static getHighlightMarker = () => new CircleStyle({
        radius: 8,
        fill: new Fill({
            color: hexToRGBArray(Colors.Lights0),
        }),
        stroke: new Stroke({
            color: hexToRGBArray(Colors.Darks700),
            width: 1,
        }),
    })
}
