/* eslint-disable */

/** @typedef {Object} GeoImageOptions
 * @property {url} url url of the static image
 * @property {image} image the static image, if not provided, use url to load an image
 * @property {ol.Coordinate} imageCenter coordinate of the center of the image
 * @property {ol.Size|number} imageScale [scalex, scaley] of the image
 * @property {number} imageRotate angle of the image in radian, default 0
 * @property {ol.Extent} imageCrop of the image to be show (in the image) default: [0,0,imageWidth,imageHeight]
 * @property {Array.<ol.Coordinate>} imageMask linestring to mask the image on the map
 */

import ol_source_ImageCanvas from 'ol/source/ImageCanvas.js'
import { boundingExtent as ol_extent_boundingExtent } from 'ol/extent.js'
import { calculateCentrePoint, rotateExtent, findMaximumExtentOfRotatedRectangle, rotatePoint } from '@/utils/map-utils';
import { degreesToRadians } from '@turf/helpers';

/** Layer source with georeferencement to place it on a map
 * @constructor
 * @extends {ol_source_ImageCanvas}
 * @param {GeoImageOptions} options
 */
export class GeoImageSource extends ol_source_ImageCanvas {
    constructor(opt_options) {
        const options = {
            attributions: opt_options.attributions,
            logo: opt_options.logo,
            projection: opt_options.projection,
        }
        // Draw image on canvas
        options.canvasFunction = function (extent, resolution, pixelRatio, size) {
            return this.calculateImage(extent, resolution, pixelRatio, size)
        }

        super(options)
        this.imageExtent = opt_options.imageExtent

        // Load Image
        this._image = (opt_options.image ? opt_options.image : new Image())
        this._image.crossOrigin = opt_options.crossOrigin ?? 'anonymous' // 'anonymous';

        // Show image on load
        this._image.onload = function () {
            this.setImageSize()
            this.changed()
        }.bind(this)
        if (!opt_options.image) this._image.src = opt_options.url

        // Rotation of the image
        this.rotate = opt_options.imageRotate ? opt_options.imageRotate : 0

        // Calculate extent on change
        this.on('change', function () {
            this.set('extent', this.calculateExtent())
        }.bind(this))
    }

    /** calculate image at extent / resolution
   * @param {ol/extent/Extent} extent
   * @param {number} resolution
   * @param {number} pixelRatio
   * @param {ol/size/Size} size
   * @return {HTMLCanvasElement}
   */
    calculateImage(extent, resolution, pixelRatio, size) {
        if (!this.imageExtent)
            return

        const canvas = document.createElement('canvas')
        canvas.width = size[0]
        canvas.height = size[1]
        const ctx = canvas.getContext('2d')

        if (!this._imageSize) {
            return canvas
        }

        const imageExtentWidth = this.imageExtent[2] - this.imageExtent[0];
        const imageExtentHeight = this.imageExtent[3] - this.imageExtent[1];
        const scaleX = imageExtentWidth / resolution * pixelRatio;
        const scaleY = imageExtentHeight / resolution * pixelRatio;

        const offsetX = (this.imageExtent[0] - extent[0]) / resolution * pixelRatio;
        const offsetY = (extent[3] - this.imageExtent[3]) / resolution * pixelRatio;

        if (this.cropExtent && this.cropExtent.toString() !== this.imageExtent.toString()) {

            const cropCenterX = (this.cropExtent[0] + this.cropExtent[2]) / 2;
            const cropCenterY = (this.cropExtent[1] + this.cropExtent[3]) / 2;

            // Calculate the distance from the imageExtent corner to the crop center
            const canvasCropCentreX = (cropCenterX - this.imageExtent[0]) / resolution * pixelRatio;
            const canvasCropCentreY = (this.imageExtent[3] - cropCenterY) / resolution * pixelRatio;

            const cropCentrePoint = [offsetX + canvasCropCentreX, offsetY + canvasCropCentreY]

            const topLeftOfImage = [offsetX, offsetY]

            // Rotate the crop centre around image extent, so we get the correct fixed rotation point
            const rotatedCentreOfCrop = rotatePoint(cropCentrePoint, [offsetX + (scaleX / 2), offsetY + (scaleY / 2)], this.cropRotation)

            // Translate to this point
            ctx.translate(rotatedCentreOfCrop[0], rotatedCentreOfCrop[1])

            // Rotate around this point
            ctx.rotate(this.rotate)

            // Translate back to original top left of image,
            var distanceToImageLeftFromCropCentre = topLeftOfImage[0] - cropCentrePoint[0];
            var distanceToImageTopFromCropCentre = topLeftOfImage[1] - cropCentrePoint[1];

            ctx.drawImage(this._image, distanceToImageLeftFromCropCentre, distanceToImageTopFromCropCentre, scaleX, scaleY)
        }
        else if (this.rotate) {
            // Move to the center of where the image will be drawn
            ctx.translate(offsetX + scaleX / 2, offsetY + scaleY / 2);
            // Rotate around this point
            ctx.rotate(this.rotate);
            // Draw the image centered on this point
            ctx.drawImage(this._image, -scaleX / 2, -scaleY / 2, scaleX, scaleY);
        }
        else {
            ctx.drawImage(this._image, offsetX, offsetY, scaleX, scaleY);
        }

        return canvas;
    }

    // Getter and Setter for imageExtent
    getImageExtent() {
        return this.imageExtent;
    }

    setImageExtent(extent) {
        this.imageExtent = extent;
        this.changed();
    }

    setCropExtent(extent, cropRotationAngle) {
        this.cropExtent = extent;
        this.cropRotation = degreesToRadians(cropRotationAngle)
        this.useCropExtent = true
        this.changed();
    }

    setUseCropExtent(useCropExtent) {
        this.useCropExtent = useCropExtent
        this.changed();
    }

    /**
   * Get image rotation.
   * @return {Number} rotation in radian.
   * @api stable
   */
    getRotation() {
        return this.rotate
    }

    /**
   * Set image rotation.
   * @param {Number} rotation in radian.
   * @api stable
   */
    setRotation(angle, rotateCentre) {
        this.rotate = angle
        this.rotateCentre = rotateCentre
        this.changed()
    }

    /**
   * Get the image.
   * @api stable
   */
    getGeoImage() {
        return this._image
    }

    /**
    * Set image size.
    */
    setImageSize() {
        const imageSize = [0, 0, this._image.naturalWidth, this._image.naturalHeight]
        this._imageSize = [imageSize[2] - imageSize[0], imageSize[3] - imageSize[1]]
        this.changed()
    }

    /** Get the extent of the source.
   * @param {module:ol/extent~Extent} extent If provided, no new extent will be created. Instead, that extent's coordinates will be overwritten.
   * @return {ol.extent}
   */
    getExtent(opt_extent) {
        let ext = this.get('extent')
        if (!ext) {
            ext = this.calculateExtent()
        }
        if (opt_extent) {
            for (let i = 0; i < opt_extent.length; i++) {
                opt_extent[i] = ext[i]
            }
        }
        return ext
    }

    getOriginalExtent() {
        return this.imageExtent
    }

    /** Calculate the extent of the source image.
   * @return {ol.extent}
   */
    calculateExtent() {
        if (this.cropExtent
            && this.cropExtent.toString() !== this.imageExtent.toString()
            && this.useCropExtent) {
            let initialPosition = findMaximumExtentOfRotatedRectangle(this.cropExtent, calculateCentrePoint(this.imageExtent), -this.cropRotation)
            return rotateExtent(initialPosition, this.cropRotation - this.getRotation());
        }

        return rotateExtent(this.imageExtent, this.getRotation());
    }
}
