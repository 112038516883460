import {
    Model,
    ModelFieldConfig,
} from '@bryntum/grid'

class ProductItemModel extends Model {
    static get fields(): Partial<ModelFieldConfig>[] {
        return [
            { name: 'name', type: 'string' },
            { name: 'selected', type: 'boolean', defaultValue: false },
            { name: 'productId', type: 'string' },
            { name: 'description', type: 'string' },
            { name: 'productSupplierType', type: 'string' },
            { name: 'categoryIds', type: 'array' },
            { name: 'hazards', type: 'array' },
        ]
    }

    get name(): string {
        return this.get('name')
    }

    set name(value: string) {
        this.set('name', value)
    }

    get selected(): boolean {
        return this.get('selected')
    }

    set selected(value: boolean) {
        this.set('selected', value)
    }

    get productId(): string {
        return this.get('productId')
    }

    set productId(value: string) {
        this.set('productId', value)
    }

    get description(): string {
        return this.get('description')
    }

    set description(value: string) {
        this.set('description', value)
    }

    get productSupplierType(): string {
        return this.get('productSupplierType')
    }

    set productSupplierType(value: string) {
        this.set('productSupplierType', value)
    }

    get categoryIds(): string[] {
        return this.get('categoryIds')
    }

    set categoryIds(value: string[]) {
        this.set('categoryIds', value)
    }

    get hazards(): string[] {
        return this.get('hazards')
    }

    set hazards(value: string[]) {
        this.set('hazards', value)
    }
}

export default ProductItemModel
