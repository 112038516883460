// Getters
export const GET_EXPORT_OPTIONS = 'GET_EXPORT_OPTIONS'
export const GET_EXPORT_OPTIONS_LOADING = 'GET_EXPORT_OPTIONS_LOADING'
export const GET_SELECTED_EXPORT_OPTION = 'GET_SELECTED_EXPORT_OPTION'
export const GET_TITLE_AVAILABILITY = 'GET_TITLE_AVAILABILITY'
export const GET_TITLE_AVAILABILITY_LOADING = 'GET_TITLE_AVAILABILITY_LOADING'

// Mutations
export const MUTATE_EXPORT_OPTIONS = 'MUTATE_EXPORT_OPTIONS'
export const MUTATE_EXPORT_OPTIONS_LOADING = 'LOAD_EXPORT_OPTIONS'
export const MUTATE_SELECTED_EXPORT_OPTION = 'MUTATE_SELECTED_EXPORT_OPTION'
export const MUTATE_SELECTED_TITLES = 'MUTATE_SELECTED_TITLES'
export const MUTATE_TITLE_AVAILABILITY = 'MUTATE_TITLE_AVAILABILITY'
export const MUTATE_TITLE_AVAILABILITY_LOADING = 'LOAD_TITLE_AVAILABILITY'

// Actions
export const LOAD_EXPORT_OPTIONS = 'LOAD_EXPORT_OPTIONS'
export const LOAD_TITLE_AVAILABILITY = 'LOAD_TITLE_AVAILABILITY'
