<template>
    <div class="ow-uploaded-document-warning caption-regular">
        <span v-t="'titlePanel.uploadedDocumentWarning'" />
        <router-link v-if="matterId && titleNumber"
                     v-t="'action.viewFile'"
                     :to="{
                         name: Route.ReviewAssistant,
                         params: {
                             matterId,
                             titleNumber,
                         }
                     }" />
    </div>
</template>

<script lang="ts">
    import { Route } from '@/enums/route.enum'

    export default {
        name: 'OwUploadedDocumentWarning',

        props: {
            matterId: {
                type: Number,
                required: false,
            },
            titleNumber: {
                type: String,
                required: false,
            },
        },

        data() {
            return {
                Route,
            }
        },
    }
</script>

<style lang="scss">
    @import './ow-uploaded-document-warning.scss';
</style>
