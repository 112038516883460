import { createI18n } from 'vue-i18n'

import en from '@/locales/en.js'

const i18n = createI18n({
    allowComposition: true,
    // @ts-ignore
    locale: import.meta.env.VITE_APP_I18N_LOCALE || 'en',
    legacy: false,
    globalInjection: true,
    warnHtmlMessage: false,
    // @ts-ignore
    fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: { en },
})
export default i18n
