import {
    IDocLibrarySearchesRow,
    ISearchesDocument,
} from '@/interfaces/searches-document.interface'
import { dynamicSort } from '@/utils/array-utils'

export const mapDocumentsToGroupedStore = (documents: ISearchesDocument[]): IDocLibrarySearchesRow[] => {
    type GroupKey = { product: string; location: string };

    // First, we group the documents
    const groups = documents.reduce<Map<GroupKey, ISearchesDocument[]>>(
        (acc, doc) => {
            const key: GroupKey = { product: doc.productName, location: doc.location }
            const keyString = JSON.stringify(key)

            const group = acc.get(keyString)
            if (group) {
                group.push(doc)
            } else {
                acc.set(keyString, [doc])
            }

            return acc
        },
        new Map(),
    )

    // Then, we map each group into an IDocLibrarySearchesRow
    const rows = Array.from(groups, ([keyString, groupDocuments]) => {
        const key = JSON.parse(keyString) as GroupKey

        const sortedDates = groupDocuments
            .map(doc => new Date(doc.createdOn))
            .sort((a, b) => b.getTime() - a.getTime())

        const row: IDocLibrarySearchesRow = {
            product: key.product,
            location: key.location,
            documentCount: groupDocuments.length,
            lastUpdated: sortedDates[0],
            documents: groupDocuments,
        }

        return row
    })

    return rows.sort(dynamicSort('product'))
}
