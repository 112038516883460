<template>
    <teleport to="body">
        <ow-modal v-model="show"
                  content-class="sketches-setting-modal"
                  :title="$t('sketches.settings')"
                  :actions-padding="false">
            <div class="sketches-setting-modal__content">
                <div class="d-flex flex-row align-center justify-space-between sketches-setting-modal__sketches-on-map">
                    <i18n-t keypath="sketches.displaySketches"
                            class="caption-regular"
                            scope="global"
                            tag="label"
                            for="sketches.displaySketches">
                        <template #link>
                            <router-link v-slot="{href}"
                                         :custom="true"
                                         class="caption-regular"
                                         :for="$t('sketches.displaySketches')"
                                         :to="{
                                             name: Route.MatterMap,
                                             params: {
                                                 matterId: currentMatter.id,
                                             }
                                         }">
                                <a :href="href"
                                   @click="show = false">
                                    {{ $t('sketches.map') }}
                                </a>
                            </router-link>
                        </template>
                    </i18n-t>
                    <v-switch id="display-sketches-switch"
                              v-model="sketchesVisibleMapValue"
                              data-test="sketches-display-visible-map"
                              :data-track="`sketches-display-visible-map-${sketchesVisibleMapValue}`"
                              hide-details
                              color="primary"
                              class="sketches-setting-modal__switch" />
                </div>
                <div class="d-flex flex-row align-center justify-space-between">
                    <label for="unit-of-measure-select"
                           class="caption-regular sketches-setting-modal__unit-of-measure-label mr-4">
                        {{ $t('sketches.settingModal.unitOfMeasurement') }}</label>
                    <ow-select id="unit-of-measure-select"
                               ref="refSketchesUnit"
                               :items="items"
                               is-dense
                               hide-details
                               is-single-line
                               is-flat
                               :selected-value="sketchesUnitValue"
                               data-test="sketches-unit-of-measurement"
                               :data-track="SketchesUnit[sketchesUnitValue] ? `sketches-unit-of-measurement-${SketchesUnit[sketchesUnitValue].toLowerCase()}`: undefined"
                               class="caption-regular sketches-setting-modal__select"
                               @change="handleSketchesUnitChange" />
                </div>
            </div>
            <template #actions>
                <ow-card-actions has-secondary-button
                                 no-padding
                                 :primary-button-text="$t('action.save')"
                                 :primary-button-disabled="!dirty"
                                 primary-button-data-test="sketches-setting-modal-button-done"
                                 secondary-button-data-test="sketches-setting-modal-button-cancel"
                                 primary-button-data-track="sketches-setting-modal-button-done"
                                 secondary-button-data-track="sketches-setting-modal-button-cancel"
                                 @primary-button-click="handleDone"
                                 @secondary-button-click="handleCancel" />
            </template>
        </ow-modal>
    </teleport>
</template>

<script setup lang="ts">
    // @ts-ignore
    // eslint-disable-next-line
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from "vuex"

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwSelect from '@/components/core/ow-select.vue'
    import { Route } from '@/enums/route.enum'
    import { SketchesUnit } from '@/enums/sketches-enums'
    import i18nPlugin from '@/plugins/i18n'
    import {
        MATTER_UPDATE_SKETCHES_SETTINGS,
    } from '@/store/modules/matter/types'
    import {
        SKETCHES_MUTATE_SHOW_SETTING_MODAL,
    } from '@/store/modules/sketches/types'

    const store = useStore()
    const { tm } = useI18n()
    const refSketchesUnit = ref(null)
    const sketchesUnit = ref<SketchesUnit>(SketchesUnit.Metric)
    const sketchesVisibleMap = ref()
    const dirty = ref(false)
    const currentMatter = computed(() => store.state.matter.currentMatter)
    const items = computed(() => tm('sketches.settingModal.unitOfMeasureOptions'))

    const show = computed({
        get() {
            return store.state.sketches.showSketchSettingModal ?? false
        },
        set(value) {
            store.commit(SKETCHES_MUTATE_SHOW_SETTING_MODAL, value)

            if (value) {
                refSketchesUnit?.value.content?.focus()
            }
        },
    })

    const sketchesVisibleMapValue = computed({
        get() {
            return sketchesVisibleMap.value
        },
        set(value) {
            dirty.value = true
            sketchesVisibleMap.value = value
        },
    })

    const sketchesUnitValue = computed({
        get() {
            return sketchesUnit.value
        },
        set(value) {
            dirty.value = true
            sketchesUnit.value = value
        },
    })

    const handleCancel = async () => {
        show.value = false
    }

    const handleDone = async () => {
        show.value = false

        if (dirty.value) {
            store.dispatch(MATTER_UPDATE_SKETCHES_SETTINGS, {
                sketchesUnit: sketchesUnit.value,
                sketchesVisibleMap: sketchesVisibleMap.value,
            })
        }
    }

    const handleSketchesUnitChange = (item) => {
        dirty.value = true
        sketchesUnit.value = item.value
    }

    watch(show, (value) => {
        if (value) {
            sketchesUnit.value = currentMatter?.value.sketchesUnit ?? SketchesUnit.Metric
            sketchesVisibleMap.value = currentMatter?.value.sketchesVisibleMap ?? true
            dirty.value = false
        }
    }, { immediate: true })
</script>

<style lang="scss">
@import "sketches-setting-modal.scss";
</style>
