<template>
    <v-card :class="{
                'is-card-layout': isCardLayout,
                'is-active-tab-zero': activeTab === 0,
                'elevation-3': isSettingsPage,
                'is-settings-layout': isSettingsPage,
                'w-100': fullWidth,
            }"
            class="matter-share">
        <v-card-title v-if="isCardLayout"
                      class="content__heading">
            Sharing settings
        </v-card-title>
        <v-card-text class="matter-share__card-text">
            <app-matter-link-share-settings v-if="isForWalkthroughOnly"
                                            :is-prevent-preview-enabled="isPreventPreviewEnabled"
                                            :is-for-walkthrough-only="true"
                                            @prevent-preview="preventPreview" />
            <v-tabs v-else
                    v-model="activeTab"
                    slider-color="primary"
                    direction="horizontal">
                <v-tab data-test="matter-share-external-tab"
                       data-track="MAT-SETTINGS - Share matter externally tab">
                    {{ $t('matter.share.external') }}
                </v-tab>
                <v-tab data-test="matter-share-internal-tab"
                       data-track="MAT-SETTINGS - Share matter internally tab">
                    {{ $t('matter.share.internal') }}
                </v-tab>
            </v-tabs>

            <v-window v-model="activeTab"
                      class="matter-share__container">
                <v-window-item>
                    <app-matter-link-share-settings :is-prevent-preview-enabled="isPreventPreviewEnabled"
                                                    @log-heap-event="logHeapEvent"
                                                    @prevent-preview="preventPreview" />
                </v-window-item>
                <v-window-item>
                    <app-permissions :matter="currentMatter"
                                     :loading="loading"
                                     :owner="currentMatterOwner"
                                     :reset-form-on-modal-close="resetFormOnModalClose"
                                     :permissions="permissions" />
                </v-window-item>
            </v-window>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
    import { mapState } from 'vuex'

    import MatterLinkShareSettings from '@/components/matter/link-share/matter-link-share-settings.vue'
    import Permissions from '@/components/matter/settings/permissions.vue'

    export default {

        components: {
            appPermissions: Permissions,
            appMatterLinkShareSettings: MatterLinkShareSettings,
        },

        props: {
            isCardLayout: {
                type: Boolean,
                required: false,
            },
            initialActiveTab: {
                type: Number,
                required: false,
                default: 0,
            },
            resetFormOnModalClose: {
                type: Boolean,
                required: false,
            },
            isPreventPreviewEnabled: {
                type: Boolean,
                required: false,
            },
            isForWalkthroughOnly: {
                type: Boolean,
                required: false,
            },
            isSettingsPage: {
                type: Boolean,
                required: false,
            },
            fullWidth: {
                type: Boolean,
                required: false,
            },
        },

        emits: ['prevent-preview', 'log-heap-event'],

        data() {
            return {
                activeTab: this.initialActiveTab || 0,
            }
        },

        computed: {
            ...mapState({
                currentMatter: (state) => state.matter.currentMatter,
                permissions: (state) => state.matter.currentMatterPermissions,
                loading: (state) => state.matter.loadingList,
                currentMatterOwner: state => state.matter.currentMatter.createdByUserEmail,
            }),
        },

        methods: {
            preventPreview() {
                this.$emit('prevent-preview')
            },

            logHeapEvent() {
                this.$emit('log-heap-event')
            },
        },
    }
</script>

<style lang="scss">
    @import 'matter-share';
</style>
