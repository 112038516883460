import httpClient from '@/api/http-client'

export default class ApplicationEnquiryApi {
    static END_POINT = 'ApplicationEnquiry'
    static controller = null

    /**
     * Get the day list for the specified title
     * @static
     * @param titleNumber {String}
     * @param source {String}
     * @returns {Promise<T|*>}
     */
    static getDayListByTitleNumbers(titleNumber : string, source: string) : Promise<any> {
        if (this.controller) {
            this.controller.abort()
        }
        this.controller = new AbortController()

        const uri = `${ this.END_POINT }/GetBGApplicationEnquiry?titleNo=${ titleNumber }&source=${ source }`
        return httpClient
            .get(uri)
            .then(response => response.data)
    }

    /**
     * Get a backdated applications as a CSV.
     * @static
     * @param data {Object}
     * @example
     *  The data object should be in the following for:
     *      {
     *          selectedFields: [
     *              'pricePaid',
     *              'leaseDate',
     *              ...
     *          ],
     *          titleNumbers: [
     *              'TGL50538',
     *              ...
     *          ]
     *      }
     * @returns {Promise<T|*>}
     */
    static getMultipleBackDatedApplicationsAsCsv(data) {
        const uri = `${ this.END_POINT }/GetMultipleBGApplicationEnquiryCSV`
        return httpClient.post(uri, data)
    }
}
