<template>
    <ow-grid class="matter-searches-product-table__grid"
             :grid-config="gridConfig"
             theme="material" />
</template>

<script setup lang="ts">
    import {
        GridConfig,
        Store,
    } from '@bryntum/grid'
    import {
        ref,
    } from 'vue'

    import OwGrid from '@/components/core/ow-grid/ow-grid.vue'
    import {
        defaultGridConfig,
        useGridConfig,
    } from '@/components/core/ow-grid/use-grid-config'

    import { useProductColumns } from './columns'
    import ProductItemModel from './models/ProductItemModel'

    const props = defineProps<{
        store: Store
        multiSelect?: boolean
        config?: Partial<GridConfig>
    }>()

    const gridConfig = ref(useGridConfig({
        store: props.store,
        overrides: {
            ...props.config,
            rowHeight: 60,
            features: {
                ...defaultGridConfig.features,
                cellEdit: true,
            },
            onCellClick({ record }) {
                // deselect all other records
                if (!props.multiSelect) {
                    props.store.allRecords.forEach((productItem: ProductItemModel) => {
                        if (productItem !== record) {
                            productItem.selected = false
                        }
                    })
                }
                const productItem = record as ProductItemModel
                productItem.selected = !productItem.selected
            },
        },
        columns: useProductColumns,
    }))
</script>
