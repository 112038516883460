<template>
    <div class="matter-search-card pa-4 d-flex"
         :class="{'matter-search-card--clickable': cardLayout}"
         @click="$emit('click')"
         @mouseenter="isHovered = true"
         @mouseleave="isHovered = false">
        <matter-searches-boundary-preview :title-numbers="titleNumbers"
                                          :geo-json="geoJson"
                                          :map-options="mapOptions" />
        <div v-if="hasDefaultSlot"
             class="matter-search-card__content justify-start d-flex flex-column pa-4 pl-6"
             :class="{ 'pt-0': !cardLayout }">
            <slot />
        </div>
        <div v-else
             class="matter-search-card__content justify-center d-flex flex-row align-center pa-4 pl-6">
            <div class="matter-search-card__content d-flex flex-column pl-6">
                <h1 v-if="titleNumbers?.length"
                    class="matter-search-card__heading content__heading text-no-wrap"
                    :title="titleNumbers.join(', ')">
                    {{ titleNumbers.join(', ') }}
                </h1>
                <div v-if="address"
                     class="matter-search-card__heading content__heading d-flex text-no-wrap">
                    <span>{{ getOneLineAddress(address) }}</span>
                </div>
                <div class="matter-search-card__sub-heading d-flex text-no-wrap">
                    <div class="body-highlight">
                        {{ gbpCurrencyFormatter.format(totalGrossPrice ?? 0) }}
                    </div>
                    <div v-if="submittedDate"
                         class="matter-search-card__sub-heading d-flex text-no-wrap">
                        <div class="mx-0">
                            •
                        </div>
                        <span class="body-highlight">{{ submittedText }}: <span class="body-regular">{{ isDraft && format(submittedDate) === '01-01-0001' ? 'N/A' : format(submittedDate) }}</span></span>
                    </div>
                </div>
                <div class="matter-search-card__status body-highlight d-flex flex-row align-center mt-3">
                    <div v-if="progress !== undefined && maxProgress !== undefined"
                         class="matter-search-card__progress-container d-flex flex-row align-center">
                        <div class="matter-search-card__progress-text caption-highlight text-no-wrap">
                            {{ status }}
                        </div>
                        <v-progress-linear class="matter-search-card__progress-bar"
                                           color=""
                                           :model-value="progress"
                                           :max="maxProgress"
                                           height="16" />
                    </div>
                    <ow-chip v-else
                             :label="statusText"
                             small
                             hide-close-button
                             :color="statusColour" />
                </div>
            </div>
            <!-- TODO: uncomment when Delete Draft Order endpoint is ready -->
            <!--            <ow-button v-if="isDraft && isHovered"-->
            <!--                       title="Click to delete the draft order"-->
            <!--                       is-danger-light-->
            <!--                       small-->
            <!--                       class="matter-search-card__delete pr-3"-->
            <!--                       @click.stop="$emit('delete-draft')">-->
            <!--                <template #iconSuffix>-->
            <!--                    <span class="mdi mdi-close-circle" />-->
            <!--                </template>-->
            <!--                Delete-->
            <!--            </ow-button>-->
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
        useSlots,
    } from 'vue'

    import {StructuredAddress} from "@/api/searches.api"
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwChip from '@/components/core/ow-chip.vue'
    import MatterSearchesBoundaryPreview from '@/components/matter-searches/matter-searches-boundary-preview.vue'
    import { format } from '@/utils/date-utils'
    import { gbpCurrencyFormatter } from '@/utils/number-utils'

    const props = withDefaults(defineProps<{
        titleNumbers?: string[]
        geoJson?: string
        address?: StructuredAddress
        totalGrossPrice?: number
        submittedDate?: string
        status?: number
        state?: number
        progress?: number
        maxProgress?: number
        cardLayout?: boolean
        mapOptions: {
            boundaryColour: string;
            npsLayer: string
            layerAttribution: string
            osDataHubApiKey: string
        },
    }>(), {
        status: 0,
        maxProgress: 100,
        cardLayout: true,
        // statusColour: 'default',
    })

    defineEmits<{
        (e: 'click'): void,
        (e: 'delete-draft'): void,
    }>()

    const slots = useSlots()
    const hasDefaultSlot = computed(() => !!slots.default && !!slots.default().length)
    const isDraft = computed(() => props.state === 3)
    const isHovered = ref(false)

    const statusText = computed(() => {
        if (props.state === 0) {
            return 'Submitted'
        } else if (props.state === 1) {
            return 'Failed'
        } else if (props.state === 2) {
            return 'Complete'
        } else if (isDraft.value) {
            return 'Draft'
        }
        return ''
    })

    const submittedText = computed(() => {
        if (props.state === 0) {
            return 'Submitted'
        } else if (props.state === 1) {
            return 'Failed'
        } else if (props.state === 2) {
            return 'Completed'
        } else if (isDraft.value) {
            return 'Created'
        }
        return ''
    })

    const statusColour = computed(() => {
        if (props.state === 0) {
            return 'warning'
        } else if (props.state === 1) {
            return 'error'
        } else if (props.state === 2) {
            return 'success'
        } else if (isDraft.value) {
            return 'default'
        }
        return ''
    })

    function getOneLineAddress(address: StructuredAddress): string {
        const addressDetails = [
            address?.organisationName,
            `${ address?.buildingNumber }${ address?.thoroughfareName ? ' ' + address?.thoroughfareName : '' }`,
            address?.postTown,
            address?.postcode,
        ]
        const filteredAddressDetails = addressDetails.filter(item => item && item !== 'undefined' && item !== 'null')
        const oneLineAddress = filteredAddressDetails.join(', ')
        return oneLineAddress.trim()
    }
</script>

<style lang="scss">
    @import './matter-searches-card.scss';
</style>
