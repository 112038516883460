import gql from 'graphql-tag'

/**
 * Returns title numbers without digital registers as designated by the
 * edition date being null.
 * @param {any} titleNumbers an array of title numbers to consider, otherwise all title numbers for the matter.
 */
export const GET_TITLES_WITHOUT_DIGITAL_REGISTERS = (titleNumbers) => {
    const query = `query titleRegisters(
            $matterId: Long!
            ${ titleNumbers ? '$titleNumbers: [String!]' : '' }
        ) {
            titleRegisters(
                matterId: $matterId
                titleNumbers: $titleNumbers
                where: {editionDate: null}
            ) {
                titleNumber
            }
        }`
    return gql`${ query }`
}
