import {
    format,
    differenceInDays,
} from '@/utils/date-utils'
import { Model } from '@bryntum/grid'

export const dateFormat = (value) => {
    if (value) {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        const expectedDate = new Date(value)
        expectedDate.setHours(0, 0, 0, 0)
        const diffDays = differenceInDays(expectedDate, date)
        const displayText = format(value)
        if (diffDays <= 0) {
            return `<span class="caption-regular hide-in-percy">${ displayText }</span>`
        }
        return `<span class="caption-regular hide-in-percy">${ displayText }</span><span class="caption-italic ml-1"> (${ diffDays } ${ diffDays > 1 ? 'days' : 'day' })</span>`
    } else {
        return '-'
    }
}

export const calculatingMarkup = '<div class="v-progress-circular v-progress-circular--indeterminate v-progress-circular--visible v-theme--light ml-2" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width: 24px; height: 24px;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style="transform: rotate(calc(-90deg));"><circle class="v-progress-circular__underlay" fill="transparent" cx="50%" cy="50%" r="20" stroke-width="8" stroke-dasharray="125.66370614359172" stroke-dashoffset="0"></circle><circle class="v-progress-circular__overlay" fill="transparent" cx="50%" cy="50%" r="20" stroke-width="8" stroke-dasharray="125.66370614359172" stroke-dashoffset="125.66370614359172px"></circle></svg><!----></div>'

export const pillMarkup = (args: {
    record?: Model
    mapPillClass?: (item: string, index?: number) => string
    mapPillItems?: (record?: Model) => string[]
    getPillStyle?: (item: string, index?: number) => string
    align?: 'left' | 'right'
    filter?: (item: string) => boolean
} = {
    align: 'left',
    filter: () => true,
}) => {
    const getPillClass = (item: string, index?: number) => {
        return (args?.mapPillClass && args.mapPillClass(item, index)) ?? '--default'
    }
    const getPillStyle = (item: string, index?: number) => {
        return (args?.getPillStyle && args.getPillStyle(item, index)) ?? null
    }
    const renderPill = (item: string, index?: number) => {
        return `<div style='${ getPillStyle(item, index) }' class='label-caps-smallest  px-1 ${ args.align === 'left' ? 'mr-2' : 'ml-2' } ${ getPillClass(item, index) }'><span class="text-no-wrap">${ item }</span></div>`
    }
    const items = args.mapPillItems && args.mapPillItems(args.record).filter( (value) => (args?.filter && args.filter(value)) ?? true)
    return `
        <div class="chip-items d-inline-flex flex-no-wrap">
            ${ items.map((item: string, index: number) => renderPill(item, index)).join('') }
        </div>`
}
