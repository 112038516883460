import { TitlePanels } from '@/store/modules/title-panels'

import actions from './actions'
import defaultTitleStatus from './default-title-status'
import getters from './getters'
import mutations from './mutations'

const state = {
    loading: false,
    loadingSummaryTitle: false, // Is loading the title for summary display panel
    selectedSummaryTitle: null, // The title displayed in the summary details panel
    loadingState: {
        orderTitlePlan: false,
        orderRegister: false,
    },
    selectedTitleNumber: null,
    expandedTitleNumber: null,
    titles: [],
    titleCache: {},
    activeTitlePanel: TitlePanels.LIST,
    selectedTitle: defaultTitleStatus,
    selectedTitleLayer: null,
    selectedTitleFeatures: null,
    selectedEntryTypes: [],
    errormessage: null,

    // UI
    collapsePanel: false,
    pendingRegisterIndicatorSelection: null, // an indicator to select on the title panel register tab
    pendingRegisterIndicatorTextSelectionSpan: [], // additional text to highlight.
    registerFlagColourPalette: [
        '#ffcccc',
        '#c1ecbf',
        '#bfd9ec',
        '#b8c79e',
        '#c7f3f3',
        '#e3d5f1',
        '#d0b8b8',
        '#e8cd44',
        '#e3abff',
        '#adadad',
        '#00ffe7',
        '#a871ff',
        '#fd6c6c',
    ],
    showMultiSelectTitlePanel: false,

    BASE_DOCUMENT_URL: 'BusinessGateway/GetBGDocumentFileByFilename?filename=',
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
