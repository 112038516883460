<template>
    <div class="ow-slider">
        <span v-if="!simple"
              class="ow-slider__label body-copy--greyout"
              data-test="ow-slider-label">{{ label }}</span>
        <span v-if="!simple"
              class="ow-slider__min"
              data-test="ow-slider-min"
              @click="updateValue(min)">{{ min }} {{ unit }}</span>
        <v-slider class="ow-slider__slider"
                  :model-value="modelValue"
                  :step="step"
                  :min="min"
                  :max="max"
                  :thumb-label="simple ? false : 'always'"
                  hide-details
                  :data-test="dataTestAttribute"
                  color="primary"
                  @update:model-value="updateValue">
            <template v-for="(_, name) in $slots"
                      #[name]="data">
                <slot :name="name"
                      v-bind="data" />
            </template>
        </v-slider>
        <span v-if="!simple"
              class="ow-slider__max"
              data-test="ow-slider-max"
              @click="updateValue(max)">{{ max }} {{ unit }}</span>
    </div>
</template>

<script lang="ts">
    export default {
        props: {
            modelValue: {
                type: [Number, String],
                required: true,
            },
            min: {
                type: Number,
                required: true,
            },
            max: {
                type: Number,
                required: true,
            },
            unit: {
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: true,
            },
            step: {
                type: Number,
                required: false,
                default: 1,
            },
            dataTestAttribute: {
                type: String,
                required: false,
            },
            simple: {
                type: Boolean,
                required: false,
            },
            forcePrecisionRounding: {
                type: Boolean,
                required: false,
            },
        },

        emits: ['update:modelValue'],

        methods: {
            updateValue(val: number): void {
                val = this.forcePrecisionRounding
                    ? Math.round(val)
                    : val
                this.$emit('update:modelValue', val)
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-slider.scss'
</style>
