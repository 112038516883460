<template>
    <section class="pdf-page-selection-item"
             :class="{'selected': props.selected}"
             :data-test="props.selected"
             @click="onClick">
        <canvas ref="canvas" />
        <div class="pdf-page-selection-item-actions">
            <ow-radio v-if="!multiSelect"
                      :id="`pdf-page-selection-page-${props.pageNumber}`"
                      v-model="radioModelInternal"
                      :name="`pdf-page-selection-page-${props.pageNumber}`"
                      :label="pageLabel.toString()" />
            <ow-checkbox v-if="multiSelect"
                         :id="`pdf-page-selection-page-${props.pageNumber}`"
                         v-model="props.selected"
                         :label="pageLabel.toString()" />
        </div>
    </section>
</template>
<script setup lang="ts">
    import { PDFPageProxy } from 'pdfjs-dist'
    import * as pdfjsLib from 'pdfjs-dist/build/pdf.js'
    import {
        computed,
        ref,
        watch,
    } from 'vue'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwRadio from '@/components/core/ow-radio.vue'
    import i18n from '@/plugins/i18n'

    let PDFJSWorker
    (async () => {
        PDFJSWorker = await import('pdfjs-dist/build/pdf.worker.entry')
        pdfjsLib.GlobalWorkerOptions.workerSrc = PDFJSWorker
    })()
    const emit = defineEmits<{
        (e: 'select', page: PDFPageProxy): void
        (e: 'deselect', page: PDFPageProxy): void
    }>()

    const props = withDefaults(defineProps<{
        pdfDocument: pdfjsLib.PDFDocumentProxy;
        pageNumber: number;
        selected: boolean;
        multiSelect?: boolean;
    }>(), {
        pageNumber: 1,
        selected: false,
        multiSelect: false,
    })

    const radioModelInternal = ref<string>('')
    const canvas = ref<HTMLCanvasElement | null>(null)

    watch(
        () => props.pageNumber,
        async () => {
            const page = await props.pdfDocument.getPage(props.pageNumber)
            const viewport = page.getViewport({ scale: 0.2 })

            if (canvas.value) {
                canvas.value.height = viewport.height
                canvas.value.width = viewport.width

                const canvasContext = canvas.value.getContext('2d')
                page.render({ canvasContext, viewport })
            }
        },
        { immediate: true },
    )

    watch(
        () => props.selected,
        (val) => {
            radioModelInternal.value = val ? `pdf-page-selection-page-${ props.pageNumber }` : ''
        },
        { immediate: true },
    )

    const pageLabel = computed(() => {
        return i18n.global.t('documents.pageSelection.pageNumber', { pageNumber: props.pageNumber })
    })

    const onClick = async () => {
        const page = await props.pdfDocument.getPage(props.pageNumber) as PDFPageProxy
        emit(props.selected ? 'deselect' : 'select', page)
    }

</script>
<style  lang="scss">
  @import "pdf-page-selection-item";
</style>
