<template>
    <div class="upload-a-document">
        <h3 v-t="'mapOverlays.step1.uploadDocument.title'"
            class="body-highlight" />
        <p v-t="'mapOverlays.step1.uploadDocument.instructions'"
           class="caption-regular" />

        <ow-file-uploader-drop-zone :accepted-file-extensions="[UploadFileExtensions.PDF]"
                                    :accepted-file-types="[UploadFileTypes.PDF]"
                                    :clear-files="clearFiles"
                                    class="upload-a-document__drop-zone"
                                    i18n-path-for-accepted-file-types-error="documents.upload.acceptedFileTypeErrorPdfOnly"
                                    i18n-path-for-drop-zone-text="documents.upload.dropZoneTextPdfOnly"
                                    i18n-path-for-too-many-files="documents.upload.tooManyFiles"
                                    @files="onFilesUpdated" />
    </div>
</template>

<script lang="ts" setup>
    import OwFileUploaderDropZone from '@/components/core/ow-file-uploader-drop-zone.vue'
    import {
        UploadFileExtensions,
        UploadFileTypes,
    } from '@/enums/upload-file-types.enum'

    const EVENTS = {
        FILES: 'files',
    }
    const emits = defineEmits<{
        (e: typeof EVENTS.FILES, files: File[])
    }>()

    interface Props {
        clearFiles?: number,
    }

    withDefaults(defineProps<Props>(), {
        clearFiles: 0,
    })

    const onFilesUpdated = (files: File[]) => {
        emits(EVENTS.FILES, files)
    }
</script>

<style lang="scss" scoped>
  @import './upload-a-document';
</style>
