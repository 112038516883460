<template>
    <ow-modal :model-value="modelValue"
              subtitle="Where would you like to add your title to?"
              title="Add to matter"
              class="add-to-matter-dialog"
              @update:model-value="onInput">
        <div class="add-to-matter-dialog__content"
             data-test="add-to-matter-dialog-content">
            <ow-button data-test="add-to-matter-dialog-create-new-matter-button"
                       data-track="MAT-CREATE - Add to matter modal"
                       is-secondary
                       @click="onCreateNewMatterClick">
                Create new matter
            </ow-button>
            <div class="add-to-matter-dialog__divider">
                <span class="add-to-matter-dialog__line" />
                <span>or</span>
                <span class="add-to-matter-dialog__line" />
            </div>
            <ow-autocomplete :items="sortedFilteredMatters"
                             data-test-attribute="add-to-matter-dialog-dropdown"
                             item-text="name"
                             item-value="id"
                             label="Add to existing matter"
                             outlined
                             @change="onChange" />
            <ow-button :disabled="isLoading || !selectedMatter"
                       :is-loading="isLoading"
                       is-primary
                       data-test="add-to-matter-dialog-confirm-button"
                       @click="onConfirmClick">
                Confirm
            </ow-button>
        </div>
    </ow-modal>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import MatterApi, { IMatterListRequest } from "@/api/matter.api"
    import OwAutocomplete from '@/components/core/ow-autocomplete.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import { MatterState } from '@/consts/matter.js'
    import { Route } from '@/enums/route.enum'
    import { IState } from "@/interfaces/store/state.interface"
    import {
        MATTER_ADD_TITLE,
        MATTER_MUTATE_MATTERS,
        MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE,
        MATTER_SET_CURRENT_BY_ID,
    } from '@/store/modules/matter/types'
    import {
        dynamicSort,
        isNullOrEmpty,
    } from '@/utils/array-utils'
    import { appendMatterHintText } from "@/utils/matter-utils"

    export default {
        components: {
            OwModal,
            OwButton,
            OwAutocomplete,
        },
        props: {
            modelValue: {
                type: Boolean,
                required: false,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            matter: {
                type: Object,
                required: false,
                default: null,
            },
        },
        emits: ['update:modelValue', 'close-dialog'],
        data() {
            return {
                selectedMatter: null,
                isLoading: false,
            }
        },
        computed: {
            ...mapState({
                matters: (state: IState) => state.matter.matters,
            }),
            sortedFilteredMatters() {
                return (this.matters || [])
                    .filter(matter => matter.state === MatterState.Open)
                    .sort(dynamicSort('-lastAccessed'))
            },
        },
        async mounted() {
            this.selectedMatter = this.matter
            // NOTE: If there are no matters loaded try to load them
            if (isNullOrEmpty(this.matters)) {
                try {
                    const defaultPageSize = 100
                    const defaultPageIndex = 0
                    const options: IMatterListRequest = {
                        pageSize: defaultPageSize,
                        pageIndex: defaultPageIndex,
                    }
                    const response = await MatterApi.getMatterListWithPagination(options)
                    if (response?.data) {
                        // Append additional properties to the data for display purposes.
                        const newItems = response.data
                        newItems.forEach(x => appendMatterHintText(x))
                        this.updateMatters(newItems)
                    }
                } catch (err: any) {
                    throw new Error(err)
                }
            }
        },
        methods: {
            ...mapActions({
                addTitleToMatter: MATTER_ADD_TITLE,
                setCurrentMatterById: MATTER_SET_CURRENT_BY_ID,
            }),
            ...mapMutations({
                setTitlesToAddAfterMatterCreate: MATTER_MUTATE_TITLES_TO_ADD_AFTER_MATTER_CREATE,
                updateMatters: MATTER_MUTATE_MATTERS,
            }),
            onInput() {
                this.$emit('update:modelValue', this.$event)
            },
            onChange(value: any) {
                this.selectedMatter = value
            },
            closeDialog() {
                this.$emit('close-dialog')
            },
            async onConfirmClick() {
                this.isLoading = true
                await this.setCurrentMatterById(this.selectedMatter.id)
                await this.addTitleToMatter({ titleNumber: this.titleNumber })
                await this.$router.push({
                    name: Route.MatterMapTitle,
                    params: {
                        titleNumber: this.titleNumber,
                        matterId: this.selectedMatter.id,
                    },
                })
                this.closeDialog()
                this.isLoading = false
            },
            onCreateNewMatterClick() {
                this.closeDialog()
                this.setTitlesToAddAfterMatterCreate([this.titleNumber])
                this.$router.push({
                    name: Route.MattersCreate,
                    hash: '#details',
                })
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './add-to-matter-dialog.scss';
</style>
