import {
    LOGGING_DATADOG_INITIALISE,
    LOGGING_HEAP_INITIALISE,
    LOGGING_INITIALISE_ALL,
    LOGGING_INTERCOM_INITIALISE,
    LOGGING_OSMAPS_INITIALISE,
    LOGGING_OW_INITIALISE,
} from '@/store/mutation-types'

import { actions as datadogActions } from './datadog'
import { actions as heapActions } from './heap'
import { actions as intercomActions } from './intercom'
import { actions as owActions } from './orbital-witness'
import { actions as osMapActions } from './os-maps'

const state = {
    // Logging initialisation status - has there already been a request to setup the logging or not.
    initialised: {
        intercom: false,
        heap: false,
        osmaps: false,
        ow: false,
        datadog: false,
    },

    // Current logging status - is the logging provider set up and active?
    active: {
        intercom: false,
        heap: false,
        osmaps: false,
        ow: false,
        datadog: false,
    },

    // Logging specific settings

    // OS Maps API usage
    osMapsLogging: { // temporary logging functionality required to report to OS prior to October 2019 pricing model changes(?).
        enabled: false,
        queuedUsage: {
            // periodically posted to the server and then emptied. See USER_INIT_OSMAPS_USAGE_LOGGING.
        },
        updateTimeout: null, // used to throttle updates to the server
    },

}

const getters = {}

const mutations = {}

const actions = {
    async [LOGGING_INITIALISE_ALL]({ dispatch }) {
        // The order of these is important.
        // Heap/Intercom/Sentry all use the DataDog session, so it needs to be initialised first
        await dispatch(LOGGING_OW_INITIALISE)
        await dispatch(LOGGING_DATADOG_INITIALISE)
        await dispatch(LOGGING_OSMAPS_INITIALISE)
        await dispatch(LOGGING_HEAP_INITIALISE)
        await dispatch(LOGGING_INTERCOM_INITIALISE)
    },

    ...datadogActions,
    ...heapActions,
    ...intercomActions,
    ...osMapActions,
    ...owActions,
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
