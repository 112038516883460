import {
    IShapshotRenderData,
    ISnapshotModel,
} from '@/components/snapshots/common/snapshot-interfaces'
import {
    SnapshotOrientation,
    SnapshotType,
} from '@/components/snapshots/common/snapshot-enums-consts'

export class BaseSnapshotModel implements ISnapshotModel {
    public configId: string
    public configVersion = '1.0'
    public type: SnapshotType = SnapshotType.Siteplan
    public renderData: IShapshotRenderData
}

export type SnapshotApiModel = {
    configId: string
    configVersion: string
    type: SnapshotType
    renderDataJson: string
    urlOverride?: string
    matterId?: number
}

/**
 * The snapshot-type specific data required for rendering a snapshot.
 */
export class BaseRenderDataModel implements IShapshotRenderData {
    public layoutId?: string
    public orientation?: SnapshotOrientation
    showTitle: boolean
    showSubtitle: boolean
    title: string
    subtitle: string
}
