import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import {
    Layer,
} from 'ol/layer'
import LayerGroup from 'ol/layer/Group'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { LayerNames } from '@/consts/map-layers'
import olMap from 'ol/Map'
import {
    IMapRolloverOptions,
    IMapRolloverSection,
    IMapRolloverSectionItem,
} from '@/components/map-rollover/common/map-rollover-interfaces'
import { StyleLike } from 'ol/style/Style'
import { FeatureLike } from 'ol/Feature'
import i18n from '@/plugins/i18n'
import { IOwSketchOrStyleProps } from '@/store/modules/sketches/types/style'

import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import {
    Ref,
    watch,
} from 'vue'
import {
    OverlayListItemModel,
    OverlayModel,
} from '@/components/map/overlays/overlays-types'
import OverlaysApi from '@/api/overlays.api'
import {
    getGeoImage,
    layerEquals,
    validBoundingBox,
} from '@/utils/map-utils'
import { GeoImage } from '@/store/modules/map/layers/geo-image/geo-image-layer'
import { setImagePostRender } from '@/utils/overlays-utils'

type GenericKeyValuePairType = {
    [x: string]: any,
}

export type OverlaysFeaturesType = {
    name: string,
    description: string,
} | GenericKeyValuePairType

export type OverlaysSnapshotConfigItem = {
    url: string,
    boundingBox: number[],
    opacity: number,
    name: string,
    rotation: number,
    visible: boolean,
    id: string,
    visibleAreaGeoJson: string
}
export type OverlaysLayerParams = {
    overlays: Ref<Array<OverlayListItemModel>>,
    targetMap: olMap,
    snapshotConfig?: LayerSnapshotModel,
    overlayLoadingCb: (overlayId: string, isOverlayLoading: boolean) => void,
}

/** Used to display multiple overlays on the map. */
export class OverlaysLayer implements IMapSnapshotLayer {
    name: string = LayerNames.Overlays
    public layerGroup: LayerGroup
    public targetMap: olMap
    private initialised = false
    private overlays: Ref<Array<OverlayListItemModel>>
    public imageLayers: Map<string, GeoImage> = new Map<string, GeoImage>()
    public selectedLayer: OverlayModel = null
    public interactionLayer: VectorLayer<VectorSource>
    public isFromSnapshot: boolean = false
    public overlayLoadingCb: (overlayId: string, isOverlayLoading: boolean) => void

    constructor(args: OverlaysLayerParams = null) {
        if (args?.snapshotConfig) {
            // Args are currently passed only when loading from a snapshot.
            this.isFromSnapshot = true
            this.targetMap = args.targetMap
            this.initialiseFromConfig(args.snapshotConfig)
        }
    }

    public initialiseFromConfig(config: LayerSnapshotModel): void {
        // This is called when the layer is initialised from a snapshot config i.e. Export assistant.
        this.initialiseMapLayer()
        const overlays = JSON.parse(config.configJson).overlays as OverlaysSnapshotConfigItem[]
        overlays.forEach((overlayConfig: OverlaysSnapshotConfigItem) => {
            if (!validBoundingBox(overlayConfig.boundingBox)) {
                return
            }

            const imageLayer = getGeoImage(
                overlayConfig.url,
                overlayConfig.boundingBox,
                overlayConfig.opacity,
                overlayConfig.rotation,
                overlayConfig.id,
                overlayConfig.visible)

            setImagePostRender(
                imageLayer,
                overlayConfig.rotation,
                overlayConfig.boundingBox,
                overlayConfig.visibleAreaGeoJson)

            this.layerGroup.getLayers().push(imageLayer)
            this.imageLayers.set(overlayConfig.name, imageLayer)
        })
        this.initialised = true
    }

    public async initialiseIfRequired(params: OverlaysLayerParams): Promise<void> {
        this.overlays = params.overlays
        this.targetMap = params.targetMap
        this.overlayLoadingCb = params.overlayLoadingCb

        watch(() => this.overlays, async (newVal, oldVal) => {
            if (newVal !== oldVal) {
                await this.updateOverlays()
            }
        })
        if (!this.initialised) {
            this.initialiseMapLayer()
            this.initialised = true
        }
    }

    private initialiseMapLayer(): void {
        this.layerGroup = new LayerGroup({
            zIndex: 10,
        })
        this.targetMap.addLayer(this.layerGroup)
        this.layerGroup.set('name', LayerNames.Overlays)
        this.layerGroup.set('getOwLayer', () => this)
        this.layerGroup.set('getRolloverTextForPoint', OverlaysLayer.getRolloverTextForPoint.bind(this, this.layerGroup, this.getRolloverOptions(), null))
    }

    clearOverlays() {
        this.imageLayers.forEach((layer, key) => {
            this.invalidateOverlay(key)
        })
    }

    async updateOverlays() {
        // Remove any overlays that are no longer in the list
        this.imageLayers.forEach((layer, key) => {
            if (!this.overlays.value.find(overlay => overlay.overlayId === key)) {
                this.layerGroup.getLayers().remove(layer)
                this.imageLayers.delete(key)
            }
        })

        // Add any new overlays - assume we will pre-emptively load them regardless of visibility, and assume they have finished processing.
        for (const overlay of this.overlays.value) {
            if (!this.imageLayers.has(overlay.overlayId)) {
                // get the overlay image
                const overlayRecord = (await OverlaysApi.getOverlay(overlay.overlayId)).data as OverlayModel

                const imageLayer = getGeoImage(
                    overlayRecord.sourceImageUrl,
                    overlayRecord.boundingBox,
                    overlay.opacity,
                    overlayRecord.rotation,
                    overlayRecord.id,
                    overlay.visible)

                imageLayer.set('overlayId', overlayRecord.id)

                this.overlayLoadingCb(overlayRecord.id, true)

                this.layerGroup.getLayers().push(imageLayer)
                this.imageLayers.set(overlay.overlayId, imageLayer)

                imageLayer.on('sourceready', () => {
                    this.overlayLoadingCb(overlayRecord.id, false)
                })

                setImagePostRender(
                    imageLayer,
                    overlayRecord.rotation,
                    overlayRecord.boundingBox,
                    overlayRecord.visibleAreaGeoJson)
            }
        }

        // Update visibility and opacity
        this.imageLayers.forEach((layer, key) => {
            const overlay = this.overlays.value.find(overlay => overlay.overlayId === key)
            if (overlay) {
                // If the overlay is visible but the opacity is too low, increase it
                if (overlay.visible && overlay.opacity < 0.1) {
                    overlay.opacity = 0.5
                }
                layer.setVisible(overlay.visible)
                layer.setOpacity(overlay.opacity)
            }
        })
    }

    public async setOverlays(overlays: Ref<Array<OverlayListItemModel>>): Promise<void> {
        this.overlays = overlays
        await this.updateOverlays()
    }

    public showOverlay(overlayId: string): void {
        const layer = this.imageLayers.get(overlayId)
        if (layer) {
            layer.setVisible(true)
        }
    }

    public hideOverlay(overlayId: string): void {
        const layer = this.imageLayers.get(overlayId)
        if (layer) {
            layer.setVisible(false)
        }
    }

    public updateSelectedOverlay(selectedOverlayModel: OverlayModel) {
        const overlay = this.overlays.value.find(x => x.overlayId === selectedOverlayModel.id)

        if (!overlay) {
            return
        }

        overlay.opacity = selectedOverlayModel.opacity
        overlay.rotation = selectedOverlayModel.rotation
        overlay.boundingBox = selectedOverlayModel.boundingBox
        overlay.visibleAreaGeoJson = selectedOverlayModel.visibleAreaGeoJson
    }

    public invalidateOverlay(overlayId: string): void {
        this.layerGroup.getLayers().remove(this.imageLayers.get(overlayId))
        this.imageLayers.delete(overlayId)
    }

    getIsLoading(): boolean {
        return false
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        return []
    }

    getLayer(): Layer | LayerGroup {
        return this.layerGroup
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        const overlays = Array.from(this.imageLayers.values())
            .sort((a, b) => a.getZIndex() - b.getZIndex())
            .map(x => {
                const overlayId = x.get('overlayId')
                if (overlayId) {
                    const overlay = this.overlays.value
                        .find(overlay => overlay.overlayId === overlayId)

                    return {
                        id: overlayId,
                        name: overlay.name,
                        opacity: overlay.opacity,
                        visible: overlay.visible,
                        boundingBox: overlay.boundingBox,
                        url: x.getSource().getGeoImage().src,
                        rotation: overlay.rotation,
                        visibleAreaGeoJson: overlay.visibleAreaGeoJson,
                    }
                }
                return null
            }).filter(x => x !== null)

        return {
            name: LayerNames.Overlays,
            configJson: JSON.stringify({ overlays }),
        }
    }

    getVisible(): boolean {
        return false
    }

    async setVisible(visible: boolean, targetMap?: olMap): Promise<void> {
        this.targetMap = targetMap ?? this.targetMap
        this.layerGroup.setVisible(visible)
        return Promise.resolve()
    }

    public getRolloverOptions(): IMapRolloverOptions {
        return {
            category: i18n.global.t(`map.rollover.${ LayerNames.Overlays }.category`),
            allowClick: true,
            getFeatures: (targetMap, pixel): Record<string, FeatureLike[]> => {
                const features = targetMap.getFeaturesAtPixel(pixel, {
                    layerFilter: currentLayer => layerEquals(this.interactionLayer, currentLayer),
                })
                if (!features?.length) return null

                return features
            },
            getSource: () => {
                return undefined
            },
            addItems: (features): IMapRolloverSectionItem[] => {
                const result: IMapRolloverSectionItem[] = []
                const featureArray = features as FeatureLike[]
                if (!featureArray.length) return result
                featureArray.forEach(feature => {
                    const properties: OverlaysFeaturesType = feature.getProperties()
                    const style: Partial<IOwSketchOrStyleProps> = {
                        fillColour: '#000000',
                        strokeColour: '#ffffff',
                    }
                    result.push({
                        primary: properties.name,
                        extended: [
                            {
                                name: i18n.global.t('mapOverlays.mapRollOver.name'),
                                value: properties.name,
                            },
                        ],
                        style,
                    })
                })
                return result
            },
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public static getRolloverTextForPoint(layer, rolloverOptions: IMapRolloverOptions, style: StyleLike, targetMap, pixel): IMapRolloverSection {
        // TODO: implement map rollover
        return null
    }
}
