<template>
    <div :class="{'matter-content-visible': showMapTitlesNav}"
         class="d-flex flex-column walkthrough-page-container">
        <!-- Header -->
        <app-walkthrough-page-container-header :is-shared-link-view="isSharedLinkView"
                                               @add-new-page="$emit('add-new-page', $event)"
                                               @delete-page="$emit('delete-page', $event)"
                                               @duplicate-page="$emit('duplicate-page', $event)"
                                               @request-reorder-page-prompt="$emit('request-reorder-page-prompt', $event)"
                                               @select-page="$emit('select-page', $event)" />

        <!-- Add titles/sketches control -->
        <div v-if="!isSharedLinkView"
             style="padding: 8px 12px;">
            <v-btn v-if="!showMapTitlesNav"
                   color="primary"
                   data-test="walkthrough-page-container-add-titles"
                   data-track="WALKTHROUGH - Open title list panel to add titles to a walkthrough"
                   size="large"
                   @click="$emit('toggle-title-nav-visibility')">
                Add titles
            </v-btn>
            <v-btn v-if="showMapTitlesNav"
                   color="primary"
                   data-track="WALKTHROUGH - Close the title list panel and finish adding titles to a walkthrough"
                   size="large"
                   variant="outlined"
                   @click="$emit('toggle-title-nav-visibility')">
                Done
            </v-btn>
        </div>

        <!-- Page content -->
        <app-walkthrough-page :is-shared-link-view="isSharedLinkView" />

        <!-- Dialogs -->
        <app-walkthrough-page-reorder-prompt />
    </div>
</template>

<script lang="ts">
    import {
        mapState,
    } from 'vuex'

    import WalkthroughPage from '@/components/walkthrough/walkthrough-page.vue'
    import WalkthroughPageContainerHeader from '@/components/walkthrough/walkthrough-page-container-header.vue'
    import WalkthroughPageReorderPrompt from '@/components/walkthrough/walkthrough-page-reorder-prompt.vue'

    export default {
        name: 'WalkthroughPageContainer',

        components: {
            appWalkthroughPage: WalkthroughPage,
            appWalkthroughPageReorderPrompt: WalkthroughPageReorderPrompt,
            appWalkthroughPageContainerHeader: WalkthroughPageContainerHeader,
        },

        props: {
            isSharedLinkView: {
                type: Boolean,
                required: false,
            },
        },
        emits: ['toggle-title-nav-visibility', 'select-page', 'request-reorder-page-prompt', 'duplicate-page', 'delete-page', 'add-new-page'],

        computed: {
            ...mapState({
                showMapTitlesNav: state => state.user.showMapTitlesNav,
            }),
        },
    }
</script>
<style lang="scss">
    @import 'walkthrough-page-container.scss';
</style>
