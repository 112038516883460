<template>
    <ow-card has-outline
             class="matter-title-add-titles-option">
        <div class="matter-title-add-titles-option__header"
             data-test="matter-title-add-titles-option-header"
             @click="$emit('select', importMethod)">
            <h6 class="matter-title-add-titles-option__title"
                :data-test="dataTest">
                {{ title }}
            </h6>
            <ow-radio :id="importMethod"
                      v-model="radioValue"
                      name="import-method" />
        </div>
        <div v-if="currentSelected === importMethod"
             data-test="matter-title-add-titles-option-expanded">
            <div class="matter-title-add-titles-option__content">
                <slot />
            </div>
            <div v-if="slots.footer"
                 class="matter-title-add-titles-option__footer">
                <slot name="footer" />
            </div>
        </div>
    </ow-card>
</template>

<script setup lang="ts">
    import { computed,
             useSlots } from 'vue'

    import OwCard from '@/components/core/ow-card.vue'
    import OwRadio from '@/components/core/ow-radio.vue'

    const slots = useSlots()

    const props = defineProps<{
        importMethod: string;
        currentSelected: string;
        title: string
        dataTest?: string
    }>()

    const emits = defineEmits<{
        (e: 'select', importMethod: string): void
    }>()

    const radioValue = computed({
        get(): string {
            return props.currentSelected
        },
        set(value: string): void {
            emits('select', value)
        },
    })
</script>

<style lang="scss">
    @import 'matter-side-panel-add-titles-option';
</style>
