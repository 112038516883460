<template>
    <div>
        <div v-if="sketch"
             v-hotkey="keyMap"
             class="sketch-tools sketch-tool-actions">
            <div v-if="sketch?.name"
                 class="sketch-tool-section">
                <div :style="nameStyle"
                     class="caption-highlight sketch-name"
                     :title="sketch.name"
                     data-test="sketches-tool-actions-name"
                     data-track="sketches-tool-actions-name"
                     @click="$emit('action', ToolActionType.EditName)">
                    {{ sketch.name }}
                </div>
            </div>
            <div class="divider" />
            <div class="sketch-tool-section">
                <div class="sketch-type">
                    <component :is="sketchIcon"
                               v-if="sketchIcon !== ''"
                               class="sketches-list-item__icon" />
                    {{ sketchTypeName }}
                </div>
            </div>
            <div class="divider" />
            <div class="sketch-tool-section sketch-tool-section__style"
                 @mouseenter="showStyleOptionsMenu"
                 @mouseleave="hideStyleOptionsMenu">
                <label v-t="'style.style'"
                       class="label-caps-small" />
                <style-option :ow-style="sketch"
                              :sketch-type="sketch?.sketchType"
                              :with-chevron="!hideStyleSelect"
                              :is-expanded="showStyleOptionsMenuValue"
                              is-style-preset
                              :disabled="hideStyleSelect"
                              data-test="sketches-tool-actions-style"
                              data-track="sketches-tool-actions-style"
                              @click="onStyleClick" />
                <div v-show="showStyleOptionsMenuValue && !hideStyleSelect"
                     class="sketches-action-menu"
                     :class="{ 'top-nav-visible': isTopNavVisible}">
                    <style-panel :selected-style="sketch"
                                 :type="sketch.sketchType"
                                 :is-complex-sketch="isComplexSketch(sketch)"
                                 @select-style="style => $emit('action', ToolActionType.SelectStyle, style)" />
                </div>
            </div>
            <div class="divider" />
            <div class="sketch-tool-section sketch-tool-section__snapping"
                 @mouseenter="showSnappingOptionsMenu"
                 @mouseleave="hideSnappingOptionsMenu">
                <label v-t="'sketches.snapSketchTo'"
                       class="label-caps-small" />
                <div class="sketch-tool-actions__action-snapping">
                    <ow-button data-test="sketches-tool-actions-snapping"
                               data-track="sketches-tool-actions-snapping"
                               class="sketch-tool-actions__action"
                               full-height
                               small
                               is-borderless
                               large
                               @click="showSnappingOptionsMenuValue = !showSnappingOptionsMenuValue">
                        <span class="caption-highlight">{{ snappingText }}</span>
                        <v-icon v-if="!showSnappingOptionsMenuValue">
                            $chevron-down
                        </v-icon>
                        <v-icon v-if="showSnappingOptionsMenuValue">
                            $chevron-up
                        </v-icon>
                    </ow-button>
                    <div v-show="showSnappingOptionsMenuValue"
                         class="sketches-action-menu sketch-tool-actions__action-snapping-menu">
                        <ul>
                            <li v-t="'sketches.snapTo.none'"
                                data-test="sketches-tool-actions-snapping-none"
                                data-track="sketches-tool-actions-snapping-none"
                                @click="onSnapToClick(SnappingMode.None)" />
                            <li v-t="'sketches.snapTo.titleBoundaries'"
                                data-test="sketches-tool-actions-snapping-title-boundaries"
                                data-track="sketches-tool-actions-snapping-title-boundaries"
                                @click="onSnapToClick(SnappingMode.TitleBoundaries)" />
                            <li v-if="!hideSketchesSnapping"
                                v-t="'sketches.snapTo.sketches'"
                                data-test="sketches-tool-actions-snapping-sketches"
                                data-track="sketches-tool-actions-snapping-sketches"
                                @click="onSnapToClick(SnappingMode.Sketches)" />
                        </ul>
                    </div>
                </div>
            </div>
            <ow-button data-test="sketches-tool-actions-clear"
                       data-track="sketches-tool-actions-clear"
                       class="sketch-tool-actions__action"
                       full-height
                       small
                       is-borderless
                       :disabled="!canClear"
                       large
                       @click="$emit('action', ToolActionType.Clear)">
                <span v-t="'action.clear'" />
            </ow-button>

            <ow-button data-test="sketches-tool-actions-undo"
                       data-track="sketches-tool-actions-undo"
                       class="sketch-tool-actions__action"
                       full-height
                       small
                       :disabled="!canUndo"
                       is-borderless
                       large
                       @click="$emit('action', ToolActionType.Undo)">
                <span v-t="'action.undo'" />
            </ow-button>
            <ow-button data-test="sketches-tool-actions-done"
                       data-track="sketches-tool-actions-done"
                       class="sketch-tool-actions__action"
                       :class="{'sketch-tool-actions--primary': hasFeaturesAndNotDrawing}"
                       full-height
                       small
                       is-borderless
                       large
                       :is-primary="hasFeaturesAndNotDrawing"
                       @click="$emit('action', ToolActionType.Done)">
                <span v-t="'action.done'" />
            </ow-button>
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        nextTick,
        ref,
        shallowRef,
        watch,
    } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import StyleOption from '@/components/sketches/style-option.vue'
    import StylePanel from '@/components/sketches/style-panel.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { useSketchIcon } from '@/composables/use-sketch-icon'
    import {
        SketchType,
        SnappingMode,
        ToolActionType,
    } from '@/enums/sketches-enums'
    import i18n from '@/plugins/i18n'
    import { ISketch } from '@/store/modules/sketches/types/sketch'
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    import { isComplexSketch } from '@/utils/sketch-utils'

    const props = defineProps<{
        sketch: ISketch,
        isDrawing: boolean,
        hasFeatures: boolean,
        canUndo?: boolean,
        canClear?: boolean,
        keyMap?: Record<string, any>,
        snappingMode: SnappingMode,
        hideStyleSelect?: boolean,
        hideSketchesSnapping?: boolean,
    }>()

    const emit = defineEmits<{
        (e: 'action', action: ToolActionType, data?: unknown),
    }>()

    const sketchIcon = shallowRef('')
    useSketchIcon(props.sketch?.sketchType).then((icon) => {
        sketchIcon.value = icon
    })
    const sketchTypeName = computed(() => {
        return props.sketch?.sketchType ? SketchType[props.sketch.sketchType] : ''
    })
    const nameStyle = computed(() => {
        return {
            background: props.sketch.strokeColour,
        }
    })

    // Snapping.
    const snappingText = computed(() => {
        return i18n.global.t(`sketches.snapTo.${ props.snappingMode.toString() }`)
    })
    const showSnappingOptionsMenuTimeout = ref(null)
    const showSnappingOptionsMenuValue = ref<boolean>(false)
    const hideSnappingOptionsMenu = () => {
        showSnappingOptionsMenuTimeout.value = setTimeout(() => {
            showSnappingOptionsMenuValue.value = false
        }, 500)
    }
    const showSnappingOptionsMenu = () => {
        clearTimeout(showSnappingOptionsMenuTimeout.value)
        showSnappingOptionsMenuValue.value = true
    }

    const onSnapToClick = async (mode: SnappingMode) => {
        emit('action', ToolActionType.SnapTo, mode)
        showSnappingOptionsMenuValue.value = false
    }

    // Style.
    const showStyleOptionsMenuValue = ref<boolean>(false)
    const showStyleOptionsMenuTimeout = ref(null)
    const hideStyleOptionsMenu = () => {
        showStyleOptionsMenuTimeout.value = setTimeout(() => {
            showStyleOptionsMenuValue.value = false
        }, 500)
    }
    const showStyleOptionsMenu = () => {
        clearTimeout(showStyleOptionsMenuTimeout.value)
        showStyleOptionsMenuValue.value = true
    }
    const onStyleClick = () => {
        showStyleOptionsMenuValue.value = !showStyleOptionsMenuValue.value
    }

    // The sketch has features and is not being edited.
    const hasFeaturesAndNotDrawing = computed(() => props.hasFeatures && !props.isDrawing)

    // watch the sketch type and update the icon
    watch(() => props.sketch, async () => {
        await nextTick()
        useSketchIcon(props?.sketch?.sketchType).then((icon) => {
            sketchIcon.value = icon
        })
    })

    const { isTopNavVisible } = useMapTopNav()

    defineExpose({
        hideStyleOptionsMenu,
        showStyleOptionsMenuTimeout,
        showStyleOptionsMenuValue,

    })
</script>

<style lang="scss">
 @import "sketches-tool-actions.scss";
</style>
