<template>
    <ow-grid class="matter-searches-category-table__grid"
             :grid-config="gridConfig"
             :header="header"
             :collapsed="collapsed"
             theme="material"
             @collapse="emit('collapse')" />
</template>

<script setup lang="ts">
    import {
        GridConfig,
        Store,
    } from '@bryntum/grid'
    import {
        computed,
        ref,
    } from 'vue'

    import OwGrid from '@/components/core/ow-grid/ow-grid.vue'
    import { useGridConfig } from '@/components/core/ow-grid/use-grid-config'
    import {
        SearchProductsFilter,
    } from '@/store/modules/searches/types'

    import { GridHeader } from '../core/ow-grid'
    import { useCategoryColumns } from './columns'
    import CategoryItemModel from './models/CategoryItemModel'

    const props = defineProps<{
        store: Store
        config?: Partial<GridConfig>
        collapsed?: boolean
        collapsible?: boolean
        filter?: SearchProductsFilter
    }>()

    const emit = defineEmits<{
        (e: 'add', args: CategoryItemModel): void,
        (e: 'swap', args: CategoryItemModel): void
        (e: 'collapse'): void
    }>()

    const gridConfig = ref(useGridConfig({
        store: props.store,
        overrides: {
            ...props.config,
            rowHeight: 64,
        },
        customArgs: {
            filterFn: (record: any) => {
                if (!props.filter?.searchText) {
                    return true
                }
                return record.description.toLowerCase().includes(props.filter?.searchText?.toLowerCase() ?? '')
            },
        },
        on: async (event: string, column: string, category: CategoryItemModel) => {
            switch (event) {
                case 'add': {
                    emit('add', category)
                    break
                }
                case 'swap': {
                    emit('swap', category)
                    break
                }
            }
        },
        columns: useCategoryColumns,
    }))

    const recordCount = computed(() => props?.store?.isFiltered ? props?.store?.storage?.filteredValues?.length : props?.store?.allRecords?.length)

    const header = computed<GridHeader>(() => ({
        title: `Recommended (${ recordCount.value })`,
        description: 'These products are not included in your order but are recommended',
        showCount: true,
        collapsible: props.collapsible,
        sticky: true,
        link: {
            label: props.collapsed ? 'View all' : 'Hide all',
            show: true,
            theme: 'primary',
            handler: () => {
                emit('collapse')
            },
        },
    }))
</script>

<style lang="scss">
    @import './matter-searches-category-table';
</style>
