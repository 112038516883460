<template>
    <div ref="map"
         :title="!showLayerAttribution ? layerAttribution : undefined"
         class="core-map hide-in-percy">
        <map-watermark v-if="showWatermark"
                       :base-layer="mapBackdrops.osMap"
                       class="map-watermark"
                       :class="{'map-watermark__top': watermarkPosition === OwTooltipPosition.Top,
                                'map-watermark__bottom': watermarkPosition === OwTooltipPosition.Bottom}" />
    </div>
</template>

<script lang="ts">
    import { ScaleLine } from 'ol/control'
    import Attribution from 'ol/control/Attribution'
    import {defaults as defaultInteractions,
            MouseWheelZoom} from 'ol/interaction'
    import {
        Layer,
        Tile as TileLayer,
    } from 'ol/layer'
    import VectorLayer from 'ol/layer/Vector'
    import OlMap from 'ol/Map'
    import Projection from 'ol/proj/Projection'
    import View, { ViewOptions } from 'ol/View'
    import { PropType } from 'vue'

    import { CoreMapInteractions } from '@/components/core/maps/core-map-interactions.interface'
    import { CoreMapView } from '@/components/core/maps/core-map-view.interface'
    import MapWatermark from "@/components/map/map-watermark.vue"
    import { mapBackdrops } from "@/consts/map"
    import { SUPPORTED_MAP_PROJECTIONS } from '@/consts/map-projections'
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { initBritishNationalGridProjection } from '@/utils/map-utils'

    export default {
        name: 'CoreMap',
        components: { MapWatermark },

        props: {
            initialView: {
                type: Object as PropType<CoreMapView>,
                required: true,
            },
            layers: {
                type: Array as PropType<TileLayer<any>[] | VectorLayer<any>[]>,
                required: true,
            },
            interactions: {
                type: Object as PropType<CoreMapInteractions>,
                required: false,
            },
            name: {
                type: String,
                required: false,
                default: 'map',
            },
            showMapScale: {
                type: Boolean,
                required: false,
                default: true,
            },
            layerAttribution: {
                type: String,
                required: false,
            },
            showLayerAttribution: {
                type: Boolean,
                required: false,
                default: true,
            },
            showWatermark: {
                type: Boolean,
                required: false,
                default: false,
            },
            watermarkPosition: {
                type: String,
                required: false,
                default: OwTooltipPosition.Top,
            },
            interactiveScrollZoom: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        emits: [
            'map-initialised',
            'loaded',
            'loading',
        ],

        data() {
            return {
                map: null as OlMap | null,
                defaultProjection: SUPPORTED_MAP_PROJECTIONS.EPSG27700,
                isLoading: false,
            }
        },

        computed: {
            OwTooltipPosition() {
                return OwTooltipPosition
            },
            mapBackdrops() {
                return mapBackdrops
            },
        },

        watch: {
            layers(): void {
                this.updateMapLayers()
            },
        },

        mounted() {
            this.initialiseMap()
            window.addEventListener('resize', this.updateSize)
            if (this.interactiveScrollZoom) {
                document.addEventListener("click", this.handleClick)
            }
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.updateSize)
            if (this.interactiveScrollZoom) {
                document.removeEventListener("click", this.handleClick)
            }
        },

        methods: {
            initialiseMap(): void {
                initBritishNationalGridProjection()

                const interactionOptions = {
                    doubleClickZoom: false,
                    dragPan: false,
                    keyboard: false,
                    mouseWheelZoom: false,
                    ...this.interactions,
                }

                const projectionOptions = this.initialView.projection || this.defaultProjection
                const projection = new Projection(projectionOptions)
                const controls: any[] = this.showLayerAttribution
                    ? [
                        new Attribution({
                            collapsible: false,
                        })]
                    : []
                if (this.showMapScale) {
                    controls.push(new ScaleLine({ bar: true, minWidth: 200 }))
                }

                const viewOptions:ViewOptions = {
                    projection,
                    padding: this.initialView.paddingWithinMap,
                    minZoom: this.initialView.minZoom,
                    maxZoom: this.initialView.maxZoom,
                }
                if (this.initialView.extent) {
                    viewOptions.extent = this.initialView.extent
                } else {
                    viewOptions.center = this.initialView.center
                    viewOptions.zoom = this.initialView.zoom
                }

                this.map = new OlMap({
                    target: this.$refs.map,

                    // the map view will initially show the whole world
                    view: new View(viewOptions),
                    controls,
                    interactions: defaultInteractions(interactionOptions),
                })
                this.layers.forEach(x => this.map?.addLayer(x))
                const mapLayer = this.map.getLayers().getArray()[0]
                if (mapLayer) {
                    mapLayer.on('prerender', this.handlePreRender)
                    this.map.on('rendercomplete', this.handleRenderComplete)
                }

                if (isNullOrEmpty((window as any).map)) {
                    (window as any).map = []
                }

                (window as any).map[this.name] = this.map
                if (this.interactiveScrollZoom) {
                    const mapInteractions = this.map.getInteractions()
                    mapInteractions.forEach(i => {
                        if (i instanceof MouseWheelZoom) {
                            i.setActive(false)
                        }
                    })
                }
                this.$emit('map-initialised', this.map)
            },

            handleRenderComplete() {
                this.$emit('loaded', this.map)
                this.isLoading = false
            },

            handlePreRender() {
                if (!this.isLoading) {
                    this.isLoading = true
                    this.$emit('loading')
                }
            },

            updateSize() {
                if (this.map !== null) {
                    this.map.updateSize()
                }
            },

            updateMapLayers() {
                if (this.map) {
                    const existingMapLayers = this.map.getLayers().getArray()
                    existingMapLayers.forEach((x: Layer) => {
                        if (!this.layers.includes(x)) {
                            x.setVisible(false)
                        } else{
                            x.setVisible(true)
                        }
                    })
                    this.layers.filter(x => !existingMapLayers.includes(x))
                        .forEach(x => this.map.addLayer(x))
                }
            },

            handleClick(event): void {
                const component = this.$refs.map
                if (component && this.map) {
                    const mapInteractions = this.map.getInteractions()
                    mapInteractions.forEach(i => {
                        if (i instanceof MouseWheelZoom) {
                            i.setActive(component.contains(event.target))
                        }
                    })
                }
            },
        },
    }
</script>

<style lang="scss"
       scoped>
@import './core-map';
</style>
