<template>
    <ow-dialog v-if="error?.size > 0"
               data-test="error-dialog"
               :model-value="showErrorMessage"
               :title="$t('searches.error.title')"
               @update:model-value="store.commit(SEARCHES_MUTATE_CLEAR_ERRORS)">
        <ow-alert type="danger">
            <div class="d-flex flex-column">
                {{ lastError.title }}
                <div v-for="(e, index) in lastError.errors"
                     :key="index">
                    <span v-if="e"
                          v-dompurify-html="e"
                          class="caption-highlight" />
                </div>
            </div>
        </ow-alert>
        <span class="d-flex mt-4">{{ errorMessageSubtitle }}</span>
        <div class="d-flex w-100 mt-5 justify-space-between">
            <div class="d-flex gc-3">
                <ow-button-ds v-if="hasReturnToMap"
                              outlined
                              @click="onNavigateToMap">
                    {{ $t('searches.error.cta.returnToMap') }}
                </ow-button-ds>
                <ow-button-ds outlined
                              @click="onCopyToClipboard">
                    {{ $t('searches.error.cta.copyToClipboard') }}
                </ow-button-ds>
            </div>
            <ow-button-ds v-if="hasRetry"
                          class="ml-3"
                          is-primary
                          @click="onRetry">
                {{ $t('searches.error.cta.retry') }}
            </ow-button-ds>
        </div>
    </ow-dialog>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OwAlert from '@/components/core/ow-alert.vue'
    import OwButtonDs from '@/components/core/ow-button-ds.vue'
    import OwDialog from '@/components/core/ow-dialog.vue'
    import { Route } from '@/enums/route.enum'
    import {
        getStepName,
        SearchesSteps,
    } from '@/enums/searches.enum'
    import {              ISearchesError,
                          SEARCHES_GET_ERROR,
                          SEARCHES_GET_HAS_ERRORS,
                          SEARCHES_GET_SELECTED_TITLE_NUMBERS,
                          SEARCHES_MUTATE_CLEAR_ERRORS } from '@/store/modules/searches/types'

    const error = computed<Map<string,ISearchesError>>(() => store.getters[SEARCHES_GET_ERROR])
    const selectedTitleNumbers = computed<string[]>(() => store.getters[SEARCHES_GET_SELECTED_TITLE_NUMBERS])
    const showErrorMessage = computed<boolean>(() => store.getters[SEARCHES_GET_HAS_ERRORS])
    const store = useStore()
    const { t, te } = useI18n()
    const router = useRouter()

    withDefaults(defineProps<{
        hasRetry?: boolean
        hasReturnToMap?: boolean
    }>(), {
        hasRetry: true,
        hasReturnToMap: true,
    })

    const emit = defineEmits<{
        (e: 'retry'): void
    }>()

    const lastError = computed<ISearchesError>(() => {
        if (!error.value?.entries || error.value?.size === 0) {
            return null
        }
        const lastRequest = error?.value?.entries().next().value ?? {}
        return {
            title: lastRequest?.[0],
            ...lastRequest?.[1],
        }
    })

    const onRetry = () => {
        // retry the last request
        const lastRequest = error.value.entries().next()
        const lastRequestKey = lastRequest.value?.[0]

        store.commit(SEARCHES_MUTATE_CLEAR_ERRORS)
        switch (lastRequestKey) {
            // add more cases here if needed
            default:
                emit('retry')
                break
        }
    }


    const onNavigateToMap = () => {
        store.commit(SEARCHES_MUTATE_CLEAR_ERRORS)

        router.push({
            name: Route.MatterSearchesCreate,
            params: {
                step: getStepName(SearchesSteps.DefinePolygon),
                args: selectedTitleNumbers.value.join(','),
            },
        })
    }

    const onCopyToClipboard = () => {
        const errorDetails = Array.from(error.value.entries()).map(([key, value]) => {
            return `${ key }:\n${ JSON.stringify(value?.request) }:\n${ value.errors.join('\n') }`
        }).join('\n\n')

        // copy the error details to the clipboard
        let errorText = `Error: ${ errorMessageSubtitle.value }`
        errorText += `\nTrace Id: ${ lastError?.value?.traceId ?? 'N/A' }`
        errorText += `\nDetail: ${ lastError?.value?.detail ?? 'N/A' }`
        errorText += `\n\n${ errorDetails }`
        navigator.clipboard.writeText(errorText)
    }

    const errorMessageSubtitle = computed<string>(() => {
        const lastRequestKey = lastError.value?.[0]
        const translatedError = te(`searches.error.${ lastRequestKey }`)
        return translatedError ? t(`searches.error.${ lastRequestKey }`) : t('searches.error.default')
    })
</script>
