import i18n from '@/plugins/i18n'

export const parentTitleNumberColumnRenderer = ({ isExport, includeInTitleOrganiser, record, value }) => {
    if (!isExport) {
        if (record.tenureType === 'Freehold') {
            return 'N/A as title is freehold'
        } else if (value) {
            return value
        } else {
            return includeInTitleOrganiser
                ? i18n.global.t('matter.titleOrganiser.unknownParentTitleNotOwnedGroupName')
                : `
                <div title="Order the Parent Title register to populate the row.">
                    <i aria-hidden="true" class="material-icons order-register-info">info_outline</i>
                </div>
            `
        }
    }

    return (value || value === 0) ? value : null
}
