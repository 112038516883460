<template>
    <div class="snapshot-assistant-options">
        <div class="scrollable-area">
            <div class="configuration-settings">
                <snapshot-title-options :service="service"
                                        data-test="snapshot-assistant-title-options" />
                <snapshot-layout-options v-if="service.snapshotModel.renderData.layoutId"
                                         :service="service" />
            </div>
            <!-- Snapshot type specific options -->
            <!--suppress TypeScriptValidateTypes -->
            <map-snapshot-options v-if="service.snapshotModel.type === SnapshotType.Siteplan"
                                  :service="service"
                                  data-test="snapshot-assistant-map-options" />
        </div>
        <!-- Common snapshot actions -->
        <div class="snapshot-actions">
            <v-progress-linear v-if="isLoadingContent"
                               :indeterminate="true"
                               color="primary" />
            <div class="side-by-side-buttons">
                <ow-button-ds is-primary
                              class="save-button"
                              data-track="Export Assistant - Download PDF"
                              data-test="snapshot-assistant-download-pdf"
                              @click="download(SnapshotExportFormat.PDF)">
                    <span v-t="'snapshots.actions.downloadPdf'" />
                </ow-button-ds>
                <ow-button-ds is-primary
                              class="save-button"
                              data-test="snapshot-assistant-download-image"
                              data-track="Export Assistant - Download Image"
                              @click="download(SnapshotExportFormat.JPEG)">
                    <span v-t="'snapshots.actions.downloadImage'" />
                </ow-button-ds>
            </div>
            <ow-button-ds is-secondary
                          class="cancel-button"
                          data-test="snapshot-assistant-cancel"
                          data-track="Export Assistant - Cancel button"
                          @click="onCancelClick">
                <span v-t="'action.cancel'" />
            </ow-button-ds>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { ref,
             watch } from 'vue'
    import { useStore } from "vuex"

    import OwButtonDs from '@/components/core/ow-button-ds.vue'
    import {
        SnapshotExportFormat,
        SnapshotType,
    } from '@/components/snapshots/common/snapshot-enums-consts'
    import { ISnapshotService } from '@/components/snapshots/common/snapshot-interfaces'
    import SnapshotLayoutOptions from '@/components/snapshots/common/snapshot-layout-options.vue'
    import SnapshotTitleOptions from '@/components/snapshots/common/snapshot-title-options.vue'
    import MapSnapshotOptions from '@/components/snapshots/map-snapshots/config-components/map-snapshot-options.vue'
    import { MapSnapshotRenderData } from '@/components/snapshots/map-snapshots/map-snapshot-models'
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
    } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    interface Props {
        service: ISnapshotService
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const props = defineProps<Props>()
    const isLoadingContent = ref<boolean>(false)

    const emit = defineEmits<{
        (e: 'cancel-requested'): void,
        (e: 'save-requested'): void,
    }>()

    const store = useStore()

    const onCancelClick = () => {
        emit('cancel-requested')
    }

    watch(() => props.service.isLoadingContent.value, (isLoading: boolean) => {
        isLoadingContent.value = isLoading
    }, { immediate: true })

    const download = async (format: SnapshotExportFormat) => {
        // We want to log an application log event...
        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'snapshot-export-assistant',
            description: `${ format },${ props.service.snapshotModel.renderData.layoutId },${ props.service.snapshotModel.renderData.orientation }`,
        })

        await props.service.saveCurrentSnapshot(format, afterDownload)
    }

    const afterDownload = async (format: SnapshotExportFormat) => {
        // Log a heap event after exporting.
        let metadata: any = {
            format,
            layout: props.service.snapshotModel.renderData.layoutId,
            orientation: props.service.snapshotModel.renderData.orientation,
        }
        // Snapshot type specific logging.
        switch (props.service.snapshotModel.type) {
            case SnapshotType.Siteplan:
                metadata = {
                    ...metadata,
                    keyIncluded: (props.service as MapSnapshotService).keyConfig.items.length > 0,
                    numberOfLabels: (props.service as MapSnapshotService).keyConfig.items.filter(x => !isNullOrWhitespace(x.label)).length,
                    labels: (props.service as MapSnapshotService).keyConfig.items
                        .filter(x => !isNullOrWhitespace(x.label))
                        .map(x => x.label).join('|'),
                    titleIncluded: (props.service.snapshotModel.renderData as MapSnapshotRenderData).showTitle,
                    subtitleIncluded: (props.service.snapshotModel.renderData as MapSnapshotRenderData).showSubtitle,
                }
                break
        }

        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Export Assistant - Export Generated',
            metadata,
        })
    }

    defineExpose({
        onCancelClick,
        download,
        store,
        afterDownload,
    })

</script>
<style lang="scss">
@import 'snapshot-assistant-options';
</style>
