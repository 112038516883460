import {
    Arrowhead,
    SketchesUnit,
    SketchGeometryType,
    SketchSource,
    SketchType,
} from '@/enums/sketches-enums'

import {
    getOLStyleForOWStyleDefinition,
    StyleTarget,
} from '@/utils/style-utils'
import Style from 'ol/style/Style'
import Feature from 'ol/Feature'
import { SketchesFactory } from '@/store/modules/sketches/types/sketches-factory'
import { Stroke } from 'ol/style'
import {
    ISketchMeasurement,
    sketchToMeasurement,
} from '@/utils/sketch-measurement'

export interface ISketch{
    id: number
    matterGroupId: number | null
    name: string
    sketchType: SketchType
    sketchSource: SketchSource
    comment: string | null
    visible: boolean
    sortOrder: number
    strokeColour: string
    strokeWidth: number
    fillOpacity: number
    fillColour: string
    hatch: boolean
    dash?: boolean
    arrowhead?: Arrowhead
    showLabel?: boolean

    geoJson: string
    features: Array<Feature>
    // Style shown on the map.
    olStyle: Style | Style[]

    // Style shown when editing the sketch which may be cached.
    olDrawingStyle?: Style | Style[]

    getMeasurement?: (unitOfMeasurement?: SketchesUnit) => ISketchMeasurement | string | null
}

export class SketchRecord {
    id: number
    matterGroupId: number | null
    name: string
    sketchType: SketchType
    sketchSource: SketchSource = SketchSource.User
    comment: string | null
    visible: boolean
    sortOrder: number
    strokeColour: string
    strokeWidth: number
    fillOpacity: number
    fillColour: string
    hatch: boolean
    dash: boolean
    arrowhead: Arrowhead
    showLabel: boolean
    geoJson: string
}
export class BaseAbstractSketch extends SketchRecord implements ISketch {
    constructor(record: SketchRecord = null, features: Array<Feature> = []) {
        super()
        if (record) {
            this.id = record.id
            this.matterGroupId = record.matterGroupId
            this.name = record.name
            this.sketchType = record.sketchType
            this.strokeColour = record.strokeColour
            this.comment = record.comment
            this.visible = record.visible
            this.sortOrder = record.sortOrder
            this.strokeColour = record.strokeColour
            this.strokeWidth = record.strokeWidth
            this.fillOpacity = record.fillOpacity
            this.fillColour = record.fillColour
            this.hatch = record.hatch
            this.dash = record.dash
            this.arrowhead = record?.arrowhead ?? Arrowhead.None
            this.showLabel = record?.showLabel ?? false
        }
        this.features = features
        this.geoJson = SketchesFactory.geoJsonFromFeatures(features) // re-computed to avoid change detection issues.
        this.olStyle = new Style({
            stroke: new Stroke({
                color: '#ea0c11',
                width: 4,
            }),
        })
        this.olDrawingStyle = null
    }

    geometryType: SketchGeometryType
    features: Array<Feature>
    olStyle: Style | Style[]
    olDrawingStyle?: Array<Style>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    /**
     * Returns the measurement for the sketch.
     * In the case of a marker / text sketch, this is the number of features.
     * @param {SketchesUnit} unitOfMeasurement The unit of measurement.
     * @returns {ISketchMeasurement | string | null} The measurement.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getMeasurement: () => ISketchMeasurement | string | null = (unitOfMeasurement?: SketchesUnit) => {
        return null
    }
}

export class AreaSketch extends BaseAbstractSketch {
    constructor(record: SketchRecord, features: Array<Feature>) {
        super(record, features)
        this.geometryType = SketchGeometryType.MULTI_POLYGON
        this.olStyle = getOLStyleForOWStyleDefinition(record)
        this.sketchType = SketchType.Area
    }

    getMeasurement: () => ISketchMeasurement | string | null = (unitOfMeasurement?: SketchesUnit) => {
        return sketchToMeasurement(this.features, this.sketchType, unitOfMeasurement)
    }
}

export class LineSketch extends BaseAbstractSketch {
    constructor(record: SketchRecord, features: Array<Feature>) {
        super(record, features)
        this.sketchType = SketchType.Line
        this.geometryType = SketchGeometryType.MULTI_LINE_STRING
        this.olStyle = getOLStyleForOWStyleDefinition({ ...record, features }, StyleTarget.Line)
    }

    getMeasurement: () => ISketchMeasurement | string | null = (unitOfMeasurement?: SketchesUnit) => {
        return sketchToMeasurement(this.features, this.sketchType, unitOfMeasurement)
    }
}

export class MarkerSketch extends BaseAbstractSketch {
    constructor(record: SketchRecord, features: Array<Feature>) {
        super(record, features)
        this.geometryType = SketchGeometryType.MULTI_POINT
        this.sketchType = SketchType.Marker
        this.olStyle = getOLStyleForOWStyleDefinition(record, StyleTarget.Marker)
    }

    getMeasurement: () => ISketchMeasurement | string | null = () => {
        if (this.features.length) {
            return `(${ this.features.length })`
        }
        return null
    }
}

export class TextSketch extends BaseAbstractSketch {
    constructor(record: SketchRecord, features: Array<Feature>) {
        super(record, features)
        this.geometryType = SketchGeometryType.POINT
        this.sketchType = SketchType.Text
        this.olStyle = getOLStyleForOWStyleDefinition(record, StyleTarget.Text)
    }

    getMeasurement: () => ISketchMeasurement | string | null = () => {
        if (this.features.length) {
            return `(${ this.features.length })`
        }
        return null
    }
}
