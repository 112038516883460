import { Column } from '@bryntum/grid'

import { parentTitleNumberColumnRenderer } from '@/components/title-analysis/column-renderers/parent-title-number'
import { titleNumberColumnRenderer } from '@/components/title-analysis/column-renderers/title-number'

export class TitleNumberColumn extends Column {
    static get type() {
        return 'title-number'
    }

    renderer = titleNumberColumnRenderer
}

export class ParentTitleNumberColumn extends Column {
    static get type() {
        return 'parent-title-number'
    }

    renderer = parentTitleNumberColumnRenderer
}
