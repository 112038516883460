<template>
    <div class="company-groups-create">
        <div class="company-groups-create__search">
            <find-companies-card v-model:items="items"
                                 v-model:selected="selectedItems"
                                 v-model:search="searchText"
                                 :current-group="currentGroup" />
        </div>
        <div class="company-groups-create__update">
            <update-monitoring-group-card v-model:selected="selectedItems"
                                          v-model:dirty="dirty"
                                          v-model="currentGroup"
                                          :is-update="!isNullOrWhitespace(props.groupId)"
                                          :is-saving="isSaving"
                                          :is-loading="isLoading"
                                          @cancel="handleCancel"
                                          @save="handleSave"
                                          @delete="handleDelete" />
        </div>
    </div>
    <!-- confirmation dialog -->
    <ow-modal v-model="dialog"
              max-width="400"
              persistent>
        <span v-t="'assetMonitoring.preferences.cards.findCompanies.cancel.subtitle'"
              class="caption-regular" />
        <template #actions>
            <div class="d-flex justify-end gc-3">
                <ow-button is-primary
                           small
                           @click="initialiseView">
                    {{ t('action.confirm') }}
                </ow-button>
                <ow-button small
                           @click="onCancel">
                    {{ t('action.cancel') }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        onActivated,
        onBeforeMount,
        onMounted,
        ref,
        toRaw,
        watch,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import { useRouter } from "vue-router"
    import { useStore } from "vuex"

    import CompanyGroupsApi from "@/api/company-groups.api"
    import NotificationsApi from "@/api/notifications.api"
    import FindCompaniesCard from "@/components/asset-monitoring/company-groups/find-companies-card.vue"
    import {
        IAssetMonitoringCompanyGroup,
        IAssetMonitoringCompanyGroupItem,
        IAssetMonitoringCompanyGroupSelected,
    } from "@/components/asset-monitoring/company-groups/types"
    import UpdateMonitoringGroupCard from "@/components/asset-monitoring/company-groups/update-monitoring-group-card.vue"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwModal from "@/components/core/ow-modal.vue"
    import { useToaster } from "@/composables/use-toaster"
    import { Route } from "@/enums/route.enum"
    import { NOTIFICATIONS_DISPLAY_NOTIFICATION } from "@/store/modules/notifications/types"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    const props = defineProps<{
        matterId: string,
        groupId?: string,
    }>()

    const items = ref<any[]>([])
    const isSaving = ref(false)
    const dirty = ref(false)
    const currentGroup = ref<IAssetMonitoringCompanyGroup | null>(null)
    const selectedItems = ref<IAssetMonitoringCompanyGroupSelected>({
        searchRef: {
            id: '',
            searchText: '',
        },
        selected: [],
    })

    const originalCompanyGroup = ref<IAssetMonitoringCompanyGroup | null>(null)
    const searchText = ref('')
    const isLoading = ref(false)
    const router = useRouter()
    const { success, error } = useToaster()
    const dialog = ref(false)
    const store = useStore()
    const { t } = useI18n()

    const handleCancel = async (back: boolean) => {
        // return to the previous page if back is true
        if (back) {
            return await router.push({
                name: Route.AssetMonitoringCompanyAlertsPreferences,
            })
        }

        dialog.value = true
    }

    const initialiseView = () => {
        currentGroup.value = null
        searchText.value = ''
        selectedItems.value = {
            searchRef: {
                id: '',
                searchText: '',
            },
            selected: null,
        }
        items.value = []
        dialog.value = false
        dirty.value = false
        loadExistingGroup()
    }

    const handleSave = async () => {
        isSaving.value = true
        try {
            if (!props.groupId) {
                await addCompanyGroup()
            } else {
                await updateCompanyGroup()
            }

            await router.push({
                name: Route.AssetMonitoringCompanyAlertsPreferences,
            })
        } catch (error) {
            console.error(error)
        } finally {
            isSaving.value = false
        }
    }

    const handleDelete = async () => {
        try {
            await CompanyGroupsApi.deleteCompanyGroup(props.groupId)
        } catch (err) {
            console.error(err)
            await error(err.response.data, t('assetMonitoring.preferences.cards.findCompanies.notification.delete.error.title'))
            return
        }

        await success(t('assetMonitoring.preferences.cards.findCompanies.notification.delete.description'))

        await router.push({
            name: Route.AssetMonitoringCompanyAlertsPreferences,
        })
    }

    const addCompanyGroup = async () => {
        const companyNumbers =  currentGroup.value.items.map((item) => item.regNumber)
        try {
            await CompanyGroupsApi.createCompanyGroup({
                groupName: currentGroup.value.groupName,
                companyNumbers,
                isEnabled: true,
                matterId: props.matterId,
            })
        } catch (err) {
            console.error(err)
            await error(err.response.data, t('assetMonitoring.preferences.cards.findCompanies.notification.add.error.title'))
            return
        }

        await success(t('assetMonitoring.preferences.cards.findCompanies.notification.add.description', {
            count: companyNumbers.length,
        }), t('assetMonitoring.preferences.cards.findCompanies.notification.add.title', {
            groupName: currentGroup.value.groupName,
        }))
    }

    const updateCompanyGroup = async () => {
        const companyNumbers =  currentGroup.value.items.map((item) => item.regNumber)
        try {
            // if the group name has changed, update the companies as well
            if (originalCompanyGroup.value.groupName !== currentGroup.value.groupName) {
                await CompanyGroupsApi.updateCompanyGroup(props.groupId, {
                    groupName: currentGroup.value.groupName,
                    isEnabled: currentGroup.value.isEnabled,
                })
            }

            // update the companies
            await CompanyGroupsApi.updateCompanyGroupCompanies(props.groupId,
                                                               companyNumbers,
            )
        } catch (err) {
            console.error(err)
            await error(err.response.data, t('assetMonitoring.preferences.cards.findCompanies.notification.add.error.title'))
            return
        }

        await success(t('assetMonitoring.preferences.cards.findCompanies.notification.update.description'))
    }

    const onCancel = () => {
        dialog.value = false
        dirty.value = false
    }

    const loadExistingGroup = async () => {
        if (!props.groupId) {
            return
        }
        isLoading.value = true
        try {
            const resp = await CompanyGroupsApi.getCompanyGroup(props.groupId)
            currentGroup.value = {
                isEnabled: resp.data.isEnabled,
                groupName: resp.data.groupName,
                items: resp.data.companies.map((company) => ({
                    address: company.address,
                    regNumber: company.companyNumber,
                    name: company.name,
                    searchRef: {
                        id: props.groupId,
                        searchText: resp.data.groupName,
                    },
                    id: company.companyNumber,
                })),
            }
            originalCompanyGroup.value = JSON.parse(JSON.stringify({ ...currentGroup.value }))
        } catch (err) {
            console.error(err)
            await error(err.response.data, t('assetMonitoring.preferences.cards.findCompanies.notification.get.error.title'))
        } finally {
            isLoading.value = false
        }
    }

    onMounted(() => {
        initialiseView()
    })

    onActivated(() => {
        initialiseView()
    })

    watch(() => props.groupId, async () => {
        initialiseView()
    })
</script>

<style scoped lang="scss">
    @import './company-groups-create';
</style>
