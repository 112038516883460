<template>
    <ow-modal v-model="showConfirmationDialog"
              persistent
              content-class="ow-confirmation-dialog"
              data-test-attribute="ow-confirmation-dialog"
              :title="isSuccessState ? confirmationDialog.successTitle : confirmationDialog.title">
        <span data-test="ow-confirmation-dialog--content">
            {{ isSuccessState ? confirmationDialog.successSubtitle : confirmationDialog.subtitle }}
        </span>
        <template v-if="!isSuccessState"
                  #actions>
            <div class="ow-confirmation-dialog__actions">
                <ow-button class="ow-confirmation-dialog__button-cancel"
                           data-test="ow-confirmation-dialog-button-cancel"
                           @click="showConfirmationDialog = false">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button is-primary
                           :disabled="isSubmitting"
                           data-test="ow-confirmation-dialog-button-submit"
                           @click="submit">
                    {{ confirmationDialog.submitButtonText }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script>
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds'
    import OwModal from '@/components/core/ow-modal'
    import {
        CORE_MUTATE_CONFIRMATION_DIALOG,
        CORE_MUTATE_INIT_CONFIRMATION_DIALOG,
    } from '@/store/modules/core/types'

    export default {
        name: 'OwConfirmationDialog',

        components: {
            OwButton,
            OwModal,
        },

        data() {
            return {
                isSubmitting: false,
                isSuccessState: false,
            }
        },

        computed: {
            ...mapState({
                confirmationDialog: state => state.core.confirmationDialog,
            }),

            showConfirmationDialog: {
                get() {
                    return this.confirmationDialog.showDialog
                },
                set(val) {
                    this.setConfirmationDialog({ showDialog: val })
                    if (!val) {
                        this.initialiseDialog()
                    }
                    this.confirmationDialog.reset()
                },
            },
        },

        methods: {
            ...mapMutations({
                setConfirmationDialog: CORE_MUTATE_CONFIRMATION_DIALOG,
                initConfirmationDialog: CORE_MUTATE_INIT_CONFIRMATION_DIALOG,
            }),

            async submit() {
                this.isSubmitting = true
                await this.confirmationDialog.submit()
                this.isSubmitting = false

                if (this.confirmationDialog.successTitle) {
                    this.isSuccessState = true
                } else {
                    this.showConfirmationDialog = false
                }
            },

            initialiseDialog() {
                this.initConfirmationDialog()
                this.isSuccessState = false
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-confirmation-dialog.scss';
</style>
