<template>
    <div class="overlays-selected-page-and-scale">
        <div class="overlays-selected-page-and-scale__header">
            <div>
                <h3 v-t="'mapOverlays.stepFlow.step2.setPlanScale'"
                    class="headers-h3-card-heading" />
                <div v-t="'mapOverlays.stepFlow.step2.setPlanScaleHint'"
                     class="caption-regular plan-scale-hint" />
            </div>
            <div class="overlays-selected-page-and-scale__scale">
                <label v-t="'mapOverlays.planScale'"
                       class="caption-highlight" />
                <span class="scale-rep-fraction">1:</span>
                <ow-combo-box v-model="scaleInternal"
                              :default-value="defaultScale"
                              :options="scaleOptions"
                              data-track="OVERLAY PLANS - Plan scale field" />
            </div>
        </div>
        <ow-loading-skeleton v-if="isLoading" />
        <div class="overlays-selected-page-and-scale__preview"
             data-test="overlays-selected-page-preview">
            <div ref="canvasContainer"
                 class="overlays-selected-page-and-scale__preview--canvas-container"
                 @mousedown="startDrag"
                 @mouseup="endDrag"
                 @mousemove.prevent="dragCanvas"
                 @wheel="onWheelScroll">
                <canvas ref="canvas"
                        :data-page-number="page?.pageNumber ?? null"
                        class="overlays-selected-page-and-scale__preview--image" />
            </div>

            <split-zoom-control v-if="!isLoading"
                                :zoom-in-disabled="currentCanvasZoom >= maxScale"
                                :zoom-out-disabled="currentCanvasZoom <= minScale"
                                disable-tooltips
                                @zoom-out-start="zoomOut"
                                @zoom-in-start="zoomIn" />
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { PDFPageProxy } from 'pdfjs-dist'
    import {
        ref,
        toRaw,
        watch,
    } from 'vue'

    import OwComboBox from '@/components/core/ow-combo-box.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import SplitZoomControl from '@/components/map/split-zoom-control.vue'

    interface Props {
        page?: PDFPageProxy
        scale: number,
    }

    const props = defineProps<Props>()
    const emit = defineEmits<{
        (e: 'select-scale', value: number)
    }>()
    let pageInternal: PDFPageProxy | null = null

    const defaultScale = ref<string>('1250')
    const scaleInternal = ref<string>(props.scale.toString() ?? defaultScale.value)
    const scaleOptions = [
        '500',
        '1250',
        '2500',
        '5000',
        '10000',
        '20000',
        '25000',
        '50000',
    ]
    const isLoading = ref(true)
    const minScale = 0.5
    const maxScale = 5

    watch(scaleInternal, () => {
        emit('select-scale', parseInt(scaleInternal.value))
    })

    // Zoom the preview image
    const canvas = ref<HTMLCanvasElement | null>(null)
    const canvasContainer = ref<HTMLCanvasElement | null>(null)
    const currentCanvasZoom = ref<number>(1)

    watch(() => props.page, async () => {
              // Need to wait for the animation to complete so that the container has it's correct width
              setTimeout(() => {
                  if (props.page) {
                      isLoading.value = true
                      // set initial scale to fit page to width of container
                      const viewport = props.page?.getViewport({ scale: 1 })
                      const padding = 20 // px
                      currentCanvasZoom.value = (canvasContainer.value?.clientWidth - padding) / viewport.width
                      drawCanvas()
                      isLoading.value = false
                  }
              }, 500)
          },
          { immediate: true },
    )

    // Zoom preview image in/out
    const drawCanvas = (): void => {
        pageInternal = toRaw(props.page)
        const viewport = pageInternal.getViewport({ scale: currentCanvasZoom.value })
        if (canvas.value) {
            canvas.value.height = viewport.height
            canvas.value.width = viewport.width

            const canvasContext = canvas.value.getContext('2d')
            pageInternal.render({
                canvasContext,
                viewport,
            })
        }
    }
    const zoomIn = (): void => {
        currentCanvasZoom.value += 0.5
        drawCanvas()
    }

    const zoomOut = (): void => {
        currentCanvasZoom.value -= 0.5
        drawCanvas()
    }

    const onWheelScroll = (event): void => {
        if (event.ctrlKey) {
            event.preventDefault()
            // Calculate the new scale factor based on the mouse wheel direction
            const zoomFactor = 0.1
            if (event.deltaY < 0) {
                currentCanvasZoom.value = Math.min(maxScale, currentCanvasZoom.value + zoomFactor)
            } else {
                currentCanvasZoom.value = Math.max(minScale, currentCanvasZoom.value - zoomFactor)
            }
            drawCanvas()
        }
    }

    // Drag preview image
    const isDragging = ref<boolean>(false)
    const startX = ref<number>(0)
    const startY = ref<number>(0)
    const scrollLeft = ref<number>(0)
    const scrollTop = ref<number>(0)

    const startDrag = (event: MouseEvent): void => {
        isDragging.value = true
        startX.value = event.clientX
        startY.value = event.clientY
        scrollLeft.value = canvasContainer.value.scrollLeft
        scrollTop.value = canvasContainer.value.scrollTop
        canvas.value.style.cursor = 'grabbing'
    }

    const dragCanvas = (event: MouseEvent): void => {
        if (!isDragging.value) {
            return
        }

        const dx = event.clientX - startX.value
        const dy = event.clientY - startY.value
        canvasContainer.value.scrollLeft = scrollLeft.value - dx
        canvasContainer.value.scrollTop = scrollTop.value - dy
    }

    const endDrag = (): void => {
        isDragging.value = false
        canvas.value.style.cursor = 'grab'
    }
</script>
<style lang="scss" scoped>
@import 'selected-page-and-scale.scss';
</style>
