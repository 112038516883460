import * as signalR from '@microsoft/signalr'

import { getMatterHubUri } from '@/utils/environment.utils'
import { MatterHubActions } from '@/store/modules/matter-hub/matter-hub-events'
import SecurityService from '@/api/security'

export default {

    // Initialisation
    install(app) {
        // Notification hub endpoint based on the current environment
        const uriForEnvironment = getMatterHubUri()

        // SignalR Connection
        let connection = null
        let isConnecting = false

        // On reconnect - methods to call on Vue.prototype.$notifications along with options to pass.
        let callOnReconnect = []
        const addCallOnReconnect = (method, options) => {
            if (!callOnReconnect.some(c => c.method === method && c.options === options)) {
                callOnReconnect.push({ method, options })
            }
        }
        const removeCallOnReconnect = (method, options) => {
            callOnReconnect = callOnReconnect.filter(c => c.method !== method && c.options !== options)
        }

        // Initialise connection
        const initConnectionIfRequired = async (force = false) => {
            if (!isConnecting && (connection === null || force === true)) {
                isConnecting = true
                if (connection !== null) {
                    await connection.stop()
                }

                connection = new signalR.HubConnectionBuilder()
                    .withUrl(uriForEnvironment, {
                        accessTokenFactory: async () => await SecurityService.getAccessToken(),
                        transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling,
                    })
                    .withAutomaticReconnect()
                    .build()

                await connection
                    .start({ waitForPageLoad: false, transport: ['webSockets', 'longPolling'] })
                    .then(() => {
                        isConnecting = false
                        // If subscribe requests were made prior to connection, try them again now.
                        resume()
                    })
                    .catch(err => {
                        isConnecting = false
                        console.error(err.toString())
                    })

                // Handle reconnect
                connection.onreconnected(() => {
                    resume()
                })
            }
        }

        const resume = () => {
            callOnReconnect.forEach(call => {
                app.config.globalProperties.$matterNotifications[call.method](call.options)
            })
        }

        // Add $notifications and useful calls to Vue components
        app.config.globalProperties.$matterNotifications = {

            /**
             * Subscribe to notifications for the specified matter.
             * @param matterId
             * @param callback
             */
            subscribeToMatter: async (matterId: Number, callback: Function = null) => {
                await initConnectionIfRequired()

                if (connection?.connectionStarted === true) {
                    try {
                        await connection.invoke(MatterHubActions.Subscribe, matterId)
                        if (callback) {
                            callback()
                        }
                        console.info(`Subscribed to notifications for matter '${ matterId }'.`)
                    } catch (err) {
                        console.error(err.toString())
                        throw new Error(err)
                    }
                }
                addCallOnReconnect(MatterHubActions.Subscribe, matterId)
            },

            /**
             * Unsubscribe from notifications for the specified matter.
             * @param matterId
             */
            unsubscribeFromMatter: async (matterId: Number) => {
                await initConnectionIfRequired()

                if (connection?.connectionStarted === true) {
                    connection
                        .invoke(MatterHubActions.Unsubscribe, matterId)
                        .then(() => {
                            console.info(`Unsubscribed from notifications for matter '${ matterId }'.`)
                        })
                        .catch(err => {
                            console.error(err.toString())
                        })
                }

                removeCallOnReconnect(MatterHubActions.Unsubscribe, matterId)
            },

            /**
             * Subscribe to a message stream on the matter hub.
             * @param method
             * @param callback
             */
            on: async (method: string, callback: Function) => {
                await initConnectionIfRequired()
                return connection.on(method, (matterId, message) => {
                    callback(matterId, message)
                })
            },

            /**
             * Unsubscribe from a message stream on the matter hub.
             * @param method
             * @param fn
             */
            off: (method: string, fn: Function) => {
                if (connection?.connectionStarted === true) {
                    connection.off(method, fn)
                }
            },
        }
    },
}
