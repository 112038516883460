<template>
    <div v-if="!comingSoon && ($route?.name === route && $route?.params === routeParams)"
         class="d-flex px-3 py-1 align-center justify-center top-nav-item"
         :class="{ 'top-nav-item--selected': selected, 'top-nav-item--disabled': disabled }"
         :tabindex="disabled ? -1 : 0"
         @click="emit('click', route)">
        <span class="caption-highlight"> {{ title }}</span>
        <ow-colored-label v-if="alertCount > 0"
                          class="top-nav-item--notification"
                          theme="notification-success">
            {{ alertCount }}
        </ow-colored-label>
        <div v-if="betaLabel"
             class="beta-label"
             data-test="app-dialog-beta-label">
            BETA
        </div>
    </div>
    <div v-else
         class="d-flex px-3 py-1 align-center justify-center top-nav-item"
         :class="{ 'top-nav-item--selected': selected, 'top-nav-item--disabled': disabled, 'top-nav-item--coming-soon': comingSoon }"
         :tabindex="disabled ? -1 : 0"
         :data-track="dataTrackId"
         :data-test="dataTestId"
         @click="handleTopClick">
        <v-fade-transition>
            <span v-show="delayedComingSoon"
                  class="top-nav-item--coming-soon-tag"> {{ $t('label.comingSoon') }}</span>
        </v-fade-transition>
        <span class="caption-highlight"> {{ title }}</span>
        <ow-colored-label v-if="alertCount > 0"
                          class="top-nav-item--notification"
                          theme="notification-success">
            {{ alertCount }}
        </ow-colored-label>
        <div v-if="betaLabel"
             class="align-center justify-center top-nav-item--beta-label"
             data-test="app-dialog-beta-label">
            BETA
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        ref,
        watch,
    } from 'vue'
    import { useRouter } from 'vue-router'

    import OwColoredLabel from "@/components/core/ow-colored-label.vue"

    const router = useRouter()

    const props = defineProps<{
        title?: string;
        route?: string;
        routeParams?: Record<string, string>;
        selected?: boolean;
        disabled?: boolean;
        comingSoon?: boolean;
        betaLabel?: boolean;
        dataTrackId?: string;
        dataTestId?: string;
        alertCount?: number;
    }>()

    const delayedComingSoon = ref(false)
    watch(() => props.comingSoon, (comingSoon) => {
        if (comingSoon) {
            setTimeout(() => {
                delayedComingSoon.value = true
            }, 500)
        }
    }, {
        immediate: true,
    })

    const emit = defineEmits<{
        (e: 'click', route: string): void
    }>()

    const handleTopClick = () => {
        const selectedRoute = props.route
        router.push({ name: selectedRoute })
        emit('click', selectedRoute)
    }
</script>

<style lang="scss" scoped>
@import './top-nav-item.scss';
</style>
