<template>
    <ow-modal v-model="model"
              :title="title ?? $t('dialog.registerInterest.title')"
              @close="model = false">
        <div class="dialog-register-interest__content d-flex flex-column align-center gr-4">
            <div v-if="internalShowPreamble">
                <p>{{ preamble }}</p>
                <ow-card-actions :primary-button-text="$t('action.imInterested')"
                                 justify="center"
                                 @click="onPreambleClick" />
            </div>
            <div v-else>
                <p v-t="'dialog.registerInterest.confirmation'" />
                <ow-card-actions :secondary-button-text="$t('dialog.registerInterest.ctaInTouch')"
                                 :has-primary-button="false"
                                 has-secondary-button
                                 justify="center"
                                 @click="onClose" />
            </div>
        </div>
    </ow-modal>
</template>

<script setup lang="ts">
    import { computed,
             ref,
             watch } from 'vue'
    import { useStore } from 'vuex'

    import owCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'
    const model = defineModel<boolean>()
    const store = useStore()

    const props = withDefaults(defineProps<{
        title?: string
        sendHeapEventOnOpen?: boolean
        heapEventMetadata?: Record<string, string>
        preamble?: string
        heapTrackType?: string
    }>(), {
        sendHeapEventOnOpen: true,
    })

    const internalShowPreamble = ref(!isNullOrWhitespace(props.preamble))

    const defaultHeapEventMetadata = computed(() => ({
        userEmail: store.state.user.email,
        orgId: store.state.user?.organisationId,
    }))

    const onClose = () => {
        model.value = false
        internalShowPreamble.value = !isNullOrWhitespace(props.preamble) ?? false
    }

    const onPreambleClick = async () => {
        internalShowPreamble.value = false
        await heapTrackEvent()
    }

    const heapTrackEvent = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: props.heapTrackType ?? 'Dialog - Register Interest',
            metadata: props.heapEventMetadata ?? defaultHeapEventMetadata.value,
        })
    }

    watch(() => model.value, async (newValue) => {
        if (newValue && props.sendHeapEventOnOpen && props.heapTrackType && isNullOrWhitespace(props.preamble)) {
            await heapTrackEvent()
        }
    }, {
        immediate: true,
    })

</script>

<style lang="scss">
    @import './dialog-register-interest';
</style>
