import { StatusCode } from '@/consts/status-code'
import { apolloClient } from '@/graphql/apollo'
import { GET_COMPANIES_HOUSE_DATA_QUERY } from '@/graphql/queries/owner-companies-house.gql'
import { GET_TITLES_COMPLEXITY_QUERY } from '@/graphql/queries/title-complexity.gql'
import {
    GET_INTERSECTION_WITH_UNREG_LAND,
    GET_OWNER_NAMES,
    GET_OWNERS_ADDRESS_AND_TENURE,
    GET_TITLE_AVAILABILITY_QUERY,
    GET_TITLE_INFORMATION_QUERY,
    GET_TITLE_REGISTER_ML_TAGS,
    GET_TITLE_SCHEDULE_OF_NOTICE_OF_LEASES,
    GET_TITLE_TENURES,
} from '@/graphql/queries/title-information.gql'
import { GET_TITLES_QUERY_BY_COLUMN_SELECTION } from '@/graphql/queries/titles.gql'
import { isNullOrEmpty } from '@/utils/array-utils'
import * as derived from '@/utils/derived-data'
import {
    getCcodOcodMatchesOwner,
    getProprietorNameArray,
    getTenureType,
} from '@/utils/derived-data'
import { isNullOrWhitespace } from '@/utils/string-utils'
import { getTenureDisplayText } from '@/utils/title-enums'

import TitlesApi from './title.api'

export default class TitleInformationApi {
    static async getTitleInformationByTitleNumber(matterId, titleNumber) {
        const response = await apolloClient.query({
            query: GET_TITLE_INFORMATION_QUERY(),
            variables: {
                matterId,
                titleNumbers: [titleNumber],
            }, // Disables the cache so always goes back to the server
            fetchPolicy: 'network-only',
        })

        const data = response.data?.titleRegisters[0]
        if (!data) {
            return null
        }

        const title = {
            titleNumber: data.titleNumber,
            tenure: getTenureDisplayText(data?.tenure, data?.ccodOcodTitle?.tenure),
            qualityOfTenure: derived.getQualityOfTenure(data),
            tenureType: derived.getTenureType(data),
            editionDate: data.editionDate ? new Date(data.editionDate) : undefined,
            proprietorNames: derived.getProprietorNameArray(data),
            leaseTermText: null,
            addresses: [],
            companyRegNumbers: null,
        }

        if (!isNullOrEmpty(data?.lease)) {
            title.leaseTermText = data.lease[0]?.leaseTerm
        }

        if (!isNullOrEmpty(data?.propertyAddress) || data?.ccodOcodTitle) {
            const propertyAddressesAndPostcodes = derived.getPropertyAddressesAndPostcodes(data)
            // Flatten out all the addressLines into a single array
            const propertyAddresses = propertyAddressesAndPostcodes?.map(({ addressLines }) => ({ address: addressLines }))
            title.addresses = !isNullOrEmpty(propertyAddresses) ? propertyAddresses : []
        }

        const companyNums = derived.getCompanyRegNo(data)
        title.companyRegNumbers = isNullOrWhitespace(companyNums) ? null : companyNums.split(', ')

        return title
    }

    static async getTitleUnregisteredLandIntersection(titleNumber) {
        const response = await apolloClient.query({
            query: GET_INTERSECTION_WITH_UNREG_LAND(),
            variables: {
                titleNumbers: [titleNumber],
            },
            fetchPolicy: 'network-only',
        })

        const data = response.data?.titleRegisters[0]

        if (!data) {
            return null
        }

        const title = {
            titleNumber: data?.titleNumber,
            intersectsUnregLand: data?.unregLandIntersection[0]?.intersectsUnregLand,
        }

        return title.intersectsUnregLand
    }

    static async getTitleAvailability(matterId, titleNumbers) {
        const response = await apolloClient.query({
            query: GET_TITLE_AVAILABILITY_QUERY(),
            variables: {
                matterId,
                titleNumbers,
            },
            fetchPolicy: 'network-only',
        })

        const data = response.data?.titleRegisters

        if (!data) {
            return null
        }

        const availability = titleNumbers.reduce((acc, val) => {
            acc[val] = false
            return acc
        }, {})

        data.forEach(({ registerEntryIndicators }) => {
            if (registerEntryIndicators?.titleNumber) {
                availability[registerEntryIndicators.titleNumber] = true
            }
        })

        return availability
    }

    static async getOwnerNamesByTitleNumber(titleNumber) {
        const response = await apolloClient.query({
            query: GET_OWNER_NAMES(),
            variables: {
                titleNumbers: [titleNumber],
            },
            fetchPolicy: 'network-only',
        })

        const data = response?.data?.titleRegisters
        if (!data) {
            return []
        }

        // TODO: temp hack, i'm sorry
        for (const title of data) {
            if (isNullOrEmpty(title.proprietorshipParties)) {
                const titleInfoResponse = await TitlesApi.getByTitleNumber(titleNumber, null)
                if (titleInfoResponse.commonTitleInformation
                    && !isNullOrEmpty(titleInfoResponse.commonTitleInformation.proprietorships)
                ) {
                    title.proprietorshipParties = titleInfoResponse.commonTitleInformation.proprietorships
                }
            }
        }

        return data.map(x => getProprietorNameArray(x, false))[0] ?? []
    }

    static async getOwnerNamesByTitleNumbers(matterId, titleNumbers): Promise<Array<{titleNumber: string, owners: Array<string>, ownerKey: string }>> {
        const response = await apolloClient.query({
            query: GET_OWNER_NAMES(),
            variables: {
                matterId,
                titleNumbers,
            },
            fetchPolicy: 'network-only',
        })

        const data = response?.data?.titleRegisters
        if (!data) {
            return []
        }
        return data.map(x => {
            const owners = getProprietorNameArray(x, false) ?? []
            return {
                titleNumber: x.titleNumber,
                owners,
                ownerKey: owners.length > 0 ? owners.sort().map(x => x.toUpperCase()).join('||') : null,
            }
        })
    }

    static async getMLTagsByTitleNumbers(titleNumbers) {
        const response = await apolloClient.query({
            query: GET_TITLE_REGISTER_ML_TAGS(),
            variables: {
                titleNumbers,
            },
            fetchPolicy: 'network-only',
        })

        const data = response?.data?.titleRegisters
        if (!data) {
            return []
        }
        return data
    }

    static async getTenureByTitleNumbers(matterId, titleNumbers) {
        const response = await apolloClient.query({
            query: GET_TITLE_TENURES(),
            variables: {
                titleNumbers,
                matterId,
            },
            fetchPolicy: 'network-only',
        })

        const data = response?.data?.titleRegisters
        if (!data) {
            return []
        }
        return data.map(item => ({ titleNumber: item.titleNumber, tenure: getTenureType(item) }))
    }

    static async getComplexityByTitleNumbers(matterId, titleNumbers) {
        const response = await apolloClient.query({
            query: GET_TITLES_COMPLEXITY_QUERY(true, true),
            variables: titleNumbers.length > 0
                ? {
                    matterId,
                    titleNumbers,
                }
                : {
                    matterId,
                },
            // Disables the cache so always goes back to the server
            fetchPolicy: 'network-only',
        })

        const data = response?.data?.titleRegisters
        if (!data) {
            return []
        }
        return data
    }

    static async getTitleScheduleOfNoticeOfLeases(matterId: number, titleNumbers: string[]) {
        const response = await apolloClient.query({
            query: GET_TITLE_SCHEDULE_OF_NOTICE_OF_LEASES(),
            variables: {
                matterId,
                titleNumbers,
            },
            fetchPolicy: 'network-only',
        })

        const data = response.data?.titleRegisters

        if (!data) {
            return null
        }

        return data.map((title: any) => title.scheduleOfNoticesOfLease)
    }

    static async getTitlesByColumnSelection(matterId: string, columns: any[]) {
        const response = await apolloClient.query({
            query: GET_TITLES_QUERY_BY_COLUMN_SELECTION(columns),
            variables: {
                matterId,
            },
            // Disables the cache so always goes back to the server
            fetchPolicy: 'network-only',
        })

        const data = response.data?.titleRegisters
        if (!data) {
            return null
        }
        return data
    }

    static async getCompaniesHouseDataByTitleNumbers(titleNumbers: string[], matterId?: number) {
        const useMatter = matterId !== undefined
        const response = await apolloClient.query({
            query: GET_COMPANIES_HOUSE_DATA_QUERY(useMatter),
            variables: useMatter
                ? {
                    matterId,
                    titleNumbers,
                }
                : {
                    titleNumbers,
                },
            fetchPolicy: 'cache-first',
        }).then(({ data }) => {
            // Query work fine and result is the proper data object
            return data
        }).catch(({ networkError }) => {
            // if network error, we return undefined to signal to try again
            if (networkError && networkError?.statusCode !== StatusCode.INTERNAL_SERVER_ERROR) {
                return undefined
            }
            // We already log errors elsewhere, here we just return a null object
            return null
        })
        if (!response) {
            return response
        }

        const data = response?.titleRegisters
        if (!data) {
            return []
        }
        return data
    }

    static async getOwnersTenureAddressesByTitleNumbers(titleNumbers: string[]) {
        const response = await apolloClient.query({
            query: GET_OWNERS_ADDRESS_AND_TENURE(),
            variables: {
                titleNumbers,
            },
            fetchPolicy: 'network-only',
        })

        const data = response.data?.titleRegisters

        if (!data) {
            return null
        }

        return data
    }

    static async checkOwnerMatchWithCcodOcodByTitleNumber(titleNumber: string): Promise<boolean | undefined> {
        const response = await apolloClient.query({
            query: GET_OWNER_NAMES(),
            variables: {
                titleNumbers: [titleNumber],
            },
            fetchPolicy: 'network-only',
        })

        const data = response?.data?.titleRegisters
        if (isNullOrEmpty(data)) {
            return undefined
        }
        return getCcodOcodMatchesOwner(data[0])
    }
}
