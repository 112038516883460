<template>
    <v-card class="matter-details elevation-3">
        <v-card-title class="content__heading matter-details__title">
            Matter Details
            <div class="content__heading matter-details__title-actions">
                <div v-if="isDuplicateMattersEnabled">
                    <ow-button-ds v-t="'matterSettings.actions.duplicateMatter'"
                                  data-test="duplicate-matter-button"
                                  class="mr-2"
                                  @click="duplicateMatter" />
                </div>
                <ow-button-ds class="matter-details__title-icon mt-1"
                              data-test="matter-details-title-icon"
                              data-track="MAT-SETTINGS - Edit matter details"
                              icon
                              is-borderless
                              small
                              @click="toggleEditMode">
                    <v-icon v-if="isEditable">
                        $close
                    </v-icon>
                    <v-icon v-else>
                        $edit
                    </v-icon>
                </ow-button-ds>
            </div>
        </v-card-title>
        <v-card-text>
            <v-progress-linear v-if="loading"
                               data-test="matter-details-loading-bar"
                               indeterminate />
            <dl v-else-if="!isEditable && !loading"
                data-test="matter-details-read-only-info">
                <dt v-if="usesClientCodes"
                    class="body-copy--greyout">
                    Client Code:
                </dt>
                <dd v-if="usesClientCodes"
                    class="body-copy">
                    {{ details.clientCode ? details.clientCode : '-' }}
                </dd>

                <dt class="body-copy--greyout">
                    Matter Code:
                </dt>
                <dd class="body-copy">
                    {{ details.code ? details.code : '-' }}
                </dd>

                <dt class="body-copy--greyout">
                    Transaction Type:
                </dt>
                <dd class="body-copy">
                    {{ details.type ? details.type : '-' }}
                </dd>
            </dl>
            <form v-else
                  class="matter-details__form"
                  @submit="submitForm">
                <matter-client-code-inputs :client-code="details.clientCode"
                                           :matter-code="details.code"
                                           client-code-data-test-attribute="matters-details-client-code"
                                           matter-code-data-test-attribute="matter-details-code"
                                           @change="onMatterClientCodeChanged" />

                <fieldset>
                    <v-select id="editType"
                              v-model="transactionType"
                              :items="types"
                              class="matter-details__form--transaction-type"
                              data-test="matter-details-type-select"
                              data-track="MAT-SETTINGS - Edit transaction type"
                              label="Transaction type"
                              variant="underlined" />
                </fieldset>
            </form>
        </v-card-text>
        <ow-card-actions v-if="isEditable"
                         :primary-button-disabled="loading || !isValid || !isChanged"
                         :primary-button-loading="loadingState.updateMatterDetails"
                         :primary-button-text="$t('action.update')"
                         justify="start"
                         primary-button-data-test="matter-details-update-button"
                         primary-button-data-track="MAT-SETTINGS - Update matter details"
                         @primary-button-click="submitForm" />
    </v-card>
</template>

<script>
    import { mapActions,
             mapState } from 'vuex'

    import OwButtonDs from '@/components/core/ow-button-ds.vue'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import MatterClientCodeInputs from '@/components/matter/matter-client-code-inputs.vue'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'


    export default {
        name: 'MatterDetails',

        components: {
            OwButtonDs,
            MatterClientCodeInputs,
            OwCardActions,
        },

        mixins : [FlagsMixin],

        props: {
            details: {
                type: Object,
                required: true,
            },
            types: {
                type: Array,
                required: true,
            },
            loading: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'update',
            'duplicate-matter',
        ],


        data() {
            return {
                isEditable: false,
                isValid: true,
                isChanged: false,
                form: {
                    clientCode: this.details.clientCode,
                    code: this.details.code,
                    type: this.details.type,
                },
            }
        },

        computed: {
            ...mapState({
                loadingState: state => state.matter.loadingState,
                usesClientCodes: state => state.user.usesClientCodes,
            }),

            transactionType: {
                get() {
                    return this.form.type ?? this.details.type
                },
                set(val) {
                    this.isChanged = true
                    this.form.type = val
                    this.logHeapEvent({
                        type: 'MAT-SETTINGS - Edit transaction type',
                        metadata: {
                            transactionType: val,
                        },
                    })
                },
            },
        },

        watch: {
            loading() {
                // If updating, then is loading. Once not loading, then switch to not be editable
                this.isEditable = this.loading ? this.isEditable : false
            },

            'loadingState.updateMatterDetails'() {
                this.isEditable = this.loadingState.updateMatterDetails || false
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            submitForm() {
                this.isChanged = false
                this.$emit('update', this.form)
            },

            toggleEditMode() {
                this.isEditable = !this.isEditable

                if (!this.isEditable) {
                    this.form.clientCode = ''
                    this.form.code = ''
                    this.form.type = ''
                }
            },

            duplicateMatter() {
                this.$emit('duplicate-matter')
            },

            onMatterClientCodeChanged(matterClientCode) {
                this.isChanged = true
                this.isValid = matterClientCode !== null
                this.form.code = matterClientCode?.matterCode ?? null
                this.form.clientCode = matterClientCode?.clientCode ?? null
            },
        },
    }
</script>

<style lang="scss"
       scoped>
@import './matter-details';
</style>
