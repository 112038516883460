<template>
    <div class="company-group-card pa-4 align-center d-flex flew-row justify-space-between gc-4">
        <div class="d-flex w-100 justify-start">
            <section class="company-group-card__name d-flex flex-column">
                <span class="body-highlight">{{ groupName }}</span>
                <span class="body-regular-small">{{ $t('assetMonitoring.preferences.cards.companyGroup.companies', {
                    count: companies.length,
                }) }}</span>
            </section>
            <section class="company-group-card__companies">
                <div v-for="company in companies.slice(0, 10)"
                     :key="company.companyNumber">
                    <span class="body-regular-small">{{ company.name }}</span>
                </div>
                <section v-if="companies.length > 10"
                         class="d-block company-group-card__more">
                    <span class="body-regular-small">{{ `+ ${companies.length - 10} more` }}</span>
                </section>
            </section>
        </div>
        <section>
            <ow-toggle v-model="toggleModel" />
        </section>
        <section>
            <ow-button is-primary
                       is-link
                       @click="$emit('edit-group')">
                {{ $t('action.edit') }}
            </ow-button>
        </section>
    </div>
</template>

<script setup lang="ts">
    import { computed } from "vue"

    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwCard from "@/components/core/ow-card.vue"
    import OwToggle from "@/components/core/ow-toggle.vue"
    const emit = defineEmits<{
        (e: 'edit-group')
        (e: 'toggle-group')
    }>()

    const props = defineProps<{
        isEnabled: boolean
        groupName: string
        companies: {
            name: string
            address: string
            companyNumber: string
        }[]
    }>()

    const toggleModel = computed({
        get: () => props.isEnabled,
        set: () => {
            emit('toggle-group')
        },
    })
</script>

<style scoped lang="scss">
    @import './company-group-card.scss';
</style>