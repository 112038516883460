<template>
    <div class="overlays-list">
        <overlays-options-panel v-if="showOverlaysOptionsPanel"
                                :selected-overlays="selectedOverlays"
                                anchor-element-selector=".overlays-list"
                                @dismiss="onOverlaysSelectionDismiss"
                                @show-selection="emit('show', selectedOverlays.map(x => x.overlayId))"
                                @hide-selection="emit('hide', selectedOverlays.map(x => x.overlayId))"
                                @change-selection="onChangeSelection"
                                @remove="onRemoveClick" />
        <ul>
            <overlays-list-item v-for="overlay in overlays"
                                :key="overlay.overlayId"
                                :data-overlay="`${overlay.overlayId}`"
                                data-test="overlay-list-item"
                                :selected="isSelected(overlay)"
                                :expanded="expandedOverlay === overlay"
                                :overlay="overlay"
                                @mouseleave="emit('highlight', null)"
                                @mouseover="emit('highlight', overlay.overlayId)"
                                @select="onSelect(overlay)"
                                @deselect="onDeselect(overlay)"
                                @click="onItemClick(overlay)"
                                @show="emit('show', [overlay.overlayId])"
                                @hide="emit('hide', [overlay.overlayId])" />
        </ul>
        <overlays-name-edit-modal />
    </div>
</template>
<script setup lang="ts">
    import olMap from 'ol/Map'
    import {
        computed,
        nextTick,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import OverlaysListItem from '@/components/map/overlays/components/overlays-list-item.vue'
    import OverlaysOptionsPanel from '@/components/map/overlays/components/overlays-options-panel.vue'
    import { OverlayListItemModel } from '@/components/map/overlays/overlays-types'
    import OverlaysNameEditModal from "@/components/overlays/overlay-name-modal.vue"
    import { OVERLAYS_MUTATE_SHOW_NAME_MODAL } from '@/store/modules/overlays/types'
    import { validBoundingBox } from '@/utils/map-utils'

    const store = useStore()
    interface Props {
        targetMap: olMap | null,
        overlays: Array<OverlayListItemModel>,
        preExpandedOverlay: OverlayListItemModel | null
    }
    const props = defineProps<Props>()
    const emit = defineEmits<{
        (e: 'remove', overlayIds: Array<string>): void
        (e: 'highlight', overlayId: string): void
        (e: 'show', overlayIds: Array<string>): void
        (e: 'hide', overlayIds: Array<string>): void
    }>()

    // Selection
    const selectedOverlays = ref<Array<OverlayListItemModel>>([])
    const isSelected = (overlay: OverlayListItemModel) => selectedOverlays.value.includes(overlay)
    const onSelect = (overlay: OverlayListItemModel) => {
        selectedOverlays.value.push(overlay)
    }
    const onDeselect = (overlay: OverlayListItemModel) => {
        selectedOverlays.value = selectedOverlays.value.filter((item) => item !== overlay)
    }
    const showOverlaysOptionsPanel = computed<boolean>(() => {
        return selectedOverlays.value.length > 0
    })
    const onOverlaysSelectionDismiss = () => {
        selectedOverlays.value = []
    }

    // Expand/collapse
    const expandedOverlay = ref<OverlayListItemModel | null>(null)
    const onItemClick = async (overlay: OverlayListItemModel) => {
        if (expandedOverlay.value === overlay) {
            expandedOverlay.value = null
        } else {
            expandedOverlay.value = overlay
        }

        if (props.targetMap && validBoundingBox(overlay.boundingBox)) {
            props.targetMap.getView().fit(overlay.boundingBox, {
                duration: 500,
                padding: [50, 50, 50, 50],
            })
        }

        await nextTick()
        const element = document.querySelector(`[data-overlay="${ overlay.overlayId }"]`)
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    watch(() => props.preExpandedOverlay, async () => {
        if (props.preExpandedOverlay) {
            onItemClick(props.preExpandedOverlay)
        }
    }, { immediate: true })

    // Remove
    const onRemoveClick = () => {
        emit('remove', selectedOverlays.value.map(x => x.overlayId))
        selectedOverlays.value = []
    }

    const onChangeSelection = () => {
        if (selectedOverlays.value.length === 1) {
            store.commit(OVERLAYS_MUTATE_SHOW_NAME_MODAL, { show: true, overlay: selectedOverlays.value[0] })
        }
    }

    defineExpose({
        expandedOverlay,
    })

</script>
<style scoped lang="scss">
  @import 'overlays-list.scss';
</style>
