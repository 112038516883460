<template>
    <div class="full-width ow-page-container"
         :class="{
             [pageName]: true,
             '--inline-step-flow': inlineStepFlow,
         }">
        <header v-if="headerTitle"
                class="full-width__header">
            <div>
                <h1 class="full-width__header--title content__heading">
                    <v-icon v-if="hasHeaderIcon">
                        {{ headerIcon }}
                    </v-icon>
                    <component :is="headerSvgIcon"
                               v-else />
                    {{ headerTitle }}
                </h1>
                <div v-if="headerDescription"
                     class="full-width__header--description d-flex body-regular">
                    {{ headerDescription }}
                </div>
            </div>

            <template v-if="hasNavigationSlotContent && inlineStepFlow"
                      class="full-width__sub-navigation">
                <slot name="navigation" />
            </template>

            <div v-if="hasHeaderActionsSlotContent"
                 class="full-width__header--actions">
                <slot name="headerActions" />
            </div>
        </header>

        <template v-if="hasNavigationSlotContent && !inlineStepFlow"
                  class="full-width__sub-navigation">
            <slot name="navigation" />
        </template>

        <section class="full-width__content">
            <slot />
        </section>
        <section v-if="hasFooterSlotContent"
                 class="full-width__footer">
            <slot name="footer" />
        </section>
    </div>
</template>

<script lang="ts" setup>
    import type { Component } from 'vue'
    import { computed,
             useSlots } from 'vue'

    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    interface Props {
        pageName: string,
        headerTitle?: string,
        headerDescription?: string,
        headerIcon?: string,
        headerSvgIcon?: Component,
        inlineStepFlow?: boolean,
    }

    const props = defineProps<Props>()

    const slots = useSlots()
    const hasNavigationSlotContent = computed((): boolean => {
        return Object.keys(slots).includes('navigation') &&
            !isNullOrEmpty(slots?.navigation())
    })
    const hasHeaderActionsSlotContent = computed((): boolean => {
        return Object.keys(slots).includes('headerActions') &&
            !isNullOrEmpty(slots?.headerActions())
    })
    const hasFooterSlotContent = computed((): boolean => {
        return Object.keys(slots).includes('footer') &&
            !isNullOrEmpty(slots?.footer())
    })

    const hasHeaderIcon = computed((): boolean => !isNullOrWhitespace(props?.headerIcon))
</script>

<style lang="scss"
       scoped>
    @import './full-width';
</style>
