<template>
    <div class="matter-settings-loading-skeleton">
        <ow-loading-skeleton margin="24 0 8 0"
                             width="176" />
        <ow-loading-skeleton width="312" />
        <div class="matter-settings-loading-skeleton__top-card-row">
            <ow-loading-skeleton height="215px" />
            <ow-loading-skeleton height="215px" />
        </div>
        <ow-loading-skeleton height="215px"
                             margin-top="32"
                             width="555" />
    </div>
</template>

<script lang="ts">
    import owLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'

    export default {
        components: {
            owLoadingSkeleton,
        },
    }
</script>

<style lang="scss">
    @import './matters-settings-loading-skeleton.scss';
</style>
