import { COPILOT_SUPPORTED_DOC_TYPES } from '@/components/copilot/constants'
import { DOCUMENT_SOURCE } from '@/consts/document-source'
import {
    TITLE_GET_ALL_TITLE_DATA_SORTED_BY_NUMBER,
    TITLE_GET_IS_PLAN_PURCHASED,
    TITLE_GET_IS_REGISTER_PURCHASED,
    TITLE_GET_OWNS_COPILOT_OC2_DOCS,
    TITLE_GET_OWNS_OC2_DOCUMENTS,
    TITLE_GET_SELECTED_TITLE,
    TITLE_GET_SELECTED_TITLE_NUMBER,
    TITLE_GET_SHOW_MULTI_TITLE_PANEL,
    TITLE_GET_TITLES_LOADING,
} from '@/store/modules/titles/types'
import {
    dynamicSort,
    isNullOrEmpty,
} from '@/utils/array-utils'

export default {
    [TITLE_GET_TITLES_LOADING]: state => state.loading,
    [TITLE_GET_SELECTED_TITLE]: state => state.selectedTitle,
    [TITLE_GET_SELECTED_TITLE_NUMBER]: state => state.selectedTitleNumber,
    [TITLE_GET_SHOW_MULTI_TITLE_PANEL]: state => state.showMultiSelectTitlePanel,

    /**
     * Get all the titles sorted by the title number.
     * @param state
     * @returns {{errorMessage: *, selectedTitleNumber: *, titles: *, selectedTitle: *}}
     */
    [TITLE_GET_ALL_TITLE_DATA_SORTED_BY_NUMBER](state) {
        if (!isNullOrEmpty(state.titles)) {
            const sortedTitles = state.titles.sort(dynamicSort('titleNumber'))

            return {
                selectedTitleNumber: state.selectedTitleNumber,
                selectedTitle: state.selectedTitle,
                titles: sortedTitles,
                errorMessage: state.errorMessage,
            }
        }
        return []
    },

    [TITLE_GET_IS_REGISTER_PURCHASED](state) {
        const validSource = (state.selectedTitle?.record?.source === DOCUMENT_SOURCE.HMLR ||
            state.selectedTitle?.record?.source === DOCUMENT_SOURCE.SCOTLAND)
        const registerAvailability = state.selectedTitle?.officialCopiesAvailability?.results?.titleRegister
        return (validSource && (Boolean(registerAvailability?.documentId) ||
            Boolean(registerAvailability?.editionDate)))
    },

    [TITLE_GET_IS_PLAN_PURCHASED](state) {
        const planAvailability = state.selectedTitle.officialCopiesAvailability.results.titlePlan
        return Boolean(planAvailability.documentId)
    },

    [TITLE_GET_OWNS_OC2_DOCUMENTS](state) {
        const oc2Docs = state.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments
        if (isNullOrEmpty(oc2Docs)) {
            return false
        }
        return oc2Docs.some(doc => Boolean(doc?.documentId))
    },

    [TITLE_GET_OWNS_COPILOT_OC2_DOCS](state) {
        const oc2Docs = state.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments
        if (isNullOrEmpty(oc2Docs)) {
            return false
        }
        return oc2Docs.some(doc => Boolean(doc?.documentId) && COPILOT_SUPPORTED_DOC_TYPES.includes(doc?.documentType))
    },
}
