<template>
    <div class="schedule-of-lease-record">
        <h3 class="schedule-of-lease-record__header"
            @click="isExpanded = !isExpanded">
            <ow-text-highlight :queries="[searchString]"
                               class="schedule-of-lease-record__header--name label-caps-medium"
                               @match-count-changed="nameSearchMatchCount = $event">
                {{ $t('titles.sonol.title') }}
            </ow-text-highlight>
            <v-icon v-if="!isExpanded">
                $chevron-down
            </v-icon>
            <v-icon v-else>
                $chevron-up
            </v-icon>
        </h3>
        <div v-for="(entry, index) in scheduleOfLeases"
             v-show="isExpanded"
             :key="index"
             class="schedule-of-lease-record__entry">
            <div class="schedule-of-lease-record__entry--code">
                <ow-text-highlight :queries="[searchString]"
                                   class="label-caps-medium"
                                   data-test="review-assistant-register-record-entry-code label-caps-medium"
                                   @match-count-changed="indexSearchMatchCount[index] = $event">
                    {{ index + 1 }}
                </ow-text-highlight>
            </div>
            <section>
                <schedule-of-lease-record-entry :entry="entry"
                                                :highlight-dates="highlightDates"
                                                :search-filter="searchString"
                                                @scrolled="selectEntry(entry, index)"
                                                @match-count-changed="entrySearchMatchCount[index] = $event" />
            </section>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        inject,
        PropType,
        ref,
        watch,
    } from 'vue'

    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import ScheduleOfLeaseRecordEntry from '@/components/title-panel/v2/register/schedule-of-lease-record-entry.vue'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'

    const props = defineProps({
        scheduleOfLeases: {
            type: Array as PropType<Array<IScheduleOfLeaseEntry>>,
            default: () => [],
        },

        highlightDates: {
            type: Boolean,
            required: false,
        },
        searchString: {
            type: String,
            required: false,
        },
    })

    const emit = defineEmits<{
        (e: 'entry-clicked', entry: IScheduleOfLeaseEntry, index: string): void
        (e: 'match-count-changed', value: number): void
    }>()

    const isExpanded = ref <boolean>(true)
    const nameSearchMatchCount = ref<number>(0)
    const indexSearchMatchCount = computed<number[]>(() => new Array(props.scheduleOfLeases?.length ?? 0).fill(0))
    const entrySearchMatchCount = computed<number[]>(() => new Array(props.scheduleOfLeases?.length ?? 0).fill(0))
    const totalSearchMatchCount = computed(() => {
        return nameSearchMatchCount.value +
            indexSearchMatchCount.value?.reduce((a, b) => a + b, 0) +
            entrySearchMatchCount.value?.reduce((a, b) => a + b, 0)
    })
    watch(totalSearchMatchCount, (value) => {
        emit('match-count-changed', value)
    })

    const selectEntry = (entry: IScheduleOfLeaseEntry, index: number): void => {
        emit('entry-clicked', entry, String(index))
    }
</script>

<style lang="scss">
    @import './schedule-of-lease-record.scss';
</style>
