import { StyleLike } from 'ol/style/Style'
import { LayerNames } from '@/consts/map-layers'
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { FeatureLike } from 'ol/Feature'
import { MapBrowserEvent } from 'ol'
import i18n from '@/plugins/i18n'

import {
    getEnvironmentalRolloverOptions,
    SssiFeaturesType,
} from './environmental-layers-common'
export type SSSILayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void
}

export class SssiLayer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: SSSILayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:vsssi_combined'
        this.name = LayerNames.SSSI
        this.i18nNameRef = 'map.options.legend.environmental[3]'
        this.attributionText = [`${ i18n.global.t('map.options.environmental.attribution.sssi') } - © ${ i18n.global.t('map.options.environmental.source.england') } & ${ i18n.global.t('map.options.environmental.source.wales') }- released ${ settings.sssiReleaseDate }`]
        this.rolloverOptions = getEnvironmentalRolloverOptions(LayerNames.SSSI, this.style)

        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Toggle SSSI layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                let textArray = []
                if (features.length) {
                    const properties = features[0].getProperties() as SssiFeaturesType
                    textArray = SssiLayer.getHoverTextArray(properties)
                }
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public style:StyleLike = new Style({
        fill: new Fill({
            color: 'rgba(100, 221, 23, 0.8)',
        }),
        stroke: new Stroke({
            color: '#ffffff',
            width: 2,
        }),
    })

    public static getHoverTextArray(properties: SssiFeaturesType):string[] {
        const source = properties.england ? `${ i18n.global.t('map.options.environmental.source.england') }` : `${ i18n.global.t('map.options.environmental.source.wales') }`
        return [
            `${ i18n.global.t('map.options.environmental.hover.sssi.name') }: ${ properties.name }`,
            `${ i18n.global.t('map.options.environmental.hover.sssi.source') }: ${ source }`,
        ]
    }
}
