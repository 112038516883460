<template>
    <ow-modal v-model="showLicenceModal"
              class="ow-file-uploader"
              :title="t('admin.user.dialog.licenceManagement.title')">
        <section v-dompurify-html="t('admin.user.dialog.licenceManagement.instructions')"
                 class="body-regular mb-3" />
        <section>
            <user-licences v-model:licence-type="selectedLicenceType" />
        </section>
        <section v-if="licenceUpdateError"
                 class="d-flex error-text user-dialog__errors">
            {{ licenceErrorMessage ? licenceErrorMessage : t('admin.user.dialog.licenceManagement.error') }}
        </section>
        <template #actions>
            <ow-card-actions has-secondary-button
                             primary-button-data-test="con29Dwc-product-config-confirm"
                             secondary-button-data-test="con29Dwc-product-config-cancel"
                             :primary-button-text="t('action.confirm')"
                             :primary-button-disabled="maxLicencesExceeded || !hasLicencesToChange"
                             @primary-button-click="setLicencesForuserLicenceManager"
                             @secondary-button-click="$emit('close')" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import { computed,
             ref,
             watch } from 'vue'
    import { useI18n } from 'vue-i18n'

    import UsersApi from '@/api/users.api'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import UserLicences from '@/components/settings/users/user-licences.vue'
    import { LicenceType } from '@/enums/licenceType'

    import { UserLicenceManager } from './user-licence-manager'

    const { t } = useI18n()

    const props = defineProps<{
        userLicenceManager: UserLicenceManager
        userIsSystemAdmin: boolean
        organisationId: number,
        maxStandardUsersCount: number,
        maxPremiumUsersCount: number,
        maxLiteUsersCount: number,
        activeStandardUsersCount: number,
        activePremiumUsersCount: number,
        activeLiteUsersCount: number,
    }>()

    const emit = defineEmits<{
        'update-users',
        'close'
    }>()

    const hasLicencesToChange = computed(() => props.userLicenceManager.hasLicencesToChange(selectedLicenceType.value))

    const showLicenceModal = defineModel<boolean>()
    const selectedLicenceType = ref<LicenceType>(LicenceType.Standard)
    const licenceUpdateError = ref(false)
    const maxLicencesExceeded = ref(false)
    const licenceErrorMessage = ref('')

    watch(() => showLicenceModal.value, (newValue) => {
        resetModal()
        if(!newValue) {
            return
        }
        evaluateLicenceCounts()
    })

    watch(() => selectedLicenceType.value, (newValue) => {
        resetModal()
        if(!newValue) {
            return
        }
        evaluateLicenceCounts()
    })

    const resetModal = () => {
        licenceErrorMessage.value = ''
        licenceUpdateError.value = false
        maxLicencesExceeded.value = false
    }

    const evaluateLicenceCounts = () => {
        let licenceCount
        let maxUsersCount
        let currentUserCount

        switch (selectedLicenceType.value) {
            case LicenceType.Standard:
                licenceCount = props.userLicenceManager.noneLicenceCount + props.userLicenceManager.premiumLicenceCount + props.userLicenceManager.liteLicenceCount
                currentUserCount = props.activeStandardUsersCount
                maxUsersCount = props.maxStandardUsersCount
                break
            case LicenceType.Premium:
                licenceCount = props.userLicenceManager.noneLicenceCount + props.userLicenceManager.standardLicenceCount + props.userLicenceManager.liteLicenceCount
                currentUserCount = props.activePremiumUsersCount
                maxUsersCount = props.maxPremiumUsersCount
                break
            case LicenceType.Lite:
                licenceCount = props.userLicenceManager.noneLicenceCount + props.userLicenceManager.standardLicenceCount + props.userLicenceManager.premiumLicenceCount
                currentUserCount = props.activeLiteUsersCount
                maxUsersCount = props.maxLiteUsersCount
                break
        }

        if (licenceCount + currentUserCount > maxUsersCount) {
            licenceUpdateError.value = true
            licenceErrorMessage.value = t('admin.user.dialog.licenceManagement.maxLicencesExceeded')
            maxLicencesExceeded.value = true
        }
    }
    const setLicencesForuserLicenceManager = async () => {
        try {
            const organsationId = props.userIsSystemAdmin ? props.organisationId : null
            await UsersApi.setSelectedLicencesForUsers(
                props.userLicenceManager.witnessUsers.map(x => x.id),
                selectedLicenceType.value,
                organsationId)
            licenceUpdateError.value = null
            emit('update-users')
        } catch (error) {
            licenceUpdateError.value = error
        }
    }
</script>