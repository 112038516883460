<template>
    <div id="titlePanelSummary"
         class="title-panel pa-0">
        <component :is="row.component"
                   v-for="(row, index) in rows"
                   :key="index"
                   :current-matter-id="currentMatterId"
                   :is-loading="isLoading"
                   :is-ordering-allowed="isOrderingAllowed"
                   :matter-id="currentMatterId"
                   :official-copies="officialCopies"
                   :order-with-checkout="orderWithCheckout"
                   :selected-title="selectedTitle"
                   :selected-title-number="selectedTitleNumber"
                   :tab-controller="tabController"
                   :show-title-complexity="showTitleComplexity"
                   :action-controller="actionController"
                   :title-number="selectedTitleNumber"
                   :is-full-screen="isFullScreen"
                   :user-has-vts-charge="titleSummaryCharge !== null"
                   @set-selected-tab="setSelectedTab" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        markRaw,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import Boundary from "@/components/title-panel/v2/cards/boundary.vue"
    import OrderDocuments from "@/components/title-panel/v2/cards/order-documents.vue"
    import Overview from "@/components/title-panel/v2/cards/overview.vue"
    import Ownership from "@/components/title-panel/v2/cards/ownership.vue"
    import Searches from "@/components/title-panel/v2/cards/searches.vue"
    import TitleComplexity from '@/components/title-panel/v2/cards/title-complexity.vue'
    import {
        ITitlePanelActionController,
        ITitlePanelTabController,
    } from "@/composables/use-title-panel"
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import {
        TITLE_GET_SELECTED_TITLE,
        TITLE_GET_SELECTED_TITLE_NUMBER,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'

    const rows = ref<{
        component,
    }[]>([])
    const store = useStore()
    const selectedTitle = computed(() => store.getters[TITLE_GET_SELECTED_TITLE])
    const selectedTitleNumber = computed({
        get: () => store.getters[TITLE_GET_SELECTED_TITLE_NUMBER],
        set: (value) => store.dispatch(TITLE_LOOKUP_TITLE, value),
    })
    const bgDataLoaded = computed(() => selectedTitle.value.record?.titleMetadata?.bgDataLoaded)
    const currentMatterId = computed(() => store.state.matter?.currentMatter?.id)
    const titleSummaryCharge = computed(() => store.state.user.titleSummaryCharge)
    const documentSource = computed(() => selectedTitle?.value?.record?.source)
    const officialCopies = computed(() => selectedTitle?.value?.officialCopiesAvailability?.results)

    const props = withDefaults(defineProps<{
        showTitleComplexity?: boolean,
        isOrderingAllowed?: boolean,
        orderWithCheckout?: boolean,
        tabController: ITitlePanelTabController,
        actionController: ITitlePanelActionController,
        isFullScreen?: boolean,
        isLoading?: boolean,
    }>(), {
        showTitleComplexity: true,
        isOrderingAllowed: true,
        orderWithCheckout: false,
        isLoading: false,
        isFullScreen: false,

    })

    const emit = defineEmits<{
        (e: 'set-selected-tab', tabIndex: TitlePanelTabName),
    }>()

    const setSelectedTab = (tabIndex: TitlePanelTabName) => {
        emit('set-selected-tab', tabIndex)
    }

    const componentsToRender = (documentSource?: string) => {
        const components = []
        if (!documentSource) {
            return [
                Overview,
                Ownership,
                OrderDocuments,
            ]
        }
        switch (documentSource) {
            case DOCUMENT_SOURCE.SCOTLAND:
                components.push(
                    Overview,
                    Ownership,
                    OrderDocuments,
                )
                break
            default:
                components.push(
                    Overview,
                    Ownership,
                    TitleComplexity,
                    Boundary,
                    Searches,
                    OrderDocuments,
                )
        }
        return components
    }

    const renderComponents = (documentSource?: string) => {
        rows.value = componentsToRender(documentSource)
            .filter(component => component)
            .map((component) => {
                return {
                    component: markRaw(component),
                }
            })
    }

    watch(() => documentSource.value, (val, old) => {
        if (val !== old) {
            if (val) {
                renderComponents(val)
            }
        }
    })

    watch(() => selectedTitleNumber.value, () => {
        renderComponents(documentSource.value)
    }, {
        immediate: true,
    })

    onMounted(() => {
        renderComponents(documentSource.value)
    })

</script>

<style scoped lang="scss">
@import './summary-tab-renderer.scss';
</style>
