import {
    Arrowhead,
    SketchMode,
    SketchType,
} from '@/enums/sketches-enums'
import {
    Fill,
    RegularShape,
    Stroke,
    Style,
    Text,
} from 'ol/style'
import {
    getOLStyleForOWStyleDefinition,
    StyleTarget,
} from '@/utils/style-utils'
import {
    LineString,
    MultiLineString,
    MultiPoint,
    MultiPolygon,
    Polygon,
} from 'ol/geom'
import CircleStyle from 'ol/style/Circle'
import { Coordinate } from 'ol/coordinate'
import { FeatureLike } from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'
import { ISketch } from '@/store/modules/sketches/types/sketch'
import { MapBrowserEvent } from 'ol'
import Point from 'ol/geom/Point'
import { SKETCH_COMPLEX_GEOJSON_MIN_LENGTH } from '@/consts/sketch-consts'
import { hexToRGBArray } from './colour-utils'
import { hatchStyleFunction } from './map-utils'
import { isNullOrWhitespace } from './string-utils'

export const drawSketchSegmentStartStyle = new Style({
    image: new CircleStyle({
        radius: 6,
        fill: new Fill({
            color: '#FFFFFF',
        }),
        stroke: new Stroke({
            color: '#0098FF',
            width: 2,
        }),
    }),
})
const drawSketchSegmentMidPointStyle = new Style({
    image: new CircleStyle({
        radius: 4,
        fill: new Fill({
            color: '#FFFFFF',
        }),
        stroke: new Stroke({
            color: '#0098FF',
            width: 2,
        }),
    }),
})

export const getLineStringsFromGeometry = (geometry: Geometry): Array<LineString> => {
    const geometryType = geometry.getType()
    let lines = []

    switch (geometryType) {
        case 'Polygon':
            lines = [new LineString((geometry as Polygon).getCoordinates()[0])]
            break
        case 'MultiPolygon':
            lines = (geometry as MultiPolygon).getPolygons().flatMap(polygon => new LineString(polygon.getCoordinates()[0]))
            break
        case 'LineString':
            lines = [geometry as LineString]
            break
        case 'MultiLineString':
            lines = (geometry as MultiLineString).getLineStrings()
            break
    }
    return lines
}

/**
 * Get the style for the sketch features that have been drawn.
 */
export const getDrawSketchStyle = (feature: FeatureLike, sketch: ISketch, minSegmentLength: number = 10): Style | Style[] => {
    const styleTarget = getStyleTargetForSketchType(sketch.sketchType)
    if (sketch?.geoJson?.length > SKETCH_COMPLEX_GEOJSON_MIN_LENGTH && sketch.sketchType !== SketchType.Text) {
        // If we've already computed a style independent of geometry/resolution,
        // we may as well cache it rather than re-compute it each time.
        if (sketch.olDrawingStyle) {
            return sketch.olDrawingStyle
        } else {
            sketch.olDrawingStyle = getOLStyleForOWStyleDefinition(sketch, getStyleTargetForSketchType(sketch.sketchType))
            return sketch.olDrawingStyle
        }
    }

    const result = []
    const geometry = feature.getGeometry()
    const geometryType = geometry.getType()
    const lines = getLineStringsFromGeometry(geometry as Geometry)

    if (lines.length) {
        const lineStyle = getOLStyleForOWStyleDefinition(sketch)
        if (lineStyle instanceof Style) {
            result.push(lineStyle)
        } else {
            result.push(...lineStyle)
        }
        for (const line of lines) {
            const segmentCount = line.getCoordinates().length - 1
            let segmentIndex = 0
            line.forEachSegment((a, b) => {
                const segment = new LineString([a, b])

                // Small circle style for segment midpoints.
                if (segment.getLength() > minSegmentLength) {
                    const midPointStyle = drawSketchSegmentMidPointStyle.clone()
                    const segmentMidPoint = new Point(segment.getCoordinateAt(0.5))
                    midPointStyle.setGeometry(segmentMidPoint)
                    result.push(midPointStyle)

                    // Larger circle style for segment start points.
                    const startPointStyle = drawSketchSegmentStartStyle.clone()
                    const segmentStartPoint = new Point(segment.getFirstCoordinate())
                    startPointStyle.setGeometry(segmentStartPoint)
                    result.push(startPointStyle)

                    // Add endpoint for lines.
                    if (geometryType === 'LineString' || geometryType === 'MultiLineString') {
                        const endPointStyle = drawSketchSegmentStartStyle.clone()
                        const segmentEndPoint = new Point(segment.getLastCoordinate())
                        endPointStyle.setGeometry(segmentEndPoint)
                        result.push(endPointStyle)
                    }
                }

                // Line arrowheads.
                if (geometryType === 'LineString' || geometryType === 'MultiLineString') {
                    addArrowheads(a, b, segmentIndex, segmentCount, sketch).forEach((arrowhead) => {
                        result.push(arrowhead)
                    })
                }
                segmentIndex++
            })
        }
    } else if (geometryType === 'Point' || geometryType === 'MultiPoint') {
        if (sketch.sketchType === SketchType.Marker || sketch.sketchType === SketchType.Text) {
            result.push(getOLStyleForOWStyleDefinition(sketch, styleTarget))
        }
        // Add a circle on the start point the point.
        const points = geometryType === 'Point'
            ? [(geometry as Point).getFirstCoordinate()]
            : (geometry as MultiPoint).getPoints().map(x => x.getFirstCoordinate())
        for (const point of points) {
            const pointStyle = drawSketchSegmentStartStyle.clone()
            const pointGeom = new Point(point)
            pointStyle.setGeometry(pointGeom)
            result.push(pointStyle)
        }
    }

    return result
}

export const addArrowheads = (a: Coordinate, b: Coordinate, segmentIndex, segmentCount, sketch: ISketch, highlight = false): Array<Style> => {
    const result = []

    // Add start arrowhead
    if ((sketch.arrowhead === Arrowhead.Both) && (segmentIndex === 0)) {
        result.push(addArrowhead(a, b, sketch, Arrowhead.Start, highlight))
    }

    // Add end arrowhead
    if ((sketch.arrowhead === Arrowhead.End || sketch.arrowhead === Arrowhead.Both) && (segmentIndex === segmentCount - 1)) {
        result.push(addArrowhead(b, a, sketch, Arrowhead.End, highlight))
    }

    return result
}

const calculateRotation = (a: Coordinate, b: Coordinate, arrowhead: Arrowhead): number => {
    const [dx, dy] = arrowhead === Arrowhead.Start ? [b[0] - a[0], b[1] - a[1]] : [a[0] - b[0], a[1] - b[1]]
    const rotationOffset = arrowhead === Arrowhead.End ? -Math.PI / 2 : Math.PI / 2
    return Math.atan2(dy, dx) + rotationOffset
}
export const addArrowhead = (a: Coordinate, b: Coordinate, sketch: ISketch, arrowhead: Arrowhead, highlight = false): Style => {
    const strokeColour = highlight ? '#FFFFFF' : sketch.strokeColour
    return new Style({
        geometry: new Point(a),
        image: new RegularShape({
            fill: new Fill({ color: strokeColour }),
            points: 3,
            radius: 3 + sketch.strokeWidth,
            stroke: new Stroke({ color: strokeColour, width: sketch.strokeWidth }),
            displacement: [0, -sketch.strokeWidth * 2 + (sketch.strokeWidth === 2 ? 1 : sketch.strokeWidth === 4 ? 2 : 3)],
            rotation: -calculateRotation(a, b, arrowhead),
        }),
    })
}

/**
 * Get the style for the sketch features while drawing.
 */
export const sketchInProgressStyleFn = (feature: FeatureLike, sketchType: SketchType): Array<Style> => {
    const result: Array<Style> = []
    const geomType = feature.getGeometry().getType()
    let showStartPoint = false
    // Get the base style for the sketch type.
    switch (geomType) {
        case 'Polygon':
        case 'MultiPolygon':
            // We don't show the polygon fill while drawing.
            break
        case 'LineString':
        case 'MultiLineString':
        case 'GeometryCollection':
            result.push(new Style({
                stroke: new Stroke({
                    color: '#0098ff',
                    width: 4,
                    lineCap: 'square',
                    lineDash: [10, 10],
                }),
            }))
            showStartPoint = sketchType === SketchType.Area
            break
        case 'Point':
        case 'MultiPoint':
            result.push(sketchStartEndPoint)
            showStartPoint = sketchType === SketchType.Area
            break
    }
    if (showStartPoint) {
        const lines = getLineStringsFromGeometry(feature.getGeometry() as Geometry)
        if (lines.length) {
            const line = lines[0]
            const startPointStyle = sketchStartEndPoint.clone()
            const startPoint = new Point(line.getFirstCoordinate())
            startPointStyle.setGeometry(startPoint)
            result.push(startPointStyle)
        }
    }
    return result
}

const sketchStartEndPoint = new Style({
    image: new CircleStyle({
        radius: 6,
        fill: new Fill({
            color: 'rgba(0,152,255,1)',
        }),
    }),
    zIndex: Infinity,
})

export const getDrawingModeForSketchType = (sketchType: SketchType): SketchMode => {
    switch (sketchType) {
        case SketchType.Area:
            return SketchMode.Area
        case SketchType.Line:
            return SketchMode.Line
        case SketchType.Marker:
            return SketchMode.Marker
        case SketchType.Text:
            return SketchMode.Text
    }
}

export const getStyleTargetForSketchType = (sketchType: SketchType): StyleTarget => {
    switch (sketchType) {
        case SketchType.Area:
            return StyleTarget.Boundary
        case SketchType.Line:
            return StyleTarget.Line
        case SketchType.Marker:
            return StyleTarget.Marker
        case SketchType.Text:
            return StyleTarget.Text
    }
}

export const highlightStyle = new Style({
    zIndex: 9999,
    stroke: new Stroke({
        color: 'rgb(255, 255, 255)',
        width: 5,
    }),
    fill: new Fill({
        color: 'transparent', // slight fill to trigger pointer events else it will flicker.
    }),
    text: new Text({
        font: '14px Calibri,sans-serif',
        overflow: true,
        fill: new Fill({ color: 'rgb(0, 0, 0)' }),
        stroke: new Stroke({
            color: 'rgb(255, 255, 255)',
            width: 8,
        }),
    }),
})

export const getDefaultStyle = (source, targetType: StyleTarget = StyleTarget.Boundary, highlight = false): Style => {
    let style = new Style()
    if (highlight) {
        style = highlightStyle.clone()
    } else {
        let useFillColour = source.fillColour ?? (source.fill && source.colour ? source.colour : null)
        if (useFillColour) {
            if (useFillColour.indexOf('#') > -1) {
                const rgb = hexToRGBArray(useFillColour ?? '#f44e3b')
                useFillColour = `rgba(${ rgb.toString() },${ source.fillOpacity })`
            } else if (useFillColour.indexOf('rgb') > -1) {
                useFillColour = useFillColour.substr(0, useFillColour.length - 2) + ',' + useFillColour + ')'
            }
        } else {
            useFillColour = 'transparent'
        }

        let fill:Fill

        if (source.hatch === true) {
            fill = new Fill({
                color: hatchStyleFunction(useFillColour),
            })
        } else {
            fill = new Fill({
                color: useFillColour,
            })
        }

        const text = new Text({
            font: '14px Calibri,sans-serif',
            overflow: true,
            fill: new Fill({ color: 'rgb(255, 255, 255)' }),
            stroke: new Stroke({ color: source.labelColour ?? source.colour ?? source.strokeColour, width: 8 }),
        })

        const stroke = new Stroke({
            color: source?.colour ?? source.strokeColour,
            width: source.strokeWidth,
            lineCap: source.dashed || source.dash ? 'square' : 'round',
            lineDash: source.dashed || source.dash ? [source.strokeWidth, source.strokeWidth * 2.5] : null,
        })

        style = new Style({
            zIndex: source?.zIndex,
            stroke,
            fill,
            text,
        })
    }

    let labelText = null
    if (targetType !== StyleTarget.VectorTile) {
        if (source?.showLabel === true || highlight) {
            labelText = source.name
        } else if (!isNullOrWhitespace(source?.label)) {
            labelText = source?.label
            if (source?.showTitleNumber === true) {
                labelText = `${ labelText } (${ source.titleNumber })`
            } else if (source?.showLabel) {
                labelText = `${ labelText }`
            }
        } else if (source.showTitleNumber === true) {
            labelText = source.titleNumber
        }

        if (labelText) {
            style.getText().setText(labelText)
        }
    }
    return style
}

export const getHighlightStyle = (sketch: ISketch) => {
    return getOLStyleForOWStyleDefinition(sketch, getStyleTargetForSketchType(sketch.sketchType), true)
}

export const clickFirstPolygonPointCondition = (mapBrowserEvent: MapBrowserEvent<any>, drawInteraction, targetMap) => {
    const features = drawInteraction?.getOverlay()?.getSource()?.getFeatures() ?? []
    const polygon = features.find((feature) => feature.getGeometry() instanceof Polygon || feature.getGeometry() instanceof MultiPolygon)
    const geometry = polygon?.getGeometry()
    const offset = 10

    // when drawing other geometries, such as lines, allow completion
    if (!geometry) {
        return true
    }

    // when drawing a polygon allow completion if the first coordinate is clicked
    // this is to allow the user to complete the polygon with double click disabled
    const firstCoordinate = geometry.getFirstCoordinate()
    const coordinatePixel = targetMap.getPixelFromCoordinate(firstCoordinate)
    const { pixel } = mapBrowserEvent
    if (
        (pixel[0] - offset >= coordinatePixel[0] || pixel[0] + offset <= coordinatePixel[0]) &&
        (pixel[1] - offset >= coordinatePixel[1] || pixel[1] + offset <= coordinatePixel[1])
    ) {
        return false
    }

    return true
}

/**
 * Returns a SVG as a string for use as a map icon.
 * @param {any} colour - a valid hex colour to be used as a fill.
 * @param {number} size - the size of the icon - 1-4 (1 being the smallest).
 * @param {boolean} encodeUri - whether to encode the SVG for use in a data URI.
 * @param highlight
 */
export const getSketchMarkerPinSVG = (colour: string, size: number, encodeUri: boolean = true, highlight = false) => {
    const sizeMultipliers = [1, 1.5, 2, 2.5, 3, 3.5, 4]
    if (size > sizeMultipliers.length) {
        size = sizeMultipliers.length
    }
    const width = 20 * sizeMultipliers[size - 1]
    const height = 30 * sizeMultipliers[size - 1]
    const svg = `<svg width="${ width }" height="${ height }" viewBox="0 0 43 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.5 0C9.61357 0 0 9.5465 0 21.35C0 37.3625 21.5 61 21.5 61C21.5 61 43 37.3625 43 21.35C43 9.5465 33.3864 0 21.5 0ZM21.5 28.975C19.4635 28.975 17.5104 28.1717 16.0704 26.7417C14.6304 25.3117 13.8214 23.3723 13.8214 21.35C13.8214 19.3277 14.6304 17.3883 16.0704 15.9583C17.5104 14.5283 19.4635 13.725 21.5 13.725C23.5365 13.725 25.4896 14.5283 26.9296 15.9583C28.3696 17.3883 29.1786 19.3277 29.1786 21.35C29.1786 23.3723 28.3696 25.3117 26.9296 26.7417C25.4896 28.1717 23.5365 28.975 21.5 28.975Z" fill="${ highlight ? '#FFFFFF' : colour }"/>
                    </svg>`
    return encodeUri ? encodeURIComponent(svg) : svg
}

/**
 * Determines if a given sketch is complex.
 *
 * @param {ISketch} sketch - The sketch to evaluate.
 * @returns {boolean} - True if the sketch is complex, false otherwise.
 */
export const isComplexSketch = (sketch: ISketch): boolean => {
    return Boolean(sketch?.geoJson?.length > SKETCH_COMPLEX_GEOJSON_MIN_LENGTH)
}
