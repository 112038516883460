<template>
    <div v-if="!isLoaded">
        <ow-loading-skeleton class="fill-height" />
    </div>
    <div v-else>
        <div v-if="!isAuthorised"
             class="unauthorised">
            <h1>
                Unauthorised
            </h1>
            <p>Snapshot URL could not be validated. Please contact support.</p>
        </div>
        <div v-else>
            <!--            <ow-snapshot-map class="fill-height"-->
            <!--                             :initial-view="null"-->
            <!--                             :os-data-hub-api-key="osDataHubApiKey"-->
            <!--                             :snapshot-data="renderData"-->
            <!--                             @loaded="mapLoaded" />-->
            <!-- Browserless.io needs an element to tie it's waitFor handler to. We can wait for the tiles to load and then display this element -->
            <!-- This will trigger browserless to take a screenshot only when the tiles are loaded. -->
            <p v-if="tilesLoaded"
               id="hide-from-headless" />
        </div>
    </div>
</template>
<script lang="ts" setup>
    import {
        onMounted,
        Ref,
        ref,
        UnwrapRef,
    } from 'vue'
    import { useStore } from "vuex"

    import ConfigurationApi from '@/api/configuration.api'
    import SnapshotApi from '@/api/snapshot.api'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { IShapshotRenderData } from '@/components/snapshots/common/snapshot-interfaces'
    import { SnapshotApiModel } from '@/components/snapshots/common/snapshot-models'
    import {
        MUTATE_SETTINGS,
    } from '@/store/modules/config/types'
    import { getOSDataHubAPIKey } from '@/utils/environment.utils'

    const store = useStore()

    interface Props {
        token: string
    }
    const props = defineProps<Props>()

    const isAuthorised: Ref<UnwrapRef<boolean>> = ref(false)
    const osDataHubApiKey: string = getOSDataHubAPIKey()
    const renderData: Ref<UnwrapRef<IShapshotRenderData>> = ref(null)
    const isLoaded: Ref<UnwrapRef<boolean>> = ref(false)
    const tilesLoaded: Ref<UnwrapRef<boolean>> = ref(false)

    onMounted(async () => {
        const config = await ConfigurationApi.getSnapshotSettings()
        await store.commit(`config/${ MUTATE_SETTINGS }`, config.data)
        const response: SnapshotApiModel = await SnapshotApi.getSnapshot(props.token)
        console.info(response)
        // TODO: complete implementation of snapshot renderer view when it is required
        // isAuthorised.value = response.isAuthorised
        // renderData.value = new Snapshot(response.snapshotData)
        isLoaded.value = true
    })

    function mapLoaded() {
        tilesLoaded.value = true
    }

    defineExpose({
        isAuthorised,
        renderData,
        osDataHubApiKey,
        isLoaded,
        tilesLoaded,
        mapLoaded,
    })

</script>

<style scoped lang="scss">
@import 'snapshot.scss';
</style>
