<template>
    <title-details-list-item class="expandable-title-details-list-item"
                             :class="{ 'expanded': isExpanded }"
                             :title-number="titleNumber"
                             :address="addresses && addresses.length ? addresses.join(', ') : $t('titles.addressUnavailable')"
                             :tenure="tenure"
                             :is-visible="isVisible"
                             :is-address-visible="!isExpanded"
                             :is-selected="isSelected"
                             :is-selectable="isSelectable"
                             :is-highlighted="isHighlighted"
                             :custom-name="customName"
                             :label-colour="labelColour"
                             :is-matter-link-share-user="isMatterLinkShareUser"
                             :is-register-ordered="isRegisterOrdered"
                             :is-register-uploaded="isRegisterUploaded"
                             :show-title-added-to-matter="showTitleAddedToMatter"
                             :register-response-date="registerResponseDate"
                             :has-light-background="hasLightBackground"
                             @click="onExpand"
                             @selection-change="onSelectionChange"
                             @visibility-button-click="onVisibilityButtonClick">
        <template v-if="isExpanded"
                  #belowContent>
            <div class="expandable-title-details-list-item__below-content"
                 data-test="expandable-title-details-list-item-expanded-content">
                <address-owner-grid :addresses="addresses"
                                    :owners="ownerNames"
                                    :is-loading-owners="isLoadingOwners"
                                    :owner-data-is-unavailable="ownerDataIsUnavailable" />
            </div>
        </template>
        <template v-if="isExpanded"
                  #actions>
            <ow-button v-if="!isTitleDetailsPanelVisible"
                       class="expandable-title-details-list-item__action-button"
                       data-test="expandable-title-details-list-item-expanded-content-button"
                       :data-track="seeDetailsButtonDataTrack"
                       @click="onClick">
                {{ $t('buttons.openTitle') }}
            </ow-button>
            <ow-button v-else
                       class="expandable-matter-title-details-list-item__action-button elevation-2"
                       data-test="expandable-matter-title-details-list-item-expanded-content-button"
                       :data-track="seeDetailsButtonDataTrack"
                       size="large"
                       @click="onClick">
                {{ $t('buttons.closeTitle') }}
            </ow-button>
        </template>
        <template v-if="isExpanded"
                  #afterLabel>
            <ow-colored-label class="expandable-title-details-list-item__tenure-label"
                              data-test="expandable-title-details-list-item-tenure-label"
                              :theme="colouredLabelTheme"
                              is-extra-small>
                {{ tenure }}
            </ow-colored-label>
        </template>
        <template #dropdownMenuItems>
            <slot name="dropdownMenuItems" />
        </template>
    </title-details-list-item>
</template>

<script lang="ts">
    import { computed } from 'vue'
    import { mapGetters } from 'vuex'

    import TitleInformationApi from '@/api/title-information.api'
    import OwButton from '@/components/core/ow-button.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import AddressOwnerGrid from '@/components/matter/titles/address-owner-grid.vue'
    import TitleDetailsListItem from '@/components/matter/titles/title-details-list-item.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { inject as titlePanelProvider } from '@/composables/use-title-panel'
    import { TenureType } from '@/enums/tenure-type'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export default {
        name: 'ExpandableTitleDetailsListItem',

        components: {
            AddressOwnerGrid,
            OwButton,
            OwColoredLabel,
            TitleDetailsListItem,
        },

        props: {
            tenure: {
                type: String,
                required: true,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            addresses: {
                type: Array,
                default: null,
                required: false,
            },
            isVisible: {
                type: Boolean,
                required: false,
            },
            isSelected: {
                type: Boolean,
                required: false,
                default: false,
            },
            isRegisterOrdered: {
                type: Boolean,
                required: false,
            },
            isRegisterUploaded: {
                type: Boolean,
                required: false,
            },
            registerResponseDate: {
                type: String,
                required: false,
            },
            isSelectable: {
                type: Boolean,
                required: false,
                default: true,
            },
            customName: {
                type: String,
                required: false,
                default: null,
            },
            labelColour: {
                type: String,
                required: false,
                default: null,
            },
            isHighlighted: {
                type: Boolean,
                required: false,
            },
            isExpanded: {
                type: Boolean,
                required: false,
            },
            seeDetailsButtonDataTrack: {
                type: String,
                required: false,
                default: undefined,
            },
            hasLightBackground: {
                type: Boolean,
                required: false,
            },
            isMatterLinkShareUser: {
                type: Boolean,
                required: false,
            },
            showTitleAddedToMatter: {
                type: Boolean,
                default: true,
            },
        },
        emits: [
            'on-expand',
            'on-collapse',
            'selection-change',
            'clear-titles',
            'click',
            'visibility-button-click',
        ],

        setup() {
            const { isTitlePanelVisible, isSummaryTitlePanelVisible } = titlePanelProvider()
            return {
                isTitlePanelVisible,
                isSummaryTitlePanelVisible,
            }
        },

        data() {
            return {
                ownerNames: new Array<string>(),
                isLoadingOwners: false,
                hasLoadedOwners: false,
                ownerDataIsUnavailable: false,
            }
        },
        computed: {
            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            colouredLabelTheme() {
                switch (this.tenure) {
                    case TenureType.Freehold:
                        return 'tenure-freehold'
                    case TenureType.Leasehold:
                        return 'tenure-leasehold'
                    default:
                        return 'tenure-other'
                }
            },

            isTitleDetailsPanelVisible(): boolean {
                return this.isSharedLinkView ? this.isSummaryTitlePanelVisible : this.isTitlePanelVisible
            },
        },
        watch: {
            isExpanded(val: boolean) {
                if (!this.hasLoadedOwners && val === true) {
                    this.populateOwnerData()
                }
            },
        },
        mounted() {
            if (this.isExpanded) {
                this.populateOwnerData()
            }
        },
        methods: {
            onSelectionChange(val: boolean) {
                this.$emit('selection-change', val)
            },
            onClick() {
                this.isTitleDetailsPanelVisible
                    ? this.$emit('clear-titles')
                    : this.$emit('click', this.titleNumber)
            },
            onVisibilityButtonClick() {
                this.$emit('visibility-button-click', this.titleNumber)
            },
            onExpand() {
                if (this.isExpanded) {
                    this.$emit('on-collapse', this.titleNumber)
                } else {
                    this.$emit('on-expand', this.titleNumber)
                }
            },
            async populateOwnerData() {
                this.isLoadingOwners = true
                this.ownerNames = await TitleInformationApi.getOwnerNamesByTitleNumber(this.titleNumber)
                this.ownerDataIsUnavailable = isNullOrEmpty(this.ownerNames)
                this.hasLoadedOwners = true
                this.isLoadingOwners = false
            },
        },
    }
</script>

<style lang="scss">
    @import 'expandable-title-details-list-item';
</style>
