import httpClient, { HttpClient } from '@/api/http-client'
import LandRegistryApi from '@/api/land-registry.api'
import { postcodeRegEx } from '@/consts/search.js'
import { IHttpClientRequest } from '@/interfaces/http-client-request.interface'

export interface ICompanySearch {
    Name: string
    RegNumber: string
    Address: string
}

function checkIfPostcode(val: string): boolean {
    return postcodeRegEx.test(val.toUpperCase()) && val.length <= 8
}

export default class SearchApi {
    private static controller: AbortController
    private static searchHistoryKey = 'searchHistory'
    private static numberOfSearchHistoryTerms = 5

    /**
     * Perform the various searches applicable for the provided search string.
     * @param searchText {String}
     * @returns {Promise<[]>}
     */
    static search(searchText: string): Promise<any> {
        const requests = []
        let isPostcode = false

        if (!searchText || searchText.length < 2) {
            return new Promise((resolve) => resolve([]))
        }

        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()

        if (checkIfPostcode(searchText)) {
            requests.push(this.searchPostcode(searchText, this.controller))
            isPostcode = true
        }

        const hasSpaces = searchText.indexOf(' ') !== -1
        const containsNumber = /\d/.test(searchText)

        if (!hasSpaces && containsNumber && searchText.length >= 3 && searchText.length < 10) {
            requests.push(this.titleSearch(searchText, this.controller))
        }

        if (!containsNumber && searchText.length >= 4) {
            requests.push(this.searchPlace(searchText, this.controller))
        }

        if (!isPostcode && hasSpaces && searchText.length >= 5) {
            requests.push(this.searchAddress(searchText, this.controller))
        }

        return httpClient.getAllByPromise(requests)
    }

    /**
     * Search across all types, including the companies search for owners.
     * @param searchText {String}
     * @return {Promise<*[]>|Promise<unknown>}
     */
    static searchAll(searchText: string): Promise<any[]> {
        const requests = []
        let isPostcode = false

        if (!searchText || searchText.length < 2) {
            return new Promise((resolve) => resolve([]))
        }

        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()

        // There's no way to identify a company search,
        // so making it always be first in the array that is returned
        requests.push(LandRegistryApi.searchByCorporateOwner(searchText, this.controller))

        requests.push(this.titleSearch(searchText, this.controller))

        const hasSpaces = searchText.includes(' ')
        const containsNumber = /\d/.test(searchText)

        // Have to pay for the place searches, so only perform search if necessary.
        if (checkIfPostcode(searchText)) {
            requests.push(this.searchPostcode(searchText, this.controller))
            isPostcode = true
        }

        if (!containsNumber && searchText.length >= 4) {
            requests.push(this.searchPlace(searchText, this.controller))
        }

        if (!isPostcode && hasSpaces && searchText.length >= 5) {
            requests.push(this.searchAddress(searchText, this.controller))
        }

        return httpClient
            .getAllByPromise(requests)
            .finally(() => {
                this.controller = null
            })
    }

    /**
     * Search for a specific title by it's number
     * @param searchText {String}
     * @param controller {AbortController}
     * @returns Promise
     */
    static titleSearch(searchText: string, controller: AbortController): Promise<any> {
        const request = this.getTitleRequest(searchText, controller)
        return httpClient.get(request.url, request.options)
    }

    static getTitleRequest(searchText: string, controller: AbortController): IHttpClientRequest {
        const endpoint = `landregistry/titlenumbers/${ searchText }/20.json`
        return {
            url: endpoint,
            options: {
                requestId: 'titleNumber',
                signal: controller?.signal,
            },
        }
    }

    /**
     * Search for a specific place by it's name
     * @param searchText {String}
     * @param controller {AbortController}
     * @returns Promise
     */
    static searchPlace(searchText: string, controller: AbortController): Promise<any> {
        const request = this.getPlaceRequest(searchText, controller)
        return httpClient.get(request.url, request.options)
    }

    static getPlaceRequest(searchText: string, controller: AbortController): IHttpClientRequest {
        const endpoint = `places/${ searchText }`
        return {
            url: endpoint,
            options: {
                requestId: 'place',
                signal: controller.signal,
            },
        }
    }

    /**
     * Search for an address
     * @param searchText {String}
     * @param controller {AbortController}
     * @returns Promise
     */
    static searchAddress(searchText: string, controller: AbortController): Promise<any> {
        const request = this.getAddressRequest(searchText, controller)
        return httpClient.get(request.url, request.options)
    }

    static getAddressRequest(searchText: string, controller: AbortController): IHttpClientRequest {
        const endpoint = `addresses/${ searchText }`
        return {
            url: endpoint,
            options: {
                requestId: 'address',
                signal: controller.signal,
            },
        }
    }

    /**
     * Search for an address
     * @param searchText {String}
     * @param controller {AbortController}
     * @returns Promise
     */
    static searchPostcode(searchText: string, controller: AbortController): Promise<any> {
        const request = this.getPostcodeRequest(searchText, controller)
        return httpClient.get(request.url, request.options)
    }

    static getPostcodeRequest(searchText: string, controller: AbortController): IHttpClientRequest {
        const endpoint = `postcode/${ searchText }`
        return {
            url: endpoint,
            options: {
                requestId: 'postcode',
                signal: controller.signal,
            },
        }
    }

    /**
     * Log the search selection on the server
     * @static
     * @param data
     * @returns {Promise<void>}
     */
    static async logSelection(data: any): Promise<void> {
        const endpoint = 'search/select'
        await httpClient.post(endpoint, data)
    }

    /**
     * Store the search term to create a history of terms.
     * @param searchTerm
     */
    static async addSearchHistory(searchTerm: string): Promise<void> {
        searchTerm = searchTerm.trim().toUpperCase()
        const history = await SearchApi.getSearchHistory()

        if (history.length >= this.numberOfSearchHistoryTerms) {
            history.pop()
        }

        if (!history.includes(searchTerm)) {
            history.unshift(searchTerm)
            localStorage.setItem(this.searchHistoryKey, JSON.stringify(history))
        }
    }

    /**
     * Get the search term history
     */
    static async getSearchHistory(): Promise<string[]> {
        return JSON.parse(localStorage.getItem(this.searchHistoryKey)) || []
    }

    /**
     * Remove all the search terms from history
     */
    static async clearSearchHistory(): Promise<void> {
        localStorage.removeItem(this.searchHistoryKey)
    }
}
