import {
    SITE_VISIT_MUTATE_BOUNDS,
    SITE_VISIT_MUTATE_ENABLED,
    SITE_VISIT_MUTATE_PANORAMA_HEADING,
    SITE_VISIT_MUTATE_PANORAMA_LOCATION,
    SITE_VISIT_MUTATE_POINTER_LOCATION,
    SITE_VISIT_MUTATE_STARTING_LOCATION,
} from './types.js'

export default {
    [SITE_VISIT_MUTATE_ENABLED](state, val) {
        state.enabled = val
    },

    [SITE_VISIT_MUTATE_BOUNDS](state, val) {
        const googleMapBounds = new window.google.maps.LatLngBounds()
        googleMapBounds.extend(new window.google.maps.LatLng(val[1], val[0]))
        googleMapBounds.extend(new window.google.maps.LatLng(val[3], val[2]))

        // Map not sized when not visible, fitting to bounds doesn't work if set immediately
        setTimeout(() => {
            state.map.fitBounds(googleMapBounds)
        }, 500)
    },

    [SITE_VISIT_MUTATE_STARTING_LOCATION](state, val) {
        state.selectingStartLocation = val
    },

    [SITE_VISIT_MUTATE_PANORAMA_HEADING](state, val) {
        state.panoramaHeading = val
    },

    [SITE_VISIT_MUTATE_PANORAMA_LOCATION](state, val) {
        state.panoramaLocation = val
    },

    [SITE_VISIT_MUTATE_POINTER_LOCATION](state, val) {
        state.pointerLocation = val
    },
}
