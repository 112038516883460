<template>
    <v-dialog v-model="show"
              column
              content-class="walkthrough-page-reorder-prompt"
              persistent
              width="500px">
        <v-card v-if="currentPages">
            <v-card-title class="content__subheading">
                Re-order pages
            </v-card-title>
            <v-card-text>
                <p>Change the order of the pages by dragging them around.</p>

                <draggable v-bind="dragOptions"
                           class="dragArea"
                           style="min-height:20px;"
                           :list="pages">
                    <transition-group name="flip-first">
                        <div v-for="page in pages"
                             :key="page"
                             class="d-flex flex flex-row align-center mt-4 walkthrough-page-reorder-item"
                             :class="{'selected-page': page.pageNumber === selectedPage.pageNumber}"
                             :data-pagenumber="page.pageNumber">
                            <div class="d-flex shrink">
                                <v-icon>$drag</v-icon>
                            </div>
                            <div class="item-text">
                                {{ getPageName(page) }}
                            </div>
                        </div>
                    </transition-group>
                </draggable>
            </v-card-text>

            <v-divider />

            <ow-card-actions has-secondary-button
                             :primary-button-disabled="!hasChanges"
                             :primary-button-text="$t('action.save')"
                             :secondary-button-text="$t('action.cancel')"
                             primary-button-data-track="WALKTHROUGH - Save reorder page changes"
                             secondary-button-data-track="WALKTHROUGH - Cancel reorder page changes"
                             @primary-button-click="onSaveClick"
                             @secondary-button-click="onCancelClick" />
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
    import cloneDeep from 'lodash.clonedeep'
    import isEqual from 'lodash.isequal'
    import omit from 'lodash.omit'
    import draggable from 'vuedraggable'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import {
        WALKTHROUGH_MUTATE_SELECTED_PAGE,
        WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT,
        WALKTHROUGH_SAVE,
        WALKTHROUGH_SET_PAGES,
    } from '@/store/modules/walkthrough/types'
    import WalkthroughUtils from '@/store/modules/walkthrough/walkthrough-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'WalkthroughPageReorderPrompt',

        components: {
            draggable,
            OwCardActions,
        },

        data() {
            return {
                pages: [], // A copy of the pages local to this component to modify.

                // Options for the draggable container.
                dragOptions: {
                    animation: 100,
                    ghostClass: 'ghost',
                },
            }
        },

        computed: {

            ...mapState({
                selectedPage: state => state.walkthrough.selectedPage,
                getShowPrompt: state => state.walkthrough.showPageReorderPrompt,
                currentPages: state => state.walkthrough.walkthrough?.pages,
            }),

            show: {
                get() {
                    return this.getShowPrompt
                },

                set(val) {
                    this.mutateShowPrompt(val)
                },
            },

            /**  */
            hasChanges() {
                if (this.currentPages) {
                    return !isEqual(this.pages.map(p => p.pageNumber), this.currentPages.map(p => p.pageNumber))
                }
                return false
            },

        },

        watch: {

            /** Assign the value of the description to null if no text is provided. */
            'selectedPage.description'(val) {
                if (isNullOrWhitespace(val)) {
                    this.selectedPage.description = null
                }
            },

            /**
             * When hiding/showing the dialog, create local stores of data to manipulate
             * without changing the main state prior to commit the changes.
             */
            show(val) {
                if (val === true) {
                    // Add a temporary flag to the selected page, so that it can found and re-selected afterwards after being re-ordered.
                    // It's particular only to this component and is deleted when the component is closed,
                    // so doesn't need to use a vuex mutation.
                    this.selectedPage.reordering = true

                    // Store the pages locally to the component to avoid making changes to the page order prior to saving.
                    // Exclude the map layer from cloning so as to not break the map, add it back later by referencing the former page number.
                    this.pages = this.currentPages.map(page => cloneDeep(omit(page, ['layer'])))
                } else {
                    // The property is no longer needed.
                    delete this.selectedPage.reordering
                }
            },

        },

        methods: {

            ...mapMutations({
                mutateShowPrompt: WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT,
                mutateSelectedPage: WALKTHROUGH_MUTATE_SELECTED_PAGE,
            }),

            ...mapActions({
                setWalkthroughPages: WALKTHROUGH_SET_PAGES,
                saveWalkthrough: WALKTHROUGH_SAVE,
            }),

            onCancelClick() {
                this.show = false
            },

            async onSaveClick() {
                // Create page numbers based on the new order.
                this.pages.forEach((page, pageIndex) => {
                    // Set a suitable page number.
                    page.pageNumber = pageIndex + 1
                })

                // And save the new pages to the state.
                this.setWalkthroughPages(this.pages)

                // Retrieve the page that was initially selected and re-select it.
                const newPageSelection = this.currentPages.find(page => page.reordering === true)
                this.mutateSelectedPage(newPageSelection)

                // Save the walkthrough.
                await this.saveWalkthrough()

                // Close the dialog.
                this.show = false
            },

            /**
             * Returns a sensible page name for a given page.
             * @param page The page to return a suitable name for.
             */
            getPageName(page) {
                const pageName = WalkthroughUtils.getNameForPage(page)

                const currentPageNumber = page.pageNumber
                const newPageNumber = this.pages.indexOf(page) + 1

                // Indicate that default page names will be updated if needed e.g. 'Page 1'
                // may become 'Page 2' if moved.
                if (currentPageNumber !== newPageNumber && page.name === null) {
                    const newPageName = `Page ${ newPageNumber }`
                    return `${ pageName } (rename to ${ newPageName })`
                } else {
                    return pageName
                }
            },

        },
    }
</script>
<style lang="scss">
    @import 'walkthrough-page-reorder-prompt.scss';
</style>
