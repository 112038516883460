import {
    NPS_EXPORT_TITLE_BOUNDARIES,
    NPS_GET_FEATURES_BY_TITLE_NUMBERS,
    NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
    NPS_MUTATE_LOADED_FEATURES_FOR_TITLE_NUMBERS,
    NPS_MUTATE_LOADING_FEATURES_FOR_TITLE_NUMBERS,
    NPS_MUTATE_STORE_FEATURES,
} from './types'
import { exportOpenLayersFeaturesToShapefile } from '@/utils/spatial-io'
import Feature from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'
import i18n from '@/plugins/i18n'
import MapApi from '@/api/map.api'
import { TENURE_FULL_FROM_CODE } from '@/utils/title-enums'

export default {

    async [NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS]({
        commit,
        state,
        rootState,
    }, titleNumberArray) {
        // Set loading state.
        commit(NPS_MUTATE_LOADING_FEATURES_FOR_TITLE_NUMBERS, titleNumberArray)

        // Deduplicate the array of title numbers.
        titleNumberArray = [...new Set(titleNumberArray)]

        // Determine title boundaries that actually need loading.
        const loadedTitleNumbers = state.features.map(feature => feature.get('title_no'))
        const titleNumbersToLoad = titleNumberArray.filter(titleNumber => {
            return !loadedTitleNumbers.includes(titleNumber) &&
                !state.loadingFeaturesForTitleNumbers.includes(titleNumber)
        })

        if (titleNumbersToLoad.length > 0) {
            const layer = rootState.config.settings.npsWFSLayer
            const features: Feature<Geometry>[] = await MapApi.getGeometryForMultipleTitleNumbers(titleNumbersToLoad, layer)
            const featureTitleNumbers = features.map(feature => feature.get('title_no'))
            commit(NPS_MUTATE_LOADED_FEATURES_FOR_TITLE_NUMBERS, featureTitleNumbers)
            commit(NPS_MUTATE_STORE_FEATURES, features)
            state.loadingFeaturesForTitleNumbers = []
            return features
        }
    },

    async [NPS_EXPORT_TITLE_BOUNDARIES]({ dispatch, getters }, titles) {
        // Determine titles to export, excluding those without boundaries.
        const titlesToExport = titles.filter(x => x.boundaryAvailable)

        // Ensure the boundary data is loaded for the titles.
        const titleNumbers: Array<string> = titlesToExport.map(x => x.titleNumber)
        await dispatch(NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS, titleNumbers)
        const features: Array<Feature> = getters[NPS_GET_FEATURES_BY_TITLE_NUMBERS](titleNumbers)

        // Loop through the titles and set the properties to be included in the export.
        features.forEach((feature: Feature) => {
            const titleMetadata = titlesToExport.find(x => x.titleNumber === feature.get('title_no'))
            feature.setProperties({
                label: titleMetadata.label,
                address: titleMetadata.addresses?.join(', ') ?? null,
                colour: titleMetadata.colour,
                tenure: TENURE_FULL_FROM_CODE[titleMetadata.tenure],
            })
        })
        const fileName = i18n.global.t('titles.titleBoundaryExportFileName', titlesToExport.length, { count: titlesToExport.length, titleNumber: titleNumbers[0] }).toString()
        await exportOpenLayersFeaturesToShapefile(features, fileName)
    },
}
