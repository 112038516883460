import batchPromises from 'batch-promises'
import qs from 'qs'

import httpClient from '@/api/http-client'

export default class DocumentsApi {
    static END_POINT = 'BusinessGateway'

    /**
     * Get the all the documents from the server for the current matter.
     * @static
     * @param matterId the associated matter Id.
     * @returns {Promise<T | *>}
     */
    static getAll(matterId) {
        const uri = `${ this.END_POINT }/GetMatterDocumentList?matterId=${ matterId }`
        return httpClient
            .post(uri, {})
            .then(response => response.data)
    }

    /**
     * Get the all the documents from the server for the current matter.
     * @static
     * @param matterDocumentIds an array of matterDocumentIds
     * @param matterId the associated matter Id.
     * @returns {Promise<T | *>}
     */
    static getDocumentsByMatterDocumentIds(matterDocumentIds = [], matterId) {
        let uri = `${ this.END_POINT }/GetMatterDocumentList`
        if (matterId) {
            uri += `?matterId=${ matterId }`
        }
        return httpClient
            .post(uri, qs.stringify({
                matterDocumentIds,
            }))
            .then(response => response.data)
    }

    /**
     * Get document metadata for a document with the given type and Id.
     * @static
     * @param type a document type ('register', 'titleplan', 'oc2document', 'spreadsheet')
     * @param id the associated document Id.
     * @returns {Promise<T | *>}
     */
    static async getDocumentMetadataByTypeAndId(type, id) {
        const uri = `documents/${ type }/${ id }/metadata`
        return httpClient
            .get(uri)
            .then(response => response.data)
    }

    /**
     * Prompt a download of a document given a type and id.
     * @static
     * @param type a document type ('register', 'titleplan', 'oc2document', 'spreadsheet')
     * @param id the associated document Id.
     * @returns {Promise<T | *>}
     */
    static async downloadDocumentByTypeAndId(type, id) {
        const uri = `documents/${ type }/${ id }/download`
        const response = await httpClient.get(uri, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const filename = this.getFilenameFromDispositionHeader(response)
        window.saveAs(blob, filename)
    }

    /**
     * Prompt a download of a document given a type and id.
     * @static
     * @param type a document type ('register', 'titleplan', 'oc2document', 'spreadsheet')
     * @param id the associated document Id.
     * @param matterId the associated matter Id.
     * @returns {Promise<T | *>}
     */
    static addDocumentToMatter(type, id, matterId) {
        const uri = `documents/${ type }/${ id }/add-to-matter/${ matterId }`
        return httpClient.post(uri, {})
            .then(response => response.data)
    }

    /**
     * Downloads a copy filed document
     * @static
     * @param filename the document guid for download purposes
     * @returns directs browser to document location, forcing download
     */
    static async downloadCopyFiled(filename) {
        const uri = `${ this.END_POINT }/GetBGDocumentFileByFilename?filename=${ filename }&asDownload=true`
        const response = await httpClient.get(uri, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const saveAsFile = this.getFilenameFromDispositionHeader(response)
        window.saveAs(blob, saveAsFile)
    }

    /**
     * Downloads a non-copy filed document
     * @static
     * @param url the documentUrl from the document object
     * @param matterId
     * @returns directs browser to document location, forcing download
     */
    static async downloadOtherDocument(url, matterId) {
        const uri = `/${ url }&asDownload=true&matterId=${ matterId }`
        const response = await httpClient.get(uri, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const filename = this.getFilenameFromDispositionHeader(response)

        window.saveAs(blob, filename)
    }

    /**
     * Update the document library store of documents, or update only a subset
     * @param {Object} request - {titleNumber: , documentTypeField: ,
     *     documentDate: , filedUnderField: }
     */
    static orderDocument(request) {
        // This can be commented in for testing without getting charged
        // return new Promise(resolve => setTimeout(() => resolve({
        //     status: 200,
        //     data: {
        //         message: null,
        //         ok: true,
        //         filename: '8f81cb7c-c4c7-4964-9efb-895b415e6dcb',
        //         augmentedFilename: null,
        //         showAlternative: false,
        //         matterDocumentId: 7128,
        //     },
        // }), 1000))

        const documentRequestURL = `${ this.END_POINT }/GetBGDocumentStringByTitle`

        return httpClient
            .post(documentRequestURL, {
                titleNo: request.titleNumber,
                documentCode: request.document.documentTypeField,
                documentDate: request.document.documentDate,
                documentFilledUnder: request.document.filedUnderField ?? request.document.filedUnder,
                documentEntryNumbers: request.document.entryNumberField?.toString() ?? request.document.entryNumbers?.toString(),
                matterId: request.matterId,
            })
    }

    /**
     * Order multiple documents. Uses batching to order 10 at a time.
     * @param request {Object}
     * @see orderDocument - is called by the batching method
     * @example
     *  The request object should contain
     *      {
     *          titleNumber: 'xyz123',
     *          documents: [],
     *          matterId: 123,
     *      }
     * @return {Promise<*[]>} - Returns an array of the responses
     */
    static orderMultipleDocuments(request) {
        return batchPromises(10, request.documents, document => {
            return this.orderDocument({
                titleNumber: request.titleNumber,
                document,
                show: false,
                matterId: request.matterId,
            })
        })
    }

    /**
     * Download multiple matter documents
     * @param {any} request - array of { documentType: *, documentId: *,
     *     responseType: * }
     */
    static async downloadDocuments(request) {
        const documentRequestURL = `/matter/${ request.matterId }/bulkdocumentdownload`
        // Gets the filename in blob storage to use to request the zip file
        const result = await httpClient
            .post(documentRequestURL, request)
            .catch(error => {
                console.error(error)
                return {
                    ok: false,
                    message: error.message,
                }
            })

        // Now request the zip file and save it to the user's machine
        const resultUrl = `/matter/downloadbulkdocuments/${ result.data }`
        const response = await httpClient.get(resultUrl, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/zip' })
        const filename = this.getFilenameFromDispositionHeader(response)

        window.saveAs(blob, filename)
    }

    static getFilenameFromDispositionHeader(response) {
        let filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split(';')[0]

        if (filename.includes('"')) {
            filename = filename.replace(/"/g, '')
        }

        return filename
    }

    /**
     * remove a document of a given type and id.
     * @static
     * @param document a document with type ('register', 'titleplan', 'oc2document', 'spreadsheet')
     * @param id the associated document Id.
     * @param matterId the associated matter Id.
     * @returns {Promise<T | *>}
     */
    static removeDocumentFromMatter(matterId, documentId, documentType) {
        const requestBody = {
            registerIds: [],
            titlePlanIds: [],
            oc2DocumentIds: [],
            spreadsheetIds: [],
            searchIds: [],
        }

        switch (documentType) {
            case 'register':
                requestBody.registerIds.push(documentId)
                break
            case 'titleplan':
                requestBody.titlePlanIds.push(documentId)
                break
            case 'oc2document':
                requestBody.oc2DocumentIds.push(documentId)
                break
            case 'spreadsheet':
                requestBody.spreadsheetIds.push(documentId)
                break
        }

        const uri = `documents/${ matterId }`
        return httpClient.delete(uri, { data: requestBody })
            .then(response => response.data)
    }
}
