import {
    CheckColumnConfig,
    TemplateColumnConfig,
} from '@bryntum/grid'
import { useI18n } from 'vue-i18n'

export const useColumns = () => {
    const { t } = useI18n()
    const ProviderEmailNotificationColumn = ({
        text: t('searches.notifications.table.columns.providerEmailNotification') as string,
        field: 'isSubscribedToProviderEmailNotification',
        width: 100,
        type: 'check',
    } as Partial<CheckColumnConfig>)

    const NameColumn = ({
        field: 'name',
        text: t('searches.notifications.table.columns.name') as string,
        flex: 1,
    }) as Partial<TemplateColumnConfig>

    const EmailColumn = ({
        field: 'email',
        text: t('searches.notifications.table.columns.email') as string,
        flex: 1,
    }) as Partial<TemplateColumnConfig>

    return {
        gridColumns: [
            NameColumn,
            EmailColumn,
            ProviderEmailNotificationColumn,
        ],
    }
}
