import {
    Arrowhead,
    SketchMode,
    SketchType,
    SnappingMode,
} from '@/enums/sketches-enums'
import {
    createEmpty,
    extend,
    isEmpty,
} from 'ol/extent'
import {
    SKETCHES_MUTATE_ADD_SKETCH,
    SKETCHES_MUTATE_ADD_SKETCH_ID_PENDING_SAVE,
    SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES,
    SKETCHES_MUTATE_COLLAPSE_LIST,
    SKETCHES_MUTATE_CURRENT_MODE,
    SKETCHES_MUTATE_CURRENT_SKETCH,
    SKETCHES_MUTATE_CURRENT_SKETCH_STYLE,
    SKETCHES_MUTATE_DESELECT_SKETCHES,
    SKETCHES_MUTATE_DRAW_LAYER_WITH_SKETCH,
    SKETCHES_MUTATE_ENABLE_MAP_SELECTION,
    SKETCHES_MUTATE_HIGHLIGHT_SKETCH,
    SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD,
    SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX,
    SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL,
    SKETCHES_MUTATE_INITIALISING,
    SKETCHES_MUTATE_IS_DRAWING,
    SKETCHES_MUTATE_LOADING_SKETCHES,
    SKETCHES_MUTATE_MAKING_UNDO_REDO_CHANGES,
    SKETCHES_MUTATE_REMOVE_SKETCH,
    SKETCHES_MUTATE_REMOVE_SKETCH_ID_PENDING_SAVE,
    SKETCHES_MUTATE_SELECT_SKETCHES,
    SKETCHES_MUTATE_SHOW_BULK_OPTIONS,
    SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL,
    SKETCHES_MUTATE_SHOW_SETTING_MODAL,
    SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS,
    SKETCHES_MUTATE_SKETCH,
    SKETCHES_MUTATE_SKETCHES,
    SKETCHES_MUTATE_SKETCH_TO_ACTIVATE_ON_LOAD,
    SKETCHES_MUTATE_SNAPPING_MODE,
    SKETCHES_MUTATE_TARGET_MAP,
    SKETCHES_MUTATE_ZOOM_TO_SKETCH,
    SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL,
} from '@/store/modules/sketches/types'
import { IOwStyle } from '@/store/modules/sketches/types/style'
import { ISketch } from '@/store/modules/sketches/types/sketch'
import { ISketchesState } from '@/store/modules/sketches/index'
import { SketchesFactory } from '@/store/modules/sketches/types/sketches-factory'

export default {
    [SKETCHES_MUTATE_LOADING_SKETCHES](state: ISketchesState, loading: boolean): void {
        state.loading = loading
    },
    [SKETCHES_MUTATE_SKETCHES](state: ISketchesState, sketches: Array<ISketch>): void {
        state.sketches = sketches
        state.layer?.getSource().clear()
        state.layer?.getSource().addFeatures(sketches.map(sketch => sketch.features).flat())
    },
    [SKETCHES_MUTATE_COLLAPSE_LIST](state: ISketchesState, collapse: boolean): void {
        state.collapsedList = collapse
    },
    [SKETCHES_MUTATE_SELECT_SKETCHES](state: ISketchesState, sketches: Array<ISketch>): void {
        state.selectedSketches.push(...sketches)
    },
    [SKETCHES_MUTATE_HIGHLIGHT_SKETCH](state: ISketchesState, args): void {
        if (args) {
            const { sketch, tooltip } = args
            if (state.highlightedSketch !== sketch) {
                state.highlightedSketch = sketch
                state.highlightedSketchTooltipText = tooltip ?? []
                state.layer?.getSource().changed()
            }
        }
    },
    [SKETCHES_MUTATE_DESELECT_SKETCHES](state: ISketchesState, sketches: Array<ISketch>): void {
        state.selectedSketches = state.selectedSketches.filter(sketch => !sketches.includes(sketch))
    },
    [SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES](state: ISketchesState): void {
        state.selectedSketches = []
    },
    [SKETCHES_MUTATE_SKETCH](state: ISketchesState, sketch: ISketch): void {
        const index = state.sketches.findIndex(s => s.id === sketch.id)
        state.sketches.splice(index, 1, sketch)
    },
    [SKETCHES_MUTATE_SHOW_BULK_OPTIONS](state: ISketchesState, show: boolean): void {
        state.showBulkActions = show
    },
    [SKETCHES_MUTATE_CURRENT_SKETCH](state: ISketchesState, sketch: ISketch): void {
        state.currentSketch = sketch
        state.drawLayer?.getSource().clear()
        if (sketch) {
            state.drawLayer?.getSource().addFeatures(sketch.features.map(x => x.clone()))
            state.drawLayer?.getSource().changed()

            // Fade out the other sketches when once is being modified.
            state.layer?.setOpacity(0.5)
        } else {
            state.layer?.setOpacity(1)
            state.history.currentSketchGeoJson = []
        }
        state.layer?.getSource().changed()
    },
    [SKETCHES_MUTATE_SNAPPING_MODE](state: ISketchesState, mode: SnappingMode): void {
        state.snappingMode = mode
    },
    [SKETCHES_MUTATE_TARGET_MAP](state: ISketchesState, map): void {
        state.targetMap = map
    },
    [SKETCHES_MUTATE_ADD_SKETCH](state: ISketchesState, sketch: ISketch): void {
        state.sketches.push(sketch)
        state.layer.getSource().addFeatures(sketch.features)
    },
    [SKETCHES_MUTATE_REMOVE_SKETCH](state: ISketchesState, sketch: ISketch): void {
        const index = state.sketches.findIndex(s => s.id === sketch.id)
        state.sketches.splice(index, 1)
        const features = state.layer.getSource().getFeatures().filter(x => x.getProperties().sketchId === sketch.id)
        if (features.length) {
            features.forEach(x => state.layer.getSource().removeFeature(x))
        }
    },
    [SKETCHES_MUTATE_IS_DRAWING](state: ISketchesState, isDrawing: boolean): void {
        state.isDrawing = isDrawing
    },
    [SKETCHES_MUTATE_ADD_SKETCH_ID_PENDING_SAVE](state: ISketchesState, sketchId: number): void {
        if (!state.sketchIdsWithPendingChanges.includes(sketchId)) {
            state.sketchIdsWithPendingChanges.push(sketchId)
        }
    },
    [SKETCHES_MUTATE_REMOVE_SKETCH_ID_PENDING_SAVE](state: ISketchesState, sketchId: number): void {
        state.sketchIdsWithPendingChanges = state.sketchIdsWithPendingChanges.filter(id => id !== sketchId)
    },
    [SKETCHES_MUTATE_INITIALISING](state: ISketchesState, initialising: boolean): void {
        state.initialising = initialising
    },
    [SKETCHES_MUTATE_ZOOM_TO_SKETCH](state: ISketchesState, sketch:ISketch): void {
        let extent = createEmpty()
        sketch.features.forEach(feature => {
            extent = extend(extent, feature.getGeometry().getExtent())
        })
        if (!isEmpty(extent)) {
            state.targetMap.getView().fit(extent, {
                duration: 500,
                padding: [75, 75, 75, 75],
                maxZoom: 19,
            })
        }
    },
    [SKETCHES_MUTATE_CURRENT_MODE](state: ISketchesState, mode: SketchMode): void {
        state.currentMode = mode
    },
    [SKETCHES_MUTATE_DRAW_LAYER_WITH_SKETCH](state: ISketchesState, sketch: ISketch): void {
        state.layer.getSource().getFeatures().forEach(feature => {
            if (feature.getProperties().sketchId === sketch.id) {
                state.layer.getSource().removeFeature(feature)
            }
        })
        state.layer.getSource().addFeatures(sketch.features)
        state.layer.getSource().changed()
    },
    [SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS](state: ISketchesState, show: boolean): void {
        state.showSketchModeOptions = show
    },
    // Do not use directly, use the action instead.
    [SKETCHES_MUTATE_CURRENT_SKETCH_STYLE](state: ISketchesState, style: IOwStyle): void {
        state.currentSketch.olDrawingStyle = null
        state.currentSketch.strokeWidth = style.strokeWidth
        state.currentSketch.strokeColour = style.strokeColour
        if (state.currentSketch.sketchType === SketchType.Area || state.currentSketch.sketchType === SketchType.Text) {
            state.currentSketch.fillColour = style.fillColour
            state.currentSketch.fillOpacity = style.fillOpacity
        } else {
            state.currentSketch.fillColour = null
            state.currentSketch.fillOpacity = null
        }
        state.currentSketch.dash = style?.dash ?? false
        state.currentSketch.hatch = style?.hatch ?? false
        state.currentSketch.arrowhead = style?.arrowhead ?? Arrowhead.None
        state.currentSketch.showLabel = style?.showLabel ?? false

        state.drawLayer.getSource().changed()
    },
    [SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL](state: ISketchesState, { show, sketch } : {show: boolean, sketch: ISketch}): void {
        state.showNameDescriptionModal = show
        state.nameDescriptionModalSketch = sketch ?? null
        if (!show && !sketch) {
            state.nameDescriptionModalSketch = null
        }
    },
    [SKETCHES_MUTATE_SHOW_SETTING_MODAL](state: ISketchesState, { show, sketch } : {show: boolean, sketch: ISketch}): void {
        state.showSketchSettingModal = show
        state.sketchSettingModalSketch = sketch ?? null
        if (!show && !sketch) {
            state.sketchSettingModalSketch = null
        }
    },
    [SKETCHES_MUTATE_ENABLE_MAP_SELECTION](state: ISketchesState, enabled: boolean): void {
        state.enableSketchesMapSelection = enabled
    },
    [SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX](state: ISketchesState, index: number): void {
        state.history.currentSketchGeoJsonIndex = index
        const features = SketchesFactory.featuresFromGeoJson(state.history.currentSketchGeoJson[index])
        features.forEach(x => x.setProperties({ sketchId: state.currentSketch.id }))
        state.drawLayer?.getSource().clear()
        state.drawLayer?.getSource().addFeatures(features)
    },
    [SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD](state: ISketchesState, geoJson: string): void {
        if (!state.history.currentSketchGeoJson.includes(geoJson)) {
            state.history.currentSketchGeoJson = state.history.currentSketchGeoJson.slice(0, state.history.currentSketchGeoJsonIndex)
            state.history.currentSketchGeoJson.push(geoJson)
            state.history.currentSketchGeoJsonIndex = state.history.currentSketchGeoJson.length // ahead of the last feature state i.e. is not in an 'undone' state.
        }
    },
    [SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL](state: ISketchesState): void {
        state.history.currentSketchGeoJson = []
        state.history.currentSketchGeoJsonIndex = null
    },
    [SKETCHES_MUTATE_MAKING_UNDO_REDO_CHANGES](state: ISketchesState, value: boolean): void {
        state.makingChangesWithUndoRedo = value
    },
    [SKETCHES_MUTATE_SKETCH_TO_ACTIVATE_ON_LOAD](state: ISketchesState, sketchId: number): void {
        state.sketchToActivateOnLoad = sketchId
    },
    [SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL](state: ISketchesState, show: boolean): void {
        state.showImportTitlesModal = show
    },
}
