import { IMapRolloverOptions } from '@/components/map-rollover/common/map-rollover-interfaces'
import { LayerNames } from '@/consts/map-layers'
import i18n from '@/plugins/i18n'
import Style, {
    StyleFunction,
    StyleLike,
} from 'ol/style/Style'
import { UnregisteredLandFeatureType } from './unregistered-land-layer'

export const getUnregisteredRolloverOptions = (layerName: LayerNames, style: StyleLike): IMapRolloverOptions => {
    return {
        showPrimary: true,
        showExtended: true,
        sortOrder: 0,
        category: i18n.global.t(`map.rollover.${ layerName }.category`),
        source: i18n.global.t(`map.rollover.${ layerName }.source`),
        getStyle: (feature) => (style as StyleFunction)(feature, null) as Style,
        getPrimary: (features) => {
            const titleNos = new Set<string>()
            if (features.length) {
                features.forEach(f => {
                    const properties = f.getProperties() as UnregisteredLandFeatureType
                    if (properties.title_no) {
                        titleNos.add(properties.title_no)
                    }
                })
            }
            const titleNosString = Array.from(titleNos).join(',')
            return titleNosString
        },
        getExtended(features) {
            const properties = features[0].getProperties() as UnregisteredLandFeatureType
            const unregisteredLandType = properties.tenure == 'CN' ? i18n.global.t(`map.rollover.${ layerName }.extended.cn`) : i18n.global.t(`map.rollover.${ layerName }.extended.baseType`)
            return [
                {
                    name: 'type',
                    value: unregisteredLandType,
                },
            ]
        },
    }
}
