<template>
    <ow-page-layout page-name="matter-searches-details">
        <matter-searches-error-dialog :has-return-to-map="false"
                                      @retry="initialise" />
        <div class="matter-searches-details__container d-flex flex-column pa-6 pt-8 pb-0">
            <a v-if="!newOrder"
               class="matter-searches-details__back d-flex align-center mb-2"
               @click="onBackToSearchesClick">
                <span class="mdi mdi-arrow-left mr-2" />
                <span class="caption-highlight">Back to searches</span>
            </a>
            <matter-searches-order-details-loading-skeleton v-if="loading" />
            <matter-searches-details-header v-else
                                            :map-options="mapOptions"
                                            :order="order"
                                            :loading="loading"
                                            :new-order="newOrder && newOrder.toString() === 'true'"
                                            @overlay-click="onOverlayClick" />
            <matter-searches-select-product-loading-skeleton v-if="loading" />
            <v-tabs v-if="!loading"
                    v-model="activeTab"
                    color="primary"
                    slider-color="primary">
                <v-tab :key="0">
                    {{ $t('searches.order.tabs.orderDetails') }}
                </v-tab>
                <v-tab v-show="notificationStore?.count !== 0"
                       :key="1">
                    {{ $t('searches.order.tabs.setupNotifications') }}
                </v-tab>
            </v-tabs>
            <v-window v-if="!loading"
                      v-model="activeTab">
                <v-window-item :key="0">
                    <div class="matter-searches-details__notifications d-flex flex-column mb-4 mt-4">
                        <matter-searches-order-details-table v-if="!loading"
                                                             :height="gridHeight"
                                                             :store="orderDetailsStore"
                                                             @download="onDownload"
                                                             @view="onView"
                                                             @view-document="onViewDocument" />
                    </div>
                </v-window-item>
                <v-window-item :key="1">
                    <v-fade-transition>
                        <ow-alert v-if="newOrder && showNewOrderNotification"
                                  class="matter-searches-details__notifications-alert mb-4"
                                  type="success"
                                  :has-close="showNewOrderNotification"
                                  title="Notifications"
                                  @close="showNewOrderNotification = false">
                            <div v-dompurify-html="$t('searches.order.alerts.newOrder')"
                                 class="caption-regular" />
                        </ow-alert>
                    </v-fade-transition>
                    <div class="d-flex justify-space-between align-center">
                        <ow-text-field v-model="searchText"
                                       class="matter-searches-filter pr-4 w-100"
                                       data-test="matter-searches-filter-search-text"
                                       :max-length="1000"
                                       :placeholder="$t('searches.filterUsers').toString()"
                                       small
                                       clearable
                                       @click:clear="searchText = ''">
                            <template #iconPrefix>
                                <v-icon>$search</v-icon>
                            </template>
                        </ow-text-field>
                        <ow-card-actions no-padding
                                         has-secondary-button
                                         has-primary-button
                                         secondary-button-text="Cancel"
                                         :primary-button-text="isSaving ? $t('action.savingChanges') : $t('action.saveChanges') "
                                         :primary-button-disabled="!notificationStore?.changes?.modified?.length"
                                         :secondary-button-disabled="!notificationStore?.changes?.modified?.length"
                                         primary-button-data-test="notifications-button-save"
                                         :primary-button-loading="isSaving"
                                         @primary-button-click="onSaveNotificationChanges"
                                         @secondary-button-click="notificationStore?.revertChanges()" />
                    </div>
                    <div class="matter-searches-details__notifications d-flex flex-column mb-4 mt-4">
                        <matter-searches-notification-table v-if="!loading"
                                                            :height="gridHeight"
                                                            borderless
                                                            :loading="isSaving"
                                                            class="flex-grow-1 matter-searches-details__notifications-table"
                                                            :store="notificationStore" />
                    </div>
                </v-window-item>
            </v-window>
        </div>
    </ow-page-layout>
</template>

<script setup lang="ts">
    import {
        Model,
        Store,
    } from '@bryntum/grid'
    import debounce from 'lodash.debounce'
    import {
        computed,
        onActivated,
        onBeforeMount,
        onBeforeUnmount,
        onDeactivated,
        onMounted,
        reactive,
        ref,
        watch,
        WritableComputedRef,
    } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import SearchesApi, {
        IDownloadSearchesDocumentsRequest,
        INotificationConfigUser } from '@/api/searches.api'
    import OwPageLayout from '@/components/core/layout/full-width.vue'
    import OwAlert from '@/components/core/ow-alert.vue'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwTextField from '@/components/core/ow-textfield.vue'
    import MatterSearchesOrderDetailsLoadingSkeleton from '@/components/loading-skeletons/matter-searches-order-details-loading-skeleton.vue'
    import MatterSearchesSelectProductLoadingSkeleton from '@/components/loading-skeletons/matter-searches-select-product-loading-skeleton.vue'
    import MatterSearchesDetailsHeader from '@/components/matter-searches/matter-searches-details-header.vue'
    import matterSearchesErrorDialog from '@/components/matter-searches/matter-searches-error-dialog.vue'
    import MatterSearchesNotificationTable from '@/components/matter-searches/matter-searches-notification-table.vue'
    import MatterSearchesOrderDetailsTable from '@/components/matter-searches/matter-searches-order-details-table.vue'
    import NotificationModel from '@/components/matter-searches/models/NotificationModel'
    import { useMapOptions } from '@/composables/use-map-options'
    import { Route } from '@/enums/route.enum'
    import {
        SEARCHES_FETCH_ORDER_DETAILS,
        SEARCHES_GET_LOADING,
        SEARCHES_GET_NOTIFICATION_USERS,
        SEARCHES_GET_ORDER_DETAILS,
        SEARCHES_GET_ORDERS,
        SEARCHES_MUTATE_ADD_ERROR,
        SEARCHES_MUTATE_CLEAR_ERRORS,
        SEARCHES_MUTATE_LOADING,
        SearchOrder,
        SearchOrderDetails,
    } from '@/store/modules/searches/types'
    import { dynamicSortMultipleFields } from '@/utils/array-utils'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const mapOptions = useMapOptions()

    // store
    const orderSearchEnabled = computed(() => store.state.config.featureFlags?.orderSearch)
    const matterId = computed(() => route.params.matterId)
    const id = computed(() => route.params.id)
    const tab = computed(() => route.query?.tab)
    const newOrder = computed(() => route.query?.newOrder)
    const order = computed<SearchOrderDetails | undefined>(() => store.getters[SEARCHES_GET_ORDER_DETAILS])
    const orders = computed<SearchOrder[]>(() => store.getters[SEARCHES_GET_ORDERS])

    const activeTab = ref<number>(parseInt(tab.value?.toString()) ?? 0)

    const searchText = ref('')
    const gridHeight = ref(null)
    const isSaving = ref(false)

    const showNewOrderNotification = ref(false)

    const handleResize = async () => {
        switch (activeTab.value) {
            case 0:
                gridHeight.value = `${ window.innerHeight - (newOrder.value ? 450 : 490) }px`
                break
            case 1:
                gridHeight.value = `${ window.innerHeight - (newOrder.value && showNewOrderNotification.value ? 570 : newOrder.value ? 490 : 530) }px`
                break
            default:
                break
        }
    }

    /**
     * Debounce the resize handler to prevent it from firing too often
     */
    const debouncedResizeHandler = debounce(() => {
        handleResize()
    }, 100)

    const initialise = async () => {
        await fetchOrder()

        setTimeout(() => {
            showNewOrderNotification.value = true
        }, 1500)
    }

    onMounted(async () => {
        window.addEventListener('resize', debouncedResizeHandler)
        debouncedResizeHandler()

        initialise()
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', debouncedResizeHandler)
    })

    onActivated( async () => {
        initialise()
    })

    onDeactivated(() => {
        store.commit(SEARCHES_MUTATE_CLEAR_ERRORS)
    })

    const loading: WritableComputedRef<boolean> = computed({
        get(): boolean {
            return store.getters[SEARCHES_GET_LOADING]
        },
        set(loading: boolean): void {
            store.commit(SEARCHES_MUTATE_LOADING, loading)
        },
    })

    const orderDetailsStore = reactive(new Store({
        data: order.value?.searchOrderItems || [],
    }))

    const notificationStore = reactive(new Store({
        data: [],
    }))

    onBeforeMount(() => {
        if (orderSearchEnabled.value !== undefined && !orderSearchEnabled.value) {
            redirectToMatterMap()
        }
    })

    const redirectToMatterMap = () => {
        router.push({
            name: Route.MatterMap,
            params: {
                matterId: matterId.value,
            },
        })
    }

    const onOverlayClick = (orderId: string) => {
        let autoSelect = 'false'

        // how many documents in the whole order
        const docs = order.value.searchOrderItems.reduce((total, item) => total + item.availableDocuments.length, 0)
        if (docs === 1) {
            autoSelect = 'true'
        }

        // add heap event
        store.dispatch('LOGGING_HEAP_TRACK_EVENT', {
            type: 'Searches - Overlay search clicked from order details',
            metadata: {
                autoSelect,
                orderId,
                numberOfDocuments: docs,
            },
        })

        // push to overlays create step
        router.push({ name: Route.OverlaysCreate, query: { documentId: orderId, autoSelect } })
    }

    const onBackToSearchesClick = () => {
        router.push({ name: 'matter-searches' })
    }

    const viewDocument = (document: string) => {
        router.push({
            name: Route.SearchesDocument,
            params: {
                matterId: matterId.value.toString(),
                orderId: order.value.id,
            },
            query: {
                fileName: document,
                fromSearchesOrder: 'true',
            },
        })
    }

    const viewOrder = (orderItemId: string) => {
        router.push({
            name: Route.DocumentsLibrary,
            hash: '#searches',
            params: {
                orderId: orderItemId,
            },
        })
    }

    const onDownload = async (column: string, model: Model) => {
        const request: IDownloadSearchesDocumentsRequest = {
            documents: { [order.value.id]: order.value.searchOrderItems.find(x => x.id == model.get('id')).availableDocuments },
        }
        await SearchesApi.downloadDocuments(request)
    }

    const onView = async (column: string, model: Model) => {
        const orderId = model.get('id')
        const availableDocuments = order.value.searchOrderItems.find(x => x.id == orderId).availableDocuments
        if (availableDocuments.length === 1) {
            viewDocument(availableDocuments[0])
            return
        }
        // no document to view so view the order
        viewOrder(orderId)
    }

    const onViewDocument = async (documentId?: string) => {
        viewDocument(documentId)
    }

    const onSaveNotificationChanges = async () => {
        isSaving.value = true
        try {
            let resp
            try {
                resp = await SearchesApi.updateNotificationUsers(order?.value?.id, notificationStore.allRecords
                    .filter( (model: Model) => {
                        const notificationModel = model as NotificationModel
                        return notificationModel.isSubscribedToProviderEmailNotification
                    }).map((model: Model) => {
                        const notificationModel = model as NotificationModel
                        return {
                            id: notificationModel.id as string,
                            email: notificationModel.email,
                            name: notificationModel.name,
                            providerUserId: notificationModel?.providerUserId ?? '',
                            isSubscribedToProviderEmailNotification: notificationModel.isSubscribedToProviderEmailNotification,
                        }
                    }))
            } catch (err) {
                store.commit(SEARCHES_MUTATE_ADD_ERROR, {
                    key: 'UPDATE_NOTIFICATION_USERS',
                    value: {
                        traceId: err?.response?.data?.traceId,
                        detail: err?.response?.data?.detail,
                        request: order?.value?.id,
                        errors: err?.message ? [err.message] : [err],
                    },
                })
            }

            if (resp?.ok) {
                notificationStore.forEach((model: Model) => {
                    const notificationModel = model as NotificationModel
                    // find the model in the response
                    const updatedModel = resp.data.users.find((x: INotificationConfigUser) => x.email === notificationModel.email)
                    if (updatedModel) {
                        notificationModel.isSubscribedToProviderEmailNotification = updatedModel.isSubscribedToProviderEmailNotification
                    } else {
                        notificationModel.isSubscribedToProviderEmailNotification = false
                    }
                })
                notificationStore.commit()
                searchText.value = ''
            } else {
                notificationStore.revertChanges()
            }
        } finally {
            notificationStore.sort(dynamicSortMultipleFields(['-isSubscribedToProviderEmailNotification', 'name']))
            isSaving.value = false
        }
    }

    const fetchOrder = async () => {
        loading.value = true
        try {
            await store.dispatch(SEARCHES_FETCH_ORDER_DETAILS, {
                id: id?.value,
            })
            notificationStore.data = store.getters[SEARCHES_GET_NOTIFICATION_USERS] ?? []
        } catch (err) {
            store.commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: 'SEARCHES_FETCH_ORDER_DETAILS',
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request: order?.value?.id,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        } finally {
            loading.value = false
        }
    }

    watch(() => order.value, (val) => {
        if (val && route.name === Route.MatterSearchesDetails) {
            orderDetailsStore.data = order.value?.searchOrderItems || []
            if (!order?.value?.address) {
                initialise()
            }
        }
    })

    watch(searchText, (newVal) => {
        notificationStore.filter({
            replace: true,
            filters: [
                {
                    filterBy: (notificationModel: NotificationModel) => {
                        return notificationModel.name.toLowerCase().includes(newVal.toLowerCase()) ||
                            notificationModel.email.toLowerCase().includes(newVal.toLowerCase())
                    },
                },
            ],
        })
    })

    watch(() => activeTab.value, () => {
        router.push({
            query: {
                tab: activeTab.value,
                newOrder: newOrder.value,
            },
        })
        handleResize()
    })

    watch(() => tab.value, (val, oldVal) => {
        if (val === oldVal) {
            return
        }
        const value = parseInt(tab.value?.toString()) ?? 0
        if (value !== activeTab.value && value >= 0 && value <= 1) {
            activeTab.value = value
        }
    })

    watch(() => newOrder.value, (val, oldVal) => {
        if (val === oldVal) {
            return
        }
        if (val === 'true') {
            activeTab.value = 1
        }
    }, {
        immediate: true,
    })

    watch(() => showNewOrderNotification.value, () => {
        handleResize()
    })
</script>

<style lang="scss">
    @import './matter-searches-details.scss';
</style>
