<template>
    <div class="title-panel-find-nearby">
        <div class="title-panel-find-nearby__container">
            <find-nearby-container v-if="isActive"
                                   :current-matter-id="currentMatterId"
                                   :selected-title-numbers="selectedTitleNumbers"
                                   :target-map="map" />
        </div>
    </div>
</template>

<script lang="ts">
    import { mapState } from 'vuex'

    import FindNearbyContainer from '@/components/find-nearby/find-nearby-container.vue'
    import FindNearbyContainerWithMap from "@/components/title-panel/v2/find-nearby/find-nearby-container-with-map.vue"
    import { useTitlePanelTabs } from '@/composables/use-title-panel-tabs'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import FeatureFlagsMixin from "@/feature-flags/feature-flags-mixin"
    import { IState } from '@/interfaces/store/state.interface'


    export default {
        name: 'FindNearbyTab',

        components: { FindNearbyContainer },

        mixins: [ FeatureFlagsMixin ],

        props: {
            selectedTitleNumbers: {
                type: Array,
                required: true,
            },

            currentMatterId: {
                type: Number,
                required: true,
            },
        },

        setup() {
            const { activeTab } = useTitlePanelTabs()
            return {
                activeTab,
            }
        },

        computed: {
            ...mapState({
                map: (state: IState) => state.map.map,
            }),

            isActive(): boolean {
                return this.activeTab === TitlePanelTabName.FindNearby
            },
        },
    }
</script>
