import { useI18n } from 'vue-i18n'
import {
    Model,
    TemplateColumnConfig,
} from '@bryntum/grid'
import { useActionColumn } from '@/components/core/ow-grid/columns/use-action-column'
import { useCurrencyColumn } from '@/components/core/ow-grid/columns/use-currency-column'
import { useDateColumn } from '@/components/core/ow-grid/columns/use-date-column'
import { ItemState } from '@/enums/searches.enum'
import { usePillsColumn } from '@/components/core/ow-grid/columns/use-pills-column'
import { dateFormat } from '@/components/core/ow-grid/columns'
import OrderDetailItemModel from '@/components/matter-searches/models/OrderDetailItemModel'
import { HazardsColumn } from '@/components/matter-searches/columns'

export const useColumns = ({
    on,
}: {
    on: (event: string, column: string, record: Model) => void
}) => {
    const { t } = useI18n()
    const ProductColumn = ({
        field: 'productName',
        text: t('searches.columns.name') as string,
        cls: 'column-name',
        flex: 1,
        type: 'template',
        template: ({ value, record }: { value: string, record: Model}) => {
            const orderDetail = record as OrderDetailItemModel
            return `
            <div class="d-flex flex-column product-column__container">
                <div>
                    <span class="body-highlight product-column" title="${ value }">${ value }</span>
                </div>
                <div class="supplier-name  product-column" title="${ orderDetail.supplierName }">${ orderDetail.supplierName }</div>
            </div>
        `
        },
    }) as Partial<TemplateColumnConfig>

    const ExpectedDateColumn = usePillsColumn({
        field: 'expectedDate',
        text: t('searches.columns.expectedDate') as string,
        width: 200,
        align: 'right',
        cls: 'hide-in-percy',
        tooltipRenderer: ({ record }: { record: Model }) => {
            const orderDetail = record as OrderDetailItemModel
            return orderDetail.currentExpectedDate ? `Original Expected Date: ${ dateFormat(orderDetail.expectedDate) }` : null
        },
        mapPillItems: (orderDetailItem: OrderDetailItemModel) => {
            if (orderDetailItem.status === ItemState.DocumentsAvailable) {
                return ['ready']
            }
            const date = orderDetailItem.currentExpectedDate ?? orderDetailItem.expectedDate
            const displayDate = date ? dateFormat(date) : '-'
            return [displayDate]
        },
        mapPillClass: (label: string | number) => {
            if (label === 'ready') {
                return '--success'
            } else {
                return '--transparent'
            }
        },
    })

    const CostColumn = useCurrencyColumn({
        field: 'cost',
        text: t('searches.columns.cost') as string,
    })


    return {
        gridColumns: [
            ProductColumn,
            HazardsColumn,
            ExpectedDateColumn,
            CostColumn,
        ],
    }
}
