// Getters
export const NPS_GET_FEATURES_BY_TITLE_NUMBERS = 'NPS_GET_FEATURES_BY_TITLE_NUMBERS'
export const NPS_GET_FEATURES_BY_TITLE_NUMBER = 'NPS_GET_FEATURES_BY_TITLE_NUMBER'

// Mutations
export const NPS_MUTATE_CLEAR_FEATURES = 'NPS_MUTATE_CLEAR_FEATURES'
export const NPS_MUTATE_LOADED_FEATURES_FOR_TITLE_NUMBERS = 'NPS_MUTATE_LOADED_FEATURES_FOR_TITLE_NUMBERS'
export const NPS_MUTATE_LOADING_FEATURES_FOR_TITLE_NUMBERS = 'NPS_MUTATE_LOADING_FEATURES_FOR_TITLE_NUMBERS'
export const NPS_MUTATE_STORE_FEATURES = 'NPS_MUTATE_STORE_FEATURES'

// Actions
export const NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS = 'NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS'
export const NPS_EXPORT_TITLE_BOUNDARIES = 'NPS_EXPORT_TITLE_BOUNDARIES'
