<template>
    <aside class="ow-quick-action p-4 d-flex align-center justify-start flex-grow-0 flex-shrink-0"
           :class="{ 'ow-quick-action--disabled': isDisabled }"
           :data-test="dataTestAttribute"
           :data-track="dataTrackAttribute"
           :title="!isDisabled ? description : ''"
           @click="onClick">
        <component :is="icon"
                   width="35px"
                   class="ow-quick-action__icon" />
        <div class="ow-quick-action__content d-flex flex-column justify-start flex-grow-1 flex-shrink-0">
            <h3 class="ow-quick-action__content--title body-highlight">
                {{ title }}
            </h3>
            <p class="ow-quick-action__content--description caption-regular">
                {{ description }}
            </p>
        </div>
        <ow-tooltip v-if="isDisabled && disabledTooltip"
                    activator="parent"
                    :position="OwTooltipPosition.Bottom">
            {{ disabledTooltip }}
        </ow-tooltip>
    </aside>
</template>

<script setup lang="ts">
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    const props = withDefaults(defineProps<{
        title: string,
        description?: string,
        icon: Object,
        dataTestAttribute?: string,
        dataTrackAttribute?: string,
        isDisabled?: boolean,
        disabledTooltip?: string,
    }>(), {
        dataTestAttribute: 'ow-quick-action-card',
        dataTrackAttribute: 'Quick Action Card',
        description: '',
        isDisabled: false,
    })

    const emit = defineEmits<{
        (e: 'click'),
    }>()

    const onClick = () => {
        if (!props.isDisabled) {
            emit('click')
        }
    }
</script>

<style scoped lang="scss">
    @import './ow-quick-action-card';
</style>
