<template>
    <div :class="{ hasDivider: includeDivider }"
         class="tab-owner-results-header">
        <div class="tab-owner-results-header__select">
            <label class="body-copy">Select: </label>
            <a :class="{ 'disabled-select': isAllDisabled }"
               class="select-options"
               data-test="owner-search-header-select-all"
               data-track="SEARCH - Search by owner select all owners"
               @click="setIsAllSelection(true)">all</a> /
            <a :class="{ 'disabled-select': isNoneDisabled }"
               class="select-options"
               data-test="owner-search-header-select-none"
               data-track="SEARCH - Search by owner select no owners"
               @click="setIsAllSelection(false)">none</a>
        </div>
        <v-select v-model="selectedOwnerSortOption"
                  :items="ownerSortOptions"
                  class="tab-owner-results-header__sort body-copy"
                  data-test="tab-owner-results-header-sort-by"
                  data-track="SEARCH - Search by owner sort by"
                  hide-details
                  item-title="label"
                  item-value="id"
                  label="Sort by" />
    </div>
</template>

<script>
    export default {
        name: 'TabOwnerResultsHeader',

        props: {
            ownerSortOptions: {
                type: Array,
                required: true,
            },

            isAllDisabled: {
                type: Boolean,
                default: false,
            },

            isNoneDisabled: {
                type: Boolean,
                default: true,
            },

            includeDivider: {
                type: Boolean,
                default: false,
            },
        },

        emits: [
            'is-all-selected',
            'sort-by',
        ],

        data() {
            return {
                selectedOwnerSortOption: null,
                isAllSelected: false,
            }
        },

        watch: {
            selectedOwnerSortOption(option) {
                this.$emit('sort-by', option)
            },
        },

        mounted() {
            this.selectedOwnerSortOption = this.ownerSortOptions[0]
        },

        methods: {
            setIsAllSelection(isAll) {
                this.isAllSelected = isAll
                this.$emit('is-all-selected', isAll)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './tab-owner-results-header'
</style>
