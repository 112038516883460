<template>
    <div class="organisation-matters-page">
        <div class="fixed-header">
            <header class="header-content">
                <h1 class="content__heading">
                    {{ $t('label.matters') }}
                </h1>
            </header>
        </div>
        <div class="matter-actions">
            <ow-textfield ref="matterSearch"
                          v-model="localSearchTextValue"
                          :placeholder="$t('matter.search')"
                          class="matter-actions__search"
                          clearable
                          data-test="matter-list-search">
                <template #iconPrefix>
                    <v-icon>$search</v-icon>
                </template>
            </ow-textfield>
            <div class="matter-actions__context">
                <div>
                    <ow-loading-skeleton v-if="isLoading"
                                         height="20px"
                                         width="150" />
                    <p v-else
                       class="body-copy"
                       data-test="display-matters-title">
                        {{ displayingMattersText }}
                    </p>
                </div>
                <ow-button :disabled="isLoading"
                           :loading="isLoading"
                           data-test="organisation-matters-export-btn"
                           data-track="ACCOUNT - Export all matters"
                           is-secondary
                           @click="exportMatters()">
                    <v-icon start>
                        $cloud-download
                    </v-icon>
                    {{ $t('action.exportCsv') }}
                </ow-button>
                <ow-button :disabled="isLoading"
                           data-test="organisation-matter-refresh"
                           is-secondary
                           @click="refreshMatters">
                    {{ $t('action.refresh') }}
                </ow-button>
            </div>
        </div>
        <ow-data-grid :allow-selection="false"
                      :headers="headers"
                      :is-loading="isLoading"
                      :items="matters"
                      :sort-by="sortBy"
                      class="mt-4 matters-table"
                      has-pagination
                      hover
                      item-key="key">
            <template #item="{ item }: { item: any }">
                <tr>
                    <td :title="item.name"
                        class="caption-regular matters-table__column--name">
                        {{ item.name }}
                    </td>
                    <td :title="item.createdByUserName"
                        class="caption-regular matters-table__column--username">
                        {{ item.createdByUserName }}
                    </td>
                    <td :title="item.clientCode ? item.clientCode : '-'"
                        class="caption-regular matters-table__column--code">
                        {{ item.clientCode ? item.clientCode : '-' }}
                    </td>
                    <td :title="item.code ? item.code : '-'"
                        class="caption-regular matters-table__column--code">
                        {{ item.code ? item.code : '-' }}
                    </td>
                    <td class="caption-regular">
                        {{ item.type ? item.type : '-' }}
                    </td>
                    <td class="caption-regular matters-table__column--date">
                        {{ formatDateShort(item.createdTimestamp) }}
                    </td>
                    <td class="caption-regular matters-table__column--date">
                        {{ formatDateShort(item.lastAccessed) }}
                    </td>
                    <td class="caption-regular matters-table__column--shared">
                        <span v-if="item.sharedWithOrganisation">{{ $t('table.label.organisation') }}</span>
                        <span v-else-if="item.sharedWithSpecificUsers">{{ $t('table.label.specificUsers') }}</span>
                        <span v-else>{{ $t('table.label.private') }}</span>
                    </td>
                    <td class="caption-regular">
                        <v-btn :data-test="`organisation-matters-table-button-edit-${ item.id }`"
                               icon
                               variant="text"
                               @click="onEditClicked(item)">
                            <v-icon class="matters-table__edit-icon">
                                $edit
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </ow-data-grid>
        <organisation-edit-matter :organisation-users="organisationUsers"
                                  :selected-matter="selectedMatter"
                                  :show-dialog="showDialog"
                                  @cancel="onEditCancelled"
                                  @submit="onEditMatterSubmit" />
    </div>
</template>

<script lang="ts" setup>
    import debounce from 'lodash.debounce'
    import {
        computed,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import MatterApi, { IMatterListRequest } from '@/api/matter.api'
    import UserApi from '@/api/user.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import OrganisationEditMatter from '@/components/organisation/organisation-edit-matter.vue'
    import useDates from '@/composables/use-dates'
    import { IMatter } from '@/interfaces/matter.interface'
    import { NOTIFICATIONS_DISPLAY_NOTIFICATION } from '@/store/modules/notifications/types'
    import { INotification } from '@/store/modules/notifications/types/notification.interface'
    import { exportAsCsv } from '@/utils/csv-utils'

    onMounted(async () => {
        await loadMatters()
        await getUsers()
    })

    const store = useStore()
    const { t } = useI18n()
    const { formatDateShort } = useDates()

    const searchText = ref<string>('')
    const matters = ref<Array<IMatter>>([])
    const isLoading = ref<boolean>(true)
    const organisationId: number = store.state.user.organisationId

    const debouncedSearch = debounce(async () => {
        isLoading.value = true
        await refreshMatters()
    }, 750)

    const sortBy = ref([ { key: 'lastAccessed', order: 'desc' } ])

    const localSearchTextValue = computed<string>
        ({
            get: () => searchText.value,
            set: (key: string) => {
                searchText.value = key
                debouncedSearch()
            },
        })

    async function refreshMatters() {
        matters.value = []
        await loadMatters()
    }

    async function loadMatters() {
        isLoading.value = true

        // Only text search is supported for now.
        // Options for pagination are available but it performs pretty well rn so no need to implement it.
        const options: IMatterListRequest = {
            pageSize: 999999,
            pageIndex: 0,
            searchText: searchText.value,
        }

        const response = await MatterApi.getMatterListForOrganisation(options, organisationId)

        if (response?.data) {
            matters.value.push(...response.data)
        }

        isLoading.value = false
    }

    const displayingMattersText = computed(() => {
        return t('admin.organisation.label.displayingMatters', matters.value.length)
    })

    const showDialog = ref<boolean>(false)
    const selectedMatter = ref<IMatter>(null)
    const organisationUsers = ref<Array<string>>([])

    async function getUsers() {
        const response = await UserApi.getUsersByOrganisationId(organisationId)
        if (response?.data) {
            organisationUsers.value = response.data.users.map(user => user.email).sort()
        } else {
            console.error('Failed to get users')
        }
    }

    function onEditClicked(matter) {
        selectedMatter.value = matter
        showDialog.value = true
    }

    function onEditCancelled() {
        showDialog.value = false
    }

    function onEditMatterSubmit(response) {
        let notification: INotification = null

        if (response.status === 200 && response.data === true) {
            notification = {
                toaster: {
                    isSuccess: true,
                    message: t('matter.success.updateOwner', { matter: selectedMatter.value?.name }).toString(),
                },
            }
        } else {
            notification = {
                toaster: {
                    isError: true,
                    message: t('matter.error.updateOwner', { matter: selectedMatter.value?.name }).toString(),
                },
            }
        }

        store.dispatch(NOTIFICATIONS_DISPLAY_NOTIFICATION, notification)
        showDialog.value = false
        refreshMatters()
    }

    const headers = computed(() => {
        const headerArray = [
            {
                title: t('table.label.name'),
                align: 'start',
                key: 'name',
                class: 'label-caps-small',
            },
            {
                title: t('table.label.createdBy'),
                align: 'start',
                key: 'createdByUserName',
                class: 'label-caps-small',
            },
            {
                title: t('table.label.clientCode'),
                align: 'start',
                key: 'clientCode',
                class: 'label-caps-small',
            },
            {
                title: t('table.label.matterCode'),
                align: 'start',
                key: 'code',
                class: 'label-caps-small',
            },
            {
                title: t('table.label.matterType'),
                align: 'start',
                key: 'type',
                class: 'label-caps-small',
            },
            {
                title: t('table.label.createdOn'),
                align: 'start',
                key: 'createdTimestamp',
                class: 'label-caps-small',
            },
            {
                title: t('table.label.lastAccessed'),
                align: 'start',
                key: 'lastAccessed',
                class: 'label-caps-small',
            },
            {
                title: t('table.label.sharedWith'),
                align: 'start',
                key: 'sharedWith',
                class: 'label-caps-small',
                sortable: false,
                width: '100',
            },
            {
                title: t('action.edit'),
                align: 'start',
                key: 'edit',
                class: 'label-caps-small',
                sortable: false,
            },
        ]
        headerArray.forEach(h => h.title = h.title.toUpperCase())
        return headerArray
    })

    const exportMatters = () => {
        const rows = [
            headers.value?.map(h => h.key !== 'edit' ? h.title : ''),
            ...matters.value?.map(m => {
                return [
                    m.name,
                    m.createdByUserName,
                    m.clientCode ? m.clientCode : '-',
                    m.code ? m.code : '-',
                    m.type ? m.type : '-',
                    formatDateShort(m.createdTimestamp),
                    formatDateShort(m.lastAccessed),
                    m.sharedWithOrganisation ? t('table.label.organisation') :
                    m.sharedWithSpecificUsers ? t('table.label.specificUsers') : t('table.label.private'),
                ]
            }),
        ]
        exportAsCsv(rows, 'all-matters.csv')
    }

    defineExpose({
        debouncedSearch,
        matters,
        isLoading,
        showDialog,
        selectedMatter,
        organisationUsers,
    })
</script>

<style lang="scss" scoped>
@import './organisation-matters';
</style>
