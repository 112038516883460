import { MapBrowserEvent } from 'ol'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from './base-mvt-data-layer'
import { LayerNames } from '@/consts/map-layers'
import Style, {
    StyleFunction,
    StyleLike,
} from 'ol/style/Style'
import {
    Fill,
} from 'ol/style'
import { FeatureLike } from 'ol/Feature'
import i18n from '@/plugins/i18n'
import {
    IMapRolloverOptions,
} from '@/components/map-rollover/common/map-rollover-interfaces'

export type RadonLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: ({ text, hoverCaption } : { text: Array<string>, hoverCaption: string },
                                event: MapBrowserEvent<any>) => void
}

export type RadonFeatureType = {
    id: string,
    class_max: number,
    layer: string,
    tile: string,
}

export class RadonLayer extends BaseMvtDataLayer {
    public maxResolution = 256

    constructor(params: RadonLayerParams, settings: any) {
        super(params, settings)
        this.targetMap = params.targetMap ?? null
        this.layerName = 'ow:radon'
        this.i18nNameRef = 'map.options.legend.environmental[4]'
        this.attributionText = ['Contains British Geological Survey materials © UKRI 2024. Radon Potential classification UK Health Security Agency © Crown copyright 2024']
        this.name = LayerNames.RadonLayer
        this.rolloverOptions = this.getRolloverOptions(this.style, this.targetMap)
        this.initialiseLayer()
    }

    public style:StyleLike = (feature: FeatureLike) => {
        const properties = feature.getProperties()
        let zIndex = 300
        let colour = 'rgba(252, 240, 218, 0.8)'

        // Determine colour and zIndex
        switch (properties.class_max) {
            case 1:
                colour = 'rgba(252, 240, 218, 0.8)'
                zIndex = 309
                break
            case 2:
                colour = 'rgba(226, 190, 160, 0.8)'
                zIndex = 308
                break
            case 3:
                colour = 'rgba(193, 163, 159, 0.8)'
                zIndex = 307
                break
            case 4:
                colour = 'rgba(169, 112, 115, 0.8)'
                zIndex = 306
                break
            case 5:
                colour = 'rgba(145, 67, 83, 0.8)'
                zIndex = 306
                break
            case 6:
                colour = 'rgba(111, 51, 64, 0.8)'
                zIndex = 306
                break
            default:
                zIndex = 306
        }
        return new Style({
            zIndex,
            fill: new Fill({
                color: colour,
            }),
        })
    }

    private getRolloverOptions(style: StyleLike, targetMap: any): IMapRolloverOptions {
        return {
            showPrimary: true,
            showExtended: true,
            sortOrder: 0,
            category: i18n.global.t(`map.rollover.environmental.category`),
            source: i18n.global.t(`map.rollover.environmental.source.bgs`),
            getStyle: (feature) => (style as StyleFunction)(feature, null) as Style,
            getPrimary: () => 'Radon indicative',
            getExtended(features) {
                const properties = features[0].getProperties() as RadonFeatureType
                let name = 'Default levels'
                let value = 'Radon class not described by source information.'
                // NOTE: Due to performance if there is a zoom level force the user to zoom in
                const zoomLevel = targetMap?.getView().getZoom() ?? 20
                if (zoomLevel > 10.5) {
                    switch (properties.class_max) {
                        case 1:
                            name = '0-1%'
                            value = 'All parts of this 1km grid square are in the lowest band of radon potential. Less than 1% of homes above the Action Level.'
                            break
                        case 2:
                            name = '1-3%'
                            value = 'Some parts of this 1km grid square are in bands of elevated radon potential. Maximum radon potential is 1-3%.'
                            break
                        case 3:
                            name = '3-5%'
                            value = 'Some parts of this 1km grid square are in bands of elevated radon potential. Maximum radon potential is 3-5%.'
                            break
                        case 4:
                            name = '5-10%'
                            value = 'Some parts of this 1km grid square are in bands of elevated radon potential. Maximum radon potential is 5-10%.'
                            break
                        case 5:
                            name = '10-30%'
                            value = 'Some parts of this 1km grid square are in bands of elevated radon potential. Maximum radon potential is 10-30%.'
                            break
                        case 6:
                            name = '>30%'
                            value = 'Some parts of this 1km grid square are in bands of elevated radon potential. Maximum radon potential is greater than 30%.'
                            break
                    }
                } else {
                    name = 'Info'
                    value = 'Please zoom in for radon percentage reading.'
                }
                return [
                    {
                        name,
                        value,
                    },
                ]
            },
        }
    }
}
