<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 80 80"
                  desc="Searches icon">
        <g clip-path="url(#clip0_362_16768)">
            <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                  fill="#84DBFF" />
            <path d="M57.89 15.874H22.1105C21.2286 15.874 20.4727 16.5669 20.4727 17.5118V63.2441C20.4727 64.126 21.1656 64.8819 22.1105 64.8819H57.827C58.7089 64.8819 59.4648 64.189 59.4648 63.2441V17.4488C59.4648 16.5669 58.7719 15.874 57.89 15.874Z"
                  fill="#324A5E" />
            <path d="M23.4331 18.7715V56.5038H51.9056V51.8424H56.567V18.7715H23.4331Z"
                  fill="white" />
            <path d="M41.7638 43.8429C46.9126 43.8429 51.0866 39.669 51.0866 34.5201C51.0866 29.3712 46.9126 25.1973 41.7638 25.1973C36.6149 25.1973 32.4409 29.3712 32.4409 34.5201C32.4409 39.669 36.6149 43.8429 41.7638 43.8429Z"
                  fill="#E6E9EE" />
            <path d="M51.9053 56.5042L56.5667 51.8428H51.9053V56.5042Z"
                  fill="#E6E9EE" />
            <path d="M29.0392 24.9453H27.5903V26.3941H29.0392V24.9453Z"
                  fill="#FF7058" />
            <path d="M29.0392 29.04H27.5903V30.4889H29.0392V29.04Z"
                  fill="#FF7058" />
            <path d="M29.0392 33.0713H27.5903V34.5201H29.0392V33.0713Z"
                  fill="#FF7058" />
            <path d="M29.0392 37.1025H27.5903V38.5514H29.0392V37.1025Z"
                  fill="#FF7058" />
            <path d="M29.0392 41.1348H27.5903V42.5836H29.0392V41.1348Z"
                  fill="#FF7058" />
            <path d="M29.0392 45.166H27.5903V46.6148H29.0392V45.166Z"
                  fill="#FF7058" />
            <path d="M52.4725 24.9453H31.4961V26.3941H52.4725V24.9453Z"
                  fill="#E6E9EE" />
            <path d="M52.4725 29.04H31.4961V30.4889H52.4725V29.04Z"
                  fill="#E6E9EE" />
            <path d="M52.4725 33.0713H31.4961V34.5201H52.4725V33.0713Z"
                  fill="#E6E9EE" />
            <path d="M52.4725 37.1025H31.4961V38.5514H52.4725V37.1025Z"
                  fill="#E6E9EE" />
            <path d="M52.4725 41.1348H31.4961V42.5836H52.4725V41.1348Z"
                  fill="#E6E9EE" />
            <path d="M43.0866 45.166H31.4961V46.6148H43.0866V45.166Z"
                  fill="#E6E9EE" />
            <path d="M34.2046 29.2286H50.5195C50.2676 28.7247 50.1416 28.0948 50.1416 27.4648H34.8345C34.7715 28.1578 34.5825 28.7877 34.2046 29.2286Z"
                  fill="#CED5E0" />
            <path d="M50.5826 32.4414H32.5669V34.2052H50.8976C50.6456 33.6383 50.5197 33.0713 50.5826 32.4414Z"
                  fill="#CED5E0" />
            <path d="M50.5196 37.3545H32.5669V39.1183H50.2047C50.1417 38.5513 50.2677 37.9214 50.5196 37.3545Z"
                  fill="#CED5E0" />
            <path d="M36.2832 42.3311C36.6612 42.835 36.8501 43.4649 36.8501 44.0948H46.3619C46.4249 43.8429 46.5509 43.5279 46.6769 43.3389C46.8659 43.024 47.1808 42.646 47.5588 42.3941H36.2832V42.3311Z"
                  fill="#CED5E0" />
            <path d="M51.9058 20.0946H28.0947L30.3624 14.6143H49.638L51.9058 20.0946Z"
                  fill="#FF7058" />
            <path d="M44.3467 15.433H35.6538V13.8582C35.6538 11.4645 37.6066 9.51172 40.0003 9.51172C42.394 9.51172 44.3467 11.4645 44.3467 13.8582V15.433Z"
                  fill="#FF7058" />
            <path d="M40.0001 15.3068C40.9394 15.3068 41.7009 14.5454 41.7009 13.6061C41.7009 12.6667 40.9394 11.9053 40.0001 11.9053C39.0608 11.9053 38.2993 12.6667 38.2993 13.6061C38.2993 14.5454 39.0608 15.3068 40.0001 15.3068Z"
                  fill="white" />
            <path d="M51.1806 37.7656L49.9951 40.1309L54.2187 42.2479L55.4043 39.8826L51.1806 37.7656Z"
                  fill="#2B3B4E" />
            <path d="M46.6773 24.756C41.26 22.0474 34.6458 24.2521 31.9372 29.6694C29.2285 35.0867 31.4332 41.7009 36.8506 44.4096C42.2679 47.1182 48.8821 44.9135 51.5907 39.4962C54.2994 34.0159 52.0947 27.4647 46.6773 24.756ZM49.26 38.2993C47.1813 42.4568 42.1419 44.0946 37.9844 42.0159C33.8899 40.0001 32.1891 34.9608 34.2679 30.8033C36.3466 26.6458 41.386 25.008 45.5435 27.0867C49.638 29.1025 51.3388 34.1419 49.26 38.2993Z"
                  fill="#324A5E" />
            <path d="M54.488 39.2438L53.1652 41.8895C52.9132 42.3934 53.1022 43.0863 53.6691 43.3383L64.5667 48.8186C65.0707 49.0706 65.7636 48.8816 66.0156 48.3147L67.3384 45.669C67.5904 45.165 67.4014 44.4721 66.8345 44.2202L55.9368 38.7399C55.3699 38.5509 54.74 38.7399 54.488 39.2438Z"
                  fill="#FF7058" />
            <path d="M65.7485 43.7258L57.0195 39.3506L54.7332 43.9121L63.4621 48.2873L65.7485 43.7258Z"
                  fill="#FFD05B" />
        </g>
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconSearchesIntro',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '80px',
            },
            height: {
                type: String,
                required: false,
                default: '80px',
            },
        },
    }
</script>
