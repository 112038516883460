<template>
    <v-container id="logsPage"
                 fill-height
                 fluid>
        <div class="d-flex flex flex-column">
            <div class="admin-log__controls">
                <v-select v-model="filter.time"
                          :items="times"
                          hide-details
                          :label="$t('form.label.timeRange')" />

                <v-text-field id="adminLogTypeFilter"
                              v-model="filter.type"
                              hide-details
                              label="Type"
                              style="width:220px;"
                              @keyup.enter="getLogs()" />
                <v-text-field v-model="filter.subType"
                              hide-details
                              label="Sub-type"
                              style="width:220px;"
                              @keyup.enter="getLogs()" />
                <v-text-field v-model="filter.user"
                              hide-details
                              label="User"
                              style="width:220px;"
                              @keyup.enter="getLogs()" />

                <v-btn :loading="loading"
                       class="hidden-md-and-down"
                       color="primary"
                       size="large"
                       @click="getLogs()">
                    <v-icon start>
                        $refresh
                    </v-icon>
                    {{ $t('action.update') }}
                </v-btn>
                <v-btn :loading="loading"
                       class="hidden-lg-and-up"
                       icon
                       color="primary"
                       size="small"
                       @click="getLogs()">
                    <v-icon>$refresh</v-icon>
                </v-btn>
                <v-btn :loading="loading"
                       class="hidden-md-and-down"
                       color="primary"
                       size="large"
                       @click="exportCSV()">
                    <v-icon start>
                        $export-csv
                    </v-icon>
                    {{ $t('action.exportCsv') }}
                </v-btn>
                <v-btn :loading="loading"
                       class="hidden-lg-and-up"
                       icon
                       color="primary"
                       size="small"
                       @click="exportCSV()">
                    <v-icon>$export-csv</v-icon>
                </v-btn>
            </div>

            <div v-if="logs.length > 0"
                 class="d-flex">
                <h6 v-if="logs.length < filter.maxResults">
                    {{ $t('label.showingResults', logs.length) }}
                </h6>
                <h6 v-if="logs.length >= filter.maxResults">
                    {{ $t('admin.log.maxResultsInfo', 2000) }}
                </h6>
            </div>

            <div class="d-flex flex-column"
                 style="height:calc(100vh - 20px);overflow-y:auto">
                <ow-data-grid v-model="selected"
                              :headers="headers"
                              :item-key="'id'"
                              :items="logs"
                              :items-per-page="-1"
                              class="elevation-1 admin-table"
                              :sort-by="sortBy"
                              :allow-selection="false"
                              fixed-header
                              height="550"
                              hide-default-footer>
                    <template #headers>
                        <tr>
                            <th class="column sortable">
                                When
                            </th>
                            <th class="column sortable">
                                Type
                            </th>
                            <th class="column sortable hidden-sm-and-down">
                                SubType
                            </th>
                            <th class="column sortable">
                                User
                            </th>
                            <th class="column sortable">
                                Message
                            </th>
                        </tr>
                    </template>
                    <template #items="props">
                        <tr :key="props.item.id">
                            <td class="body-copy">
                                {{ props.item.when }}
                            </td>
                            <td class="body-copy">
                                {{ props.item.type }}
                            </td>
                            <td class="hidden-sm-and-down body-copy">
                                {{ props.item.subType }}
                            </td>
                            <td class="body-copy">
                                {{ props.item.user }}
                            </td>
                            <td v-dompurify-html="props.item.message"
                                class="admin-log-message body-copy" />
                        </tr>
                    </template>
                </ow-data-grid>
            </div>
        </div>
    </v-container>
</template>
<script>

    import { ref } from 'vue'

    import UserApi from '@/api/user.api'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'

    export default {

        components: {
            OwDataGrid,
        },

        data() {
            return {
                loading: false,
                selected: [], // eslint-disable-next-line vue/no-unused-properties
                headers: [{
                    title: 'When',
                    align: 'left',
                    key: 'when',
                    width: '166px',
                }, {
                    title: 'Type',
                    align: 'left',
                    key: 'type',
                }, {
                    title: 'Sub-type',
                    align: 'left',
                    key: 'subType',
                }, {
                    title: 'User',
                    align: 'left',
                    key: 'user',
                }, {
                    title: 'Message',
                    align: 'left',
                    key: 'message',
                    sortable: false,
                }],
                logs: [],
                times: [

                    'Today', 'Past 3 days', 'Past 7 days', 'Past 5 weeks', 'This year'],
                filter: {
                    time: 'Today',
                    user: null,
                    type: null,
                    subType: null,
                    maxResults: 1000,
                },
                sortBy: ref([{key: 'when', order: 'asc'}]),
            }
        },

        watch: {

            'filter.time'() {
                this.getLogs()
            },

        },

        mounted() {
            this.getLogs()
        },

        methods: {

            async getLogs() {
                this.loading = true

                const query = {
                    from: this.filter.time,
                    user: this.filter.user,
                    type: this.filter.type,
                    subType: this.filter.subType,
                    maxResults: this.filter.maxResults,
                }

                const response = await UserApi.getUserLogs({ query })
                if (response.ok) {
                    this.logs = response.data
                }

                this.loading = false
            },

            async exportCSV() {
                this.loading = true

                const query = {
                    from: this.filter.time,
                    user: this.filter.user,
                    type: this.filter.type,
                    subType: this.filter.subType,
                }

                const response = await UserApi.exportLogsAsCsv({ query })
                if (response.ok) {
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.style = 'display: none'
                    const blob = new Blob([response.data], { type: 'text/csv' })
                    const url = window.URL.createObjectURL(blob)
                    a.href = url
                    a.download = 'log.csv'
                    a.click()
                    window.URL.revokeObjectURL(url)
                }
                this.loading = false
            },
        },
    }
</script>

<style lang="scss">
    @import './admin-log'
</style>
