import { getBoundaryDataAttribution } from '@/store/modules/map/layers/hmlr'
import { getOSDataHubAPIKey } from '@/utils/environment.utils'
import { useStore } from 'vuex'

export const useMapOptions = () => {
    const store = useStore()
    const npsLayer = store.state?.config?.settings?.npsLayer
    const monthlyHMLRDataVersion = store?.state?.config?.settings?.monthlyHMLRDataVersion
    const osDataHubApiKey = getOSDataHubAPIKey()
    const layerAttribution = getBoundaryDataAttribution(monthlyHMLRDataVersion)

    return {
        npsLayer,
        boundaryColour: '#f44336',
        boundaryWidth: 5,
        layerAttribution,
        osDataHubApiKey,
        name: 'title-boundary-map',
    }
}
