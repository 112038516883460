<template>
    <v-card v-if="loadingSelectedTitle || title !== null"
            class="title-summary-panel"
            :class="[
                isWalkthrough ? 'walkthrough' : '',
            ]"
            data-test="title-summary-panel">
        <v-card-title v-if="title !== null"
                      class="title-summary-panel__header">
            <div class="d-flex flex-row flex align-center justify-space-between">
                <div class="content__heading title-summary-panel__header--title"
                     :title="`Details for ${displayTitle}`">
                    Details for {{ displayTitle }}
                </div>
                <div class="d-flex shrink">
                    <v-btn class="title-summary-panel__header--close-btn"
                           data-test="btn-title-summary-panel-close"
                           data-track="WALKTHROUGH - Close title summary details panel"
                           variant="text"
                           icon
                           @click="onCloseClick()">
                        <v-icon>$close</v-icon>
                    </v-btn>
                </div>
            </div>
        </v-card-title>
        <v-card-text v-if="!loadingSelectedTitle && title !== null">
            <article>
                <div class="d-flex flex-column main-data-property">
                    <div v-if="showTitleNumberProperty"
                         class="d-flex flex-column title-summary-panel__item">
                        <label class="title-summary-panel__label">Title Number</label>
                        <p>{{ title.titleNumber }}</p>
                    </div>

                    <div class="d-flex flex-column title-summary-panel__item">
                        <label class="title-summary-panel__label">Tenure</label>
                        <p :class="{
                               'tenure--freehold': tenureType === 'freehold',
                               'tenure--leasehold': tenureType === 'leasehold'
                           }"
                           class="tenure">
                            {{ title.tenure }}
                        </p>
                    </div>

                    <div v-if="hasOwnerNames"
                         class="d-flex flex-column title-summary-panel__item">
                        <label class="title-summary-panel__label">{{ ownerLabel }}</label>
                        <ul class="title-summary-panel__list">
                            <li v-for="(name, index) in title.proprietorNames"
                                :key="index">
                                {{ name }}
                                <a v-if="getCompanyRegOwnerByIndex(index)"
                                   :href="getCompaniesHouseLink(getCompanyRegOwnerByIndex(index))"
                                   target="_blank">
                                    ({{ getCompanyRegOwnerByIndex(index) }})
                                    <ow-tooltip :position="OwTooltipPosition.Bottom"
                                                color="primary"
                                                activator="parent">
                                        <span>Search on Companies House</span>
                                    </ow-tooltip>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div v-if="hasLeaseTermText"
                         class="d-flex flex-column title-summary-panel__item">
                        <label class="title-summary-panel__label">Lease Term</label>
                        <p>{{ title.leaseTermText }}</p>
                    </div>

                    <div v-if="titleAddresses.length === 0"
                         class="d-flex flex-column">
                        <label class="title-summary-panel__label">{{ addressLabel }}</label>
                        <p>
                            No address information available. Please load the digital title to view address information.
                        </p>
                    </div>

                    <div v-else
                         class="d-flex flex-column">
                        <label class="title-summary-panel__label">{{ addressLabel }}</label>

                        <div v-if="hasMultipleAddresses">
                            {{ titleAddresses.length }} addresses registered at this property
                        </div>

                        <p v-if="!hasMultipleAddresses">
                            {{ titleAddresses[0].address }}
                        </p>
                        <ul v-else
                            class="address-list title-summary-panel__list">
                            <li v-for="(address, index) in titleAddresses"
                                :key="address.uprn"
                                :class="{
                                    'address-list__first' : index === 0,
                                }"
                                class="address-list__item">
                                <span class="address-list__item--index">{{ index + 1 }}.</span>
                                <span class="address-list__item--address">{{ address.address }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </article>
        </v-card-text>
        <v-card-text v-if="loadingSelectedTitle">
            <v-progress-linear color="primary"
                               indeterminate />
        </v-card-text>
    </v-card>
</template>

<script>
    import pluralize from 'pluralize'
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { MATTER_GET_MATTER_CONTENTS } from '@/store/modules/matter/types'
    import { TITLE_CLEAR } from '@/store/modules/titles/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import { titleDataMixin } from './title-details.mixin'

    export default {
        name: 'TitleSummaryPanel',

        components: { OwTooltip },

        mixins: [titleDataMixin],

        data() {
            return {
                OwTooltipPosition,
                titleLabel: '',
            }
        },

        computed: {
            ...mapState({
                loadingSelectedTitle: state => state.title.loadingSummaryTitle,
                title: state => state.title.selectedSummaryTitle,
                isWalkthrough: state => state.walkthrough.enabled,
            }),

            ...mapGetters({
                matterContent: MATTER_GET_MATTER_CONTENTS,
            }),

            displayTitle() {
                return this.titleLabel ?? this.title.titleNumber
            },

            titleAddresses() {
                return this.title?.addresses ?? []
            },

            ownerLabel() {
                return pluralize('Owner', this.title?.proprietorNames?.length)
            },

            addressLabel() {
                return pluralize('Address', this.titleAddresses?.length)
            },

            hasMultipleAddresses() {
                return this.titleAddresses?.length > 1
            },

            showTitleNumberProperty() {
                return this.title?.label !== this.title.titleNumber
            },
        },

        watch: {
            'title.titleNumber': {
                handler(newTitleNumber) {
                    if (!isNullOrWhitespace(newTitleNumber)) {
                        this.titleLabel = this.matterContent?.titles.find(title => title.titleNumber === newTitleNumber)?.label ?? newTitleNumber
                    }
                },
                immediate: true,
                deep: true,
            },
        },

        methods: {
            ...mapActions({
                clearTitles: TITLE_CLEAR,
            }),

            async onCloseClick() {
                await this.clearTitles()
            },

            getCompanyRegOwnerByIndex(index) {
                if (isNullOrEmpty(this.title?.companyRegNumbers)) {
                    return null
                }
                return this.title?.companyRegNumbers[index]
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './title-summary-panel';
</style>
