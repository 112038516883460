import { ModelFieldConfig } from "@bryntum/grid"
import BasketItemModel from "./BasketItemModel"

export type Con29DwcProductConfig = {
    lessThan10Years: boolean
    developerName: string
    siteNameAndPhase: string
    offSiteRoadName: string
    expedited: string
}


class BasketItemCon29DwcModel extends BasketItemModel {
    static get fields(): Partial<ModelFieldConfig>[] {
        const fields = super.fields
        return [
            ...fields,
            { name: 'isConfigurable', type: 'boolean', defaultValue: true },
            { name: 'config', type: 'object', defaultValue: {
                lessThan10Years: false,
                expedited: 'Normal',
            }},
        ]
    }

    override productOptionsToJson(): string {
        const productConfig = this.config as Con29DwcProductConfig
        const result: Con29DwcProductConfig = {
            lessThan10Years: productConfig?.lessThan10Years || false,
            developerName: productConfig?.developerName || '',
            siteNameAndPhase: productConfig?.siteNameAndPhase || '',
            offSiteRoadName: productConfig?.offSiteRoadName || '',
            expedited: productConfig?.expedited || 'Normal',
        }

        return JSON.stringify(result)
    }

    override jsonToProductOptions(json: string): void {
        const productConfig = JSON.parse(json) as Con29DwcProductConfig
        this.config = productConfig
    }
}

export default BasketItemCon29DwcModel
