<template>
    <nav class="settings-sub-navigation">
        <ul class="settings-sub-navigation__container">
            <li :class="{
                'is-active': route.name === Route.MattersSettings,
            }">
                <router-link :to="overviewLink"
                             class="caption-highlight">
                    Overview
                </router-link>
            </li>
            <li v-if="isAssetMonitoringEnabled"
                :class="{
                    'is-active': route.name === Route.MattersMonitoringPreferences,
                }">
                <router-link :to="monitoringPreferencesLink"
                             class="caption-highlight">
                    Monitoring preferences
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import {
        type RouteLocationRaw,
        useRoute,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import { Route } from '@/enums/route.enum'

    const route = useRoute()
    const store = useStore()

    const props = defineProps<{
        currentMatterId?: Number,
    }>()

    const isAssetMonitoringEnabled = computed<boolean>(() => store.state.config.featureFlags?.assetMonitoring)

    const overviewLink = computed<RouteLocationRaw>((): RouteLocationRaw => {
        return {
            name: Route.MattersSettings,
            params: { matterId: props.currentMatterId?.toString() },
        }
    })

    const monitoringPreferencesLink = computed<RouteLocationRaw>((): RouteLocationRaw => {
        return {
            name: Route.AssetMonitoringTitleAlertsPreferences,
            params: { matterId: props.currentMatterId?.toString() },
        }
    })
</script>

<style scoped lang="scss">
    @import './settings-sub-navigation';
</style>
