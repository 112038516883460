<template>
    <div id="dialogRoot"
         class="dialog-root">
        <dialog-export-official-copies-copies />
        <dialog-remove-titles-from-matter />
        <dialog-remove-documents-from-matter />
        <dialog-group-create />
        <dialog-group-update />
        <dialog-group-delete />

        <app-popup />
        <warn-popups-blocked />

        <ow-prevent-transition-dialog v-model="showPreventTransitionDialog"
                                      :content-text="preventTransition.text"
                                      :title="preventTransition.title"
                                      @close="discardChanges"
                                      @submit="saveChanges" />

        <ow-confirmation-dialog />
    </div>
</template>

<script lang="ts">
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import AppPopup from '@/components/app-popup.vue'
    import WarnPopupsBlocked from '@/components/app-warnpopupsblocked.vue'
    import OwConfirmationDialog from '@/components/core/ow-confirmation-dialog.vue'
    import OwPreventTransitionDialog from '@/components/core/ow-prevent-transition-dialog.vue'
    import DialogExportOfficialCopiesCopies from '@/components/dialogs/dialog-export-official-copies.vue'
    import DialogGroupCreate from '@/components/dialogs/dialog-group-create.vue'
    import DialogGroupDelete from '@/components/dialogs/dialog-group-delete.vue'
    import DialogGroupUpdate from '@/components/dialogs/dialog-group-update.vue'
    import DialogRemoveDocumentsFromMatter from '@/components/dialogs/dialog-remove-documents-from-matter.vue'
    import DialogRemoveTitlesFromMatter from '@/components/dialogs/dialog-remove-titles-from-matter.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE,
        CORE_MUTATE_PREVENT_TRANSITION,
    } from '@/store/modules/core/types'

    export default {
        name: 'DialogRoot',
        components: {
            AppPopup,
            DialogExportOfficialCopiesCopies,
            DialogGroupCreate,
            DialogGroupDelete,
            DialogGroupUpdate,
            DialogRemoveTitlesFromMatter,
            WarnPopupsBlocked,
            OwConfirmationDialog,
            OwPreventTransitionDialog,
            DialogRemoveDocumentsFromMatter,
        },
        mixins: [FeatureFlagsMixin],
        computed: {
            ...mapState({
                preventTransition: (state: IState) => state.core.preventTransition,
            }),

            showPreventTransitionDialog: {
                get(): boolean {
                    return this.preventTransition.showDialog
                },
                set(val: boolean) {
                    this.setPreventTransition({ showDialog: val })
                },
            },
        },
        methods: {
            ...mapMutations({
                setPreventTransition: CORE_MUTATE_PREVENT_TRANSITION,
                initPreventTransitionState: CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE,
            }),

            async saveChanges(closeDialog?: Function): Promise<void> {
                await this.preventTransition.submitForm()
                if (closeDialog) {
                    closeDialog()
                }
                await this.proceedTransition()
            },

            discardChanges(): void {
                this.preventTransition.resetForm()
                this.proceedTransition()
                this.initPreventTransitionState()
            },

            async proceedTransition(): Promise<void> {
                const routeName = this.preventTransition.transitionRoute?.name
                this.initPreventTransitionState()

                if (routeName) {
                    await this.$router.push({ name: routeName })
                }
            },
        },
    }
</script>
