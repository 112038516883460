<template>
    <v-form ref="formRef"
            class="w-100"
            :data-valid="valid"
            @submit.prevent>
        <div class="matter-searches-enter-address-form d-flex flex-column gr-1"
             :class="{
                 'matter-searches-enter-address-form--full-width': fullWidth,
             }">
            <div v-if="addresses.length && !disabled"
                 class="d-flex flex-row gc-4">
                <div class="d-flex-inline w-75">
                    <label for="address-select"
                           class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                        {{ t('searches.enterAddressForm.titlesWithAddresses', {
                            num: addresses.length,
                        }) }}
                    </label>
                    <v-select id="address-select"
                              v-model="selectedAddress"
                              :disabled="disabled"
                              data-test="sector-select"
                              variant="solo"
                              item-title="address"
                              item-value="address"

                              :items="addresses" />
                </div>
            </div>
            <v-divider v-if="!disabled"
                       class="pt-3" />
            <div class="d-flex flex-row gc-4">
                <div class="d-flex-inline w-25">
                    <label for="sector-select"
                           class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                        {{ t('searches.enterAddressForm.sector') }}
                    </label>
                    <v-select id="sector-select"
                              v-model="form.sector"
                              :readonly="disabled"
                              data-test="sector-select"
                              variant="solo"
                              :rules="[rules.required]"
                              :items="[PropertyType[PropertyType.Commercial].toString(), PropertyType[PropertyType.Residential].toString()]" />
                </div>
                <div class="d-flex-inline w-50">
                    <label for="organisation-name-textfield"
                           class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                        {{ t('searches.enterAddressForm.organisationName') }}
                    </label>
                    <v-text-field id="organisation-name-textfield"
                                  v-model="form.organisationName"
                                  :readonly="disabled"
                                  data-test="organisation-name-textfield"
                                  variant="solo" />
                </div>
                <div class="d-flex-inline w-25">
                    <label for="building-number-textfield"
                           class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                        {{ t('searches.enterAddressForm.buildingNumber') }}
                    </label>
                    <v-text-field id="building-number-textfield"
                                  v-model="form.buildingNumber"
                                  :readonly="disabled"
                                  data-test="building-number-textfield"
                                  variant="solo" />
                </div>
            </div>
            <div class="d-flex flex-row gc-4">
                <div class="d-flex-inline w-50">
                    <label for="thoroughfare-name-textfield"
                           class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                        {{ `${ t('searches.enterAddressForm.thoroughfareName') }${ disabled ? '' : '*' }` }}
                    </label>
                    <v-text-field id="thoroughfare-name-textfield"
                                  v-model="form.thoroughfareName"
                                  :readonly="disabled"
                                  data-test="thoroughfare-name-textfield"
                                  variant="solo"
                                  :rules="[rules.required]" />
                </div>
                <div class="d-flex-inline w-25">
                    <label for="post-town-textfield"
                           class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                        {{ `${t('searches.enterAddressForm.postTown')}${ disabled ? '' : '*' }` }}
                    </label>
                    <v-text-field id="post-town-textfield"
                                  v-model="form.postTown"
                                  :readonly="disabled"
                                  data-test="post-town-textfield"
                                  variant="solo"
                                  :rules="[rules.required]" />
                </div>
                <div class="d-flex-inline w-25">
                    <label for="postcode-textfield"
                           class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                        {{ `${t('searches.enterAddressForm.postcode')}${ disabled ? '' : '*' }` }}
                    </label>
                    <v-text-field id="postcode-textfield"
                                  v-model="form.postcode"
                                  :readonly="disabled"
                                  data-test="postcode-textfield"
                                  variant="solo"
                                  :rules="[rules.required, rules.ukpostcode]" />
                </div>
            </div>
        </div>
    </v-form>
</template>

<script setup lang="ts">
    import {
        computed,
        reactive,
        ref,
    } from 'vue'
    import { watch } from 'vue'
    import { onMounted } from 'vue'
    import { onActivated } from 'vue'
    import { useI18n } from 'vue-i18n'

    import {StructuredAddress} from "@/api/searches.api"
    import {PropertyType} from "@/enums/searches.enum"
    import {isNullOrWhitespace} from "@/utils/string-utils"

    const form = reactive<StructuredAddress>({
        organisationName: '',
        buildingNumber: '',
        thoroughfareName: '',
        postTown: '',
        postcode: '',
        sector: '',
    })

    const model = defineModel<StructuredAddress>()
    const valid = defineModel<boolean>('valid')

    const formRef = ref(null)
    const { t } = useI18n()

    const rules = reactive({
        required: (v: string) => !isNullOrWhitespace(v) || 'Field is required',
        ukpostcode: (v: string) => /^([A-Z][A-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][A-Z]{2}|GIR ?0A{2})$/i.test(v) || 'Invalid UK postcode',
    })

    const props = withDefaults(defineProps<{
        addresses: {
            postcode: string
            address: string
        }[],
        disabled: boolean
        fullWidth: boolean
    }>(), {
        addresses: () => [],
        disabled: false,
    })

    const selectedAddress = ref<string>(null)

    const init = async () => {
        if (!model?.value) {
            selectedAddress.value = props.addresses?.[0]?.address
            return
        }
        if (model?.value.postcode || model?.value.thoroughfareName) {
            const index = props.addresses.findIndex((a) => {
                const addressEntries = a.address.trim().split(', ')
                const thoroughfareName = addressEntries.slice(0, 2).join(', ')
                return a.postcode === model?.value.postcode && thoroughfareName === model?.value.thoroughfareName
            })

            if (index !== -1 && index <= props.addresses.length) {
                selectedAddress.value = props.addresses[index].address
            } else {
                selectedAddress.value = props.addresses?.[0]?.address
            }

        }

        const valid = await validate()
        emit('update:valid', valid)
    }

    onMounted(() => {
        init()
    })

    onActivated(() => {
        init()
    })

    const emit = defineEmits<{
        (e: 'update:valid', valid: boolean): void
        (e: 'update:modelValue', value: StructuredAddress): void
    }>()

    const validate = async () => {
        const { valid } = await formRef.value.validate()
        return valid
    }

    watch(() => model?.value, (newValue: StructuredAddress) => {
        form.organisationName = newValue?.organisationName ?? ''
        form.buildingNumber = newValue?.buildingNumber ?? ''
        form.thoroughfareName = newValue?.thoroughfareName ?? ''
        form.postTown = newValue?.postTown ?? ''
        form.postcode = newValue?.postcode ?? ''
        form.sector = newValue?.sector ?? PropertyType[PropertyType.Commercial].toString()
    }, {
        immediate: true,
        deep: true,
    })

    watch(() => form, async () => {
        model.value = {
            organisationName: form.organisationName,
            buildingNumber: form.buildingNumber,
            thoroughfareName: form.thoroughfareName,
            postTown: form.postTown,
            postcode: form.postcode,
            sector: form.sector,
        }
        const validationResult = await validate()
        valid.value = validationResult
    }, {
        deep: true,
    })
</script>

<style lang="scss">
    @import './matter-searches-enter-address-form';
</style>
