<template>
    <div ref="gridRef">
        <ow-data-grid :headers="headers"
                      :items="documents"
                      item-key="documentId"
                      class="documents-table"
                      has-pagination
                      has-pagination-all-option
                      :sort-by="sortBy"
                      hover
                      :height="gridHeight"
                      @items-selected="onItemSelection">
            <template #[`item.document`]="{ item }">
                <td class="body-regular documents-table__doc-name"
                    :title="getDocumentName(item)"
                    @click="item.selected = !item.selected">
                    <div class="document-name">
                        {{ getDocumentName(item) }}
                    </div>
                </td>
            </template>
            <template #[`item.groupName`]="{ item }">
                <td class="caption-regular documents-table__item"
                    :title="item?.groupName"
                    @click="item.selected = !item.selected">
                    <div class="group-name">
                        {{ item.groupName ? item.groupName : '-' }}
                    </div>
                </td>
            </template>
            <template #[`item.titleNo`]="{ item }">
                <td :class="getClassForTitleNumberCell(item)"
                    class="caption-regular documents-table__title-no"
                    data-test="documents-table-title-number"
                    data-track="DOCS - Open title from documents table">
                    <ow-title-number-tooltip :title-number="item.titleNo"
                                             @click="handleTooltipClick" />
                </td>
            </template>
            <template #[`item.documentType`]="{ item }">
                <td data-test="documents-table-document-type"
                    class="caption-regular documents-table__item document-type"
                    @click="item.selected = !item.selected">
                    {{ item.documentType }}
                </td>
            </template>
            <template #[`item.documentDateSortable`]="{ item }">
                <td class="caption-regular documents-table__item"
                    @click="item.selected = !item.selected">
                    {{ item.documentDate }}
                </td>
            </template>
            <template #[`item.documentDownloadDateSortable`]="{ item }">
                <td class="hidden-sm-and-down caption-regular documents-table__item"
                    @click="item.selected = !item.selected">
                    {{ item.documentDownloadDate }}
                </td>
            </template>
            <template #[`item.documentStatus`]="{ item }">
                <td :class="{'is-current-matter-inactive': isCurrentMatterInactive}"
                    class="caption-regular documents-table__item--actions">
                    <document-order-view-link :document="item"
                                              :document-type="item.highLevelDocumentType"
                                              :show-only-download-button="isCurrentMatterInactive"
                                              :title-number="item.titleNo" />

                    <remove-document-button :document="item" />
                </td>
            </template>
        </ow-data-grid>
    </div>
</template>
<script lang="ts">
    import { ref } from 'vue'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwTitleNumberTooltip from '@/components/core/ow-title-number-tooltip.vue'
    import DocumentOrderViewLink from '@/components/documents/document-order-view-link.vue'
    import RemoveDocumentButton from '@/components/documents/remove-document-button.vue'
    import useWindowResize from '@/composables/use-window-resize'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    // eslint-disable-next-line no-unused-vars
    import {
        getPurchasedDocumentName,
    } from '@/utils/document-utils'

    export default {
        name: 'DocumentsTable',
        components: {
            OwTitleNumberTooltip,
            DocumentOrderViewLink,
            OwDataGrid,
            RemoveDocumentButton,
        },

        mixins: [FlagsMixin],

        props: {
            documents: {
                type: Array,
                required: true,
            },
            isCurrentMatterInactive: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'selected-documents-changed',
            'title-number-selected',
        ],

        setup() {
            const gridHeight = ref('0')
            const gridRef = ref(null)
            useWindowResize({
                onResize({ clientHeight }) {
                    const copilotHeight = document.querySelector('.cta-copilot')?.clientHeight ?? 80
                    gridHeight.value = ((clientHeight - gridRef.value.getBoundingClientRect().top) - copilotHeight - 60).toString()
                },
            })
            return {
                gridHeight,
                gridRef,
            }
        },
        data() {
            return {
                selected: [],
                sortBy: ref([{key: 'documentDateSortable', order: 'asc'}]),
            }
        },

        computed: {
            headers() {
                return [
                    {
                        title: this.$t('table.label.document'),
                        align: 'left',
                        key: 'document',
                    },
                    {
                        title: this.$t('table.label.group'),
                        align: 'left',
                        key: 'groupName',
                    },
                    {
                        title: this.$t('table.label.title'),
                        align: 'left',
                        key: 'titleNo',
                    },
                    {
                        title: this.$t('table.label.type'),
                        align: 'left',
                        key: 'documentType',
                    },
                    {
                        title: this.$t('table.label.documentDate'),
                        align: 'left',
                        key: 'documentDateSortable',
                    },
                    {
                        title: this.$t('table.label.downloadDate'),
                        align: 'left',
                        key: 'documentDownloadDateSortable',
                    },
                    {
                        title: this.isCurrentMatterInactive ? '' : this.$t('table.label.status'),
                        align: 'center',
                        key: 'documentStatus',
                    },
                ]
            },
        },

        watch: {

            selected(val) {
                this.$emit('selected-documents-changed', val)
            },

        },

        methods: {
            getClassForTitleNumberCell(item) {
                if (item.documentType !== 'Spreadsheet' && !this.isCurrentMatterInactive) {
                    return 'clickable'
                }
                return ''
            },

            onItemSelection(items) {
                this.selected = this.documents.filter(({ documentId }) => items.includes(documentId))
            },

            getDocumentName(document) {
                document.document = getPurchasedDocumentName(document)
                return document.document
            },

            handleTooltipClick(titleNumber) {
                this.$emit('title-number-selected', titleNumber)
            },
        },
    }
</script>
<style lang="scss">
    @import 'documents-table.scss';
</style>
