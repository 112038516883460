<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 24 24"
                  desc="TAM Icon">
        <path d="M0 0h24v24H0V0z"
              fill="none" />
        <path id="path-to-fill"
              fill="#333333"
              d="M20 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H5V5h15zm-5 14h-5v-9h5v9zM5 10h3v9H5v-9zm12 9v-9h3v9h-3z" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconTam',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '24px',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
        },
    }
</script>
