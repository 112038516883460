<template>
    <div class="matter-searches-step-footer d-flex justify-space-between align-center pa-4 gap-4">
        <div class="d-flex align-center gap-4 text-no-wrap">
            <ow-button data-test="cancel-button"
                       @click="onCancelClick">
                {{ $t('action.cancel') }}
            </ow-button>
            <ow-button v-if="!isBackButtonDisabled"
                       data-test="back-button"
                       @click="onBackClick">
                {{ $t('action.back') }}
            </ow-button>
        </div>
        <div v-if="(stepId === SearchesSteps.SelectProducts || stepId === SearchesSteps.Draft)"
             class="d-flex align-center gap-4 text-no-wrap">
            <v-tooltip :location="'top center'"
                       :open-on-hover="!valid || errored">
                <template #activator="{ props: activatorProps }">
                    <div v-bind="activatorProps">
                        <span class="body-highlight">{{ $t('searches.productsSelected', basket?.allCount ?? 0) }}</span>
                        <span v-show="!valid || errored"
                              :style="{
                                  color: Colors.Danger
                              }"
                              class="mdi mdi-alert px-2" />
                    </div>
                </template>
                <matter-searches-step-footer-tooltip :basket="basket"
                                                     :valid="valid === true"
                                                     :errored="errored" />
            </v-tooltip>
            <div class="d-flex">
                <ow-button is-secondary
                           data-test="save-draft-button"
                           :disabled="totalCost?.totalGross === 0 || calculatingQuote || submittingOrder || errored || savingDraftOrder || !valid || !basketDirty"
                           :is-loading="savingDraftOrder"
                           class="mr-3"
                           @click="onSaveDraft">
                    {{ getDraftOrderButtonText() }}
                </ow-button>
                <ow-button is-primary
                           data-test="create-order-button-confirmation"
                           :is-loading="calculatingQuote"
                           :disabled="totalCost?.totalGross === 0 || calculatingQuote || submittingOrder || errored || !valid"
                           class="mr-16"
                           @click="showConfirmationDialog = true">
                    {{ getCreateOrderButtonText() }}
                </ow-button>
            </div>
        </div>
        <div v-else-if="stepId === SearchesSteps.DefinePolygon"
             class="d-flex align-center gap-4 text-no-wrap">
            <ow-tooltip :disabled="!geoJsonDisconnected"
                        max-width="400px"
                        activator="parent">
                <template #activator="{ props: activatorProps }">
                    <ow-button is-primary
                               v-bind="activatorProps"
                               data-test="select-product-button"
                               class="mr-16"
                               :disabled="!geoJson || loading"
                               :is-loading="loading"
                               :is-danger="geoJsonDisconnected"
                               @click="onConfirmClick">
                        {{ confirmButtonText }}
                        <template v-if="geoJsonDisconnected"
                                  #iconSuffix>
                            <span class="mdi mdi-alert" />
                        </template>
                    </ow-button>
                </template>
                <div class="matter-searches-step-footer__alert d-flex flex-column gc-2">
                    <div v-t="'searches.map.boundaryDisconnected'"
                         class="headers-h2 my-2" />
                    <div v-t="'searches.map.boundaryDisconnectedDescription'"
                         class="caption-regular mb-2" />
                    <div v-t="'searches.map.clickToEditBoundary'"
                         class="caption-highlight mb-3" />
                    <img src="/src/media/searches-boundary.gif"
                         alt="Boundary disconnected animation"
                         class="matter-searches-step-footer__alert-img mb-2">
                </div>
            </ow-tooltip>
        </div>
        <div v-else-if="stepId === SearchesSteps.DefineAddress"
             class="d-flex align-center gap-4 text-no-wrap">
            <ow-button v-if="addressValid"
                       data-test="select-address-button"
                       @click="resetForm">
                {{ $t('action.clear') }}
            </ow-button>
            <ow-button is-primary
                       data-test="select-address-button"
                       class="mr-16"
                       :disabled="!confirmDetailsValid"
                       @click="onConfirmClick">
                {{ confirmAddressText }}
            </ow-button>
        </div>
        <ow-dialog v-model="showConfirmationDialog"
                   :title="$t('searches.createOrder.orderConfirmationTitle')"
                   @update:model-value="showConfirmationDialog = false">
            <span v-t="'searches.createOrder.orderConfirmation'"
                  class="caption-regular" />
            <div class="d-flex mt-3 justify-end">
                <ow-button class="mt-3 mr-3"
                           outlined
                           :disabled="submittingOrder"
                           @click="showConfirmationDialog = false">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button class="mt-3"
                           data-test="create-order-button"
                           :disabled="submittingOrder"
                           :is-loading="submittingOrder"
                           outlined
                           is-primary
                           @click="onCreateOrder">
                    {{ getCreateOrderButtonText() }}<template #iconSuffix>
                        <span v-if="!valid || errored"
                              class="mdi mdi-alert" />
                    </template>
                </ow-button>
            </div>
        </ow-dialog>
    </div>
</template>

<script setup lang="ts">
    import { Store } from '@bryntum/grid'
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import {              useRoute,
                          useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import {IGetOrganisationSettingsResponse,
            StructuredAddress} from '@/api/searches.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDialog from '@/components/core/ow-dialog.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import MatterSearchesStepFooterTooltip from '@/components/matter-searches/matter-searches-step-footer-tooltip.vue'
    import { Colors } from '@/enums/colors.enum'
    import { Route } from '@/enums/route.enum'
    import {
        SearchesSteps,
    } from '@/enums/searches.enum'
    import i18n from '@/plugins/i18n'
    import {
        ProductTotals,
        SEARCHES_CREATE_DRAFT_ORDER,
        SEARCHES_CREATE_ORDER,
        SEARCHES_GET_BASKET,
        SEARCHES_GET_BASKET_DIRTY,
        SEARCHES_GET_DRAFT_ORDER_ID,
        SEARCHES_GET_ERROR,
        SEARCHES_GET_GEOJSON,
        SEARCHES_GET_GEOJSON_HAS_MULTIPLE_GEOMETRIES,
        SEARCHES_GET_LOADING,
        SEARCHES_GET_ORGANISATION_SETTINGS,
        SEARCHES_GET_PRODUCT_TOTALS,
        SEARCHES_GET_PROJECT_NAME,
        SEARCHES_GET_QUOTE_CALCULATING,
        SEARCHES_GET_SAVING_DRAFT_ORDER,
        SEARCHES_GET_STRUCTURED_ADDRESS,
        SEARCHES_GET_SUBMITTING_ORDER,
        SEARCHES_MUTATE_PROJECT_NAME,
        SEARCHES_MUTATE_STRUCTURED_ADDRESS,
        SEARCHES_MUTATE_SUBMITTING_ORDER,
        SEARCHES_SHOW_DRAW_BOUNDARY,
        SEARCHES_SUBMIT_DRAFT_ORDER,
        SEARCHES_UPDATE_DRAFT_ORDER,
    } from '@/store/modules/searches/types'
    import { gbpCurrencyFormatter } from '@/utils/number-utils'

    import BasketItemModel from '../models/BasketItemModel'

    const props = defineProps<{
        stepId?: SearchesSteps
        matterId: string | string[]
    }>()

    const emit = defineEmits<{
        (e: 'step-change', id: number): void
    }>()

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const basket = computed<Store>(() => store.getters[SEARCHES_GET_BASKET])
    const totalCost = computed<ProductTotals>(() => store.getters[SEARCHES_GET_PRODUCT_TOTALS])
    const calculatingQuote = computed<boolean>(() => store.getters[SEARCHES_GET_QUOTE_CALCULATING] || basket.value?.some((basketItem: BasketItemModel) => basketItem.calculating))
    const valid = computed<any[] | boolean>(() => basket.value?.every((basketItem: BasketItemModel) => basketItem.valid))
    const errored = computed<boolean>(() => basket.value?.some((basketItem: BasketItemModel) => basketItem.errored))
    const submittingOrder = computed<boolean>(() => store.getters[SEARCHES_GET_SUBMITTING_ORDER])
    const loading = computed<boolean>(() => store.getters[SEARCHES_GET_LOADING])
    const savingDraftOrder = computed<boolean>(() => store.getters[SEARCHES_GET_SAVING_DRAFT_ORDER])
    const basketDirty = computed<boolean>(() => store.getters[SEARCHES_GET_BASKET_DIRTY])
    const argsParam = computed<string>(() => route.params?.args?.toString())
    const draftOrderId = computed<string>(() => store.getters[SEARCHES_GET_DRAFT_ORDER_ID])
    const geoJson = computed<string>(() => store.getters[SEARCHES_GET_GEOJSON])
    const geoJsonDisconnected = computed<boolean>(() => store.getters[SEARCHES_GET_GEOJSON_HAS_MULTIPLE_GEOMETRIES])
    const error = computed<Map<string, any>>(() => store.getters[SEARCHES_GET_ERROR])
    const isDraftOrder = computed(() => argsParam.value && props.stepId === SearchesSteps.Draft)
    const isBackButtonDisabled = computed(() => {
        return props?.stepId === SearchesSteps.DefinePolygon || isDraftOrder.value
    })
    const showConfirmationDialog = ref<boolean>(false)

    const structuredAddress = computed<StructuredAddress>({
        get: () => store.getters[SEARCHES_GET_STRUCTURED_ADDRESS],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_STRUCTURED_ADDRESS, val)
        },
    })

    const addressValid = computed<boolean>(() => store.state.searches.addressValid)

    const organisationSettings = computed((): IGetOrganisationSettingsResponse => store.getters[SEARCHES_GET_ORGANISATION_SETTINGS])
    const projectNameFormValid = computed<boolean>(() => store.state.searches.projectNameValid)

    const projectNameValid = computed<boolean>(() => {
        if(organisationSettings.value.projectMask == null){
            return true
        }

        return projectNameFormValid.value
    })

    const confirmDetailsValid = computed<boolean>(() => addressValid.value && projectNameValid.value)


    const resetForm = () => {
        structuredAddress.value = {
            organisationName: '',
            buildingNumber: '',
            thoroughfareName: '',
            postTown: '',
            postcode: '',
            sector: '',
        }
        //projectName.value = ''
    }

    const onBackClick = async () => {
        emit('step-change', props.stepId - 1)
    }

    const getCreateOrderButtonText = () => {
        if (submittingOrder.value) {
            return 'Submitting Order'
        }
        if (calculatingQuote.value || basket.value?.some((basketModel) => basketModel.get('calculating') === true)) {
            return 'Calculating Quote'
        }
        if (totalCost.value?.totalGross === 0) {
            return 'Create Order'
        }
        return `Order Searches${ totalCost.value?.totalGross > 0 ? ` (${ gbpCurrencyFormatter.format(totalCost.value?.totalGross) } inc VAT)` : '' }`
    }

    const onConfirmClick = async () => {
        if (geoJsonDisconnected.value) {
            return store.dispatch(SEARCHES_SHOW_DRAW_BOUNDARY)
        }
        onSelectProducts()
    }

    const confirmButtonText = computed(() => {
        return geoJsonDisconnected.value ? i18n.global.t('searches.map.editBoundary') : i18n.global.t('searches.createOrder.confirmBoundary')
    })

    const confirmAddressText = i18n.global.t('searches.createOrder.confirmDetails')

    const getDraftOrderButtonText = () => {
        if (savingDraftOrder.value) {
            return i18n.global.t('searches.createOrder.savingDraft')
        } else if (draftOrderId.value) {
            return i18n.global.t('searches.createOrder.updateDraft')
        }
        return i18n.global.t('searches.createOrder.saveDraft')
    }

    const onCreateOrder = async () => {
        store.commit(SEARCHES_MUTATE_SUBMITTING_ORDER, true)
        try {
            let response = null
            if (draftOrderId.value) {
                response = await store.dispatch(SEARCHES_SUBMIT_DRAFT_ORDER)
            } else {
                response = await store.dispatch(SEARCHES_CREATE_ORDER)
            }
            if (response?.ok) {
                await router.push({
                    name: Route.MatterSearchesDetails,
                    params: {
                        matterId: props.matterId.toString(),
                        id: response.data,
                    },
                    query: {
                        newOrder: 'true',
                        tab: 1,
                    },
                })
            }
        } finally {
            store.commit(SEARCHES_MUTATE_SUBMITTING_ORDER, false)
        }
    }

    const onSaveDraft = async () => {
        if (draftOrderId.value) {
            store.dispatch(SEARCHES_UPDATE_DRAFT_ORDER)
        } else {
            store.dispatch(SEARCHES_CREATE_DRAFT_ORDER)
        }
    }

    const onSelectProducts = () => {
        emit('step-change', props.stepId + 1)
    }

    const onCancelClick = async () => {
        emit('step-change', SearchesSteps.None)
    }

    watch(() => error.value, (newValue) => {
        if (newValue?.size === 0) {
            showConfirmationDialog.value = false
        }
    }, {
        deep: true,
    })



    defineExpose({
        router,
        onSelectProducts,
    })
</script>


<style lang="scss">
    @import './matter-searches-step-footer';
</style>
