import {
    FindNearbySettings,
    FindNearbyType,
} from '@/components/find-nearby/find-nearby-settings'
import {
    IFindNearbyResults,
    IFindNearbyService,
    IFindNearbySubtitleText,
} from '@/components/find-nearby/implementations/_common/find-nearby-service.interface'
import Feature from 'ol/Feature'
import FindNearbyApi from '@/api/find-nearby.api'
import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
import { FindNearbyServiceBase } from '@/components/find-nearby/implementations/_common/find-nearby-service-base'
import i18n from '@/plugins/i18n'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import {
    INearbyMatterTitle,
} from '@/components/find-nearby/implementations/matter-titles/nearby-matter-title.interface'

export interface IMatterTitlesServiceOptions {
    getMapFeaturesFn: (any) => Promise<Array<Feature>>,
}

export class FindNearbyMatterTitlesService extends FindNearbyServiceBase implements IFindNearbyService {
    public label = 'Matter titles'
    private readonly resultsCache: Map<string, IHttpClientResponse>
    public results: IFindNearbyResults
    private readonly getMapFeaturesFn: (any) => Promise<Array<Feature>>

    public settings: FindNearbySettings = {
        type: FindNearbyType.MatterTitles,
        defaultRadiusMetres: FindNearbyDefaults.BUFFER_DEFAULT_RADIUS,
        radiusStepMetres: FindNearbyDefaults.BUFFER_DEFAULT_STEP,
        maximumRadiusMetres: FindNearbyDefaults.BUFFER_DEFAULT_MAX_RADIUS,
        minimumRadiusMetres: FindNearbyDefaults.BUFFER_DEFAULT_MIN_RADIUS,
        thumbLabelFormatter: (value) => value > 999 ? '1km' : `${ value }m`,
        featureKey: 'title_no',
    }

    public displayComponentName = 'MatterTitleResults'

    constructor(options: IMatterTitlesServiceOptions) {
        super()
        this.resultsCache = new Map<string, IHttpClientResponse>()
        this.getMapFeaturesFn = options.getMapFeaturesFn
    }

    public async handleRequest(props: { titleNumbers: Array<string>, matterIds: Array<number> }): Promise<IFindNearbyResults> {
        try {
            let response: IHttpClientResponse = this.resultsCache.get(props.titleNumbers.join(','))
            if (!response) {
                if (this.resultsCache.size > 10) {
                    this.resultsCache.delete(this.resultsCache.keys()[0])
                }

                response = await FindNearbyApi.getMatterTitles(props.titleNumbers, this.settings.maximumRadiusMetres, props.matterIds)
                this.resultsCache.set(props.titleNumbers.join(','), response)
            }

            if (response?.ok) {
                this.results = {
                    records: response.data?.items,
                    features: [],
                }
                const nearbyTitleNumbers = this.results?.records.map(t => t.titleNumber)
                this.results.features = await this.getMapFeaturesFn(nearbyTitleNumbers)
                return this.results
            }
        } catch (err: any) {
            throw new Error(err)
        }
    }

    public reset(): void {
        this.results = null
        this.resultsCache.clear()
    }

    public getFilteredResults(props: { distanceMetres: number }): IFindNearbyResults {
        const records = this.results?.records?.filter(r => r.distanceMetres <= props.distanceMetres) ?? []
        const featureIds = records.map(r => r.titleNumber)
        const features = this.results?.features?.filter(f => featureIds.includes(f.get(this.settings.featureKey))) ?? []
        return {
            records,
            features,
        }
    }

    public getSubtitleText(params: IFindNearbySubtitleText): string {
        if (!params.filteredResults) {
            return 'nowt to see here!'
        }

        const resultCount = params.filteredResults.length
        const distinctTitles = new Set(params.filteredResults.map(r => r.titleNumber)).size
        const distinctMatters = new Set(params.filteredResults.map(r => r.matterId)).size
        return i18n.global.t('titlePanel.findNearby.matterTitlesSubheading', {
            resultCount,
            distance: params.selectedDistanceInMetres,
            titleNumbers: params.selectedTitleNumbers.join(', '),
            distinctTitles,
            distinctMatters,
        }).toString()
    }

    public getResultKeyValue(result: INearbyMatterTitle): string {
        return result.titleNumber
    }

    public getTitleText(): string {
        return i18n.global.t('titlePanel.findNearby.matterTitlesHeading')
    }

    public getRecordsFromFeatureIds(featureIds: Array<string>): Array<any> {
        return this.results?.records?.filter(x => featureIds.includes(x.titleNumber)) ?? []
    }

    public getFeaturesForRecords(records: Array<any>, selectedDistance: number): Array<Feature> {
        const titleNumbers = records
            .filter(x => x.distanceMetres <= selectedDistance)
            .map(r => r.titleNumber)
        return this.results?.features?.filter(x => titleNumbers.includes(x.get(this.settings.featureKey))) ?? []
    }
}
