export const ORGANISATION_INDUSTRIES = [
    'Legal - In House',
    'Legal - Private Practice',
    'Lender',
    'Insurance',
    'Property Developer',
    'Surveyor',
    'Conveyancing',
    'Agency',
    'Asset Management',
    'Valuers',
    'Other',
]
