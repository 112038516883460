import {INotificationConfigUser,
    StructuredAddress} from '@/api/searches.api'
import {
    ItemState,
    OrderState,
} from '@/enums/searches.enum'

export const SEARCHES_GET_STRUCTURED_ADDRESS = 'SEARCHES_GET_STRUCTURED_ADDRESS'
export const SEARCHES_GET_ORDERS = 'SEARCHES_GET_ORDERS'
export const SEARCHES_GET_LOADING = 'SEARCHES_GET_LOADING'
export const SEARCHES_GET_PRODUCTS = 'SEARCHES_GET_PRODUCTS'
export const SEARCHES_GET_HAZARDS = 'SEARCHES_GET_HAZARDS'
export const SEARCHES_GET_ACTIVE_HAZARDS = 'SEARCHES_GET_ACTIVE_HAZARDS'
export const SEARCHES_GET_CATEGORIES = 'SEARCHES_GET_CATEGORIES'
export const SEARCHES_GET_PRODUCT_QUOTE = 'SEARCHES_GET_PRODUCT_QUOTE'
export const SEARCHES_GET_PROPERTY_TYPES = 'SEARCHES_GET_PROPERTY_TYPES'
export const SEARCHES_GET_PRODUCT_TOTALS = 'SEARCHES_GET_PRODUCT_TOTALS'
export const SEARCHES_GET_QUOTE_CALCULATING = 'SEARCHES_GET_QUOTE_CALCULATING'
export const SEARCHES_GET_FILTER = 'SEARCHES_GET_FILTER'
export const SEARCHES_GET_SUBMITTING_ORDER = 'SEARCHES_GET_SUBMITTING_ORDER'
export const SEARCHES_GET_ORDER_DETAILS = 'SEARCHES_GET_ORDER_DETAILS'
export const SEARCHES_GET_DRAFT_ORDER_DETAILS = 'SEARCHES_GET_DRAFT_ORDER_DETAILS'
export const SEARCHES_GET_SEARCH_PROVIDERS = 'SEARCHES_GET_SEARCH_PROVIDERS'
export const SEARCHES_GET_PROPERTY_TYPE = 'SEARCHES_GET_PROPERTY_TYPE'
export const SEARCHES_GET_ADDRESS = 'SEARCHES_GET_ADDRESS'
export const SEARCHES_GET_FILTERED_TITLE_NUMBERS = 'SEARCHES_GET_FILTERED_TITLE_NUMBERS'
export const SEARCHES_GET_SELECTED_TITLE_NUMBERS = 'SEARCHES_GET_SELECTED_TITLE_NUMBERS'
export const SEARCHES_GET_AREA_SQM = 'SEARCHES_GET_AREA_SQM'
export const SEARCHES_GET_GEOJSON = 'SEARCHES_GET_GEOJSON'
export const SEARCHES_GET_EPSG4326_GEOJSON = 'SEARCHES_GET_EPSG4326_GEOJSON'
export const SEARCHES_GET_GEO_LOCATION = 'SEARCHES_GET_GEO_LOCATION'
export const SEARCHES_GET_SKETCH_OPTIONS = 'SEARCHES_GET_SKETCH_OPTIONS'
export const SEARCHES_GET_SEARCHES_LAYER = 'SEARCHES_GET_SEARCHES_LAYER'
export const SEARCHES_GET_SEARCH_PROVIDER = 'SEARCHES_GET_SEARCH_PROVIDER'
export const SEARCHES_GET_MAP_KEY = 'SEARCHES_GET_MAP_KEY'
export const SEARCHES_GET_PRODUCT_PREFERENCES = 'SEARCHES_GET_PRODUCT_PREFERENCES'
export const SEARCHES_GET_TMG_CON29_CONFIG = 'SEARCHES_GET_TMG_CON29_CONFIG'
export const SEARCHES_GET_TMG_CON29_CONFIG_SHOW = 'SEARCHES_GET_TMG_CON29_CONFIG_SHOW'
export const SEARCHES_GET_CON29_DWC_CONFIG = 'SEARCHES_GET_CON29_DWC_CONFIG'
export const SEARCHES_GET_CON29_DWC_CONFIG_SHOW = 'SEARCHES_GET_CON29_DWC_CONFIG_SHOW'
export const SEARCHES_GET_TMG_LLC1_CONFIG = 'SEARCHES_GET_TMG_LLC1_CONFIG'
export const SEARCHES_GET_TMG_LLC1_CONFIG_SHOW = 'SEARCHES_GET_TMG_LLC1_CONFIG_SHOW'
export const SEARCHES_GET_HIGHWAYS_RA_CONFIG = 'SEARCHES_GET_HIGHWAYS_RA_CONFIG'
export const SEARCHES_GET_HIGHWAYS_RA_CONFIG_SHOW = 'SEARCHES_GET_HIGHWAYS_RA_CONFIG_SHOW'
export const SEARCHES_GET_ARG_SS_COMBO_CONFIG = 'SEARCHES_GET_ARG_SS_COMBO_CONFIG'
export const SEARCHES_GET_ARG_SS_COMBO_CONFIG_SHOW = 'SEARCHES_GET_ARG_SS_COMBO_CONFIG_SHOW'
export const SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING = 'SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING'
export const SEARCHES_GET_NOTIFICATION_USERS = 'SEARCHES_GET_NOTIFICATION_USERS'
export const SEARCHES_GET_SELECTED_NOTIFICATION_USERS = 'SEARCHES_GET_SELECTED_NOTIFICATION_USERS'
export const SEARCHES_GET_GEOJSON_HAS_MULTIPLE_GEOMETRIES = 'SEARCHES_GET_GEOJSON_HAS_MULTIPLE_GEOMETRIES'
export const SEARCHES_GET_ERROR = 'SEARCHES_GET_ERROR'
export const SEARCHES_GET_HAS_ERRORS = 'SEARCHES_GET_HAS_ERRORS'
export const SEARCHES_GET_INIT_CONTEXT_REQUEST = 'SEARCHES_GET_INIT_CONTEXT_REQUEST'
export const SEARCHES_GET_SEARCHES_FETCH_CATEGORIES_REQUEST = 'SEARCHES_GET_SEARCHES_FETCH_CATEGORIES_REQUEST'

export const SEARCHES_MUTATE_STRUCTURED_ADDRESS = 'SEARCHES_MUTATE_STRUCTURED_ADDRESS'
export const SEARCHES_MUTATE_STRUCTURED_ADDRESS_VALID = 'SEARCHES_MUTATE_STRUCTURED_ADDRESS_VALID'
export const SEARCHES_MUTATE_CONTEXTID = 'SEARCHES_MUTATE_CONTEXTID'
export const SEARCHES_MUTATE_ORDERS = 'SEARCHES_MUTATE_ORDERS'
export const SEARCHES_MUTATE_CATEGORIES = 'SEARCHES_MUTATE_CATEGORIES'
export const SEARCHES_MUTATE_PRODUCTS = 'SEARCHES_MUTATE_PRODUCTS'
export const SEARCHES_MUTATE_LOADING = 'SEARCHES_MUTATE_LOADING'
export const SEARCHES_MUTATE_ADD_ERROR = 'SEARCHES_MUTATE_ADD_ERROR'
export const SEARCHES_MUTATE_ADD_ERROR_FROM_EXCEPTION = 'SEARCHES_MUTATE_ADD_ERROR_FROM_EXCEPTION'
export const SEARCHES_MUTATE_CLEAR_ERRORS = 'SEARCHES_MUTATE_CLEAR_ERRORS'
export const SEARCHES_MUTATE_PRODUCT_QUOTE = 'SEARCHES_MUTATE_PRODUCT_QUOTE'
export const SEARCHES_MUTATE_BASKET = 'SEARCHES_MUTATE_BASKET'
export const SEARCHES_MUTATE_CLEAR_PRODUCT_QUOTE = 'SEARCHES_MUTATE_CLEAR_PRODUCT_QUOTE'
export const SEARCHES_MUTATE_QUOTE_CALCULATING = 'SEARCHES_MUTATE_QUOTE_CALCULATING'
export const SEARCHES_MUTATE_FILTER = 'SEARCHES_MUTATE_FILTER'
export const SEARCHES_MUTATE_SUBMITTING_ORDER = 'SEARCHES_MUTATE_SUBMITTING_ORDER'
export const SEARCHES_MUTATE_RESET = 'SEARCHES_MUTATE_RESET'
export const SEARCHES_MUTATE_CLEAR_QUOTE = 'SEARCHES_MUTATE_CLEAR_QUOTE'
export const SEARCHES_MUTATE_ORDER_DETAILS = 'SEARCHES_MUTATE_ORDER_DETAILS'
export const SEARCHES_MUTATE_NOTIFICATION_USERS = 'SEARCHES_MUTATE_NOTIFICATION_USERS'
export const SEARCHES_MUTATE_DRAFT_ORDER_DETAILS = 'SEARCHES_MUTATE_DRAFT_ORDER_DETAILS'
export const SEARCHES_MUTATE_SEARCH_PROVIDERS = 'SEARCHES_MUTATE_SEARCH_PROVIDERS'
export const SEARCHES_MUTATE_FILTERED_TITLE_NUMBER = 'SEARCHES_MUTATE_FILTERED_TITLE_NUMBER'
export const SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS = 'SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS'
export const SEARCHES_MUTATE_AREA_SQM = 'SEARCHES_MUTATE_AREA_SQM'
export const SEARCHES_MUTATE_GEOJSON = 'SEARCHES_MUTATE_GEOJSON'
export const SEARCHES_MUTATE_MAP = 'SEARCHES_MUTATE_MAP'
export const SEARCHES_MUTATE_HAZARDS = 'SEARCHES_MUTATE_HAZARDS'
export const SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS = 'SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS'
export const SEARCHES_MUTATE_BOUNDARY_LAYER = 'SEARCHES_MUTATE_BOUNDARY_LAYER'
export const SEARCHES_MUTATE_SEARCHES_LAYER = 'SEARCHES_MUTATE_SEARCHES_LAYER'
export const SEARCHES_MUTATE_MAP_KEY = 'SEARCHES_MUTATE_MAP_KEY'
export const SEARCHES_MUTATE_CLICK_PRODUCT_LINK = 'SEARCHES_MUTATE_CLICK_PRODUCT_LINK'

export const SEARCHES_MUTATE_NEW_SKETCH = 'SEARCHES_MUTATE_NEW_SKETCH'
export const SEARCHES_MUTATE_DRAW_LAYER_WITH_SKETCH = 'SEARCHES_MUTATE_DRAW_LAYER_WITH_SKETCH'
export const SEARCHES_MUTATE_HIGHLIGHT_SKETCH = 'SEARCHES_MUTATE_HIGHLIGHT_SKETCH'
export const SEARCHES_MUTATE_SNAPPING_MODE = 'SEARCHES_MUTATE_SNAPPING_MODE'
export const SEARCHES_MUTATE_REMOVE_SKETCH = 'SEARCHES_MUTATE_REMOVE_SKETCH'
export const SEARCHES_MUTATE_CURRENT_MODE = 'SEARCHES_MUTATE_CURRENT_MODE'
export const SEARCHES_MUTATE_ZOOM_TO_SKETCH = 'SEARCHES_MUTATE_ZOOM_TO_SKETCH'
export const SEARCHES_MUTATE_IS_DRAWING = 'SEARCHES_MUTATE_IS_DRAWING'
export const SEARCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS = 'SEARCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS'
export const SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX = 'SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX'
export const SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD = 'SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD'
export const SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL = 'SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL'
export const SEARCHES_MUTATE_MAKING_UNDO_REDO_CHANGES = 'SEARCHES_MUTATE_MAKING_UNDO_REDO_CHANGES'
export const SEARCHES_MUTATE_SEARCH_PROVIDER = 'SEARCHES_MUTATE_SEARCH_PROVIDER'
export const SEARCHES_MUTATE_PRODUCT_PREFERENCES = 'SEARCHES_MUTATE_PRODUCT_PREFERENCES'
export const SEARCHES_MUTATE_PRODUCT_CONFIG = 'SEARCHES_MUTATE_PRODUCT_CONFIG'
export const SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW = 'SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW'

export const SEARCHES_FETCH_ORDERS = 'SEARCHES_FETCH_ORDERS'
export const SEARCHES_FETCH_PRODUCTS = 'SEARCHES_FETCH_PRODUCTS'
export const SEARCHES_FETCH_CATEGORIES = 'SEARCHES_FETCH_CATEGORIES'
export const SEARCHES_FETCH_HAZARDS = 'SEARCHES_FETCH_HAZARDS'
export const SEARCHES_FETCH_PRODUCT_QUOTE = 'SEARCHES_FETCH_PRODUCT_QUOTE'
export const SEARCHES_CREATE_ORDER = 'SEARCHES_CREATE_ORDER'
export const SEARCHES_FETCH_ORDER_DETAILS = 'SEARCHES_FETCH_ORDER_DETAILS'
export const SEARCHES_FETCH_DRAFT_ORDER_DETAILS = 'SEARCHES_FETCH_DRAFT_ORDER_DETAILS'
export const SEARCHES_FETCH_SEARCH_PROVIDERS = 'SEARCHES_FETCH_SEARCH_PROVIDERS'
export const SEARCHES_FETCH_PRODUCT_PREFERENCES = 'SEARCHES_FETCH_PRODUCT_PREFERENCES'
export const SEARCHES_SET_FILTERED_TITLES = 'SEARCHES_SET_FILTERED_TITLES'

export const SEARCHES_MUTATE_ORGANISATION_SETTINGS = 'SEARCHES_MUTATE_ORGANISATION_SETTINGS'
export const SEARCHES_GET_ORGANISATION_SETTINGS = 'SEARCHES_GET_ORGANISATION_SETTINGS'

export const SEARCHES_DRAW_BOUNDARY_START = 'SEARCHES_DRAW_BOUNDARY_START'
export const SEARCHES_DRAW_BOUNDARY_END = 'SEARCHES_DRAW_BOUNDARY_END'

export const SEARCHES_UPDATE_SKETCH = 'SEARCHES_UPDATE_SKETCH'
export const SEARCHES_NEW_SKETCH = 'SEARCHES_NEW_SKETCH'
export const SEARCHES_SKETCH_INITIALISE = 'SEARCHES_SKETCH_INITIALISE'
export const SEARCHES_SELECT_BY_PIXEL = 'SEARCHES_SELECT_BY_PIXEL'
export const SEARCHES_ACTIVATE_SKETCH = 'SEARCHES_ACTIVATE_SKETCH'
export const SEARCHES_SET_SNAPPING_MODE = 'SEARCHES_SET_SNAPPING_MODE'
export const SEARCHES_SET_MODE = 'SEARCHES_SET_MODE'
export const SEARCHES_INIT_DRAW_INTERACTION = 'SEARCHES_INIT_DRAW_INTERACTION'
export const SEARCHES_INIT_MODIFY_INTERACTION = 'SEARCHES_INIT_MODIFY_INTERACTION'
export const SEARCHES_REMOVE_SKETCH = 'SEARCHES_REMOVE_SKETCH'
export const SEARCHES_RESET = 'SEARCHES_RESET'
export const SEARCHES_CLEAR_CURRENT_SKETCH = 'SEARCHES_CLEAR_CURRENT_SKETCH'
export const SEARCHES_UPDATE_AREA_SQM = 'SEARCHES_UPDATE_AREA_SQM'
export const SEARCHES_UPDATE_BOUNDARY = 'SEARCHES_UPDATE_BOUNDARY'
export const SEARCHES_UPDATE_TITLES_IN_BOUNDARY = 'SEARCHES_UPDATE_TITLES_IN_BOUNDARY'
export const SEARCHES_UNDO = 'SEARCHES_UNDO'
export const SEARCHES_SHOW_DRAW_BOUNDARY = 'SEARCHES_SHOW_DRAW_BOUNDARY'

// basket
export const SEARCHES_GET_BASKET = 'SEARCHES_GET_BASKET'
export const SEARCHES_GET_BASKET_CALCULATING = 'SEARCHES_GET_BASKET_CALCULATING'
export const SEARCHES_GET_BASKET_ITEMS = 'SEARCHES_GET_BASKET_ITEMS'
export const SEARCHES_GET_BASKET_COUNT = 'SEARCHES_GET_BASKET_COUNT'
export const SEARCHES_GET_BASKET_EMPTY = 'SEARCHES_GET_BASKET_EMPTY'
export const SEARCHES_GET_BASKET_PRODUCT_IDS = 'SEARCHES_GET_BASKET_PRODUCT_IDS'
export const SEARCHES_GET_SELECTED_BASKET_ITEM = 'SEARCHES_GET_SELECTED_BASKET_ITEM'
export const SEARCHES_GET_BASKET_DIRTY = 'SEARCHES_GET_BASKET_DIRTY'

export const SEARCHES_INIT_CONTEXT = 'SEARCHES_INIT_CONTEXT'
export const SEARCHES_BASKET_HAS_CATEGORY = 'SEARCHES_BASKET_HAS_CATEGORY'
export const SEARCHES_BASKET_HAS_PRODUCT = 'SEARCHES_BASKET_HAS_PRODUCT'

export const SEARCHES_MUTATE_BASKET_FILTER = 'SEARCHES_MUTATE_BASKET_FILTER'
export const SEARCHES_MUTATE_CLEAR_BASKET = 'SEARCHES_MUTATE_CLEAR_BASKET'
export const SEARCHES_MUTATE_REMOVE_BASKET_ITEM = 'SEARCHES_MUTATE_REMOVE_BASKET_ITEM'
export const SEARCHES_MUTATE_ADD_BASKET_ITEM = 'SEARCHES_MUTATE_ADD_BASKET_ITEM'
export const SEARCHES_MUTATE_BASKET_ITEMS_WITH_QUOTE = 'SEARCHES_MUTATE_BASKET_ITEMS_WITH_QUOTE'
export const SEARCHES_MUTATE_SELECTED_BASKET_ITEM = 'SEARCHES_MUTATE_SELECTED_BASKET_ITEM'
export const SEARCHES_MUTATE_QUOTE_TOTALS = 'SEARCHES_MUTATE_QUOTE_TOTALS'
export const SEARCHES_MUTATE_BASKET_DIRTY = 'SEARCHES_MUTATE_BASKET_DIRTY'

// recommended
export const SEARCHES_GET_RECOMMENDED_CATEGORIES = 'SEARCHES_GET_RECOMMENDED_CATEGORIES'

export const SEARCHES_MUTATE_RECOMMENDED_FILTER = 'SEARCHES_MUTATE_RECOMMENDED_FILTER'
export const SEARCHES_MUTATE_RECOMMENDED_SORT = 'SEARCHES_MUTATE_RECOMMENDED_SORT'
export const SEARCHES_MUTATE_REMOVE_RECOMMENDED_ITEM = 'SEARCHES_MUTATE_REMOVE_RECOMMENDED_ITEM'
export const SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM = 'SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM'
export const SEARCHES_MUTATE_RECOMMENDED_CLEAR = 'SEARCHES_MUTATE_RECOMMENDED_CLEAR'

// other
export const SEARCHES_GET_OTHER_CATEGORIES = 'SEARCHES_GET_OTHER_CATEGORIES'
export const SEARCHES_MUTATE_OTHER_FILTER = 'SEARCHES_MUTATE_OTHER_FILTER'
export const SEARCHES_GET_OTHER_PRODUCTS = 'SEARCHES_GET_OTHER_PRODUCTS'

// hazards
export const SEARCHES_GET_UNMAPPED_HAZARDS = 'SEARCHES_GET_UNMAPPED_HAZARDS'

// categories
export const SEARCHES_GET_AVAILABLE_CATEGORIES = 'SEARCHES_GET_AVAILABLE_CATEGORIES'
export const SEARCHES_MUTATE_SELECTED_CATEGORY = 'SEARCHES_MUTATE_SELECTED_CATEGORY'
export const SEARCHES_GET_SELECTED_CATEGORY = 'SEARCHES_GET_SELECTED_CATEGORY'

// products
export const SEARCHES_MUTATE_OTHER_PRODUCTS = 'SEARCHES_MUTATE_OTHER_PRODUCTS'
export const SEARCHES_GET_AVAILABLE_PRODUCTS = 'SEARCHES_GET_AVAILABLE_PRODUCTS'
export const SEARCHES_ADD_PRODUCT_TO_BASKET = 'SEARCHES_ADD_PRODUCT_TO_BASKET'

// product preferences
export const SEARCHES_PREPOPULATE_BASKET = 'SEARCHES_PREPOPULATE_BASKET'
export const SEARCHES_ADD_PRODUCTS_TO_BASKET = 'SEARCHES_ADD_PRODUCTS_TO_BASKET'
export const SEARCHES_ADD_TO_BASKET = 'SEARCHES_ADD_TO_BASKET'
export const SEARCHES_REMOVE_FROM_BASKET = 'SEARCHES_REMOVE_FROM_BASKET'

// quote
export const SEARCHES_QUOTE = 'SEARCHES_QUOTE'

// order
export const SEARCHES_GET_ORDER_REQUEST = 'SEARCHES_GET_ORDER_REQUEST'

// draft order
export const SEARCHES_MUTATE_SAVING_DRAFT_ORDER = 'SEARCHES_MUTATE_SAVING_DRAFT_ORDER'
export const SEARCHES_GET_SAVING_DRAFT_ORDER = 'SEARCHES_GET_SAVING_DRAFT_ORDER'
export const SEARCHES_CREATE_DRAFT_ORDER = 'SEARCHES_CREATE_DRAFT_ORDER'
export const SEARCHES_UPDATE_DRAFT_ORDER = 'SEARCHES_UPDATE_DRAFT_ORDER'
export const SEARCHES_SUBMIT_DRAFT_ORDER = 'SEARCHES_SUBMIT_DRAFT_ORDER'
export const SEARCHES_MUTATE_DRAFT_ORDER_ID = 'SEARCHES_MUTATE_DRAFT_ORDER_ID'
export const SEARCHES_GET_DRAFT_ORDER_ID = 'SEARCHES_GET_DRAFT_ORDER_ID'
export const SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER = 'SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER'

export const SEARCHES_MUTATE_PROJECT_NAME = 'SEARCHES_MUTATE_PROJECT_NAME'
export const SEARCHES_MUTATE_PROJECT_NAME_VALID = 'SEARCHES_MUTATE_PROJECT_NAME_VALID'
export const SEARCHES_GET_PROJECT_NAME = 'SEARCHES_GET_PROJECT_NAME'
export const SEARCHES_GET_PROJECT_NAME_VALID = 'SEARCHES_GET_PROJECT_NAME_VALID'

export enum ProductPreferenceBehaviour {
    Recommended,
    Required,
}

export interface SearchProvider {
    id: number,
    name?: string,
}

export interface ProductTotals {
    totalNet: number,
    totalVat: number,
    totalGross: number,
}

export interface ProductPrice {
    totalGrossFee: number,
    totalVat: number,
}

export interface Product {
    name: string,
    description: string,
    productId: string
    productSiteType?: number,
    productDisplaySequence?: number,
    productSupplierType?: string,
    maximumSiteSizeExceeded?: boolean,
    categoryIds?: string[],
    hazards?: string[],

    siteType?: string,
    supplierName?: string,
    price?: ProductPrice,
    expectedDate?: string,
    selected?: boolean,
}

export interface SearchCategoryWithProducts {
    categoryId: string
    selectedProductId?: number | string,
    selectedProductCategoryIds?: string[],
    selectedProductHazards?: string[],
    name: string,
    shortName: string,
    description: string,
    isActive?: boolean,
    products: Product[],
}

export interface SearchesCategory {
    selectedProductId?: number | string,
    products: Product[],
}

export interface SearchesHazard {
    id?: string
    name?: string
    shortName?: string
    description?: string
    isActive?: boolean
}

export interface SearchesProduct {
    productId: string
    name: string,
    description: string,
    productSupplierType?: string,
    categoryIds?: string[],
}

export interface SearchHazardDescription {
    code: string,
    name: string,
}

export interface SearchOrder {
    id: string,
    providerId: number,
    contextId: string,
    statusLastRead?: string,
    submittedDate?: string,
    titleNumber?: string,
    state: OrderState,
    address?: StructuredAddress,   //TODO: remove this when context is saved in db
    totalGrossPrice?: number,
    associatedTitleNumbers?: string[],
    geoJson?: string,
}

export interface SearchProductQuote {
    contactId: number,
    products: Product[],
    totals: ProductTotals,
}

export interface SearchOrderItem {
    id: string
    productName: string,
    expectedDate: string,
    returnedDate: string,
    supplierId: number,
    supplierName: string,
    status: ItemState,
    totalGrossPrice: number,
    totalVat: number,
    totalNetPrice: number,
    searchOrderId: string,
    providerStatus: string,
    providerDocumentUrl: string,
    providerDocumentUrls: string[],
    availableDocuments?: string[],
    productOptions: string,
    hazards?: string[],
}

export interface SearchOrderDetails {
    id: string,                 //the OW guid that identifies the order in our Db
    userId?: string,
    matterId: number,
    providerId: number,
    titleNumber: string,
    address: StructuredAddress,     //TODO: remove this when context is saved in db
    requestId?: string,             //the initial id we get from SF; not used with TM
    orderId?: string,               //the id we get from the provider
    detailsLastUpdated?: string,
    submittedDate?: string,
    state: OrderState,
    searchOrderItems: SearchOrderItem[],
    associatedTitleNumbers: string[],
    geoJson?: string,
}

export interface ISearchBoundary {
    selectedTitleNumbers: string[],
    filteredTitleNumbers: any[],
}

export interface SearchProductsFilter {
    searchText: string,
    searchProviderId: number,
    productPreferenceId: number,
}

export interface ISearchProductPreference {
    id: number,
    productPreferenceListId: number,
    providerProductId: string,
    productPreferenceBehaviour: ProductPreferenceBehaviour,
}

export interface ISearchProductPreferenceList {
    id: number,
    name: string,
    transactionType: string,    //not used at the moment
    productPreferences: ISearchProductPreference[],
    isDefault: boolean,
    isResidential: boolean,
}

export interface ISearchDraftDetails {
    orderId: string,
    providerName: string,
    usersName: string,
    address: StructuredAddress,    //TODO: remove this when context is saved in db
    geoJson: string,
    titleNumber: string,
    associatedTitleNumbers: string[],
    matterDetails: IMatterDetails,
    draftOrderItems: IDraftOrderItem[],
    totalGrossFee: number,
    totalNetFee: number,
    totalVat: number
    propertySectorType: string,
    templateId: number,
}

export interface IMatterDetails {
    matterName: string,
    clientCode?: string,
    matterCode?: string
}

export interface IDraftOrderItem {
    productName: string,
    supplierName: string,
    hazards: string[],
    expectedDate: Date,
    cost: number
}

export interface ISearchesError {
    title?: string,
    traceId?: string,
    detail?: string,
    request?: any,
    errors: string[],
}
