import gql from 'graphql-tag'

const GET_COMPANIES_HOUSE_DATA_QUERY = (withMatter = true) => {
    const query = `query companiesHouseData(
            ${ withMatter === true ? '$matterId: Long' : '' }
            $titleNumbers: [String!]!
        ) {
            titleRegisters(
                ${ withMatter === true ? 'matterId: $matterId' : '' }
                titleNumbers: $titleNumbers
            ) {
                titleNumber
                companiesHouseData {
                  companyName
                  companyRegistrationNumber
                  companyStatus
                  displayNatureOfBusiness
                  displayAddress
                }
                lastUpdatedCompaniesHouseDateTime
            }
        }`

    return gql`${ query }`
}

export { GET_COMPANIES_HOUSE_DATA_QUERY }
