<template>
    <div class="document-library-loading-skeleton">
        <div class="document-library-loading-skeleton__table">
            <div v-if="isBeta"
                 class="document-library-loading-skeleton__table--beta">
                <ow-loading-skeleton height="16px"
                                     width="43" />
                <ow-loading-skeleton height="16px"
                                     width="630" />
            </div>
            <div class="document-library-loading-skeleton__table--filters">
                <ow-loading-skeleton height="20px"
                                     width="300" />
                <ow-loading-skeleton height="40px"
                                     width="395" />
            </div>
            <div class="document-library-loading-skeleton__table--items">
                <ow-loading-skeleton height="530px" />
            </div>
            <div class="document-library-loading-skeleton__table--pagination">
                <ow-loading-skeleton width="400"
                                     height="55px" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'

    export default {
        name: 'LoadingSkeleton',

        components: {
            OwLoadingSkeleton,
        },

        props: {
            isBeta: {
                type: Boolean,
                required: false,
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './loading-skeleton';
</style>
