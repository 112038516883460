<template>
    <div class="planning-actions-advanced">
        <ow-multiselect v-model="selectedPlanningDecisions"
                        :items="planningDecisionItems"
                        data-track="TITLE-DETAILS-PANEL - Filter by planning decision"
                        label="Planning decision" />

        <ow-slider v-model="selectedDistance"
                   :max="maxDistance"
                   :min="0"
                   :step="5"
                   color="primary"
                   data-test-attribute="planning-actions-advanced-selected-distance"
                   data-track="TITLE-DETAILS-PANEL - Filter by distance"
                   label="Radius"
                   unit="m" />
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwMultiselect from '@/components/core/ow-multiselect.vue'
    import OwSlider from '@/components/core/ow-slider.vue'
    import { PlanningDecisionText } from '@/consts/planning'
    import {
        PLANNING_MUTATE_DISTANCE,
        PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS,
        PLANNING_UPDATE_FILTERED_RESULTS,
    } from '@/store/modules/planning/types'

    export default {
        components: {
            OwMultiselect,
            OwSlider,
        },

        computed: {
            ...mapState({
                selectedDistanceState: state => state.planning.inputs.selectedDistance,
                maxDistance: state => state.planning.inputs.maxDistance,
                selectedPlanningDecisionsState: state => state.planning.selectedPlanningDecisions,
            }),

            selectedDistance: {
                get() {
                    return this.selectedDistanceState
                },
                set(val) {
                    this.setSelectedDistance(val)
                },
            },

            selectedPlanningDecisions: {
                get() {
                    return this.selectedPlanningDecisionsState
                },
                set(val) {
                    this.setSelectedPlanningDecisions(val)
                    this.updateFilteredResults()
                },
            },

            planningDecisionItems() {
                return Object.entries(PlanningDecisionText).map(([key, value]) => {
                    return {
                        title: value,
                        value: key,
                    }
                })
            },
        },

        methods: {
            ...mapActions({
                updateFilteredResults: PLANNING_UPDATE_FILTERED_RESULTS,
            }),

            ...mapMutations({
                setSelectedDistance: PLANNING_MUTATE_DISTANCE,
                setSelectedPlanningDecisions: PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS,
            }),
        },
    }

</script>
<style lang="scss">
    @import 'planning-actions-advanced'
</style>
