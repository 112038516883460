<template>
    <div class="ow-search d-flex align-center"
         :class="{
             'ow-search--collapsed': collapsed,
         }">
        <v-menu v-model="showMenu"
                open-on-click
                :open-on-focus="false"
                :open-on-hover="false"
                :close-on-content-click="false"
                persistent
                no-click-animation
                content-class="ow-search__tooltip"
                :content-props="{
                    style: {
                        height: (modelValue && !totalSearchResultCount) ? height + 50 + 'px' : height + 'px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                }"
                contained
                :location="location">
            <template #activator="{ props: activatorProps }">
                <ow-button v-bind="activatorProps"
                           ref="buttonRef"
                           data-test="ow-search-button"
                           class="ow-search__button"
                           :is-secondary="collapsed"
                           :is-primary="!collapsed"
                           full-height
                           :style="{
                               minHeight: height + 'px',
                           }"
                           @click="collapsed = !collapsed">
                    <v-icon>$search</v-icon>
                </ow-button>
            </template>
            <div class="d-flex flex-column"
                 :style="{
                     width: searchWidth,
                 }">
                <div class="d-flex align-center justify-center">
                    <ow-textfield ref="inputRef"
                                  :data-test-id="dataTestId"
                                  :data-trac-id="dataTrackId"
                                  :model-value="modelValue"
                                  class="ow-search__input"
                                  :class="{
                                      'mr-2': totalSearchResultCount > 0,
                                  }"
                                  :max-length="1000"
                                  borderless
                                  clearable
                                  hide-details
                                  :placeholder="`Search${searchContext ? ` ${searchContext}` : ''}...`"
                                  @update:model-value="$emit('update:modelValue', $event)"
                                  @click:clear="onClear" />
                    <div v-if="modelValue && totalSearchResultCount > 0"
                         class="ow-search__nav d-flex flex-row align-center">
                        <v-divider vertical
                                   class="mr-1" />
                        <div class="d-flex gc-1 align-center">
                            <ow-button :disabled="totalSearchResultCount === 1"
                                       :data-test="prevButtonDataTestId"
                                       is-secondary
                                       is-borderless
                                       small
                                       icon
                                       @click="$emit('previous-button-click')">
                                <v-icon>$chevron-left</v-icon>
                            </ow-button>
                            <div class="accents-small text-no-wrap">
                                {{ searchResultIndex }} of {{ totalSearchResultCount }}
                            </div>
                            <ow-button :disabled="totalSearchResultCount === 1"
                                       :data-test="nextButtonDataTestId"
                                       is-secondary
                                       is-borderless
                                       small
                                       icon
                                       @click="$emit('next-button-click')">
                                <v-icon>$chevron-right</v-icon>
                            </ow-button>
                        </div>
                    </div>
                </div>
                <div v-if="modelValue && totalSearchResultCount === 0"
                     class="d-flex flex-column ow-search__no-results">
                    <v-divider class="mt-3"
                               horizontal />
                    <div class="d-flex flex-column align-center mt-2">
                        <h3 class="accents-highlight ml-2">
                            {{ $t('error.noResultsFound') }}
                        </h3>
                        <div class="accents-italic">
                            {{ $t('action.tryAnotherTerm') }}
                        </div>
                    </div>
                </div>
            </div>
        </v-menu>
    </div>
</template>

<script setup lang="ts">
    import { nextTick,
             ref,
             watch } from 'vue'
    import { onBeforeUnmount,
             onMounted } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'

    const collapsed = ref(true)
    const showMenu = ref(false)
    const inputRef = ref<HTMLInputElement | null>(null)

    const emit = defineEmits(['previous-button-click', 'next-button-click', 'update:modelValue', 'clear'])
    const props = withDefaults(defineProps<{
        modelValue?: string
        width?: number
        height?: number
        autoWidth?: boolean
        parentClass?: string
        searchContext?: string
        location?: 'left' | 'right'
        totalSearchResultCount: number
        searchResultIndex: number
        dataTestId?: string
        dataTrackId?: string
        prevButtonDataTestId?: string
        nextButtonDataTestId?: string
    }>(), {
        location: 'left',
        autoWidth: false,
        width: 150,
        height: 57,
    })

    const searchWidth = ref(null)
    const buttonRef = ref(null)

    const resizeHandler = () => {
        if (!props.autoWidth) {
            searchWidth.value = props.width + 'px'
            return
        }
        const parentControl = document.querySelector(`.${ props.parentClass }`)
        const controlRef = parentControl as HTMLElement

        if (props.location === 'left') {
            const offsetWidth = (controlRef?.offsetWidth - 18)
            let availableSpace = offsetWidth / 3
            if (availableSpace < 150) {
                availableSpace = offsetWidth
            }
            if (availableSpace > 400) {
                availableSpace = 400
            }
            searchWidth.value = `${ availableSpace }px`
        } else {
            const buttonOffsetLeft = buttonRef.value?.$el?.offsetLeft
            const offsetWidth = (controlRef?.offsetWidth - buttonOffsetLeft - 18)
            searchWidth.value = `${ offsetWidth }px`
        }
    }

    /**
     * Set the visibility of the action button labels on mount and when the window is resized
     */
    onMounted(async () => {
        window.addEventListener('resize', resizeHandler)
        await nextTick()
        resizeHandler()
    })

    onBeforeUnmount(() => {
        window.removeEventListener('resize', resizeHandler)
        showMenu.value = false
    })

    const onClear = () => {
        emit('clear', {
            target: {
                value: '',
            },
        })
    }

    watch(() => showMenu.value, (val) => {
        if (val) {
            setTimeout(() => {
                inputRef.value?.focus()
            }, 100)
        }
    })

    defineExpose({
        showMenu,
        collapsed,
    })

    watch(() => props.modelValue, (val) => {
        if (!showMenu.value) {
            buttonRef.value?.$el?.click()
        }
    })

</script>

<style lang="scss">
    @import './ow-search';
</style>
