<template>
    <section class="ownership-diff-table"
             data-test="asset-monitoring-ownership-diff-table">
        <table class="base-sub-type-item__table">
            <thead>
                <tr>
                    <th class="label-caps-small base-sub-type-item__table--heading ownership-diff-table__row--row-type">
                        {{ t('label.type') }}
                    </th>
                    <th v-if="renderDetailsColumn"
                        class="label-caps-small base-sub-type-item__table--heading ownership-diff-table__row--row-type">
                        {{ t('assetMonitoring.dataGrid.label.details') }}
                    </th>
                    <th class="label-caps-small base-sub-type-item__table--heading ownership-diff-table__row--row-type">
                        {{ t('assetMonitoring.dataGrid.label.label') }}
                    </th>
                    <th class="label-caps-small base-sub-type-item__table--heading ownership-diff-table__row--details">
                        {{ t('assetMonitoring.dataGrid.label.details') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="itm in allItems"
                    class="ownership-diff-table__row"
                    data-test="ownership-change-row">
                    <td class="caption-regular ownership-diff-table__details--row-type"
                        data-test="ownership-change-row-type">
                        {{ itm.type }}
                    </td>
                    <td v-if="renderDetailsColumn"
                        class="ownership-diff-table__details--label"
                        data-test="ownership-change-row-detail">
                        <p> {{ itm.detail }} </p>
                    </td>
                    <td>
                        <p class="ownership-diff-table__label">
                            {{ t('assetMonitoring.dataGrid.ownership.name') }}
                        </p>
                        <p v-if="itm?.displayAddress"
                           class="ownership-diff-table__label"
                           data-test="ownership-change-row-address-label">
                            {{ t('assetMonitoring.dataGrid.ownership.address') }}
                        </p>
                        <p v-if="itm?.companyNumber"
                           class="ownership-diff-table__label"
                           data-test="ownership-change-row-company-number-label">
                            {{ t('assetMonitoring.dataGrid.ownership.number') }}
                        </p>
                        <p v-if="itm?.companyStatus"
                           class="ownership-diff-table__label"
                           data-test="ownership-change-row-status-label">
                            {{ t('assetMonitoring.dataGrid.ownership.status') }}
                        </p>
                        <p v-if="itm?.displayNatureOfBusiness"
                           class="ownership-diff-table__label"
                           data-test="ownership-change-row-nob-label">
                            {{ t('assetMonitoring.dataGrid.ownership.natureOfBusiness') }}
                        </p>
                    </td>
                    <td :title="`${ itm.companyName } ${ itm.displayAddress }`"
                        class="caption-regular ownership-diff-table__details--row"
                        data-test="ownership-change-row-details">
                        <div>
                            <div class="ownership-diff-table__details--updated">
                                <div>
                                    <div class="caption-regular"
                                         data-test="ownership-change-row-owner">
                                        {{ itm.companyName }}
                                    </div>
                                    <div v-if="itm?.displayAddress"
                                         class="caption-regular"
                                         data-test="ownership-change-row-address">
                                        {{ itm.displayAddress }}
                                    </div>
                                    <div v-if="itm?.companyNumber"
                                         class="caption-regular"
                                         data-test="ownership-change-row-number">
                                        {{ itm.companyNumber }}
                                    </div>
                                    <div v-if="itm?.companyStatus"
                                         class="caption-regular"
                                         data-test="ownership-change-row-status">
                                        {{ itm.companyStatus }}
                                    </div>
                                    <div v-if="itm?.displayNatureOfBusiness"
                                         class="caption-regular"
                                         data-test="ownership-change-row-nob">
                                        {{ itm.displayNatureOfBusiness }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </section>
</template>

<script setup lang="ts">
    import {
        computed,
        PropType,
    } from "vue"
    import { useI18n } from "vue-i18n"

    import {
        Difference,
    } from '@/components/asset-monitoring/difference-generic'
    import {
        IOwnershipDiffTableData,
        IOwnershipDiffTableRow,
    } from "@/components/asset-monitoring/grid/sub-type-items/ownership-diff-table/ownership-diff-table-models"

    const { t } = useI18n()

    const props = defineProps({
        items: {
            type: Object as PropType<Difference<IOwnershipDiffTableData>>,
            required: true,
        },
    })
    const allItems = computed<Array<IOwnershipDiffTableRow>>(() => {
        return [].concat(
            props.items.added.map((item) => ({
                companyName: item.companyName,
                displayAddress: item.displayAddress,
                companyNumber: item.companyNumber,
                companyStatus: item.companyStatus,
                displayNatureOfBusiness: item.displayNatureOfBusiness,
                type: t('label.added'),
            })),
            props.items.removed.map((item) => ({
                companyName: item.companyName,
                displayAddress: item.displayAddress,
                companyNumber: item.companyNumber,
                companyStatus: item.companyStatus,
                displayNatureOfBusiness: item.displayNatureOfBusiness,
                type: t('label.removed'),
            })),
            props.items.updated.flatMap((item) => [
                {
                    companyName: item.before?.companyName,
                    displayAddress: item.before?.displayAddress,
                    companyStatus: item.before?.companyStatus,
                    displayNatureOfBusiness: item.before?.displayNatureOfBusiness,
                    companyNumber: item.before?.companyNumber,
                    type: t('label.updated'),
                    detail: t('assetMonitoring.dataGrid.label.from'),
                },
                {
                    companyName: item.after?.companyName,
                    displayAddress: item.after?.displayAddress,
                    companyNumber: item.after?.companyNumber,
                    companyStatus: item.after?.companyStatus,
                    displayNatureOfBusiness: item.after?.displayNatureOfBusiness,
                    detail: t('assetMonitoring.dataGrid.label.to'),
                },
            ]),
        )
    })

    const renderDetailsColumn = computed<boolean>(() => {
        return allItems.value.some(x => x.detail)
    })
</script>

<style scoped lang="scss">
 @import './ownership-diff-table';
</style>
