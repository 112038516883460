import UserApi from '@/api/user.api'
import { LOGGING_OSMAPS_INITIALISE } from '@/store/mutation-types'
import { isProductionEnvironment } from '@/utils/environment.utils'

export const actions = {
    [LOGGING_OSMAPS_INITIALISE]({
        rootState,
        state,
    }) {
        if (state.initialised.osmaps === false) {
            state.initialised.osmaps = true
            // Temporary function to log OS Maps API usage as required by OS.
            if (window !== undefined) {
                if (isProductionEnvironment()) {
                    if (window?.owvars?.underTest !== true) {
                        const mapState = rootState.map

                        const updateServer = function() { // called periodically and potentially on session end
                            const request = []
                            for (const property in state.osMapsLogging.queuedUsage) {
                                request.push({
                                    zoomLevel: property,
                                    requestCount: state.osMapsLogging.queuedUsage[property],
                                })
                            }
                            if (request.length > 0) {
                                UserApi
                                    .logOsMapUsage(request)
                                    .then(() => {
                                        state.osMapsLogging.queuedUsage = {}
                                    })
                            }
                        }

                        // get os layers and respond to associated load events, updating the server every 30 seconds
                        mapState.baseLayers.forEach(function(bl) {
                            if (bl.id.indexOf('osMaps') !== -1) {
                                bl.layer.getSource().on('tileloadend', function(e) {
                                    // creates and updates a key value pair store of zoomlevel : request count
                                    const zoom = e.tile.getTileCoord()[0]
                                    if (state.osMapsLogging.queuedUsage[zoom] === undefined) {
                                        state.osMapsLogging.queuedUsage[zoom] = 1
                                    } else {
                                        state.osMapsLogging.queuedUsage[zoom]++
                                    }
                                    if (state.osMapsLogging.updateTimeout != null) {
                                        window.clearTimeout(state.osMapsLogging.updateTimeout)
                                    }

                                    // update every minute
                                    state.osMapsLogging.updateTimeout = window.setTimeout(function() {
                                        updateServer()
                                    }, 600000)
                                })
                            }
                        })

                        // update the server on possible session end
                        const onSessionEnd = () => {
                            updateServer()
                            window.removeEventListener('beforeunload', onSessionEnd)
                        }
                        window.addEventListener('beforeunload', onSessionEnd)
                        state.active.osmaps = true
                    }
                }
            }
        }
    },
}
