import i18n from '@/plugins/i18n'
import './columns.scss'
import {
    ChipViewConfig,
    Model,
    TemplateColumnConfig,
    WidgetColumnConfig,
} from '@bryntum/grid'
import { getHazardStyle } from '@/components/matter-searches/util/hazard-mapping'
import { dateFormat } from '@/components/core/ow-grid/columns'
import { useCalculatingColumn } from '@/components/core/ow-grid/columns/use-calculating-column'
import { gbpCurrencyFormatter } from '@/utils/number-utils'
import BasketItemModel from '@/components/matter-searches/models/BasketItemModel'
import { configurableCategoryTemplate } from './templates/configurable-category'
import { Colors } from '@/enums/colors.enum'
export { useColumns as useBasketColumns } from '@/components/matter-searches/columns/use-basket-columns'
export { useColumns as useCategoryColumns } from '@/components/matter-searches/columns/use-category-columns'
export { useColumns as useOrderDetailsColumns } from '@/components/matter-searches/columns/use-order-details-columns'
export { useColumns as useDraftOrderDetailsColumns } from '@/components/matter-searches/columns/use-draft-order-details-columns'
export { useColumns as useOtherColumns } from '@/components/matter-searches/columns/use-other-columns'
export { useColumns as useProductColumns } from '@/components/matter-searches/columns/use-product-columns'
export { useColumns as useNotificationColumns } from '@/components/matter-searches/columns/use-notification-columns'

export const HazardsColumn = ({
    field: 'categoryIds',
    text: i18n.global.t('searches.columns.hazard') as string,
    type: 'widget',
    readOnly: true,
    width: 220,
    align: 'right',
    autoHeight: true,
    widgets: [{
        type: 'chipview',
        closable: false,
        multiSelect: false,
        allowGroupSelect: false,
        displayField: 'id',
        cls: 'chip-items d-inline-flex my-2',
        getItemStyle(record) {
            return getHazardStyle(record.id as string)
        },
        getItemCls(_record) {
            return 'chip-item'
        },
    } as ChipViewConfig],
    renderer({ widgets, record }) {
        const widget = widgets[0] as unknown as ChipViewConfig
        const hazards = record.get('selectedProductHazards') ?? record.get('hazards')
        widget.items = hazards?.filter(hazard => hazard.toLowerCase() !== 'other').map((hazard) => {
            return {
                id: hazard,
                name: hazard,
            }
        })
    },
} as Partial<WidgetColumnConfig>)

export const ExpectedDateColumn = useCalculatingColumn({
    field: 'expectedDate',
    align: 'right',
    text: i18n.global.t('searches.columns.expectedDate') as string,
    width: 160,
    cls: 'hide-in-percy',
    calculating: (model: Model) => model.get('calculating'),
    format: ({ value, record }) => {
        const basketItem = record as BasketItemModel
        if (basketItem.errored) {
            return i18n.global.t('searches.createOrder.na')
        }
        if (value) {
            const expectedDate = new Date(value)
            return dateFormat(expectedDate)
        } else {
            return ''
        }
    },
})

export const CostColumn = useCalculatingColumn({
    width: 100,
    align: 'right',
    field: 'totalGrossFee',
    text: i18n.global.t('searches.columns.cost') as string,
    calculating: (model: Model) => model.get('calculating'),
    format: ({ value, record }) => {
        const basketItem = record as BasketItemModel
        if (basketItem.errored) {
            return i18n.global.t('searches.createOrder.na')
        }
        return value ? `<span class="body-highlight">${ gbpCurrencyFormatter.format(value) }${ !basketItem.valid ? /*html*/`<span title="${ i18n.global.t('searches.createOrder.invalidProducts') }" style="color: ${ Colors.Danger }" class="mdi mdi-alert"></span>` : '' }</span` : ''
    },
})

const standardCategoryTemplate = (record: BasketItemModel, configurableCategoryTemplate: string) => {
    const basketItem = record as BasketItemModel
    const productDetail = /*html*/`
                <div class="d-flex flex-row">
                    <span class="product-icon mdi mdi-information-outline mr-2"></span>
                    <div class="w-100">
                        <span class="body-highlight product-column" title="${ basketItem.description }">${ basketItem.description }</span>
                        <div class="caption-regular" title="${ basketItem.supplierDescription }">${ basketItem.supplierDescription }</div>
                    </div>
                </div>
            `

    return /*html*/`
            <div class='d-flex flex-column w-100 ${ configurableCategoryTemplate ? 'pt-2' : '' }'>
                <div class='flex-grow-1'>
                    ${ productDetail ? `<div>${ productDetail }</div>` : '' }
                </div>
                <div class="w-100">
                    ${ configurableCategoryTemplate ? `<div class="ml-5 rounded-lg w-75 product-column__config">${ configurableCategoryTemplate }</div>` : '' }
                </div>
            </div>
        `
}

export const ProductColumn = ({
    field: 'description',
    text: i18n.global.t('searches.columns.name') as string,
    cls: 'column-name',
    flex: 1,
    type: 'template',
    // tooltipRenderer(renderData) {
    //     const basketItem = renderData.record as BasketItemModel
    //     return basketItem.collapsed ? configurableCategoryTemplate(basketItem, true) : ''
    // },
    autoHeight: true,
    template: ({ record }: { record: Model }) => {
        const basketItem = record as BasketItemModel
        const configuration = configurableCategoryTemplate(basketItem)
        return standardCategoryTemplate(basketItem, configuration)
    },

}) as Partial<TemplateColumnConfig>
