export class BoundaryStyle {
    public alias: string
    public colour: string
    public labelColour: string
    public fillColour: string
    public fillOpacity: number
    public strokeWidth: number
    public hatch: boolean
    public show = true
    public dashed = false
    public fill: boolean

    constructor(
        alias = 'default',
        colour = '#FF0000',
        labelColour = '#FF0000',
        fillOpacity = 0.5,
        strokeWidth = 3,
        hatch = false,
        show = true,
        dashed = false,
    ) {
        this.alias = alias
        this.colour = colour
        this.labelColour = labelColour
        this.fillOpacity = fillOpacity
        this.strokeWidth = strokeWidth
        this.hatch = hatch
        this.show = show
        this.dashed = dashed
        this.fill = this.fillOpacity > 0
        if (this.fill && !this.fillColour) {
            this.fillColour = this.colour
        }
    }
}
