<template>
    <div class="copies-filed-tab">
        <div class="copies-filed-tab__content">
            <order-documents-card v-if="scottishSpike"
                                  :current-matter-id="currentMatterId"
                                  :is-ordering-allowed="isOrderingAllowed"
                                  :order-with-checkout="orderWithCheckout"
                                  :selected-title="selectedTitle"
                                  :selected-title-number="selectedTitleNumber"
                                  :official-copies="officialCopies"
                                  :is-loading="loading"
                                  :user-has-vts-charge="userHasVtsCharge"
                                  no-padding />
            <div v-else
                 class="copies-filed-tab__register-and-title-plan">
                <order-register-card :current-matter-id="currentMatterId"
                                     :is-ordering-allowed="isOrderingAllowed"
                                     :order-with-checkout="orderWithCheckout"
                                     :selected-title="selectedTitle"
                                     :selected-title-number="selectedTitleNumber"
                                     :user-has-vts-charge="userHasVtsCharge"
                                     :official-copies="true" />

                <order-title-plan-card :current-matter-id="currentMatterId"
                                       :is-ordering-allowed="isOrderingAllowed"
                                       :selected-title="selectedTitle"
                                       :selected-title-number="selectedTitleNumber"
                                       :official-copies="true" />
            </div>

            <ow-card :loading="isLoading || !selectedTitle?.record"
                     :loading-skeleton="{
                         rows: 5,
                         expandRows: false,
                     }"
                     class="copies-filed-tab__content">
                <copies-filed-document-table :documents="documents"
                                             :is-full-screen="isFullScreen"
                                             :is-ordering-allowed="isOrderingAllowed"
                                             :official-copies-availability="selectedTitle?.officialCopiesAvailability"
                                             :selected-title-number="selectedTitleNumber"
                                             :single-document-heap-metadata="singleDocumentHeapMetadata"
                                             data-test-attribute="copies-filed-table-matched-documents"
                                             @retry-official-copies="retryOfficialCopies"
                                             @order-documents="updateCopiesFiledOrderRequests" />

                <copies-filed-document-table v-if="unMatchedOcdaDocument.length > 0"
                                             :documents="unMatchedOcdaDocument"
                                             :is-full-screen="isFullScreen"
                                             :is-ordering-allowed="false"
                                             :is-previously-ordered-document-view="true"
                                             :selected-title-number="selectedTitleNumber"
                                             :single-document-heap-metadata="singleDocumentHeapMetadata"
                                             data-test-attribute="copies-filed-table-unmatched-documents"
                                             @order-documents="updateCopiesFiledOrderRequests" />

                <copies-filed-popup :order-details="orderDetails"
                                    :show="showOrderConfirmationPopup"
                                    @close-popup="showOrderConfirmationPopup = false"
                                    @order-docs="orderDocuments" />
            </ow-card>
        </div>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { mapActions,
             mapMutations,
             mapState } from 'vuex'

    import OwCard from '@/components/core/ow-card.vue'
    import CopiesFiledDocumentTable from '@/components/title-panel/tabs/copies-filed-tab/copies-filed-document-table.vue'
    import CopiesFiledPopup from '@/components/title-panel/tabs/copies-filed-tab/copies-filed-popup.vue'
    import OrderRegisterCard from '@/components/title-panel/tabs/order-register-card.vue'
    import OrderTitlePlanCard from '@/components/title-panel/tabs/order-title-plan-card.vue'
    import OrderDocumentsCard from '@/components/title-panel/tabs/summary-tab/order-documents-card/order-documents-card.vue'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import { MUTATE_DOCUMENT_ORDER_ERROR,
             ORDER_MULTIPLE_COPY_FILED_DOCUMENTS } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import { TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT,
             USER_SHOW_POPUP } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { hasDocumentBeenOrdered } from '@/utils/document-utils'

    export default {
        name: 'CopiesFiles',

        components: {
            CopiesFiledDocumentTable,
            CopiesFiledPopup,
            OrderDocumentsCard,
            OrderRegisterCard,
            OrderTitlePlanCard,
            OwCard,
        },

        mixins: [ flagsMixin ],

        props: {
            selectedTitleNumber: {
                type: null as unknown as PropType<string | null>,
                required: true,
            },

            selectedTitle: {
                type: Object,
                required: true,
            },

            titleCharge: {
                type: Number,
                required: false,
                default: 0,
            },

            isFullScreen: {
                type: Boolean,
                required: false,
            },

            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },

            orderWithCheckout: {
                type: Boolean,
                required: false,
            },

            isLoading: {
                type: Boolean,
                required: true,
            },

            officialCopies: {
                type: Array,
                required: false,
            },
        },

        data: () => ({
            showOrderConfirmationPopup: false,
            orderDetails: {},
            documentsToOrderRequests: [],
        }),

        computed: {

            ...mapState({
                currentMatterId: (state) => state.matter.currentMatter.id,
                titleSummaryCharge: (state) => state.user.titleSummaryCharge,
                orderErrors: (state) => state.documents.orderErrors,
            }),

            documents() {
                if (!this.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments) {
                    return []
                }
                return this.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments
                    .filter((doc) => doc.matchedAgainstOcda === true) ?? []
            },

            unMatchedOcdaDocument() {
                if (!this.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments) {
                    return []
                }
                return this.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments
                    .filter((doc) => hasDocumentBeenOrdered(doc) && doc.matchedAgainstOcda === false && doc.documentType !== '') ?? []
            },

            userHasVtsCharge() {
                return this.titleSummaryCharge !== null
            },

            loading() {
                return this.isLoading || this.selectedTitle?.officialCopiesAvailability?.loading
            },

            isRegisterOrdered() {
                return Boolean(this.selectedTitle?.record?.titleMetadata?.dateBGRegister)
            },

            singleDocumentHeapMetadata() {
                return {
                    type: 'DOC-ORDERING - Order copies filed document',
                    metadata: {
                        matterId: this.currentMatterId,
                        hasOrderedRegister: this.isRegisterOrdered,
                    },
                }
            },

            scottishSpike() {
                return this.$store.state?.config?.featureFlags?.scottishSpike
            },
        },

        methods: {

            ...mapActions({
                showPopup: USER_SHOW_POPUP,
                addTitleToMatter: MATTER_ADD_TITLE,
                fetchOfficialCopiesAvailability: TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapActions('documentOrdering', {
                orderDocumentsAction: ORDER_DOCUMENTS,
            }),

            ...mapMutations({
                mutateDocumentErrors: MUTATE_DOCUMENT_ORDER_ERROR,
            }),

            async retryOfficialCopies() {
                await this.fetchOfficialCopiesAvailability({
                    titleNumber: this.selectedTitleNumber,
                    matterId: this.currentMatterId,
                })
            },

            createOrderRequestObject(docsToOrder) {
                if (isNullOrEmpty(docsToOrder)) {
                    return {}
                }

                const countOfDocsToOrder = docsToOrder.length
                return {
                    countOfDocsToOrder,
                    totalCost: countOfDocsToOrder * this.titleCharge,
                }
            },

            /**
             * Order the documents.
             * @param data {Array}
             */
            async updateCopiesFiledOrderRequests(data) {
                this.orderDetails = this.createOrderRequestObject(data.documents)
                this.documentsToOrderRequests = data.documents.map(document =>
                    DocumentOrderRequest.createCopiesFiledRequest(this.selectedTitleNumber, this.currentMatterId, document),
                )
                this.showOrderConfirmationPopup = true
                let heapTypeSuffix = ''
                if (data.isSelectedDocuments) {
                    heapTypeSuffix = 'Order selected copies filed documents'
                }
                if (data.isAllDocuments) {
                    heapTypeSuffix = 'Order all copies filed documents'
                }
                if (data.isDocumentsInGroup) {
                    heapTypeSuffix = 'Order all copies filed documents in group'
                }
                this.logHeapEvent({
                    type: `TITLE-DETAILS-PANEL - ${ heapTypeSuffix }`,
                    metadata: {
                        hasOrderedRegister: this.isRegisterOrdered,
                        matterId: this.currentMatterId,
                    },
                })
            },

            /**
             * Actually orders the documents in the previously set request.
             * Shows any errors that may have arisen during the ordering process.
             * @see documentOrderRequest
             */
            async orderDocuments() {
                await this.addTitleToMatter(this.selectedTitleNumber)
                await this.orderDocumentsAction(this.documentsToOrderRequests)
                this.orderDetails = {}
                this.documentsToOrderRequests = []

                if (!isNullOrEmpty(this.orderErrors)) {
                    this.showPopup({
                        title: 'Order Documents',
                        icon: '$info',
                        contentHTML: this.orderErrors.join(''),
                    })
                    this.mutateDocumentErrors(null)
                }
            },
        },
    }
</script>

<style lang="scss"
       scoped>
@import './copies-filed-tab';
</style>
