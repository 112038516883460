<template>
    <ow-grid class="matter-searches-order-details-table"
             :grid-config="gridConfig"
             :header="header"
             theme="material" />
</template>

<script setup lang="ts">
    import {
        Model,
        Store,
    } from '@bryntum/grid'
    import {
        computed,
        ref,
        watch,
    } from 'vue'

    import OwGrid from '@/components/core/ow-grid/ow-grid.vue'
    import { useGridConfig } from '@/components/core/ow-grid/use-grid-config'
    import i18n from '@/plugins/i18n'

    import { GridHeader } from '../core/ow-grid'
    import { useOrderDetailsColumns } from './columns'

    const props = defineProps<{
        store: Store
        height?: string
    }>()

    const emit = defineEmits<{
        (e: 'download' | 'view', column: string, model: Model): void,
        (e: 'view-document', documentId: string, model: Model): void
    }>()

    const gridConfig = ref(useGridConfig({
        store: props.store,
        overrides: {
            rowHeight: 100,
            height: props.height,
            listeners: {
                cellClick: ({ event }) => {
                    const getDataId = (element: HTMLElement) => {
                        if (element.dataset.id) {
                            return element.dataset.id
                        }
                        if (element.parentElement) {
                            return getDataId(element.parentElement)
                        }
                        return null
                    }

                    const getDocumentId = (element: HTMLElement) => {
                        if (element.dataset.documentId) {
                            return element.dataset.documentId
                        }
                        if (element.parentElement) {
                            return getDataId(element.parentElement)
                        }
                        return null
                    }

                    const findClass = (element: HTMLElement, className: string) => {
                        if (element.classList.contains(className)) {
                            return element
                        }
                        if (element.parentElement) {
                            return findClass(element.parentElement, className)
                        }
                        return null
                    }

                    const target = event.target as HTMLElement
                    const links = ['document-link']
                    if (links.some(link => findClass(target, link))) {
                        const id = getDataId(target)
                        const documentId = getDocumentId(target)
                        const model = props.store.getById(id)
                        emit('view-document', documentId, model)
                    }
                },
            },
        },
        on: async (event: string, column: string, model: Model) => {
            switch (event) {
                case 'download':
                case 'view':
                    emit(event, column, model)
                    break
            }
        },
        columns: useOrderDetailsColumns,
    }))

    const header = computed<GridHeader>(() => ({
        title: i18n.global.t('searches.order.header.title', { count: props.store.count }),
        description: i18n.global.t('searches.order.header.description'),
        showCount: true,
        collapsible: false,
        borderless: false,
        sticky: true,
    }))

    watch(() => props.height, (height) => {
        gridConfig.value.height = height
    })
</script>

<style lang="scss">
    @import './matter-searches-order-details-table';
</style>
