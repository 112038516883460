import {
    ModelFieldConfig,
} from '@bryntum/grid'
import ProductItemModel from './ProductItemModel'
import CategoryItemModel from './CategoryItemModel'

class BasketItemModel extends ProductItemModel {
    static get fields(): Partial<ModelFieldConfig>[] {
        const fields = super.fields

        return [
            ...fields,
            { name: 'category', type: 'object' },
            { name: 'supplierId', type: 'number' },
            { name: 'supplierName', type: 'string' },
            { name: 'supplierDescription', type: 'string' },
            { name: 'expectedDate', type: 'string' },
            { name: 'totalGrossFee', type: 'number' },
            { name: 'totalNetFee', type: 'number' },
            { name: 'totalVat', type: 'number' },
            { name: 'calculating', type: 'boolean', defaultValue: false },
            { name: 'isActive', type: 'boolean' },
            { name: 'isConfigurable', type: 'boolean', defaultValue: false},
            { name: 'config', type: 'object', defaultValue: {}},
            { name: 'collapsed', type: 'boolean', defaultValue: true },
            { name: 'errored', type: 'boolean', defaultValue: false },
            { name: 'errors', type: 'array', defaultValue: [] },
            { name: 'valid', type: 'boolean', defaultValue: true },
        ]
    }

    get category(): CategoryItemModel {
        return this.get('category')
    }

    set category(value: CategoryItemModel) {
        this.set('category', value)
    }

    get supplierId(): number {
        return this.get('supplierId')
    }

    set supplierId(value: number) {
        this.set('supplierId', value)
    }

    get supplierName(): string {
        return this.get('supplierName')
    }

    set supplierName(value: string) {
        this.set('supplierName', value)
    }

    get supplierDescription(): string {
        return this.get('supplierDescription')
    }

    set supplierDescription(value: string) {
        this.set('supplierDescription', value)
    }

    get expectedDate(): string {
        return this.get('expectedDate')
    }

    set expectedDate(value: string) {
        this.set('expectedDate', value)
    }

    get totalGrossFee(): number {
        return this.get('totalGrossFee')
    }

    set totalGrossFee(value: number) {
        this.set('totalGrossFee', value)
    }

    get totalNetFee(): number {
        return this.get('totalNetFee')
    }

    set totalNetFee(value: number) {
        this.set('totalNetFee', value)
    }

    get totalVat(): number {
        return this.get('totalVat')
    }

    set totalVat(value: number) {
        this.set('totalVat', value)
    }

    get calculating(): boolean {
        return this.get('calculating')
    }

    set calculating(value: boolean) {
        this.set('calculating', value)
    }

    get errored(): boolean {
        return this.get('errored')
    }

    set errored(value: boolean) {
        this.set('errored', value)
    }

    get errorItems(): {
        errorCode: string
        errorMessage: string
        propertyName: string
    }[] {
        return this.get('errors')
    }

    set errorItems(value: {
        errorCode: string
        errorMessage: string
        propertyName: string
    }[]) {
        this.set('errors', value)
    }

    get mandatoryFields(): string[] {
        return this.get('mandatoryFields')
    }

    set mandatoryFields(value: string[]) {
        this.set('mandatoryFields', value)
    }

    get valid(): boolean {
        return this.get('valid')
    }

    get isActive(): boolean {
        return this.get('isActive')
    }

    set isActive(value: boolean) {
        this.set('isActive', value)
    }

    /**
     * determines if the basket item is configurable
     */
    get isConfigurable(): boolean {
        return this.get('isConfigurable')
    }

    /**
     * gets the basket item configuration
     */
    get config(): object {
        return this.get('config')
    }

    /**
     * sets the basket item configuration
     */
    set config(value: object) {
        this.set('config', value)
    }

    /**
     * determines if the basket item is collapsed
     */
    get collapsed(): boolean {
        return this.get('collapsed')
    }

    /**
     * sets the basket item as collapsed
     */
    set collapsed(value: boolean) {
        this.set('collapsed', value)
    }

    /**
     * returns the basket item config as a JSON string
     */
    productOptionsToJson(): string {
        return JSON.stringify(this.config)
    }

    /**
     * returns the basket item config as a JSON string
     */
    jsonToProductOptions(json: string): void {
        // not implemented
    }
}

export default BasketItemModel
