<template>
    <div class="register-card-flags d-flex flex-row gc-2 my-2">
        <ow-chip v-for="(item, index) in selectedEntryTypes"
                 :key="index"
                 :color="item.colour"
                 :label="item.label"
                 data-test="title-details-panel-register-flag-chip"
                 data-track="TITLE-DETAILS-PANEL - Remove register flag chip"
                 :data-track-attribute="item.label"
                 small
                 @click="onChipClick(item)" />
    </div>
</template>

<script lang="ts" setup>
    import OwChip from '@/components/core/ow-chip.vue'
    import { ISimplifiedRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'

    const emit = defineEmits<{
        (e: 'toggle-entry-type', value: ISimplifiedRegisterLinkedIndicator),
    }>()

    defineProps<{
        selectedEntryTypes: ISimplifiedRegisterLinkedIndicator[]
    }>()

    const onChipClick = (item: ISimplifiedRegisterLinkedIndicator): void => {
        item.selected = !item.selected
        emit('toggle-entry-type', item)
    }
</script>

<style scoped>

</style>
