import {
    reactive,
    ref,
} from 'vue'

import BusinessGatewayApi from '@/api/business-gateway.api'
import LandRegistryApi from '@/api/land-registry.api'
import TitleInformationApi from '@/api/title-information.api'
import {
    ILeaseHierarchyEdge,
    ILeaseHierarchyNode,
    LeaseHierarchy,
    LeaseHierarchyItem,
    LogMessage,
    OcdaReferredDocument,
    OcdaResponse,
    TitleRegister,
} from '@/components/lease-hierarchy/lease-hierarchy-types'
import { isNullOrEmpty } from '@/utils/array-utils'
import {
    getPropertyAddressesAndPostcodes,
    getProprietorNameArray,
    getQualityOfTenure,
    getTenureType,
} from '@/utils/derived-data'
import { TENURES } from '@/utils/title-enums'

export default function usLeaseHierarchy() {
// Maximum calls to make to the OCDA service
    const maxOcdaCalls = 4_000

    // When to stop generating the hierarchy based on depth. It's the OCDA structure makes it possible to have an
    // infinitely recursive hierarchy, however unlikely.
    const maxHierarchyDepth = 20

    // We shouldn't need to retrieve data for a title wherever it appears in a hierarchy more than once.
    const maxRetrievalCountPerTitle = 1

    // Loading state
    const isLoading = ref<boolean>(false)
    const isLoaded = ref<boolean>(false)

    // This it the hierarchy data that will be populated by this service.
    const hierarchyData = reactive<LeaseHierarchy>({
        items: [],
        forTitleNumbers: [],
        depth: 0,
        subjectTitleIsCautionAgainstFirstRegistration: false,
        subjectTitleIsParentFreehold: false,
        hasRemovedUnrelatedHierarchy: false,
    })
    const hierarchyNodes = ref<Array<ILeaseHierarchyNode>>([])
    const hierarchyEdges = ref<Array<ILeaseHierarchyEdge>>([])

    // Diagnostic log messages
    const logMessages = ref<Array<LogMessage>>([])
    let ocdaCallsCount = 0
    let hierarchyDepth = 0

    // A store of hierarchies retrieved for a title number. Given a title number can appear in more than one place in
    // a hierarchy, we shouldn't need to request the data more than once.
    const retrievedHierarchies: Map<string, LeaseHierarchyItem> = new Map<
        string,
        LeaseHierarchyItem
    >()

    // Counts against titles to prevent excessive retrieval of data for a title.
    const retrievalCounts: Map<string, number> = new Map<string, number>()

    // Whether the service has been stopped because of some limit or user intervention.
    const stopped = ref<boolean>(false)

    // A callback function that will be called when the hierarchy is updated.
    let onUpdate = () => {
    }

    const initialise = (titleNumbers: string[]) => {
        hierarchyData.items = []
        hierarchyData.forTitleNumbers = titleNumbers
        hierarchyData.depth = 0
        hierarchyData.subjectTitleIsCautionAgainstFirstRegistration = false
        hierarchyData.subjectTitleIsParentFreehold = false
        hierarchyData.hasRemovedUnrelatedHierarchy = false
        hierarchyNodes.value = []
        hierarchyEdges.value = []
        logMessages.value = []
        ocdaCallsCount = 0
        hierarchyDepth = 0
        retrievalCounts.clear()
        retrievedHierarchies.clear()
        stopped.value = false
        isLoaded.value = false
        isLoading.value = true
    }

    const loadLeaseHierarchy = async (titleNumbers: string[], callback: () => void = undefined) => {
        onUpdate = callback

        // Reset hierarchy data
        initialise(titleNumbers)

        // Load the hierarchy...
        addLogMessage(`Loading lease hierarchy for ${ titleNumbers }...`)
        addLogMessage(`Getting freeholds that intersect with ${ titleNumbers }`)
        const freeholdTitleNumbers = await LandRegistryApi.getFreeholdsIntersectingTitleNumbers(titleNumbers) as string[]

        addLogMessage(
            `Got ${ freeholdTitleNumbers.length } freeholds that intersect with ${ titleNumbers }`,
        )

        addLogMessage(
            `Creating hierarchies based on ${ freeholdTitleNumbers.length } root titles from ${ freeholdTitleNumbers }...`,
        )

        for (const hierarchyRootTitleNumber of freeholdTitleNumbers) {
            const nodeId = `root-${ hierarchyRootTitleNumber }`
            const hierarchy = createHierarchyItem(
                nodeId,
                null,
                hierarchyRootTitleNumber,
                'Parent Freehold',
                0,
            )
            hierarchyData.items.push(hierarchy)
            // Add to nodes
            const node: ILeaseHierarchyNode = {
                type: 'custom',
                id: nodeId,
                data: {
                    titleNumber: hierarchyRootTitleNumber,
                    isTitleOfInterest: hierarchyData.forTitleNumbers.includes(
                        hierarchyRootTitleNumber,
                    ),
                    isUnregisteredLease: false,
                },
                position: { x: 0, y: 0 },
            }
            hierarchyNodes.value.push(node)
            await extendHierarchy(hierarchy, hierarchyRootTitleNumber, 0, nodeId)
        }

        const rootHierarchiesCount = hierarchyData.items.length
        if (rootHierarchiesCount > 0) {
            addLogMessage('Removing unrelated hierarchies...')
            removeUnrelatedHierarchies()
            const removedCount = rootHierarchiesCount - hierarchyData.items.length
            addLogMessage(`Removed ${ removedCount } unrelated hierarchies`)
            if (removedCount > 0) {
                hierarchyData.hasRemovedUnrelatedHierarchy = true
            }
        }

        hierarchyData.depth = hierarchyDepth

        if (hierarchyData.items.length > 0) {
            // If there are hierarchies, populate with supporting data (owner, address etc)
            await populateHierarchySupportingData()

            // If there are edges, emphasise the branches that contain the title of interest
            if (hierarchyEdges.value.length > 0) {
                emphasiseBranches()
            }
        }

        if (hierarchyData.items.length === 0) {
            addLogMessage(`No lease hierarchy found for ${ titleNumbers }`)
        }

        isLoaded.value = true
        isLoading.value = false
        if (onUpdate) {
            onUpdate()
        }
    }

    const removeUnrelatedHierarchies = () => {
        const containsTitle = (
            hierarchy: LeaseHierarchyItem,
            titleNumbers: string[],
        ): boolean => {
            if (titleNumbers.includes(hierarchy.titleNumber)) {
                return true
            }
            return hierarchy.items.some((item) => containsTitle(item, titleNumbers))
        }

        // Filter the hierarchy using the updated containsTitle function
        hierarchyData.items = hierarchyData.items.filter((item) =>
            containsTitle(item, hierarchyData.forTitleNumbers),
        )

        if (hierarchyData.items.length === 1) {
            if (hierarchyData.items[0].items.length === 0) {
                hierarchyData.items = []
            }
        }

        // Collect all relevant node IDs from the filtered hierarchies
        const relevantNodeIds = new Set<string>()
        const collectNodeIds = (items: LeaseHierarchyItem[]) => {
            items.forEach((item) => {
                relevantNodeIds.add(item.id)
                collectNodeIds(item.items)
            })
        }
        collectNodeIds(hierarchyData.items)

        // Filter out nodes that are not part of the relevant hierarchies
        hierarchyNodes.value = hierarchyNodes.value.filter((node) =>
            relevantNodeIds.has(node.id),
        )

        // Filter out edges that do not connect the remaining nodes
        hierarchyEdges.value = hierarchyEdges.value.filter(
            (edge) => relevantNodeIds.has(edge.source) && relevantNodeIds.has(edge.target),
        )
    }

    const extendHierarchy = async (
        hierarchy: LeaseHierarchyItem,
        titleNumber: string,
        depth: number,
        parentId: string,
    ): Promise<void> => {
        if (
            stopped.value ||
            ocdaCallsCount >= maxOcdaCalls ||
            depth >= maxHierarchyDepth
        ) {
            addLogMessage(
                `Stopping recursion - OCDA Calls: ${ ocdaCallsCount }, Depth: ${ depth }`,
            )
            stopped.value = true
            return
        }
        if (ocdaCallsCount >= maxOcdaCalls) {
            addLogMessage(`Reached max OCDA calls (${ maxOcdaCalls }), stopping`)
            stopped.value = true
            return
        }
        if (depth >= maxHierarchyDepth) {
            addLogMessage(
                `Reached max hierarchy depth (${ maxHierarchyDepth }), stopping`,
            )
            stopped.value = true
            return
        }
        addLogMessage(`Extending hierarchy with OCDA response from ${ titleNumber }...`)
        let ocdaResponse: any
        try {
            ocdaResponse = await BusinessGatewayApi.getOcdaByTitleNumber(titleNumber) as { data: OcdaResponse }
        } catch (error) {
            addLogMessage(`Error getting OCDA response for ${ titleNumber }`)
            if (error.response.data === 'Title not found') {
                ocdaResponse = {
                    data: {
                        title_number: 'Unknown title',
                        data: { referred_to_documents: [] },
                    },
                }
            }
        }
        ocdaCallsCount++
        const leaseItems = ocdaResponse.data?.data?.referred_to_documents.filter((x) =>
            x.entry_numbers.some((y) => y.includes('L')),
        )
        addLogMessage(
            `Got ${ leaseItems.length } lease items in OCDA response for ${ titleNumber }`,
        )

        if (leaseItems.length) {
            retrievedHierarchies.set(titleNumber, hierarchy)
        }

        const leaseTitleNumbers = [ ...new Set(leaseItems.map((doc) => doc.filed_under)) ] as string[]

        for (const leaseTitleNumber of leaseTitleNumbers) {
            if (
                leaseTitleNumber !== titleNumber &&
                !hierarchy.items.some((x) => leaseTitleNumber === x.titleNumber)
            ) {
                const uniqueNodeId = `n-${ hierarchyNodes.value.length }`

                // Add to hierarchy
                const newHierarchyItem = createHierarchyItem(
                    uniqueNodeId,
                    titleNumber,
                    leaseTitleNumber,
                    'Lease',
                    depth + 1,
                )
                hierarchy.items.push(newHierarchyItem)
                addNodeItem(
                    leaseItems.find(
                        (x) => x.filed_under === leaseTitleNumber,
                    ) as OcdaReferredDocument,
                    titleNumber,
                )

                // Add edge from parent to child, if parent exists
                if (parentId) {
                    addEdgeItem(uniqueNodeId, parentId, titleNumber, leaseTitleNumber)
                }

                const retrievalCount = retrievalCounts.get(leaseTitleNumber) || 0
                if (retrievalCount < maxRetrievalCountPerTitle) {
                    retrievalCounts.set(leaseTitleNumber, retrievalCount + 1)
                    await extendHierarchy(
                        newHierarchyItem,
                        leaseTitleNumber,
                        depth + 1,
                        uniqueNodeId,
                    )
                } else {
                    addLogMessage(
                        `Reached max retrieval count (${ maxRetrievalCountPerTitle }) for ${ leaseTitleNumber }, skipping`,
                    )
                    newHierarchyItem.skipped = true
                }
                hierarchyDepth = Math.max(hierarchyDepth, depth + 1)
            }
        }
    }

    /**
     * Adds a node to the hierarchy
     * @param ocdaDoc a document from the OCDA response
     * @param parentTitleNumber the parent title number
     */
    const addNodeItem = (ocdaDoc: OcdaReferredDocument, parentTitleNumber: string) => {
        const uniqueNodeId = `n-${ hierarchyNodes.value.length }`
        const node: ILeaseHierarchyNode = {
            type: 'custom',
            id: uniqueNodeId,
            data: {
                titleNumber: ocdaDoc.filed_under,
                isTitleOfInterest: hierarchyData.forTitleNumbers.includes(
                    ocdaDoc.filed_under,
                ),
                isUnregisteredLease: ocdaDoc.filed_under === parentTitleNumber,
            },
            position: { x: 0, y: 0 },
        }
        hierarchyNodes.value.push(node)
    }

    const addEdgeItem = (
        target: string,
        parentId: string,
        sourceTitleNumber: string,
        targetTitleNumber: string,
    ) => {
        const edge: ILeaseHierarchyEdge = {
            id: `e-${ hierarchyEdges.value.length }`,
            source: parentId,
            target,
            animated: false,
            sourceTitleNumber,
            targetTitleNumber,
        }
        hierarchyEdges.value.push(edge)
    }

    // Emphasise the hierarchy branches that contain the title of interest.
    const emphasiseBranches = () => {
        // Store the IDs of nodes of interest
        const nodesOfInterestIds = new Set<string>()

        // Find the nodes of interest for each title number
        hierarchyNodes.value.forEach((node) => {
            if (hierarchyData.forTitleNumbers.includes(node.data.titleNumber!)) {
                nodesOfInterestIds.add(node.id)
            }
        })

        // Set to store the IDs of nodes that are ancestors of the nodes of interest
        const ancestorNodes = new Set<string>()
        nodesOfInterestIds.forEach((nodeId) => ancestorNodes.add(nodeId))

        // Find and animate edges connected to nodes of interest
        hierarchyEdges.value.forEach((edge) => {
            if (nodesOfInterestIds.has(edge.target) || nodesOfInterestIds.has(edge.source)) {
                edge.animated = true
                ancestorNodes.add(edge.source)
                ancestorNodes.add(edge.target)
            }
        })

        // Animate the edges along the path to the root for each node of interest
        hierarchyEdges.value.forEach((edge) => {
            if (ancestorNodes.has(edge.target)) {
                edge.animated = true
                edge.style = { stroke: '#00a4dd', strokeWidth: '4px' }
                ancestorNodes.add(edge.source) // Add the source as it's an ancestor
            }
        })
    }

    // Populate the hierarchy with supporting data - currently owner names, tenures, and addresses.
    const updateHierarchyDataWithSupportingData = (
        items: LeaseHierarchyItem[],
        titleNumber: string,
        ownerNames: string[],
        addresses: string[],
        tenure: string,
    ): void => {
        if (isNullOrEmpty(items)) {
            return
        }

        items.forEach((item) => {
            if (
                tenure === TENURES.cautionAgainstFirstRegistration &&
                hierarchyData.forTitleNumbers.includes(item.titleNumber)
            ) {
                hierarchyData.subjectTitleIsCautionAgainstFirstRegistration = true
            }
            if (item.titleNumber === titleNumber) {
                item.ownerNames = ownerNames
                item.addresses = addresses
                item.tenure = tenure
            }
            if (item.items.length > 0) {
                updateHierarchyDataWithSupportingData(
                    item.items,
                    titleNumber,
                    ownerNames,
                    addresses,
                    tenure,
                )
            }
        })
    }

    const updateHierarchyNodesWithSupportingData = (
        items: ILeaseHierarchyNode[],
        titleNumber: string,
        ownerNames: string[],
        addresses: string[],
        tenure: string,
    ): void => {
        if (isNullOrEmpty(items)) {
            return
        }


        items.forEach((item) => {
            if (
                tenure === TENURES.cautionAgainstFirstRegistration &&
                hierarchyData.forTitleNumbers.includes(item.data.titleNumber!)
            ) {
                hierarchyData.subjectTitleIsCautionAgainstFirstRegistration = true
            }
            if (item.data.titleNumber === titleNumber) {
                item.data.ownerNames = ownerNames
                item.data.addresses = addresses
                item.data.tenure = tenure
            }
        })
    }

    // Populate the hierarchy with supporting data - currently owner names, tenures, and addresses from GraphQL
    const populateHierarchySupportingData = async (): Promise<void> => {
        addLogMessage('Populating hierarchy ownership data...')
        let ownerNamesCount = 0
        let addressesCount = 0
        // Get distinct list of titles.
        const titleNumbers = extractDistinctTitleNumbers(hierarchyData.items)
        const ownerResponse = (await TitleInformationApi.getOwnersTenureAddressesByTitleNumbers(titleNumbers)) as TitleRegister[]

        ownerResponse.forEach((titleData) => {
            const ownerNames = getProprietorNameArray(titleData) ?? []
            const addresses = (getPropertyAddressesAndPostcodes(titleData) ?? [])
                .map((x) => x.addressLines)
                .filter((x) => x) as string[]
            ownerNamesCount += ownerNames.length
            addressesCount += addresses.length
            const qualityOfTenure = getQualityOfTenure(titleData)
            const tenureType = getTenureType(titleData)
            const tenure =
                qualityOfTenure && tenureType ? `${ qualityOfTenure } ${ tenureType }` : 'Unknown'

            // Iterate (recursively) over the hierarchy and populate.
            updateHierarchyDataWithSupportingData(
                hierarchyData.items,
                titleData.titleNumber,
                ownerNames,
                addresses,
                tenure,
            )

            // Iterate over the nodes and populate.
            updateHierarchyNodesWithSupportingData(
                hierarchyNodes.value,
                titleData.titleNumber,
                ownerNames,
                addresses,
                tenure,
            )
        })
        addLogMessage(
            `Populated hierarchy ownership data with ${ ownerNamesCount } owner names and ${ addressesCount } addresses `,
        )
    }

    const extractDistinctTitleNumbers = (items: LeaseHierarchyItem[]): string[] => {
        const titleNumbers = new Set<string>()
        const extract = (items: LeaseHierarchyItem[]) => {
            items.forEach((item) => {
                titleNumbers.add(item.titleNumber)
                if (item.items && item.items.length > 0) {
                    extract(item.items)
                }
            })
        }
        extract(items)
        return Array.from(titleNumbers)
    }

    // Creates a new node on the hierarchy.
    const createHierarchyItem = (
        id: string,
        parentTitleNumber: string | null,
        titleNumber: string,
        source: string,
        depth: number,
    ): LeaseHierarchyItem => {
        // Check if we've already created a hierarchy for this title number
        const existing = retrievedHierarchies.get(titleNumber)
        if (existing) {
            // We've already visited this title number, return a shallow copy without children
            return { ...existing, items: [] }
        } else {
            // Create a new hierarchy item for this title number
            const result: LeaseHierarchyItem = {
                id,
                titleNumber,
                parentTitleNumber,
                addresses: [],
                items: [],
                ownerNames: [],
                source,
                depth,
                tenureCode: '',
                tenure: 'Unknown',
                notes: [],
                skipped: false,
            }
            // When the parent and child title numbers are the same, it indicates an unregistered lease.
            result.isUnregisteredLease = result.titleNumber === parentTitleNumber

            retrievedHierarchies.set(titleNumber, result)
            retrievalCounts.set(titleNumber, 0)
            return result
        }
    }

    // Adds a message to the diagnostic log.
    const addLogMessage = (line: string): void => {
        if (onUpdate) {
            onUpdate()
        }
        if (stopped.value) {
            return
        }
        logMessages.value.push({
            timestamp: new Date().toLocaleTimeString(),
            message: line,
        })
    }

    // Stops the hierarchy from being generated further.
    const stop = (): void => {
        addLogMessage('Stopping...')
        stopped.value = true
    }

    return {
        hierarchyData,
        hierarchyEdges,
        hierarchyNodes,

        isLoaded,
        isLoading,
        stopped,

        hierarchyDepth,
        logMessages,
        ocdaCallsCount,

        loadLeaseHierarchy,
        stop,
    }
}
