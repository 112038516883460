<template>
    <div class="fixed-header searches-document-viewer-header d-flex align-center">
        <a v-if="!fromSearchesOrder"
           class="searches-document-viewer-header__back"
           data-test="searches-document-viewer-header-back"
           @click="backToDocLibrary">
            <span class="mdi mdi-arrow-left mr-2" />
            <span class="caption-highlight">{{ $t('documents.library.returnDocumentLibrary') }}</span>
        </a>
        <a v-else
           class="searches-document-viewer-header__back"
           data-test="searches-document-viewer-header-back"
           @click="backToSearchesOrder">
            <span class="mdi mdi-arrow-left mr-2" />
            <span class="caption-highlight">{{ $t('documents.library.returnOrderDetails') }}</span>
        </a>

        <h6 class="searches-document-viewer-header__context"
            data-test="searches-document-viewer-header-filename">
            File: {{ fileName }}
        </h6>
    </div>
</template>

<script setup lang="ts">

    import { computed } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'

    import { Route } from '@/enums/route.enum'

    const route = useRoute()
    const router = useRouter()

    const matterId = computed(() => route.params.matterId)
    const orderId = computed(() => route.params.orderId)
    const itemId = computed(() => route.params.itemId)
    const fileName = computed(() => route.query.fileName)
    const fromSearchesOrder = computed<boolean>(() => route.query?.fromSearchesOrder?.toString() === 'true' || false)

    const backToDocLibrary = () => {
        router.push({
            name: Route.DocumentsLibrary,
            hash: '#searches',
            params: {
                id: matterId?.value,
                orderId: itemId.value,
            },
        })
    }

    const backToSearchesOrder = () => {
        router.push({
            name: Route.MatterSearchesDetails,
            params: {
                matterId: matterId?.value,
                id: orderId?.value,
            },
        })
    }

    defineExpose({
        fileName,
        router, // Exposed for unit testing
    })
</script>

<style scoped lang="scss">
    @import "searches-document-viewer-header";
</style>
