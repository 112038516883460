export enum PropertyType
{
    Commercial = 0,
    Residential = 1,
    Both = 2,
}

export enum Provider
{
    SearchFlow = 0,
    TmGroup = 1,
}

export enum OrderState
{
    Submitted = 0,
    Failed = 1,
    Completed = 2,
    Draft = 3,
}

export enum ProjectType {
    Other = 0,
    Purchase = 1,
    Sale = 2,
}

export enum ItemState {
    Pending = 0,
    UserActionRequired = 1,
    Completed = 2,
    Cancelled = 3,
    CancellationRequested = 4,
    Unknown = 5,
    PendingDownload = 6,
    DocumentsAvailable = 7
}

export enum SearchesSteps {
    None = -1,
    DefinePolygon = 0,
    DefineAddress = 1,
    SelectProducts = 2,
    SetupNotifications = 3,
    Draft = 4,
}

export const getStepName = (step: SearchesSteps) => {
    switch (step) {
        case SearchesSteps.DefinePolygon:
            return 'define-polygon'
        case SearchesSteps.DefineAddress:
            return 'define-address'
        case SearchesSteps.SelectProducts:
            return 'select-products'
        case SearchesSteps.Draft:
            return 'draft'
    }
}

export const getStepId = (step: string | string[]) => {
    switch (step) {
        case getStepName(SearchesSteps.DefinePolygon):
            return SearchesSteps.DefinePolygon
        case getStepName(SearchesSteps.DefineAddress):
            return SearchesSteps.DefineAddress
        case getStepName(SearchesSteps.SelectProducts):
            return SearchesSteps.SelectProducts
        case getStepName(SearchesSteps.SetupNotifications):
            return SearchesSteps.SetupNotifications
        case getStepName(SearchesSteps.Draft):
            return SearchesSteps.Draft
    }
}
