<template>
    <div class="order-title-plan-card">
        <header>
            <div>
                <div class="order-title-plan-card__availability">
                    <div v-t="'documents.titlePlan'"
                         class="headers-h3-card-heading" />
                    <ow-document-availability-label :hmlr-availability-code="availabilityCode"
                                                    :is-ordered="false" />
                </div>
                <div v-t="'reviewAssistant.document.editionDate'"
                     class="caption-regular" />
            </div>
            <ow-button :disabled="isLoading"
                       data-test="order-title-plan-button"
                       data-track="REVIEW-ASSISTANT - Order plan"
                       is-primary
                       @click="handleTitlePlanOrder">
                <v-progress-circular v-if="isLoading"
                                     :size="20"
                                     :width="3"
                                     color="primary"
                                     indeterminate />
                <span v-else-if="isBackdated">
                    {{ $t('reviewAssistant.document.orderBackdatedPlan', { cost: documentCharge }) }}
                </span>
                <span v-else>
                    {{ $t('reviewAssistant.document.orderPlan', { cost: documentCharge }) }}
                </span>
            </ow-button>
        </header>
        <footer>
            <span v-t="'reviewAssistant.document.orderedFrom'"
                  class="accents-small" />
            <ow-icon-hmlr :text="true"
                          alt="Logo of Her Majesty's Land Registry"
                          class="order-title-plan-card--hmlr-badge"
                          height="28"
                          width="100" />
        </footer>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapGetters,
    } from 'vuex'

    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_GET_DOCUMENT_CHARGE,
    } from '@/store/mutation-types'

    export default {
        name: 'OrderTitlePlanCard',

        components: {
            OwButton,
            OwDocumentAvailabilityLabel,
            OwIconHmlr,
        },

        props: {
            matterId: {
                type: Number,
                required: true,
            },

            titleNumber: {
                type: String,
                required: true,
            },

            availabilityCode: {
                type: String,
                validator: v => Object.values(HmlrDocumentAvailabilityCode).includes(v),
                required: false,
            },

            isBackdated: {
                type: Boolean,
                required: false,
            },
        },

        data() {
            return {
                isLoading: false,
            }
        },

        computed: {
            ...mapGetters({
                documentCharge: USER_GET_DOCUMENT_CHARGE,
            }),
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            async handleTitlePlanOrder() {
                this.isLoading = true

                const request = DocumentOrderRequest.createTitlePlanRequest(
                    this.titleNumber,
                    this.matterId,
                    false,
                    this.isBackdated,
                )

                await this.orderDocuments([ request ])

                await this.logHeapEvent({
                    type: 'Title Plan Ordered',
                    metadata: {
                        titleNumber: this.titleNumber,
                        matterId: this.matterId,
                    },
                })

                // await this.fetchTitlePlan(this.titleNumber)

                this.isLoading = false
            },
        },
    }
</script>

<style lang="scss" scoped>
@import './order-title-plan-card';
</style>
