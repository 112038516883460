<template>
    <!-- Style options -->
    <v-dialog v-model="showStylePicker"
              :scrim="false"
              content-class="matter-colour-picker"
              persistent>
        <v-card style="padding:18px;">
            <div v-if="titlesStyle != null"
                 class="d-flex flex-column">
                <div class="d-flex flex-row">
                    <div data-test="matter-title-style-title-text"
                         style="font-size: 15px; padding-bottom: 20px;">
                        <span v-if="$store.state.matter.styleTitles.length == 1">Style for {{ titlesStyle.titleNumber }}</span>
                        <span v-if="$store.state.matter.styleTitles.length > 1">Style for {{ $store.state.matter.styleTitles.length }} titles</span>
                        <span v-if="!atLeastOneTitleHasBoundary">(Boundary data unavailable)</span>
                    </div>
                    <v-spacer />
                    <v-btn data-test="matter-title-style-undo-button"
                           variant="text"
                           :disabled="!styleHasChanged"
                           size="large"
                           style="margin-top: -7px;"
                           @click="resetStyle()">
                        Undo changes
                    </v-btn>
                    <v-btn data-test="matter-title-style-done-button"
                           color="primary"
                           size="large"
                           style="margin-top: -10px;"
                           @click="showStylePicker = false">
                        Done
                    </v-btn>
                </div>
                <div v-if="atLeastOneTitleHasBoundary">
                    <colour-picker v-model="styleColour"
                                   data-test="matter-title-style-colour"
                                   :style="colourPickerStyle"
                                   :palette="presetColours" />
                    <div class="matter-title-style__options-top-row">
                        <v-checkbox v-model="titlesStyle.dashed"
                                    data-test="matter-title-style-dashed-line"
                                    label="Dashed line"
                                    color="primary"
                                    hide-details />
                        <v-checkbox v-model="titlesStyle.fill"
                                    data-test="matter-title-style-fill-boundary"
                                    label="Fill boundary"
                                    color="primary"
                                    hide-details />
                        <v-checkbox v-if="titlesStyle.fill"
                                    v-model="titlesStyle.hatch"
                                    data-test="matter-title-style-hatch-fill"
                                    label="Hatch fill"
                                    color="primary"
                                    hide-details />
                        <v-checkbox v-model="titlesStyle.showTitleNumber"
                                    data-test="matter-title-style-show-title-number"
                                    label="Title number"
                                    color="primary"
                                    hide-details />
                    </div>
                    <div class="matter-title-style__options-middle-row">
                        <v-slider v-model="titlesStyle.strokeWidth"
                                  :min="1"
                                  :max="8"
                                  :step="1"
                                  label="Boundary width"
                                  class="matter-title-style-slider"
                                  color="primary"
                                  hide-details />
                        <v-text-field v-model="titlesStyle.strokeWidth"
                                      :min="1"
                                      :max="8"
                                      data-test="matter-title-style-boundary-width"
                                      type="number"
                                      style="max-width:70px; padding:0 0 0 10px"
                                      hide-details />
                    </div>
                    <div v-if="titlesStyle.fill"
                         class="d-flex flex-row">
                        <v-slider v-model="styleTransparency"
                                  :min="0"
                                  :max="100"
                                  :step="1"
                                  label="Fill transparency"
                                  style="padding: 0;"
                                  color="primary"
                                  class="matter-title-style-slider"
                                  hide-details />
                        <v-text-field v-model="styleTransparency"
                                      :min="0"
                                      :max="100"
                                      data-test="matter-title-style-transparency"
                                      type="number"
                                      style="max-width:70px; padding:0 0 0 10px"
                                      suffix="%"
                                      hide-details />
                    </div>
                </div>
                <div class="d-flex flex-row">
                    <v-text-field v-model="titlesStyle.label"
                                  clearable
                                  data-test="matter-title-style-name"
                                  hide-details
                                  label="Optional name (appears on map and list)"
                                  style="max-width: 350px" />
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
    import { Compact } from '@lk77/vue3-color'
    import {
        mapActions,
    } from 'vuex'

    import { BoundaryColourPaletteUsage } from '@/enums/boundary-colour-palette-usage'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { DefaultBoundaryColourPalette } from '@/models/styles/default-boundary-colour-palette'
    import { MATTER_SET_TITLES_STYLE } from '@/store/modules/matter/types'

    export default {
        name: 'MatterTitleStyle',
        components: {
            colourPicker: Compact,
        },
        mixins: [FeatureFlagsMixin],

        data() {
            return {
                stylingTitlesOriginalTitles: null,
                titlesStyle: null,
                styleColour: null,
                styleTransparency: 25,
                showStylePicker: false,
                initializing: true,
                colourPalette: new DefaultBoundaryColourPalette(BoundaryColourPaletteUsage.TitleStyle),
            }
        },

        computed: {

            colourPickerStyle() {
                const container = document.getElementById('mainContainer')
                return {
                    'max-width': container.clientWidth - 200 + 'px',
                }
            },

            styleHasChanged() {
                if (this.titles.length === 1) {
                    if (Math.round(this.stylingTitlesOriginalTitles[0].fillOpacity * 100) !== Math.round(this.titlesStyle.fillOpacity * 100)) {
                        return true
                    }
                    if (this.stylingTitlesOriginalTitles[0].fill !== this.titlesStyle.fill) {
                        return true
                    }
                    if (this.stylingTitlesOriginalTitles[0].dashed !== this.titlesStyle.dashed) {
                        return true
                    }
                    if (this.stylingTitlesOriginalTitles[0].colour !== this.titlesStyle.colour) {
                        return true
                    }
                    if (this.stylingTitlesOriginalTitles[0].showTitleNumber !== this.titlesStyle.showTitleNumber) {
                        return true
                    }
                    if (this.stylingTitlesOriginalTitles[0].strokeWidth !== this.titlesStyle.strokeWidth) {
                        return true
                    }
                    if (this.stylingTitlesOriginalTitles[0].hatch !== this.titlesStyle.hatch) {
                        return true
                    }
                    if (this.stylingTitlesOriginalTitles[0].label !== this.titlesStyle.label) {
                        return true
                    }
                    return false
                } else if (this.titles.length > 1) {
                    let result = false
                    this.titles.forEach(title => {
                        if (Math.round(this.stylingTitlesOriginalTitles[0].fillOpacity * 100) !== Math.round(title.fillOpacity * 100)) {
                            result = true
                        }
                        if (this.stylingTitlesOriginalTitles[0].fill !== title.fill) {
                            result = true
                        }
                        if (this.stylingTitlesOriginalTitles[0].dashed !== title.dashed) {
                            result = true
                        }
                        if (this.stylingTitlesOriginalTitles[0].colour !== title.colour) {
                            result = true
                        }
                        if (this.stylingTitlesOriginalTitles[0].showTitleNumber !== title.showTitleNumber) {
                            result = true
                        }
                        if (this.stylingTitlesOriginalTitles[0].strokeWidth !== title.strokeWidth) {
                            result = true
                        }
                        if (this.stylingTitlesOriginalTitles[0].hatch !== title.hatch) {
                            result = true
                        }
                        if (this.stylingTitlesOriginalTitles[0].label !== title.label) {
                            result = true
                        }
                    })
                    return result
                } else {
                    return false
                }
            },

            titles() {
                return this.$store.state.matter.styleTitles
            },

            atLeastOneTitleHasBoundary() {
                return this.titles.some(t => t.boundaryAvailable)
            },

            presetColours() {
                return [...new Set(this.colourPalette.getPalette().map(c => c.colour))]
            },
        },

        watch: {
            'styleColour.hex'(val) {
                if (val !== undefined) {
                    this.titlesStyle.colour = val

                    this.updateTitlesStyle()
                }
            },

            'titlesStyle.fill'(val) {
                if (val === true && (this.titlesStyle.fillOpacity == null || this.titlesStyle.fillOpacity === 0)) {
                    this.styleTransparency = 50
                    this.titlesStyle.fillOpacity = 0.55
                } else if (val === false) {
                    this.titlesStyle.fillOpacity = 0
                }
                this.updateTitlesStyle()
            },

            styleTransparency(val) {
                if (val != null && val !== 0) {
                    this.titlesStyle.fillOpacity = 1 - (val / 100)
                }
                this.updateTitlesStyle()
            },

            'titlesStyle.strokeWidth'() {
                this.updateTitlesStyle()
            },

            'titlesStyle.dashed'() {
                this.updateTitlesStyle()
            },

            'titlesStyle.hatch'() {
                this.updateTitlesStyle()
            },

            'titlesStyle.showTitleNumber'() {
                this.updateTitlesStyle()
            },

            'titlesStyle.label': function() {
                this.updateTitlesStyle()
            },

            titles(val) {
                let showPicker = false
                if (val != null) {
                    if (val.length > 0) {
                        this.titlesStyle = JSON.parse(JSON.stringify(val[0]))
                        this.stylingTitlesOriginalTitles = JSON.parse(JSON.stringify(val))
                        this.styleColour = this.titlesStyle.colour
                        if (this.titlesStyle.fill === true) {
                            this.styleTransparency = (1 - this.titlesStyle.fillOpacity) * 100
                        }
                        showPicker = true
                    }
                }

                this.showStylePicker = showPicker
            },

            showStylePicker(val) {
                if (val === false) {
                    this.$store.state.matter.styleTitles = []
                } else {
                    this.initializing = true
                    window.setTimeout(() => {
                        this.initializing = false
                    }, 200)
                }
            },

            '$route.name'(val) {
                if (!val.includes('map')) {
                    this.showStylePicker = false
                }
            },
        },

        methods: {
            ...mapActions({
                setTitlesStyle: MATTER_SET_TITLES_STYLE,
            }),

            updateTitlesStyle() {
                if (this.titles.length > 0 && this.initializing === false) {
                    this.titles.forEach(title => {
                        title.colour = this.titlesStyle.colour
                        title.strokeWidth = this.titlesStyle.strokeWidth
                        title.fill = this.titlesStyle.fill
                        title.fillOpacity = this.titlesStyle.fillOpacity
                        title.showTitleNumber = this.titlesStyle.showTitleNumber
                        title.dashed = this.titlesStyle.dashed
                        title.hatch = this.titlesStyle.hatch
                        title.label = this.titlesStyle.label
                        // Assumes that there is only one label colour per 'main' colour.
                        const palettteEntry = this.colourPalette.getPalette().find(c => c.colour === title.colour)
                        if (palettteEntry) {
                            title.labelColour = palettteEntry.labelColour
                        }
                    })
                    this.setTitlesStyle(this.titles)
                }
            },

            resetStyle() {
                this.titles.forEach((title, index) => {
                    const original = this.stylingTitlesOriginalTitles.find(t => {
                        return t.titleNumber === title.titleNumber
                    })

                    title.colour = original.colour
                    title.labelColour = original.labelColour
                    title.strokeWidth = original.strokeWidth
                    title.fill = original.fill
                    title.fillOpacity = original.fillOpacity
                    title.showTitleNumber = original.showTitleNumber
                    title.dashed = original.dashed
                    title.hatch = original.hatch
                    title.label = original.label

                    if (index === 0) {
                        if (original.fill === true) {
                            this.styleTransparency = (1 - this.stylingTitlesOriginalTitles[0].fillOpacity) * 100
                        }
                        this.styleColour = original.colour
                    }
                })

                this.setTitlesStyle(this.titles)
                this.initializing = true
                this.titlesStyle = JSON.parse(JSON.stringify(this.titles[0]))
                // this.stylingTitlesOriginalTitles = JSON.parse(JSON.stringify(val));
                this.styleColour = this.titlesStyle.colour
                if (this.titlesStyle.fill === true) {
                    this.styleTransparency = (1 - this.titlesStyle.fillOpacity) * 100
                }
                this.initializing = false
            },

        },
    }
</script>

<style lang="scss">
    @import 'matter-title-style';
</style>
