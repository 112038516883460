<template>
    <ow-card has-outline
             rounded-lg
             :title="$t('assetMonitoring.preferences.cards.updateMonitoringGroup.title')"
             class="update-monitoring-group-card pa-6 gr-2 d-flex flex-column"
             :class="{
                 '--new-group': !isUpdate,
                 '--update-group': isUpdate,
             }">
        <section v-if="isLoading">
            <ow-loading-skeleton class="update-monitoring-group-card__loading"
                                 height="30px"
                                 margin-bottom="12" />
            <ow-loading-skeleton class="update-monitoring-group-card__loading"
                                 height="100px"
                                 margin-bottom="12" />
            <ow-loading-skeleton class="update-monitoring-group-card__loading"
                                 height="30px"
                                 margin-bottom="12" />
        </section>
        <template v-else>
            <section>
                <label class="update-monitoring-group-card__group-label body-regular-small"
                       for="groupName">{{ $t('assetMonitoring.preferences.cards.updateMonitoringGroup.groupName') }}</label>
                <v-text-field :model-value="model?.groupName"
                              data-test="group-name"
                              variant="outlined"
                              density="compact"
                              hide-details
                              @update:model-value="updateValue('groupName', $event)" />
            </section>
            <section v-if="model?.items?.length"
                     class="mt-2 ml-1">
                <span data-test="group-item-count"
                      class="label-caps-small">{{ $t('assetMonitoring.preferences.cards.updateMonitoringGroup.companiesSelected', {
                          count: model?.items?.length,
                      }) }}</span>
            </section>
            <section class="update-monitoring-group-card__list">
                <update-monitoring-group-list :items="model?.items"
                                              :is-loading="isLoading"
                                              @remove-group="onRemoveGroup" />
            </section>
            <v-divider class="mb-2" />
            <div class="d-flex flex-column">
                <ow-card-actions no-padding
                                 direction="column"
                                 primary-button-data-test="save-button"
                                 secondary-button-data-test="cancel-button"
                                 :primary-button-text="isUpdate ? $t('action.saveChanges') : $t('assetMonitoring.preferences.cards.updateMonitoringGroup.save')"
                                 :primary-button-loading="isSaving"
                                 :secondary-button-text="!dirty || isNullOrEmpty(model?.items) ? $t('action.back') : $t('action.cancel')"
                                 :primary-button-disabled="!dirty || isNullOrEmpty(model?.items) || !model?.groupName"
                                 secondary-button-borderless
                                 has-secondary-button
                                 has-primary-button
                                 @primary-button-click="$emit('save')"
                                 @secondary-button-click="$emit('cancel', !dirty)" />
                <v-divider v-if="isUpdate"
                           class="mt-3" />
                <ow-button v-if="isUpdate"
                           data-test="delete-button"
                           class="mt-3"
                           is-text
                           is-danger-light
                           @click="$emit('delete')">
                    {{ $t('action.delete') }}
                </ow-button>
            </div>
        </template>
    </ow-card>
</template>

<script setup lang="ts">

    import {
        watch,
    } from "vue"

    import {
        IAssetMonitoringCompanyGroup,
        IAssetMonitoringCompanyGroupItem,
        ICompaniesGroupSearchRef,
    } from "@/components/asset-monitoring/company-groups/types"
    import UpdateMonitoringGroupList
        from "@/components/asset-monitoring/company-groups/update-monitoring-group-list.vue"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwCard from "@/components/core/ow-card.vue"
    import OwCardActions from "@/components/core/ow-card-actions.vue"
    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import { isNullOrEmpty } from "@/utils/array-utils"
    const model = defineModel<IAssetMonitoringCompanyGroup | null>({
        default: null,
    })
    const dirty = defineModel<boolean>('dirty', {
        default: false,
    })

    withDefaults(defineProps<{
        isUpdate?: boolean,
        isSaving?: boolean,
        isLoading?: boolean
    }>(), {
        isUpdate: false,
        isSaving: false,
        isLoading: false,
    })

    defineEmits<{
        (e: 'save'),
        (e: 'delete'),
        (e: 'cancel', back: boolean),
    }>()

    const modelSelected = defineModel<{
        searchRef: ICompaniesGroupSearchRef
        selected: IAssetMonitoringCompanyGroupItem[],
    }>('selected', {
        default: {
            searchRef: {
                id: '',
                searchText: '',
            },
            selected: [],
        },
    })

    const updateValue = (key: keyof IAssetMonitoringCompanyGroup, value: any) => {
        dirty.value = true
        model.value = {
            ...model.value,
            [key]: value,
        }
    }

    const onRemoveGroup = (groups: IAssetMonitoringCompanyGroupItem[]) => {
        groups.forEach((group) => {
            model.value.items?.splice(model.value.items?.indexOf(group), 1)
            model.value = {
                ...model.value,
                items: model.value.items,
            }
        })
        dirty.value = true
    }

    watch(() => modelSelected.value, (newValue) => {
        const modelValueItems = JSON.stringify(model.value?.items)
        // If the model is null, we are creating a new group
        if (model.value === null) {
            model.value = {
                groupName: '',
                items: [],
            }
        }

        if (!newValue?.selected) {
            return
        }

        // filter out any null values
        const companies = newValue.selected.filter(company => company)

        // Add the selected companies to the model, don't add duplicates
        if (!isNullOrEmpty(companies)) {
            companies.forEach((company) => {
                const existing = model.value.items?.find((item) =>
                    item?.regNumber === company?.regNumber )
                if (!existing) {
                    if (!model.value.items) {
                        model.value.items = []
                    }
                    model.value.items?.push({
                        id: company.id,
                        name: company.name,
                        searchRef: newValue.searchRef,
                        regNumber: company.regNumber,
                    })
                }
            })
        }

        // Remove companies that are no longer selected
        // We use the search ref to ensure we are only removing companies from the same search
        const itemsToCheck = model.value?.items?.filter((item) => item?.searchRef.id === newValue?.searchRef.id)
        itemsToCheck?.forEach((item) => {
            if (!companies.find((selected) => selected.name === item.name)) {
                model.value.items?.splice(model.value.items?.indexOf(item), 1)
            }
        })

        // If the model has changed, set dirty
        if (modelValueItems !== JSON.stringify(model.value?.items)) {
            dirty.value = true
        }
    },{
        deep: true,
    })
</script>

<style scoped lang="scss">
    @import './update-monitoring-group-card.scss';
</style>