<template>
    <li class="overlays-list-item"
        data-test="overlays-list-item"
        data-track="overlays-list-item"
        :class="{expanded: expanded, visible: overlay.visible}">
        <div class="overlays-list-item-inner">
            <div class="overlays-list-item__checkbox"
                 @click="selectedInternal = !selectedInternal">
                <ow-checkbox :id="`overlay-list-item-${overlay.overlayId}`"
                             v-model="selectedInternal"
                             data-test="overlays-list-item-checkbox"
                             data-track="overlays-list-item-checkbox" />
            </div>
            <div class="overlays-list-item__content"
                 data-test="overlays-list-item-content"
                 data-track="overlays-list-item-content"
                 @click="onClick">
                <label class="caption-highlight overlay-name">
                    {{ overlay.name }}
                </label>
                <div class="overlays-list-item__description accents-small">
                    {{ description }}
                </div>
            </div>

            <ow-progress v-if="overlay.loading"
                         size="small"
                         color="primary"
                         data-test="overlays-list-item-progress" />

            <v-btn icon
                   class="overlays-list-item__visibility"
                   :class="{ 'visible': overlay.visible }"
                   color=""
                   variant="text"
                   data-test="overlays-list-item-visibility"
                   @click="onVisibilityButtonClick">
                <v-icon class="visibility-off-icon">
                    {{ '$visibility-off' }}
                </v-icon>
                <v-icon class="visibility-on-icon">
                    {{ '$visibility' }}
                </v-icon>
            </v-btn>
        </div>
        <div v-if="expanded"
             class="overlays-list-item__actions">
            <ow-button is-secondary
                       data-test="overlays-list-item-edit-plan-button"
                       data-track="overlays-list-item-edit-plan-button"
                       full-width
                       @click="onEditClick">
                {{ t('mapOverlays.panel.editPlan') }}
            </ow-button>
        </div>
    </li>
</template>
<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from "vue-i18n"
    import { useRouter } from "vue-router"
    import { useStore } from "vuex"

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwProgress from '@/components/core/ow-progress.vue'
    import { OverlayListItemModel } from '@/components/map/overlays/overlays-types'
    import { Route } from '@/enums/route.enum'
    import i18n from '@/plugins/i18n'
    import { format } from '@/utils/date-utils'

    const { t } = useI18n()
    const router = useRouter()
    const store = useStore()
    interface Props {
        overlay: OverlayListItemModel,
        selected: boolean,
        expanded: boolean,
    }

    const props = withDefaults(defineProps<Props>(), {
        selected: false,
        expanded: false,
    })

    const emit = defineEmits<{
        (e: 'select', overlay: OverlayListItemModel): void
        (e: 'deselect', overlay: OverlayListItemModel): void
        (e: 'click', overlay: OverlayListItemModel): void
        (e: 'show', overlay: OverlayListItemModel): void
        (e: 'hide', overlay: OverlayListItemModel): void
    }>()

    const description = computed<string>(() => {
        const result = []
        if (props.overlay.targetPages?.length) {
            // TODO: revisit once multiple pages can comprise an overlay
            result.push(i18n.global.t('mapOverlays.panel.pageNumber', { pageNumber: props.overlay.targetPages[0] }))
        }
        if (props.overlay.createdOn) {
            // TODO: update this with vue3 update
            result.push(format(props.overlay.createdOn, 'dd-MM-yyyy'))
        }
        return result.join(' - ')
    })

    const selectedInternal = computed<boolean>({
        get():boolean {
            return props.selected
        },
        set(newVal: boolean) {
            emit(newVal ? 'select' : 'deselect', props.overlay)
        },
    })

    const onClick = () => {
        emit('click', props.overlay)
    }

    const onVisibilityButtonClick = () => {
        if (props.overlay.visible) {
            emit('hide', props.overlay)
        } else {
            emit('show', props.overlay)
        }
    }
    const onEditClick = () => {
        router.push({
            name: Route.Overlay,
            params: {
                overlayId: props.overlay.overlayId,
                matterId: store.state.matter.currentMatter.id,
            },
        })
    }
</script>
<style scoped lang="scss">
@import 'overlays-list-item.scss';
</style>
