<template>
    <div class="checkout-review-order">
        <section class="checkout-review-order__item-list">
            <ow-card has-outline>
                <h2 class="content__heading">
                    {{ $t('checkout.label.reviewOrder') }}
                </h2>
                <ul class="review-list">
                    <li v-for="(item, index) in items"
                        :key="`${ item.itemType }-${ item.displayName }-${ index }`"
                        class="review-list__item">
                        <checkout-item-document-order v-if="isDocumentOrder(item)"
                                                      :item="item" />
                        <checkout-item-report-order v-else
                                                    :item="item" />
                    </li>
                </ul>
            </ow-card>
        </section>
        <section class="checkout-review-order__sidebar">
            <ow-card class="checkout-review-order__sidebar--content"
                     has-outline>
                <div>
                    <h2 class="content__heading">
                        {{ $t('label.summary') }}
                    </h2>
                    <ul class="cart-list">
                        <li v-if="reportItems.length > 0"
                            class="cart-list__item">
                            <div>
                                {{ cartTotals.reportText }}
                            </div>
                            <div class="price">
                                <span>{{ cartTotals.reportsPriceText }}</span>
                            </div>
                        </li>
                        <li v-if="registerDocumentItems.length > 0"
                            class="cart-list__item">
                            <div>
                                {{ cartTotals.titleRegistersText }}
                            </div>
                            <div class="price">
                                <span>{{ cartTotals.titleRegistersPriceText }}</span>
                            </div>
                        </li>
                    </ul>
                    <hr class="divider" />
                    <ul class="cart-list">
                        <li v-if="reportItems.length > 0"
                            class="cart-list__item">
                            <div>
                                <strong>{{ $t('label.chargeFrom') }}</strong> : {{ $t('name.orbitalWitness') }}
                            </div>
                            <div class="price">
                                <ow-icon-ow-logo />
                                <span>{{ cartTotals.reportsPriceText }}</span>
                            </div>
                        </li>
                        <li v-if="registerDocumentItems.length > 0"
                            class="cart-list__item">
                            <div>
                                <strong>{{ $t('label.chargeFrom') }}</strong> : {{ $t('name.hmlr') }}
                            </div>
                            <div class="price">
                                <ow-icon-hmlr width="16"
                                              height="16" />
                                <span>{{ cartTotals.titleRegistersPriceText }}</span>
                            </div>
                        </li>
                    </ul>
                    <hr class="divider" />
                    <div class="cart-list__item">
                        <div>
                            <strong>{{ $t('label.total') }}:</strong>
                        </div>
                        <div>
                            <strong>{{ cartTotals.totalPriceText }}</strong>
                        </div>
                    </div>
                </div>
                <div class="checkout-review-order__sidebar--actions">
                    <h2 class="content__heading">
                        {{ $t('checkout.label.quickOrder') }}
                    </h2>
                    <matter-client-code-inputs :client-code="clientCode"
                                               :matter-code="matterCode"
                                               client-code-data-test-attribute="matters-create-client-code"
                                               matter-code-data-test-attribute="matter-create-code"
                                               @change="onMatterClientCodeChanged" />
                    <ow-button :block="true"
                               :disabled="!isValid"
                               class="action-button"
                               data-test="checkout-review-order-confirm-button"
                               data-track="CHECKOUT - confirm order"
                               large
                               @click="onConfirm">
                        {{ $t('checkout.label.confirmOrder') }} ({{ cartTotals.totalPriceText }})
                    </ow-button>
                </div>
            </ow-card>
        </section>
    </div>
</template>

<script lang="ts">
    import pluralize from 'pluralize'
    import { PropType } from 'vue'
    import { mapActions } from 'vuex'

    import CheckoutItemDocumentOrder from '@/components/checkout/checkout-item-document-order.vue'
    import CheckoutItemReportOrder from '@/components/checkout/checkout-item-report-order.vue'
    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import OwButton from '@/components/core/ow-button.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import MatterClientCodeInputs from '@/components/matter/matter-client-code-inputs.vue'
    import { DocumentType } from '@/consts/document-type'
    import { CheckoutItemType } from '@/enums/checkout-item-type'
    import { IBasicMatter } from '@/interfaces/basic-matter.interface'
    import { ICheckoutItemBase } from '@/interfaces/checkout/checkout-item-base.interface'
    import { ICheckoutItemDocumentOrder } from '@/interfaces/checkout/checkout-item-document-order.interface'
    import { ICheckoutItemReportOrder } from '@/interfaces/checkout/checkout-item-report-order.interface'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
    } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    interface IData {
        orderMatterDetails: IBasicMatter
    }

    export default {
        name: 'CheckoutReviewOrder',

        components: {
            CheckoutItemDocumentOrder,
            CheckoutItemReportOrder,
            MatterClientCodeInputs,
            OwButton,
            OwCard,
            OwIconHmlr,
            OwIconOwLogo,
        },

        props: {
            items: {
                type: Array as PropType<ICheckoutItemReportOrder[] | ICheckoutItemDocumentOrder[]>,
                required: true,
            },

            clientCode: {
                type: String,
                required: false,
                default: null,
            },

            matterCode: {
                type: String,
                required: false,
                default: null,
            },
        },

        emits: [
            'confirm',
            'update-matter',
        ],

        data(): IData {
            return {
                orderMatterDetails: {
                    clientCode: '',
                    matterCode: '',
                },
            }
        },

        computed: {
            registerDocumentItems(): ICheckoutItemDocumentOrder[] {
                return this.items
                    .filter(item => item.itemType === CheckoutItemType.Document)
                    .filter((docItem: ICheckoutItemDocumentOrder) =>
                        docItem.document.request.documentType === DocumentType.REGISTER)
            },

            reportItems(): ICheckoutItemReportOrder[] {
                return this.items
                    .filter(item => item.itemType === CheckoutItemType.Report)
            },

            cartTotals() {
                const totals = {
                    titleRegisters: this.registerDocumentItems.length,
                    reports: this.reportItems.length,
                }
                const prices = {
                    titleRegistersPrice: this.registerDocumentItems.reduce((total, item) => total + item.cost, 0),
                    reportsPrice: this.reportItems.reduce((total, item) => total + item.cost, 0),
                }
                const totalPrice = prices.titleRegistersPrice + prices.reportsPrice
                return {
                    totalPrice,
                    totalPriceText: `£${ totalPrice }`,
                    ...totals,
                    ...prices,
                    titleRegistersText: `${ pluralize('Title register', totals.titleRegisters) } (${ totals.titleRegisters })`,
                    titleRegistersPriceText: `£${ prices.titleRegistersPrice }`,
                    reportText: `${ pluralize('Report', totals.reports) } (${ totals.reports })`,
                    reportsPriceText: prices.reportsPrice === 0 ? 'Free' : `£${ prices.reportsPrice }`,
                }
            },

            isValid(): boolean {
                return !isNullOrWhitespace(this.orderMatterDetails.matterCode)
            },
        },

        mounted() {
            this.logCheckoutStart()
        },

        methods: {
            ...mapActions({
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            isDocumentOrder(item: ICheckoutItemBase): boolean {
                return item.itemType === CheckoutItemType.Document
            },

            onConfirm(): void {
                this.$emit('confirm', this.orderMatterDetails)
            },

            onMatterClientCodeChanged(matterClientCode: {
                clientCode: string | null,
                matterCode: string | null,
            }): void {
                this.orderMatterDetails.matterCode = matterClientCode?.matterCode
                this.orderMatterDetails.clientCode = matterClientCode?.clientCode
                this.$emit('update-matter', this.orderMatterDetails)
            },

            logCheckoutStart(): void {
                this.logFeatureUsage({ type: 'checkout_started' })
                this.logHeapEvent({ type: 'CHECKOUT - started' })
            },
        },
    }
</script>

<style lang="scss">
    @import 'checkout-review-order';
</style>
