/// See https://www.figma.com/file/3OmijfkDNaxNK8qIqka4bk/OW-Design-System?node-id=749%3A10006&t=7qFoVG0CYo9FxwnK-0
import { BoundaryColourPaletteUsage } from '@/enums/boundary-colour-palette-usage'
import { BoundaryStyle } from '@/models/styles/boundary-style'
import { IBoundaryColourPalette } from '@/interfaces/boundary-colour-palette.interface'

export class DefaultBoundaryColourPalette implements IBoundaryColourPalette {
    private readonly _palette: Array<BoundaryStyle>
    constructor(usage?: BoundaryColourPaletteUsage) {
        this._palette = [
            // Set 1
            new BoundaryStyle('Blue-1', '#158BF3', '#0B77D5', 0.2, 4),
            new BoundaryStyle('Pink-1', '#F53B9E', '#E40C7F', 0.2, 4),
            new BoundaryStyle('Orange-1', '#DA6905', '#BA5803', 0.2, 4),
            new BoundaryStyle('Green-1', '#099F03', '#078703', 0.2, 4),
            new BoundaryStyle('Purple-1', '#AA67F7', '#9846F6', 0.2, 4),
            new BoundaryStyle('Teal-1', '#039994', '#03817D', 0.2, 4),
            new BoundaryStyle('Fuschia-1', '#DC45E4', '#C91ED2', 0.2, 4),
            new BoundaryStyle('Red-1', '#F64A50', '#EA0B12', 0.2, 4),
            new BoundaryStyle('Grey-1', '#7D8B98', '#6C7689', 0.2, 4),
            new BoundaryStyle('DarkGrey-1', '#384048', '#384048', 0.2, 4),

            // Set 2
            new BoundaryStyle('Blue-2', '#158BF3', '#0B77D5', 0, 4),
            new BoundaryStyle('Pink-2', '#F53B9E', '#E40C7F', 0, 4),
            new BoundaryStyle('Orange-2', '#DA6905', '#BA5803', 0, 4),
            new BoundaryStyle('Green-2', '#099F03', '#078703', 0, 4),
            new BoundaryStyle('Purple-2', '#AA67F7', '#9846F6', 0, 4),
            new BoundaryStyle('Teal-2', '#039994', '#03817D', 0, 4),
            new BoundaryStyle('Fuschia-2', '#DC45E4', '#C91ED2', 0, 4),
            new BoundaryStyle('Red-2', '#F64A50', '#EA0B12', 0, 4),
            new BoundaryStyle('Grey-2', '#7D8B98', '#6C7689', 0, 4),
            new BoundaryStyle('DarkGrey-2', '#384048', '#384048', 0, 4),

            // Set 3
            new BoundaryStyle('Blue-3', '#158BF3', '#0B77D5', 1, 4, true),
            new BoundaryStyle('Pink-3', '#F53B9E', '#E40C7F', 1, 4, true),
            new BoundaryStyle('Orange-3', '#DA6905', '#BA5803', 1, 4, true),
            new BoundaryStyle('Green-3', '#099F03', '#078703', 1, 4, true),
            new BoundaryStyle('Purple-3', '#AA67F7', '#9846F6', 1, 4, true),
            new BoundaryStyle('Teal-3', '#039994', '#03817D', 1, 4, true),
            new BoundaryStyle('Fuschia-3', '#DC45E4', '#C91ED2', 1, 4, true),
            new BoundaryStyle('Red-3', '#F64A50', '#EA0B12', 1, 4, true),
            new BoundaryStyle('Grey-3', '#7D8B98', '#6C7689', 1, 4, true),
            new BoundaryStyle('DarkGrey-3', '#384048', '#384048', 1, 4, true),

            // Set 4
            new BoundaryStyle('Blue-4', '#158BF3', '#0B77D5', 1, 4),
            new BoundaryStyle('Pink-4', '#F53B9E', '#E40C7F', 1, 4),
            new BoundaryStyle('Orange-4', '#DA6905', '#BA5803', 1, 4),
            new BoundaryStyle('Green-4', '#099F03', '#078703', 1, 4),
            new BoundaryStyle('Purple-4', '#AA67F7', '#9846F6', 1, 4),
            new BoundaryStyle('Teal-4', '#039994', '#03817D', 1, 4),
            new BoundaryStyle('Fuschia-4', '#DC45E4', '#C91ED2', 1, 4),
            new BoundaryStyle('Red-4', '#F64A50', '#EA0B12', 1, 4),
            new BoundaryStyle('Grey-4', '#7D8B98', '#6C7689', 1, 4),
            new BoundaryStyle('DarkGrey-4', '#384048', '#384048', 1, 4),

            // Additional colours for sketches/title list only
            ...([BoundaryColourPaletteUsage.Sketches, BoundaryColourPaletteUsage.TitleStyle].includes(usage)
                ? [
                    new BoundaryStyle('Light Green', '#84CF81', '#348532', 0, 4),
                    new BoundaryStyle('Dark Blue', '#0B467A', '#0B467A', 0, 4),
                    new BoundaryStyle('Yellow', '#FFC835', '#F1C40F', 0, 4),
                    new BoundaryStyle('Brown', '#7A680B', '#7A680B', 0, 4),
                    new BoundaryStyle('Light Orange', '#EDB482', '#B46118', 0, 4),
                ]
                : []),
        ]
    }

    getPalette(): Array<BoundaryStyle> {
        return this._palette
    }

    getEntryForIndex(index: number): BoundaryStyle {
        return this._palette[index % this._palette.length]
    }

    getSketchesTextColours(): Array<BoundaryStyle> {
        return [
            new BoundaryStyle('White', '#000000', '#FFFFFF', 1, 4),
            new BoundaryStyle('Black', '#FFFFFF', '#000000', 1, 4),
        ]
    }

    getEntryForTenure(tenure: string): BoundaryStyle {
        switch (tenure) {
            case 'Freehold':
                return this._palette.find(style => style.alias === 'Orange-1')
            case 'Leasehold':
                return this._palette.find(style => style.alias === 'Blue-1')
            default:
                return this._palette.find(style => style.alias === 'DarkGrey-1')
        }
    }

    getEntryForComplexity(complexity: string): BoundaryStyle {
        switch (complexity) {
            case 'Extremely Simple':
                return new BoundaryStyle('Extremely-Simple', '#004F34', '#004F34', 0.3, 4)
            case 'Very Simple':
                return new BoundaryStyle('Very-Simple', '#00925E', '#006B46', 0.3, 4)
            case 'Fairly Simple':
                return new BoundaryStyle('Fairly-Simple', '#00D088', '#008556', 0.3, 4)
            case 'Fairly Complex':
                return new BoundaryStyle('Fairly-Complex', '#DF8413', '#AA640E', 0.3, 4)
            case 'Very Complex':
                return new BoundaryStyle('Very-Complex', '#F44235', '#E41A0C', 0.3, 4)
            case 'Extremely Complex':
                return new BoundaryStyle('Extremely-Complex', '#A9140A', '#A9140A', 0.3, 4)
            default:
                return new BoundaryStyle('Unknown-Complexity', '#7D8B98', '#6C7689', 0.3, 4)
        }
    }
}
