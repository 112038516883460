import SketchesApi from '@/api/sketches.api'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import {
    SketchSource,
    SketchType,
} from '@/enums/sketches-enums'
import { SketchesFactory } from '@/store/modules/sketches/types/sketches-factory'
import GeoJSON from 'ol/format/GeoJSON'
import { Feature } from 'ol'
import {
    MultiPoint,
    LineString,
    MultiLineString,
    Polygon,
    MultiPolygon,
    Point,
} from 'ol/geom'
import i18n from '@/plugins/i18n'
import { useStore } from "vuex"
import { SKETCHES_LOAD_BY_MATTER_ID } from '@/store/modules/sketches/types'
import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

export const useStoreSketch = () => {
    const store = useStore()

    const storeSketchRequest = async (name: string, features: Array<Feature>, source: SketchSource): Promise<void> => {
        const featuresByType = [
            {
                type: SketchType.Marker,
                label: i18n.global.t('sketches.spatialImportTypes.points'),
                features: features.filter(feature => {
                    const geom = feature.getGeometry()
                    return geom instanceof MultiPoint
                }),
            },
            {
                type: SketchType.Line,
                label: i18n.global.t('sketches.spatialImportTypes.lines'),
                features: features.filter(feature => {
                    const geom = feature.getGeometry()
                    return geom instanceof LineString || geom instanceof MultiLineString
                }),
            },
            {
                type: SketchType.Area,
                label: i18n.global.t('sketches.spatialImportTypes.areas'),
                features: features.filter(feature => {
                    const geom = feature.getGeometry()
                    return geom instanceof Polygon || geom instanceof MultiPolygon
                }),
            },
            {
                type: SketchType.Text,
                label: i18n.global.t('sketches.spatialImportTypes.text'),
                features: features.filter(feature => {
                    const geom = feature.getGeometry()
                    return geom instanceof Point
                }),
            },
        ].filter(ft => ft.features.length)

        for (const ft of featuresByType) {
            const geoJson:String = new GeoJSON().writeFeatures(ft.features, {
                featureProjection: CoordinateSystemCode.EPSG27700,
                dataProjection: CoordinateSystemCode.EPSG4326,
            })
            const newSketch = SketchesFactory.newSketch(ft.type)
            newSketch.visible = true
            newSketch.name = name
            if (featuresByType.length > 1) {
                newSketch.name = `${ name } - ${ ft.label }`
            }
            newSketch.strokeWidth = 4
            newSketch.strokeColour = '#0B467A'
            newSketch.fillOpacity = 0
            newSketch.fillColour = null
            newSketch.hatch = false
            newSketch.dash = false
            newSketch.geoJson = geoJson as string
            newSketch.sketchSource = source
            await SketchesApi.createSketch(store.state.matter.currentMatter.id, newSketch)

            await store.dispatch(SKETCHES_LOAD_BY_MATTER_ID, store.state.matter.currentMatter.id)

            await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MAT-TITLE-LIST - Add sketches to a matter via DoM or Shapefile',
                featuresCount: features.length,
            })
        }
    }
    return {
        storeSketchRequest,
    }
}
