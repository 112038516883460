import { IReviewAssistantState } from '@/interfaces/store/review-assistant/review-assistant-state.interface'

import mutations from './mutations'

const state: IReviewAssistantState = {
    entryPoint: '/',
}

export default {
    namespaced: true,
    state,
    mutations,
}
