<template>
    <header class="walkthrough-header"
            data-test="walkthrough-header">
        <div v-if="isSharedLinkView"
             class="walkthrough-header__logo-container"
             data-test="walkthrough-header-logo-button"
             @click="$emit('logo-click')">
            <ow-icon-ow-logo-bw width="40"
                                height="34"
                                data-test="walkthrough-header-logo" />
        </div>
        <div class="walkthrough-header__header-content">
            <h3 class="walkthrough-header__title hide-in-percy">
                {{ walkthroughHeaderText }}
            </h3>
            <div class="walkthrough-header__actions">
                <v-btn v-if="!isSharedLinkView"
                       :loading="isCreateShareableLinkLoading"
                       color="white"
                       data-test="walkthrough-header-preview-button"
                       data-track="WALKTHROUGH - Preview"
                       variant="text"
                       @click="hasChanges ? preventPreview() : openPreview()">
                    Preview
                </v-btn>
                <v-btn v-if="!isSharedLinkView"
                       :disabled="!hasChanges"
                       color="white"
                       data-test="walkthrough-header-save-button"
                       data-track="WALKTHROUGH - Save changes"
                       variant="text"
                       @click="$emit('save')">
                    Save
                </v-btn>
                <v-btn v-if="!isSharedLinkView"
                       color="white"
                       data-test="walkthrough-header-sharing-button"
                       data-track="WALKTHROUGH - Share from within the walkthrough edit page"
                       variant="text"
                       :value="sharingButtonText"
                       @click="onSharingSettingsClick">
                    {{ sharingButtonText }}
                </v-btn>
                <v-btn v-if="isSharedLinkView"
                       color="white"
                       data-test="walkthrough-header-exit-button"
                       data-track="WALKTHROUGH - Exit walkthrough when viewed through client share link"
                       variant="text"
                       @click="$emit('exit')">
                    <v-icon start>
                        $exit-to-app
                    </v-icon>
                    Exit
                </v-btn>
                <v-btn v-if="!isSharedLinkView"
                       color="white"
                       data-test="walkthrough-header-close-button"
                       data-track="WALKTHROUGH - Close walkthrough edit"
                       icon
                       variant="text"
                       @click="tryCloseWalkthrough">
                    <v-icon>$close</v-icon>
                </v-btn>
            </div>
        </div>

        <ow-dialog v-model="isSharingDialogOpened"
                   icon="$matter-shared-nav"
                   title="Walkthrough sharing options">
            <app-matter-share :is-for-walkthrough-only="true"
                              :is-prevent-preview-enabled="hasChanges"
                              :reset-form-on-modal-close="true"
                              @log-heap-event="logHeapEventForPreview"
                              @prevent-preview="preventPreview" />
        </ow-dialog>
    </header>
</template>

<script lang="ts">

    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwIconOwLogoBw from '@/components/core/icons/ow-icon-ow-logo-bw.vue'
    import owDialog from '@/components/core/ow-dialog.vue'
    import MatterShare from '@/components/matter/matter-share.vue'
    import {
        CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE,
        CORE_MUTATE_PREVENT_TRANSITION,
    } from '@/store/modules/core/types'
    import {
        MATTER_CREATE_LINK_SHARE,
        MATTER_GET_LINK_SHARE_URL,
        MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT,
    } from '@/store/modules/matter/types'
    import {
        WALKTHROUGH_GET_HAS_CHANGES,
        WALKTHROUGH_SAVE,
    } from '@/store/modules/walkthrough/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        OPEN_WINDOW,
    } from '@/store/mutation-types'

    export default {
        name: 'WalkthroughHeader',

        components: {
            owDialog,
            OwIconOwLogoBw,
            appMatterShare: MatterShare,
        },

        props: {
            isSharedLinkView: {
                type: Boolean,
                required: false,
            },
        },
        emits: ['logo-click', 'save', 'exit', 'close'],

        data() {
            return {
                isSharingDialogOpened: false,
                isCreateShareableLinkLoading: false,
                preventTransitionTitle: 'Want to save your walkthrough?',
                preventTransitionText: 'You will lose the changes if you close it.',
            }
        },

        computed: {
            ...mapState({
                walkthroughsEnabledInLinkShare: state => state.matter.currentMatter.linkShare?.isWalkthroughShare,
                linkShareEnabled: state => state.matter.currentMatter.linkShare?.enabled,
                matterName: state => state.matter.currentMatter.name,
                currentMatter: state => state.matter.currentMatter,
                preventTransition: state => state.core.preventTransition,
                linkShare: state => state.matter.currentMatter.linkShare,
            }),

            ...mapGetters({
                hasChanges: WALKTHROUGH_GET_HAS_CHANGES,
                URLForLink: MATTER_GET_LINK_SHARE_URL,
            }),

            sharingButtonText() {
                if (this.walkthroughsEnabledInLinkShare === true &&
                    this.linkShareEnabled === true) {
                    return 'Sharing settings'
                } else {
                    return 'Not shared'
                }
            },

            walkthroughHeaderText() {
                return `Walkthrough of ${ this.matterName }`
            },
        },

        watch: {
            isSharingDialogOpened() {
                if (!this.isSharingDialogOpened &&
                    typeof (this.preventTransition.resetForm) === 'function') {
                    this.preventTransition.resetForm()
                    this.initPreventTransitionState()
                }
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                saveWalkthrough: WALKTHROUGH_SAVE,
                openWindow: OPEN_WINDOW,
                createShareableLink: MATTER_CREATE_LINK_SHARE,
            }),

            ...mapMutations({
                setPreventTransition: CORE_MUTATE_PREVENT_TRANSITION,
            }),

            ...mapMutations({
                initPreventTransitionState: CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE,
            }),

            onSharingSettingsClick() {
                this.isSharingDialogOpened = true
                this.logHeapEvent({
                    type: 'Walkthrough: Open Sharing Settings',
                    metadata: {
                        matterId: this.currentMatter.id,
                        walkthroughId: this.currentMatter.walkthroughId,
                        isUserMatterOwner: this.currentMatter.createdByMe,
                    },
                })
            },

            async saveWalkthroughAndOpenPreview() {
                await this.saveWalkthrough()
                await this.openPreview()
            },

            async saveWalkthroughAndClosePreview() {
                await this.saveWalkthrough()
                this.closeWalkthrough()
            },

            async openPreview() {
                if (!this.linkShare) {
                    this.isCreateShareableLinkLoading = true
                    await this.createShareableLink({
                        enabled: true,
                        isWalkthroughShare: true,
                    })
                    this.isCreateShareableLinkLoading = false
                }
                this.openWindow(this.URLForLink)
                this.logHeapEventForPreview()
            },

            tryCloseWalkthrough() {
                if (this.hasChanges) {
                    this.preventClose()
                } else {
                    this.closeWalkthrough()
                }
            },

            closeWalkthrough() {
                this.$emit('close')
            },

            preventClose() {
                this.setPreventTransition({
                    showDialog: true,
                    resetForm: this.closeWalkthrough,
                    submitForm: this.saveWalkthroughAndClosePreview,
                    title: this.preventTransitionTitle,
                    text: this.preventTransitionText,
                })
            },

            preventPreview() {
                this.setPreventTransition({
                    showDialog: true,
                    resetForm: this.openPreview,
                    submitForm: this.saveWalkthroughAndOpenPreview,
                    title: this.preventTransitionTitle,
                    text: this.preventTransitionText,
                })
            },

            logHeapEventForPreview() {
                this.logHeapEvent({
                    type: 'Walkthrough: Enter Preview',
                    metadata: {
                        matterId: this.currentMatter.id,
                        walkthroughId: this.currentMatter.walkthroughId,
                        isUserMatterOwner: this.currentMatter.createdByMe,
                    },
                })
            },
        },
    }
</script>
<style lang="scss">
    @import 'walkthrough-header.scss';
</style>
