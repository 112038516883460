import { getGraphQlUri } from '@/utils/environment.utils'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    name: 'Hello',
    loaded: false,
    settings: {},
    featureFlags: undefined,
    endpoints: {
        graphQL: getGraphQlUri(),
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
