<template>
    <section class="pdf-page-selection">
        <v-progress-linear v-if="isLoading"
                           class="pdf-page-selection__loading"
                           indeterminate />
        <pdf-page-selection-item v-for="i in numPages"
                                 :key="i"
                                 :data-page-number="i"
                                 :pdf-document="pdfDocument"
                                 :page-number="i"
                                 :selected="selectedPageNumbers.includes(i)"
                                 data-test="pdf-page-selection-item"
                                 data-track="DOCUMENTS - Select PDF page"
                                 :multi-select="props.allowMultipleSelection"
                                 @select="addPage"
                                 @deselect="removePage" />
    </section>
</template>
<script setup lang="ts">
    import { PDFPageProxy } from 'pdfjs-dist'
    import * as pdfjsLib from 'pdfjs-dist/build/pdf.js'
    import {
        onMounted,
        ref,
        watch,
    } from 'vue'

    import SecurityService from '@/api/security'
    import PdfPageSelectionItem from '@/components/documents/pdf-page-selection-item.vue'
    import { getApiUri } from '@/utils/environment.utils'

    const emit = defineEmits(['loading', 'loaded', 'change'])
    interface Props {
        documentUrl: string
        preselectPageNumbers?: number[],
        allowMultipleSelection?: boolean,
    }
    const props = withDefaults(defineProps<Props>(), {
        preselectPageNumbers: () => [] as number[],
        allowMultipleSelection: false,
    })

    let pdfDocument: pdfjsLib.PDFDocumentProxy | null = null
    const numPages = ref(0)
    const isLoading = ref(true)
    const selectedPageNumbers = ref<number[]>([])
    const selectedPages = ref<PDFPageProxy[]>([])
    onMounted(async () => {
        selectedPageNumbers.value = props.preselectPageNumbers

        isLoading.value = true
        emit('loading')
        try {
            pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry')
            const token = await SecurityService.getAccessToken()

            const httpHeaders = { Authorization: `Bearer ${ token }` }
            const useApiAuth = props.documentUrl.includes(getApiUri())
            const loadingTask = await pdfjsLib.getDocument({
                url: props.documentUrl,
                maxImageSize: Number.MAX_VALUE,
                disableAutoFetch: true,
                disableStream: true,
                httpHeaders: useApiAuth ? httpHeaders : undefined,
                withCredentials: useApiAuth,
            })
            pdfDocument = await loadingTask.promise
            numPages.value = pdfDocument.numPages
        } catch (error) {
            console.error('Error loading PDF: ', error)
        } finally {
            isLoading.value = false
        }
        isLoading.value = false
        emit('loaded')
        if (selectedPageNumbers.value.length > 0) {
            await updateSelectedPages()
        }
    })

    const updateSelectedPages = async () => {
        const pages = []
        for (const pageNumber of selectedPageNumbers.value) {
            if (pdfDocument) {
                const page = await pdfDocument.getPage(pageNumber)
                if (page) {
                    pages.push(page)
                }
            }
        }
        selectedPages.value = pages
        emit('change', selectedPages.value) // Emitting the actual pages
    }

    const addPage = async (page: PDFPageProxy) => {
        selectedPageNumbers.value = props.allowMultipleSelection
            ? [...selectedPageNumbers.value, page.pageNumber]
            : [page.pageNumber]
        await updateSelectedPages()
    }

    const removePage = async (page: PDFPageProxy) => {
        selectedPageNumbers.value = selectedPageNumbers.value.filter((i) => i !== page.pageNumber)
        await updateSelectedPages()
    }

    watch(
        () => props.allowMultipleSelection,
        (val) => {
            if (!val) {
                selectedPageNumbers.value = selectedPageNumbers.value.slice(0, 1)
            }
        },
    )
</script>
<style scoped lang="scss">
  @import "pdf-page-selection";
</style>
