import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

const state = {
    loadingTitleComplexityForTitleNumbers: [], // array of title numbers for which loading is occurring.
    titleComplexityResults: [],
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
