import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { isNullOrEmpty } from '@/utils/array-utils'

export default class FindNearbyApi {
    static END_POINT = 'find-nearby'
    private static controller: AbortController

    /**
     * Get titles and associated matter details within the given bounding boxes.
     * @param titleNumbers
     * @param distanceMetres
     * @param excludeMatterIds - an optional list of matter Ids to exclude from the results.
     */
    static getMatterTitles(titleNumbers: Array<string>, distanceMetres: number, excludeMatterIds:number[] = []): Promise<IHttpClientResponse> {
        const uri = `${ this.END_POINT }/matter-titles`

        if (isNullOrEmpty(titleNumbers)) {
            throw new Error(`titleNumbers array was null or empty in api call: ${ uri }`)
        }

        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()

        return httpClient
            .post(uri, { titleNumbers, distanceMetres, excludeMatterIds },
                { signal: this.controller.signal })
    }

    static getCorporateOwners(titleNumbers: Array<string>, distanceMetres: number): Promise<IHttpClientResponse> {
        const uri = `${ this.END_POINT }/corporate-owners`

        if (isNullOrEmpty(titleNumbers)) {
            throw new Error(`titleNumbers array was null or empty in api call: ${ uri }`)
        }

        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()

        return httpClient
            .post(uri, { titleNumbers, distanceMetres },
                { signal: this.controller.signal })
    }
}
