<template>
    <ow-card :loading="!selectedTitle?.record || documentAvailabilityLoading"
             :loading-skeleton="{ rows: 0, columns: 1, expandRows: false, reverse: true, ctas: 2 }"
             :has-hmlr-badge="true"
             class="order-register"
             title="Title Register">
        <div :class="{'is-ordered': dateBGRegister}"
             class="order-register__tab-card-content">
            <div class="order-register__register-details">
                <ow-icon-title-register width="36" />
                <div class="order-register__register-text">
                    <ow-document-availability-label v-if="showDocumentAvailabilityText"
                                                    :hmlr-availability-code="documentAvailabilityCode"
                                                    :is-ordered="!showDocumentAvailabilityText" />
                    <div class="order-register__date body-copy--greyout hide-in-percy"
                         data-test="order-register-label-loaded-date">
                        Edition date: <span v-if="editionDate">{{ formatDate(editionDate) }}</span>
                        <span v-else>(Available after order)</span>
                    </div>
                    <div v-if="dateDataOC"
                         class="order-register__date body-copy--greyout hide-in-percy"
                         data-test="order-register-label-search-from-date">
                        {{ $t('documents.searchFrom', { date: formatDate(new Date(dateDataOC)) }) }}
                    </div>
                    <div v-if="issuedOnDate"
                         class="order-register__date body-copy--greyout hide-in-percy"
                         data-test="order-register-label-issued-on-date">
                        {{ $t('documents.issuedOn', { date: formatDate(new Date(issuedOnDate)) }) }}
                    </div>
                </div>
            </div>
            <div class="order-register__actions"
                 :class="{'order-title-plan__actions-overflow': hasOfficialCopies && showBackdatedViewButton}">
                <ow-tooltip :disabled="isAbleToOrderRegister || isDocumentAvailable"
                            :position="OwTooltipPosition.Top">
                    <template #activator="{ props }">
                        <div v-bind="props">
                            <order-register-button :current-matter-id="currentMatterId"
                                                   :order-with-checkout="orderWithCheckout"
                                                   :selected-title-number="selectedTitleNumber"
                                                   button-data-test-attribute="order-register-button-document" />

                            <ow-split-button v-if="isDocumentAvailable"
                                             :button-data-track="documentButton.dataTrack"
                                             :disabled="documentButton.disabled"
                                             :is-menu-item-loading="isExportPdfLoading || isOrderRegisterLoading"
                                             :label="documentButton.text"
                                             :menu-items="buttonItems"
                                             :outlined="Boolean(dateBGRegister)"
                                             button-data-test="order-register-button-document"
                                             menu-data-test="order-register-menu"
                                             menu-data-track="TITLE-DETAILS-PANEL - Toggle title register button menu"
                                             @button-click="documentButton.handler"
                                             @menu-item-click="menuClickHandler" />
                        </div>
                    </template>
                    Please add this title to a matter in order to purchase documents
                </ow-tooltip>

                <ow-export-button-menu v-if="isDocumentAvailable"
                                       :current-matter-id="currentMatterId"
                                       :selected-title-numbers="[selectedTitleNumber]"
                                       button-height="40px"
                                       data-test="order-register-card-export-as-button"
                                       data-track="TITLE-DETAILS-PANEL - Export register as word document from summary panel"
                                       heap-tracker-prefix="TITLE_REGISTER_CARD" />
            </div>

            <div v-if="isFailedOrder && documentAvailabilityCode !== hmlrDocumentAvailabilityCode.Unavailable"
                 class="order-register__error-message error-text">
                There was an error with ordering this document. You have not been charged.
            </div>
            <div v-if="hasInfoContent"
                 class="ow-card__info-content-wrapper">
                <div v-for="(infoContentData, i) in computedInfoContent"
                     :key="i"
                     class="ow-card__info-content"
                     :data-track="infoContentDataTrack"
                     :class="`${ infoContentData.class }`">
                    <ow-icon-text :icon="infoContentData.icon"
                                  :is-italic-text="true"
                                  :is-single-line="false"
                                  :text="infoContentData.text" />
                </div>
            </div>
        </div>
    </ow-card>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapMutations,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OwIconTitleRegister from '@/components/core/icons/ow-icon-title-register.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import OwSplitButton from '@/components/core/ow-split-button.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import OwExportButtonMenu from '@/components/reporting/export-button-menu.vue'
    import OrderRegisterButton from '@/components/title-panel/order-register-button.vue'
    import useDates from '@/composables/use-dates'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import { ColorClass } from '@/enums/color-class.enum'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import {
        ADD_DOCUMENT_TO_MATTER,
    } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import { TITLE_MUTATE_REGISTER_ORDER_LOADING } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_GET_DOCUMENT_CHARGE,
    } from '@/store/mutation-types'
    import {
        differenceInMonths,
    } from '@/utils/date-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'OrderRegister',

        components: {
            OrderRegisterButton,
            OwCard,
            OwDocumentAvailabilityLabel,
            OwExportButtonMenu,
            OwIconText,
            OwIconTitleRegister,
            OwSplitButton,
            OwTooltip,
        },

        mixins: [
            flagsMixin,
        ],

        props: {
            selectedTitle: {
                type: Object,
                required: true,
            },
            selectedTitleNumber: {
                type: null as PropType<string | null>,
                required: true,
                default: '',
            },
            // deprecated: no longer needed as there's a getter in the store
            // eslint-disable-next-line vue/no-unused-properties
            userHasVtsCharge: {
                type: Boolean,
                required: false,
            },
            currentMatterId: {
                type: Number,
                required: false,
                default: undefined,
            },
            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },
            orderWithCheckout: {
                type: Boolean,
                required: false,
            },
            infoContentDataTrack: {
                type: String,
                required: false,
                default: 'ow-card-info-content',
            },
            officialCopies: {
                type: Object,
                required: false,
                default: null,
            },
        },

        data() {
            return {
                isExportPdfLoading: false,
                hmlrDocumentAvailabilityCode: HmlrDocumentAvailabilityCode,
                OwTooltipPosition,
            }
        },

        computed: {
            ...mapGetters({
                documentCharge: USER_GET_DOCUMENT_CHARGE,
            }),

            isOrderRegisterLoading() {
                return Boolean(this.registerAvailability?.loadingOrder)
            },

            isFailedOrder() {
                return Boolean(this.registerAvailability?.failedOrder)
            },

            dateBGRegister() {
                return this.registerAvailability?.responseDateValue
            },

            editionDate() {
                if (this.registerAvailability?.editionDateValue) {
                    return new Date(this.registerAvailability.editionDateValue)
                }
                return null
            },

            dateDataOC() {
                if (this.registerAvailability?.officialCopyDateTime) {
                    return this.registerAvailability.officialCopyDateTime
                }

                return null
            },

            issuedOnDate() {
                if (this.registerAvailability?.responseDateValue) {
                    return new Date(this.registerAvailability.responseDateValue)
                }
                return null
            },

            isDocumentUnderInvestigation() {
                return this.registerAvailability.orderStatus === DocumentOrderStatus.UNDER_INVESTIGATION
            },

            isDocumentAvailable() {
                return this.registerAvailability.documentId !== null || this.editionDate !== null
            },

            showBackdatedViewButton() {
                return Boolean(this.registerAvailability?.currentVersionIsBackdated)
            },

            isAbleToOrderRegister() {
                return this.isOrderingAllowed || this.orderWithCheckout
            },

            documentButton() {
                if (this.isDocumentAvailable && !this.showBackdatedViewButton) { // Register available and previously ordered
                    return {
                        text: this.$t('action.view'),
                        handler: () => this.viewRegisterDocument(),
                        dataTrack: 'TITLE-DETAILS-PANEL - View register',
                    }
                }
                if (this.isDocumentAvailable && this.showBackdatedViewButton) { // View Backdated register
                    return {
                        text: this.$t('action.viewBackdated'),
                        handler: () => this.viewRegisterDocument(),
                        dataTrack: 'TITLE-DETAILS-PANEL - View register',
                    }
                }
                return {}
            },

            buttonItems() {
                return [{
                    id: 1,
                    text: this.$t('action.download'),
                    dataTest: 'order-register-export-as-pdf',
                    dataTrack: 'TITLE-DETAILS-PANEL - Export register as PDF from summary panel',
                    handler: () => this.exportAsPdf(),
                }, {
                    id: 2,
                    text: this.$t('action.refreshWithCost', { cost: this.documentCharge }),
                    dataTest: 'order-register-button-refresh',
                    dataTrack: 'TITLE-DETAILS-PANEL - Refresh register from summary panel',
                    disabled: !this.isOrderingAllowed,
                    tooltipText: !this.isOrderingAllowed ? 'Please add this title to a matter in order to purchase documents' : null,
                    handler: () => this.refreshRegister(),
                }]
            },

            documentAvailabilityCode() {
                return this.registerAvailability?.hmlrAvailabilityCode ? this.registerAvailability?.hmlrAvailabilityCode : null
            },

            showDocumentAvailabilityText() {
                return this.isDocumentUnderInvestigation ||
                    (this.documentAvailabilityCode &&
                        this.selectedTitle.officialCopiesAvailability.loading === false &&
                        !this.dateBGRegister)
            },

            registerAvailability() {
                return this.selectedTitle.officialCopiesAvailability.results?.titleRegister
            },

            documentAvailabilityLoading() {
                return this.selectedTitle.officialCopiesAvailability.loading
            },

            infoContent() {
                const info = []
                // if issued on date is over three months old
                if (this.issuedOnDate && differenceInMonths(new Date(), this.issuedOnDate) >= 3) {
                    info.push({
                        text: this.$t('documents.message.oldTitleRegister'),
                        icon: '$flag',
                        class: ColorClass.Warning,
                    })
                }

                if (this.isDocumentUnderInvestigation) {
                    info.push({
                        text: this.$t('documents.message.underInvestigation'),
                        icon: '$info',
                        class: ColorClass.Primary,
                    })
                }

                return info
            },

            hasInfoContent() {
                return !isNullOrWhitespace(this.infoContent) && !isNullOrWhitespace(this.computedInfoContent[0]?.text)
            },

            computedInfoContent() {
                return (this.infoContent || []).map((infoContentData) => {
                    return {
                        ...infoContentData,
                        class: infoContentData.class || ColorClass.Warning,
                        icon: infoContentData.icon || '$flag',
                    }
                })
            },

            hasOfficialCopies() {
                return Boolean(this.officialCopies)
            },
        },

        methods: {
            ...mapActions({
                addDocumentToMatter: ADD_DOCUMENT_TO_MATTER,
                addTitleToMatter: MATTER_ADD_TITLE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            ...mapMutations({
                setOrderRegisterLoading: TITLE_MUTATE_REGISTER_ORDER_LOADING,
            }),

            async exportAsPdf() {
                this.isExportPdfLoading = true

                const documentId = this.selectedTitle.record.titleMetadata.registerDocumentId
                await DocumentsApi.downloadDocumentByTypeAndId(HighLevelDocumentType.Register, documentId)

                await this.logHeapEvent({
                    type: 'Register - Export document',
                    metadata: {
                        titleNumber: this.selectedTitleNumber,
                        matterId: this.currentMatterId,
                        docFormat: '.pdf',
                    },
                })

                this.isExportPdfLoading = false
            },

            async refreshRegister() {
                this.setOrderRegisterLoading(true)
                const request = DocumentOrderRequest.createRegisterRequest(this.selectedTitleNumber, this.currentMatterId, true)
                await this.orderDocuments([request])
                await this.addTitleAndDocumentToMatter()
            },

            async viewRegisterDocument() {
                if (this.isReviewAssistantEnabled && this.currentMatterId) {
                    this.$router.push({
                        name: Route.ReviewAssistant,
                        params: {
                            matterId: this.currentMatterId,
                            titleNumber: this.selectedTitleNumber,
                        },
                    })
                } else {
                    const success = await this.addTitleAndDocumentToMatter()
                    if (success) {
                        const documentId = this.registerAvailability.documentId
                        this.$router.push({
                            name: Route.DocumentViewer,
                            params: {
                                documentType: HighLevelDocumentType.Register,
                                documentId,
                            },
                            query: {
                                fromMatterId: this.currentMatterId,
                            },
                        })
                    }
                }
            },

            menuClickHandler(item) {
                item.handler()
            },

            formatDate(value: Date): string {
                const { formatDateShort } = useDates()
                return formatDateShort(value)
            },

            async addTitleAndDocumentToMatter(): Promise<boolean> {
                await this.addTitleToMatter({
                    titleNumber: this.selectedTitleNumber,
                    show: true,
                })
                const documentId = this.registerAvailability.documentId
                if (documentId) {
                    await this.addDocumentToMatter({
                        documentType: HighLevelDocumentType.Register,
                        documentId,
                        matterId: this.currentMatterId,
                    })

                    return true
                }
                console.error('Unable to add document to matter as no documentId in the store', this.registerAvailability)
                return false
            },
        },
    }
</script>

<style lang="scss">
    @import './order-register-card';
</style>
