<template>
    <ow-grid :grid-config="gridConfig"
             :header="header"
             :loading="loading"
             theme="material" />
</template>

<script setup lang="ts">
    import {
        GridConfig,
        Store,
    } from '@bryntum/grid'
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwGrid from '@/components/core/ow-grid/ow-grid.vue'
    import {
        defaultGridConfig,
        useGridConfig,
    } from '@/components/core/ow-grid/use-grid-config'

    import { GridHeader } from '../core/ow-grid'
    import { useNotificationColumns } from './columns'

    const { t } = useI18n()

    const props = defineProps<{
        store: Store
        height?: string
        loading?: boolean
        config?: Partial<GridConfig>
    }>()

    const header = computed<GridHeader>(() => ({
        title: t('searches.notifications.table.header.title', props.store.allRecords.filter(r => r.get('isSubscribedToProviderEmailNotification')).length) as string,
        description: t('searches.notifications.table.header.description') as string,
        showCount: true,
        collapsible: false,
        borderless: false,
        sticky: true,
    }))

    const gridConfig = ref(useGridConfig({
        overrides: {
            ...props.config,
            rowHeight: 45,
            height: props?.height,
            features: {
                ...defaultGridConfig.features,
                cellEdit: true,
            },
        },
        store: props.store,
        columns: useNotificationColumns,
    }))

    watch(() => props.height, (height) => {
        gridConfig.value.height = height
    })
</script>

<style lang="scss">
    @import './matter-searches-notification-table';
</style>
