<template>
    <v-text-field ref="refSearch"
                  v-model.trim="modelValue"
                  class="find-companies-search pt-2"
                  data-test="find-companies-search"
                  hide-details
                  :disabled="isLoading"
                  variant="outlined"
                  @keydown.enter="debouncedSearch">
        <template #prepend-inner>
            <v-icon class="find-companies-search__icon">
                $search
            </v-icon>
        </template>
        <template #append-inner>
            <ow-button class="find-companies-search__button"
                       data-test="find-companies-search-button"
                       full-height
                       :is-loading="isLoading"
                       is-primary
                       :disabled="isLoading || !modelValue"
                       @click="debouncedSearch()">
                {{ $t('action.search') }}
            </ow-button>
        </template>
    </v-text-field>
</template>
<script setup lang="ts">
    import debounce from 'lodash.debounce'
    import {
        onMounted,
        ref,
    } from "vue"

    import OwButton from "@/components/core/ow-button-ds.vue"
    const modelValue = defineModel<string>()

    const refSearch = ref<HTMLInputElement | null>(null)
    onMounted(() => {
        refSearch.value?.focus()
    })

    defineProps<{
        isLoading: boolean
    }>()

    const emit = defineEmits<{
        (e: 'search', value: string)
    }>()

    const debouncedSearch = debounce(() => {
        emit('search', modelValue.value)
    }, 100)
</script>

<style scoped lang="scss">
@import './find-companies-search.scss';
</style>