import i18n from "@/plugins/i18n"
import { isNullOrEmpty } from "@/utils/array-utils"
import { Con29DwcProductConfig } from "../../models/BasketItemCon29DwcModel"
import { HighwaysRaProductConfig } from "../../models/BasketItemHighwaysRaModel"
import { LLC1ProductConfig } from "../../models/BasketItemLLC1Model"
import BasketItemModel from "../../models/BasketItemModel"
import { Con29ProductConfig } from "../../models/BasketItemTMGCon29Model"
import { ArgSSComboProductConfig } from "../../models/BasketItemArgSSComboModel"
import { Colors } from "@/enums/colors.enum"

const collapsedTemplate = (record: BasketItemModel, dirty: boolean) => {
    return /*html*/`
            <div class='${ record.productId }-expand d-flex align-center flex-row py-2 px-1 mb-4 mt-2 w-100' data-id="${ record.id }">
                <a href="#" class='${ record.productId }-link ml-2 accents-link-text'>
                    ${ dirty ? i18n.global.t('searches.productConfig.additionalOptionsSelected') : i18n.global.t('searches.productConfig.additionalOptionsAvailable') }
                    ${ record.errored ? `<span class="mdi mdi-alert" style="color: ${ Colors.Danger }" />` : '' }
                </a>
                <div>
                    <a href="#" class="${ record.productId }-expand accents-link-text expand-link"><span class="py-4 px-4 mdi ${ record.collapsed ? 'mdi-arrow-down' : 'mdi-arrow-up' }"/> </a>
                </div>
            </div>
        `
}

const expandedTemplate = (record: BasketItemModel, expandedMarkup: string, tooltip?: boolean) => {
    return /*html*/`
            <div class='${ record.productId }-expand d-flex align-center flex-row pa-3 mb-4 mt-2 w-100' data-id="${ record.id }">
                <div class="d-flex flex-column w-100">
                    ${ tooltip ? `<span class="caption-italic">${ i18n.global.t('searches.productConfig.additionalOptions') }</span>` : '' }
                    ${ expandedMarkup }
                </div>
                <div>
                    <a href="#" class="${ record.productId }-expand accents-link-text expand-link"><span class="py-4 px-4 mdi ${ record.collapsed ? 'mdi-arrow-down' : 'mdi-arrow-up' }"/> </a>
                </div>
            </div>
        `
}

const fieldTemplate = (i18nKey: string, value?: string, flagged?: 'mandatory' | 'invalid') => /*html*/`
    <li class="caption-italic">
        ${ i18n.global.t(i18nKey, [value], { plural: parseInt(value) }) }
        ${ flagged ? `<span title="${ i18n.global.t(`searches.createOrder.${ flagged }`) }" class="mdi mdi-alert" style="color: ${ Colors.Danger }"/>` : '' }
    </li>`

const editLinkTemplate = (productId: string, optionalText?: string) => /*html*/`
    <div class="flex-shrink-1">
        <a href="#" class="accents-link-text mt-2 ${ productId }-link">${ optionalText ? optionalText : i18n.global.t('searches.productConfig.editConfig') }</a>
    </div>`

const tmgCon29ConfigurableCategoryTemplate = (record: BasketItemModel, tooltip: boolean) => {
    const config = record.config as Con29ProductConfig
    const { roadConfig, standardEnquiries, additionalEnquiries, additionalEnquiriesConfig, additionalQuestionsConfig } = config
    const dirty = standardEnquiries !== true || additionalEnquiries === true || !isNullOrEmpty(roadConfig)
    let standardEnqField = ''
    let enquiriesField = ''
    let roadsField = ''
    let questionsField = ''
    let additionalEnqField = ''

    if (standardEnquiries) {
        standardEnqField = fieldTemplate('searches.productConfig.con29.standardEnquiries.title')

        if (!isNullOrEmpty(roadConfig)) {
            roadsField = fieldTemplate('searches.productConfig.con29.fields.additionalRoads', roadConfig?.length?.toString())
        }
    }

    if (additionalEnquiries) {
        additionalEnqField = fieldTemplate('searches.productConfig.con29.additionalEnquiries.title')
        if (!isNullOrEmpty(additionalQuestionsConfig)) {
            questionsField = fieldTemplate('searches.productConfig.con29.fields.additionalQuestions', additionalQuestionsConfig?.length?.toString())
        }
        if (!isNullOrEmpty(additionalEnquiriesConfig)) {
            const invalid = record.errored ? 'invalid' : undefined
            enquiriesField = fieldTemplate('searches.productConfig.con29.fields.additionalEnquiries', additionalEnquiriesConfig?.length?.toString(), invalid)
        }
    }

    if (record.collapsed && !tooltip) {
        return collapsedTemplate(record, dirty)
    }
    return expandedTemplate(record, /*html*/`
                        <span class="caption-italic">Included:</span>
                        <ul class="d-flex flex-column ml-4">
                            ${ standardEnqField }
                            <ul class="ml-6">
                                ${ roadsField }
                            </ul>
                            ${ additionalEnqField }
                            <ul class="ml-6">
                                ${ enquiriesField }
                                ${ questionsField }
                            </ul>
                        </ul>
                        ${ !tooltip ? editLinkTemplate(record.productId, config.additionalEnquiries ? i18n.global.t('searches.productConfig.editConfig') : i18n.global.t('searches.productConfig.addOptionalEnquiries')) : '' }`, tooltip)
}

const con29DwcConfigurableCategoryTemplate = (record: BasketItemModel, tooltip: boolean) => {
    const config = record.config as Con29DwcProductConfig
    const { lessThan10Years, expedited, developerName, siteNameAndPhase, offSiteRoadName } = config
    const dirty = config?.lessThan10Years !== false || config?.expedited !== 'Normal'

    let lessThan10YearsField = ''
    let developerNameField = ''
    let siteNameAndPhaseField = ''
    let offSiteRoadNameField = ''
    let expeditedField = ''

    lessThan10YearsField = fieldTemplate('searches.productConfig.con29Dwc.fields.lessThan10Years', lessThan10Years ? 'Yes' : 'No')
    if (lessThan10Years) {
        developerNameField = fieldTemplate('searches.productConfig.con29Dwc.fields.developerName', developerName)
        siteNameAndPhaseField = fieldTemplate('searches.productConfig.con29Dwc.fields.siteNameAndPhase', siteNameAndPhase)
        offSiteRoadNameField = fieldTemplate('searches.productConfig.con29Dwc.fields.offSiteRoadName', offSiteRoadName)
    }
    expeditedField = fieldTemplate('searches.productConfig.con29Dwc.fields.expedited', expedited)

    if (record.collapsed && !tooltip) {
        return collapsedTemplate(record, dirty)
    }
    return expandedTemplate(record, /*html*/`
                        <ul class="ml-4">
                            ${ lessThan10YearsField }
                            <ul class="ml-6">
                                ${ developerNameField }
                                ${ siteNameAndPhaseField }
                                ${ offSiteRoadNameField }
                            </ul>
                            ${ expeditedField }
                        </ul>
                        ${ !tooltip ? editLinkTemplate(record.productId) : '' }`, tooltip)
}

const tmgLLC1ConfigurableCategoryTemplate = (record: BasketItemModel, tooltip: boolean) => {
    const config = record.config as LLC1ProductConfig
    const { numberOfParcels } = config
    const dirty = config?.numberOfParcels !== 1

    let numberOfParcelsField = ''

    numberOfParcelsField = fieldTemplate('searches.productConfig.tmgLLC1.fields.numberOfParcels', numberOfParcels?.toString())

    if (record.collapsed && !tooltip) {
        return collapsedTemplate(record, dirty)
    }
    return expandedTemplate(record, /*html*/`
                        <ul class="ml-4">
                            ${ numberOfParcelsField }
                        </ul>
                        ${ !tooltip ? editLinkTemplate(record.productId) : '' }`, tooltip)
}

const highwaysRaConfigurableCategoryTemplate = (record: BasketItemModel, tooltip: boolean) => {
    const config = record.config as HighwaysRaProductConfig
    const { searchPurpose } = config
    const dirty = config?.searchPurpose !== ''

    let searchPurposeField = ''

    searchPurposeField = fieldTemplate('searches.productConfig.highwaysRa.fields.searchPurpose', searchPurpose?.toString())

    if (record.collapsed && !tooltip) {
        return collapsedTemplate(record, dirty)
    }
    return expandedTemplate(record, /*html*/`
                        <ul class="ml-4">
                            ${ searchPurposeField }
                        </ul>
                        ${ !tooltip ? editLinkTemplate(record.productId) : '' }`, tooltip)
}

const argSSComboConfigurableCategoryTemplate = (record: BasketItemModel, tooltip: boolean) => {
    const config = record.config as ArgSSComboProductConfig
    const { currentSiteUse, proposedSiteUse, yearsInCurrentUse, additionalConsiderations, plannedDevelopment } = config
    const dirty = false

    let currentSiteUseField = ''
    let proposedSiteUseField = ''
    let yearsInCurrentUseField = ''
    let additionalConsiderationsField = ''
    let plannedDevelopmentField = ''

    const mandatory = !record.valid ? 'mandatory' : undefined
    currentSiteUseField = fieldTemplate('searches.productConfig.argSSCombo.fields.currentSiteUseField', currentSiteUse, mandatory)
    proposedSiteUseField = fieldTemplate('searches.productConfig.argSSCombo.fields.proposedSiteUseField', proposedSiteUse, mandatory)
    yearsInCurrentUseField = fieldTemplate('searches.productConfig.argSSCombo.fields.yearsInCurrentUseField', yearsInCurrentUse?.toString(), mandatory)
    plannedDevelopmentField = fieldTemplate('searches.productConfig.argSSCombo.fields.plannedDevelopmentField', plannedDevelopment ? 'Yes' : 'No')
    if (additionalConsiderations) {
        additionalConsiderationsField = fieldTemplate('searches.productConfig.argSSCombo.fields.additionalConsiderationsField', additionalConsiderations)
    }
    if (record.collapsed && !tooltip) {
        return collapsedTemplate(record, dirty)
    }
    return expandedTemplate(record, /*html*/`
                        <ul class="ml-4">
                            ${ currentSiteUseField }
                            ${ proposedSiteUseField }
                            ${ yearsInCurrentUseField }
                            ${ additionalConsiderationsField }
                            ${ plannedDevelopmentField }
                        </ul>
                        ${ !tooltip ? editLinkTemplate(record.productId) : '' }`, tooltip)
}

export const configurableCategoryTemplate = (record: BasketItemModel, tooltip?: boolean) => {
    if (!record.isConfigurable) {
        return ''
    }

    switch (record.productId) {
        case 'TMGCon29':
            return tmgCon29ConfigurableCategoryTemplate(record, tooltip)
        case 'Con29DWC':
            return con29DwcConfigurableCategoryTemplate(record, tooltip)
        case 'TMGLLC1':
            return tmgLLC1ConfigurableCategoryTemplate(record, tooltip)
        case 'Highways':
        case 'HighwaysRA':
            return highwaysRaConfigurableCategoryTemplate(record, tooltip)
        case 'ArgSSCombo':
            return argSSComboConfigurableCategoryTemplate(record, tooltip)
        default:
            return ''
    }
}
