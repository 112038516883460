export const PlanningDecision = {
    OTHER: 0,
    PERMITTED: 1,
    REJECTED: 2,
}

export const PlanningDecisionText = {
    0: 'Other',
    1: 'Permitted',
    2: 'Rejected',
}

export const PlanningType = {
    FULL: 1,
    OTHER: 0,
}

export const PlanningColours = {
    decision: [
        '#DF8413', // other
        '#008456', // permitted
        '#E41A0C', // rejected
    ],

    distance: '#000000',
}

export const planningSortingOptions = {
    DISTANCE_ASC: '1',
    DISTANCE_DESC: '2',
    DATE_RECEIVED_ASC: '3',
    DATE_RECEIVED_DESC: '4',
}

export const planningSortingOptionsNames = {
    1: 'Distance (closest to furthest)',
    2: 'Distance (furthest to closest)',
    3: 'Date (oldest to newest)',
    4: 'Date (newest to oldest)',
}
