<template>
    <div class="epc-chart"
         :class="{ 'has-potential-column': potentialEnergyBand && potentialEnergyRating, [`divider-position-${dividerPosition}`]: true }">
        <div class="epc-chart__top">
            <div class="epc-chart__top-left">
                <div class="epc-chart__top-left-title">
                    Energy Efficiency Rating
                </div>
                <slot name="aboveDividerBands" />
            </div>
            <div class="epc-chart__top-right">
                <div class="epc-chart__top-right-current">
                    Current
                </div>
                <div v-if="potentialEnergyBand && potentialEnergyRating"
                     class="epc-chart__top-right-potential">
                    Potential
                </div>
            </div>
        </div>
        <epc-divider :text="dividerText" />
        <div class="epc-chart__bottom">
            <slot name="belowDividerBands" />
        </div>
        <ow-coloured-chevron-label class="epc-chart__chevron-label--current"
                                   :label="`${energyBand}|${energyRating}`"
                                   data-test="epc-chart-chevron-label"
                                   is-large
                                   :theme="getThemeForEnergyBand(energyBand)" />
        <ow-coloured-chevron-label v-if="potentialEnergyBand && potentialEnergyRating"
                                   class="epc-chart__chevron-label--potential"
                                   data-test="epc-chart-chevron-label-potential"
                                   :label="`${potentialEnergyBand}|${potentialEnergyRating}`"
                                   is-large
                                   :theme="getThemeForEnergyBand(potentialEnergyBand)" />
    </div>
</template>

<script lang="ts">
    import OwColouredChevronLabel from '@/components/core/ow-coloured-chevron-label.vue'
    import EpcDivider from '@/components/epc/epc-divider.vue'
    import { EnergyBand } from '@/enums/epc.enum'
    import { getThemeForEnergyBand } from '@/models/epc.model'

    export default {
        name: 'EpcChart',

        components: {
            EpcDivider,
            OwColouredChevronLabel,
        },

        props: {
            energyBand: {
                type: String,
                required: true,
            },
            energyRating: {
                type: Number,
                required: true,
            },
            potentialEnergyBand: {
                type: String,
                required: false,
                default: null,
            },
            potentialEnergyRating: {
                type: Number,
                required: false,
                default: null,
            },
            dividerText: {
                type: String,
                required: true,
            },
            dividerPosition: {
                type: String,
                required: false,
                default: 'e-f',
                validator(value: string) {
                    return ['d-e', 'e-f'].includes(value)
                },
            },
        },

        methods: {
            getThemeForEnergyBand(energyBand: EnergyBand) {
                return getThemeForEnergyBand(energyBand)
            },
        },
    }
</script>

<style lang="scss">
    @import './epc-chart.scss';
</style>
