<template>
    <ow-loading-skeleton v-if="isLoading"
                         class="side-nav-section d-flex align-center px-2 w-75 mb-1" />
    <button v-else
            tabindex="0"
            class="side-nav-section d-flex align-center px-2"
            :class="{
                'side-nav-section--selected': selected
            }"
            :title="title"
            @click="emit('click')">
        <span class="side-nav-section__text caption-highlight text-no-wrap">{{ title }}</span>
    </button>
</template>

<script setup lang="ts">

    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"

    withDefaults(defineProps<{
        title: string
        selected?: boolean
        isLoading?: boolean
    }>(), {
        selected: false,
        isLoading: false,
    })

    const emit = defineEmits<{
        (e: 'click'): void,
    }>()
</script>

<style scoped lang="scss">
@import './side-nav-section';
</style>
