<template>
    <div class="checkout-process-order">
        <ow-card :has-outline="true"
                 class="checkout-process-order__progress">
            <div v-if="success"
                 class="checkout-process-order__progress--content">
                <v-icon class="status-icon"
                        color="success">
                    $check
                </v-icon>
                <div>
                    <h2 v-t="'checkout.message.orderComplete'"
                        data-test="checkout-process-order-completed-heading" />
                    <div v-t="'checkout.message.orderReadyToDownload'"
                         class="body-copy" />
                </div>
                <a :download="lastDownloadFilename"
                   :href="lastDownloadURL"
                   class="checkout-process-order__download-button"
                   data-test="checkout-process-order-download-button"
                   data-track="CHECKOUT - download">
                    <ow-button :outlined="true"
                               @click="onDownloadClick">
                        {{ $t('action.download') }}
                    </ow-button>
                </a>
            </div>

            <div v-else-if="!isOk"
                 class="checkout-process-order__progress--content">
                <v-icon class="status-icon"
                        color="error">
                    $close
                </v-icon>
                <div>
                    <h2 v-t="'message.errorOccurred'" />
                    <div class="body-copy">
                        {{ errorMessage }}
                    </div>
                </div>
                <ow-button class="checkout-process-order__download-button"
                           @click="retryOrder">
                    {{ $t('action.tryAgain') }}
                </ow-button>
            </div>

            <div v-else
                 class="checkout-process-order__progress--content">
                <v-progress-circular indeterminate />
                <div>
                    <h2>{{ $t('checkout.message.processingOrder') }}...</h2>
                    <div>
                        <strong>Step {{ currentStep.step }}/{{ totalSteps }}</strong>
                        {{ currentStep.stepName }}
                    </div>
                </div>
            </div>
        </ow-card>

        <v-divider class="mb-4" />
        <h2 v-if="success || !isOk"
            v-t="'message.nextSteps'"
            class="checkout-process-order__title" />

        <template v-if="success">
            <div v-t="'report.action.generateReport'"
                 class="checkout-process-order__next-steps--subtitle body-copy" />
            <div class="checkout-process-order__next-steps--templates d-flex justify-space-between mb-4">
                <template-card v-for="(template, index) in templates"
                               :key="index"
                               :data-track="`REPORTING - Select template ${ template.name }`"
                               :is-secondary-button="true"
                               :template="template"
                               :thumbnail="true"
                               class="checkout-process-order__next-steps--template"
                               data-test="reporting-template-card"
                               @export="handleExportSelected" />
            </div>
        </template>

        <template v-if="success || !isOk">
            <div v-t="'checkout.message.orderDocument'"
                 class="checkout-process-order__next-steps--subtitle body-copy" />
            <ow-action-card :svg-icon="Icons.HMLR_ICON"
                            class="checkout-process-order__order-document"
                            subtitle="Order official copies quickly"
                            title="Order title registers">
                <title-search-box data-test="homepage-title-search-box"
                                  show-single-result />
            </ow-action-card>
        </template>
    </div>
</template>

<script lang="ts">
    import { saveAs } from 'file-saver'
    import { PropType } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OwActionCard from '@/components/core/ow-action-card.vue'
    import OwButton from '@/components/core/ow-button.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import TemplateCard from '@/components/reporting/template-card.vue'
    import TitleSearchBox from '@/components/search/title-search-box.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import { DocumentType } from '@/consts/document-type'
    import { Icons } from '@/consts/icons'
    import { MatterTier } from '@/consts/matter-tier'
    import { CheckoutItemType } from '@/enums/checkout-item-type'
    import {MAIN_API_REPORT_ENUM} from "@/enums/reporting-api.enum"
    import { Route } from '@/enums/route.enum'
    import { IBasicMatter } from '@/interfaces/basic-matter.interface'
    import { ICheckoutItemBase } from '@/interfaces/checkout/checkout-item-base.interface'
    import { ICheckoutItemDocumentOrder } from '@/interfaces/checkout/checkout-item-document-order.interface'
    import { ICheckoutItemReportOrder } from '@/interfaces/checkout/checkout-item-report-order.interface'
    import { IReportDownloadItem } from '@/interfaces/checkout/report-download-item.interface'
    import { ICheckoutState } from '@/interfaces/store/checkout/checkout-state.interface'
    import { IOrganisationHubMessage } from '@/interfaces/store/organisation-hub/organisation-hub-message.interface'
    import {
        TrackedTitle,
        TrackedTitleListener,
    } from '@/interfaces/store/organisation-hub/tracked-title'
    import { CheckoutItemDocumentOrder } from '@/models/checkout/checkout-item-document-order.model'
    import { CheckoutItemReportOrder } from '@/models/checkout/checkout-item-report-order.model'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { CLEAR_CART } from '@/store/modules/checkout/types'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import {
        GENERATE_DOCX_AND_DOWNLOAD,
        GENERATE_REPORT_DOCX_AND_DOWNLOAD,
    } from '@/store/modules/documents/documents-types'
    import {
        MATTER_ADD_MULTIPLE_TITLES,
        MATTER_CREATE,
        MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
    } from '@/store/modules/matter/types'
    import {
        ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER_AND_STATUS,
    } from '@/store/modules/organisation-hub/types'
    import {
        GET_EXPORT_OPTIONS,
        LOAD_EXPORT_OPTIONS,
        MUTATE_SELECTED_EXPORT_OPTION,
    } from '@/store/modules/reports/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
    } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export default {
        name: 'CheckoutProcessOrder',

        components: {
            OwActionCard,
            OwButton,
            OwCard,
            TemplateCard,
            TitleSearchBox,
        },

        props: {
            items: {
                type: Array as PropType<ICheckoutItemBase[]>,
                required: true,
            },
            basicMatterCodes: {
                type: Object as PropType<IBasicMatter>,
                required: true,
            },
        },

        emits: [
            'matter-created',
        ],

        data() {
            return {
                totalSteps: 0,
                currentStep: {},
                errorMessage: '',
                isOk: true,
                matter: null,
                downloadedDocumentIds: [],
                Icons,
                allReportsGenerated: false,
            }
        },

        computed: {
            ...mapState({
                lastDownloadURL: (state: any) => state.documents.lastDownloadURL,
                lastDownloadFilename: (state: any) => state.documents.lastDownloadFilename,
                disabledReportingFeatures: (state: any) => state.reports.disabledFeatures,
            }),

            ...mapState('checkout', {
                downloadedReports: (state: ICheckoutState) => state.downloadedReports,
            }),

            ...mapGetters('reports', {
                exportOptions: GET_EXPORT_OPTIONS,
            }),

            ...mapGetters('organisationHub', {
                getTrackedTitlesByStatus: ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER_AND_STATUS,
            }),

            templates() {
                return this.exportOptions.filter(template => template.is_available && -1).slice(0, 4)
            },

            titleNumbers() {
                return this.items
                    .filter((item: ICheckoutItemBase) => item.itemType === CheckoutItemType.Document)
                    .map((item: ICheckoutItemDocumentOrder) => item.title.titleNumber)
            },

            titleNumbersFromReports() {
                return this.reportsToOrder.reduce((arr, order) => arr.concat(order.selectedTitleNumbers), [])
            },

            documentsToOrder(): CheckoutItemDocumentOrder[] {
                return this.items.filter((item: ICheckoutItemBase) => item.itemType === CheckoutItemType.Document)
            },

            reportsToOrder(): CheckoutItemReportOrder[] {
                return this.items.filter((item: ICheckoutItemBase) => item.itemType === CheckoutItemType.Report)
            },

            hasDocumentsToOrder(): boolean {
                return !isNullOrEmpty(this.documentsToOrder)
            },

            hasReportsToGenerate(): boolean {
                return !isNullOrEmpty(this.reportsToOrder)
            },

            orderedDocumentIds(): number[] {
                const trackedTitlesWithOrderedStatus: TrackedTitle[] = this.getTrackedTitlesByStatus(TrackedTitleListener.Checkout, DocumentOrderStatus.ORDERED)
                const trackedTitleOrderedStatusUpdate: IOrganisationHubMessage[] = trackedTitlesWithOrderedStatus
                    .map((title: TrackedTitle) => {
                        return title.updates.find((update: IOrganisationHubMessage) => update.message.status === DocumentOrderStatus.ORDERED)
                    })
                return trackedTitleOrderedStatusUpdate.map((update: IOrganisationHubMessage) => update.message.notificationData.documentId)
            },

            derivedDocuments(): IOrganisationHubMessage[] {
                const derivedTitles: TrackedTitle[] = this.getTrackedTitlesByStatus(TrackedTitleListener.Checkout, DocumentOrderStatus.DERIVED)
                return derivedTitles
                    .map((title: TrackedTitle) =>
                        title.updates.find((update: IOrganisationHubMessage) => update.message.status === DocumentOrderStatus.DERIVED))
            },

            success(): boolean {
                if ((!isNullOrEmpty(this.downloadedDocumentIds) &&
                    this.orderedDocumentIds.length === this.downloadedDocumentIds.length) &&
                    !this.hasReportsToGenerate) {
                    return true
                }

                if (this.hasReportsToGenerate && this.allReportsGenerated) {
                    return true
                }

                return false
            },
        },

        watch: {
            'orderedDocumentIds.length'() {
                const docIdsToDownload = this.orderedDocumentIds.filter((id: number) => !this.downloadedDocumentIds.includes(id))
                docIdsToDownload.forEach((id: number) => {
                    this.downloadDocument(id, HighLevelDocumentType.Register)
                    this.downloadedDocumentIds.push(id)
                })
            },

            async 'derivedDocuments.length'() {
                if (this.derivedDocuments.length === this.documentsToOrder.length &&
                    this.isOk &&
                    this.hasReportsToGenerate) {
                    await this.generateReports()
                }
            },

            success() {
                this.logCheckoutCompleted()
            },
        },

        async mounted() {
            this.loadExportOptions()
            await this.processCart()
        },

        beforeUnmount() {
            this.clearCart()
        },

        methods: {
            ...mapActions({
                addMultipleTitles: MATTER_ADD_MULTIPLE_TITLES,
                createMatter: MATTER_CREATE,
                generateDocxAndDownload: GENERATE_DOCX_AND_DOWNLOAD,
                generateReportDocxAndDownload: GENERATE_REPORT_DOCX_AND_DOWNLOAD,
                exportTitleAnalysisReport: MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapActions('reports', {
                loadExportOptions: LOAD_EXPORT_OPTIONS,
            }),

            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            ...mapActions('checkout', {
                clearCart: CLEAR_CART,
            }),

            ...mapMutations('reports', {
                setSelectedExportOption: MUTATE_SELECTED_EXPORT_OPTION,
            }),

            async processCart(): Promise<void> {
                // Set any docs that may have already been downloaded
                this.downloadedDocumentIds = this.orderedDocumentIds

                this.totalSteps = this.hasDocumentsToOrder && this.hasReportsToGenerate ? 3 : 2
                this.currentStep = {
                    step: 1,
                    title: `${ this.$t('checkout.message.processingOrder') }...`,
                }

                await this.createBasicMatter()
                if (this.isOk) {
                    await this.addTitlesToMatter()
                }

                if (this.isOk && this.hasDocumentsToOrder) {
                    await this.processDocumentOrdering()
                } else if (this.isOk && this.hasReportsToGenerate) {
                    await this.generateReports()
                }
            },

            async createBasicMatter(): Promise<void> {
                try {
                    this.matter = await this.createMatter({
                        name: `Quick purchase - ${ new Date().toISOString() }`,
                        code: this.basicMatterCodes.matterCode,
                        clientCode: this.basicMatterCodes.clientCode,
                        tier: MatterTier.Basic,
                        type: 'Other',
                    })
                    this.$emit('matter-created', this.matter.id)
                    await this.logHeapEvent({
                        type: 'CHECKOUT - Basic matter created',
                        metadata: {
                            ...this.matter,
                            hasReports: this.hasReportsToGenerate,
                            hasDocuments: this.hasDocumentsToOrder,
                        },
                    })
                } catch (e) {
                    this.isOk = false
                    this.errorMessage = e.message
                    this.logFeatureUsage({ type: 'checkout_error_basic_matter' })
                    this.logHeapEvent({
                        type: 'CHECKOUT - Error - Basic matter',
                        metadata: {
                            error: this.$t('matter.error.matterCreate', { message: e.message }),
                            hasReports: this.hasReportsToGenerate,
                            hasDocuments: this.hasDocumentsToOrder,
                        },
                    })
                }
            },

            async addTitlesToMatter(): Promise<void> {
                const titlesToAdd = this.hasReportsToGenerate ? this.titleNumbersFromReports : this.titleNumbers
                try {
                    this.addMultipleTitles({
                        matterId: this.matter.id,
                        titleNumbers: titlesToAdd,
                        showPopup: false,
                    })
                } catch (e) {
                    this.isOk = false
                    this.errorMessage = e.message
                    this.logFeatureUsage({ type: 'checkout_error_add_titles_to_matter' })
                    this.logHeapEvent({
                        type: 'CHECKOUT - Error - Add titles to matter',
                        metadata: {
                            error: this.$t('matter.error.addTitlesToMatter', { message: e.message }),
                            hasReports: this.hasReportsToGenerate,
                            hasDocuments: this.hasDocumentsToOrder,
                        },
                    })
                }
            },

            async processDocumentOrdering(): Promise<void> {
                this.currentStep = {
                    step: this.currentStep.step + 1,
                    stepName: `${ this.$t('checkout.message.processingDocuments') }...`,
                }

                // Documents may have been downloaded by now, so check if any are already ordered and we don't
                // have to order them again.
                this.downloadedDocumentIds.forEach(docId => {
                    this.downloadDocument(docId, HighLevelDocumentType.Register)
                })

                const orderRequests: DocumentOrderRequest[] = this.items
                    .filter((cartItem: ICheckoutItemBase) => cartItem.itemType === CheckoutItemType.Document)
                    .filter((cartItem: ICheckoutItemDocumentOrder) => cartItem.document.request.documentType === DocumentType.REGISTER)
                    .map((documentOrder: ICheckoutItemDocumentOrder) => {
                        return DocumentOrderRequest.createRegisterRequest(
                            documentOrder.document.request.titleNumber,
                            this.matter.id,
                            documentOrder.document.request.forceOrder)
                    })

                try {
                    await this.orderDocuments(orderRequests)
                } catch (e) {
                    this.isOk = false
                    this.errorMessage = e.message
                    this.logFeatureUsage({ type: 'checkout_error_document_ordering' })
                    this.logHeapEvent({
                        type: 'CHECKOUT - Error - Document Ordering',
                        metadata: {
                            error: `${ this.$t('documents.error.orderDocuments') }...`,
                            keyValues: orderRequests
                                .map(request => request.keyValue)
                                .join(', '),
                            hasReports: this.hasReportsToGenerate,
                            hasDocuments: this.hasDocumentsToOrder,
                        },
                    })
                }
            },

            async generateReports(): Promise<void> {
                this.currentStep = {
                    step: this.currentStep.step + 1,
                    stepName: `${ this.$t('report.message.generatingReport') }...`,
                }

                let countOfGeneratedReports = 0
                const reportRequests: ICheckoutItemReportOrder[] = this.items
                    .filter((cartItem: ICheckoutItemBase) => cartItem.itemType === CheckoutItemType.Report)
                if (isNullOrEmpty(reportRequests) ||
                    reportRequests.some((report: ICheckoutItemReportOrder) => isNullOrEmpty(report.selectedTitleNumbers))) {
                    throw new Error('report.error.processReports')
                }

                for (const reportRequest of reportRequests) {
                    try {
                        switch (reportRequest?.template?.reportingAPIEnum) {
                            case (MAIN_API_REPORT_ENUM.TAR): {
                                await this.logHeapEvent({
                                    type: 'Title Analysis Report',
                                    metadata: {
                                        matterId: this.matter?.id,
                                        titleNumberCount: reportRequest.selectedTitleNumbers.length,
                                        location: "CHECKOUT",
                                    },
                                })
                                await this.exportTitleAnalysisReport({
                                    titleNumbers: reportRequest.selectedTitleNumbers,
                                    generatedMatterId: this.matter?.id,
                                })
                                break
                            }
                            default: {
                                if (reportRequest?.template?.template) {
                                    await this.generateReportDocxAndDownload({
                                        template: reportRequest.template,
                                        matterId: this.matter?.id,
                                        titleNumbers: reportRequest.selectedTitleNumbers,
                                        disabledFeatures: this.disabledReportingFeatures,
                                    })
                                } else {
                                    await this.generateDocxAndDownload(reportRequest.selectedTitleNumbers)
                                }
                            }
                        }

                        this.logHeapEvent({
                            type: 'CHECKOUT - Export title register reports',
                            metadata: {
                                matterId: this.matter.id,
                                matterName: this.matter.name,
                                titleNumber: reportRequest.selectedTitleNumbers.join(', '),
                                template: reportRequest.template,
                                hasReports: this.hasReportsToGenerate,
                                hasDocuments: this.hasDocumentsToOrder,
                            },
                        })

                        countOfGeneratedReports++
                        if (countOfGeneratedReports === reportRequests.length) {
                            this.allReportsGenerated = true
                        }
                    } catch (e) {
                        this.isOk = false
                        this.errorMessage = e.message
                        this.logFeatureUsage({ type: 'checkout_error_generate_report' })
                        this.logHeapEvent({
                            type: 'CHECKOUT - Error - Generate Report',
                            metadata: {
                                error: this.$t('report.error.generateReport', { message: e.message }),
                                matterId: this.matter.id,
                                matterName: this.matter.name,
                                titleNumber: reportRequest.selectedTitleNumbers.join(', '),
                                template: reportRequest.template,
                                hasReports: this.hasReportsToGenerate,
                                hasDocuments: this.hasDocumentsToOrder,
                            },
                        })
                    }
                }
            },

            retryOrder(): void {
                this.logHeapEvent({
                    type: 'CHECKOUT - retry order',
                    metadata: {
                        hasReports: this.hasReportsToGenerate,
                        hasDocuments: this.hasDocumentsToOrder,
                    },
                })
                this.reset()
                this.processCart()
            },

            reset(): void {
                this.isOk = true
                this.errorMessage = ''
                this.downloadedDocumentIds = []
            },

            async handleExportSelected(template) {
                if (template) {
                    this.setSelectedExportOption(template)
                }
                await this.$router.push({ name: Route.ReportsSelectTitles })
            },

            async downloadDocument(documentId: number, documentType: string): Promise<void> {
                await DocumentsApi.downloadDocumentByTypeAndId(documentType, documentId)
            },

            logCheckoutCompleted(): void {
                this.logFeatureUsage({ type: 'checkout_success' })
                this.logHeapEvent({
                    type: 'CHECKOUT - success',
                    metadata: {
                        hasReports: this.hasReportsToGenerate,
                        hasDocuments: this.hasDocumentsToOrder,
                    },
                })
            },

            onDownloadClick(): void {
                this.downloadedDocumentIds.forEach(docId => {
                    this.downloadDocument(docId, HighLevelDocumentType.Register)
                })

                this.downloadedReports.forEach((report: IReportDownloadItem) => {
                    saveAs(report.blobDownloadUrl, report.filename)
                })

                this.logHeapEvent({
                    type: 'CHECKOUT - download',
                    metadata: {
                        hasReports: this.hasReportsToGenerate,
                        hasDocuments: this.hasDocumentsToOrder,
                    },
                })
            },
        },
    }
</script>

<style lang="scss">
    @import 'checkout-process-order';
</style>
