<template>
    <ow-modal v-model="promptUpdate.show"
              content-class="dialog-group"
              :title="$t('matter.prompts.updateGroup.title')"
              persistent>
        <div class="dialog-group__content">
            <span class="body-highlight">
                {{ selectedGroupName }}
            </span>
            <ow-textfield ref="textfield"
                          v-model="textInput"
                          data-test="matter-group-dialogs-group-update-name"
                          label="Rename group to" />
        </div>
        <template #actions>
            <div class="dialog-group__actions">
                <ow-button data-test="matter-group-dialogs-button-update-group-cancel"
                           @click="handleCancel">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button data-test="matter-group-dialogs-button-delete"
                           is-secondary
                           @click="handleDelete">
                    {{ $t('matter.prompts.deleteGroup.title') }}
                </ow-button>
                <ow-button data-test="matter-group-dialogs-button-update-group"
                           is-primary
                           @click="handleUpdate">
                    {{ $t('action.update') }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script lang="ts">
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { IState } from '@/interfaces/store/state.interface'
    import { MATTER_UPDATE_GROUP } from '@/store/modules/matter/types'

    export default {
        name: 'DialogGroupUpdate',
        components: {
            OwButton,
            OwModal,
            OwTextfield,
        },
        data() {
            return {
                textInput: '',
            }
        },
        computed: {
            ...mapState({
                promptUpdate: (state: IState) => state.matter.prompts.updateGroup,
                promptDelete: (state: IState) => state.matter.prompts.deleteGroup,
            }),
            selectedGroupName() {
                return this.promptUpdate.group?.name ?? ''
            },
        },
        watch: {
            'promptUpdate.show'(value) {
                if (value) {
                    this.textInput = this.promptUpdate.group?.name ?? ''
                    this.$nextTick(() => this.$refs.textfield?.focus())
                }
            },
        },
        methods: {
            ...mapActions({
                updateGroup: MATTER_UPDATE_GROUP,
            }),

            async handleUpdate() {
                this.promptUpdate.group.name = this.textInput
                await this.updateGroup(this.promptUpdate.group)
                this.promptUpdate.show = false
                this.promptUpdate.group = null
            },

            handleDelete() {
                this.promptUpdate.show = false
                this.promptDelete.group = this.promptUpdate.group
                this.promptUpdate.group = null
                this.promptDelete.show = true
            },

            handleCancel() {
                this.promptUpdate.show = false
                this.promptUpdate.group = null
            },
        },
    }
</script>

<style lang="scss">
    @import './dialog-group';
</style>
