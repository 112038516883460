<template>
    <div v-if="show"
         class="matter-searches-select-product-step d-flex flex-column">
        <matter-searches-error-dialog @retry="initialise" />
        <matter-searches-order-details :key="orderDetailsKey"
                                       :title-numbers="selectedTitleNumbers"
                                       :address="structuredAddress"
                                       :map-options="mapOptions"
                                       :geo-json="geoJson"
                                       :hazards="hazards"
                                       :provider="providers[filter.searchProviderId]?.name"
                                       :show-edit-button="!isDraftOrder"
                                       @map-edit="onMapEdit" />
        <matter-searches-filter :providers="providers"
                                :filter="filter"
                                :loading="loading"
                                :basket-calculating="basketCalculating"
                                :product-preferences="productPreferences"
                                :show-search-provider="false"
                                :show-search-filter="false"
                                :show-product-preference="!isDraftOrder"
                                @filter="onFilter">
            <ow-alert v-if="hazards?.length"
                      type="info"
                      class="d-flex align-center matter-searches-select-product-step__hazards">
                <div class="d-inline-flex flex-no-wrap align-center">
                    <label class="caption-regular mr-2 text-no-wrap"> {{ `${providers[filter.searchProviderId]?.name} recommends you also add a product for ` }}</label>
                    <div v-dompurify-html="hazardMarkup"
                         class="hazards" />
                </div>
            </ow-alert>
        </matter-searches-filter>
        <matters-searches-select-product-skeleton v-if="loading" />
        <div v-else>
            <div ref="basketElement"
                 class="matter-searches-select-product-step__grid d-flex flex-column mb-4">
                <matter-searches-basket-table :store="basket"
                                              :loading="loading || basketCalculating"
                                              :config="{
                                                  hideHeaders: false,
                                                  emptyText: $t('searches.noProductsSelected')
                                              }"
                                              @remove="onRemoveFromBasket"
                                              @swap="onSwapBasketItem"
                                              @clear="onClearBasket"
                                              @click-link="onClickProductLink"
                                              @expand-collapse="onExpandCollapse" />
            </div>

            <div class="matter-searches-select-product-step__grid d-flex flex-column mb-4">
                <matter-searches-category-table :store="recommendedCategories"
                                                :collapsed="recommendedCategoriesCollapsed"
                                                collapsible
                                                :filter="filter"
                                                :config="{
                                                    hideHeaders: true,
                                                    emptyText: $t('searches.noProductsAvailable')
                                                }"
                                                @add="onAddToBasket"
                                                @swap="onSwapCategoryItem"
                                                @collapse="recommendedCategoriesCollapsed = !recommendedCategoriesCollapsed" />
            </div>


            <div class="matter-searches-select-product-step__grid d-flex flex-column">
                <matter-searches-other-table :store="otherProducts"
                                             :collapsed="otherProductsCollapsed"
                                             collapsible
                                             :filter="filter"
                                             :config="{
                                                 hideHeaders: true,
                                                 emptyText: $t('searches.noProductsAvailable')
                                             }"
                                             @add="onAddProductToBasket"
                                             @collapse="onOtherTableCollapse">
                    <template #header>
                        <div ref="searchTextElement"
                             class="matter-searches-select-product-step__filter">
                            <ow-text-field v-model="searchText"
                                           class="pl-4 pr-6"
                                           borderless
                                           :max-length="1000"
                                           :placeholder="$t('searches.filterProducts').toString()"
                                           small
                                           clearable
                                           @click:clear="searchText = ''">
                                <template #iconPrefix>
                                    <v-icon>$search</v-icon>
                                </template>
                            </ow-text-field>
                        </div>
                    </template>
                </matter-searches-other-table>
            </div>
        </div>
        <dialog-select-searches-product v-if="showSelectProductDialog"
                                        :selected-category="selectedCategory"
                                        :selected-basket-item="selectedBasketItem"
                                        :categories="availableCategories"
                                        :multi-select="multiSelectProducts"
                                        :filter="filter"
                                        @add="onAddProductsToBasket"
                                        @close="onDialogClose" />

        <matter-searches-con29-product-config-dialog />
        <matter-searches-con29-dwc-product-config-dialog />
        <matter-searches-tmg-llc1-product-config-dialog />
        <matter-searches-highways-ra-product-config-dialog />
        <matter-searches-arg-ss-combo-product-config-dialog />
    </div>
</template>

<script setup lang="ts">
    import { Store } from '@bryntum/grid'
    import {
        computed,
        onMounted,
        reactive,
        ref,
        watch,
        WritableComputedRef,
    } from 'vue'
    import { nextTick } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {              useRoute,
                          useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import { StructuredAddress } from '@/api/searches.api'
    import OwAlert from '@/components/core/ow-alert.vue'
    import owAlert from '@/components/core/ow-alert.vue'
    import OwTextField from '@/components/core/ow-textfield.vue'
    import DialogSelectSearchesProduct from '@/components/dialogs/dialog-select-searches-product.vue'
    import MattersSearchesSelectProductSkeleton from '@/components/loading-skeletons/matter-searches-select-product-loading-skeleton.vue'
    import MatterSearchesArgSsComboProductConfigDialog from '@/components/matter-searches/matter-searches-arg-ss-combo-product-config-dialog.vue'
    import MatterSearchesBasketTable from '@/components/matter-searches/matter-searches-basket-table.vue'
    import MatterSearchesCategoryTable from '@/components/matter-searches/matter-searches-category-table.vue'
    import MatterSearchesCon29DwcProductConfigDialog from '@/components/matter-searches/matter-searches-con29-dwc-product-config-dialog.vue'
    import MatterSearchesCon29ProductConfigDialog from '@/components/matter-searches/matter-searches-con29-product-config-dialog.vue'
    import MatterSearchesErrorDialog from '@/components/matter-searches/matter-searches-error-dialog.vue'
    import MatterSearchesFilter from '@/components/matter-searches/matter-searches-filter.vue'
    import MatterSearchesHighwaysRaProductConfigDialog from '@/components/matter-searches/matter-searches-highways-ra-product-config-dialog.vue'
    import MatterSearchesTmgLlc1ProductConfigDialog from '@/components/matter-searches/matter-searches-llc1-product-config-dialog.vue'
    import MatterSearchesOrderDetails from '@/components/matter-searches/matter-searches-order-details.vue'
    import MatterSearchesOtherTable from '@/components/matter-searches/matter-searches-other-table.vue'
    import BasketItemModel from '@/components/matter-searches/models/BasketItemModel'
    import CategoryItemModel from '@/components/matter-searches/models/CategoryItemModel'
    import { renderHazards } from '@/components/matter-searches/util/hazard-mapping'
    import { useMapOptions } from '@/composables/use-map-options'
    import { Route } from '@/enums/route.enum'
    import {
        getStepId,
        getStepName,
        SearchesSteps,
    } from '@/enums/searches.enum'
    import {
        ISearchProductPreferenceList,
        Product,
        SearchCategoryWithProducts,
        SEARCHES_ADD_PRODUCT_TO_BASKET,
        SEARCHES_ADD_PRODUCTS_TO_BASKET,
        SEARCHES_ADD_TO_BASKET,
        SEARCHES_FETCH_CATEGORIES,
        SEARCHES_FETCH_PRODUCT_PREFERENCES,
        SEARCHES_FETCH_SEARCH_PROVIDERS,
        SEARCHES_GET_AVAILABLE_CATEGORIES,
        SEARCHES_GET_BASKET,
        SEARCHES_GET_BASKET_CALCULATING,
        SEARCHES_GET_DRAFT_ORDER_ID,
        SEARCHES_GET_FILTER,
        SEARCHES_GET_GEOJSON,
        SEARCHES_GET_HAS_ERRORS,
        SEARCHES_GET_INIT_CONTEXT_REQUEST,
        SEARCHES_GET_LOADING,
        SEARCHES_GET_OTHER_PRODUCTS,
        SEARCHES_GET_PRODUCT_PREFERENCES,
        SEARCHES_GET_RECOMMENDED_CATEGORIES,
        SEARCHES_GET_SEARCH_PROVIDERS,
        SEARCHES_GET_SEARCHES_FETCH_CATEGORIES_REQUEST,
        SEARCHES_GET_SELECTED_BASKET_ITEM,
        SEARCHES_GET_SELECTED_CATEGORY,
        SEARCHES_GET_SELECTED_TITLE_NUMBERS,
        SEARCHES_GET_STRUCTURED_ADDRESS,
        SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING,
        SEARCHES_GET_UNMAPPED_HAZARDS,
        SEARCHES_INIT_CONTEXT,
        SEARCHES_MUTATE_BASKET_DIRTY,
        SEARCHES_MUTATE_CLEAR_ERRORS,
        SEARCHES_MUTATE_CLEAR_QUOTE,
        SEARCHES_MUTATE_CLICK_PRODUCT_LINK,
        SEARCHES_MUTATE_FILTER,
        SEARCHES_MUTATE_LOADING,
        SEARCHES_MUTATE_OTHER_FILTER,
        SEARCHES_MUTATE_SELECTED_BASKET_ITEM,
        SEARCHES_MUTATE_SELECTED_CATEGORY,
        SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS,
        SEARCHES_MUTATE_STRUCTURED_ADDRESS,
        SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER,
        SEARCHES_PREPOPULATE_BASKET,
        SEARCHES_QUOTE,
        SEARCHES_REMOVE_FROM_BASKET,
        SearchHazardDescription,
        SearchProductsFilter,
        SearchProvider,
    } from '@/store/modules/searches/types'

    const props = defineProps({
        show: {
            type: Boolean,
            default: false,
        },
    })

    // composables
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const mapOptions = useMapOptions()

    // computed
    const address = computed<string>(() => store.getters[SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING])
    const structuredAddress = computed<StructuredAddress>(() => store.getters[SEARCHES_GET_STRUCTURED_ADDRESS])
    const geoJson = computed<string>(() => store.getters[SEARCHES_GET_GEOJSON])
    const providers = computed<SearchProvider[]>(() => store.getters[SEARCHES_GET_SEARCH_PROVIDERS])
    const productPreferences = computed<ISearchProductPreferenceList[]>(() => store.getters[SEARCHES_GET_PRODUCT_PREFERENCES])
    const organisationId = computed<string>(() => store.state.user.organisationId)
    const selectedCategory = computed<CategoryItemModel>(() => store.getters[SEARCHES_GET_SELECTED_CATEGORY])
    const multiSelectProducts = ref(false)
    const showSelectProductDialog = ref(false)
    const otherProductsCollapsed = ref(true)
    const recommendedCategoriesCollapsed = ref(false)
    const searchText = ref('')
    const basket = reactive<Store>(store.getters[SEARCHES_GET_BASKET])
    const basketCalculating = computed<boolean>(() => store.getters[SEARCHES_GET_BASKET_CALCULATING])
    const recommendedCategories = reactive<Store>(store.getters[SEARCHES_GET_RECOMMENDED_CATEGORIES])
    const otherProducts = reactive<Store>(store.getters[SEARCHES_GET_OTHER_PRODUCTS])
    const hazards = computed<SearchHazardDescription[]>(() => store.getters[SEARCHES_GET_UNMAPPED_HAZARDS])
    const availableCategories = computed<SearchCategoryWithProducts[]>(() => store.getters[SEARCHES_GET_AVAILABLE_CATEGORIES])
    const basketElement = ref<HTMLElement>(null)
    const hazardMarkup = computed(() => renderHazards(hazards.value.map(hazard => hazard.name)))
    const searchTextElement = ref<HTMLElement>(null)
    const argsParam = computed<string>(() => route.params?.args?.toString() ?? undefined)
    const stepParam = computed<SearchesSteps>(() => getStepId(route.params?.step) ?? undefined)
    const orderDetailsKey = computed<string>(() => store.getters[SEARCHES_GET_DRAFT_ORDER_ID] + Date.now() ?? 'order-details' + Date.now())
    const isDraftOrder = computed<boolean>(() => argsParam.value && stepParam.value === SearchesSteps.Draft)
    const { t, te } = useI18n()

    const loading: WritableComputedRef<boolean> = computed({
        get(): boolean {
            return store.getters[SEARCHES_GET_LOADING]
        },
        set(loading: boolean): void {
            store.commit(SEARCHES_MUTATE_LOADING, loading)
        },
    })

    const selectedBasketItem = computed<BasketItemModel>({
        get: () => store.getters[SEARCHES_GET_SELECTED_BASKET_ITEM],
        set: (basketItem: BasketItemModel) => store.commit(SEARCHES_MUTATE_SELECTED_BASKET_ITEM, basketItem),
    })

    const selectedTitleNumbers = computed<string[]>({
        get: () => store.getters[SEARCHES_GET_SELECTED_TITLE_NUMBERS],
        set: (titleNumbers: []) => {
            store.commit(SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS, titleNumbers, {
                root: true,
            })
        },
    })
    const filter = computed<SearchProductsFilter>({
        get: () => store.getters[SEARCHES_GET_FILTER],
        set: (filter: SearchProductsFilter) => store.commit(SEARCHES_MUTATE_FILTER, filter),
    })

    // lifecycle
    onMounted(async () => {
        loading.value = true
        if (!selectedTitleNumbers.value || selectedTitleNumbers.value.length === 0) {
            store.commit(SEARCHES_MUTATE_STRUCTURED_ADDRESS, null)
            return
        }
    })

    const onClickProductLink = (model: BasketItemModel) => {
        store.commit(SEARCHES_MUTATE_CLICK_PRODUCT_LINK, model)
    }

    const onExpandCollapse = (model: BasketItemModel) => {
        model.collapsed = !model.collapsed
    }

    // handlers
    const onFilter = (newFilter: SearchProductsFilter) => {
        if ((filter.value?.searchProviderId !== newFilter?.searchProviderId)) {
            initialise()
        }
        filter.value = newFilter
        store.commit(SEARCHES_MUTATE_OTHER_FILTER)
    }

    const onMapEdit = () => {
        router.push({
            name: Route.MatterSearchesCreate,
            params: {
                step: getStepName(SearchesSteps.DefinePolygon),
                args: selectedTitleNumbers.value.join(','),
            },
        })
    }

    const onClearBasket = async () => {
        await store.dispatch(SEARCHES_PREPOPULATE_BASKET, {
            addPPToBasket: false,
        })
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, false)
    }

    const onOtherTableCollapse = () => {
        otherProductsCollapsed.value = !otherProductsCollapsed.value
        if (!otherProductsCollapsed.value) {
            nextTick(() => {
                searchTextElement.value.querySelector('input').focus()
            })
        }
    }

    const onDialogClose = () => {
        showSelectProductDialog.value = false
    }

    const onAddToBasket = async (category: CategoryItemModel) => {
        store.dispatch(SEARCHES_ADD_TO_BASKET, category)
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, true)
    }

    const onRemoveFromBasket = (basketItem: BasketItemModel) => {
        store.dispatch(SEARCHES_REMOVE_FROM_BASKET, basketItem)
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, basket.count > 0)
    }

    const onSwapCategoryItem = (model: CategoryItemModel) => {
        store.commit(SEARCHES_MUTATE_SELECTED_CATEGORY, model)
        store.commit(SEARCHES_MUTATE_SELECTED_BASKET_ITEM, undefined)
        multiSelectProducts.value = false
        showSelectProductDialog.value = true
    }

    const onSwapBasketItem = (basketItem: BasketItemModel) => {
        store.commit(SEARCHES_MUTATE_SELECTED_CATEGORY, undefined)
        store.commit(SEARCHES_MUTATE_SELECTED_BASKET_ITEM, basketItem)
        multiSelectProducts.value = false
        showSelectProductDialog.value = true
    }

    // methods
    const quote = async () => {
        store.dispatch(SEARCHES_QUOTE)
    }

    const onAddProductsToBasket = (data: {
        category?: CategoryItemModel
        products: Product[]
    }) => {
        store.dispatch(SEARCHES_ADD_PRODUCTS_TO_BASKET, {
            category: data.category,
            products: data.products,
        })
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, true)
    }

    const onAddProductToBasket = (product: Product) => {
        store.dispatch(SEARCHES_ADD_PRODUCT_TO_BASKET, product)

        // scroll to the location of the basket
        if (basketElement.value) {
            basketElement.value.scrollIntoView({ behavior: 'instant' })
        }
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, true)
    }

    const fetchData = async () => {
        if (isDraftOrder.value) {
            await store.dispatch(SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER, {
                id: argsParam.value,
            })
        }
        await store.dispatch(SEARCHES_FETCH_SEARCH_PROVIDERS)
        await store.dispatch(SEARCHES_FETCH_PRODUCT_PREFERENCES, organisationId.value)
        await store.dispatch(SEARCHES_INIT_CONTEXT, store.getters[SEARCHES_GET_INIT_CONTEXT_REQUEST])
        await store.dispatch(SEARCHES_FETCH_CATEGORIES, store.getters[SEARCHES_GET_SEARCHES_FETCH_CATEGORIES_REQUEST])
        await store.dispatch(SEARCHES_PREPOPULATE_BASKET, {
            addPPToBasket: !isDraftOrder.value,
            addDraftOrdersToBasket: isDraftOrder?.value,
        })
    }

    const initialise = async () => {
        store.commit(SEARCHES_MUTATE_CLEAR_QUOTE)
        loading.value = true
        searchText.value = ''
        try {
            store.commit(SEARCHES_MUTATE_CLEAR_ERRORS)
            await fetchData()
        } finally {
            loading.value = false
        }
    }

    // watch
    watch(() => props.show, (show) => {
        if (show) {
            initialise()
        }
    }, {immediate: true})

    watch(() => filter.value, async (newValue, oldValue) => {
        if (newValue?.productPreferenceId !== oldValue?.productPreferenceId) {
            await reFetchProductListIfSectorChanged()
            await store.dispatch(SEARCHES_PREPOPULATE_BASKET)
        }

        async function reFetchProductListIfSectorChanged() {
            if (productPreferences.value.find(p => p.id == oldValue.productPreferenceId)?.isResidential
                != productPreferences.value.find(p => p.id == newValue.productPreferenceId)?.isResidential) {
                await store.dispatch(SEARCHES_FETCH_CATEGORIES, store.getters[SEARCHES_GET_SEARCHES_FETCH_CATEGORIES_REQUEST])
            }
        }
    })

    watch([searchText], () => {
        store.commit(SEARCHES_MUTATE_FILTER, {
            ...filter.value,
            searchText: searchText.value,
        })
    })

    defineExpose({
        onQuote: quote,
        onFilter,
        onRemoveFromBasket,
    })
</script>

<style scoped lang="scss">
    @import './matter-searches-select-product-step';
</style>
