<template>
    <section class="matter-client-code-inputs">
        <fieldset>
            <!-- Client code-->
            <v-text-field v-show="usesClientCodes && !clientCodeCheckbox"
                          ref="clientCodeField"
                          v-model="formClientCode"
                          :data-test="clientCodeDataTestAttribute"
                          :hide-details="!hasClientCodeHint"
                          :hint="clientCodeFormat"
                          :persistent-hint="hasClientCodeHint"
                          :rules="[rules.clientCode, rules.validCode]"
                          data-track="collect-client-code"
                          label="Client code"
                          variant="underlined"
                          @update:model-value="emitChange" />
            <v-checkbox v-if="usesClientCodes"
                        v-model="clientCodeCheckbox"
                        class="matter-client-code-inputs__checkbox"
                        color="primary"
                        data-test="matter-client-code-inputs-work-without-client-code"
                        data-track="toggle-work-without-client-code"
                        hide-details
                        label="Work without a client code" />
            <p v-if="usesClientCodes && clientCodeCheckbox"
               class="content__consent-text matters-create__client-code-tip"
               data-test="matter-client-code-inputs-work-without-client-code-tip">
                You may be required to provide this information to your organisation later on.
            </p>
        </fieldset>

        <!-- Matter code-->
        <fieldset>
            <v-text-field ref="matterCodeField"
                          v-model="formMatterCode"
                          :data-test="matterCodeDataTestAttribute"
                          :hint="matterCodeFormat"
                          :persistent-hint="hasMatterCodeHint"
                          :rules="[rules.mandatoryMatterCodes, rules.validCode, rules.validOrganisationMatterCode]"
                          data-track="collect-matter-code"
                          variant="underlined"
                          label="Matter code"
                          @update:model-value="emitChange" />
        </fieldset>
    </section>
</template>

<script lang="ts">
    import { nextTick } from 'vue'
    import { mapState } from 'vuex'

    import { matterCodeValidationRegEx } from '@/consts/matter'
    import { validateMatterCodeWithMask } from '@/utils/matter-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'MatterClientCodeInputs',

        props: {
            clientCode: {
                type: String,
                default: '',
            },

            matterCode: {
                type: String,
                default: '',
            },

            clientCodeDataTestAttribute: {
                type: String,
                default: 'matter-client-code-inputs-client-code',
            },

            matterCodeDataTestAttribute: {
                type: String,
                default: 'matter-client-code-inputs-matter-code',
            },
        },

        emits: ['change'],

        data() {
            return {
                formClientCode: this.clientCode,
                formMatterCode: this.matterCode,
                clientCodeCheckbox: false,
                rules: {
                    clientCode: (value) => {
                        if (this.usesClientCodes && !this.clientCodeCheckbox) {
                            if (isNullOrWhitespace(value)) {
                                return this.$t('matter.mandatoryClientCode', { format: this.clientCodeFormat })
                            }
                        }
                        return true
                    },
                    mandatoryMatterCodes: (value) => {
                        if (this.mandatoryMatterCodes) {
                            if (isNullOrWhitespace(value)) {
                                return this.hasMatterCodeHint ? this.$t('matter.mandatoryMatterCode', { format: this.matterCodeFormat }) :
                                    this.$t('matter.mandatoryMatterCodeWithoutHint')
                            }
                        }
                        return true
                    },
                    validCode: (value) => {
                        const validHMLRMatterCode = matterCodeValidationRegEx.test(value)

                        if (!validHMLRMatterCode) {
                            return this.$t('matter.invalidCode')
                        }
                        return true
                    },
                    validOrganisationMatterCode: (value) => {
                        const validOrganisationMatterCode = isNullOrWhitespace(this.matterCodeMask)
                            ? true
                            : validateMatterCodeWithMask(value, this.matterCodeMask)

                        if (!validOrganisationMatterCode) {
                            return this.hasMatterCodeHint
                                ? this.$t('matter.invalidCodeFormat', { format: this.matterCodeFormat })
                                : this.$t('matter.invalidCodeFormatWithoutHint')
                        }

                        return true
                    },
                },
            }
        },

        computed: {
            ...mapState({
                usesClientCodes: (state: any) => state.user.usesClientCodes,
                mandatoryMatterCodes: (state: any) => state.user.mandatoryMatterCodes,
                matterCodeFormat: (state: any) => state.user.matterCodeFormat,
                clientCodeFormat: (state: any) => state.user.clientCodeFormat,
                matterCodeMask: (state: any) => state.user.matterCodeMask,
            }),

            hasClientCodeHint(): boolean {
                return !isNullOrWhitespace(this.clientCodeFormat)
            },

            hasMatterCodeHint(): boolean {
                return !isNullOrWhitespace(this.matterCodeFormat)
            },
        },

        watch: {
            clientCodeCheckbox(val) {
                if (val) {
                    this.formClientCode = ''
                }

                this.emitChange()
            },
        },

        methods: {
            async emitChange(): Promise<void> {
                await nextTick()
                const matterCodeFieldValidationResult =  await this.$refs?.matterCodeField?.validate()
                const clientCodeFieldValidationResult = await this.$refs?.clientCodeField?.validate()
                if (clientCodeFieldValidationResult &&
                    matterCodeFieldValidationResult) {
                    this.$emit('change', {
                        clientCode: this.formClientCode || null,
                        matterCode: this.formMatterCode ?? null,
                        matterCodeError: matterCodeFieldValidationResult.length !== 0 || clientCodeFieldValidationResult.length !== 0,
                    })
                } else {
                    this.$emit('change', null)
                }
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './matter-client-code-inputs';
</style>
