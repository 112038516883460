<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 14 12"
                  desc="Megaphone icon">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.56.32c-.13.04-.2.1-.45.46a9.8 9.8 0 0 1-1.8 2.06 5.22 5.22 0 0 1-1.72 1l.7 2.86.27 1.07h.03l.14-.02a6.5 6.5 0 0 1 3.05.48c.54.2 1.22.49 1.64.71.1.05.12.06.2.06.15.01.3-.05.36-.15a.59.59 0 0 0 .07-.23 8.3 8.3 0 0 0-.3-1.37l-.27-1.16c-.02-.1 0-.13.08-.28.22-.37.3-.6.3-.95a1.6 1.6 0 0 0-.27-1.03c-.16-.24-.4-.45-.7-.63-.14-.08-.15-.1-.2-.23l-.18-.73A12.05 12.05 0 0 0 9.03.5a.4.4 0 0 0-.18-.16.8.8 0 0 0-.3-.02Zm3.22.88c-.08.04-.17.16-.64.83-.5.72-.52.74-.52.86 0 .1.03.15.1.21.12.12.26.12.38.02l.13-.15c.15-.2.83-1.18.91-1.32.05-.08.06-.1.05-.16 0-.17-.11-.3-.28-.3a.2.2 0 0 0-.13.01Zm-3.27.76c.1.36 1.43 5.7 1.42 5.71 0 .03-.03.02-.25-.07-1-.41-2.27-.72-3.4-.83-.15-.01-.16-.01-.17-.04l-.57-2.32.15-.08a10.91 10.91 0 0 0 2.78-2.5l.04.13Zm4.7 1.5-1.6.4a.27.27 0 0 0-.17.15.22.22 0 0 0-.03.14c0 .06 0 .09.03.14.06.1.14.15.25.15.08 0 1.35-.3 1.79-.43a.21.21 0 0 0 .15-.14.28.28 0 0 0 .04-.14.25.25 0 0 0-.08-.2c-.09-.1-.16-.11-.38-.07Zm-9.56.59a118.28 118.28 0 0 0-2 .52 835.92 835.92 0 0 0 .98 3.9c.03.03.16 0 .62-.1a25.8 25.8 0 0 0 1.55-.4 469.58 469.58 0 0 0-.9-3.68c-.09-.3-.06-.28-.25-.24ZM.93 5.23a1.8 1.8 0 0 0-.36.4c-.22.3-.3.8-.2 1.27.16.7.66 1.23 1.27 1.35.11.02.13.01.12-.03l-.75-3c-.02-.03-.02-.03-.08 0Zm10.56.22a.28.28 0 0 0-.17.15c-.03.05-.03.07-.03.15.01.15.04.2.19.27l1.58.68c.06.02.2 0 .26-.02.09-.05.14-.15.14-.27 0-.1-.03-.17-.09-.22a148.96 148.96 0 0 1-1.02-.46c-.74-.31-.75-.32-.86-.28ZM4.8 8.81l-1.32.32c-.63.16-.62.16-.62.18a5821.31 5821.31 0 0 1 1.12 1.67c.35.51.44.63.5.68.06.04.21.03.44-.02.47-.1 1.43-.35 1.52-.39a.32.32 0 0 0 .15-.14.54.54 0 0 0 0-.3.99.99 0 0 0-.18-.35c-.11-.12-.3-.25-.58-.4-.28-.15-.35-.24-.4-.63-.06-.3-.1-.4-.22-.53-.09-.09-.15-.13-.21-.13l-.2.04Z"
              :fill="color" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconMegaphone',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '14px',
            },
            height: {
                type: String,
                required: false,
                default: '12px',
            },
            color: {
                type: String,
                required: false,
                default: '#1D73AD',
            },
        },
    }
</script>
