<template>
    <title-panel-card v-if="isOrderSearchEnabled && !searchesDisabled"
                      class="property-searches-card"
                      data-section="searches"
                      title="Searches">
        <ow-loading-skeleton v-if="isLoading"
                             class="mt-2"
                             height="100px"
                             width="100%" />
        <button v-else
                :class="{
                    'property-searches-card__button--disabled': loading,
                }"
                class="property-searches-card__button d-flex justify-space-between align-center py-4 px-4"
                data-test="property-searches-card-button"
                data-track="TITLE-DETAILS-PANEL - Order searches"
                @click="onButtonClick">
            <ow-icon-searches class="property-searches-card__icon" />
            <div class="property-searches-card__text d-flex flex-grow-1 flex-column justify-start px-4">
                <span v-t="'searches.orderSearches'"
                      class="body-highlight d-flex justify-start" />
                <span v-t="'searches.orderSearchesDescription'"
                      class="caption-regular d-flex justify-start"
                      style="opacity: 0.6;" />
            </div>
            <div class="property-searches-card__arrow">
                <ow-icon icon="ow-icon-chevron-right" />
            </div>
        </button>
    </title-panel-card>
</template>

<script lang="ts" setup>
    import {
        computed,
        onMounted,
        ref,
    } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import OwIconSearches from '@/components/core/icons/ow-icon-searches-intro.vue'
    import OwIcon from '@/components/core/ow-icon.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import TitlePanelCard from '@/components/title-panel/v2/cards/title-panel-card.vue'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { ITitlePanelTabController } from '@/composables/use-title-panel'
    import { Route } from '@/enums/route.enum'
    import {
        getStepName,
        SearchesSteps,
    } from '@/enums/searches.enum'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import initLdClient from '@/feature-flags/init-ld-client'
    import { MATTER_ADD_MULTIPLE_TITLES } from '@/store/modules/matter/types'
    import { SEARCHES_MUTATE_MAP_KEY } from '@/store/modules/searches/types'

    const router = useRouter()
    const route = useRoute()
    const props = defineProps<{
        isLoading: boolean
        matterId?: number
        isFullScreen?: boolean
        tabController: ITitlePanelTabController
    }>()
    const store = useStore()

    const searchesDisabled = computed<boolean>(() => route?.name === Route.TitleDetails)
    const loading = ref(false)
    const { selectedTitleNumber } = useSelectedTitle()
    const isOrderSearchEnabled = computed(() => initLdClient.checkFlag('order-search'))

    onMounted(() => {
        if (isOrderSearchEnabled.value && !props.isFullScreen) {
            props.tabController.addTabSection(TitlePanelTabName.Summary, {
                id: 'searches',
                order: 400,
            })
        }
    })

    const onButtonClick = async () => {
        loading.value = true
        try {
            if (searchesDisabled.value) {
                return
            }

            // is title number in the matter
            const title = store.state.currentMatter?.selectedTitleNumbers?.find((t) => t.titleNumber === selectedTitleNumber.value)
            if (!title) {
                // add title number to the matter
                await store.dispatch(MATTER_ADD_MULTIPLE_TITLES, {
                    matterId: props?.matterId,
                    titleNumbers: [ selectedTitleNumber.value ],
                    showPopup: false,
                })
            }
            await router.push({
                name: Route.MatterSearchesCreate,
                params: {
                    args: selectedTitleNumber.value,
                    matterId: props?.matterId?.toString(),
                    step: getStepName(SearchesSteps.DefinePolygon),
                },
            })
            store.commit(SEARCHES_MUTATE_MAP_KEY)
        } finally {
            loading.value = false
        }
    }

    defineExpose({
        router,
        searchesDisabled,
    })
</script>

<style lang="scss">
    @import './searches.scss';
</style>
