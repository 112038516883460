import {
    confirmationDialogInitialState,
    preventTransitionInitialState,
} from './index'
import {
    CORE_MUTATE_CONFIRMATION_DIALOG,
    CORE_MUTATE_INIT_CONFIRMATION_DIALOG,
    CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE,
    CORE_MUTATE_PREVENT_TRANSITION,
} from './types'

export default {
    [CORE_MUTATE_PREVENT_TRANSITION](state, update) {
        state.preventTransition = {
            ...state.preventTransition,
            ...update,
        }
    },

    [CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE](state) {
        state.preventTransition = { ...preventTransitionInitialState }
    },

    [CORE_MUTATE_CONFIRMATION_DIALOG](state, update) {
        state.confirmationDialog = {
            ...state.confirmationDialog,
            ...update,
        }
    },

    [CORE_MUTATE_INIT_CONFIRMATION_DIALOG](state) {
        state.preventTransition = { ...confirmationDialogInitialState }
    },
}
