<template>
    <div class="asset-monitoring-data-grid">
        <header class="asset-monitoring-data-grid__header">
            <div class="label-caps-small asset-monitoring-data-grid__header--date"
                 data-track="Asset Monitoring: sort alerts by date"
                 @click="dateSortHandler">
                {{ t('assetMonitoring.dataGrid.label.date') }}
                <v-icon :class="{
                    'date-desc': currentSortOrder === 'desc',
                }">
                    $chevron-up
                </v-icon>
            </div>
            <div class="label-caps-small asset-monitoring-data-grid__header--details">
                {{ t('assetMonitoring.dataGrid.label.details') }}
            </div>
        </header>

        <div :class="{
                 'asset-monitoring-data-grid__updates-banner--visible': notificationMessageCount > 0,
             }"
             class="asset-monitoring-data-grid__updates-banner body-regular">
            {{
                t('assetMonitoring.text.updatesReceived', {
                    count: notificationMessageCount,
                })
            }}
            <ow-button is-secondary
                       small
                       data-track="Asset monitoring - refresh alerts"
                       @click="refreshHandler">
                {{ t('action.refresh') }}
            </ow-button>
        </div>

        <div v-if="isLoading"
             class="asset-monitoring-data-grid__skeleton">
            <ow-loading-skeleton v-for="i in 5"
                                 :key="i"
                                 class="asset-monitoring-data-grid__skeleton"
                                 height="130px"
                                 wiidth="100%" />
        </div>
        <div v-else-if="!hasItems"
             data-test="asset-monitoring-data-grid-no-data"
             class="asset-monitoring-data-grid__no-data">
            <ow-icon-asset-monitoring />
            <div class="asset-monitoring-data-grid__no-data--text">
                <h3 class="headers-display">
                    {{ t('assetMonitoring.text.noData') }}
                </h3>
                <p class="body-regular">
                    {{ t('assetMonitoring.text.noDataInfo') }}
                </p>
            </div>
        </div>
        <div v-else>
            <v-list class="asset-monitoring-data-grid__row-container">
                <v-list-item v-for="row in rows"
                             :key="row.data.notificationId"
                             :data-test="`asset-monitoring-data-grid-item-${ row.data.notificationId }`"
                             data-track="Asset monitoring - alert item"
                             class="asset-monitoring-data-grid__rows">
                    <component :is="row.component"
                               :item="row.data"
                               :matter-id="matterId"
                               container-selector=".asset-monitoring-data-grid__rows"
                               @view-title="handleViewTitle(row.data)" />
                </v-list-item>
                <v-list-item v-if="!isLoading && moreDataAvailable"
                             v-intersect="loadNextPage"
                             data-test="asset-monitoring-data-grid-loader">
                    <ow-loading-skeleton height="112px" />
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        markRaw,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { INotificationMessageArguments } from '@/api/notifications.api'
    import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import CompaniesHouseChangedRowItem
        from "@/components/asset-monitoring/grid/row-items/companies-house-changed-row-item.vue"
    import TitleRegisterOutdatedRowItem
        from '@/components/asset-monitoring/grid/row-items/title-register-outdated-row-item.vue'
    import { NotificationType } from '@/components/asset-monitoring/notification-type.enum'
    import OwIconAssetMonitoring from '@/components/core/icons/ow-icon-asset-monitoring.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const { t } = useI18n()
    const rows = ref([])
    const store = useAssetMonitoringStore()

    const emit = defineEmits<{
        (e: 'sort-by', value: string),
        (e: 'refresh'),
        (e: 'load-next-page')
        (e: 'view-title', row: any),
    }>()

    const props = defineProps<{
        items: Array<IAssetMonitoringNotification>,
        matterId: number,
        isLoading: boolean,
    }>()

    const notificationMessages = computed<Array<INotificationMessageArguments>>(() => store.
        notificationMessages)
    const notificationMessageCount = computed<number>(() => notificationMessages.value.length)
    const hasItems = computed((): boolean => !isNullOrEmpty(props.items))
    const totalResults = computed((): number => store.totalResults)
    // If (results - current notif count) is above 0 there's more results to load.
    const moreDataAvailable = computed((): boolean => (totalResults.value - props.items.length) > 0)

    const getNotificationTypeComponent = (notificationType: NotificationType) => {
        switch (notificationType) {
            case NotificationType.TitleRegisterOutOfDate:
            case NotificationType.OwnershipChanged:
            case NotificationType.BoundaryChanged:
                return markRaw(TitleRegisterOutdatedRowItem)
            case NotificationType.CompaniesHouseChanged:
                return markRaw(CompaniesHouseChangedRowItem)

        }
    }

    watch(() => props.items, () => {
        rows.value = props.items?.map((item: IAssetMonitoringNotification) => {
            return {
                component: getNotificationTypeComponent(item.notificationType),
                data: {
                    ...item,
                    titleNumber: item.titleNumber,
                },
            }
        })
    }, { immediate: true })

    const currentSortOrder = ref<string>('desc')
    const dateSortHandler = () => {
        if (currentSortOrder.value === 'desc') {
            currentSortOrder.value = 'asc'
        } else {
            currentSortOrder.value = 'desc'
        }
        emit('sort-by', currentSortOrder.value)
    }

    const refreshHandler = async () => {
        await store.updateNotificationMessages(new Array<INotificationMessageArguments>())
        emit('refresh')
    }

    const loadNextPage = (intersects: boolean) => {
        if(intersects && moreDataAvailable.value){
            emit('load-next-page')
        }
    }

    const handleViewTitle = (row: any) => {
        emit('view-title', row)
    }
</script>

<style lang="scss"
       scoped>
    @import "./data-grid.scss";
</style>
