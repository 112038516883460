<template>
    <div id="admin-log-page">
        <div class="fixed-header">
            <header>
                <h1 class="content__heading">
                    Log
                </h1>
            </header>
        </div>
        <admin-log />
    </div>
</template>

<script>
    import AdminLog from '@/components/admin/admin-log.vue'

    export default {
        name: 'Logs',

        components: {
            AdminLog,
        },
    }
</script>

<style lang="scss">
    @import './logs';
</style>
