import {
    AUTH_GET_IS_AUTHENTICATED,
    AUTH_GET_LOADING,
    AUTH_GET_USER,
} from '@/store/modules/auth-oidc/types'

export default {
    [AUTH_GET_IS_AUTHENTICATED](state) {
        return state.isAuthenticated
    },

    [AUTH_GET_USER](state) {
        return state.user
    },

    [AUTH_GET_LOADING](state) {
        return state.loading
    },
}
