import { HubConnection } from '@microsoft/signalr'

import {
    MATTER_HUB_MUTATE_CURRENTLY_SUBSCRIBED_TO_MATTER_ID,
    MATTER_HUB_MUTATE_ERROR,
    MATTER_HUB_MUTATE_HUB,
    MATTER_HUB_MUTATE_IS_SUBSCRIBED,
    MATTER_HUB_MUTATE_MESSAGE,
} from '@/store/modules/matter-hub/types'
import { IMatterHubMessage } from '@/store/modules/matter-hub/interfaces/matter-hub-message.interface'
import { IMatterHubState } from '@/store/modules/matter-hub/interfaces/matter-hub-state.interface'

export default {
    [MATTER_HUB_MUTATE_HUB]: (state: IMatterHubState, hub: HubConnection) => {
        state.hub = hub
    },

    [MATTER_HUB_MUTATE_IS_SUBSCRIBED]: (state: IMatterHubState, isSubscribed: boolean) => {
        state.isSubscribed = isSubscribed
    },

    [MATTER_HUB_MUTATE_ERROR]: (state: IMatterHubState, error: string) => {
        state.error = error
    },

    [MATTER_HUB_MUTATE_MESSAGE]: (state: IMatterHubState, message: IMatterHubMessage) => {
        state.messages.push(message)
    },

    [MATTER_HUB_MUTATE_CURRENTLY_SUBSCRIBED_TO_MATTER_ID]: (state: IMatterHubState, matterId: number | null) => {
        state.currentlySubscribedToMatterId = matterId
    },
}
