<template>
    <div class="d-flex flex-row align-center documents-summary">
        <div v-if="!loading"
             class="body-regular documents-summary__show-label">
            <span>{{ $t('label.showing') }}</span>
            <!-- Filtered document count-->
            <span v-if="hasFilter"
                  data-test="doc-library-summary-filtered-count">{{ filteredDocuments.length }} of</span>
            <!-- Total document count -->
            <span data-test="doc-library-summary-count">{{ documents.length }} documents,</span>
            <!-- Filtered unique title number count -->
            <span v-if="hasFilter"
                  data-test="doc-library-summary-filtered-title-count">
                {{ filteredDocumentsTitleNumbers.length }} of
            </span>
            <!-- Total unique title number count -->
            <span data-test="doc-library-summary-title-count">{{ documentsTitleNumbers.length }} titles</span>
        </div>
        <v-progress-linear v-if="loading"
                           data-test="doc-library-progressbar"
                           indeterminate />

        <div class="d-flex shrink">
            <v-btn :disabled="loading"
                   color="primary"
                   data-test="doc-library-refresh"
                   data-track="DOCS - Refresh data"
                   size="large"
                   variant="text"
                   @click="onRefreshClick">
                {{ $t('action.refresh') }}
            </v-btn>
        </div>
        <v-spacer />
        <div class="documents-summary__exporting-buttons">
            <ow-button :disabled="!selectedDownloadableDocumentsLength"
                       :is-loading="loadingBulkDownload"
                       :outlined="true"
                       class="documents-summary__reporting-button"
                       data-test="document-summary-download-as-pdf"
                       @click="downloadPdf">
                <v-icon>$download</v-icon>
                {{ $t('action.downloadAsPdf') }} {{ selectedDownloadableDocumentsLength > 0 ? `(${selectedDownloadableDocumentsLength})` : '' }}
            </ow-button>
            <ow-button is-primary
                       class="elevation-2 documents-summary__reporting-button"
                       data-test="document-summary-see-reporting-options"
                       data-track="DOCUMENTS - Go to reporting options from purchased tab"
                       @click="seeReportingOptions">
                {{ $t('report.action.seeReportingOptions') }}
                <v-icon>$chevron-right</v-icon>
            </ow-button>
        </div>
    </div>
</template>
<script>
    import OwButton from '@/components/core/ow-button-ds.vue'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { getDocumentDownloadAvailability } from '@/utils/document-utils'

    export default {
        name: 'DocumentsSummary',

        components: {
            OwButton,
        },

        mixins: [flagsMixin],

        props: {
            filteredDocuments: {
                type: Array,
                required: false,
                default: () => [],
            },
            documents: {
                type: Array,
                required: false,
                default: () => [],
            },
            filteredDocumentsTitleNumbers: {
                type: Array,
                required: false,
                default: () => [],
            },
            documentsTitleNumbers: {
                type: Array,
                required: false,
                default: () => [],
            },
            hasFilter: {
                type: Boolean,
                required: false,
                default: false,
            },
            loading: {
                type: Boolean,
                required: false,
                default: false,
            },
            selectedDocuments: {
                type: Array,
                required: false,
                default: () => [],
            },
            loadingBulkDownload: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        emits: [
            'download-as-pdf-clicked',
            'see-reporting-options-clicked',
            'refresh',
        ],

        computed: {
            selectedDownloadableDocumentsLength() {
                if (!this.selectedDocuments || this.selectedDocuments.length === 0) {
                    return 0
                }
                return this.selectedDocuments.reduce((acc, doc) => acc + (getDocumentDownloadAvailability(doc) ? 1 : 0), 0)
            },
        },

        methods: {
            downloadPdf() {
                this.$emit('download-as-pdf-clicked')
            },
            seeReportingOptions() {
                this.$emit('see-reporting-options-clicked')
            },
            onRefreshClick() {
                this.$emit('refresh')
            },
        },
    }
</script>

<style lang="scss">
    @import './documents-summary.scss';
</style>
