import isEqual from 'lodash.isequal'

import {
    WALKTHROUGH_GET_FIRST_PAGE,
    WALKTHROUGH_GET_HAS_CHANGES,
    WALKTHROUGH_GET_LAST_PAGE,
    WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE,
} from './types.js'
import WalkthroughUtils from './walkthrough-utils.js'

export default {

    [WALKTHROUGH_GET_FIRST_PAGE](state) {
        if (state.walkthrough) {
            return [...state.walkthrough?.pages].sort((a, b) => a.pageNumber - b.pageNumber)[0]
        }
        return null
    },

    [WALKTHROUGH_GET_LAST_PAGE](state) {
        if (state.walkthrough) {
            return [...state.walkthrough?.pages].sort((a, b) => b.pageNumber - a.pageNumber)[0]
        }
        return null
    },

    /**
     * Indicates whether there are unsaved changes to the walkthrough.
     */
    [WALKTHROUGH_GET_HAS_CHANGES](state) {
        if (state.walkthrough) {
            return !isEqual(
                WalkthroughUtils.getSimplifiedWalkthroughObject(state.walkthrough),
                WalkthroughUtils.getSimplifiedWalkthroughObject(state.sourceWalkthrough),
            )
        }
        return false
    },

    /**
     * Returns a function that returns a boolean indicating whether a title number is included in
     * the current selected walkthrough page.
     */
    [WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE](state) {
        return (titleNumber) => {
            return state.selectedPage?.contentItems
                .some(item => item.titleNumber == titleNumber) ?? false
        }
    },

}
