import ApolloClient from 'apollo-boost'
import { onError } from 'apollo-link-error'

import { getGraphQlUri } from '@/utils/environment.utils'
import SecurityService from '@/api/security'
import { createApolloProvider } from '@vue/apollo-option'

const uriForEnvironment = getGraphQlUri()

// Error Handling
onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${ message }, Location: ${ locations }, Path: ${ path }`,
            ),
        )
    }

    if (networkError) {
        console.error(`[Network error]: ${ networkError }`)
    }
})

// Create the apollo client
export const apolloClient = new ApolloClient({
    // You should use an absolute URL here
    uri: uriForEnvironment,
    request: async (operation) => {
        operation.setContext({
            headers: {
                Authorization: `Bearer ${ await SecurityService.getAccessToken() }`,
            },
        })
    },
})

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
})
export default apolloProvider
