<template>
    <section ref="rootRef"
             class="title-number-group"
             data-test="document-selection-title-number-group">
        <template v-if="isVisible">
            <div class="title-number-group__header">
                <ow-checkbox :id="`select-group-${group.titleNumber}`"
                             v-model="areAllSelected"
                             :disabled="!group.documents.length"
                             :indeterminate="isGroupSelectAllIndeterminate" />
                <div class="title-number caption-highlight">
                    {{ group.titleNumber }}
                </div>
                <div class="address accents-small title-number-group__address"
                     :title="group.address">
                    {{ group.address }}
                </div>
            </div>

            <div v-if="!group.documents.length"
                 v-t="'titlePanel.owCopilotBlocked.noDocumentsToSend'"
                 class="accents-italic ml-4 pb-4" />
            <template v-else>
                <document-selection-title-num-group-item v-for="(doc, idx) in group.documents"
                                                         :key="doc.documentId+idx"
                                                         :document="doc"
                                                         :title-number="group.titleNumber"
                                                         :list-position="idx"
                                                         class="title-number-group__documents"
                                                         @change="updateSelected" />
            </template>
        </template>
    </section>
</template>

<script setup lang="ts">
    import { computed,
             onMounted,
             PropType,
             ref } from 'vue'

    import { IDocumentSelectionGroup } from '@/components/copilot/document-selection/document-selection-group.interface'
    import DocumentSelectionTitleNumGroupItem from '@/components/copilot/document-selection/document-selection-title-num-group-item.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const rootRef = ref(null)

    const emit = defineEmits<{
        (e: 'change', value: boolean, documentId: number[]),
    }>()

    const props = defineProps({
        group: {
            type: Object as PropType<IDocumentSelectionGroup>,
            required: true,
        },
        scrollElementContainer: {
            type: String,
            required: true,
        },
    })

    const isGroupSelectAllIndeterminate = computed(() => {
        const selected = props.group.documents.filter(d => d.selected)
        if(isNullOrEmpty(selected)) {
            return false
        }

        return selected.length !== props.group?.documents?.length
    })

    const areAllSelected = computed({
        get: (): boolean => {
            if (isNullOrEmpty(props.group.documents)) {
                return false
            }
            return !isGroupSelectAllIndeterminate.value &&
                props.group?.documents?.filter(d => d.selected).length === props.group?.documents?.length
        },
        set: (isSelected: boolean): void => {
            const docIds = props.group?.documents?.map(d => d.documentId)
            emit('change', isSelected, docIds)
        },
    })

    const updateSelected = (val: boolean, documentId: number) => {
        emit('change', val, [documentId])
    }

    const scrollObserver = ref(null) // The Intersection Observer used to detect visibility changes.
    let isVisible = ref<boolean>(false)
    const initialiseIntersectionObserver = () => {
        // Listen for changes to the position of this component within it's parent scrollable element.
        if (scrollObserver.value === null) {
            // The parent scrollable element.
            const scrollElement = ref(document.querySelector(props.scrollElementContainer))
            if (scrollElement.value !== null) {
                const options = {
                    root: scrollElement.value,
                    rootMargin: '16px',
                    threshold: 1.0,
                }

                scrollObserver.value = new IntersectionObserver((entries: Array<any>) => {
                    entries.forEach((entry: any) => {
                        if (entry.target.dataset.group === props.group.titleNumber) {
                            // True if component instant is visible.
                            isVisible.value = entry.intersectionRatio > 0
                        }
                    })
                }, options)
                scrollObserver.value.observe(rootRef.value)
            }
        }

    }

    onMounted(() => {
        initialiseIntersectionObserver()
    })
</script>

<style scoped lang="scss">
    @import './document-selection-title-num-group.scss';
</style>
