<template>
    <div class="lease-hierarchy">
        <ow-card class="basic-information"
                 :loading="!selectedTitle?.record || isLoading"
                 :loading-skeleton="{ rows: 1, columns: 0, expandRows: false, displayInline: true }"
                 :title="t('titlePanel.leaseHierarchy.title').toString()">
            <div v-if="hasHierarchyNodes && isLoaded"
                 class="lease-hierarchy__controls">
                <ow-checkbox id="schedule-of-leases-select-all"
                             v-model="allTitlesSelected"
                             :disabled="!isLoaded || !hasHierarchyNodes"
                             class="lease-hierarchy__select-all"
                             data-test="schedule-of-leases-select-all"
                             data-track="TITLE DETAILS PANEL - Schedule of leases select all titles to add to matter"
                             label="Select all" />
                <ow-add-to-group v-if="hasSelectedTitles"
                                 ref="addToGroup"
                                 v-model="selectedGroup"
                                 :disabled="!hasSelectedTitles || isAddingTitles"
                                 class="schedule-of-leases__add-titles--groups"
                                 data-test="schedule-of-leases-add-to-group"
                                 data-track="TITLE DETAILS PANEL - Schedule of leases select a group"
                                 clearable />
                <ow-button class="schedule-of-leases__add-titles__button"
                           :disabled="!hasSelectedTitles || isAddingTitles"
                           data-test="schedule-of-leases-add-to-matter-button"
                           data-track="TITLE DETAILS PANEL - Schedule of leases add titles to matter"
                           is-primary
                           @click="addSelectedTitlesToMatter">
                    <span v-t="'action.addToMatter'" />
                </ow-button>
            </div>
            <div v-if="showDebugControls"
                 class="lease-hierarchy__controls">
                <!-- These controls are intended for testing only -->
                <ow-button @click="onShowLogClick">
                    <span v-if="showLog">Hide log</span>
                    <span v-else>Show log</span>
                </ow-button>
                <ow-button v-if="isLoading"
                           @click="onStopClick">
                    Stop
                </ow-button>
                <ow-button v-else
                           @click="onRefreshClick">
                    Refresh
                </ow-button>
            </div>
            <section v-if="showLog && showDebugControls">
                <ul class="lease-hierarchy__log">
                    <li v-for="message in logMessages">
                        {{ message.timestamp }}: {{ message.message }}
                    </li>
                </ul>
                <p v-if="!isLoaded && hasHierarchyNodes">
                    OCDA calls made: {{ ocdaCallsCount }},
                    Hierarchy depth: {{ hierarchyDepth }}
                </p>
            </section>
            <section :key="hierarchyKey"
                     class="lease-hierarchy__output">
                <template v-if="isLoading">
                    <ow-loading-text :text="t('titlePanel.leaseHierarchy.loading')"
                                     :is-loading="isLoading" />
                </template>
                <div v-else
                     v-dompurify-html="summaryText"
                     class="hide-in-percy" />

                <lease-hierarchy-item v-for="item in hierarchyData.items"
                                      :key="getUniqueKey(item)"
                                      :depth="item.depth"
                                      :item="item"
                                      class="hierarchy-item"
                                      :selected-title-numbers="selectedTitleNumbers"
                                      @title-selected="handleTitleSelected" />
            </section>
        </ow-card>
    </div>
</template>
<script setup lang="ts">
    import {
        computed,
        getCurrentInstance,
        onActivated,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwLoadingText from '@/components/core/ow-loading-text.vue'
    import LeaseHierarchyItem from '@/components/lease-hierarchy/lease-hierarchy-item.vue'
    import {
        LeaseHierarchyItem as LeaseHierarchyItemType,
    } from '@/components/lease-hierarchy/lease-hierarchy-types'
    import useLeaseHierarchy from '@/components/lease-hierarchy/use-lease-hierarchy.composable'
    import OwAddToGroup, { GroupItem } from '@/components/matter/titles/matter-side-panel-add-titles/add-to-group.vue'
    import useDates from '@/composables/use-dates'
    import {
        MATTER_ADD_MULTIPLE_TITLES,
        MATTER_ADD_TITLES_TO_MATTER_AND_GROUP,
    } from '@/store/modules/matter/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const { t } = useI18n()
    const store = useStore()
    const { formatDateShort } = useDates()
    const {
        isLoaded,
        isLoading,
        hierarchyData,
        hierarchyDepth,
        hierarchyNodes,
        loadLeaseHierarchy,
        logMessages,
        ocdaCallsCount,
        stop,
    } = useLeaseHierarchy()

    interface Props {
        selectedTitleNumber?: string,
        isCautionAgainstFirstRegistration: boolean,
        selectedTitle?: any,
    }
    const props = defineProps<Props>()

    // Show debug output
    const showLog = ref(false)
    const showDebugControls = computed(() => store.state.config.featureFlags.allowDebugMode)
    const summaryText = ref<string>()
    const selectedTitleNumbers = ref<string[]>([])
    const selectedGroup = ref<GroupItem>(null)
    const isAddingTitles = ref(false)
    let updateInterval = undefined
    const hierarchyKey = ref(0)
    const recordSource = computed(() => props.selectedTitle?.record?.source)

    const hasHierarchyNodes = computed(() => !isNullOrEmpty(hierarchyData.items))

    onMounted(() => {
        refreshHierarchy()
    })

    onActivated(() => {
        refreshHierarchy()
    })

    watch(() => recordSource.value, () => {
        refreshHierarchy()
    })

    const refreshHierarchy = async () => {
        const loadingStartDate = new Date() // this will need to come from the server response once longer caching is enabled.

        if (!props.isCautionAgainstFirstRegistration) {
            // Updating this key value every half a second ensures that the nodes of the hierarchy tree appear
            // as it triggers a re-render.
            updateInterval = setInterval(() => {
                hierarchyKey.value++
            }, 500)

            await loadLeaseHierarchy([props.selectedTitleNumber])

            if (updateInterval) {
                clearInterval(updateInterval)
            }
        }
        updateSummaryText(loadingStartDate)
    }

    const updateSummaryText = (loadingStartDate: Date) => {
        if (props.isCautionAgainstFirstRegistration) {
            summaryText.value = `<p>${ t('titlePanel.leaseHierarchy.cafrMessage').toString() }</p>`
        }  else if (!isNullOrEmpty(hierarchyData.items)) {
            summaryText.value = `<p>${ t('titlePanel.leaseHierarchy.disclaimer', {
                ocdaDate: formatDateShort(loadingStartDate),
                hmlrMonthlyDataDate: store.state.config.settings.monthlyHMLRDataVersion,
            }).toString() }</p>`
        } else {
            summaryText.value = `<p>${ t('titlePanel.leaseHierarchy.noData') }</p>`
            if (hierarchyData.hasRemovedUnrelatedHierarchy) {
                summaryText.value = `${ summaryText.value }<p style="margin-top: 16px;">${ t('titlePanel.leaseHierarchy.hierarchyRemoved') }</p>`
            }
        }
    }

    const onShowLogClick = () => {
        showLog.value = !showLog.value
    }

    const onRefreshClick = async () => {
        await refreshHierarchy()
    }

    const onStopClick = () => {
        stop()
    }

    const hasSelectedTitles = computed(() => {
        return selectedTitleNumbers.value.length > 0
    })

    const getUniqueKey = (item: LeaseHierarchyItemType) => {
        return item.titleNumber + item.parentTitleNumber + Date.now().toString()
    }

    const handleTitleSelected = (titleNumber: string) => {
        if (!selectedTitleNumbers.value.includes(titleNumber)) {
            selectedTitleNumbers.value.push(titleNumber)
        } else {
            selectedTitleNumbers.value.splice(selectedTitleNumbers.value.indexOf(titleNumber), 1)
        }
    }

    const allTitlesSelected = computed({
        get() {
            const allTitles = new Set([...hierarchyNodes.value.map(x => x.data.titleNumber)])
            return !isNullOrEmpty(selectedTitleNumbers.value) && selectedTitleNumbers.value.length === allTitles.size
        },
        set(value: boolean) {
            selectAll(value)
        },
    })

    const selectAll = (selectAll: boolean) => {
        if (selectAll) {
            selectedTitleNumbers.value = [...new Set([...hierarchyNodes.value.map(x => x.data.titleNumber)])]
        } else {
            selectedTitleNumbers.value = []
        }
    }

    const addSelectedTitlesToMatter = async () : Promise<void> => {
        isAddingTitles.value = true

        // move to group if selected
        if (selectedGroup.value) {
            await store.dispatch(MATTER_ADD_TITLES_TO_MATTER_AND_GROUP, {
                matterGroupId: selectedGroup.value.id,
                titles: Array.from(selectedTitleNumbers.value).map((t: string) => ({
                    titleNumber: t,
                    matterGroupId: selectedGroup.value.id,
                    selected: false,
                })),
                showPopup: true,
            })
        } else {
            await store.dispatch(MATTER_ADD_MULTIPLE_TITLES, {
                showPopup: !selectedGroup.value,
                titleNumbers: selectedTitleNumbers.value,
            })
        }

        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'TITLE DETAILS PANEL - Schedule of leases add titles to matter',
            metadata: {
                titleNumbers: selectedTitleNumbers.value,
                matterGroupId: selectedGroup.value?.id,
                allTitlesSelected,
            },
        })

        selectedTitleNumbers.value = []
        isAddingTitles.value = false
        const instance = getCurrentInstance()
        instance?.proxy?.$forceUpdate()
    }

</script>
<style scoped lang="scss">
    @import 'lease-hierarchy.scss';
</style>
