<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 24 24"
                  desc="Ordered register icon">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6 2h8l6 6v12a2 2 0 0 1-2 2H5.99A1.99 1.99 0 0 1 4 20l.01-16c0-1.105.885-2 1.99-2zm4.182 15.636-4.546-4.545 1.273-1.273 3.273 3.273 6.909-6.91 1.273 1.274-8.182 8.181z"
              fill="currentColor" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconDocExporting',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '16px',
            },
            height: {
                type: String,
                required: false,
                default: '16px',
            },
        },
    }
</script>
