<template>
    <section class="config-section map-snapshot-options">
        <map-snapshot-scale-options v-if="props.service.targetMap"
                                    :service="props.service" />
        <div class="map-snapshot-options__item">
            <label v-t="'snapshots.map.key.showKey'"
                   for="snapshot-map-show-key"
                   class="body-highlight" />
            <v-switch id="snapshot-map-show-key"
                      v-model="includeKeyInternal"
                      color="primary"
                      class="sketches-list__checkbox"
                      data-track="Export Assistant - Show key"
                      hide-details />
        </div>
        <map-key-config v-if="includeKeyInternal"
                        :config="props.service.keyConfig"
                        :show-actions="false"
                        :suggested-items="[]"
                        @item-removed="onKeyItemRemoved" />
        <ow-button v-if="hasKeyChanges"
                   data-test="map-snapshot-reset-key"
                   data-track="Export Assistant - Reset key"
                   is-secondary
                   small
                   @click="service.resetKey()">
            <span v-t="'snapshots.map.key.reset'" />
        </ow-button>
    </section>
</template>
<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
    import MapKeyConfig from '@/components/snapshots/map-snapshots/config-components/map-key-config.vue'
    import MapSnapshotScaleOptions
        from '@/components/snapshots/map-snapshots/config-components/map-snapshot-scale-options.vue'
    import { MapSnapshotRenderData } from '@/components/snapshots/map-snapshots/map-snapshot-models'
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'

    interface Props {
        service: MapSnapshotService
    }

    const props = defineProps<Props>()
    const { t } = useI18n()

    const onKeyItemRemoved = (item: KeyConfigItemModel) => {
        props.service.ignoreKeyItemIds.push(item.id)
    }

    const includeKeyInternal = computed<boolean>({
        get(): boolean {
            return (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeKey
        },
        set(newVal) {
            (props.service.snapshotModel.renderData as MapSnapshotRenderData).includeKey = newVal
        },
    })

    const hasKeyChanges = computed(() => {
        return props.service.ignoreKeyItemIds.length
    })

    defineExpose({
        onKeyItemRemoved,
        hasKeyChanges,
    })
</script>

<style lang="scss" scoped>
    @import 'map-snapshot-options.scss';
</style>
