<template>
    <!-- Title selection options -->
    <div v-if="show"
         class="d-flex justify-space-between matter-side-panel-title-actions"
         data-test="matter-side-panel-title-actions">
        <ow-button data-test="matter-side-panel-title-actions-walkthrough"
                   full-width
                   full-height
                   is-borderless
                   is-secondary
                   @click="onWalkthroughClick">
            {{ walkthroughButtonText }}
        </ow-button>
        <ow-button data-test="matter-side-panel-title-actions-title-organiser"
                   full-width
                   full-height
                   is-borderless
                   is-secondary
                   data-track="MAT-TITLE-LIST - Title Organiser button"
                   @click="onTitleOrganiserClick">
            <span v-t="'matter.titleOrganiser.name'" />
        </ow-button>
    </div>
</template>

<script>
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import owButton from '@/components/core/ow-button-ds.vue'
    import { Route } from '@/enums/route.enum'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import {
        MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
        MATTER_UPDATE_TITLE_SORT,
    } from '@/store/modules/matter/types'
    import {
        TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
        TITLE_SHOW_TITLE_BOUNDARY,
    } from '@/store/modules/titles/types'
    import { USER_MUTATE_SHOW_MAP_TITLES_NAV } from '@/store/mutation-types'

    export default {
        name: 'MatterSidePanelTitleActions',

        components: {
            owButton,
        },

        computed: {
            ...mapState({
                currentMatter: state => state.matter.currentMatter,
                isWalkthrough: state => state.walkthrough.enabled,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            show() {
                return !this.isSharedLinkView &&
                    !this.isWalkthrough
            },

            walkthroughButtonText() {
                if (this.currentMatter.walkthroughId === null) {
                    return this.$t('action.createWalkthrough')
                } else {
                    return this.$t('action.editWalkthrough')
                }
            },
        },

        methods: {
            ...mapMutations({
                setShowMapTitlesNav: USER_MUTATE_SHOW_MAP_TITLES_NAV,
                setTitleOrganiserEnabled: MATTER_MUTATE_TITLE_ORGANISER_ENABLED,
                setExpandedTitleNumber: TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
            }),

            ...mapActions({
                showTitleBoundary: TITLE_SHOW_TITLE_BOUNDARY,
            }),

            onWalkthroughClick() {
                this.setShowMapTitlesNav(false)
                this.setExpandedTitleNumber(null)
                this.showTitleBoundary(null)
                this.$router.push({ name: Route.MatterMapWalkthrough })
            },

            onTitleOrganiserClick() {
                this.setTitleOrganiserEnabled(true)
            },
        },
    }
</script>
<style lang="scss">
    @import 'matter-side-panel-title-actions.scss';
</style>
