<template>
    <div class="ow-icon-renderer d-flex py-2 align-center"
         :class="{
             'justify-center': props.align === 'center',
             'justify-end': props.align === 'right',
             'justify-start': props.align === 'left',
         }">
        <div class="d-flex align-center gc-3"
             :class="{
                 'mr-3': !props.noPadding,
             }">
            <span v-if="!hideAttributionText"
                  v-t="'reviewAssistant.document.orderedFrom'"
                  class="accents-small" />
            <component :is="component"
                       :text="showIconText"
                       width="75px"
                       height="24px" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        markRaw,
        ref,
        watch,
    } from "vue"

    import OwIconHmlr from "@/components/core/icons/ow-icon-hmlr.vue"
    import OwIconScotLIS from "@/components/core/icons/ow-icon-scotlis.vue"
    import { DOCUMENT_SOURCE } from "@/consts/document-source"
    const props = withDefaults(defineProps<{
        recordSource?: string,
        hideAttributionText?: boolean,
        align?: 'left' | 'right' | 'center',
        noPadding?: boolean,
    }>(), {
        hideAttributionText: false,
        align: 'right',
        noPadding: false,
        recordSource: DOCUMENT_SOURCE.HMLR,
    })

    const showIconText = computed(() => {
        return props.recordSource !== DOCUMENT_SOURCE.SCOTLAND
    })

    const component = ref()

    const getIconComponent = (datasource: String) => {
        switch (datasource) {
            case DOCUMENT_SOURCE.HMLR:
                return markRaw(OwIconHmlr)
            case DOCUMENT_SOURCE.SCOTLAND:
                return markRaw(OwIconScotLIS)
        }
    }

    watch(() => props.recordSource, () => {
        component.value = getIconComponent(props.recordSource)
    },{
        immediate: true,
    })
</script>
