export const MATTER_MAX_NAME_LENGTH = 200

export const MatterState = {
    Open: 0,
    Closed: 1,
}

/**
 * Array of available matter transaction types
 * @type {string[]}
 */
export const matterTypes = [
    'Acquisition',
    'Asset management',
    'Corporate M&A Property Diligence',
    'Development',
    'Disposal',
    'Finance/lending',
    'Investment',
    'Lease renewal/extension',
    'Other',
    'OW Training',
]

/**
 * This validates matter codes against the regex used by HMLR for reference text. The matter codes may be used by HMLR in correspondence
 * with the customer.
 * @see {@link https://landregistry.github.io/bgtechdoc/documents/official_copy_known/request_title_known_official_copy_v2_1.xsd}
 */
export const matterCodeValidationRegEx = new RegExp(/^([A-Za-z0-9\s~!"@#$%'()*+,\-./:;=>?[\\\]_{}^£&]*$)/)
