<template>
    <section :class="{ selected: inFocus }"
             class="title-panel-register-document">
        <header class="title-panel-register-document__header">
            <v-tabs v-model="currentTab"
                    class="title-panel-register-document__tabs"
                    density="default"
                    slider-color="primary">
                <v-tab :key="ReviewAssistantDocumentTab.TitleRegister"
                       :ripple="false"
                       class="title-panel-register-document__tabs--register"
                       data-test="title-details-panel-document-title-register-tab"
                       data-track="TITLE-DETAILS-PANEL - Title register tab">
                    <span v-t="'documents.titleRegister'"
                          class="button-small" />
                </v-tab>
                <v-tab :key="ReviewAssistantDocumentTab.TitlePlan"
                       :ripple="false"
                       class="title-panel-register-document__tabs--plan"
                       data-test="title-details-panel-document-title-plan-tab"
                       data-track="TITLE-DETAILS-PANEL - Title plan tab">
                    <span v-t="'documents.titlePlan'"
                          class="button-small" />
                    <span>
                        <ow-tooltip :position="OwTooltipPosition.Bottom"
                                    activator="parent">
                            <span v-t="'reviewAssistant.titlePlan.helpText'" />
                        </ow-tooltip>
                        <a href="#"
                           @click="handleIconClick">
                            <v-icon id="planHelpIcon"
                                    class="title-panel-register-document__tabs--icon">
                                $info
                            </v-icon>
                        </a>
                    </span>
                </v-tab>
                <v-tab v-show="hasRelatedDocument"
                       :key="ReviewAssistantDocumentTab.RelatedDocument"
                       :ripple="false"
                       class="title-panel-register-document__tabs--related"
                       data-test="title-details-panel-document-related-document-tab"
                       data-track="TITLE-DETAILS-PANEL - Related document tab">
                    <span class="button-small">{{ relatedDocumentMetadata?.documentTypeValue }}</span>
                    <ow-tooltip activator="parent"
                                position="top">
                        <span>{{ relatedDocumentMetadata?.documentType }}</span>
                    </ow-tooltip>
                </v-tab>
            </v-tabs>
            <div class="title-panel-register-document__header--controls">
                <ow-button :disabled="!documentMetadata"
                           data-test="title-details-panel-document-zoom-in"
                           data-track="TITLE-DETAILS-PANEL - Document zoom in"
                           full-height
                           icon
                           is-borderless
                           small
                           @click="zoomIn">
                    <v-icon>$zoom-in</v-icon>
                </ow-button>
                <ow-button :disabled="!documentMetadata"
                           data-test="title-details-panel-document-zoom-out"
                           data-track="TITLE-DETAILS-PANEL - Document zoom out"
                           full-height
                           icon
                           is-borderless
                           small
                           @click="zoomOut">
                    <v-icon>$zoom-out</v-icon>
                </ow-button>
                <div class="title-panel-register-document__header--controls-separator" />
                <ow-button :disabled="isDownloadingDocument || !documentMetadata"
                           data-test="title-details-panel-document-download-button"
                           data-track="TITLE-DETAILS-PANEL - Document download"
                           full-height
                           icon
                           is-borderless
                           small
                           @click="documentDownloadHandler">
                    <v-progress-circular v-if="isDownloadingDocument"
                                         :size="20"
                                         :width="3"
                                         color="primary"
                                         indeterminate />
                    <v-icon v-else>
                        $download
                    </v-icon>
                </ow-button>
            </div>
        </header>

        <div v-if="currentTab == ReviewAssistantDocumentTab.TitlePlan"
             class="title-panel-register-document__doc-order">
            <order-documents-card class="w-100"
                                  data-section="registerDocument"
                                  :current-matter-id="matterId"
                                  :is-ordering-allowed="isOrderingAllowed"
                                  :is-loading="isLoading"
                                  :official-copies="officialCopies"
                                  :selected-title="selectedTitle"
                                  :selected-title-number="titleNumber"
                                  :user-has-vts-charge="userHasVTSCharge"
                                  is-card
                                  no-padding
                                  hide-register />
            <div v-if="isUploadDocumentsEnabled"
                 class="title-panel-register-document__doc-order--upload">
                <span v-t="'documents.upload.alreadyGotDocuments'"
                      class="caption-regular" />
                <a v-t="'action.uploadDocument'"
                   class="caption-highlight"
                   data-test="title-panel-register-document-upload-documents-link"
                   @click="documentsUploadedHandler" />
            </div>
        </div>

        <ow-pdf-viewer v-if="documentMetadata"
                       :document-metadata="documentMetadata"
                       :document-url="documentUrl"
                       :next-search-result="nextSearchResult"
                       :page-number="pdfPage"
                       :search-text="currentSearchText"
                       :zoom="zoom"
                       data-test="title-details-panel-document-viewer"
                       fit-to="width"
                       @pages="numOfPages = $event"
                       @current-zoom="currentZoom = $event"
                       @document-scrolled="hasScrolledDocument = true"
                       @has-interacted="hasInteractedWithDocument = true"
                       @is-loaded="onDocumentLoaded"
                       @loading-error="hasLoadingError = $event"
                       @page-changed="updatePageNumber"
                       @search-loading="searchLoading = $event"
                       @search-result-count="searchResultCount = $event"
                       @selected-search-result-number="selectedSearchResultNumber = $event" />
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        nextTick,
        PropType,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwPdfViewer from '@/components/core/ow-pdf-viewer.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import OrderDocumentsCard from '@/components/title-panel/v2/cards/order-documents-card/order-documents-card.vue'
    import {
        HmlDocumentAvailabilityTextForCode,
        HmlrDocumentAvailabilityCode,
    } from '@/consts/document-availability'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { checkFlag } from '@/feature-flags'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { ReviewAssistantDocumentTab } from '@/interfaces/review-assistant.interface'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { getApiUri } from '@/utils/environment.utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'
    import doc = Mocha.reporters.doc;

    const emit = defineEmits<{
        (e: 'page-changed', pdfPage: number),
        (e: 'search-count-changed', payload: number),
        (e: 'tab-changed', payload: ReviewAssistantDocumentTab),
        (e: 'upload-documents'): void,
    }>()

    const props = defineProps({
        matterId: {
            type: [ Number, undefined ],
            required: true,
        },
        titleNumber: {
            type: [ String, undefined ],
            required: true,
        },
        registerMetadata: {
            type: Object as PropType<IDocumentMetadata>,
            required: false,
        },
        titlePlanMetadata: {
            type: Object as PropType<IDocumentMetadata>,
            required: false,
        },
        relatedDocumentMetadata: {
            type: Object as PropType<IDocumentMetadata>,
            required: false,
        },
        activeTab: {
            type: Number as PropType<ReviewAssistantDocumentTab>,
            default: ReviewAssistantDocumentTab.TitleRegister,
        },
        pdfPage: {
            type: Number,
            required: false,
        },
        searchText: {
            type: String,
            required: false,
        },
        searchIndex: {
            type: Number,
            required: false,
        },
        inFocus: {
            type: Boolean,
            default: true,
        },
        isOrderingAllowed: {
            type: Boolean,
            required: false,
            default: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    })

    const { t } = useI18n()
    const store = useStore()

    const loadedPDF = ref<boolean>(false)
    const hasLoadingError = ref<boolean>(false)
    const documentUrl = ref<string>('')
    const documentMetadata = ref<any>({})

    const isDownloadingDocument = ref(false)

    // Search
    const currentSearchText = ref<string>(null)
    const searchLoading = ref(false)
    const searchResultCount = ref(0)
    const selectedSearchResultNumber = ref<number>(null)
    const numOfPages = ref<number>(null)
    const nextSearchResult = ref<number>(0) // Use positive number to search forward and negative to search backwards

    // Zoom related properties
    const currentZoom = ref<number>(100)
    const zoom = ref<string>('reset') // Can be set to in/out/reset
    let zoomCounter = ref<number>(0) // Way to get zoom to trigger when clicked multiple times

    // Logging properties
    const hasInteractedWithDocument = ref(false)
    const hasScrolledDocument = ref(false)

    const isUploadDocumentsEnabled = computed<boolean>(() => checkFlag('upload-documents', false))

    const currentTab = computed({
        get(): ReviewAssistantDocumentTab {
            return props.activeTab
        },
        set(value: ReviewAssistantDocumentTab) {
            emit('tab-changed', value)
            // reset search when tab is changed
            currentSearchText.value = null // Clears the current PDF search
            currentSearchText.value = props.searchText
            nextSearchResult.value = 1
        },
    })

    const titleRegisterUrl = computed<string>((): string => {
        const url = props.registerMetadata?.documentDownloadUrl ?? props.registerMetadata?.downloadUrl
        return isNullOrWhitespace(url) ? '' : `${ getApiUri() }/${ url }`
    })
    const hasTitleRegister = computed((): boolean => {
        return !isNullOrWhitespace(titleRegisterUrl.value)
    })

    const isTitlePlanAvailable = computed((): boolean => {
        return props.titlePlanMetadata?.documentStatus !== HmlDocumentAvailabilityTextForCode[HmlrDocumentAvailabilityCode.Unavailable]
    })
    const titlePlanUrl = computed((): string => {
        const url = props.titlePlanMetadata?.documentDownloadUrl
        return isNullOrWhitespace(url) ? '' : `${ getApiUri() }/${ url }`
    })
    const hasTitlePlan = computed((): boolean => {
        return props.titlePlanMetadata && // Document metadata passed to the component
            props.titlePlanMetadata.documentStatus && // Doc status (along with URL) loaded from GQL API
            isTitlePlanAvailable.value && // Doc status is not 'Unavailable'
            !isNullOrWhitespace(titlePlanUrl.value) // GQL API did not return a blank URL
    })
    const officialCopies = computed(() => store.state.title.selectedTitle?.officialCopiesAvailability?.results)
    const selectedTitle = computed(() => store.state.title.selectedTitle)
    const userHasVTSCharge = computed(() => store.state.user.titleSummaryCharge !== null)

    const relatedDocumentUrl = computed((): string => {
        let url = props.relatedDocumentMetadata?.augmentedFilename
            ? `${ getApiUri() }/BusinessGateway/GetBGDocumentFileByFilename?filename=${ props.relatedDocumentMetadata?.augmentedFilename }`
            : null

        if (isNullOrWhitespace(url)) {
            url = props.relatedDocumentMetadata?.documentDownloadUrl
        }

        return url
    })
    const hasRelatedDocument = computed((): boolean => {
        return !isNullOrWhitespace(relatedDocumentUrl.value)
    })

    watch(() => props.searchText, async (newVal: string): Promise<void> => {
        await nextTick()
        currentSearchText.value = newVal
        nextSearchResult.value = 1
    }, { immediate: true })

    watch(() => searchLoading.value, (isLoading: boolean): void => {
        if (!isLoading) {
            emit('search-count-changed', searchResultCount.value)
        }
    }, { immediate: true })

    watch(() => props.searchIndex, (value: number): void => {
        nextSearchResult.value = value
    }, { immediate: true })

    watch(() => props.activeTab, (newValue: ReviewAssistantDocumentTab): void => {
        currentTab.value = newValue
    }, { immediate: true })


    const showTitleRegister = (): void => {
        // If there's no metadata with a url, then can't do anything
        if (!props.registerMetadata
            || isNullOrWhitespace(titleRegisterUrl.value)) {
            return
        }
        documentMetadata.value = props.registerMetadata
        documentUrl.value = titleRegisterUrl.value
    }

    const showTitlePlan = (): void => {
        // If there's no metadata with a url, then can't do anything
        if (!hasTitlePlan.value) {
            documentMetadata.value = null
            return
        }

        documentMetadata.value = props.titlePlanMetadata
        documentUrl.value = titlePlanUrl.value
    }

    const showRelatedDocument = (): void => {
        // If there's no metadata with a url, then can't do anything
        if (isNullOrWhitespace(relatedDocumentUrl.value)) {
            return
        }
        documentMetadata.value = props.relatedDocumentMetadata
        documentUrl.value = relatedDocumentUrl.value
    }

    watch(currentTab, (newTab): void => {
        isDownloadingDocument.value = false
        currentSearchText.value = null

        switch (newTab) {
            case ReviewAssistantDocumentTab.TitlePlan:
                showTitlePlan()
                break

            case ReviewAssistantDocumentTab.RelatedDocument:
                showRelatedDocument()
                break

            default:
                showTitleRegister()
                break
        }
    })

    watch(() => props.registerMetadata, () => {
        if (hasTitleRegister.value
            && currentTab.value === ReviewAssistantDocumentTab.TitleRegister) {
            showTitleRegister()
        }
    }, { deep: true, immediate: true })

    watch(() => props.titlePlanMetadata, () => {
        if (hasTitlePlan.value
            && currentTab.value === ReviewAssistantDocumentTab.TitlePlan) {
            showTitlePlan()
        }
    }, { deep: true, immediate: true })

    watch(() => props.relatedDocumentMetadata, () => {
        if (hasRelatedDocument.value) {
            showRelatedDocument()
        }
    }, { deep: true, immediate: true })

    const zoomIn = (): void => {
        zoom.value = `in${ zoomCounter.value }`
        zoomCounter.value++
    }

    const zoomOut = (): void => {
        zoom.value = `out${ zoomCounter.value }`
        zoomCounter.value--
    }

    const handleIconClick = () => {
        window.open(t('reviewAssistant.titlePlan.helpLink'), '_blank')
    }

    const updatePageNumber = (pdfPage: number): void => {
        emit('page-changed', pdfPage)
    }

    const documentsUploadedHandler = () => {
        emit('upload-documents')
    }

    const documentDownloadHandler = async (): Promise<void> => {
        isDownloadingDocument.value = true

        await DocumentsApi.downloadOtherDocument(documentMetadata.value.downloadUrl ?? documentMetadata.value.documentDownloadUrl, props.matterId)

        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Review Assistant - Export Document',
            metadata: {
                titleNumber: props.titleNumber,
                matterId: props.matterId,
                docFormat: '.pdf',
                documentType: documentMetadata.value.documentType,
            },
        })

        isDownloadingDocument.value = false
    }

    const onDocumentLoaded = (isLoaded: boolean): void => {
        loadedPDF.value = isLoaded
        if (currentTab.value === ReviewAssistantDocumentTab.TitleRegister
            && isLoaded
            && !isNullOrWhitespace(props.searchText)) {
            currentSearchText.value = null
            currentSearchText.value = props.searchText
        }
    }
</script>

<style lang="scss">
    @import 'document.scss';
</style>
