import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import {
    Layer,
    Tile as TileLayerCanvas,
} from 'ol/layer'
import TileLayer from 'ol/layer/WebGLTile'
import XYZ from 'ol/source/XYZ'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import olMap from 'ol/Map'
import { getOSDataHubAPIKey } from '@/utils/environment.utils'
import { LayerNames } from '@/consts/map-layers'
import { osLayerTileGrid } from '@/consts/map'

type OsImageTileLayerOptions = {
    layerName: string,
    getTargetMapFn?: () => olMap,
    snapshotConfig?: LayerSnapshotModel
    targetMap?: olMap,
}

type OsImageTileLayerRenderData = {
    layerName: string,
}
export class OsImageTileLayer implements IMapSnapshotLayer {
    public i18nNameRef = 'map.options.os'
    public name: string = LayerNames.OrdnanceSurvey
    private layer: TileLayerCanvas<XYZ> | TileLayer
    private options: OsImageTileLayerOptions
    private loadingTilesCount = 0

    constructor(args: OsImageTileLayerOptions) {
        this.options = args
        if (args.snapshotConfig) {
            this.initialiseFromSnapshotConfig(args.snapshotConfig)
        } else {
            this.layer = this.getOSDataHubTileLayer(args.layerName)
        }
        this.layer.set('getOwLayer', () => this)
        this.layer.getSource().on('tileloadstart', () => {
            this.loadingTilesCount++
        })
        this.layer.getSource().on(['tileloadend', 'tileloaderror'], () => {
            this.loadingTilesCount--
        })
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        return undefined
    }

    getLayer(): Layer {
        return this.layer
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: LayerNames.OrdnanceSurvey,
            configJson: JSON.stringify({
                layerName: this.options.layerName,
            }),
        }
    }

    initialiseFromSnapshotConfig(snapshotConfig: LayerSnapshotModel): void {
        const osConfig = JSON.parse(snapshotConfig.configJson) as OsImageTileLayerRenderData
        this.layer = this.getOSDataHubTileLayer(osConfig.layerName)
    }

    getOSDataHubTileLayer = (layerName) => {
        const layerOptions = {
            name: layerName,
            source: new XYZ({
                attributions: ['Contains OS data © Crown copyright and database rights ' + (new Date()).getFullYear()],
                crossOrigin: 'anonymous',
                tileGrid: osLayerTileGrid,
                url: `https://api.os.uk/maps/raster/v1/zxy/${ layerName }/{z}/{x}/{y}.png?key=${ getOSDataHubAPIKey() }`,
                projection: CoordinateSystemCode.EPSG27700,
            }),
            visible: true,
            updateWhileAnimating: false,
            updateWhileInteracting: false,
            zIndex: 0,
        }

        // TODO: Switch this for the WebGL Tile Layer when it is fixed - at the moment using it as the base layer causes issues with webgl context being lost
        return new TileLayerCanvas(layerOptions)
    }

    async setVisible(visible: boolean): Promise<void> {
        this.layer?.setVisible(visible)
        return Promise.resolve()
    }

    getVisible(): boolean {
        return this.layer?.getVisible()
    }

    public getIsLoading(): boolean {
        return this.loadingTilesCount > 0
    }
}
