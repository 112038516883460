import BasketItemArgSSComboModel from "./BasketItemArgSSComboModel"
import BasketItemCon29DwcModel from "./BasketItemCon29DwcModel"
import BasketItemHighwaysRaModel from "./BasketItemHighwaysRaModel"
import BasketItemLLC1Model from "./BasketItemLLC1Model"
import BasketItemModel from "./BasketItemModel"
import BasketItemTMGCon29Model from "./BasketItemTMGCon29Model"

class BasketItemModelFactory {
    public static createBasketItemModel(productId: string) {
        let basketItem: BasketItemModel
        switch (productId) {
            case 'TMGCon29':
                basketItem = new BasketItemTMGCon29Model()
                break
            case 'Con29DWC':
                basketItem = new BasketItemCon29DwcModel()
                break
            case 'TMGLLC1':
                basketItem = new BasketItemLLC1Model()
                break
            case 'HighwaysRA':
            case 'Highways':
                basketItem = new BasketItemHighwaysRaModel()
                break
            case 'ArgSSCombo':
                basketItem = new BasketItemArgSSComboModel()
                break
            default:
                basketItem = new BasketItemModel()
                break
        }
        return basketItem
    }
}

export default BasketItemModelFactory
