<template>
    <ow-base-icon :height="height"
                  :width="width"
                  desc="Site Visit Icon"
                  viewBox="0 0 24 24">
        <path d="M21.616 19.616c0 2.325-4.825 3.384-9.308 3.384S3 21.94 3 19.616c0-1.79 2.662-2.633 4.895-3.027a.847.847 0 0 1 .293 1.668c-2.692.472-3.453 1.213-3.497 1.372.13.443 2.669 1.679 7.617 1.679s7.487-1.236 7.617-1.706c-.045-.132-.81-.877-3.522-1.349a.847.847 0 0 1 .292-1.667c2.244.391 4.92 1.233 4.92 3.03zM8.923 14.539h.846v5.077c0 .466.38.845.847.845H14c.466 0 .847-.38.847-.845v-5.077h.845c.467 0 .847-.38.847-.847V8.616c0-.433-.68-1.384-1.859-1.521-.484-.093-1.417-.159-2.359-.159-.95 0-1.897.066-2.387.159-1.178.138-1.857 1.088-1.857 1.52v5.077c0 .467.38.847.846.847zm3.385-8.462a2.54 2.54 0 1 0-.001-5.079 2.54 2.54 0 0 0 0 5.079z"
              fill="#F89801" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconSiteVisit',

        components: {
            OwBaseIcon,
        },

        props: {
            width: {
                type: String,
                required: false,
                default: '24px',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
        },
    }
</script>
