export enum AssetMonitoringDataSource {
    UNKNOWN,
    HMLR,
    companiesHouse
}


export interface BaseRowItemModel{
    title: string,
    dataSource: AssetMonitoringDataSource,
    descriptionPath?: string
    titleNumber?: string,
    boundaryDifference?: number,
    isBoundaryLarger?: boolean,
}
