<template>
    <v-dialog v-model="showPopup"
              class="order-documents-popup"
              max-width="540">
        <v-card>
            <v-card-title class="order-documents-popup__title"
                          primary-title>
                <div>
                    <h6 class="content__heading">
                        {{ title }}
                    </h6>
                    <div v-if="hasSubtitle"
                         class="body-copy content__subtitle">
                        {{ subtitle }}
                    </div>
                    <div v-if="title === 'Change your address'"
                         class="body-copy">
                        To add a new address go to Account >>
                        <router-link :to="{ name: Route.SettingsAccountPage }"
                                     data-track="TITLE-DETAILS-PANEL - Go to basic info to update address">
                            Basic Info
                        </router-link>
                        .
                    </div>
                </div>
            </v-card-title>

            <v-card-text>
                <div v-if="showCurrentAddress"
                     class="d-flex flex-row justify-space-between align-center">
                    <div v-dompurify-html="currentAddress"
                         class="d-flex flex order-documents-popup__content--address" />
                    <div class="d-flex shrink">
                        <a data-track="TITLE-DETAILS-PANEL - Change address for copies filed order"
                           href="#"
                           @click.prevent="onChangeAddress">
                            Change the address
                        </a>
                    </div>
                </div>

                <div v-if="showAddressChoices"
                     class="d-flex">
                    <div v-if="showChangeAddresses"
                         class="d-flex">
                        <v-radio-group v-model="selectedAddress">
                            <v-radio :label="formattedOrganisationAddress"
                                     color="primary"
                                     data-track="TITLE-DETAILS-PANEL - Use organisation address for order"
                                     hide-details
                                     value="org" />

                            <v-radio v-if="hasAlternateAddress"
                                     :label="formattedAlternateAddress"
                                     color="primary"
                                     data-track="TITLE-DETAILS-PANEL - Use alternate address for order"
                                     hide-details
                                     value="alt" />
                        </v-radio-group>
                    </div>
                </div>

                <p v-if="hasWarningText"
                   class="order-documents-popup__content--text-warning">
                    {{ warningText }}
                </p>

                <p v-if="hasContentText"
                   class="order-documents-popup__content--text">
                    {{ contentText }}
                </p>
            </v-card-text>
            <ow-card-actions :has-secondary-button="showBackButton"
                             secondary-button-data-track="TITLE-DETAILS-PANEL - Back button on copies filed address popup"
                             primary-button-data-track="TITLE-DETAILS-PANEL - Confirm button on copies filed address popup"
                             :secondary-button-text="backButtonLabel"
                             :primary-button-text="confirmButtonLabel"
                             @secondary-button-click="onBack"
                             @primary-button-click="onConfirm" />
        </v-card>
    </v-dialog>
</template>

<script>
    import pluralize from 'pluralize'
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import { POPUP_VIEWS } from '@/components/title-panel/tabs/copies-filed-tab/popup-views'
    import { Route } from '@/enums/route.enum'
    import { USER_SAVE_WORKING_FROM_HOME_PREFERENCES } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'CopiesFiledPopup',
        components: {
            OwCardActions,
        },
        props: {
            show: {
                type: Boolean,
                default: false,
            },

            orderDetails: {
                type: Object,
                required: true,
            },
        },

        emits: [
            'close-popup',
            'order-docs',
        ],

        data: () => ({
            title: '',
            subtitle: '',
            contentText: '',
            warningText: '',
            showCurrentAddress: false,
            showAddressChoices: false,
            showCheckbox: false,
            selectedAddress: '',
            backButtonLabel: 'Back',
            confirmButtonLabel: 'Confirm',
            currentPopupView: POPUP_VIEWS.CONFIRM_ORDER,
            Route,
        }),

        computed: {
            ...mapState({
                organisationAddress: state => state.user.organisationAddress,
                userPreferences: state => state.user.preferences,
            }),

            showPopup: {
                get() {
                    return this.show
                },
                set() {
                    this.closePopup()
                },
            },

            showBackButton() {
                return !isNullOrWhitespace(this.backButtonLabel)
            },

            formattedOrganisationAddress() {
                return this.formatAddressText(this.organisationAddress, true)
            },

            formattedAlternateAddress() {
                return this.formatAddressText(this.userPreferences, true)
            },

            currentAddress() {
                if (this.selectedAddress === 'alt') {
                    return this.formatAddressText(this.userPreferences)
                }
                return this.formatAddressText(this.organisationAddress)
            },

            hasContentText() {
                return !isNullOrWhitespace(this.contentText)
            },

            hasSubtitle() {
                return !isNullOrWhitespace(this.subtitle)
            },

            hasWarningText() {
                return !isNullOrWhitespace(this.warningText)
            },

            selectedAddressType() {
                return this.userPreferences.useCustomPostalAddress ? 'alt' : 'org'
            },

            showChangeAddresses() {
                return this.currentPopupView === POPUP_VIEWS.CHANGE_ADDRESS
            },

            hasAlternateAddress() {
                return !isNullOrWhitespace(this.userPreferences.addressPostcode)
            },
        },

        watch: {
            currentPopupView() {
                this.setContentForView()
            },

            orderDetails: {
                handler() {
                    this.setContentForView()
                },
                deep: true,
            },
        },

        mounted() {
            this.setContentForView()
        },

        methods: {
            ...mapActions({
                saveWorkingFromHomePreferences: USER_SAVE_WORKING_FROM_HOME_PREFERENCES,
            }),

            /**
             * Handles the click event on the "back" button and determines which view to go back to
             */
            onBack() {
                switch (this.currentPopupView) {
                    case POPUP_VIEWS.CONFIRM_ORDER:
                        this.closePopup()
                        break

                    case POPUP_VIEWS.CHANGE_ADDRESS:
                        this.currentPopupView = POPUP_VIEWS.CONFIRM_ORDER
                        break
                }
            },

            /**
             * Handles the click event on the confirmation button and determines the action
             * as well as setting the next view.
             */
            async onConfirm() {
                const updatedUserPreferences = {
                    ...this.userPreferences,
                    useCustomPostalAddress: this.selectedAddress === 'alt',
                }

                switch (this.currentPopupView) {
                    case POPUP_VIEWS.CONFIRM_ORDER:
                        this.currentPopupView = POPUP_VIEWS.PROCESSING_CONFIRMATION
                        this.$emit('order-docs')
                        break

                    case POPUP_VIEWS.CHANGE_ADDRESS:
                        await this.saveWorkingFromHomePreferences(updatedUserPreferences)
                        this.currentPopupView = POPUP_VIEWS.CONFIRM_ORDER
                        break

                    case POPUP_VIEWS.PROCESSING_CONFIRMATION:
                        this.closePopup()
                        break
                }
            },

            /**
             * Handles the click event on the change address link
             */
            onChangeAddress() {
                this.currentPopupView = POPUP_VIEWS.CHANGE_ADDRESS
            },

            /**
             * Generic method to close the popup and reset it to it's original state
             */
            closePopup() {
                this.$emit('close-popup')
                this.currentPopupView = POPUP_VIEWS.CONFIRM_ORDER
            },

            /**
             * Format the provided address as either a single line or multiple lines
             * @param address {Object}
             * @param isSingleLine {Boolean}
             * @return {string} - The formatted string
             */
            formatAddressText(address, isSingleLine = false) {
                let output = `${ address.addressHouseNumber }${ isSingleLine ? ', ' : ',<br/>' }`

                if (!isNullOrWhitespace(address.addressStreet)) {
                    output += `${ address.addressStreet }${ isSingleLine ? ', ' : ',<br/>' }`
                }

                if (!isNullOrWhitespace(address.addressLocality)) {
                    output += `${ address.addressLocality }${ isSingleLine ? ', ' : ',<br/>' }`
                }

                output += `${ address.addressTown }. ${ address.addressPostcode }`

                return output
            },

            /**
             * Sets the content to be displayed in each of the views within the popup
             */
            setContentForView() {
                this.selectedAddress = this.selectedAddressType

                switch (this.currentPopupView) {
                    case POPUP_VIEWS.CONFIRM_ORDER:
                        this.title = 'Confirm your order'
                        this.subtitle = 'Any documents that the Land Registry sends via post will be delivered to this address:'
                        this.contentText = `You are ordering ${ pluralize('document', this.orderDetails.countOfDocsToOrder, true) } for £${ this.orderDetails.totalCost }.`
                        this.warningText = ''
                        this.backButtonLabel = 'Cancel'
                        this.confirmButtonLabel = `Order ${ this.orderDetails.countOfDocsToOrder } (£${ this.orderDetails.totalCost })`
                        this.showCheckbox = true
                        this.showCurrentAddress = true
                        this.showAddressChoices = false
                        break

                    case POPUP_VIEWS.CHANGE_ADDRESS:
                        this.title = 'Change your address'
                        this.subtitle = 'Choose from existing addresses.'
                        this.contentText = ''
                        this.warningText = ''
                        this.backButtonLabel = 'Back'
                        this.confirmButtonLabel = 'Confirm'
                        this.showCheckbox = false
                        this.showCurrentAddress = false
                        this.showAddressChoices = true
                        break

                    case POPUP_VIEWS.PROCESSING_CONFIRMATION:
                        this.title = 'Your order is being processed'
                        this.subtitle = ''
                        this.contentText = 'The documents will appear in your Document Library and the Official Copies section as they become available.'
                        this.warningText = 'Please do not refresh the browser while the order is processing.'
                        this.backButtonLabel = ''
                        this.confirmButtonLabel = 'Ok'
                        this.showCheckbox = false
                        this.showCurrentAddress = false
                        this.showAddressChoices = false
                        break
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './copies-filed-popup'
</style>
