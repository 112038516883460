import { ModelFieldConfig } from "@bryntum/grid"
import BasketItemModel from "./BasketItemModel"

export type LLC1ProductConfig = {
    numberOfParcels: number
}


class BasketItemLLC1Model extends BasketItemModel {
    static get fields(): Partial<ModelFieldConfig>[] {
        const fields = super.fields
        return [
            ...fields,
            { name: 'isConfigurable', type: 'boolean', defaultValue: true },
            { name: 'config', type: 'object', defaultValue: {
                numberOfParcels: 1,
            }},
        ]
    }

    override productOptionsToJson(): string {
        const productConfig = this.config as LLC1ProductConfig
        const result: LLC1ProductConfig = {
            numberOfParcels: productConfig?.numberOfParcels,
        }

        return JSON.stringify(result)
    }

    override jsonToProductOptions(json: string): void {
        const productConfig = JSON.parse(json) as LLC1ProductConfig
        this.config = productConfig
    }
}

export default BasketItemLLC1Model
