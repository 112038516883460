// core actions

// core getters
export const CORE_GET_APP_ELEMENT = 'GET_APP_ELEMENT'

// core mutations
export const CORE_MUTATE_PREVENT_TRANSITION = 'CORE_MUTATE_PREVENT_TRANSITION'
export const CORE_MUTATE_CONFIRMATION_DIALOG = 'CORE_MUTATE_CONFIRMATION_DIALOG'
export const CORE_MUTATE_INIT_CONFIRMATION_DIALOG = 'CORE_MUTATE_INIT_CONFIRMATION_DIALOG'
export const CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE = 'CORE_MUTATE_INIT_PREVENT_TRANSITION_STATE'
