<template>
    <div>
        <v-fade-transition disabled>
            <map-rollover-overlay v-show="show && !isDisabled"
                                  class="map-rollover"
                                  data-test="map-rollover"
                                  :overlay-id="overlayId"
                                  :max-categories="maxCategories"
                                  :categories="allCategories" />
        </v-fade-transition>

        <map-rollover-overlay ref="offscreenOverlay"
                              overlay-id="offscreen-overlay"
                              :style="{ position: 'fixed', left: '-10000%', top: '-10000%' }"
                              :max-categories="10"
                              :categories="allCategories" />
    </div>
</template>

<script lang="ts" setup>

    import { debounce } from 'lodash'
    import {
        Map,
        Overlay,
    } from 'ol'
    import {
        onBeforeUnmount,
        ref,
        watch,
    } from 'vue'

    import { IMapRolloverSection } from './common/map-rollover-interfaces'
    import MapRolloverOverlay from './map-rollover-overlay.vue'

    const allCategories = ref<Record<string, IMapRolloverSection[]>>({})
    const show = ref(false)
    const overlayId = 'map-rollover-overlay'
    const maxCategories = ref(10)

    const props = defineProps<{
        targetMap: Map | null
        isDisabled: boolean
    }>()

    const overlay = ref()
    const offscreenOverlay = ref()

    /**
     * Calculate the position and offset of the child overlay
     */
    const calculateOffsetOverlayPosition = (args: { x: number; y: number; offscreenWidth?: number; offscreenHeight?: number; mapWidth?: number; mapHeight?: number; offsetX?: number; offsetY?: number; attributePanelTop?: number }) => {
        let position = null
        let pointerOffset = [15, 15]
        const { x, y, offscreenWidth, offscreenHeight, mapWidth, mapHeight, offsetY, attributePanelTop } = args
        let offsetX = args.offsetX ?? 15

        const leftOrRightPosition = (x + offscreenWidth + offsetX) <= mapWidth ? 'left' : 'right'
        offsetX = leftOrRightPosition === 'left' ? offsetX : -offsetX
        const topPosition = 'top'

        if (y + offscreenHeight + offsetY <= mapHeight) {
            position = `${ topPosition }-${ leftOrRightPosition }`
            pointerOffset = [offsetX, offsetY]
        } else if (y - offscreenHeight - offsetY >= 0) {
            position = `bottom-${ leftOrRightPosition }`
            pointerOffset = [offsetX, -offsetY]
        } else if (y < (mapHeight / 2) && y - offscreenHeight - offsetY <= 0) {
            position = `top-${ leftOrRightPosition }`
            pointerOffset = [offsetX, -offsetY]
        } else {
            position = `bottom-${ leftOrRightPosition }`
            pointerOffset = [offsetX, offsetY]
        }

        // if attribute panel is visible, offset by its height
        if (y > attributePanelTop) {
            pointerOffset = [offsetX, attributePanelTop - y - 15]
        }
        return { position, offset: pointerOffset }
    }

    /**
     * Set all categories
     * @param layers ol layers with getRolloverTextForPoint method
     */
    const setAllCategories = (layers: any[]) => {
        // find first layer that allows click, except for sketches as other layers take precedence
        const clickableLayerId = layers.findIndex((layer: { allowClick: any; category: string }) => layer.allowClick && layer.category !== 'Sketches')
        if (clickableLayerId >= 0) {
            layers = layers.map((layer: any, i: any) => ({ ...layer, allowClick: i === clickableLayerId }))
        }

        // sort layers by allowClick and category
        layers.sort((a: { allowClick: any; category: string }, b: { allowClick: any; category: any }) => {
            if (a.allowClick && !b.allowClick) return -1
            if (!a.allowClick && b.allowClick) return 1
            return a.category.localeCompare(b.category)
        })

        // group layers by category
        const groupedCategories = layers.reduce((group: { [x: string]: any[] }, section: { category: string }) => {
            const category = section.category ? section.category.toLowerCase() : 'uncategorized'
            group[category] = group[category] || []
            group[category].push(section)
            return group
        }, {})

        // hide overlay if no categories
        if (Object.keys(groupedCategories).length === 0) {
            return false
        }

        // set categories and show overlay
        allCategories.value = groupedCategories
        return true
    }

    const render = (e: { originalEvent: MouseEvent }, x: number, y: number) => {
        const { targetMap } = props

        // position overlay
        requestAnimationFrame(() => {
            // get offscreen overlay element and dimensions
            const offscreenElement = offscreenOverlay.value.$el
            const { width: offscreenWidth, height: offscreenHeight } = offscreenElement.getBoundingClientRect()
            // get attribute panel dimensions
            const attributePanel = targetMap.getViewport().querySelector('.ol-attribution')
            const { top: attributePanelTop, height: attributePanelHeight } = attributePanel.getBoundingClientRect()
            // get map dimensions
            let mapHeight = targetMap.getViewport().clientHeight
            const mapWidth = targetMap.getViewport().clientWidth

            // reduce map height by attribute panel height
            mapHeight -= attributePanelHeight

            // can overlay fit in map height?
            const { position, offset } = calculateOffsetOverlayPosition({ x, y, offscreenWidth, offscreenHeight, mapWidth, mapHeight, offsetX: 15, offsetY: 15, attributePanelTop })

            // get max categories to display
            let maxCategoriesToDisplay = null
            let totalHeight = 0
            const padding = 100

            // get height of each category and add to total height until it exceeds map height
            offscreenElement.querySelectorAll('.map-rollover-overlay__section').forEach((categoryElement: { getBoundingClientRect: () => { (): any; new(): any; height: any } }, index: any) => {
                const categoryHeight = categoryElement.getBoundingClientRect().height
                if (categoryHeight > 0) {
                    totalHeight += categoryHeight
                    if (position.includes('top') && maxCategoriesToDisplay === null && (y + padding + totalHeight) > mapHeight) {
                        maxCategoriesToDisplay = index
                    } else if (position.includes('bottom') && maxCategoriesToDisplay === null && (y - padding - totalHeight) < 0) {
                        maxCategoriesToDisplay = index
                    }
                }
            })

            // if overlay doesn't fit in the map, set max categories to display
            const fitsInRemainingSpace = (mapHeight - y) - offscreenHeight > 0
            if (!fitsInRemainingSpace) {
                maxCategories.value = maxCategoriesToDisplay ?? Object.keys(allCategories.value).length
            } else {
                maxCategories.value = Object.keys(allCategories.value).length
            }

            if (maxCategories?.value > 10) {
                maxCategories.value = 10
            }

            // set position and offset
            overlay.value.setPositioning(position)
            overlay.value.setOffset([offset[0], offset[1]])
            overlay.value.setPosition(targetMap.getEventCoordinate(e.originalEvent))
            show.value = true
        })
    }

    // target map pointer move event handler
    const handlePointerMove = (e: { pixel: any; originalEvent: MouseEvent }) => {
        // get visible layers with getRolloverTextForPoint method
        const visibleLayers = props.targetMap.getLayers().getArray()
            .filter(layer => layer.getVisible() && layer.get('getRolloverTextForPoint'))

        // get rollover text for each layer
        // filter out empty results and reverse order so that top layer is first
        const layers = visibleLayers.map(layer => layer.get('getRolloverTextForPoint')(props.targetMap, e.pixel),
        ).filter(result => result).sort((a, b) => b.order - a.order)

        // set categories and show overlay
        if (!setAllCategories(layers)) {
            show.value = false
            return
        }

        // get pixel coordinates from event and render overlay
        const [x, y] = props.targetMap.getPixelFromCoordinate(props.targetMap.getEventCoordinate(e.originalEvent))
        render(e, x, y)
    }

    // remove event listener on unmount
    onBeforeUnmount(() => {
        props.targetMap.un('pointermove', handlePointerMove)

        const viewport = props.targetMap.getViewport()
        viewport.removeEventListener('mouseleave', hideOverlay)
    })

    const hideOverlay = () => {
        show.value = false
    }

    // watch for targetMap to be set
    watch(() => props.targetMap, () => {
        const { targetMap } = props

        if (targetMap) {
            // calculate offset depending on map size
            const offset = [15, 15]

            // if overlay exists, remove existing
            if (overlay.value) {
                // remove event listener
                targetMap.un('pointermove', handlePointerMove)
                targetMap.removeOverlay(overlay.value)
            }

            // add overlay to map
            overlay.value = new Overlay({
                id: overlayId,
                element: document.getElementById(overlayId),
                offset,
                stopEvent: false,
                className: 'map-rollover',
            })
            // NOTE: Might need to reduce this debounce depending on UX, bigger debounce quicker map actions, lower debounce less responsive rollovers
            targetMap.on('pointermove', debounce(handlePointerMove, 25))
            targetMap.addOverlay(overlay.value)

            // hide overlay on mouse leave
            const viewport = targetMap.getViewport()
            viewport.addEventListener('mouseleave', hideOverlay)
        }
    }, { immediate: true })

    defineExpose({
        show,
        overlay,
        render,
    })

</script>
