import {
    Draw,
    Modify,
    Snap,
} from 'ol/interaction'
import {
    SketchMode,
    SnappingMode,
} from '@/enums/sketches-enums'
import actions from '@/store/modules/sketches/actions'
import { Feature } from 'ol'
import { ISketch } from './types/sketch'
import mutations from '@/store/modules/sketches/mutations'
import olMap from 'ol/Map'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { StyleLike } from 'ol/style/Style'

export interface ISketchesState {
    initialising: boolean
    sketches: Array<ISketch>
    loading: boolean
    collapsedList: boolean,
    currentMode: SketchMode,
    currentSketch: ISketch | null,
    highlightedSketch: ISketch | null,
    selectedSketches: Array<ISketch>
    styleCache: Map<string, StyleLike>
    highlightStyleCache: Map<string, StyleLike>

    // Shows existing sketches.
    layer: VectorLayer<VectorSource>

    // Used for drawing new (or editing existing) sketches.
    drawLayer: VectorLayer<VectorSource>
    drawInteraction: Draw
    modifyInteraction: Modify
    snapInteraction: Snap
    snapInteractionFeatures: Feature[]
    showBulkActions: boolean
    snappingMode: SnappingMode
    targetMap: olMap,
    isDrawing: boolean,

    // Used to debounce saving changes to the server.
    debouncedSaveChanges: number | null,
    sketchIdsWithPendingChanges: Array<number>,
    showSketchModeOptions: boolean,
    showNameDescriptionModal: boolean,
    nameDescriptionModalSketch: ISketch | null,
    showSketchSettingModal: boolean,
    sketchSettingModalSketch: ISketch | null,
    history:{
        currentSketchGeoJson: Array<string>,
        currentSketchGeoJsonIndex?: number,
    },
    debouncedOnSketchChange: number | null, // (addToHistory: boolean) => Promise<void>,
    makingChangesWithUndoRedo: boolean,

    enableSketchesMapSelection: boolean,
    sketchToActivateOnLoad: number | null,

    highlightedSketchTooltipText: string[],
    showImportTitlesModal: boolean,
}

const state: ISketchesState = {
    styleCache: new Map<string, StyleLike>(),
    highlightStyleCache: new Map<string, StyleLike>(),
    initialising: false,
    sketches: [],
    layer: null,
    loading: false,
    collapsedList: false,
    currentMode: SketchMode.None,
    currentSketch: null,
    selectedSketches: [],
    showBulkActions: false,
    drawLayer: null,
    drawInteraction: null,
    modifyInteraction: null,
    snapInteraction: null,
    snapInteractionFeatures: null,
    snappingMode: SnappingMode.None,
    targetMap: null,
    isDrawing: false,
    debouncedSaveChanges: null,
    sketchIdsWithPendingChanges: [],
    showSketchModeOptions: true,
    showNameDescriptionModal: false,
    nameDescriptionModalSketch: null,
    showSketchSettingModal: false,
    sketchSettingModalSketch: null,
    highlightedSketch: null,
    enableSketchesMapSelection: false,
    history: {
        currentSketchGeoJson: [],
        currentSketchGeoJsonIndex: 0,
    },
    debouncedOnSketchChange: null,
    makingChangesWithUndoRedo: false,
    sketchToActivateOnLoad: null,
    highlightedSketchTooltipText: [''],
    showImportTitlesModal: false,
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
}
