import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
import { IDocumentOrder } from '@/interfaces/store/document-ordering/document-order.interface'
import { IDocumentOrderNotificationData } from '@/interfaces/document-order-notification-data-interface'

/**
 * Used to track updates on the status of an in-progress order
 * @implements {IDocumentOrder}
 */
export class DocumentOrder implements IDocumentOrder {
    public orderId: string
    public status: number
    public notificationData: IDocumentOrderNotificationData
    public hmlrAvailabilityCode: string
    readonly request: DocumentOrderRequest
    readonly orderType: string

    constructor(
        orderType: string,
        request: DocumentOrderRequest,
        hmlrAvailabilityCode?: string,
        orderId?: string,
        status?: number,
        notificationData?: IDocumentOrderNotificationData) {
        this.orderId = orderId
        this.orderType = orderType
        this.request = request
        this.status = status
        this.notificationData = notificationData
        this.hmlrAvailabilityCode = hmlrAvailabilityCode
    }

    get keyValue(): string {
        return this.request.keyValue
    }

    /**
     * Just for debugging purposes
     */
    toJSON(): IDocumentOrder {
        return {
            keyValue: this.keyValue,
            orderId: this.orderId,
            orderType: this.orderType,
            request: this.request,
            status: this.status,
            notificationData: this.notificationData,
            hmlrAvailabilityCode: this.hmlrAvailabilityCode,
        }
    }
}
