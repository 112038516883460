import { AppVersion } from '@/models/app-version.model'
import {
    LOGGING_HEAP_INITIALISE,
    LOGGING_HEAP_RESET_IDENTITY,
    LOGGING_HEAP_TRACK_EVENT,
    LOGGING_REMOVE_STATE_PROPERTY,
    LOGGING_SET_STATE_PROPERTIES,
    LOGGING_SET_STATE_PROPERTY,
} from '@/store/mutation-types'
import { supportsWebGL } from '@/utils/map-utils'

import { getDatadogSessionUrl } from './datadog'

export const actions = {
    async [LOGGING_HEAP_INITIALISE]({ state, rootState }) {
        // Don't initialise Heap for e2e testing
        if (state.initialised.heap === false && !window?.Cypress) {
            state.initialised.heap = true
            if (window?.owvars?.underTest !== true) {
                // Initialise Heap for non-testing use
                const user = rootState.user
                // eslint-disable-next-line no-unused-expressions
                window?.heap?.load(rootState.config.settings.heapAppId, {
                    secureCookie: true,
                })
                // eslint-disable-next-line no-unused-expressions
                window?.heap?.identify(user.email)

                // Detect if the user has webgl support. Increasingly the visualisation aspects of the app use this
                // and we want to determine if enough users have it enabled that we can rely on it or whether we instead
                // need to use fallbacks.
                const hasWebGl = supportsWebGL() ?? false

                // These are sent once when the session is initialised
                window?.heap?.addUserProperties({
                    'User ID': user.id,
                    'Organisation ID': user.organisationId,
                    Organisation: user.organisation,
                    'Organisation Industry': user.organisationIndustry,
                    'Organisation Status': user.organisationStatus,
                    'Title Summary Charge': user.titleSummaryCharge,
                    'Title Summary Charge Amount': user.titleSummaryCharge,
                    'Uses Client Codes': user.usesClientCodes,
                    'Mandatory Matter Codes': user.mandatoryMatterCodes,
                    'Pricing Model Id': user.pricingModelId,
                    'Pricing Model Name': user.pricingModelName,
                    'Matter Link Share User': rootState.linkShareClient.isMatterLinkShareUser,
                    'Has WebGL support': hasWebGl,
                })

                // Clear the event properties from the previous session
                window?.heap?.clearEventProperties()

                // These get added to every LOGGING_HEAP_TRACK_EVENT
                window?.heap?.addEventProperties({
                    matterId: -1,
                    matterType: 'None',
                    appVersion: AppVersion.currentAppVersion,
                    sessionURL: getDatadogSessionUrl(),
                })
                if (window?.heap) {
                    state.active.heap = true
                }
            }
        }
    },

    [LOGGING_HEAP_TRACK_EVENT](context, request) {
        // request - {type: 'Some Event', metadata: {some: 'object'}}
        if (context.state.active.heap === true) {
            window.heap.track(request.type, request.metadata)
        }
    },

    [LOGGING_HEAP_RESET_IDENTITY](context) {
        if (context.state.active.heap === true) {
            window.heap.resetIdentity()
        }
    },

    [LOGGING_SET_STATE_PROPERTY](context, request) {
        // request - {property: "Title", value: "TGL50538"}

        // Heap
        if (window.heap?.addEventProperties) {
            window.heap.addEventProperties({ [request.property]: request.value })
        }
    },

    [LOGGING_SET_STATE_PROPERTIES](context, request) {
        // request - {someProperty: "Title", someOtherProperty: "SomeOtherValue"}

        // Heap
        if (window.heap?.addEventProperties) {
            window.heap.addEventProperties(request)
        }
    },

    [LOGGING_REMOVE_STATE_PROPERTY](context, propertyName) {
        // Heap
        if (context.state.active.heap === true) {
            window.heap.removeEventProperty(propertyName)
        }
    },
}
