<template>
    <ow-tooltip :disabled="isDisabled"
                :position="tooltipPosition"
                activator="parent"
                max-width="300px"
                class="ow-lock-tooltip">
        <div class="pa-2 d-flex align-center">
            <v-icon class="ow-lock-tooltip__icon label-caps-medium mr-4">
                $lock
            </v-icon>
            <div class="ow-lock-tooltip__message d-flex flex-column">
                <template v-if="tooltip || !featureId">
                    <span class="accents-small">{{ tooltip }}</span>
                </template>
                <template v-else>
                    <div class="accents-highlight pb-1">
                        {{ tooltipTitle }}
                    </div>
                    <div class="accents-small">
                        {{ t('licencing.tooltip', { licence: licenceType }) }}
                    </div>
                </template>
            </div>
        </div>
    </ow-tooltip>
</template>

<script lang="ts" setup>
    import { computed } from "vue"
    import { useI18n } from "vue-i18n"

    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"

    const props = withDefaults(defineProps<{
        tooltip?: string
        featureId?: FeatureId
        licenceType?: string
        tooltipPosition?: OwTooltipPosition,
        isDisabled?: boolean
    }>(), {
        tooltipPosition: OwTooltipPosition.Bottom,
    })
    const { t } = useI18n()
    const tooltipTitle = computed(() => {
        let friendlyFeature = t(`licencing.features.${ props?.featureId }`)
        if (!friendlyFeature) {
            friendlyFeature = props?.featureId
        }
        return t('licencing.tooltipTitle', { feature: friendlyFeature })
    })
</script>

<style lang="scss">
@import './ow-lock-tooltip.scss';
</style>
