<template>
    <!-- "input" event gets emitted when v-dialog "value" changes
     that's why we have to use "input" and "value", reserved keyword we cannot change -->
    <v-dialog :model-value="modelValue"
              data-test="app-dialog"
              :max-width="maxWidth"
              width="100%"
              :persistent="persistent"
              @update:model-value="onInput">
        <v-card :class="{'has-subtitle': subtitle, [additionalClass]: additionalClass}"
                :data-test="dataTestAttribute"
                class="app-dialog">
            <v-card-title class="app-dialog__header-wrapper d-flex justify-space-between">
                <div class="app-dialog__header">
                    <v-icon v-if="icon"
                            class="app-dialog__icon">
                        {{ icon }}
                    </v-icon>
                    <div class="app-dialog__title-wrapper">
                        <h2 class="app-dialog__title"
                            data-test="app-dialog-title">
                            {{ title }}
                        </h2>
                        <div v-if="subtitle"
                             class="app-dialog__subtitle"
                             data-test="app-dialog-subtitle">
                            {{ subtitle }}
                        </div>
                    </div>
                    <div v-if="hasBetaLabel"
                         class="beta-label"
                         data-test="app-dialog-beta-label">
                        BETA
                    </div>
                </div>
                <v-btn class="app-dialog__icon-close"
                       variant="text"
                       icon
                       data-test="app-dialog-icon-close"
                       @click="closeDialog">
                    <v-icon>$close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text v-if="!hideSlot">
                <slot />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    /**
     * @deprecated Use the DS ow-modal instead
     */
    export default {
        name: 'AppDialog',

        props: {
            title: {
                type: String,
                required: true,
            },
            subtitle: {
                type: String,
                required: false,
                default: '',
            },
            icon: {
                type: String,
                required: false,
                default: '',
            },
            hasBetaLabel: {
                type: Boolean,
                required: false,
            },
            modelValue: {
                type: Boolean,
                required: false,
            },
            persistent: {
                type: Boolean,
                required: false,
            },
            additionalClass: {
                type: String,
                required: false,
                default: null,
            },
            hideSlot: {
                type: Boolean,
                required: false,
            },
            dataTestAttribute: {
                type: String,
                required: false,
                default: null,
            },
            maxWidth: {
                type: String,
                required: false,
                default: '555',
            },
        },
        emits: ['update:modelValue'],

        methods: {

            // "input" event gets emitted when v-dialog "value" changes
            // that's why we have to use "input" and "value", reserved keyword we cannot change
            onInput() {
                this.$emit('update:modelValue', this.$event)
            },

            closeDialog() {
                this.$emit('update:modelValue', false)
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-dialog';
</style>
