/**
 * Used to provide a hint that the user should not expect to see a value
 * in this column for freehold titles. See usage in gridConfig.js.
 */
export const freeholdNotApplicableColumnRenderer = ({ record, value, isExport }) => {
    if (!isExport) {
        if (record.tenureType === 'Freehold') {
            return 'N/A as title is freehold'
        }
        return (value || value === 0) ? value : 'N/A'
    } else {
        if (record.tenureType === 'Freehold') {
            return null
        } else {
            return value
        }
    }
}
