import {
    IMapSnapshotLayer,
} from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { LayerNames } from '@/consts/map-layers'
import VectorTileLayer from 'ol/layer/VectorTile'
import { hexToRGBArray } from '@/utils/colour-utils'
import { Colors } from '@/enums/colors.enum'
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import VectorTileSource from 'ol/source/VectorTile'
import MVT from 'ol/format/MVT'
import { getGeoserverApiUri } from '@/utils/environment.utils'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { geoserver27700TileGrid } from '@/store/modules/map/layers/geoserver-27700-tilegrid'
import { getTitleNumbersForVectorTileFeature } from '@/store/modules/map/layers/hmlr'
import { Layer } from 'ol/layer'
import { Source } from 'ol/source'
import LayerRenderer from 'ol/renderer/Layer'
import i18n from '@/plugins/i18n'
import {
    Map,
} from 'ol'
import { toRaw } from 'vue'

import { FeatureLike } from 'ol/Feature'

import {
    IMapRollover,
    IMapRolloverOptions,
    IMapRolloverSectionItem,
} from '@/components/map-rollover/common/map-rollover-interfaces'
import { BaseMvtDataLayer } from './base-mvt-data-layer'
import { layerEquals } from '@/utils/map-utils'

export type NpsLayerConstructorParams = {
    layerName?: string,
    showFreeholdsGetterFn?: () => boolean,
    showLeaseholdsGetterFn?: () => boolean,
    emphasiseNPSCoverageGetterFn?: () => boolean
    clickedTitleNosGetterFn?: () => Array<string>
    snapshotConfig?: LayerSnapshotModel
}

type NpsLayerRenderData = {
    showFreeholds: boolean
    showLeaseholds: boolean
    showUnregisteredLand: boolean
}

export class NpsLayer implements IMapSnapshotLayer, IMapRollover {
    public name:string = LayerNames.NPS
    private loadingTilesCount = 0
    private npsStyleCache = new Map()
    private layer: VectorTileLayer
    private constructorArgs: NpsLayerConstructorParams
    rolloverOptions: IMapRolloverOptions

    constructor(args: NpsLayerConstructorParams) {
        this.constructorArgs = args
        if (args.snapshotConfig) {
            this.initialiseFromSnapshotConfig(args.snapshotConfig)
        } else {
            this.layer = this.getNpsVectorTileLayer(
                args.showFreeholdsGetterFn,
                args.showLeaseholdsGetterFn,
                args.emphasiseNPSCoverageGetterFn,
                args.clickedTitleNosGetterFn,
            )
        }

        this.layer.getSource().on('tileloadstart', () => {
            this.loadingTilesCount++
        })
        this.layer.getSource().on(['tileloadend', 'tileloaderror'], () => {
            this.loadingTilesCount--
        })
    }

    async setVisible(visible: boolean): Promise<void> {
        this.layer?.setVisible(visible)
        return Promise.resolve()
    }

    getVisible(): boolean {
        return this.layer?.getVisible()
    }

    getLayer(): Layer<Source, LayerRenderer<any>> {
        return this.layer
    }

    getKeyItems(): KeyConfigItemModel[] {
        let result:Array<KeyConfigItemModel> = []
        if (this.constructorArgs.showFreeholdsGetterFn()) {
            result.push({
                id: 'freeholds',
                label: i18n.global.t('map.options.freeholds'),
                style: {
                    strokeColour: Colors.NpsLayerFreehold,
                    fillOpacity: 0.5,
                },
            })
        }
        if (this.constructorArgs.showLeaseholdsGetterFn()) {
            result.push({
                id: 'leaseholds',
                label: i18n.global.t('map.options.leaseholds'),
                style: {
                    strokeColour: Colors.NpsLayerLeasehold,
                    fillOpacity: 0.5,
                },
            })
        }
        if (this.constructorArgs.emphasiseNPSCoverageGetterFn()) {
            result = [
                {
                    id: 'unregisteredLand',
                    label: i18n.global.t('map.options.unregisteredLand'),
                    style: {
                        strokeColour: Colors.EmphasiseUnregisteredColour,
                        fillOpacity: 0.5,
                    },
                },
            ]
        }
        return result
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: LayerNames.NPS,
            configJson: JSON.stringify({
                showFreeholds: this.constructorArgs.showFreeholdsGetterFn(),
                showLeaseholds: this.constructorArgs.showLeaseholdsGetterFn(),
                showUnregisteredLand: this.constructorArgs.emphasiseNPSCoverageGetterFn(),
            }),
        }
    }

    initialiseFromSnapshotConfig(snapshotConfig: LayerSnapshotModel): void {
        const npsConfig = JSON.parse(snapshotConfig.configJson) as NpsLayerRenderData
        this.constructorArgs.showFreeholdsGetterFn = () => npsConfig.showFreeholds
        this.constructorArgs.showLeaseholdsGetterFn = () => npsConfig.showLeaseholds
        this.constructorArgs.emphasiseNPSCoverageGetterFn = () => npsConfig.showUnregisteredLand
        this.layer = this.getNpsVectorTileLayer(
            () => npsConfig.showFreeholds,
            () => npsConfig.showLeaseholds,
            () => npsConfig.showUnregisteredLand,
            () => null)
    }

    getNpsVectorTileLayer = (
        showFreeholdsGetterFn,
        showLeaseholdsGetterFn,
        emphasiseNPSCoverageGetterFn,
        clickedTitleNosGetterFn = null,
    ) => {
        const result = new VectorTileLayer({
            visible: true,
            preload: 0,
            zIndex: 10,
            style: (feature) => {
                const properties = feature.getProperties()
                const titleNumbers = getTitleNumbersForVectorTileFeature(feature)

                const isSelected = clickedTitleNosGetterFn()?.some(x => titleNumbers.includes(x))

                if (!showFreeholdsGetterFn() || !showLeaseholdsGetterFn()) {
                    // Return empty style for polygons if we don't want to show all of them
                    if (showFreeholdsGetterFn() === true &&
                        properties.freehold === undefined &&
                        properties.other === undefined) {
                        return null
                    } else if (showLeaseholdsGetterFn() === true &&
                        properties.leasehold === undefined &&
                        properties.other === undefined) {
                        return null
                    }
                }

                // Style what's left
                let colour = hexToRGBArray(Colors.NpsLayerOther)
                let opacity = 0.1
                let zIndex = 500
                if (!emphasiseNPSCoverageGetterFn()) {
                    // Default leasehold / freehold / other styles
                    if (properties.freehold != null) {
                        colour = hexToRGBArray(Colors.NpsLayerFreehold)
                        opacity += properties.freehold.split(',').length / 20
                        zIndex = 100
                    } else if (properties.leasehold != null) {
                        colour = hexToRGBArray(Colors.NpsLayerLeasehold)
                        opacity += properties.leasehold.split(',').length / 20
                        zIndex = 200
                    } else {
                        opacity += properties.other.split(',').length / 20
                    }
                    if (opacity > 1) {
                        opacity = 1
                    }
                } else {
                    // Styles when 'unregistered land' is selected
                    if (properties.tenure.includes('CN')) {
                        // Show cautions against first registration ('CN') in a different style to the rest...
                        colour = hexToRGBArray(Colors.CautionAgainstFirstRegistrationLayer)
                        opacity = 0.3
                    } else {
                        // ... which should be grey
                        colour = hexToRGBArray(Colors.EmphasiseUnregisteredColour)
                        opacity = 0.8
                        zIndex = 200
                    }
                }

                // Store/retrieve a reference to the style instead of creating 10's of thousands of style objects with the same properties.
                const hashValue = `${ zIndex }-${ isSelected }-${ colour }-${ opacity }`
                const existingStyle = this.npsStyleCache.get(hashValue)
                if (existingStyle !== undefined) {
                    return existingStyle
                }

                // Return the resulting style
                const result = new Style({
                    zIndex,
                    fill: isSelected
                        ? null
                        : new Fill({
                            color: `rgba(${ colour },${ opacity })`,
                        }),
                    stroke: new Stroke({
                        color: `rgb(${ colour })`,
                        width: 1,
                    }),
                })
                this.npsStyleCache.set(hashValue, result)
                return result
            },
            source: new VectorTileSource({
                format: new MVT(),
                url: `${ getGeoserverApiUri() }/gwc/service/tms/1.0.0/ow:nps_deduplicated@${ CoordinateSystemCode.EPSG27700 }@pbf/{z}/{x}/{-y}.pbf`,
                tileGrid: geoserver27700TileGrid,
                transition: 0,
                projection: CoordinateSystemCode.EPSG27700,
                overlaps: true,
            }),
            renderBuffer: 100,
            updateWhileInteracting: false,
            updateWhileAnimating: false,
            maxResolution: 11,
        })
        result.set('getOwLayer', () => this)
        result.set('name', this.name)
        result.set('getRolloverTextForPoint', BaseMvtDataLayer.getRolloverTextForPoint.bind(this, this.layer, this.getRolloverOptions(), null))
        return result
    }

    getIsLoading(): boolean {
        return this.loadingTilesCount > 0
    }

    public getRolloverOptions(): IMapRolloverOptions {
        return {
            showPrimary: true,
            sortOrder: 100,
            allowClick: true,
            category: i18n.global.t(`map.rollover.${ this.name }.category`),
            source: i18n.global.t(`map.rollover.${ this.name }.source`),
            getFeatures: (targetMap, pixel): Record<string, FeatureLike[]> => {
                const freehold = []
                const leasehold = []
                const other = []
                targetMap.getFeaturesAtPixel(pixel, {
                    layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
                }).forEach(feature => {
                    if (feature.getProperties().freehold) {
                        // if comma separated list of freehold titles, split into separate features
                        const freeholdTitles = feature.getProperties().freehold.split(',')
                        freeholdTitles.forEach(title => {
                            freehold.push(title)
                        })
                    } else if (feature.getProperties().leasehold) {
                        const leaseholdTitles = feature.getProperties().leasehold.split(',')
                        leaseholdTitles.forEach(title => {
                            leasehold.push(title)
                        })
                    } else {
                        const otherTitles = feature.getProperties()?.other.split(',')
                        otherTitles.forEach(title => {
                            other.push(title)
                        })
                    }
                })

                if (freehold.length === 0 && leasehold.length === 0 && other.length === 0) {
                    return null
                }

                return {
                    freehold: this.constructorArgs.showFreeholdsGetterFn ? freehold : [],
                    leasehold: this.constructorArgs.showLeaseholdsGetterFn ? leasehold : [],
                    other,
                }
            },
            addItems: (features: Record<string, FeatureLike[]>): IMapRolloverSectionItem[] => {
                const result: IMapRolloverSectionItem[] = []
                if (this.constructorArgs.showFreeholdsGetterFn && features.freehold?.length > 0) {
                    result.push({
                        primary: `${ i18n.global.t(`map.rollover.${ this.name }.freeholds`) } (${ features.freehold.length })`,
                        extended: features.freehold.join(', '),
                        style: {
                            fillColour: Colors.NpsLayerFreehold,
                        },
                    })
                }

                if (this.constructorArgs.showLeaseholdsGetterFn && features.leasehold?.length > 0) {
                    result.push({
                        primary: `${ i18n.global.t(`map.rollover.${ this.name }.leaseholds`) } (${ features.leasehold.length })`,
                        extended: features.leasehold.join(', '),
                        style: {
                            fillColour: Colors.NpsLayerLeasehold,
                        },
                    })
                }

                if (features.other?.length > 0) {
                    result.push({
                        primary: `${ i18n.global.t(`map.rollover.${ this.name }.other`) } (${ features.other.length })`,
                        extended: features.other.join(', '),
                        style: {
                            fillColour: Colors.NpsLayerOther,
                        },
                    })
                }

                return result
            },
        }
    }
}
