<template>
    <v-expansion-panels v-model="model"
                        class="mt-1"
                        data-test="register-entries"
                        variant="accordion"
                        multiple>
        <register-item v-for="register in registers"
                       :key="register.prefix"
                       disable-unmatched-items
                       :highlight-dates="highlightDates"
                       :register="register"
                       :is-expanded="model.includes(register.prefix)"
                       :show-entry-types="selectedEntryTypes"
                       @title-number-selected="$emit('title-number-selected')" />
    </v-expansion-panels>
</template>

<script setup lang="ts">
    import { IRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'

    import RegisterItem from './register-item.vue'

    const model = defineModel<string[]>()

    defineProps<{
        registers: IRegister[]
        highlightDates: boolean
        selectedEntryTypes: IRegisterLinkedIndicator[]
    }>()

    defineEmits<{
        (e: 'title-number-selected'): void
    }>()
</script>

<style scoped>

</style>
