<template>
    <div class="no-results-prompt">
        <h1 v-t="'error.noResults'"
            class="no-results-prompt__header" />
        <div v-if="showNextSteps"
             class="no-results-prompt__followup">
            <p v-t="'message.searchOutOfMatter'"
               class="no-results-prompt__followup--label body-regular" />
            <ow-button data-test="no-results-prompt__followup--button"
                       is-secondary
                       @click="onEventHandler(events.click)">
                {{ buttonLabel }}
            </ow-button>
        </div>
    </div>
</template>

<script lang="ts">
    import OwButton from '@/components/core/ow-button-ds.vue'

    export default {
        components: {
            OwButton,
        },

        props: {
            titleNumber: {
                type: String,
                required: true,
            },
            showNextSteps: {
                type: Boolean,
                default: true,
            },
        },

        emits: [
            'click',
        ],

        data() {
            return {
                events: {
                    click: 'click',
                },
            }
        },

        computed: {
            buttonLabel() {
                return this.$t('titlePanel.searchFor', { value: this.titleNumber })
            },
        },

        methods: {
            onEventHandler(evtName: string): void {
                this.$emit(evtName)
            },
        },
    }
</script>

<style lang="scss">
    @import 'no-results-prompt.scss';
</style>
