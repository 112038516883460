<template>
    <div class="matter-searches-intro d-flex flex-row">
        <div>
            <ow-icon-searches-intro />
        </div>
        <div class="d-flex flex-column pl-5 gr-3">
            <h1 class="headers-h1-page-title mb-2">
                {{ $t('searches.intro.title') }}
            </h1>
            <p class="body-regular">
                {{ $t('searches.intro.description') }}
            </p>
            <div v-if="isSearchesEnabled">
                <p class="body-regular mb-3">
                    {{ $t('searches.intro.footer') }}
                </p>
                <ow-button data-test="order-searches-button"
                           is-primary
                           @click="$emit('order-search')">
                    {{ $t('searches.intro.cta') }}
                </ow-button>
            </div>
            <div v-else>
                <p class="body-regular">
                    {{ $t('searches.intro.registerInterestText') }}
                </p>
                <div class="d-flex justify-center w-100 mt-3">
                    <ow-button-ds data-test="register-interest-button"
                                  is-primary
                                  @click="$emit('searches-register-interest')">
                        {{ $t('searches.intro.registerInterestCta') }}
                    </ow-button-ds>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed} from "vue"
    import { useStore } from "vuex"

    import OwIconSearchesIntro from '@/components/core/icons/ow-icon-searches-intro.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwButtonDs from "@/components/core/ow-button-ds.vue"

    const store = useStore()

    defineEmits<{
        (e: 'order-search'): void,
        (e: 'searches-register-interest'): void
    }>()

    const isSearchesEnabled = computed(() => store.state.config.featureFlags.orderSearch)
</script>

<style lang="scss">
    @import './matter-searches-intro.scss';
</style>
