<template>
    <section class="searches-documents-table">
        <ow-data-grid :allow-selection="false"
                      :headers="headers"
                      :is-loading="isLoading"
                      :initial-items-per-page="Number.MAX_SAFE_INTEGER"
                      :items="documents"
                      :sort-by="sortBy"
                      calculate-widths
                      hover
                      item-key="id">
            <template #[`item`]="{ item } : { item: ISearchesDocument }">
                <tr>
                    <td class="body-regular searches-documents-table__item"
                        :class="{ 'selected': item.path === selectedDocumentId }"
                        data-test="searches-document-table-selection"
                        data-track="OVERLAY PLANS - Select document"
                        @click="onRowSelected(item)">
                        <ow-radio :id="item.path"
                                  v-model="selected"
                                  name="rowSelector" />
                    </td>
                    <td :title="item.fileName"
                        class="body-regular searches-documents-table__item searches-documents-table__filename"
                        :class="{ 'selected': item.path === selectedDocumentId }"
                        data-test="searches-documents-table-document-name"
                        @click="onRowSelected(item)">
                        <span class="searches-documents-table__filename--text">{{ item.fileName }}</span>
                    </td>
                    <td :title="item.productName"
                        class="body-regular searches-documents-table__item searches-documents-table__item--product-name"
                        :class="{ 'selected': item.path === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.productName }}
                    </td>
                    <td :title="item.location"
                        class="body-regular searches-documents-table__item searches-documents-table__item--location"
                        :class="{ 'selected': item.path === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.location }}
                    </td>
                    <td :title="item.orderId"
                        class="body-regular searches-documents-table__item searches-documents-table__item--order-id"
                        :class="{ 'selected': item.path === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.orderId }}
                    </td>
                    <td class="caption-regular searches-documents-table__item searches-documents-table__item--created-on"
                        :class="{ 'selected': item.path === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ formatDateShort(item.createdOn) }}
                    </td>
                </tr>
            </template>
        </ow-data-grid>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        WritableComputedRef,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwRadio from '@/components/core/ow-radio.vue'
    import useDates from '@/composables/use-dates'
    import { ISearchesDocument } from '@/interfaces/searches-document.interface'
    const { formatDateShort } = useDates()
    const { t } = useI18n()
    const selectedDocumentId = computed(() => props.selectedDocument?.path ?? '')

    interface Props {
        documents: Array<ISearchesDocument>,
        isLoading?: boolean,
        selectedDocument?: ISearchesDocument,
    }

    const props = withDefaults(defineProps<Props>(), {
        isLoading: true,
        clearAll: 0,
        selectedDocument: null,
    })

    const emit = defineEmits<{
        (e: 'change', item: ISearchesDocument)
    }>()

    const selected: WritableComputedRef<string> = computed({
        get(): string {
            return props?.selectedDocument?.path ?? ''
        },
        set(docId: string): void {
            const selectedItem = props.documents.find((item: ISearchesDocument) => item.path === docId)
            emit('change', selectedItem)
        },
    })

    const headers = [
        {
            align: 'left',
            key: 'selection',
            class: 'label-caps-small',
            width: '40',
        },
        {
            title: t('documents.searches.table.headings.document').toUpperCase(),
            align: 'left',
            key: 'fileName',
        },
        {
            title: t('documents.searches.table.headings.productName').toUpperCase(),
            align: 'left',
            key: 'productName',
            width: '250',
        },
        {
            title: t('documents.searches.table.headings.titleAddress').toUpperCase(),
            align: 'left',
            key: 'titleAddress',
            width: '200',
        },
        {
            title: t('documents.searches.table.headings.orderId').toUpperCase(),
            align: 'left',
            key: 'orderId',
            width: '200',
        },
        {
            title: t('documents.searches.table.headings.createdOn').toUpperCase(),
            align: 'left',
            key: 'createdOn',
            width: '150',
        },
    ]

    const sortBy = ref([{key: 'fileName', order: 'asc'}])

    const onRowSelected = (item: ISearchesDocument) => {
        selected.value = item.path.toString()
    }
</script>

<style lang="scss" scoped>
@import './searches-documents-table';
</style>
