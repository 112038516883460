export const USER_ROLES = {
    SystemAdmin: 'SystemAdmin',
    OrgAdmin: 'OrgAdmin',
    User: 'User',
}

export const USER_ROLE_TEXT = {
    SystemAdmin: 'Sys Admin',
    OrgAdmin: 'Org Admin',
    User: 'User',
}
