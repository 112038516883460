<template>
    <ow-data-grid :allow-selection="false"
                  :headers="dataGridHeaders"
                  :is-loading="isLoading"
                  :items="records"
                  :sort-by="sortBy"
                  hover
                  class="find-nearby-results-matter-titles"
                  data-test="find-nearby-matter-titles-results-table">
        <template #item="{ item }">
            <tr :class="{ highlight: shouldHighlightItem(item)}"
                @mouseover="$emit('mouse-over-item', item)">
                <td :title="getTitleText(item)"
                    class="find-nearby-results-matter-titles__title">
                    {{ getTitleText(item) }}
                </td>
                <td class="find-nearby-results-matter-titles__matter-name">
                    <a :href="`/matters/${item.matterId}/map/${item.titleNumber}`"
                       data-test="find-nearby-matter-title-link"
                       :title="item.matterName"
                       target="_blank"
                       @click="onMatterClick">
                        {{ item.matterName }}
                    </a>
                </td>
                <td :title="item.groupName"
                    class="find-nearby-results-matter-titles__group-name">
                    {{ getDisplayGroupName(item.groupName) }}
                </td>
            </tr>
        </template>
    </ow-data-grid>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { ref } from 'vue'
    import { mapActions } from 'vuex'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    import { INearbyMatterTitle } from './nearby-matter-title.interface'

    export default {
        name: 'MatterTitleResults',

        components: {
            OwDataGrid,
        },

        props: {
            records: {
                type: Array as PropType<INearbyMatterTitle[]>,
                required: false,
                default: () => [],
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            highlightResults: {
                type: Array as PropType<INearbyMatterTitle[]>,
                required: false,
            },
        },

        emits: ['mouse-over-item'],

        data() {
            return {
                dataGridHeaders: [
                    { title: this.$t('titlePanel.findNearby.matterTitles.columns.title'), key: 'titleNumber', class: 'title-number', width: '215px' },
                    { title: this.$t('titlePanel.findNearby.matterTitles.columns.inMatter'), key: 'matterName', class: 'in-matter', width: '215px' },
                    { title: this.$t('titlePanel.findNearby.matterTitles.columns.inGroup'), key: 'groupName', class: 'in-group', width: '215px' },
                ],
                sortBy: ref([{key: 'titleNumber', order: 'asc'}]),
            }
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            getDisplayGroupName(groupName: string): string {
                return groupName ?? this.$t('label.notApplicable')
            },

            getTitleText(item: INearbyMatterTitle): string {
                return FindNearbyDefaults.getTitleDisplayText(item)
            },

            shouldHighlightItem(item: INearbyMatterTitle): boolean {
                return this.highlightResults?.includes(item)
            },

            onMatterClick() {
                this.logHeapEvent({
                    type: 'NEARBY TAB - Open nearby title',
                })
            },
        },
    }
</script>

<style lang="scss">
    @import './matter-titles-results.scss';
</style>
