import {
    dynamicSort,
    sortBySortOrderFn,
} from '@/utils/array-utils'
import i18n from '@/plugins/i18n'
import { IMatterGroup } from '@/interfaces/matter-group.interface'
import { ITitleInMatterReference } from '@/interfaces/title-in-matter.reference'
import TitleInformationApi from '@/api/title-information.api'
import { TitleOrganiserBaseService } from '@/store/modules/matter/title-organiser/services/_title-organiser-base'
import { TitleOrganiserResult } from '@/store/modules/matter/title-organiser/title-organiser-result.type'

export class TitleOrganiserTenureService extends TitleOrganiserBaseService {
    id = 'tenure'

    public async getMatterContents(matterId: string, currentTitles: Array<ITitleInMatterReference>, currentGroups: Array<IMatterGroup>,
        applyStyling: boolean): Promise<TitleOrganiserResult> {
        this.isLoading = true
        currentTitles.sort(sortBySortOrderFn)
        const boundaryAvailable = (titleNumber: string) => currentTitles.find(t => t.titleNumber === titleNumber)?.boundaryAvailable ?? false

        // Get tenure data from the server (currently this is already held on the currentTitles objects, however we want to move this data to GraphQL API).
        const tenureData = (await TitleInformationApi.getTenureByTitleNumbers(matterId, currentTitles.map(t => t.titleNumber)))
            .sort(dynamicSort('tenure'))
        const otherTenureValue = i18n.global.t('matter.titleOrganiser.otherGroupName')
        // Create groups representing the different tenures.
        const newGroupNames = [...new Set(tenureData.map(t => (t.tenure ?? otherTenureValue)))]
        const newGroups:Array<IMatterGroup> = newGroupNames.map((groupName: string, i) => {
            return {
                id: i,
                name: groupName,
                sortOrder: (i + 1) * 100,
                expanded: true,
            }
        })

        // Allocate titles to groups and set the boundary style.
        const newTitles = []
        newGroups.forEach(group => {
            const groupStyle = this.colourPalette.getEntryForTenure(group.name)
            const titleNumbersInGroup = tenureData.filter(t => (t.tenure ?? otherTenureValue) === group.name)
                .map(t => t.titleNumber)

            titleNumbersInGroup.forEach((titleNumber: string, titleIndex) => {
                const titleItem = currentTitles.find(t => t.titleNumber === titleNumber)
                newTitles.push({
                    titleNumber: titleItem.titleNumber,
                    boundaryStyle: applyStyling && boundaryAvailable(titleItem.titleNumber) ? groupStyle : null,
                    sortOrder: group.sortOrder + (titleIndex * 100),
                    matterGroupId: group.id,
                })
            })
        })
        this.isLoading = false
        return Promise.resolve(new TitleOrganiserResult(newTitles, newGroups))
    }

    i18nReference = 'matter.titleOrganiser.tenure'

    quickStylingByDefault = true
    isLoading = false

    hasAppliedChanges = true
}
