<template>
    <ow-modal v-model="exportPrompt.show"
              content-class="dialog-export-official-copies"
              :title="titleText"
              persistent>
        <div class="dialog-export-official-copies__content">
            <template v-if="!showExportResultsNew">
                <span class="body-highlight">
                    Options
                </span>
                <div class="dialog-export-official-copies__options">
                    <v-radio-group v-model="exportSelection"
                                   :mandatory="true"
                                   hide-details
                                   data-test="dialog-export-official-copies__options"
                                   style="padding-top:0; margin-top:0">
                        <v-radio class="caption-highlight"
                                 data-test="dialog-export-official-copies__options--official-copies"
                                 color="primary"
                                 :label="$t('documents.officialCopies')"
                                 :value="0" />
                        <v-radio class="caption-highlight"
                                 data-test="dialog-export-official-copies__options--pending-applications"
                                 color="primary"
                                 :label="$t('documents.pendingApplications')"
                                 :value="1" />
                    </v-radio-group>
                </div>
                <template v-if="exportMethod === exportMethods.officialCopies">
                    <span class="body-regular">
                        {{ $t('matter.prompts.purchaseOptions.body') }}
                    </span>
                    <div v-if="hmlrTitles.length">
                        <div class="order-documents-card__footer d-flex align-center justify-start mb-1">
                            <div class="d-flex align-center gc-3 ml-3">
                                <span v-t="'reviewAssistant.document.orderedFrom'"
                                      class="accents-small" />
                                <ow-icon icon="ow-icon-hmlr"
                                         :text="true"
                                         width="75px"
                                         height="24px" />
                            </div>
                        </div>
                        <div class="caption-highlight dialog-export-official-copies__documents">
                            <ow-checkbox id="dialog-export-official-copies--title-register"
                                         v-model="officialCopies.register"
                                         data-test="dialog-export-official-copies--title-register"
                                         :label="$t('documents.titleRegister')" />
                            <ow-checkbox id="dialog-export-official-copies--title-plan"
                                         v-model="officialCopies.titlePlan"
                                         data-test="dialog-export-official-copies--title-plan"
                                         :label="$t('documents.titlePlan')" />
                        </div>
                    </div>
                    <div v-if="scotLisTitles.length">
                        <div class="order-documents-card__footer d-flex align-center justify-between mb-1">
                            <div class="d-flex align-center gc-3 ml-3">
                                <span v-t="'reviewAssistant.document.orderedFrom'"
                                      class="accents-small" />
                                <ow-icon icon="ow-icon-lis"
                                         :text="true"
                                         width="75px"
                                         height="24px" />
                            </div>
                        </div>
                        <div class="caption-highlight dialog-export-official-copies__documents">
                            <ow-checkbox id="dialog-export-official-copies--title-sheet"
                                         v-model="officialCopies.titleSheet"
                                         data-test="dialog-export-official-copies--title-sheet"
                                         :label="$t('documents.titleSheetTitlePlan')" />
                        </div>
                    </div>
                </template>
            </template>
            <document-ordering-progress v-if="showExportResultsNew"
                                        class="dialog-export-official-copies__result"
                                        :order-requests="documentOrderRequests"
                                        :order-updates="documentOrderUpdates"
                                        @error="handleDocumentOrderingError"
                                        @dismiss="handleCancel" />
        </div>
        <template #actions>
            <ow-card-actions has-secondary-button
                             :has-primary-button="!documentOrderingError && !showExportResultsNew"
                             no-padding
                             :primary-button-text="confirmText"
                             :primary-button-loading="loading"
                             :primary-button-disabled="loading || !isSelectionValid"
                             primary-button-data-test="dialog-export-official-copies__controls--confirm"
                             secondary-button-data-test="dialog-export-official-copies__controls--cancel"
                             :secondary-button-text="$t('action.close')"
                             @primary-button-click="handleConfirm"
                             @secondary-button-click="handleCancel">
                <template #primary-button-prefix>
                    <span v-if="confirm && !isLoading && !showExportResultsNew"
                          v-dompurify-html="$t('matter.prompts.purchaseOptions.query', officialCopiesExportPrice)"
                          data-test="dialog-export-official-copies__controls--caption"
                          class="caption-regular"
                          style="line-height: 20px; margin: 0 8px 0 84px" />
                </template>
            </ow-card-actions>
        </template>
    </ow-modal>
</template>

<script lang="ts">
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import BusinessGatewayApi from '@/api/business-gateway.api'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwIcon from '@/components/core/ow-icon.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import DocumentOrderingProgress from '@/components/documents/document-ordering-progress.vue'
    import {DATA_PROVIDER} from "@/consts/data-provider"
    import FlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { TrackedTitleListener } from '@/interfaces/store/organisation-hub/tracked-title'
    import { IState } from '@/interfaces/store/state.interface'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import {
        MATTER_ADD_TITLES_TO_GROUP,
        MATTER_CREATE_GROUP,
        MATTER_EXPORT_OFFICIAL_COPIES,
        MATTER_EXPORT_SPREADSHEET_DAYLIST,
        MATTER_MUTATE_BULK_ORDER_RESPONSE,
        MATTER_MUTATE_CREATE_GROUP_PROMPT,
        MATTER_MUTATE_CREATE_GROUP_TITLES,
    } from '@/store/modules/matter/types'
    import {
        ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER,
        ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE,
        ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER,
    } from '@/store/modules/organisation-hub/types'
    import {
        TITLE_GET_SELECTED_TITLE_NUMBER,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'DialogExportOfficialCopies',
        components: {
            DocumentOrderingProgress,
            OwModal,
            OwCheckbox,
            OwIcon,
            OwCardActions,
        },
        mixins: [FlagsMixin],
        data() {
            return {
                loading: false,
                documentOrderingError: false,
                confirm: false,
                documentOrderRequests: [],
                hasNoDocumentsToDownload: false,
                exportSelection: 0,
                officialCopies: {
                    register: true,
                    titlePlan: true,
                    titleSheet: true,
                },
                exportMethods: {
                    officialCopies: 'official-copies',
                    pendingApplications: 'pending-applications',
                },
            }
        },
        computed: {
            ...mapState({
                exportPrompt: (state: IState) => state.matter.prompts.export,
                currentMatter: (state: IState) => state.matter.currentMatter,
                documentCharge: (state: IState) => state.user.titleSummaryCharge,
            }),
            ...mapGetters('organisationHub', {
                getTrackedTitles: ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER,
            }),
            exportMethod() {
                if (!this.exportSelection) {
                    return this.exportMethods.officialCopies
                }
                return this.exportMethods.pendingApplications
            },
            titleText() {
                if (this.exportPrompt.purchase) {
                    return this.$t('matter.prompts.purchaseOptions.title', this.titlesCount)
                } else {
                    return this.$t('matter.prompts.exportOptions.title', this.titlesCount)
                }
            },
            confirmText() {
                if (this.confirm) {
                    return this.$t('action.confirm')
                }
                if (this.exportMethod === this.exportMethods.officialCopies) {
                    if (this.officialCopiesExportPrice === 0) {
                        return this.$t('action.purchase')
                    } else {
                        return `${ this.$t('action.purchase') } (£${ this.officialCopiesExportPrice })`
                    }
                } else {
                    return this.loading
                        ? this.$t('label.exporting')
                        : `${ this.$t('action.export') } (${ this.$t('label.free') })`
                }
            },
            titlesCount() {
                return this.exportPrompt.titles?.length ?? 0
            },
            hmlrTitles() {
                const filteredTitles = this.exportPrompt.titles.filter(title => title.dataProvider === DATA_PROVIDER.HMLR)
                if (isNullOrEmpty(filteredTitles)) {
                    this.officialCopies.titlePlan = false
                    this.officialCopies.register = false
                }
                return filteredTitles
            },
            scotLisTitles() {
                const filteredTitles = this.exportPrompt.titles.filter(title => title.dataProvider === DATA_PROVIDER.SCOTLAND)
                if (isNullOrEmpty(filteredTitles)) {
                    this.officialCopies.titleSheet = false
                }
                return filteredTitles
            },
            officialCopiesExportPrice() {
                let hmlrCostPerTitle = 0
                let scotLisCostPerTitle = 0
                if (this.officialCopies.register === true) {
                    hmlrCostPerTitle += this.documentCharge
                }
                if (this.officialCopies.titlePlan === true) {
                    hmlrCostPerTitle += this.documentCharge
                }
                if (this.officialCopies.titleSheet === true) {
                    scotLisCostPerTitle += this.documentCharge
                }
                return this.hmlrTitles.length * hmlrCostPerTitle + this.scotLisTitles.length * scotLisCostPerTitle
            },
            documentOrderUpdates() {
                return this.getTrackedTitles(TrackedTitleListener.BulkExport)
                    .flatMap(t => t.updates)
            },
            showExportResultsNew() {
                return this.documentOrderRequests.length > 0
            },
            isLoading() {
                return this.loading || Boolean(this.documentOrderRequests.length)
            },
            isSelectionValid() {
                if (this.exportMethod === this.exportMethods.pendingApplications) {
                    return true
                }

                return this.officialCopies.register || this.officialCopies.titlePlan || this.officialCopies.titleSheet
            },
        },
        watch: {
            'exportPrompt.purchase'(value) {
                this.exportSelection = Number(!value)
            },
            'exportPrompt.show'(value) {
                if (!value) {
                    this.handleCancel()
                }
            },
            'officialCopies.register'() {
                this.confirm = false
            },
            'officialCopies.titlePlan'() {
                this.confirm = false
            },
            'officialCopies.titleSheet'() {
                this.confirm = false
            },
            exportMethod() {
                this.confirm = false
            },
        },
        methods: {
            ...mapMutations({
                mutateBulkOrderRegisters: MATTER_MUTATE_BULK_ORDER_RESPONSE,
            }),
            ...mapActions({
                exportDayList: MATTER_EXPORT_SPREADSHEET_DAYLIST,
            }),
            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),
            ...mapMutations('organisationHub', {
                trackTitle: ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE,
                removedTrackedTitles: ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER,
            }),
            handleDocumentOrderingError() {
                this.documentOrderingError = true
            },
            async handleConfirm() {
                this.loading = true

                if (this.exportMethod === this.exportMethods.pendingApplications) {
                    await this.exportDayList()
                    this.exportPrompt.show = false
                } else if (!this.confirm) {
                    this.confirm = true
                } else {
                    const hmlrTitleNumbers = this.hmlrTitles.map(title => title.titleNumber)
                    const scotLisTitleNumbers = this.scotLisTitles.map(title => title.titleNumber)

                    this.documentOrderRequests = []
                    // Create order requests and listen for updates to these documents via the associated titles.
                    hmlrTitleNumbers.forEach((titleNumber: string) => {
                        this.trackTitle({
                            titleNumber,
                            listener: TrackedTitleListener.BulkExport,
                        })
                        if (this.officialCopies.register) {
                            this.documentOrderRequests.push(DocumentOrderRequest.createRegisterRequest(titleNumber, this.currentMatter.id, false))
                        }
                        if (this.officialCopies.titlePlan) {
                            this.documentOrderRequests.push(DocumentOrderRequest.createTitlePlanRequest(titleNumber, this.currentMatter.id, false))
                        }
                    })
                    scotLisTitleNumbers.forEach((titleNumber: string) => {
                        this.trackTitle({
                            titleNumber,
                            listener: TrackedTitleListener.BulkExport,
                        })
                        if (this.officialCopies.titleSheet) {
                            this.documentOrderRequests.push(DocumentOrderRequest.createRegisterRequest(titleNumber, this.currentMatter.id, false))
                        }
                    })
                    await this.orderDocuments(this.documentOrderRequests)
                }
                this.loading = false
            },
            handleCancel() {
                this.$store.state.matter.prompts.export.titles = []
                this.documentOrderingError = false
                this.exportPrompt.show = false
                this.documentOrderRequests = []
                this.confirm = false
                this.loading = false
                this.hasNoDocumentsToDownload = false
                this.mutateBulkOrderRegisters({
                    failedToOrderTitleNumbers: [],
                    titleNumbersOrdered: [],
                    errorMessages: [],
                    filename: '',
                })
                this.removedTrackedTitles(TrackedTitleListener.BulkExport)
            },
        },
    }
</script>

<style lang="scss">
    @import './dialog-export-official-copies';
</style>
