<template>
    <ow-card :loading="isLoading || !selectedTitle.record?.titleMetadata?.bgDataLoaded || showProgressBar"
             :loading-skeleton="{ rows: 1, expandRows: true, reverse: false, ctas: 0 }"
             title="Title Complexity"
             class="d-flex flex-column title-complexity"
             data-test="title-complexity">
        <div v-if="errorState"
             class="d-flex flex-row">
            <p class="error-text">
                Unable to determine title complexity, please check back again later.
            </p>
        </div>
        <div v-if="showProgressBar"
             class="d-flex flex-row">
            <v-progress-linear indeterminate
                               color="primary" />
        </div>
        <div v-if="!inCurrentMatter"
             data-test="title-complexity-not-in-matter-tip"
             class="d-flex flex-row">
            <p style="font-weight: bold; margin-top: 10px;">
                This title must be added to your matter to view the
                complexity rating.
            </p>
        </div>
        <div v-if="showComplexity"
             :class="{loaded: !loading}"
             class="d-flex flex-row complexity-score-container"
             row>
            <div class="d-flex shrink">
                <!-- Graphic -->
                <v-icon :class="complexityCSSClass"
                        class="complexity-score-icon">
                    $organisation
                </v-icon>
            </div>

            <div class="d-flex">
                <div class="d-flex flex-column"
                     style="padding-left: 28px;">
                    <h2 :class="complexityCSSClass">
                        <span data-test="title-complexity-score-category-text">{{ complexityScoreCategoryText }}</span>
                        <span v-if="debugModeEnabled"
                              class="complexity-score-value"
                              data-test="title-complexity-score-value">({{ complexityScoreValue }})</span>
                    </h2>

                    <p class="title-complexity-category-description"
                       style="margin: 5px 0 0 0;">
                        <span data-test="title-complexity-category-description">
                            Orbital Witness rates this title as {{
                                complexityScoreCategoryText.toLowerCase()
                            }}.
                        </span>
                    </p>

                    <a class="complexity-score-feedback-btn"
                       data-track="TITLE-DETAILS-PANEL - Do you agree with the complexity score"
                       href="#"
                       @click.prevent="requestFeedback()">
                        Do you agree with this rating?
                    </a>
                </div>
            </div>
        </div>
        <!-- More information popup -->
        <template #title-suffix>
            <v-menu content-class="complexity-score-more-info-area"
                    menu-props="{}">
                <template #activator="{ props }">
                    <v-btn class="complexity-score-about-btn"
                           data-test="title-complexity-info"
                           data-track="TITLE-DETAILS-PANEL - Complexity score information"
                           icon
                           size="small"
                           style="margin:0"
                           variant="text"
                           v-bind="props">
                        <v-icon>$info</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        <p>
                            Title Complexity helps you see what you're dealing with at the outset.
                            Orbital Witness instantly compares every title you download against tens of thousands of
                            titles to give you a quick
                            and accurate benchmark of how complex the title is on a six point scale:
                        </p>
                        <ul style="font-weight: 500; margin: 7px 15px;">
                            <li class="title-complexity-category-extremelysimple">
                                Extremely Simple
                            </li>
                            <li class="title-complexity-category-verysimple">
                                Very Simple
                            </li>
                            <li class="title-complexity-category-fairlysimple">
                                Fairly Simple
                            </li>
                            <li class="title-complexity-category-fairlycomplex">
                                Fairly Complex
                            </li>
                            <li class="title-complexity-category-verycomplex">
                                Very Complex
                            </li>
                            <li class="title-complexity-category-extremelycomplex">
                                Extremely Complex
                            </li>
                        </ul>
                        <p>
                            The rating looks at a range of data points about the title
                            taking into account both the volume of content to review,
                            and the number of "unusual" aspects of a title.
                        </p>
                        <!-- List of complexity indicators to present in debug mode-->
                        <ul v-if="debugModeEnabled"
                            class="title-complexity-indicator-list"
                            data-test="title-complexity-indicator-list">
                            <li v-for="indicator in complexityIndicators"
                                :key="indicator.id">
                                <strong>{{ indicator.text }}:</strong> {{ indicator.value }}
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-menu>
        </template>
    </ow-card>
</template>

<script>
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import OwCard from '@/components/core/ow-card.vue'
    import {
        FETCH_COMPLEXITY_FOR_TITLES,
        GET_TITLE_COMPLEXITY,
    } from '@/store/modules/complexity/types'
    import { MATTER_HAS_TITLE_NUMBER } from '@/store/modules/matter/types'
    import { OPEN_WINDOW } from '@/store/mutation-types'
    import { COMPLEXITY } from '@/utils/title-enums'

    export default {
        name: 'TitleComplexity',

        components: {
            OwCard,
        },

        props: ['matterId', 'titleNumber', 'selectedTitle', 'isLoading'],

        data() {
            return {
                retryAttempts: 0, // has the complexity score may not yet be available.
                retryInterval: 2000, // retry the complexity score request interval (doubles on fail).
                retryTimeout: null, // the setTimeout value.
                errorState: false,
                complexity: undefined,
            }
        },

        computed: {

            ...mapState({
                loadingTitleComplexityForTitleNumbers: state => state.complexity.loadingTitleComplexityForTitleNumbers,
                debugModeEnabled: state => state.diagnostics.debugModeEnabled,
                loadingMatter: state => state.matter.loading,
            }),

            ...mapGetters('complexity', {
                getTitleComplexity: GET_TITLE_COMPLEXITY,
            }),

            ...mapGetters({
                matterHasTitle: MATTER_HAS_TITLE_NUMBER,
            }),

            loading() {
                return this.loadingTitleComplexityForTitleNumbers.includes(this.titleNumber) || this.complexity === undefined
            },

            showProgressBar() {
                return this.retryAttempts > 0 && !this.errorState
            },

            showComplexity() {
                return this.retryAttempts === 0 && this.errorState === false && this.inCurrentMatter === true
            },

            complexityScoreValue() {
                if (this.complexity?.score?.value) {
                    return `${ this.complexity?.score?.value } / ${ COMPLEXITY.MAX_SCORE }`
                }
                return ''
            },

            complexityScoreCategoryText() {
                if (this.complexity?.categoryText) {
                    return this.complexity?.categoryText
                }
                return ''
            },

            complexityIndicators() {
                if (this.complexity?.indicators) {
                    return this.complexity.indicators
                }
                return []
            },

            complexityCSSClass() {
                if (!this.loading) {
                    return `title-complexity-category-${ this.complexity.score.category.toLowerCase() }`
                }
                return ''
            },

            inCurrentMatter() {
                return this.matterHasTitle(this.titleNumber)
            },
        },

        watch: {

            titleNumber(val) {
                if (val) {
                    this.refresh()
                }
            },

            matterId(val) {
                if (val) {
                    this.refresh()
                }
            },

            inCurrentMatter(val) {
                if (val === true) {
                    this.refresh()
                }
            },

            loadingMatter(val) {
                if (val === false) {
                    this.refresh()
                }
            },
        },

        mounted() {
            if (this.titleNumber) {
                this.retryAttempts = 0
                this.retryInterval = 2000
                this.refresh()
            }
        },

        beforeUnmount() {
            clearTimeout(this.retryTimeout)
            this.errorState = false
            this.retryAttempts = 0
        },

        methods: {

            ...mapActions('complexity', {
                fetchComplexityForTitles: FETCH_COMPLEXITY_FOR_TITLES,
            }),

            ...mapActions({
                openWindow: OPEN_WINDOW,
            }),

            async refresh() {
                if (!this.loadingMatter) {
                    if (this.inCurrentMatter === true) {
                        // Request the latest complexity data for the title.
                        await this.fetchComplexityForTitles({
                            matterId: this.matterId,
                            titleNumbers: [this.titleNumber],
                            debugMode: this.debugModeEnabled,
                        })
                        this.complexity = this.getTitleComplexity(this.titleNumber)

                        if (this.complexity === undefined) {
                            // If complexity has not loaded from the endpoint
                            if (this.retryInterval < 120000) { // stop trying after 2 minutes
                                this.retryTimeout = setTimeout(async () => {
                                    await this.refresh()
                                }, this.retryInterval)
                                this.retryInterval = this.retryInterval * 2
                                this.retryAttempts++
                            } else {
                                this.errorState = true
                                console.error('Unable to load title complexity')
                            }
                        } else {
                            // Complexity is available, reset error/retry state
                            this.retryAttempts = 0
                            this.errorState = false
                        }
                    }
                }
            },

            requestFeedback() {
                const feedbackUrl = `https://docs.google.com/forms/d/e/1FAIpQLSfLW5DnAQPAx3fp9pVeMYzSVmVg10YQMHzHRLuk48KoxJACxQ/viewform?usp=pp_url&entry.1077297722=${ this.titleNumber }&entry.205510247=${ this.complexityScoreCategoryText }`
                this.openWindow(feedbackUrl)
            },
        },
    }
</script>

<style lang="scss">
    @import './title-complexity-card';
</style>
