import {
    FindNearbySettings,
    FindNearbyType,
} from '@/components/find-nearby/find-nearby-settings'
import {
    IFindNearbyResults,
    IFindNearbyService,
    IFindNearbySubtitleText,
} from '@/components/find-nearby/implementations/_common/find-nearby-service.interface'
import Feature from 'ol/Feature'
import FindNearbyApi from '@/api/find-nearby.api'
import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
import { FindNearbyServiceBase } from '@/components/find-nearby/implementations/_common/find-nearby-service-base'
import i18n from '@/plugins/i18n'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'

export interface ICorporateOwnersServiceOptions {
    getMapFeaturesFn: (any) => Promise<Array<Feature>>,
    onAddTitlesToMatterFn: (any) => void,
}

export class FindNearbyCorporateOwnerService extends FindNearbyServiceBase implements IFindNearbyService {
    public label = 'Corporate owners'
    private readonly resultsCache: Map<string, IHttpClientResponse>
    public results: IFindNearbyResults
    private readonly getMapFeaturesFn: (any) => Promise<Array<Feature>>
    public readonly onAddTitlesToMatterFn: (any) => void

    public settings: FindNearbySettings = {
        type: FindNearbyType.CorporateOwners,
        defaultRadiusMetres: 50,
        radiusStepMetres: 5,
        maximumRadiusMetres: 500,
        minimumRadiusMetres: FindNearbyDefaults.BUFFER_DEFAULT_MIN_RADIUS,
        thumbLabelFormatter: (value) => value > 999 ? '1km' : `${ value }m`,
        featureKey: 'title_no',
    }

    public displayComponentName = 'CorporateOwnerResults'

    constructor(options: ICorporateOwnersServiceOptions) {
        super()
        this.resultsCache = new Map<string, IHttpClientResponse>()
        this.getMapFeaturesFn = options.getMapFeaturesFn
        this.onAddTitlesToMatterFn = options.onAddTitlesToMatterFn
    }

    public async handleRequest(props: { titleNumbers: Array<string> }): Promise<IFindNearbyResults> {
        let response: IHttpClientResponse = this.resultsCache.get(props.titleNumbers.join(','))
        if (!response) {
            if (this.resultsCache.size > 10) {
                this.resultsCache.delete(this.resultsCache.keys()[0])
            }

            response = await FindNearbyApi.getCorporateOwners(props.titleNumbers, this.settings.maximumRadiusMetres)
            this.resultsCache.set(props.titleNumbers.join(','), response)
        }

        if (response?.ok) {
            this.results = { records: response.data, features: [] }
            // Assign a count of titles
            this.results.records.forEach(x => {
                x.titlesCount = x.titles.length
            })
            const nearbyTitleNumbers = this.results?.records.flatMap(x => x.titles).map(x => x.titleNumber)
            this.results.features = await this.getMapFeaturesFn(nearbyTitleNumbers)
            return this.results
        }
    }

    public reset(): void {
        this.results = null
        this.resultsCache.clear()
    }

    public getSubtitleText(params: IFindNearbySubtitleText): string {
        if (!params.filteredResults) {
            return 'nowt to see here!'
        }

        const resultCount = params.filteredResults.length
        const distinctTitles = new Set(params.filteredResults.map(r => r.titleNumber)).size
        return i18n.global.t('titlePanel.findNearby.corporateOwnersSubheading', {
            resultCount,
            distance: params.selectedDistanceInMetres,
            titleNumbers: params.selectedTitleNumbers.join(', '),
            distinctTitles,
        }).toString()
    }

    public getResultKeyValue(result: any): string {
        return result.ownerName
    }

    public getTitleText(): string {
        return i18n.global.t('titlePanel.findNearby.corporateOwnersHeading')
    }

    public getFilteredResults(props: { distanceMetres: number }): IFindNearbyResults {
        const result = {
            records: [],
            features: [],
        } as IFindNearbyResults

        const featureIds = [] as Array<string>
        this.results?.records.forEach(x => {
            const titles = x.titles.filter(t => t.distanceMetres <= props.distanceMetres)
            featureIds.push(...titles.map(t => t.titleNumber))
            x.titlesCount = titles.length
            if (titles.length) {
                result.records.push({
                    ...x,
                    titles,
                })
            }
        })

        result.features = this.results?.features?.filter(f => featureIds.includes(f.get(this.settings.featureKey))) ?? []
        return result
    }

    public getRecordsFromFeatureIds(featureIds: Array<string>): Array<any> {
        return this.results?.records?.filter(x => x.titles.some(t => featureIds.includes(t.titleNumber))) ?? []
    }

    public getFeaturesForRecords(records: Array<any>, selectedDistance: number): Array<Feature> {
        const ownerNames = records.map(x => x.ownerName)
        const titleNumbers = this.results.records
            .filter(x => ownerNames.includes(x.ownerName))
            .flatMap(x => x.titles)
            .filter(x => x.distanceMetres <= selectedDistance)
            .map(x => x.titleNumber)

        return this.results?.features?.filter(x => titleNumbers.includes(x.get(this.settings.featureKey))) ?? []
    }

    // Called by results component.
    public onAddTitlesToMatter(titleNumbers: Array<string>) {
        this.onAddTitlesToMatterFn(titleNumbers)
    }
}
