<template>
    <ownership-diff-table :items="items" />
</template>

<script lang="ts" setup>
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { OwnershipDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import {
        Difference,
    } from '@/components/asset-monitoring/difference-generic'
    import OwnershipDiffTable from "@/components/asset-monitoring/grid/sub-type-items/ownership-diff-table/ownership-diff-table.vue"
    import {IOwnershipDiffTableData} from "@/components/asset-monitoring/grid/sub-type-items/ownership-diff-table/ownership-diff-table-models"

    const props = defineProps({
        item: {
            type: Object as PropType<IAssetMonitoringNotificationDetail>,
            required: true,
        },
    })

    const emit = defineEmits<{
        (e: 'count-of-updates', value: number),
    }>()

    const diff = computed<Difference<OwnershipDatapoint>>(() => {
        if (props.item) {
            return JSON.parse(props.item?.difference)
        }

        return null
    })

    const items = ref<Difference<IOwnershipDiffTableData>>(null)

    watch(items, () => {
        let length = items.value?.added.length ?? 0
        length += items.value?.removed.length ?? 0
        length += items.value?.updated.length ?? 0
        emit('count-of-updates', length)
    }, { immediate: true })

    watch(diff, (newVal: Difference<OwnershipDatapoint>) => {
        const added = newVal.added.map((item) => ({
            companyName: item.name,
            displayAddress: item.address,
        }))
        const removed = newVal.removed.map((item) => ({
            companyName: item.name,
            displayAddress: item.address,
        }))
        const updated = newVal.updated.map((item) => ({
            before: {
                companyName: item?.before?.name,
                displayAddress: item?.before?.address,
            },
            after: {
                companyName: item?.after?.name,
                displayAddress: item?.after?.address,
            },
        }))

        items.value = {
            added,
            removed,
            updated,
        }
    }, { immediate: true })

</script>

<style lang="scss" scoped>
    @import './ownership-change';
</style>
