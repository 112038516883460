<template>
    <svg :style="{ width: `${ width }`, height: `${ height }`}"
         :viewBox="viewBox"
         role="img"
         xmlns="http://www.w3.org/2000/svg">
        <desc>{{ desc }}</desc>
        <slot />
    </svg>
</template>

<script lang="ts">
    export default {
        name: 'OwBaseIcon',
        props: {
            width: {
                type: String,
                required: false,
                default: '24px',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
            viewBox: {
                type: String,
                required: true,
            },
            desc: {
                type: String,
                required: true,
            },
        },
    }
</script>
