<template>
    <v-menu v-if="item.visible === undefined || item.visible"
            :model-value="item.menuVisible"
            open-on-hover
            :open-on-focus="false"
            sub-menu
            :close-on-content-click="false"
            :content-class="contentClass"
            max-height="500px"
            :offset="4"
            :location="'right top'"
            :disabled="!hasChildren"
            @update:model-value="item.menuVisible = $event">
        <template #activator="{ props: activatorProps }">
            <div v-bind="activatorProps"
                 :key="item.value">
                <div class="d-flex align-center gc-2 ow-dropdown-filter-item"
                     :class="{
                         'ow-dropdown-filter-item--selected': item.menuVisible,
                         'ow-dropdown-filter-item--disabled': item.readonly === true,
                         'ow-dropdown-filter-item--parent': isNullOrWhitespace(item?.parentId ?? ''),
                     }"
                     @click="onItemClicked">
                    <ow-checkbox v-if="!item.disableCheck"
                                 :id="item.title"
                                 v-model="itemChecked"
                                 :indeterminate="item.indeterminate" />
                    <div class="w-100 d-flex justify-space-between align-center gc-4">
                        <span class="body-regular-small text-no-wrap">{{ item.title }}</span>
                        <ow-chip v-if="filterActive && item?.tag"
                                 hide-close-button
                                 :label="item.tag" />
                    </div>
                    <v-icon v-if="hasChildren"
                            :class="{
                                'ow-dropdown-filter-item__icon--open': item.menuVisible,
                                'ow-dropdown-filter-item__icon--closed': !item.menuVisible,
                            }">
                        mdi-chevron-right
                    </v-icon>
                </div>
            </div>
        </template>
        <div class="ow-dropdown-filter__filter">
            <ow-dropdown-filter-item v-for="child in item.items"
                                     :item="child"
                                     @check="emit('check', $event)" />
        </div>
    </v-menu>
</template>

<script setup lang="ts">
    import {
        computed,
        defineProps,
        watch,
    } from "vue"

    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import OwChip from "@/components/core/ow-chip.vue"
    import {
        IOptionItem,
        IOptionLinkItem,
    } from "@/interfaces/option-item.interface"
    import { isNullOrEmpty } from "@/utils/array-utils"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    const emit = defineEmits(['check'])

    const props = defineProps<{
        item: IOptionLinkItem
        filterActive?: boolean
    }>()

    const itemChecked = computed({
        get: () => props.item?.checked,
        set: (value: boolean) => {
            props.item.checked = value
            emit('check', props.item)
        },
    })

    const onItemClicked = () => {
        if (props.item.readonly || props.item.disableCheck || props.item.disableCheckRow) {
            return
        }

        itemChecked.value = !itemChecked.value
    }

    const contentClass = computed(() => {
        return [
            'caption-regular',
            'ow-dropdown-filter-item__content',
        ].join(' ')
    })

    const hasChildren = computed(() => {
        return !isNullOrEmpty(props.item.items)
    })
</script>

<style lang="scss">
@import './ow-dropdown-filter-item';
</style>