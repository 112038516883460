<template>
    <div class="epc-divider">
        <div class="epc-divider__line" />
        <div class="epc-divider__text">
            {{ text }}
        </div>
        <div class="epc-divider__line" />
    </div>
</template>

<script lang="ts">
    export default {
        name: 'EpcDivider',
        props: {
            text: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import './epc-divider.scss';
</style>
