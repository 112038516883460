import { StyleLike } from 'ol/style/Style'
import { LayerNames } from '@/consts/map-layers'
import {
    Fill,
    Style,
} from 'ol/style'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { FeatureLike } from 'ol/Feature'
import { MapBrowserEvent } from 'ol'
import { isNullOrWhitespace } from '@/utils/string-utils'
import { format } from '@/utils/date-utils'
import i18n from '@/plugins/i18n'

export type ScheduledMonumentsFeaturesType = {
    name: string,
    england: boolean,
    wales: boolean,
    // eslint-disable-next-line camelcase
    entry_number: string,
    // eslint-disable-next-line camelcase
    date_first_listed: string,
}

export type ScheduledMonumentsLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void
}

export class ScheduledMonumentsLayer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: ScheduledMonumentsLayerParams, settings: any) {
        super(params, settings)
        this.name = LayerNames.ScheduledMonuments
        this.layerName = settings.scheduledMonumentsLayer
        this.i18nNameRef = 'map.options.legend.scheduledMonuments[0]'
        this.attributionText = [`Scheduled Monuments data - © Historic England & Cadw - released ${ settings.scheduledMonumentsReleaseDate }`]
        this.rolloverOptions = {
            showPrimary: true,
            showExtended: true,
            sortOrder: 0,
            category: i18n.global.t(`map.rollover.${ LayerNames.ScheduledMonuments }.category`),
            getStyle: () => this.style,
            getSource: (features) => {
                const properties = features[0].getProperties() as ScheduledMonumentsFeaturesType
                return properties.england ? 'Historic England' : 'Cadw'
            },
            getPrimary: (features) => {
                const properties = features[0].getProperties() as ScheduledMonumentsFeaturesType
                return properties.name
            },
            getExtended(features) {
                const properties = features[0].getProperties() as ScheduledMonumentsFeaturesType
                return [
                    {
                        name: i18n.global.t(`map.rollover.${ LayerNames.ScheduledMonuments }.extended.entryNumber`),
                        value: properties.entry_number,
                    },
                    {
                        name: i18n.global.t(`map.rollover.${ LayerNames.ScheduledMonuments }.extended.listedDate`),
                        value: properties.date_first_listed ? format(properties.date_first_listed, 'dd LLL yyyy') : '-',
                    },
                ]
            },
        }

        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onClickFn = (features: Array<FeatureLike>) => {
                const properties = features[0].getProperties()
                if (!isNullOrWhitespace(properties?.external_link)) {
                    window.open(properties.external_link, '_blank')
                    this.eventLogger.logEvent({
                        type: 'MAP - Scheduled Monuments point clicked',
                        metadata: properties.external_link,
                    })
                }
            }
            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Toggle Scheduled Monuments layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                const textArray: Array<string> = []
                if (features.length > 0) {
                    const properties: ScheduledMonumentsFeaturesType = features[0].getProperties() as ScheduledMonumentsFeaturesType
                    textArray.push(...ScheduledMonumentsLayer.getHoverTextArray(properties))
                }
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public static getHoverTextArray(properties: ScheduledMonumentsFeaturesType) {
        const listedDate = properties.date_first_listed ? format(properties.date_first_listed, 'dd LLL yyyy') : '-'
        const source = properties.england ? 'Historic England' : 'Cadw'
        return [`${ i18n.global.t('map.options.scheduledMonuments.hover.name') }: ${ properties.name }`,
            `${ i18n.global.t('map.options.scheduledMonuments.hover.entryNumber') }: ${ properties.entry_number }`,
            `${ i18n.global.t('map.options.scheduledMonuments.hover.dateFirstListed') }: ${ listedDate }`,
            `${ i18n.global.t('map.options.scheduledMonuments.hover.source') }: ${ source }`,
        ]
    }

    public style:StyleLike = new Style({
        fill: new Fill({ color: 'rgba(123, 104, 238, 0.6)' }),
    })
}
