<template>
    <ow-modal v-model="show"
              persistent
              title="Product Configuration"
              content-class="matter-searches-arg-ss-combo-product-config-dialog">
        <div class="matter-searches-arg-ss-combo-product-config-dialog__content">
            <div class="d-flex gr-2 pt-2 flex-column">
                <div class="d-flex flex-row align-center justify-space-between">
                    <label class="caption-regular w-50"
                           for="currentSizeUse-textfield">
                        {{ $t('searches.productConfig.argSSCombo.currentSiteUse') }}
                    </label>
                    <ow-select id="currentSizeUse-textfield"
                               v-model="currentSiteUse"
                               class="matter-searches-arg-ss-combo-product-config-dialog__input"
                               hide-selected
                               is-outlined
                               is-dense
                               clearable
                               hide-details
                               :items="['Residential', 'Commercial', 'Industrial', 'Mixed', 'Other']" />
                </div>
                <div class="d-flex flex-row align-center justify-space-between">
                    <label class="caption-regular w-50"
                           for="proposedSiteUse-textfield">
                        {{ $t('searches.productConfig.argSSCombo.proposedSiteUse') }}
                    </label>
                    <ow-select id="proposedSiteUse-textfield"
                               v-model="proposedSiteUse"
                               class="matter-searches-arg-ss-combo-product-config-dialog__input"
                               hide-selected
                               is-outlined
                               is-dense
                               clearable
                               hide-details
                               :items="['Residential', 'Commercial', 'Industrial', 'Mixed', 'Other']" />
                </div>
                <div class="d-flex flex-row align-center justify-space-between">
                    <label class="caption-regular w-50"
                           for="yearsInCurrentUse-textfield">
                        {{ $t('searches.productConfig.argSSCombo.yearsInCurrentUse') }}
                    </label>
                    <ow-textfield id="yearsInCurrentUse-textfield"
                                  v-model="yearsInCurrentUse"
                                  class="matter-searches-arg-ss-combo-product-config-dialog__input"
                                  type="number"
                                  :min="0"
                                  :max="100"
                                  is-outlined
                                  is-dense
                                  hide-details />
                </div>
                <v-divider class="my-2" />
                <div class="d-flex flex-row align-center justify-space-between">
                    <label class="caption-regular w-50"
                           for="plannedDevelopment-textfield">
                        {{ $t('searches.productConfig.argSSCombo.plannedDevelopment') }}
                    </label>
                    <v-switch v-model="plannedDevelopment"
                              class="matter-searches-arg-ss-combo-product-config-dialog__input pl-2"
                              color="primary"
                              hide-details />
                </div>
                <div class="d-flex flex-row align-center justify-space-between">
                    <label class="caption-regular w-50"
                           for="additionalConsiderations-textfield">
                        {{ $t('searches.productConfig.argSSCombo.additionalConsiderations') }}
                    </label>
                    <ow-select id="additionalConsiderations-textfield"
                               v-model="additionalConsiderations"
                               class="matter-searches-arg-ss-combo-product-config-dialog__input"
                               hide-selected
                               is-outlined
                               is-dense
                               hide-details
                               clearable
                               :items="['Purchase', 'Sale', 'Regeneration']" />
                </div>
            </div>
        </div>
        <template #actions>
            <ow-card-actions has-secondary-button
                             no-padding
                             primary-button-data-test="con29Dwc-product-config-confirm"
                             secondary-button-data-test="con29Dwc-product-config-cancel"
                             :primary-button-text="$t('action.save')"
                             :primary-button-disabled="!dirty || !valid"
                             @primary-button-click="handleConfirm"
                             @secondary-button-click="handleCancel" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        computed,
        nextTick,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwSelect from '@/components/core/ow-select.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { AdditionalConsiderations,
             ArgSSComboProductConfig,
             CurrentProposedSiteUse } from '@/components/matter-searches/models/BasketItemArgSSComboModel'
    import {
        SEARCHES_GET_ARG_SS_COMBO_CONFIG,
        SEARCHES_GET_ARG_SS_COMBO_CONFIG_SHOW,
        SEARCHES_GET_SELECTED_BASKET_ITEM,
        SEARCHES_MUTATE_BASKET_DIRTY,
        SEARCHES_MUTATE_PRODUCT_CONFIG,
        SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW,
        SEARCHES_QUOTE} from '@/store/modules/searches/types'

    const store = useStore()

    const selectedBasketItem = computed(() => store.getters[SEARCHES_GET_SELECTED_BASKET_ITEM])

    const show = computed<boolean>({
        get: () => store.getters[SEARCHES_GET_ARG_SS_COMBO_CONFIG_SHOW],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW, {
                model: selectedBasketItem.value,
                show: val,
            })
        },
    })

    const config = computed<ArgSSComboProductConfig>({
        get: () => store.getters[SEARCHES_GET_ARG_SS_COMBO_CONFIG],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG, {
                model: selectedBasketItem.value,
                config: val,
            })
        },
    })

    const currentSiteUse = ref<CurrentProposedSiteUse>()
    const proposedSiteUse = ref<CurrentProposedSiteUse>()
    const additionalConsiderations = ref<AdditionalConsiderations>()
    const yearsInCurrentUse = ref<number>()
    const plannedDevelopment = ref<boolean>()

    let defaultConfig: ArgSSComboProductConfig = null
    const dirty = ref(false)
    const valid = ref(false)
    const initialising = ref(true)

    const handleCancel = () => {
        show.value = false
    }

    const validateConfig = () => {
        valid.value = currentSiteUse.value &&
            proposedSiteUse.value &&
            yearsInCurrentUse.value !== undefined
    }

    const handleConfirm = () => {
        if (!valid.value) {
            return
        }

        config.value = {
            currentSiteUse: currentSiteUse.value,
            proposedSiteUse: proposedSiteUse.value,
            additionalConsiderations: additionalConsiderations.value,
            yearsInCurrentUse: yearsInCurrentUse.value,
            plannedDevelopment: plannedDevelopment.value,
        }
        show.value = false
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, true)
        store.dispatch(SEARCHES_QUOTE)
    }

    watch(() => currentSiteUse.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.currentSiteUse
        }
        validateConfig()
    })

    watch(() => proposedSiteUse.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.proposedSiteUse
        }
        validateConfig()
    })

    watch(() => additionalConsiderations.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.additionalConsiderations
        }
        validateConfig()
    })

    watch(() => yearsInCurrentUse.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.yearsInCurrentUse
        }
        validateConfig()
    })

    watch(() => plannedDevelopment.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.plannedDevelopment
        }
        validateConfig()
    })

    watch(() => show.value, (val) => {
        if (!val) {
            currentSiteUse.value = 'Commercial',
            proposedSiteUse.value = 'Commercial',
            additionalConsiderations.value = undefined
            yearsInCurrentUse.value = undefined
            plannedDevelopment.value = false
        }

        initialising.value = true
        currentSiteUse.value = config?.value?.currentSiteUse
        proposedSiteUse.value = config?.value?.proposedSiteUse
        additionalConsiderations.value = config?.value.additionalConsiderations
        yearsInCurrentUse.value = config?.value?.yearsInCurrentUse
        plannedDevelopment.value = config?.value.plannedDevelopment
        defaultConfig = JSON.parse(JSON.stringify({
            currentSiteUse: currentSiteUse.value,
            proposedSiteUse: proposedSiteUse.value,
            additionalConsiderations: additionalConsiderations.value,
            yearsInCurrentUse: yearsInCurrentUse.value,
            plannedDevelopment: plannedDevelopment.value,
        }))
        dirty.value = false

        nextTick(() => {
            initialising.value = false
        })

    }, {
        immediate: true,
    })
</script>

<style lang="scss">
@import './matter-searches-arg-ss-combo-product-config-dialog.scss';
</style>
