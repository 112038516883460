<template>
    <div id="title-organiser-panel"
         class="title-organiser-panel"
         :class="{ 'top-nav-visible': isTopNavVisible }"
         data-test="title-organiser"
         :style="componentStyle">
        <header class="title-organiser-panel__panel-header">
            <h1 v-t="'matter.titleOrganiser.name'"
                class="content__subheading" />
            <v-icon class="title-organiser__panel-header--close-icon"
                    data-test="title-organiser-close-btn"
                    data-track="TITLE-ORGANISER - X button"
                    @click="onCancelClick">
                $close
            </v-icon>
        </header>
        <div class="title-organiser-panel__divider" />
        <div v-if="!confirmChanges">
            <section class="title-organiser-panel__styling">
                <h2 v-t="'matter.titleOrganiser.quickStyling'"
                    class="body-highlight" />
                <div class="d-flex py-2 pr-2 title-organiser-panel__quick-styling">
                    <div v-t="'matter.titleOrganiser.quickStylingDescription'"
                         class="caption-regular" />
                    <v-switch v-model="applyQuickStyling"
                              color="primary"
                              hide-details
                              data-test="title-organiser-quick-styling-switch"
                              data-track="TITLE ORGANISER - Quick Styling button"
                              :data-track-value="applyQuickStyling" />
                </div>
            </section>

            <div class="title-organiser-panel__divider"
                 style="margin: 0 24px;" />

            <section class="title-organiser-panel__items-header">
                <h2 v-t="'matter.titleOrganiser.groupTitlesBy'"
                    class="body-highlight" />
            </section>
            <div class="title-organiser-panel__divider" />
            <section class="title-organiser-panel__items">
                <v-radio-group v-model="selectedId"
                               density="compact"
                               class="title-organiser-panel__radio-group"
                               data-test="title-organiser-panel-radio-group"
                               color="primary">
                    <span v-for="service in availableServices"
                          :key="`${service.id}-container`">
                        <title-organiser-item :selected="service === selectedService"
                                              :service="service"
                                              :selected-field="selectedField"
                                              tabindex="0"
                                              @select="onSelectService"
                                              @select-field="onSelectField">
                            <div v-if="selectedColumn(service)"
                                 class="title-organiser-panel__selected">
                                <span>Selected: {{ selectedColumn(service) }}</span>
                            </div>
                        </title-organiser-item>
                        <div class="title-organiser-panel__divider" />
                    </span>
                </v-radio-group>
            </section>

            <section class="title-organiser-panel__actions">
                <ow-button data-test="title-organiser-panel-cancel"
                           full-width
                           :disabled="loading"
                           data-track="TITLE-ORGANISER - Cancel button"
                           @click="onCancelClick">
                    <span v-t="'action.cancel'" />
                </ow-button>
                <ow-button data-test="title-organiser-panel-save"
                           full-width
                           :is-primary="hasAppliedChanges"
                           :disabled="disableSaveButton"
                           data-track="TITLE-ORGANISER - Save button"
                           @click="onSaveClick">
                    <span v-t="'action.saveChanges'" />
                </ow-button>
            </section>
        </div>
        <div v-else>
            <section class="title-organiser-panel__confirm-changes"
                     data-test="title-organiser-panel-confirm-changes">
                <span v-t="'matter.titleOrganiser.confirmChangesDescription'"
                      class="body-regular" />
                <div class="title-organiser-panel__warning">
                    <div class="title-organiser-panel__warning-icon">
                        <ow-icon-warning width="20px"
                                         height="20px" />
                    </div>
                    <span v-t="'matter.titleOrganiser.confirmChangesWarning'"
                          class="caption-highlight" />
                </div>
            </section>
            <div class="title-organiser-panel__divider" />
            <section class="title-organiser-panel__actions">
                <ow-button data-test="title-organiser-panel-back"
                           data-track="TITLE-ORGANISER - Back button"
                           :disabled="loading"
                           full-width
                           @click="confirmChanges = false">
                    <span v-t="'action.back'" />
                </ow-button>
                <ow-button data-test="title-organiser-panel-confirm-btn"
                           :is-primary="hasAppliedChanges"
                           :disabled="disableSaveButton"
                           data-track="TITLE-ORGANISER - Confirm button"
                           full-width
                           @click="confirmChangesAndDismiss">
                    <span v-t="'action.confirm'" />
                </ow-button>
            </section>
        </div>
    </div>
</template>

<script lang="ts">

    import { PropType,
             StyleValue } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwIconWarning from '@/components/core/icons/ow-icon-warning.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import TitleOrganiserItem from '@/components/title-organiser/title-organiser-item.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { IState } from '@/interfaces/store/state.interface'
    import { ITitleOrganiserService } from '@/store/modules/matter/title-organiser/title-organiser-service.interface'
    import {
        MATTER_GET_AVAILABLE_SERVICES,
        MATTER_MUTATE_STYLE_PROMPT,
        MATTER_MUTATE_TITLE_ORGANISER_QUICK_STYLING,
        MATTER_MUTATE_TITLE_ORGANISER_SELECTED_FIELD,
        MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE,
    } from '@/store/modules/matter/types'
    import {
        MATTER_TITLE_ORGANISER_APPLY_CHANGES,
        MATTER_TITLE_ORGANISER_INITIALISE_MAP,
        MATTER_TITLE_ORGANISER_RELOAD_MATTER_CONTENT,
        MATTER_TITLE_ORGANISER_RESET_MAP,
    } from '@/store/modules/organisation-hub/types'
    import {
        TITLE_CLEAR,
        TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
        TITLE_SHOW_SELECTION_LAYER,
        TITLE_SHOW_TITLE_BOUNDARY,
    } from '@/store/modules/titles/types'

    export default {
        name: 'TitleOrganiserPanel',
        components: {
            OwIconWarning,
            OwButton,
            TitleOrganiserItem,
        },
        props: {
            anchorElementSelector: {
                type: String,
                required: false,
                default: () => {},
            },
            anchorOffset: {
                type: Array as PropType<Array<number>>,
                required: false,
                default: () => [0, 0, 0, 0],
            },
        },
        emits: ['dismiss'],

        setup() {
            const { isTopNavVisible } = useMapTopNav()
            return {
                isTopNavVisible,
            }
        },
        data() {
            return {
                confirmChanges: false,
            }
        },
        computed: {
            ...mapState({
                titleOrganiserServices: (state: IState) => state.matter.titleOrganiser.availableServices,
                selectedService: (state: IState) => state.matter.titleOrganiser.selectedService,
                currentMatter: (state: IState) => state.matter.currentMatter,
                titleOrganiser: (state: IState) => state.matter.titleOrganiser,
                loading: (state: IState) => state.matter.titleOrganiser.loading,
            }),
            ...mapGetters({
                availableServices: MATTER_GET_AVAILABLE_SERVICES,
            }),

            applyQuickStyling: {
                get(): boolean {
                    return this.titleOrganiser?.applyQuickStyling
                },
                set(val) {
                    this.setQuickStyling(val)
                    this.reloadTitleOrganiserContent()
                },
            },

            selectedField: {
                get(): string {
                    return this.titleOrganiser?.selectedField
                },
                set(val) {
                    this.setSelectedField(val)
                    this.reloadTitleOrganiserContent()
                },
            },

            selectedId: {
                get(): string {
                    return this.selectedService?.id
                },
                set(val) {
                    const service = this.availableServices.find((service) => service.id === val)
                    this.onSelectService(service)
                },
            },

            componentStyle(): StyleValue {
                if (!this.anchorElementSelector) {
                    return null
                }
                const anchorElementRect = document.querySelector(this.anchorElementSelector)?.getBoundingClientRect()
                if (!anchorElementRect) {
                    return null
                }
                const left = anchorElementRect.left + anchorElementRect.width
                let style = {} as any
                if (this.anchorOffset?.length == 4) {
                    style = {
                        marginTop: `${ this.anchorOffset[0] }px`,
                        marginRight: `${ this.anchorOffset[1] }px`,
                        marginBottom: `${ this.anchorOffset[2] }px`,
                        marginLeft: `${ this.anchorOffset[3] }px`,
                    }
                }
                style.left = `${ left }px`
                return style
            },
            hasAppliedChanges(): boolean {
                return (this.selectedService?.hasAppliedChanges ?? false) ||
                    this.applyQuickStyling
            },
            shouldDisplayConfirmationPrompt(): boolean {
                // If the matter has groups or visible titles, display the confirmation prompt.
                return this.currentMatter.groups.length > 0 || this.currentMatter.selectedTitles.some(t => t.show)
            },

            disableSaveButton(): boolean {
                return this.loading || !this.hasAppliedChanges
            },

        },

        watch: {
            CONFIG_GET_FEATURE_FLAGS: {
                handler(value) {
                    console.info('Feature flags changed. Reloading title organiser content.')
                    this.availableServices.forEach((service: ITitleOrganiserService) => {
                        service.isAvailable(value)
                        service.onInit()
                    })
                },
            },
        },

        async mounted() {
            // initialise services
            this.availableServices.forEach((service: ITitleOrganiserService) => {
                service.onInit()
            })
            // select first service
            this.onSelectService(this.titleOrganiserServices[0])

            // When the panel is mounted, we should dismiss various other panels.
            await this.clearSelectedTitle()
            this.setExpandedTitle(null)
            await this.showTitleBoundary(null)
            this.setTitleSelectionLayerVisible(false)
            this.styleTitles({ titles: [] })
            this.initialiseMap()
        },

        async beforeUnmount() {
            this.setTitleSelectionLayerVisible(true)
            this.setExpandedTitle(null)
            this.$emit('dismiss')
            await this.showTitleBoundary(null)
        },

        methods: {
            ...mapMutations({
                setExpandedTitle: TITLE_MUTATE_EXPANDED_TITLE_NUMBER,
                styleTitles: MATTER_MUTATE_STYLE_PROMPT,
                selectService: MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE,
                setQuickStyling: MATTER_MUTATE_TITLE_ORGANISER_QUICK_STYLING,
                setSelectedField: MATTER_MUTATE_TITLE_ORGANISER_SELECTED_FIELD,
                setTitleSelectionLayerVisible: TITLE_SHOW_SELECTION_LAYER,
            }),

            ...mapActions({
                clearSelectedTitle: TITLE_CLEAR,
                showTitleBoundary: TITLE_SHOW_TITLE_BOUNDARY,
                reloadTitleOrganiserContent: MATTER_TITLE_ORGANISER_RELOAD_MATTER_CONTENT,
                applyChanges: MATTER_TITLE_ORGANISER_APPLY_CHANGES,
                initialiseMap: MATTER_TITLE_ORGANISER_INITIALISE_MAP,
                resetMap: MATTER_TITLE_ORGANISER_RESET_MAP,
            }),

            async onSaveClick(): Promise<void> {
                if (this.shouldDisplayConfirmationPrompt) {
                    this.confirmChanges = true
                } else {
                    await this.applyChanges()
                    this.resetMap()
                    this.$emit('dismiss')
                }
            },

            onCancelClick(): void {
                this.resetMap()
                this.$emit('dismiss')
            },

            async confirmChangesAndDismiss(): Promise<void> {
                await this.applyChanges()
                this.resetMap()
                this.$emit('dismiss')
            },

            onSelectService(service): void {
                if (this.selectedId == service.id) {
                    return
                }
                this.selectService(service)
                this.applyQuickStyling = service.quickStylingByDefault

                this.reloadTitleOrganiserContent()
            },

            onSelectField({ field, service }): void {
                this.onSelectService(service)
                this.selectedField = field
            },

            selectedColumn(service): string {
                if (service.options?.columns && this.selectedField) {
                    return service.options.columns.find(c => c.field == this.selectedField)?.text
                }
                return null
            },
        },
    }
</script>

<style lang="scss">
@import './title-organiser-panel';
</style>
