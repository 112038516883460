<template>
    <div :id="overlayId"
         class="map-rollover-overlay rounded-lg pa-0">
        <div v-for="(category, categoryIndex) in Object.keys(categories ?? {}).splice(0, maxCategories)"
             :key="categoryIndex"
             class="map-rollover-overlay__categories px-4 pt-3">
            <map-rollover-section v-show="firstSectionInCategory(category)"
                                  :id="`map-rollover-category-${ category }`"
                                  class="map-rollover-overlay__section"
                                  :header="firstSectionInCategory(category).category"
                                  :allow-click="firstSectionInCategory(category).allowClick"
                                  :click-label="firstSectionInCategory(category).clickText">
                <div v-for="(section, sectionIndex) in categories[category]"
                     :key="`map-rollover-overlay-section-${sectionIndex}`">
                    <map-rollover-section-item v-for="(item, itemIndex) in section.items"
                                               :key="`map-rollover-overlay-item-${item?.id ?? itemIndex}`"
                                               :category="firstSectionInCategory(category).category"
                                               :collapsed="Object.keys(categories).length > 1"
                                               :extended="item?.extended"
                                               :header="item?.primary"
                                               :key-colour="item?.style?.fillColour ?? item?.style?.strokeColour" />
                </div>
                <template #footer>
                    <div class="map-rollover-overlay__footer pb-2">
                        <div v-if="firstSectionInCategory(category)?.source"
                             class="map-rollover-overlay__footer-text">
                            {{ `Source: ${firstSectionInCategory(category).source}` }}
                        </div>
                    </div>
                </template>
            </map-rollover-section>
        </div>
        <div v-if="Object.keys(categories ?? {}).length > maxCategories"
             class="d-flex flex-column align-center map-rollover-overlay__overflow pa-4 justify-center">
            <div class="caption-highlight">
                {{ `+${Object.keys(categories).length - maxCategories} more` }}
            </div>
            <div class="caption-regular">
                {{ $t('map.rollover.viewAll') }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        withDefaults,
    } from 'vue'

    import { IMapRolloverSection } from './common/map-rollover-interfaces'
    import MapRolloverSection from './map-rollover-section.vue'
    import MapRolloverSectionItem from './map-rollover-section-item.vue'

    const props = withDefaults(defineProps<{
        overlayId?: string,
        categories?: Record<string, IMapRolloverSection[]>
        maxCategories?: number
    }>(), {
        categories: () => ({}),
        maxCategories: 5,
        overlayId: 'map-rollover-overlay',
    })

    const firstSectionInCategory = (category) => {
        if (!props.categories?.[category]) {
            return null
        }
        return props.categories?.[category]?.[0]
    }
</script>

<style lang="scss" scoped>
    @import './map-rollover-overlay.scss';
</style>
