import { HttpStatusCode } from 'axios'
import axiosRetry from 'axios-retry'
import qs from 'qs'

import httpClient, { HttpClient } from '@/api/http-client'

export default class BusinessGatewayApi {
    static END_POINT = 'BusinessGateway'
    static controller // AbortController

    /**
     * Get the raw HMLR OCDA (Official Copy Document Availability) data using the title number.
     * @static
     * @param titleNumber {String}
     * @param abortController for cancelling requests
     * @returns {Promise<T | *>}
     */
    static async getOcdaByTitleNumber(titleNumber, abortController = new AbortController()) {
        const client = new HttpClient()

        axiosRetry(client.instance, {
            retries: 3,
            retryDelay: axiosRetry.exponentialDelay,
            retryCondition: (error) => {
                return error.response.status === HttpStatusCode.TooManyRequests
            },
        })
        if (!abortController) {
            // If an abort controller is not provided, use the global one.
            if (this.controller) {
                this.controller.abort()
            }

            this.controller = new AbortController()
            abortController = this.controller
        }

        const uri = `lease-hierarchy/${ titleNumber }/ocda`
        const response = await client
            .get(uri, {
                signal: abortController.signal,
            })
        return response
    }

    /**
     * Get the title information held by business gateway.
     * @static
     * @param data {Object}
     * @returns {Promise<T | *>}
     * @example
     *  The data should be provided in the following format:
     *      {
                titleNo: 'XXXX',
                forceNew: true/false, // Whether to get new data regardless
                forceNewIfNotToday: true/false, // whether to get new data if it hasn't already been loaded today
                allowBackdated: true/false, // whether backdated data is OK or not.
            }
     */
    static getTitleInformation(data) {
        const uri = `${ this.END_POINT }/GetBGTitleInformation`
        const dataToPost = qs.stringify(data)
        return httpClient
            .post(uri, dataToPost)
            .then(response => {
                switch (response.status) {
                    case 200:
                        return response.data

                    case 204:
                        return '' // no content
                }

                console.error('getTitleInformation', response)
            })
    }

    /**
     * Get the title plan PDF string held by business gateway using the title number.
     * @static
     * @param titleNumber
     * @returns {Promise<T | *>}
     */
    static getTitlePlanPdfStringByTitle(titleNumber, matterId) {
        const uri = `${ this.END_POINT }/GetBGTitlePlanStringByTitle?titleNo=${ titleNumber }&matterId=${ matterId }`
        return httpClient
            .post(uri, null)
            .then(response => response.data)
    }

    /**
     * Get a new title plan PDF string held by business gateway using the title number.
     * @static
     * @param titleNumber
     * @returns {Promise<T | *>}
     */
    static getNewTitlePlanPdfStringByTitle(titleNumber, matterId) {
        const uri = `${ this.END_POINT }/GetNewBGTitlePlanStringByTitle?titleNo=${ titleNumber }&matterId=${ matterId }`
        return httpClient
            .post(uri, null)
            .then(response => response.data)
    }

    /**
     * Get the register PDF string held by business gateway using the title number.
     * @static
     * @param titleNumber
     * @returns {Promise<T | *>}
     */
    static getRegisterPdfStringByTitle(titleNumber, matterId) {
        const uri = `${ this.END_POINT }/GetBGRegisterStringByTitle?titleNo=${ titleNumber }&matterId=${ matterId }`
        return httpClient
            .post(uri, null)
            .then(response => response.data)
    }

    /**
     * Get a new register PDF string held by business gateway using the title number.
     * @static
     * @param titleNumber
     * @returns {Promise<T | *>}
     */
    static getNewRegisterPdfStringByTitle(titleNumber, matterId) {
        const uri = `${ this.END_POINT }/GetNewBGRegisterStringByTitle?titleNo=${ titleNumber }&matterId=${ matterId }`
        return httpClient
            .post(uri, null)
            .then(response => response.data)
    }

    /**
     * Get a backdated register PDF string held by business gateway using the title number.
     * @static
     * @param titleNumber
     * @returns {Promise<T | *>}
     */
    static getBackDatedRegisterByTitle(titleNumber, matterId) {
        const uri = `${ this.END_POINT }/GetBackDatedBGRegisterByTitle?titleNo=${ titleNumber }&matterId=${ matterId }`
        return httpClient
            .post(uri, null)
            .then(response => response.data)
    }

    /**
     * Get a backdated title plan PDF string held by business gateway using the title number.
     * @static
     * @param titleNumber
     * @returns {Promise<T | *>}
     */
    static getBackDatedTitlePlanByTitle(titleNumber, matterId) {
        const uri = `${ this.END_POINT }/GetBackdatedBGTitlePlanStringByTitle?titleNo=${ titleNumber }&matterId=${ matterId }`
        return httpClient
            .post(uri, null)
            .then(response => response.data)
    }

    /**
     * Get a backdated title queries as a CSV.
     * @static
     * @param data {Object}
     * @example
     *  The data object should be in the following for:
     *      {
     *          selectedFields: [
     *              'pricePaid',
     *              'leaseDate',
     *              ...
     *          ],
     *          titleNumbers: [
     *              'TGL50538',
     *              ...
     *          ]
     *      }
     * @returns {Promise<T | *>}
     */
    static getMultipleBackDatedQueriesAsCsv(data) {
        const uri = `${ this.END_POINT }/GetMultipleBGQueriesCSV`
        return httpClient
            .post(uri, data)
            .then(response => response.data)
    }

    /**
     * Export the Official Copies of title registers/title plans.
     * @static
     * @param request {Object}
     * @example
     *  The data object should be in the following for:
     *      {
     *          titleNumbers: [],
     *          registers: true,
     *          titleplans: true
     *      }
     * @returns {Promise<T | *>}
     */
    static exportOfficialCopies(request) {
        const uri = `${ this.END_POINT }/BulkOfficialCopies`
        return httpClient
            .post(uri, request)
            .then(response => response.data)
    }

    /**
     * Save the bulk download blob to the user's device.
     * @param filename {String}
     */
    static async saveBulkExportFile(filename) {
        const resultUrl = `${ this.END_POINT }/GetBulkExportFile?filename=${ filename }`
        const response = await httpClient.get(resultUrl, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/zip' })
        const outputFilename = response.headers['content-disposition']
            .split('filename="')[1]
            .split('"')[0]
        window.saveAs(blob, outputFilename)
    }

    /**
     * Save the bulk backdated query results as a CSV file to the user's device.
     * @param filename {String}
     */
    static async saveBulkBackDatedQueryResultsCsvFile(filename, matterId) {
        const resultUrl = `${ this.END_POINT }/GetMultipleBGQueriesCSVFile?filename=${ filename }&matterId=${ matterId }`
        const response = await httpClient.get(resultUrl, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const outputFilename = response.headers['content-disposition']
            .split('filename="')[1]
            .split('"')[0]
        window.saveAs(blob, outputFilename)
    }

    /**
     * Save the bulk day list query results as a CSV file to the user's device.
     * @param filename {String}
     */
    static async saveBulkDayListQueryResultsCsvFile(filename) {
        const resultUrl = `${ this.END_POINT }/GetMultipleBGDaylistCSVFile?filename=${ filename }`
        const response = await httpClient.get(resultUrl, { responseType: 'blob' })
        if (response.ok) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const outputFilename = response.headers['content-disposition']
                .split('filename="')[1]
                .split('"')[0]
            window.saveAs(blob, outputFilename)
        }
    }
}
