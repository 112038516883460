<template>
    <div class="overlaying-plans-container">
        <header class="caption-highlight">
            <router-link :to="{
                             name: Route.OverlaysList,
                             params: {
                                 matterId: matterId.toString(),
                             },
                         }"
                         data-track="OVERLAY PLANS - Back to overlaid plans list">
                <v-icon>$chevron-left</v-icon>
                {{ t('mapOverlays.panel.backToOverlaidPlans') }}
            </router-link>
            <ow-progress v-if="loading"
                         size="small"
                         color="primary"
                         class="overlaying-plans-container__progress"
                         data-test="overlaying-plans-container-progress" />
        </header>
        <section class="overlaying-plans-container__title">
            <h3 class="caption-highlight overlaying-plans-container__title--name"
                @click="onTitleClick">
                {{ overlay.name }}
            </h3>

            <p class="accents-small overlaying-plans-container__title--details">
                {{ $t('mapOverlays.panel.page') }} {{ overlay.targetPages.toString() }} - {{ format(overlay.createdOn) }}
            </p>
            <i class="accents-small overlaying-plans-container__title--details">
                {{ $t('mapOverlays.panel.lastUpdated') }}:
                <span>
                    {{ format(getUpdatedOn(), 'dd-MM-yyyy HH:mm:ss') }}
                </span>
            </i>
        </section>

        <div v-if="!cropping">
            <section class="overlaying-plans-container__main">
                <h3 class="caption-highlight">
                    {{ t('mapOverlays.panel.position') }}
                </h3>
                <p class="caption-italic mb-4">
                    {{ t('mapOverlays.panel.positionHint') }}
                </p>

                <fieldset class="d-flex justify-space-between align-center overlaying-plans-container__main--selector">
                    <label class="caption-highlight">{{ $t('mapOverlays.panel.planScale') }}</label>
                    <ow-textfield v-model="scaleRatio"
                                  :step="1"
                                  :min="1"
                                  data-track="OVERLAY PLANS - Plan scale input"
                                  data-test="overlaying-plans-container-scale-text-field"
                                  type="number">
                        <template #iconPrefix>
                            1:
                        </template>
                    </ow-textfield>
                </fieldset>

                <fieldset class="d-flex flex-column overlaying-plans-container__main--selector">
                    <label class="caption-highlight">{{ $t('mapOverlays.panel.transparency') }}</label>
                    <span class="d-flex flex-row justify-space-between align-center">
                        <ow-slider v-model="transparency"
                                   :max="100"
                                   :min="0"
                                   :step="1"
                                   label="Transparency"
                                   data-track="OVERLAY PLANS - Transparency slider"
                                   simple
                                   unit="%" />
                        <ow-textfield v-model="transparency"
                                      :step="1"
                                      data-track="OVERLAY PLANS - Transparency input"
                                      data-test="overlaying-plans-container-transparency-text-field"
                                      type="number">
                            <template #iconSuffix>
                                %
                            </template>
                        </ow-textfield>
                    </span>
                </fieldset>

                <fieldset class="d-flex flex-column overlaying-plans-container__main--selector">
                    <label class="caption-highlight">{{ $t('mapOverlays.panel.rotation') }}</label>
                    <span class="d-flex flex-row justify-space-between align-center">
                        <ow-slider v-model="rotationSlider"
                                   :max="180"
                                   :min="-180"
                                   label="Rotation"
                                   data-track="OVERLAY PLANS - Rotation slider"
                                   simple
                                   :step="0.1"
                                   force-precision-rounding
                                   unit="°" />

                        <ow-textfield v-model="rotationTextField"
                                      data-track="OVERLAY PLANS - Rotation input"
                                      data-test="overlaying-plans-container-rotation-text-field"
                                      type="number"
                                      :min="-180"
                                      :max="180"
                                      :step="0.1"
                                      :pattern="'[0-9]-'">
                            <template #iconSuffix>
                                °
                            </template>
                        </ow-textfield>
                    </span>
                    <span class="d-flex flex-row justify-content-end" />
                </fieldset>
            </section>

            <section v-if="planNotOnScreen"
                     class="overlaying-plans-container__map-actions">
                <div class="d-flex align-center flex-row">
                    <ow-button data-test="overlaying-plans-container-pan-to-plan-button"
                               data-track="OVERLAY PLANS - Pan to plan"
                               full-width
                               :disabled="!planNotOnScreen"
                               small
                               @click="panToPlan">
                        {{ $t('mapOverlays.panel.panToPlan') }}
                    </ow-button>
                    <ow-button data-test="overlaying-plans-container-plan-to-me-button"
                               full-width
                               :disabled="!planNotOnScreen"
                               data-track="OVERLAY PLANS - Bring plan to me"
                               small
                               @click="panPlanToMe">
                        {{ $t('mapOverlays.panel.planToMe') }}
                    </ow-button>
                </div>
            </section>

            <section v-if="isCroppingEnabled"
                     class="justify-center align-center flex-column mt-5">
                <div class="d-flex justify-center align-center flex-row">
                    <ow-button is-secondary
                               data-test="overlaying-plans-container-crop-plan-button"
                               data-track="OVERLAY PLANS - Crop plan"
                               full-height
                               class="w-75"
                               @click="cropPlan">
                        <template #iconPrefix>
                            <v-icon>crop</v-icon>
                        </template>
                        Crop Plan
                    </ow-button>
                </div>
            </section>

            <section class="overlaying-plans-container__main-actions">
                <div class="d-flex flex-row">
                    <ow-button data-test="overlaying-plans-container-done-button"
                               data-track="OVERLAY PLANS - Done"
                               full-height
                               full-width
                               is-primary
                               small
                               @click="onDone">
                        {{ $t('action.done') }}
                    </ow-button>
                </div>
            </section>

            <section class="justify-center align-center flex-column overlaying-plans-container__remove">
                <ow-button is-danger-light
                           is-secondary
                           data-test="overlaying-plans-container-remove-plan-button"
                           data-track="OVERLAY PLANS - Remove overlaid plan"
                           small
                           @click="onRemovePlan">
                    <template #iconPrefix>
                        <v-icon>$delete</v-icon>
                    </template>
                    {{ $t('mapOverlays.panel.removePlan') }}
                </ow-button>
            </section>
        </div>
        <div v-else>
            <section class="justify-center align-center flex-column">
                <div class="d-flex justify-center align-center flex-column">
                    <ow-button data-test="overlaying-plans-container-reset-button"
                               data-track="OVERLAY PLANS - Reset crop"
                               full-height
                               is-secondary
                               class="mt-5 w-75"
                               @click="onResetCropExtent">
                        Reset
                    </ow-button>
                    <ow-button data-test="overlaying-plans-container-save-changes-button"
                               data-track="OVERLAY PLANS - Cropping complete"
                               full-height
                               full-width
                               is-primary
                               small
                               class="mt-5"
                               @click="onCroppingComplete">
                        {{ $t('action.saveChanges') }}
                    </ow-button>
                </div>
            </section>
        </div>
        <overlays-name-edit-modal />
    </div>
</template>
<script lang="ts" setup>
    import debounce from 'lodash.debounce'
    import { intersects } from 'ol/extent'
    import olMap from 'ol/Map'
    import {
        computed,
        onMounted,
        onUnmounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwProgress from '@/components/core/ow-progress.vue'
    import OwSlider from '@/components/core/ow-slider.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { OverlaysService } from '@/components/map/overlays/overlays-service'
    import { OverlayModel } from '@/components/map/overlays/overlays-types'
    import OverlaysNameEditModal from "@/components/overlays/overlay-name-modal.vue"
    import { MAX_ZOOM_TO_TITLE_ZOOM_LEVEL } from '@/consts/map'
    import { Route } from '@/enums/route.enum'
    import { OVERLAYS_MUTATE_SHOW_NAME_MODAL } from "@/store/modules/overlays/types"
    import { waitForExpression } from '@/utils/app-utils'
    import { format } from '@/utils/date-utils'
    import {
        validBoundingBox,
        validCentrePoint,
    } from '@/utils/map-utils'

    const { t } = useI18n()
    const store: any = useStore()

    const transparency = ref<number>(40)
    const scaleRatio = ref<number>(0)
    const scalePercentage = ref<number>(100)
    const service = ref<OverlaysService>()
    const loading = ref<boolean>(false)
    const cropping = ref<boolean>(false)
    const rotationTextField = ref<string|number>(0)
    const rotationSlider = ref<number>(0)
    const isCroppingEnabled = computed(() => store.state.config.featureFlags.overlaysCroppingEnabled)

    interface Props {
        overlay: OverlayModel,
        targetMap: olMap,
        matterId: number,
        updatedOn: string
    }

    const props = defineProps<Props>()

    // Event Handlers
    const emit = defineEmits<{
        (e: 'remove', overlay: OverlayModel): void,
        (e: 'dismiss'),
        (e: 'done', overlay: OverlayModel): void,
        (e: 'updated', overlay: OverlayModel): void,
    }>()
    const onRemovePlan = () => {
        emit('remove', props.overlay)
        emit('dismiss')
    }
    const onDone = () => {
        emit('done', props.overlay)
    }

    const initialise = async () => {
        // If service requires initialisation and all requirements are available, initialise it.
        if (service.value) {
            service.value.dispose()
        }
        loading.value = true

        const ready = () => props.targetMap && props.overlay &&
            !(props.targetMap.getView().getAnimating())

        await waitForExpression(ready)

        addMapEventListeners()
        // If the opacity is not set or is set to zero, set it to 0.5 so the user can see the overlay.
        if (!props.overlay.opacity) {
            props.overlay.opacity = 0.5
        }
        scaleRatio.value = props.overlay.scale ?? 1250
        rotationTextField.value = props.overlay.rotation ?? 0.0
        rotationSlider.value = props.overlay.rotation ?? 0.0

        service.value = new OverlaysService({
            targetMap: props.targetMap,
            overlay: props.overlay,
            updateCallback: onUpdated,
            imageLoadedCallback: onImageLoaded,
        })

        await service.value.initialise()
        transparency.value = props.overlay.opacity * 100
        scalePercentage.value = 100
        panToPlan()
        if (!validCentrePoint(props.overlay.centrePoint)) {
            props.overlay.centrePoint = props.targetMap.getView().getCenter()
        }
        reEvaluatePlanPosition()
    }

    watch(rotationTextField, (value: string): void => {
        if (value.toString() !== '' &&
            value.toString() !== '-') {
            rotationSlider.value = +value
            service.value.rotate(value)
        }
    })
    watch(rotationSlider, (value: any): void => {
        if (service.value) {
            rotationTextField.value = value
            service.value.rotate(value)
        }
    })

    // Update sliders/textboxes as values changes
    watch(transparency, (value: number): void => {
        if (service.value) {
            props.overlay.opacity = Number(value) / 100
            service.value?.setTransparency(props.overlay.opacity)
        }
    })

    watch(scaleRatio, (value: number): void => {
        if (value <= 0) {
            props.overlay.scale = 1
            service.value.updateFromScaleRatio()
        } else if (service.value &&
            props.overlay.scale !== value) {
            props.overlay.scale = value
            service.value.updateFromScaleRatio()
        }
    })

    watch(scalePercentage, (value: number): void => {
        scaleRatio.value = Math.round(props.overlay.scale / 100 * value)
    })

    watch(() => props.overlay?.id, async (val) => {
        if (val) {
            await initialise()
        }
    })

    watch(() => props.overlay, (val: OverlayModel) => {
        if (val) {
            scaleRatio.value = props.overlay.scale
            onUpdated()
        }
    }, { deep: true })

    // Lifecycle hooks
    onMounted(async () => {
        await initialise()
    })

    onUnmounted(() => {
        service.value?.dispose()
        removeMapEventListeners()
    })

    const planNotOnScreen = ref<boolean>(false)

    const addMapEventListeners = () => {
        props.targetMap.on('moveend', reEvaluatePlanPosition)
    }

    const removeMapEventListeners = () => {
        props.targetMap.un('moveend', reEvaluatePlanPosition)
    }

    const reEvaluatePlanPosition = () => {
        planNotOnScreen.value = !intersects(props.targetMap.getView().calculateExtent(props.targetMap.getSize()), props.overlay.boundingBox)
    }

    const panToPlan = () => {
        if (validBoundingBox(props.overlay.boundingBox)) {
            props.targetMap.getView().fit(props.overlay.boundingBox, {
                duration: 500,
                padding: [50, 50, 50, 50],
            })
        } else if (validCentrePoint(props.overlay.centrePoint)) {
            props.targetMap.getView().setCenter(props.overlay.centrePoint)
            props.targetMap.getView().animate( { zoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL, duration: 500 })
        }
    }

    const panPlanToMe = async () => {
        props.overlay.boundingBox = service.value.calculateImageExtent(true)
        service.value.dispose()
        if (!service.value.initialised) {
            await initialise()
        }
    }

    const getUpdatedOn = () => {
        return props.updatedOn ?? props.overlay.updatedOn
    }

    const onImageLoaded = () => {
        loading.value = false
    }

    const cropPlan = () => {
        service.value.crop()
        cropping.value = true
    }

    const onCroppingComplete = () => {
        cropping.value = false
        service.value.croppingComplete()
    }
    const onResetCropExtent = () => {
        cropping.value = false
        service.value.resetCropExtent()
    }

    const onTitleClick = () => {
        store.commit(OVERLAYS_MUTATE_SHOW_NAME_MODAL, { show: true, overlay: props.overlay })
    }

    const onUpdated = debounce(() => {
        emit('updated', props.overlay)
    }, 2000, { maxWait: 2000 })

</script>
<style lang="scss" scoped>
    @import "overlaying-plans-container.scss";
</style>
