<template>
    <ow-grid class="matter-searches-order-details-table"
             :grid-config="gridConfig"
             theme="material" />
</template>


<script setup lang="ts">
    import {Store} from "@bryntum/grid"
    import {ref} from "vue"

    import OwGrid from "@/components/core/ow-grid/ow-grid.vue"
    import {useGridConfig} from "@/components/core/ow-grid/use-grid-config"
    import {useDraftOrderDetailsColumns} from "@/components/matter-searches/columns"

    const props = defineProps<{
        store: Store
    }>()

    const gridConfig = ref(useGridConfig({
        store: props.store,
        overrides: {
            rowHeight: 100,
        },
        columns: useDraftOrderDetailsColumns,
    }))

</script>

<style scoped lang="scss">
    @import './matter-searches-draft-details-table';
</style>
