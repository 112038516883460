import actions from './actions'
import getters from './getters'
import mutations from './mutations'

import { ICheckoutState } from '@/interfaces/store/checkout/checkout-state.interface'

const state: ICheckoutState = {
    items: [],
    entryPoint: '',
    orderConfirmed: false,
    basicMatterCodes: {},
    downloadedReports: [],
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
