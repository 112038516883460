
// @/stores/core/actions.ts

export default {

    /**
     * Updates reset page after update state
     *
     * @param {boolean} resetPageAfterUpdate - whether to reset the page after an update to the pagination data
     *
     */
    updateResetPageAfterUpdate(resetPageAfterUpdate: boolean): void {
        this.pagination.resetPageAfterUpdate = resetPageAfterUpdate
    },

    /**
     * Toggles the full screen state of the title panel
     */
    toggleFullscreen(): void {
        this.titlePanel.fullScreen = !this.titlePanel.fullScreen
    },
}