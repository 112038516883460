<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 48 48"
                  desc="Searches Overlay icon">
        <g clip-path="url(#a)">
            <path fill="#324A5E"
                  d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24Z" />
            <path fill="#fff"
                  d="M43.2 38.362c.491-.68.945-1.36 1.398-2.078l-5.518-10.47-9.373 1.588.189 1.058.907 5.065.302 1.663.378 2.154.303 1.663 1.285 7.181a23.63 23.63 0 0 0 7.861-5.216c.454-.415.832-.869 1.247-1.36l1.02-1.248ZM19.73 25.814l-.152.945-.189 1.02-.453 2.798-.19 1.058-1.473 8.882-.227 1.398-.416 2.495-.378 2.267a24.08 24.08 0 0 1-3.212-1.36c-.756-.378-1.436-.794-2.154-1.247-.567-.378-1.134-.756-1.664-1.21a22.332 22.332 0 0 1-3.59-3.477A25.293 25.293 0 0 1 4.12 37.38l5.14-10.016 10.47-1.55Z" />
            <path fill="#E6E9EE"
                  d="M33.07 46.223a23.663 23.663 0 0 1-4.837 1.399A22.607 22.607 0 0 1 24 48a23.97 23.97 0 0 1-7.748-1.285l.378-2.268.416-2.495.226-1.398 1.474-8.882.19-1.058.453-2.797.189-1.02.151-.945 9.978 1.587.189 1.058.907 5.027.302 1.663.378 2.154.303 1.663 1.285 7.22Z" />
            <path fill="#4CDBC4"
                  d="m37.947 27.06-8.05 1.437.907 5.064 7.974-4.724-.831-1.776Z" />
            <path fill="#84DBFF"
                  d="m43.2 38.361-3.892-8.39-8.202 5.253.378 2.155 10.734 2.267.983-1.285ZM40.934 41.007a24.02 24.02 0 0 1-7.862 5.216h-.038l-1.36-7.22h.075l9.185 2.004Z" />
            <path fill="#2C9984"
                  d="M29.896 28.498 19.578 26.76l-.642 3.855 11.867 2.948-.907-5.065Z" />
            <path fill="#54C0EB"
                  d="m31.105 35.225-12.32-3.553-1.512 8.882 14.21-3.175-.378-2.154ZM33.071 46.223c-1.512.642-3.137 1.096-4.8 1.398l-11.603-3.175.416-2.494 14.664-2.948 1.323 7.219Z" />
            <path fill="#4CDBC4"
                  d="m18.936 30.615-12.094 6.16 3.855-8.617 8.882-1.398-.643 3.855Z" />
            <path fill="#84DBFF"
                  d="m18.785 31.672-1.474 8.882-8.089 2.343a22.333 22.333 0 0 1-3.59-3.477l.189-.454 12.964-7.294ZM17.046 41.953l-.415 2.495-3.59.907c-.757-.378-1.437-.794-2.155-1.247l6.16-2.155Z" />
            <path fill="#fff"
                  d="M29.068 11.434h-13.95V36h18.425V15.923l-4.475-4.49Z" />
            <path fill="#F1543F"
                  d="M27.767 14.572h-6.872v1.5h6.872v-1.5Z" />
            <path fill="#ACB3BA"
                  d="M31.385 25.934h-14.11v.962h14.11v-.962ZM31.385 29.346h-14.11v.962h14.11v-.962ZM31.385 19.12h-14.11v.961h14.11v-.962ZM31.385 22.531h-14.11v.962h14.11v-.962ZM24.627 32.748h-7.352v.962h7.352v-.962ZM29.068 15.923h4.475l-4.475-4.49v4.49Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff"
                      d="M0 0h48v48H0z" />
            </clipPath>
        </defs>
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconSearchesIntro',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '48px',
            },
            height: {
                type: String,
                required: false,
                default: '48px',
            },
        },
    }
</script>
