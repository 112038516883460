export enum DATA_PROVIDER {
    HMLR = 0,
    SCOTLAND = 1
}

export const getDataProviderText = (dataProvider) => {
    switch (dataProvider) {
        case DATA_PROVIDER.HMLR:
            return 'HMLR'
        case DATA_PROVIDER.SCOTLAND:
            return 'Scotland'
    }
}