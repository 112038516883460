import {
    Fill,
    Style,
} from 'ol/style'
import { LayerNames } from '@/consts/map-layers'
import { BaseMvtDataLayer } from '@/store/modules/map/layers/base-mvt-data-layer'
import { MapBrowserEvent } from 'ol'
import { FeatureLike } from 'ol/Feature'
import { StyleLike } from 'ol/style/Style'
import {
    FloodZoneLayerParams,
    getFloodZoneHoverTextArray,
    getFloodZoneRolloverOptions,
} from '@/store/modules/map/layers/flood-layers-common'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { IOwSketchOrStyleProps } from '@/store/modules/sketches/types/style'
import i18n from '@/plugins/i18n'

import { FloodZoneFeaturesType } from './flood-layers-common'

const floodZone2Style: Partial<IOwSketchOrStyleProps> = {
    fillColour: 'rgba(173,203,234, 0.8)',
    strokeColour: 'transparent',
}
// This layer is currently updated manually. Note the attribution text is hard-coded.
export class FloodZone2Layer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: FloodZoneLayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:vflood_zone_2_2024'
        this.name = LayerNames.FloodZone2
        this.rolloverOptions = getFloodZoneRolloverOptions(LayerNames.FloodZone2, this.style)
        this.attributionText = [i18n.global.t('map.options.flood.attribution.englandAndWales')]

        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Toggle flood zone 2 layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                const propertiesArray: Array<FloodZoneFeaturesType> = features
                    .map(x => x.getProperties() as FloodZoneFeaturesType)

                const textArray = getFloodZoneHoverTextArray(propertiesArray)
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public name: string = LayerNames.ConservationAreas

    public style:StyleLike = new Style({
        zIndex: 310,
        fill: new Fill({ color: floodZone2Style.fillColour }),
    })

    getKeyItems(): Array<KeyConfigItemModel> {
        const featuresInExtent = this.layer
            .getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
        if (!featuresInExtent.length) {
            return []
        }

        return [{
            id: this.name,
            label: i18n.global.t('map.options.flood.text_zone_2') as string,
            style: floodZone2Style,
        }]
    }
}
