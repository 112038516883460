/**
 * Convert the notification type string in the response to the NotificationType enum.
 * @param type
 * @private
 */
import {NotificationSubType} from "@/components/asset-monitoring/notification-sub-type.enum"
import {NotificationType} from "@/components/asset-monitoring/notification-type.enum"
import i18n from '@/plugins/i18n'
import {isNullOrWhitespace} from '@/utils/string-utils'

export const convertToNotificationType = (type: string): NotificationType => {
    if (isNullOrWhitespace(type)) {
        return  NotificationType.Unknown
    }
    switch (type.toLowerCase()) {
        case 'titleregisteroutofdate':
            return NotificationType.TitleRegisterOutOfDate
        case 'companieshousechanged':
            return NotificationType.CompaniesHouseChanged
        case 'ownershipchanged':
            return NotificationType.OwnershipChanged
        case 'boundarychanged':
            return NotificationType.BoundaryChanged
        default:
            return NotificationType.Unknown
    }
}

/**
 * Convert the notification subtype string in the response to the NotificationSubType enum.
 * @param subType
 * @private
 */
export const convertToNotificationSubType = (subType: string): NotificationSubType => {
    if (isNullOrWhitespace(subType)) {
        return  NotificationSubType.Unknown
    }

    switch (subType.toString().toLowerCase()) {
        case 'completedpendingapplication':
            return NotificationSubType.CompletedPendingApplication
        case 'ownershipchange':
            return NotificationSubType.OwnershipChange
        case 'editiondatedoesntmatchocdaresponse':
            return NotificationSubType.EditionDateDoesntMatchOcdaResponse
        case 'newdocumentinocda':
            return NotificationSubType.NewDocumentInOCDA
        case 'boundarychange':
            return NotificationSubType.BoundaryChange
        case 'companiesHouse':
            return NotificationSubType.CompaniesHouse
        default:
            return NotificationSubType.Unknown
    }
}

/**
 * Returns the toaster message for a given subType.
 *
 * @param {string} subType - The subType of the notification.
 * @returns {string} The toaster message.
 */
export const getToasterMessageForSubType = (subType: string): string => {
    switch (convertToNotificationSubType(subType)) {
        case NotificationSubType.BoundaryChange:
            return `${ i18n.global.t('assetMonitoring.toaster.boundaryUpdatedMessage') }<br />${ i18n.global.t('assetMonitoring.toaster.action') }`

        case NotificationSubType.CompletedPendingApplication:
            return `${ i18n.global.t('assetMonitoring.toaster.pendingApplicationsUpdatedMessage') }<br />${ i18n.global.t('assetMonitoring.toaster.action') }`

        case NotificationSubType.NewDocumentInOCDA:
        case NotificationSubType.EditionDateDoesntMatchOcdaResponse:
            return `${ i18n.global.t('assetMonitoring.toaster.ocdaUpdatedMessage') }<br />${ i18n.global.t('assetMonitoring.toaster.action') }`

        case NotificationSubType.OwnershipChange:
            return `${ i18n.global.t('assetMonitoring.toaster.ownershipUpdatedMessage') }<br />${ i18n.global.t('assetMonitoring.toaster.action') }`

        case NotificationSubType.CompaniesHouse:
            return `${ i18n.global.t('assetMonitoring.toaster.companiesHouseUpdatedMessage') }<br />${ i18n.global.t('assetMonitoring.toaster.action') }`
    }
}
