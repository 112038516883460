import {
    HttpTransportType,
    HubConnectionBuilder,
} from '@microsoft/signalr'

import { getOrganisationHubUri } from '@/utils/environment.utils'
import { OrganisationHubActions } from '@/consts/organisation-hub'
import SecurityService from '@/api/security'

export default {
    // Initialisation
    install(app) {
        // Notification hub endpoint based on the current environment
        const uriForEnvironment = getOrganisationHubUri()

        // SignalR Connection
        let connection = null
        let isConnecting = false

        // On reconnect - methods to call on Vue.prototype.$notifications along with options to pass.
        let callOnReconnect = []
        const addCallOnReconnect = (method, options) => {
            if (!callOnReconnect.some(c => c.method === method && c.options === options)) {
                callOnReconnect.push({ method, options })
            }
        }
        const removeCallOnReconnect = (method, options) => {
            callOnReconnect = callOnReconnect.filter(c => c.method !== method && c.options !== options)
        }

        // Initialise connection
        const initConnectionIfRequired = async (force = false) => {
            if (!isConnecting && (connection === null || force === true)) {
                isConnecting = true
                if (connection !== null) {
                    await connection.stop()
                }

                connection = new HubConnectionBuilder()
                    .withUrl(uriForEnvironment, {
                        accessTokenFactory: async () => await SecurityService.getAccessToken(),
                        transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
                    })
                    .withAutomaticReconnect()
                    .build()

                await connection
                    .start({ waitForPageLoad: false, transport: ['webSockets', 'longPolling'] })
                    .then(() => {
                        isConnecting = false
                        // If subscribe requests were made prior to connection, try them again now.
                        resume()
                    })
                    .catch(err => {
                        isConnecting = false
                        console.error(err.toString())
                    })

                // Handle reconnect
                connection.onreconnected(() => {
                    resume()
                })
            }
        }

        const resume = () => {
            callOnReconnect.forEach(call => {
                app.config.globalProperties.$organisationNotifications[call.method](call.options)
            })
        }

        // Add $organisationNotifications and useful calls to Vue components
        app.config.globalProperties.$organisationNotifications = {
            /**
             * Subscribe to notifications for the specified organisation.
             * @param organisationId
             * @param callback
             */
            subscribeToOrganisation: async (organisationId: Number, callback: Function = null) => {
                await initConnectionIfRequired()

                if (connection?.connectionStarted === true) {
                    try {
                        await connection.invoke(OrganisationHubActions.Subscribe, organisationId)
                        if (callback) {
                            callback()
                        }
                        console.info(`Subscribed to notifications for organisation '${ organisationId }'.`)
                    } catch (err) {
                        console.error(err.toString())
                        throw new Error(err)
                    }
                }
                addCallOnReconnect(OrganisationHubActions.Subscribe, organisationId)
            },

            /**
             * Unsubscribe from notifications for the specified organisation.
             * @param organisationId
             */
            unsubscribeFromOrganisation: async (organisationId: Number) => {
                await initConnectionIfRequired()

                if (connection?.connectionStarted === true) {
                    connection
                        .invoke(OrganisationHubActions.Unsubscribe, organisationId)
                        .then(() => {
                            console.info(`Unsubscribed from notifications for organisation '${ organisationId }'.`)
                        })
                        .catch(err => {
                            console.error(err.toString())
                        })
                }

                removeCallOnReconnect(OrganisationHubActions.Unsubscribe, organisationId)
            },

            /**
             * Subscribe to a message stream on the organisation hub.
             * @param method
             * @param callback
             */
            on: async (method: string, callback: Function) => {
                await initConnectionIfRequired()
                return connection.on(method, (organisationId, message) => {
                    callback(organisationId, message)
                })
            },

            /**
             * Unsubscribe from a message stream on the organisation hub.
             * @param method
             * @param fn
             */
            off: (method: string, fn: Function) => {
                if (connection?.connectionStarted === true) {
                    connection.off(method, fn)
                }
            },
        }
    },
}
