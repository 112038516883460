import VectorTileLayer from 'ol/layer/VectorTile'
import {
    Stroke,
    Style,
} from 'ol/style'

const highlightStyle = new Style({
    zIndex: 500,
    stroke: new Stroke({
        color: '#fff',
        width: 2,
    }),
})
export const getNpsVectorTileHighlightLayer = (mapState, source) => {
    return new VectorTileLayer({
        visible: true,
        maxResolution: mapState.nps.defaultMaxResolution,
        preload: 0,
        zIndex: 11,
        style: (feature) => {
            const titleNumbers = getTitleNumbersForVectorTileFeature(feature)
            if (mapState.nps.overIds.includes(feature.get('ogc_fid')) ||
                mapState.nps.overTitleNumbers.some(x => titleNumbers.includes(x))) {
                return highlightStyle
            } else {
                return null
            }
        },
        source,
        tileOptions: { crossOriginKeyword: 'anonymous' },
        transitionEffect: null,
        updateWhileInteracting: false,
        updateWhileAnimating: false,
        renderMode: 'vector',
    })
}

export const getBoundaryDataAttribution = (versionText) => {
    /* Adding a whitespace character at the end of this string seems to be a workaround to an issue with the
    snapshot feature which uses the html-to-image library - it otherwise removes the last block of text from some
    divs in the image it generates - not sure why. Hopefully we can retire that client-side approach and create map snapshots
    on the server instead, at which point this workaround should no longer be needed */
    return `Boundary data for indicative use only - released ${ versionText } `
}

export const getTitleNumbersForVectorTileFeature = (feature) => {
    const titleNumbers = [].concat(
        feature.get('leasehold')?.split(',') || [],
        feature.get('freehold')?.split(',') || [],
        feature.get('other')?.split(',') || [])
    return titleNumbers
}

export const getTitleNumbersAndTenuresForVectorTileFeature = (feature) => {
    const tenureCodes = feature.get('tenure')?.split(',')
    const leasehold = feature.get('leasehold')?.split(',').map((number, idx) => ({
        titleNumber: number,
        tenure: tenureCodes[idx],
    }))
    const freehold = feature.get('freehold')?.split(',').map((number, idx) => ({
        titleNumber: number,
        tenure: tenureCodes[idx],
    }))
    const other = feature.get('other')?.split(',').map(number => ({
        titleNumber: number,
        tenure: 'other',
    }))

    return [].concat(
        leasehold || [],
        freehold || [],
        other || [])
}

export const getTitleNumbersAndTenuresForVectorTile = (feature, state, layer) => {
    if (layer === state.nps.layer) {
        return getTitleNumbersAndTenuresForVectorTileFeature(feature)
    } else if (layer === state.dataLayers.unregisteredLand.unregisteredLandLayer) {
        const titleNo = feature.get('title_no')
        return [{
            titleNumber: titleNo,
            tenure: 'other',
        }]
    }
    return []
}

export const dedupOther = (titleObjects) => {
    const notOtherArray = titleObjects.filter(({ tenure }) => tenure !== 'other')
    const otherTitleNumbersSet = new Set(
        titleObjects.filter(({ tenure }) => tenure === 'other').map(({ titleNumber }) => titleNumber),
    )
    const dedupedOther = Array.from(otherTitleNumbersSet).map(tn => {
        return {
            titleNumber: tn,
            tenure: 'other',
        }
    })
    return [...notOtherArray, ...dedupedOther]
}
