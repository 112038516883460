<template>
    <span class="ow-loading-text"
          data-test="ow-loading-text">
        {{ text }}<span v-if="isLoading"><span class="ow-loading-text__dot1"
                                               data-test="ow-loading-text-dots">.</span><span class="ow-loading-text__dot2">.</span><span class="ow-loading-text__dot3">.</span></span>
    </span>
</template>

<script lang="ts">
    export default {
        props: {
            text: {
                type: String,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-loading-text.scss';
</style>
