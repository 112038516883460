import {
    SketchMode,
    SnappingMode,
} from '@/enums/sketches-enums'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import {
    SearchOrder,
    SearchProductQuote,
    SearchProductsFilter,
    SearchOrderDetails,
    SearchProvider,
    ISearchProductPreferenceList,
    SearchesProduct,
    SearchesHazard,
    ISearchDraftDetails,
} from './types'

import OlMap from 'ol/Map'
import { Modify } from 'ol/interaction'
import { BoundaryLayer } from '../map/layers/title-boundary-layer/boundary-layer'
import { SearchesLayer } from '../map/layers/searches-layer'
import { PropertyType } from '@/enums/searches.enum'
import { Store,
    StoreConfig } from '@bryntum/grid'
import BasketItemModel from '@/components/matter-searches/models/BasketItemModel'
import CategoryItemModel from '@/components/matter-searches/models/CategoryItemModel'
import ProductItemModel from '@/components/matter-searches/models/ProductItemModel'
import { HighwaysRaProductConfig } from '@/components/matter-searches/models/BasketItemHighwaysRaModel'
import { Con29ProductConfig } from '@/components/matter-searches/models/BasketItemTMGCon29Model'
import { Con29DwcProductConfig } from '@/components/matter-searches/models/BasketItemCon29DwcModel'
import { LLC1ProductConfig } from '@/components/matter-searches/models/BasketItemLLC1Model'
import { ArgSSComboProductConfig } from '@/components/matter-searches/models/BasketItemArgSSComboModel'
import {
    IGetOrganisationSettingsResponse,
    INotificationConfigUser,
    StructuredAddress,
} from "@/api/searches.api"
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Feature } from 'ol'
import { Geometry } from 'ol/geom'

export interface ISearchesSearchByBoundary {
    showToolActions: boolean,
    isDrawing: boolean,
}

export interface ISearchesAddress {
    address: StructuredAddress
    uprn: string,   //TODO: we can probably remove this at some point
}

export interface ISearchesState {
    contextId: string
    products: SearchesProduct[]
    categories: Store
    selectedCategory: CategoryItemModel
    selectedBasketItem: BasketItemModel
    hazards: SearchesHazard[]
    loading: boolean
    error?: Map<string, {
        request?: any,
        errors: string[],
    }>
    quote?: SearchProductQuote
    orders?: SearchOrder[]
    basket?: Store
    recommended?: Store
    otherProducts?: Store
    quoteCalculating: boolean
    filter: SearchProductsFilter
    submittingOrder?: boolean
    savingDraftOrder?: boolean
    orderDetails?: SearchOrderDetails
    draftOrderDetails?: ISearchDraftDetails
    searchProviders?: SearchProvider[]
    address?: ISearchesAddress,
    addressValid: boolean,
    filteredTitleNumbers?: any[]
    selectedTitleNumbers?: string[]
    areaSqMetres: number
    geoJSON?: any
    map?: OlMap
    boundaryLayer?: BoundaryLayer
    searchesLayer?: SearchesLayer
    propertyTypes?: {
        id: PropertyType,
        name: string,
    }[]
    productPreferences?: ISearchProductPreferenceList[]
    basketDirty?: boolean
    draftOrderId?: string
    productConfig?: {
        highwaysRa: {
            show: boolean,
            config: HighwaysRaProductConfig
        },
        tmgCon29: {
            show: boolean,
            config: Con29ProductConfig,
        },
        con29Dwc: {
            show: boolean,
            config: Con29DwcProductConfig,
        },
        tmgLLC1: {
            show: boolean,
            config: LLC1ProductConfig,
        },
        argSSCombo: {
            show: boolean,
            config: ArgSSComboProductConfig,
        }
    }
    mapKey?: string
    sketch?: {
        showSketchModeOptions: boolean,
        initialising: boolean,
        makingChangesWithUndoRedo: boolean,
        isDrawing: boolean,
        styleCache: any,
        highlightStyleCache: any,
        debouncedOnSketchChange: (addToHistory?: boolean) => void,
        layer: any,
        drawLayer: VectorLayer<VectorSource<Feature<Geometry>>>,
        searchesBoundaryLayer: VectorLayer<VectorSource<Feature<Geometry>>>,
        drawInteraction: any,
        modifyInteraction: Modify,
        snapInteraction: any,
        snapInteractionFeatures: any,
        currentSketch: any,
        targetMap: OlMap,
        highlightedSketch: any,
        currentMode: SketchMode,
        snappingMode: SnappingMode,
        history:{
            currentSketchGeoJson: Array<string>,
            currentSketchGeoJsonIndex?: number,
        },
    },
    notificationUsers?: INotificationConfigUser[],
    organisationSettings: IGetOrganisationSettingsResponse,
    projectName?: string,
    projectNameValid: boolean,
}

export const defaultState: ISearchesState = {
    basket: new Store({
        data: [],
        autoCommit: true,
        modelClass: BasketItemModel,
    } as Partial<StoreConfig>),
    recommended: new Store({
        data: [],
        autoCommit: true,
        modelClass: CategoryItemModel,
    } as Partial<StoreConfig>),
    categories: new Store({
        data: [],
        autoCommit: true,
        modelClass: CategoryItemModel,
    } as Partial<StoreConfig>),
    otherProducts: new Store({
        data: [],
        autoCommit: true,
        modelClass: ProductItemModel,
    } as Partial<StoreConfig>),
    selectedCategory: null,
    selectedBasketItem: null,
    products: [],
    hazards: [],
    loading: false,
    error: new Map<string, {
        request?: any,
        errors: string[],
    }>(),
    quote: null,
    orders: null,
    basketDirty: false,
    draftOrderId: null,
    quoteCalculating: false,
    contextId: null,
    filter: {
        searchText: '',
        searchProviderId: null,
        productPreferenceId: null,
    },
    submittingOrder: false,
    savingDraftOrder: false,
    orderDetails: null,
    searchProviders: [],
    address: null,
    filteredTitleNumbers: [],
    selectedTitleNumbers: [],
    propertyTypes: [
        {
            id: PropertyType.Commercial,
            name: 'Commercial',
        },
        {
            id: PropertyType.Residential,
            name: 'Residential',
        },
    ],
    productPreferences: null,
    productConfig: {
        highwaysRa: {
            show: false,
            config: {
                searchPurpose: '',
            },
        },
        tmgCon29: {
            show: false,
            config: {
                standardEnquiries: true,
                additionalEnquiries: false,
                roadConfig: [],
                additionalEnquiriesConfig: [],
                additionalQuestionsConfig: [],
            },
        },
        con29Dwc: {
            show: false,
            config: {
                lessThan10Years: false,
                expedited: 'Normal',
                developerName: '',
                offSiteRoadName: '',
                siteNameAndPhase: '',
            },
        },
        tmgLLC1: {
            show: false,
            config: {
                numberOfParcels: 1,
            },
        },
        argSSCombo: {
            show: false,
            config: {
                plannedDevelopment: false,
                additionalConsiderations: null,
                currentSiteUse: undefined,
                proposedSiteUse: undefined,
                yearsInCurrentUse: 0,
            },
        },
    },
    areaSqMetres: 0,
    geoJSON: null,
    map: null,
    boundaryLayer: null,
    searchesLayer: null,
    mapKey: "initial-map",
    sketch: {
        showSketchModeOptions: false,
        initialising: false,
        isDrawing: false,
        makingChangesWithUndoRedo: false,
        currentMode: SketchMode.None,
        styleCache: new Map(),
        highlightStyleCache: new Map(),
        debouncedOnSketchChange: null,
        layer: null,
        drawLayer: null,
        searchesBoundaryLayer: null,
        drawInteraction: null,
        modifyInteraction: null,
        snapInteraction: null,
        snapInteractionFeatures: null,
        currentSketch: null,
        targetMap: null,
        highlightedSketch: null,
        snappingMode: SnappingMode.None,
        history: {
            currentSketchGeoJson: [],
            currentSketchGeoJsonIndex: 0,
        },
    },
    draftOrderDetails: null,
    notificationUsers: [],
    addressValid: false,
    projectName: null,
    projectNameValid: false,
    organisationSettings: {
        projectMask: null,
    },
}

const state: ISearchesState = Object.assign({}, defaultState)

export default {
    state,
    getters,
    mutations,
    actions,
}
