import TitlesApi from '@/api/title.api'
import { useRouter } from 'vue-router'
import { Coordinate } from 'ol/coordinate'


export default {
    /**
     * Retrieves notifications for a given matter.
     * @param {string[]} inspireIds - The inspire id polygons
     */
    async matchInspireIds(inspireIds: string[]): Promise<void> {
        this.isLoading = true
        this.selectedTitleNumbers = []
        const response= await TitlesApi.matchByScottishInspireId(inspireIds)
        if (response.ok) {
            this.selectedTitleNumbers = response.data.filter(titleNumber => this.testTitleNumbers.includes(titleNumber))
        }
        this.isLoading = false
    },

    async matchClickedCoordinate(coordinate: Coordinate): Promise<void> {
        this.isLoading = true
        this.selectedTitleNumbers = []
        const response= await TitlesApi.matchByCoordinate(coordinate)
        if (response.ok) {
            this.selectedTitleNumbers = response.data
        }
        this.isLoading = false
    },
}
