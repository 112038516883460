<template>
    <div class="review-assistant-register-record-loading-skeleton">
        <h3 class="review-assistant-register-record-loading-skeleton__header">
            <ow-loading-skeleton class="review-assistant-register-record-loading-skeleton__header--prefix"
                                 height="24px"
                                 width="24" />
            <ow-loading-skeleton class="review-assistant-register-record-loading-skeleton__header--name"
                                 height="24px"
                                 width="200" />
            <v-icon>
                $chevron-up
            </v-icon>
        </h3>
        <div v-for="index in 3"
             :key="index"
             class="review-assistant-register-record-loading-skeleton__entry">
            <ow-loading-skeleton class="review-assistant-register-record-loading-skeleton__entry--code"
                                 height="24px"
                                 width="32" />
            <ow-loading-skeleton class="review-assistant-register-record-loading-skeleton__entry--body"
                                 height="48px"
                                 width="100%" />
        </div>
    </div>
</template>

<script lang="ts">
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'

    export default {
        components: {
            OwLoadingSkeleton,
        },
    }
</script>

<style lang="scss">
    @import 'register-record-loading-skeleton.scss';
</style>
