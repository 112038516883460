<template>
    <form class="manage-addresses__form">
        <fieldset class="manage-addresses__form--fieldset">
            <v-text-field id="editBuilding"
                          v-model="form.addressHouseNumber"
                          :disabled="!formIsEnabled"
                          :rules="[rules.required]"
                          autocomplete="ow"
                          :clearable="true"
                          data-test="address-form-building"
                          data-track="ACCOUNT - House or building number for alt address"
                          hint="Required"
                          label="House/building no."
                          @update:model-value="hasUpdate" />
        </fieldset>
        <fieldset class="manage-addresses__form--fieldset">
            <v-text-field id="editStreet"
                          v-model="form.addressStreet"
                          :disabled="!formIsEnabled"
                          autocomplete="ow"
                          :clearable="true"
                          data-test="address-form-street"
                          data-track="ACCOUNT - Street name for alt address"
                          label="Street name"
                          @update:model-value="hasUpdate" />
        </fieldset>
        <fieldset class="manage-addresses__form--fieldset">
            <v-text-field id="editLocality"
                          v-model="form.addressLocality"
                          :disabled="!formIsEnabled"
                          autocomplete="ow"
                          :clearable="true"
                          data-test="address-form-locality"
                          data-track="ACCOUNT - Locality for alt address"
                          label="Locality"
                          @update:model-value="hasUpdate" />
        </fieldset>
        <fieldset class="manage-addresses__form--fieldset">
            <v-text-field id="editTown"
                          v-model="form.addressTown"
                          :disabled="!formIsEnabled"
                          :rules="[rules.required]"
                          autocomplete="ow"
                          :clearable="true"
                          data-test="address-form-town"
                          data-track="ACCOUNT - Town for alt address"
                          hint="Required"
                          label="Town/city"
                          @update:model-value="hasUpdate" />
        </fieldset>
        <fieldset class="manage-addresses__form--fieldset">
            <v-text-field id="editPostcode"
                          v-model="form.addressPostcode"
                          :disabled="!formIsEnabled"
                          :rules="[rules.required]"
                          autocomplete="ow"
                          :clearable="true"
                          data-test="address-form-postcode"
                          data-track="ACCOUNT - Postcode for alt address"
                          hint="Required"
                          label="Postcode"
                          @update:model-value="hasUpdate" />
        </fieldset>
        <fieldset class="manage-addresses__form--fieldset">
            <v-checkbox v-model="hasAgreedTerms"
                        :disabled="!formIsEnabled"
                        :rules="[rules.required]"
                        color="primary"
                        data-test="address-form-consent"
                        data-track="ACCOUNT - Consent for alt address"
                        hide-details
                        @update:model-value="hasUpdate">
                <template #label>
                    <span class="content__consent-text">
                        I consent to allow Orbital Witness to store and process the
                        personal information submitted above, and to share this
                        with HMLR, in order to receive documents sent to this
                        address. I understand that Orbital Witness will not share
                        this information with any other third party, and that
                        I may delete it at any time.
                    </span>
                </template>
            </v-checkbox>
        </fieldset>
    </form>
</template>

<script>
    import { mapActions } from 'vuex'

    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'

    const EVENTS = {
        updated: 'updated',
        isValid: 'form-is-valid',
    }

    export default {
        name: 'AddressesForm',

        props: {
            address: {
                type: Object,
                required: true,
            },

            formIsEnabled: {
                type: Boolean,
                required: true,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                form: {
                    addressHouseNumber: null,
                    addressStreet: null,
                    addressLocality: null,
                    addressTown: null,
                    addressPostcode: null,
                },
                rules: {
                    required: value => !!value || '',
                },
                hasAgreedTerms: false,
            }
        },

        computed: {
            validForm() {
                // Validate address preferences
                if (!this.formHasValue('addressHouseNumber')) {
                    return false
                }
                if (!this.formHasValue('addressTown')) {
                    return false
                }
                if (!this.formHasValue('addressPostcode')) {
                    return false
                }

                return this.hasAgreedTerms
            },
        },

        watch: {
            address() {
                this.form = this.address
            },

            hasAgreedTerms(newVal) {
                this.logHeapEvent({
                    type: 'ACCOUNT- Consent for alt address',
                    metadata: {
                        hasGivenConsent: newVal,
                    },
                })
            },
        },

        mounted() {
            this.form = this.address
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            hasUpdate() {
                if (this.validForm) {
                    const newAddress = {
                        addressHouseNumber: this.getValue('addressHouseNumber'),
                        addressStreet: this.getValue('addressStreet'),
                        addressLocality: this.getValue('addressLocality'),
                        addressTown: this.getValue('addressTown'),
                        addressPostcode: this.getValue('addressPostcode'),
                    }

                    this.$emit(EVENTS.updated, newAddress)
                }

                this.$emit(EVENTS.isValid, this.validForm)
            },

            getValue(key) {
                return this.form[key] ? this.form[key] : this.address[key]
            },

            formHasValue(key) {
                const val = this.form[key]
                return val && val.length > 0
            },
        },
    }
</script>

<style lang="scss">
    @import './addresses-form'
</style>
