// Top-level document types used in the application, used in URL paths and API requests.
export const HighLevelDocumentType = {
    Register: 'register',
    TitlePlan: 'titleplan',
    OC2Document: 'oc2document',
    Spreadsheet: 'spreadsheet',
    Uploaded: 'uploaded',
    UploadedOC2: 'uploadedoc2document',
    Searches: 'searches',
}
