<template>
    <div class="address-owner-grid">
        <span class="small-card-heading address-owner-grid__title">Address</span>
        <span class="address-owner-grid__content-wrapper">
            <div class="content__label address-owner-grid__content"
                 :title="address">
                <ow-text-highlight :queries="[filter]">
                    {{ address }}
                </ow-text-highlight>
            </div>
            <div v-if="additionalAddressesCount > 0"
                 class="small-card-heading address-owner-grid__additional-content"
                 data-test="address-owner-grid-additional-addresses">
                {{ additionalAddressesText }}
            </div>
        </span>
        <span class="small-card-heading address-owner-grid__title">Owner</span>
        <ow-loading-skeleton v-if="isLoadingOwners"
                             height="12px" />
        <span v-else-if="ownerDataIsUnavailable"
              class="content__label address-owner-grid__content">
            Unavailable. Please purchase the Title Register.
        </span>
        <span v-else
              class="address-owner-grid__content-wrapper">
            <div class="content__label address-owner-grid__content"
                 :title="owners[0]">
                {{ owners[0] }}
            </div>
            <div v-if="additionalOwnersCount > 0"
                 class="small-card-heading address-owner-grid__additional-content">
                {{ additionalOwnersText }}
            </div>
        </span>
    </div>
</template>

<script lang="ts">
    import { inject } from 'vue'

    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'

    export default {
        components: { OwLoadingSkeleton, OwTextHighlight },
        props: {
            addresses: {
                type: Array,
                required: true,
            },
            owners: {
                type: Array,
                required: true,
            },
            isLoadingOwners: {
                type: Boolean,
                required: true,
            },
            ownerDataIsUnavailable: {
                type: Boolean,
                required: true,
            },
        },
        setup() {
            const filterProvider = inject('FILTER_PROVIDER')
            return {
                filterProvider,
            }
        },
        computed: {
            address(): string {
                return this.addresses?.length ? this.addresses[0] : this.$t('titles.addressUnavailable')
            },
            additionalAddressesCount(): number {
                return this.addresses?.length - 1
            },
            additionalAddressesText(): string {
                return this.$t('matter.addressOwnerGrid.additionalAddresses', this.additionalAddressesCount)
            },
            additionalOwnersCount() {
                return this.owners?.length - 1
            },
            additionalOwnersText() {
                return this.$t('matter.addressOwnerGrid.additionalOwners', this.additionalOwnersCount)
            },
            filter() {
                return this.filterProvider ?? ''
                    .toLowerCase()
                    .replace(/\W/g, ' ')
            },
        },
    }
</script>

<style lang="scss">
    @import 'address-owner-grid';
</style>
