<template>
    <ow-card :loading="isLoading || loading"
             :loading-skeleton="{ rows: 1, columns: 1, expandRows: true, reverse: false, ctas: 0 }"
             class="copies-filed"
             title="Copies Filed">
        <div class="title-summary-section">
            <v-btn v-if="totalCopiesFiled > 0"
                   class="title-lr-button copies-filed__button-view"
                   color="primary"
                   datat-track="TITLE-DETAILS-PANEL - View copies filed from summary panel"
                   size="small"
                   variant="text"
                   @click="showCopiesFiled">
                View Copies Filed
                <v-icon style="font-size: 20px; margin-left: 5px;">
                    $chevron-double-right
                </v-icon>
            </v-btn>
            <table v-if="totalCopiesFiled">
                <thead>
                    <tr>
                        <th style="width:250px;">
                            Type
                        </th>
                        <th style="width:50px;">
                            Filed
                        </th>
                        <th style="width:50px;">
                            Downloaded
                        </th>
                        <th col="button-view"
                            style="text-align: right">
                            <v-btn v-if="totalCopiesFiled > 0"
                                   class="title-lr-button"
                                   color="primary"
                                   data-test="copies-filed-view"
                                   datat-track="TITLE-DETAILS-PANEL - View copies filed from summary panel"
                                   size="small"
                                   variant="text"
                                   @click="showCopiesFiled">
                                View Copies Filed
                                <v-icon style="font-size: 20px; margin-left: 5px;">
                                    $chevron-double-right
                                </v-icon>
                            </v-btn>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(document, index) in copiesFiledWithCounts"
                        :key="index"
                        :data-test="'title-summary-copies-filed-' + document.documentType.toLowerCase()">
                        <td style="font-weight: bold; opacity: 0.6;">
                            {{ document.documentType }}
                        </td>
                        <td :data-test="'title-summary-copies-filed-count-' + document.documentType.toLowerCase()">
                            {{ document.totalCount }}
                        </td>
                        <td :data-test="'title-summary-copies-downloaded-count-' + document.documentType.toLowerCase()">
                            {{ document.downloadedCount }}
                        </td>
                        <td col="button-view" />
                    </tr>
                </tbody>
            </table>
            <span v-else
                  class="body-copy"
                  data-test="copies-filed-empty-state-message">{{ $t('officialCopiesEmptyStateText') }}.</span>
        </div>
    </ow-card>
</template>

<script lang="ts">
    import { mapState } from 'vuex'

    import OwCard from '@/components/core/ow-card.vue'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import {
        isNullOrEmpty,
        unique,
    } from '@/utils/array-utils'

    export default {
        name: 'CopiesFiled',

        components: {
            OwCard,
        },

        mixins: [flagsMixin],
        props: {
            isLoading: {
                type: Boolean,
                required: true,
            },
        },
        emits: ['set-selected-tab'],

        computed: {
            ...mapState({
                titleRegisterData: state => state.title.selectedTitle.record.bgProprietorshipData.documentDetails,
                ocAvailabilityData: state => state.title.selectedTitle.officialCopiesAvailability,
            }),

            useOcAvailabilityData() {
                return !this.ocAvailabilityData.hmlrDocumentAvailabilityStatusRateLimited &&
                    !this.ocAvailabilityData.hmlrDocumentAvailabilityStatusError
            },

            copiesFiledArray() {
                return this.useOcAvailabilityData ? this.ocAvailabilityData.results.referredToDocuments : this.titleRegisterData
            },

            totalCopiesFiled() {
                if (this.copiesFiledArray != null) {
                    return this.copiesFiledArray.length
                }
                return 0
            },

            copiesFiledWithCounts() {
                const documentType = []

                if (!isNullOrEmpty(this.copiesFiledArray)) {
                    this.copiesFiledArray
                        .forEach(item => {
                            documentType.push(item.documentType)
                        })
                    const documentTypeWithCounts = []

                    const uniqueDocumentTypes = unique(documentType)
                    uniqueDocumentTypes
                        .forEach(item => {
                            const totalCount = this.copiesFiledArray.filter(doc => {
                                return doc.documentType === item
                            }).length

                            const downloadedCount = this.copiesFiledArray.filter(doc => {
                                return doc.documentType === item && doc.filename != null && !doc.loading && !doc.documentPending
                            }).length

                            const docItem = {
                                documentType: item,
                                totalCount,
                                downloadedCount,
                            }
                            documentTypeWithCounts.push(docItem)
                        })

                    return documentTypeWithCounts
                }
                return 0
            },

            loading() {
                return this.useOcAvailabilityData && this.ocAvailabilityData.loading
            },
        },

        methods: {
            showCopiesFiled() {
                this.$emit('set-selected-tab', TitlePanelTabName.CopiesFiled)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './copies-filed-card';
</style>
