import mutations from "./mutations"
import actions from "./actions"
import {
    OverlayListItemModel,
    OverlayModel,
} from "@/components/map/overlays/overlays-types"

export interface IOverlaysState {
    showNameEditModal: boolean
    nameEditModalOverlay: OverlayListItemModel | OverlayModel
}

const state: IOverlaysState = {
    showNameEditModal: false,
    nameEditModalOverlay: null,
}

export default {
    namespaced: false,
    state,
    mutations,
    actions,
}
