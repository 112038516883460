// TODO: try and replace with library funcs from e,g turf.js
export enum ExtentPoint {
    TopLeft,
    TopRight,
    BottomRight,
    BottomLeft,
    TopCentre,
    BottomCentre,
    LeftCentre,
    RightCentre,
}

export const CropExtentPoints = [
    ExtentPoint.TopLeft,
    ExtentPoint.TopRight,
    ExtentPoint.BottomRight,
    ExtentPoint.BottomLeft,
    ExtentPoint.TopCentre,
    ExtentPoint.BottomCentre,
    ExtentPoint.LeftCentre,
    ExtentPoint.RightCentre,
]

export const Corners = [
    ExtentPoint.TopLeft,
    ExtentPoint.TopRight,
    ExtentPoint.BottomRight,
    ExtentPoint.BottomLeft,
]


export const getFeaturePoints = (
    bottomLeft: [number, number],
    bottomRight: [number, number],
    topLeft: [number, number],
    topRight: [number, number]): [number, number][] => {
    return [
        topLeft, topRight, bottomRight, bottomLeft, // corners
        midpoint(topLeft, topRight), // top centre
        midpoint(bottomLeft, bottomRight), // bottom centre
        midpoint(bottomLeft, topLeft), // left centre
        midpoint(bottomRight, topRight)] // right centre
}

export const getCropPolygon = (bl: [number, number], br: [number, number], tl: [number, number], tr: [number, number]): [number, number][] => {
    return [
        bl, tl, tr, br, bl,
    ]
}

export const midpoint = (point1: [number, number], point2: [number, number]): [number, number] => {
    return [(point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2]
}

export const translatePoint = (point: [number, number], direction: [number, number], amount: number): [number, number] => {
    return [point[0] + direction[0] * amount, point[1] + direction[1] * amount]
}

export const distanceFromPointToLine = (point: [number, number], linePoint1: [number, number], linePoint2: [number, number]): number => {
    // Convert line points to line equation coefficients (Ax + By + C = 0)
    const A = linePoint2[1] - linePoint1[1]
    const B = linePoint1[0] - linePoint2[0]
    const C = linePoint2[0] * linePoint1[1] - linePoint1[0] * linePoint2[1]

    // Apply the point-line distance formula
    const distance = Math.abs(A * point[0] + B * point[1] + C) / Math.sqrt(A ** 2 + B ** 2)
    return distance
}

export const crossProduct = (A: [number, number], B: [number, number], C: [number, number]) => {
    const AB = [ B[0] - A[0], B[1] - A[1] ]
    const AC = [ C[0] - A[0], C[1] - A[1] ]
    return AB[0] * AC[1] - AB[1] * AC[0]
}

export const getDirectionVector = (a: [number, number][], b: [number, number][]): [number, number] => {
    const midPointA = midpoint(a[0], a[1])
    const midPointB = midpoint(b[0], b[1])
    const directionVector = [midPointB[0] - midPointA[0], midPointB[1] - midPointA[1]]
    const magnitude = Math.sqrt(directionVector[0] ** 2 + directionVector[1] ** 2)
    return [directionVector[0] / magnitude, directionVector[1] / magnitude]
}

export const vectorFromPoints = (point1: [number, number], point2: [number, number]): [number, number] => {
    return [point2[0] - point1[0], point2[1] - point1[1]]
}

export const dotProduct = (v: number[], u: number[]): number => {
    if (v.length === u.length) {
        let result = 0
        for (let i = 0; i < v.length; i++) {
            result += v[i] * u[i]
        }
        return result
    } else {
        console.error('Both vectors must be of same length.')
        return undefined
    }
}
