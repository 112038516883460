export enum UploadedDocumentStatus {
    pending,
    processing,
    invalidFileType,
    failedAntiVirusCheck,
    duplicate,
    invalidFileContent,
    exceededMaximumFileSize,
    uploaded,
    scanning,
    scanningFailed,
    scanningSucceeded,
    documentType,
    extracting,
    extractingFailed,
    extractingSucceeded,
    parsing,
    parsingFailed,
    parsingSucceeded,
    complete,
}

export const uploadDocumentSuccessResponses: Array<UploadedDocumentStatus> = [
    UploadedDocumentStatus.uploaded,
    UploadedDocumentStatus.processing,
    UploadedDocumentStatus.pending,
    UploadedDocumentStatus.scanningSucceeded,
    UploadedDocumentStatus.parsingSucceeded,
    UploadedDocumentStatus.extractingSucceeded,
    UploadedDocumentStatus.complete,
]

export const uploadDocumentFailureResponses: Array<UploadedDocumentStatus> = [
    UploadedDocumentStatus.invalidFileContent,
    UploadedDocumentStatus.invalidFileType,
    UploadedDocumentStatus.duplicate,
    UploadedDocumentStatus.failedAntiVirusCheck,
    UploadedDocumentStatus.exceededMaximumFileSize,
    UploadedDocumentStatus.scanningFailed,
    UploadedDocumentStatus.extractingFailed,
    UploadedDocumentStatus.parsingFailed,
]
