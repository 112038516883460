<template>
    <v-card class="disbursable-charges elevation-3">
        <v-card-title class="content__heading">
            Disbursable Charges
        </v-card-title>
        <v-card-text>
            <v-progress-linear v-if="loading"
                               indeterminate />
            <dl v-else>
                <dt class="body-copy--greyout">
                    HMLR:
                </dt>
                <dd class="body-copy">
                    &pound;{{ charges.hmlrCharges }}
                </dd>

                <dt v-if="showOWCharges"
                    class="body-copy--greyout">
                    Orbital Witness:
                </dt>
                <dd v-if="showOWCharges"
                    class="body-copy">
                    &pound;{{ charges.softwareProviderCharges }}
                </dd>

                <dt class="body-copy--greyout">
                    Total:
                </dt>
                <dd class="body-copy">
                    &pound;{{ charges.totalDispursableCharges }}
                </dd>
            </dl>
        </v-card-text>
        <ow-card-actions v-if="showCharges">
            <v-checkbox v-model="showCharges"
                        color="primary"
                        hide-details>
                <template #label>
                    Show charges in the side bar
                </template>
            </v-checkbox>
        </ow-card-actions>
    </v-card>
</template>

<script>
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    export default {
        name: 'DisbursableCharges',
        components: { OwCardActions },
        props: {
            charges: {
                type: Object,
                required: true,
                default: () => {
                    return {
                        softwareProviderCharges: '-',
                        hmlrCharges: '-',
                        totalDispursableCharges: '-',
                    }
                },
            },
            loading: {
                type: Boolean,
                required: false,
            },
        },

        data() {
            return {
                // TODO: Currently using this to hide the show charges checkbox as it's new functionality
                showCharges: false,
            }
        },

        computed: {
            showOWCharges() {
                return this.charges.softwareProviderCharges > 0
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import './disbursable-charges'
</style>
