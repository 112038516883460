// Getters

// Mutations
export const MUTATE_SETTINGS = 'MUTATE_SETTINGS'
export const MUTATE_LOADED = 'MUTATE_LOADED'
export const MUTATE_FEATURE_FLAGS = 'MUTATE_FEATURE_FLAGS'

// Actions
export const RELOAD = 'RELOAD'
export const INIT_FEATURE_FLAGS = 'INIT_FEATURE_FLAGS'
