<template>
    <div v-if="currentMatterId"
         class="select-a-document">
        <upload-a-document class="select-a-document__upload-document"
                           :clear-files="clearFilesCounter"
                           @files="onFilesUpdated" />
        <select-document-table :current-matter-id="currentMatterId"
                               :is-current-matter-active="isCurrentMatterActive"
                               :is-loading-purchased="isLoadingPurchasedDocs"
                               :is-loading-uploaded="isLoadingUploadedDocs"
                               :is-loading-searches="isLoadingSearchesDocs"
                               :purchased-documents="filteredPurchasedDocuments"
                               :selected-purchased-document="selectedPurchasedDocument"
                               :selected-table="selectedTable"
                               :uploaded-documents="filteredUploadedDocuments"
                               :selected-uploaded-document="selectedUploadedDocument"
                               :searches-documents="filteredSearchesDocuments"
                               :selected-searches-document="selectedSearchesDocument"
                               :selected-document-id="selectedDocumentId"
                               class="select-a-document__table"
                               @change="handleDocumentSelection"
                               @clear="handleClear" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import { IFile } from '@/components/core/types/ow-file.interface'
    import { IOwFileUploaderEvent } from '@/components/core/types/ow-file-uploader-event.interface'
    import SelectDocumentTable from '@/components/map/overlays/components/select-document-table.vue'
    import UploadADocument from '@/components/map/overlays/components/upload-a-document.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { OverlayDocumentType } from '@/enums/overlay-document-type'
    import { UploadedDocumentStatus } from '@/enums/uploaded-document-status.enum'
    import { ISearchesDocument } from '@/interfaces/searches-document.interface'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import {
        DOCUMENTS_UPLOAD_FILES,
        GET_LIBRARY_DOCUMENTS,
    } from '@/store/modules/documents/documents-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { getOverlayDocumentType } from '@/utils/overlays-utils'

    enum tableTabs {
        purchased,
        uploaded,
        searches,
    }

    const store: any = useStore()
    const filteredPurchasedDocuments = ref<Array<any>>([])
    const filteredUploadedDocuments = ref<Array<IUploadedDocument>>([])
    const filteredSearchesDocuments = ref<Array<ISearchesDocument>>([])
    const selectedTable = ref<number>(tableTabs.purchased)
    const clearFilesCounter = ref<number>(0) // Hack: Increment to clear files from drop zone.
    const selectedUploadedDocument = ref<IUploadedDocument>()
    const selectedPurchasedDocument = ref<any>()
    const selectedSearchesDocument = ref<ISearchesDocument>()

    const uploadedDocuments = computed((): Array<IUploadedDocument> => store.state.documents.library.uploadedDocuments)
    const searchesDocuments = computed((): Array<ISearchesDocument> => store.state.documents.library.searchesDocuments)
    const lastUploadedDoc = computed((): IUploadedDocument => store.state.documents.library.lastUploadedDocument)
    const purchasedDocuments = computed((): Array<any> => store.getters[GET_LIBRARY_DOCUMENTS])
    const isLoadingPurchasedDocs = computed((): boolean => store.state.documents.library.loading)
    const isLoadingUploadedDocs = computed((): boolean => store.state.documents.library.loadingUploadedDocuments)
    const isLoadingSearchesDocs = computed((): boolean => store.state.documents.library.loading)
    const currentMatterId = computed((): number => store.state.matter.currentMatter.id)
    const isCurrentMatterActive = computed((): boolean => store.state.matter.currentMatter.isActive)
    const overlayDocumentType = ref<OverlayDocumentType>()



    const props = defineProps<{
        selectedDocument: any
        selectedDocumentId: string
    }>()
    watch(() => props.selectedDocument, (val) => {
        if (!val) {
            return
        }
        overlayDocumentType.value = getOverlayDocumentType(val)
        if (overlayDocumentType.value === OverlayDocumentType.Uploaded) {
            selectedUploadedDocument.value = val
            selectedPurchasedDocument.value = null
            selectedSearchesDocument.value = null
            selectedTable.value = tableTabs.uploaded
        } else if (overlayDocumentType.value === OverlayDocumentType.Searches) {
            selectedSearchesDocument.value = val
            selectedPurchasedDocument.value = null
            selectedUploadedDocument.value = null
            selectedTable.value = tableTabs.searches
        } else {
            selectedPurchasedDocument.value = val
            selectedUploadedDocument.value = null
            selectedSearchesDocument.value = null
            selectedTable.value = tableTabs.purchased
        }
    }, { immediate: true })

    const emit = defineEmits<{
        (e: 'selected', item: any)
        (e: 'uploading', files: IOwFileUploaderEvent)
        (e: 'clear')
    }>()

    const handleDocumentSelection = (item: any) => {
        emit('selected', item)
    }

    const handleClear = () => {
        emit('clear')
    }

    // Filter out any uploaded docs that weren't successful (duplicates, etc.)
    watch(uploadedDocuments, (newVal: Array<IUploadedDocument>) => {
        filteredUploadedDocuments.value = newVal
            .filter((item: IUploadedDocument) => item.status === UploadedDocumentStatus.complete ||
                item.status === UploadedDocumentStatus.uploaded) // TODO: All statuses get reset to 'uploaded' on refresh
    }, { immediate: true, deep: true })
    // Filter out any purchased docs that aren't downloadable (sent in post, etc.) and aren't registers
    watch(purchasedDocuments, (newVal: Array<any>) => {
        filteredPurchasedDocuments.value = newVal.filter((item: any) => item.showDownload && item.highLevelDocumentType !== HighLevelDocumentType.Register)
    }, { immediate: true, deep: true })
    // TODO: Filter out any searches that do not match the query order id parameter
    watch(searchesDocuments, (newVal: Array<ISearchesDocument>) => {
        if (!props.selectedDocument) {
            filteredSearchesDocuments.value = newVal
            return
        }
        filteredSearchesDocuments.value = newVal.filter((item: ISearchesDocument) => item.orderId === props.selectedDocument.orderId || item.orderItemId === props.selectedDocument.id)
    }, { immediate: true, deep: true })

    watch(() => lastUploadedDoc, (newDoc) => {
        clearFilesCounter.value++
        emit('selected', newDoc.value)
    }, { deep: true })

    const onFilesUpdated = async (payload: { formData: FormData, files: Array<IFile> }) => {
        if (!isNullOrEmpty(payload.files)) {
            const event: IOwFileUploaderEvent = {
                formData: payload.formData,
                filesCount: payload.files.length,
            }
            emit('uploading', event)
            await store.dispatch(DOCUMENTS_UPLOAD_FILES, event)
            selectedTable.value = tableTabs.uploaded
        }
    }
</script>

<style lang="scss" scoped>
    @import './select-a-document';
</style>
