import { MapBrowserEvent } from 'ol'
import { FeatureLike } from 'ol/Feature'
import {
    Fill,
} from 'ol/style'
import Style, {
    StyleFunction,
    StyleLike,
} from 'ol/style/Style'

import {
    IMapRolloverOptions,
} from '@/components/map-rollover/common/map-rollover-interfaces'
import { LayerNames } from '@/consts/map-layers'
import i18n from '@/plugins/i18n'
import { AgriculturalGradeFeatureType } from '@/store/modules/map/layers/environmental-layers-common'

import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from './base-mvt-data-layer'

export type AgriculturalGradeLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: ({ text, hoverCaption } : { text: Array<string>, hoverCaption: string },
        event: MapBrowserEvent<any>) => void
}

export class AgriculturalGradeLayer extends BaseMvtDataLayer {
    public maxResolution = 256

    constructor(params: AgriculturalGradeLayerParams, settings: any) {
        super(params, settings)
        this.targetMap = params.targetMap ?? null
        this.layerName = 'ow:agricultural_grade'
        this.i18nNameRef = 'map.options.legend.environmental[5]'
        this.attributionText = [
            `© ${ i18n.global.t('map.options.environmental.source.england') } & ${ i18n.global.t('map.options.environmental.source.wales') }. Published 2019`,
        ]
        this.name = LayerNames.AgriculturalGradeLayer
        this.rolloverOptions = this.getRolloverOptions(this.style, this.targetMap)
        this.initialiseLayer()
    }

    public style:StyleLike = (feature: FeatureLike) => {
        const properties = feature.getProperties()
        let zIndex: number
        let colour = 'rgba(62, 221, 255, 0.8)'

        // Determine colour and zIndex
        switch (properties.grade) {
            case '1':
                colour = 'rgb(62, 221, 255, 0.8)'
                zIndex = 316
                break
            case '2':
                colour = 'rgb(196, 252, 253, 0.8)'
                zIndex = 315
                break
            case '3':
            case '3a':
            case '3b':
                colour = 'rgb(181, 251, 163, 0.8)'
                zIndex = 314
                break
            case '4':
                colour = 'rgb(249, 249, 161, 0.8)'
                zIndex = 313
                break
            case '5':
                colour = 'rgb(178, 135, 102, 0.8)'
                zIndex = 312
                break
            case 'Non Agricultural':
                colour = 'rgb(254, 99, 68, 0.8)'
                zIndex = 311
                break
            case 'Urban':
                colour = 'rgb(254, 99, 68, 0.8)'
                zIndex = 310
                break
            default:
                zIndex = 317
        }
        return new Style({
            zIndex,
            fill: new Fill({
                color: colour,
            }),
        })
    }

    private getRolloverOptions(style: StyleLike, targetMap: any): IMapRolloverOptions {
        return {
            showPrimary: true,
            showExtended: true,
            sortOrder: 0,
            category: i18n.global.t(`map.rollover.environmental.category`),
            getSource: (features: FeatureLike[] | Record<string, FeatureLike[]>): string => {
                const properties = features[0].getProperties() as AgriculturalGradeFeatureType
                return properties.england ? i18n.global.t('map.rollover.environmental.source.england') : i18n.global.t('map.rollover.environmental.source.wales')
            },
            getStyle: (feature) => (style as StyleFunction)(feature, null) as Style,
            getPrimary: () => i18n.global.t(`map.rollover.environmental.agricultural-grade-layer.primary`),
            getExtended(features) {
                const properties = features[0].getProperties() as AgriculturalGradeFeatureType
                let name = i18n.global.t('map.options.environmental.hover.agriculturalGrade.grade')
                let value = properties.grade
                // NOTE: Due to performance if there is a zoom level force the user to zoom in
                const zoomLevel = targetMap?.getView().getZoom() ?? 12
                if (zoomLevel <= 10.5) {
                    name = i18n.global.t('label.info')
                    value = i18n.global.t(`map.rollover.environmental.agricultural-grade-layer.zoomIn`)
                }
                return [
                    {
                        name,
                        value,
                    },
                ]
            },
        }
    }
}
