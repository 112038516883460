<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 642 929"
                  desc="North arrow icon">
        <path style="fill:#000"
              d="M373.75 91.496c-.95-1.132-74.87 153.23-164.19 343.02-160.8 341.68-162.27 345.16-156.49 350.27 3.203 2.83 6.954 4.79 8.319 4.34 1.365-.46 71.171-73.88 155.14-163.1 83.97-89.22 153.66-162.83 154.87-163.56 1.2-.72 71.42 72.34 156.04 162.29s155.21 163.82 156.95 164.19 5.57-1.19 8.5-3.44c5.04-3.86-3.75-23.46-156.04-348-88.77-189.18-162.15-344.88-163.1-346.01zm-2.72 42.694c1.4-1.53 2.45 63.91 2.45 148.36v151.07l-142.3 151.34C106.57 717.42 87.38 737.82 86.08 738.47c.143-.35 1.009-1.57 1.361-2.26.81-1.59 64.409-137.07 141.3-301.05 76.89-163.99 140.93-299.45 142.29-300.97zm-99.77 642V1021h32.11V816.18L411.92 1021h44.6V776.19h-32.11v204.8l-108.56-204.8h-44.59z"
              transform="translate(-51.471 -91.49)" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconNorthArrow',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '50px',
            },
            height: {
                type: String,
                required: false,
                default: '72px',
            },
        },
    }
</script>
