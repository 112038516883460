// Getters
export const WALKTHROUGH_GET_FIRST_PAGE = 'WALKTHROUGH_GET_FIRST_PAGE'
export const WALKTHROUGH_GET_LAST_PAGE = 'WALKTHROUGH_GET_LAST_PAGE'
export const WALKTHROUGH_GET_HAS_CHANGES = 'WALKTHROUGH_GET_HAS_CHANGES'
export const WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE = 'WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE'

// Mutations
export const WALKTHROUGH_MUTATE_ENABLED = 'WALKTHROUGH_MUTATE_ENABLED'
export const WALKTHROUGH_MUTATE_LOADING = 'WALKTHROUGH_MUTATE_LOADING'
export const WALKTHROUGH_MUTATE_WALKTHROUGH = 'WALKTHROUGH_MUTATE_WALKTHROUGH'
export const WALKTHROUGH_MUTATE_SELECTED_PAGE = 'WALKTHROUGH_MUTATE_SELECTED_PAGE'
export const WALKTHROUGH_MUTATE_PAGES = 'WALKTHROUGH_MUTATE_PAGES'
export const WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT = 'WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT'
export const WALKTHROUGH_MUTATE_DELETE_PAGE = 'WALKTHROUGH_MUTATE_DELETE_PAGE'
export const WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE = 'WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE'
export const WALKTHROUGH_MUTATE_REMOVE_ITEM_FROM_SELECTED_PAGE = 'WALKTHROUGH_MUTATE_REMOVE_ITEM_FROM_SELECTED_PAGE'
export const WALKTHROUGH_MUTATE_HIGHLIGHT_BOUNDARIES_FOR_TITLE_NUMBER = 'WALKTHROUGH_MUTATE_HIGHLIGHT_BOUNDARIES_FOR_TITLE_NUMBER'
export const WALKTHROUGH_MUTATE_FEATURE_TRACKING_REVISION_COUNTER = 'WALKTHROUGH_MUTATE_FEATURE_TRACKING_REVISION_COUNTER'
export const WALKTHROUGH_MUTATE_PAGE_CONTENT_ITEM_VISIBILITY = 'WALKTHROUGH_MUTATE_PAGE_CONTENT_ITEM_VISIBILITY'
export const WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT = 'WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT'
export const WALKTHROUGH_MUTATE_CONTENT_ITEM_BOUNDARY_AVAILABLE = 'WALKTHROUGH_MUTATE_CONTENT_ITEM_BOUNDARY_AVAILABLE'
export const WALKTHROUGH_SET_PAGES = 'WALKTHROUGH_SET_PAGES'
export const WALKTHROUGH_MUTATE_SELECTED_PAGE_TITLE_COMMENT = 'WALKTHROUGH_MUTATE_SELECTED_PAGE_TITLE_COMMENT'
export const WALKTHROUGH_MUTATE_SELECTED_PAGE_DESCRIPTION = 'WALKTHROUGH_MUTATE_SELECTED_PAGE_DESCRIPTION'

// Actions
export const WALKTHROUGH_LOAD_BY_ID = 'WALKTHROUGH_LOAD_BY_ID'
export const WALKTHROUGH_RESET = 'WALKTHROUGH_RESET'
export const WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER = 'WALKTHROUGH_UPDATE_SELECTED_PAGE_LAYER'
export const WALKTHROUGH_ADD_NEW_PAGE = 'WALKTHROUGH_ADD_NEW_PAGE'
export const WALKTHROUGH_SAVE = 'WALKTHROUGH_SAVE'
export const WALKTHROUGH_GET_BY_MATTER_ID = 'WALKTHROUGH_GET_BY_MATTER_ID'
