export enum TENURES {
    absoluteFreehold = 'Absolute Freehold',
    absoluteLeasehold = 'Absolute Leasehold',
    absoluteRentcharge = 'Absolute Rent Charge',
    possessoryFreehold = 'Possessory Freehold',
    goodLeasehold = 'Leasehold title (Good)',
    qualifiedFreehold = 'Qualified Freehold',
    schemeTitleFreehold = 'Scheme Title Freehold',
    schemeTitleLeasehold = 'Scheme Title Leasehold',
    qualifiedLeasehold = 'Qualified Leasehold',
    possessoryLeasehold = 'Possessory Leasehold',
    possessoryRentcharge = 'Possessory Rent Charge',
    qualifiedRentcharge = 'Qualified Rent Charge',
    cautionAgainstFirstRegistration = 'Caution Against First Registration',
}

export enum SCHEDULE_CODES {
    singleRegisteredLease = 'Single Registered Lease',
}

export enum RESTRICTION_TYPES {
    ChargeRestriction = 'ChargeRestriction',
    NonChargeRestriction = 'NonChargeRestriction',
}

export enum TENURE_TYPE_FROM_TENURE {
    absoluteFreehold = 'Freehold',
    absoluteLeasehold = 'Leasehold',
    absoluteRentcharge = 'Rent Charge',
    possessoryFreehold = 'Freehold',
    goodLeasehold = 'Leasehold',
    qualifiedFreehold = 'Freehold',
    schemeTitleFreehold = 'Freehold',
    schemeTitleLeasehold = 'Leasehold',
    qualifiedLeasehold = 'Leasehold',
    possessoryLeasehold = 'Leasehold',
    possessoryRentcharge = 'Rent Charge',
    qualifiedRentcharge = 'Rent Charge',
    cautionAgainstFirstRegistration = 'Caution Against First Registration',
}

export enum QUALITY_FROM_TENURE {
    absoluteFreehold = 'Absolute',
    absoluteLeasehold = 'Absolute',
    absoluteRentcharge = 'Absolute',
    possessoryFreehold = 'Possessory',
    goodLeasehold = 'Good',
    qualifiedFreehold = 'Qualified',
    schemeTitleFreehold = 'Scheme Title',
    schemeTitleLeasehold = 'Scheme Title',
    qualifiedLeasehold = 'Qualified',
    possessoryLeasehold = 'Possessory',
    possessoryRentcharge = 'Possessory',
    qualifiedRentcharge = 'Qualified',
}

/**
 * Source - {@link https://use-land-property-data.service.gov.uk/datasets/nps/tech-spec/3}
 */
export enum TENURE_FULL_FROM_CODE {
    QF = 'Freehold title (Qualified)',
    AF = 'Freehold title (Absolute)',
    PF = 'Freehold title (Possessory)',
    AL = 'Leasehold title (Absolute)',
    GL = 'Leasehold title (Good)',
    QL = 'Leasehold title (Qualified)',
    PL = 'Leasehold title (Possessory)',
    SF = 'Scheme Title - Freehold',
    CF = 'Commonhold Freehold',
    SL = 'Scheme Title - Leasehold',
    CL = 'Commonhold Leasehold',
    AR = 'Absolute Rentcharge',
    PR = 'Possessory Rentcharge',
    QR = 'Qualified Rentcharge',
    CN = 'Caution Against First Registration'
}

/**
 * Takes the tenure enumeration string from either the register or OCOD/CCOD data
 * returned from the server and converts it into a display string to use within the app.
 * @param tenureEnum
 * @param ocodCcodTenureEnum
 * @returns {String}
 * @see TENURES
 */
export const getTenureDisplayText = (tenureEnum, ocodCcodTenureEnum) =>
    tenureEnum ? TENURES[tenureEnum] : TENURES[ocodCcodTenureEnum]

/**
 * Takes the schedule code enumeration string returned from the server and
 * converts it into a display string to use within the app.
 * @param scheduleCodeEnum
 * @returns {String}
 */
export const getScheduleCodeDisplayText = (scheduleCodeEnum) => SCHEDULE_CODES[scheduleCodeEnum]

/**
 * Minimum and maximum score that can be associated with title complexity.
 */
export const COMPLEXITY = {
    MIN_SCORE: 1,
    MAX_SCORE: 6,
}
