import i18n from '@/plugins/i18n'

/**
 * Used to provide a hint that the user will need to download the digital register when a value
 * cannot otherwise be displayed in a column. See usage in gridConfig.js.
 */
export const benefitsFromDigitalRegisterHintColumnRenderer = ({ record, value, isExport, includeInTitleOrganiser, recommendParentDigitalRegister = false }) => {
    if (!isExport) {
        // If there is a value available, despite the digital register otherwise being required, use it.
        if (value || value === 0) {
            return value
        } else if (!record.editionDate) {
            // Otherwise return a hint that the digital register is required (indicated by the presence of the edition date).
            return includeInTitleOrganiser
                ? i18n.global.t('matter.titleOrganiser.unknownRegisterNotOwnedGroupName')
                : informationIconRenderer({ recommendParentDigitalRegister })
        }
        // Or if the digital register is available, but there is still no value, return N/A.
        return includeInTitleOrganiser ? i18n.global.t('matter.titleOrganiser.unknownRegisterNotOwnedGroupName') : 'N/A'
    } else {
        // We don't want to show 'N/A' in the Excel export, leads to invalid files.
        return (value || value === 0) ? value : null
    }
}

export const informationIconRenderer = ({ recommendParentDigitalRegister = false }) => {
    const iconHintText = recommendParentDigitalRegister ? 'Parent Title register' : 'register'

    return `
        <div title="Order the ${ iconHintText } to populate the cell.">
            <i aria-hidden="true" class="material-icons order-register-info">info_outline</i>
        </div>
    `
}
