<template>
    <div class="ow-grid"
         :class="{
             '--collapsed': collapsed,
             '--loading': loading,
         }">
        <ow-grid-header v-if="header"
                        v-bind="header"
                        :collapsed="collapsed"
                        :class="{
                            '--collapsed': collapsed,
                        }"
                        @click="onCollapseToggle">
            <slot name="header" />
        </ow-grid-header>
        <div v-show="!collapsed"
             class="ow-grid ow-grid__content"
             :class="{
                 '--hide-header': gridConfig?.hideHeaders,
                 'material': theme === 'material',
                 'classic-light': theme === 'classic-light',
             }">
            <v-progress-circular v-if="loading"
                                 class="ow-grid__spinner"
                                 indeterminate />
            <bryntum-grid class="ow-grid__grid"
                          v-bind="gridConfig" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        GridConfig,
        Store,
    } from '@bryntum/grid'
    import { BryntumGrid } from '@bryntum/grid-vue-3'

    import {
        GridHeader,
        GridTheme,
    } from '.'
    import OwGridHeader from './ow-grid-header.vue'

    const emit = defineEmits<{
        (e: 'collapse', collapsed: boolean): void,
    }>()

    const props = defineProps<{
        // eslint-disable-next-line vue/no-unused-properties
        gridConfig: GridConfig
        theme: GridTheme,
        header?: GridHeader
        collapsed?: boolean
        loading?: boolean
    }>()

    const onCollapseToggle = () => {
        emit('collapse', !props.collapsed)
    }
</script>

<style lang="scss">
    @import './ow-grid.scss';
</style>
