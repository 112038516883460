import qs from 'qs'

import httpClient from './http-client'

export default class UserApi {
    static END_POINT = 'user'

    /**
     * Get the users for the specified organisation
     * @param organisationId
     * @returns {Promise<T | *>}
     */
    static getUsersByOrganisationId(organisationId) {
        const uri = 'users'
        const params = new URLSearchParams()
        if (organisationId) {
            params.set('organisationId', organisationId)
        }

        return httpClient.get(`${ uri }?${ params.toString() }`)
    }

    /**
     * Create a new user
     * @static
     * @param user {Object}
     * @returns {Promise<T | *>}
     */
    static create(user) {
        if (!user) {
            throw new SyntaxError('Must provide an object with the user data to create')
        }

        const uri = `${ this.END_POINT }/create`
        return httpClient
            .post(uri, qs.stringify({ model: user }))
            .then(response => {
                if (response.status === 200 && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Delete all users contained in the userIds array
     * @static
     * @param userIds {Array} - An array of the userId's to delete
     * @returns {Promise<T | *>}
     */
    static deleteUsers(userIds) {
        const uri = `${ this.END_POINT }/remove`
        const data = qs.stringify({ model: userIds })

        return httpClient
            .post(uri, data)
            .then(response => {
                if (response.status === 200 && response.data === true) {
                    return {
                        ok: true,
                        data: response.data,
                    }
                }

                return {
                    ok: false,
                    status: response.status,
                    message: response?.data?.join(', '),
                    data: null,
                }
            })
    }

    /**
     * Update the user details
     * @static
     * @param user {Object}
     * @returns {Promise<T | *>}
     */
    static update(user) {
        const uri = `${ this.END_POINT }/update`
        const data = qs.stringify({ model: user })
        return httpClient
            .post(uri, data)
            .then(response => {
                if (response.status === 200 && response.data === true) {
                    return {
                        ok: true,
                        data: response.data,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Update the current user's preferences
     * @static
     * @param preferences {Object}
     * @returns {Promise<T | *>}
     */
    static updatePreferences(preferences) {
        const uri = `${ this.END_POINT }/preferences/update`
        const data = qs.stringify(preferences)
        return httpClient
            .post(uri, data)
            .then(response => {
                if (response.data === true) {
                    return {
                        success: true,
                        data: response.data,
                    }
                }

                return {
                    success: false,
                    error: response.data.join('. '),
                }
            })
    }

    /**
     * Used to record usage of a feature.
     * @static
     * @param type {String}
     * @param description {String}
     * @returns {Promise<T | *>}
     */
    static logFeatureUsage(type, description, matterId) {
        const uri = `${ this.END_POINT }/enable`
        const data = qs.stringify({
            type,
            description,
            matterId,
        })

        return httpClient
            .post(uri, data)
            .then(response => {
                if (response.data === true) {
                    return {
                        success: true,
                        data: response.data,
                    }
                }

                return {
                    success: false,
                    error: response.data.join('. '),
                }
            })
    }

    /**
     * Get the profile for the current user.
     * @static
     * @returns {Promise<T | *>}
     */
    static getUserProfile() {
        const uri = `${ this.END_POINT }/profile`

        return httpClient
            .get(uri)
            .then(response => {
                if (response?.status === 200) {
                    if (response.data && response.data.error === null) {
                        return {
                            ok: true,
                            message: '',
                            data: response.data,
                        }
                    } else if (response.data.error !== null) {
                        return {
                            ok: false,
                            message: `Something is wrong with the user profile ${ response.data.error }`,
                            data: response.data,
                        }
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong getting the user profile: ${ response.status }`,
                }
            })
    }

    /**
     * Used to update the user's last active timestamp
     * @static
     * @returns {Promise<T | *>}
     */
    static userStatusUpdate() {
        const uri = `${ this.END_POINT }/status`

        return httpClient
            .get(uri)
            .then(response => {
                if (response?.status === 200) {
                    return {
                        ok: true,
                        message: '',
                        data: response.data,
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong getting the initiating the user status update: ${ response.status }`,
                }
            })
    }

    /**
     * Log the OS map usage for the user
     * @static
     * @param request {Object}
     * @returns {Promise<T | *>}
     */
    static logOsMapUsage(request) {
        const uri = `${ this.END_POINT }/log/osmaps`

        return httpClient
            .post(uri, request)
            .then(response => {
                if (response.status === 200) {
                    return {
                        ok: true,
                        message: '',
                        data: response.data,
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong logging the OS map usage: ${ response.status }`,
                }
            })
    }

    /**
     * Get the logs for the current user
     * @static
     * @param request {Object}
     * @returns data object returned from the server
     */
    static getUserLogs(request) {
        const uri = `${ this.END_POINT }/logs`

        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => {
                if (response.status === 200) {
                    return {
                        ok: true,
                        message: '',
                        data: response.data,
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong getting the logs ${ response.status }`,
                }
            })
    }

    /**
     * Get the logs for the current user
     * @static
     * @param request {Object}
     * @returns data object returned from the server
     */
    static exportLogsAsCsv(request) {
        const uri = `${ this.END_POINT }/logs/export`

        return httpClient
            .post(uri, qs.stringify(request), {
                responseType: 'blob',
            })
            .then(response => {
                if (response.status === 200) {
                    return {
                        ok: true,
                        message: '',
                        data: response.data,
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong getting the logs ${ response.status }`,
                }
            })
    }

    static createProductboardToken() {
        const uri = `${ this.END_POINT }/CreateProductBoardToken`

        return httpClient
            .post(uri)
            .then(response => {
                if (response.status === 200) {
                    return {
                        ok: true,
                        token: response.data.token,
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong with fetching the productboard token ${ response.status }`,
                }
            })
    }

    /**
     * Get the list of matters that the current user can access.
     * @static
     * @returns {Promise<T|*>}
     */
    static getRecentlyAccessedMatters() {
        const uri = `${ this.END_POINT }/recentlyaccessedmatters`

        return httpClient
            .get(uri)
            .then(response => {
                return {
                    ok: true,
                    data: response.data,
                }
            })
            .catch(function(error) {
                return {
                    ok: false,
                    message: 'Something went wrong getting the list of recently accessed matters',
                    error,
                }
            })
    }
}
