import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { ISketch } from '@/store/modules/sketches/types/sketch'

export default class SketchesApi {
    static controller = null
    static updateController: Record<number, AbortController> = {}

    static END_POINT = 'sketches'

    /**
     * Get sketches by matter id
     * @param matterId
     */
    static async getSketchesByMatterId(matterId: number): Promise<IHttpClientResponse> {
        if (!matterId) {
            return
        }
        const uri = `${ this.END_POINT }/${ matterId }/list`

        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()

        return httpClient.get(uri, {
            signal: this.controller.signal,
        })
    }

    /**
     * Update a sketch by matter id and sketch id
     * @param matterId
     * @param sketch
     */
    static async updateSketch(matterId:number, sketch: ISketch): Promise<IHttpClientResponse> {
        const uri = `${ this.END_POINT }/${ matterId }/${ sketch.id }`

        if (this.updateController[sketch.id]) {
            this.updateController[sketch.id].abort()
        }

        this.updateController[sketch.id] = new AbortController()

        const resp = httpClient.put(uri, sketch, {
            signal: this.updateController[sketch.id].signal,
        })
        return resp
    }

    /**
     * Create a sketch
     * @param matterId
     * @param sketch
     */
    static async createSketch(matterId: number, sketch: ISketch): Promise<IHttpClientResponse> {
        const uri = `${ this.END_POINT }/${ matterId }`
        return httpClient.post(uri, sketch)
    }

    /**
     * Delete a sketch
     * @param matterId
     * @param sketchId
     */
    static async deleteSketch(matterId:number, sketchId: number): Promise<IHttpClientResponse> {
        const uri = `${ this.END_POINT }/${ matterId }/${ sketchId }`
        return httpClient.delete(uri)
    }
}
