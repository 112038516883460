<template>
    <div>
        <v-tooltip :disabled="!showTooltip || isCopilotButtonEnabled"
                   location="bottom"
                   activator="parent"
                   show="isRegisterPurchased">
            <template #activator="{ props: activatorProps }">
                <div v-if="$slots.default"
                     :data-test="dataTest ?? 'title-panel-open-copilot'"
                     :data-track="dataTrack ?? 'TITLE-DETAILS-PANEL - Open Copilot'"
                     v-bind="activatorProps"
                     @click="onCopilotClick">
                    <slot v-bind="{ disabled: !isCopilotButtonEnabled }" />
                </div>
                <ow-button v-else
                           :data-test="dataTest ?? 'title-panel-open-copilot'"
                           :data-track="dataTrack ?? 'TITLE-DETAILS-PANEL - Open Copilot'"
                           :disabled="!isCopilotButtonEnabled"
                           :full-height="fullHeight"
                           :full-width="fullWidth"
                           :is-borderless="isBorderless"
                           :is-primary="isPrimary"
                           :is-secondary="isSecondary"
                           v-bind="activatorProps"
                           @click="onCopilotClick">
                    <span>{{ title ?? $t('buttons.copilot') }}</span>
                </ow-button>
            </template>

            <span v-if="disabledTooltipText">{{ disabledTooltipText }}</span>
            <span v-else-if="!hasSelectedValidDocuments">{{ $t('titlePanel.owCopilotBlocked.noDocuments') }}</span>
            <span v-else-if="selectedDocuments !== null && selectedDocuments?.length === 0">{{
                $t('titlePanel.owCopilotBlocked.noDocuments')
            }}</span>
            <span v-else>{{ $t('titlePanel.owCopilotBlocked.noDocumentsToSend') }}</span>
        </v-tooltip>

        <teleport to="body">
            <ow-modal v-model="owCopilotBlocked"
                      :title="$t('titlePanel.owCopilotBlocked.title')"
                      content-class="ow-content-blocked"
                      data-test-attribute="ow-copilot-blocked">
                <span>
                    {{ $t('titlePanel.owCopilotBlocked.content') }}
                </span>
                <template #actions>
                    <div>
                        <ow-button data-test="ow-copilot-blocked-button-submit"
                                   is-primary
                                   @click="onClose">
                            {{ $t('titlePanel.owCopilotBlocked.submitButtonText') }}
                        </ow-button>
                    </div>
                </template>
            </ow-modal>
        </teleport>

        <teleport to="body">
            <ow-modal v-if="showDocumentModal"
                      v-model="showDocumentModal"
                      class="open-copilot-button__modal"
                      no-padding>
                <document-selection :document-ids="copilotSupportedDocumentIds ?? []"
                                    :show-empty-titles="showEmptyTitles"
                                    :selected-title-numbers="selectedTitleNumbers ? selectedTitleNumbers : selectedDocuments || showAllDocuments ? null : [selectedTitleNumber]"
                                    @close="showDocumentModal = false" />
            </ow-modal>
        </teleport>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useStore } from 'vuex'

    import {
        COPILOT_DOC_LIMIT,
        COPILOT_SUPPORTED_DOC_TYPES,
    } from '@/components/copilot/constants'
    import { useCopilot } from '@/components/copilot/use-copilot'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import {
        TITLE_GET_IS_PLAN_PURCHASED,
        TITLE_GET_IS_REGISTER_PURCHASED,
        TITLE_GET_OWNS_COPILOT_OC2_DOCS,
        TITLE_GET_SELECTED_TITLE_NUMBER,
    } from '@/store/modules/titles/types'
    import { OPEN_WINDOW } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    import DocumentSelection from './document-selection/document-selection.vue'

    const props = withDefaults(defineProps<{
        selectedDocuments?: Array<any> | null,
        title?: string,
        dataTrack?: string,
        dataTest?: string,
        isSecondary?: boolean,
        isPrimary?: boolean,
        fullWidth?: boolean,
        fullHeight?: boolean,
        isBorderless?: boolean,
        showTooltip?: boolean,
        showAllDocuments?: boolean,
        showEmptyTitles?: boolean,
        selectedTitleNumbers?: string[],
        sendToCopilot?: boolean,
        isDisabled?: boolean,
        disabledTooltipText?: string,
        trackPosition?: string,
    }>(), {
        title: null,
        isPrimary: true,
        showTooltip: true,
        selectedDocuments: null,
        sendToCopilot: false,
        isDisabled: false,
    })

    const store = useStore()
    const clientCode = computed((): string => store.state.matter.currentMatter.clientCode)
    const matterCode = computed((): string => store.state.matter.currentMatter.code)
    const currentMatterId = computed((): number => parseInt(store.state.matter.currentMatter.id))
    const selectedTitleNumber = computed((): string => store.getters[TITLE_GET_SELECTED_TITLE_NUMBER])
    const owCopilotBlocked = ref<boolean>(false)
    const showDocumentModal = ref<boolean>(false)
    const {
        copilotUrl,
        trackClick,
        setDocumentIds,
    } = useCopilot({
        clientCode: clientCode.value,
        matterCode: matterCode.value,
        report: '',
        matterId: currentMatterId.value,
        titleNumbers: selectedTitleNumber.value ? [selectedTitleNumber.value] : [],
        position: props?.trackPosition ?? 'Title Details Panel Header Button',
    })

    const copilotSupportedDocumentIds = computed((): Array<number> => {
        const tmp = props?.selectedDocuments?.filter((doc) => {
            const hasRequiredProperties = (
                (doc.downloadUrl || doc.documentDownloadUrl)
                || doc.documentSource === DOCUMENT_SOURCE.UPLOADED)
            const isSupportedDocumentType = COPILOT_SUPPORTED_DOC_TYPES.includes(doc.documentType)
            return hasRequiredProperties && isSupportedDocumentType
        })
            .map((doc) => doc.documentId)
        return tmp ?? []
    })

    const isRegisterOwned = computed((): boolean => store.getters[TITLE_GET_IS_REGISTER_PURCHASED])
    const isTitlePlanOwned = computed(() => store.getters[TITLE_GET_IS_PLAN_PURCHASED])
    const isOc2DocOwned = computed(() => store.getters[TITLE_GET_OWNS_COPILOT_OC2_DOCS])
    const hasPurchasedDocumentsForTitle = computed((): boolean => {
        return copilotSupportedDocumentIds?.value?.length > 0 ||
            isRegisterOwned.value ||
            isTitlePlanOwned.value ||
            isOc2DocOwned.value
    })

    const hasSelectedValidDocuments = computed((): boolean => {
        if (props.selectedDocuments === null) {
            return true
        }
        if (!props.selectedDocuments?.length) {
            return false
        }
        return props.selectedDocuments.filter((doc) => (doc?.downloadUrl || doc?.documentDownloadUrl) && COPILOT_SUPPORTED_DOC_TYPES.includes(doc.documentType)).length > 0
    })

    const onClose = (): void => {
        owCopilotBlocked.value = false
    }

    const isCopilotButtonEnabled = computed((): boolean => {
        if (props.isDisabled) {
            return false
        }
        if (props.showAllDocuments || props.sendToCopilot) {
            return true
        }
        if (!isNullOrEmpty(props.selectedDocuments)) {
            return hasSelectedValidDocuments.value
        }
        return hasPurchasedDocumentsForTitle.value
    })

    const onCopilotClick = async (): Promise<void> => {
        await trackClick()

        // // if x or less supported documents (+ register), open copilot. else show modal.
        if (!props.sendToCopilot) {
            showDocumentModal.value = true
        } else if (copilotSupportedDocumentIds?.value?.length < COPILOT_DOC_LIMIT) {
            setDocumentIds(copilotSupportedDocumentIds.value)
            await store.dispatch(OPEN_WINDOW, copilotUrl.value)
        } else {
            owCopilotBlocked.value = true
        }
    }
</script>
