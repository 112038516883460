import {
    NPS_MUTATE_CLEAR_FEATURES,
    NPS_MUTATE_LOADED_FEATURES_FOR_TITLE_NUMBERS,
    NPS_MUTATE_LOADING_FEATURES_FOR_TITLE_NUMBERS,
    NPS_MUTATE_STORE_FEATURES,
} from './types.js'

export default {

    [NPS_MUTATE_LOADED_FEATURES_FOR_TITLE_NUMBERS](state, titleNumbers) {
        state.loadingFeaturesForTitleNumbers = state.loadingFeaturesForTitleNumbers.filter(titleNumber => !titleNumbers.includes(titleNumber))
    },

    [NPS_MUTATE_LOADING_FEATURES_FOR_TITLE_NUMBERS](state, titleNumbers) {
        state.loadingFeaturesForTitleNumbers.push(titleNumbers)
        state.loadingFeaturesForTitleNumbers = [...new Set(state.loadingFeaturesForTitleNumbers)]
    },

    [NPS_MUTATE_STORE_FEATURES](state, features) {
        // Remove any existing features with the same title numbers.
        const newFeatureTitleNumbers = features.map(feature => feature.get('title_no'))
        state.features = state.features.filter(feature => !newFeatureTitleNumbers.includes(feature.get('title_no')))

        // Add the new features.
        state.features.push(...features)
    },

    [NPS_MUTATE_CLEAR_FEATURES](state) {
        state.features = []
    },
}
