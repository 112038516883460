import {
    LINK_SHARE_CLIENT_MUTATE_IS_MATTER_LINK_SHARE_USER,
    LINK_SHARE_CLIENT_MUTATE_IS_SHARED_LINK_VIEW,
} from './types.js'

export default {
    [LINK_SHARE_CLIENT_MUTATE_IS_MATTER_LINK_SHARE_USER](state, val) {
        state.isMatterLinkShareUser = val
    },

    [LINK_SHARE_CLIENT_MUTATE_IS_SHARED_LINK_VIEW](state, val) {
        state.isSharedLinkView = val
    },
}
