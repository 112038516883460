import httpClient from './http-client'

export default class PricingApi {
    static END_POINT = 'pricing'

    /**
     * Get the pricing models
     * @static
     * @returns {Promise<T | *>}
     */
    static loadPricingModels() {
        const endpoint = `${ this.END_POINT }/models`
        return httpClient.get(endpoint)
    }
}
