import httpClient from '@/api/http-client'

export default class UprnApi {
    static END_POINT = 'uprn'

    /**
     * Search for an os uprn record by its uprn
     * @param uprn {String}
     * @returns Promise
     */
    static getOsRecordForUprn(uprn) {
        return httpClient.get(`${ this.END_POINT }/${ uprn }`)
    }
}
