import qs from 'qs'

import httpClient from '@/api/http-client'
import { StatusCode } from '@/consts/status-code'

export default class WalkthroughsApi {
    static END_POINT = 'walkthrough'

    /**
    * Get a nullable walkthrough Id (int) for the given matter.
    * @param matterId {Number} The ID of the matter.
     * @returns {Promise<T|*>}
    */
    static getWalkthroughIdByMatterId(matterId) {
        const uri = `${ this.END_POINT }/id/${ matterId }`
        try {
            return httpClient
                .get(uri)
                .then(response => {
                    if (response.status === StatusCode.SUCCESS) {
                        return response
                    }
                })
        } catch (error) {
            if (error?.response.status === StatusCode.FORBIDDEN ||
                error?.response.status === StatusCode.UNAUTHORIZED) {
                return Promise.resolve({
                    status: StatusCode.FORBIDDEN,
                    data: null,
                })
            }
        }
    }

    /**
    * Get metadata associated with the walkthroughs for the given walkthrough Id.
    * for the given matter.
    * @param walkthroughId {Number} The ID of the walkthrough.
     * @returns {Promise<T|*>}
    */
    static getWalkthroughById(walkthroughId) {
        const uri = `${ this.END_POINT }/${ walkthroughId }`
        return httpClient.get(uri)
    }

    /**
    * Get metadata associated with the walkthroughs for the given matter Id.
    * for the given matter.
    * @param matterId {Number} The ID of the matter.
     * @returns {Promise<T|*>}
    */
    static getWalkthroughsByMatterId(matterId) {
        const uri = `${ this.END_POINT }/list/${ matterId }`
        return httpClient.get(uri)
    }

    /**
     * Saves a walkthrough. See CreateWalkthroughRequest or usage in WALKTHROUGH_SAVE action.
     * @param {Object} model CreateWalkthroughRequest object.
     * @returns {Promise<T|*>}
     */
    static saveWalkthrough(model) {
        const uri = `${ this.END_POINT }/create`
        return httpClient.post(uri, qs.stringify(model))
    }

    /**
    * Updates a walkthrough. See UpdateWalkthroughRequest or usage in WALKTHROUGH_SAVE action.
     * @returns {Promise<T|*>}
    */
    static updateWalkthrough(model) {
        const uri = `${ this.END_POINT }/update`
        return httpClient.post(uri, qs.stringify(model))
    }
}
