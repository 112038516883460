import GeoJSON from 'ol/format/GeoJSON'
import WKT from 'ol/format/WKT'
import {
    Draw,
    Modify,
} from 'ol/interaction'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
} from 'ol/style'

import { SketchType } from '@/enums/sketches-enums'
import { sketchInProgressStyleFn } from '@/utils/sketch-utils'

import * as types from '../mutation-types'

export default {
    state: {
        initialized: false,
        drawing: false,
        firstDraw: true,
        mode: 'line',
        source: null,
        layer: null,
        geoJsonWriterTimer: null,
        geoJsonFormat: new GeoJSON(),
        interactions: {
            modify: null,
            draw: null,
            snap: null,
        },
        freehand: false,
        eraseMode: false,
        colour: { hex: '#BC3FEA' },
        palette: ['#8FCE00', '#1CAE43', '#28E1F0', '#3728F0', '#17208B', '#BC3FEA', '#D30985', '#841C1C', '#4A1700'],
    },

    mutations: {
        _setMode(state, val) {
            state.mode = val
        },

        _setDrawing(state, val) {
            state.drawing = val
        },
    },

    getters: {},

    actions: {
        _initialize(context) {
            if (context.state.layer == null) {
                // needs initializing

                const map = context.rootState.map.map
                if (map != null) {
                    // Annotation layer and source
                    context.state.source = new VectorSource()
                    context.state.layer = new VectorLayer({
                        zIndex: 16,
                        source: context.state.source,
                        style: (feature) => {
                            return new Style({
                                stroke: new Stroke({
                                    color: feature.get('strokeColour'),
                                    width: 3,
                                }),
                                image: new CircleStyle({
                                    radius: 10,
                                    fill: new Fill({
                                        color: feature.get('strokeColour'),
                                    }),
                                    stroke: new Stroke({
                                        color: feature.get('strokeColour'),
                                        width: 3,
                                    }),
                                }),
                            })
                        },
                    })
                    map.addLayer(context.state.layer)
                    context.state.initialized = true
                }
            }
        },

        [types.MATTER_DRAW_GET_WKT](context) {
            return new Promise((resolve) => {
                const list = []
                const format = new WKT()
                const features = context.state.source.getFeatures()
                features.forEach((f) => {
                    const wkt = format.writeGeometry(f.getGeometry())
                    list.push(wkt)
                })
                resolve(list)
            })
        },

        [types.MATTER_DRAW_ANNOTATION_ENABLE](context, enable) {
            if (enable === true) {
                if (context.state.firstDraw === true) {
                    context.state.firstDraw = false
                    context.dispatch('_initialize') // init mode
                    // context.dispatch(types.ANNOTATION_SET_MODE, "line");
                }
            }

            if (context.state.interactions.modify != null) {
                context.state.interactions.modify.setActive(enable)
            }
            if (context.state.interactions.snap != null) {
                context.state.interactions.snap.setActive(enable)
            }
            if (context.state.interactions.draw != null) {
                context.state.interactions.draw.setActive(enable)
            }
        },

        [types.MATTER_DRAW_ANNOTATION_SET_MODE](context, mode) {
            const map = context.rootState.map.map

            if (context.state.interactions.draw != null) {
                // Interactions - remove
                map.removeInteraction(context.state.interactions.draw)
                map.removeInteraction(context.state.interactions.modify)
                map.removeInteraction(context.state.interactions.snap)
            }

            // Interactions - draw
            let drawingMode = 'Point'
            context.state.eraseMode = false
            switch (mode) {
                case 'point':
                    drawingMode = 'Point'
                    break
                case 'line':
                    drawingMode = 'LineString'
                    break
                case 'polygon':
                    drawingMode = 'Polygon'
                    break
                case 'circle':
                    drawingMode = 'Circle'
                    break
                case 'erase':
                    drawingMode = null
                    context.state.eraseMode = true
            }

            if (drawingMode != null) {
                // Enable draw modify
                const modify = new Modify({ source: context.state.source })
                map.addInteraction(modify)

                // Interactions - draw
                context.state.interactions.draw = new Draw({
                    source: context.state.source,
                    type: drawingMode,
                    style: (feature) => {
                        return sketchInProgressStyleFn(feature, SketchType.Area)
                    },
                    freehand: false,
                })
                context.state.interactions.draw.on('drawstart', () => {
                    context.commit('_setDrawing', true)
                })
                context.state.interactions.draw.on('drawend', (e) => {
                    e.feature.setProperties({
                        strokeColour: context.state.colour.hex,
                        strokeWidth: 3,
                    })
                    context.commit('_setDrawing', false)
                })

                map.addInteraction(context.state.interactions.draw)

                // Interactions - snap - temporarily disabled
                // context.state.interactions.snap = new Snap({ source: context.state.source });
                // map.addInteraction(context.state.interactions.snap);
            } else {
                map.removeInteraction(context.state.interactions.snap)
            }
            context.commit('_setMode', mode)
        },

        [types.MATTER_DRAW_ANNOTATION_CLEAR_FEATURES](context) {
            if (context.state.initialized === true) {
                context.state.source.clear()
            }
        },

        [types.MATTER_DRAW_ANNOTATION_END](context) {
            const map = context.rootState.map.map

            if (context.state.initialized === true) {
                map.removeInteraction(context.state.interactions.draw)
                map.removeInteraction(context.state.interactions.modify)
                context.state.interactions.draw = null
            }
        },
    },
}
