import { CheckoutItemType } from '@/enums/checkout-item-type'
import { ICheckoutItemBase } from '@/interfaces/checkout/checkout-item-base.interface'

/**
 * Implementation for the base checkout item.
 * This should never be used directly, but extended to include the properties
 * required to display and process the item being purchased.
 * @implements ICheckoutItemBase
 */
export class CheckoutItemBase implements ICheckoutItemBase {
    readonly displayName: string
    readonly cost: number
    readonly itemType: CheckoutItemType

    constructor(
        displayName: string,
        cost: number,
        itemType: CheckoutItemType) {
        this.displayName = displayName
        this.cost = cost
        this.itemType = itemType
    }

    get costText(): string {
        return this.cost > 0 ? `£${ this.cost }` : 'Free'
    }

    /**
     * Just for debugging purposes
     */
    toJSON(): ICheckoutItemBase {
        return {
            displayName: this.displayName,
            cost: this.cost,
            costText: this.costText,
            itemType: this.itemType,
        }
    }
}
