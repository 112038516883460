import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    allResults: {
        addresses: [],
        companies: [],
        places: [],
        postcodes: [],
        titleNumbers: [],
    },
    popupContainer: null,
    popupHTML: null,
    showPopup: false,
    loading: false,
    loadingTitles: false,
    showOwnerSearch: false,
    ownerTitles: [],
    isActive: false, // the search panel is open
    isOwnerTitlesPanelOpen: false,
    searchText: '',
    isResultsPanelOpen: false,
    searchResultsLayer: null,
    selectedTitleNumbers: [],
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
