<template>
    <div class="ow-alert d-flex"
         :class="{
             'ow-alert--success': type === 'success',
             'ow-alert--warning': type === 'warning',
             'ow-alert--danger': type === 'danger',
             'ow-alert--info': type === 'info',
         }">
        <div class="ow-alert__icon justify-center py-3 d-flex align-center">
            <v-icon v-if="type === 'success'">
                $success
            </v-icon>
            <v-icon v-else-if="type === 'warning'">
                $warning
            </v-icon>
            <v-icon v-else-if="type === 'danger'">
                $warning
            </v-icon>
            <v-icon v-else>
                $info
            </v-icon>
        </div>
        <div class="d-flex justify-space-between w-100 align-center">
            <div v-if="hasContentSlot"
                 class="ow-alert__text py-2 px-4 accents-small">
                <slot />
            </div>
            <div v-else
                 class="ow-alert__text py-2 px-4 accents-small">
                {{ text }}
            </div>
            <v-icon v-if="hasClose"
                    class="ow-alert__close mr-4"
                    @click="$emit('close')">
                $close
            </v-icon>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        useSlots,
    } from 'vue'
    const slots = useSlots()

    defineEmits<{
        (e: 'close'): void
    }>()

    withDefaults(defineProps<{
        text?: string
        type?: 'info' | 'success' | 'warning' | 'danger'
        hasClose?: boolean
    }>(), {
        type: 'warning',
        hasClose: false,
    })

    const hasContentSlot = computed(() => slots.default && slots.default())
</script>

<style lang="scss">
    @import './ow-alert';
</style>
