<template>
    <teleport to="body">
        <ow-modal v-model="isUploadModalVisible"
                  :data-test="dataTestAttribute"
                  :title="t(i18nPathForTitle)"
                  :tooltip-text="i18nPathForTooltipText ? t(i18nPathForTooltipText) : ''"
                  class="ow-file-uploader"
                  data-test-attribute="ow-file-uploader-modal"
                  @update:model-value="setUploadModalVisible">
            <div v-if="i18nPathForText"
                 v-dompurify-html="t(i18nPathForText)"
                 class="body-regular mb-3" />

            <ow-drop-zone :accepted-file-extensions="acceptedFileExtensions"
                          :accepted-file-types="acceptedFileTypes"
                          :i18n-path-for-accepted-file-types-error="i18nPathForAcceptedFileTypesError"
                          :i18n-path-for-drop-zone-text="i18nPathForDropZoneText"
                          :i18n-path-for-too-many-files="i18nPathForTooManyFiles"
                          :max-file-size="maxFileSize"
                          :min-file-size="minFileSize"
                          :multiple="multiple"
                          data-test="ow-file-uploader-drop-zone"
                          @files="handleFileUpdate" />

            <template #actions>
                <div class="ow-file-uploader__actions">
                    <ow-button data-test="ow-file-uploader-actions-cancel"
                               data-track="DOCUMENTS - cancel button on the file upload dialog"
                               @click="setUploadModalVisible(false)">
                        <span v-t="'action.cancel'" />
                    </ow-button>
                    <ow-button :disabled="!allFilesValid"
                               data-test="ow-file-uploader-actions-upload"
                               data-track="DOCUMENTS - upload button on the file upload dialog"
                               is-primary
                               @click="startUpload">
                        <ow-progress v-if="isProcessing" />
                        <span v-else
                              v-t="'action.upload'" />
                    </ow-button>
                </div>
            </template>
        </ow-modal>
    </teleport>
</template>

<script lang="ts" setup>
    import {
        computed,
        PropType,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {mapGetters,
            useStore} from "vuex"

    import { FORM_DATA_KEY } from '@/api/document-upload.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDropZone from '@/components/core/ow-file-uploader-drop-zone.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwProgress from '@/components/core/ow-progress.vue'
    import { IFile } from '@/components/core/types/ow-file.interface'
    import { IOwFileUploaderEvent } from '@/components/core/types/ow-file-uploader-event.interface'
    import {
        UploadFileExtensions,
        UploadFileTypes,
    } from '@/enums/upload-file-types.enum'
    import {GET_UPLOAD_DOCUMENTS_DIALOG_STATE,
            SET_UPLOAD_DOCUMENTS_DIALOG_STATE} from "@/store/modules/documents/documents-types"

    const { t } = useI18n()
    const store = useStore()
    const emit = defineEmits<{
        (e: 'files', v: IOwFileUploaderEvent),
    }>()

    const files = ref<Array<IFile>>([])
    const formData = ref(new FormData())
    const hasFiles = computed(() => formData.value.getAll(FORM_DATA_KEY).length > 0)
    const isUploadModalVisible = computed(() => store.getters[GET_UPLOAD_DOCUMENTS_DIALOG_STATE])
    const setUploadModalVisible = (isVisible: boolean) => store.commit(SET_UPLOAD_DOCUMENTS_DIALOG_STATE, isVisible)

    const props = defineProps({
        acceptedFileTypes: {
            type: Array as PropType<Array<UploadFileTypes>>,
            required: false,
            default: () => [],
        },
        acceptedFileExtensions: {
            type: Array as PropType<Array<UploadFileExtensions>>,
            required: false,
            default: () => [],
        },
        i18nPathForAcceptedFileTypesError: {
            type: String,
            required: false,
        },
        i18nPathForDropZoneText: {
            type: String,
            required: false,
            default: 'documents.upload.dropZoneText',
        },
        i18nPathForTooltipText: {
            type: String,
            required: false,
            default: '',
        },
        i18nPathForText: {
            type: String,
            required: false,
        },
        i18nPathForTitle: {
            type: String,
            required: true,
        },
        i18nPathForTooManyFiles: {
            type: String,
            required: false,
            default: 'documents.upload.tooManyFiles',
        },
        maxFileSize: {
            type: Number,
            required: false,
            default: 31_457_280, // 30MB is the max that the server can handle currently.
        },
        minFileSize: {
            type: Number,
            required: false,
            default: 1024,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        dataTestAttribute: {
            type: String,
            required: false,
            default: 'ow-file-uploader',
        },
    })

    const isProcessing = ref(false)

    const allFilesValid = computed(() =>
        hasFiles.value &&
        files.value.every((file) => file.isValid))

    const startUpload = () => {
        isProcessing.value = true
        const payload: IOwFileUploaderEvent = {
            formData: formData.value,
            filesCount: formData.value.getAll(FORM_DATA_KEY).length,
        }
        emit('files', payload)
        isProcessing.value = false
        formData.value = new FormData()
        files.value = []
    }

    const handleFileUpdate = (payload: { formData: FormData, files: Array<IFile> }) => {
        formData.value = payload.formData
        files.value = payload.files
    }

    defineExpose({
        files,
        formData,
    })
</script>

<style lang="scss">
@import './ow-file-uploader.scss';
</style>
