<template>
    <v-fade-transition>
        <div v-if="showSketchModeOptions"
             class="sketches-tool-panel"
             :class="{ 'top-nav-visible': isTopNavVisible }"
             data-test="sketches-tool-panel"
             :style="componentStyle">
            <div v-if="currentMode === SketchMode.None"
                 class="sketches-tool-panel__inner">
                <header>
                    <span v-t="'sketches.toolPanel.heading'"
                          class="caption-highlight" />
                    <v-btn variant="text"
                           icon
                           @click="onCloseClick">
                        <v-icon>$close</v-icon>
                    </v-btn>
                </header>
                <div class="sketches-tool-panel__items"
                     :class="{ disabled: Boolean(isCreatingSketchType) }">
                    <sketches-tool-panel-item :title="$t('sketches.toolPanel.area')"
                                              :description="$t('sketches.toolPanel.areaDescription')"
                                              :is-loading="isCreatingSketchType === SketchType.Area"
                                              :image="areaImage"
                                              :gif="areaGif"
                                              icon-name="ow-icon-sketches-area"
                                              data-test="sketches-tool-panel-item-area"
                                              data-track="sketches-tool-panel-item-area"
                                              @click="newSketch(SketchType.Area)" />
                    <sketches-tool-panel-item :title="$t('sketches.toolPanel.linesAndArrows')"
                                              :description="$t('sketches.toolPanel.linesAndArrowsDescription')"
                                              :is-loading="isCreatingSketchType === SketchType.Line"
                                              :image="lineImage"
                                              :gif="lineGif"
                                              icon-name="ow-icon-sketches-line"
                                              data-test="sketches-tool-panel-item-line"
                                              data-track="sketches-tool-panel-item-line"
                                              @click="newSketch(SketchType.Line)" />
                    <sketches-tool-panel-item :title="$t('sketches.toolPanel.marker')"
                                              :description="$t('sketches.toolPanel.markerDescription')"
                                              :is-loading="isCreatingSketchType === SketchType.Marker"
                                              :image="markerImage"
                                              :gif="markerGif"
                                              icon-name="ow-icon-sketches-marker"
                                              data-test="sketches-tool-panel-item-marker"
                                              data-track="sketches-tool-panel-item-marker"
                                              @click="newSketch(SketchType.Marker)" />
                    <sketches-tool-panel-item :title="$t('sketches.toolPanel.text')"
                                              :description="$t('sketches.toolPanel.textDescription')"
                                              :is-loading="isCreatingSketchType === SketchType.Text"
                                              :image="textImage"
                                              :gif="textGif"
                                              icon-name="ow-icon-sketches-text"
                                              data-test="sketches-tool-panel-item-text"
                                              data-track="sketches-tool-panel-item-text"
                                              @click="newSketch(SketchType.Text)" />
                </div>
            </div>
            <sketches-tool-actions v-if="currentMode !== SketchMode.None && currentSketch"
                                   :sketch="currentSketch"
                                   :can-clear="canClear"
                                   :can-undo="canUndo"
                                   :has-features="hasFeatures"
                                   :is-drawing="isDrawing"
                                   :snapping-mode="snappingMode"
                                   :key-map="keyMap"
                                   @action="onAction">
                <div v-if="hintText"
                     v-dompurify-html="hintText"
                     class="sketches-tool-panel__hint-text" />
            </sketches-tool-actions>
        </div>
    </v-fade-transition>
</template>

<script lang="ts" setup>
    import {
        computed,
        nextTick,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useStore } from "vuex"

    import SketchesToolActions from '@/components/sketches/sketches-tool-actions.vue'
    import SketchesToolPanelItem from '@/components/sketches/sketches-tool-panel-item.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import {
        SketchMode,
        SketchType,
        ToolActionType,
    } from '@/enums/sketches-enums'
    import areaGif from '@/media/draw-area.gif'
    import lineGif from '@/media/draw-line.gif'
    import markerGif from '@/media/draw-marker.gif'
    import textGif from '@/media/draw-text.gif'
    import areaImage from '@/media/sketches-area.png'
    import lineImage from '@/media/sketches-lines.png'
    import markerImage from '@/media/sketches-marker.png'
    import textImage from '@/media/sketches-text.png'
    import i18n from '@/plugins/i18n'
    import { ISketchesState } from '@/store/modules/sketches'
    import {
        SKETCHES_CLEAR_CURRENT_SKETCH,
        SKETCHES_INITIALISE,
        SKETCHES_MUTATE_CURRENT_SKETCH,
        SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL,
        SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS,
        SKETCHES_NEW_SKETCH,
        SKETCHES_SET_CURRENT_SKETCH_STYLE,
        SKETCHES_SET_MODE,
        SKETCHES_SET_SNAPPING_MODE,
        SKETCHES_UNDO,
    } from '@/store/modules/sketches/types'

    const store = useStore()
    const sketchesState: ISketchesState = store.state.sketches

    interface Props {
        anchorElementSelector: string,
    }

    const props = defineProps<Props>()
    const componentStyle = ref({ left: '0px' })
    const anchorElement = computed(() => document.querySelector(props.anchorElementSelector))
    const isListCollapsed = computed(() => sketchesState.collapsedList)
    const currentMode = computed(() => sketchesState.currentMode)
    const currentSketch = computed(() => sketchesState.currentSketch)
    const isCreatingSketchType = ref(null)
    const expanding = ref(false)
    const canClear = computed<boolean>(() => sketchesState.drawLayer?.getSource().getFeatures().length > 0 || sketchesState.isDrawing)
    const hasFeatures = computed(() => sketchesState.drawLayer?.getSource()?.getFeatures()?.length > 0)
    const isDrawing = computed(() => sketchesState.isDrawing)
    const snappingMode = computed(() => sketchesState.snappingMode)
    const canUndo = computed(() => {
        const inProgressSketchFeatures = sketchesState.drawInteraction?.getOverlay().getSource().getFeatures() ?? []
        const hasGeometryChangeHistory = sketchesState.history.currentSketchGeoJson.length > 0 &&
            sketchesState.history.currentSketchGeoJsonIndex > 0
        return inProgressSketchFeatures.length > 1 || hasGeometryChangeHistory
    })
    const keyMap = computed(() => {
        return {
            'ctrl+z': onUndoClick,
            'command+z': onUndoClick,
            esc: onDoneClick,
            enter: () => {
                sketchesState.drawInteraction?.finishDrawing()
            },
        }
    })

    watch(isListCollapsed, async () => {
        expanding.value = true
        setTimeout(() => {
            updateComponentStyle()
            expanding.value = false
        }, 500)
    })

    onMounted(() => {
        updateComponentStyle()
    })
    const newSketch = async (type: SketchType) => {
        isCreatingSketchType.value = type
        try {
            await store.dispatch(SKETCHES_INITIALISE, { targetMap: store.state.map.map })
            const currentMatterId = store.state.matter.currentMatter.id
            const newSketch = await store.dispatch(SKETCHES_NEW_SKETCH, { matterId: currentMatterId, type })
            store.commit(SKETCHES_MUTATE_CURRENT_SKETCH, newSketch)
            switch (type) {
                case SketchType.Area:
                    await store.dispatch(SKETCHES_SET_MODE, SketchMode.Area)
                    break
                case SketchType.Line:
                    await store.dispatch(SKETCHES_SET_MODE, SketchMode.Line)
                    break
                case SketchType.Text:
                    await store.dispatch(SKETCHES_SET_MODE, SketchMode.Text)
                    break
                case SketchType.Marker:
                    await store.dispatch(SKETCHES_SET_MODE, SketchMode.Marker)
                    break
            }
        } catch (e) {
            console.error('Error creating sketch', e)
        } finally {
            isCreatingSketchType.value = null
        }
    }
    const updateComponentStyle = () => {
        if (!props.anchorElementSelector) {
            return null
        }
        const anchorElementRect = anchorElement.value?.getBoundingClientRect()
        if (!anchorElementRect) {
            return null
        }
        const left = anchorElementRect.left + anchorElementRect.width
        componentStyle.value = {
            left: `${ left }px`,
        }
    }

    // Hint text.
    const hintText = computed(() => {
        if (currentMode.value === SketchMode.None || !currentSketch.value) {
            return null
        }
        let i18nKey = ''
        switch (currentMode.value) {
            case SketchMode.Area:
                i18nKey = 'sketches.areaHint'
                break
            case SketchMode.Line:
                i18nKey = 'sketches.lineHint'
                break
            case SketchMode.Text:
                i18nKey = 'sketches.textHint'
                break
            case SketchMode.Marker:
                i18nKey = 'sketches.markerHint'
                break
        }
        if (sketchesState.isDrawing) {
            i18nKey = `${ i18nKey }WithProgress`
        } else if (sketchesState.drawLayer.getSource().getFeatures().length) {
            i18nKey = `${ i18nKey }WithExisting`
        }
        return i18n.global.t(i18nKey, { sketchName: currentSketch.value.name })
    })

    const showSketchModeOptions = computed(() => sketchesState.showSketchModeOptions && !expanding.value)
    const onCloseClick = () => {
        store.commit(SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS, false)
    }

    const onUndoClick = async () => {
        if (canUndo.value) {
            await store.dispatch(SKETCHES_UNDO)
            await nextTick()
        }
    }

    const onDoneClick = async () => {
        await store.dispatch(SKETCHES_SET_MODE, SketchMode.None)
        store.commit(SKETCHES_MUTATE_CURRENT_SKETCH, null)
    }

    const { isTopNavVisible } = useMapTopNav()
    const onAction = async (action:ToolActionType, data?:unknown) => {
        switch (action) {
            case ToolActionType.Clear:
                store.dispatch(SKETCHES_CLEAR_CURRENT_SKETCH)
                break
            case ToolActionType.Undo:
                onUndoClick()
                break
            case ToolActionType.SnapTo:
                await store.dispatch(SKETCHES_SET_SNAPPING_MODE, data)
                break
            case ToolActionType.EditName:
                store.commit(SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL, { show: true, sketch: currentSketch.value })
                break
            case ToolActionType.Done:
                onDoneClick()
                break
            case ToolActionType.SelectStyle:
                await store.dispatch(SKETCHES_SET_CURRENT_SKETCH_STYLE, data)
                break
        }
    }

    defineExpose({
        newSketch,
        store,
        isCreatingSketchType,
    })
</script>

<style lang="scss">
@import 'sketches-tool-panel.scss';
</style>
