<template>
    <ow-grid class="matter-searches-other-table__grid"
             :grid-config="gridConfig"
             :header="allProductsHeader"
             :collapsed="collapsed"
             theme="material"
             @collapse="emit('collapse')">
        <template #header>
            <slot name="header" />
        </template>
    </ow-grid>
</template>

<script setup lang="ts">
    import {
        GridConfig,
        Store,
    } from '@bryntum/grid'
    import {
        computed,
        ref,
    } from 'vue'

    import OwGrid from '@/components/core/ow-grid/ow-grid.vue'
    import { useGridConfig } from '@/components/core/ow-grid/use-grid-config'
    import {
        SearchProductsFilter,
    } from '@/store/modules/searches/types'

    import { GridHeader } from '../core/ow-grid'
    import { useOtherColumns } from './columns'
    import ProductItemModel from './models/ProductItemModel'

    const props = defineProps<{
        store: Store
        config?: Partial<GridConfig>
        collapsed?: boolean
        collapsible?: boolean
        filter?: SearchProductsFilter
    }>()

    const emit = defineEmits<{
        (e: 'add', args: ProductItemModel): void,
        (e: 'collapse'): void
    }>()

    const gridConfig = ref(useGridConfig({
        store: props.store,
        overrides: {
            ...props.config,
            autoHeight: true,
        },
        customArgs: {
            filterFn: (record: any) => {
                if (!props.filter?.searchText) {
                    return true
                }
                return record.description.toLowerCase().includes(props.filter?.searchText?.toLowerCase() ?? '')
            },
        },
        on: async (event: string, column: string, product: ProductItemModel) => {
            switch (event) {
                case 'add': {
                    emit('add', product)
                    break
                }
            }
        },
        columns: useOtherColumns,
    }))

    const recordCount = computed(() => props?.store?.isFiltered ? props?.store?.storage?.filteredValues?.length : props?.store?.allRecords?.length)

    const allProductsHeader = computed<GridHeader>(() => ({
        title: `All other products (${ recordCount.value })`,
        description: 'View all products available for this boundary',
        showCount: true,
        collapsible: props.collapsible,
        sticky: true,
        link: {
            label: props.collapsed ? 'View all' : 'Hide all',
            show: true,
            theme: 'primary',
            handler: () => {
                emit('collapse')
            },
        },
    }))
</script>

<style lang="scss">
    @import './matter-searches-category-table';
</style>
