/// See https://www.figma.com/file/3OmijfkDNaxNK8qIqka4bk/OW-Design-System?node-id=749%3A10006&t=7qFoVG0CYo9FxwnK-0
import {
    Arrowhead,
    SketchType,
} from '@/enums/sketches-enums'
import { IOwStyle } from '@/store/modules/sketches/types/style'

export class SketchesStyleFactory {
    static getDefaultSketchStyles(sketchType: SketchType, isComplexSketch: boolean) {
        switch (sketchType) {
            case SketchType.Line:
                return this.getDefaultLineSketchStyles(isComplexSketch)
            case SketchType.Marker:
                return this.getDefaultMarkerSketchStyles()
            case SketchType.Text:
                return this.getDefaultTextSketchStyles()
            default:
                return this.getDefaultAreaSketchStyles()
        }
    }

    static getDefaultSketchStyle(sketchType: SketchType) {
        switch (sketchType) {
            case SketchType.Line:
                return this.getDefaultLineSketchStyles(false)[0]
            case SketchType.Marker:
                return this.getDefaultMarkerSketchStyles()[0]
            case SketchType.Text:
                return this.getDefaultTextSketchStyles()[0]
            default:
                return this.getDefaultAreaSketchStyles()[0]
        }
    }

    static getDefaultSketchImportStyle(sketchType: SketchType) {
        switch (sketchType) {
            case SketchType.Line:
                return this.getDefaultLineSketchStyles(true)[1]
            case SketchType.Marker:
                return this.getDefaultMarkerSketchStyles()[2]
            case SketchType.Text:
                return this.getDefaultTextSketchStyles()[3]
            default:
                return this.getDefaultAreaSketchStyles()[0]
        }
    }

    private static getDefaultAreaSketchStyles(): Array<Partial<IOwStyle>> {
        return [
            {
                fillColour: '#F64A50',
                strokeColour: '#F64A50',
                labelColour: '#EA0B12',
                strokeWidth: 4,
                fillOpacity: 0.2,
                hatch: false,
            },
            {
                fillColour: '#158BF3',
                strokeColour: '#158BF3',
                labelColour: '#0B77D5',
                strokeWidth: 4,
                fillOpacity: 1,
                hatch: true,
            },
            {
                fillColour: '#AA67F7',
                strokeColour: '#AA67F7',
                labelColour: '#9846F6',
                strokeWidth: 4,
                fillOpacity: 0.2,
                hatch: false,
            },
            {
                fillColour: '#DA6905',
                strokeColour: '#DA6905',
                labelColour: '#BA5803',
                strokeWidth: 4,
                fillOpacity: 1,
                hatch: true,
            },
            {
                fillColour: '#099F03',
                strokeColour: '#099F03',
                labelColour: '#078703',
                strokeWidth: 4,
                fillOpacity: 0,
                hatch: false,
            },
            {
                fillColour: '#7D8B98',
                strokeColour: '#7D8B98',
                labelColour: '#6C7689',
                strokeWidth: 4,
                fillOpacity: 0,
                hatch: false,
                dash: true,
            },
        ]
    }

    private static getDefaultLineSketchStyles(isComplexSketch: boolean): Array<Partial<IOwStyle>> {
        return [
            {
                dash: false,
                strokeColour: '#F64A50',
                strokeWidth: 4,
                arrowhead: Arrowhead.None,
            },
            {
                dash: false,
                strokeColour: '#158BF3',
                strokeWidth: 4,
                arrowhead: Arrowhead.None,
            },
            {
                dash: false,
                strokeColour: '#AA67F7',
                strokeWidth: 4,
                arrowhead: isComplexSketch ? Arrowhead.None : Arrowhead.End,
            },
            {
                dash: true,
                strokeColour: '#DA6905',
                strokeWidth: 4,
                arrowhead: isComplexSketch ? Arrowhead.None : Arrowhead.Both,
            },
            {
                dash: false,
                strokeColour: '#099F03',
                strokeWidth: 6,
                arrowhead: Arrowhead.None,
            },
            {
                dash: true,
                strokeColour: '#7D8B98',
                strokeWidth: 4,
                arrowhead: Arrowhead.None,
            },
        ]
    }

    private static getDefaultMarkerSketchStyles(): Array<Partial<IOwStyle>> {
        return [
            {
                strokeColour: '#F64A50',
                strokeWidth: 3,
            },
            {
                strokeColour: '#158BF3',
                strokeWidth: 3,
            },
            {
                strokeColour: '#AA67F7',
                strokeWidth: 3,
            },
            {
                strokeColour: '#DA6905',
                strokeWidth: 3,
            },
            {
                strokeColour: '#099F03',
                strokeWidth: 3,
            },
            {
                strokeColour: '#7D8B98',
                strokeWidth: 3,
            },
        ]
    }

    private static getDefaultTextSketchStyles(): Array<Partial<IOwStyle>> {
        return [
            {
                strokeColour: '#F64A50',
                fillOpacity: 1,
                fillColour: '#FFFFFF',
                strokeWidth: 3,
            },
            {
                strokeColour: '#158BF3',
                fillOpacity: 1,
                fillColour: '#FFFFFF',
                strokeWidth: 3,
            },
            {
                strokeColour: '#AA67F7',
                fillOpacity: 1,
                fillColour: '#FFFFFF',
                strokeWidth: 3,
            },
            {
                strokeColour: '#DA6905',
                fillOpacity: 1,
                fillColour: '#FFFFFF',
                strokeWidth: 3,
            },
            {
                strokeColour: '#099F03',
                fillOpacity: 0,
                fillColour: '#FFFFFF',
                strokeWidth: 3,
            },
            {
                strokeColour: '#7D8B98',
                fillOpacity: 0,
                fillColour: '#FFFFFF',
                strokeWidth: 4,
            },
        ]
    }
}
