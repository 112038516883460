<template>
    <div class="ow-radio">
        <label>
            <input type="radio"
                   :name="name"
                   :checked="isChecked"
                   :value="id"
                   @change="handleChange" />
            {{ label }}
        </label>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'

    const props = defineProps<{
        modelValue: string;
        id: string;
        label?: string;
        name: string;
    }>()
    const emit = defineEmits<{
        (e: 'update:modelValue', v: string)
    }>()

    const isChecked = computed(() => props.modelValue === props.id)

    const handleChange = (event: Event) => {
        emit('update:modelValue', (event.target as HTMLInputElement).value)
    }
</script>

<style lang="scss">
    @import './ow-radio';
</style>
