import i18n from '@/plugins/i18n'
import { IMatterGroup } from '@/interfaces/matter-group.interface'
import { ITitleInMatterReference } from '@/interfaces/title-in-matter.reference'
import {
    sortBySortOrderFn,
} from '@/utils/array-utils'
import TitleInformationApi from '@/api/title-information.api'
import { TitleOrganiserBaseService } from '@/store/modules/matter/title-organiser/services/_title-organiser-base'
import { TitleOrganiserResult } from '@/store/modules/matter/title-organiser/title-organiser-result.type'

export class TitleOrganiserComplexityService extends TitleOrganiserBaseService {
    id = 'complexity'

    public async getMatterContents(matterId: string, currentTitles: Array<ITitleInMatterReference>, currentGroups: Array<IMatterGroup>,
        applyStyling: boolean): Promise<TitleOrganiserResult> {
        this.isLoading = true
        currentTitles.sort(sortBySortOrderFn)
        const boundaryAvailable = (titleNumber: string) => currentTitles.find(t => t.titleNumber === titleNumber)?.boundaryAvailable ?? false

        const data = await TitleInformationApi.getComplexityByTitleNumbers(matterId, currentTitles.map(t => t.titleNumber))
        if (!data) {
            this.isLoading = false
            return Promise.resolve(new TitleOrganiserResult([], []))
        }

        // Get the i18n label for the complexity category.
        const unknownGroupName = i18n.global.t('matter.titleOrganiser.unknownRegisterNotOwnedGroupName')
        const i18nCategory = (category) => i18n.global.te(`matter.titleComplexity.${ category }`) ? i18n.global.t(`matter.titleComplexity.${ category }`) : unknownGroupName

        // Create groups for each complexity category.
        const complexityCategories = ['extremelySimple', 'verySimple', 'fairlySimple', 'fairlyComplex', 'veryComplex', 'extremelyComplex', 'unknown']
        const i18nComplexityCategories = complexityCategories.map(category => i18nCategory(category))

        // Create group objects.
        const groups: Partial<IMatterGroup>[] = i18nComplexityCategories.map((category) => {
            return {
                name: category.toString(),
                expanded: true,
            }
        })

        const complexityData = data.map(({ titleNumber, complexity }) => ({ titleNumber, ...complexity }))

        // Allocate titles to groups and set the boundary style.
        const titleNumbers = {}
        currentTitles.forEach((title) => {
            titleNumbers[title.titleNumber] = title
        })

        const newGroups = []
        const newTitles = []
        let groupIndex = 0

        // Add the group if there are titles with that complexity.
        groups.forEach((group) => {
            const groupStyle = this.colourPalette.getEntryForComplexity(group.name)
            const titleNumbersInGroup = complexityData.filter(data => i18nCategory(data?.category) === group.name)
                .map(t => t.titleNumber)

            if (titleNumbersInGroup.length > 0) {
                const groupSortOrder = group.name === i18nCategory(data?.category) ? 999999 : (groupIndex + 1) * 100000
                newGroups.push({ ...group, id: groupIndex, sortOrder: groupSortOrder })
                titleNumbersInGroup.forEach((titleNumber, titleIndex) => {
                    const titleItem = titleNumbers[titleNumber]
                    newTitles.push({
                        titleNumber: titleItem.titleNumber,
                        boundaryStyle: applyStyling && boundaryAvailable(titleItem.titleNumber) ? groupStyle : null,
                        sortOrder: groupSortOrder + (titleIndex * 100),
                        matterGroupId: groupIndex,
                    })
                })
                groupIndex++
            }
        })

        // sort the remaining groups by sort order
        newGroups.sort((a, b) => a.sortOrder - b.sortOrder)

        this.isLoading = false
        return Promise.resolve(new TitleOrganiserResult(newTitles, newGroups))
    }

    i18nReference = 'matter.titleOrganiser.complexity'

    quickStylingByDefault = true
    isLoading = false

    hasAppliedChanges = true
}
