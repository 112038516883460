<template>
    <ow-modal v-model="show"
              persistent
              title="Product Configuration"
              content-class="matter-searches-highways-ra-config-dialog">
        <div class="matter-searches-highways-ra-config-dialog__content">
            <div class="d-flex gr-2 pt-2 flex-column">
                <div class="d-flex flex-row align-center justify-space-between">
                    <label class="caption-regular w-50"
                           for="numberofparcels-textfield">
                        {{ $t('searches.productConfig.highwaysRa.searchPurpose') }}
                    </label>
                    <ow-textfield id="search-purpose-textfield"
                                  v-model="searchPurpose"
                                  class="matter-searches-highways-ra-config-dialog__input" />
                </div>
            </div>
        </div>
        <template #actions>
            <ow-card-actions has-secondary-button
                             no-padding
                             primary-button-data-test="highways-ra-product-config-confirm"
                             secondary-button-data-test="highways-ra-product-config-cancel"
                             :primary-button-text="$t('action.save')"
                             :primary-button-disabled="!dirty || !valid"
                             @primary-button-click="handleConfirm"
                             @secondary-button-click="handleCancel" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        computed,
        nextTick,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import owTextfield from '@/components/core/ow-textfield.vue'
    import { HighwaysRaProductConfig } from '@/components/matter-searches/models/BasketItemHighwaysRaModel'
    import { SEARCHES_GET_HIGHWAYS_RA_CONFIG,
             SEARCHES_GET_HIGHWAYS_RA_CONFIG_SHOW,
             SEARCHES_GET_SELECTED_BASKET_ITEM,
             SEARCHES_MUTATE_BASKET_DIRTY,
             SEARCHES_MUTATE_PRODUCT_CONFIG,
             SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW,
             SEARCHES_QUOTE} from '@/store/modules/searches/types'

    const store = useStore()

    const selectedBasketItem = computed(() => store.getters[SEARCHES_GET_SELECTED_BASKET_ITEM])

    const show = computed<boolean>({
        get: () => store.getters[SEARCHES_GET_HIGHWAYS_RA_CONFIG_SHOW],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW, {
                model: selectedBasketItem.value,
                show: val,
            })
        },
    })

    const config = computed<HighwaysRaProductConfig>({
        get: () => store.getters[SEARCHES_GET_HIGHWAYS_RA_CONFIG],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG, {
                model: selectedBasketItem.value,
                config: val,
            })
        },
    })
    const searchPurpose = ref<string>(config?.value.searchPurpose ?? '')

    let defaultConfig: HighwaysRaProductConfig = null
    const dirty = ref(false)
    const valid = ref(false)
    const initialising = ref(true)

    const handleCancel = () => {
        show.value = false
    }

    const validateConfig = () => {
        valid.value = true
    }

    const handleConfirm = () => {
        if (!valid.value) {
            return
        }
        config.value = {
            searchPurpose: searchPurpose.value,
        }
        show.value = false
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, true)
        store.dispatch(SEARCHES_QUOTE)
    }

    watch(() => searchPurpose.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.searchPurpose
        }
        validateConfig()
    })

    watch(() => show.value, (val) => {
        initialising.value = true
        if (val) {

            searchPurpose.value = config.value?.searchPurpose ?? ''
            defaultConfig = JSON.parse(JSON.stringify({
                searchPurpose: searchPurpose.value,
            }))
            dirty.value = false

            nextTick(() => {
                initialising.value = false
            })
        }
    }, {
        immediate: true,
    })
</script>

<style lang="scss">
@import './matter-searches-highways-ra-product-config-dialog.scss';
</style>
