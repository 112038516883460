<template>
    <ow-loading-skeleton v-if="loading"
                         height="200px" />
    <strong v-else-if="items.length === 0"
            class="title-select-table">
        You haven't added any titles yet.
    </strong>
    <ow-data-grid v-else
                  :allow-selection="!isAvailabilityLoading"
                  :headers="dataGridHeaders"
                  :items="items"
                  :preselected-items="preselectedItems"
                  class="title-select-table"
                  item-key="titleNumber"
                  :sort-by="sortBy"
                  narrow
                  @items-selected="handleItemsSelection">
        <template #customSelectionHeader>
            <span class="body-copy">
                {{ selectedItems.length }} {{ pluralisedSelectedItems }} selected
            </span>
        </template>
        <template #item.availability="{ item }">
            <ow-colored-label v-if="isAvailabilityLoaded(item)"
                              :theme="documentAvailabilityColor(documentAvailability[item.titleNumber])"
                              is-small>
                {{ documentAvailabilityText(documentAvailability[item.titleNumber]) }}
            </ow-colored-label>
            <ow-loading-skeleton v-else
                                 height="22px" />
        </template>
    </ow-data-grid>
</template>

<script lang="ts">
    import pluralize from 'pluralize'
    import { ref } from 'vue'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import {
        HmlDocumentAvailabilityTextForCode,
        HmlrDocumentAvailabilityCode,
    } from '@/consts/document-availability'
    import { IDataGridHeader } from '@/interfaces/core-components/data-grid-header.interface'
    import { IDataGridItem } from '@/interfaces/core-components/data-grid-item.interface'
    import { IDocumentValidation } from '@/interfaces/document-validation.interface'

    export default {
        name: 'TitleSelectTable',

        components: {
            OwColoredLabel,
            OwDataGrid,
            OwLoadingSkeleton,
        },

        props: {
            items: {
                type: Array,
                required: true,
            },
            documentAvailability: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
                required: false,
            },
            preselectedItems: {
                type: Array,
                default: () => [],
                required: false,
            },
        },

        emits: [
            'items-selected',
        ],

        data() {
            return {
                dataGridHeaders: [
                    { title: 'Title', key: 'titleNumber', sortable: false },
                    { title: 'Availability', key: 'availability', sortable: false },
                    { title: 'Address', key: 'address', sortable: false },
                ],
                selectedItems: [],
                isAvailabilityLoading: false,
                sortBy: ref([{key: 'titleNumber', order: 'asc'}]),
            } as {
                dataGridHeaders: IDataGridHeader[],
                selectedItems: IDataGridItem[],
                isAvailabilityLoading: boolean,
                sortBy: Object,
            }
        },

        computed: {
            pluralisedSelectedItems() {
                return pluralize('title', this.selectedItems.length)
            },
        },

        methods: {
            documentAvailabilityText(documentAvailability: IDocumentValidation) {
                return documentAvailability.hasBeenOrdered
                    ? 'Ordered'
                    : HmlDocumentAvailabilityTextForCode[documentAvailability.availability]
            },

            documentAvailabilityColor(documentAvailability: IDocumentValidation) {
                if (documentAvailability.hasBeenOrdered) {
                    return 'shadow'
                }

                switch (documentAvailability.availability) {
                    case HmlrDocumentAvailabilityCode.Available:
                        return 'success'
                    case HmlrDocumentAvailabilityCode.NotImmediatelyAvailable:
                        return 'warning'
                    case HmlrDocumentAvailabilityCode.Unavailable:
                        return 'danger'
                    default:
                        return 'shadow'
                }
            },

            handleItemsSelection(values: IDataGridItem[]) {
                this.selectedItems = values
                this.$emit('items-selected', values)
            },

            isAvailabilityLoaded(item) {
                if (this.documentAvailability[item.titleNumber]) {
                    this.isAvailabilityLoading = false
                    return true
                }
                this.isAvailabilityLoading = true
                return false
            },
        },
    }
</script>

<style lang="scss">
    @import 'title-select-table.scss';
</style>
