<template>
    <section class="config-section snapshot-title-options">
        <h3 v-t="'snapshots.title.title'"
            class="body-highlight" />
        <div class="snapshot-title-options__item">
            <label v-t="'snapshots.title.showTitle'"
                   for="snapshot-title-show-title"
                   class="body-copy" />
            <v-switch id="snapshot-title-show-title"
                      v-model="showTitleInternal"
                      data-track="Export Assistant - Show title"
                      hide-details
                      color="primary"
                      class="snapshot-title-options__item--toggle" />
        </div>
        <div class="snapshot-title-options__item">
            <label v-t="'snapshots.title.showSubtitle'"
                   for="snapshot-title-show-subtitle"
                   class="body-copy" />
            <v-switch id="snapshot-title-show-subtitle"
                      v-model="showSubtitleInternal"
                      data-track="Export Assistant - Show subtitle"
                      hide-details
                      color="primary"
                      class="snapshot-title-options__item--toggle" />
        </div>
    </section>
</template>
<script setup lang="ts">
    // Note that the title and subtitle values are not yet persisted to the snapshot model as it is not required.
    import {
        onDeactivated,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import {
        ISnapshotService,
    } from '@/components/snapshots/common/snapshot-interfaces'
    import { BaseRenderDataModel } from '@/components/snapshots/common/snapshot-models'
    interface Props {
        service: ISnapshotService
    }

    const props = defineProps<Props>()
    const { t } = useI18n()

    const showTitleInternal = ref<boolean>(false)
    const showSubtitleInternal = ref<boolean>(false)

    onDeactivated(() => {
        showTitleInternal.value = false
        showSubtitleInternal.value = false
    })

    watch(showTitleInternal, newValue => {
        (props.service.snapshotModel.renderData as BaseRenderDataModel).showTitle = newValue
    })

    watch(showSubtitleInternal, newValue => {
        (props.service.snapshotModel.renderData as BaseRenderDataModel).showSubtitle = newValue
    })

</script>

<style scoped lang="scss">
@import "snapshot-title-options";
</style>
