import { isNullOrEmpty } from '@/utils/array-utils'

export const titleDataMixin = {
    computed: {
        /**
         * Returns the type of the tenure, either freehold, leasehold or other
         * @returns {string}
         */
        tenureType() {
            if (this.selectedTitle?.record?.tenure != null) {
                const tenure = this.selectedTitle.record?.tenure?.toUpperCase()

                if (tenure?.includes('FREEHOLD')) {
                    return 'freehold'
                }

                if (tenure?.includes('LEASEHOLD')) {
                    return 'leasehold'
                }

                return 'other'
            }

            return 'other' // not sure if this should be null, or whether this would ever happen
        },

        /**
         * Get the lease terms of the selected title
         * @returns {*[]|*} returns array of lease terms
         */
        leaseTermText() { //
            const proprietorshipData = this.selectedTitle?.record?.bgProprietorshipData
            if (proprietorshipData != null) {
                if (proprietorshipData.leaseField != null) {
                    return proprietorshipData.leaseField
                        .map((item) => {
                            return item.leaseTermField
                        })
                }
            }
            return []
        },

        /**
         * Is there any lease term text to display?
         * @return {boolean} - true if there is lease term text, false otherwise
         */
        hasLeaseTermText() {
            return !isNullOrEmpty(this.leaseTermText)
        },
    },
}
