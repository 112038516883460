<template>
    <div ref="rendererViewportRef"
         class="snapshot-renderer"
         :style="rendererStyle">
        <div class="snapshot-renderer__preview-container">
            <div v-if="service.exporting.value"
                 v-t="'snapshots.generatingExport'"
                 data-test="snapshot-renderer-generating-export"
                 class="snapshot-renderer-loading-state" />

            <div ref="previewAreaRef"
                 class="snapshot-renderer__preview"
                 data-test="snapshot-renderer-preview"
                 :class="{exporting: service.exporting.value}"
                 :style="combinedPreviewStyle">
                <div class="renderer-container">
                    <component :is="rendererComponent"
                               v-bind="{ service }" />
                </div>
            </div>
            <div class="zoom-controls-container">
                <!-- TODO -->
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import {
        computed,
        nextTick,
        onMounted,
        onUnmounted,
        ref,
        watch,
    } from 'vue'

    import {
        PageLayouts,
        SnapshotFontSizeBaseline,
        SnapshotType,
    } from '@/components/snapshots/common/snapshot-enums-consts'
    import { ISnapshotService } from '@/components/snapshots/common/snapshot-interfaces'
    import {
        getLayoutStyleForPageLayout,
        getScalingFactorForPageLayout,
    } from '@/components/snapshots/common/snapshot-utils'
    import MapSnapshotRenderer from '@/components/snapshots/map-snapshots/render-components/map-snapshot-renderer.vue'

    interface Props {
        service: ISnapshotService
    }
    const props = defineProps<Props>()
    const rendererViewportRef = ref<HTMLElement | null>(null)
    const previewAreaRef = ref<HTMLElement | null>(null)

    const rendererComponent = computed(() => {
        switch (props.service.snapshotModel.type) {
            case SnapshotType.Siteplan:
                return MapSnapshotRenderer
            default:
                console.error(`Unknown snapshot type ${ props.service.snapshotModel.type }`)
        }
        return undefined
    })

    const pageLayoutStyle = computed(() => {
        // Get the styling associated for the selected layout.
        return getLayoutStyleForPageLayout(
            rendererViewportRef.value,
            props.service.snapshotModel.renderData,
        )
    })

    const combinedPreviewStyle = computed(() => {
        return {
            ...pageLayoutStyle.value,
            ...pageTransformToFitStyle.value,
        }
    })

    const pageTransformToFitStyle = ref<Partial<CSSStyleDeclaration>>()
    watch(pageLayoutStyle, async () => {
        await updatePreviewToFitAvailableSpace()
    })

    const updatePreviewToFitAvailableSpace = async () => {
        await nextTick()
        const scaleFactor = getScalingFactorForPageLayout(rendererViewportRef.value, previewAreaRef.value)
        if (scaleFactor < 1) {
            pageTransformToFitStyle.value = {
                transform: `scale(${ scaleFactor })`,
            }
        } else {
            pageTransformToFitStyle.value = {
                transform: 'none',
            }
        }
    }
    const rendererStyle = computed<Partial<CSSStyleDeclaration>>(() => {
        const layout = PageLayouts[props.service.snapshotModel.renderData.layoutId]
        return {
            fontSize: `${ Math.round(SnapshotFontSizeBaseline * layout.graphicSizeMultiplier) ?? 1 }px`,
        }
    })

    onMounted(async () => {
        await updatePreviewToFitAvailableSpace()
        window.addEventListener('resize', updatePreviewToFitAvailableSpace)
    })

    onUnmounted(() => {
        window.removeEventListener('resize', updatePreviewToFitAvailableSpace)
    })

    defineExpose({
        pageLayoutStyle,
        rendererStyle,
        updatePreviewToFitAvailableSpace,
        pageTransformToFitStyle,
    })
</script>

<style lang="scss">
@import 'snapshot-renderer-preview';
</style>
