<template>
    <title-details-list-item :tenure="tenure"
                             :title-number="titleNumber"
                             :is-visible="isVisible"
                             uses-visibility-variations
                             :address="addresses && addresses.length ? addresses.join(', ') : $t('titles.addressUnavailable')"
                             :custom-name="customName"
                             :label-colour="labelColour"
                             :is-focused="isSelected"
                             :is-highlighted="isHighlighted"
                             :is-register-ordered="isRegisterOrdered"
                             :is-register-uploaded="isRegisterUploaded"
                             :has-light-background="hasLightBackground"
                             clickable-area="whole-item"
                             data-test="addable-title-details-list-item"
                             @click="onClick">
        <template #afterContent>
            <v-icon v-if="isSelected"
                    :data-track="onRemoveDataTrack"
                    data-test="addable-title-details-list-item-icon-selected">
                $minus
            </v-icon>
            <v-icon v-else
                    :data-track="onAddDataTrack"
                    data-test="addable-title-details-list-item-icon-unselected">
                $plus
            </v-icon>
        </template>
    </title-details-list-item>
</template>

<script lang="ts">
    import TitleDetailsListItem from '@/components/matter/titles/title-details-list-item.vue'

    export default {
        components: {
            TitleDetailsListItem,
        },
        props: {
            tenure: {
                type: String,
                required: true,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            addresses: {
                type: Array,
                default: null,
                required: false,
            },
            isSelected: {
                type: Boolean,
                required: false,
            },
            customName: {
                type: String,
                required: false,
                default: null,
            },
            labelColour: {
                type: String,
                required: false,
                default: null,
            },
            onAddDataTrack: {
                type: String,
                required: false,
                default: null,
            },
            isRegisterOrdered: {
                type: Boolean,
                required: false,
            },
            onRemoveDataTrack: {
                type: String,
                required: false,
                default: null,
            },
            isHighlighted: {
                type: Boolean,
                required: false,
            },
            hasLightBackground: {
                type: Boolean,
                default: false,
            },
            isVisible: {
                type: Boolean,
                required: false,
                default: true,
            },
            isRegisterUploaded: {
                type: Boolean,
                required: false,
            },
        },
        emits: [
            'click',
        ],
        methods: {
            onClick() {
                this.$emit('click', this.titleNumber)
            },
        },
    }
</script>

<style lang="scss">
    @import 'addable-title-details-list-item';
</style>
