<template>
    <div v-if="show"
         class="matter-searches-select-product-step d-flex flex-row">
        <matter-searches-error-dialog @retry="initialise" />
        <matter-searches-order-details :map-options="mapOptions"
                                       :geo-json="geoJson"
                                       :title-numbers="selectedTitleNumbers"
                                       show-edit-button
                                       :show-title-numbers="false"
                                       @map-edit="onMapEdit" />
        <div class="d-flex flex-column w-100">
            <matter-searches-project-name-form v-if="organisationSettings?.projectMask"
                                               v-model="projectName"
                                               v-model:valid="projectNameValid"
                                               :project-mask="organisationSettings?.projectMask" />
            <div v-t="'searches.createOrder.fields.address'"
                 class="body-highlight" />
            <div v-t="'searches.enterAddressForm.title'"
                 class="body-regular" />
            <matter-searches-enter-address-form v-model="structuredForm"
                                                v-model:valid="structuredAddressValid"
                                                :addresses="addresses"
                                                class="mt-4" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { add } from 'ol/coordinate'
    import {
        computed,
        onMounted,
        ref,
        watch,
        WritableComputedRef,
    } from 'vue'
    import {              useRoute,
                          useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import MatterApi from '@/api/matter.api'
    import {IGetOrganisationSettingsResponse,
            StructuredAddress} from '@/api/searches.api'
    import MatterSearchesEnterAddressForm from '@/components/matter-searches/matter-searches-enter-address-form.vue'
    import MatterSearchesErrorDialog from '@/components/matter-searches/matter-searches-error-dialog.vue'
    import MatterSearchesOrderDetails from '@/components/matter-searches/matter-searches-order-details.vue'
    import MatterSearchesProjectNameForm from "@/components/matter-searches/matter-searches-project-name-form.vue"
    import { useMapOptions } from '@/composables/use-map-options'
    import { Route } from '@/enums/route.enum'
    import {
        getStepName,
        PropertyType,
        SearchesSteps,
    } from '@/enums/searches.enum'
    import {
        SEARCHES_GET_GEOJSON,
        SEARCHES_GET_LOADING,
        SEARCHES_GET_ORGANISATION_SETTINGS,
        SEARCHES_GET_PROJECT_NAME,
        SEARCHES_GET_SELECTED_TITLE_NUMBERS,
        SEARCHES_GET_STRUCTURED_ADDRESS,
        SEARCHES_MUTATE_CLEAR_ERRORS,
        SEARCHES_MUTATE_LOADING,
        SEARCHES_MUTATE_PROJECT_NAME,
        SEARCHES_MUTATE_PROJECT_NAME_VALID,
        SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS,
        SEARCHES_MUTATE_STRUCTURED_ADDRESS,
        SEARCHES_MUTATE_STRUCTURED_ADDRESS_VALID,
    } from '@/store/modules/searches/types'

    const props = defineProps({
        show: {
            type: Boolean,
            default: false,
        },
    })

    const addresses = ref<{
        postcode: string;
        address: string;
    }[]>([])

    // composables
    const store = useStore()
    const router = useRouter()
    const mapOptions = useMapOptions()

    // computed
    const geoJson = computed<string>(() => store.getters[SEARCHES_GET_GEOJSON])
    const structuredForm = computed<StructuredAddress>({
        get: () => store.getters[SEARCHES_GET_STRUCTURED_ADDRESS],
        set: (structuredAddress: StructuredAddress) => {
            store.commit(SEARCHES_MUTATE_STRUCTURED_ADDRESS, structuredAddress)
        },
    })

    const loading = computed<boolean>({
        get(): boolean {
            return store.getters[SEARCHES_GET_LOADING]
        },
        set(loading: boolean): void {
            store.commit(SEARCHES_MUTATE_LOADING, loading)
        },
    })

    const selectedTitleNumbers = computed<string[]>({
        get: () => store.getters[SEARCHES_GET_SELECTED_TITLE_NUMBERS],
        set: (titleNumbers: []) => {
            store.commit(SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS, titleNumbers, {
                root: true,
            })
        },
    })

    const structuredAddressValid = computed<boolean>({
        get: () => store.state?.addressValid,
        set: (valid: boolean) => {
            store.commit(SEARCHES_MUTATE_STRUCTURED_ADDRESS_VALID, valid, {
                root: true,
            })
        },
    })

    const organisationSettings = computed((): IGetOrganisationSettingsResponse => store.getters[SEARCHES_GET_ORGANISATION_SETTINGS])

    const projectNameValid = computed<boolean>({
        get: () => store.state?.projectNameValid,
        set: (valid: boolean) => {
            store.commit(SEARCHES_MUTATE_PROJECT_NAME_VALID, valid, {
                root: true,
            })
        },
    })

    const projectName = computed<string>({
        get: () => store.getters[SEARCHES_GET_PROJECT_NAME],
        set: (projectName: string) => {
            store.commit(SEARCHES_MUTATE_PROJECT_NAME, projectName)
        },
    })

    // lifecycle
    onMounted(() => {
        loading.value = true
        if (!selectedTitleNumbers.value || selectedTitleNumbers.value.length === 0) {
            store.commit(SEARCHES_MUTATE_STRUCTURED_ADDRESS, null)
            return
        }
    })

    const onMapEdit = () => {
        router.push({
            name: Route.MatterSearchesCreate,
            params: {
                step: getStepName(SearchesSteps.DefinePolygon),
                args: selectedTitleNumbers.value.join(','),
            },
        })
    }

    const fetchData = async () => {
        if (!selectedTitleNumbers.value || selectedTitleNumbers.value.length === 0) {
            addresses.value = []
        }
        const titles: any[] = await MatterApi.getTitleAddressesForMatter(store.state.matter.currentMatter.id)
        const titlesWithAddresses = titles.filter((title) => selectedTitleNumbers.value.includes(title.titleNumber))
        const mappedAddresses = titlesWithAddresses.map((title) => ({
            postcode: title.addresses?.[0]?.postcode ?? '',
            address: title.addresses?.[0]?.address ?? null,
        })).filter((address) => address.address)
        addresses.value = mappedAddresses
    }

    const initialise = async () => {
        loading.value = true
        try {
            store.commit(SEARCHES_MUTATE_CLEAR_ERRORS)
            await fetchData()
        } finally {
            loading.value = false
        }
    }

    // watch
    watch(() => props.show, (show) => {
        if (show) {
            initialise()
        }
    })

    watch(() => selectedTitleNumbers.value, async () => {
        if (!selectedTitleNumbers.value || selectedTitleNumbers.value.length === 0) {
            structuredForm.value = {
                ...structuredForm.value,
                postcode: '',
                thoroughfareName: '',
                postTown: '',
                buildingNumber: '',
                organisationName: '',
                sector: PropertyType[PropertyType.Commercial].toString(),
            }
        }
    })
</script>
