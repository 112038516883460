<template>
    <div class="address-data">
        <div v-if="addresses.length === 0"
             class="main-data-property">
            <label>No address information available. Please load the digital title to view address information.</label>
        </div>
        <div v-else
             class="main-data-property">
            <label>{{ labelText }}</label>

            <p v-if="!hasMultipleAddresses">
                <OwClipboard :text="addresses[0].address"
                             data-track="TITLE-DETAILS-PANEL - Copy Address info">
                    {{ addresses[0].address }}
                </OwClipboard>
            </p>
            <ul v-else
                class="main-data-property__address-list">
                <div class="main-data-property__address-list--header">
                    {{ addresses.length }} addresses registered at this property
                </div>
                <li v-for="(address, index) in filteredAddresses"
                    :key="address.uprn"
                    :class="{
                        'main-data-property__address-list--hide' : index > 2 && !showAllAddresses,
                    }"
                    class="main-data-property__address-list--item">
                    <OwClipboard :text="address.address"
                                 data-track="TITLE-DETAILS-PANEL - Copy Address info">
                        <span class="main-data-property__address-list--index">{{ index + 1 }}.</span>
                        <span class="main-data-property__address-list--address">{{ address.address }}</span>
                    </OwClipboard>
                </li>
            </ul>

            <p v-if="hasMultipleAddresses"
               class="show-more-link">
                <a v-if="!showAllAddresses"
                   data-test="title-details-summary-address-show-more-btn"
                   data-track="TITLE-DETAILS-PANEL - Show multiple addresses on the summary panel"
                   href="#"
                   @click.prevent="showAllAddresses = true">
                    Show more
                </a>
                <a v-else
                   data-test="title-details-summary-address-show-less-btn"
                   data-track="TITLE-DETAILS-PANEL - Hide multiple addresses on the summary panel"
                   href="#"
                   @click.prevent="showAllAddresses = false">
                    Show less
                </a>
            </p>
        </div>
    </div>
</template>

<script>
    import pluralize from 'pluralize'

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import featureFlagsMixin from '@/feature-flags/feature-flags-mixin'

    export default {
        name: 'AddressData',

        components: {
            OwClipboard,
        },

        mixins: [featureFlagsMixin],

        props: {
            addresses: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                showAllAddresses: false,
            }
        },

        computed: {
            labelText() {
                return pluralize('Address', this.addresses.length)
            },

            hasMultipleAddresses() {
                return this.addresses.length > 1
            },

            filteredAddresses() {
                return this.addresses.filter(address => address)
            },
        },
    }
</script>

<style lang="scss">
    @import './address-data'
</style>
