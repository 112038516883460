<template>
    <div class="update-monitoring-group-list">
        <section v-if="isNullOrEmpty(items)">
            <div data-test="no-companies-added"
                 class="update-monitoring-group-list__empty body-regular-small text-center py-2">
                {{ $t('assetMonitoring.preferences.cards.updateMonitoringGroup.noCompaniesAdded') }}
            </div>
        </section>
        <section v-for="group in groupsByKey"
                 :key="group.searchRef.id"
                 class="update-monitoring-group-list-group">
            <div v-for="item in group.items"
                 :key="item.regNumber"
                 :data-test="`item-${item.regNumber}`"
                 class="update-monitoring-group-list__item ml-1 d-flex align-center justify-space-between">
                <section class="update-monitoring-group-list__item-content">
                    <span class="body-regular-small ml-2">
                        {{ item.name }}
                    </span>
                    <span class="caption-highlight ml-2">
                        {{ item.regNumber }}
                    </span>
                </section>
                <section class="d-flex">
                    <span class="body-regular-small mx-2 update-monitoring-group-list__remove">
                        <ow-button is-text
                                   :data-test="`remove-button-${item.regNumber}`"
                                   is-primary
                                   small
                                   is-link
                                   @click="$emit('remove-group', [item])">{{ $t('action.remove') }}</ow-button>
                    </span>
                </section>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
    } from "vue"

    import {
        IAssetMonitoringCompanyGroupItem,
        ICompaniesGroupSearchRef,
    } from "@/components/asset-monitoring/company-groups/types"
    import OwButton from "@/components/core/ow-button-ds.vue"
    import { isNullOrEmpty } from "@/utils/array-utils"

    defineEmits<{
        (e: 'remove-group', items: IAssetMonitoringCompanyGroupItem[]),
    }>()

    const props = defineProps<{
        items?: IAssetMonitoringCompanyGroupItem[],
    }>()

    const groupsByKey = computed(() => {
        const groups: Record<string, {
            searchRef: ICompaniesGroupSearchRef
            items: IAssetMonitoringCompanyGroupItem[],
        }> = {}

        props.items?.forEach((item) => {
            if (!groups[item.searchRef.id]) {
                groups[item.searchRef.id] = {
                    searchRef: item.searchRef,
                    items: [],
                }
            }
            groups[item.searchRef.id].items.push(item)
        })
        // reverse the order of the groups
        return Object.values(groups).reverse()
    })
</script>

<style scoped>
@import './update-monitoring-group-list.scss';
</style>