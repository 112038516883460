// getters
export const GET_TITLE_COMPLEXITY = 'GET_TITLE_COMPLEXITY'

// actions
export const FETCH_COMPLEXITY_FOR_TITLES = 'FETCH_COMPLEXITY_FOR_TITLES'

// mutations
export const MUTATE_ADD_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS = 'MUTATE_ADD_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS'
export const MUTATE_REMOVE_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS = 'MUTATE_REMOVE_LOADING_TITLE_COMPLEXITY_TITLE_NUMBERS'
export const MUTATE_SET_TITLE_COMPLEXITY = 'MUTATE_SET_TITLE_COMPLEXITY'
