export enum DocumentOrderStatus {
    UNKNOWN,
    ORDERING,
    ORDERED,
    RETRYING,
    PROCESSING,
    FAILURE,
    COMPLETED,
    COME_BACK_LATER,
    REMOTE_FAILURE,
    SENT_IN_POST,
    UNAVAILABLE,
    DERIVING,
    DERIVED,
    DERIVATION_FAILED,
    AUGMENTING,
    AUGMENTED,
    UNDER_INVESTIGATION,
    AUGMENTATION_FAILED,
}

export const getDocumentOrderStatusText = (status): string => {
    switch (status) {
        case DocumentOrderStatus.ORDERING:
        case DocumentOrderStatus.RETRYING:
            return 'Requesting'

        case DocumentOrderStatus.FAILURE:
        case DocumentOrderStatus.REMOTE_FAILURE:
            return 'Error'

        case DocumentOrderStatus.UNAVAILABLE:
            return 'Unavailable'

        case DocumentOrderStatus.SENT_IN_POST:
            return 'Sent in post'

        case DocumentOrderStatus.COME_BACK_LATER:
            return 'Pending with HMLR...'

        case DocumentOrderStatus.UNDER_INVESTIGATION:
            return 'HMLR Processing'

        case DocumentOrderStatus.ORDERED:
        case DocumentOrderStatus.DERIVING:
        case DocumentOrderStatus.AUGMENTING:
        case DocumentOrderStatus.PROCESSING:
            return 'Enhancing'

        default:
            return ''
    }
}

export const isTitleDocumentAvailableToView = (status: number): Boolean => {
    switch (status) {
        case DocumentOrderStatus.ORDERED:
        case DocumentOrderStatus.DERIVING:
        case DocumentOrderStatus.AUGMENTING:
        case DocumentOrderStatus.PROCESSING:
        case DocumentOrderStatus.AUGMENTED:
        case DocumentOrderStatus.DERIVED:
            return true
        default:
            return false
    }
}
