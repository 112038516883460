<template>
    <div class="link-share-landing">
        <ow-public-background class="link-share-landing__background" />
        <section :class="{loaded: !loadingMetadata, 'has-organisation-logo': hasOrganisationLogo}"
                 class="d-flex flex flex-column justify-center content-container">
            <div v-if="!loadingMetadata">
                <div v-if="isLinkViewable"
                     class="content-container__header">
                    <img v-if="hasOrganisationLogo"
                         :alt="metadata.organisationName"
                         :src="organisationLogoURL"
                         class="organisation-logo"
                         data-test="link-share-landing-organisation-logo"
                         @error="onImgError()" />

                    <h2 class="content__heading hide-in-percy"
                        data-test="link-share-landing-shared-project-title">
                        {{ hasOrganisationLogo ? '' : `${ metadata.organisationName } ` }}has shared a project with you
                    </h2>

                    <v-text-field v-if="!isPasswordConfirmed && metadata.passwordProtected"
                                  v-model="password"
                                  :append-icon="showPassword ? '$visibility' : '$eye-off'"
                                  :type="showPassword ? 'text' : 'password'"
                                  class="link-share-landing__input-password"
                                  data-test="link-share-landing-password-input"
                                  label="A password is required"
                                  style="width: 250px"
                                  @click:append="showPassword = !showPassword" />

                    <p v-if="errorText"
                       class="error-text">
                        {{ errorText }}
                    </p>
                </div>

                <div v-if="isLinkViewable"
                     class="content-container__grid">
                    <div v-if="hasInteractiveSitePlan"
                         class="content-container__grid--option-card">
                        <h3 class="content__subheading">
                            Interactive site plan
                        </h3>
                        <p class="body-copy--greyout">
                            See an overview of the property and titles.
                        </p>
                        <ow-button :disabled="disableEnterButton"
                                   class="content-container__grid--button"
                                   data-test="link-share-landing-isp-button"
                                   is-primary
                                   @click="onIspClick">
                            Enter
                        </ow-button>
                    </div>
                    <div v-if="hasWalkthrough"
                         class="content-container__grid--option-card">
                        <h3 class="content__subheading">
                            Interactive walkthrough
                        </h3>
                        <p class="body-copy--greyout">
                            See an overview of the issues related to the project.
                        </p>
                        <ow-button :disabled="disableEnterButton"
                                   class="content-container__grid--button"
                                   data-test="link-share-landing-walkthrough-button"
                                   is-primary
                                   @click="onWalkthroughClick">
                            Enter
                        </ow-button>
                    </div>
                </div>

                <div v-if="!isLinkViewable"
                     class="d-flex flex-column justify-center content-container__error"
                     data-test="link-share-landing-invalid">
                    <img v-if="hasOrganisationLogo"
                         :alt="metadata.organisationName"
                         :src="organisationLogoURL"
                         class="organisation-logo"
                         data-test="link-share-landing-organisation-logo"
                         @error="onImgError()" />

                    <h2 class="content__heading">
                        Oops!
                    </h2>
                    <p class="body-copy">
                        This link has been de-activated and/or no interactive sharing methods have been selected.
                    </p>
                    <p v-if="metadata.organisationId !== null"
                       class="body-copy">
                        Please contact someone from {{ metadata.organisationName }}
                    </p>
                </div>

                <footer align-center
                        class="d-flex justify-center align-center flex-row content-container__footer">
                    <span class="body-copy--greyout">
                        Powered by
                    </span>
                    <img alt="The Orbital Witness company logo"
                         class="content-container__footer--logo"
                         src="../media/ow-logo.png" />
                </footer>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions,
             mapMutations,
             mapState } from 'vuex'

    import MatterLinkShareApi from '@/api/matter-link-share.api'
    import SecurityService from '@/api/security'
    import OwPublicBackground from '@/components/core/backgrounds/ow-public-background.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import { StatusCode } from '@/consts/status-code'
    import { Route } from '@/enums/route.enum'
    import { LINK_SHARE_CLIENT_MUTATE_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { getAppUri,
             getOrganisationSharedLogoURL } from '@/utils/environment.utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'LinkShareLandingPage',

        components: {
            OwButton,
            OwPublicBackground,
        },

        data() {
            return {
                loadingMetadata: false,
                errorText: null,
                password: null,
                showPassword: false,
                metadata: {
                    isValid: false,
                    organisationId: null,
                    passwordProtected: false,
                    organisationName: null,
                    matterId: null,
                    isIspShare: false,
                    isWalkthroughShare: false,
                },
                organisationLogoURL: null,
                hasOrganisationLogo: true,
                isPasswordConfirmed: false,
                userCanAccessMatterWithoutLogout: false,
            }
        },

        computed: {

            ...mapState({
                isMatterLinkShareUser: state => state.linkShareClient.isMatterLinkShareUser,
            }),

            disableEnterButton() {
                return this.metadata.passwordProtected === true && isNullOrWhitespace(this.password) && !this.isPasswordConfirmed
            },

            hasWalkthrough() {
                return this.metadata.isWalkthroughShare
            },

            hasInteractiveSitePlan() {
                return this.metadata.isIspShare
            },

            isLinkViewable() {
                return Boolean(this.metadata.isValid && (this.hasWalkthrough || this.hasInteractiveSitePlan))
            },

            linkGuid() {
                return this.$route?.params?.linkGuid
            },
        },

        async created() {
            await this.loadMetaData()
            if (!this.metadata.passwordProtected) {
                this.isPasswordConfirmed = true
            }
            this.setIsSharedLinkView(true)
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapMutations('linkShareClient', {
                setIsSharedLinkView: LINK_SHARE_CLIENT_MUTATE_IS_SHARED_LINK_VIEW,
            }),

            async loadMetaData() {
                this.loadingMetadata = true

                // Retrieve metadata associated with the shareable link
                const response = await MatterLinkShareApi.getLinkSharePublicMetadata(this.linkGuid)
                if (response.ok === true) {
                    this.metadata = { ...response?.data }
                }
                if (this.metadata?.organisationId !== null) {
                    this.organisationLogoURL = getOrganisationSharedLogoURL(this.metadata.organisationId)
                }

                try {
                    this.userCanAccessMatterWithoutLogout =
                        await MatterLinkShareApi.getUserCanAccessMatterByIdAsync(this.linkGuid)
                } catch (error) {
                    if (error?.response.status === StatusCode.UNAUTHORIZED ||
                        error?.response.status === StatusCode.FORBIDDEN) {
                        this.userCanAccessMatterWithoutLogout = false
                    } else {
                        this.errorText = error?.message ?? 'Something went wrong'
                    }
                }

                this.loadingMetadata = false
            },

            onImgError() {
                this.hasOrganisationLogo = false
            },

            async onIspClick() {
                const returnUrl = `${ getAppUri() }/matters/${ this.metadata.matterId }/map`
                await this.logHeapEvent({
                    type: 'WALKTHROUGH - Landing ISP selected',
                    metadata: {
                        matterId: this.metadata.matterId,
                        isMatterShareLinkUser: this.isMatterLinkShareUser,
                    },
                })
                await this.clickHandler(returnUrl, Route.MatterMap)
            },

            async onWalkthroughClick() {
                const returnUrl = `${ getAppUri() }/matters/${ this.metadata.matterId }/map/walkthrough`
                await this.logHeapEvent({
                    type: 'WALKTHROUGH - Landing walkthrough selected',
                    metadata: {
                        matterId: this.metadata.matterId,
                        isMatterShareLinkUser: this.isMatterLinkShareUser,
                    },
                })
                await this.clickHandler(returnUrl, Route.MatterMapWalkthrough)
            },

            async clickHandler(returnUrl, routeName) {
                const getMagicLinkResponse =
                    await MatterLinkShareApi.getMatterLinkShareQuickLoginURL(
                        this.linkGuid,
                        this.password,
                        returnUrl)
                const magicUrl = getMagicLinkResponse.data

                if (isNullOrWhitespace(magicUrl)) {
                    this.errorText = 'Please check the details and try again.'
                } else {
                    if (this.userCanAccessMatterWithoutLogout) {
                        await this.$router.push({
                            name: routeName,
                            params: {
                                matterId: this.metadata.matterId,
                            },
                        })
                    } else {
                        // Log out of current session, and log in with the link-share-user associated with the shareable link.
                        await SecurityService.removeUser()
                        window.location.replace(magicUrl)
                    }
                    this.isPasswordConfirmed = true
                    this.errorText = null
                }
            },

        },
    }
</script>

<style lang="scss">
@import './link-share-landing.scss'
</style>
