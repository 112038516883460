<template>
    <li class="ownership-corporate-owner"
        data-test="ownership-corporate-owner">
        <p v-if="index > -1"
           class="owner-index-banner label-caps-small">
            {{ t('titlePanel.cards.ownership.labels.owner') }} {{ index + 1 }}
        </p>

        <div class="ownership-corporate-owner__data">
            <div class="ownership-corporate-owner__split-container">
                <dl>
                    <template v-if="hasOwnerName">
                        <dt v-t="'titlePanel.cards.ownership.labels.owner'"
                            class="label-caps-smallest" />
                        <dd class="body-highlight owner-name">
                            <ow-loading-skeleton v-if="isLoading"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="owner.name"
                                         class="body-highlight text-icon-field"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate company name - hmlr">
                                {{ owner.name }}


                                <span v-if="!isOcodCcodMatched">
                                    <v-icon class="ownership-corporate-owner__match-icon"
                                            color="warning"
                                            data-test="title-summary-ocod-ccod-mismatch-icon"
                                            size="medium">
                                        $warning
                                    </v-icon>
                                    <ow-tooltip :position="OwTooltipPosition.Bottom"
                                                activator="parent">
                                        <span v-t="'titlePanel.cards.ownership.flags.ccodOcodMismatch'" />
                                    </ow-tooltip>
                                </span>
                            </OwClipboard>
                        </dd>
                    </template>

                    <template v-if="hasCompanyRegNumber">
                        <dt v-t="'titlePanel.cards.ownership.labels.regNo'"
                            class="label-caps-smallest" />
                        <dd class="body-regular-small">
                            <ow-loading-skeleton v-if="isLoading"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="owner.companyRegNumber"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate company reg no. - hmlr">
                                {{ owner.companyRegNumber }}
                            </OwClipboard>
                        </dd>
                    </template>

                    <template v-if="hasProprietorship">
                        <dt v-t="'titlePanel.cards.ownership.labels.proprietorship'"
                            class="label-caps-smallest" />
                        <dd class="body-regular-small">
                            <ow-loading-skeleton v-if="isLoading"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="owner.proprietorship"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate company proprietorship - hmlr">
                                {{ owner.proprietorship }}
                            </OwClipboard>
                        </dd>
                    </template>

                    <template v-if="hasCorrespondenceAddress && isExpanded">
                        <dt v-t="'titlePanel.cards.ownership.labels.ownerAddress'"
                            class="label-caps-smallest" />
                        <dd class="body-regular-small">
                            <ow-loading-skeleton v-if="isLoading"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="owner.correspondanceAddress"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate correspondence address - hmlr">
                                {{ owner.correspondanceAddress }}
                            </OwClipboard>
                        </dd>
                    </template>


                    <template v-if="hasDateAdded && isExpanded">
                        <dt v-t="'titlePanel.cards.ownership.labels.dateAdded'"
                            class="label-caps-smallest" />
                        <dd class="body-regular-small">
                            <ow-loading-skeleton v-if="isLoading"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="owner.dateAdded"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate date added - hmlr">
                                {{ owner.dateAdded }}
                            </OwClipboard>
                        </dd>
                    </template>

                    <template v-if="hasCountryIncorporated && isExpanded">
                        <dt v-t="'titlePanel.cards.ownership.labels.incorporated'"
                            class="label-caps-smallest" />
                        <dd class="body-regular-small">
                            <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="owner.countryIncorporated"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate country incorporated - hmlr">
                                {{ owner.countryIncorporated }}
                            </OwClipboard>
                        </dd>
                    </template>
                </dl>
            </div>

            <div class="ownership-corporate-owner__split-container ownership-corporate-owner__split-container--bordered">
                <div v-if="owner.isOverseas"
                     class="overseas">
                    <p class="owner-index-banner label-caps-small">
                        {{ t('titlePanel.cards.ownership.labels.foreignCompany') }}
                    </p>

                    <p v-if="!showCompaniesHouseData"
                       class="text-icon-field">
                        <span v-t="'titlePanel.cards.ownership.labels.companyRegCannotBeMatched'"
                              class="body-italic-x-small" />
                        <v-icon class="ownership-corporate-owner__match-icon"
                                color="warning"
                                data-test="title-summary-foreign-company-icon"
                                size="medium">
                            $warning
                        </v-icon>
                    </p>
                </div>

                <template v-if="showCompaniesHouseData">
                    <dl>
                        <dt v-t="'titlePanel.cards.ownership.labels.companiesHouseOwner'"
                            class="label-caps-smallest" />
                        <dd>
                            <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="companiesHouseProfile?.companyName"
                                         class="body-highlight text-icon-field"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate company name">
                                {{ companiesHouseProfile?.companyName }}

                                <span v-if="isOwnerMismatched">
                                    <v-icon class="ownership-corporate-owner__match-icon"
                                            color="warning"
                                            data-test="title-summary-companies-house-mismatch-icon"
                                            size="medium">
                                        $warning
                                    </v-icon>
                                    <ow-tooltip :position="OwTooltipPosition.Bottom"
                                                activator="parent">
                                        <span v-t="'titlePanel.cards.ownership.labels.companiesHouseDoesntMatchSourceName'" />
                                    </ow-tooltip>
                                </span>

                                <span v-else>
                                    <v-icon class="ownership-corporate-owner__match-icon"
                                            color="success"
                                            data-test="title-summary-companies-house-match-icon"
                                            size="medium">
                                        $success-circle
                                    </v-icon>
                                    <ow-tooltip :position="OwTooltipPosition.Bottom"
                                                activator="parent">
                                        <span v-t="'titlePanel.cards.ownership.labels.companiesHouseMatchesSourceName'" />
                                    </ow-tooltip>
                                </span>
                            </OwClipboard>
                        </dd>

                        <dt v-t="'titlePanel.cards.ownership.labels.regNo'"
                            class="label-caps-smallest" />
                        <dd>
                            <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                                 data-test="title-details-panel-loading"
                                                 height="24px"
                                                 width="100%" />
                            <OwClipboard v-else
                                         :text="formatCompanyRegNumber(companyRegNo)"
                                         data-track="TITLE-DETAILS-PANEL - Copy corporate owner reg no.">
                                <a :href="getCompaniesHouseLink(companyRegNo)"
                                   class="body-highlight"
                                   target="_blank">
                                    {{ formatCompanyRegNumber(companyRegNo) }}
                                </a>
                            </OwClipboard>
                        </dd>

                        <dt v-t="'titlePanel.cards.ownership.labels.companyStatus'"
                            class="label-caps-smallest" />
                        <dd class="body-regular-small">
                            <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                                 height="24px"
                                                 width="100%" />
                            <span v-else>
                                <ow-colored-label v-if="isCompanyActive === 'success'"
                                                  theme="success">
                                    {{ companiesHouseProfile?.companyStatus }}
                                </ow-colored-label>
                                <ow-colored-label v-else
                                                  theme="warning">
                                    {{ companiesHouseProfile?.companyStatus }}
                                </ow-colored-label>
                            </span>
                        </dd>

                        <template v-if="isExpanded">
                            <dt v-t="'titlePanel.cards.ownership.labels.companyRegisteredAddress'"
                                class="label-caps-smallest" />
                            <dd class="body-regular-small">
                                <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                                     data-test="title-details-panel-loading"
                                                     height="24px"
                                                     width="100%" />
                                <OwClipboard v-else
                                             :text="companiesHouseProfile?.displayAddress"
                                             data-track="TITLE-DETAILS-PANEL - Copy corporate registered office address">
                                    {{ companiesHouseProfile?.displayAddress }}
                                </OwClipboard>
                            </dd>

                            <dt v-t="'titlePanel.cards.ownership.labels.companyNatureOfBusiness'"
                                class="label-caps-smallest" />
                            <dd class="body-regular-small">
                                <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                                     data-test="title-details-panel-loading"
                                                     height="24px"
                                                     width="100%" />
                                <OwClipboard v-else
                                             :text="companiesHouseProfile?.displayNatureOfBusiness"
                                             data-track="TITLE-DETAILS-PANEL - Copy corporate nature of business">
                                    {{ companiesHouseProfile?.displayNatureOfBusiness }}
                                </OwClipboard>
                            </dd>
                        </template>
                    </dl>
                </template>

                <template v-else-if="owner.isOverseas">
                    <div class="overseas">
                        <p class="owner-index-banner label-caps-small">
                            {{ t('titlePanel.cards.ownership.labels.overseasEntity') }}
                        </p>
                        <template v-if="!hasOverseasRegNumber">
                            <p class="text-icon-field py-0 my-0">
                                <span v-t="'titlePanel.cards.ownership.labels.overseasEntityNotFoundOnCH'"
                                      class="body-italic-x-small" />
                                <v-icon class="ownership-corporate-owner__match-icon"
                                        color="warning"
                                        data-test="title-summary-foreign-company-icon"
                                        size="medium">
                                    $warning
                                </v-icon>
                            </p>
                            <p>
                                <a :href="getCompaniesHouseLink(null)"
                                   class="body-regular-small"
                                   data-track="TITLE-DETAILS-PANEL - Search for overseas entity on companies house"
                                   target="_blank">
                                    {{ $t('companiesHouse.ownerText.searchForOverseasEntity') }}
                                </a>
                            </p>
                        </template>
                        <template v-else>
                            <dl class="pl-2">
                                <dt v-t="'titlePanel.cards.ownership.labels.companyStatus'"
                                    class="label-caps-smallest" />
                                <dd class="body-regular-small">
                                    <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                                         height="24px"
                                                         width="100%" />
                                    <span v-else
                                          class="text-icon-field">
                                        <a :href="getCompaniesHouseLink(owner.overseasRegistrationNumber)"
                                           class="body-regular-small"
                                           data-track="TITLE-DETAILS-PANEL - Search for overseas entity on companies house"
                                           target="_blank">
                                            {{
                                                $t('companiesHouse.buttons.link', { companyRegNumber: formatCompanyRegNumber(owner.overseasRegistrationNumber) })
                                            }}
                                        </a>
                                        <v-icon class="ownership-corporate-owner__match-icon"
                                                color="warning"
                                                data-test="title-summary-foreign-company-icon"
                                                size="medium">
                                            $warning
                                        </v-icon>
                                    </span>
                                </dd>
                            </dl>
                        </template>
                    </div>
                </template>

                <template v-else>
                    <div class="overseas">
                        <ow-loading-skeleton v-if="isLoadingCompaniesHouseData"
                                             height="24px"
                                             width="100%" />

                        <p v-if="!isLoadingCompaniesHouseData"
                           class="text-icon-field">
                            <span v-t="'titlePanel.cards.ownership.labels.companyRegCannotBeMatched'"
                                  class="body-italic-x-small" />
                            <v-icon class="ownership-corporate-owner__match-icon"
                                    color="warning"
                                    data-test="title-summary-foreign-company-icon"
                                    size="medium">
                                $warning
                            </v-icon>
                        </p>
                        <p v-if="!isLoadingCompaniesHouseData">
                            <a :href="getCompaniesHouseLink(null)"
                               class="body-regular-small"
                               data-track="TITLE-DETAILS-PANEL - Go to companies house"
                               target="_blank">
                                {{
                                    $t('titlePanel.cards.ownership.labels.gotoCompaniesHouse')
                                }}
                            </a>
                        </p>
                    </div>
                </template>
            </div>
        </div>

        <div class="ownership-corporate-owner__expander">
            <a class="ownership-corporate-owner__expander__link body-regular-small"
               href="#"
               @click.prevent="isExpanded = !isExpanded">
                <span v-if="isExpanded"
                      v-t="'buttons.showLess'"
                      class="text-no-wrap mr-3" />
                <span v-else
                      v-t="'buttons.showMore'"
                      class="text-no-wrap mr-3" />
            </a>
        </div>
    </li>
</template>


<script lang="ts" setup>
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { ICompaniesHouseData } from '@/interfaces/title/companies-house.interface'
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'
    import {
        isNullOrWhitespace,
        pad,
    } from '@/utils/string-utils'

    const { t } = useI18n()

    const props = defineProps({
        owner: {
            type: Object as PropType<any>,
            required: true,
        },
        index: Number,
        companiesHouseProfile: Object as PropType<ICompaniesHouseData>,
        isLoading: Boolean,
        isLoadingCompaniesHouseData: Boolean,
        showCompaniesHouseData: Boolean,
        isOwnerMismatched: Boolean,
        isOcodCcodMatched: Boolean,
    })

    const hasOwnerName = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner.name))
    const hasCorrespondenceAddress = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner?.correspondanceAddress))
    const hasProprietorship = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner?.proprietorship))
    const hasCountryIncorporated = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner?.countryIncorporated))
    const hasDateAdded = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner?.dateAdded))
    const hasCompanyRegNumber = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner?.companyRegNumber))
    const hasOverseasRegNumber = computed<boolean>((): boolean => !isNullOrWhitespace(props.owner?.overseasRegistrationNumber))

    const isExpanded = ref<boolean>(false)
    const companyRegNo = computed<string>(() => props.owner?.companyRegNumber)
    const isCompanyActive = computed<string>((): string => {
        return props.companiesHouseProfile?.isActive ? 'success' : 'warning'
    })

    const formatCompanyRegNumber = (companyRegNumber: string): string => {
        return pad(companyRegNumber, 8, '0')
    }

    const getCompaniesHouseLink = (number: number): string => {
        return getCompaniesHouseLinkFromCompanyRegistrationNumber(number)
    }
</script>
<style lang="scss" scoped>
    @import './ownership-corporate-owner.scss';
</style>
