export const titleNumberSearchResult = {
    query: 'TGL50538',
    titleNumbers: [
        {
            number: 'TGL50538',
            tenure: 'Freehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[532365.759,175901.3],[532365.759,176179.75],[532670.8,176179.75],[532670.8,175901.3],[532365.759,175901.3]]]}',
        },
        {
            number: 'TGL505380',
            tenure: 'Leasehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[533589.141,179213.33],[533589.141,179261.239],[533618.828,179261.239],[533618.828,179213.33],[533589.141,179213.33]]]}',
        },
        {
            number: 'TGL505381',
            tenure: 'Leasehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[532333.008,178339.517],[532333.008,178400.705],[532365.666,178400.705],[532365.666,178339.517],[532333.008,178339.517]]]}',
        },
        {
            number: 'TGL505382',
            tenure: 'Leasehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[533589.14,179213.33],[533589.14,179261.24],[533618.82,179261.24],[533618.82,179213.33],[533589.14,179213.33]]]}',
        },
        {
            number: 'TGL505383',
            tenure: 'Leasehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[544513.039,183659.686],[544513.039,183729.859],[544583.226,183729.859],[544583.226,183659.686],[544513.039,183659.686]]]}',
        },
        {
            number: 'TGL505384',
            tenure: 'Freehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[544869.632,173528.042],[544869.632,173543.587],[544890.436,173543.587],[544890.436,173528.042],[544869.632,173528.042]]]}',
        },
        {
            number: 'TGL505385',
            tenure: 'Leasehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[528211.6,172975.9],[528211.6,173024.85],[528249.55,173024.85],[528249.55,172975.9],[528211.6,172975.9]]]}',
        },
        {
            number: 'TGL505386',
            tenure: 'Freehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[544833.022,173545.899],[544833.022,173561.196],[544858.991,173561.196],[544858.991,173545.899],[544833.022,173545.899]]]}',
        },
        {
            number: 'TGL505387',
            tenure: 'Leasehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[512650.9,169799.24],[512650.9,169823.06],[512668.91,169823.06],[512668.91,169799.24],[512650.9,169799.24]]]}',
        },
        {
            number: 'TGL505388',
            tenure: 'Freehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[544838.363,173559.65],[544838.363,173588.477],[544871.643,173588.477],[544871.643,173559.65],[544838.363,173559.65]]]}',
        },
        {
            number: 'TGL505389',
            tenure: 'Leasehold title (Absolute)',
            estateInterest: 'EL',
            registeredStatus: '',
            bbox: '{"type":"Polygon","coordinates":[[[530544.306,175295.15],[530544.306,175316.06],[530556.6,175316.06],[530556.6,175295.15],[530544.306,175295.15]]]}',
        },
    ],
    errorMessage: null,
    requested: '2021-12-20T10:51:13.8078479+00:00',
    timeTakenMS: 52,
    fromCache: true,
    ok: true,
}
