<template>
    <div class="uploaded-documents">
        <div class="uploaded-documents--label">
            <ow-colored-label class="accents-small"
                              is-beta-label
                              is-extra-small />
            <span v-t="'documents.upload.betaLabel'"
                  class="accents-italic" />
        </div>
        <uploaded-documents-summary :filtered-documents-count="0"
                                    :is-loading="isLoading"
                                    :documents="documents"
                                    :selected-documents-count="selectedReportTitleRegisters.length"
                                    @refresh-documents="$emit('refresh-documents')"
                                    @see-reporting-options-clicked="handleReportClicked" />

        <uploaded-documents-table :current-matter-id="currentMatterId"
                                  :documents="documents"
                                  :is-downloading="isDownloading"
                                  @download="$emit('download', $event)"
                                  @view="$emit('view', $event)"
                                  @selected-documents-changed="setSelectedDocuments"
                                  @title-number-selected="redirectToTitleNumber" />

        <title-panel-v2 v-if="selectedTitleNumber && isTitlePanelV2"
                        :is-pinned="isPinned"
                        :refresh-tam-on-register-order="true"
                        :selected-title-number="selectedTitleNumber"
                        :show-add-to-matter-action="false"
                        :show-title-visibility-action="false"
                        :matter-id="currentMatter.id"
                        class="document-library__title-details-panel-v2"
                        @clear-titles="clearSelectedTitle"
                        @title-number-selected="redirectToTitleNumber" />
        <title-details-panel v-if="selectedTitleNumber && !isTitlePanelV2"
                             :is-pinned="isPinned"
                             :selected-title-number="selectedTitleNumber"
                             :show-add-to-matter-action="false"
                             :show-title-visibility-action="false"
                             class="document-library__title-details-panel"
                             @clear-titles="clearSelectedTitle"
                             @title-number-selected="redirectToTitleNumber" />
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapState,
    } from 'vuex'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import UploadedDocumentsSummary from '@/components/documents/library/uploaded-documents/uploaded-documents-summary.vue'
    import UploadedDocumentsTable from '@/components/documents/library/uploaded-documents/uploaded-documents-table.vue'
    import TitleDetailsPanel from '@/components/title-panel/title-panel.vue'
    import TitlePanelV2 from "@/components/title-panel/v2/title-panel.vue"
    import { Route } from '@/enums/route.enum'
    import { uploadDocumentSuccessResponses } from '@/enums/uploaded-document-status.enum'
    import { checkFlag } from "@/feature-flags"
    import { IState } from "@/interfaces/store/state.interface"
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import { MATTER_HAS_TITLE_NUMBER } from '@/store/modules/matter/types'
    import { TITLE_CLEAR,
             TITLE_LOOKUP_TITLE } from '@/store/modules/titles/types'

    const EVENTS = {
        download: 'download',
        view: 'view',
        refresh: 'refresh-documents',
        selectDocuments: 'select-documents',
    }

    export default {
        name: 'UploadedDocuments',

        components: {
            TitlePanelV2,
            OwColoredLabel,
            UploadedDocumentsTable,
            UploadedDocumentsSummary,
            TitleDetailsPanel,
        },

        props: {
            currentMatterId: {
                type: [String, Number],
                required: true,
            },
            documents: {
                type: Array as PropType<Array<IUploadedDocument>>,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
            isDownloading: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                selectedDocuments: [],
                selectedTitleNumber: null,
                tabHash: '#uploaded',
            }
        },

        computed: {
            ...mapState({
                currentMatter: (state: IState) => state.matter?.currentMatter,
            }),

            ...mapGetters({
                matterHasTitle: MATTER_HAS_TITLE_NUMBER,
            }),

            routeName() {
                return this.$route.name
            },

            isPinned() {
                return this.matterHasTitle(this.selectedTitleNumber)
            },

            isTitlePanelV2() {
                return checkFlag('title-panel-v-2', false)
            },

            selectedReportTitles() {
                const reportTitles =
                    new Set(this.selectedReportTitleRegisters
                        .map(document => document.titleNumber))

                return Array.from(reportTitles)
            },

            selectedReportTitleRegisters() {
                return this.selectedDocuments.filter(document => document.type === 'TitleRegister' &&
                    document.titleNumber != null &&
                    uploadDocumentSuccessResponses.includes(document.status))
            },
        },

        watch: {
            routeName(newRouteName, oldRouteName) {
                if (newRouteName !== oldRouteName || this.selectedTitleNumber !== this.$route?.params?.titleNumber) {
                    const onThisTab = this.tabHash == this.$route.hash
                    if (!onThisTab) {
                        this.selectedTitleNumber = null
                        return
                    }
                    switch (newRouteName) {
                        case Route.DocumentsLibraryTitleDetails:
                            this.showTitleDetailsPanel(this.$route?.params?.titleNumber)
                            break

                        case Route.DocumentsLibrary:
                            this.clearSelectedTitle()
                            break
                    }
                }
            },
        },

        mounted() {
            this.showTitleDetailsPanel(this.$route.params.titleNumber)
        },

        methods: {

            ...mapActions({
                clearTitle: TITLE_CLEAR,
                lookupTitle: TITLE_LOOKUP_TITLE,
            }),

            setSelectedDocuments(val) {
                this.selectedDocuments = val
                this.$emit(EVENTS.selectDocuments, val)
            },

            clearSelectedTitle() {
                this.clearTitle()
                this.selectedTitleNumber = null
                this.$router.push({
                    name: Route.DocumentsLibrary,
                    params: {
                        matterId: this.currentMatterId,
                    },
                    hash: this.tabHash,
                })
            },

            redirectToTitleNumber(titleNumber) {
                if (titleNumber !== this.selectedTitleNumber) {
                    this.$router.push({
                        name: Route.DocumentsLibraryTitleDetails,
                        params: {
                            id: this.currentMatterId,
                            titleNumber,
                        },
                        hash: this.tabHash,
                    })
                }
            },

            showTitleDetailsPanel(titleNumber) {
                if (!titleNumber) {
                    return
                }

                if (titleNumber !== this.selectedTitleNumber ||
                    !this.selectedTitleNumber?.record) {
                    this.lookupTitle(titleNumber)
                }

                this.selectedTitleNumber = titleNumber
            },
            async handleReportClicked() {
                await this.$router.push({
                    name: Route.MatterReports,
                    params: {
                        matterId: this.currentMatterId,
                    },
                    query: {
                        selectedTitleNumbers: this.selectedReportTitles,
                        origin: 'DOCS',
                    },
                })
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './uploaded-documents';
</style>
