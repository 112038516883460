import qs from 'qs'

import { StatusCode } from '@/consts/status-code'

import httpClient from './http-client'

export default class OrganisationApi {
    static END_POINT = 'organisation'

    /**
     * Create a new organisation
     * @static
     * @param data {Object}
     * @returns {Promise<T | *>}
     */
    static create(data) {
        const endpoint = `${ this.END_POINT }/create`
        return httpClient
            .post(endpoint, qs.stringify(data))
            .then(response => {
                if (response.status === StatusCode.SUCCESS && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Update an existing organisation
     * @static
     * @param data {Object}
     * @returns {Promise<T | *>}
     */
    static update(data) {
        const endpoint = `${ this.END_POINT }/update`
        return httpClient
            .post(endpoint, qs.stringify(data))
            .then(response => {
                if (response.status === StatusCode.SUCCESS && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Get the list of organisations from the server.
     * @static
     * @returns {Promise<T | *>}
     */
    static get() {
        const endpoint = `${ this.END_POINT }/list`
        return httpClient
            .get(endpoint)
            .then(response => response.data)
    }

    /**
     * Returns a list of all available products that can be assigned to an organisation
     * @returns {Promise<T | *>}
     */
    static getProducts() {
        const endpoint = `${ this.END_POINT }/products`
        return httpClient
            .get(endpoint)
            .then(response => response.data)
    }

    /**
     * Get the list of organisations that the user has access to.
     * The summary just includes id, name and sections.
     * @returns {Promise<T | *>}
     */
    static getSummaryOrganisations() {
        const endpoint = `${ this.END_POINT }/list/summary`
        return httpClient.get(endpoint)
    }

    /**
     * Add a section to an existing organisation
     * @static
     * @param data {Object}
     * @returns {Promise<T | *>}
     */
    static addOrganisationSection(data) {
        const endpoint = `${ this.END_POINT }/section/add`
        return httpClient
            .post(endpoint, qs.stringify(data))
            .then(response => {
                if (response.status === StatusCode.SUCCESS && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Update a section in an existing organisation
     * @static
     * @param data {Object}
     * @returns data as a json object
     */
    static updateOrganisationSection(data) {
        const endpoint = `${ this.END_POINT }/section/update`
        return httpClient
            .post(endpoint, qs.stringify(data))
            .then(response => {
                if (response.status === StatusCode.SUCCESS && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Delete a section from an organisation
     * @static
     * @param data {Object}
     * @returns {Promise<T | *>}
     */
    static deleteOrganisationSection(data) {
        const endpoint = `${ this.END_POINT }/section/delete`
        return httpClient
            .post(endpoint, qs.stringify(data))
            .then(response => {
                if (response.status === StatusCode.SUCCESS && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Get the profile of organisations from the server.
     * @static
     * @returns {Promise<T | *>}
     */
    static getProfile() {
        const endpoint = `${ this.END_POINT }/profile`
        return httpClient
            .get(endpoint)
            .then(response => response.data)
    }

    /**
     * Adds the email to receive the nightly emails for charges incurred
     * @static
     * @param data {Object}
     * @returns {Promise<T | *>}
     */
    static addEmailForNightlyChargesDigest(data) {
        const endpoint = `${ this.END_POINT }/emailtest`
        return httpClient
            .post(endpoint, qs.stringify(data))
            .then(response => {
                if (response.status === StatusCode.SUCCESS) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Get users for the current organisation
     * @static
     * @returns {Promise<T | *>}
     */
    static getOrganisationUsers() {
        const endpoint = `${ this.END_POINT }/user/list`
        return httpClient
            .get(endpoint)
            .then(response => {
                if (response.status === StatusCode.SUCCESS) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Get summary information for all users in the current organisation - all users can access this endpoint
     * @static
     * @returns {Promise<T | *>}
     */
    static getOrganisationUserListSummary() {
        const endpoint = `${ this.END_POINT }/user/list/summary`
        return httpClient
            .get(endpoint)
            .then(response => {
                if (response.status === StatusCode.SUCCESS) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Create a new user in the current organisation
     * @static
     * @param user {Object}
     * @returns {Promise<T | *>}
     */
    static createOrganisationUser(user) {
        const endpoint = `${ this.END_POINT }/user/create`
        return httpClient
            .post(endpoint, qs.stringify({ model: user }))
            .then(response => {
                if (response.status === StatusCode.SUCCESS && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Update an user in the current organisation
     * @static
     * @param user {Object}
     * @returns {Promise<T | *>}
     */
    static updateOrganisationUser(user) {
        const endpoint = `${ this.END_POINT }/user/update`
        return httpClient
            .post(endpoint, qs.stringify({ model: user }))
            .then(response => {
                // Only get `true` back from the server for this request, so check that here and return a standard response
                if (response.status === StatusCode.SUCCESS && response.data === true) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }

    /**
     * Get the charges for the current organisation
     * This can optionally be done for a given time period
     * @static
     * @param charge {Object}
     * @example
     *  The charge object is:
     *      {
     *          to: '',
     *          from: '',
     *      }
     * @returns {Promise<T | *>}
     */
    static getCharges(charge) {
        const endpoint = `${ this.END_POINT }/charges`
        return httpClient
            .post(endpoint, charge)
            .then(response => {
                if (response.status === StatusCode.SUCCESS) {
                    return {
                        ...response,
                        ok: true,
                    }
                }

                return {
                    ...response,
                    ok: false,
                }
            })
    }
}
