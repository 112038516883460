<template>
    <!-- "input" event gets emitted when v-dialog "value" changes
     that's why we have to use "input" and "value", reserved keyword we cannot change -->
    <ow-dialog :model-value="modelValue"
               :title="title"
               :subtitle="subtitle"
               @update:model-value="onInput">
        <div class="app-prevent-transition-dialog__content"
             data-test="app-prevent-transition-dialog-content">
            {{ contentText }}
        </div>
        <div class="app-prevent-transition-dialog__actions">
            <v-btn class="app-prevent-transition-dialog__button-cancel"
                   size="large"
                   data-test="app-prevent-transition-dialog-button-cancel"
                   variant="outlined"
                   @click="closeDialog">
                Cancel
            </v-btn>
            <div class="app-prevent-transition-dialog__actions-wrapper">
                <v-btn v-if="!isSimple"
                       class="app-prevent-transition-dialog__button-close"
                       size="large"
                       variant="text"
                       data-test="app-prevent-transition-dialog-button-close"
                       @click="close">
                    Discard changes
                </v-btn>
                <v-btn size="large"
                       color="primary"
                       :loading="!isSimple && isSubmitting"
                       data-test="app-prevent-transition-dialog-button-submit"
                       @click="submit">
                    Save changes
                </v-btn>
            </div>
        </div>
    </ow-dialog>
</template>

<script lang="ts">
    import owDialog from '@/components/core/ow-dialog.vue'

    export default {
        components: {
            owDialog,
        },

        props: {
            title: {
                type: String,
                required: false,
                default: 'You have unsaved changes.',
            },
            subtitle: {
                type: String,
                required: false,
                default: '',
            },
            contentText: {
                type: String,
                required: false,
                default: 'You can save your changes, discard your changes, or cancel to continue editing.',
            },
            isSimple: {
                type: Boolean,
                required: false,
            },
            modelValue: {
                type: Boolean,
                required: false,
            },
        },

        emits: ['update:modelValue', 'submit', 'close'],

        data() {
            return {
                isSubmitting: false,
            }
        },

        methods: {
            close(): void {
                this.$emit('close')
                this.closeDialog()
            },

            submit() : void{
                this.isSubmitting = true
                this.$emit('submit', () => {
                    this.isSubmitting = false
                    this.closeDialog()
                })
            },

            // "input" event gets emitted when v-dialog "value" changes
            // that's why we have to use "input" and "value", reserved keyword we cannot change
            onInput(event: any): void {
                this.$emit('update:modelValue', event)
            },

            closeDialog(): void {
                this.$emit('update:modelValue', false)
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-prevent-transition-dialog';
</style>
