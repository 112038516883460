import { HubConnection } from '@microsoft/signalr'

import {
    ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE,
    ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE_UPDATE,
    ORGANISATION_HUB_MUTATE_ERROR,
    ORGANISATION_HUB_MUTATE_HUB,
    ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED,
    ORGANISATION_HUB_MUTATE_MESSAGE,
    ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLE,
    ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER,
} from '@/store/modules/organisation-hub/types'
import {
    TrackedTitle,
    TrackedTitleListener,
    TrackTitleRequest,
} from '@/interfaces/store/organisation-hub/tracked-title'
import { IOrganisationHubMessage } from '@/interfaces/store/organisation-hub/organisation-hub-message.interface'
import { IOrganisationHubState } from '@/interfaces/store/organisation-hub/organisation-hub-state.interface'
import { isNullOrEmpty } from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export default {
    [ORGANISATION_HUB_MUTATE_HUB]: (state: IOrganisationHubState, hub: HubConnection) => {
        state.hub = hub
    },

    [ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED]: (state: IOrganisationHubState, isSubscribed: boolean) => {
        state.isSubscribed = isSubscribed
    },

    [ORGANISATION_HUB_MUTATE_ERROR]: (state: IOrganisationHubState, error: string) => {
        state.error = error
    },

    [ORGANISATION_HUB_MUTATE_MESSAGE]: (state: IOrganisationHubState, message: IOrganisationHubMessage) => {
        state.messages.push(message)
    },

    /**
     * Add a tracked title to the list of tracked titles.
     */
    [ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE]: (state: IOrganisationHubState, request: TrackTitleRequest) => {
        if (isNullOrWhitespace(request.titleNumber)) {
            return
        }

        const existing = state.trackedTitles.find(trackedTitle => trackedTitle.titleNumber === request.titleNumber)
        if (existing && !existing.listeners.includes(request.listener)) {
            existing.listeners.push(request.listener)
        } else if (!existing) {
            state.trackedTitles.push(new TrackedTitle(request.titleNumber, request.listener))
        }
    },

    /**
     * Remove a tracked title from the list of tracked titles.
     */
    [ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLE]: (state: IOrganisationHubState, request: TrackTitleRequest) => {
        const existingIndex = state.trackedTitles.findIndex(trackedTitle => trackedTitle.titleNumber === request.titleNumber)
        if (existingIndex > -1) {
            const existingItem = state.trackedTitles[existingIndex]
            if (existingItem.listeners.length > 1) {
                existingItem.listeners = existingItem.listeners.filter(x => x !== request.listener)
            } else {
                state.trackedTitles.splice(existingIndex, 1)
            }
        }
    },

    [ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE_UPDATE]: (state: IOrganisationHubState, update: IOrganisationHubMessage) => {
        // Store the message if it's relevant to the user i.e. it's related to a title we are tracking.
        state.trackedTitles
            .filter(t => t.titleNumber === update?.message?.titleNumber)
            .forEach(trackedTitle => {
                trackedTitle.updates.push(update)
            })
    },

    [ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER]: (state: IOrganisationHubState, listener: TrackedTitleListener) => {
        state.trackedTitles.forEach(x => {
            x.listeners = x.listeners.filter(f => f !== listener)
        })
        state.trackedTitles = state.trackedTitles.filter(x => !isNullOrEmpty(x.listeners))
    },
}
