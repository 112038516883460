<template>
    <div class="organisation-sections-list">
        <table class="organisation-sections-list__table"
               data-test="organisation-section-list-table">
            <thead>
                <tr>
                    <td>{{ $t('form.label.name') }}</td>
                    <td>{{ $t('form.label.address') }}</td>
                    <td col="actions" />
                </tr>
            </thead>
            <tbody>
                <tr v-for="(section, index) in sections"
                    :key="index"
                    :data-test="`organisation-sections-list-item-${ index }`">
                    <td :data-test="`organisation-sections-list-item-${ index }-name`"
                        class="section-list-row">
                        {{ section.name }}
                    </td>
                    <td :data-test="`organisation-sections-list-item-${ index }-address`"
                        class="section-list-row pr-2">
                        {{ section.addressHouseNumber }} {{ section.addressStreet }}
                    </td>
                    <td>
                        <v-btn icon
                               :data-test="`organisation-section-list-section-${ section.name }`"
                               @click="onEditClick(section)">
                            <v-icon>$edit</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        props: {
            sections: {
                type: Array,
                required: true,
            },
        },
        emits: ['on-edit-click'],

        methods: {
            onEditClick(section) {
                this.$emit('on-edit-click', section)
            },
        },
    }
</script>

<style lang="scss">
    @import './organisation-sections-list.scss';
</style>
