import {
    ADD_ITEMS_TO_CART,
    CHECKOUT_MUTATE_CART_ITEMS_ADD,
    CHECKOUT_MUTATE_CART_ITEMS_EMPTY,
    CLEAR_CART,
} from './types'
import {
    equalsIgnoreOrder,
    isNullOrEmpty,
} from '@/utils/array-utils'
import {
    ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE,
    ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER,
} from '@/store/modules/organisation-hub/types'
import {
    TrackedTitleListener,
    TrackTitleRequest,
} from '@/interfaces/store/organisation-hub/tracked-title'
import { CheckoutItemDocumentOrder } from '@/models/checkout/checkout-item-document-order.model'
import { CheckoutItemReportOrder } from '@/models/checkout/checkout-item-report-order.model'
import { CheckoutItemType } from '@/enums/checkout-item-type'
import { ICheckoutItemBase } from '@/interfaces/checkout/checkout-item-base.interface'

export default {
    /**
     * Empties the cart and removes any tracked titles
     * @param commit
     */
    [CLEAR_CART]({ commit }): void {
        commit(CHECKOUT_MUTATE_CART_ITEMS_EMPTY)
        commit(`organisationHub/${ ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER }`, TrackedTitleListener.Checkout, { root: true })
    },

    /**
     * Add a checkout item into the cart.
     * @param commit
     * @param items
     */
    [ADD_ITEMS_TO_CART]({ state, commit }, items: (ICheckoutItemBase)[]): void {
        if (isNullOrEmpty(items)) {
            return
        }

        const checkoutDocuments = state.items.filter(({ itemType }) => itemType === CheckoutItemType.Document)
        const checkoutReports = state.items.filter(({ itemType }) => itemType === CheckoutItemType.Report)

        items.forEach(item => {
            switch (item.itemType) {
                case CheckoutItemType.Document: {
                    if (!checkoutDocuments.find(({ displayName }) => displayName === item.displayName)) {
                        const documentOrder = item as CheckoutItemDocumentOrder

                        commit(CHECKOUT_MUTATE_CART_ITEMS_ADD, documentOrder)

                        const trackedOrderRequest = new TrackTitleRequest(documentOrder.title.titleNumber, TrackedTitleListener.Checkout)
                        commit(`organisationHub/${ ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE }`, trackedOrderRequest, { root: true })
                    }
                    break
                }
                case CheckoutItemType.Report: {
                    const reportOrder = item as CheckoutItemReportOrder

                    if (!checkoutReports.find(({ selectedTitleNumbers }) => equalsIgnoreOrder(selectedTitleNumbers, reportOrder.selectedTitleNumbers))) {
                        commit(CHECKOUT_MUTATE_CART_ITEMS_ADD, reportOrder)
                    }
                    break
                }
            }
        })
    },
}
