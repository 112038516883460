<template>
    <aside v-if="isCtaVisible"
           :class="{
               'small': isSmall,
               'no-outline': noOutline,
           }"
           class="cta-copilot"
           data-test="cta-copilot">
        <div class="cta-copilot__icon">
            <ow-icon-copilot-integration :width="isSmall ? '235px' : '210px'"
                                         height="100%" />
        </div>
        <a :href="copilotUrl"
           class="cta-copilot__content"
           :class="{
               'not-clickable': !isClickable,
           }"
           data-test="cta-copilot-link"
           data-track="Copilot CTA: Copilot link"
           target="_blank"
           @click="trackClick">
            <h3 class="headers-h1-page-title"
                data-test="cta-copilot-title">
                {{ title }}
            </h3>
            <p class="caption-regular"
               data-test="cta-copilot-description">
                {{ description }}
            </p>
        </a>
        <v-icon v-if="includeCloseButton"
                class="cta-copilot__action-btn"
                data-test="cta-copilot-close-btn"
                data-track="Copilot CTA: Hide the CTA"
                @click="disableCta">
            $close
        </v-icon>
        <div class="d-flex flex-row gc-3">
            <slot name="actions" />
        </div>
    </aside>
</template>

<script lang="ts" setup>
    import { useCopilot } from '@/components/copilot/use-copilot'
    import OwIconCopilotIntegration from '@/components/core/icons/ow-icon-copilot-integration.vue'
    import { CopilotReport } from '@/enums/copilot-report.enum'

    interface IProps {
        clientCode?: string,
        description?: string,
        documentIds?: Array<number>,
        documentType?: string,
        isClickable?: boolean,
        includeCloseButton?: boolean,
        isSmall?: boolean,
        matterId: number,
        matterCode: string,
        position: string,
        report?: string,
        titleNumber?: string,
        title: string,
        noOutline?: boolean,
    }

    const props = withDefaults(defineProps<IProps>(), {
        isClickable: false,
        isSmall: false,
        includeCloseButton: false,
        report: CopilotReport.LongFormLeaseReport,
    })

    const {
        copilotUrl,
        isCtaVisible,
        disableCta,
        trackClick,
    } = useCopilot({
        clientCode: props.clientCode,
        documentIds: props.documentIds,
        documentType: props.documentType,
        matterId: props.matterId,
        matterCode: props.matterCode,
        position: props.position,
        report: props.report,
        titleNumbers: [props.titleNumber],
    })
</script>

<style lang="scss" scoped>
@import './copilot-cta.scss';
</style>
