<template>
    <span class="upload-documents-button">
        <ow-button :data-test="buttonDataTestAttribute"
                   :data-track="buttonDataTrackAttribute"
                   :full-width="fullWidthButton"
                   :is-primary="isPrimary"
                   :is-secondary="!isPrimary"
                   :small="smallButton"
                   @click="setUploadModalVisible(true)">
            <span v-t="'documents.upload.uploadDocuments'" />
        </ow-button>

        <ow-file-uploader :accepted-file-types="[UploadFileTypes.PDF]"
                          :data-test-attribute="modalDataTestAttribute"
                          i18n-path-for-accepted-file-types-error="documents.upload.acceptedFileTypeErrorPdfOnly"
                          i18n-path-for-drop-zone-text="documents.upload.dropZoneTextPdfOnly"
                          i18n-path-for-tooltip-text="documents.upload.tooltipText"
                          i18n-path-for-text="documents.upload.instructions"
                          i18n-path-for-title="documents.upload.uploadDocuments"
                          multiple
                          @files="uploadFilesHandler" />
    </span>
</template>
<script lang="ts">
    import {
        mapActions,
        mapGetters,
        mapMutations,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwFileUploader from '@/components/core/ow-file-uploader.vue'
    import { IOwFileUploaderEvent } from '@/components/core/types/ow-file-uploader-event.interface'
    import { UploadFileTypes } from '@/enums/upload-file-types.enum'
    import {
        DOCUMENTS_UPLOAD_FILES,
        GET_UPLOAD_DOCUMENTS_DIALOG_STATE,
        SET_UPLOAD_DOCUMENTS_DIALOG_STATE,
    } from '@/store/modules/documents/documents-types'

    const EVENTS = {
        uploadStarted: 'upload-started',
    }

    export default {
        name: 'UploadDocumentsButton',

        components: {
            OwButton,
            OwFileUploader,
        },

        props: {
            buttonDataTestAttribute: {
                type: String,
                required: false,
                default: 'upload-documents-button',
            },
            buttonDataTrackAttribute: {
                type: String,
                required: false,
                default: 'UPLOAD-DOCUMENTS: Upload documents button',
            },
            fullWidthButton: {
                type: Boolean,
                required: false,
            },
            modalDataTestAttribute: {
                type: String,
                required: false,
                default: 'upload-documents-button-modal',
            },
            matterGroupId: {
                type: [String, Number],
                required: false,
            },
            isPrimary: {
                type: Boolean,
                required: false,
            },
            smallButton: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        data() {
            return {
                UploadFileTypes,
            }
        },

        methods: {
            ...mapActions({
                startFileUpload: DOCUMENTS_UPLOAD_FILES,
            }),

            ...mapMutations({
                setUploadModalVisible: SET_UPLOAD_DOCUMENTS_DIALOG_STATE,
            }),

            async uploadFilesHandler(filesToUpload: IOwFileUploaderEvent): Promise<void> {
                this.setUploadModalVisible(false)
                // Need a tick to remove the modal, else it gets stuck on the screen.
                // I think it's the teleport that makes it need this.
                await this.$nextTick()

                this.$emit(EVENTS.uploadStarted)
                await this.startFileUpload({
                    ...filesToUpload,
                    matterGroupId: this.matterGroupId,
                })
            },
        },
    }
</script>

<style lang="scss">
    @import './upload-documents-button';
</style>
