<template>
    <ul class="ow-step-flow"
        :class="{
            'ow-step-flow--small': small,
            'ow-step-flow--disabled': disabled,

        }">
        <li v-for="(step, index) in steps"
            :key="step.id"
            :data-test="`ow-step-flow-item-${step.id}`">
            <a href="#"
               :class="{ selected: currentStepId === step.id, 'ow-step-flow__item--disabled': isDisabled(step) }"
               class="ow-step-flow__item"
               @click.prevent="handleStepChange(step.id)">
                <span class="body-regular circled-number">
                    {{ index + 1 }}</span>
                <div class="ow-step-flow__item--content">
                    <h3 class="headers-h3-card-heading">
                        {{ step.title }}
                    </h3>
                    <p class="caption-regular">
                        {{ step.description }}
                    </p>
                </div>
            </a>
        </li>
    </ul>
</template>

<script setup lang="ts">
    import { PropType } from 'vue'

    import { IOwStepFlowItem } from '@/interfaces/core-components/ow-step-flow.interface'

    defineProps({
        steps: {
            type: Array as PropType<Array<IOwStepFlowItem>>,
            required: true,
        },
        currentStepId: {
            type: Number,
            required: false,
            default: 0,
            validator: (value: number): boolean => value >= 0,
        },
        small: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    })

    const emit = defineEmits<{
        (e: 'step-change', id: number): void
    }>()

    const handleStepChange = (stepId: number): void => {
        emit('step-change', stepId)
    }

    const isDisabled = (step: IOwStepFlowItem): boolean => {
        // check item disabled is  function
        if (step?.disabled && typeof step.disabled === 'function') {
            return step.disabled()
        }
        return step.disabled as boolean
    }

</script>

<style scoped lang="scss">
  @import './ow-step-flow.scss';
</style>
