import jwtDecode from "jwt-decode"
import {
    Log,
    User,
    UserManager,
} from 'oidc-client-ts'

import { getAuthSettings } from '../../public/auth-settings.js'

class SecurityService {
    private userManager: UserManager

    constructor() {
        const settings = getAuthSettings()
        this.userManager = new UserManager(settings)

        Log.setLogger(console)
        Log.setLevel(Log.ERROR)

        this.userManager.events.addAccessTokenExpiring(async () => {
            await this.signInSilent()
        })

        this.userManager.events.addSilentRenewError((error) => {
            console.error('Silent Renew Error：', error)
        })
    }

    public getUser(): Promise<User> {
        return this.userManager.getUser()
    }

    public signIn(): Promise<any> {
        const returnToUrl = window.location.pathname + window.location.search + window.location.hash
        return this.userManager.signinRedirect({ state: returnToUrl })
    }

    public signInSilent(): Promise<any> {
        return this.userManager.signinSilent()
    }

    public async signOutRedirect(): Promise<void> {
        await this.userManager.signoutRedirect()
        await this.userManager.clearStaleState()
    }

    public async getAccessToken(): Promise<string> {
        const user = await this.getUser()
        return user?.access_token
    }

    public async getAccessTokenData(): Promise<{
        licenceType: string,
        grandfatheredModules: string[],
    } | null> {
        // Get the access token
        const accessToken = await this.getAccessToken()
        if (!accessToken) {
            return null
        }

        // Decode the JWT token to get the payload
        let decodedToken: any
        try {
            decodedToken = jwtDecode(accessToken)
            return {
                licenceType: decodedToken.ow_licence_type,
                grandfatheredModules: decodedToken?.ow_grandfathered_modules ?? [],
            }
        } catch (error) {
            // If the token is invalid, return false
            return null
        }
    }

    public removeUser(): Promise<void> {
        return this.userManager.removeUser()
    }
}

const service = new SecurityService()

export { service as default }
