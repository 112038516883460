<template>
    <ow-grid class="matter-searches-basket-table__grid"
             :grid-config="gridConfig"
             :header="header"
             theme="material" />
</template>

<script setup lang="ts">
    import {
        GridConfig,
        Model,
        Store,
    } from '@bryntum/grid'
    import {
        computed,
        ref,
    } from 'vue'

    import OwGrid from '@/components/core/ow-grid/ow-grid.vue'
    import { useGridConfig } from '@/components/core/ow-grid/use-grid-config'

    import { GridHeader } from '../core/ow-grid'
    import { useBasketColumns } from './columns'

    const props = defineProps<{
        store: Store
        config?: Partial<GridConfig>
        loading?: boolean
    }>()

    const emit = defineEmits<{
        (e: 'remove', model: Model): void
        (e: 'swap', model: Model): void
        (e: 'clear'): void
        (e: 'click-link', model: Model): void
        (e: 'expand-collapse', model: Model): void
    }>()

    const gridConfig = ref(useGridConfig({
        store: props.store,
        overrides: {
            ...props.config,
            rowHeight: 64,
            listeners: {
                cellClick: ({ event }) => {
                    const getDataId = (element: HTMLElement) => {
                        if (element.dataset.id) {
                            return element.dataset.id
                        }
                        if (element.parentElement) {
                            return getDataId(element.parentElement)
                        }
                        return null
                    }

                    const findClass = (element: HTMLElement, className: string) => {
                        if (element.classList.contains(className)) {
                            return element
                        }
                        if (element.parentElement) {
                            return findClass(element.parentElement, className)
                        }
                        return null
                    }

                    const target = event.target as HTMLElement
                    const links = ['TMGCon29-link', 'Con29DWC-link', 'TMGLLC1-link', 'HighwaysRA-link', 'Highways-link', 'ArgSSCombo-link']
                    const expandCollapse = ['TMGCon29-expand', 'Con29DWC-expand', 'TMGLLC1-expand', 'HighwaysRA-expand', 'Highways-expand', 'ArgSSCombo-expand']
                    if (links.some(link => findClass(target, link))) {
                        const id = getDataId(target)
                        const model = props.store.getById(id)
                        emit('click-link', model)
                    } else if (expandCollapse.some(header => findClass(target, header))) {
                        const id = getDataId(target)
                        const model = props.store.getById(id)
                        emit('expand-collapse', model)
                    }
                },
            },
        },
        on: async (event: string, column: string, model: Model) => {
            switch (event) {
                case 'remove': {
                    emit('remove', model)
                    break
                }
                case 'swap': {
                    emit('swap', model)
                    break
                }
            }
        },
        columns: useBasketColumns,
    }))


    const recordCount = computed(() => props?.store?.isFiltered ? props?.store?.storage?.filteredValues?.length : props?.store?.allRecords?.length)

    const header = computed<GridHeader>(() => ({
        title: `Basket (${ recordCount.value })`,
        description: 'These products will make up your searches order',
        sticky: true,
        link: {
            label: 'Remove all',
            show: props?.store?.allRecords?.length > 0,
            disabled: props?.loading,
            theme: 'danger',
            handler: () => {
                emit('clear')
            },
        },
    }))
</script>

<style lang="scss">
    @import './matter-searches-basket-table';
</style>
