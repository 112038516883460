import { ModelFieldConfig } from "@bryntum/grid"
import BasketItemModel from "./BasketItemModel"

export type RoadConfig = {
    name: string
    usrn?: string
}

export const Con29AdditionalEnquiryItems = [
    "RoadProposalsByPrivateBodiesIndicator",
    "SimplifiedPlanningZonesIndicator",
    "AdvertisementsIndicator",
    "LandMaintenanceNoticesIndicator",
    "CompletionNoticesIndicator",
    "MineralConsultationAreasIndicator",
    "ParksAndCountrysideIndicator",
    "HazardousSubstanceConsentsIndicator",
    "PipelinesIndicator",
    "EnvironmentAndPollutionNoticesIndicator",
    "HousesInMultipleOccupationIndicator",
    "FoodSafetyNoticesIndicator",
    "NoiseAbatementIndicator",
    "HedgerowNoticesIndicator",
    "UrbanDevelopmentAreasIndicator",
    "FloodDefenceAndLandDrainageConsentsIndicator",
    "EnterpriseZonesIndicator",
    "CommonLandTownAndVillageGreensIndicator",
    "InnerUrbanImprovementAreasIndicator",
]

export type Con29ProductConfig = {
        standardEnquiries: boolean
        additionalEnquiries: boolean
        roadConfig: RoadConfig[]
        additionalEnquiriesConfig?: string[]
        additionalQuestionsConfig?: string[]
}

type Con29ProductConfigJsonResult = {
    con29R: boolean,
    roadSearchList: {
        usrn?: string
        roadsAndFootways: string
    }[],
    con29O: boolean,
    roadProposalsByPrivateBodiesIndicator?: boolean,
    advertisementsIndicator?: boolean,
    parksAndCountrysideIndicator?: boolean,
    housesInMultipleOccupationIndicator?: boolean,
    urbanDevelopmentAreasIndicator?: boolean,
    innerUrbanImprovementAreasIndicator?: boolean,
    landMaintenanceNoticesIndicator?: boolean,
    hazardousSubstanceConsentsIndicator?: boolean,
    foodSafetyNoticesIndicator?: boolean,
    completionNoticesIndicator?: boolean,
    pipelinesIndicator?: boolean,
    noiseAbatementIndicator?: boolean,
    enterpriseZonesIndicator?: boolean,
    simplifiedPlanningZonesIndicator?: boolean,
    mineralConsultationAreasIndicator?: boolean,
    environmentAndPollutionNoticesIndicator?: boolean,
    hedgerowNoticesIndicator?: boolean,
    floodDefenceAndLandDrainageConsentsIndicator?: boolean,
    commonLandTownAndVillageGreensIndicator?: boolean,
    additionalQuestions: string[]
}


class BasketItemTMGCon29Model extends BasketItemModel {
    static get fields(): Partial<ModelFieldConfig>[] {
        const fields = super.fields
        return [
            ...fields,
            { name: 'isConfigurable', type: 'boolean', defaultValue: true },
            { name: 'config', type: 'object', defaultValue: {
                standardEnquiries: true,
            }},
            { name: 'collapsed', type: 'boolean', defaultValue: false },
        ]
    }

    override productOptionsToJson(): string {
        const productConfig = this.config as Con29ProductConfig
        const result: Con29ProductConfigJsonResult = {
            con29R: productConfig?.standardEnquiries || false,
            roadSearchList: productConfig?.roadConfig?.map(road => ({
                roadsAndFootways: road.name,
                usrn: road.usrn,
            })) || [],
            con29O: productConfig?.additionalEnquiries || false,
            additionalQuestions: productConfig?.additionalQuestionsConfig ?? [],
        }

        const additionalEnquiriesConfig = productConfig?.additionalEnquiriesConfig
        if (additionalEnquiriesConfig) {
            Con29AdditionalEnquiryItems.forEach(indicator => {
                if (additionalEnquiriesConfig.includes(indicator)) {
                    result[indicator] = true
                }
            })
        }

        return JSON.stringify(result)
    }

    override jsonToProductOptions(json: string): void {
        const productConfig = JSON.parse(json) as Con29ProductConfigJsonResult
        this.config = {
            standardEnquiries: productConfig.con29R,
            additionalEnquiries: productConfig.con29O,
            roadConfig: productConfig.roadSearchList.map(road => ({
                name: road.roadsAndFootways,
                usrn: road.usrn,
            })),
            additionalEnquiriesConfig: Con29AdditionalEnquiryItems.filter(indicator => productConfig[indicator]),
            additionalQuestionsConfig: productConfig.additionalQuestions,
        }
    }
}

export default BasketItemTMGCon29Model
