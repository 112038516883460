<template>
    <section :class="{
                 'is-visible': showTitlePanel,
                 'matter-titles-collapsed': isMatterTitlesCollapsed,
                 'full-screen': isFullScreen,
                 'title-panel-collapsed': (!titlePanelFullScreen && isViewingMap),
                 'viewing-map': isViewingMap,
                 'multi-title-panel': multiTitlePanel,
                 'collapse-panel': collapsePanel,
                 'top-nav-visible': isTopNavVisible,
             }"
             class="title-panel-v2">
        <title-panel-header :current-matter-id="matterId"
                            :has-drop-shadow="titlePanelDropShadow"
                            :is-loading="delayedIsLoading"
                            :is-ordering-allowed="isOrderingAllowed"
                            :ownership-mismatch="trOwnershipMismatch"
                            :is-collapsed="(!titlePanelFullScreen && isViewingMap)"
                            @pin="addToMatterHandler"
                            @close-panel="closePanel"
                            @toggle-collapsed="toggleFullscreen"
                            @collapse-panel="onTogglePanelCollapseClick"
                            @open-register="openRegisterClickHandler">
            <template #header-prefix>
                <slot name="header-prefix" />
            </template>
        </title-panel-header>
        <div v-show="!isPanelCollapsed"
             class="title-panel-v2__content">
            <title-panel-content :is-full-screen="isFullScreen"
                                 :is-collapsed="(!titlePanelFullScreen && isViewingMap)"
                                 :is-loading="delayedIsLoading"
                                 :is-ordering-allowed="isOrderingAllowed"
                                 :matter-id="currentMatterId"
                                 :order-with-checkout="orderWithCheckout"
                                 :show-title-complexity="showTitleComplexity"
                                 scroll-element-id="sideNavContent"
                                 @click="onTabClick"
                                 @scroll-content="onScroll"
                                 @title-number-selected="titleNumber => $emit('title-number-selected', titleNumber)"
                                 @set-selected-tab="setSelectedTab"
                                 @open-register="openRegisterClickHandler" />
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import TitleInformationApi from '@/api/title-information.api'
    import TitlePanelContent from '@/components/title-panel/v2/title-panel-content.vue'
    import { useMapTopNav } from "@/composables/use-map-top-nav"
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { inject as titlePanelProvider } from '@/composables/use-title-panel'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { Route } from "@/enums/route.enum"
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import { ITitleRegisterDocument } from '@/interfaces/official-copy-availability.interface'
    import { ADD_DOCUMENT_TO_MATTER } from '@/store/modules/documents/documents-types'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { MAP_UPDATE_SIZE } from "@/store/modules/map/types"
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import {
        TITLE_GET_SHOW_MULTI_TITLE_PANEL,
        TITLE_MUTATE_COLLAPSE_PANEL,
    } from "@/store/modules/titles/types"
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_LOG_FEATURE_USAGE,
        USER_MUTATE_SHOW_MAP_TITLES_NAV,
    } from '@/store/mutation-types'
    import { useCoreComponentStore } from "@/stores/core"
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import TitlePanelHeader from './title-panel-header.vue'

    const store = useStore()
    const router = useRouter()
    const {
        selectedTitle,
        selectedTitleNumber,
        titleRecordSource,
    } = useSelectedTitle()

    const props = defineProps({
        isOrderingAllowed: {
            type: Boolean,
            required: false,
            default: true,
        },
        matterId: [ Number, String ],
        isFullScreen: Boolean,
        orderWithCheckout: Boolean,
        showTitleComplexity: {
            type: Boolean,
            required: false,
            default: true,
        },
    })

    const titlePanelDropShadow = ref<boolean>(false)
    const { setSelectedTab, titlePanelFullScreen, forceTitlePanelFullScreen, restoreTitlePanelFullScreen } = titlePanelProvider()
    const { toggleFullscreen } = useCoreComponentStore()
    const { isTopNavVisible } = useMapTopNav()
    const route = useRoute()

    const emit = defineEmits<{
        (e: 'clear-titles', selectedTitleNumber: string): void
        (e: 'pin-title', selectedTitleNumber: string): void
        (e: 'title-number-selected', titleNumber: string): void
    }>()

    const isViewingMap = computed(() => route.meta.globalNavName === 'map')
    const isSharedLinkView = computed(() => store.getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`])
    const isWalkthrough = computed<boolean>((): boolean => store.state.walkthrough.enabled)
    const isOwnerTitlesPanelOpen = computed<boolean>((): boolean => store.state.search.isOwnerTitlesPanelOpen)
    const isMatterTitlesCollapsed = computed<boolean>(() => !store.state.user.showMapTitlesNav)
    const titleRegister = computed<ITitleRegisterDocument>(() => store.state.title?.selectedTitle?.officialCopiesAvailability?.results?.titleRegister)
    const planningData = computed(() => store.state.config?.featureFlags?.planningData)
    const multiTitlePanel = computed<boolean>(() => store.getters[TITLE_GET_SHOW_MULTI_TITLE_PANEL])
    const collapsePanel = computed<boolean>(() => store.state.title.collapsePanel)

    const currentMatterId = ref<number>(0)
    watch(() => props.matterId, (matterId: string|number) => {
        // No idea what is converting the number to a string. The parent is sending a number, but this receives a string.
        // We do use both interchangeably, so we need to handle both cases I guess anyhow.
        currentMatterId.value = typeof matterId === 'string' ? parseInt(matterId.toString()) : matterId
    }, { immediate: true })

    const showTitlePanel = computed<boolean>((): boolean => {
        return isValidRoute.value &&
            !isSharedLinkView.value &&
            !isWalkthrough.value &&
            !isOwnerTitlesPanelOpen.value &&
            Boolean(selectedTitleNumber.value)
    })

    const isValidRoute = computed(() => {
        return ![Route.MatterSketches, Route.OverlaysList, Route.Overlay].includes(route.name as Route)
    })

    const closePanel = () => {
        emit('clear-titles', selectedTitleNumber.value)
    }

    const trOwnershipMismatch = ref<boolean>(false)

    const delayedIsLoading = ref<boolean>(false)
    const isLoading = computed<boolean>(() => !titleRecordSource.value)

    watch(() => isLoading.value, (newIsLoading: boolean) => {
        if (newIsLoading) {
            delayedIsLoading.value = true
            setTimeout(() => {
                delayedIsLoading.value = false
            }, 1000)
        }
    }, {
        immediate: true,
    })

    watch(() => selectedTitleNumber.value, async (newTitleNumber: string, oldTitleNumber: string) => {
        if (!isNullOrWhitespace(newTitleNumber)
            && newTitleNumber !== oldTitleNumber) {
            const trOwnershipFlag = await TitleInformationApi.checkOwnerMatchWithCcodOcodByTitleNumber(newTitleNumber)
            trOwnershipMismatch.value = trOwnershipFlag === false
        }
    }, { immediate: true })

    const openRegisterClickHandler = async (): Promise<void> => {
        const documentId = titleRegister.value?.documentId
        if (documentId) {
            await store.dispatch(MATTER_ADD_TITLE, {
                titleNumber: selectedTitle.value?.titleNumber,
                show: true,
            })
            await store.dispatch(ADD_DOCUMENT_TO_MATTER, {
                documentType: HighLevelDocumentType.Register,
                documentId,
                matterId: props.matterId?.toString(),
            })

            await router.push({
                name: Route.MatterMapTitle,
                params: {
                    matterId: props.matterId?.toString(),
                    titleNumber: selectedTitleNumber.value,
                },
                query: {
                    tab: 'register',
                },
            })
        } else {
            console.error('Unable to view document as no documentId in the store', titleRegister.value)
        }
    }

    const addToMatterHandler = () => {
        emit('pin-title', selectedTitleNumber.value)
    }

    const onScroll = (scrollTop: number) => {
        titlePanelDropShadow.value = scrollTop > 0
    }

    const logLeases = async () => {
        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'Leases hierarchy',
            description: `opened lease tab for title ${ selectedTitleNumber.value }`,
        })
    }

    const trackHeapFindNearby = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'TITLE-DETAILS-PANEL - Nearby tab',
        })
    }

    const trackHeapPlanning = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Title panel - Planning tab',
        })

        await store.dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: planningData.value ? 'PLANNING_TAB_VIEWED' : 'PLANNING_PROMO_VIEWED',
            description: planningData.value ? 'Planning tab viewed' : 'Planning promo tab viewed',
        })
    }

    const isPanelCollapsed = computed( () => store.state.title.collapsePanel)

    const onTogglePanelCollapseClick = async () => {
        store.commit(TITLE_MUTATE_COLLAPSE_PANEL, !isPanelCollapsed.value)
        await store.dispatch(MAP_UPDATE_SIZE)
    }
    const onTabClick = async ({ tabId }: { tabId: string }) => {
        switch (tabId) {
            case TitlePanelTabName.Leases:
                await logLeases()
                break
            case  TitlePanelTabName.Planning:
                await trackHeapPlanning()
                break
            case TitlePanelTabName.FindNearby:
                await trackHeapFindNearby()
                break
        }
    }

    watch(() => route?.name, () => {
        switch (route?.name) {
            case Route.MatterMap:
            case Route.MatterMapTitle:
                restoreTitlePanelFullScreen()
                break
            default:
                forceTitlePanelFullScreen()
        }
    }, {
        immediate: true,
    })
</script>

<style lang="scss">
    @import './title-panel';
</style>
