<template>
    <div class="d-flex flex-column">
        <div v-if="group != null"
             :data-test="'titles-group-item-' + group.name"
             class="d-flex flex-row group-item"
             :class="{
                 'group-item--default': !isWalkthrough && !isSharedLinkView && !titleOrganiserEnabled,
                 'group-item--walkthrough': isWalkthrough,
                 'group-item--shared': isSharedLinkView,
                 'group-item--title-organiser': titleOrganiserEnabled,
             }"
             @mouseleave="mouseOver = false"
             @mouseover="mouseOver = true">
            <div class="d-flex flex-fill flex-row flex-nowrap group-item__header-container"
                 :class="{
                     'mouse-over': mouseOver,
                     'all-hidden': allHidden,
                     'no-titles': filteredTitles.length === 0,
                 }">
                <div class="d-flex flex-shrink-1 group-item__checkbox-container">
                    <v-checkbox v-if="showSelectionCheckbox"
                                density="compact"
                                data-test="matter-side-panel-group-item-checkbox"
                                :model-value="group.selected || indeterminate"
                                :indeterminate="indeterminate"
                                :disabled="disabled"
                                color="primary"
                                @update:model-value="onSelectionChange" />
                </div>

                <div class="d-flex flex-grow-1 group-description-container"
                     @click="toggleExpanded">
                    <div class="d-flex flex-column">
                        <div class="group-name"
                             data-test="matter-side-panel-group-item-group-name"
                             :title="group.name">
                            <ow-text-highlight :queries="[filter]">
                                {{ group.name }}
                            </ow-text-highlight>
                        </div>
                        <div class="group-title-count">
                            {{ titleCount }}
                        </div>
                    </div>
                </div>

                <div v-if="hasEditButton && showEditButton"
                     class="d-flex shrink group-item__edit-button"
                     :class="{'header-icon': mouseOver, 'header-icon--hide': !mouseOver}">
                    <v-btn data-test="matter-side-panel-group-item-button-edit"
                           data-track="MAT-TITLE-LIST - Group edit button"
                           icon
                           density="comfortable"
                           style="margin:0"
                           variant="text"
                           @click="onEditButtonClick()">
                        <v-icon>$edit</v-icon>
                    </v-btn>
                </div>

                <div v-if="hasVisibilityButton && showVisibilityButton"
                     class="d-flex shrink group-item__visibility-button"
                     :class="{'header-icon': mouseOver || allHidden, 'header-icon--hide': !mouseOver && allHidden}">
                    <div>
                        <v-hover v-slot="{ hover }">
                            <v-btn :disabled="!atLeastOneTitleHasBoundary"
                                   density="comfortable"
                                   data-test="matter-side-panel-group-item-visibility"
                                   data-track="MAT-TITLE-LIST - Group visibility button"
                                   icon
                                   style="margin:0"
                                   variant="text"
                                   @click="allTitlesAreVisible = !allTitlesAreVisible">
                                <v-icon>
                                    {{ visibilityIcon(hover) }}
                                </v-icon>
                            </v-btn>
                        </v-hover>
                        <ow-tooltip activator="parent"
                                    :disabled="atLeastOneTitleHasBoundary"
                                    :position="OwTooltipPosition.Right">
                            <span>{{ $t('matter.noBoundaryData') }}</span>
                        </ow-tooltip>
                    </div>
                </div>

                <div v-if="isWalkthrough"
                     class="d-flex flex-shrink-1 group-item__toggle-wrapper">
                    <v-btn data-test="matter-side-panel-group-item-walkthrough-toggle"
                           data-track="WALKTHROUGH - Group add or remove titles"
                           density="comfortable"
                           icon
                           class="header-icon"
                           style="margin:0"
                           variant="text"
                           @click="toggleWalkthroughInclusion()">
                        <v-icon v-if="!isIncludedInSelectedWalkthroughPage">
                            $add
                        </v-icon>
                        <v-icon v-if="isIncludedInSelectedWalkthroughPage">
                            $minus
                        </v-icon>
                    </v-btn>
                </div>

                <div class="d-flex flex-shrink-1">
                    <v-btn icon
                           class="header-icon"
                           style="margin:0"
                           density="comfortable"
                           data-track="MAT-TITLE-LIST - Group show or hide titles button"
                           variant="text"
                           @click="toggleExpanded">
                        <v-icon v-if="!group.expanded">
                            $chevron-down
                        </v-icon>
                        <v-icon v-if="group.expanded">
                            $chevron-up
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="group-item group-item_titles">
            <div class="d-flex flex-column matter-group-tile-container">
                <draggable :list="filteredTitles"
                           :data-groupid="group.id"
                           :disabled="disableDragging"
                           :group="{name: 'groups'}"
                           item-key="titleNumber"
                           class="matter-group-tile-container__titles"
                           :data-test="group.expanded ? 'matter-side-panel-group-item-titles' : ''"
                           :class="{
                               'matter-group-tile-container__titles--expanded': group.expanded,
                           }"
                           v-bind="dragOptions"
                           @change="onChange">
                    <template #item="{element, index}">
                        <div v-if="group.expanded">
                            <matter-side-panel-title-item :key="element.titleNumber"
                                                          :data-sortorder="element.sortOrder"
                                                          :data-titlenumber="element.titleNumber"
                                                          :disabled="disabled"
                                                          :title="element"
                                                          :is-expanded="element.titleNumber === expandedTitleNumber"
                                                          :has-light-background="index % 2 === 1"
                                                          :has-menu="hasMenu"
                                                          :has-expanded="hasExpanded"
                                                          :has-visibility-button="hasVisibilityButton"
                                                          :scroll-observer-element-id="scrollObserverElementId"
                                                          :clickable-area="clickableArea"
                                                          :disable-expand-collapse="disableExpandCollapse"
                                                          @clear-titles="clearTitles"
                                                          @on-expand="onExpand"
                                                          @on-collapse="onCollapse"
                                                          @add-title-to-group="addTitleToGroup"
                                                          @title-click="$emit(EVENTS.titleClick, element)"
                                                          @title-mouse-over="$emit(EVENTS.titleMouseOver, element)"
                                                          @title-mouse-out="$emit(EVENTS.titleMouseOut, element)" />
                        </div>
                    </template>
                </draggable>
                <div v-if="group.expanded && !filteredTitles.length"
                     class="caption-italic matter-group-tile-container__no-titles">
                    {{ $t('matter.emptyGroup') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import pluralize from 'pluralize'
    import { inject } from 'vue'
    import draggable from 'vuedraggable'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { applyNewSortOrderToItems } from '@/store/modules/matter/group.utils.js'
    import {
        MATTER_ADD_TITLES_TO_GROUP,
        MATTER_GET_MATTER_CONTENTS,
        MATTER_GROUP_SET_EXPANDED,
        MATTER_SHOW_BOUNDARIES,
        MATTER_UPDATE_TITLE_SORT,
    } from '@/store/modules/matter/types'
    import {
        WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE,
        WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE,
    } from '@/store/modules/walkthrough/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import MatterTitleItem from './matter-side-panel-title-item.vue'

    const EVENTS = {
        addTitleToGroup: 'add-title-to-group',
        clearTitles: 'clear-titles',
        expand: 'on-expand',
        collapse: 'on-collapse',
        titleClick: 'title-click',
        titleMouseOver: 'title-mouse-over',
        titleMouseOut: 'title-mouse-out',
    }
    export default {
        name: 'MatterSidePanelGroupItem',
        components: {
            draggable,
            MatterSidePanelTitleItem: MatterTitleItem,
            OwTextHighlight,
            OwTooltip,
        },
        mixins: [FeatureFlagsMixin],
        props: {
            group: {
                type: Object,
                required: false,
                default: null,
            },
            filteredTitles: {
                type: Array,
                required: false,
                default: () => [],
            },
            expandedTitleNumber: {
                type: String,
                required: false,
                default: null,
            },
            scrollObserverElementId: {
                type: String,
                default: '.matter-side-panel-titles__content',
            },
            hasMenu: {
                type: Boolean,
                required: false,
                default: true,
            },
            hasExpanded: {
                type: Boolean,
                required: false,
                default: true,
            },
            hasVisibilityButton: {
                type: Boolean,
                required: false,
                default: true,
            },
            hasEditButton: {
                type: Boolean,
                required: false,
                default: true,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            clickableArea: {
                type: String,
                default: 'content',
                required: false,
                validator(val: string) {
                    return ['content', 'whole-item', 'none'].includes(val)
                },
            },
            disableExpandCollapse: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        emits: Object.values(EVENTS),
        setup() {
            const filterProvider = inject('FILTER_PROVIDER')
            return {
                filterProvider,
            }
        },
        data() {
            return {
                mouseOver: false,
                dragOptions: {
                    animation: 100,
                    ghostClass: 'ghost',
                    dragoverBubble: true,
                },
                OwTooltipPosition,
                EVENTS,
            }
        },

        computed: {
            ...mapState({
                isWalkthrough: state => state.walkthrough.enabled,
                titleOrganiserEnabled: state => state.matter.titleOrganiser.enable,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            ...mapGetters({
                getTitleIsIncludedInCurrentPage: WALKTHROUGH_GET_TITLE_NUMBER_IS_INCLUDED_IN_SELECTED_PAGE,
                matterContent: MATTER_GET_MATTER_CONTENTS,
            }),

            titlesInGroup() {
                return this.matterContent.titles.filter(title => {
                    return title.matterGroupId == this.group.id
                })
            },

            allTitlesAreVisible: {
                get() {
                    const someNotVisible = this.filteredTitles.some(title => {
                        return title.show === false
                    })
                    return !someNotVisible
                },

                set(val) {
                    const titles = this.filteredTitles.filter(title => title.matterGroupId === this.group.id)
                    this.showBoundaries({
                        titles,
                        show: val,
                    })
                },
            },

            selectedFilteredTitlesCount() {
                return this.filteredTitles.filter(title => title.selected).length
            },

            showSelectionCheckbox() {
                return !this.isSharedLinkView && !this.isWalkthrough && !this.titleOrganiserEnabled
            },

            showEditButton() {
                return !this.isSharedLinkView && !this.isWalkthrough && !this.titleOrganiserEnabled
            },

            showVisibilityButton() {
                return (!this.isWalkthrough && !this.titleOrganiserEnabled)
            },

            isIncludedInSelectedWalkthroughPage() {
                return this.filteredTitles.every(title => {
                    return this.getTitleIsIncludedInCurrentPage(title.titleNumber)
                })
            },

            allHidden() {
                return this.filteredTitles.every(title => {
                    return title.show === false
                })
            },

            someVisible() {
                return this.filteredTitles.some(title => {
                    return title.show === true
                })
            },

            atLeastOneTitleHasBoundary() {
                return this.filteredTitles.some(t => t.boundaryAvailable)
            },

            titleCount() {
                return pluralize('Title', this.titlesInGroup.length, true)
            },

            indeterminate() {
                return this.selectedFilteredTitlesCount > 0 &&
                    this.selectedFilteredTitlesCount !== this.filteredTitles.length
            },

            groupTitleCount() {
                return this.filteredTitles.length
            },

            disableDragging() {
                return this.isSharedLinkView || this.titleOrganiserEnabled
            },

            filter() {
                return this.filterProvider ?? ''
                    .toLowerCase()
                    .replace(/\W/g, ' ')
            },
        },

        watch: {
            selectedFilteredTitlesCount(val) {
                if (val === this.filteredTitles.length) {
                    this.group.selected = true
                } else if (val === 0) {
                    this.group.selected = false
                }
            },

            groupTitleCount(val) {
                if (val === 0) {
                    this.group.selected = false
                    this.group.expanded = true
                }
            },
        },

        methods: {
            ...mapActions({
                showBoundaries: MATTER_SHOW_BOUNDARIES,
                addTitlesToGroup: MATTER_ADD_TITLES_TO_GROUP,
                updateTitleSort: MATTER_UPDATE_TITLE_SORT,
                setGroupExpanded: MATTER_GROUP_SET_EXPANDED,
            }),

            ...mapMutations({
                toggleInclusionInSelectedWalkthroughPage: WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE,
            }),

            addTitleToGroup(title, groupId) {
                this.$emit(this.EVENTS.addTitleToGroup, title, groupId)
            },

            clearTitles() {
                this.$emit(this.EVENTS.clearTitles)
            },

            async onChange(evt) {
                const supportedEvent = evt.added || evt.moved
                if (typeof supportedEvent != "undefined") {
                    // Get title number from the data attribute
                    const titleNumber = supportedEvent.element.titleNumber
                    if (isNullOrWhitespace(titleNumber)) {
                        return
                    }

                    // Find the corresponding title and add it to the group
                    const title = this.matterContent.titles.find(t => {
                        return t.titleNumber == titleNumber
                    })

                    if (title !== undefined) {
                        this.applyNewSortOrderToTitleInGroup(title, supportedEvent.newIndex)
                        await this.addTitlesToGroup({
                            matterGroupId: this.group.id,
                            titles: [title],
                        })
                    } else {
                        console.error('Error - attempted to move a title not found in the matter, this shouldn\'t happen')
                    }
                }
            },

            applyNewSortOrderToTitleInGroup(title, newIndex) {
                const updatedSortItems = applyNewSortOrderToItems(this.filteredTitles, title, newIndex)
                this.updateTitleSort(updatedSortItems.map(x => {
                    return { titleNumber: x.titleNumber, sortOrder: x.sortOrder }
                }))
            },

            onExpand(titleNumber) {
                this.$emit(this.EVENTS.expand, titleNumber)
            },

            onCollapse() {
                this.$emit(this.EVENTS.collapse)
            },

            onEditButtonClick() {
                // TODO: These should be done via a mutation!
                this.$store.state.matter.prompts.updateGroup.group = this.group
                this.$store.state.matter.prompts.updateGroup.show = true
            },

            toggleWalkthroughInclusion() {
                if (this.isIncludedInSelectedWalkthroughPage) {
                    // Remove all titles.
                    this.filteredTitles.forEach(title => {
                        this.toggleInclusionInSelectedWalkthroughPage(title)
                    })
                } else {
                    // Add titles not already in the walkthrough page.
                    this.filteredTitles.filter(title => {
                        return !this.getTitleIsIncludedInCurrentPage(title.titleNumber)
                    }).forEach(title => {
                        this.toggleInclusionInSelectedWalkthroughPage(title)
                    })
                }
            },

            onSelectionChange() {
                const value = this.selectedFilteredTitlesCount === 0

                this.matterContent.titles.forEach(title => {
                    if (title.matterGroupId === this.group.id) {
                        title.selected = value
                    }
                })
                this.$emit('selection-changed', this.group, value)
            },

            toggleExpanded() {
                this.setGroupExpanded({
                    groupId: this.group.id,
                    expanded: !this.group.expanded,
                })
            },

            visibilityIcon(hover) {
                let shouldShowIcon = hover
                    ? !this.allTitlesAreVisible && !this.someVisible
                    : this.allTitlesAreVisible || this.someVisible

                if (!this.atLeastOneTitleHasBoundary) {
                    shouldShowIcon = false
                }

                return shouldShowIcon ? '$visibility' : '$visibility-off'
            },
        },
    }

</script>
<style lang="scss">
    @import 'matter-side-panel-group-item';
</style>
