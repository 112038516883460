import {
    IFindNearbyResults,
    IFindNearbyService,
    IFindNearbySubtitleText,
} from '@/components/find-nearby/implementations/_common/find-nearby-service.interface'
import Feature from 'ol/Feature'
import { FindNearbySettings } from '@/components/find-nearby/find-nearby-settings'

export abstract class FindNearbyServiceBase implements IFindNearbyService {
    label = 'Not implemented'

    loading: boolean
    settings: FindNearbySettings
    displayComponentName: string

    abstract handleRequest(props: any): Promise<IFindNearbyResults>

    abstract reset(): void

    abstract getTitleText(): string

    abstract getSubtitleText(params: IFindNearbySubtitleText): string

    abstract getResultKeyValue(result: any): string

    abstract getFilteredResults(props: any): IFindNearbyResults

    abstract getFeaturesForRecords(recordIds: Array<string>, selectedDistance: number): Array<Feature>

    abstract getRecordsFromFeatureIds(featureIds: Array<string>): Array<any>
}
