<template>
    <div>
        <teleport to="body">
            <exporting-status-modal v-bind="$props"
                                    @request-retry="$emit('request-retry', $event)"
                                    @update:model-value="$emit('update:modelValue', $event)" />
        </teleport>
    </div>
</template>

<script lang="ts">
    import ExportingStatusModal, { EVENTS } from '@/components/reporting/exporting-status-modal.vue'

    export default {
        name: 'ExportingStatusModalWithTeleport',

        components: {
            ExportingStatusModal,
        },

        props: {
            // eslint-disable-next-line vue/no-unused-properties
            modelValue: {
                type: Boolean,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            ordering: {
                type: Boolean,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            exporting: {
                type: Boolean,
                required: true,
            },
            // eslint-disable-next-line vue/no-unused-properties
            error: {
                type: Boolean,
                default: false,
                required: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            loadingTimeout: {
                type: Number,
                required: false,
                default: 5000,
            },
            // eslint-disable-next-line vue/no-unused-properties
            isAsyncExporting: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        emits: [
            EVENTS.Input,
            EVENTS.Retry,
        ],
    }
</script>
