import { isLocalDevEnvironment } from '@/utils/environment.utils'
import { StatusCode } from '@/consts/status-code'

interface IBuild {
    build: {
        run: string,
        url: string,
        timestamp: Date,
        commit: {
            sha: string,
            url: string,
        },
        pullRequest?: {
            url: string,
            title: string,
        },
    }
}

export default class AppVersionApi {
    static async getLatestAppVersion(): Promise<string | null> {
        const buildFile = isLocalDevEnvironment() ? 'test-build.json' : 'build.json'

        const response: Response = await fetch(`/${ buildFile }`)
        if (response.status === StatusCode.SUCCESS) {
            const json: IBuild = await response.json()
            return json?.build?.commit?.sha
        } else {
            console.error('Unable to determine application version')
            return null
        }
    }
}
