import { StyleLike } from 'ol/style/Style'
import { LayerNames } from '@/consts/map-layers'
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { FeatureLike } from 'ol/Feature'
import { MapBrowserEvent } from 'ol'
import i18n from '@/plugins/i18n'
import { isNullOrWhitespace } from '@/utils/string-utils'
import {
    getEnvironmentalRolloverOptions,
    AonbLayerFeaturesType,
} from './environmental-layers-common'

export type AonbLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: ({ text, hoverCaption } : { text: Array<string>, hoverCaption: string },
                                event: MapBrowserEvent<any>) => void
}

export class AonbLayer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: ({ text, hoverCaption } : { text: Array<string>, hoverCaption: string },
                                                 event: MapBrowserEvent<any>) => void

    constructor(params: AonbLayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:vaonb_combined'
        this.name = LayerNames.AONB
        this.i18nNameRef = 'map.options.legend.environmental[1]'
        this.attributionText = [`${ i18n.global.t('map.options.environmental.attribution.aonb') } - © ${ i18n.global.t('map.options.environmental.source.england') } & ${ i18n.global.t('map.options.environmental.source.wales') }- released ${ settings.aonbReleaseDate }`]
        this.rolloverOptions = getEnvironmentalRolloverOptions(LayerNames.AONB, this.style)

        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Toggle AONB layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                let textArray = []
                let hoverCaption = ''
                if (features.length) {
                    const properties = features[0].getProperties() as AonbLayerFeaturesType
                    textArray = AonbLayer.getHoverTextArray(properties)
                    hoverCaption = properties.england ? `${ i18n.global.t('map.options.environmental.hover.aonb.caption') }` : ''
                }
                this.onHoverTextChangeCallback({
                    text: textArray,
                    hoverCaption,
                }, event)
            }
        }
        this.onClickFn = this.onClick.bind(this)
        this.initialiseLayer()
    }

    public style:StyleLike = new Style({
        fill: new Fill({ color: 'rgba(0, 77, 64, 0.6)' }),
        stroke: new Stroke({
            color: '#ffffff',
            width: 2,
        }),
    })

    private onClick(features: Array<FeatureLike>): void {
        if (features.length) {
            const properties = features[0].getProperties() as AonbLayerFeaturesType
            if (!isNullOrWhitespace(properties?.external_link)) {
                window.open(properties.external_link, '_blank')
                this.eventLogger.logEvent({
                    type: 'MAP - AONB point clicked',
                    metadata: {
                        value: properties.external_link,
                    },
                })
            }
        }
    }

    public static getHoverTextArray(properties: AonbLayerFeaturesType):string[] {
        const source = properties.england ? `${ i18n.global.t('map.options.environmental.source.england') }` : `${ i18n.global.t('map.options.environmental.source.wales') }`
        return [
            `${ i18n.global.t('map.options.environmental.hover.aonb.name') }: ${ properties.name }`,
            `${ i18n.global.t('map.options.environmental.hover.aonb.designationDate') }: ${ properties.designation_date }`,
            `${ i18n.global.t('map.options.environmental.hover.aonb.source') }: ${ source }`,
        ]
    }
}
