<template>
    <v-select v-model="select"
              :class="{ 'full-width': isFullWidth }"
              :clearable="clearable"
              :data-test="dataTestAttribute"
              :data-track="dataTrackAttribute"
              :density="isDense ? 'compact' : 'default'"
              :disabled="disabled"
              :flat="isFlat"
              :hide-details="hideDetails"
              :item-title="itemText"
              :item-value="itemValue"
              :items="items"
              :label="label"
              :return-object="true"
              :single-line="isSingleLine"
              :variant="isOutlined ? 'outlined' : 'solo'"
              class="ow-select"
              @click:clear="onClear">
        <template v-for="(_, name) in $slots"
                  #[name]="slotData">
            <slot :name="name"
                  v-bind="slotData" />
        </template>
    </v-select>
</template>

<script lang="ts">
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'OwSelect',

        props: {
            modelValue: {
                type: [ String, Number, Object ],
                required: false,
            },
            dataTestAttribute: {
                type: String,
                default: 'ow-select',
            },

            dataTrackAttribute: {
                type: String,
                default: 'ow-select',
            },

            disabled: {
                type: Boolean,
                default: false,
            },

            items: {
                type: Array,
                required: true,
            },

            label: {
                type: String,
                required: false,
                default: 'Select...',
            },
            itemText: {
                type: String,
                required: false,
                default: 'text',
            },
            itemValue: {
                type: String,
                required: false,
                default: 'value',
            },
            selectedValue: {
                type: [ String, Number, Object ],
                required: false,
            },
            hideDetails: {
                type: Boolean,
                required: false,
            },
            isDense: {
                type: Boolean,
                required: false,
            },
            isOutlined: {
                type: Boolean,
                required: false,
            },
            clearable: {
                type: Boolean,
                required: false,
            },
            isFlat: {
                type: Boolean,
                required: false,
            },
            isSingleLine: {
                type: Boolean,
                required: false,
            },
            isFullWidth: {
                type: Boolean,
                required: false,
            },
        },

        emits: [ 'change', 'clear', 'update:modelValue' ],

        computed: {
            select: {
                get(): any {
                    if (!isNullOrWhitespace(this.selectedValue)) {
                        return this.selectedValue
                    }

                    return this.modelValue
                },
                set(value: any): void {
                    this.$emit('change', value)
                    this.$emit('update:modelValue', value)
                },
            },
        },

        methods: {
            onClear(): void {
                this.$emit('clear')
            },
        },
    }
</script>
