/* eslint-disable */

import {
    Image as ImageLayer,
} from 'ol/layer.js'

/**
 * @classdesc
 * Image layer to use with a GeoImage source and return the extent calcaulted with this source.
 * @extends {ol.layer.Image}
 * @param {Object=} options Layer Image options.
 * @api
 */
export class GeoImage extends ImageLayer {
    constructor(options) {
        super(options)
    }

    /**
   * Return the {@link module:ol/extent~Extent extent} of the source associated with the layer.
   * @return {ol.Extent} The layer extent.
   * @observable
   * @api
   */
    getExtent() {
        return this.getSource().getExtent()
    }

    getOriginalExtent() {
        return this.getSource().getOriginalExtent()
    }
}
