<template>
    <v-tooltip :model-value="modelValue"
               data-test-id="ow-callout"
               class="ow-callout"
               :max-width="maxWidth"
               :disabled="!modelValue || !canShow()"
               :open-on-hover="false"
               :open-on-click="false"
               :open-on-focus="false"
               :content-class="contentClass"
               :location="props.position">
        <template #activator="{ props: attrs}">
            <div class="ow-callout__container">
                <transition name="fade-co">
                    <div v-if="modelValue && canShow()"
                         :style="{ left: highlightOffset?.left, top: highlightOffset?.top, width: highlightOffset?.right, height: highlightOffset?.bottom }"
                         class="ow-callout__background" />
                </transition>
                <slot name="activator"
                      v-bind="attrs" />
            </div>
        </template>
        <slot />
    </v-tooltip>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
        watch,
    } from 'vue'

    import { IOwCalloutOffset } from '@/components/core/types/ow-callout-offset'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'

    const props = withDefaults(defineProps<{
        modelValue: boolean
        calloutId?: string
        contextId?: string
        position?: OwTooltipPosition
        highlightOffset?: IOwCalloutOffset
        maxWidth?: string | number
        closeDelay?: number
        persistence?: 'LocalStorage' | 'SessionStorage' | 'None'
    }>(), {
        calloutId: null,
        contextId: null,
        position: OwTooltipPosition.Right,
        highlightOffset: null,
        maxWidth: 250,
        closeDelay: 5000,
        persistence: 'None',
    })

    const emit = defineEmits<{
        (e: 'update:modelValue', v: boolean)
    }>()
    const timeoutHandle = ref<any>(null)

    /**
     * Returns whether the callout can be shown
     */
    const canShow = () => {
        if (props.persistence !== 'None' && props.calloutId) {
            return !getSetting()
        }
        return true
    }

    watch(() => props.modelValue, (value) => {
        // if callout is being displayed but there is already a persisted config / persistence is None, do not show
        if ((value && !canShow())) {
            clearTimeout(timeoutHandle?.value)
            emit('update:modelValue', false)
            return
        }

        // if callout is toggled, we do not need the timeout
        if (props.closeDelay === 0) {
            return
        }

        // create a timeout to close the callout
        clearTimeout(timeoutHandle?.value)
        timeoutHandle.value = setTimeout(() => {
            setSetting()
            emit('update:modelValue', false)
        }, props.closeDelay)
    })

    /**
     * Returns the storage to use based on the persistence setting
     */
    const storageToUse = () => {
        if (props.persistence === 'SessionStorage') {
            return sessionStorage
        }
        return localStorage
    }

    /**
     * Returns the prefix to use for the storage key
     */
    const valueToSet = computed(() => {
        if (props.contextId && props.contextId !== '') {
            return `${ props.contextId }-${ props.calloutId }`
        }
        return props.calloutId
    })

    const settingKey = 'callout-settings'

    /**
     * Returns the value of the storage item
     */
    const getSetting = () => {
        if (props.persistence === 'None') {
            return false
        }

        const hashMap = JSON.parse(storageToUse().getItem(settingKey))
        if (hashMap) {
            return hashMap[valueToSet.value]
        }
        return false
    }

    /**
     * Sets the value of the storage item
     */
    const setSetting = () => {
        if (props.persistence === 'None') {
            return false
        }

        const hashMap = JSON.parse(storageToUse().getItem(settingKey)) ?? {}
        hashMap[valueToSet.value] = true
        storageToUse().setItem(settingKey, JSON.stringify(hashMap))
    }

    /**
     * Returns the class for the content
     */
    const contentClass = computed(() => {
        return [
            'caption-regular',
            'ow-callout__content',
            `ow-callout__content--${ props.position }`,
        ].join(' ')
    })
</script>

<style lang="scss">
    @import './ow-callout.scss';
</style>
