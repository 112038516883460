import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    isAuthenticated: false,
    user: null,
    loading: false,
    error: 'null',
}

export default {
    state,
    getters,
    mutations,
    actions,
}
