import { debounce } from 'lodash'
import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'
import { shallowRef } from 'vue'

import ApplicationEnquiryApi from '@/api/application-enquiry.api'
import BusinessGatewayApi from '@/api/business-gateway.api'
import MatterApi from '@/api/matter.api'
import MatterLinkShareApi from '@/api/matter-link-share.api'
import ReportingApi from '@/api/reporting.api'
import WalkthroughsApi from '@/api/walkthroughs.api'
import { DocumentOrderStatus } from '@/consts/document-order-status'
import {TITLE_LIMIT} from "@/consts/title-limits"
import { Route } from '@/enums/route.enum'
import { apolloClient } from '@/graphql/apollo'
import { GET_TITLES_WITHOUT_DIGITAL_REGISTERS } from '@/graphql/queries/title-register-availability.gql.js'
import i18n from '@/plugins/i18n'
import router from '@/router'
import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
import { getBoundaryDataAttribution } from '@/store/modules/map/layers/hmlr'
import { MatterTitleBoundaryLayerGroup } from '@/store/modules/map/layers/title-boundary-layer/layer-group'
import { MAP_MUTATE_DISABLED } from '@/store/modules/map/types'
import {
    SUBSCRIBE_TO_MATTER_HUB,
    UNSUBSCRIBE_FROM_MATTER_HUB,
} from '@/store/modules/matter-hub/types'
import {
    NPS_GET_FEATURES_BY_TITLE_NUMBER,
    NPS_MUTATE_CLEAR_FEATURES,
} from '@/store/modules/nps/types'
import {
    ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE,
    MATTER_TITLE_ORGANISER_APPLY_CHANGES,
    MATTER_TITLE_ORGANISER_INITIALISE_MAP,
    MATTER_TITLE_ORGANISER_RELOAD_MATTER_CONTENT,
    MATTER_TITLE_ORGANISER_RESET_MAP,
} from '@/store/modules/organisation-hub/types'
import { SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER } from '@/store/modules/site-visit/types'
import {
    SKETCHES_LOAD_BY_MATTER_ID,
    SKETCHES_RESET,
} from '@/store/modules/sketches/types'
import {
    TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL,
    TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL,
    TITLE_MUTATE_SELECTED_TITLE_NUMBER,
    TITLE_MUTATE_SET_TITLES,
    TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL,
} from '@/store/modules/titles/types'
import {
    LOGGING_HEAP_TRACK_EVENT,
    LOGGING_INTERCOM_TRACK_EVENT,
    LOGGING_LOG_AXIOS_ERROR,
    LOGGING_LOG_FEATURE_USAGE,
    LOGGING_SET_STATE_PROPERTIES,
    USER_SHOW_POPUP,
} from '@/store/mutation-types'
import {useAssetMonitoringStore} from "@/stores/asset-monitoring"
import {     chunkArray,
    isNullOrEmpty } from '@/utils/array-utils'
import { getLinkToMatter } from '@/utils/link-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

import {
    DOCUMENTS_MUTATE_LIBRARY_DOCUMENTS,
    POLL_FOR_UPDATES,
    REFRESH_DOCUMENTS,
} from '../documents/documents-types'
import {
    MATTER_ADD_MULTIPLE_TITLES,
    MATTER_ADD_TITLE,
    MATTER_ADD_TITLES_TO_GROUP,
    MATTER_ADD_TITLES_TO_MATTER_AND_GROUP,
    MATTER_AVAILABLE_SERVICES_INIT,
    MATTER_CLEAR_CURRENT_MATTER,
    MATTER_CREATE,
    MATTER_CREATE_GROUP,
    MATTER_CREATE_LINK_SHARE,
    MATTER_DELETE,
    MATTER_DUPLICATE,
    MATTER_ENABLE_LINK_SHARE,
    MATTER_EXPORT_OFFICIAL_COPIES,
    MATTER_EXPORT_SPREADSHEET_DAYLIST,
    MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
    MATTER_FETCH_ADDRESSES_BY_MATTER_ID,
    MATTER_FETCH_DIGITAL_REGISTER_AVAILABILITY_FOR_TITLE_NUMBERS,
    MATTER_GET_ADDRESSES_FOR_TITLES,
    MATTER_GET_MATTER_CONTENTS,
    MATTER_GROUP_SET_EXPANDED,
    MATTER_HANDLE_SET_CURRENT_MATTER_ERROR,
    MATTER_HIGHLIGHT_BOUNDARY,
    MATTER_IMPORT_FROM_SPREADSHEET,
    MATTER_IMPORT_FROM_WKT_AREA,
    MATTER_INITIALISE_BOUNDARY_LAYER,
    MATTER_MUTATE_BULK_ORDER_LOADING,
    MATTER_MUTATE_BULK_ORDER_RESPONSE,
    MATTER_MUTATE_CURRENT_MATTER,
    MATTER_MUTATE_CURRENT_MATTER_ADDRESSES,
    MATTER_MUTATE_CURRENT_MATTER_STATE,
    MATTER_MUTATE_DUPLICATE_MATTER_ID,
    MATTER_MUTATE_GROUP_SET_EXPANDED,
    MATTER_MUTATE_GROUPS,
    MATTER_MUTATE_LINK_SHARING_DETAILS,
    MATTER_MUTATE_LINK_SHARING_ENABLED,
    MATTER_MUTATE_LINK_SHARING_LOADING,
    MATTER_MUTATE_LIST_ITEM_MATTER_STATE_BY_MATTER_ID,
    MATTER_MUTATE_LOADING,
    MATTER_MUTATE_LOADING_STATE,
    MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER,
    MATTER_MUTATE_MATTER_READ_STATUS,
    MATTER_MUTATE_PENDING_LOADING_MATTER_ID,
    MATTER_MUTATE_RESET_CURRENT_MATTER,
    MATTER_MUTATE_SELECTED_TITLES,
    MATTER_MUTATE_SKETCHES_SETTINGS,
    MATTER_MUTATE_SKETCHES_VISIBLE_MAP,
    MATTER_MUTATE_SORT_ORDER_FOR_ALL_GROUPS,
    MATTER_MUTATE_TITLE,
    MATTER_MUTATE_TITLE_ORGANISER_LOADING,
    MATTER_MUTATE_TITLE_ORGANISER_MATTER_CONTENT,
    MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE,
    MATTER_MUTATE_UPDATE_STATE_ERROR,
    MATTER_MUTATE_WALKTHROUGH_ID,
    MATTER_MUTATE_ZOOM_TO_EXTENT_ON_TITLE_BOUNDARIES_LOADED,
    MATTER_PROMPT_CREATE,
    MATTER_REFRESH_PERMISSIONS,
    MATTER_REMOVE_GROUP,
    MATTER_REMOVE_HIGHLIGHT_BOUNDARY,
    MATTER_REMOVE_TITLES,
    MATTER_REMOVE_TITLES_FROM_GROUP,
    MATTER_REQUEST_SHOW_TITLE_LIST,
    MATTER_RESET_CURRENT,
    MATTER_SELECT_TITLENUMBER,
    MATTER_SET_BOUNDARY_HIGHLIGHT_VISIBLE,
    MATTER_SET_CURRENT_BY_ID,
    MATTER_SET_CURRENT_MATTER,
    MATTER_SET_TITLES_STYLE,
    MATTER_SHOW_BOUNDARIES,
    MATTER_UPDATE,
    MATTER_UPDATE_BOUNDARY_LAYER,
    MATTER_UPDATE_BOUNDARY_LAYER_TITLES,
    MATTER_UPDATE_CURRENT_MATTER,
    MATTER_UPDATE_CURRENT_MATTER_CHARGES,
    MATTER_UPDATE_GROUP,
    MATTER_UPDATE_GROUP_SORT,
    MATTER_UPDATE_LINK_SHARE,
    MATTER_UPDATE_MATTER_READ_STATUS,
    MATTER_UPDATE_MATTER_STATE,
    MATTER_UPDATE_PERMISSIONS,
    MATTER_UPDATE_SKETCHES_SETTINGS,
    MATTER_UPDATE_SKETCHES_VISIBLE_MAP,
    MATTER_UPDATE_TITLE_SORT,
    MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT,
} from './types'

export default {
    async [MATTER_CREATE]({
        commit,
        dispatch,
        state,
    }, model) {
        state.creatingMatter = true

        commit(MATTER_MUTATE_LOADING, true)
        const response = await MatterApi.createMatter(model)

        commit(MATTER_MUTATE_LOADING, false)

        state.creatingMatter = false

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)

            throw new Error(response.message)
        } else {
            // TODO: more mutations!
            state.prompts.create.show = false
            state.prompts.create.asFirst = false
            const linkToMatter = getLinkToMatter(response.data.id)
            dispatch(LOGGING_INTERCOM_TRACK_EVENT, {
                type: 'matter-created',
                metadata: {
                    matterName: model.name,
                    matterId: response.data.id,
                    transactionType: model.type,
                    linkToMatter,
                    tier: model.tier,
                    hasMatterCode: !isNullOrWhitespace(model.matterCode),
                    hasClientCode: !isNullOrWhitespace(model.clientCode),
                },
            })
        }

        return response.data
    },

    async [MATTER_UPDATE]({
        commit,
        dispatch,
        state,
    }, {
        matter,
        isNameUpdateOnly,
    }) {
        commit(MATTER_MUTATE_LOADING_STATE, {
            key: isNameUpdateOnly ? 'updateMatterName' : 'updateMatterDetails',
            value: true,
        })
        commit(MATTER_MUTATE_LOADING, true)
        const response = await MatterApi.updateMatter(matter)
        commit(MATTER_MUTATE_LOADING, false)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        } else {
            await dispatch(MATTER_UPDATE_CURRENT_MATTER, matter)
            state.prompts.update.show = false
        }
        commit(MATTER_MUTATE_LOADING_STATE, {
            key: isNameUpdateOnly ? 'updateMatterName' : 'updateMatterDetails',
            value: false,
        })
        return response.data
    },


    async [MATTER_DUPLICATE]({ commit, dispatch }, { matterId, newMatterName, newMatterCode }) {
        const response = await MatterApi.duplicateMatter({
            matterId: matterId,
            matterName: newMatterName,
            matterCode: newMatterCode,
        })
        if (!response?.ok) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response?.details)
        } else if (response?.data?.matterId) {
            commit(MATTER_MUTATE_DUPLICATE_MATTER_ID, response.data.matterId)
        }
    },


    [MATTER_UPDATE_CURRENT_MATTER]({
        state,
        commit,
    }, updates) {
        // Only updates top level properties. Currently only used when
        // updating name, matter/client code or transaction type
        state.currentMatter = {
            ...state.currentMatter,
            ...updates,
        }

        // Discard title boundary features
        commit(NPS_MUTATE_CLEAR_FEATURES)
    },

    // TODO: This is a mutation!
    [MATTER_PROMPT_CREATE]: function(context, asFirst = false) {
        context.state.prompts.create.show = true
        context.state.prompts.create.asFirst = asFirst
    },

    async [MATTER_SET_CURRENT_BY_ID]({
        commit,
        dispatch,
        state,
    }, matterId) {
        // Only need to set the matter if the requested matter is not already loading or the same as current matter
        if (parseInt(matterId) === state.pendingLoadingMatterId ||
            parseInt(matterId) === state.currentMatter.id) {
            return
        }

        if (matterId != null) {
            commit(MATTER_MUTATE_LOADING_STATE, {
                key: 'loadingCurrentMatter',
                value: true,
            })

            if (state.matterHub?.hub && state.matter.currentMatter?.id) {
                await dispatch(UNSUBSCRIBE_FROM_MATTER_HUB, null, { root: true })
            }
            commit(MATTER_MUTATE_PENDING_LOADING_MATTER_ID, parseInt(matterId))

            try {
                const walkthroughIdResponse = await WalkthroughsApi.getWalkthroughIdByMatterId(matterId)
                commit(MATTER_MUTATE_WALKTHROUGH_ID, walkthroughIdResponse.data)
            } catch (err) {
                await dispatch(MATTER_SET_CURRENT_MATTER, null)
                dispatch(LOGGING_LOG_AXIOS_ERROR, err)
            }

            try {
                commit(MATTER_MUTATE_LOADING, true)
                const currentMatter = await MatterApi.setCurrentMatter(matterId)
                commit(MATTER_MUTATE_LOADING, false)
                commit(MATTER_MUTATE_PENDING_LOADING_MATTER_ID, null)
                commit(MATTER_MUTATE_LOADING_STATE, {
                    key: 'loadingCurrentMatter',
                    value: false,
                })

                if (currentMatter.error) {
                    await router.push({ name: Route.MattersList })
                }

                await dispatch(MATTER_SET_CURRENT_MATTER, { currentMatter })
                dispatch(POLL_FOR_UPDATES, null, { root: true })

                await dispatch(SUBSCRIBE_TO_MATTER_HUB, {
                    hub: global.app.config.globalProperties.$matterNotifications,
                    matterId,
                },
                { root: true })

                return currentMatter
            } catch (err) {
                await dispatch(MATTER_HANDLE_SET_CURRENT_MATTER_ERROR, err)
            }
            commit(MATTER_MUTATE_LOADING_STATE, {
                key: 'loadingCurrentMatter',
                value: false,
            })
        } else {
            await dispatch(MATTER_SET_CURRENT_MATTER, null)
            await dispatch(REFRESH_DOCUMENTS, null, { root: true })
        }
    },

    // TODO: Could probably be using the MATTER_SET_CURRENT_BY_ID action and cut down on repeated code!
    async [MATTER_RESET_CURRENT]({
        commit,
        dispatch,
        state,
    }, { forceReset = false,
        resetZoom = true } = {}) {
        commit(MAP_MUTATE_DISABLED, false)

        if ((parseInt(state.currentMatter.id) === state.pendingLoadingMatterId) && forceReset === false) {
            return
        }

        try {
            commit(MATTER_MUTATE_PENDING_LOADING_MATTER_ID, parseInt(state.currentMatter.id))
            commit(MATTER_MUTATE_LOADING, true)
            const response = await MatterApi.setCurrentMatter(state.currentMatter.id)
            commit(MATTER_MUTATE_LOADING, false)
            commit(MATTER_MUTATE_PENDING_LOADING_MATTER_ID, null)

            if (response?.ok === false) {
                await dispatch(MATTER_HANDLE_SET_CURRENT_MATTER_ERROR, response.message)
            } else {
                await dispatch(MATTER_SET_CURRENT_MATTER, { currentMatter: response, resetZoom })
                dispatch(POLL_FOR_UPDATES, null, { root: true })
            }
            return response
        } catch (err) {
            await dispatch(MATTER_HANDLE_SET_CURRENT_MATTER_ERROR, err)
        }
    },

    async [MATTER_HANDLE_SET_CURRENT_MATTER_ERROR]({
        commit,
        dispatch,
    }, error) {
        await dispatch(MATTER_SET_CURRENT_MATTER, null)
        commit(MATTER_MUTATE_LOADING, false)
        commit(MATTER_MUTATE_PENDING_LOADING_MATTER_ID, null)
        dispatch(LOGGING_LOG_AXIOS_ERROR, error)
        console.error('Unable to set current matter', error)
        dispatch(USER_SHOW_POPUP, {
            title: i18n.global.t('error.title'),
            contentHTML: i18n.global.t('error.errorSettingMatter'),
        })
    },

    async [MATTER_EXPORT_SPREADSHEET_DAYLIST]({
        dispatch,
        state,
    }) {
        const result = state.prompts.export.titles.map(a => a.titleNumber)

        const selectedFields = []

        const data = {
            titleNumbers: result,
            selectedFields,
            matterId: state.currentMatter.id,
        }

        const response = await ApplicationEnquiryApi.getMultipleBackDatedApplicationsAsCsv(data)

        if (response.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        } else {
            await BusinessGatewayApi.saveBulkDayListQueryResultsCsvFile(response.data)
            dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES)
            dispatch(REFRESH_DOCUMENTS, null, { root: true })
        }

        return response
    },

    async [MATTER_ADD_MULTIPLE_TITLES]({
        commit,
        dispatch,
        state,
    }, request) {
        request.matterId = request.matterId ?? state.currentMatter.id
        if (request.matterId == null) {
            return
        }

        request.showPopup = request.showPopup ?? true

        commit(MATTER_MUTATE_LOADING, true)
        const response = await MatterApi.addTitlesToMatter(request)
        commit(MATTER_MUTATE_LOADING, false)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        } else {
            // only do this awful refresh if in a matter! - TODO: add methods to support (partial?) refresh of the titles list.
            if (state.currentMatter.id) {
                await dispatch(MATTER_RESET_CURRENT)
            }

            if (request.showPopup) {
                dispatch(USER_SHOW_POPUP, {
                    title: 'Titles added',
                    icon: '$info',
                    contentHTML: `<p>${ response?.message ? response.message : 'Unable to add titles to matter' }</p>`,
                    testName: 'add-multiple-titles-success',
                })
            }
        }

        return response
    },

    async [MATTER_ADD_TITLE]({
        commit,
        dispatch,
        state,
    }, request) {
        const serviceRequest = {
            matterId: state.currentMatter.id,
            titles: [],
        }

        if (state.currentMatter.id == null) {
            return
        }

        let hasTitleAlready = false

        state.currentMatter.selectedTitles.forEach((t) => {
            if (t.titleNumber === request.titleNumber) {
                hasTitleAlready = true
            }
        })
        state.titlesBeingAddedToCurrentMatter.forEach((t) => {
            if (t === request.titleNumber) {
                hasTitleAlready = true
            }
        })

        const titlesRequest = {
            titleNumber: request.titleNumber,
        }

        if (hasTitleAlready === false && request.titleNumber) {
            // populate default properties
            titlesRequest.showTitleNumber = (request.showTitleNumber === undefined) ? true : request.showTitleNumber
            titlesRequest.fill = (request.fill === undefined) ? false : request.fill
            titlesRequest.strokeWidth = (request.strokeWidth === undefined) ? 3 : request.strokeWidth
            titlesRequest.colour = (request.colour === undefined) ? '#F44336' : request.colour
            titlesRequest.label = null
            const defaultNewIndex = (state.titlesBeingAddedToCurrentMatter.length + state.currentMatter.selectedTitles.length + 1) * 100
            titlesRequest.sortOrder = (request.sortOrder === undefined) ? defaultNewIndex : request.sortOrder
            serviceRequest.titles.push(titlesRequest)

            // add title
            // TODO: This is a mutation!
            state.titlesBeingAddedToCurrentMatter.push(request.titleNumber)
            if (serviceRequest.titles) {
                const response = await MatterApi.setMatterTitles(serviceRequest)
                if (response?.ok !== false && !isNullOrEmpty(response)) {
                    response.forEach((item) => {
                        const title = Object.assign(item, { selected: false })
                        commit('_addCurrentMatterTitle', title)
                    })
                    await state.titlesLayer?.addBoundaryForTitleNumbers([request.titleNumber])
                    const removeIndex = state.titlesBeingAddedToCurrentMatter.indexOf(request.titleNumber)
                    if (removeIndex !== -1) {
                        state.titlesBeingAddedToCurrentMatter.splice(removeIndex, 1)
                    }
                    await dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES)
                    await dispatch(MATTER_GET_ADDRESSES_FOR_TITLES, [request.titleNumber])
                }
            }
        }
    },

    async [MATTER_REMOVE_TITLES]({
        dispatch,
        state,
        rootState,
        getters,
    }, titleNumbers) {
        if (state.currentMatter.id == null) {
            return
        }

        // TODO: this is changing the state, so should be a mutation. Think it can be done neater with filter too
        titleNumbers.forEach(titleNumber => {
            let itemIndex = -1

            state.currentMatter.selectedTitles.forEach((t, index) => {
                if (t.titleNumber === titleNumber) {
                    itemIndex = index
                }
            })

            state.currentMatter.selectedTitles.splice(itemIndex, 1)
        })

        const request = {
            matterId: state.currentMatter.id,
            titleNumbers,
        }
        const response = await MatterApi.removeTitlesFromMatter(request)
        state.titlesLayer.removeBoundaryForTitleNumbers && state.titlesLayer.removeBoundaryForTitleNumbers(titleNumbers)
        if (rootState.title.selectionLayer) {
            titleNumbers.forEach(tn => {
                const features = getters[NPS_GET_FEATURES_BY_TITLE_NUMBER](tn)
                rootState.title.selectionLayer.getSource().removeFeature(features)
            })
            rootState.title.selectionLayer.getSource().changed()
        }
        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        }
    },

    async [MATTER_DELETE]({
        commit,
        dispatch,
    }, matterId) {
        const request = {
            matterId,
        }

        commit(MATTER_MUTATE_LOADING, true)
        commit(MATTER_MUTATE_LOADING_STATE, {
            key: 'deleteMatter',
            value: true,
        })
        const response = await MatterApi.deleteMatter(request)
        commit(MATTER_MUTATE_LOADING, false)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        } else {
            await dispatch(MATTER_SET_CURRENT_MATTER, null)
        }
        commit(MATTER_MUTATE_LOADING_STATE, {
            key: 'deleteMatter',
            value: false,
        })

        return response
    },

    [MATTER_SET_TITLES_STYLE]({
        dispatch,
        state,
        rootState,
    }, titles) {
        if (state.updateMainMapOnStylePromptChanges === true) {
            rootState.title.selectionLayer?.getSource().changed()

            // Update titles layer
            state.titlesLayer?.refresh(titles.map(x => x.titleNumber))
            dispatch(MATTER_UPDATE_BOUNDARY_LAYER_TITLES, titles)
        }
    },

    async [MATTER_SHOW_BOUNDARIES]({
        state,
    }, request) { // {title: object, show: boolean, skipStyleUpdate: boolean}
        request.titles.forEach(title => {
            const titleInState = state.currentMatter.selectedTitles.find(x => x.titleNumber === title.titleNumber)
            if (titleInState) {
                titleInState.show = request.show
            }
        })
        if (request.show) {
            state.titlesLayer.addBoundaryForTitleNumbers(request.titles.map(x => x.titleNumber), request.skipStyleUpdate)
        } else {
            state.titlesLayer.removeBoundaryForTitleNumbers(request.titles.map(x => x.titleNumber), request.skipStyleUpdate)
        }
    },

    [MATTER_UPDATE_BOUNDARY_LAYER_TITLES]({
        dispatch,
        getters,
        state,
    }, titles) { // used to reduce number of requests when updating matter title
        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            if (state.titlesLayer) {
                state.titlesLayer.refresh(titles.map(x => x.titleNumber))
                return
            }
        }
        // Update street view visualisation
        dispatch(SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER)
    },

    // NOTE: There is a duplicated action which does not reset the zoom level due to BUG DD-3319
    async [MATTER_SET_CURRENT_MATTER]({
        commit,
        dispatch,
        state,
    }, { currentMatter, resetZoom = true }) {
        console.info('matter set current matter', currentMatter)
        if (currentMatter == null) {
            commit(MATTER_MUTATE_RESET_CURRENT_MATTER)
            return
        }
        commit(MATTER_MUTATE_LOADING, true)
        commit(TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL, false)

        // reset documents library on matter change
        commit(DOCUMENTS_MUTATE_LIBRARY_DOCUMENTS, [])

        if (isNullOrEmpty(currentMatter?.selectedTitles)) {
            currentMatter.selectedTitles = []
        }

        if (isNullOrEmpty(currentMatter?.groups)) {
            currentMatter.groups = []
        }

        // The returned value will include the charges for the matter
        currentMatter.loadingCharges = false

        currentMatter.groups.forEach(group => group.selected = false)
        currentMatter.selectedTitles.forEach(title => {
            title.selected = false
            title.showOptionsMenu = false
            title.showGroupMenu = false
            dispatch('_applyTitleZIndex', title)
        })

        // Update matter properties based on those provided
        commit(MATTER_MUTATE_CURRENT_MATTER, currentMatter)

        // get current matter addresses
        if (currentMatter.selectedTitles.length <= TITLE_LIMIT) {
            await dispatch(MATTER_FETCH_ADDRESSES_BY_MATTER_ID, currentMatter.id)
        }

        // NOTE: Don't reset zoom if the route is on an opened title
        const zoomToExtent = resetZoom && router.currentRoute?.value?.name !== Route.MatterMapTitle
        await state.titlesLayer?.reload(zoomToExtent)

        await dispatch(MATTER_UPDATE_BOUNDARY_LAYER)

        // Add matter details to logging information
        await dispatch(LOGGING_SET_STATE_PROPERTIES, {
            matterId: state.currentMatter.id,
            matterType: state.currentMatter.type,
        })

        // Link share details loaded along with the rest of the matter metadata.
        state.currentMatter.loading.linkShare = false

        // Load sketches if required.
        dispatch(SKETCHES_RESET)
        if (state.currentMatter.sketchesVisibleMap) {
            dispatch(SKETCHES_LOAD_BY_MATTER_ID, currentMatter.id, { root: true })
        }

        // Get asset monitoring notifications when initialising a matter so we can display unread notifications dot on the LHN
        const assetMonitoringStore = useAssetMonitoringStore()
        assetMonitoringStore.initialise(currentMatter.id)

        commit(MATTER_MUTATE_LOADING, false)
    },

    // TODO: Should be using types
    _applyTitleZIndex({ state }, title) {
        // Set a z-index for display purposes
        const titleGroup = state.currentMatter.groups.find(g => {
            return g.id === title.matterGroupId
        })
        let zIndex = 0
        if (titleGroup !== undefined) {
            zIndex = (titleGroup.sortOrder * 10000)
        }
        zIndex = zIndex + (10000 - title.sortOrder)
        title.zIndex = zIndex
    },

    async [MATTER_ZOOM_TO_CURRENT_MATTER_EXTENT]({
        dispatch,
        commit,
    }) {
        // zoom to extent of selected features
        commit(MATTER_MUTATE_ZOOM_TO_EXTENT_ON_TITLE_BOUNDARIES_LOADED, true)
        await dispatch(MATTER_UPDATE_BOUNDARY_LAYER)
    },

    async [MATTER_UPDATE_CURRENT_MATTER_CHARGES]({
        dispatch,
        state,
    }) {
        if (state.debounceUpdateCharges === null) {
            state.debounceUpdateCharges = debounce(async () => {
                if (state.currentMatter.axiosCancelChargesRequest != null) {
                    state.currentMatter.axiosCancelChargesRequest()
                    // TODO: this is a mutation!
                    state.currentMatter.axiosCancelChargesRequest = null
                }

                if (state.currentMatter.id != null) {
                    // TODO: this is a mutation!
                    state.currentMatter.loadingCharges = true
                    state.currentMatter.totalDispursableChargesUpdated = false

                    const response = await MatterApi.updateCharges(state.currentMatter.id)
                    // TODO: this is a mutation!
                    state.currentMatter.loadingCharges = false

                    if (response?.ok === false) {
                        dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
                    } else {
                        for (const charge in response) {
                            if (state.currentMatter.charges[charge] !== response[charge]) {
                                state.currentMatter.totalDispursableChargesUpdated = true
                            }

                            state.currentMatter.charges[charge] = response[charge]
                        }
                    }
                    return response
                }
            }, 5000) // shouldn't need to refresh charges more than once every 5 seconds.
        }
        state.debounceUpdateCharges()
    },

    async [MATTER_INITIALISE_BOUNDARY_LAYER]({ rootState, state, commit, dispatch }) {
        if (rootState.map.nps.layer) {
            if (state.titlesLayer) {
                state.titlesLayer.refresh()
            } else {
                state.titlesLayer = shallowRef(new MatterTitleBoundaryLayerGroup({
                    useIncreasedPointRenderingLimit: rootState.config?.featureFlags?.increasedPointRenderingLimit,
                    getMatterIdFn: () => state.currentMatter.id,
                    getTitlesDataFn: () => state.currentMatter.selectedTitles,
                    onTitleBoundaryClickFn: (titleNumbers) => {
                        if (rootState.map.preventTitleBoundaryDeselection) {
                            return
                        }
                        titleNumbers = [...new Set(titleNumbers)]
                        if (titleNumbers.length === 0) {
                            dispatch(TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL)
                        } else if (titleNumbers.length === 1) {
                            commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, titleNumbers[0])
                            const elem = document.querySelector(`[data-titlenumber="${ titleNumbers[0] }"]`)
                            if (elem) {
                                elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
                            }
                        } else {
                            const filteredTitles = state.currentMatter.selectedTitles
                                .filter(title => titleNumbers.includes(title.titleNumber))
                                .map(title => {
                                    return {
                                        titleNumber: title.titleNumber,
                                        tenure: title.tenure,
                                        addresses: title.addresses,
                                        selected: false,
                                    }
                                })
                            commit(TITLE_MUTATE_SET_TITLES, filteredTitles)
                            dispatch(TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL)
                        }
                    },
                })).value
                await state.titlesLayer.addToMap(rootState.map.map)
                state.titlesLayer.setVisible(true)
                state.titlesLayer.boundaryLayer.setHighlightBoundaryOnHover(state.titlesLayerShowBoundaryOnHover)
            }
            console.info('Matter title layer initialised')
        }
    },

    async [MATTER_UPDATE_BOUNDARY_LAYER]({
        rootState,
        state,
    }) {
        // Feature flags aren't loaded yet
        if (rootState.config.featureFlags === undefined) {
            return
        }

        // Map isn't loaded yet
        if (rootState.map.map == null) {
            return
        }

        state.titlesLayer?.refresh()

        rootState.map.map.addLayer(new VectorLayer({
            source: new VectorSource({
                attributions: [
                    getBoundaryDataAttribution(rootState.config.settings.monthlyHMLRDataVersion),
                ],
            }),
        }))
    },

    async [MATTER_REFRESH_PERMISSIONS]({
        dispatch,
        getters,
        state,
    }, matterId) {
        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            if (!matterId) {
                return
            }

            const response = await MatterApi.refreshPermissions(matterId)
            if (response?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            } else {
                // TODO: this is a mutation!
                state.currentMatterPermissions = response
            }

            return response
        }
    },

    async [MATTER_UPDATE_PERMISSIONS]({
        commit,
        dispatch,
    }, request) {
        commit(MATTER_MUTATE_LOADING, true)
        const response = await MatterApi.updatePermissions(request)
        commit(MATTER_MUTATE_LOADING, false)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        } else {
            let update
            if (request?.userIds.length) {
                update = {
                    sharedWithSpecificUsers: true,
                    sharedWithOrganisation: false,
                }
            } else if (request.shareWithOrganisation) {
                update = {
                    sharedWithSpecificUsers: false,
                    sharedWithOrganisation: true,
                }
            } else {
                update = {
                    sharedWithSpecificUsers: false,
                    sharedWithOrganisation: false,
                }
            }
            await dispatch(MATTER_UPDATE_CURRENT_MATTER, update)
        }

        return response
    },

    async [MATTER_CREATE_GROUP]({
        dispatch,
        state,
    }, request) {
        const response = await MatterApi.createGroup(request)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            return null
        }

        if (request.matterId === state.currentMatter.id) {
            response.selected = false
            // TODO: This is a mutation (maybe should be this whole if statement?)
            state.currentMatter.groups.push(response)

            return response
        }
    },

    async [MATTER_UPDATE_GROUP]({
        dispatch,
        getters,
    }, request) {
        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            const response = await MatterApi.UpdateGroup(request)

            if (response?.ok === false || response === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            }
        }
    },

    /**
     * Delete an existing group within the matter
     * If `removeTitles` is true, then the titles within the group
     * will also be deleted.
     * @param commit - from vuex
     * @param dispatch - from vuex
     * @param state - from vuex
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          group: {
     *              id: 1234,
     *              name: 'Group Name',
     *              sortOrder: 100,
     *              selected: true/false,
     *          }
     *          removeTitles: true/false,
     *          noRefresh: true/false,
     *      }
     * @return {Promise<null|any>}
     */
    async [MATTER_REMOVE_GROUP]({
        dispatch,
        state,
    }, request) {
        const matterGroupId = request.group.id

        const response = await MatterApi.DeleteGroup({ matterGroupId })

        if (response?.ok === false || response === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            return null
        }

        if (request.removeTitles === true) {
            const titlesInGroup = state.currentMatter.selectedTitles.filter(title => title.matterGroupId === matterGroupId)
            await dispatch(MATTER_REMOVE_TITLES, titlesInGroup.map(title => title.titleNumber))
        } else {
            state.currentMatter.selectedTitles.forEach((t) => {
                if (t.matterGroupId === matterGroupId) {
                    t.matterGroupId = null
                }
            })
        }

        // Remove group
        const groupIndex = state.currentMatter.groups.indexOf(request.group)
        state.currentMatter.groups.splice(groupIndex, 1)

        /// Update everything
        if (request.noRefresh !== true) {
            await dispatch(MATTER_RESET_CURRENT)
        }
    },

    /**
     * Gets the addresses for the specified titles.
     * @param commit
     * @param dispatch
     * @param state
     * @param titleNumbers {Array}
     * @return Response {Object} from the server
     */
    async [MATTER_GET_ADDRESSES_FOR_TITLES]({
        commit,
        dispatch,
    }, titleNumbers) {
        const response = await MatterApi.getTitleAddresses(titleNumbers)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            return null
        }

        commit(MATTER_MUTATE_CURRENT_MATTER_ADDRESSES, response)
        return response
    },

    async [MATTER_FETCH_ADDRESSES_BY_MATTER_ID]({
        commit,
    }, matterId) {
        if (!isNullOrWhitespace(matterId)) {
            const addresses = await MatterApi.getTitleAddressesForMatter(matterId)

            commit(MATTER_MUTATE_CURRENT_MATTER_ADDRESSES, addresses)
        }
    },

    async [MATTER_ADD_TITLES_TO_GROUP]({
        dispatch,
        getters,
    }, request) {
        const data = {
            matterGroupId: request.matterGroupId,
            titleNumbers: request.titles.map(title => title.titleNumber),
        }
        request.titles.forEach(title => {
            title.matterGroupId = request.matterGroupId
            title.selected = false
        })

        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            const response = await MatterApi.addTitlesToGroup(data)

            if (response?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
                return null
            }

            return response
        }
    },

    async [MATTER_ADD_TITLES_TO_MATTER_AND_GROUP]({
        commit,
        dispatch,
        getters,
        state,
    }, request) {
        // add the titles to the group
        const data = {
            matterGroupId: request.matterGroupId,
            titleNumbers: request.titles.map(title => title.titleNumber),
        }
        request.titles.forEach(title => {
            title.matterGroupId = request.matterGroupId
            title.selected = false
        })

        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            // Do we need to add the titles first (if they're not already in the matter)?
            const titlesToAdd = request.titles.filter(title => {
                return state.currentMatter.selectedTitles.find(t => t.titleNumber === title.titleNumber) === undefined
            })
            if (titlesToAdd.length > 0) {
                const titlesToAddResponse = await dispatch(MATTER_ADD_MULTIPLE_TITLES, {
                    titleNumbers: titlesToAdd.map(
                        (title) => title.titleNumber,
                    ),
                    showPopup: false,
                })

                if (titlesToAddResponse?.ok === false) {
                    dispatch(LOGGING_LOG_AXIOS_ERROR, titlesToAddResponse.message)
                    return null
                }
            }

            // Now add the titles to the group
            const addTitlesToGroupResponse = await MatterApi.addTitlesToGroup(data)

            if (addTitlesToGroupResponse?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, addTitlesToGroupResponse.message)
                return null
            }
            request.titles.forEach(title => {
                commit(MATTER_MUTATE_TITLE, {
                    titleNumber: title.titleNumber,
                    matterGroupId: title.matterGroupId,
                })
            })

            if (request.showPopup) {
                const groupName = state.currentMatter.groups.find(g => g.id === data.matterGroupId).name ?? 'group'
                const titlesAddedToGroupMessage = `<div>Added ${ data.titleNumbers.length } titles to ${ groupName }</div>`
                dispatch(USER_SHOW_POPUP, {
                    title: 'Titles added to group',
                    icon: '$info',
                    contentHTML: `${ titlesAddedToGroupMessage }`,
                    testName: 'add-multiple-titles-success',
                })
            }

            return addTitlesToGroupResponse
        }
    },

    async [MATTER_REMOVE_TITLES_FROM_GROUP]({
        dispatch,
        getters,
    }, request) { // {matterGroupId/matterId: x, titles: []}
        const data = {
            matterId: request.matterId,
            matterGroupId: request.matterGroupId,
            titleNumbers: request.titles.map(title => title.titleNumber),
        }
        request.titles.forEach(title => {
            title.matterGroupId = null
        })

        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            const response = await MatterApi.deleteTitlesFromGroup(data)

            if (response?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
                return null
            }

            return response
        }
    },

    [MATTER_SELECT_TITLENUMBER]({ state }, titleNumber) {
        const title = state.currentMatter.selectedTitles.find(t => t.titleNumber === titleNumber)
        title.selected = true
    },

    async [MATTER_EXPORT_OFFICIAL_COPIES]({
        commit,
        dispatch,
        state,
    }, request) {
        commit(MATTER_MUTATE_BULK_ORDER_LOADING, true)

        // {titleNumbers: [], registers: true, titleplans: true}
        request.matterId = state.currentMatter.id
        const response = await BusinessGatewayApi.exportOfficialCopies(request)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            return null
        }

        /* Some registers may not have been ordered successfully, the response will contain this information,
         * we don't need to wait until those have processed. */
        const failedToOrderTitleNumbers = response.messages?.map(message => message.titleNo) ?? []
        const titleNumbersOrdered = request.titleNumbers.filter(titleNumber => !failedToOrderTitleNumbers.includes(titleNumber))
        const filename = response.filename

        const errorMessages = response.messages?.map(message => `${ message.titleNo }: ${ message.message }`) ?? []

        commit(MATTER_MUTATE_BULK_ORDER_RESPONSE, {
            failedToOrderTitleNumbers,
            titleNumbersOrdered,
            errorMessages,
            filename,
        })

        commit(MATTER_MUTATE_BULK_ORDER_LOADING, false)
    },

    async [MATTER_EXPORT_TITLE_ANALYSIS_REPORT]({
        state,
    }, { titleNumbers, generatedMatterId = undefined, shouldMergeCellsByTitleNumber = true}) {
        const matterId = generatedMatterId || state.currentMatter.id
        return await ReportingApi.downloadTitleAnalysisReport(matterId, titleNumbers, shouldMergeCellsByTitleNumber)
    },

    // TODO: This is a mutation!
    [MATTER_REQUEST_SHOW_TITLE_LIST]({ state }) {
        state.pendingRequestToShowTitleList = true
    },

    async [MATTER_UPDATE_TITLE_SORT]({
        commit,
        dispatch,
        getters,
        state,
    }, request) { // request - array of {titleNumber: 'xxx', sortOrder: 1500}
        const data = {
            matterId: state.currentMatter.id,
            items: request.filter(x => x.sortOrder !== null),
        }
        let hasVisibleTitle = false
        request.forEach(r => {
            const title = state.currentMatter.selectedTitles.find(t => {
                return t.titleNumber === r.titleNumber
            })
            if (title !== undefined && r.sortOrder !== null) {
                title.sortOrder = r.sortOrder

                // Update the draw order of the title boundary
                dispatch('_applyTitleZIndex', title)
                if (title.show === true) {
                    hasVisibleTitle = true
                }
            }
        })

        // update layer to reflect the new draw order, assuming there are visible title boundaries
        if (hasVisibleTitle === true) {
            // TODO: This should be a mutation or separate action I think?
            if (state.tilesLayerSource) {
                state.titlesLayerSource.changed()
            }
        }

        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            const response = await MatterApi.updateTitleSortOrder(data)

            if (response?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
                return null
            }

            data.items.forEach(({ titleNumber, sortOrder }) => commit(MATTER_MUTATE_TITLE, { titleNumber, sortOrder }))

            return response
        }
    },

    async [MATTER_UPDATE_MATTER_READ_STATUS]({
        commit,
        dispatch,
    }, request) {
        const response = await MatterApi.updateMatterReadStatus(request.matterId, request.isMatterRead)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
            return null
        }

        commit(MATTER_MUTATE_MATTER_READ_STATUS, request)

        return response
    },

    async [MATTER_UPDATE_GROUP_SORT]({
        commit,
        dispatch,
        getters,
        state,
    }, request) { // request - array of {groupId: 44, sortOrder: 1500}
        const data = {
            matterId: state.currentMatter.id,
            items: request,
        }
        commit(MATTER_MUTATE_SORT_ORDER_FOR_ALL_GROUPS, request)
        const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
        if (!isSharedLinkView) {
            const response = await MatterApi.updateGroupTitleSortOrder(data)

            if (response?.ok === false) {
                dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
                return null
            }

            return response
        }
    },

    async [MATTER_HIGHLIGHT_BOUNDARY]({
        state,
    }, titleNumber) {
        state.titlesLayer?.highlightFeaturesByTitleNumber(titleNumber, true)
    },

    // TODO: This should be a mutation I think?
    [MATTER_REMOVE_HIGHLIGHT_BOUNDARY]({ state }) {
        state.titlesLayer?.highlightFeaturesByTitleNumber(null)
    },

    async [MATTER_IMPORT_FROM_WKT_AREA]({
        commit,
        dispatch,
    }, request) {
        commit(MATTER_MUTATE_LOADING, true)
        // NOTE: Split the geometries to not meet the max body size limit
        const chunkedGeometries = isNullOrEmpty(request.geometries) ? [] : chunkArray(request.geometries, 1)
        let errorMessage = undefined
        let successMessage = undefined
        let titlesAdded = 0
        // NOTE: Each backend call has a title limit, we need frontend limit as well to not overload the system
        let collectiveUploadLimitMet = false
        const collectiveUploadLimit = 20000
        for (const chunk of chunkedGeometries) {
            request.geometries = chunk
            const response = await MatterApi.importFromArea(request.matterId, request)
            if (response.data.error) {
                errorMessage = response.data.error
                break
            } else {
                titlesAdded += response.data.titleCount
                successMessage = response.data.message
                if (titlesAdded > collectiveUploadLimit) {
                    collectiveUploadLimitMet = true
                    break
                }
            }
        }

        if (!errorMessage) {
            const additionalLimitationMessage = collectiveUploadLimitMet ? `Upload limited to ${ collectiveUploadLimit } titles due to too many titles across all features uploaded.` : ''
            const replacedTotalCount = successMessage.replace(/\d+/, titlesAdded)
            const popupMessage = `${ replacedTotalCount } ${ additionalLimitationMessage }`
            // Success
            await dispatch(MATTER_RESET_CURRENT, {
                forceReset: true,
                resetZoom: false,
            })
            await dispatch(USER_SHOW_POPUP, {
                title: 'Titles added',
                icon: '$info',
                contentHTML: '<p>' + popupMessage + '</p>',
                testName: 'add-titles-draw-success',
            })
            await dispatch(MATTER_REQUEST_SHOW_TITLE_LIST)
        } else {
            // Error
            await dispatch(USER_SHOW_POPUP, {
                title: 'Error uploading titles',
                icon: '$error',
                contentHTML: '<p>' + errorMessage + '</p>',
            })
        }
        commit(MATTER_MUTATE_LOADING, false)
        return titlesAdded
    },

    async [MATTER_IMPORT_FROM_SPREADSHEET]({
        commit,
        dispatch,
    }, {
        matterId,
        request,
    }) {
        commit(MATTER_MUTATE_LOADING, true)
        const response = await MatterApi.importFromSpreadsheet(matterId, request)

        await dispatch(MATTER_RESET_CURRENT, {
            forceReset: true,
            resetZoom: false,
        })
        dispatch(USER_SHOW_POPUP, {
            title: 'Titles added',
            icon: '$info',
            contentHTML: '<p>' + response.data.message + '</p>',
            testName: 'add-titles-spreadsheet-success',
        })
        commit(MATTER_MUTATE_LOADING, false)
    },

    async [MATTER_CREATE_LINK_SHARE]({
        commit,
        state,
        dispatch,
    }, request) {
        commit(MATTER_MUTATE_LINK_SHARING_LOADING, true)
        const response = await MatterLinkShareApi.createLinkShare(state.currentMatter.id, request)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        } else {
            commit(MATTER_MUTATE_LINK_SHARING_DETAILS, response.data)
            commit(MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER)
        }

        commit(MATTER_MUTATE_LINK_SHARING_LOADING, false)

        return response
    },

    async [MATTER_UPDATE_LINK_SHARE]({
        commit,
        state,
    }, request) {
        commit(MATTER_MUTATE_LINK_SHARING_LOADING, true)
        const response = await MatterLinkShareApi.updateLinkShare(state.currentMatter.id, request)
        commit(MATTER_MUTATE_LINK_SHARING_DETAILS, response.data)
        commit(MATTER_MUTATE_LINK_SHARING_LOADING, false)
        commit(MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER)
    },

    async [MATTER_ENABLE_LINK_SHARE]({
        state,
        commit,
        dispatch,
    }, enable) {
        const response = await MatterLinkShareApi.enableLinkShare(state.currentMatter.id, enable)

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.message)
        } else {
            commit(MATTER_MUTATE_LINK_SHARING_ENABLED, enable)
            commit(MATTER_MUTATE_MATTER_LIST_FROM_CURRENT_MATTER)
        }

        return response
    },

    async [MATTER_FETCH_DIGITAL_REGISTER_AVAILABILITY_FOR_TITLE_NUMBERS]({ state }, titleNumbers) {
        const response = await apolloClient.query({
            query: GET_TITLES_WITHOUT_DIGITAL_REGISTERS(titleNumbers),
            variables: {
                matterId: state.currentMatter.id,
                titleNumbers,
            },
            // Disables the cache so always goes back to the server
            fetchPolicy: 'network-only',
        })
        return response.data?.titleRegisters?.map(r => r.titleNumber) ?? []
    },

    /**
     * Updates the matter state for a matter with the given Id,
     * updating the matter list and current matter as necessary.
     * @param {Object} request {matterId: 123, state: MatterState.Open}
     */
    async [MATTER_UPDATE_MATTER_STATE]({
        state,
        commit,
    }, request) {
        const response = await MatterApi.updateMatterState(request.matterId, request.state)

        if (response.ok) {
            commit(MATTER_MUTATE_LIST_ITEM_MATTER_STATE_BY_MATTER_ID, {
                matterId: request.matterId,
                state: request.state,
            })

            if (request.matterId === state.currentMatter.id) {
                commit(MATTER_MUTATE_CURRENT_MATTER_STATE, request.state)
            }
        } else {
            commit(MATTER_MUTATE_UPDATE_STATE_ERROR, response.message || 'An error has occurred. Please try again later')
        }
    },

    /**
     * Perform matter related actions when receiving an order message from the notification hub.
     */
    async [ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE]({ dispatch, rootState }, orderMessage) {
        const update = orderMessage.message
        if (update.status === DocumentOrderStatus.ORDERED) {
            dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, null, { root: true })
            dispatch(MATTER_GET_ADDRESSES_FOR_TITLES, [update.titleNumber])
        }
        if (update.status === DocumentOrderStatus.DERIVED && update.titleNumber === rootState.title.selectedTitleNumber) {
            await dispatch(MATTER_RESET_CURRENT)
            dispatch(MATTER_GET_ADDRESSES_FOR_TITLES, [update.titleNumber])
        }
    },

    async [MATTER_TITLE_ORGANISER_INITIALISE_MAP]({ state }) {
        const matterTitlesLayer = state.titlesLayer

        matterTitlesLayer.updateLayersDataFn(() => state.titleOrganiser.titles)
    },

    async [MATTER_TITLE_ORGANISER_RESET_MAP]({ state }) {
        const matterTitlesLayer = state.titlesLayer

        matterTitlesLayer.updateLayersDataFn(() => state.currentMatter.selectedTitles)
        matterTitlesLayer.refresh(state.currentMatter.selectedTitles.map(t => t.titleNumber))
    },

    async [MATTER_TITLE_ORGANISER_RELOAD_MATTER_CONTENT]({ commit, dispatch, state }) {
        if (!state.titleOrganiser.selectedService) {
            commit(MATTER_MUTATE_TITLE_ORGANISER_MATTER_CONTENT, {})
        }
        state.titleOrganiser.selectedService.isLoading = true

        const titleOrganiserResult = await state.titleOrganiser.selectedService?.getMatterContents(
            state.currentMatter.id,
            state.currentMatter.selectedTitles,
            state.currentMatter.groups,
            state.titleOrganiser.applyQuickStyling,
            state.titleOrganiser.selectedField,
        )

        if (!titleOrganiserResult) {
            commit(MATTER_MUTATE_TITLE_ORGANISER_MATTER_CONTENT, { titles: [], groups: [] })
            return
        }
        titleOrganiserResult.titles = state.currentMatter.selectedTitles.map(title => {
            const titleOrganiserTitle = titleOrganiserResult.titles.find(t => t.titleNumber === title.titleNumber)
            if (titleOrganiserTitle) {
                return {
                    ...title,
                    matterGroupId: titleOrganiserTitle.matterGroupId,
                    sortOrder: titleOrganiserTitle.sortOrder,
                    ...(state.titleOrganiser.applyQuickStyling ? titleOrganiserTitle.boundaryStyle : {}),
                }
            }
            return title
        })
        commit(MATTER_MUTATE_TITLE_ORGANISER_MATTER_CONTENT, titleOrganiserResult)
        await dispatch(MATTER_UPDATE_BOUNDARY_LAYER)

        const matterTitlesLayer = state.titlesLayer

        matterTitlesLayer.refresh(state.titleOrganiser.titles.map(t => t.titleNumber))

        state.titleOrganiser.selectedService.isLoading = false
    },

    async [MATTER_TITLE_ORGANISER_APPLY_CHANGES]({ commit, dispatch, state }) {
        commit(MATTER_MUTATE_TITLE_ORGANISER_LOADING, true)
        // Remove any groups that are no longer in use.
        const groupsToRemove = state.currentMatter.groups.filter(g => !state.titleOrganiser.groups.find(g2 => g2.id === g.id))
        if (groupsToRemove.length > 0) {
            await Promise.all(groupsToRemove.map(group => dispatch(MATTER_REMOVE_GROUP, {
                group: {
                    id: group.id,
                },
                noRefresh: true,
            })))
        }

        // Create any new groups (doing so one at a time to relate back to titles assigned to them).
        const groupsToAdd = state.titleOrganiser.groups.filter(g => !state.currentMatter.groups.find(g2 => g2.id === g.id))
        for (const group of groupsToAdd) {
            const createGroupResult = await dispatch(MATTER_CREATE_GROUP, {
                matterId: state.currentMatter.id,
                name: group.name,
                sortOrder: group.sortOrder,
                expanded: true,
            })
            const titlesToUpdate = state.titleOrganiser.titles.filter(t => t.matterGroupId === group.id)
            titlesToUpdate.forEach(t => {
                t.matterGroupId = createGroupResult.id
            })
            group.id = createGroupResult.id
            await dispatch(MATTER_ADD_TITLES_TO_GROUP, {
                matterGroupId: createGroupResult.id,
                titles: titlesToUpdate,
            })
        }

        if (!isNullOrEmpty(state.titleOrganiser.titles)) {
            commit(MATTER_MUTATE_SELECTED_TITLES, state.titleOrganiser.titles)
        }
        if (groupsToRemove.length > 0 || groupsToAdd.length > 0) {
            commit(MATTER_MUTATE_GROUPS, state.titleOrganiser.groups)
        }

        await dispatch(MATTER_UPDATE_BOUNDARY_LAYER_TITLES, state.titleOrganiser.titles)
        commit(MATTER_MUTATE_TITLE_ORGANISER_LOADING, false)

        // Log usage
        dispatch(LOGGING_LOG_FEATURE_USAGE, {
            type: 'title-organiser',
            description: state.titleOrganiser.selectedService?.id,
        })

        const eventMetadata = {
            quickStyling: state.titleOrganiser.applyQuickStyling,
            groupedBy: `${ state.titleOrganiser.selectedService?.id }${ state.titleOrganiser.selectedService?.id === 'advanced' &&
                state.titleOrganiser.selectedField
                ? ` - ${ state.titleOrganiser.selectedField }`
                : '' }`,
            titleCount: state.titleOrganiser.titles.length,
            groupCount: state.titleOrganiser.groups.length,
            groupedTitlesCount: state.titleOrganiser.titles.filter(t => t.matterGroupId !== null).length,
            ungroupedTitlesCount: state.titleOrganiser.titles.filter(t => t.matterGroupId === null).length,
        }
        eventMetadata.sumOfGroupCountAndUngroupedTitlesCount = eventMetadata.groupCount + eventMetadata.ungroupedTitlesCount
        dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'TITLE ORGANISER - Title Organiser used',
            metadata: eventMetadata,
        })

        // Reset the selected service
        commit(MATTER_MUTATE_TITLE_ORGANISER_SET_SERVICE, null)
    },

    async [MATTER_GROUP_SET_EXPANDED]({ commit, getters }, { groupId, expanded }) {
        const groups = getters[MATTER_GET_MATTER_CONTENTS].groups
        const group = groups.find(g => g.id === groupId)
        if (group) {
            commit(MATTER_MUTATE_GROUP_SET_EXPANDED, { groupId, expanded })
            await MatterApi.UpdateGroup(group)
        }
    },

    async [MATTER_AVAILABLE_SERVICES_INIT]({ state }) {
        await Promise.all(state.titleOrganiser.availableServices.map(async (service) => await service.init()))
    },

    async [MATTER_UPDATE_SKETCHES_VISIBLE_MAP]({ commit, state }, visibleMap) {
        commit(MATTER_MUTATE_SKETCHES_VISIBLE_MAP, visibleMap)
        await MatterApi.updateSketchesVisibleMap(state.currentMatter.id, visibleMap)
    },

    async [MATTER_CLEAR_CURRENT_MATTER]({ commit, dispatch, state }) {
        if (state.currentMatter.id) {
            commit(MATTER_MUTATE_RESET_CURRENT_MATTER)
        }
        await dispatch(UNSUBSCRIBE_FROM_MATTER_HUB, null, { root: true })
    },

    async [MATTER_UPDATE_SKETCHES_SETTINGS]({ commit, state }, { sketchesUnit, sketchesVisibleMap }) {
        commit(MATTER_MUTATE_SKETCHES_SETTINGS, { sketchesUnit, sketchesVisibleMap })
        await MatterApi.updateSketchesSettings(state.currentMatter.id, { sketchesUnit, sketchesVisibleMap })
    },

    [MATTER_SET_BOUNDARY_HIGHLIGHT_VISIBLE]({ state }, visible) {
        state.titlesLayerShowBoundaryOnHover = visible
        state.titlesLayer?.boundaryLayer?.setHighlightBoundaryOnHover(visible)
    },
}
