import { gbpCurrencyFormatter } from '@/utils/number-utils'
import {
    Column,
    ColumnConfig,
    Model,
} from '@bryntum/grid'
import { calculatingMarkup } from '.'

export interface ICurrencyColumnProps extends Partial<ColumnConfig> {
    calculating?: (record: Model) => boolean
}

export const useCurrencyColumn = (args: ICurrencyColumnProps): Partial<Column> => ({
    ...args,
    field: args.field,
    text: args.text,
    flex: 0,
    align: 'right',
    type: 'template',
    template: ({ value, record }: any) => {
        const calculating = args.calculating && args.calculating(record)
        if (calculating) {
            return calculatingMarkup
        }
        return value ? `<span class='currency-column'>${ gbpCurrencyFormatter.format(value) }</span>` : ' - '
    },
} as Partial<Column>)
