import {
    SketchType,
    SketchesUnit,
} from '@/enums/sketches-enums'
import Feature from 'ol/Feature'
import {
    getArea,
    getLength,
} from 'ol/sphere'

const calcHectaresFromMetres = (metres: number) => {
    return ((metres * 0.0001) * 100) / 100
}

const calcKmFromMetres = (metres: number) => {
    return metres / 1000
}

const calcKmSqFromMetres = (metres: number) => {
    return metres / 1000000
}

const calcAcresFromMetres = (metres: number) => {
    return ((metres * 0.000247105) * 100) / 100
}

const calcYardsFromMetres = (metres: number) => {
    return ((metres * 1.09361) * 100) / 100
}

const calcMilesFromMetres = (metres: number) => {
    return ((metres * 0.000621371) * 100) / 100
}

const areaSqMetres = (features: Feature[]) => {
    return features.reduce((total: number,
        feature) => {
        return total + getArea(feature.getGeometry() as any)
    }, 0)
}

const distanceMetres = (features: Feature[]) => {
    return features.reduce((total: number,
        feature) => {
        const length = getLength(feature.getGeometry() as any)
        return total + length
    }, 0)
}

export interface ISketchMeasurement {
    totalMetres?: number
    unit?: string
    value: string
    formattedValue?: string
}

export const sketchToMeasurement = (features: Feature[], sketchType: SketchType, unitOfMeasurement: SketchesUnit): ISketchMeasurement | null => {
    const totalMetres = sketchType === SketchType.Area ? areaSqMetres(features) : distanceMetres(features)
    if (!totalMetres) {
        return null
    }
    let value
    let unit
    const locale = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

    switch (sketchType) {
        case SketchType.Line:
            if (unitOfMeasurement === SketchesUnit.Imperial) {
                if (totalMetres < 10_000) {
                    value = calcYardsFromMetres(totalMetres).toLocaleString(undefined, locale)
                    unit = 'yd'
                } else {
                    value = calcMilesFromMetres(totalMetres).toLocaleString(undefined, locale)
                    unit = 'mi'
                }
            } else {
                if (totalMetres < 10_000) {
                    value = (totalMetres).toLocaleString(undefined, locale)
                    unit = 'm'
                } else {
                    value = calcKmFromMetres(totalMetres).toLocaleString(undefined, locale)
                    unit = 'km'
                }
            }
            break
        case SketchType.Area:
            if (unitOfMeasurement === SketchesUnit.Hectares) {
                value = calcHectaresFromMetres(totalMetres).toLocaleString(undefined, locale)
                unit = 'ha'
            } else if (unitOfMeasurement === SketchesUnit.Imperial) {
                value = calcAcresFromMetres(totalMetres).toLocaleString(undefined, locale)
                unit = 'ac'
            } else {
                if (totalMetres < 100_0000) {
                    value = (totalMetres).toLocaleString(undefined, locale)
                    unit = 'm²'
                } else {
                    // round to 2 decimal places
                    value = calcKmSqFromMetres(totalMetres).toLocaleString(undefined, locale)
                    unit = 'km²'
                }
            }
            break
        default:
            value = ''
    }
    return {
        totalMetres,
        unit,
        value,
        formattedValue: `${ value }${ unit }`,
    }
}
