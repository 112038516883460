import UserApi from '@/api/user.api'
import { AppVersion } from '@/models/app-version.model'
import {
    LOGGING_LOG_AXIOS_ERROR,
    LOGGING_LOG_FEATURE_USAGE,
    LOGGING_OW_INITIALISE,
} from '@/store/mutation-types'
import { isLocalDevEnvironment } from '@/utils/environment.utils'

export const actions = {
    async [LOGGING_LOG_FEATURE_USAGE]({ state, rootState }, request) {
        // Log OW event
        if (state.active.ow === true) {
            const matterId = rootState.matter.currentMatter.id
            await UserApi.logFeatureUsage(request.type, request.description, matterId)
        }

        // Log Intercom event
        if (state.active.intercom === true && window.Intercom) {
            window.Intercom('trackEvent', request.type, {
                type: 'feature_usage',
                description: request.description,
            })
        }
    },

    [LOGGING_OW_INITIALISE]({ state }) {
        if (state.initialised.ow === false) {
            state.initialised.ow = true

            if (!isLocalDevEnvironment()) {
                // OW internal logging, no initialisation required here.
                if (window?.owvars?.underTest !== true) {
                    state.active.ow = true
                }
            }
        }
    },

    // Is this needed now there's axios interceptors for error handling?
    [LOGGING_LOG_AXIOS_ERROR](context, error) {
        console.error(`appVersion: ${ AppVersion.currentAppVersion }`)
        if (error?.response) {
            // Request made and server responded
            console.error(error.response.data)
            console.error(error.response.status)
            console.error(error.response.headers)
        } else if (error?.request) {
            // The request was made but no response was received
            console.error(error.request)
        } else if (error?.constructor?.name === 'Cancel') {
            // The request was cancelled, take no action
        } else {
            // Something happened in setting up the request that triggered an Error
            const message = error ?? 'Something went wrong'
            console.error('Error', message)
        }
    },
}
