export const ssoOptions = {
    SSO_DISABLED: 0,
    SSO_ENABLED: 1,
    SSO_FORCED: 2,
}

export const ssoOptionNames = {
    [ssoOptions.SSO_DISABLED]: 'Disabled',
    [ssoOptions.SSO_ENABLED]: 'Enabled',
    [ssoOptions.SSO_FORCED]: 'Forced',
}
