<template>
    <teleport to="body">
        <ow-modal v-model="show"
                  content-class="dialog-group"
                  :title="$t('overlays.nameEditPrompt.title')">
            <div class="dialog-group__content">
                <ow-textfield ref="name"
                              v-model="name"
                              enter
                              data-test="overlay-name-edit-modal-name"
                              :label="$t('overlays.nameEditPrompt.name').toString()"
                              @enter="onEnterKey"
                              @input="handleInput" />
            </div>
            <template #actions>
                <div class="dialog-group__actions">
                    <ow-button data-test="overlays-name-edit-modal-button-cancel"
                               @click="handleCancel">
                        {{ $t('action.cancel') }}
                    </ow-button>
                    <ow-button data-test="overlays-name-edit-modal-button-done"
                               is-primary
                               :disabled="!valid"
                               @click="handleDone">
                        {{ $t('action.done') }}
                    </ow-button>
                </div>
            </template>
        </ow-modal>
    </teleport>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        OVERLAYS_MUTATE_SHOW_NAME_MODAL,
        OVERLAYS_UPDATE_OVERLAY_NAME,
    } from "@/store/modules/overlays/types"
    import { isNullOrWhitespace } from '@/utils/string-utils'
    // @ts-ignore

    export default {
        name: 'OverlaysNameEditModal',
        components: {
            OwButton,
            OwModal,
            OwTextfield,
        },
        data() {
            return {
                name: null,
                originalName: null,
            }
        },
        computed: {
            ...mapState({
                showNameEditModal: (state: IState) => state.overlays.showNameEditModal,
                overlay: (state: IState) => state.overlays.nameEditModalOverlay ?? null,
            }),
            show: {
                get() {
                    return this.showNameEditModal
                },
                set(value) {
                    this.setShowNameEditModal({ show: value })
                    this.$refs.name?.content?.focus()
                },
            },
            valid() {
                return !isNullOrWhitespace(this.name)
            },
        },
        watch: {
            async overlay(val) {
                if (!this.originalName) this.originalName = val?.name
                this.name = val?.name ?? null
                await this.$nextTick()
                this.$refs.name?.$refs.content?.focus()
                this.$refs.name?.$refs?.content?.select()
            },

            async show(val) {
                if (val) {
                    await this.$nextTick()
                    this.$refs.name?.$refs.content?.focus()
                    this.$refs.name?.$refs?.content?.select()
                }
            },
        },
        methods: {
            ...mapActions({
                updateOverlays: OVERLAYS_UPDATE_OVERLAY_NAME,
            }),
            ...mapMutations({
                setShowNameEditModal: OVERLAYS_MUTATE_SHOW_NAME_MODAL,
            }),

            async handleCancel() {
                this.overlay.name = this.originalName
                await this.updateOverlays(this.overlay)
                this.show = false
            },

            async handleDone() {
                this.show = false
            },

            async handleInput() {
                this.overlay.name = this.name
                await this.updateOverlays(this.overlay)
            },

            onEnterKey() {
                if (this.valid) {
                    this.handleDone()
                }
            },
        },
    }
</script>
