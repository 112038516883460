<template>
    <section class="select-document-table">
        <v-tabs v-model="selectedTab"
                :show-arrows="false"
                slider-color="primary"
                class="select-document-table__tabs">
            <v-tab :key="tabsIndexKey.purchased"
                   :ripple="false"
                   class="document-library__tab"
                   data-test="select-document-table-tab-purchased"
                   data-track="OVERLAYS - Purchased tab"
                   light>
                <span class="button-small">
                    {{ purchasedTabText }}
                </span>
            </v-tab>
            <v-tab :key="tabsIndexKey.uploaded"
                   :ripple="false"
                   class="document-library__tab"
                   data-test="select-document-table-tab-uploaded"
                   data-track="OVERLAYS - Uploaded tab"
                   light>
                <span class="button-small">
                    {{ t('documents.library.tabs.uploaded', {count: isLoadingUploaded ? 0 : numOfUploadedDocuments}) }}
                </span>
            </v-tab>
            <v-tab v-if="searchesEnabled"
                   :key="tabsIndexKey.searches"
                   :ripple="false"
                   class="document-library__tab"
                   data-test="select-document-table-tab-searches"
                   data-track="OVERLAYS - Searches tab"
                   light>
                <span class="button-small">
                    {{ t('documents.library.tabs.searches', {count: isLoadingSearches ? 0 : numOfSearchesDocuments}) }}
                </span>
            </v-tab>
        </v-tabs>

        <div class="d-flex align-center justify-space-between full-width">
            <ow-text-field v-model="searchText"
                           :max-length="1000"
                           :placeholder="t('action.filterDocs')"
                           class="select-document-table__filter flex-grow-1"
                           clearable
                           :read-only="selectedDocumentId !== null"
                           @click:clear="handleClear">
                <template #iconPrefix>
                    <v-icon>$search</v-icon>
                </template>
            </ow-text-field>
            <ow-button v-if="selectedDocumentId"
                       is-secondary
                       class="mt-4 ml-3"
                       @click="handleClear">
                Clear active filter
            </ow-button>
        </div>

        <v-window v-model="selectedTab">
            <v-window-item :key="0">
                <ow-loading-skeleton v-if="isLoadingPurchased"
                                     data-test="select-document-table-loading-purchased"
                                     height="600px" />
                <purchased-documents-table v-else
                                           class="mt-4"
                                           :selected-document="selectedPurchasedDocument"
                                           :documents="filteredPurchasedDocuments"
                                           :is-current-matter-inactive="isCurrentMatterActive"
                                           :is-loading="isLoadingPurchased"
                                           @change="handleDocumentSelection" />
            </v-window-item>

            <v-window-item :key="1">
                <ow-loading-skeleton v-if="isLoadingUploaded"
                                     data-test="select-document-table-loading-uploaded"
                                     height="600px" />
                <uploaded-documents-table v-if="currentMatterId"
                                          class="mt-4"
                                          :current-matter-id="currentMatterId"
                                          :documents="filteredUploadedDocuments"
                                          :selected-document="selectedUploadedDocument"
                                          :is-loading="isLoadingUploaded"
                                          @change="handleDocumentSelection" />
            </v-window-item>
            <v-window-item v-if="searchesEnabled"
                           :key="2">
                <ow-loading-skeleton v-if="isLoadingSearches"
                                     data-test="select-document-table-loading-searches"
                                     height="600px" />
                <searches-documents-table v-if="currentMatterId"
                                          class="mt-4"
                                          :current-matter-id="currentMatterId"
                                          :documents="filteredSearchesDocuments"
                                          :selected-document="selectedSearchesDocument"
                                          :is-loading="isLoadingSearches"
                                          @change="handleDocumentSelection" />
            </v-window-item>
        </v-window>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwTextField from '@/components/core/ow-textfield.vue'
    import PurchasedDocumentsTable from '@/components/map/overlays/components/purchased-documents-table.vue'
    import SearchesDocumentsTable from '@/components/map/overlays/components/searches-documents-table.vue'
    import UploadedDocumentsTable from '@/components/map/overlays/components/uploaded-documents-table.vue'
    import { ISearchesDocument } from '@/interfaces/searches-document.interface'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import { isNullOrWhitespace } from '@/utils/string-utils'
    const { t } = useI18n()
    const store = useStore()

    enum tabsIndexKey {
        purchased,
        uploaded,
        searches,
    }

    interface Props {
        uploadedDocuments: Array<IUploadedDocument>,
        purchasedDocuments: Array<any>,
        searchesDocuments: Array<ISearchesDocument>,
        isLoadingPurchased: boolean,
        isLoadingUploaded: boolean,
        isLoadingSearches: boolean,
        currentMatterId: number,
        isCurrentMatterActive?: boolean,
        selectedTable?: number,
        selectedUploadedDocument?: IUploadedDocument,
        selectedPurchasedDocument?: any,
        selectedSearchesDocument?: ISearchesDocument,
        selectedDocumentId: string,
    }

    const props = withDefaults(defineProps<Props>(), {
        isCurrentMatterActive: false,
        selectedTable: 0,
        selectedUploadedDocument: null,
        selectedPurchasedDocument: null,
        selectedSearchesDocument: null,
        selectedDocumentId: null,
    })

    const emit = defineEmits<{
        (e: 'change', item: any),
        (e: 'clear')
    }>()

    const selectedTab = ref<number>(tabsIndexKey.purchased)
    watch(() => props.selectedTable, (newVal: number) => {
        selectedTab.value = newVal
    },{
        immediate: true,
    })

    const filteredPurchasedDocuments = ref<Array<any>>([])
    const filteredUploadedDocuments = ref<Array<IUploadedDocument>>([])
    const filteredSearchesDocuments = ref<Array<any>>([])
    const searchText = ref<string>('')

    watch(() => props.selectedDocumentId, (val) => {
        searchText.value = val ?? ''
    }, {
        immediate: true,
    })

    watch(searchText, (newVal) => {
        if (!isNullOrWhitespace(newVal)) {
            filteredPurchasedDocuments.value = props.purchasedDocuments
                .filter((item: any) => Object.values(item).join(' ').toLowerCase().includes(newVal.toLowerCase()))
            filteredUploadedDocuments.value = props.uploadedDocuments
                .filter((item: IUploadedDocument) => Object.values(item).join(' ').toLowerCase().includes(newVal.toLowerCase()))
            filteredSearchesDocuments.value = props.searchesDocuments
                .filter((item: any) => Object.values(item).join(' ').toLowerCase().includes(newVal.toLowerCase()))
        } else {
            filteredPurchasedDocuments.value = props.purchasedDocuments
            filteredUploadedDocuments.value = props.uploadedDocuments
            filteredSearchesDocuments.value = props.searchesDocuments
        }
    }, { immediate: true })

    watch(() => props.purchasedDocuments, (newVal: Array<any>) => {
        if (!isNullOrWhitespace(searchText.value)) {
            return
        }
        filteredPurchasedDocuments.value = newVal
    }, { immediate: true, deep: true })
    watch(() => props.uploadedDocuments, (newVal: Array<IUploadedDocument>) => {
        if (!isNullOrWhitespace(searchText.value)) {
            return
        }
        filteredUploadedDocuments.value = newVal
    }, { immediate: true, deep: true })
    watch(() => props.searchesDocuments, (newVal: Array<any>) => {
        if (!isNullOrWhitespace(searchText.value)) {
            return
        }
        filteredSearchesDocuments.value = newVal
    }, { immediate: true, deep: true })

    const numOfUploadedDocuments = computed(() => props.uploadedDocuments.length)
    const numberOfPurchasedDocuments = computed(() => props.purchasedDocuments.length)
    const numOfSearchesDocuments = computed(() => props.searchesDocuments.length)
    const searchesEnabled = computed(() => store.state.config?.featureFlags?.orderSearch ?? false)
    const purchasedTabText = computed(() => {
        const count = props.isLoadingPurchased ? 0 : numberOfPurchasedDocuments.value
        return searchesEnabled.value ?  t('documents.library.tabs.officialCopies', { count }) : t('documents.library.tabs.purchased', { count })
    })

    const handleDocumentSelection = (item: any) => {
        emit('change', item)
    }

    const handleClear = () => {
        emit('clear')
    }
</script>

<style lang="scss" scoped>
@import './select-document-table';
</style>
