import i18n from '@/plugins/i18n'
import { IMatterListItem } from '@/interfaces/matter-list-item.interface'
import WalkthroughsApi from '@/api/walkthroughs.api'

/**
 * Check whether a user has access to the matter
 * @param matterId
 */
export const canUserAccessMatter = async (matterId: number): Promise<boolean> => {
    try {
        // Uses this endpoint due to matter link share user hacks.
        await WalkthroughsApi.getWalkthroughIdByMatterId(matterId)
        return true
    } catch (err) {
        return false
    }
}

/**
 * Validates a matter code given a matter code 'mask' - a regular expression.
 * @param matterCode a matter code to validate
 * @param matterCodeMask a regular expression to validate the matter code against
 */
export const validateMatterCodeWithMask = (matterCode: string, matterCodeMask: string): boolean => {
    return new RegExp(matterCodeMask).test(matterCode)
}

/**
 * Applies hint text to an existing matter list item.
 * @param matterItem a matter list item to apply hint text to.
 */
export const appendMatterHintText = (matterItem: IMatterListItem): any => {
    // Assign text describing matter sharing.
    if (matterItem.sharedWithOrganisation) {
        matterItem.sharedWithText = i18n.global.t('matter.sharedWithWholeOrganisation') as string
        matterItem.sharedWithTextShort = i18n.global.t('label.all') as string
    } else if (matterItem.sharedWithSpecificUsers) {
        matterItem.sharedWithText = matterItem.matterUserSharesCount > 1
            ? i18n.global.t('matter.sharedWithMultipleUsers', { count: matterItem.matterUserSharesCount }) as string
            : i18n.global.t('matter.sharedWithSingleUser') as string
        matterItem.sharedWithTextShort = matterItem.matterUserSharesCount?.toString()
    } else {
        matterItem.sharedWithText = i18n.global.t('matter.sharedWithNoOne') as string
        matterItem.sharedWithTextShort = null
    }

    // Assign text describing external link sharing.
    if (matterItem.hasLinkShare) {
        matterItem.linkShareTipText = i18n.global.t('matter.linkSharingEnabled') as string
        if (matterItem.hasDisabledLinkShare && matterItem.hasExpiredLinkShare) {
            matterItem.linkShareTipText = i18n.global.t('matter.linkSharingDisabledAndExpired') as string
        } else if (matterItem.hasDisabledLinkShare) {
            matterItem.linkShareTipText = i18n.global.t('matter.linkSharingDisabled') as string
        } else if (matterItem.hasExpiredLinkShare) {
            matterItem.linkShareTipText = i18n.global.t('matter.linkSharingExpired') as string
        }
    }
    return matterItem
}
