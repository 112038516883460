import { IDocumentSelectionGroup } from '@/components/copilot/document-selection/document-selection-group.interface'
import { reactive } from 'vue'
import { IDocumentSelectionGroupDocument } from '@/components/copilot/document-selection/document-selection-group-document.interface'
import { isNullOrEmpty } from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

/**
 * Filter documents based on the filter text.
 * The filter text can be a title number, document type, document id, etc.
 * @example
 *      const filtered = new FilterDocuments('title number', items).filter()
 * @param filterText
 * @param items
 */
export class FilterDocuments {
    private filterText: string
    private readonly items: Array<IDocumentSelectionGroup>
    private readonly allDocuments: Array<IDocumentSelectionGroupDocument>
    private filteredDocuments: Record<string, Array<IDocumentSelectionGroupDocument>> = {}

    /**
     * Create a new instance of the FilterDocuments class.
     */
    constructor(
        items: Array<IDocumentSelectionGroup>,
    ) {
        this.items = items

        if (!isNullOrEmpty(items)) {
            // Flatten the documents into a single array for filtering.
            this.allDocuments = items.map(i => i.documents?.concat()).flat(1)
        }

        return this
    }

    /**
     * Filter the documents and groups.
     * @return {Array<IDocumentSelectionGroup>} - The filtered items.
     */
    public filter(filterText: string): Array<IDocumentSelectionGroup> {
        if (isNullOrWhitespace(filterText) ||
            isNullOrEmpty(this.allDocuments)) {
            // No filter, so just return the original list
            return this.items
        }

        this.filterText = filterText.trim().toLowerCase()

        // Want matches that include all words in the filter text.
        // An "AND" type of search "NGL50538 AND Register" for example.
        const words = this.filterText.split(' ')
        this.allDocuments.forEach(d => {
            const docSearchString = this.getDocumentSearchString(d)
            const isMatch = words.every(word => docSearchString.includes(word))
            if (isMatch) {
                this.addFilteredDocumentToResult(d)
            }
        })

        return this.getFilteredItems()
    }

    /**
     * Filter the documents and groups based on the document ids.
     * @param documentIds
     */
    public filterOnDocumentIds = (documentIds: Array<number>): Array<IDocumentSelectionGroup> => {
        if (isNullOrEmpty(documentIds) ||
            isNullOrEmpty(this.allDocuments)) {
            // No filter, so just return the original list
            return this.items
        }

        // Just need to match a single document with the id from the array sent in here.
        this.allDocuments
            .forEach(d => {
                if (documentIds.includes(d.documentId)) {
                    this.addFilteredDocumentToResult(d)
                }
            })

        return this.getFilteredItems()
    }

    /**
     * Get the filtered items
     * @return {Array<IDocumentSelectionGroup>} - The filtered items.
     * @private
     */
    private getFilteredItems(): Array<IDocumentSelectionGroup> {
        const toReturn = []

        // If nothing found, then return nothing!
        if (this.isRecordEmpty(this.filteredDocuments) &&
            !isNullOrWhitespace(this.filterText)) {
            return reactive([])
        }

        // Go through all the documents
        for (const [_, value] of Object.entries(this.filteredDocuments)) {
            // title number is the same for all the documents
            const titleNumber = value[0].titleNumber

            if (toReturn.some(g => g.titleNumber === titleNumber)) {
                const returnGroup = toReturn.filter(g => g.titleNumber === titleNumber)[0]

                if(!returnGroup.documents) {
                    returnGroup.documents = []
                }
                returnGroup.documents.push(this.filteredDocuments[titleNumber])
            } else {
                // Haven't got the group, so grab it from the original array of items
                const selectedGroup = this.items.filter(g => g.titleNumber === titleNumber)[0]
                // Replace the documents with the filtered documents
                toReturn.push({
                    ...selectedGroup,
                    documents: value,
                })
            }
        }

        return reactive(toReturn)
    }

    /**
     * Create a search string from the document object properties
     * @param document
     * @private
     */
    private getDocumentSearchString = (document: IDocumentSelectionGroupDocument): string => {
        return `${ document.documentType } ${ document.titleNumber } ${ document.documentId } ${ document.source } ${ document.docDate } ${ document.group }`.toLowerCase()
    }

    /**
     * Check if the record object is empty
     * @param record
     * @private
     */
    private isRecordEmpty(record: Record<string, any>): boolean {
        return Object.keys(record).length === 0
    }

    /**
     * Add the document to the results
     * @param document
     * @private
     */
    private addFilteredDocumentToResult(document: IDocumentSelectionGroupDocument): void {
        const titleNumber = document.titleNumber
        // already have some docs for this title number
        if (this.filteredDocuments[titleNumber]?.some(d => d.documentId === document.documentId)) {
            // Don't add the same doc multiple times
            return
        }

        if (!this.filteredDocuments[titleNumber]) {
            this.filteredDocuments[titleNumber] = []
        }
        this.filteredDocuments[titleNumber].push(document)
    }
}
