import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import Feature from 'ol/Feature'
import { FindNearbyDefaults } from '@/components/find-nearby/defaults'
import Geometry from 'ol/geom/Geometry'
import { isNullOrEmpty } from '@/utils/array-utils'
import Map from 'ol/Map'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

export interface IFindNearbyResultsLayerParams {

    // The OpenLayers map that be the target of the buffer layer.
    targetMap: Map
}

// TODO: hide/show layer depending on whether there's any data

export class FindNearbyResultsLayer {
    // The OpenLayers map that be the target of the integration.
    private targetMap: Map

    // A layer that will display the 'nearby' area - a buffer around one or more titles.
    private layer: VectorLayer<VectorSource<Geometry>>
    private visibleFeatures: Array<Feature> = []
    private featureIdKey: string

    constructor(params: IFindNearbyResultsLayerParams) {
        // Initialise the map layer.
        this.layer = new VectorLayer({
            source: new VectorSource(),
            zIndex: 800,
            style: (feature: Feature) => {
                if (!this.visibleFeatures.includes(feature)) {
                    return null
                }

                return new Style({
                    stroke: new Stroke({
                        color: FindNearbyDefaults.RESULTS_DEFAULT_STROKE_COLOUR,
                        width: 3,
                    }),
                    fill: new Fill({
                        color: FindNearbyDefaults.RESULTS_DEFAULT_FILL_COLOUR,
                    }),
                })
            },
        })

        this.setTargetMap(params.targetMap)
    }

    public setTargetMap(map: Map): void {
        this.targetMap = map

        if (this.targetMap) {
            this.targetMap.addLayer(this.layer)
        }
    }

    public setResultFeatures(features: Feature[]): void {
        this.layer.getSource().clear()

        if (!isNullOrEmpty(features)) {
            this.layer.getSource().addFeatures(features)
        }
    }

    public setFeatureIdKey(key: string): void {
        this.featureIdKey = key
        this.layer.getSource().changed()
    }

    public setVisibleFeatures(features: Array<Feature>): void {
        this.visibleFeatures = features
        this.layer.getSource().changed()
    }

    // Resets the current buffer layer.
    public reset():void {
        this.layer.getSource().clear()
        this.visibleFeatures = []
    }

    public dispose(): void {
        this.reset()
        if (this.targetMap) {
            this.targetMap.removeLayer(this.layer)
        }
        this.layer.dispose()
    }
}
