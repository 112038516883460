<template>
    <div :data-test="dataTestAttribute"
         :data-track="dataTrackAttribute"
         class="ow-autocomplete">
        <v-autocomplete ref="autocomplete"
                        v-model="selectedItem"
                        :auto-select-first="autoSelectFirst"
                        :autofocus="autofocus"
                        :class="{ 'dd-privacy-allow': isInputVisibleInDataDog }"
                        :clearable="true"
                        clear-icon="$close"
                        :flat="true"
                        :hide-no-data="true"
                        :item-title="itemText"
                        :item-value="itemValue"
                        :item-props="true"
                        :items="items"
                        :label="label"
                        :loading="isLoading"
                        :no-data-text="noDataText"
                        :no-filter="usingAsyncData"
                        :outlined="outlined"
                        :prepend-inner-icon="prependSearchIcon"
                        :return-object="true"
                        append-icon=""
                        color="primary"
                        density="compact"
                        hide-details
                        variant="solo"
                        @focus="onFocus"
                        @focusout="onClear"
                        @keyup="onSearchInputUpdate"
                        @click:clear="onClear">
            <template v-for="(_, name) in $slots"
                      #[name]="slotData">
                <slot :name="name"
                      v-bind="slotData" />
            </template>
        </v-autocomplete>
    </div>
</template>

<script lang="ts">
    export default {
        name: 'OwAutocomplete',

        props: {
            items: {
                type: Array,
                default: () => [],
                required: true,
            },
            autofocus: {
                type: Boolean,
                required: false,
            },
            autoSelectFirst: {
                type: Boolean,
                required: false,
            },
            dataTestAttribute: {
                type: String,
                required: false,
                default: 'ow-autocomplete',
            },
            dataTrackAttribute: {
                type: String,
                required: false,
                default: 'ow-autocomplete',
            },
            includeSearchIcon: {
                type: Boolean,
                required: false,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            itemText: {
                // Property to display as the text
                type: String,
                required: false,
            },
            itemValue: {
                // Property to use as the value
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: false,
            },
            noDataText: {
                type: String,
                required: false,
            },
            selectedValue: {
                type: [String, Number],
                required: false,
            },
            usingAsyncData: {
                type: Boolean,
                required: false,
            },
            outlined: {
                type: Boolean,
                required: false,
            },
            isInputVisibleInDataDog: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'change',
            'enter-pressed',
            'search-input-update',
            'clear',
            'focus',
            'update:model-value',
        ],

        data() {
            return {
                select: this.selectedValue,
                searchInputText: '',
            }
        },

        computed: {
            prependSearchIcon() {
                return this.includeSearchIcon ? '$search' : ''
            },

            selectedItem: {
                get() {
                    return this.select
                },
                set(newValue: any) {
                    this.select = newValue
                    this.$emit('update:model-value', newValue)
                    this.$emit('change', newValue)
                },
            },
        },

        methods: {
            onEnterPressed() {
                if (this.select) {
                    this.$emit('enter-pressed', {item: this.select})
                    this.clearLocalInputVariables()
                    this.$refs.autocomplete.blur()
                }
            },

            onSearchInputUpdate(evt) {
                const value = evt?.target?.value
                if (evt.key === 'Enter') {
                    this.onEnterPressed()
                    return
                }
                if (value?.toUpperCase() !== this.searchInputText?.toUpperCase()) {
                    this.searchInputText = value
                    this.$emit('search-input-update', value)
                }
            },

            clearLocalInputVariables() {
                this.searchInputText = ''
                this.select = null
                this.$refs.autocomplete.internalSearch = ''
            },

            onClear() {
                this.clearLocalInputVariables()
                this.$emit('clear')
            },

            onFocus() {
                this.$emit('focus')
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-autocomplete';
</style>
