import { StyleLike } from 'ol/style/Style'
import { LayerNames } from '@/consts/map-layers'
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { FeatureLike } from 'ol/Feature'
import { MapBrowserEvent } from 'ol'
import i18n from '@/plugins/i18n'

import {
    getEnvironmentalRolloverOptions,
    AncientWoodlandFeaturesType,
} from './environmental-layers-common'

export type AncientWoodlandLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void
}

export class AncientWoodlandLayer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: AncientWoodlandLayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:vancient_woodland_combined'
        this.name = LayerNames.AncientWoodland
        this.i18nNameRef = 'map.options.legend.environmental[0]'
        this.attributionText = [`${ i18n.global.t('map.options.environmental.attribution.ancientWoodland') } - © ${ i18n.global.t('map.options.environmental.source.england') } & ${ i18n.global.t('map.options.environmental.source.wales') }- released ${ settings.ancientWoodlandReleaseDate }`]
        this.rolloverOptions = getEnvironmentalRolloverOptions(LayerNames.AncientWoodland, this.style)

        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Toggle Ancient Woodland layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                let textArray = []
                if (features.length) {
                    const properties = features[0].getProperties() as AncientWoodlandFeaturesType
                    textArray = AncientWoodlandLayer.getHoverTextArray(properties)
                }
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public style:StyleLike = new Style({
        fill: new Fill({
            color: 'rgba(56, 142, 60, 0.8)',
        }),
        stroke: new Stroke({
            color: '#ffffff',
            width: 2,
        }),
    })

    public static getHoverTextArray(properties: AncientWoodlandFeaturesType):string[] {
        const source = properties.england ? `${ i18n.global.t('map.options.environmental.source.england') }` : `${ i18n.global.t('map.options.environmental.source.wales') }`
        return [
            `${ i18n.global.t('map.options.environmental.hover.ancientWoodland.name') }: ${ properties.name }`,
            `${ i18n.global.t('map.options.environmental.hover.ancientWoodland.type') }: ${ properties.category_name }`,
            `${ i18n.global.t('map.options.environmental.hover.ancientWoodland.source') }: ${ source }`,
        ]
    }
}
