import httpClient from '@/api/http-client'
import { LicenceType } from '@/enums/licenceType'

export default class UsersApi {
    static END_POINT = 'users'

    /**
     * Updates a list of users to the selected licence type
     * @param userIds {string[]} - The user ids to update
     * @param licenceType {LicenceType} - desired licence type
     * @param organisationId {Number} - optional organisation id, only used for system admins
     * @returns Promise
     */
    static setSelectedLicencesForUsers(userIds: string[], licenceType: LicenceType, organisationId: number = null) {
        const data =  {
            userIds,
            licenceType,

        }
        const uri = organisationId ? `${ this.END_POINT }/${ organisationId }/update-licences/` : `${ this.END_POINT }/update-licences`
        return httpClient.post(uri, data)
    }
}
