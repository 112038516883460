<template>
    <ow-data-grid :headers="headers"
                  :items="groupedDocuments"
                  :allow-selection="false"
                  :sort-by="sortBy"
                  :disable-items-per-page-select="true"
                  sort
                  data-test="searches-grouped-table-grid"
                  has-pagination
                  class="searches-document-table">
        <template #[`item.lastUpdated`]="{ item }">
            <td class="caption-regular"
                :title="formatDate(item.lastUpdated)">
                {{ formatDate(item.lastUpdated) }}
            </td>
        </template>
        <template #[`item.documents`]="{ item }">
            <td class="caption-regular">
                <v-btn class="document-order-view-link__button-download title-lr-button"
                       color="primary"
                       data-test="document-order-view-link-download"
                       data-track="Searches - Download document"
                       variant="text"
                       icon="$download"
                       @click="$emit('download-zip', item)" />
            </td>
        </template>
    </ow-data-grid>
</template>

<script setup lang="ts">
    import {computed,
            ref} from "vue"
    import {useI18n} from "vue-i18n"

    import OwDataGrid from "@/components/core/ow-data-grid.vue"
    import useDates from "@/composables/use-dates"
    import {IDocLibrarySearchesRow} from "@/interfaces/searches-document.interface"

    const props = defineProps<{
        groupedDocuments: Array<IDocLibrarySearchesRow>
    }>()

    const emit = defineEmits<{
        (e: 'download-zip', args: any): void
    }>()

    const { t } = useI18n()

    const sortBy = ref([{key: 'lastUpdated', order: 'desc'}])

    const headers = computed(() => {
        return [
            {
                title: t('documents.library.searchesColumns.product'),
                align: 'left',
                key: 'product',
            },
            {
                title: t('documents.library.searchesColumns.titleOrAddress'),
                align: 'left',
                key: 'location',
            },
            {
                title: t('documents.library.searchesColumns.documentCount'),
                align: 'left',
                key: 'documentCount',
            },
            {
                title: t('documents.library.searchesColumns.lastUpdated'),
                align: 'left',
                key: 'lastUpdated',
            },
            {
                title: t('action.download'),
                align: 'left',
                key: 'documents',
            },
        ]
    })

    const formatDate = (date: Date) => {
        const { formatDate } = useDates()
        return formatDate(date)
    }

</script>

<style scoped lang="scss">
    @import "searches-grouped-table";
</style>
