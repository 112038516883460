<template>
    <div class="matter-link-share-checkbox rounded pa-1"
         @click="emit('update:modelValue', !modelValue)">
        <v-card class="matter-link-share-checkbox__card d-flex flex-column rounded pa-4 align-center">
            <v-card-text class="d-flex justify-center pt-2 pb-0">
                <span class="d-flex align-center justify-center">
                    <slot name="icon" />
                </span>
            </v-card-text>
            <v-card-text class="d-flex justify-center">
                <span class="caption-highlight matter-link-share-checkbox__label">{{ label }}</span>
            </v-card-text>
            <v-card-text class="d-flex justify-center pa-2 pt-0 matter-link-share-checkbox__text">
                <slot name="help-text" />
            </v-card-text>
            <v-checkbox-btn :model-value="modelValue"
                            disabled
                            class="matter-link-share-checkbox__check"
                            :data-test="dataTest"
                            :label="$t('label.enabled')"
                            hide-details />
        </v-card>
    </div>
</template>

<script setup lang="ts">
    withDefaults(defineProps<{
        label: string
        modelValue?: boolean
        dataTest?: string
    }>(), {
        label: '',
        modelValue: false,
        dataTestId: '',
    })

    const emit = defineEmits<{
        (e: 'update:modelValue', v: boolean)
    }>()

</script>

<style lang="scss">
    @import 'matter-link-share-checkbox.scss';
</style>
