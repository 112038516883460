<template>
    <div id="users-page">
        <div class="fixed-header">
            <header>
                <h1 class="content__heading">
                    <v-icon>$account-circle-outline</v-icon>
                    Users
                </h1>
            </header>
        </div>
        <app-users />
    </div>
</template>

<script>
    import Users from '@/components/settings/users/users.vue'

    export default {
        name: 'Users',

        components: {
            appUsers: Users,
        },
    }
</script>

<style lang="scss">
    @import './users';
</style>
