<template>
    <ow-base-icon :height="height"
                  :width="width"
                  desc="Map layers icon"
                  viewBox="0 0 24 24">
        <path clip-rule="evenodd"
              d="m11.043 11.955-8.718-4.34a.586.586 0 0 1 0-1.05l8.718-4.341a2.16 2.16 0 0 1 1.915 0l8.719 4.34a.586.586 0 0 1 0 1.05l-8.719 4.341a2.152 2.152 0 0 1-1.915 0zm10.634-.48-2.563-1.276-5.633 2.805a3.337 3.337 0 0 1-2.96 0L4.887 10.2l-2.562 1.276a.586.586 0 0 0 0 1.049l8.718 4.34a2.148 2.148 0 0 0 1.915.001l8.719-4.341a.586.586 0 0 0 0-1.05zm0 4.911-2.563-1.276-5.633 2.805a3.337 3.337 0 0 1-2.96 0L4.887 15.11l-2.562 1.276a.586.586 0 0 0 0 1.05l8.718 4.34a2.15 2.15 0 0 0 1.915 0l8.719-4.34a.586.586 0 0 0 0-1.05z"
              fill="#515D65"
              fill-rule="evenodd" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwMapLayersIcon',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '24px',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
        },
    }
</script>
