import {
    convertStringToDocumentType,
    DocumentType,
} from '@/consts/document-type'
import { generateOrderKeyValue } from '@/utils/document-ordering-utils'
import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'

/**
 * Mock behaviours for a document order.
 */
class DocumentMockBehaviours {
    public useMockOrdering: boolean
    constructor(useMockOrdering = false) {
        this.useMockOrdering = useMockOrdering
    }
}
/**
 * Request for a document order.
 */
export class DocumentOrderRequest {
    // A unique signature that identifies a specific document of a given documentType
    readonly keyValue: string

    // The type of document
    readonly documentType: number

    // Associated matter Id if applicable
    readonly matterId?: number

    // Whether to order regardless of whether the document is already ordered and available
    readonly forceOrder?: boolean

    // Optional title number to associate the order with
    readonly titleNumber?: string

    // Optional flag to indicate that the document is backdated on HMLR
    readonly currentVersionBackdated?: boolean

    // Optional values to enable mock document ordering behaviours
    readonly mockBehaviours?: DocumentMockBehaviours

    constructor(
        keyValue: string,
        documentType: number,
        matterId?: number,
        forceOrder?: boolean,
        titleNumber?:string,
        availableVersionBackdated?: boolean) {
        this.keyValue = keyValue
        this.documentType = documentType
        this.matterId = matterId
        this.forceOrder = forceOrder
        this.titleNumber = titleNumber
        this.currentVersionBackdated = availableVersionBackdated
        this.mockBehaviours = new DocumentMockBehaviours()
    }

    /**
     * Create request to order a register document
     * @param titleNumber
     * @param matterId
     * @param forceOrder
     * @return {DocumentOrderRequest}
     */
    static createRegisterRequest = (
        titleNumber: string,
        matterId: number = null,
        forceOrder = false): DocumentOrderRequest => {
        const documentType = DocumentType.REGISTER
        return new DocumentOrderRequest(
            generateOrderKeyValue(titleNumber, documentType),
            documentType,
            matterId,
            forceOrder,
            titleNumber)
    }

    /**
     * Create request to order a copies filed document
     * @param titleNumber
     * @param matterId
     * @param document
     * @param forceOrder
     * @return {DocumentOrderRequest}
     */
    static createCopiesFiledRequest = (
        titleNumber: string,
        matterId: number,
        document: ICopiesFiledDocument,
        forceOrder = false): DocumentOrderRequest => {
        const documentType = convertStringToDocumentType(document.documentTypeValue, document.documentType)

        return new DocumentOrderRequest(
            generateOrderKeyValue(titleNumber, documentType, document),
            documentType,
            matterId,
            forceOrder,
            titleNumber)
    }

    /**
     * Create request to order a register document
     * @param titleNumber
     * @param matterId
     * @param forceOrder
     * @param availableVersionBackdated
     * @return {DocumentOrderRequest}
     */
    static createTitlePlanRequest = (
        titleNumber: string,
        matterId: number,
        forceOrder = false,
        availableVersionBackdated = false): DocumentOrderRequest => {
        const documentType = DocumentType.PLAN
        return new DocumentOrderRequest(
            generateOrderKeyValue(titleNumber, documentType),
            documentType,
            matterId,
            forceOrder,
            titleNumber,
            availableVersionBackdated)
    }

    /**
     * Enables or disables mock behaviours for this request.
     * @param val True to enable mock behaviours, false to disable.
     */
    public useMockBehaviours(val: boolean): void {
        this.mockBehaviours.useMockOrdering = val
    }
}
