import {
    SEARCHES_MUTATE_CATEGORIES,
    SEARCHES_MUTATE_HAZARDS,
    SEARCHES_MUTATE_ORDERS,
    SEARCHES_MUTATE_LOADING,
    SearchOrder,
    SEARCHES_MUTATE_BASKET,
    SEARCHES_MUTATE_PRODUCT_QUOTE,
    SEARCHES_MUTATE_CLEAR_PRODUCT_QUOTE,
    SearchProductQuote,
    SEARCHES_MUTATE_QUOTE_CALCULATING,
    SEARCHES_MUTATE_FILTER,
    SEARCHES_MUTATE_SUBMITTING_ORDER,
    SEARCHES_MUTATE_ORDER_DETAILS,
    SearchOrderDetails,
    SEARCHES_MUTATE_SEARCH_PROVIDERS,
    SearchProvider,
    SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS,
    SEARCHES_MUTATE_AREA_SQM,
    SEARCHES_MUTATE_GEOJSON,
    SEARCHES_MUTATE_FILTERED_TITLE_NUMBER,
    SEARCHES_MUTATE_CLEAR_QUOTE,
    SEARCHES_MUTATE_MAP,
    SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS,
    SEARCHES_MUTATE_DRAW_LAYER_WITH_SKETCH,
    SEARCHES_MUTATE_HIGHLIGHT_SKETCH,
    SEARCHES_MUTATE_SNAPPING_MODE,
    SEARCHES_MUTATE_CURRENT_MODE,
    SEARCHES_MUTATE_ZOOM_TO_SKETCH,
    SEARCHES_MUTATE_IS_DRAWING,
    SEARCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS,
    SEARCHES_MUTATE_BOUNDARY_LAYER,
    SEARCHES_MUTATE_SEARCHES_LAYER,
    SEARCHES_MUTATE_NEW_SKETCH,
    SEARCHES_MUTATE_RESET,
    SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD,
    SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX,
    SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL,
    SEARCHES_MUTATE_MAKING_UNDO_REDO_CHANGES,
    SearchProductsFilter,
    SEARCHES_MUTATE_MAP_KEY,
    SEARCHES_MUTATE_PRODUCT_PREFERENCES,
    ISearchProductPreferenceList,
    SEARCHES_MUTATE_PRODUCTS,
    SearchesProduct,
    SearchesHazard,
    Product,
    SEARCHES_MUTATE_ADD_BASKET_ITEM,
    SEARCHES_MUTATE_CLEAR_BASKET,
    SEARCHES_MUTATE_REMOVE_BASKET_ITEM,
    SEARCHES_MUTATE_BASKET_ITEMS_WITH_QUOTE,
    SEARCHES_MUTATE_RECOMMENDED_SORT,
    SEARCHES_MUTATE_REMOVE_RECOMMENDED_ITEM,
    SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM,
    SEARCHES_MUTATE_RECOMMENDED_CLEAR,
    SEARCHES_MUTATE_OTHER_FILTER,
    SEARCHES_MUTATE_SELECTED_CATEGORY,
    SEARCHES_MUTATE_SELECTED_BASKET_ITEM,
    SEARCHES_MUTATE_OTHER_PRODUCTS,
    SEARCHES_MUTATE_BASKET_FILTER,
    SEARCHES_MUTATE_RECOMMENDED_FILTER,
    SEARCHES_MUTATE_CONTEXTID,
    SEARCHES_MUTATE_CLICK_PRODUCT_LINK,
    SEARCHES_MUTATE_PRODUCT_CONFIG,
    SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW,
    SEARCHES_MUTATE_QUOTE_TOTALS,
    ProductTotals,
    SEARCHES_MUTATE_DRAFT_ORDER_DETAILS,
    ISearchDraftDetails,
    SEARCHES_MUTATE_BASKET_DIRTY,
    SEARCHES_MUTATE_SAVING_DRAFT_ORDER,
    SEARCHES_MUTATE_DRAFT_ORDER_ID,
    SEARCHES_MUTATE_STRUCTURED_ADDRESS,
    SEARCHES_MUTATE_NOTIFICATION_USERS,
    SEARCHES_MUTATE_CLEAR_ERRORS,
    SEARCHES_MUTATE_ADD_ERROR,
    ISearchesError,
    SEARCHES_MUTATE_ADD_ERROR_FROM_EXCEPTION,
    SEARCHES_MUTATE_STRUCTURED_ADDRESS_VALID,
    SEARCHES_MUTATE_ORGANISATION_SETTINGS,
    SEARCHES_MUTATE_PROJECT_NAME_VALID,
    SEARCHES_MUTATE_PROJECT_NAME,
} from '@/store/modules/searches/types'

import {
    ISearchesState,
    defaultState,
} from '@/store/modules/searches'
import { Colors } from '@/enums/colors.enum'
import { ISketch } from '../sketches/types/sketch'
import {
    SketchMode,
    SnappingMode,
} from '@/enums/sketches-enums'
import {
    createEmpty,
    extend,
    isEmpty,
} from 'ol/extent'
import { BoundaryLayer } from '../map/layers/title-boundary-layer/boundary-layer'
import { SearchesLayer } from '../map/layers/searches-layer'
import { SketchesFactory } from '../sketches/types/sketches-factory'
import BasketItemModel from '@/components/matter-searches/models/BasketItemModel'
import CategoryItemModel from '@/components/matter-searches/models/CategoryItemModel'
import ProductItemModel from '@/components/matter-searches/models/ProductItemModel'
import { Con29ProductConfig } from '@/components/matter-searches/models/BasketItemTMGCon29Model'
import { Con29DwcProductConfig } from '@/components/matter-searches/models/BasketItemCon29DwcModel'
import { LLC1ProductConfig } from '@/components/matter-searches/models/BasketItemLLC1Model'
import { HighwaysRaProductConfig } from '@/components/matter-searches/models/BasketItemHighwaysRaModel'
import BasketItemModelFactory from '@/components/matter-searches/models/BasketItemModelFactory'
import { ArgSSComboProductConfig } from '@/components/matter-searches/models/BasketItemArgSSComboModel'
import {
    IGetOrganisationSettingsResponse,
    INotificationConfigUser,
    StructuredAddress,
} from "@/api/searches.api"
import { PropertyType } from '@/enums/searches.enum'

export default {
    [SEARCHES_MUTATE_STRUCTURED_ADDRESS](state: ISearchesState, address: StructuredAddress) {
        state.address = {
            address,
            uprn: '',
        }
    },

    [SEARCHES_MUTATE_STRUCTURED_ADDRESS_VALID](state: ISearchesState, valid: boolean) {
        state.addressValid = valid
    },

    [SEARCHES_MUTATE_CONTEXTID](state: ISearchesState, contextId: string) {
        state.contextId = contextId
    },

    [SEARCHES_MUTATE_ADD_ERROR](state: ISearchesState, error: {
        key: string,
        value: ISearchesError
    }) {
        state.error.set(error.key, error.value)
    },


    [SEARCHES_MUTATE_ADD_ERROR_FROM_EXCEPTION](state: ISearchesState, error: {
        key: string,
        request?: any,
        exception: any
    }) {
        const { exception, request } = error
        state.error.set(error.key, {
            traceId: exception?.response?.data?.traceId,
            detail: exception?.response?.data?.detail,
            request,
            errors: exception?.message ? [exception.message] : [exception],
        } as ISearchesError)
    },

    [SEARCHES_MUTATE_CLEAR_ERRORS](state: ISearchesState) {
        if (state.error && state.error.size > 0) {
            state.error.clear()
        }
    },

    [SEARCHES_MUTATE_ORDERS](state: ISearchesState, data: Array<SearchOrder>) {
        state.orders = data
    },

    [SEARCHES_MUTATE_PRODUCTS](state: ISearchesState, data: Array<SearchesProduct>) {
        state.products = data
    },

    [SEARCHES_MUTATE_HAZARDS](state: ISearchesState, data: Array<SearchesHazard>) {
        state.hazards = data
    },

    [SEARCHES_MUTATE_CATEGORIES](state: ISearchesState, data: CategoryItemModel[]) {
        state.categories.data = data
    },

    [SEARCHES_MUTATE_LOADING](state: ISearchesState, loading: boolean) {
        state.loading = loading
    },

    [SEARCHES_MUTATE_SUBMITTING_ORDER](state: ISearchesState, submitting: boolean) {
        state.submittingOrder = submitting
    },

    [SEARCHES_MUTATE_SAVING_DRAFT_ORDER](state: ISearchesState, saving: boolean) {
        state.savingDraftOrder = saving
    },

    [SEARCHES_MUTATE_QUOTE_CALCULATING](state: ISearchesState, calculating: boolean) {
        state.quoteCalculating = calculating
    },

    [SEARCHES_MUTATE_BASKET](state: ISearchesState, basket: BasketItemModel[]) {
        state.basket.data = basket
    },

    [SEARCHES_MUTATE_OTHER_PRODUCTS](state: ISearchesState, products: ProductItemModel[]) {
        state.otherProducts.data = products
    },

    [SEARCHES_MUTATE_PRODUCT_QUOTE](state: ISearchesState, quote: SearchProductQuote) {
        state.quote = quote
    },

    [SEARCHES_MUTATE_CLEAR_PRODUCT_QUOTE](state: ISearchesState) {
        state.quote.totals = {
            totalNet: 0,
            totalVat: 0,
            totalGross: 0,
        }
        state.quote.products = []
        state.quoteCalculating = false
    },

    [SEARCHES_MUTATE_RESET](state: ISearchesState) {
        if (state.sketch.layer) {
            state.sketch.targetMap.removeLayer(state.sketch.layer)
        }
        if (state.sketch.drawLayer) {
            state.sketch.targetMap.removeLayer(state.sketch.drawLayer)
        }
        if (state.sketch.snapInteraction) {
            state.sketch.targetMap.removeInteraction(state.sketch.snapInteraction)
        }
        if (state.sketch.drawInteraction) {
            state.sketch.targetMap.removeInteraction(state.sketch.drawInteraction)
        }
        if (state.sketch.modifyInteraction) {
            state.sketch.targetMap.removeInteraction(state.sketch.modifyInteraction)
        }
        Object.assign(state, defaultState)
    },

    [SEARCHES_MUTATE_CLEAR_QUOTE](state: ISearchesState) {
        // reset the quote
        state.quote = {
            products: [],
            totals: {
                totalNet: 0,
                totalVat: 0,
                totalGross: 0,
            },
            contactId: null,
        }
        // clear the stores
        state.basket.data = []
        state.categories.data = []
        state.otherProducts.data = []
        state.recommended.data = []

        // reset the filter
        state.filter.productPreferenceId = null

        // reset the draft order
        state.draftOrderId = null
        state.basketDirty = false
        state.draftOrderDetails = null
    },

    [SEARCHES_MUTATE_ORGANISATION_SETTINGS](state: ISearchesState, settings: IGetOrganisationSettingsResponse) {
        state.organisationSettings = settings
    },

    [SEARCHES_MUTATE_FILTER](state: ISearchesState, filter: SearchProductsFilter) {
        state.filter = filter
    },

    [SEARCHES_MUTATE_ORDER_DETAILS](state: ISearchesState, orderDetails: SearchOrderDetails) {
        state.orderDetails = orderDetails
        state.address = {
            address: orderDetails.address,
            uprn: "",
        }
        if (state.error && state.error.size > 0) {
            state.error.clear()
        }
    },

    [SEARCHES_MUTATE_DRAFT_ORDER_DETAILS](state: ISearchesState, draftOrderDetails: ISearchDraftDetails) {
        state.draftOrderDetails = draftOrderDetails
        state.address = {
            address: draftOrderDetails.address,
            uprn: "",
        }
    },

    [SEARCHES_MUTATE_SEARCH_PROVIDERS](state: ISearchesState, searchProviders: SearchProvider[]) {
        state.searchProviders = searchProviders
        if (state.filter.searchProviderId === null && searchProviders.length > 0) {
            state.filter.searchProviderId = searchProviders.find((provider) => provider.name === 'TmGroup')?.id ?? 0
        }
    },

    [SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS](state: ISearchesState, filteredTitleNumbers: any[]) {
        filteredTitleNumbers.forEach((title) => {
            title.labelColour = title.selected ? Colors.RedBoundary : Colors.BlueBoundary
        })
        state.filteredTitleNumbers = filteredTitleNumbers
    },

    [SEARCHES_MUTATE_FILTERED_TITLE_NUMBER](state: ISearchesState, titleData: any) {
        const title = state.filteredTitleNumbers.find((title) => title.titleNumber === titleData.titleNumber)
        if (title) {
            title.labelColour = title.selected ? Colors.RedBoundary : Colors.BlueBoundary
        }
    },

    [SEARCHES_MUTATE_AREA_SQM](state: ISearchesState, areaSqMetres: number) {
        state.areaSqMetres = areaSqMetres
    },

    [SEARCHES_MUTATE_GEOJSON](state: ISearchesState, geoJson: any) {
        state.geoJSON = geoJson
    },

    [SEARCHES_MUTATE_MAP](state: ISearchesState, map: any) {
        state.map = map
    },

    [SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS](state: ISearchesState, selectedTitleNumbers: any[]) {
        state.selectedTitleNumbers = selectedTitleNumbers
    },

    [SEARCHES_MUTATE_PRODUCT_PREFERENCES](state: ISearchesState, productPreferenceList: ISearchProductPreferenceList[]) {
        // filter the product preferences based on the sector
        const sector = state?.address?.address?.sector === PropertyType[PropertyType.Commercial].toString() ? PropertyType.Commercial : PropertyType.Residential
        const productPreferences = productPreferenceList?.filter(p => {
            if (p?.isResidential && sector === PropertyType.Residential) {
                return p
            } else if (!p.isResidential && sector === PropertyType.Commercial) {
                return p
            }
        })
        // set the default product preference
        if (state.filter.productPreferenceId === null && productPreferences.length > 0) {
            state.filter.productPreferenceId = productPreferences.find((pp) => {
                if (sector === PropertyType.Commercial) {
                    return !pp.isResidential && pp?.isDefault
                } else if (sector === PropertyType.Residential) {
                    return pp.isResidential && pp?.isDefault
                }
            })?.id ?? productPreferences[0]?.id
        }
        state.productPreferences = productPreferences
    },

    // SKETCHES
    [SEARCHES_MUTATE_NEW_SKETCH](state: ISearchesState, sketch: ISketch) {
        if (state.searchesLayer.getLayer().getSource().getFeatures().length > 0) {
            sketch.features = state.searchesLayer.getLayer().getSource().getFeatures()
            sketch.features.forEach(feature => feature.setProperties({ sketchId: sketch.id }))
            state.sketch.drawLayer.getSource().addFeatures(sketch.features)
        }
        state.sketch.currentSketch = sketch
        state.sketch.history.currentSketchGeoJson = []
        state.sketch.history.currentSketchGeoJsonIndex = null
    },

    [SEARCHES_MUTATE_DRAW_LAYER_WITH_SKETCH](state: ISearchesState, sketch: ISketch) {
        state.sketch.layer.getSource().getFeatures().forEach(feature => {
            if (feature.getProperties().sketchId === sketch.id) {
                state.sketch.layer.getSource().removeFeature(feature)
            }
        })
        state.sketch.layer.getSource().addFeatures(sketch.features)
        state.sketch.layer.getSource().changed()
    },

    [SEARCHES_MUTATE_HIGHLIGHT_SKETCH](state: ISearchesState, args): void {
        if (args) {
            const { sketch } = args
            if (state.sketch.highlightedSketch !== sketch) {
                state.sketch.highlightedSketch = sketch
                state.sketch.layer?.getSource().changed()
            }
        }
    },

    [SEARCHES_MUTATE_SNAPPING_MODE](state: ISearchesState, mode: SnappingMode): void {
        state.sketch.snappingMode = mode
    },

    [SEARCHES_MUTATE_CURRENT_MODE](state: ISearchesState, mode: SketchMode): void {
        state.sketch.currentMode = mode
    },

    [SEARCHES_MUTATE_ZOOM_TO_SKETCH](state: ISearchesState, sketch:ISketch): void {
        let extent = createEmpty()
        sketch.features.forEach(feature => {
            extent = extend(extent, feature.getGeometry().getExtent())
        })
        if (!isEmpty(extent)) {
            state.sketch.targetMap.getView().fit(extent, {
                duration: 500,
                padding: [75, 75, 75, 75],
                maxZoom: 19,
            })
        }
    },

    [SEARCHES_MUTATE_IS_DRAWING](state: ISearchesState, isDrawing: boolean): void {
        state.sketch.isDrawing = isDrawing
    },

    [SEARCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS](state: ISearchesState, show: boolean): void {
        state.sketch.showSketchModeOptions = show
        state.boundaryLayer.setInteractive(!state.sketch?.currentSketch?.features.length && !show)
        state.searchesLayer.setInteractive(!state.sketch?.currentSketch?.features.length && !show)
        state.searchesLayer.setVisible(!state.sketch?.currentSketch?.features.length && !show)
        state.sketch?.modifyInteraction?.setActive(show)
        state.sketch?.drawInteraction?.setActive(show)
        state.sketch?.snapInteraction?.setActive(show)

        if (!state.sketch?.currentSketch?.features.length && !show) {
            state.sketch.history.currentSketchGeoJson = []
            state.sketch.history.currentSketchGeoJsonIndex = null
        }

        state.boundaryLayer.layer.getSource().changed()
        state.searchesLayer.getLayer().getSource().changed()
        state.sketch.drawLayer.getSource().changed()
    },

    [SEARCHES_MUTATE_BOUNDARY_LAYER](state: ISearchesState, layer: BoundaryLayer): void {
        state.boundaryLayer = layer
    },

    [SEARCHES_MUTATE_SEARCHES_LAYER](state: ISearchesState, layer: SearchesLayer): void {
        state.searchesLayer = layer
    },

    [SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX](state: ISearchesState, index: number): void {
        state.sketch.history.currentSketchGeoJsonIndex = index
        if (index === 0) {
            state.sketch.history.currentSketchGeoJson = []
            state.sketch.drawLayer?.getSource().clear()
            return
        }
        const features = SketchesFactory.featuresFromGeoJson(state.sketch.history.currentSketchGeoJson[index])
        features.forEach(x => x.setProperties({ sketchId: state.sketch.currentSketch.id }))
        state.sketch.drawLayer?.getSource().clear()
        state.sketch.drawLayer?.getSource().addFeatures(features)
        state.geoJSON = state.sketch.history.currentSketchGeoJson[index]
    },
    [SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD](state: ISearchesState, geoJson: string): void {
        if (!state.sketch.history.currentSketchGeoJson.includes(geoJson)) {
            state.sketch.history.currentSketchGeoJson = state.sketch.history.currentSketchGeoJson.slice(0, state.sketch.history.currentSketchGeoJsonIndex)
            state.sketch.history.currentSketchGeoJson.push(geoJson)
            state.sketch.history.currentSketchGeoJsonIndex = state.sketch.history.currentSketchGeoJson.length // ahead of the last feature state i.e. is not in an 'undone' state.
        }
    },
    [SEARCHES_MUTATE_MAKING_UNDO_REDO_CHANGES](state: ISearchesState, value: boolean): void {
        state.sketch.makingChangesWithUndoRedo = value
    },

    [SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL](state: ISearchesState): void {
        state.sketch.history.currentSketchGeoJson = []
        state.sketch.history.currentSketchGeoJsonIndex = null
    },

    [SEARCHES_MUTATE_MAP_KEY](state: ISearchesState): void {
        state.sketch.showSketchModeOptions = false
        state.sketch.currentSketch = null

        // set the map key to a unique value to force the map to re-render
        state.mapKey = new Date().getTime().toString()
    },


    // basket
    [SEARCHES_MUTATE_BASKET_FILTER](state: ISearchesState) {
        state.basket.filter({
            filters: (record) => {
                const basketItem = record as BasketItemModel
                return basketItem.description.toLowerCase().includes(state.filter.searchText.toLowerCase()) ||
                    basketItem.name.toLowerCase().includes(state.filter.searchText.toLowerCase()) ||
                    basketItem.categoryIds.some((categoryId) => {
                        return categoryId.toLowerCase().includes(state.filter.searchText.toLowerCase())
                    })
            },
            replace: true,
        })
    },

    [SEARCHES_MUTATE_CLEAR_BASKET](state: ISearchesState) {
        state.basket.data = []
        state.basketDirty = false
    },

    [SEARCHES_MUTATE_ADD_BASKET_ITEM](state: ISearchesState, {
        category,
        product,
    }: {
        category: CategoryItemModel,
        product: Product,
    }) {
        const hazards: string[] = product.categoryIds.map((categoryId) => {
            const hazard = state.hazards.find((hazard) => hazard.id === categoryId)
            return hazard?.shortName ?? categoryId
        })

        let basketItem = BasketItemModelFactory.createBasketItemModel(product.productId)
        basketItem.category = category
        basketItem.supplierName = product.productSupplierType
        basketItem.productId = product.productId
        basketItem.categoryIds = product.categoryIds
        basketItem.hazards = hazards
        basketItem.name = product.name
        basketItem.description = product.description
        basketItem.supplierDescription = product.productSupplierType
        basketItem.expectedDate = ''
        basketItem.calculating = true

        state.basket.add(basketItem)
    },

    [SEARCHES_MUTATE_REMOVE_BASKET_ITEM](state: ISearchesState, basketItemId: string) {
        const existingBasketItem = state.basket.find((item: BasketItemModel) => item.id === basketItemId)
        if (existingBasketItem) {
            state.basket.remove(existingBasketItem)
            state.selectedBasketItem = null
        }
    },

    [SEARCHES_MUTATE_BASKET_ITEMS_WITH_QUOTE](state: ISearchesState, {
        quote,
        erroredProducts,
    }: {
        quote: SearchProductQuote,
        erroredProducts: {
            product: string,
            errorItems: {
                errorCode: string
                errorMessage: string
                propertyName: string
            }[]
        }[]
    }) {
        state.basket.forEach((basketItem: BasketItemModel) => {
            const productId = basketItem.productId
            const quoteForProduct = quote.products.find((product) => product.productId == productId)
            const errored = erroredProducts?.some((error) => error.product === productId)
            if (quoteForProduct) {
                basketItem.totalGrossFee = errored ? 0 : quoteForProduct.price.totalGrossFee
                basketItem.totalVat = errored ? 0 : quoteForProduct.price.totalVat
                basketItem.expectedDate = quoteForProduct.expectedDate
                basketItem.supplierName = quoteForProduct?.supplierName ?? basketItem.productSupplierType ?? 'Unknown'
                basketItem.calculating = false
                basketItem.errored = errored
                basketItem.errorItems = errored ? erroredProducts.find((error) => error.product === productId)?.errorItems : []
            }
        })

        // update the totals taking into account any errored products
        state.quote.totals.totalGross = quote.products.reduce((acc, product) => {
            const errored = erroredProducts?.some((error) => error.product === product.productId)
            return acc + (errored ? 0 : product.price.totalGrossFee)
        }, 0)
    },

    // recommended
    [SEARCHES_MUTATE_RECOMMENDED_FILTER](state: ISearchesState) {

        const matchesOnCategory = (category: CategoryItemModel) => {
            return category.name.toLowerCase().includes(state.filter.searchText.toLowerCase())
        }

        const matchesOnProduct = (category: CategoryItemModel) => {
            return category.products.some((product) => product.description.toLowerCase().includes(state.filter.searchText.toLowerCase()))
        }

        const matchesOnProductId = (category: CategoryItemModel) => {
            return category.products.some((product) => product.productId.toLowerCase().includes(state.filter.searchText.toLowerCase()))
        }

        state.recommended.filter({
            filters: (record) => {
                return (matchesOnCategory(record) || matchesOnProduct(record) || matchesOnProductId(record))
            },
            replace: true,
        })
    },

    [SEARCHES_MUTATE_OTHER_FILTER](state: ISearchesState) {

        state.otherProducts.filter({
            filters: (record) => {
                const product = record as ProductItemModel
                const matchesFilter = state.filter.searchText ? product.description.toLowerCase().includes(state.filter.searchText.toLowerCase()) : true
                const matchesOnHazards = product.hazards.some((hazard) => hazard.toLowerCase().includes(state.filter.searchText.toLowerCase()))
                const matchesOnSupplier = product.productSupplierType.toLowerCase().includes(state.filter.searchText.toLowerCase())
                return (matchesFilter || matchesOnHazards || matchesOnSupplier) && !state.basket.some((basketItem: BasketItemModel) => basketItem.productId === product.productId)
            },
            replace: true,
        })
    },

    [SEARCHES_MUTATE_RECOMMENDED_CLEAR](state: ISearchesState) {
        state.recommended.data = []
    },

    [SEARCHES_MUTATE_RECOMMENDED_SORT](state: ISearchesState) {
        // sort by selected product, then by category description
        state.recommended.sort((a: CategoryItemModel, b: CategoryItemModel) => {
            const productA: Product = a.products.find(item => item.productId === a?.selectedProductId)
            const productB: Product = b.products.find(item => item.productId === b?.selectedProductId)
            const descriptionA = productA?.description ?? a.description
            const descriptionB = productB?.description ?? b.description
            return descriptionA.localeCompare(descriptionB)
        })

        // sort by no product selected
        state.recommended.sort((a: CategoryItemModel, b: CategoryItemModel) => {
            const productA: Product = a.products.find(item => item.productId === a?.selectedProductId)
            const productB: Product = b.products.find(item => item.productId === b?.selectedProductId)
            return productB ? 1 : productA ? -1 : 0
        })
    },

    [SEARCHES_MUTATE_REMOVE_RECOMMENDED_ITEM](state: ISearchesState, category: CategoryItemModel) {
        const record = state.recommended.find((record: CategoryItemModel) => record.id === category.id || record.name === category.name)
        if (record) {
            state.recommended.remove(record)
            state.selectedCategory = null
        }
    },

    [SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM](state: ISearchesState, {
        category,
        productId,
    }: {
        category: CategoryItemModel,
        productId: string | undefined,
    }) {
        const selectedProduct = category?.products?.find((product) => product.productId === productId) ?? null
        const selectedProductCategoryIds = selectedProduct?.categoryIds ?? [category.categoryId]
        const selectedProductHazards = selectedProductCategoryIds?.map((categoryId) => {
            const hazard = state.hazards.find((hazard) => hazard.id === categoryId)
            return hazard?.shortName ?? categoryId
        }) ?? []

        state.recommended.add({
            categoryId: category.categoryId,
            name: category.name,
            shortName: category.shortName,
            description: category.description,
            isActive: category.isActive,
            products: category.products,
            selectedProductId: selectedProduct?.productId ?? null,
            selectedProductCategoryIds,
            selectedProductHazards,
        } as CategoryItemModel)
    },

    [SEARCHES_MUTATE_SELECTED_CATEGORY](state: ISearchesState, category: CategoryItemModel) {
        state.selectedCategory = category
    },

    [SEARCHES_MUTATE_SELECTED_BASKET_ITEM](state: ISearchesState, basketItem: BasketItemModel) {
        state.selectedBasketItem = basketItem
    },

    [SEARCHES_MUTATE_CLICK_PRODUCT_LINK](state: ISearchesState, model: BasketItemModel) {
        state.selectedBasketItem = model
        switch (model.productId) {
            case 'TMGCon29':
                state.productConfig.tmgCon29.config = model.config as Con29ProductConfig
                state.productConfig.tmgCon29.show = true
                break
            case 'Con29DWC':
                state.productConfig.con29Dwc.config = model.config as Con29DwcProductConfig
                state.productConfig.con29Dwc.show = true
                break
            case 'TMGLLC1':
                state.productConfig.tmgLLC1.config = model.config as LLC1ProductConfig
                state.productConfig.tmgLLC1.show = true
                break
            case 'Highways':
            case 'HighwaysRA':
                state.productConfig.highwaysRa.config = model.config as HighwaysRaProductConfig
                state.productConfig.highwaysRa.show = true
                break
            case 'ArgSSCombo':
                state.productConfig.argSSCombo.config = model.config as ArgSSComboProductConfig
                state.productConfig.argSSCombo.show = true
                break
        }
    },

    [SEARCHES_MUTATE_PRODUCT_CONFIG](state: ISearchesState, {
        model,
        config,
    }: {
        model: BasketItemModel,
        config: any,
    }) {
        if (state.selectedBasketItem) {
            state.selectedBasketItem.config = config
        }

        switch (model.productId) {
            case 'TMGCon29':
                state.productConfig.tmgCon29.config = config as Con29ProductConfig
                break
            case 'Con29DWC':
                state.productConfig.con29Dwc.config = config as Con29DwcProductConfig
                break
            case 'TMGLLC1':
                state.productConfig.tmgLLC1.config = config as LLC1ProductConfig
                break
            case 'Highways':
            case 'HighwaysRA':
                state.productConfig.highwaysRa.config = config as HighwaysRaProductConfig
                break
            case 'ArgSSCombo':
                state.productConfig.argSSCombo.config = config as ArgSSComboProductConfig
                break
        }
    },

    [SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW](state: ISearchesState, {
        model,
        show,
    }: {
        model: BasketItemModel,
        show: boolean,
    }) {
        switch (model.productId) {
            case 'TMGCon29':
                state.productConfig.tmgCon29.show = show
                break
            case 'Con29DWC':
                state.productConfig.con29Dwc.show = show
                break
            case 'TMGLLC1':
                state.productConfig.tmgLLC1.show = show
                break
            case 'Highways':
            case 'HighwaysRA':
                state.productConfig.highwaysRa.show = show
                break
            case 'ArgSSCombo':
                state.productConfig.argSSCombo.show = show
                break
        }
        if (!show) {
            state.selectedBasketItem = null
        }
    },

    [SEARCHES_MUTATE_QUOTE_TOTALS](state: ISearchesState) {
        // create a new quote from the state
        // remove the products from the quote that are not in the basket
        const newQuote: SearchProductQuote = {...state.quote,
            products: state.quote.products.filter((product) => {
                return state.basket.some((basketItem) => basketItem.productId === product.productId)
            }),
            totals: {
                totalGross: 0,
                totalNet: 0,
                totalVat: 0,
            }}

        // calculate the totals
        state.basket.reduce((quote, basket) => {
            const basketItem = basket as BasketItemModel
            quote.totals.totalGross += basketItem.totalGrossFee
            quote.totals.totalNet += basketItem.totalNetFee
            quote.totals.totalVat += basketItem.totalVat
            return quote
        }, newQuote)

        // update the state
        state.quote = newQuote
    },

    [SEARCHES_MUTATE_BASKET_DIRTY](state: ISearchesState, dirty: boolean) {
        state.basketDirty = dirty
    },

    [SEARCHES_MUTATE_DRAFT_ORDER_ID](state: ISearchesState, draftOrderId: string) {
        state.draftOrderId = draftOrderId
    },

    [SEARCHES_MUTATE_NOTIFICATION_USERS](state: ISearchesState, users: INotificationConfigUser[]) {
        state.notificationUsers = users
    },

    [SEARCHES_MUTATE_PROJECT_NAME](state: ISearchesState, projectName: string) {
        state.projectName = projectName
    },

    [SEARCHES_MUTATE_PROJECT_NAME_VALID](state: ISearchesState, valid: boolean) {
        state.projectNameValid = valid
    },

}
