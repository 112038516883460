export const AUTH_GET_IS_AUTHENTICATED = 'AUTH_GET_IS_AUTHENTICATED'
export const AUTH_GET_USER = 'AUTH_GET_USER'
export const AUTH_GET_LOADING = 'AUTH_GET_LOADING'

export const AUTH_MUTATE_USER = 'AUTH_MUTATE_USER'
export const AUTH_MUTATE_LOADING = 'AUTH_MUTATE_LOADING'
export const AUTH_MUTATE_ERROR = 'AUTH_MUTATE_ERROR'

export const AUTH_FETCH_USER = 'AUTH_FETCH_USER'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN'
export const AUTH_GET_NEW_TOKEN = 'AUTH_GET_NEW_TOKEN'
