// Getters
export const ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER = 'ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER'
export const ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER_AND_STATUS = 'ORGANISATION_HUB_GET_TRACKED_TITLES_BY_LISTENER_AND_STATUS'

// Mutations
/**
 * Update the organisation hub subscription status
 * @param state {OrganisationHubState}
 * @param isSubscribed {Boolean}
 */
export const ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED = 'ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED'
/**
 * Update the organisation hub error message
 * @param state {OrganisationHubState}
 * @param error {String}
 */
export const ORGANISATION_HUB_MUTATE_ERROR = 'ORGANISATION_HUB_MUTATE_ERROR'
export const ORGANISATION_HUB_MUTATE_HUB = 'ORGANISATION_HUB_MUTATE_HUB'
export const ORGANISATION_HUB_MUTATE_MESSAGE = 'ORGANISATION_HUB_MUTATE_MESSAGE'
export const ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE = 'ORGANISATION_HUB_ADD_TRACKED_TITLE'
export const ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLE = 'ORGANISATION_HUB_REMOVE_TRACKED_TITLE'
export const ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE_UPDATE = 'ORGANISATION_HUB_ADD_TRACKED_TITLE_UPDATE'
export const ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER = 'ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER'

// Intended to be implemented by as many modules that need to track title data changes.
export const MUTATE_TITLE_FROM_ORGANISATION_HUB_ORDER_MESSAGE = 'MUTATE_TITLE_FROM_ORGANISATION_HUB_ORDER_MESSAGE'

// Actions
export const SUBSCRIBE_TO_ORGANISATION_HUB = 'SUBSCRIBE_TO_ORGANISATION_HUB'
export const UNSUBSCRIBE_FROM_ORGANISATION_HUB = 'UNSUBSCRIBE_FROM_ORGANISATION_HUB'
export const INITIALISE_ORGANISATION_HUB_LISTENERS = 'INITIALISE_ORGANISATION_HUB_LISTENERS'
export const ORGANISATION_HUB_ACTION_ORDER_MESSAGE = 'ORGANISATION_HUB_ACTION_MESSAGE'
export const ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE = 'ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE'
export const MATTER_TITLE_ORGANISER_RELOAD_MATTER_CONTENT = 'MATTER_TITLE_ORGANISER_RELOAD_MATTER_CONTENT'
export const MATTER_TITLE_ORGANISER_APPLY_CHANGES = 'MATTER_TITLE_ORGANISER_APPLY_CHANGES'
export const MATTER_TITLE_ORGANISER_INITIALISE_MAP = 'MATTER_TITLE_ORGANISER_INITIALISE_MAP'
export const MATTER_TITLE_ORGANISER_RESET_MAP = 'MATTER_TITLE_ORGANISER_RESET_MAP'
