import * as types from './types'

export default {

    [types.DIAGNOSTICS_MUTATE_DEBUG_MODE](state, enable) {
        state.debugModeEnabled = enable
        if (enable === true) {
            localStorage.setItem('debugMode', true)
            console.info('Debug mode enabled')
        } else {
            localStorage.removeItem('debugMode')
        }
    },
}
