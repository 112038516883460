import FileSaver from 'file-saver'

/**
 * @param rows - Array of arrays of strings or number
 * @param filename - Name of the file to be downloaded
 * @description - Exports an array of arrays of strings or numbers to a CSV file
 * @example
 * const rows = [
 *    ['address', 'source', 'energyBand', 'energyRating'],
 *    ['1 Test Address', 'Domestic', 'A', 100],
 *    ['2 Test Address', 'Domestic', 'A', 100],
 * ]
 * exportAsCsv(rows, 'test.csv')
*/
export const exportAsCsv = (rows: Array<Array<any>>, filename: string): string => {
    rows = rows.map(row => {
        return row.map((value: any) => escapeValueForCsv(value))
    })
    const csvContent = rows.map(e => e.join(',')).join('\r\n')
    const blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8;',
    })
    FileSaver.saveAs(blob, filename)
    return csvContent
}

/**
 * @param str - String to be checked for things to escape
 * @description - Checks if a string contains a comma and if so wraps it in double quotes
 * @example
 * escapeValueForCsv('1, Test Address') // returns '"1, Test Address"'
 */
export const escapeValueForCsv = (str?: any) => {
    if (typeof str === 'string' || str instanceof String) {
        str = str.replace(/"/g, '""')
        str = `"${ str }"`
    }
    return str
}
