import Feature from 'ol/Feature'

import {
    PlanningDecisionText,
    planningSortingOptions,
} from '@/consts/planning'

export const initialState = {
    selectedPlanningDecisions: Object.entries(PlanningDecisionText).map(([key, value]) => {
        return {
            text: value,
            value: key,
        }
    }),
    selectedSortOption: planningSortingOptions.DATE_RECEIVED_DESC,
    selectedDistance: 25,
    textFilter: null,
    unavailableApplications: null,
    selectedTitleFeatures: [],
    results: [],
    filteredResults: [],
    loadingResults: false,
    maxDistance: 100,
    isRateLimitedRemainingSeconds: 0,
    promptForRetry: false,
    searchedTitleNumbers: [],
    forcePlanningDataProvider: null,
}

export default {
    // General
    loadingResults: initialState.loadingResults,
    selectedPlanningDecisions: initialState.selectedPlanningDecisions,
    selectedSortOption: initialState.selectedSortOption,
    unavailableApplications: initialState.unavailableApplications,
    forcePlanningDataProvider: null,

    // Inputs
    inputs: {
        searchedTitleNumbers: initialState.searchedTitleNumbers,
        maxDistance: initialState.maxDistance,
        selectedDistance: initialState.selectedDistance,
        selectedTitleFeatures: initialState.selectedTitleFeatures,
        selectedDateRange: 'all',
        textFilter: initialState.textFilter,
        textFilterKeywords: [], // derived from text filter string
    },

    // Results and map related
    targetMap: null,
    distanceLayer: null,
    distanceFeature: new Feature(),
    distanceAnimateListener: null,
    resultsLayer: null,
    resultsCache: [],
    results: initialState.results,
    highlightedFeatures: [],
    selectedFeatures: [],
    isRateLimitedRemainingSeconds: initialState.isRateLimitedRemainingSeconds,
    promptForRetry: initialState.promptForRetry,

    // Filtered results
    filteredResults: initialState.filteredResults,
    filteredResultsCount: 0,
}
