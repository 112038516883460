<template>
    <matter-side-panel-add-titles-option import-method="text"
                                         :current-selected="modelValue"
                                         :title="$t('matter.addTitlesPanel.enterTitleNumbers.heading')"
                                         data-test="matter-add-titles-text"
                                         @select="$emit('update:modelValue', 'text')">
        <span v-t="'matter.addTitlesPanel.enterTitleNumbers.prompt'"
              class="caption-regular" />
        <v-textarea v-model="text"
                    autocomplete="off"
                    data-test="matter-add-titles-list-textarea"
                    hide-details
                    :label="$t('matter.addTitlesPanel.enterTitleNumbers.placeholder')"
                    variant="outlined"
                    no-resize />

        <add-to-group v-model="selectedGroup" />

        <ow-button v-t="'matter.addTitlesPanel.enterTitleNumbers.addTitles'"
                   is-primary
                   small
                   :disabled="!isValid"
                   data-track="MAT-TITLE-LIST - Add titles to the matter"
                   data-test="enter-title-numbers-add-text-titles-button"
                   @click="importTextTitles" />
    </matter-side-panel-add-titles-option>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
    } from 'vue'
    import { useStore } from "vuex"

    import OwButton from '@/components/core/ow-button-ds.vue'
    import {TITLE_LIMIT} from "@/consts/title-limits"
    import {
        MATTER_ADD_MULTIPLE_TITLES,
        MATTER_REQUEST_SHOW_TITLE_LIST,
    } from '@/store/modules/matter/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import { TITLE_MUTATE_ACTIVE_TITLE_PANEL } from '@/store/modules/titles/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import AddToGroup, { GroupItem } from './add-to-group.vue'
    import MatterSidePanelAddTitlesOption from './matter-side-panel-add-titles-option.vue'

    defineProps<{modelValue: string}>()
    defineEmits<{
        (e: 'update:modelValue', value: string)
    }>()

    const maxTitlesFromText = TITLE_LIMIT

    const selectedGroup = ref<GroupItem | undefined>()
    const text = ref<string>()

    const store = useStore()

    const titlesFound = computed(() => {
        const items = text.value?.split(/\W+/)
        const filteredItems = items.filter((item) => {
            return item.trim().length > 0
        })

        return filteredItems
    })

    const isValid = computed(() => {
        return !isNullOrWhitespace(text.value) && titlesFound.value?.length <= maxTitlesFromText
    })

    const importTextTitles = async () => {
        const request = {
            titleNumbers: titlesFound.value,
            source: 'text',
            matterGroupId: selectedGroup.value?.id ?? null,
        }

        await store.dispatch(MATTER_ADD_MULTIPLE_TITLES, request)
        await store.dispatch(MATTER_REQUEST_SHOW_TITLE_LIST)

        store.commit(TITLE_MUTATE_ACTIVE_TITLE_PANEL, TitlePanels.LIST)
    }

    defineExpose({
        titlesFound,
    })
</script>
