import {
    Model,
    ModelFieldConfig,
} from '@bryntum/grid'
import { Product } from '@/store/modules/searches/types'

class CategoryItemModel extends Model {
    static get fields(): Partial<ModelFieldConfig>[] {
        return [
            { name: 'selectedProductId', type: 'string' },
            { name: 'selectedProductCategoryIds', type: 'array' },
            { name: 'selectedProductHazards', type: 'array' },
            { name: 'categoryId', type: 'string' },
            { name: 'name', type: 'string' },
            { name: 'shortName', type: 'string' },
            { name: 'description', type: 'string' },
            { name: 'isActive', type: 'boolean' },
            { name: 'products', type: 'array' },
        ]
    }

    get selectedProductId(): string {
        return this.get('selectedProductId')
    }

    set selectedProductId(value: string) {
        this.set('selectedProductId', value)
    }

    get selectedProductCategoryIds(): string[] {
        return this.get('selectedProductCategoryIds')
    }

    set selectedProductCategoryIds(value: string[]) {
        this.set('selectedProductCategoryIds', value)
    }

    get selectedProductHazards(): string[] {
        return this.get('selectedProductHazards')
    }

    set selectedProductHazards(value: string[]) {
        this.set('selectedProductHazards', value)
    }

    get categoryId(): string {
        return this.get('categoryId')
    }

    set categoryId(value: string) {
        this.set('categoryId', value)
    }

    get name(): string {
        return this.get('name')
    }

    get shortName(): string {
        return this.get('shortName')
    }

    get description(): string {
        return this.get('description')
    }

    get isActive(): boolean {
        return this.get('isActive')
    }

    set isActive(value: boolean) {
        this.set('isActive', value)
    }

    get products(): Product[] {
        return this.get('products')
    }

    set products(value: Product[]) {
        this.set('products', value)
    }
}

export default CategoryItemModel
