import actions from './actions'
import getters from './getters'
import { INotificationState } from '@/store/modules/notifications/types/notification-state.interface'
import mutations from './mutations'

const state: INotificationState = {
    notifications: [],
    toaster: null,
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
