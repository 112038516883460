import {
    calculateCentrePoint,
    convertValueToDomain,
    findRectangleExtent,
    getCoordinatesFromExtent,
    getGeoImage,
    rotateExtentAroundCentre,
    rotateExtentAroundFixedCentre,
    rotatePoint,
} from '@/utils/map-utils'
import {
    Point,
    Polygon,
} from 'ol/geom'
import { OverlayModel } from './overlays-types'
import olMap from 'ol/Map'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import {
    Collection,
    Feature,
} from 'ol'
import { Modify } from 'ol/interaction'
import {
    degreesToRadians,
    point,
    polygon,
} from '@turf/helpers'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import {
    Corners,
    CropExtentPoints,
    ExtentPoint,
    crossProduct,
    distanceFromPointToLine,
    dotProduct,
    getCropPolygon,
    getDirectionVector,
    getFeaturePoints,
    midpoint,
    translatePoint,
    vectorFromPoints,
} from '@/utils/vector-utils'
import { Style,
    Fill,
    Stroke,
} from "ol/style"
import { CoordinateSystemCode } from "@/enums/coordinate-systems"
import { GeoImage } from "@/store/modules/map/layers/geo-image/geo-image-layer"
import { Coordinate } from "ol/coordinate"

export class OverlaysCropLayer {
    // NOTE: Support scales from 1:90, 1:2800
    // NOTE: Any other scale value will be clamped
    private minScaleDomain: number = 90
    private maxScaleDomain: number = 2800
    // NOTE: The [min, max] of height/width/Lshort have been chosen empirically
    private minWidthDomain: number = 0.25
    private maxWidthDomain: number = 5
    private minHeightDomain: number = 1
    private maxHeightDomain: number = 30
    private minLShortDomain: number = 0.5
    private maxLShortDomain: number = 10
    private originalImageOverlayName: string = 'originalGeoImage'
    private originalImageLayer: GeoImage
    public overlay: OverlayModel
    public targetMap: olMap
    public originalImageExtent: number[]
    public cropExtent: number[]
    public cropLayer: VectorLayer<VectorSource>
    public modifyInteraction: Modify
    public pointCollection: Feature<Point>[]
    public modifyInteractionId: number
    public cb: () => void

    constructor(params: any) {
        this.targetMap = params.targetMap
        this.overlay = params.overlay
        this.cropExtent = params.cropExtent
        this.originalImageExtent = params.originalImageExtent
        this.cb = params.cb
    }

    public initialise() {
        // create crop interaction layer
        this.cropLayer = new VectorLayer({
            source: new VectorSource(),
            zIndex: 100000,
            style: {
                'fill-color': 'rgba(255, 255, 255, 0)',
                'circle-radius': 4,
                'circle-fill-color': 'rgba(255, 255, 255, 0.5)',
                'circle-stroke-color': 'rgba(0, 0, 0, 0)',
            },
        })

        // Add the original image as a lower transparency background
        this.originalImageLayer = getGeoImage(
            this.overlay.sourceImageUrl,
            this.originalImageExtent,
            0.45,
            this.overlay.rotation,
            'temporaryClassName',
            true,
            CoordinateSystemCode.EPSG27700,
            200,
            this.originalImageOverlayName)

        const cropExtent = rotateExtentAroundFixedCentre(
            this.cropExtent,
            calculateCentrePoint(this.originalImageExtent),
            -degreesToRadians(this.overlay.rotation))

        const [bl, br, tl, tr] = cropExtent

        this.resetCropLayer(
            getFeaturePoints(bl, br, tl, tr),
            getCropPolygon(bl, br, tl, tr))

        this.resetCropInteraction()

        this.targetMap.addLayer(this.originalImageLayer)
        this.targetMap.addLayer(this.cropLayer)
    }

    public onCropping = (e: any) => {
        const feature = e.target
        if (!feature) {
            return
        }
        const movedPoint: ExtentPoint = feature.getId() as ExtentPoint
        const pointCoordinates: Coordinate = (feature.getGeometry() as Point).getCoordinates()

        const [bl, br, tl, tr] = rotateExtentAroundCentre(this.cropExtent, -degreesToRadians(this.overlay.rotation))

        // Scaling is dependent on the moved point
        const [newPoints, polygon] = Object.values(Corners).includes(movedPoint)
            ? this.calculateVectorScaleFromMovedCorner(movedPoint, pointCoordinates, bl, br, tl, tr)
            : this.calculateVectorScaleFromMovedEdge(movedPoint, pointCoordinates, bl, br, tl, tr)

        this.resetCropLayer(newPoints, polygon)
    }

    public calculateVectorScaleFromMovedCorner(
        movedPoint: ExtentPoint,
        movedPointCoordinates: Coordinate,
        bl: [number, number],
        br: [number, number],
        tl: [number, number],
        tr: [number, number]) {
        let ptIndex: number

        // NOTE: The plan is to do freeform cropping unrotated to simplify the math
        const centre = calculateCentrePoint(this.cropExtent)
        movedPointCoordinates = rotatePoint(movedPointCoordinates, centre, degreesToRadians(this.overlay.rotation))
        let [newBl, newBr, newTl, newTr] = rotateExtentAroundCentre(this.cropExtent, 0)

        // NOTE: For the moved corner recalculate the adjacent corners
        // As their X and Y will change based on the moving corner
        switch (movedPoint) {
            case ExtentPoint.BottomLeft:
                newBl = movedPointCoordinates
                newBr = [newBr[0], newBl[1]]
                newTl = [newBl[0], newTl[1]]
                ptIndex = 0
                break
            case ExtentPoint.BottomRight:
                newBr = movedPointCoordinates
                newBl = [newBl[0], newBr[1]]
                newTr = [newBr[0], newTr[1]]
                ptIndex = 1
                break
            case ExtentPoint.TopLeft:
                newTl = movedPointCoordinates
                newTr = [newTr[0], newTl[1]]
                newBl = [newTl[0], newBl[1]]
                ptIndex = 2
                break
            case ExtentPoint.TopRight:
                newTr = movedPointCoordinates
                newTl = [newTl[0], newTr[1]]
                newBr = [newTr[0], newBr[1]]
                ptIndex = 3
                break
            default:
                console.error('Point not recognised')
                break
        }
        // NOTE: Rotate everything back
        newBl = rotatePoint(newBl, centre, -degreesToRadians(this.overlay.rotation))
        newBr = rotatePoint(newBr, centre, -degreesToRadians(this.overlay.rotation))
        newTr = rotatePoint(newTr, centre, -degreesToRadians(this.overlay.rotation))
        newTl = rotatePoint(newTl, centre, -degreesToRadians(this.overlay.rotation))
        movedPointCoordinates = rotatePoint(movedPointCoordinates, centre, -degreesToRadians(this.overlay.rotation))

        const croppedRectangle = [newBl, newBr, newTl, newTr]

        // Prepare cropped rectangle validation
        const [blo, bro, tlo, tro] = rotateExtentAroundCentre(this.originalImageExtent, -degreesToRadians(this.overlay.rotation))
        const pt = point(movedPointCoordinates)
        const poly = polygon([[blo, tlo, tro, bro, blo]])
        const ptInPoly = booleanPointInPolygon(pt, poly)
        const croppedPointInPoly = booleanPointInPolygon(point(croppedRectangle[ptIndex]), poly)

        if (!ptInPoly
            && !croppedPointInPoly) {
            const newPoints = getFeaturePoints(bl, br, tl, tr)
            const cropPolygon = getCropPolygon(bl, br, tl, tr)
            return [newPoints, cropPolygon]
        }

        // NOTE: Return points and polygon of cropped rectangle
        const newPoints = getFeaturePoints(
            croppedRectangle[0],
            croppedRectangle[1],
            croppedRectangle[2],
            croppedRectangle[3])

        const cropPolygon = getCropPolygon(
            croppedRectangle[0],
            croppedRectangle[1],
            croppedRectangle[2],
            croppedRectangle[3])

        return [newPoints, cropPolygon]
    }

    public calculateVectorScaleFromMovedEdge(
        movedPoint: ExtentPoint,
        movedPointCoordinates: Coordinate,
        bl: [number, number],
        br: [number, number],
        tl: [number, number],
        tr: [number, number]) {
        let points: any[] // points for corners of edges involved in vector resizing
        let edge: any[] // edge moving away from
        let originalEdge: any[] // extent edge

        const [blo, bro, tlo, tro] = rotateExtentAroundCentre(this.originalImageExtent, -degreesToRadians(this.overlay.rotation))
        let [blc, brc, tlc, trc] = rotateExtentAroundCentre(this.cropExtent, -degreesToRadians(this.overlay.rotation))

        switch (movedPoint) {
            case ExtentPoint.LeftCentre:
                points = [[bl, tl], [br, tr]]
                edge = [bl, tl]
                originalEdge = [blo, tlo]
                break
            case ExtentPoint.RightCentre:
                points = [[br, tr], [bl, tl]]
                edge = [br, tr]
                originalEdge = [bro, tro]
                break
            case ExtentPoint.TopCentre:
                points = [[tl, tr], [bl, br]]
                edge = [tl, tr]
                originalEdge = [tlo, tro]
                break
            case ExtentPoint.BottomCentre:
                points = [[bl, br], [tl, tr]]
                edge = [bl, br]
                originalEdge = [blo, bro]
                break
            default:
                console.error('Point not recognised')
                break
        }

        // Movement needs to be fixed onto the axis of travel
        const normalizedDirection = getDirectionVector(points[0], points[1])
        const normalizedDirectionReversed = getDirectionVector(points[1], points[0])

        // Calculate direction of travel of point, so as to allow cropping in / out
        const vector = vectorFromPoints(midpoint(edge[0], edge[1]), movedPointCoordinates)
        const projected = dotProduct(vector, normalizedDirection)
        const isDraggingTowardsOriginal = projected < 0

        const translationAmount = distanceFromPointToLine(movedPointCoordinates, edge[0], edge[1])
        const adjustedTranslationAmount = isDraggingTowardsOriginal ? -translationAmount : translationAmount

        // TODO: tidy
        const pt = point(movedPointCoordinates)
        const poly = polygon([[blo, tlo, tro, bro, blo]])
        const ptInPoly = booleanPointInPolygon(pt, poly)

        // Calculate direction of travel of point, so as to allow cropping in / out

        const edgeCornerOne = translatePoint(edge[0], normalizedDirection, adjustedTranslationAmount)
        const edgeCornerTwo = translatePoint(edge[1], normalizedDirection, adjustedTranslationAmount)

        const sideBefore = Math.sign(crossProduct(originalEdge[0], originalEdge[1], edge[0]))
        const sideAfter = Math.sign(crossProduct(originalEdge[0], originalEdge[1], edgeCornerOne))
        const shouldCorrectCropPoint = sideBefore !== sideAfter

        switch (movedPoint) {
            case ExtentPoint.LeftCentre:
                bl = ptInPoly ? edgeCornerOne : this.getCorrectedCropPoint(shouldCorrectCropPoint, blc, normalizedDirectionReversed, edge[0], originalEdge)
                tl = ptInPoly ? edgeCornerTwo : this.getCorrectedCropPoint(shouldCorrectCropPoint, tlc, normalizedDirectionReversed, edge[1], originalEdge)
                break
            case ExtentPoint.RightCentre:
                br = ptInPoly ? edgeCornerOne : this.getCorrectedCropPoint(shouldCorrectCropPoint, brc, normalizedDirectionReversed, edge[0], originalEdge)
                tr = ptInPoly ? edgeCornerTwo : this.getCorrectedCropPoint(shouldCorrectCropPoint, trc, normalizedDirectionReversed, edge[1], originalEdge)
                break
            case ExtentPoint.TopCentre:
                tl = ptInPoly ? edgeCornerOne : this.getCorrectedCropPoint(shouldCorrectCropPoint, tlc, normalizedDirectionReversed, edge[0], originalEdge)
                tr = ptInPoly ? edgeCornerTwo : this.getCorrectedCropPoint(shouldCorrectCropPoint, trc, normalizedDirectionReversed, edge[1], originalEdge)
                break
            case ExtentPoint.BottomCentre:
                bl = ptInPoly ? edgeCornerOne : this.getCorrectedCropPoint(shouldCorrectCropPoint, blc, normalizedDirectionReversed, edge[0], originalEdge)
                br = ptInPoly ? edgeCornerTwo : this.getCorrectedCropPoint(shouldCorrectCropPoint, brc, normalizedDirectionReversed, edge[1], originalEdge)
                break
            default:
                console.error('Point not recognised')
                break
        }

        const newPoints = getFeaturePoints(bl, br, tl, tr)
        const cropPolygon = getCropPolygon(bl, br, tl, tr)

        return [newPoints, cropPolygon]
    }

    public resetCropLayer(newPoints, polygon): void {
        this.cropLayer.getSource().clear()

        const rectangle = new Feature({
            geometry: new Polygon([polygon]),
        })
        const rectangleStyle = new Style({
            stroke: new Stroke({
                color: '#0098FF',   // Outline color
                width: 2,         // Outline width
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 0, 0)', // make it transparent
            }),
        })
        rectangle.setStyle(rectangleStyle)
        rectangle.setId('CROP')

        this.pointCollection = []

        CropExtentPoints.forEach((x: ExtentPoint) => {
            const handlesFeature = this.createHandles(newPoints[x], x)
            const feature = new Feature({
                geometry: new Point(newPoints[x]),
            })
            feature.setId(x)
            feature.on('change', this.onCropping.bind(this))
            this.pointCollection.push(feature)
            this.cropLayer.getSource().addFeature(feature)
            this.cropLayer.getSource().addFeature(handlesFeature)
        })

        this.cropLayer.getSource().addFeature(rectangle)

        const centre = midpoint(polygon[0], polygon[2])

        const newExtent = findRectangleExtent(
            [polygon[0], polygon[3], polygon[2], polygon[1]],
            this.overlay.rotation,
            centre)

        this.cropExtent = newExtent
    }

    public resetCropInteraction() {
        if (this.modifyInteraction) {
            this.removeModifyInteraction()
        }
        this.modifyInteraction = new Modify({
            features: new Collection(this.pointCollection),
            style: null,
        })
        this.modifyInteractionId = this.modifyInteraction['ol_uid']
        this.targetMap.addInteraction(this.modifyInteraction)
        this.modifyInteraction.on('modifyend', this.resetCropInteraction.bind(this))
        this.cb()
    }

    public getCropFeature(): any {
        return this.cropLayer.getSource().getFeatureById('CROP')
    }

    public getCropFeatureUnrotated(): any {
        const cropExtent = this.getCropExtent()
        const [bl, br, tl, tr] = getCoordinatesFromExtent(cropExtent)
        const feature = new Feature({ geometry: new Polygon([[bl, tl, tr, br, bl]]) })
        return feature
    }

    private getCropExtent(): any {
        // The extent we have been modifying is rotated
        // We need to get it axis aligned with the original image extent

        // Align on its own axis
        const cropExtent = rotateExtentAroundCentre(
            this.cropExtent,
            -degreesToRadians(this.overlay.rotation))

        const [bl, br, tl, tr] = cropExtent
        const rotatedCropExtentPolygon = getCropPolygon(bl, br, tl, tr)

        // Align on the image extent axis
        const cropExtentRotatedBackAroundOriginalCentrePoint = findRectangleExtent(
            rotatedCropExtentPolygon,
            this.overlay.rotation,
            calculateCentrePoint(this.originalImageExtent))

        return cropExtentRotatedBackAroundOriginalCentrePoint
    }

    private getCorrectedCropPoint(
        translateTowardsOriginal: boolean,
        cropEdge: [number, number],
        normalizedDirection: [number, number],
        pointToMove: [number, number],
        originalEdge: [number, number][]): [number, number] {
        return translateTowardsOriginal
            ? translatePoint(cropEdge, normalizedDirection, distanceFromPointToLine(pointToMove, originalEdge[0], originalEdge[1]))
            : cropEdge
    }

    private removeModifyInteraction(): void {
        this.targetMap.removeInteraction(this.modifyInteraction)
        const interactions = this.targetMap.getInteractions().getArray()
        const modify = interactions.find(x => x['ol_uid'] === this.modifyInteractionId)
        if (modify) {
            this.targetMap.getInteractions().remove(modify)
        }
    }

    private createHandles(centerCoordinate: [number, number], pointIndex: number): Feature {
        let rectCoordinates: [number, number][]
        // Corner handles angle order: TL, TR, BR, BT
        const rotationAnglesLHandleDeg = [270, 180, 90, 0].map(angle => angle - this.overlay.rotation)
        // Middle handles order: T, B, L, R
        const rotationAnglesRect = [90, 90, 0, 0].map(angle => angle - this.overlay.rotation)
        // First 4 indices are the corners, next 4 are the middle points
        const clampedScale = Math.min(Math.max(this.overlay.scale, this.minScaleDomain), this.maxScaleDomain)
        if (pointIndex < 4) {
            rectCoordinates = this.createLShapedHandle(centerCoordinate, rotationAnglesLHandleDeg[pointIndex], clampedScale)
        } else {
            rectCoordinates = this.createRectangleHandle(centerCoordinate, rotationAnglesRect[pointIndex-4], clampedScale)
        }
        const rectFeature = new Feature({
            geometry: new Polygon([rectCoordinates]),
        })
        const rectangleStyle = new Style({
            stroke: new Stroke({
                color: '#0098FF',   // Outline color
                width: 2,         // Outline width
            }),
            fill: new Fill({
                color: 'rgba(255, 255, 255, 1)',  // Fill color with alpha transparency
            }),
        })
        rectFeature.setStyle(rectangleStyle)
        return rectFeature
    }

    private createRectangleHandle(coordinates: [number, number], angle: number, scaleRatio: number) {
        const rectWidth = convertValueToDomain(this.minScaleDomain, this.maxScaleDomain, this.minWidthDomain, this.maxWidthDomain, scaleRatio)
        const rectHeight = convertValueToDomain(this.minScaleDomain, this.maxScaleDomain, this.minHeightDomain, this.maxHeightDomain, scaleRatio)
        const rectCoordinates = [
            [coordinates[0] - rectWidth, coordinates[1] - rectHeight],  // Bottom left corner
            [coordinates[0] + rectWidth, coordinates[1] - rectHeight],  // Bottom right corner
            [coordinates[0] + rectWidth, coordinates[1] + rectHeight],  // Top right corner
            [coordinates[0] - rectWidth, coordinates[1] + rectHeight],  // Top left corner
            [coordinates[0] - rectWidth, coordinates[1] - rectHeight],   // Closing the rectangle
        ].map(point => rotatePoint(point as [number, number], coordinates, degreesToRadians(angle)))

        return rectCoordinates
    }

    private createLShapedHandle(center: [number, number], angle: number, scaleRatio: number) {
        const smallCenterDistance = convertValueToDomain(this.minScaleDomain, this.maxScaleDomain, this.minWidthDomain, this.maxWidthDomain, scaleRatio)
        const short = convertValueToDomain(this.minScaleDomain, this.maxScaleDomain, this.minLShortDomain, this.maxLShortDomain, scaleRatio)
        const long = convertValueToDomain(this.minScaleDomain, this.maxScaleDomain, this.minHeightDomain, this.maxHeightDomain, scaleRatio)
        const bl = [center[0] - smallCenterDistance, center[1] - smallCenterDistance]
        const brb = [bl[0] + long, bl[1]]
        const brt = [brb[0], brb[1] + short]
        const blt = [center[0] + smallCenterDistance, center[1] + smallCenterDistance]
        const tr = [blt[0], blt[1] + long - short]
        const tl = [tr[0] - short, tr[1]]
        const lHandle = [
            bl,  // Bottom left corner
            brb, // Bottom right corner
            brt, // Bottom right upper corner
            blt, // L inflexion
            tr, // Top right corner
            tl, // Top left corner
            bl,   // Close polygon
        ].map(point => rotatePoint(point as [number, number], center, degreesToRadians(angle)))

        return lHandle
    }


    public dispose(): void {
        this.targetMap.getLayers().forEach(layer => {
            if (layer) {
                const name = layer.get('name')
                if (name === this.originalImageOverlayName) {
                    this.targetMap.removeLayer(layer)
                }
            }
        })
        this.cropLayer.getSource().clear()
        this.targetMap.removeLayer(this.cropLayer)
        this.removeModifyInteraction()
    }
}
