<template>
    <div>
        <div v-if="!loading"
             class="matter-searches-filter d-flex flex-column justify-space-between">
            <div class="matter-searches-filter__content d-flex justify-space-between flex-wrap gap-4">
                <div class="d-flex flex-column matter-searches-slot">
                    <slot />
                </div>
                <div class="d-flex matter-searches-filter__combos">
                    <div v-if="showSearchProvider && providers"
                         class="d-flex flex-row align-center gap-4">
                        <label for="provider"
                               class="caption-regular text-no-wrap">
                            {{ $t('searches.provider') }}
                        </label>
                        <v-select id="provider"
                                  v-model="provider"
                                  class="matter-searches-filter__combo caption-regular"
                                  data-test="matter-searches-filter-provider"
                                  :items="providers"
                                  hide-details
                                  item-title="name"
                                  item-value="id"
                                  :disabled="basketCalculating"
                                  variant="solo" />
                    </div>
                    <div v-if="productPreferences && showProductPreference"
                         class="d-flex flex-row align-center gap-4">
                        <label for="product-preference"
                               class="caption-regular text-no-wrap">
                            {{ $t('searches.productPreference') }}
                        </label>
                        <v-select id="product-preference"
                                  v-model="productPreference"
                                  class="matter-searches-filter__combo caption-regular"
                                  data-test="matter-searches-filter-product-preference"
                                  hide-details
                                  item-title="name"
                                  item-value="id"
                                  variant="solo"
                                  :disabled="basketCalculating"
                                  :items="productPreferences" />
                    </div>
                </div>
            </div>
            <div class="d-block">
                <ow-text-field v-if="showSearchFilter"
                               v-model="searchText"
                               class="matter-searches-filter pt-4"
                               data-test="matter-searches-filter-search-text"
                               :max-length="1000"
                               :placeholder="$t('searches.filterProducts').toString()"
                               small
                               clearable
                               :read-only="basketCalculating"
                               @click:clear="searchText = ''">
                    <template #iconPrefix>
                        <v-icon>$search</v-icon>
                    </template>
                </ow-text-field>
            </div>
        </div>
        <matter-searches-filter-loading-skeleton v-else />
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'

    import OwTextField from '@/components/core/ow-textfield.vue'
    import MatterSearchesFilterLoadingSkeleton from '@/components/loading-skeletons/matter-searches-filter-loading-skeleton.vue'
    import {ISearchProductPreferenceList,
            SearchProductsFilter,
            SearchProvider} from '@/store/modules/searches/types'

    const props = withDefaults(defineProps<{
        providers?: SearchProvider[]
        productPreferences?: ISearchProductPreferenceList[]
        filter?: SearchProductsFilter
        loading?: boolean
        basketCalculating?: boolean
        showSearchFilter?: boolean
        showSearchProvider?: boolean
        showProductPreference?: boolean
    }>(), {
        showSearchProvider: false,
        showProductPreference: true,
    })

    const searchText = ref('')
    const provider = ref<number>(0)
    const productPreference = ref<number>()
    const mounting = ref(false)

    const filterSearchProviderId = computed(() => props.filter?.searchProviderId)
    const filterProductPreferenceId = computed(() => props.filter?.productPreferenceId)
    const filterSearchText = computed(() => props.filter?.searchText)

    const emit = defineEmits<{
        (e: 'filter', args: SearchProductsFilter): void
    }>()

    watch(filterSearchProviderId, (val) => {
        mounting.value = true
        try {
            provider.value = val
        } finally {
            mounting.value = false
        }
    }, {
        immediate: true,
    })

    watch(filterProductPreferenceId, (val) => {
        mounting.value = true
        try {
            productPreference.value = val
        } finally {
            mounting.value = false
        }
    }, {
        immediate: true,
    })

    watch(filterSearchText, (val) => {
        mounting.value = true
        try {
            searchText.value = val
        } finally {
            mounting.value = false
        }
    }, {
        immediate: true,
    })

    watch([searchText, provider, productPreference], () => {
        if (mounting.value) {
            return
        }
        emit('filter', {
            searchText: searchText.value,
            searchProviderId: provider.value,
            productPreferenceId: productPreference.value,
        })
    })

</script>

<style lang="scss">
    @import './matter-searches-filter.scss';
</style>
