import {
    Fill,
    Style,
} from 'ol/style'
import { LayerNames } from '@/consts/map-layers'
import {
    BaseMvtDataLayer,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { MapBrowserEvent } from 'ol'
import Feature, { FeatureLike } from 'ol/Feature'
import { StyleLike } from 'ol/style/Style'
import {
    FloodZoneLayerParams,
    RiskSortOrder,
    getFloodZoneRolloverOptions,
} from '@/store/modules/map/layers/flood-layers-common'
import i18n from '@/plugins/i18n'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { unique } from '@/utils/array-utils'

import { FloodRiskFeaturesType } from './flood-layers-common'

enum RiskColours {
    'High' = 'rgba(211, 84, 0, 0.6)',
    'Medium' = 'rgba(235, 152, 78, 0.7)',
    'Low' = 'rgba(248, 196, 113, 0.8)',
    'Very Low' = 'rgba(249, 231, 159, 0.7)',
}

// This layer is currently updated manually. Note the attribution text is hard-coded.
export class FloodRisksSeaAndRiversLayer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: FloodZoneLayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:vflood_risk_river_and_sea2024'
        this.name = LayerNames.FloodRiskSeaAndRivers
        this.i18nNameRef = 'map.options.legend.floodZones[2]'
        this.attributionText = [i18n.global.t('map.options.flood.attribution.englandAndWales')]
        this.rolloverOptions = getFloodZoneRolloverOptions(LayerNames.FloodRiskSeaAndRivers, this.style)
        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Flood Risk - Rivers and Sea layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                const textArray = features.length > 0
                    ? FloodRisksSeaAndRiversLayer.getFloodRiskHoverTextArray(features[0].getProperties() as FloodRiskFeaturesType)
                    : []
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public style:StyleLike = this.getStyleForFeature.bind(this)

    private getStyleForFeature(feature: Feature): Style {
        const properties = feature.getProperties() as FloodRiskFeaturesType
        const colour = RiskColours[properties.risk]
        const styleHash = `${ properties.risk }-${ colour }`
        const result = this.styleCache.get(styleHash)

        if (result) {
            return result
        }

        const style = new Style({
            zIndex: 320,
            fill: new Fill({ color: colour }),
        })

        this.styleCache.set(styleHash, style)
        return style
    }

    public static getFloodRiskHoverTextArray = (properties: FloodRiskFeaturesType):string[] =>
        [
            `${ i18n.global.t('map.options.flood_risks.hover.risk') }: ${ properties.risk }`,
            `${ i18n.global.t('map.options.flood_risks.hover.type') }: ${ properties.type }`,
            `${ i18n.global.t('map.options.flood_risks.hover.source') }: ${ properties.source }`,
        ]

    getKeyItems(): Array<KeyConfigItemModel> {
        return unique(this.layer
            // Get features in extent
            .getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
            // Get distinct risk values
            .map(x => x.get('risk')))
            // Sort
            .sort((a, b) => RiskSortOrder.get(b) - RiskSortOrder.get(a))
            // Map to key items
            .map(x => {
                return {
                    id: `${ this.name }-${ x }`,
                    label: `${ i18n.global.t('map.options.flood_risks.text_sea_and_rivers') } - ${ x }`,
                    style: {
                        fillColour: RiskColours[x],
                        strokeColour: 'transparent',
                    },
                }
            })
    }
}
