export enum SketchMode
{
    None = 'none',
    Area = 'area',
    Line = 'line',
    Text = 'text',
    Marker = 'marker'
}
export enum SketchType
{
    Area = 1,
    Line = 2,
    Text= 3,
    Marker = 4,
    Point = 5,
 }
export enum SketchSource {
  User,
  SpatialFileImport,
  DrawOnMapTitleSearchImport,
  SpatialFileTitleSearchImport,
}
export enum SketchGeometryType{
    POLYGON = 'Polygon',
    MULTI_POLYGON = 'MultiPolygon',
    POINT = 'Point',
    MULTI_POINT = 'MultiPoint',
    LINE_STRING = 'LineString',
    MULTI_LINE_STRING = 'MultiLineString',
}
export enum SnappingMode {
    None = 'none',
    Sketches= 'sketches',
    TitleBoundaries = 'titleBoundaries',
}

export enum SketchExportFormat{
    Shapefile = 'shapefile',
    GeoJSON = 'geojson',
}

export enum Arrowhead
{
    None,
    Start,
    End,
    Both
 }

export enum SketchesUnit {
    Metric = 0,
    Imperial = 1,
    Hectares = 2,
}

export enum ToolActionType {
    Clear = 0,
    Done = 1,
    Undo = 2,
    SnapTo = 3,
    SelectStyle = 4,
    EditName = 5,
}
