<template>
    <div class="title-complexity-flags">
        <h2 class="label-caps-smallest title-complexity-flags__heading">
            {{ t('titlePanel.cards.complexity.labels.flags') }}
        </h2>

        <ow-loading-skeleton v-if="isLoading"
                             data-test="title-details-panel-loading"
                             height="24px"
                             width="100%" />

        <ul v-else
            class="title-complexity-flags__flag-container"
            data-test="title-complexity-flags">
            <li v-for="indicator in linkedIndicators?.sort(dynamicSort('label'))"
                :key="indicator.label"
                class="flag">
                <span :class="{
                          'flag__count--highlight caption-highlight': indicator.codes.length > 0,
                          'caption-regular': indicator.codes.length === 0,
                      }"
                      class="flag__count">{{ indicator.codes.length }}</span>


                <div v-if="indicator.codes.length === 0"
                     class="flag__label caption-regular"
                     data-test="title-details-panel-complexity-flag">
                    {{ indicator.label }}
                </div>
                <a v-else
                   class="flag__label caption-highlight flag__label--clickable"
                   data-test="title-details-panel-complexity-flag-link"
                   data-track="TITLE-DETAILS-PANEL - Clicked on complexity flag"
                   :data-track-attribute="indicator.label"
                   href="#"
                   @click.prevent="handleFlagClick(indicator)">
                    {{ indicator.label }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import { ISimplifiedRegisterLinkedIndicator } from "@/interfaces/documents/entry-type.interface"
    import { ILinkedIndicator } from '@/interfaces/title/linked-indicators.interface'
    import {
        TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
        TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN,
    } from '@/store/modules/titles/types'
    import {
        dynamicSort,
        isNullOrEmpty,
    } from '@/utils/array-utils'

    defineProps<{
        isLoading: boolean,
        linkedIndicators: Array<ILinkedIndicator>,
    }>()

    const emit = defineEmits<{
        (e: 'set-selected-tab', payload: { tabId: TitlePanelTabName }),
    }>()

    const { t } = useI18n()
    const store = useStore()

    const handleFlagClick = (indicator: ILinkedIndicator): void => {
        store.commit(TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN, [])
        emit('set-selected-tab', { tabId: TitlePanelTabName.Register })

        const newIndicator: ISimplifiedRegisterLinkedIndicator = {
            label: indicator.label,
            selected: true,
            codes: indicator.codes.map((code) => code.code),
            colour: indicator.colour,
        }
        store.commit(TITLE_MUTATE_REGISTER_INDICATOR_SELECTION, newIndicator)
    }
</script>

<style lang="scss" scoped>
    @import './title-complexity-flags';
</style>
