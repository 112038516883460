<template>
    <v-tooltip :content-class="contentClass"
               :location="position"
               :disabled="disabled"
               :max-width="maxWidth"
               :location-strategy="locationStrategy"
               :activator="activator"
               class="ow-tooltip caption-regular">
        <template #activator="{ props }">
            <slot name="activator"
                  v-bind="props" />
        </template>
        <slot />
    </v-tooltip>
</template>

<script lang="ts">
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"

    export default {
        name: 'OwTooltip',

        props: {
            // eslint-disable-next-line vue/no-unused-properties
            disabled: {
                type: Boolean,
                default: false,
            },
            // eslint-disable-next-line vue/no-unused-properties
            maxWidth: {
                type: String,
                default: '250px',
            },
            // eslint-disable-next-line vue/no-unused-properties
            centerContent: {
                type: Boolean,
                default: false,
            },
            position:{
                type: String,
                default: OwTooltipPosition.Top,
            },
            activator: {
                type: String,
                default: null,
            },
            locationStrategy: {
                type: String,
                default: 'connected',
            },
            theme: {
                type: String,
                default: 'dark',
            },
        },
        computed: {
            contentClass() {
                return [
                    'caption-regular',
                    'ow-tooltip__content',
                    `ow-tooltip__content--${ this.position }`,
                    this.centerContent ? 'ow-tooltip__content--centered' : undefined,
                    `ow-tooltip__content--${ this.theme }`,
                ].join(' ')
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-tooltip.scss';
</style>
