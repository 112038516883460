
// @/stores/core/getters.ts
import { ICoreState } from "."

export default {
    /**
     * Retrieves the reset page pagination state
     *
     * @returns {Function} returns the core pagination reset after update state.
     */
    resetPageAfterUpdate(state: ICoreState): boolean {
        return state.pagination.resetPageAfterUpdate
    },
}
