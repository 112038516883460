<template>
    <Skeletor :height="height"
              :width="widthValue"
              :circle="circle"
              :class="colorClass"
              :style="{ margin: marginValue }"
              :pill="pill" />
</template>

<script>
    import {defineComponent} from 'vue'
    import {Skeletor} from 'vue-skeletor'

    export default defineComponent({
        components: {
            Skeletor,
        },

        props: {
            width: {
                type: String,
                required: false,
                default: '100%',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
            marginTop: {
                type: String,
                required: false,
                default: '0',
            },
            marginRight: {
                type: String,
                required: false,
                default: '0',
            },
            marginBottom: {
                type: String,
                required: false,
                default: '0',
            },
            marginLeft: {
                type: String,
                required: false,
                default: '0',
            },
            margin: {
                type: String,
                required: false,
                default: null,
            },
            circle: {
                type: Boolean,
                required: false,
            },
            pill: {
                type: Boolean,
                required: false,
            },
            darkerColor: {
                type: Boolean,
                required: false,
            },
            lighterColor: {
                type: Boolean,
                required: false,
            },
            isPrimaryColor: {
                type: Boolean,
                required: false,
            },
            borderRadius: {
                type: Boolean,
                required: false,
                default: true,
            },
        },

        computed: {
            marginValue() {
                if (this.margin) {
                    return `${ this.margin.split(' ').join('px ') }px`
                } else {
                    return `${ this.marginTop }px ${ this.marginRight }px ${ this.marginBottom }px ${ this.marginLeft }px`
                }
            },

            widthValue() {
                return this.width === '100%' ? '100%' : this.width + 'px'
            },

            colorClass() {
                return {
                    'highlight-primary': this.isPrimaryColor && !this.lighterColor,
                    'highlight-primary--light': this.isPrimaryColor && this.lighterColor,
                    'highlight-default': !this.isPrimaryColor,
                    'highlight-default--light': !this.isPrimaryColor && this.lighterColor,
                    'highlight-default--darker': !this.isPrimaryColor && this.darkerColor,
                    'rounded': this.borderRadius && !this.pill && !this.circle,
                }
            },
        },
    })
</script>

<style lang="scss">
    @import './ow-loading-skeleton.scss';
    @import 'vue-skeletor/dist/vue-skeletor.css';
</style>
