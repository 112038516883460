<template>
    <div class="title-panel-register">
        <div class="title-panel-register__container">
            <order-register-card :current-matter-id="currentMatterId"
                                 :is-ordering-allowed="isOrderingAllowed"
                                 :order-with-checkout="orderWithCheckout"
                                 :selected-title="selectedTitle"
                                 :selected-title-number="selectedTitleNumber"
                                 :user-has-vts-charge="userHasVTSCharge" />

            <div v-if="hasBackdatedInfo"
                 class="title-panel-register__container--backdated">
                <div>
                    <ow-icon-text :is-italic-text="true"
                                  :is-single-line="false"
                                  :text="backdatedText"
                                  icon="$info" />
                </div>
            </div>

            <v-container v-if="isRegisterLoading">
                <ow-card-skeleton :rows="4"
                                  :columns="1"
                                  :expand-rows="false" />
            </v-container>
            <v-container v-else
                         v-show="selectedTitle?.record?.titleMetadata?.bgDataLoaded"
                         class="title-panel-register__data"
                         tag="section">
                <div class="title-panel-register__actions">
                    <v-text-field v-model="searchTextInput"
                                  autocomplete="new-password"
                                  class="title-panel-register__search"
                                  clearable
                                  data-test="register-search"
                                  data-track="TITLE-DETAILS-PANEL - search register entries"
                                  hide-details
                                  label="Keyword search"
                                  prepend-icon="$search"
                                  @click:clear="onSearchClear()" />
                    <v-checkbox id="titlePanelRegisterHighlightDates"
                                v-model="highlightDates"
                                color="primary"
                                data-test="checkbox-highight-dates"
                                data-track="TITLE-DETAILS-PANEL - highlight dates in register"
                                hide-details
                                label="Highlight dates &amp; times" />
                    <v-btn id="menu-activator"
                           :disabled="entryTypes.length === 0"
                           class="flags-button"
                           size="large"
                           data-test="btn-select-flags"
                           data-track="TITLE-DETAILS-PANEL - Select register flags">
                        <v-icon v-if="selectedEntryTypes.length === 0">
                            $filter
                        </v-icon>
                        <v-icon v-if="selectedEntryTypes.length > 0"
                                color="primary">
                            $filter
                        </v-icon>
                        <span>Flags</span>
                    </v-btn>
                    <v-menu :close-on-content-click="false"
                            activator="#menu-activator"
                            content-class="title-panel-register__menu-content">
                        <v-list>
                            <v-list-item v-for="(item, index) in entryTypes"
                                         :key="index"
                                         :value="index"
                                         :class="{'selected': entryTypeIsSelected(item)}"
                                         class="body-copy"
                                         data-test="filter-flags-entry"
                                         data-track="TITLE-DETAILS-PANEL - Register flag"
                                         @click="toggleEntryType(item)">
                                <v-list-item-title>
                                    {{ item.label }} <strong>({{ item.codes.length }})</strong>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>

                <div class="title-panel-register__flags">
                    <v-chip v-for="(item, index) in selectedEntryTypes"
                            :key="index"
                            :style="getTypeFilterStyle(item)"
                            style="padding: 15px;"
                            data-track="TITLE-DETAILS-PANEL - Remove register flag chip"
                            @click="toggleEntryType(item)">
                        <v-icon size="small"
                                style="margin-right: 4px">
                            $close
                        </v-icon>
                        <span data-test="flag-chip">{{ item.label }}</span>
                    </v-chip>
                </div>

                <!-- Registers -->
                <v-expansion-panels v-model="openedPanels"
                                    data-test="register"
                                    variant="accordion"
                                    multiple>
                    <register-item v-for="register in registers"
                                   :key="register.prefix"
                                   :highlight-dates="highlightDates"
                                   :register="register"
                                   :is-expanded="openedPanels.includes(register.prefix)"
                                   :show-entry-types="selectedEntryTypes"
                                   @title-number-selected="goToTitle" />
                </v-expansion-panels>
            </v-container>
        </div>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwIconText from '@/components/core/ow-icon-text.vue'
    import OwCardSkeleton from '@/components/loading-skeletons/ow-card-skeleton.vue'
    import OrderRegisterCard from '@/components/title-panel/tabs/order-register-card.vue'
    import RegisterItem from '@/components/title-panel/tabs/register/register-item.vue'
    import { useCompromise } from '@/composables/use-compromise'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        TITLE_LOOKUP_TITLE,
        TITLE_MUTATE_ENTRY_TYPES_SELECTION,
        TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
    } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export default {
        name: 'TitlePanelRegister',

        components: {
            OrderRegisterCard,
            OwIconText,
            RegisterItem,
            OwCardSkeleton,
        },

        props: {
            isLoading: {
                type: Boolean,
                required: true,
                default: false,
            },
            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },
            orderWithCheckout: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'title-number-selected',
        ],

        setup() {
            const { compromise } = useCompromise()
            return {
                compromise,
            }
        },

        data() {
            return {
                // Filtering by text / dates & times
                searchTextInput: null,
                searchTextInputLoggingDebounce: null,
                highlightDates: false,
                loading: false,

                registers: [],
                entryTypes: [], // a distinct list of registry entry types used to populate the type filter

                likeTerms: {
                    // "Search text entered": "replace with and also try to find in content"
                    ' and': ' &',
                    '&': 'and',
                    s: '\'s',
                    '\'s': 's',
                    '-': ' ',
                    ' ': '-',
                },

                // nlp - currently basic client-side implementation
                nlp: {
                    dates: [],
                    ignoreDateValues: [' now', ' may', 'set'],
                },

                // Used to determine space for scrollable content within the registerContent element
                availableRegisterContentHeight: 0,

                openedPanels: [],
            }
        },

        computed: {
            ...mapState({
                currentMatterId: (state: IState) => state.matter.currentMatter.id,
                titleSummaryCharge: (state: IState) => state.user.titleSummaryCharge,
                selectedTitle: (state: IState) => state.title.selectedTitle,
                selectedTitleNumberFromState: (state: IState) => state.title.selectedTitleNumber,
                pendingRegisterIndicatorSelection: (state: IState) => state.title.pendingRegisterIndicatorSelection,
                selectedEntryTypes: (state: IState) => state.title.selectedEntryTypes,
            }),

            selectedTitleNumber: {
                get(): string {
                    return this.selectedTitleNumberFromState ?? ''
                },
                set(val: string) {
                    this.$store.dispatch(TITLE_LOOKUP_TITLE, val)
                },
            },

            userHasVTSCharge(): boolean {
                return this.titleSummaryCharge !== null
            },

            searchText(): any[] {
                /* We may want to tidy the text (searchInput) before attempting to search on it
                 * and variations on it */
                const results = []

                // Search on detected date strings if option selected
                if (this.highlightDates === true) {
                    results.push(...this.nlp.dates)
                }

                if (this.searchTextInput != null) {
                    if (this.searchTextInput.trim().length > 1) {
                        results.push(this.searchTextInput.trim())
                    }

                    // Get similar text to also search on that
                    const text = this.searchTextInput.toLowerCase()
                    for (const term in this.likeTerms) {
                        if (text.indexOf(term.toLowerCase()) > -1) {
                            results.push(text.replace(term, this.likeTerms[term]))
                        }
                    }
                }

                // Tidy up the text strings
                return results.filter(item => {
                    return (item && item.trim().length > 0)
                })
            },

            hasBackdatedInfo(): boolean {
                return this.selectedTitle?.record?.titleMetadata?.isBackOrderCopy
            },

            backdatedText(): string {
                return this.$t('backdatedInfoFlag', { date: this.selectedTitle?.record?.bgProprietorshipData?.dateDataOC })
            },

            isRegisterLoading(): boolean {
                return this.isLoading || this.loading
            },

            loaded(): boolean {
                return this.selectedTitle?.record?.titleMetadata?.bgDataLoaded
            },
        },

        watch: {
            searchTextInput(val) {
                if (val != null) {
                    if (val.trim().length > 1) {
                        // Log a search event if required
                        if (this.searchTextInputLoggingDebounce != null) {
                            window.clearTimeout(this.searchTextInputLoggingDebounce)
                        }
                        this.searchTextInputLoggingDebounce = window.setTimeout(() => {
                            this.logHeapEvent({
                                type: 'Register Search',
                                metadata: {
                                    text: this.searchTextInput,
                                    titleNumber: this.selectedTitleNumber,
                                },
                            })
                        }, 1500)
                    }
                }

                this.updateData()
            },

            selectedEntryTypes() {
                window.setTimeout(() => {
                    this.recalculateAvailableContentHeight()
                }, 10)
                this.updateData()
            },

            highlightDates() {
                this.updateData()
            },

            pendingRegisterIndicatorSelection: {
                handler(val) {
                    // a request has been made to select an indicator (as a filter) within the title panel register tab
                    if (val != null) {
                        // Update existing selection
                        this.setEntryTypesSelection([val])

                        // Done, remove pending selection
                        this.setRegisterIndicatorSelection(null)
                    }
                },
                immediate: true,
            },

            loaded: {
                handler(val) {
                    if (!val) {
                        return
                    }

                    // Wait for the register data to be available before processing
                    setTimeout(() => {
                        this.loading = true
                        try {
                            this.createRegisterData()
                            this.populateEntryTypes()
                            this.detectTermsOfInterest()
                            this.updateData()
                            this.recalculateAvailableContentHeight()
                        } finally {
                            this.loading = false
                        }
                    }, 100)
                },
                immediate: true,
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapMutations({
                setRegisterIndicatorSelection: TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
                setEntryTypesSelection: TITLE_MUTATE_ENTRY_TYPES_SELECTION,
            }),

            createRegisterData() {
                if (this.selectedTitle?.record?.bgProprietorshipData?.registers != null) {
                    this.registers = this.selectedTitle.record.bgProprietorshipData.registers.map(register => {
                        register.show = true
                        register.searchCount = 0
                        register.highlightText = []
                        register.highlightEntryNumbers = []
                        register.showEntries = []
                        register.showAllEntries = false
                        register.filterCounts = []
                        return register
                    })
                }
                return []
            },

            // Based on the register data available for the title, pick out the types to use in the filter
            populateEntryTypes() {
                this.entryTypes = []
                this.setEntryTypesSelection([])
                if (this.selectedTitle?.record?.bgProprietorshipData?.linkedIndicators != null) {
                    this.entryTypes = this.selectedTitle.record.bgProprietorshipData.linkedIndicators.filter(indicator => {
                        return indicator.codes.length > 0
                    })

                    this.entryTypes.sort((a, b) => {
                        const textA = a.label.toUpperCase()
                        const textB = b.label.toUpperCase()
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
                    })
                }
            },

            updateData() {
                this.registers.forEach(register => {
                    this.populateRegisterInformation(register)
                })

                this.openedPanels = (this.registers || [])
                    .map(register => !isNullOrEmpty(register.showEntries) ? register.prefix : null)
                    .filter(register => register !== null)
            },

            detectTermsOfInterest() {
                let combinedText = ''
                this.registers.forEach(register => {
                    const combineText = text => {
                        combinedText = combinedText + ' ' + text
                    }

                    // Combine text from the main registers
                    register.entries.forEach(entry => {
                        combineText(entry.displayString)
                    })
                })

                // Extract dates / periods
                const doc: any = this.compromise(combinedText)
                const dateTerms: any = doc.dates().json()
                const filteredDateTerms = dateTerms.filter(term => this.nlp.ignoreDateValues.indexOf(term.text) === -1)
                    .map(term => term.text)
                this.nlp.dates = [...new Set(filteredDateTerms)]
                // Remove unhelpful terms
                this.nlp.dates = [...new Set(dateTerms.filter(term => {
                    return this.nlp.ignoreDateValues.indexOf(term.text) === -1
                }).map(term => {
                    return term.text
                }))]
            },

            populateRegisterInformation(register) {
                // Register structures are the same, this function avoids code duplication when populating data.registers derived values.
                if (this.selectedTitle?.record != null) {
                    let textMatch = false
                    let filterMatch = false
                    register.highlightText = []
                    register.highlightEntryNumbers = []
                    register.searchCount = 0
                    register.showEntries = []
                    const registerTextMatches = []
                    register.show = true
                    register.showAllEntries = false
                    register.filterCounts = []
                    register.expandPanel = true

                    // Check if the register matches the search text
                    if (this.searchText.length > 0 || this.selectedEntryTypes.length > 0) {
                        const processEntry = entry => {
                            const entryTextMatches = []

                            // Determine text matches and subsequently whether or not to show an entry
                            if (this.searchText.length > 0) {
                                this.searchText.forEach(text => {
                                    const regExMatches = entry.displayString.match(new RegExp(text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi')) || []
                                    entryTextMatches.push(...regExMatches)
                                })
                            }

                            // Determine entry number matches (and increment match counts)
                            this.searchText.forEach(text => {
                                if (text.toUpperCase() === entry.code.toUpperCase()) {
                                    register.highlightEntryNumbers.push(text.toLowerCase())
                                    textMatch = true
                                    entryTextMatches.push(text.toLowerCase())
                                }
                            })

                            registerTextMatches.push(...entryTextMatches)
                            register.searchCount += entryTextMatches.length

                            // Determine filter matches
                            if (this.selectedEntryTypes.length > 0) {
                                const selected = this.selectedEntryTypes.find(t => {
                                    // Get matching codes
                                    const codes = t.codes.map(code => code.code)
                                    const subcodes = t.codes.map(code => code.subCodes).flat()

                                    return codes.includes(entry.code) || subcodes.includes(entry.code)
                                })

                                if (selected) {
                                    filterMatch = true
                                    register.showEntries.push(entry.code)
                                    const filterCount = register.filterCounts.find(f => {
                                        return f.type === selected
                                    })
                                    if (filterCount) {
                                        filterCount.count++
                                    } else {
                                        register.filterCounts.push({
                                            type: selected,
                                            count: 1,
                                        })
                                    }
                                }
                            }

                            // Determine document matches
                            if (this.highlightDates) {
                                const documentDetails = this.selectedTitle.record.bgProprietorshipData.documentDetails
                                const documentsWithDates = documentDetails.filter((document) => {
                                    return document.entryNumberField?.indexOf(entry.code) !== -1
                                }).length
                                register.searchCount += documentsWithDates
                            }

                            if (entryTextMatches.length > 0) {
                                register.showEntries.push(entry.code)
                            }
                        }

                        register.entries.forEach(entry => {
                            processEntry(entry)
                        })

                        textMatch = registerTextMatches.length > 0
                        register.show = (textMatch || filterMatch) || register.searchCount > 0
                    } else {
                        register.showAllEntries = true
                        register.show = true
                    }

                    // this.registers[registerName].searchCount = registerTextMatches.length;
                    const uniqueTextMatches = [...new Set(registerTextMatches)]
                    register.highlightText.push(...uniqueTextMatches)
                }
            },

            toggleEntryType(type) {
                const selected = this.selectedEntryTypes.find(t => {
                    return t.label === type.label
                })

                if (selected) {
                    const index = this.selectedEntryTypes.indexOf(selected)
                    // selectedEntryTypes without the selected type
                    const newEntries = [...this.selectedEntryTypes.slice(0, index), ...this.selectedEntryTypes.slice(index + 1)]
                    this.setEntryTypesSelection(newEntries)
                } else {
                    this.setEntryTypesSelection([...this.selectedEntryTypes, type])
                }
            },

            recalculateAvailableContentHeight() {
                if (document.getElementById('titlePanelContent')) {
                    const parentAvailableHeight = Number(document.getElementById('titlePanelContent')
                        .style
                        .maxHeight
                        .replace('px', ''))
                    let availableHeight = parentAvailableHeight - 135

                    const filterElement = document.getElementById('registerSelectedEntryTypes')
                    if (filterElement != null) {
                        availableHeight = availableHeight - filterElement.clientHeight
                    }
                    this.availableRegisterContentHeight = availableHeight
                }
            },

            getTypeFilterStyle(item) {
                return { background: item.colour }
            },

            entryTypeIsSelected(item) {
                return this.selectedEntryTypes
                    .find((t) => t.label === item.label) !== undefined
            },

            onSearchClear() {
                this.searchTextInput = null
                this.updateData()
            },

            goToTitle(titleNumber) {
                this.$emit('title-number-selected', titleNumber)
            },
        },
    }
</script>

<style lang="scss">
    @import './register';
</style>
