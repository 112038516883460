<template>
    <section :class="{ selected: inFocus }"
             class="review-assistant-document-tab">
        <header class="review-assistant-document-tab__header">
            <v-tabs v-model="currentTab"
                    density="default"
                    class="review-assistant-document-tab__tabs"
                    slider-color="primary">
                <v-tab :key="ReviewAssistantDocumentTab.TitleRegister"
                       :ripple="false"
                       class="review-assistant-document-tab__tabs--register"
                       data-test="review-assistant-document-title-register-tab"
                       data-track="Review Assistant - Title register tab">
                    <span v-t="'documents.titleRegister'"
                          class="button-small" />
                </v-tab>
                <v-tab :key="ReviewAssistantDocumentTab.TitlePlan"
                       :ripple="false"
                       class="review-assistant-document-tab__tabs--plan"
                       data-test="review-assistant-document-title-plan-tab"
                       data-track="Review Assistant - Title plan tab">
                    <span v-t="'documents.titlePlan'"
                          class="button-small" />
                    <span>
                        <ow-tooltip activator="parent"
                                    :position="OwTooltipPosition.Bottom">
                            <span v-t="'reviewAssistant.titlePlan.helpText'" />
                        </ow-tooltip>
                        <a href="#"
                           @click="handleIconClick">
                            <v-icon id="planHelpIcon"
                                    style="padding-bottom: 4px">
                                $info
                            </v-icon>
                        </a>
                    </span>
                </v-tab>
                <v-tab v-show="hasRelatedDocument"
                       :key="ReviewAssistantDocumentTab.RelatedDocument"
                       :ripple="false"
                       class="review-assistant-document-tab__tabs--related"
                       data-test="review-assistant-document-related-documents-tab"
                       data-track="Review Assistant - Related documents tab">
                    <span class="button-small">{{ relatedDocumentMetadata?.documentTypeValue }}</span>
                    <ow-tooltip activator="parent"
                                position="top">
                        <span>{{ relatedDocumentMetadata?.documentType }}</span>
                    </ow-tooltip>
                </v-tab>
            </v-tabs>
            <div class="review-assistant-document-tab__header--controls">
                <ow-button full-height
                           icon
                           is-borderless
                           small
                           :disabled="!documentMetadata"
                           @click="zoomIn">
                    <v-icon>$zoom-in</v-icon>
                </ow-button>
                <ow-button full-height
                           icon
                           is-borderless
                           small
                           :disabled="!documentMetadata"
                           @click="zoomOut">
                    <v-icon>$zoom-out</v-icon>
                </ow-button>
                <div class="review-assistant-document-tab__header--controls-separator" />
                <ow-button full-height
                           icon
                           is-borderless
                           small
                           :disabled="isDownloadingDocument || !documentMetadata"
                           data-test="review-assistant-document-tab-download-button"
                           @click="documentDownloadHandler">
                    <v-progress-circular v-if="isDownloadingDocument"
                                         :size="20"
                                         :width="3"
                                         color="primary"
                                         indeterminate />
                    <v-icon v-else>
                        $download
                    </v-icon>
                </ow-button>
            </div>
        </header>

        <div v-if="currentTab == ReviewAssistantDocumentTab.TitlePlan && !hasTitlePlan"
             class="review-assistant-document-tab__doc-order">
            <order-title-plan-card v-if="isTitlePlanAvailable"
                                   :matter-id="matterId"
                                   :title-number="titleNumber"
                                   :availability-code="titlePlanAvailabilityCode"
                                   :is-backdated="isTitlePlanBackdated" />
            <div v-if="isUploadDocumentsEnabled"
                 class="review-assistant-document-tab__doc-order--upload">
                <span v-t="'documents.upload.alreadyGotDocuments'"
                      class="caption-regular" />
                <a v-t="'action.uploadDocument'"
                   class="caption-highlight"
                   data-test="review-assistant-document-tab-upload-documents-link"
                   @click="documentsUploadedHandler" />
            </div>
        </div>

        <ow-pdf-viewer v-show="documentMetadata"
                       :document-metadata="documentMetadata"
                       :document-url="documentUrl"
                       :next-search-result="nextSearchResult"
                       :page-number="pdfPage"
                       :search-text="currentSearchText"
                       :zoom="zoom"
                       fit-to="width"
                       @pages="numOfPages = $event"
                       @current-zoom="currentZoom = $event"
                       @document-scrolled="hasScrolledDocument = true"
                       @has-interacted="hasInteractedWithDocument = true"
                       @is-loaded="loadedPDF = $event"
                       @loading-error="hasLoadingError = $event"
                       @page-changed="updatePageNumber"
                       @search-loading="searchLoading = $event"
                       @search-result-count="searchResultCount = $event"
                       @selected-search-result-number="selectedSearchResultNumber = $event" />
    </section>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { mapActions } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwPdfViewer from '@/components/core/ow-pdf-viewer.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import OrderTitlePlanCard from '@/components/review-assistant/order-title-plan-card.vue'
    import {
        HmlDocumentAvailabilityTextForCode,
        HmlrDocumentAvailabilityCode,
    } from '@/consts/document-availability'
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"
    import { Route } from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { ReviewAssistantDocumentTab } from '@/interfaces/review-assistant.interface'
    import {LOGGING_HEAP_TRACK_EVENT} from '@/store/mutation-types'
    import { getApiUri } from '@/utils/environment.utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const EVENTS = {
        PAGE_CHANGED: 'page-changed',
        SEARCH_COUNT_CHANGED: 'search-count-changed',
        TAB_CHANGED: 'tab-changed',
        UPLOAD_DOCUMENTS: 'upload-documents',
    }

    export default {
        components: {
            OwButton,
            OwPdfViewer,
            OwTooltip,
            OrderTitlePlanCard,
        },

        mixins: [flagsMixin],

        props: {
            matterId: {
                type: [Number, undefined],
                required: true,
            },
            titleNumber: {
                type: [String, undefined],
                required: true,
            },
            registerMetadata: {
                type: Object as PropType<IDocumentMetadata>,
                required: false,
            },
            titlePlanMetadata: {
                type: Object as PropType<IDocumentMetadata>,
                required: false,
            },
            relatedDocumentMetadata: {
                type: Object as PropType<IDocumentMetadata>,
                required: false,
            },
            activeTab: {
                type: Number as PropType<ReviewAssistantDocumentTab>,
                default: ReviewAssistantDocumentTab.TitleRegister,
            },
            pdfPage: {
                type: Number,
                required: false,
            },
            searchText: {
                type: String,
                required: false,
            },
            searchIndex: {
                type: Number,
                required: false,
            },
            inFocus: {
                type: Boolean,
                default: true,
            },
        },

        emits: Object.values(EVENTS),

        setup(){
            const { t } = useI18n()
            return {t}
        },

        data() {
            return {
                ReviewAssistantDocumentTab,

                loadedPDF: false,
                hasLoadingError: false,
                documentUrl: '',
                documentMetadata: {},

                isDownloadingDocument: false,

                // Search
                currentSearchText: null,
                searchLoading: false,
                searchResultCount: 0,
                selectedSearchResultNumber: null,
                numOfPages: null,
                nextSearchResult: 0, // Use positive number to search forward and negative to search backwards

                // Zoom related properties
                currentZoom: 100,
                zoom: 'reset', // Can be set to in/out/reset
                zoomCounter: 0, // Way to get zoom to trigger when clicked multiple times

                // Logging properties
                hasInteractedWithDocument: false,
                hasScrolledDocument: false,
            }
        },

        computed: {
            OwTooltipPosition() {
                return OwTooltipPosition
            },
            currentTab: {
                get(): ReviewAssistantDocumentTab {
                    return this.activeTab
                },
                set(value: ReviewAssistantDocumentTab) {
                    this.$emit(EVENTS.TAB_CHANGED, value)
                    // reset search when tab is changed
                    this.currentSearchText = null // Clears the current PDF search
                    this.currentSearchText = this.searchText
                    this.nextSearchResult = 1
                },
            },
            titleRegisterUrl(): string {
                const url = this.registerMetadata?.documentDownloadUrl ?? this.registerMetadata?.downloadUrl
                return isNullOrWhitespace(url) ? '' : `${ getApiUri() }/${ url }`
            },
            hasTitleRegister(): boolean {
                return !isNullOrWhitespace(this.titleRegisterUrl)
            },
            titlePlanUrl(): string {
                const url = this.titlePlanMetadata?.documentDownloadUrl
                return isNullOrWhitespace(url) ? '' : `${ getApiUri() }/${ url }`
            },
            hasTitlePlan(): boolean {
                return this.titlePlanMetadata && // Document metadata passed to the component
                    this.titlePlanMetadata.documentStatus && // Doc status (along with URL) loaded from GQL API
                    this.isTitlePlanAvailable && // Doc status is not 'Unavailable'
                    !isNullOrWhitespace(this.titlePlanUrl) // GQL API did not return a blank URL
            },
            isTitlePlanBackdated(): boolean {
                return this.titlePlanMetadata?.hmlrAvailableVersionIsBackdated ?? false
            },
            isTitlePlanAvailable(): boolean {
                return this.titlePlanMetadata?.documentStatus !== HmlDocumentAvailabilityTextForCode[HmlrDocumentAvailabilityCode.Unavailable]
            },
            titlePlanAvailabilityCode() {
                return this.titlePlanMetadata?.hmlrAvailabilityCode ?? HmlrDocumentAvailabilityCode.Unknown
            },
            relatedDocumentUrl(): string {
                let url = this.relatedDocumentMetadata?.augmentedFilename
                    ? `${ getApiUri() }/BusinessGateway/GetBGDocumentFileByFilename?filename=${ this.relatedDocumentMetadata?.augmentedFilename }`
                    : null

                if (isNullOrWhitespace(url)) {
                    url = this.relatedDocumentMetadata?.documentDownloadUrl
                }

                return url
            },
            hasRelatedDocument(): boolean {
                return !isNullOrWhitespace(this.relatedDocumentUrl)
            },
            isReviewAssistantVisible(): boolean {
                return this.$route.name === Route.ReviewAssistant
            },
        },

        watch: {
            currentTab(newVal): void {
                this.isDownloadingDocument = false
                switch (newVal) {
                    case ReviewAssistantDocumentTab.TitlePlan:
                        this.showTitlePlan()
                        break

                    case ReviewAssistantDocumentTab.RelatedDocument:
                        this.showRelatedDocument()
                        break

                    default:
                        this.showTitleRegister()
                        break
                }
            },

            registerMetadata: {
                handler(): void {
                    if (this.hasTitleRegister && this.currentTab === ReviewAssistantDocumentTab.TitleRegister) {
                        this.showTitleRegister()
                    }
                },
                deep: true,
                immediate: true,
            },

            titlePlanMetadata: {
                handler(): void {
                    if (this.hasTitlePlan && this.currentTab === ReviewAssistantDocumentTab.TitlePlan) {
                        this.showTitlePlan()
                    }
                },
                deep: true,
                immediate: true,
            },

            relatedDocumentMetadata: {
                handler(): void {
                    if (this.hasRelatedDocument) {
                        this.showRelatedDocument()
                    }
                },
                deep: true,
                immediate: true,
            },

            searchText(newVal: string): void {
                this.currentSearchText = newVal
                this.nextSearchResult = 1
            },

            searchLoading(isLoading: boolean): void {
                if (!isLoading) {
                    this.$emit(EVENTS.SEARCH_COUNT_CHANGED, this.searchResultCount)
                }
            },

            searchIndex(value: number): void {
                this.nextSearchResult = value
            },

            activeTab(newValue: ReviewAssistantDocumentTab): void {
                this.currentTab = newValue
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            showTitleRegister(): void {
                // If there's no metadata with a url, then can't do anything
                if (this.isReviewAssistantVisible &&
                    (!this.registerMetadata ||
                        isNullOrWhitespace(this.titleRegisterUrl))) {
                    return
                }
                this.documentMetadata = this.registerMetadata
                this.documentUrl = this.titleRegisterUrl
            },

            showTitlePlan(): void {
                this.documentMetadata = null

                // If there's no metadata with a url, then can't do anything
                if (this.isReviewAssistantVisible && !this.hasTitlePlan) {
                    return
                }

                this.documentMetadata = this.titlePlanMetadata
                this.documentUrl = this.titlePlanUrl
            },

            showRelatedDocument(): void {
                // If there's no metadata with a url, then can't do anything
                if (this.isReviewAssistantVisible &&
                    (!this.titlePlanMetadata ||
                        isNullOrWhitespace(this.relatedDocumentUrl))) {
                    return
                }
                this.documentMetadata = this.relatedDocumentMetadata
                this.documentUrl = this.relatedDocumentUrl
            },

            zoomIn(): void {
                this.zoom = `in${ this.zoomCounter }`
                this.zoomCounter++
            },

            zoomOut(): void {
                this.zoom = `out${ this.zoomCounter }`
                this.zoomCounter--
            },

            handleIconClick() {
                window.open(this.t('reviewAssistant.titlePlan.helpLink'), '_blank')
            },

            updatePageNumber(pdfPage: number): void {
                this.$emit(EVENTS.PAGE_CHANGED, pdfPage)
            },

            documentsUploadedHandler() {
                this.$emit(EVENTS.UPLOAD_DOCUMENTS)
            },

            async documentDownloadHandler(): Promise<void> {
                this.isDownloadingDocument = true

                await DocumentsApi.downloadOtherDocument(this.documentMetadata.downloadUrl ?? this.documentMetadata.documentDownloadUrl, this.matterId)

                await this.logHeapEvent({
                    type: 'Review Assistant - Export Document',
                    metadata: {
                        titleNumber: this.titleNumber,
                        matterId: this.matterId,
                        docFormat: '.pdf',
                        documentType: this.documentMetadata.documentType,
                    },
                })

                this.isDownloadingDocument = false
            },
        },
    }
</script>

<style lang="scss">
    @import 'document-tab.scss';
</style>
