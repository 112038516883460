// import i18n from '@/plugins/i18n'
import { getColumnGroups,
    getColumns } from '@/components/title-analysis/configuration/gridConfig'
import { createDefaultTAMEntryForTitle } from '@/store/modules/title-analysis/utils'
import i18n from '@/plugins/i18n'
import { IMatterGroup } from '@/interfaces/matter-group.interface'
import { ITitleInMatterReference } from '@/interfaces/title-in-matter.reference'
import { sortBySortOrderFn } from '@/utils/array-utils'
import TitleInformationApi from '@/api/title-information.api'
import MatterApi from '@/api/matter.api'
import { TitleOrganiserBaseService } from '@/store/modules/matter/title-organiser/services/_title-organiser-base'
import { TitleOrganiserResult } from '@/store/modules/matter/title-organiser/title-organiser-result.type'

const tamEntry = (title, columns, addresses) => {
    const record = createDefaultTAMEntryForTitle(title, addresses)

    // Loop through each column and apply the renderer
    for (const column of columns) {
        const value = record[column.field]
        record[column.field] = column.renderer
            ? column.renderer({
                isExport: false,
                includeInTitleOrganiser: true,
                record,
                value,
            })
            : value
    }
    return record
}

export class TitleOrganiserAdvancedService extends TitleOrganiserBaseService {
    id = 'advanced'
    i18nReference = 'matter.titleOrganiser.advanced'
    quickStylingByDefault = true
    isLoading = false
    hasAppliedChanges = true
    options = null

    public async getMatterContents(matterId: string, currentTitles: Array<ITitleInMatterReference>, currentGroups: Array<IMatterGroup>,
        applyStyling: boolean, selectedField: string): Promise<TitleOrganiserResult> {
        if (!selectedField) {
            this.isLoading = false
            return Promise.resolve(null)
        }

        this.isLoading = true
        currentTitles.sort(sortBySortOrderFn)
        const boundaryAvailable = (titleNumber: string) => currentTitles.find(t => t.titleNumber === titleNumber)?.boundaryAvailable ?? false

        // Get the title register data
        const selectedColumns = ['titleNumber', selectedField]
        const columnData = this.options.columns.filter(c => selectedColumns.includes(c.field))

        const titleRegisterData = await TitleInformationApi.getTitlesByColumnSelection(matterId, columnData)
        const addressData = await MatterApi.getTitleAddressesForMatter(matterId)
        if (!titleRegisterData) {
            this.isLoading = false
            return Promise.resolve(new TitleOrganiserResult([], currentGroups))
        }

        // Create a TAM entry for each title
        const tamEntryData = titleRegisterData?.map(title => tamEntry(title, this.options.columns, addressData.find((addressTitle) => addressTitle.titleNumber == title.titleNumber )))

        // Unknown group name for titles that do not have a register
        const unknownGroupName = i18n.global.t('matter.titleOrganiser.unknownRegisterNotOwnedGroupName')

        // Create a list of groups from the unique values in the selected field
        let sortOrder = 0
        const uniqueGroupNames = [...new Set(tamEntryData.map(row => row[selectedField] ?? unknownGroupName))]

        // Sort the groups by numeric and then alphabetical order
        uniqueGroupNames.sort((a: string, b: string) => {
            const aNum = parseInt(a)
            const bNum = parseInt(b)

            if (isNaN(aNum) && isNaN(bNum)) {
                return a.toString().localeCompare(b.toString())
            }

            return aNum - bNum
        })

        // Create a group object for each unique group
        const newGroups:Array<IMatterGroup> = uniqueGroupNames.map((groupName: string, i) => {
            sortOrder += 100
            return {
                id: i,
                name: groupName.toString(),
                sortOrder: groupName === unknownGroupName ? 99999 : sortOrder,
                expanded: true,
            }
        })
        // Allocate titles to groups and set the boundary style.
        const newTitles = []
        newGroups.forEach((group, index) => {
            const groupStyle = this.colourPalette.getEntryForIndex(index)
            const titleNumbersInGroup = tamEntryData.filter(row => (row[selectedField] ?? unknownGroupName) == group.name)
                .map(t => t.titleNumber)

            titleNumbersInGroup.forEach((titleNumber: string, titleIndex) => {
                const titleItem = currentTitles.find(t => t.titleNumber === titleNumber)
                newTitles.push({
                    titleNumber: titleItem.titleNumber,
                    boundaryStyle: applyStyling && boundaryAvailable(titleItem.titleNumber) ? groupStyle : null,
                    sortOrder: group.sortOrder + (titleIndex * 100),
                    matterGroupId: group.id,
                })
            })
        })

        this.isLoading = false
        return Promise.resolve(new TitleOrganiserResult(newTitles, newGroups))
    }

    public isAvailable(featureFlags: Record<string, boolean>): boolean {
        return true
    }

    public onInit(): void {
        const columns = getColumns({
            includeOverseas: this.options?.includeOverseas ?? false,
            isExport: false,
            includeInTitleOrganiser: true,
        })

        this.options = {
            ...this.options,
            groups: getColumnGroups(),
            columns,
        }
    }
}
