<template>
    <section class="config-section map-snapshot-scale-options">
        <div v-if="showScaleBarOption"
             class="map-snapshot-scale-options__item">
            <label v-t="'snapshots.map.scale.showScaleBar'"
                   for="map-snapshot-show-scale-bar"
                   class="body-highlight" />
            <v-switch id="map-snapshot-show-scale-bar"
                      v-model="showScaleBarInternal"
                      class="map-snapshot-scale-options__item__toggle"
                      data-track="Export Assistant - Show scale bar"
                      color="primary"
                      hide-details />
        </div>
        <div v-if="showScaleTextOption"
             class="map-snapshot-scale-options__item">
            <label v-t="'snapshots.map.scale.showScaleText'"
                   for="map-snapshot-show-scale-text"
                   class="body-highlight" />
            <v-switch id="map-snapshot-show-scale-text"
                      v-model="showScaleTextOptionInternal"
                      color="primary"
                      class="map-snapshot-scale-options__item__toggle"
                      data-track="Export Assistant - Show scale text"
                      hide-details />
        </div>
        <div v-if="showScaleTextOptionInternal"
             class="map-snapshot-scale-options__item">
            <div class="map-snapshot-scale-options-text">
                <ow-combo-box v-model="scaleInternal"
                              :label="i18n.global.t('snapshots.map.scale.mapScaleHint').toString()"
                              :options="scaleOptions" />
            </div>
        </div>
    </section>
</template>
<script lang="ts" setup>
    import { computed,
             onMounted,
             ref,
             watch } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwComboBox from '@/components/core/ow-combo-box.vue'
    import { PageLayouts } from '@/components/snapshots/common/snapshot-enums-consts'
    import { getScaleForMap,
             setScaleForMap } from '@/components/snapshots/common/snapshot-utils'
    import { MapSnapshotRenderData } from '@/components/snapshots/map-snapshots/map-snapshot-models'
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'
    import i18n from '@/plugins/i18n'

    interface Props {
        service: MapSnapshotService
    }

    const props = defineProps<Props>()
    const { t } = useI18n()

    const showScaleBarInternal = ref<boolean>(false)
    const showScaleTextOptionInternal = ref<boolean>(false)
    const scaleInternal = ref<number>(1250)

    const scaleOptions = [
        500,
        1_250,
        2_500,
        5_000,
        10_000,
        20_000,
        25_000,
        50_000,
    ]

    const getRenderData = () => props.service.snapshotModel.renderData as MapSnapshotRenderData

    onMounted(() => {
        // Apply the initial values from the snapshot model
        const renderData = props.service.snapshotModel.renderData as MapSnapshotRenderData
        showScaleBarInternal.value = renderData.includeScaleBar
        showScaleTextOptionInternal.value = renderData.includeToScale !== null

        // If the layout uses real world units, then we don't want to show the scale bar,
        // in favour of showing the scale text instead.
        if (PageLayouts[renderData.layoutId].usesRealWorldUnitsMm) {
            showScaleBarInternal.value = false
            showScaleTextOptionInternal.value = true
        }
    })

    watch(showScaleBarInternal, newValue => {
        getRenderData().includeScaleBar = newValue
    })
    watch(showScaleTextOptionInternal, newValue => {
        if (!newValue) {
            getRenderData().includeToScale = null
        } else {
            getRenderData().includeScaleBar = false
            getRenderData().includeToScale = 1250
        }
    })

    /*
    Assume for simplicity that if a user has selected a real world page size,
    they will want to show it to a particular scale if anything - in which case show scale text,
    and not a scale bar.
 */
    const showScaleBarOption = computed(() => {
        return !layoutUsesRealWorldUnits.value
    })
    const showScaleTextOption = computed(() => {
        return layoutUsesRealWorldUnits.value
    })
    const layoutUsesRealWorldUnits = computed(() => {
        const pageLayout = PageLayouts[props.service.snapshotModel.renderData.layoutId]
        return pageLayout.usesRealWorldUnitsMm
    })

    watch(layoutUsesRealWorldUnits, newValue => {
        if (newValue) {
            showScaleBarInternal.value = false
            showScaleTextOptionInternal.value = true
        } else {
            showScaleBarInternal.value = true
            showScaleTextOptionInternal.value = false
        }
    })

    watch(() => scaleInternal.value, (val) => {
        setMapScale(val)
    })

    /** Sets the map scale via the resolution value,
     * scale = resolution * inchesPerMeter * dpi
     * @param scale e.g. 1250 as in 1:1250.
     */
    const setMapScale = (scale: number) => {
        const currentLayout = PageLayouts[props.service.snapshotModel.renderData.layoutId]
        const dpi = currentLayout.dpi
        if (!dpi) {
            console.error('No DPI value found for layout')
            return
        }

        setScaleForMap(props.service.targetMap, scale, dpi)
    }

    props.service.targetMap.on('moveend', () => {
        if (!layoutUsesRealWorldUnits.value) {
            return
        }
        updateMapScale()
    })

    const updateMapScale = () => {
        const currentLayout = PageLayouts[props.service.snapshotModel.renderData.layoutId]
        scaleInternal.value = getScaleForMap(props.service.targetMap, currentLayout.dpi)
    }

</script>

<style lang="scss" scoped>
    @import "map-snapshot-scale-options";
</style>
