<template>
    <ow-modal v-model="removeFromMatterDialog.show"
              content-class="dialog-remove-titles-from-matter"
              :title="$t('matter.prompts.removeTitlesFromMatter.title')"
              persistent>
        <span class="dialog-remove-titles-from-matter__content">
            {{ $t('matter.prompts.removeTitlesFromMatter.body', removeFromMatterDialog.titles.length) }}
        </span>
        <template #actions>
            <div class="dialog-remove-titles-from-matter__controls">
                <ow-button @click="handleCancel">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button is-danger
                           @click="handleConfirm">
                    {{ $t('action.remove') }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script lang="ts">
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import { IState } from '@/interfaces/store/state.interface'
    import { MATTER_REMOVE_TITLES } from '@/store/modules/matter/types'

    export default {
        name: 'DialogRemoveTitlesFromMatter',
        components: {
            OwButton,
            OwModal,
        },
        computed: {
            ...mapState({
                removeFromMatterDialog: (state: IState) => state.matter.prompts.removeFromMatter,
            }),
        },
        methods: {
            ...mapActions({
                removeTitlesFromMatter: MATTER_REMOVE_TITLES,
            }),

            handleConfirm() {
                const titleNumbers = this.removeFromMatterDialog.titles.map(title => title.titleNumber)
                this.removeTitlesFromMatter(titleNumbers)
                this.removeFromMatterDialog.show = false
            },

            handleCancel() {
                this.removeFromMatterDialog.show = false
            },
        },
    }
</script>

<style lang="scss">
    @import './dialog-remove-titles-from-matter';
</style>
