<template>
    <div :class="{
             'is-new-matter': isNewMatter,
             'elevation-3': addBorder,
         }"
         class="matter-details-permissions">
        <v-radio-group v-model="shareType"
                       color="primary"
                       data-test="matter-details-permissions-options"
                       hide-details
                       @update:model-value="checkTypeEdit">
            <v-radio v-if="isNewMatter || matter.createdByMe"
                     color="primary"
                     data-test="permissions-radio-private"
                     data-track="MAT-SETTINGS - Select private matter"
                     value="none">
                <template #label>
                    <div class="body-copy">
                        Private matter
                    </div>
                </template>
            </v-radio>

            <v-radio color="primary"
                     data-test="permissions-radio-specific"
                     data-track="MAT-SETTINGS - Select share with specific users"
                     value="specific">
                <template #label>
                    <div class="body-copy">
                        Share with specific people in my organisation
                    </div>
                </template>
            </v-radio>

            <div v-if="shareType === 'specific'"
                 class="matter-details-permissions__people-list"
                 data-test="permissions-people-list">
                <div v-if="isLoadingPermissionsList"
                     class="matter-details-permissions__people-list--loading-skeleton"
                     data-test="permissions-loading-skeleton">
                    <ow-loading-skeleton margin-left="32"
                                         width="256" />
                    <ow-loading-skeleton margin="8 0 0 32"
                                         width="256" />
                    <ow-loading-skeleton margin="8 0 16 32"
                                         width="256" />
                </div>
                <v-text-field v-if="!isLoadingPermissionsList"
                              v-model="filterPermissionsText"
                              class="matter-details-permissions__people-list--filter"
                              :clearable="true"
                              data-test="permissions-filter-people"
                              data-track="MAT-SETTINGS - Select filter users"
                              hide-details
                              variant="underlined"
                              label="Filter people..."
                              prepend-inner-icon="$search" />

                <ul v-if="!isLoadingPermissionsList"
                    class="matter-details-permissions__filter-people hide-in-percy"
                    data-test="permissions-filter-people">
                    <li v-for="permission in filteredPermissions"
                        :key="permission.userId"
                        class="hide-in-percy">
                        <ow-checkbox :id="`matter-permissions-${permission.userId}`"
                                     v-model="permission.shared"
                                     :label="permission.userName"
                                     @input="checkForUserPermissionsEdit" />
                    </li>
                </ul>
            </div>


            <v-radio v-if="canShareMattersWithOrg"
                     color="primary"
                     value="all">
                <template #label>
                    <div class="body-copy"
                         data-test="permissions-radio-whole-organisation"
                         data-track="MAT-SETTINGS - Select share with whole organisation">
                        Share with everyone in my organisation
                    </div>
                </template>
            </v-radio>
        </v-radio-group>

        <v-row v-if="!isNewMatter"
               class="matter-details-permissions__actions">
            <ow-tooltip :disabled="shareType !== 'specific' || hasSelectedPeople"
                        :position="OwTooltipPosition.Bottom">
                <template #activator>
                    <div>
                        <v-btn :disabled="isUpdateButtonDisabled"
                               :loading="isLoading"
                               color="primary"
                               data-test="permissions-button-update"
                               data-track="MAT-SETTINGS - Update permissions"
                               size="large"
                               @click="update">
                            Update
                        </v-btn>
                    </div>
                </template>
                <span>You must select at least one person from the list, or switch to a private matter.</span>
            </ow-tooltip>
        </v-row>
        <v-row v-if="isNewMatter"
               class="matter-details-permissions__actions d-flex justify-space-between">
            <div class="matter-details-permissions__actions--left-btns">
                <v-btn :disabled="isLoading"
                       data-test="matter-create-cancel"
                       size="large"
                       variant="outlined"
                       @click="cancel">
                    Cancel
                </v-btn>
                <v-btn :disabled="isLoading"
                       class="matter-details-permissions__action-back"
                       data-test="permissions-button-back"
                       size="large"
                       variant="text"
                       @click="goBack">
                    Back
                </v-btn>
            </div>
            <v-btn :disabled="isCreateButtonDisabled"
                   :loading="isLoading"
                   color="primary"
                   data-test="permissions-button-create"
                   size="large"
                   @click="update">
                {{ shareType === 'none' ? 'Create matter' : 'Create and share matter' }}
            </v-btn>
        </v-row>
    </div>
</template>

<script lang="ts">
    import { mapActions,
             mapGetters,
             mapMutations } from "vuex"

    import OwCheckbox from "@/components/core/ow-checkbox.vue"
    import owLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"
    import { CORE_MUTATE_PREVENT_TRANSITION } from "@/store/modules/core/types"
    import { MATTER_REFRESH_PERMISSIONS,
             MATTER_UPDATE_PERMISSIONS } from "@/store/modules/matter/types"
    import {
        LOGGING_HEAP_TRACK_EVENT,
        LOGGING_INTERCOM_TRACK_EVENT,
        USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG,
    } from "@/store/mutation-types"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    export default {
        name: 'Permissions',

        components: {
            OwCheckbox,
            owLoadingSkeleton,
            OwTooltip,
        },

        props: {
            matter: {
                type: Object,
                require: false,
                default: null,
            },
            loading: {
                type: Boolean,
                require: true,
            },
            permissions: {
                type: [Array, Boolean],
                required: false,
                default: () => [],
            },
            isNewMatter: {
                type: Boolean,
                required: false,
            },
            resetFormOnModalClose: {
                type: Boolean,
                required: false,
            },
            addBorder: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'update',
            'cancel',
        ],

        data() {
            return {
                shareType: this.isNewMatter ? 'specific' : 'none',
                originalShareType: 'none',
                originalPermissionUserIds: [],
                isUpdating: false,
                hasEdits: false,
                filterPermissionsText: null,
                isLoadingPermissionsList: false,
                OwTooltipPosition,
            }
        },

        computed: {
            isUpdateButtonDisabled() {
                return !this.hasEdits || (this.shareType === 'specific' && !this.hasSelectedPeople) || this.isLoading
            },

            isCreateButtonDisabled() {
                return this.isLoading || (this.shareType === 'specific' && !this.hasSelectedPeople)
            },

            hasSelectedPeople() {
                return this.permissions.some((permission) => permission.shared)
            },

            isLoading() {
                return this.loading || this.isUpdating
            },

            filteredPermissions() {
                if (!isNullOrWhitespace(this.filterPermissionsText)) {
                    return this.permissions.filter(permission =>
                        permission.userName.toLowerCase().includes(this.filterPermissionsText.toLowerCase()))
                }
                return this.permissions
            },

            canShareMattersWithOrg() {
                return this.canShareMattersWithWholeOrg()
            },
        },
        watch: {
            'matter.id'() {
                this.updateCurrentShareType()
                this.refreshPermissions()
            },

            'matter.sharedWithMe'() {
                this.updateCurrentShareType()
            },

            'matter.sharedWithOrganisation'() {
                this.updateCurrentShareType()
            },

            'matter.sharedWithSpecificUsers'() {
                this.updateCurrentShareType()
            },

            hasEdits(val) {
                if (!this.isNewMatter) {
                    this.setPreventTransition({
                        isFormDirty: val,
                        resetForm: this.resetForm,
                        submitForm: this.update,
                        resetFormOnModalClose: this.resetFormOnModalClose,
                    })
                }
            },

            permissions() {
                this.originalPermissionUserIds = this.getPermissionUserIds(this.permissions)
            },

            shareType(newVal) {
                this.logHeapEvent({
                    type: 'MAT-SETTINGS - Select share with',
                    metadata: {
                        shareType: newVal,
                    },
                })
            },
        },

        mounted() {
            if (this.matter) {
                this.updateCurrentShareType()
                this.refreshPermissions()
            }
        },

        methods: {
            ...mapMutations({
                setPreventTransition: CORE_MUTATE_PREVENT_TRANSITION,
            }),

            ...mapActions({
                logEventWithIntercom: LOGGING_INTERCOM_TRACK_EVENT,
                updatePermissions: MATTER_UPDATE_PERMISSIONS,
                refreshPermissionsStatus: MATTER_REFRESH_PERMISSIONS,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            }),

            ...mapGetters({
                canShareMattersWithWholeOrg: USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG,
            }),

            async refreshPermissions() {
                this.isLoadingPermissionsList = true
                await this.refreshPermissionsStatus(this.matter.id)
                this.isLoadingPermissionsList = false
            },

            resetForm() {
                this.permissions.forEach(permission => {
                    if (!this.originalPermissionUserIds.includes(permission.userId)) {
                        permission.shared = false
                    }
                })
                this.shareType = this.originalShareType
                this.hasEdits = false
            },

            checkTypeEdit() {
                this.hasEdits = this.shareType !== this.originalShareType
            },

            checkForUserPermissionsEdit() {
                const currentPermissionUserIds = this.getPermissionUserIds(this.permissions)
                this.hasEdits = currentPermissionUserIds.join('') !== this.originalPermissionUserIds.join('')

                if (this.hasEdits) {
                    this.logHeapEvent({
                        type: 'MAT-SETTINGS - Select specific user',
                        metadata: {
                            sharedWithUserCount: currentPermissionUserIds.length,
                        },
                    })
                }
            },

            updateCurrentShareType() {
                if (!this.isNewMatter) {
                    if (this.matter.id) {
                        this.shareType = 'none' // assume private unless otherwise
                        if (this.matter.sharedWithOrganisation === true) {
                            this.shareType = 'all'
                        } else if (this.matter.sharedWithSpecificUsers === true) {
                            this.shareType = 'specific'
                        }
                    }
                    this.originalShareType = this.shareType
                }
            },

            goBack() {
                window.history.back()
            },

            cancel() {
                this.$emit('cancel')
            },

            async update() {
                if (!this.isNewMatter) {
                    this.isUpdating = true

                    let userIds = []
                    if (this.shareType === 'specific') {
                        userIds = this.getPermissionUserIds(this.permissions)
                        this.originalPermissionUserIds = this.getPermissionUserIds(this.permissions)
                    } else {
                        this.permissions.forEach(s => {
                            s.shared = false
                        })
                        this.originalPermissionUserIds = []
                    }

                    const request = {
                        matterId: this.matter.id,
                        shareWithOrganisation: this.shareType === 'all',
                        userIds,
                    }

                    await this.logEventWithIntercom({
                        type: 'matter-shared',
                        metadata: request,
                    })
                    await this.updatePermissions(request)

                    this.isUpdating = false
                    this.hasEdits = false
                    this.originalShareType = this.shareType
                } else {
                    this.$emit('update', { // matter create -- NEEDS REFACTORING!
                        shareType: this.shareType,
                        userIds: this.getPermissionUserIds(this.permissions),
                    })
                }
            },

            /**
             * Takes an array of share object and returns an array of the
             * userId's that are marked as shared.
             * @param permissions {Array}
             * @returns {*[]|*}
             */
            getPermissionUserIds(permissions) {
                if (!permissions || !Array.isArray(permissions)) {
                    return []
                }
                return permissions
                    .filter(s => {
                        if (s.shared) {
                            return s.userId
                        }
                        return false
                    })
                    .map(r => r.userId)
            },
        },
    }
</script>

<style lang="scss">
    @import './permissions.scss';
</style>
