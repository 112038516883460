<template>
    <ow-modal v-model="show"
              persistent
              title="Product Configuration"
              content-class="matter-searches-con29-dwc-product-config-dialog">
        <div class="matter-searches-con29-dwc-product-config-dialog__content">
            <matter-searches-product-config-header v-model="lessThan10YearsChecked"
                                                   data-test-id="con29-product-config-standard-enquiries-header"
                                                   :title="$t('searches.productConfig.con29Dwc.lessThan10Years')">
                <div class="d-flex gr-2 pt-2 flex-column">
                    <div class="d-flex flex-row align-center justify-space-between">
                        <label class="caption-regular w-50"
                               for="developer-name-textfield">
                            {{ $t('searches.productConfig.con29Dwc.developerName') }}
                        </label>
                        <ow-textfield id="developer-name-textfield"
                                      v-model="developerName"
                                      class="matter-searches-con29-dwc-product-config-dialog__input" />
                    </div>
                    <div class="d-flex flex-row align-center justify-space-between">
                        <label class="caption-regular w-50"
                               for="offsiteroadname-textfield">
                            {{ $t('searches.productConfig.con29Dwc.offSiteRoadName') }}
                        </label>
                        <ow-textfield id="offsiteroadname-textfield"
                                      v-model="offSiteRoadName"
                                      class="matter-searches-con29-dwc-product-config-dialog__input" />
                    </div>
                    <div class="d-flex flex-row align-center justify-space-between">
                        <label class="caption-regular w-50"
                               for="sitenameandphase-textfield">
                            {{ $t('searches.productConfig.con29Dwc.siteNameAndPhase') }}
                        </label>
                        <ow-textfield id="sitenameandphase-textfield"
                                      v-model="siteNameAndPhase"
                                      class="matter-searches-con29-dwc-product-config-dialog__input" />
                    </div>
                </div>
            </matter-searches-product-config-header>
            <v-divider class="my-2 mt-4" />
            <div class="d-flex flex-row align-center justify-space-between">
                <label class="caption-regular w-50"
                       for="expedited-select">
                    {{ $t('searches.productConfig.con29Dwc.expedited') }}
                </label>
                <ow-select id="expedited-select"
                           v-model="expedited"
                           class="matter-searches-con29-dwc-product-config-dialog__input"
                           hide-selected
                           :label="$t('searches.productConfig.con29Dwc.expedited')"
                           is-outlined
                           is-dense
                           hide-details
                           :items="['Normal', 'Priority_1']" />
            </div>
        </div>
        <template #actions>
            <ow-card-actions has-secondary-button
                             no-padding
                             primary-button-data-test="con29Dwc-product-config-confirm"
                             secondary-button-data-test="con29Dwc-product-config-cancel"
                             :primary-button-text="$t('action.save')"
                             :primary-button-disabled="!dirty || !valid"
                             @primary-button-click="handleConfirm"
                             @secondary-button-click="handleCancel" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        computed,
        nextTick,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import owSelect from '@/components/core/ow-select.vue'
    import owTextfield from '@/components/core/ow-textfield.vue'
    import MatterSearchesProductConfigHeader from '@/components/matter-searches/matter-searches-product-config-header.vue'
    import {
        Con29DwcProductConfig,
    } from '@/components/matter-searches/models/BasketItemCon29DwcModel'
    import { SEARCHES_GET_CON29_DWC_CONFIG,
             SEARCHES_GET_CON29_DWC_CONFIG_SHOW,
             SEARCHES_GET_SELECTED_BASKET_ITEM,
             SEARCHES_MUTATE_BASKET_DIRTY,
             SEARCHES_MUTATE_PRODUCT_CONFIG,
             SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW,
             SEARCHES_QUOTE} from '@/store/modules/searches/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const store = useStore()

    const selectedBasketItem = computed(() => store.getters[SEARCHES_GET_SELECTED_BASKET_ITEM])

    const show = computed<boolean>({
        get: () => store.getters[SEARCHES_GET_CON29_DWC_CONFIG_SHOW],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG_SHOW, {
                model: selectedBasketItem.value,
                show: val,
            })
        },
    })

    const config = computed<Con29DwcProductConfig>({
        get: () => store.getters[SEARCHES_GET_CON29_DWC_CONFIG],
        set: (val) => {
            store.commit(SEARCHES_MUTATE_PRODUCT_CONFIG, {
                model: selectedBasketItem.value,
                config: val,
            })
        },
    })

    const expedited = ref<string>(config?.value.expedited ?? '')
    const developerName = ref(config?.value.developerName ?? '')
    const offSiteRoadName = ref(config?.value.offSiteRoadName ?? '')
    const siteNameAndPhase = ref(config?.value.siteNameAndPhase ?? '')

    let defaultConfig: Con29DwcProductConfig = null
    const dirty = ref(false)
    const valid = ref(false)

    const lessThan10YearsChecked = ref<boolean>()
    const initialising = ref(true)

    const handleCancel = () => {
        show.value = false
    }

    const validateConfig = () => {
        valid.value = true
        if (lessThan10YearsChecked.value) {
            valid.value =
                !isNullOrWhitespace(developerName.value)
                && !isNullOrWhitespace(offSiteRoadName.value)
                && !isNullOrWhitespace(siteNameAndPhase.value)
        }
        if (!expedited.value) {
            valid.value = false
        }
    }

    const handleConfirm = () => {
        if (!valid.value) {
            return
        }
        config.value = {
            expedited: expedited.value,
            lessThan10Years: lessThan10YearsChecked.value,
            developerName: developerName.value,
            offSiteRoadName: offSiteRoadName.value,
            siteNameAndPhase: siteNameAndPhase.value,
        }
        show.value = false
        store.commit(SEARCHES_MUTATE_BASKET_DIRTY, true)
        store.dispatch(SEARCHES_QUOTE)
    }

    watch(() => lessThan10YearsChecked.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.lessThan10Years
        }
        validateConfig()
    })

    watch(() => expedited.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.expedited
        }
        validateConfig()
    })

    watch(() => developerName.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.developerName
        }
        validateConfig()
    })

    watch(() => offSiteRoadName.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.offSiteRoadName
        }
        validateConfig()
    })

    watch(() => siteNameAndPhase.value, (val) => {
        if (initialising.value) {
            return
        }
        if (!dirty.value) {
            dirty.value = val !== defaultConfig?.siteNameAndPhase
        }
        validateConfig()
    })

    watch(() => show.value, (val) => {
        initialising.value = true
        if (val) {

            lessThan10YearsChecked.value = config?.value.lessThan10Years ?? false
            expedited.value = config?.value.expedited ?? ''

            defaultConfig = JSON.parse(JSON.stringify({
                lessThan10Years: lessThan10YearsChecked.value,
                expedited: expedited.value,
            }))
            dirty.value = false

            nextTick(() => {
                initialising.value = false
            })
        }
    }, {
        immediate: true,
    })
</script>

<style lang="scss">
@import './matter-searches-con29-dwc-product-config-dialog.scss';
</style>
