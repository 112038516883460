<template>
    <ow-modal v-model="removeDocumentsFromMatterDialog.show"
              content-class="dialog-remove-document-from-matter"
              :title="$t('dialog.removeDocumentFromMatter.title')"
              persistent>
        <span class="dialog-remove-document-from-matter__content">
            {{ $t('dialog.removeDocumentFromMatter.confirmation') }}
        </span>
        <template #actions>
            <div class="dialog-remove-document-from-matter__controls">
                <ow-button data-test="remove-documents-from-matter-cancel-button"
                           @click="handleCancel">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button is-danger
                           data-test="remove-documents-from-matter-remove-button"
                           @click="handleConfirm">
                    {{ $t('action.remove') }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        computed,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import {
        REMOVE_SINGLE_DOCUMENT_FROM_MATTER,
        REMOVE_SINGLE_UPLOADED_DOCUMENT_FROM_MATTER,
    } from '@/store/modules/documents/documents-types'
    import { MATTER_GET_REMOVE_DOCUMENT_PROMPT_STATE } from '@/store/modules/matter/types'

    const { t } = useI18n()
    const store = useStore()


    const removeDocumentsFromMatterDialog = computed(() => store.getters[MATTER_GET_REMOVE_DOCUMENT_PROMPT_STATE])
    const removeDocumentFromMatter = (val) => store.dispatch(REMOVE_SINGLE_DOCUMENT_FROM_MATTER, val)
    const removeUploadedDocumentFromMatter = (val) => store.dispatch(REMOVE_SINGLE_UPLOADED_DOCUMENT_FROM_MATTER, val)

    const handleConfirm = async () => {
        if (removeDocumentsFromMatterDialog.value.documentToRemove.documentUploadRequestId) {
            await removeUploadedDocumentFromMatter({
                documentId: removeDocumentsFromMatterDialog.value.documentToRemove.id,
                matterId: removeDocumentsFromMatterDialog.value.matterId,
            })
        } else {
            await removeDocumentFromMatter({
                matterId: removeDocumentsFromMatterDialog.value.matterId,
                documentId: removeDocumentsFromMatterDialog.value.documentToRemove.documentId,
                documentType: removeDocumentsFromMatterDialog.value.documentToRemove.highLevelDocumentType,
            })
        }

        removeDocumentsFromMatterDialog.value.show = false
    }

    const handleCancel = () => {
        removeDocumentsFromMatterDialog.value.show = false
    }

</script>

<style lang="scss">
    @import './dialog-remove-document-from-matter';
</style>
