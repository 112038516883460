<template>
    <ow-page-layout v-if="stepId !== SearchesSteps.None"
                    page-name="matter-searches-create"
                    inline-step-flow
                    :header-svg-icon="OwIconSearches"
                    :header-title="isDraftOrder ? $t('searches.orderSearchesDraft') : $t('searches.orderSearches')">
        <template #navigation>
            <div v-if="!isDraftOrder">
                <ow-step-flow class="matter-searches-create__step-flow pa-4 ml-16"
                              :steps="steps"
                              :current-step-id="stepId"
                              small
                              @step-change="onStepChange" />
            </div>
        </template>
        <template #footer>
            <matter-searches-step-footer :matter-id="matterIdParam"
                                         :step-id="stepId"
                                         @step-change="onStepChange" />
        </template>
        <div class="pa-6 pt-8">
            <matter-searches-select-product-step v-if="isDraftOrder"
                                                 show />
            <template v-else>
                <matter-searches-define-polygon-step :show="stepId === SearchesSteps.DefinePolygon" />
                <matter-searches-define-address-step :show="stepId === SearchesSteps.DefineAddress" />
                <matter-searches-select-product-step :show="(stepId === SearchesSteps.SelectProducts)" />
            </template>
        </div>
    </ow-page-layout>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import SearchesApi from "@/api/searches.api"
    import OwIconSearches from '@/components/core/icons/ow-icon-searches.vue'
    import OwPageLayout from '@/components/core/layout/full-width.vue'
    import owStepFlow from '@/components/core/ow-step-flow.vue'
    import MatterSearchesDefineAddressStep from '@/components/matter-searches/steps/matter-searches-define-address-step.vue'
    import MatterSearchesDefinePolygonStep from '@/components/matter-searches/steps/matter-searches-define-polygon-step.vue'
    import MatterSearchesSelectProductStep from '@/components/matter-searches/steps/matter-searches-select-product-step.vue'
    import MatterSearchesStepFooter from '@/components/matter-searches/steps/matter-searches-step-footer.vue'
    import { Route } from '@/enums/route.enum'
    import {
        getStepId,
        getStepName,
        SearchesSteps,
    } from '@/enums/searches.enum'
    import { IOwStepFlowItem } from '@/interfaces/core-components/ow-step-flow.interface'
    import i18n from '@/plugins/i18n'
    import {
        SEARCHES_GET_GEOJSON,
        SEARCHES_GET_SELECTED_TITLE_NUMBERS,
        SEARCHES_GET_STRUCTURED_ADDRESS,
        SEARCHES_MUTATE_CLEAR_QUOTE,
        SEARCHES_MUTATE_MAP_KEY,
        SEARCHES_MUTATE_ORGANISATION_SETTINGS,
        SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS,
        SEARCHES_RESET,
    } from '@/store/modules/searches/types'

    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    // route args
    const stepParam = computed(() => route.params?.step)
    const matterIdParam = computed(() => route.params?.matterId)
    const argsParam = computed(() => route.params?.args)

    watch(() => route.params.args, (val) => {
        if (route.params.step !== getStepName(SearchesSteps.DefinePolygon)) {
            return
        }
        if (!val) {
            store.commit(SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS, [])
            return
        }
        const param: string = val as string
        store.commit(SEARCHES_MUTATE_SELECTED_TITLE_NUMBERS, param?.split(',').length > 0 ? param.split(',') : [val])
    }, {
        immediate: true,
        deep: true,
    })

    const stepId = ref<SearchesSteps>(getStepId(stepParam?.value) ?? SearchesSteps.None)
    const selectedTitleNumbers = computed<string[]>(() => store.getters[SEARCHES_GET_SELECTED_TITLE_NUMBERS])
    const structuredAddress = computed<any>(() => store.getters[SEARCHES_GET_STRUCTURED_ADDRESS])
    const geoJson = computed<any>(() => store.getters[SEARCHES_GET_GEOJSON])
    const isDraftOrder = computed(() => argsParam.value && stepId.value === SearchesSteps.Draft)

    const steps: Array<IOwStepFlowItem> =
        [{
             id: SearchesSteps.DefinePolygon,
             title: i18n.global.t('searches.createOrder.flow.step1.title'),
             disabled: false,
         }, {
             id: SearchesSteps.DefineAddress,
             title: i18n.global.t('searches.createOrder.flow.step2.title'),
             disabled: () => !geoJson.value,
         },
         {
             id: SearchesSteps.SelectProducts,
             title: i18n.global.t('searches.createOrder.flow.step3.title'),
             disabled: () => !store.state.addressValid || !structuredAddress.value,
         }, {
             id: SearchesSteps.SetupNotifications,
             title: i18n.global.t('searches.createOrder.flow.step4.title'),
             disabled: true,
         }]

    // store
    const orderSearchEnabled = computed(() => store.state.config.featureFlags?.orderSearch)

    onBeforeMount(() => {
        if (orderSearchEnabled.value !== undefined && !orderSearchEnabled.value) {
            redirectToMatterMap()
        }
    })

    watch(() => orderSearchEnabled.value, (val) => {
        if (val !== undefined && !val) {
            redirectToMatterMap()
        }
    })

    watch(() => stepParam.value, (val) => {
        if (!val) {
            return
        }
        stepId.value = getStepId(val)
    }, {
        immediate: true,
    })

    const redirectToMatterMap = () => {
        router.push({
            name: Route.MatterMap,
            params: {
                matterId: matterIdParam.value,
            },
        })
    }

    const titleNumberArgs = () => {
        if (!selectedTitleNumbers.value || selectedTitleNumbers.value.length === 0) {
            return ''
        }
        return `${ selectedTitleNumbers.value.length > 1 ? (argsParam.value as string).split(',') : selectedTitleNumbers.value[0] }`
    }

    const onStepChange = async (newStepId: SearchesSteps) => {
        if (newStepId === SearchesSteps.None) {
            await store.dispatch(SEARCHES_RESET)
            return router.push({
                name: Route.MatterSearches,
                params: {
                    matterId: matterIdParam.value,
                },
            })
        }

        if (newStepId === SearchesSteps.DefinePolygon) {
            stepId.value = newStepId
            await router.push({
                name: Route.MatterSearchesCreate,
                params: {
                    args: titleNumberArgs(),
                    matterId: matterIdParam.value,
                    step: getStepName(newStepId),
                },
            })
        } else if (newStepId === SearchesSteps.DefineAddress && geoJson.value) {
            stepId.value = newStepId
            await router.push({
                name: Route.MatterSearchesCreate,
                params: {
                    args: titleNumberArgs(),
                    matterId: matterIdParam.value,
                    step: getStepName(newStepId),
                },
            })
        } else if (newStepId === SearchesSteps.SelectProducts && structuredAddress.value) {
            stepId.value = SearchesSteps.SelectProducts
            await router.push({
                name: Route.MatterSearchesCreate,
                params: {
                    args: titleNumberArgs(),
                    matterId: matterIdParam.value,
                    step: getStepName(newStepId),
                },
            })
        }
    }

    watch(() => route.name, async (val) => {
        if (val !== Route.MatterSearchesCreate) {
            await store.dispatch(SEARCHES_RESET)
            store.commit(SEARCHES_MUTATE_CLEAR_QUOTE)
            stepId.value = SearchesSteps.None
        } else if (val === Route.MatterSearchesCreate && route.params?.step === getStepName(SearchesSteps.SelectProducts)) {
            if (!structuredAddress?.value) {
                await router.push({
                    name: Route.MatterSearchesCreate,
                    params: {
                        matterId: matterIdParam.value,
                        step: getStepName(SearchesSteps.DefinePolygon),
                    },
                })
                store.commit(SEARCHES_MUTATE_MAP_KEY)
            }
        } else if (val === Route.MatterSearchesCreate && route.params?.step === getStepName(SearchesSteps.DefineAddress)) {
            if (!geoJson?.value) {
                await router.push({
                    name: Route.MatterSearchesCreate,
                    params: {
                        matterId: matterIdParam.value,
                        args: titleNumberArgs(),
                        step: getStepName(SearchesSteps.DefinePolygon),
                    },
                })
                store.commit(SEARCHES_MUTATE_MAP_KEY)
            }
        }
    }, {
        immediate: true,
    })

    onMounted(async () => {
        let response = await SearchesApi.getOrganisationSettings()
        store.commit(SEARCHES_MUTATE_ORGANISATION_SETTINGS, response.data)
    })
</script>

<style lang="scss">
    @import './matter-searches-create';
</style>
