import cloneDeep from 'lodash.clonedeep'

import { dynamicSort } from '@/utils/array-utils'

import {
    WALKTHROUGH_MUTATE_CONTENT_ITEM_BOUNDARY_AVAILABLE,
    WALKTHROUGH_MUTATE_DELETE_PAGE,
    WALKTHROUGH_MUTATE_ENABLED,
    WALKTHROUGH_MUTATE_FEATURE_TRACKING_REVISION_COUNTER,
    WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT,
    WALKTHROUGH_MUTATE_HIGHLIGHT_BOUNDARIES_FOR_TITLE_NUMBER,
    WALKTHROUGH_MUTATE_LOADING,
    WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE,
    WALKTHROUGH_MUTATE_PAGE_CONTENT_ITEM_VISIBILITY,
    WALKTHROUGH_MUTATE_PAGES,
    WALKTHROUGH_MUTATE_REMOVE_ITEM_FROM_SELECTED_PAGE,
    WALKTHROUGH_MUTATE_SELECTED_PAGE,
    WALKTHROUGH_MUTATE_SELECTED_PAGE_DESCRIPTION,
    WALKTHROUGH_MUTATE_SELECTED_PAGE_TITLE_COMMENT,
    WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT,
    WALKTHROUGH_MUTATE_WALKTHROUGH,
} from './types.js'

export default {

    [WALKTHROUGH_MUTATE_ENABLED](state, val) {
        state.enabled = val
    },

    [WALKTHROUGH_MUTATE_LOADING](state, val) {
        state.loading = val
    },

    [WALKTHROUGH_MUTATE_WALKTHROUGH](state, val) {
        // If the walkthrough object has been provided by the API response,
        // each page will have pageTitles and pageSketches array rather than a
        // single contentItems array. For now, merge them - this is also done
        // in the WALKTHROUGH_LOAD_BY_MATTER_ID action, replace these bits of
        // logic once the server also returns the single array.
        if (val !== null) {
            val.pages.forEach(page => {
                if (page.pageTitles || page.pageSketches) {
                    page.contentItems = page.pageTitles.concat(page.pageSketches)
                    delete page.pageTitles
                    delete page.pageSketches
                }
            })
            val.pages.sort((a, b) => a.pageNumber - b.pageNumber)
        }


        // Set the walkthrough in the state.
        state.walkthrough = val

        // Create a copy of it to compare against when determining changes.
        state.sourceWalkthrough = cloneDeep(val)
    },

    [WALKTHROUGH_MUTATE_SELECTED_PAGE](state, val) {
        // Transition one page layer to the next.
        if (state.selectedPage !== null) {
            const oldPage = state.selectedPage

            // Check it's not the same layer that's visible, which could be the
            // case when they've been re-ordered.
            if (oldPage.layer !== val.layer) {
                let opacityValue = 0
                const transitionInterval = setInterval(() => {
                    if (opacityValue === 0) {
                        val.layer.setVisible(true)
                        oldPage.layer.setVisible(false)
                    }
                    if (opacityValue < 1) {
                        val.layer.setOpacity(opacityValue)
                        oldPage.layer.setOpacity(1 - opacityValue)
                        opacityValue += 0.01
                    } else {
                        clearInterval(transitionInterval)
                    }
                }, 10)
            }
        }

        // Set the selected page
        state.selectedPage = val
    },

    [WALKTHROUGH_MUTATE_PAGES](state, pages) {
        state.walkthrough.pages = pages.sort(dynamicSort('pageNumber'))
    },

    [WALKTHROUGH_MUTATE_SHOW_PAGE_REORDER_PROMPT](state, val) {
        state.showPageReorderPrompt = val
    },

    [WALKTHROUGH_MUTATE_DELETE_PAGE](state, page) {
        const pageIndex = state.walkthrough.pages.indexOf(page)
        const pages = [...state.walkthrough.pages]
        pages.splice(pageIndex, 1)
        pages.forEach((page, index) => {
            page.pageNumber = index + 1
        })
        page.layer.dispose()
        state.walkthrough.pages = pages
    },

    /**
     * Adds/removes a title from the selected walkthrough page
     * @param matterTitle - title object as it appears in the matter, contains
     *     style and visibility information.
     */
    [WALKTHROUGH_MUTATE_MATTER_TITLE_INCLUSION_IN_SELECTED_PAGE](state, matterTitle) {
        const existingTitle = state.selectedPage?.contentItems.find(title => title.titleNumber === matterTitle.titleNumber)

        if (existingTitle) {
            // Remove the title from the page.
            const existingIndex = state.selectedPage.contentItems.indexOf(existingTitle)
            state.selectedPage.contentItems.splice(existingIndex, 1)
        } else {
            // Add the title to the page using metadata from the title as it
            // appears in the matter.
            const newPageTitle = {
                colour: matterTitle.colour,
                comment: null,
                dashed: matterTitle.dashed,
                fill: matterTitle.fill,
                fillOpacity: matterTitle.fillOpacity,
                hatch: matterTitle.hatch,
                label: matterTitle.label,
                show: true, // Assume that the title should be visible.
                showTitleNumber: matterTitle.showTitleNumber,
                sortOrder: state.selectedPage.contentItems.length,
                strokeWidth: matterTitle.strokeWidth,
                titleNumber: matterTitle.titleNumber,
                boundaryAvailable: matterTitle.boundaryAvailable,
            }

            state.selectedPage.contentItems.push(newPageTitle)
        }
    },

    [WALKTHROUGH_MUTATE_REMOVE_ITEM_FROM_SELECTED_PAGE](state, contentItem) {
        const contentItemIndex = state.selectedPage.contentItems.indexOf(contentItem)
        state.selectedPage.contentItems.splice(contentItemIndex, 1)
        state.selectedPage.contentItems.forEach((contentItem, index) => {
            contentItem.sortOrder = index
        })
    },

    /**
     * Highlight the boundaries associated with a given title number on the map.
     * @param {any} titleNumber - can pass null to remove the hightlight.
     */
    [WALKTHROUGH_MUTATE_HIGHLIGHT_BOUNDARIES_FOR_TITLE_NUMBER](state, titleNumber) {
        state.highlightBoundariesForTitleNumber = titleNumber
    },

    /**
     * Increments the features revision counter for the walkthrough i.e. when
     * title/sketch features are added/removed.
     */
    [WALKTHROUGH_MUTATE_FEATURE_TRACKING_REVISION_COUNTER](state) {
        state.featureChangeTrackingRevisionCounter++
    },

    [WALKTHROUGH_MUTATE_PAGE_CONTENT_ITEM_VISIBILITY](state, item) {
        item.show = !item.show
        state.selectedPage.layer.getSource().changed()
    },

    [WALKTHROUGH_MUTATE_HAS_ZOOOMED_TO_INITIAL_PAGE_EXTENT](state, val) {
        state.hasZoomedToInitialPageExtent = val
    },

    [WALKTHROUGH_MUTATE_CONTENT_ITEM_BOUNDARY_AVAILABLE](state, payload) {
        payload.item.boundaryAvailable = payload.val
    },

    [WALKTHROUGH_MUTATE_SELECTED_PAGE_TITLE_COMMENT](state, payload) {
        const updatedTitle = state.selectedPage.contentItems.find(i => i.titleNumber === payload.titleNumber)
        if (updatedTitle?.comment !== payload?.comment) {
            updatedTitle.comment = payload.comment
        }
    },

    [WALKTHROUGH_MUTATE_SELECTED_PAGE_DESCRIPTION](state, pageDescription) {
        if (state.selectedPage.description !== pageDescription) {
            state.selectedPage.description = pageDescription
        }
    },
}
