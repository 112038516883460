<template>
    <div class="matter-searches-project-name-form">
        <v-form ref="formRef"
                :data-valid="valid"
                class="w-50"
                @submit.prevent>
            <div class="matter-searches-project-name-form__input">
                <label for="project-name-textfield"
                       class="caption-regular text-no-wrap matter-searches-enter-address-form__label">
                    {{ t('searches.projectNameForm.projectName') }}
                </label>
                <v-text-field id="project-name-textfield"
                              v-model="form.projectName"
                              :rules="[rules.required, rules.mask]"
                              required
                              data-test="project-name-textfield"
                              variant="solo" />
            </div>
        </v-form>
    </div>
</template>

<script setup lang="ts">
    import {
        onActivated,
        onMounted,
        reactive,
        ref,
        watch,
    } from "vue"
    import {              defineEmits,
                          defineProps } from "vue"
    import {useI18n} from "vue-i18n"
    import {useStore} from "vuex"

    import {StructuredAddress} from "@/api/searches.api"

    const { t } = useI18n()

    const props = defineProps({
        projectMask: {
            type: String,
            required: true,
        },
    })
    const store = useStore()
    const emit = defineEmits<{
        (e: 'update:valid', valid: boolean): void
        (e: 'update:modelValue', value: string): void
    }>()

    let form = reactive({
        projectName: "",
    })

    const model = defineModel<string>()
    const valid = defineModel<boolean>('valid-project-name')
    const formRef = ref(null)

    let rules = reactive({
        required: (value) => !!value || t('searches.projectNameForm.required'),
        mask: (value) => {
            const maskRegex = new RegExp(
                '^' +
                    props.projectMask
                        .replace(/A/g, '[A-Za-z]')
                        .replace(/N/g, '\\d')
                        .replace(/n/g, '\\d?')
                    + '$',
            )
            return maskRegex.test(value) || t('searches.projectNameForm.invalid', {projectMask: props.projectMask})
        },
    })

    const validate = async () => {
        const { valid } = await formRef.value.validate()
        return valid
    }

    // Emit events whenever the form data changes
    watch(() => form.projectName, async (newProjectName) => {
        emit('update:modelValue', newProjectName)
        const validationResult = await validate()
        valid.value = validationResult
        emit('update:valid', validationResult)
    }, {
        deep: true,
    })

    watch(() => model.value, (newValue) => {
        form.projectName = newValue
    }, {
        immediate: true,
        deep: true,
    })

    onMounted(() => {
        init()
    })

    onActivated(() => {
        init()
    })

    const init = async () => {
        if(!store.state.searches.projectName){
            return
        }
        model.value = store.state.searches.projectName
        const validationResult = await validate()
        valid.value = validationResult
        emit('update:valid', validationResult)
    }
</script>

<style scoped lang="scss">
@import './matter-searches-project-name-form';
</style>
