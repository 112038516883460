import {
    createDefaultTAMEntryForTitle,
    createGenericRegisterEntriesForTitle,
    getTitleNumberLabelsMapping,
    getTitleNumberMatterGroupMapping,
} from '@/store/modules/title-analysis/utils'
import { dynamicSortMultipleFields } from '@/utils/array-utils'

import {
    ANALYSIS_MUTATE_ADDRESSES,
    ANALYSIS_MUTATE_APPLYING_TEMPLATE,
    ANALYSIS_MUTATE_DERIVED_DATA,
    ANALYSIS_MUTATE_DERIVED_DATA_REGISTER_ENTRIES,
    ANALYSIS_MUTATE_GRID_INSTANCE,
    ANALYSIS_MUTATE_GROUPING,
    ANALYSIS_MUTATE_HAS_LOADED_INITIAL_GRID_STATE,
    ANALYSIS_MUTATE_INITIAL_GRID_CONFIG,
    ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE,
    ANALYSIS_MUTATE_LOADING_STATUS,
    ANALYSIS_MUTATE_ORDER_STATUS,
    ANALYSIS_MUTATE_SELECTED_COLUMNS,
    ANALYSIS_MUTATE_SELECTED_FILTERS,
    ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION,
    ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION,
    ANALYSIS_MUTATE_SORT,
    ANALYSIS_MUTATE_SOURCE_DATA,
    ANALYSIS_MUTATE_TEMPLATE_APPLIED,
} from './types.js'

export default {
    [ANALYSIS_MUTATE_SOURCE_DATA](state, data) {
        state.sourceData = data
    },

    /**
     * Takes the data returned by theGraphQL endpoint from the state
     * and maps it to the data object required to display in the grid.
     * @param state
     * @returns {[]}
     */
    [ANALYSIS_MUTATE_DERIVED_DATA](state, {
        data,
        matter,
        addressData,
    }) {
        // Matter group and label are determined on the fly, if required
        let titleNumberMatterGroupMapping = {}
        let titleNumberLabelsMapping = {}

        if (state.selectedColumns.find(c => c.field === 'matterGroup')) {
            titleNumberMatterGroupMapping = getTitleNumberMatterGroupMapping(matter)
        }
        if (state.selectedColumns.find(c => c.field === 'titleLabel')) {
            titleNumberLabelsMapping = getTitleNumberLabelsMapping(matter)
        }

        if (!data) {
            return []
        }

        state.derivedData = data.map(title => {
            return createDefaultTAMEntryForTitle(title, addressData.find((addressTitle) => addressTitle.titleNumber === title.titleNumber ), titleNumberMatterGroupMapping[title.titleNumber], titleNumberLabelsMapping[title.titleNumber])
        })
    },

    [ANALYSIS_MUTATE_DERIVED_DATA_REGISTER_ENTRIES](state, {
        data,
        matter,
    }) {
        if (!data) {
            return
        }
        const formattedData = []

        // Matter group derived on client
        const titleNumberMatterGroupMapping = getTitleNumberMatterGroupMapping(matter)

        data.forEach(title => {
            const entries = createGenericRegisterEntriesForTitle(title, titleNumberMatterGroupMapping[title.titleNumber])
            formattedData.push(...entries)
        })

        state.derivedData = formattedData.sort(dynamicSortMultipleFields(['titleNumber', 'registerSection', 'entryNumber']))
    },

    [ANALYSIS_MUTATE_LOADING_STATUS](state, status) {
        state.loading = status
    },

    [ANALYSIS_MUTATE_SELECTED_COLUMNS](state, columns) {
        state.selectedColumns = columns
    },

    [ANALYSIS_MUTATE_ADDRESSES](state, addresses) {
        state.addresses = addresses
    },

    [ANALYSIS_MUTATE_INITIAL_GRID_CONFIG](state, config) {
        // NOTE: After it's first initialized, initialGridConfig.columns becomes
        // a ColumnStore (bryntum.com/products/grid/docs/#Grid/data/ColumnStore).
        // This ensures it's not being reset back and break the grid.
        const columns = state.initialGridConfig?.columns ?? config.columns

        state.initialGridConfig = { ...config, columns }
        state.selectedColumns = config.columns.filter(c => !c.hidden === true)
    },

    [ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION](state, show) {
        state.showColumnSelection = show
    },

    [ANALYSIS_MUTATE_HAS_LOADED_INITIAL_GRID_STATE](state, val) {
        state.hasLoadedInitialGridState = val
    },

    [ANALYSIS_MUTATE_GRID_INSTANCE](state, val) {
        state.gridInstance = val
    },

    [ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION](state, val) {
        state.showTemplateSelection = val
    },

    [ANALYSIS_MUTATE_SELECTED_FILTERS](state, val) {
        state.gridInstance.store.clearFilters()
        state.gridInstance.store.filter(val)
        state.gridInstance.store.filter(val) // yes, otherwise it doesn't work. TODO: roll our own filter logic.
    },

    [ANALYSIS_MUTATE_SORT](state, val) {
        if (state.gridInstance) {
            state.gridInstance.store.clearSorters()
            if (val?.length) {
                state.gridInstance.store.sort(val.field, val.ascending)
            }
        } else {
            console.error('Tried to set sort on grid instance but it has not initialized yet')
        }
    },

    [ANALYSIS_MUTATE_ORDER_STATUS](state, {
        titleNumber,
        documentOrderStatus,
    }) {
        const registerToUpdate = state.gridInstance?.store?.find(titleRegister => titleRegister.titleNumber === titleNumber)
        if (registerToUpdate) {
            const derivedDataRegisterToUpdate = state.derivedData.find(titleRegister => titleRegister.titleNumber === titleNumber)

            if (documentOrderStatus) {
                registerToUpdate.documentOrderStatus = documentOrderStatus
            }

            if (derivedDataRegisterToUpdate && documentOrderStatus) {
                derivedDataRegisterToUpdate.documentOrderStatus = documentOrderStatus
            }
        }
    },

    [ANALYSIS_MUTATE_GROUPING](state, val) {
        if (state.gridInstance) {
            state.gridInstance.store.clearGroupers()
            if (val) {
                state.gridInstance.store.group(val.field, val.ascending)
            }
        } else {
            console.error('Tried to set grouping on grid instance but it has not initialized yet')
        }
    },

    [ANALYSIS_MUTATE_APPLYING_TEMPLATE](state, val) {
        state.applyingTemplate = val
    },

    [ANALYSIS_MUTATE_TEMPLATE_APPLIED](state, val) {
        if (state.gridInstance.features.filterBar) {
            state.gridInstance.features.filterBar.hideFilterBar()
            state.isFilterBarVisible = false
        }
        state.appliedTemplate = val
    },

    [ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE](state, isVisible) {
        state.isFilterBarVisible = isVisible
    },
}
