import UserApi from '@/api/user.api'
import { INIT_FEATURE_FLAGS } from '@/store/modules/config/types'
import { LINK_SHARE_CLIENT_MUTATE_IS_MATTER_LINK_SHARE_USER } from '@/store/modules/link-share-client/types'
import {
    HIDE_POPUP_BLOCKED_WARNING,
    OPEN_WINDOW,
    USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG,
    USER_CREATE_PRODUCTBOARD_TOKEN,
    USER_GET_BASIC_INFO,
    USER_GET_DOCUMENT_CHARGE,
    USER_HIDE_POPUP,
    USER_INITIATE_STATUS_UPDATES,
    USER_IS_ADMIN,
    USER_IS_IN_ROLE,
    USER_IS_ORGANISATION_ADMIN,
    USER_MUTATE_LOADING,
    USER_MUTATE_LOGIN,
    USER_MUTATE_LOGOUT,
    USER_MUTATE_POPUP_PROPERTIES,
    USER_MUTATE_PRODUCTBOARD_TOKEN,
    USER_MUTATE_PROFILE,
    USER_MUTATE_PROFILE_USES_CLIENT_CODE,
    USER_MUTATE_SHOW_MAP_TITLES_NAV,
    USER_MUTATE_USER_PREFERENCES,
    USER_REQUEST_PROFILE,
    USER_SAVE_WORKING_FROM_HOME_PREFERENCES,
    USER_SHOW_POPUP,
} from '@/store/mutation-types'
import { isNullOrWhitespace } from '@/utils/string-utils'
import { formatProfileAddress } from '@/utils/user.utils'

import { MAP_SET_NPS_LAYER_CLICKABLE } from './map/types'

export default {

    state: {

        // ui
        showAdminNav: false,
        showOrgAdminNav: false,
        showMapTitlesNav: true,

        // profile
        id: null,
        name: null,
        firstName: null,
        lastName: null,
        email: null,
        organisation: null,
        organisationId: null,
        organisationAddress: {
            addressHouseNumber: null,
            addressStreet: null,
            addressLocality: null,
            addressTown: null,
            addressPostcode: null,
        },
        lastSeen: null,
        accountCreatedAt: null,
        intercomHMAC: null,
        isLoggedIn: false,
        isAdmin: false,
        profileLoaded: false,
        roles: [],
        showLoginPrompt: false,
        loading: false,
        errorMessage: '',
        usesClientCodes: false,
        mandatoryMatterCodes: false,
        profilePromise: null,
        productboardToken: null,

        preferences: {
            hiddenMatterPanel: null,
            // Custom postal address
            useCustomPostalAddress: null,
            addressHouseNumber: null,
            addressStreet: null,
            addressLocality: null,
            addressTown: null,
            addressPostcode: null,
        },

        canShareMattersWithWholeOrg: false,

        // feature flags
        canHideMatterPanel: false,

        // dynamic popups
        popupBlockerChecker: null,
        showWarnPopupsBlocked: false,
        popup: {
            show: false,
            title: null,
            contentHTML: null,
            showButton: true,
            persistent: false,
            testName: null,
        },

        currentMatterId: null,
        titleSummaryCharge: null,
        pricingModelId: null,
        pricingModelName: null,
    },

    mutations: {
        [USER_MUTATE_LOGIN](state) {
            state.isLoggedIn = true
            state.showLoginPrompt = false
            state.loading = false
        },

        [USER_MUTATE_PRODUCTBOARD_TOKEN](state, token) {
            state.productboardToken = token
        },

        [USER_MUTATE_LOGOUT](state) {
            state.isLoggedIn = false
            state.name = null
            state.firstName = null
            state.roles = []
            state.lastName = null
            state.profileLoaded = false
        },

        [USER_MUTATE_LOADING](state, isLoading) {
            state.loading = isLoading
        },

        [USER_MUTATE_PROFILE](state, profile) {
            state.id = profile.id
            state.firstName = profile.firstName
            state.lastName = profile.lastname
            state.name = `${ state.firstName } ${ state.lastName }`
            state.email = profile.email
            state.accountCreatedAt = new Date(profile.accountCreatedAt)
            state.intercomHMAC = profile.intercomHMAC
            state.organisation = profile.organisation
            state.organisationId = profile.organisationId
            state.lastSeen = new Date(profile.lastSeen)
            state.roles = profile.roles
            state.currentMatterId = profile.currentMatter != null ? profile.currentMatter.id : null
            state.usesClientCodes = profile.usesClientCodes
            state.mandatoryMatterCodes = profile.mandatoryMatterCodes
            state.pricingModelId = profile.pricingModelId
            state.pricingModelName = profile.pricingModelName
            state.titleSummaryCharge = profile.titleSummaryCharge
            state.organisationIndustry = profile.organisationIndustry
            state.organisationStatus = profile.organisationStatus
            state.matterCodeFormat = profile.matterCodeFormat
            state.matterCodeMask = profile.matterCodeMask
            state.clientCodeFormat = profile.clientCodeFormat
            state.canShareMattersWithWholeOrg = profile.canShareMattersWithWholeOrg

            if (profile.organisationAddress) {
                state.organisationAddress = formatProfileAddress(profile.organisationAddress)
            }

            // Feature flags
            state.canHideMatterPanel = profile.canHideMatterPanel

            // User preferences (if applicable)
            state.preferences = profile.preferences

            state.profileLoaded = true
        },

        [USER_MUTATE_USER_PREFERENCES](state, preferences) {
            state.preferences = preferences
        },

        [USER_MUTATE_SHOW_MAP_TITLES_NAV](state, show) {
            state.showMapTitlesNav = show
        },

        [USER_MUTATE_POPUP_PROPERTIES](state, properties) {
            state.popup.show = properties.show !== undefined ? properties.show : true
            state.popup.title = properties.title || null
            state.popup.icon = properties.icon || null
            state.popup.contentHTML = properties.contentHTML || null
            state.popup.showButtons = properties.showButtons || true
            state.popup.persistent = properties.persistent
            state.popup.testName = properties.testName || null
            state.popup.showCancelButton = properties.showCancelButton !== undefined ? properties.showCancelButton : false
            state.popup.cancelText = properties.cancelText || 'Cancel'
            state.popup.okText = properties.okText || 'OK'
            state.popup.cancelCallback = properties.cancelCallback || null
            state.popup.okCallback = properties.okCallback || null
        },

        [USER_MUTATE_PROFILE_USES_CLIENT_CODE](state, useClientCode) {
            state.usesClientCodes = useClientCode
        },
    },

    getters: {
        [USER_IS_IN_ROLE](state) {
            return (role) => {
                if (state.roles == null) {
                    return false
                }
                return state.roles.indexOf(role) !== -1
            }
        },

        [USER_IS_ADMIN](state, getters) {
            return getters[USER_IS_IN_ROLE]('SystemAdmin')
        },

        [USER_IS_ORGANISATION_ADMIN](state, getters) {
            return getters[USER_IS_IN_ROLE]('OrgAdmin')
        },

        [USER_GET_BASIC_INFO](state) {
            return {
                name: state.name,
                organisation: state.organisation,
                joinDate: state.accountCreatedAt,
            }
        },

        [USER_GET_DOCUMENT_CHARGE](state) {
            return `£${ state.titleSummaryCharge }`
        },

        [USER_CAN_SHARE_MATTERS_WITH_WHOLE_ORG](state) {
            return state.canShareMattersWithWholeOrg
        },
    },

    actions: {

        async [USER_CREATE_PRODUCTBOARD_TOKEN]({ commit }) {
            const response = await UserApi.createProductboardToken()

            if (response.ok) {
                commit(USER_MUTATE_PRODUCTBOARD_TOKEN, response.token)
            }
        },

        async [USER_REQUEST_PROFILE]({
            commit,
            dispatch,
            state,
        }) {
            if (isNullOrWhitespace(state.id)) {
                commit(USER_MUTATE_LOADING, true)

                try {
                    const response = await UserApi.getUserProfile()
                    if (response.ok) {
                        commit(USER_MUTATE_PROFILE, response.data)
                        commit(`linkShareClient/${ LINK_SHARE_CLIENT_MUTATE_IS_MATTER_LINK_SHARE_USER }`, response.data.isMatterLinkShareUser, { root: true })
                        commit(USER_MUTATE_LOGIN)
                        commit(MAP_SET_NPS_LAYER_CLICKABLE, true) // Can disable clicks here for certain users if required

                        // Initialise feature flags for the user
                        await dispatch(`config/${ INIT_FEATURE_FLAGS }`, response.data, { root: true })
                    } else {
                        console.error({
                            title: 'Profile error',
                            text: response.message,
                        })
                        // TODO: These are mutations! Better to create a mutation for all 3 values.
                        state.isLoggedIn = false
                        state.showLoginPrompt = true
                    }
                } catch (err) {
                    console.error(err)
                    // TODO: These are mutations! Better to create a mutation for all 3 values.
                    state.isLoggedIn = false
                    state.showLoginPrompt = true
                }
                commit(USER_MUTATE_LOADING, false)
            }
        },

        async [USER_INITIATE_STATUS_UPDATES]({
            commit,
            rootState,
        }) {
            const updateUserStatus = async () => {
                const response = await UserApi.userStatusUpdate()
                if (!response.ok) {
                    commit(USER_MUTATE_LOGOUT)
                }
            }

            await updateUserStatus()

            let lastAction = rootState.diagnostics.lastClicked
            window.setInterval(async () => {
                if (lastAction !== rootState.diagnostics.lastClicked) {
                    lastAction = rootState.diagnostics.lastClicked

                    await updateUserStatus()
                }
            }, 30000)
        },

        // TODO: This is more of a utility than an action I think?
        // Doesn't seem to be anything listening for the showWarnPopupsBlocked anyhow!
        [OPEN_WINDOW]({ state }, args: { urlToOpen: string, shouldRetainCookies: boolean } | string) {
            const url = typeof args === 'string' ? args : args?.urlToOpen
            const shouldRetainCookies = typeof args === 'object' ? args?.shouldRetainCookies ?? true : false

            // https://stackoverflow.com/questions/668286/detect-blocked-popup-in-chrome/1089792#1089792
            if (state.popupBlockerChecker == null) {
                state.popupBlockerChecker = {
                    check: function(popupWindow, urlAttemptingToOpen) {
                        const _scope = this
                        if (popupWindow) {
                            if (/chrome/.test(navigator.userAgent.toLowerCase())) {
                                setTimeout(function() {
                                    _scope._is_popup_blocked(_scope, popupWindow, urlAttemptingToOpen)
                                }, 200)
                            } else {
                                popupWindow.onload = function() {
                                    _scope._is_popup_blocked(_scope, popupWindow, urlAttemptingToOpen)
                                }
                            }
                        } else {
                            _scope._displayError(urlAttemptingToOpen)
                        }
                    },
                    _is_popup_blocked: function(scope, popupWindow, urlAttemptingToOpen) {
                        if (popupWindow.location.href) {
                            if (!(popupWindow.innerHeight > 0)) {
                                scope._displayError(urlAttemptingToOpen)
                            } else {
                                // TODO: This is a mutation!
                                state.showWarnPopupsBlocked = false
                            }
                        }
                    },
                    _displayError: function() {
                        // TODO: This is a mutation!
                        state.showWarnPopupsBlocked = true
                    },
                }
            }

            const popup = shouldRetainCookies
                ? window.open(url, '_blank')
                : window.open(url, '_blank', 'noopener,noreferrer')

            const targetURL = new URL(url)
            const currentURL = new URL(window.location.href)
            if (targetURL.origin === currentURL.origin) {
                state.popupBlockerChecker.check(popup, url)
            }

            // TODO: Would this not be easier to check for popup blockers?
            // const popup = window.open(urlToOpen, '_blank')
            // try {
            //     popup.focus();
            // } catch (e) {
            //     // TODO: This is a mutation!
            //     state.showWarnPopupsBlocked = true
            // }
        },

        // TODO: This is a mutation!
        [HIDE_POPUP_BLOCKED_WARNING]({ state }) {
            state.showWarnPopupsBlocked = false
        },

        // TODO: This is a mutation!
        [USER_SHOW_POPUP]({ commit }, request) {
            commit(USER_MUTATE_POPUP_PROPERTIES, request)
        },

        // TODO: This is a mutation!
        [USER_HIDE_POPUP]({ commit }) {
            commit(USER_MUTATE_POPUP_PROPERTIES, {
                show: false,
            })
        },

        async [USER_SAVE_WORKING_FROM_HOME_PREFERENCES]({
            commit,
            state,
        }, workingFromHomePreferences) {
            // TODO: This is a mutation!
            const newPreferences = { ...state.preferences, ...workingFromHomePreferences }
            const result = await UserApi.updatePreferences(newPreferences)
            if (result.success) {
                commit(USER_MUTATE_USER_PREFERENCES, newPreferences)
            }
        },
    },
}
