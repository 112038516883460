<template>
    <div class="title-alerts-preferences d-flex flex-column gr-4">
        <div class="email-preferences__instructions">
            <p v-t="'assetMonitoring.preferences.emailInstructions'"
               class="body-regular" />
        </div>

        <section class="email-preferences__content">
            <preference-details-card :is-loading="isUpdatingSettings"
                                     :settings="settings"
                                     class="monitoring-preferences__content--row"
                                     :preference-properties="matterPreferenceProperties"
                                     :title="t('assetMonitoring.preferences.cards.email.matterCardTitle')"
                                     :description="t('assetMonitoring.preferences.cards.email.matterCardDescription')"
                                     data-test="email-preferences-matter-settings"
                                     @update="updateMatterSettings" />
        </section>

        <section class="email-preferences__content">
            <preference-details-card :is-loading="isUpdatingUserEmailPreferences"
                                     :settings="userEmailPreferences"
                                     class="monitoring-preferences__content--row"
                                     :preference-properties="userPreferenceProperties"
                                     :title="t('assetMonitoring.preferences.cards.email.userCardTitle')"
                                     :description="t('assetMonitoring.preferences.cards.email.userCardDescription')"
                                     :disabled="!store.notificationSettings.dailySummaryEmailEnabled"
                                     data-test="email-preferences-user-settings"
                                     @update="updateUserSettings" />
        </section>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        onMounted,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {useRoute} from "vue-router"

    import {INotificationSettings} from '@/api/notifications.api'
    import {IUserEmailPreferences} from '@/api/user-email-preferences.api'
    import PreferenceDetailsCard from "@/components/asset-monitoring/monitoring-preferences/preference-details-card.vue"
    import {IPreferenceDetailsModel} from "@/components/asset-monitoring/monitoring-preferences/preference-details-model-interface"
    import {useAssetMonitoringStore} from "@/stores/asset-monitoring"

    const { t } = useI18n()
    const route = useRoute()
    const store = useAssetMonitoringStore()
    const currentMatterId = computed<number>(() => Number(route.params.matterId))
    const settings = computed<INotificationSettings>(() => store.notificationSettings)
    const userEmailPreferences = computed<IUserEmailPreferences>(() => store.userEmailPreferences)
    const isUpdatingSettings = computed<boolean>(() => store.isUpdatingSettings)
    const isUpdatingUserEmailPreferences = computed<boolean>(() => store.isUpdatingUserEmailPreferences)

    onMounted(async () => {
        if (!store.areNotificationSettingsLoaded) {
            await store.getNotificationSettings(currentMatterId.value)
        }

        if (!store.areUserEmailPreferencesLoaded) {
            await store.getUserEmailPreferences(currentMatterId.value)
        }
    })

    const matterPreferenceProperties = ref<Array<IPreferenceDetailsModel>>(
        [
            {
                value: 'dailySummaryEmailEnabled',
            },
        ],
    )

    const userPreferenceProperties = ref<Array<IPreferenceDetailsModel>>(
        [
            {
                value: 'optedIn',
            },
        ],
    )

    const updateMatterSettings = async (request: INotificationSettings) => {
        await store.updateNotificationSettings(currentMatterId.value, request)
    }

    const updateUserSettings = async (request: IUserEmailPreferences) => {
        await store.updateUserEmailPreferences(currentMatterId.value, request)
    }
</script>

<style scoped lang="scss">
    @import './email-preferences';
</style>
