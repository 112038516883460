import { IReportsState } from '@/interfaces/store/reports/reports-state.interface'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IExportTemplateOptions } from '@/interfaces/export-template-options.interface'

const state: IReportsState = {
    exportOptionsLoading: false,
    exportOptions: [],
    selectedExportOption: {} as IExportTemplateOptions,
    selectedTitles: [],
    titleAvailabilityLoading: false,
    titleAvailability: {},
    disabledFeatures: [],
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
