<template>
    <ow-card v-if="selectedTitle?.record && show"
             :loading="!selectedTitle.record"
             class="boundary-data-unavailable"
             data-test="title-panel-boundary-data-unavailable">
        <v-icon>$warning</v-icon>
        <p>
            {{ $t('titles.unavailableBoundaryWithDataSource',
                  [getDataProviderText(selectedTitle?.record.source)]) }}
        </p>
        <a class="boundary-data-unavailable__learn-more"
           href="https://intercom.help/orbital-witness/en/articles/5394971-title-boundary-can-t-be-displayed"
           target="_blank"
           data-track="TITLE-DETAILS-PANEL - Learn more about why boundary cannot be displayed">Learn more</a>
    </ow-card>
</template>

<script lang="ts">
    import OwCard from '@/components/core/ow-card.vue'
    import { getDataProviderText } from '@/consts/document-source'

    export default {
        name: 'BoundaryDataUnavailable',

        components: {
            OwCard,
        },

        props: {
            selectedTitle: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                getDataProviderText,
            }
        },

        computed: {
            show() {
                return this.selectedTitle?.bboxGeom === null
            },
        },
    }
</script>

<style lang="scss">
    @import './boundary-data-unavailable';
</style>
