<template>
    <section class="bulk-options-panel"
             data-test="bulk-options-panel"
             :style="componentStyle">
        <header class="bulk-options-panel__panel-header">
            <h3 v-t="'mapOverlays.selection.title'"
                class="content__subheading" />
            <v-icon class="bulk-options-panel__panel-header--close-icon"
                    data-test="bulk-options-panel-close-btn"
                    @click="$emit('dismiss')">
                $close
            </v-icon>
        </header>
        <div class="bulk-options-panel__divider" />
        <!-- Count of selection -->
        <div class="bulk-options-panel__item bulk-options-panel__item--info caption-highlight"
             data-test="bulk-options-panel-selection-text">
            {{ t('mapOverlays.selection.overlaysSelected', selectedOverlays.length) }}
        </div>

        <!-- Hide / show overlays -->
        <div class="bulk-options-panel__item"
             :class="{disabled: !canShowSelectedOverlays}"
             data-track="bulk-options-panel-show-overlays-btn"
             data-test="bulk-options-panel-show-overlays-btn"
             @click="$emit('show-selection')">
            <label>
                {{ t('mapOverlays.selection.showSelectedOverlays', selectedOverlays.length) }}
            </label>
        </div>
        <div :class="{disabled: !canHideSelectedOverlays}"
             data-test="bulk-options-panel-hide-overlays-btn"
             data-track="bulk-options-panel-hide-overlays-btn"
             class="bulk-options-panel__item"
             @click="$emit('hide-selection')">
            <label>
                {{ t('mapOverlays.selection.hideSelectedOverlays', selectedOverlays.length) }}
            </label>
        </div>
        <!-- Other options -->
        <div :class="{disabled: selectedOverlays.length !== 1}"
             class="bulk-options-panel__item"
             data-test="bulk-options-panel-edit-overlay-name-btn"
             data-track="OVERLAYS LIST - edit name"
             @click="$emit('change-selection')">
            <label v-t="'mapOverlays.selection.renameOverlay'" />
        </div>

        <div class="bulk-options-panel__divider"
             style="margin: 16px 16px;" />

        <div class="bulk-options-panel__item bulk-options-panel__item--danger"
             data-test="bulk-options-panel-remove-overlays-btn"
             data-track="bulk-options-panel-remove-overlays-btn"
             @click="$emit('remove')">
            <label>
                {{ t('mapOverlays.selection.removeOverlays', selectedOverlays.length) }}
            </label>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from "vue-i18n"

    import { OverlayListItemModel } from '@/components/map/overlays/overlays-types'

    const { t } = useI18n()

    interface Props {
        selectedOverlays: Array<OverlayListItemModel>
        anchorElementSelector?: string,
        anchorOffset?: Array<number>,
    }
    const props = defineProps<Props>()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,func-call-spacing
    const emit = defineEmits<{
        (e: 'dismiss'): void
        (e: 'show-selection'): void
        (e: 'hide-selection'): void
        (e: 'change-selection'): void
        (e: 'remove'): void
    }>()

    // Appearance and validation.
    const componentStyle = computed(() => {
        if (!props.anchorElementSelector) {
            return null
        }
        const anchorElementRect = document.querySelector(props.anchorElementSelector)?.getBoundingClientRect()
        if (!anchorElementRect) {
            return null
        }
        const left = anchorElementRect.left + anchorElementRect.width
        let style = {} as any
        if (props.anchorOffset?.length == 4) {
            style = {
                marginTop: `${ props.anchorOffset[0] }px`,
                marginRight: `${ props.anchorOffset[1] }px`,
                marginBottom: `${ props.anchorOffset[2] }px`,
                marginLeft: `${ props.anchorOffset[3] }px`,
            }
        }
        style.left = `${ left }px`
        return style
    })
    const canShowSelectedOverlays = computed(() => {
        return props.selectedOverlays.some(x => {
            return !x.visible
        })
    })
    const canHideSelectedOverlays = computed(() => {
        return props.selectedOverlays.some(x => {
            return x.visible
        })
    })

</script>

<style lang="scss">
  @import "overlays-options-panel.scss";
</style>
