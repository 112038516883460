<template>
    <transition name="fade">
        <div v-if="show"
             class="ow-overlay"
             @click="click">
            <div class="ow-overlay__content"
                 @click="clickOutside">
                <slot />
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
    export default {
        name: 'OwOverlay',
        props: {
            show: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['click'],
        methods: {
            click() {
                this.$emit('click')
            },
            clickOutside(event: PointerEvent) {
                event.stopPropagation()
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './ow-overlay';
</style>
