import WMTSTileGrid from 'ol/tilegrid/WMTS'

import { CoordinateSystemCode } from '@/enums/coordinate-systems'

/** Returns the tile grid used by Bluesky layers in the EPSG:27700 projection */
export const bluesky27700TileGrid = new WMTSTileGrid({
    projection: CoordinateSystemCode.EPSG27700,
    tileSize: [500, 500],
    extent: [
        0,
        0,
        896000,
        1408000,
    ],
    maxZoom: 11,
    minZoom: 0,
    resolutions: [
        256,
        128,
        64,
        32,
        16,
        8,
        4,
        2,
        1,
        0.5,
        0.25,
        0.125,
    ],
    sizes: [
        [7, 11],
        [14, 22],
        [28, 44],
        [56, 88],
        [112, 224],
        [224, 448],
        [448, 896],
        [896, 1792],
        [1792, 3584],
        [3584, 7168],
        [7168, 14336],
        [14336, 28672],
    ],
    matrixIds: [
        "2/2/2/2/2/2/2/2/2/2",
        "2/2/2/2/2/2/2/2/2",
        "2/2/2/2/2/2/2/2",
        "2/2/2/2/2/2/2",
        "2/2/2/2/2/2",
        "2/2/2/2/2",
        "2/2/2/2",
        "2/2/2",
        "2/2",
        "2",
        ".",
        "12.5cm",
    ],
})
