<template>
    <ow-clipboard :text="textEntry"
                  scrollable
                  @clicked="logClip"
                  @scrolled="handleScroll">
        <div class="schedule-of-lease-record-entry"
             data-test="review-assistant-register-record-entry-text">
            <div class="schedule-of-lease-record-entry__data">
                <div v-for="([key, value], index) of parsedEntry"
                     :key="key+value"
                     class="schedule-of-lease-record-entry__section">
                    <ow-text-highlight :queries="searchQueries"
                                       class="label-caps-medium schedule-of-lease-record-entry__section--label"
                                       @match-count-changed="keySearchMatchCount[index] = $event">
                        {{ key }}:
                    </ow-text-highlight>
                    <ow-text-highlight :queries="searchQueries"
                                       class="body-regular schedule-of-lease-record-entry__section--data"
                                       @match-count-changed="valueSearchMatchCount[index] = $event">
                        {{ value }}
                    </ow-text-highlight>
                </div>
            </div>
            <div v-if="!isNullOrEmpty(props.entry.notes)"
                 class="schedule-of-lease-record-entry__notes">
                <ow-text-highlight v-for="(note, index) of props.entry.notes"
                                   :key="`note-${index}`"
                                   :queries="searchQueries"
                                   class="body-italic"
                                   @match-count-changed="noteSearchMatchCount[index] = $event">
                    {{ note }}
                </ow-text-highlight>
            </div>
        </div>
    </ow-clipboard>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwTextHighlight from '@/components/core/ow-text-highlight.vue'
    import { useCompromise } from '@/composables/use-compromise'
    import { IScheduleOfLeaseEntry } from '@/interfaces/documents/schedule-of-lease-entry.interface'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const props = defineProps<{
        entry: IScheduleOfLeaseEntry,
        searchFilter: string,
        highlightDates?: boolean,
    }>()

    const emit = defineEmits<{
        (e: 'scrolled'): void
        (e: 'match-count-changed', value: number): void
    }>()

    const { compromise } = useCompromise()
    const store = useStore()
    const { t } = useI18n()

    const parsedEntry = computed<[ string, string ][]>(() => {
        const result = []

        if (!isNullOrWhitespace(props.entry.registrationDateAndPlanRef)) {
            result.push([ t('titles.sonol.regDateAndPlan'), props.entry.registrationDateAndPlanRef ])
        }

        if (!isNullOrWhitespace(props.entry.propertyDescription)) {
            result.push([ t('titles.sonol.propertyDescription'), props.entry.propertyDescription ])
        }

        if (!isNullOrWhitespace(props.entry.dateOfLease)) {
            result.push([ t('titles.sonol.dateOfLease'), props.entry.dateOfLease ])
        }

        if (!isNullOrWhitespace(props.entry.term)) {
            result.push([ t('titles.sonol.term'), props.entry.term ])
        }

        if (!isNullOrWhitespace(props.entry.lesseesTitle)) {
            result.push([ t('titles.sonol.lesseesTitle'), props.entry.lesseesTitle ])
        }

        return result
    })

    const textEntry = computed(() => {
        const entriesText = parsedEntry.value.map(line => line.join(': ')).join('\n')
        const notesText = !isNullOrEmpty(props.entry?.notes) ? `\n\n${ props.entry.notes.join('\n') }` : ''

        return entriesText + notesText
    })
    const dateFilters = computed<string[]>(() => {
        const doc: any = compromise(textEntry.value)
        const dateTerms: string[] = doc
            .dates()
            .json()
            .map(({ text }) => text)

        return dateTerms
    })
    const searchQueries = computed<string[]>(() => {
        if (props.highlightDates) {
            return [
                props.searchFilter,
                ...dateFilters.value,
            ]
        }

        return [ props.searchFilter ]
    })


    const keySearchMatchCount = ref(new Array(5).fill(0))
    const valueSearchMatchCount = ref(new Array(5).fill(0))
    const noteSearchMatchCount = computed(() => new Array(props.entry?.notes?.length ?? 0).fill(0))
    const totalSearchMatchCount = computed<number>(() => {
        return keySearchMatchCount.value.reduce((acc: number, v: number) => acc + v, 0) +
            valueSearchMatchCount.value.reduce((acc: number, v: number) => acc + v, 0) +
            noteSearchMatchCount.value.reduce((acc: number, v: number) => acc + v, 0)
    })
    watch(totalSearchMatchCount, (value) => {
        emit('match-count-changed', value)
    })


    const logClip = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'REVIEW-ASSISTANT - Copy Register Entry',
            metadata: { content: 'sonol' },
        })
    }

    const handleScroll = (): void => {
        emit('scrolled')
    }
</script>

<style lang="scss" scoped>
    @import './schedule-of-lease-record-entry.scss';
</style>
