<template>
    <v-snackbar v-model="isVisible"
                :timeout="timeout"
                :vertical="multiLine"
                class="ow-snackbar"
                location="bottom start"
                rounded="4px"
                transition="slide-y-reverse-transition">
        <div :class="multiLine ? 'ow-snackbar__content ow-snackbar__content--multi-line' : 'ow-snackbar__content'">
            <section>
                <slot />
            </section>
            <v-icon v-if="multiLine"
                    class="ow-snackbar__actions--close-btn"
                    data-test="ow-snackbar-close-btn"
                    data-track="Close snackbar"
                    @click="isVisible=false">
                $close
            </v-icon>
        </div>

        <template #actions>
            <div :class="multiLine ? 'ow-snackbar__actions ow-snackbar__actions--multi-line' : 'ow-snackbar__actions'"
                 class="ow-snackbar__actions">
                <slot name="actions" />
                <v-icon v-if="!multiLine"
                        class="ow-snackbar__actions--close-btn"
                        data-test="ow-snackbar-close-btn"
                        data-track="Close snackbar"
                        @click="isVisible=false">
                    $close
                </v-icon>
            </div>
        </template>
    </v-snackbar>
</template>

<script lang="ts"
        setup>

    import { computed } from 'vue'
    import { useStore } from 'vuex'

    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'

    const props = defineProps({
        // Should use v-model and not this prop directly. See docs in Storybook for more details.
        modelValue: {
            type: Boolean,
            required: false,
        },
        timeout: {
            type: Number,
            required: false,
            default: -1,
        },
        multiLine: {
            type: Boolean,
            required: false,
        },
    })

    const emit = defineEmits<{
        (event: 'update:modelValue', value: boolean): void
    }>()

    const store = useStore()
    const isMatterLinkShareUser = computed(() => store.getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`])

    const isVisible = computed({
        // This is so that v-model can be used on the component
        get(): boolean {
            if (isMatterLinkShareUser.value) {
                // If matter link share user, never show a snackbar.
                return false
            }

            return props.modelValue
        },
        set(val: boolean) {
            emit('update:modelValue', val)
        },
    })
</script>

<style lang="scss">
    @import './ow-snackbar.scss';
</style>
