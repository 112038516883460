<template>
    <div class="searches-draft-basket">
        <ow-loading-skeleton v-if="!isLoaded" />
        <div v-else>
            <div class="d-flex flex-row justify-space-between searches-draft-basket__header">
                <ow-icon-ow-logo height="60"
                                 width="71" />
                <div class="d-flex flex-row gap-4 searches-draft-basket__header__right">
                    <div class="searches-draft-basket__map">
                        <matter-searches-boundary-preview :title-numbers="order.associatedTitleNumbers"
                                                          :map-options="mapOptions"
                                                          :geo-json="order.geoJson"
                                                          width="240px"
                                                          height="184px" />
                    </div>
                    <div>
                        <matter-searches-draft-header-detail title="Title Number"
                                                             :value="order.titleNumber" />
                        <matter-searches-draft-header-detail title="Provider"
                                                             :value="order.providerName" />
                        <matter-searches-draft-header-detail title="Matter Name"
                                                             :value="order.matterDetails.matterName" />
                        <matter-searches-draft-header-detail v-if="!isNullOrWhitespace(order.matterDetails.clientCode)"
                                                             title="Client Code"
                                                             :value="order.matterDetails.clientCode" />
                        <matter-searches-draft-header-detail v-if="!isNullOrWhitespace(order.matterDetails.matterCode)"
                                                             title="Matter Code"
                                                             :value="order.matterDetails.matterCode" />
                        <matter-searches-draft-header-detail title="Date"
                                                             :value="todaysDate" />
                    </div>
                </div>
            </div>
            <p class="body-highlight">
                {{ order.usersName }}
            </p>
            <p class="body-copy">
                Searches draft order
            </p>
            <p class="body-copy">
                {{ order.address }}
            </p>
            <matter-searches-draft-details-table class="searches-draft-basket__table"
                                                 :store="draftOrderDetailsStore" />
            <div class="d-flex justify-end align-end searches-draft-basket__total">
                <p class="body-highlight">
                    Total: {{ totalCost }}
                </p>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import {Store} from "@bryntum/grid"
    import {computed,
            onMounted,
            reactive,
            ref,
            watch} from "vue"
    import {useStore} from "vuex"

    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import MatterSearchesBoundaryPreview from "@/components/matter-searches/matter-searches-boundary-preview.vue"
    import MatterSearchesDraftDetailsTable from "@/components/matter-searches/matter-searches-draft-details-table.vue"
    import MatterSearchesDraftHeaderDetail from "@/components/matter-searches/matter-searches-draft-header-detail.vue"
    import {useMapOptions} from "@/composables/use-map-options"
    import {
        ISearchDraftDetails,
        SEARCHES_FETCH_DRAFT_ORDER_DETAILS,
        SEARCHES_GET_DRAFT_ORDER_DETAILS,
    } from "@/store/modules/searches/types"
    import {format} from "@/utils/date-utils"
    import {isNullOrWhitespace} from "@/utils/string-utils"

    const store = useStore()
    const mapOptions = useMapOptions()
    interface Props {
        draftOrderId: string
    }
    const props = defineProps<Props>()

    const isLoaded = ref<Boolean>(false)

    const todaysDate = computed<string>(() => {
        const date = new Date().toJSON().slice(0,10)
        return format(date)
    })
    const order = computed<ISearchDraftDetails | undefined>(() => store.getters[SEARCHES_GET_DRAFT_ORDER_DETAILS])
    const totalCost = computed<string | undefined>(() => {
        return new Intl.NumberFormat(
            'en-GB',
            { style: 'currency', currency: 'GBP' }).format(order.value.totalGrossFee)
    })

    const draftOrderDetailsStore = reactive(new Store({
        data: order.value?.draftOrderItems || [],
    }))

    onMounted(async () => {
        await store.dispatch(SEARCHES_FETCH_DRAFT_ORDER_DETAILS, {
            id: props.draftOrderId,
        })
        isLoaded.value = true
    })

    watch(() => order.value, () => {
        draftOrderDetailsStore.data = order.value?.draftOrderItems || []
    }, {
        immediate: true,
    })
/*
* RUNNING LOCALLY:
* ngrok http --domain=witness.ngrok.io https://localhost:44366 <--- makes the web app public
* docker run --rm -p 3000:3000 -e "MAX_CONCURRENT_SESSIONS=1" -e "PREBOOT_CHROME=true" -e "KEEP_ALIVE=true" browserless/chrome:latest
* */

</script>

<style scoped lang="scss">
@import 'searches-draft-basket.scss';
</style>
