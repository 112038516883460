<template>
    <div :class="{
             'large': !small,
             small,
         }"
         class="ow-textfield">
        <label v-if="label"
               :class="{ 'accents-small': small }"
               class="caption-regular ow-textfield__label"
               for="ow-textfield__input--textfield">
            {{ label }}
        </label>
        <div :class="{ borderless }"
             class="ow-textfield__input"
             @click="handleClick">
            <div v-if="hasIconPrefix"
                 class="ow-textfield__input--iconPrefix"
                 @click="onEventHandler(events.iconPrefixClick)">
                <slot name="iconPrefix" />
            </div>

            <input id="ow-textfield__input--textfield"
                   ref="content"
                   v-model="localValue"
                   :class="{ 'caption-regular': small }"
                   :maxlength="maxLength"
                   :placeholder="placeholder"
                   :readonly="readOnly"
                   :type="type"
                   :min="min"
                   :max="max"
                   :step="step"
                   :pattern="pattern"
                   :data-test="dataTestId"
                   :data-track="dataTrackId"
                   aria-label="content"
                   class="body-regular"
                   @blur="onEventHandler(events.blur, $event)"
                   @focus="onEventHandler(events.focus, $event)"
                   @keyup.enter="$emit('enter')"
                   @keyup.esc="blur" />

            <div v-if="clearable && localValue"
                 class="ow-textfield__input--iconClear"
                 @click="handleClear">
                <v-icon>$clear</v-icon>
            </div>

            <div v-if="hasIconSuffix"
                 class="ow-textfield__input--iconSuffix"
                 @click="onEventHandler(events.iconSuffixClick, $event)">
                <slot name="iconSuffix" />
            </div>
        </div>
        <div v-if="hasHintText && !localValue"
             class="accents-small ow-textfield__hint">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
    import { useSlots } from 'vue'

    export default {
        name: 'OwTextfield',

        props: {
            modelValue: {
                type: [String, Number],
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            small: {
                type: Boolean,
                required: false,
            },
            clearable: {
                type: Boolean,
                required: false,
            },
            readOnly: {
                type: Boolean,
                required: false,
            },
            borderless: {
                type: Boolean,
                required: false,
            },
            maxLength: {
                type: Number,
                required: false,
            },
            type: {
                type: String,
                required: false,
                default: 'text',
            },
            min: {
                type: Number,
                required: false,
            },
            max: {
                type: Number,
                required: false,
            },
            step: {
                type: Number,
                required: false,
            },
            pattern: {
                type: String,
                required: false,
            },
            dataTestId: {
                type: String,
                required: false,
            },
            dataTrackId: {
                type: String,
                required: false,
            },
        },

        emits: [
            'focus',
            'blur',
            'enter',
            'click',
            'click:clear',
            'click:prefixIcon',
            'click:suffixIcon',
            'update:modelValue',
        ],

        data() {
            return {
                events: {
                    focus: 'focus',
                    blur: 'blur',
                    click: 'click',
                    clearClick: 'click:clear',
                    iconPrefixClick: 'click:prefixIcon',
                    iconSuffixClick: 'click:suffixIcon',
                },
                slots: useSlots(),
            }
        },

        computed: {
            localValue: {
                get(): string {
                    return this.modelValue?.toString()
                },
                set(newValue: string): void {
                    this.$emit('update:modelValue', newValue)
                },
            },

            hasHintText(): boolean {
                return Boolean(this.slots.default)
            },

            hasIconPrefix(): boolean {
                return Boolean(this.slots.iconPrefix)
            },

            hasIconSuffix(): boolean {
                return Boolean(this.slots.iconSuffix)
            },
        },

        methods: {
            onEventHandler(evtName: string, evt: any): void {
                this.$emit(evtName, evt)
            },

            focus() {
                this.$refs.content?.focus()
            },

            blur() {
                this.$refs.content?.blur()
            },

            handleClick(): void {
                this.onEventHandler(this.events.click)
                this.focus()
            },

            handleClear(): void {
                this.onEventHandler(this.events.clearClick)
                this.localValue = ''
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-textfield.scss';
</style>
