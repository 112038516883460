<template>
    <v-container class="orgChargesPage">
        <div class="d-flex flex-row orgChargesPage__table-header">
            <p>Disbursable Charges incurred through title plan and register requests</p>
            <v-spacer />
            <div>
                <v-btn :disabled="loading"
                       :loading="loading"
                       class="hidden-sm-and-down download-button"
                       color="primary"
                       data-track="ACCOUNT - Download organisation charges"
                       size="large"
                       @click="download()">
                    <v-icon start>
                        $cloud-download
                    </v-icon>
                    Download
                </v-btn>
                <v-btn :disabled="loading"
                       :loading="loading"
                       class="hidden-md-and-up download-button"
                       color="primary"
                       data-track="ACCOUNT - Download organisation charges"
                       icon
                       size="small"
                       @click="download()">
                    <v-icon start>
                        $cloud-download
                    </v-icon>
                </v-btn>
                <v-btn :disabled="loading"
                       :loading="loading"
                       class="hidden-sm-and-down"
                       color="primary"
                       size="large"
                       data-track="ACCOUNT - Refresh organisation charges data"
                       @click="getCharges()">
                    <v-icon start>
                        $refresh
                    </v-icon>
                    Refresh
                </v-btn>
                <v-btn :disabled="loading"
                       :loading="loading"
                       class="hidden-md-and-up"
                       color="primary"
                       data-track="ACCOUNT - Refresh organisation charges data"
                       icon
                       size="small"
                       @click="getCharges()">
                    <v-icon>$refresh</v-icon>
                </v-btn>
            </div>
        </div>
        <ow-data-grid :allow-selection="false"
                      :headers="headers"
                      :is-loading="loading"
                      :items="charges"
                      :sort-by="sortBy"
                      class="mt-4 charges-table"
                      data-test="charges-list-table"
                      has-pagination
                      hover
                      item-key="id">
            <template #[`item.when`]="{ item }">
                {{ getTimestampText(item.when) }}
            </template>
            <template #[`item.matter`]="{ item }">
                <span v-if="item.matterCode != null">{{ item.matterCode }} - </span>
                <span v-if="item.matterName != null">{{ item.matterName }}</span>

                <i v-if="item.matterName == null && item.matterCode == null">
                    Temporary matter
                </i>
            </template>
        </ow-data-grid>
    </v-container>
</template>
<script lang="ts" setup>
    import {DefaultUniform} from "ol/webgl/Helper"
    import {
        onMounted,
        ref,
    } from "vue"

    import OrganisationApi from '@/api/organisations.api'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import useDates from '@/composables/use-dates'
    import {exportAsCsv} from '@/utils/csv-utils'
    import TIME = DefaultUniform.TIME;

    const loading = ref<boolean>(false)
    const charges = ref<Array<any>>([])
    const {formatDateTime} = useDates()

    const headers = [
        {
            title: 'Date & Time (UTC)',
            key: 'when',
            align: 'left',
            width: '165px',
        }, {
            title: 'Client Code',
            key: 'clientCode',
            align: 'left',
        }, {
            title: 'Matter',
            key: 'matter',
            align: 'left',
        }, {
            title: 'User',
            key: 'userName',
            align: 'left',
            width: '200px',
        }, {
            title: 'Type',
            key: 'type',
            align: 'left',
        }, {
            title: 'Title',
            key: 'associatedTitle',
            align: 'left',
        }, {
            title: 'Amount (£)',
            key: 'amount',
            align: 'left',
            sortable: false,
        }]

    const sortBy = ref([{key: 'when', order: 'desc'}])

    onMounted(() => {
        getCharges()
    })

    const getCharges = async () => {
        loading.value = true

        const response = await OrganisationApi.getCharges({
            to: null,
            from: null,
        })

        if (response.ok) {
            charges.value = response.data
        }

        loading.value = false
    }

    const getTimestampText = (timestamp: string): string => {
        if (timestamp) {
            if (timestamp === '0001-01-01T00:00:00') {
                return 'Unknown'
            } else {
                return formatDateTime(new Date(timestamp))
            }
        } else {
            return ''
        }
    }

    const download = () => {
        const self = this

        const rows = [[
            'Timestamp (UTC)',
            'Client Code',
            'Matter Code',
            'Matter',
            'User',
            'Type',
            'Title',
            'Amount',
        ]]

        charges.value.forEach((charge) => {
            rows.push([
                getTimestampText(charge.when),
                charge.clientCode,
                charge.matterCode,
                charge.matterName,
                charge.userName,
                charge.type,
                charge.associatedTitle,
                charge.amount,
            ])
        })

        exportAsCsv(rows, 'charges.csv')
    }
</script>
<style lang="scss"
       scoped>
@import './organisation-charges'
</style>
