import {
    CheckColumnConfig,
    ChipViewConfig,
    Model,
    TemplateColumnConfig,
    WidgetColumnConfig,
} from '@bryntum/grid'
import { getHazardStyle } from '../util/hazard-mapping'
import ProductItemModel from '../models/ProductItemModel'
import { useI18n } from 'vue-i18n'
import { HazardsColumn } from '.'

export const useColumns = () => {
    const { t } = useI18n()
    const CheckboxColumn = ({
        field: 'selected',
        width: 75,
        type: 'check',
    } as Partial<CheckColumnConfig>)

    const CategoryColumn = ({
        field: 'description',
        text: t('searches.columns.name') as string,
        flex: 1,
        type: 'template',
        // tooltipRenderer(renderData) {
        //     const productItem = renderData.record as ProductItemModel
        //     return `<div class="caption-regular">${ productItem.description } - ${ productItem.productSupplierType }</div>`
        // },
        template: ({ record }: { record: Model }) => {
            const productItem = record as ProductItemModel
            const productDetail = `
            <div class="d-flex flex-row">
                <span class="product-icon mdi mdi-information-outline mr-2 product-icon"></span>
                <div class="product-column__container">
                        <span class="body-highlight product-column" title="${ productItem.description }">${ productItem.description }</span>
                        <div class="caption-regular" title="${ productItem.productSupplierType }">${ productItem.productSupplierType }</div>
                    </div>
                </div>
                `

            return `
                <div class='d-flex flex-column product-column__container'>
                    ${ productDetail ? `<div>${ productDetail }</div>` : '' }
                </div>
            `
        },
    }) as Partial<TemplateColumnConfig>

    return {
        gridColumns: [
            CheckboxColumn,
            CategoryColumn,
            HazardsColumn,
        ],
    }
}
