<template>
    <div class="owner-result-item">
        <div v-if="show"
             :title="owner.text"
             class="body-copy--greyout owner-result-item__owner-name">
            <v-checkbox v-model="isChecked"
                        :data-test="`owner-results-item-checkbox-${ owner.sort }`"
                        :label="owner.text"
                        :ripple="false"
                        density="compact"
                        color="primary"
                        data-track="SEARCH - Company result checkbox"
                        hide-details />
        </div>
        <div v-if="show"
             class="body-copy--greyout owner-result-item__company-number">
            <a v-for="companyRegistrationNumber in owner.companyRegistrationNumbers"
               :key="companyRegistrationNumber"
               :href="companiesHouseLink(companyRegistrationNumber)"
               class="owner-result-item__number"
               data-track="SEARCH - Companies house link"
               target="_blank"
               title="Search on Companies House">
                {{ getCompaniesHouseText(companyRegistrationNumber) }}
            </a>
        </div>
        <div v-if="show"
             class="body-copy--greyout owner-result-item__title-count">
            {{ titleCountText }}
        </div>
    </div>
</template>

<script>
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'
    import {
        isNullOrWhitespace,
        pad,
    } from '@/utils/string-utils'

    export default {
        name: 'TabOwnerResultsItem',

        props: {
            owner: {
                type: Object,
                required: true,
            },
        },

        emits: [
            'selection-changed',
        ],

        data() {
            return {
                element: null, // A reference to the component element determined on mounted().
                scrollVisibility: false, // Used to determine whether an element should be visible based on it's scroll position.
                scrollElement: null, // The parent scrollable element.
                scrollObserver: null, // The Intersection Observer used to detect visibility changes.
            }
        },

        computed: {
            titleCountText() {
                if (this.owner.titleCount === 1) {
                    return `${ this.owner.titleCount } title`
                }
                return `${ this.owner.titleCount } titles`
            },

            isChecked: {
                get() {
                    return this.owner.selected
                },
                set(val) {
                    this.$emit('selection-changed', {
                        owner: this.owner,
                        selected: val,
                    })
                },
            },

            show() {
                return this.owner !== null && this.scrollVisibility
            },
        },

        watch: {},

        mounted() {
            this.$nextTick(() => {
                this.initialiseIntersectionObserver()
            })
        },

        methods: {
            companiesHouseLink(regNumber) {
                if (!isNullOrWhitespace(regNumber)) {
                    return getCompaniesHouseLinkFromCompanyRegistrationNumber(regNumber)
                }
            },

            getCompaniesHouseText(companyRegistrationNumber) {
                if (!isNullOrWhitespace(companyRegistrationNumber)) {
                    return pad(companyRegistrationNumber, 8, '0')
                }
                return ''
            },

            initialiseIntersectionObserver() {
                // Listen for changes to the position of this component within it's parent scrollable element
                if (this.scrollObserver === null) {
                    this.element = this.$el
                    this.scrollElement = document.querySelector('.owner-results')
                    if (this.scrollElement) {
                        const options = {
                            root: this.scrollElement,
                            rootMargin: '100px',
                            threshold: 1.0,
                        }

                        this.scrollObserver = new IntersectionObserver((entries) => {
                            entries.forEach(entry => {
                                if (entry.target.dataset.owner === this.owner.text) {
                                    this.scrollVisibility = entry.intersectionRatio > 0
                                }
                            })
                        }, options)
                        this.scrollObserver.observe(this.element)
                    }
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './tab-owner-results-item';
</style>
