<template>
    <span v-dompurify-html="enrichedContent"
          class="ow-text-highlight" />
</template>

<script lang="ts">
    const EVENTS = {
        MATCH_COUNT_CHANGED: 'match-count-changed',
    }

    export const OW_TEXT_HIGHLIGHT_SELECTOR = 'href="#ow-text-highlight--mark"'

    export default {
        name: 'OwTextHighlight',
        props: {
            queries: {
                type: Array,
                required: false,
                default: () => [],
            },
        },
        emits: Object.values(EVENTS),
        data() {
            return {
                totalMatchCount: 0,
            }
        },
        computed: {
            filteredQueries() {
                return this.queries?.filter((v: string) => v?.length)?.map(this.escapeRegExp)
            },
            content: {
                cache: false,
                get() {
                    const defaultSlot = this.$slots.default
                    if (!defaultSlot) {
                        return null
                    }

                    const vNodes = defaultSlot()
                    return vNodes.map(vNode => vNode.children).join('')
                },
                set() {
                    // do nothing
                },
            },
            enrichedContent() {
                if (!this.content || !this.filteredQueries.length) {
                    return this.content
                }

                const re = new RegExp(`(${ this.filteredQueries.join('|') })`, 'gi')

                let matchCount = 0
                const result = this.content.replace(re, (match: string) => {
                    matchCount++
                    return `<mark ${ OW_TEXT_HIGHLIGHT_SELECTOR }>${ match }</mark>`
                })

                this.totalMatchCount = matchCount

                return result
            },
        },
        watch: {
            totalMatchCount(value: number) {
                this.$emit(EVENTS.MATCH_COUNT_CHANGED, value)
            },
        },
        methods: {
            escapeRegExp(string: string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-text-highlight.scss';
</style>

