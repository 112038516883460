<template>
    <ow-card class="title-select-card"
             has-outline>
        <h4>Select your titles</h4>
        <p class="body-copy--greyout">
            Enter title numbers below (separated by a space) and click the “Validate Titles” button to validate them.
            You will be able to add available and previously ordered titles to your report.
        </p>
        <!--        <v-tabs v-model="tab"-->
        <!--                fixed-tabs>-->
        <!--            <v-tab href="#titleSelection">-->
        <!--                Title selection-->
        <!--            </v-tab>-->
        <!--            <v-tab href="#existingMatter">-->
        <!--                Existing matter-->
        <!--            </v-tab>-->
        <!--        </v-tabs>-->
        <!--        <v-window v-model="tab"-->
        <!--                      class="title-select-card__selection-items">-->
        <!--            <v-window-item class="title-select-card__title-selection-tab"-->
        <!--                        data-test="title-selection-tab"-->
        <!--                        value="titleSelection">-->
        <v-textarea v-model="rawTitleNumbers"
                    :error-messages="errorMessages"
                    data-test="title-numbers-input"
                    data-track="REPORTING - Title number input for validation"
                    label="Title numbers"
                    messages="Please separate title numbers by a space"
                    variant="outlined" />
        <ow-button :disabled="isLoading || rawTitleNumbers.length === 0"
                   :is-loading="isLoading"
                   data-test="validate-button"
                   data-track="REPORTING - Validate titles"
                   @click="validateTitleNumbers">
            Validate Titles
        </ow-button>
        <title-select-table v-if="hasValidTitles"
                            :document-availability="availabilityMap"
                            :items="tableItems"
                            :loading="isLoading"
                            :preselected-items="preselectedTitles"
                            data-test="title-select-table"
                            @items-selected="selectTitles" />
        <!--            </v-window-item>-->
        <!--            <v-window-item class="title-select-card__existing-matter-tab"-->
        <!--                        data-test="existing-matter-tab"-->
        <!--                        value="existingMatter">-->
        <!--                <template v-if="selectedMatter">-->
        <!--                    <ow-card class="title-select-card__selected-matter"-->
        <!--                             has-outline>-->
        <!--                        {{ selectedMatter.name }}-->
        <!--                        <ow-button text-->
        <!--                                   @click="resetSelectedMatter">-->
        <!--                            Change matter-->
        <!--                        </ow-button>-->
        <!--                    </ow-card>-->
        <!--                    <title-select-table :document-availability="availabilityMap"-->
        <!--                                        :items="tableItems"-->
        <!--                                        :loading="isLoading"-->
        <!--                                        @items-selected="selectTitles" />-->
        <!--                </template>-->
        <!--                <v-list v-else-->
        <!--                        class="title-select-card__available-matter-list"-->
        <!--                        dense>-->
        <!--                    <v-list-item-group v-model="selectedMatter">-->
        <!--                        <v-list-item v-for="matter in matters"-->
        <!--                                     :key="matter.id"-->
        <!--                                     :value="matter"-->
        <!--                                     data-test="title-select-matter">-->
        <!--                            <v-list-item-content>-->
        <!--                                <ow-card has-outline>-->
        <!--                                    {{ matter.name }}-->
        <!--                                </ow-card>-->
        <!--                            </v-list-item-content>-->
        <!--                        </v-list-item>-->
        <!--                    </v-list-item-group>-->
        <!--                </v-list>-->
        <!--            </v-window-item>-->
        <!--        </v-window>-->
    </ow-card>
</template>

<script lang="ts">
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import CopiesAvailabilityApi from '@/api/copies-availability.api'
    import MatterApi from '@/api/matter.api'
    import TitleApi from '@/api/title.api'
    import OwButton from '@/components/core/ow-button.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import TitleSelectTable from '@/components/reporting/title-select-table.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import { IDataGridItem } from '@/interfaces/core-components/data-grid-item.interface'
    import { IHmlrDocumentAvailabilityCode } from '@/interfaces/document-availability.interface'
    import { IDocumentValidation } from '@/interfaces/document-validation.interface'
    import { IReportsState } from '@/interfaces/store/reports/reports-state.interface'
    import { ITitleInformation } from '@/interfaces/title-information.interface'
    import { MUTATE_SELECTED_TITLES } from '@/store/modules/reports/types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    type TitleSelectCardType = {
        tab: ('titleSelection' | 'existingMatter'),
        selectedMatter: any,
        rawTitleNumbers: string,
        validTitleNumbers: string[],
        invalidTitleNumbers: string[],
        validTitles: ITitleInformation[],
        preselectedTitles: ITitleInformation[],
        isLoading: boolean,
        availabilityMap: {
            [titleNumber: string]: IDocumentValidation,
        },
    }

    export default {
        name: 'TitleSelectCard',

        components: {
            OwButton,
            OwCard,
            TitleSelectTable,
        },

        props: {
            // eslint-disable-next-line vue/no-unused-properties
            matters: {
                type: Array,
                default: () => [],
                required: false,
            },
        },

        emits: [
            'items-selected',
        ],

        data() {
            return {
                tab: 'titleSelection',
                selectedMatter: null,
                rawTitleNumbers: '',
                validTitleNumbers: [],
                invalidTitleNumbers: [],
                validTitles: [],
                preselectedTitles: [],
                isLoading: false,
                availabilityMap: {},
            } as TitleSelectCardType
        },

        computed: {
            ...mapState('reports', {
                selectedTitles: (state: IReportsState) => state.selectedTitles,
            }),

            tableItems() {
                return this.validTitles.map(title => ({
                    ...title,
                    address: title.titleInformation?.addresses[0]?.address,
                    isSelectable: this.isTitleNumberSelectable(title.titleNumber),
                    isOrdered: this.availabilityMap[title.titleNumber]?.hasBeenOrdered,
                })) as IDataGridItem[]
            },

            errorMessages() {
                return this.invalidTitleNumbers.length === 0
                    ? ''
                    : `Titles cannot be identified with HMLR: ${ this.invalidTitleNumbers.join(', ') }`
            },

            hasValidTitles(): boolean {
                return !isNullOrEmpty(this.validTitles)
            },
        },

        watch: {
            tab() {
                this.abortAllRequests()
                this.selectedMatter = null
                this.clearTitleSelection()
            },

            selectedTitles: {
                async handler(val) {
                    this.rawTitleNumbers = val?.join(' ') ?? ''
                },
                immediate: true,
            },

            async selectedMatter(val) {
                this.clearTitleSelection()

                if (val) {
                    this.isLoading = true

                    const titles = await MatterApi.getTitleAddressesForMatter(this.selectedMatter.id)
                    this.validTitleNumbers = titles.map(title => title.titleNumber)

                    this.isLoading = false
                } else {
                    this.validTitles = []
                }
            },

            async validTitleNumbers(titleNumbers) {
                this.clearTitleSelection()

                if (isNullOrEmpty(titleNumbers)) {
                    this.validTitles = []
                } else {
                    this.isLoading = true

                    try {
                        let titleInformation: ITitleInformation[] = await TitleApi.getByTitleNumbers(titleNumbers, null)

                        if (isNullOrEmpty(titleInformation)) {
                            this.isLoading = false
                            return
                        }

                        titleInformation = titleInformation.filter(title => title.titleNumber)
                        this.invalidTitleNumbers = titleNumbers.filter(titleNumber => !titleInformation.find(title => title.titleNumber === titleNumber))
                        this.validTitles = titleInformation
                    } catch (e) {
                        // TODO: (DOBO) Handle TitleApi errors
                        console.error(e)
                    }

                    this.isLoading = false
                }
            },

            async validTitles(titleInformation) {
                if (isNullOrEmpty(titleInformation)) {
                    this.availabilityMap = {}
                } else {
                    try {
                        const titleNumbers = titleInformation.map(title => title.titleNumber)
                        const titleAvailabilities = await CopiesAvailabilityApi.officialCopiesAvailabilities(titleNumbers, null)

                        this.availabilityMap = titleAvailabilities.reduce((acc: { [titleNumber: string]: IHmlrDocumentAvailabilityCode }, cur: any) => ({
                            ...acc,
                            [cur.titleNumber]: {
                                availability: cur.titleRegister.hmlrAvailabilityCode,
                                hasBeenOrdered: cur.titleRegister.orderStatus === DocumentOrderStatus.DERIVED,
                            },
                        }), {} as { [key: string]: string })

                        titleNumbers.forEach(titleNumber => {
                            this.availabilityMap[titleNumber] = this.availabilityMap[titleNumber] || {
                                availability: HmlrDocumentAvailabilityCode.Unknown,
                                hasBeenOrdered: false,
                            }
                        })
                    } catch (e) {
                        // TODO: (DOBO) Handle CopiesAvailabilityApi errors
                        console.error(e)
                    }
                }
            },
        },

        beforeUnmount() {
            this.abortAllRequests()
            this.clearTitleSelection()
        },

        methods: {
            ...mapMutations('reports', {
                setSelectedTitles: MUTATE_SELECTED_TITLES,
            }),

            // eslint-disable-next-line vue/no-unused-properties
            resetSelectedMatter() {
                this.abortAllRequests()

                this.selectedMatter = null
            },

            async validateTitleNumbers() {
                this.abortAllRequests()

                this.validTitleNumbers = Array.from(new Set(this.rawTitleNumbers.split(/\W+/)))
                    .filter(titleNumber => titleNumber)
                    .map((titleNumber: string) => titleNumber.toUpperCase())
            },

            selectTitles(titleNumbers: string[]) {
                const titles = this.tableItems.filter(ti => titleNumbers.includes(ti.titleNumber))
                const titlesWithAvailability = titles.map(title => {
                    return {
                        ...title,
                        hmlrAvailabilityCode: this.availabilityMap[title.titleNumber]?.availability,
                    }
                })
                this.$emit('items-selected', titlesWithAvailability)
            },

            clearTitleSelection() {
                this.selectTitles([])
                this.preselectedTitles = []
                this.setSelectedTitles([])
            },

            abortAllRequests() {
                MatterApi.abortAllRequests()
                TitleApi.abortAllRequests()
                CopiesAvailabilityApi.abortAllRequests()
            },

            isTitleNumberSelectable(titleNumber: string): boolean {
                const availabilityObj: IDocumentValidation = this.availabilityMap[titleNumber]
                if (availabilityObj) {
                    return availabilityObj.availability === HmlrDocumentAvailabilityCode.Available ||
                        availabilityObj.hasBeenOrdered
                }
                return false
            },
        },
    }
</script>

<style lang="scss">
    @import 'title-select-card.scss';
</style>
