export const COPILOT_DOC_LIMIT = 24
export const COPILOT_SUPPORTED_DOC_TYPES = [
    'Abstract',
    'Agreement',
    'Assent',
    'Assignment',
    'Charge',
    'Conveyance',
    'Deed',
    'Indenture',
    'Lease',
    'Licence',
    'Plan',
    'Sub-Charge',
    'Transfer',
    'Register',
    'Other',
    'Commonhold Community Statement',
    'Memorandum and Articles of Association',
    'Surrender of Development Rights',
    'Termination Document',
    'TitleRegister',    // Used with uploaded docs
    'TitlePlan',        // Used with uploaded docs
    'OfficialCopy',     // Used with uploaded docs
]
