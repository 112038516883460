import httpClient from '@/api/http-client'

export default class PlanningApi {
    static END_POINT = 'planning'

    /**
     * Nearby planning applications.
     * @param {Array} boundingBoxes Array of extents [[x1, y1, x2, y2]]
     * @param {String} forcePlanningDataProvider override default behaviours to force the use of the given planning data provider.
     * @returns {Promise<T | *>}
     */
    static search(boundingBoxes, forcePlanningDataProvider) {
        const uri = `${ this.END_POINT }/search`
        return httpClient
            .post(uri, { boundingBoxes, forcePlanningDataProvider })
    }
}
