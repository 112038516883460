import { IMatterHubState } from '@/store/modules/matter-hub/interfaces/matter-hub-state.interface'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state: IMatterHubState = {
    isSubscribed: false,
    hub: null,
    messages: [],
    error: null,
    currentlySubscribedToMatterId: null,
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
