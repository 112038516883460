import {
    StyleLike,
    StyleFunction,
} from 'ol/style/Style'
import { LayerNames } from '@/consts/map-layers'
import {
    Stroke,
    Style,
} from 'ol/style'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { FeatureLike } from 'ol/Feature'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { getOWStyleFromOLStyle } from '@/utils/map-utils'
import { SketchType } from '@/enums/sketches-enums'

import {
    IMapRolloverSectionItem,
} from '@/components/map-rollover/common/map-rollover-interfaces'

import i18n from '@/plugins/i18n'

const MaintenanceResponsibility = {
    MaintainableAtPublicExpense: 'Maintainable At Public Expense',
    NotMaintainedAtPublicExpense: 'Not Maintained At Public Expense',
    MaintenanceResponsibilityIsToAnotherHighwayAuthority: 'Maintenance Responsibility Is To Another Highway Authority',
    ProspectivelyMaintainableAtPublicExpense: 'Prospectively Maintainable At Public Expense',
    StreetOutsideScopeOfEToN: 'Street Outside Scope Of EToN',
    Unknown: 'Unknown',
}

export class OsHighwaysMaintenanceLayer extends BaseMvtDataLayer {
    constructor(params: BaseMvtDataLayerParams, settings: any) {
        super(params, settings)
        this.name = LayerNames.HighwaysMaintenance
        this.layerName = settings.highwayMaintenanceLayer
        this.attributionText = [settings.highwayMaintenanceNotice]
        this.rolloverOptions = {
            showPrimary: true,
            showExtended: true,
            sortOrder: 0,
            category: i18n.global.t(`map.rollover.${ LayerNames.HighwaysMaintenance }.category`),
            source: i18n.global.t(`map.rollover.${ LayerNames.HighwaysMaintenance }.source`),
            getStyle: (feature) => {
                return (this.style as StyleFunction)(feature, null) as Style
            },
            addItems: (features: FeatureLike[]): IMapRolloverSectionItem[] => {
                const result: IMapRolloverSectionItem[] = []
                features.forEach(feature => {
                    const properties = feature.getProperties() as {name: string, maintenanceresponsibility: string, maintenanceauthorityname: string}
                    const existing = result.some(x => x.id === `${ properties.maintenanceresponsibility }-${ properties.name }-${ properties.maintenanceauthorityname }`)
                    if (!existing) {
                    // @ts-ignore
                        const itemStyle = getOWStyleFromOLStyle(this.style(feature))
                        result.push({
                            id: `${ properties.maintenanceresponsibility }-${ properties.name }-${ properties.maintenanceauthorityname }`,
                            primary: properties.maintenanceresponsibility,
                            style: itemStyle,
                            extended: [
                                properties?.name
                                    ? {
                                        name: i18n.global.t(`map.rollover.${ LayerNames.HighwaysMaintenance }.extended.name`),
                                        value: properties?.name ?? '-',
                                    }
                                    : null,
                                properties?.maintenanceauthorityname
                                    ? {
                                        name: i18n.global.t(`map.rollover.${ LayerNames.HighwaysMaintenance }.extended.authority`),
                                        value: properties?.maintenanceauthorityname ?? '-',
                                    }
                                    : null].filter(x => x),
                        })
                    }
                })

                // sort by primary
                result.sort((a, b) => {
                    if (a.primary < b.primary) {
                        return -1
                    }
                    if (a.primary > b.primary) {
                        return 1
                    }
                    return 0
                })
                return result
            },
        }
        this.initialiseLayer()
    }

    public name: string = LayerNames.HighwaysMaintenance

    public style:StyleLike = (feature: FeatureLike) => {
        const properties = feature.getProperties()
        let strokeWidth = 5
        let zIndex = 300
        let colour = 'rgba(0, 200, 130, 2)'

        // Determine colour and zIndex
        switch (properties.maintenanceresponsibility) {
            case MaintenanceResponsibility.MaintainableAtPublicExpense:
                zIndex = 300
                strokeWidth = 9
                break
            case MaintenanceResponsibility.NotMaintainedAtPublicExpense:
                colour = 'rgba(150, 50, 50, 2)'
                zIndex = 309
                break
            case MaintenanceResponsibility.MaintenanceResponsibilityIsToAnotherHighwayAuthority:
                colour = 'rgba(25, 100, 160, 2)'
                zIndex = 308
                break
            case MaintenanceResponsibility.ProspectivelyMaintainableAtPublicExpense:
                colour = 'rgba(25, 100, 160, 2)'
                zIndex = 307
                break
            case MaintenanceResponsibility.StreetOutsideScopeOfEToN:
                colour = 'rgba(25, 100, 160, 2)'
                zIndex = 306
                break
            default:
                colour = 'rgba(100, 100, 100, 2)'
                zIndex = 306
        }

        // Style roll-over features differently.
        if (this.interactive && this.overFeatures
            .map(x => x.get('ogc_fid'))
            .includes(feature.get('ogc_fid'))) {
            strokeWidth += 2
            zIndex *= 2
        }

        return new Style({
            zIndex,
            stroke: new Stroke({
                color: colour,
                width: strokeWidth,
            }),
        })
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        const featuresInExtent = this.layer
            .getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))

        const result = []
        const uniqueValues = new Set(featuresInExtent.map(x => x.get('maintenanceresponsibility')))
        uniqueValues.forEach(x => {
            // @ts-ignore
            const style = getOWStyleFromOLStyle(this.style({ getProperties: () => ({ maintenanceresponsibility: x }) }))
            style.sketchType = SketchType.Line
            result.push({
                id: `${ this.name }-${ x }`,
                label: x,
                style,
            })
        })
        return result
    }
}
