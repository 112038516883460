import { pillMarkup } from '@/components/core/ow-grid/columns'
import './hazard-mapping.scss'
import { BoundaryStyle } from '@/models/styles/boundary-style'
import { HazardColourPalette } from '@/models/styles/hazard-colour-palette'

const palette = new HazardColourPalette()

export const HazardLabelMap: Record<string, string[]> = {
    Coal: ['coal'],
    HS2: ['hs2'],
    Crossrail: ['crossrail'],
    EnergyExploration: ['energy exploration'],
    WindFarms: ['wind farms', 'wind', 'windfarms'],
    SolarFarms: ['solar farms', 'solar', 'solarfarms'],
    RenewablePowerPlants: ['renewable power plants', 'renewable', 'renewablepowerplants'],
    LondonUnderground: ['london underground', 'underground', 'transport'],
    Flood: ['flood'],
    BathStone: ['bath stone', 'bath'],
    Metalliferous: ['metalliferous (tin)', 'metalliferous'],
    Kaolin: ['kaolin & ball clay', 'kaolin & ball'],
    Limestone: ['limestone'],
    Brine: ['brine'],
    GroundStability: ['ground stability'],
    NonCoalMining: ['non coal mining', 'non coal'],
    GroundMovement: ['ground movement'],
    Mineral: ['mineral'],
    Environmental: ['enviro', 'environmental'],
    Radon: ['radon'],
    Utilities: ['utilities'],
    Chancel: ['chancel'],
    Highways: ['highways'],
    Planning: ['planning'],
    HMLR: ['hmlr'],
    Other: ['other'],
    'Energy&Infrastructure': ['e&i', 'energy&infrastructure'],
    'Drainage&Water': ['drainage&water', 'drainage'],
    LocalAuthority: ['localauthority', 'local'],
}

export const getHazardStyle = (item: string) => {
    const hazard = item.toLowerCase()

    // get matching label from item text
    const label = Object.keys(HazardLabelMap).find(key => HazardLabelMap[key].includes(hazard))

    const paletteEntry = palette.getEntryForName(label)
    const background = paletteEntry?.fillColour ?? '#000000'
    const colour = paletteEntry?.labelColour ?? '#FFFFFF'
    return `background-color: ${ background }; color: ${ colour }`
}

export const renderHazards = (items: string[], options: {
    align?: 'left' | 'right'
    filter?: (item: string) => boolean
} = {
    align: 'left',
    filter: () => true,
}) => pillMarkup({
    mapPillClass: (item: string) =>
        'hazard',
    getPillStyle: (item: string) => {
        return getHazardStyle(item)
    },
    mapPillItems: () => items,
    align: options.align,
    filter: options.filter,
})
