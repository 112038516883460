<template>
    <section class="purchased-documents-table">
        <ow-data-grid :allow-selection="false"
                      :headers="headers"
                      :initial-items-per-page="Number.MAX_SAFE_INTEGER"
                      :items="documents"
                      :is-loading="isLoading"
                      :sort-by="sortBy"
                      calculate-widths
                      hover
                      item-key="documentId">
            <template #[`item`]="{ item }">
                <tr>
                    <td class="body-regular purchased-documents-table__item"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        <ow-radio :id="radioButtonId(item)"
                                  v-model="selected"
                                  data-test="purchased-document-table-selection"
                                  data-track="OVERLAY PLANS - Select document"
                                  name="rowSelector" />
                    </td>
                    <td class="body-regular purchased-documents-table__item"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        data-test="purchased-documents-table-document-name"
                        @click="onRowSelected(item)">
                        {{ getDocumentName(item) }}
                    </td>
                    <td class="body-regular purchased-documents-table__item"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.groupName ? item.groupName : '-' }}
                    </td>
                    <td class="body-regular purchased-documents-table__item"
                        data-test="documents-table-document-type"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.documentType }}
                    </td>
                    <td class="body-regular purchased-documents-table__item--title-no"
                        data-test="documents-table-title-number"
                        data-track="DOCS - Open title from documents table"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.titleNo }}
                    </td>
                    <td class="caption-regular purchased-documents-table__item"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.documentDate }}
                    </td>
                    <td class="hidden-sm-and-down accents-small purchased-documents-table__item"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        <span class="purchased-documents-table__item--status">
                            {{ item.showDownload ? 'Ordered' : '' }}
                        </span>
                    </td>
                    <td class="hidden-sm-and-down caption-regular purchased-documents-table__item"
                        :class="{ 'selected': item.documentId === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.documentDownloadDate }}
                    </td>
                </tr>
            </template>
        </ow-data-grid>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        WritableComputedRef,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwRadio from '@/components/core/ow-radio.vue'
    import { getPurchasedDocumentName } from '@/utils/document-utils'

    const { t } = useI18n()
    const selectedDocumentId = computed(() => props.selectedDocument?.documentId ?? 0)

    interface Props {
        documents: Array<any>,
        isCurrentMatterInactive?: boolean,
        isLoading?: boolean,
        selectedDocument?: any
    }

    const props = withDefaults(defineProps<Props>(), {
        isCurrentMatterInactive: false,
        isLoading: true,
        selectedDocument: null,
    })

    const emit = defineEmits<{
        (e: 'change', item: any)
    }>()

    const selected: WritableComputedRef<string> = computed({
        get(): string {
            return props?.selectedDocument?.documentId.toString() ?? ''
        },
        set(docId: string): void {
            const documentId = Number(docId)
            const selectedItem = props.documents.find((item: any) => item.documentId === documentId)
            emit('change', selectedItem)
        },
    })

    const headers = [
        {
            align: 'left',
            key: 'selection',
            width: '40',
        },
        {
            title: t('table.label.document'),
            align: 'left',
            key: 'document',
        },
        {
            title: t('table.label.group'),
            align: 'left',
            key: 'groupName',
        },
        {
            title: t('table.label.type'),
            align: 'left',
            key: 'documentType',
            width: '120',
        },
        {
            title: t('table.label.title'),
            align: 'left',
            key: 'titleNo',
            width: '120',
        },
        {
            title: t('table.label.documentDate'),
            align: 'left',
            key: 'documentDateSortable',
            width: '165',
        },
        {
            title: props.isCurrentMatterInactive ? '' : t('table.label.status'),
            align: 'left',
            key: 'showDownload',
            width: props.isCurrentMatterInactive ? '100' : '120',
        },
        {
            title: t('table.label.downloadDate'),
            align: 'left',
            key: 'documentDownloadDateSortable',
            width: '165',
        },
    ]

    const sortBy = ref([{key: 'fileName', order: 'asc'}])

    const onRowSelected = (item: any) => {
        selected.value = item.documentId.toString()
    }

    const radioButtonId = (item: any): string => {
        if (item) {
            return item?.documentId.toString()
        }
        return null
    }

    const getDocumentName = (document: any) => {
        document.document = getPurchasedDocumentName(document)
        return document.document
    }

</script>

<style lang="scss" scoped>
@import './purchased-documents-table';
</style>
