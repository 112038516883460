<template>
    <div class="title-details d-flex flex-column">
        <div v-if="selectedTitleNumber && isTitlePanelV2Enabled"
             class="title-details__panel-container flex-column">
            <title-panel class="title-details__panel"
                         :selected-title-number="selectedTitleNumber"
                         :is-loading="isLoading || isLookupTitleLoading"
                         is-full-screen
                         order-with-checkout
                         :is-ordering-allowed="false"
                         :matter-id="currentMatter.id"
                         :show-title-complexity="false"
                         @pin-title="toggleAddToMatterDialog(true)"
                         @clear-titles="onClearTitles"
                         @title-number-selected="goToTitle">
                <template #header-prefix>
                    <title-search-box class="title-details__search"
                                      show-single-result
                                      :has-shadow="false" />
                </template>
            </title-panel>
        </div>
        <div v-else-if="selectedTitleNumber"
             class="ow-page-container">
            <title-page :selected-title-number="selectedTitleNumber"
                        :is-loading="isLoading || isLookupTitleLoading"
                        @add-to-matter="toggleAddToMatterDialog(true)"
                        @title-number-selected="goToTitle" />
        </div>
    </div>
    <add-to-matter-dialog v-if="selectedTitleNumber"
                          v-model="isAddToMatterDialogVisible"
                          :title-number="selectedTitleNumber"
                          :matter="currentMatter"
                          @close-dialog="toggleAddToMatterDialog(false)" />
</template>

<script setup lang="ts">
    import { computed,
             onMounted,
             ref,
             watch } from 'vue'
    import { useRoute,
             useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import AddToMatterDialog from '@/components/search/add-to-matter-dialog.vue'
    import TitleSearchBox from "@/components/search/title-search-box.vue"
    import TitlePage from '@/components/title-panel/title-page/title-page.vue'
    import TitlePanel from '@/components/title-panel/v2/title-panel.vue'
    import {
        Route as AppRoute,
        Route    } from '@/enums/route.enum'
    import { checkFlag } from "@/feature-flags"
    import defaultTitleStatus from '@/store/modules/titles/default-title-status'
    import {
        TITLE_LOOKUP_TITLE,
        TITLE_MUTATE_SELECTED_TITLE,
        TITLE_MUTATE_SELECTED_TITLE_NUMBER,
    } from '@/store/modules/titles/types'

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const isAddToMatterDialogVisible = ref(false)

    const currentMatter = computed(() => store.state.matter.currentMatter)
    const selectedTitleNumber = computed(() => store.state.title.selectedTitleNumber)
    const isLoading = computed(() => store.state.title.selectedTitle.loading)
    const isLookupTitleLoading = computed(() => store.state.title.loadingState.lookupTitle)
    const isTitlePanelV2Enabled = computed(() => checkFlag('title-panel-v-2', false))

    const populateTitlePage = () => {
        const titleNumber = route.params.titleNumber
        if (selectedTitleNumber.value !== titleNumber) {
            store.dispatch(TITLE_LOOKUP_TITLE, titleNumber)
        }
    }

    const toggleAddToMatterDialog = (val: boolean) => {
        isAddToMatterDialogVisible.value = val
    }

    const goToTitle = (titleNumber: string) => {
        router.push({
            name: AppRoute.TitleDetails,
            params: {
                titleNumber,
            },
        })
    }

    watch(() => route, (to, from) => {
        const isNewSearch = Boolean(from.name === AppRoute.Search && to.name === AppRoute.TitleDetails)
        const isClickBetweenTabs = Boolean(from.name === AppRoute.TitleDetails && to.name === AppRoute.TitleDetails)
        const isClickAwayFromTitleDetails = Boolean(from.name === AppRoute.TitleDetails && to.name !== AppRoute.TitleDetails && to.name !== AppRoute.DocumentViewer)

        if (isNewSearch || isClickBetweenTabs) {
            populateTitlePage()
        }

        if (isClickAwayFromTitleDetails) {
            store.commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, null)
            store.commit(TITLE_MUTATE_SELECTED_TITLE, defaultTitleStatus)
        }
    })

    onMounted(() => {
        populateTitlePage()
    })

    const onClearTitles = async (): Promise<void> => {
        const matterIdParam = route.params?.matterId

        const shouldNavigateToReferrer = (): boolean => {
            const referrerURL = new URL(document.referrer)
            return document.referrer &&
                referrerURL.origin === window.location.origin &&
                referrerURL.href !== window.location.href
        }

        if (shouldNavigateToReferrer()) {
            await router.push(document.referrer)
        } else if (matterIdParam) {
            await router.push({
                name: Route.MatterMap,
                params: { matterId: matterIdParam },
            })
        } else {
            await router.push({
                name: AppRoute.Homepage,
            })
        }
    }
</script>

<style lang="scss">
@import './title-details.scss';
</style>
