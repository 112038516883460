import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import Geometry from 'ol/geom/Geometry'
import { isNullOrEmpty } from '@/utils/array-utils'
import MapApi from '@/api/map.api'
import olMap from 'ol/Map'
import { Style } from 'ol/style'
import { TitleBoundaryLayerSettings } from '@/store/modules/map/layers/title-boundary-layer/settings'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { getOLStyleForOWStyleDefinition,
    layerEquals } from '@/utils/map-utils'
import { ISnapshotPageLayout } from '@/components/snapshots/common/snapshot-interfaces'

export type BoundaryLayerInitialisationParams = {
    getTitlesDataFn: () => any
    onTitleBoundaryClickFn: (titleNumber: string[]) => void
    onTitleBoundariesLoadedFn?: (titleNumbers: string[]) => void
    onLoadingTitleBoundaryFn?: (toLoad: number, remaining: number) => void
    overFeaturesClassName?: string
    interactive: boolean
}

/**
 * The Boundary layer is used to show the full detailed boundary of titles. It is expensive to load all titles boundaries at all times for
 * larger matters, so this class handles loading them on demand. It is orchestrated by the layer-group.ts class.
 */
export class BoundaryLayer {
    public map: olMap
    public readonly layer: VectorLayer<VectorSource<Feature<Geometry>>>
    public titleNumbersPendingBoundaryLoad: Set<string> = new Set<string>()
    public loadedBoundariesForTitleNumbers: Set<string> = new Set<string>()
    public totalTitleNumbersToLoad: number
    public totalTitleNumbersRemaining: number
    private highlightedTitleNumber: string
    private readonly styleCache: Map<any, Style | Style[]>
    private getTitlesDataFn: () => any
    private readonly onTitleBoundaryClickFn: (titleNumbers: string[]) => void
    private readonly onTitleBoundariesLoadedFn: (titleNumbers: string[]) => void
    private readonly onLoadingTitleBoundaryFn: (toLoad: number, remaining: number) => void
    public hasLoadedSomeTitleBoundaries = false
    private clickedTitleNumbers: string[] = []
    private interactive: boolean
    private readonly overFeaturesClassName: string = 'ow-over-layer-matter-title-boundaries'
    private snapshotPageLayout: ISnapshotPageLayout
    public highlightBoundaryOnHover = true

    constructor(params: BoundaryLayerInitialisationParams) {
        this.interactive = params.interactive
        this.getTitlesDataFn = params.getTitlesDataFn
        this.onTitleBoundaryClickFn = params.onTitleBoundaryClickFn
        this.onTitleBoundariesLoadedFn = params.onTitleBoundariesLoadedFn
        this.onLoadingTitleBoundaryFn = params.onLoadingTitleBoundaryFn
        this.overFeaturesClassName = params.overFeaturesClassName ?? this.overFeaturesClassName
        this.styleCache = new Map()
        this.layer = (window as any).testl = new VectorLayer({
            zIndex: 16,
            source: new VectorSource(),
            updateWhileAnimating: false,
            updateWhileInteracting: false,
            style: (feature: Feature) => {
                // Style may have been generated for this feature already
                const titleNumber = TitleBoundaryLayerSettings.getTitleNumberFromFeature(feature)

                // Check for a style generated previously for this title.
                const cachedStyle = this.styleCache.get(titleNumber)
                if (cachedStyle !== undefined) {
                    return cachedStyle
                }

                // Not generated, generate and add to cache
                const title = this.getTitlesDataFn().find(item => titleNumber === item.titleNumber)

                if (title) {
                    const newStyle = getOLStyleForOWStyleDefinition(title, this.snapshotPageLayout?.graphicSizeMultiplier ?? 1)
                    this.styleCache.set(titleNumber, newStyle)
                    return newStyle
                }

                return null
            },
        })
    }

    // Sets the target map on to which the layer should be added and related map events observed.
    public setMap(map: olMap): void {
        this.map = map

        map.on('singleclick', (e) => {
            if (!this.layer.getVisible() || !this.interactive) {
                this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
                return
            }

            this.clickedTitleNumbers = []
            const pixel = map.getEventPixel(e.originalEvent)
            map.forEachFeatureAtPixel(pixel, (feature) => {
                const titleNumber = feature.get('titleNumber')
                if (titleNumber) {
                    this.clickedTitleNumbers.push(titleNumber)
                }
            }, {
                layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
            })
            this.onTitleBoundaryClickFn(this.clickedTitleNumbers)
            this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
        })
        map.on('pointermove', (e) => {
            if (!this.layer.getVisible() || !this.interactive) {
                return
            }
            const pixel = map.getEventPixel(e.originalEvent)
            const isHoveringOverTitle = map.forEachFeatureAtPixel(pixel, this.onTitleNumberHoverHandler.bind(this), {
                layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
            })
            if (!isHoveringOverTitle) {
                this.highlightFeaturesByTitleNumber(null)
                this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
            }
        })
    }

    // Loads and boundaries and displays them (without loading them again if they have already been loaded).
    public async loadBoundariesForTitles(titleNumbers: string[]): Promise<void> {
        // Some boundaries will already be loaded and just require showing.
        const titlesToShow = []
        const titlesToLoad = []
        titleNumbers.forEach(titleNumber => {
            if (this.loadedBoundariesForTitleNumbers.has(titleNumber)) {
                titlesToShow.push(titleNumber)
            } else {
                titlesToLoad.push(titleNumber)
            }
        })

        // Show titles already loaded.
        if (!isNullOrEmpty(titlesToShow)) {
            // Delete any cached styles.
            titlesToShow.forEach(x => this.styleCache.delete(x))
            this.layer.getSource().forEachFeature(feature => {
                if (feature.get('titleNumber') && titlesToShow.includes(feature.get('titleNumber'))) {
                    feature.changed()
                }
            })
        }

        // Load titles not already loaded (which will be visible by default).
        this.totalTitleNumbersToLoad = titlesToLoad.length
        this.totalTitleNumbersRemaining = titlesToLoad.length
        if (!isNullOrEmpty(titlesToLoad)) {
            const batchSize = 50
            for (let i = 0; i < titlesToLoad.length; i += batchSize) {
                const batch = titlesToLoad.slice(i, i + batchSize)
                batch.forEach(x => this.titleNumbersPendingBoundaryLoad.add(x))
                await this._loadBoundariesForTitles()
            }
        }
    }

    public updateTitlesDataFn(newFunction : () => any): void {
        this.getTitlesDataFn = newFunction
    }

    // Redraws the existing boundaries based on the latest style definitions.
    public refresh(titleNumbers: string[] = []): void {
        if (titleNumbers?.length > 0) {
            titleNumbers.forEach(titleNumber => {
                const title = this.getTitlesDataFn().find(item => titleNumber === item.titleNumber)
                if (title && title.boundaryAvailable) {
                    this.styleCache.delete(titleNumber)
                    const newStyle = getOLStyleForOWStyleDefinition(title)
                    this.styleCache.set(titleNumber, newStyle)
                }
            })
        }
        this.layer.getSource().changed()
    }

    // Adds boundaries for the list of title numbers.
    public async addBoundaryForTitleNumbers(titleNumbers: string[]) {
        await this.loadBoundariesForTitles(titleNumbers)
    }

    // Zooms to the extent of the visible data - consider revising this to only the visible data.
    public zoomToExtent(): void {
        if (this.layer.getSource().getFeatures().length) {
            this.map.getView().fit(this.layer.getSource().getExtent(), {
                duration: 500,
                padding: [100, 100, 100, 100],
            })
        }
    }

    // Removes the boundary for a title number - but does not remove the feature, so just hides it.
    public removeBoundaryForTitleNumbers(titleNumbers: string[]) {
        titleNumbers.forEach(x => this.styleCache.delete(x))
        this.layer.getSource().forEachFeature(feature => {
            if (titleNumbers.includes(TitleBoundaryLayerSettings.getTitleNumberFromFeature(feature))) {
                feature.changed()
            }
        })
        this.layer.getSource().changed()
    }

    public getHighlightedTitleNumber(): string {
        return this.highlightedTitleNumber
    }

    // Resets the layer, loading all boundaries for the title numbers passes by the getTitlesDataFn function.
    public async reload(loadAllBoundaries: boolean = false): Promise<void> {
        this.loadedBoundariesForTitleNumbers.clear()
        this.titleNumbersPendingBoundaryLoad.clear()
        this.clickedTitleNumbers = []
        this.layer.getSource().clear()
        this.styleCache.clear()

        // Load all boundaries if loadAllBoundaries is true.
        // Or load all boundaries if there is only a small number of titles.
        // Await the load to ensure the layer is fully loaded before returning.
        if (loadAllBoundaries || (this.getTitlesDataFn().length <= TitleBoundaryLayerSettings.SMALL_NUMBER_OF_TOTAL_TITLES)) {
            return await this.loadBoundariesForTitles(this.getTitlesDataFn().map(item => item.titleNumber))
        }
    }

    // Returns a count of titles with loaded boundaries.
    public getTitleNumbersWithLoadedBoundariesCount(): number {
        return this.layer
            .getSource()
            .getFeatures()
            .length
    }

    // Called when needing to highlight a title e.g. on mouse over.
    public highlightFeaturesByTitleNumber(titleNumber: string): void {
        if (this.highlightedTitleNumber === titleNumber) {
            return
        }

        // Remove currently highlighted feature
        this.styleCache.delete(this.highlightedTitleNumber)
        this.highlightedTitleNumber = titleNumber

        if (this.layer.getVisible()) {
            if (titleNumber) {
                const titleData = this.getTitlesDataFn().find(item => titleNumber === item.titleNumber)
                if (titleData) {
                    const highlightStyle = TitleBoundaryLayerSettings.getHighlightStyle(titleData)
                    this.styleCache.set(titleNumber, highlightStyle)
                }
            }
            this.layer.getSource().changed()
        }
    }

    public setHighlightBoundaryOnHover(enabled: boolean): void {
        this.highlightBoundaryOnHover = enabled
    }

    // Handles highlighting a given feature.
    public onTitleNumberHoverHandler(feature): boolean {
        if (!this.highlightBoundaryOnHover) return
        const titleNumber = feature.get('titleNumber')
        if (titleNumber) {
            this.highlightFeaturesByTitleNumber(titleNumber)
            if (this.map.getTargetElement()) {
                this.map.getTargetElement().classList.add(this.overFeaturesClassName)
            }
            return true
        }
    }

    // Internal method called via a throttled function to load boundaries for titles.
    public async _loadBoundariesForTitles(): Promise<void> {
        const titleNumbersLoaded = []
        if (this.titleNumbersPendingBoundaryLoad.size > 0) {
            const data = await MapApi.getBoundariesForTitleNumbers([...this.titleNumbersPendingBoundaryLoad])
            this.titleNumbersPendingBoundaryLoad.forEach(x => this.loadedBoundariesForTitleNumbers.add(x))
            data.forEach(item => {
                titleNumbersLoaded.push(item.titleNumber)
                const feature = (new GeoJSON().readFeature(item.geoJSON) as Feature)
                feature.set('titleNumber', item.titleNumber)
                this.layer.getSource().addFeature(feature)

                // how many title boundaries are still pending to load
                this.totalTitleNumbersRemaining = this.totalTitleNumbersRemaining - 1

                if (this.onLoadingTitleBoundaryFn) {
                    this.onLoadingTitleBoundaryFn(this.totalTitleNumbersToLoad, this.totalTitleNumbersRemaining)
                }
            })
            this.layer.getSource().changed()
            this.titleNumbersPendingBoundaryLoad.clear()
            this.hasLoadedSomeTitleBoundaries = true

            if (this.onTitleBoundariesLoadedFn) {
                this.onTitleBoundariesLoadedFn(titleNumbersLoaded)
            }
        }
    }

    public setInteractive(interactive: boolean): void {
        this.interactive = interactive
    }

    public setSnapshotLayout(layout: ISnapshotPageLayout) {
        this.snapshotPageLayout = layout
        this.styleCache.clear()
        this.layer.changed()
    }
}
