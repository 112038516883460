import {
    Circle as CircleStyle,
    Stroke,
    Style,
} from 'ol/style.js'
import { easeOut } from 'ol/easing.js'
import Feature from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'
import { getVectorContext } from 'ol/render.js'
import olMap from 'ol/Map'
import Point from 'ol/geom/Point'
import { unByKey } from 'ol/Observable.js'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import WebGLPointsLayer from 'ol/layer/WebGLPoints'

import {
    getOLStyleForOWStyleDefinition,
    StyleTarget,
} from '@/utils/style-utils'
import { enableWebGlLayerReloadWorkaround,
    layerEquals } from '@/utils/map-utils'
import { hexToRGBArray } from '@/utils/colour-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'
import MapApi from '@/api/map.api'
import { TitleBoundaryLayerSettings } from '@/store/modules/map/layers/title-boundary-layer/settings'
import { Colors } from '@/enums/colors.enum'

export interface PointLayerInitialisationParams {
    getTitlesDataFn: () => any,
    onPointClickFn: (titleNumber: string[]) => void,
    interactive: boolean
}

export type PointData = {
    // X Coordinate
    x: number,

    // Y Coordinate
    y: number,

    // Title Number
    t: string,

    show: string,
}

/**
 * The point layer is used to provide a rough indication as to the location of a title, intended to be faster than loading detailed boundaries.
 * It loads all titles provided. In the future this can be replaced with clustering (with clusters computed on the server).
 * It uses WebGL, so if users have that disabled for some reason, none of this will work. We should consider fallbacks, matter size limits and comms etc.
 */
export class PointLayer {
    public map: olMap
    private highlightedFeatureIds: string[] = [] // TODO: support highlighting points.
    public highlightedTitleNumber: string
    public readonly layer: WebGLPointsLayer<VectorSource<Point>>
    public readonly highlightLayer: VectorLayer<VectorSource<Geometry>>
    public getTitlesDataFn: () => any
    private readonly onPointClickFn: (titleNumber: string[]) => void
    public excludedTitleNumbers: Set<string> = new Set<string>()
    private pointData: PointData[]
    public titleNumbersLoaded: Set<string> = new Set<string>()
    private readonly styleCache: Map<any, Style | Style[]>
    private clickedTitleNumbers: string[] = []
    private readonly featureTitleKey = 'title'
    private readonly interactive: boolean = true
    private readonly overFeaturesClassName = 'ow-over-layer-matter-title-points'

    constructor(params: PointLayerInitialisationParams) {
        this.getTitlesDataFn = params.getTitlesDataFn
        this.onPointClickFn = params.onPointClickFn
        this.styleCache = new Map()
        this.interactive = params.interactive

        // Initialise the web gl layer
        this.layer = new WebGLPointsLayer<VectorSource<Point>>({
            zIndex: 15,
            source: new VectorSource(),
            style: {
                'circle-radius': 8,
                'circle-fill-color': ['color', ['get', 'r'], ['get', 'g'], ['get', 'b'], 0.8],
                'circle-stroke-width': 1,
                'circle-stroke-color': '#0e437c',
            },
        })
        enableWebGlLayerReloadWorkaround(this.layer, () => this.map)

        // Initialise the Vector highlight layer used for text highlights.
        this.highlightLayer = new VectorLayer({
            zIndex: 20,
            maxZoom: TitleBoundaryLayerSettings.ZOOM_LEVEL_ABOVE_WHICH_BOUNDARIES_CAN_BE_HIDDEN_FOR_PERFORMANCE,
            source: new VectorSource(),
            style: (feature:Feature) => {
                // Style may have been generated for this feature already
                const titleNumber = TitleBoundaryLayerSettings.getTitleNumberFromFeature(feature)

                // Check for a style generated previously for this title.
                const cachedStyle = this.styleCache.get(titleNumber)
                if (cachedStyle) {
                    return cachedStyle
                }

                // Not generated, generate and add to cache
                const title = params.getTitlesDataFn().find(item => titleNumber === item.titleNumber)

                if (title) {
                    const newStyle = getOLStyleForOWStyleDefinition(title, StyleTarget.Boundary)
                    this.styleCache.set(titleNumber, newStyle)
                    return newStyle
                }

                return null
            },
        })

        this.highlightLayer.getSource().on('addfeature', (event) => {
            this.flash(event.feature)
        })
    }

    public updateTitlesDataFn(newFunction : () => any): void {
        this.getTitlesDataFn = newFunction
    }

    // Refreshed the layer e.g. when something has changed and it needs to be redrawn.
    public refresh(titleNumbers: Array<string> = []): void {
        if (!this.pointData) {
            return
        }

        // If there are very few titles, no need to show the points visually.
        const showPoints = this.getTitlesDataFn().length > TitleBoundaryLayerSettings.SMALL_NUMBER_OF_TOTAL_TITLES || this.map.getView().getZoom() <= 11
        this.layer.setOpacity(showPoints ? 1 : 0)

        if (titleNumbers.length > 0) {
            // Update only particular points
            titleNumbers.forEach(titleNumber => {
                const feature = this.layer.getSource().getFeatureById(titleNumber)
                if (feature) {
                    const titleMetadata = this.getTitlesDataFn().find(x => x.titleNumber === titleNumber)
                    const styleProperties = this.getWebGlStylePropertiesForTitle(titleMetadata)
                    feature.setProperties(styleProperties)
                    feature.setProperties({ highlight: false })
                    feature.changed()
                }
            })
            return
        } else {
            // Everything needs refreshing
            this.layer.getSource().clear()
        }

        // Everything needs refreshing - add all points
        const features: Feature<Point>[] = this.pointData.map(x => {
            // Some titles do not have boundaries, so we don't want to consider them.
            if (x.x === null || x.y === null) {
                return undefined
            }
            const titleMetadata = this.getTitlesDataFn()
                .find(y => y.titleNumber === x.t)

            if (titleMetadata) {
                const styleProperties = this.getWebGlStylePropertiesForTitle(titleMetadata)

                const feature = new Feature({
                    geometry: new Point([x.x, x.y]),
                    title: x.t,
                    ...styleProperties,
                })
                feature.setId(x.t)
                return feature
            }
            return undefined
        }).filter(x => Boolean(x))

        this.layer.getSource().addFeatures(features)

        this.layer.changed()
    }

    // Resets the layer and loads all of the point data needed for the associated titles.
    public async reload(): Promise<void> {
        // Reset store of title numbers loaded.
        this.titleNumbersLoaded.clear()
        this.excludedTitleNumbers.clear()

        // Get title numbers passed in.
        const titleNumbers = this.getTitlesDataFn()
            .filter(x => x.show)
            .map(item => item.titleNumber)

        // Get point data for title numbers.
        this.pointData = await PointLayer.getPointData(titleNumbers)
        this.titleNumbersLoaded = new Set(titleNumbers)

        // Redraw the layer.
        this.refresh()
    }

    // Zooms to the extent of the point data.
    public zoomToExtent() {
        if (this.layer.getSource().getFeatures().length) {
            this.map.getView().fit(this.layer.getSource().getExtent(), {
                duration: 500,
                padding: [100, 100, 100, 100],
                minResolution: 0.66,
            })
        }
    }

    // Loads point data for the specified title numbers if not already loaded.
    public async addPointForTitleNumbers(titleNumbers: string[]) {
        titleNumbers = titleNumbers.filter(x => !this.titleNumbersLoaded.has(x))
        const newPointData = await PointLayer.getPointData(titleNumbers)
        this.pointData.push(...newPointData)
        titleNumbers.forEach(x => this.titleNumbersLoaded.add(x))

        // Redraw the layer.
        this.refresh()
    }

    // Removes the point data for a title number - but does not remove the feature, so just hides it.
    public removePointForTitleNumbers(titleNumbers: string[]) {
        titleNumbers = titleNumbers.filter(x => this.titleNumbersLoaded.has(x))
        titleNumbers.forEach((titleNumber: string) => {
            const idx = this.pointData.findIndex(p => p.t === titleNumber)
            this.pointData.splice(idx, 1)
            this.titleNumbersLoaded.delete(titleNumber)
        })

        // Redraw the layer.
        this.refresh()
    }

    public setMap(map: olMap): void {
        this.map = map

        this.map.addLayer(this.highlightLayer)

        this.initialiseMapEvents()
    }

    private initialiseMapEvents(): void {
        if (this.interactive) {
            this.map.on('singleclick', (e) => {
                if (!this.layer.getVisible()) {
                    return
                }

                this.clickedTitleNumbers = []
                const pixel = this.map.getEventPixel(e.originalEvent)
                this.map.forEachFeatureAtPixel(pixel, (feature) => {
                    const titleNumber = feature.get(this.featureTitleKey)
                    if (titleNumber) {
                        this.clickedTitleNumbers.push(titleNumber)
                    }
                }, {
                    layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
                })
                this.onPointClickFn(this.clickedTitleNumbers)
                this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
            })

            this.map.on('pointermove', (e) => {
                const pixel = this.map.getEventPixel(e.originalEvent)
                const isHoveringOverTitle = this.map.forEachFeatureAtPixel(pixel, this.onTitleNumberHoverHandler.bind(this))
                if (!isHoveringOverTitle) {
                    this.highlightFeaturesByTitleNumber(null)
                    this.map.getTargetElement().classList.remove(this.overFeaturesClassName)
                }
            })
        }
    }

    // Called when needing to highlight a title e.g. on mouse over.
    public highlightFeaturesByTitleNumber(titleNumber: string): void {
        if (this.highlightedTitleNumber === titleNumber) {
            return
        }

        this.removeAllHighlightFeatures()

        this.addHighlightFeatureByTitleNumber(titleNumber)
    }

    /**
     * Sets the visibility of the layers.
     * @param isVisible
     */
    public setVisible(isVisible: boolean): void {
        this.layer.setVisible(isVisible)
        this.highlightLayer.setVisible(isVisible)
    }

    private addHighlightFeatureByTitleNumber(titleNumber: string): void {
        if (this.highlightLayer.getVisible() &&
            !isNullOrWhitespace(titleNumber)) {
            const titleData = this.getTitlesDataFn().find(item => titleNumber === item.titleNumber)
            if (titleData) {
                const highlightStyle =
                    TitleBoundaryLayerSettings.getHighlightStyle(titleData, true, -18)
                this.styleCache.set(titleNumber, highlightStyle)
                const feature = this.layer.getSource().getFeatureById(titleNumber)
                const geometry = feature?.getGeometry()
                if (geometry) {
                    this.highlightLayer
                        .getSource()
                        .addFeature(new Feature({
                            geometry,
                            text: highlightStyle.getText().getText(), // Get the text string from the text object.
                            titleNumber,
                        }))
                }
            }
            this.highlightLayer.getSource().changed()
            this.highlightedTitleNumber = titleNumber
        }
    }

    private removeAllHighlightFeatures(): void {
        if (this.highlightLayer.getVisible()) {
            this.highlightLayer.getSource().getFeatures().forEach((feature) => {
                const featureTitleNumber = feature.get('titleNumber')
                this.styleCache.delete(featureTitleNumber)
                this.highlightLayer.getSource().removeFeature(feature)
            })
            this.highlightLayer.getSource().changed()
            this.highlightedTitleNumber = null
        }
    }

    // Loads points for an array of titles
    private static async getPointData(titleNumbers: string[]): Promise<any> {
        return await MapApi.getPointsForTitleNumbers(titleNumbers)
    }

    // Returns the title numbers in the current view extent, used to orchestrate loading the full detailed boundaries.
    public getTitleNumbersInExtent(extent: number[]): any {
        return (this.layer?.getSource()?.getFeaturesInExtent(extent) ?? [])
            .map(feature => {
                return TitleBoundaryLayerSettings.getTitleNumberFromFeature(feature, this.featureTitleKey)
            })
    }

    // Set title numbers to not show.
    public addExcludedTitleNumbers(titleNumbers: Array<string>): void {
        titleNumbers.forEach(titleNumber => this.excludedTitleNumbers.add(titleNumber))
        this.refresh(titleNumbers)
    }

    /** Returns the web gl layer style for a given title, this is different to the Style object used for the majority of other OpenLayers
     * style properties - see the OpenLayers examples for more information, this is likely to need updating if they adopt Style here too. */
    public getWebGlStylePropertiesForTitle(titleMetadata: any): any {
        const rgbArray: number[] = hexToRGBArray(titleMetadata.colour ?? TitleBoundaryLayerSettings.DEFAULT_POINT_COLOUR)
        const exclude = this.excludedTitleNumbers.has(titleMetadata.titleNumber)
        return {
            r: rgbArray[0],
            g: rgbArray[1],
            b: rgbArray[2],
            show: exclude ? 'false' : 'true',
        }
    }

    private flash(feature: Feature): void {
        const duration = 500
        const start = Date.now()
        const flashGeom = feature?.getGeometry().clone()
        if (flashGeom) {
            const animate = (event) => {
                const frameState = event.frameState
                const elapsed = frameState.time - start
                if (elapsed >= duration) {
                    unByKey(listenerKey)
                    return
                }
                const vectorContext = getVectorContext(event)
                const elapsedRatio = elapsed / duration
                // radius will be 5 at start and 30 at end.
                const radius = easeOut(elapsedRatio) * 25 + 5
                const opacity = easeOut(1 - elapsedRatio)

                const style = new Style({
                    image: new CircleStyle({
                        radius,
                        stroke: new Stroke({
                            color: Colors.Lights0,
                            width: 0.5 + opacity,
                        }),
                    }),
                })

                vectorContext.setStyle(style)
                vectorContext.drawGeometry(flashGeom)
                // tell OpenLayers to continue postrender animation
                this.highlightLayer.getSource().changed()
            }

            const listenerKey = this.highlightLayer.on('postrender', animate)
        }
    }

    // Handles highlighting a given feature.
    private onTitleNumberHoverHandler(feature): boolean {
        const titleNumber = feature.get(this.featureTitleKey)
        if (titleNumber) {
            this.highlightFeaturesByTitleNumber(titleNumber)
            this.map.getTargetElement().classList.add(this.overFeaturesClassName)
            return true
        }
    }
}
