import { useI18n } from 'vue-i18n'
import {
    Model,
    TemplateColumnConfig,
} from '@bryntum/grid'
import { useActionColumn } from '@/components/core/ow-grid/columns/use-action-column'
import { useCurrencyColumn } from '@/components/core/ow-grid/columns/use-currency-column'
import { useDateColumn } from '@/components/core/ow-grid/columns/use-date-column'
import { ItemState } from '@/enums/searches.enum'
import { usePillsColumn } from '@/components/core/ow-grid/columns/use-pills-column'
import { dateFormat } from '@/components/core/ow-grid/columns'
import OrderDetailItemModel from '@/components/matter-searches/models/OrderDetailItemModel'
import { HazardsColumn } from '@/components/matter-searches/columns'
import { documentMarkup } from './templates/documents'

export const useColumns = ({
    on,
}: {
    on: (event: string, column: string, record: Model) => void
}) => {
    const { t } = useI18n()
    const ProductColumn = ({
        text: t('searches.columns.name') as string,
        cls: 'column-name',
        flex: 1,
        type: 'template',
        autoHeight: true,
        template: ({ record }: { record: Model}) => {
            const orderDetail = record as OrderDetailItemModel

            return /*html*/`
            <div class="d-flex flex-column product-column__container mt-2">
                <div>
                    <span class="body-highlight product-column" title="${ orderDetail?.productName }">${ orderDetail?.productName }</span>
                </div>
                <div class="supplier-name product-column" title="${ orderDetail?.supplierName }">${ orderDetail?.supplierName }</div>
                <div>
                    ${ documentMarkup(orderDetail) }
                </div>
            </div>
        `
        },
    }) as Partial<TemplateColumnConfig>

    const ExpectedDateColumn = usePillsColumn({
        field: 'expectedDate',
        text: t('searches.columns.expectedDate') as string,
        width: 200,
        align: 'right',
        cls: 'hide-in-percy',
        tooltipRenderer: ({ record }: { record: Model }) => {
            const orderDetail = record as OrderDetailItemModel
            return orderDetail.currentExpectedDate ? `Original Expected Date: ${ dateFormat(orderDetail.expectedDate) }` : null
        },
        mapPillItems: (orderDetailItem: OrderDetailItemModel) => {
            if (orderDetailItem.status === ItemState.DocumentsAvailable) {
                return ['ready']
            }
            const date = orderDetailItem.currentExpectedDate ?? orderDetailItem.expectedDate
            const displayDate = date ? dateFormat(date) : '-'
            return [displayDate]
        },
        mapPillClass: (label: string | number) => {
            if (label === 'ready') {
                return '--success hide-in-percy'
            } else {
                return '--transparent hide-in-percy'
            }
        },
    })

    const ReturnedDateColumn = useDateColumn({
        field: 'returnedDate',
        text: t('searches.columns.returnedDate') as string,
        width: 200,
        className: 'column-returned-date',
    })

    const CostColumn = useCurrencyColumn({
        field: 'totalGrossPrice',
        text: t('searches.columns.cost') as string,
    })

    const StatusColumn = usePillsColumn({
        field: 'status',
        text: t('searches.columns.status') as string,
        width: 140,
        align: 'right',
        mapPillItems: (orderDetailItem: OrderDetailItemModel) => {
            switch (orderDetailItem.status) {
                case ItemState.DocumentsAvailable:
                    return ['ready']
                case ItemState.Completed:
                    return ['completed']
                case ItemState.Cancelled:
                    return ['cancelled']
                case ItemState.UserActionRequired:
                    return ['user-action']
                default:
                    return ['pending']
            }
        },
        mapPillClass: (field: string | number) => {
            switch (field) {
                case 'ready':
                case 'completed':
                    return '--success'
                case 'cancelled':
                    return '--danger'
                case 'user-action':
                    return '--warning'
                default:
                    return '--default'
            }
        },
    })

    const DownloadColumn = useActionColumn({
        onEvent: on,
        width: 110,
        actions: [{
            id: 'view',
            title: t('action.view') as string,
            primary: true,
            visible: ({ record }: any) => record.status === ItemState.Completed || record.status === ItemState.DocumentsAvailable,
            style: 'link',
        }, {
            id: 'download',
            className: 'mdi mdi-download',
            title: '',
            primary: true,
            visible: ({ record }: any) => record.status === ItemState.Completed || record.status === ItemState.DocumentsAvailable,
            style: 'icon',
        }],
    })

    return {
        gridColumns: [
            ProductColumn,
            HazardsColumn,
            StatusColumn,
            ExpectedDateColumn,
            ReturnedDateColumn,
            CostColumn,
            DownloadColumn,
        ],
    }
}
