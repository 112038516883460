import {
    StyleFunction,
    StyleLike,
} from 'ol/style/Style'
import { LayerNames } from '@/consts/map-layers'
import {
    Stroke,
    Style,
} from 'ol/style'
import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import { FeatureLike } from 'ol/Feature'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { SketchType } from '@/enums/sketches-enums'
import { getOWStyleFromOLStyle } from '@/utils/map-utils'

import i18n from '@/plugins/i18n'

import {
    IMapRolloverSectionItem,
} from '@/components/map-rollover/common/map-rollover-interfaces'

export type OsPublicRightsOfWayLayerParams = BaseMvtDataLayerParams & {
    overFeaturesGetterFn: () => Array<FeatureLike>
}

enum HighwayDedication {
    Bridleway = 'Bridleway',
    BywayOpenToAllTraffic = 'Byway Open To All Traffic',
    CycleTrackOrCycleWay = 'Cycle Track Or Cycle Way',
    PedestrianWayOrFootpath = 'Pedestrian Way Or Footpath',
    RestrictedByway = 'Restricted Byway',
}

export class OsPublicRightsOfWayLayer extends BaseMvtDataLayer {
    public maxResolution = 6
    private readonly overFeaturesGetterFn: () => Array<FeatureLike>

    constructor(params: OsPublicRightsOfWayLayerParams, settings: any) {
        super(params, settings)
        this.name = LayerNames.PublicRightsOfWay
        this.rolloverOptions = {
            sortOrder: 0,
            category: i18n.global.t(`map.rollover.${ LayerNames.PublicRightsOfWay }.category`),
            source: i18n.global.t(`map.rollover.${ LayerNames.PublicRightsOfWay }.source`),
            getStyle: (feature) => (this.style as StyleFunction)(feature, null) as Style,
            addItems: (features: FeatureLike[]): IMapRolloverSectionItem[] => {
                const result: IMapRolloverSectionItem[] = []

                features.forEach(feature => {
                    const properties = feature.getProperties() as {dedication: string, nationalcycleroute: boolean}
                    const existing = result.some(x => x.id === `${ properties.dedication }-${ properties.nationalcycleroute }`)
                    if (!existing) {
                        const itemStyle = getOWStyleFromOLStyle((this.style as StyleFunction)(feature, null) as Style)
                        result.push({
                            id: `${ properties.dedication }-${ properties.nationalcycleroute }`,
                            primary: properties.dedication,
                            style: itemStyle,
                        })
                    }
                })

                // sort by primary
                result.sort((a, b) => {
                    if (a.primary < b.primary) {
                        return -1
                    }
                    if (a.primary > b.primary) {
                        return 1
                    }
                    return 0
                })
                return result
            },
        }
        this.layerName = 'ow:osmm_highways_dedication_combined_prow'
        this.attributionText = [settings.highwayDedicationNotice]
        this.overFeaturesGetterFn = params.overFeaturesGetterFn
        this.initialiseLayer()
    }

    public name: string = LayerNames.PublicRightsOfWay

    public style:StyleLike = (feature: FeatureLike) => {
        let strokeWidth = 4
        let zIndex = 300
        let colour = 'rgb(50, 54, 160)'
        if (this.overFeaturesGetterFn != null && this.interactive) {
            const overFeatures = this.overFeaturesGetterFn()
            if (overFeatures
                .map(x => x.get('localid'))
                .includes(feature.get('localid'))) {
                strokeWidth = 4
            }
        }
        const properties = feature.getProperties()

        // Determine colour and zIndex
        switch (properties.dedication) {
            case HighwayDedication.PedestrianWayOrFootpath:
                colour = 'rgb(255, 19, 254)'
                zIndex = 305
                break
            case HighwayDedication.Bridleway:
                colour = 'rgb(41, 255, 78)'
                zIndex = 304
                break
            case HighwayDedication.BywayOpenToAllTraffic:
                colour = 'rgb(25, 100, 160)'
                zIndex = 303
                break
            case HighwayDedication.RestrictedByway:
                colour = 'rgb(255, 2, 2)'
                zIndex = 302
                break
            case HighwayDedication.CycleTrackOrCycleWay:
                colour = 'rgb(249, 251, 70)'
                strokeWidth = 6
                zIndex = 301
                break
        }

        if (properties.nationalcycleroute) {
            colour = 'rgb(249, 251, 70)'
            strokeWidth = 6
        }

        return new Style({
            zIndex,
            stroke: new Stroke({
                color: colour,
                width: strokeWidth,
            }),
        })
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        const result = []
        const featuresInExtent = this.layer
            .getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
        featuresInExtent.forEach(feature => {
            const properties = feature.getProperties() as {dedication: string, nationalcycleroute: boolean}
            const existing = result.some(x => x.id === `${ properties.dedication }-${ properties.nationalcycleroute }`)
            if (!existing) {
                const style = getOWStyleFromOLStyle((this.style as StyleFunction)(feature, 1) as Style)
                style.sketchType = SketchType.Line
                result.push({
                    id: `${ properties.dedication }-${ properties.nationalcycleroute }`,
                    label: properties.dedication,
                    style,
                })
            }
        })

        return result
    }
}
