<template>
    <div class="colored-label"
         :class="{
             'is-beta-label': isBetaLabel,
             'is-count': isCount,
             'is-notification-dot': isNotificationDot,
             'is-small': isSmall,
             'is-extra-small': isExtraSmall,
             'is-success': theme === 'success',
             'is-warning': theme === 'warning',
             'is-danger': theme === 'danger',
             'is-shadow': theme === 'shadow',
             'is-tenure-freehold': theme === 'tenure-freehold',
             'is-tenure-leasehold': theme === 'tenure-leasehold',
             'is-tenure-other': theme === 'tenure-other',
             'is-expired': theme === 'expired',
             'is-notification': theme === 'notification-success',
             'is-boundary-green': theme === 'boundary-green',
             'is-danger-500': theme === 'danger-500',
             'is-boundary-red': theme === 'boundary-red',
             'is-boundary-blue': theme === 'boundary-blue',
             'is-boundary-grey': theme === 'boundary-grey',
             'is-primary': theme === 'primary',
             'is-grey': theme === 'grey',
         }"
         :style="{
             backgroundColor: backgroundColor,
             color: color,
             margin: marginValue,
         }"
         data-test="colored-label">
        {{ isBetaLabel ? 'BETA' : '' }}
        {{ isNewLabel ? 'NEW' : '' }}
        <slot v-if="!isBetaLabel" />
    </div>
</template>

<script>
    export default {
        props: {
            isSmall: {
                type: Boolean,
                required: false,
            },
            isExtraSmall: {
                type: Boolean,
                required: false,
            },
            isBetaLabel: {
                type: Boolean,
                required: false,
            },
            isNewLabel: {
                type: Boolean,
                required: false,
            },
            isNotificationDot: {
                type: Boolean,
                required: false,
            },
            isCount: {
                type: Boolean,
                required: false,
            },
            margin: {
                type: String,
                required: false,
            },
            backgroundColor: {
                type: String,
                required: false,
                default: '$colour-accent',
            },
            color: {
                type: String,
                required: false,
                default: '$colour-white',
            },
            theme: {
                type: String,
                required: false,
                default: null,
                validator: (value) => {
                    return [
                        'success',
                        'warning',
                        'danger',
                        'primary',
                        'shadow',
                        'tenure-freehold',
                        'tenure-leasehold',
                        'tenure-other',
                        'expired',
                        'notification-success',
                        'boundary-red',
                        'boundary-blue',
                        'boundary-grey',
                        'boundary-green',
                        'danger-500',
                        'grey',
                    ].includes(value)
                },
            },
        },

        computed: {
            marginValue() {
                return `${ this.margin?.split(' ').join('px ') }px`
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-colored-label.scss';
</style>
