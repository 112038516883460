export enum OwButtonColor {
    Primary = 'primary',
    Secondary = 'secondary',
    Information = 'info',
    Error = 'error',
    Warning = 'warning',
    Success = 'success',
    White = 'white',
    DarkGrey = 'dark-grey',
}
