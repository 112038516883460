<template>
    <ow-data-grid :key="tableKey"
                  v-model="selectedItems"
                  :allow-selection="false"
                  class="epc-table mt-4"
                  :fixed-header="epcTableHasFixedHeader"
                  :headers="epcTableDataGridHeaders"
                  :hover="true"
                  :items="gridItems"
                  :sort-by="sortBy"
                  single-select
                  theme="light"
                  narrow
                  :no-data-text="$t('titlePanel.cards.epc.noData')"
                  data-test="epc-table"
                  @row-click="onRowClick"
                  @items-selected="selectedItems = $event"
                  @update:model-value="selectedItems = $event"
                  @current-items="onCurrentItemsChange"
                  @update-data-sorted="onCurrentItemsChange">
        <template #item.address="{ item } : {item: IEpcTableRow}">
            <td :class="{ 'is-selected': item.id === selectedItemId }"
                :title="item.address"
                class="body-copy address">
                {{ item.address }}
            </td>
        </template>
        <template #item.source="{ item } : {item: IEpcTableRow}">
            <td class="body-copy">
                {{ capitaliseWord(item.source) }}
            </td>
        </template>
        <template #item.energyBand="{ item } : {item: IEpcTableRow}">
            <td class="body-copy epc-rating">
                <ow-coloured-chevron-label :label="item.energyBand"
                                           :theme="item.theme" />
            </td>
        </template>
        <template #item.expiryDate="{ item } : {item: IEpcTableRow}">
            <td :class="{'expired': item.expired === true}"
                class="body-copy expiry-date">
                {{ getExpiryDate(item.expiryDate) }}
            </td>
        </template>
        <template #item.viewChart="{ item }">
            <ow-button class="mx-2"
                       full-height
                       full-width
                       is-flat
                       is-primary
                       is-secondary
                       is-text
                       @click="viewChart(item)">
                View
            </ow-button>
        </template>
    </ow-data-grid>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import EpcApi from '@/api/epc.api'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwColouredChevronLabel from '@/components/core/ow-coloured-chevron-label.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import useDates from "@/composables/use-dates"
    import { useSelectedTitle } from "@/composables/use-selected-title"
    import {
        IEpcData,
        IEpcResponse,
    } from '@/interfaces/epc.interface'
    import { EPC } from '@/models/epc.model'
    import { LOGGING_HEAP_TRACK_EVENT } from "@/store/mutation-types"
    import { capitalise } from '@/utils/string-utils'

    import { getExpiryDate } from "./util"

    export interface IEpcTableRow {
        id: string
        address: string
        source: string
        energyBand: string
        energyRating: string
        expired: boolean
        expiryDate: string
        theme: string
        lodgementDateTime: Date
        potentialEnergyBand: string
        potentialEnergyRating: string
        uprn: string
    }

    const store = useStore()
    const { t } = useI18n()
    const { formatDateShort } = useDates()
    const tableKey = ref(0)
    const selectedItems = ref()

    const gridItems = defineModel<any[]>('items')
    const loading = defineModel('loading')
    const selectedRow = defineModel<IEpcTableRow>()
    const { selectedTitle, selectedTitleNumber } = useSelectedTitle()

    const epcTableDataGridHeaders = ref([
        { title: 'Address', key: 'address', class: 'epc__epc-table-header-epc-address' },
        { title: 'Type', key: 'source', class: 'type epc__epc-table-header-epc-type' },
        { title: 'Rating', key: 'energyBand', class: 'rating epc__epc-table-header-epc-rating' },
        { title: 'Expiry date', key: 'expiryDate', class: 'date epc__epc-table-header-epc-date' },
        { title: '', key: 'viewChart', sortable: false },
    ])
    const selectedItemId = ref(null)
    const sortBy = ref([ { key: 'address', order: 'desc' } ])

    const epcTableHasFixedHeader = computed(() => gridItems.value?.length > 5)

    const capitaliseWord = (word: string): string => capitalise(word)

    const emit = defineEmits([ 'view-chart' ])

    const onRowClick = (item: IEpcTableRow): void => {
        viewChart(item, true)
    }

    const viewChart = (item: IEpcTableRow, clickRow: boolean = false): void => {
        loading.value = true
        const fakeLoadingSkeletonTimeout = Math.random() * 200 + 400

        setTimeout(() => {
            selectedItemId.value = item.id
            selectedRow.value = item
            selectedItems.value = [ item.id ]
            store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'TITLE-DETAILS-PANEL - EPC - View chart',
                metadata: {
                    source: item.source,
                    address: item.address,
                    uprn: item.uprn,
                },
            })
            loading.value = false

            if (clickRow) {
                emit('view-chart', item)
            }
        }, fakeLoadingSkeletonTimeout)
    }
    const onCurrentItemsChange = (items: Array<IEpcData>) => {
        gridItems.value = items
    }

    const getItems = async () => {
        try {
            const epcData: IEpcResponse[] = await EpcApi.byTitleNumbers([ selectedTitleNumber.value ])
            const epc = new EPC(epcData)
            gridItems.value = epc.data
        } catch (e) {
            console.error(e)
        } finally {
            loading.value = false
        }
    }

    watch(() => selectedTitle.value.record, async (val) => {
        if (val) {
            await getItems()
        }
    }, { immediate: true, deep: true })

    watch(() => selectedItems.value, (val) => {
        if (val && val.length === 1) {
            selectedRow.value = gridItems.value.find((item: IEpcTableRow) => item.id === val[0])
        }
    }, { immediate: true, deep: true })

    watch(() => selectedRow.value, (val) => {
        if (val) {
            selectedItemId.value = val.id
            selectedItems.value = [ val.id ]
        }
    }, { immediate: true, deep: true })

</script>

<style lang="scss">
@import './epc-table.scss';
</style>
