<template>
    <div class="matter-side-panel-uploading-docs"
         data-test="matter-side-panel-uploading-docs">
        <ow-progress size="medium" />
        <div class="matter-side-panel-uploading-docs__content">
            <p class="caption-regular">
                {{ $t('matter.uploadingDocsPanel.detectingTitles') }}
            </p>
            <p class="caption-regular">
                {{ $t('matter.uploadingDocsPanel.appearWhenReady') }}
            </p>
        </div>
        <div class="matter-side-panel-uploading-docs__content">
            <p class="caption-regular">
                {{ $t('matter.uploadingDocsPanel.ctaDescription') }}
            </p>
            <ow-button is-secondary
                       small
                       data-test="matter-side-panel-uploading-docs-button"
                       @click="handleDocLibraryButtonClick">
                {{ $t('matter.uploadingDocsPanel.goToDocumentLibrary') }}
            </ow-button>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { useRouter } from 'vue-router'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwProgress from '@/components/core/ow-progress.vue'
    import { Route } from '@/enums/route.enum'

    const router = useRouter()

    const handleDocLibraryButtonClick = () => {
        router.push({ name: Route.DocumentsLibrary, hash: '#uploaded' })
    }
</script>

<style lang="scss">
    @import 'matter-side-panel-uploading-docs';
</style>
