import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { LayerNames } from '@/consts/map-layers'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { Vector as VectorLayer } from 'ol/layer'
import olMap from 'ol/Map'
import { Vector as VectorSource } from 'ol/source'
import { Style } from 'ol/style'
import Feature, { FeatureLike } from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { ISketch } from '@/store/modules/sketches/types/sketch'
import {
    getStyleTargetForSketchType,
} from '@/utils/sketch-utils'

import { getOLStyleForOWStyleDefinition } from '@/utils/style-utils'

import { SketchesFactory } from '@/store/modules/sketches/types/sketches-factory'
import { SketchType } from '@/enums/sketches-enums'
import { sketchSnapshotAttributes } from '@/components/snapshots/common/snapshot-enums-consts'
import { IOwSketchOrStyleProps } from '@/store/modules/sketches/types/style'

import i18n from '@/plugins/i18n'

import {
    BaseMvtDataLayer,
} from '@/store/modules/map/layers/base-mvt-data-layer'

import {
    IMapRolloverOptions,
    IMapRolloverSectionItem,
} from '@/components/map-rollover/common/map-rollover-interfaces'

export type SketchesLayerParams = {
    snapshotConfig?: LayerSnapshotModel
    targetMap: olMap
    getSketchesFn: () => Array<ISketch>
    getSketchesStyleFn: (feature: Feature) => Style
}

export type SketchesSnapshotConfig = {
    geoJson: string
    sketchData: Array<ISketch>
}

/**
 * Used to indicate selected titles on the map.
 * Consider refactoring the titles store module to move more layer and map logic here.
 */
export class SketchesLayer implements IMapSnapshotLayer {
    name: string = LayerNames.Sketches
    private readonly layer: VectorLayer<VectorSource>
    private readonly args: SketchesLayerParams
    private readonly getSketchesFn: () => Array<ISketch>
    private readonly keyItemCache: Map<number, KeyConfigItemModel> = new Map<number, KeyConfigItemModel>()
    private readonly rolloverOptions: IMapRolloverOptions

    constructor(args: SketchesLayerParams) {
        this.args = args
        const layerSource = new VectorSource()
        let styleFunction: (feature: Feature) => Style | Style[]
        if (this.args.snapshotConfig) {
            // For a snapshot, the sketch data is stored in the snapshot config.
            const sketchesSnapshotConfig = JSON.parse(this.args.snapshotConfig.configJson) as SketchesSnapshotConfig
            const features = new GeoJSON().readFeatures(sketchesSnapshotConfig.geoJson, {
                featureProjection: CoordinateSystemCode.EPSG27700,
                dataProjection: CoordinateSystemCode.EPSG4326,
            })
            this.getSketchesFn = () => sketchesSnapshotConfig.sketchData.map(sketch => SketchesFactory.sketchFromRecord(sketch))
            layerSource.addFeatures(features)
            const styleCache = new Map<string, Style | Style[]>()
            styleFunction = feature => {
                const sketchId = feature.get('sketchId')
                let style = styleCache.get(sketchId)
                if (!style) {
                    const sketch = this.getSketchesFn().find(sketch => sketch.id === sketchId)
                    if (!sketch) {
                        return null
                    }
                    style = getOLStyleForOWStyleDefinition(sketch, getStyleTargetForSketchType(sketch.sketchType))
                    styleCache.set(sketchId, style)
                }
                return style
            }
        } else {
            styleFunction = args.getSketchesStyleFn
            this.getSketchesFn = args.getSketchesFn
        }
        this.layer = new VectorLayer({
            zIndex: 350,
            source: layerSource,
            style: styleFunction,
        })
        this.layer.set('name', this.name)
        this.layer.set('getRolloverTextForPoint', BaseMvtDataLayer.getRolloverTextForPoint.bind(this, this.layer, this.getRolloverOptions(), null))
        this.layer.set('getOwLayer', () => this)
    }

    getIsLoading(): boolean {
        return this.layer.getSource().getFeatures().length === 0
    }

    getKeyItems(targetMap: olMap): Array<KeyConfigItemModel> {
        const result:KeyConfigItemModel[] = []
        const extent = targetMap.getView().calculateExtent(targetMap.getSize())
        const featuresInExtent = this.layer.getSource().getFeaturesInExtent(extent)
        featuresInExtent.forEach(feature => {
            const sketchId = feature.get('sketchId')
            const existingKeyItem = this.keyItemCache.get(sketchId)
            if (existingKeyItem) {
                if (result.indexOf(existingKeyItem) === -1) {
                    result.push(existingKeyItem)
                }
                return
            }

            const sketch = this.getSketchesFn().find(sketch => sketch.id === sketchId)
            if (sketch?.sketchType === SketchType.Text) {
                // No need to show text sketches in the key.
                return
            }

            const newItem = {
                id: `sketch-${ sketch.id }`,
                label: sketch.name,
                style: sketch,
            }
            this.keyItemCache.set(sketchId, newItem)
            result.push(newItem)
        })

        // Some sketches will have the same style, so consolidate them.
        const deduplicatedResultsByStyle = result.reduce((acc, item) => {
            const existingItem = acc.find(x => this.sketchKeyStylesAreEqual(x.style, item.style))
            if (existingItem) {
                existingItem.label += `, ${ item.label }`
            } else {
                acc.push(item)
            }
            return acc
        }, [])

        return deduplicatedResultsByStyle
    }

    getLayer(): VectorLayer<VectorSource> {
        return this.layer
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        // Filter out sketches that do not have any visible features.
        const visibleSketches = this.getSketchesFn().filter(sketch => {
            return sketch.visible &&
                this.layer.getSource().getFeatures().some(feature => sketch.id === feature.get('sketchId'))
        })
        const visibleFeatures = this.layer.getSource().getFeatures().filter(feature => {
            return visibleSketches.some(sketch => sketch.id === feature.get('sketchId'))
        })
        if (visibleFeatures.length === 0) {
            return null
        }

        const featureGeoJson = new GeoJSON().writeFeatures(visibleFeatures, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        })

        const result = {
            name: this.name,
            configJson: JSON.stringify({
                geoJson: featureGeoJson,
                sketchData: visibleSketches
                    .map(sketch => {
                        const simplifiedSketchModel = {}
                        sketchSnapshotAttributes.forEach(attribute => {
                            simplifiedSketchModel[attribute] = sketch[attribute]
                        })
                        return sketch
                    }),
            }),
        }
        return result
    }

    getVisible(): boolean {
        return this.layer.getVisible()
    }

    setVisible(visible: boolean, targetMap?: olMap): Promise<void> {
        this.layer.setVisible(visible)
        if (targetMap && !targetMap.getLayers().getArray().includes(this.layer)) {
            targetMap.addLayer(this.layer)
        }
        return Promise.resolve()
    }

    /** Compares two sketches to determine if they are equal. */
    sketchKeyStylesAreEqual(sketch1: Partial<IOwSketchOrStyleProps>, sketch2: Partial<IOwSketchOrStyleProps>): boolean {
        const getStyleProps = (style: Partial<IOwSketchOrStyleProps>) => {
            return {
                arrowhead: style.arrowhead,
                comment: style.comment,
                dash: style.dash,
                fillColour: style.fillColour,
                fillOpacity: style.fillOpacity,
                hatch: style.hatch,
                showLabel: style.showLabel,
                strokeColour: style.strokeColour,
                strokeWidth: style.strokeWidth,
            }
        }

        return JSON.stringify(getStyleProps(sketch1)) === JSON.stringify(getStyleProps(sketch2))
    }

    public getRolloverOptions(): IMapRolloverOptions {
        return {
            showPrimary: false,
            allowClick: true,
            clickText: i18n.global.t(`map.rollover.${ LayerNames.Sketches }.clickText`),
            sortOrder: 0,
            category: i18n.global.t(`map.rollover.${ LayerNames.Sketches }.category`),
            addItems: (features: FeatureLike[]): IMapRolloverSectionItem[] => {
                const result: IMapRolloverSectionItem[] = []
                features.forEach(feature => {
                    const sketchId = feature.get('sketchId')
                    const sketch = this.getSketchesFn().find(sketch => sketch.id === sketchId)
                    if (!sketch) {
                        return
                    }
                    const existing = result.some(x => x.id === `${ sketch.id }`)
                    if (!existing) {
                        result.push({
                            id: sketch.id.toString(),
                            primary: sketch.name,
                            style: {
                                fillColour: sketch?.strokeColour ?? sketch?.fillColour,
                            },
                            extended: [
                                {
                                    name: i18n.global.t(`map.rollover.${ LayerNames.Sketches }.extended.sketchType`),
                                    value: SketchType[sketch.sketchType].toString(),
                                },
                            ],
                        })
                    }
                })
                return result
            },
        }
    }
}
