import { LicenceType } from "@/enums/licenceType"

export class UserLicenceManager {
    selectedUserIds = []
    witnessUsers = []
    noneLicenceCount = 0
    standardLicenceCount = 0
    premiumLicenceCount = 0
    liteLicenceCount = 0

    constructor() {
        this.selectedUserIds = []
        this.noneLicenceCount = 0
        this.standardLicenceCount = 0
        this.premiumLicenceCount = 0
        this.liteLicenceCount = 0
    }

    reset() {
        this.selectedUserIds = []
        this.witnessUsers = []
        this.noneLicenceCount = 0
        this.standardLicenceCount = 0
        this.premiumLicenceCount = 0
        this.liteLicenceCount = 0
    }

    setSelectedUsers(users: any[], selectedUserIds: string[]) {
        this.reset()
        this.selectedUserIds = selectedUserIds
        const filteredUsers = users.filter(x => selectedUserIds.includes(x.id))

        filteredUsers.forEach(user => {
            // users must be active and have access to Witness
            if (user.active && user.products.some(x => x.id === 1 && x.availableToUser)) {
                this.witnessUsers.push(user)
            }
            // Count licence types
            switch (user.licenceType) {
                case LicenceType.None:
                    this.noneLicenceCount++
                    break
                case LicenceType.Standard:
                    this.standardLicenceCount++
                    break
                case LicenceType.Premium:
                    this.premiumLicenceCount++
                    break
                case LicenceType.Lite:
                    this.liteLicenceCount++
                    break
            }
        })
    }

    hasLicencesToChange(licenceType: LicenceType) {
        // if all selected users have the same licence type, return false
        return this.witnessUsers.filter(x => x.licenceType === licenceType).length !== this.witnessUsers.length
    }
}