<template>
    <div class="epc-band"
         :class="theme">
        <div class="epc-band__range">
            {{ range }}
        </div>
        <div class="epc-band__letter">
            {{ band }}
        </div>
    </div>
</template>

<script lang="ts">
    import { EnergyBand } from '@/enums/epc.enum'

    export default {
        name: 'EpcBand',
        props: {
            range: {
                type: String,
                required: true,
            },
            band: {
                type: String,
                required: true,
            },
        },
        computed: {
            theme() {
                if (this.band === EnergyBand.APlus) {
                    return 'a-plus'
                }
                return this.band.toLowerCase()
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import './epc-band.scss';
</style>
