<template>
    <div>
        <div class="export-button-menu">
            <ow-tooltip activator="parent"
                        :disabled="isMenuOpen || disabled"
                        max-width="400px"
                        :position="tooltipPosition">
                <span v-t="!showPdfExport ? 'report.action.exportBtnTooltip' : 'report.action.exportBtnWithPdfTooltip'" />
            </ow-tooltip>
            <ow-button-menu v-model="isMenuOpen"
                            :button-text="$t('report.action.export')"
                            class="export-button-menu__button"
                            :full-height="isFullHeight"
                            :is-borderless="isBorderless"
                            :disabled="disabled"
                            :small="isSmall"
                            is-secondary
                            :style="{
                                height: buttonHeight,
                            }">
                <template #links>
                    <ul class="export-button-menu__links">
                        <li v-for="template in exportOptions.filter(t => !t?.hideInSubMenu)"
                            v-if="exportOptions?.length">
                            <a class="export-button-menu__links--link"
                               href="#"
                               @click.prevent="onExportAs(template)">
                                <v-icon class="export-button-menu__links--icon">$download</v-icon>
                                {{ template.name }}
                            </a>
                        </li>
                    </ul>
                </template>

                <template #actions>
                    <ow-button full-width
                               small
                               @click="actionBtnClickHandler">
                        <span v-t="'report.action.viewMoreTemplates'" />
                    </ow-button>
                    <div v-if="showPdfExport"
                         class="pt-3">
                        <ow-button full-width
                                   small
                                   is-primary
                                   @click="exportPdfBtnClickHandler">
                            <span v-t="'action.downloadAsPdf'" />
                        </ow-button>
                    </div>
                </template>
            </ow-button-menu>
        </div>

        <exporting-status-modal v-model="isExportStatusDialogVisible"
                                :error="hasError"
                                :exporting="isExportingData"
                                data-test="export-button-menu-export-status-dialog"
                                @request-retry="onExportAs" />
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapMutations,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwButtonMenu from '@/components/core/ow-button-menu.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import ExportingStatusModal from '@/components/reporting/exporting-status-modal-with-teleport.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import ExportOptionsMixin from '@/mixins/export-options.mixin'
    import {
        MUTATE_SELECTED_EXPORT_OPTION,
        MUTATE_SELECTED_TITLES,
    } from '@/store/modules/reports/types'

    const Events = {
        linkSelected: 'selected-template',
        actionBtnClick: 'action-button-click',
    }

    export default {
        name: 'ExportButtonMenu',

        components: {
            ExportingStatusModal,
            OwButton,
            OwButtonMenu,
            OwTooltip,
        },

        mixins: [
            ExportOptionsMixin,
        ],

        props: {
            currentMatterId: {
                type: [Number, String],
                required: false,
            },
            selectedTitleNumbers: {
                type: Array as PropType<Array<String>>,
                default: () => [],
                required: false,
            },
            buttonHeight: {
                type: String,
                required: false,
                default: '50px',
            },
            isBorderless: {
                type: Boolean,
                required: false,
            },
            disabled: {
                type: Boolean,
                required: false,
            },
            isFullHeight: {
                type: Boolean,
                required: false,
            },
            isSmall: {
                type: Boolean,
                required: false,
            },
            heapTrackerPrefix: {
                type: String,
                required: true,
            },
            tooltipPosition: {
                type: String as PropType<OwTooltipPosition>,
                default: OwTooltipPosition.Bottom,
            },
            showPdfExport: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        emits: [
            Events.linkSelected,
            Events.actionBtnClick,
        ],

        data() {
            return {
                isMenuOpen: false,
                isExportStatusDialogVisible: false,
                currentTemplate: null,
            }
        },

        methods: {
            ...mapMutations('reports', {
                setSelectedExportOption: MUTATE_SELECTED_EXPORT_OPTION,
                setSelectedTitles: MUTATE_SELECTED_TITLES,
            }),

            async actionBtnClickHandler(): Promise<void> {
                // Call the handler in the export mixin
                await this.viewAllTemplatesClickHandler(
                    this.selectedTitleNumbers,
                    this.currentMatterId,
                    this.heapTrackerPrefix,
                )
            },

            async exportPdfBtnClickHandler(): Promise<void> {
                // Call the handler in the export mixin
                this.$emit(Events.linkSelected, 'pdf')
            },

            async onExportAs(chosenTemplate) {
                // Set the current template so that retry can use it if clicked.
                if (chosenTemplate) {
                    this.currentTemplate = chosenTemplate
                }

                // if current matter id is not set then redirect to the select titles page
                if (!this.currentMatterId) {
                    this.setSelectedExportOption(chosenTemplate)
                    this.setSelectedTitles(this.selectedTitleNumbers)
                    return this.$router.push({ name: Route.ReportsSelectTitles })
                }

                // Call the handler in the export mixin
                await this.linkClickHandler(
                    this.selectedTitleNumbers,
                    this.currentMatterId,
                    this.heapTrackerPrefix,
                    this.currentTemplate,
                )
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './export-button-menu';
</style>
