import { SketchType } from '@/enums/sketches-enums'

export const useSketchIcon = async (sketchType: SketchType) => {
    let iconName = 'area'
    import('@/components/core/icons/ow-icon-sketches-area.vue')
    import('@/components/core/icons/ow-icon-sketches-line.vue')
    import('@/components/core/icons/ow-icon-sketches-text.vue')
    import('@/components/core/icons/ow-icon-sketches-marker.vue')

    switch (sketchType) {
        case SketchType.Area:
            iconName = 'area'
            break
        case SketchType.Line:
            iconName = 'line'
            break
        case SketchType.Text:
            iconName = 'text'
            break
        case SketchType.Marker:
            iconName = 'marker'
            break
    }
    const val = await import(`@/components/core/icons/ow-icon-sketches-${ iconName }.vue`)
    return val.default
}
