<template>
    <svg class="ow-background"
         :width="width"
         :height="height"
         viewBox="0 0 1280 800"
         preserveAspectRatio="xMidYMid slice"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path id="a"
                  d="M0 800h1280V0H0z" />
        </defs>
        <g fill="none"
           fill-rule="evenodd">
            <mask id="b"
                  fill="#fff">
                <use xlink:href="#a" />
            </mask>
            <use fill="#006A87"
                 xlink:href="#a" />
            <g mask="url(#b)">
                <path fill="#006A87"
                      d="M311.8 588.9l63 36.4V516.1l-63-36.4z" />
                <path fill="#FF6C50"
                      d="M469.4 243L217.2 388.6 122.6 334 28 388.6l189.2 109.3L564 297.6z" />
                <path fill="#006A87"
                      d="M28 607.1l189.2 109.2V497.9L28 388.6z" />
                <path fill="#26DEEB"
                      d="M217.2 497.9v218.4l94.6-54.6V552.5l63-36.4v109.2L564 516.1V297.6z" />
            </g>
            <g mask="url(#b)">
                <path fill="#FF6C50"
                      d="M994 552.3l143 82.5 143-82.5-143-82.6z" />
                <path fill="#006A87"
                      d="M851 800h143V387.1l-143-82.5z" />
                <path fill="#FF6C50"
                      d="M994 222l-143 82.6 143 82.5 143-82.5z" />
                <path fill="#26DEEB"
                      d="M994 387.1v165.2l-143 82.5V800h429V552.3l-143 82.5V304.6z" />
                <path fill="#006A87"
                      d="M708 800h143V634.8l-143-82.5z" />
                <path fill="#FF6C50"
                      d="M708 552.3l143 82.5 143-82.5-143-82.6z" />
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'OwPublicBackground',

        props: {
            width: {
                type: Number,
                default: 1280,
                required: false,
            },
            height: {
                type: Number,
                default: 800,
                required: false,
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-background.scss';
</style>
