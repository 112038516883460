import UAParser from 'ua-parser-js'

const userAgentParser = new UAParser()
const isMobileDevice = typeof window !== 'undefined' && userAgentParser.getDevice().type === 'mobile'
const isTabletDevice = typeof window !== 'undefined' && userAgentParser.getDevice().type === 'tablet'

// This is used to track the pages before logging in.
export const anonymousUser = () => {
    const device = getDevice()
    return {
        anonymous: false,
        key: 'anonymous', // trying to cut down on the number of MAU's
        custom: {
            browser: userAgentParser.getResult().browser.name,
            device,
        },
    }
}

export const createLdUser = (user) => {
    const device = getDevice()

    // If an orbital witness user, then record name and email
    // Otherwise just have the userId for GDPR reasons.
    return {
        key: user.id,
        firstName: user.firstName,
        lastName: user.lastname,
        email: user.email,
        custom: {
            browser: userAgentParser.getResult().browser.name,
            device,
            organisation: user.organisation,
            roles: user.roles,
            pricingModelId: user.pricingModelId,
            pricingModelName: user.pricingModelName,
            titleSummaryCharge: user.titleSummaryCharge,
            intercomHMAC: user.intercomHMAC,
            useClientCodes: user.useClientCodes,
        },
    }
}

const getDevice = () => {
    let device

    if (isMobileDevice) {
        device = 'mobile'
    } else if (isTabletDevice) {
        device = 'tablet'
    } else {
        device = 'desktop'
    }

    return device
}
