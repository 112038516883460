<template>
    <v-dialog :model-value="showDialog"
              class="edit-user-dialog"
              max-width="500px"
              width="100%"
              persistent
              @update:model-value="$emit('update:modelValue', $event.target.value)">
        <v-card>
            <v-card-title>
                {{ $t('action.editMatter') }}
            </v-card-title>
            <v-card-text>
                <v-form ref="form"
                        v-model="isFormValid"
                        data-test="edit-user-dialog-form">
                    <v-text-field v-model="matterName"
                                  :label="`* ${$t('form.label.name')}`"
                                  required
                                  disabled
                                  data-test="edit-user-dialog-name" />
                    <v-autocomplete ref="new-owner-input"
                                    v-model="formData.newOwner"
                                    :items="organisationUsers"
                                    data-test="edit-owner-dialog-owner"
                                    :error-messages="selectionErrors"
                                    :label="`* ${$t('form.label.owner')}`"
                                    clearable
                                    variant="underlined"
                                    required />
                </v-form>
            </v-card-text>
            <ow-card-actions has-secondary-button
                             secondary-button-data-test="edit-user-dialog-btn-cancel"
                             primary-button-data-test="edit-user-dialog-btn-submit"
                             :primary-button-disabled="!isFormValid"
                             :primary-button-text="$t('action.save')"
                             @primary-button-click="save"
                             @secondary-button-click="cancel" />
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
    } from 'vue'

    import MatterApi, { IUpdateMatterOwnerRequest } from '@/api/matter.api'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import { IMatter } from '@/interfaces/matter.interface'
    import i18n from '@/plugins/i18n'

    interface Props {
        showDialog: boolean
        organisationUsers: Array<string>
        selectedMatter?: IMatter
    }

    const props = defineProps<Props>()
    const emit = defineEmits<{
        (e: 'cancel'): void
        (e: 'submit', response): void
        (e: 'update:modelValue', value: boolean): void
    }>()

    const isFormValid = ref<boolean>(true)
    interface FormData {
        newOwner: string
    }
    const formData = ref<FormData>({
        newOwner: '',
    })

    const selectionErrors = computed(() => {
        const errors = []
        // NOTE: (DOBO) createdByUser is not part of the IMatter interface
        if (formData.value.newOwner === props.selectedMatter?.createdByUser) {
            errors.push(i18n.global.t('form.error.invalidOwner'))
        }
        return errors
    })

    const matterName = computed(() => {
        return props.selectedMatter?.name ?? ''
    })

    async function save() {
        if (isFormValid.value) {
            const request: IUpdateMatterOwnerRequest = {
                id: Number(props.selectedMatter.id),
                newOwnerEmail: formData.value.newOwner,
                type: props.selectedMatter.type || 'Other',
                name: props.selectedMatter.name || 'Unknown',
            }
            const response = await MatterApi.updateMatterOwner(request)
            clearForm()
            emit('submit', response)
        }
    }

    function cancel() {
        clearForm()
        emit('cancel')
    }

    function clearForm() {
        formData.value.newOwner = ''
    }

    defineExpose({
        formData,
        isFormValid,
    })

</script>
