import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import {
    Layer,
    Tile as TileLayerCanvas,
} from 'ol/layer'
import TileLayer from 'ol/layer/WebGLTile'
import XYZ from 'ol/source/XYZ'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import olMap from 'ol/Map'
import { LayerNames } from '@/consts/map-layers'

type AzureMapsAerialLayerOptions = {
    getTargetMapFn?: () => olMap,
    snapshotConfig?: LayerSnapshotModel
}

export class AzureMapsAerialLayer implements IMapSnapshotLayer {
    private apiKey: string
    public name: string = LayerNames.AzureMapsAerial
    private layer: TileLayerCanvas<XYZ> | TileLayer
    private options: AzureMapsAerialLayerOptions
    private loadingTilesCount = 0

    constructor(args: AzureMapsAerialLayerOptions, settings: any) {
        this.options = args
        this.apiKey = settings.azureMapsKey
        if (args.snapshotConfig) {
            this.initialiseFromSnapshotConfig()
        } else {
            this.layer = this.getAzureMapsTileLayer()
        }
        this.layer.set('getOwLayer', () => this)
        this.layer.set('name', LayerNames.AzureMapsAerial)
        this.layer.getSource().on('tileloadstart', () => {
            this.loadingTilesCount++
        })
        this.layer.getSource().on(['tileloadend', 'tileloaderror'], () => {
            this.loadingTilesCount--
        })
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        return undefined
    }

    getLayer(): Layer {
        return this.layer
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: LayerNames.AzureMapsAerial,
            configJson: JSON.stringify({
                layerName: LayerNames.AzureMapsAerial,
            }),
        }
    }

    initialiseFromSnapshotConfig(): void {
        this.layer = this.getAzureMapsTileLayer()
    }

    getAzureMapsTileLayer = () => {
        const layerOptions = {
            name: 'aerial',
            source: new XYZ({
                url: `https://atlas.microsoft.com/map/imagery/png?api-version=1&style=satellite&tileSize=256&view=Auto&zoom={z}&x={x}&y={y}&subscription-key=${ this.apiKey }`,
                attributions: `© ${ new Date().getFullYear() } DigitalGlobe, Microsoft`,
                tileSize: 256,
                cacheSize: 50,
                crossOrigin: 'anonymous',
            }),
            visible: true,
            zIndex: 0,
            projection: CoordinateSystemCode.EPSG900913,
            maxZoom: 21,
            preload: 0,
        }

        // TODO: Switch this for the WebGL Tile Layer when it is fixed - at the moment using it as the base layer causes issues with webgl context being lost
        return new TileLayerCanvas(layerOptions)
    }

    async setVisible(visible: boolean): Promise<void> {
        this.layer?.setVisible(visible)
        return Promise.resolve()
    }

    getVisible(): boolean {
        return this.layer?.getVisible()
    }

    public getIsLoading(): boolean {
        return this.loadingTilesCount > 0
    }
}
