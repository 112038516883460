import { ModelFieldConfig } from "@bryntum/grid"
import BasketItemModel from "./BasketItemModel"

export type AdditionalConsiderations = 'Purchase' | 'Sale' | 'Regeneration'
export type CurrentProposedSiteUse = "Residential" | "Commercial" | "Industrial" | "Mixed" | "Other"

export type ArgSSComboProductConfig = {
    plannedDevelopment?: boolean
    additionalConsiderations?: AdditionalConsiderations
    currentSiteUse: CurrentProposedSiteUse
    proposedSiteUse: CurrentProposedSiteUse
    yearsInCurrentUse: number
}

class BasketItemArgSSComboModel extends BasketItemModel {
    static get fields(): Partial<ModelFieldConfig>[] {
        const fields = super.fields
        return [
            ...fields,
            { name: 'isConfigurable', type: 'boolean', defaultValue: true },
            { name: 'config', type: 'object', defaultValue: {
                plannedDevelopment: false,
                currentSiteUse: undefined,
                proposedSiteUse: undefined,
                yearsInCurrentUse: undefined,
            }},
            { name: 'collapsed', type: 'boolean', defaultValue: false },
        ]
    }

    override get valid(): boolean {
        const config = this.config as ArgSSComboProductConfig
        return super.valid && !!config.currentSiteUse && !!config.proposedSiteUse && config.yearsInCurrentUse !== null
    }

    override productOptionsToJson(): string {
        const productConfig = this.config as ArgSSComboProductConfig
        const result = {
            plannedDevelopment: productConfig?.plannedDevelopment ?? false,
            additionalConsiderations: productConfig?.additionalConsiderations ?? '',
            currentSiteUse: productConfig?.currentSiteUse,
            proposedSiteUse: productConfig?.proposedSiteUse,
            yearsInCurrentUse: productConfig?.yearsInCurrentUse,
        } as ArgSSComboProductConfig

        return JSON.stringify(result)
    }

    override jsonToProductOptions(json: string): void {
        const productConfig = JSON.parse(json) as ArgSSComboProductConfig
        this.config = productConfig
    }
}

export default BasketItemArgSSComboModel
