import { ModelFieldConfig } from "@bryntum/grid"
import BasketItemModel from "./BasketItemModel"

export type HighwaysRaProductConfig = {
    searchPurpose: string
}


class BasketItemHighwaysRaModel extends BasketItemModel {
    static get fields(): Partial<ModelFieldConfig>[] {
        const fields = super.fields
        return [
            ...fields,
            { name: 'isConfigurable', type: 'boolean', defaultValue: true },
            { name: 'config', type: 'object', defaultValue: {
                searchPurpose: '',
            }},
        ]
    }

    override productOptionsToJson(): string {
        const productConfig = this.config as HighwaysRaProductConfig
        const result: HighwaysRaProductConfig = {
            searchPurpose: productConfig?.searchPurpose,
        }

        return JSON.stringify(result)
    }

    override jsonToProductOptions(json: string): void {
        const productConfig = JSON.parse(json) as HighwaysRaProductConfig
        this.config = productConfig
    }
}

export default BasketItemHighwaysRaModel
