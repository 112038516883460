// @/stores/asset-monitoring/index.ts
import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'

import {
    IGetNotificationResponseItem,
    INotificationMessageArguments,
    INotificationSettings,
} from '@/api/notifications.api'
import { IUserEmailPreferences } from "@/api/user-email-preferences.api"
import { NotificationEntityType } from "@/components/asset-monitoring/notification-entity-type.enum"

import amActions from './actions'
import amGetters from './getters'

/**
 * Represents the state of the asset monitoring store.
 */

export interface IAssetMonitoringNotificationType {
    notifications: IGetNotificationResponseItem[],
    notificationMessages: INotificationMessageArguments[],

    totalResults: number
    currentPageIndex: number
    pageSize: number
    sortBy: string,
    filterText?: string
    showOnlyUnread?: boolean,
    lastCheckedDate?: string,

    selectedNotificationTypes?: string[],
    selectedNotificationSubTypes?: string[],
    selectedEntityValues: string[],
}

export interface IAssetMonitoringState {
    isLoading: boolean
    unreadTitleNotifications: number,
    unreadCompanyNotifications: number,

    notificationTypes: {
        [NotificationEntityType.CompanyNumber]: IAssetMonitoringNotificationType,
        [NotificationEntityType.TitleNumber]: IAssetMonitoringNotificationType,
    }

    notificationSettings: INotificationSettings,
    userEmailPreferences: IUserEmailPreferences,
    areNotificationSettingsLoaded: boolean,
    isLoadingSettings: boolean,
    isUpdatingSettings: boolean,
    isLoadingUserEmailPreferences: boolean,
    isUpdatingUserEmailPreferences: boolean,
    areUserEmailPreferencesLoaded: boolean,
    entityType?: NotificationEntityType,
}

export const assetMonitoringOptions = {
    state: (): IAssetMonitoringState => {
        return {
            notificationTypes: {
                [NotificationEntityType.CompanyNumber]: {
                    notifications: [],
                    notificationMessages: [],
                    totalResults: 0,
                    currentPageIndex: 0,
                    pageSize: 100,
                    sortBy: 'DateDesc',
                    selectedEntityValues: [],
                },
                [NotificationEntityType.TitleNumber]: {
                    notifications: [],
                    notificationMessages: [],
                    totalResults: 0,
                    currentPageIndex: 0,
                    pageSize: 100,
                    sortBy: 'DateDesc',
                    selectedEntityValues: [],
                },
            },

            isLoading: false,
            unreadTitleNotifications: 0,
            unreadCompanyNotifications: 0,
            notificationSettings: {
                isActive: false,
                trackDaylist: false,
                trackOcda: false,
                trackOwnership: false,
                trackBoundary: false,
                trackCompaniesHouse: false,
                dailySummaryEmailEnabled: false,
            },
            userEmailPreferences: {
                optedIn: false,
            },
            areNotificationSettingsLoaded: false,
            isLoadingSettings: false,
            isUpdatingSettings: false,
            isLoadingUserEmailPreferences: false,
            isUpdatingUserEmailPreferences: false,
            areUserEmailPreferencesLoaded: false,
            entityType: NotificationEntityType.TitleNumber,
        }
    },
    getters: { ...amGetters },
    actions: { ...amActions },
}

/**
 * The `useAssetMonitoringStore` variable is a store created using the `defineStore` function.
 * It is used to manage the state, getters, and actions related to asset monitoring.
 */
export const useAssetMonitoringStore = defineStore('assetMonitoring', assetMonitoringOptions)

// Enables Hot Module Replacement in Pinia
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAssetMonitoringStore, import.meta.hot))
}
