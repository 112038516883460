<template>
    <ow-modal v-model="promptCreate.show"
              content-class="dialog-group"
              :title="$t('matter.prompts.createGroup.title')"
              persistent>
        <div class="dialog-group__content">
            <span class="caption-regular">
                {{ $t('matter.prompts.createGroup.body') }}
            </span>
            <ow-textfield ref="textfield"
                          v-model="textInput"
                          data-test="matter-group-dialogs-group-name" />
        </div>
        <template #actions>
            <div class="dialog-group__actions">
                <ow-button data-test="matter-group-dialogs-button-create-cancel"
                           @click="handleCancel">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button data-test="matter-group-dialogs-button-create-group"
                           :disabled="!textInput.length"
                           is-primary
                           @click="handleCreate">
                    {{ $t('action.create') }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        MATTER_ADD_TITLES_TO_GROUP,
        MATTER_CREATE_GROUP,
        MATTER_MUTATE_CREATE_GROUP_PROMPT,
        MATTER_MUTATE_CREATE_GROUP_TITLES,
        MATTER_UPDATE_TITLE_SORT,
    } from '@/store/modules/matter/types'

    export default {
        name: 'DialogGroupCreate',
        components: {
            OwButton,
            OwModal,
            OwTextfield,
        },
        data() {
            return {
                textInput: '',
            }
        },
        computed: {
            ...mapState({
                promptCreate: (state: IState) => state.matter.prompts.createGroup,
                currentMatter: (state: IState) => state.matter.currentMatter,
            }),
        },
        watch: {
            'promptCreate.show'(value) {
                if (value) {
                    this.$nextTick(() => this.$refs.textfield?.focus())
                } else {
                    this.textInput = ''
                }
            },
        },
        methods: {
            ...mapMutations({
                setCreateGroupTitles: MATTER_MUTATE_CREATE_GROUP_TITLES,
                showCreateGroupPrompt: MATTER_MUTATE_CREATE_GROUP_PROMPT,
            }),

            ...mapActions({
                addTitlesToGroup: MATTER_ADD_TITLES_TO_GROUP,
                createGroup: MATTER_CREATE_GROUP,
                updateTitleSort: MATTER_UPDATE_TITLE_SORT,
            }),

            executeCallback(newGroup: any) {
                if (this.promptCreate.callback) {
                    this.promptCreate.callback(newGroup)
                }
            },

            async handleCreate() {
                let newSortOrder = 100
                if (this.currentMatter.groups.length > 1) {
                    newSortOrder = this.currentMatter.groups[this.currentMatter.groups.length - 1].sortOrder + 100
                }
                const newGroup = await this.createGroup({
                    matterId: this.currentMatter.id,
                    name: this.textInput,
                    sortOrder: newSortOrder,
                    expanded: true,
                })
                if (this.promptCreate.titles.length > 0) {
                    this.updateTitleSort(this.promptCreate.titles.map((title, index) => ({
                        titleNumber: title.titleNumber,
                        sortOrder: (index + 1) * 100,
                    })))
                    await this.addTitlesToGroup({
                        matterGroupId: newGroup.id,
                        titles: this.promptCreate.titles,
                    })
                    this.setCreateGroupTitles([])
                }

                this.executeCallback(newGroup)
                this.showCreateGroupPrompt({ show: false })
            },

            handleCancel() {
                this.executeCallback(null)
                this.showCreateGroupPrompt({ show: false })
            },
        },
    }
</script>

<style lang="scss">
    @import './dialog-group';
</style>
