import {
    AreaSketch,
    ISketch,
    LineSketch,
    MarkerSketch,
    TextSketch,
} from '@/store/modules/sketches/types/sketch'
import {
    Arrowhead,
    SketchGeometryType,
    SketchSource,
    SketchType,
} from '@/enums/sketches-enums'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { DefaultBoundaryColourPalette } from '@/models/styles/default-boundary-colour-palette'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import { IOwStyle } from '@/store/modules/sketches/types/style'
import { isNullOrWhitespace } from '@/utils/string-utils'
import { SketchesStyleFactory } from '@/models/styles/sketches-style-factory'
import { unique } from '@/utils/array-utils'

export const SketchesFactory = {

    defaultColour: DefaultBoundaryColourPalette,

    sketchFromRecord: (record: {geoJson: string, sketchType: SketchType}) : ISketch => {
        const features = SketchesFactory.featuresFromGeoJson(record.geoJson)
        let sketch: ISketch
        switch (record.sketchType) {
            case SketchType.Area:
                sketch = SketchesFactory.areaSketchFromRecord(record, features)
                break
            case SketchType.Line:
                sketch = SketchesFactory.lineSketchFromRecord(record, features)
                break
            case SketchType.Marker:
                sketch = SketchesFactory.pointSketchFromRecord(record, features)
                break
            case SketchType.Text:
                sketch = SketchesFactory.textSketchFromRecord(record, features)
                break
            default:
                sketch = SketchesFactory.areaSketchFromRecord(record, features)
                console.warn(`Sketch type ${ record.sketchType } not supported, assuming Area.`)
        }
        sketch.features = features
        sketch.features.forEach((feature) => {
            // Assign a sketch id to each feature so that they can be identified.
            feature.setProperties({ sketchId: sketch.id, sketchType: record.sketchType })
        })
        return sketch
    },

    areaSketchFromRecord: (record: any, features: Array<Feature>): AreaSketch => {
        return new AreaSketch(record, features)
    },

    lineSketchFromRecord: (record: any, features: Array<Feature>): LineSketch => {
        return new LineSketch(record, features)
    },

    pointSketchFromRecord: (record: any, features: Array<Feature>): MarkerSketch => {
        return new MarkerSketch(record, features)
    },

    textSketchFromRecord: (record: any, features: Array<Feature>): TextSketch => {
        return new TextSketch(record, features)
    },

    newSketch: (type: SketchType): ISketch => {
        switch (type) {
            case SketchType.Area:
                return SketchesFactory.newAreaSketch()
            case SketchType.Line:
                return SketchesFactory.newLineSketch()
            case SketchType.Marker:
                return SketchesFactory.newMarkerSketch()
            case SketchType.Text:
                return SketchesFactory.newTextSketch()
            default:
                throw new Error(`Sketch type ${ type } not supported.`)
        }
    },
    newAreaSketch: ():AreaSketch => {
        const result = new AreaSketch(null, [])
        const defaultStyle = SketchesStyleFactory.getDefaultSketchStyle(SketchType.Area)
        result.strokeColour = defaultStyle.strokeColour
        result.strokeWidth = defaultStyle.strokeWidth
        result.fillColour = defaultStyle.fillColour
        result.fillOpacity = defaultStyle.fillOpacity
        result.hatch = defaultStyle.hatch
        result.dash = defaultStyle.dash
        result.geoJson = null
        result.comment = null
        result.matterGroupId = null
        result.arrowhead = Arrowhead.None
        result.showLabel = false
        return result
    },
    newLineSketch: ():LineSketch => {
        const result = new LineSketch(null, [])
        const defaultStyle = SketchesStyleFactory.getDefaultSketchStyle(SketchType.Line)
        result.strokeColour = defaultStyle.strokeColour
        result.strokeWidth = defaultStyle.strokeWidth
        result.fillColour = null // not applicable
        result.fillOpacity = null // not applicable
        result.hatch = false // not applicable
        result.dash = defaultStyle.dash
        result.geoJson = null
        result.comment = null
        result.matterGroupId = null
        result.arrowhead = Arrowhead.None
        result.showLabel = false
        return result
    },
    newMarkerSketch: ():MarkerSketch => {
        const result = new MarkerSketch(null, [])
        const defaultStyle: any = SketchesStyleFactory.getDefaultSketchStyle(SketchType.Marker)
        result.strokeColour = defaultStyle?.colour ?? defaultStyle.strokeColour
        result.strokeWidth = defaultStyle.strokeWidth
        result.fillColour = null // not applicable
        result.fillOpacity = null // not applicable
        result.hatch = false // not applicable
        result.dash = false // not applicable
        result.geoJson = null
        result.comment = null
        result.matterGroupId = null
        result.arrowhead = Arrowhead.None
        result.showLabel = false
        return result
    },
    newTextSketch: ():TextSketch => {
        const result = new TextSketch(null, [])
        const defaultStyle: any = SketchesStyleFactory.getDefaultSketchStyle(SketchType.Text)
        result.strokeColour = defaultStyle.strokeColour
        result.strokeWidth = defaultStyle.strokeWidth
        result.fillColour = defaultStyle.fillColour
        result.fillOpacity = defaultStyle.fillOpacity
        result.hatch = false // not applicable
        result.dash = false // not applicable
        result.geoJson = null
        result.comment = null
        result.matterGroupId = null
        result.arrowhead = Arrowhead.None
        result.showLabel = false
        return result
    },
    featuresFromGeoJson: (geoJson: string): Array<Feature> => {
        const reader = new GeoJSON()
        const features = !isNullOrWhitespace(geoJson)
            ? (reader.readFeatures(geoJson, {
                dataProjection: CoordinateSystemCode.EPSG4326,
                featureProjection: CoordinateSystemCode.EPSG27700,
            }))
            : [] as any
        return features
    },
    geoJsonFromFeatures: (features: Array<Feature>): string => {
        return JSON.stringify(new GeoJSON().writeFeaturesObject(features,
            {
                featureProjection: CoordinateSystemCode.EPSG27700,
                dataProjection: CoordinateSystemCode.EPSG4326,
                decimals: 8, // around 1mm precision, ensures coordinates are comparable between read/writes (see API docs).
            }))
    },
    /***
     * Creates new sketch(es) from the given features. This is useful if the features have come from elsewher e.g. a file import.
     * Features without geometries are ignored.
     * @param name - the name of the sketch.
     * @param features - the OpenLayers features.
     * @param visible - whether the sketch should be visible.
     * @param preserveAttributeNames - the names of the attributes to preserve from the original feature.
     */
    newSketchesFromFeatures: (name: string, features: Array<Feature>, visible = true, preserveAttributeNames: Array<string> = []): Array<ISketch> => {
        // Remove features without geometries.
        features = features.filter(x => Boolean(x.getGeometry()))
        if (features.length === 0) {
            return []
        }

        // Remove properties that are not required to avoid conflicts with internal properties.
        features.forEach(feature => {
            const properties = feature.getProperties()
            Object.keys(properties).forEach(key => {
                if (!preserveAttributeNames.includes(key) && key !== 'geometry') {
                    feature.unset(key)
                }
            })
        })

        const geometryTypes: Array<SketchGeometryType> = unique(features.map(x => x.getGeometry()?.getType()))
        return geometryTypes.map(geometryType => {
            const featuresOfType = features.filter(x => x.getGeometry()?.getType() === geometryType)
            let sketch: ISketch, useStyle: Partial<IOwStyle>
            switch (geometryType) {
                case SketchGeometryType.POLYGON:
                case SketchGeometryType.MULTI_POLYGON :
                    sketch = SketchesFactory.newAreaSketch()
                    useStyle = SketchesStyleFactory.getDefaultSketchImportStyle(SketchType.Area)
                    sketch.fillColour = useStyle.fillColour
                    sketch.fillOpacity = useStyle.fillOpacity
                    sketch.strokeColour = useStyle.strokeColour
                    sketch.strokeWidth = useStyle.strokeWidth
                    sketch.dash = useStyle.dash
                    sketch.hatch = useStyle.hatch
                    sketch.showLabel = useStyle.showLabel
                    break
                case SketchGeometryType.LINE_STRING:
                case SketchGeometryType.MULTI_LINE_STRING:
                    sketch = SketchesFactory.newLineSketch()
                    useStyle = SketchesStyleFactory.getDefaultSketchImportStyle(SketchType.Line)
                    sketch.strokeColour = useStyle.strokeColour
                    sketch.strokeWidth = useStyle.strokeWidth
                    sketch.dash = useStyle.dash
                    sketch.arrowhead = useStyle.arrowhead
                    sketch.showLabel = useStyle.showLabel
                    break
                case SketchGeometryType.POINT:
                case SketchGeometryType.MULTI_POINT:
                    sketch = SketchesFactory.newMarkerSketch()
                    useStyle = SketchesStyleFactory.getDefaultSketchImportStyle(SketchType.Marker)
                    sketch.strokeColour = useStyle.strokeColour
                    sketch.strokeWidth = useStyle.strokeWidth
                    sketch.showLabel = useStyle.showLabel
                    break
                default:
                    throw new Error(`Geometry type ${ geometryType } not supported.`)
            }
            sketch.name = name
            sketch.visible = visible
            sketch.features = featuresOfType
            sketch.geoJson = SketchesFactory.geoJsonFromFeatures(featuresOfType)
            sketch.sketchSource = SketchSource.SpatialFileImport
            return sketch
        })
    },
}
