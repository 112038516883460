<template>
    <div class="body-copy no-results d-flex flex-column gr-3 py-3">
        <span v-t="`${ noSearchLabel }`"
              class="caption-italic" />
        <v-divider v-if="scottishSpike" />
        <div v-if="scottishSpike"
             data-test="no-results-scottish-warn"
             class="d-flex gc-2">
            <v-icon :size="16">
                $info
            </v-icon>
            <i18n-t keypath="label.scottishSearchWarning"
                    scope="global"
                    tag="span"
                    class="accents-italic">
                <template #link>
                    <a v-t="'action.addTitles'"
                       class="accents-italic"
                       href="#"
                       @click="onAddTitleClick" />
                </template>
            </i18n-t>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import { TitlePanels } from '@/store/modules/title-panels'
    import {
        TITLE_CLEAR,
        TITLE_MUTATE_ACTIVE_TITLE_PANEL,
    } from '@/store/modules/titles/types'
    import {
        USER_MUTATE_SHOW_MAP_TITLES_NAV } from '@/store/mutation-types'

    const props = defineProps<{
        context: 'CorporateOwner' | 'Place' | 'TitleNumber'
    }>()

    const store = useStore()
    const { t } = useI18n()
    const scottishSpike = computed(() => store.state.config.featureFlags?.scottishSpike)
    const noSearchLabel = computed(() => {
        switch (props.context) {
            case 'CorporateOwner':
                return 'label.noCorporateOwnersFound'
            case 'Place':
                return 'label.noPlacesFound'
            case 'TitleNumber':
                return 'label.noTitlesFound'
            default:
                return ''
        }
    })

    const onAddTitleClick = async () => {
        await store.dispatch(TITLE_CLEAR)
        store.commit(USER_MUTATE_SHOW_MAP_TITLES_NAV, true)
        store.commit(TITLE_MUTATE_ACTIVE_TITLE_PANEL, TitlePanels.ADD)
    }
</script>
