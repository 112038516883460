<template>
    <div ref="gridRef">
        <ow-data-grid :allow-selection="false"
                      :disable-items-per-page-select="true"
                      :headers="headers"
                      :height="gridHeight"
                      :items="documents"
                      :sort-by="sortBy"
                      class="searches-document-table"
                      data-test="searches-document-table-grid"
                      has-pagination
                      item-key="searchOrderId"
                      sort>
            <template #[`item.fileName`]="{ item }">
                <td :title="item.fileName"
                    class="body-regular">
                    {{ item.fileName }}
                </td>
            </template>
            <template #[`item.productName`]="{ item }">
                <td :title="item.productName"
                    class="caption-regular">
                    {{ item.productName }}
                </td>
            </template>

            <template #[`item.createdOn`]="{ item }">
                <td :title="formatDate(item.createdOn)"
                    class="caption-regular">
                    {{ formatDate(item.createdOn) }}
                </td>
            </template>
            <template #[`item.orderId`]="{ item }">
                <td class="caption-regular">
                    <searches-document-actions :document="item"
                                               @download-document="$emit('download-document', item)"
                                               @view-document="$emit('view-document', item)"
                                               @view-order="$emit('view-order', item)" />
                </td>
            </template>
        </ow-data-grid>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import SearchesDocumentActions from '@/components/documents/library/searches-documents/searches-document-actions.vue'
    import useDates from '@/composables/use-dates'
    import useWindowResize from '@/composables/use-window-resize'
    import { ISearchesDocument } from '@/interfaces/searches-document.interface'

    defineProps<{
        documents: Array<ISearchesDocument>,
    }>()

    const emit = defineEmits<{
        (e: 'view-document', args: any): void
        (e: 'view-order', args: any): void
        (e: 'download-document', args: any): void
    }>()

    const { t } = useI18n()

    const sortBy = ref([ { key: 'createdOn', order: 'desc' } ])

    const headers = computed(() => {
        return [
            {
                title: t('table.label.document'),
                align: 'left',
                key: 'fileName',
                width: 300,
            },
            {
                title: t('documents.library.searchesColumns.product'),
                align: 'left',
                key: 'productName',
            },
            {
                title: t('documents.library.searchesColumns.titleOrAddress'),
                align: 'left',
                key: 'location',
            },
            {
                title: t('documents.library.searchesColumns.receivedOn'),
                align: 'left',
                key: 'createdOn',
            },
            {
                title: t('documents.upload.table.headings.actions'),
                align: 'center',
                key: 'orderId',
            },
        ]
    })

    const formatDate = (date: Date) => {
        const { formatDate } = useDates()
        return formatDate(date)
    }

    const gridHeight = ref('0')
    const gridRef = ref(null)
    useWindowResize({
        onResize({ clientHeight }) {
            const copilotHeight = document.querySelector('.cta-copilot')?.clientHeight ?? 80
            gridHeight.value = ((clientHeight - gridRef.value?.getBoundingClientRect().top) - copilotHeight - 60).toString()
        },
    })
</script>

<style lang="scss">
    @import "searches-documents-table";
</style>
