<template>
    <ow-card :loading="isLoading"
             :loading-skeleton="{ rows: 2, columns: 0, expandRows: true }"
             class="basic-information"
             title="Basic Information">
        <div class="main-data-property">
            <ow-feature-toggle v-if="showTitlePanelV2Toggle"
                               class="d-flex align-center gc-2 main-data-property__toggle"
                               :data-test="'title-panel-v2-toggle-fixed'"
                               :data-track="'TITLE-PANEL: toggle v2 panel on'"
                               :feature-key="titlePanelV2Key"
                               :left-label="$t('titlePanel.cards.basicInformation.fullscreenToggle')" />

            <div class="d-flex flex-column">
                <div v-if="selectedTitle?.record?.tenure"
                     class="d-flex flex-column">
                    <label>Tenure</label>
                    <OwClipboard :text="selectedTitle.record.tenure"
                                 data-track="TITLE-DETAILS-PANEL - Copy Tenure info">
                        <p v-if="tenureType != null"
                           :class="{
                               'tenure--freehold': tenureType === 'freehold',
                               'tenure--leasehold': tenureType === 'leasehold'
                           }"
                           class="tenure"
                           data-test="basic-info-tenure">
                            {{ selectedTitle.record.tenure }}
                            <span v-if="selectedTitle?.record?.estateInterest"> - {{
                                selectedTitle?.record?.estateInterest
                            }}</span>
                        </p>
                    </OwClipboard>
                </div>

                <div v-if="hasLeaseTermText"
                     class="d-flex flex-column">
                    <label>Lease Term</label>
                    <ul>
                        <li v-for="(leaseTerm, index) in leaseTermText"
                            :key="index">
                            <OwClipboard :text="leaseTerm"
                                         data-track="TITLE-DETAILS-PANEL - Copy Lease Term info">
                                {{ leaseTerm }}
                            </OwClipboard>
                        </li>
                    </ul>
                </div>

                <div class="d-flex">
                    <app-address-data v-if="selectedTitle?.record?.addresses"
                                      :addresses="selectedTitle.record.addresses" />
                </div>
                <div v-if="isUnregisteredLandIntersectionEnabled && intersectsUnregisteredLand !== null"
                     class="d-flex">
                    <label>Intersection with unregistered land:</label>
                    <span v-if="intersectsUnregisteredLand"
                          v-t="'titlePanel.cards.basicInformation.yes'"
                          class="basic-information__error" />
                    <span v-else
                          v-t="'titlePanel.cards.basicInformation.no'"
                          class="basic-information__ok" />
                </div>
            </div>
        </div>
    </ow-card>
</template>

<script lang="ts">
    import TitleInformationApi from '@/api/title-information.api'
    import OwCard from '@/components/core/ow-card.vue'
    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwFeatureToggle from '@/components/core/ow-feature-toggle.vue'
    import AddressData from '@/components/title-panel/tabs/summary-tab/address-data.vue'
    import { titleDataMixin } from '@/components/title-panel/tabs/summary-tab/title-data.mixin'
    import { titlePanelV2Key } from '@/consts/feature-keys'
    import {Route} from "@/enums/route.enum"
    import featureFlagsMixin from '@/feature-flags/feature-flags-mixin'

    export default {
        name: 'BasicInformation',

        components: {
            appAddressData: AddressData,
            OwCard,
            OwClipboard,
            OwFeatureToggle,
        },

        mixins: [
            titleDataMixin,
            featureFlagsMixin,
        ],

        props: {
            selectedTitle: {
                type: Object,
                default: null,
            },
            titleNumber: {
                type: String,
                default: null,
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                intersectsUnregisteredLand: null,
                titlePanelV2Key,
            }
        },

        computed: {
            showTitlePanelV2Toggle() {
                return this.isTitlePanelV2ToggleEnabled && this.$route.name === Route.MatterMapTitle
            },
        },

        watch: {
            titleNumber: {
                handler(){
                    this.getUnregisteredIntersection()
                },
                immediate: true,
            },
        },

        methods: {
            async getUnregisteredIntersection() {
                if (!this.titleNumber) {
                    return
                }
                this.intersectsUnregisteredLand = await TitleInformationApi.getTitleUnregisteredLandIntersection(this.titleNumber)
            },
        },
    }
</script>

<style lang="scss">
    @import './basic-information-card';
</style>
