import { inject } from 'vue'

export const useCompromise = (): {
    compromise: (text) => {
        dates: any
    }
} => {
    const compromise = inject('compromise') as (text) => {
        dates: any
    }
    return {
        compromise,
    }
}
