import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'

export default class CompaniesHouseApi {
    static API_ROUTE = '/companies-house'
    static ROE_ENDPOINT = '/roe'

    /**
     * Gets the ROE data for the specified company on exact name match
     * @param companyName
     */
    static getROEData(companyName: string): Promise<IHttpClientResponse> {
        const uri = `${ this.API_ROUTE }${ this.ROE_ENDPOINT }/${ companyName }`
        return httpClient.get(uri)
    }
}
