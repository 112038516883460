import qs from 'qs'

import httpClient from '@/api/http-client'
import { MatterTier } from '@/consts/matter-tier'
import { SketchesUnit } from '@/enums/sketches-enums'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'

export interface IMatterListRequest {
    pageSize: number,
    searchText?: string,
    sortField?: string,
    sortAscending?: boolean,
    pageIndex: number,
    onlyOpenMatters?: boolean,
    onlyClosedMatters?: boolean,
    onlyMattersOwnedByUser?: boolean,
    onlyMattersSharedWithUser?: boolean,
    onlyMattersSharedExternally?: boolean
}

export interface IUpdateMatterOwnerRequest {
    newOwnerEmail: string,
    id: number,
    type: string,
    name: string
}

export interface IDuplicateMatterRequest {
    matterId: number,   // The id of the OLD matter
    matterName: string, // The name of the NEW matter
    matterCode: string,
}

export interface IDuplicateMatterResponse {
    matterId: number,   // The id of the NEW matter
}

export default class MatterApi {
    static controller = null

    static END_POINT = 'matters'

    /**
     * Get the list of matters that the current user can access.
     * @static
     * @returns {Promise<T|*>}
     * @deprecated use getMatterListWithPagination now
     */
    static getMatterList(): Promise<any> {
        const uri = `${ this.END_POINT }/list`

        // Currently we only care about Standard tier matters.
        // If that changes, consider adding another method, or passing in the filter rather than defining it here.
        const filter = {
            tiers: [MatterTier.Standard],
        }
        return httpClient.post(uri, qs.stringify(filter))
    }

    /**
     * Get the list of matters that the current user can access.
     * @static
     * @returns {Promise<T|*>}
     */
    static getMatterListWithPagination(request: IMatterListRequest): Promise<any> {
        const uri = `${ this.END_POINT }/list`

        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()

        return httpClient.get(uri, {
            signal: this.controller.signal,
            params: request,
        })
    }

    /**
     * Create a new matter
     * @static
     * @param model {Object}
     * @example
     *  The model should be:
     *      {
     *          name: 'Matter Name',
     *          code: 'MAT001',
     *          type: 'Acquisition',
     *          clientCode: 'Client001',
     *      }
     * @returns {Promise<T|*>}
     */
    static createMatter(model): Promise<any> {
        const uri = `${ this.END_POINT }/create`
        return httpClient.post(uri, qs.stringify(model))
    }

    /**
     * Create a new matter
     * @static
     * @param model {Object}
     * @example
     *  The model should be:
     *      {
     *          id: 1234,
     *          name: 'Matter Name',
     *          code: 'MAT001',
     *          type: 'Acquisition',
     *          clientCode: 'Client001',
     *      }
     * @returns {Promise<T|*>}
     */
    static updateMatter(model): Promise<any> {
        const uri = `${ this.END_POINT }/update`
        return httpClient.post(uri, qs.stringify(model))
    }

    /**
     * Delete a matter
     * @static
     * @param request {Object}
     * @example
     *  The request should be:
     *      {
     *          matterId: 1234,
     *      }
     * @returns {Promise<T | *>}
     */
    static deleteMatter(request): Promise<any> {
        const uri = 'matter/delete'
        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => response.data)
    }

    /**
     * Duplicate a matter
     * @static
     * @returns {Promise<T | *>}
     * @param request {IDuplicateMatterRequest}
     * @example The request should be:
     *     {
     *          matterId: '1234',
     *          matterName: 'New Matter Name',
     *          matterCode: 'New Matter Code',
     *     }
     */
    static duplicateMatter(request: IDuplicateMatterRequest): Promise<IHttpClientResponse<IDuplicateMatterResponse>> {
        const uri = 'matter/duplicate'
        return httpClient
            .post(uri, request)
            .then(response => response)
    }

    /**
     * Sets the current matter on the server
     * @static
     * @param matterId: {String}
     * @returns {Promise<T | *>}
     */
    static setCurrentMatter(matterId): Promise<any> {
        const uri = `${ this.END_POINT }/setcurrent`
        return httpClient
            .post(uri, qs.stringify({
                matterId,
            }))
            .then(response => response.data)
    }

    /**
     * Get the addresses for the provided titles
     * @static
     * @param titles {Array} - array of title numbers to look up
     * @returns {Promise<T | *>}
     */
    static getTitleAddresses(titles): Promise<any> {
        // TODO: make this work as /matters/addressesfortitles!
        const uri = 'matter/addressesfortitles'
        return httpClient
            .post(uri, titles)
            .then(response => response.data)
    }

    /**
     * Add a title to the current matter
     * @static
     * @param request {Object}
     * @returns {Promise<T | *>}
     */
    static setMatterTitles(request): Promise<any> {
        const uri = `${ this.END_POINT }/settitles`
        return httpClient
            .post(uri, request)
            .then(response => response.data)
    }

    /**
     * Add titles to the current matter
     * @static
     * @param request {Object}
     * @example
     *  The request should be:
     *      {
     *          matterId: 1234,
     *          titleNumbers: ['1', '2', '3'],
     *      }
     * @returns {Promise<T | *>}
     */
    static addTitlesToMatter(request): Promise<any> {
        // TODO: make this work as /matters/addtitles!
        const uri = 'matter/addtitles'
        return httpClient
            .post(uri, request)
            .then(response => response.data)
    }

    /**
     * Removes titles from the current matter
     * @static
     * @param request {Object}
     * @example
     *  The request should be:
     *      {
     *          matterId: 1234,
     *          titleNumbers: ['1', '2', '3'],
     *      }
     * @returns {Promise<T | *>}
     */
    static removeTitlesFromMatter(request): Promise<any> {
        const uri = `${ this.END_POINT }/removetitles`
        return httpClient
            .post(uri, request)
            .then()
    }

    /**
     * Update the charges on the current matter
     * @static
     * @param matterId {String}
     * @returns {Promise<T | *>}
     */
    static updateCharges(matterId): Promise<any> {
        const uri = `/matter/${ matterId }/charges`
        return httpClient
            .get(uri)
            .then(response => response.data)
    }

    /**
     * Update the permissions of the matter
     * @static
     * @param request {Object}
     * @returns {Promise<T | *>}
     */
    static updatePermissions(request): Promise<any> {
        const uri = 'matter/sharing/update'
        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => response.data)
    }

    /**
     * Refresh the permissions of the matter
     * @static
     * @param matterId {String}
     * @returns {Promise<T | *>}
     */
    static refreshPermissions(matterId): Promise<any> {
        const uri = `matter/${ matterId }/sharing`
        return httpClient
            .get(uri)
            .then(response => response.data)
    }

    /**
     * Create a group within the matter
     * @static
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          id: 1234,
     *          name: 'Group',
     *          sortOrder: 100,
     *      }
     * @returns {Promise<T | *>}
     */
    static createGroup(request): Promise<any> {
        const uri = 'matter/creategroup'
        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => response.data)
    }

    /**
     * Update an existing group within the matter
     * @static
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          id: 1234,
     *          name: 'Group Name',
     *          sortOrder: 100,
     *      }
     * @returns {Promise<T | *>}
     */
    static UpdateGroup(request): Promise<any> {
        const uri = 'matter/updategroup'
        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => {
                // The response from the server is false for failure, and new the group object for success
                if (response?.data) {
                    return {
                        ok: true,
                        message: '',
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong updating the group ${ request.id }`,
                }
            })
    }

    /**
     * Delete an existing group within the matter
     * If `removeTitles` is true, then the titles within the group
     * will also be deleted.
     * @static
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          matterGroupId: 1234,
     *          removeTitles: true/false,
     *      }
     * @returns {Promise<T | *>}
     */
    static DeleteGroup(request): Promise<any> {
        const uri = 'matter/removegroup'
        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => {
                // The response is true/false from the server
                if (response?.data === true) {
                    return {
                        ok: true,
                        message: '',
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong updating the group ${ request.matterGroupId }`,
                }
            })
    }

    /**
     * Get the addresses for all the titles in the matter.
     * @static
     * @param matterId {String}
     * @returns {Promise<T | *>}
     */
    static getTitleAddressesForMatter(matterId, abortController = null): Promise<any> {
        if (!abortController) {
            if (this.controller) {
                this.controller.abort()
            }

            this.controller = new AbortController()
            abortController = this.controller
        }

        const uri = `matter/addresses/${ matterId }`

        try {
            return httpClient
                .get(uri, { signal: abortController.signal })
                .then(response => response?.data || [])
                .finally(() => {
                    this.controller = null
                })
        } catch (error) {
            return Promise.resolve([])
        }
    }

    /**
     * Add the selected titles to the group
     * @static
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          matterGroupId: 987,
     *          titleNumbers: ['XYZ987', 'ABC123'],
     *      }
     * @returns {Promise<T | *>}
     */
    static addTitlesToGroup(request): Promise<any> {
        const uri = 'matter/movetitlestogroup'
        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => response.data)
    }

    /**
     * Delete the selected titles from the group
     * @static
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          matterId: 1234,
     *          matterGroupId: 987,
     *          titleNumbers: ['XYZ987', 'ABC123'],
     *      }
     * @returns {Promise<T | *>}
     */
    static deleteTitlesFromGroup(request): Promise<any> {
        const uri = 'matter/removetitlesfromgroup'
        return httpClient
            .post(uri, qs.stringify(request))
            .then(response => response.data)
    }

    /**
     * Update the sort order of the titles within the matter
     * @static
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          matterId: 1234,
     *          items: [
     *              { titleNumber: 'ABC123', sortOrder: 900 }
     *          ],
     *      }
     * @returns {Promise<T | *>}
     */
    static updateTitleSortOrder(request): Promise<any> {
        const uri = 'matter/titles/updatesort'
        return httpClient
            .post(uri, request)
            .then(response => {
                // The response is true/false from the server
                if (response?.data?.ok) {
                    return {
                        ok: true,
                        message: '',
                    }
                }

                return {
                    ok: false,
                    message: `Something went wrong updating the title sort order: ${ response.errorMessage }`,
                }
            })
    }

    /**
     * Update the sort order of the groups within the matter.
     * @static
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          matterId: 1234,
     *          items: [
     *              { groupId: 'ABC123', sortOrder: 900 }
     *          ],
     *      }
     * @returns {Promise<T | *>}
     */
    static updateGroupTitleSortOrder(request): Promise<any> {
        const uri = 'matter/groups/updatesort'
        return httpClient
            .post(uri, request)
    }

    /**
     * Import titles from the selected area into the matter
     * @param matterId {Number}
     * @param request {Object}
     * @example
     *  The request object should be:
     *      {
     *          geometries: {},
     *          matterGroupId: 1234,
     *      }
     *  The geometries can be found using `MATTER_DRAW_GET_WKT`
     * @returns {Promise<T | *>}
     */
    static importFromArea(matterId, request): Promise<any> {
        const uri = `matter/${ matterId }/importfromarea`
        return httpClient
            .post(uri, qs.stringify(request))
    }

    /**
     * Import titles from an uploaded spreadsheet
     * @param matterId {Number}
     * @param request {Object}
     * @example
     *  The request object should be the file upload form data plus:
     *      {
     *          matterGroupId: 1234,
     *          file: '',
     *      }
     * @returns {Promise<T | *>}
     */
    static importFromSpreadsheet(matterId, request): Promise<any> {
        const uri = `matter/${ matterId }/importfromspreadsheet`
        return httpClient
            .post(uri, request, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
    }

    /**
     * Updates "isRead" attribute on matter
     * @param matterId {Number}
     * @param isMatterRead {Boolean}
     * @example
     *  The request object should be the file upload form data plus:
     *      {
     *          matterId: 23,
     *          isRead: true,
     *      }
     * @returns {Promise<T | *>}
     */
    static updateMatterReadStatus(matterId, isMatterRead): Promise<any> {
        const uri = `userMatterActions/${ matterId }?isRead=${ isMatterRead }`
        return httpClient.put(uri, {})
    }

    /**
     * Updates sketch visibility on map
     * @param matterId {Number}
     * @param visible {Boolean}
     * @example
     *  The request object should be as:
     *      {
     *          matterId: 23,
     *          visible: true,
     *      }
     * @returns {Promise<T | *>}
     */
    static updateSketchesVisibleMap(matterId, visible): Promise<any> {
        const uri = `matter/${ matterId }/sketch-visibility/${ visible }`
        return httpClient
            .put(uri, {})
    }

    /**
     * Updates sketch settings
     * @param matterId {Number}
     * @param settings {Object}
     * @example
     *  The request object should be as:
     *      {
     *          matterId: 23,
     *          settings: {
     *             sketchesUnit: 0,
     *             sketchesVisibleMap: true,
     *          },
     *      }
     * @returns {Promise<T | *>}
     */
    static updateSketchesSettings(matterId, { sketchesUnit, sketchesVisibleMap }: {sketchesUnit: SketchesUnit, sketchesVisibleMap: boolean}): Promise<any> {
        const uri = `matter/${ matterId }/sketch-settings`
        return httpClient
            .put(uri, {
                matterId,
                sketchesUnit,
                sketchesVisibleMap,
            })
    }

    /**
     * Updates status attribute on matter
     * @param matterId {Number}
     * @param state {Number}
     * @example
     *  The request object should be as:
     *      {
     *          matterId: 23,
     *          state: 1,
     *      }
     * @returns {Promise<T | *>}
     */
    static updateMatterState(matterId, state): Promise<any> {
        const uri = 'matters/update-state'
        return httpClient
            .post(uri, {
                matterId,
                state,
            })
    }

    /**
     * Get a list of all matters in an organisation
     * @static
     * @returns {Promise<T|*>}
     */
    static getMatterListForOrganisation(request: IMatterListRequest, organisationId: number): Promise<any> {
        const uri = `${ this.END_POINT }/list/${ organisationId }`

        if (this.controller) {
            this.controller.abort()
        }

        this.controller = new AbortController()

        return httpClient.get(uri, {
            signal: this.controller.signal,
            params: request,
        })
    }

    /**
     * Update the owner of a matter
     * @static
     * @returns {Promise<T|*>}
     */
    static updateMatterOwner(request: IUpdateMatterOwnerRequest): Promise<any> {
        const uri = `${ this.END_POINT }/update-owner`

        return httpClient.post(uri, request)
    }

    static abortAllRequests(): void {
        if (this.controller) {
            this.controller.abort()
        }
    }

    /**
     * Checks if the user can access the specified matter.
     *
     * @param {number} matterId - The ID of the matter to check access for.
     * @returns {Promise<IHttpClientResponse<boolean>>} - A promise that resolves to an Http client response containing a boolean value indicating if the user can access the matter.
     */
    static canAccessMatter(matterId: number): Promise<IHttpClientResponse<boolean>> {
        return httpClient.get(`${ this.END_POINT }/${ matterId }/can-access`)
    }
}
