<template>
    <div id="admin-settings-page">
        <div class="fixed-header">
            <header>
                <h1 class="content__heading">
                    Settings
                </h1>
            </header>
        </div>
        <app-settings />
    </div>
</template>

<script>
    import Settings from '@/components/admin/admin-settings.vue'

    export default {
        name: 'AdminSettings',

        components: {
            appSettings: Settings,
        },
    }
</script>

<style lang="scss" scoped>
    @import './admin-settings';
</style>
