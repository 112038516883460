import { selectedOrganisationInitialState } from './index'
import {
    MUTATE_AVAILABLE_PRODUCTS,
    MUTATE_INITIALISE_SELECTED_ORGANISATION,
    MUTATE_LIST,
    MUTATE_PRICING_MODELS,
    MUTATE_PROFILE,
    MUTATE_SELECTED_ORGANISATION,
    MUTATE_SELECTED_ORGANISATION_SECTION,
} from './types'

export default {
    [MUTATE_LIST](state, payload) {
        state.list = payload
    },

    [MUTATE_PROFILE](state, payload) {
        state.profile = payload
    },

    [MUTATE_PRICING_MODELS](state, payload) {
        state.pricingModels = payload
    },

    [MUTATE_AVAILABLE_PRODUCTS](state, payload) {
        state.availableProducts = payload
    },

    [MUTATE_SELECTED_ORGANISATION](state, payload) {
        state.selectedOrganisation = {
            ...state.selectedOrganisation,
            ...payload,
        }
    },

    [MUTATE_SELECTED_ORGANISATION_SECTION](state, payload) {
        state.selectedOrganisationSection = {
            ...state.selectedOrganisationSection,
            ...payload,
        }
    },

    [MUTATE_INITIALISE_SELECTED_ORGANISATION](state) {
        state.selectedOrganisation = { ...selectedOrganisationInitialState }
    },
}
