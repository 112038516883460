<template>
    <div class="am-alert-filter-pills d-flex align-center"
         :class="{
             'am-alert-filter-pills--scrollable': scrollable,
         }">
        <div v-if="isNullOrEmpty(filters)"
             class="am-alert-filter-pills__empty d-flex align-center">
            No filters applied
        </div>
        <div v-else
             class="am-alert-filter-pills__items-container d-flex align-center">
            <ow-button v-if="scrollable"
                       class="am-alert-filter-pills__scroll-left"
                       icon
                       is-flat
                       small
                       @click="onScrollLeft">
                <template #iconPrefix>
                    <v-icon>$chevron-left</v-icon>
                </template>
            </ow-button>
            <div ref="pillsElement"
                 class="am-alert-filter-pills__items">
                <ow-chip v-for="(item, index) in filters"
                         :key="index"
                         :label="item.title"
                         color="dark"
                         :is-caps="false"
                         :disabled="disabled"
                         @click="$emit('remove', item)" />
            </div>
            <ow-button v-if="scrollable"
                       class="am-alert-filter-pills__scroll-right"
                       icon
                       small
                       is-flat
                       @click="onScrollRight">
                <template #iconSuffix>
                    <v-icon>$chevron-right</v-icon>
                </template>
            </ow-button>
            <ow-button v-if="!isNullOrEmpty(filters)"
                       class="ml-3"
                       is-link
                       :disabled="disabled"
                       @click="$emit('clear')">
                Clear All
            </ow-button>
        </div>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        nextTick,
        ref,
        watch,
    } from "vue"

    import OwButton from "@/components/core/ow-button-ds.vue"
    import OwChip from "@/components/core/ow-chip.vue"
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const scrollable = ref(false)
    const pillsElement = ref()

    const props = defineProps<{
        filters: IOptionLinkItem[]
        disabled?: boolean
    }>()

    defineEmits<{
        remove: (item: IOptionLinkItem) => void
        clear: () => void
    }>()

    const onScrollRight = () => {
        if (pillsElement.value) {
            pillsElement.value.scrollLeft += 100
        }
    }

    const onScrollLeft = () => {
        if (pillsElement.value) {
            pillsElement.value.scrollLeft -= 100
        }
    }

    watch(() => props.filters, async () => {
        if (!pillsElement.value) {
            return
        }
        await nextTick()
        scrollable.value = pillsElement.value?.scrollWidth > pillsElement.value?.clientWidth
    }, {
        immediate: true,
        deep: true,
    })
</script>

<style scoped lang="scss">
@import './am-alert-filter-pills';
</style>
