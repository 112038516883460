import { MapBrowserEvent } from 'ol'
import { FeatureLike } from 'ol/Feature'
import {
    Fill,
    Style,
} from 'ol/style'
import { StyleLike } from 'ol/style/Style'

import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerNames } from '@/consts/map-layers'
import i18n from '@/plugins/i18n'
import {
    BaseMvtDataLayer,
} from '@/store/modules/map/layers/base-mvt-data-layer'
import {
    FloodZoneLayerParams,
    getFloodZoneHoverTextArray,
    getFloodZoneRolloverOptions,
} from '@/store/modules/map/layers/flood-layers-common'
import { IOwSketchOrStyleProps } from '@/store/modules/sketches/types/style'

import { FloodZoneFeaturesType } from './flood-layers-common'

const floodZone3Style: Partial<IOwSketchOrStyleProps> = {
    fillColour: 'rgba(50, 119, 225, 0.4)',
    strokeColour: 'transparent',
}
// This layer is currently updated manually. Note the attribution text is hard-coded.
export class FloodZone3Layer extends BaseMvtDataLayer {
    private readonly onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void

    constructor(params: FloodZoneLayerParams, settings: any) {
        super(params, settings)
        this.layerName = 'ow:vflood_zone_3_2024'
        this.name = LayerNames.FloodZone3
        this.rolloverOptions = getFloodZoneRolloverOptions(LayerNames.FloodZone3, this.style)
        this.attributionText = [i18n.global.t('map.options.flood.attribution.englandAndWales')]

        if (this.interactive) {
            this.onHoverTextChangeCallback = params.onHoverTextChangeCallback

            this.onVisibilityChangedFn = (visible: boolean) => {
                this.eventLogger?.logEvent({
                    type: 'MAP - Toggle flood zone 3 layer on map',
                    metadata: visible,
                })
            }
            this.onOverFeaturesChangedFn = (features: Array<FeatureLike>, event: MapBrowserEvent<any>) => {
                const propertiesArray: Array<FloodZoneFeaturesType> = features
                    .map(x => x.getProperties() as FloodZoneFeaturesType)

                const textArray = getFloodZoneHoverTextArray(propertiesArray)
                this.onHoverTextChangeCallback(textArray, event)
            }
        }
        this.initialiseLayer()
    }

    public name: string = LayerNames.ConservationAreas

    public style:StyleLike = new Style({
        zIndex: 315,
        fill: new Fill({ color: floodZone3Style.fillColour }),
    })

    getKeyItems(): Array<KeyConfigItemModel> {
        const featuresInExtent = this.layer
            .getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
        if (!featuresInExtent.length) {
            return []
        }

        return [{
            id: this.name,
            label: i18n.global.t('map.options.flood.text_zone_3') as string,
            style: floodZone3Style,
        }]
    }
}
