<template>
    <div class="map-rollover-section-item pb-2">
        <div class="map-rollover-section-item__key"
             :style="{
                 backgroundColor: keyColour,
             }" />
        <div>
            <div v-if="header"
                 class="map-rollover-section-item__header"
                 :class="{
                     'caption-regular': !displayAsText,
                     'label-caps-smallest': displayAsText,
                     'pb-1': visibleItems.length > 1,
                 }">
                {{ header }}
            </div>

            <div v-if="displayAsText"
                 class="map-rollover-section-item__text">
                <span>
                    {{ extended }}
                </span>
            </div>
            <div v-else-if="visibleItems.length > 0">
                <div v-for="field in visibleItems"
                     :key="`extended-${field.name}`"
                     class="d-flex align-near map-rollover-section-item__child">
                    <div class="map-rollover-section-item__child-field mr-1">
                        {{ `${field?.name}:` }}
                    </div>
                    <div class="map-rollover-section-item__child-value">
                        {{ field?.value }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
    } from 'vue'

    const props = defineProps<{
        header?: string,
        extended?: Record<string, string>[] | string,
        collapsed?: boolean,
        keyColour: string,
    }>()

    const visibleItems = computed(() => {
        if (!props.extended || typeof props.extended === 'string') {
            return []
        }

        if (props.collapsed) {
            return [props.extended?.[0]]
        }

        return props.extended
    })

    // if extended is a string, display it as text
    const displayAsText = computed(() => typeof props.extended === 'string')
</script>

<style lang="scss" scoped>
    @import './map-rollover-section-item.scss';
</style>
