<template>
    <div id="exporting-icons">
        <ow-xls-exporting-icon v-if="isExcel"
                               height="30px"
                               width="30px" />
        <ow-doc-exporting-icon v-else
                               height="30px"
                               width="30px" />
    </div>
</template>

<script lang="ts">
    import OwDocExportingIcon from '@/components/core/icons/ow-icon-doc-exporting.vue'
    import OwXlsExportingIcon from '@/components/core/icons/ow-icon-xls-exporting.vue'
    import { DOC_TYPE_ENUM } from "@/enums/reporting-api.enum"

    export default {
        name: 'ExportingIcons',

        components: {
            OwDocExportingIcon,
            OwXlsExportingIcon,
        },

        props: {
            documentIcon: {
                type: String,
                required: false,
                default: DOC_TYPE_ENUM.DOC,
                validator(value: string) {
                    return [DOC_TYPE_ENUM.DOC, DOC_TYPE_ENUM.EXCEL].includes(value)
                },
            },
        },

        computed: {
            isExcel() {
                return this.documentIcon === DOC_TYPE_ENUM.EXCEL
            },
        },
    }
</script>

<style lang="scss">
    @import 'exporting-icons.scss';
</style>
