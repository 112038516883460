import { OVERLAYS_UPDATE_OVERLAY_NAME } from "@/store/modules/overlays/types"
import {
    OverlayListItemModel,
    OverlayModel,
    UpdateOverlayRequest,
} from "@/components/map/overlays/overlays-types"
import OverlaysApi from "@/api/overlays.api"

export default {
    async [OVERLAYS_UPDATE_OVERLAY_NAME]({}, overlay: OverlayListItemModel | OverlayModel) {
        if (overlay) {
            const request: UpdateOverlayRequest = {
                name: overlay.name,
            }
            const id = overlay.overlayId || overlay.id
            if (id) await OverlaysApi.updateOverlay(id, request)
        }
    },
}
