import { AppVersion } from '@/models/app-version.model'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import Geometry from 'ol/geom/Geometry'
import { getGeometryForTitleNumberResult } from '@/api/mock-responses/map/get-geometry-for-title-number.results'
import { getGeoserverApiUri } from '@/utils/environment.utils'
import httpClient from '@/api/http-client'
import { isNullOrWhitespace } from '@/utils/string-utils'
import { STORY_TEST_TITLE_NUMBER } from '@/consts/story-testing'

const MAX_TITLES_PER_REQUEST = 50

export default class MapApi {
    /**
     * Get the feature polygons for the selected title number
     * @static
     * @param titleNumber {String}
     * @param layer {String} - NPS layer string from the configuration
     * @returns {Promise<T | *>}
     */
    static async getGeometryForTitleNumber(titleNumber, layer) {
        if (isNullOrWhitespace(titleNumber)) {
            return {
                ok: false,
                message: 'Title number is required',
            }
        }

        if (isNullOrWhitespace(layer)) {
            return {
                ok: false,
                message: 'Layer is required',
            }
        }

        if (titleNumber.toLowerCase() === STORY_TEST_TITLE_NUMBER) {
            return getGeometryForTitleNumberResult
        }
        const uri = `${ getGeoserverApiUri() }/wfs?request=getfeature&typeNames=${ layer }&srsName=${ CoordinateSystemCode.EPSG27700 }&outputFormat=application/json&cql_filter=title_no%3D%27${ titleNumber }%27&appVersion=${ AppVersion.currentAppVersion }`
        return await (await fetch(uri)).json()
    }

    /**
     * Get the feature polygons for the provided title numbers
     * @static
     * @param titleNumbers {Array} - An array of title number strings
     * @param layer {String} - NPS layer string from the configuration, the default is ow:nps
     * @returns {Promise<Feature<Geometry>[]>}
     */
    static async getGeometryForMultipleTitleNumbers(titleNumbers, layer = 'ow:nps'): Promise<Feature<Geometry>[]> {
        const featuresGeometryList: Feature<Geometry>[] = []
        const titleStringArrays = [] // Array to store groups of title strings
        let titleCount = 0 // Variable to keep track of the number of titles in a group

        // make sure titleNumbers is unique
        titleNumbers = [...new Set(titleNumbers)]

        for (let i = 0; i < titleNumbers.length; i++) {
            // Check if a new group needs to be created or the current group should be extended
            if (titleStringArrays.length === 0 || titleCount > MAX_TITLES_PER_REQUEST) {
                // Create a new subarray with the current title number
                titleStringArrays.push(['%27' + titleNumbers[i] + '%27'])
                titleCount = 0 // Reset the title count for the new group
            } else {
                // Add the current title number to the last subarray in titleStringArrays
                titleStringArrays[titleStringArrays.length - 1].push('%27' + titleNumbers[i] + '%27')
            }
            titleCount++ // Increment the title count for the current group
        }

        if (titleStringArrays.length > 0) {
            for (const arr of titleStringArrays) {
                // Load feature from WFS if required
                const inString = arr.join(',')
                const uri = `${ getGeoserverApiUri() }/wfs?request=getfeature&typeNames=${ layer }&srsName=${ CoordinateSystemCode.EPSG27700 }&outputFormat=application/json&cql_filter=title_no%20IN%20(${ inString })&appVersion=${ AppVersion.currentAppVersion }`

                try {
                    const response = await fetch(uri)
                    const body = await response.json()
                    const features = new GeoJSON().readFeatures(body)
                    featuresGeometryList.push(...features)
                } catch (error) {
                    console.error(error)
                }
            }
        }

        return featuresGeometryList
    }

    // Returns point data for title numbers.
    static async getPointsForTitleNumbers(titleNumbers): Promise<Array<{titleNumber: string, x: number, y: number}>> {
        return httpClient
            .post('/hmlr/titles/points', titleNumbers)
            .then(response => response.data)
    }

    // Returns boundary geojson data for title numbers.
    static async getBoundariesForTitleNumbers(titleNumbers) {
        return httpClient
            .post('/hmlr/titles/boundaries', titleNumbers)
            .then(response => response.data)
    }
}
