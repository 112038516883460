import i18n from '@/plugins/i18n'
import { BaseMvtDataLayerParams } from '@/store/modules/map/layers/base-mvt-data-layer'
import { MapBrowserEvent } from 'ol'

import { LayerNames } from '@/consts/map-layers'

import {
    StyleLike,
} from 'ol/style/Style'

import { Style } from 'ol/style'

import { IMapRolloverOptions } from '@/components/map-rollover/common/map-rollover-interfaces'
import { FeatureLike } from 'ol/Feature'
import { unique } from '@/utils/array-utils'

export type BaseFloodRiskFeaturesType = {
    type: string,
    source: string,
}

export type FloodRiskFeaturesType = BaseFloodRiskFeaturesType & {
    id?: number,
    risk: string,
}

export type FloodZoneFeaturesType = BaseFloodRiskFeaturesType & {
    id?: number,
    risk: string,
    // eslint-disable-next-line camelcase
    object_id?: number,
    // eslint-disable-next-line camelcase
    layer_name: string,
    layer: string,
}

export const Risk = {
    2: 'High',
    1: 'Medium',
    0: 'Low',
} as const

export const getFloodZoneHoverTextArray = (propertiesArray: Array<FloodZoneFeaturesType>):string[] => {
    if (!propertiesArray.length) {
        return []
    }
    const layerName = new Set()
    const layerType = new Set()
    const source = new Set()

    propertiesArray.forEach(properties => {
        layerName.add(properties.layer_name)
        layerType.add(properties.type)
        source.add(properties.source)
    })
    return [
        `${ i18n.global.t('map.options.flood.hover.layer') }: ${ Array.from(layerName).join(', ') }`,
        `${ i18n.global.t('map.options.flood.hover.type') }: ${ Array.from(layerType).join(', ') }`,
        `${ i18n.global.t('map.options.flood.hover.source') }: ${ Array.from(source).join(', ') }`,
    ]
}

export const getFloodZoneRolloverOptions = (layerName: LayerNames, style: StyleLike): IMapRolloverOptions => {
    let result: IMapRolloverOptions = {
        showPrimary: true,
        sortOrder: 0,
        category: i18n.global.t('map.rollover.flood.category'),
        primary: i18n.global.t(`map.rollover.flood.${ layerName.toLowerCase() }.primary`),
        getSource(features) {
            const properties = features[0].getProperties() as FloodZoneFeaturesType
            return properties.source
        },
        getStyle: (feature: FeatureLike) => {
            if (typeof style === 'function') {
                return (style(feature, null) as Style)
            } else {
                return style
            }
        },
        getExtended: (features) => {
            let result = []
            if (layerName === LayerNames.FloodRiskSeaAndRivers || layerName === LayerNames.FloodRiskSurfaceWater) {
                const risks = unique((features
                    .map(x => x.getProperties().risk)
                    .sort((a, b) => RiskSortOrder.get(b) - RiskSortOrder.get(a))))

                if (!risks.length) {
                    return result
                }

                // first risk is the highest
                const risk = risks[0]

                // get label for risk
                const label = layerName === LayerNames.FloodRiskSeaAndRivers ? risk.toLowerCase().replace(' ', '_') : Risk[risk].toLowerCase()

                result = [{
                    name: i18n.global.t('map.rollover.flood.extended.risk'),
                    value: i18n.global.t(`map.rollover.flood.risk.${ label }`),
                }]
            } else {
                const properties = features[0].getProperties() as FloodZoneFeaturesType
                result = [
                    {
                        name: i18n.global.t('map.rollover.flood.extended.type'),
                        value: properties.type,
                    },
                ]
            }
            return result
        },
    }

    // if flood risk layer, add additional properties
    if (layerName === LayerNames.FloodRiskSeaAndRivers || layerName === LayerNames.FloodRiskSurfaceWater) {
        result = {
            ...result,
            sortDisplayValues: (a, b) => {
                return RiskSortOrder.get(b) - RiskSortOrder.get(a)
            },
        }
    }
    return result
}

export type FloodZoneLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeCallback: (value: Array<string>, event: MapBrowserEvent<any>) => void
}

export const RiskSortOrder = new Map<string, number>(Object.entries({
    High: 1,
    Medium: 2,
    Low: 3,
    'Very Low': 4,
}))
