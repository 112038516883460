<template>
    <section class="pb-4 pt-1">
        <label id="section-id-label"
               class="caption-regular"
               for="section-id">
            {{ t('licencing.licenceType') }}
        </label>
        <v-select v-model="licenceType"
                  :items="licenceTypeOptionsWithoutMatterLinkShare"
                  data-test="licence-type-dropdown"
                  item-value="id"
                  item-title="label"
                  variant="solo"
                  hide-details />
    </section>
</template>

<script setup lang="ts">
    import { onMounted } from "vue"
    import { useI18n } from "vue-i18n"

    import { inject as licenceProvider } from "@/composables/use-licence-controller"
    import { LicenceType } from "@/enums/licenceType"

    const props = defineProps<{
    }>()

    const { t } = useI18n()

    const licenceType = defineModel('licenceType')

    const emit = defineEmits<{
        'licence-type'
    }>()

    const {licenceTypeOptionsWithoutMatterLinkShare} = licenceProvider()

    onMounted(() => {
        licenceType.value = LicenceType.Standard
        emit('licence-type', licenceType.value)
    })
</script>
