import {
    EnergyBand,
    EPCColours,
} from '@/enums/epc.enum'
import {
    IEpcData,
    IEpcResponse,
} from '@/interfaces/epc.interface'

export function getThemeForEnergyBand(band: EnergyBand): EPCColours {
    switch (band) {
        case EnergyBand.APlus:
            return EPCColours.GreenAPlus
        case EnergyBand.A:
            return EPCColours.GreenA
        case EnergyBand.B:
            return EPCColours.GreenB
        case EnergyBand.C:
            return EPCColours.GreenC
        case EnergyBand.D:
            return EPCColours.YellowD
        case EnergyBand.E:
            return EPCColours.OrangeE
        case EnergyBand.F:
            return EPCColours.OrangeF
        case EnergyBand.G:
            return EPCColours.RedG
        default:
            return EPCColours.GreenAPlus
    }
}

/**
 * Used for getting EPC data for a title number.
 */
export class EPC {
    public epcData: Array<IEpcResponse>

    constructor(epcData: IEpcResponse[]) {
        this.epcData = epcData
    }

    get data(): Array<IEpcData> {
        return this.processData(this.epcData)
    }

    getThemeForEnergyBand(band: EnergyBand): EPCColours {
        return getThemeForEnergyBand(band)
    }

    processData(epcData: Array<IEpcResponse>): Array<IEpcData> {
        const items = []
        epcData.forEach(item => {
            item.epcRecords.forEach((data: IEpcData) => {
                items.push({
                    id: data.lmkKey,
                    address: `${ data.address }, ${ data.postcode }`,
                    source: data.source,
                    energyRating: data.energyRating,
                    energyBand: data.energyBand,
                    potentialEnergyRating: data.potentialEnergyRating,
                    potentialEnergyBand: data.potentialEnergyBand,
                    lodgementDateTime: data.lodgementDateTime,
                    expiryDate: data.expiryDate ?? 'N/A',
                    expired: data.expired,
                    theme: this.getThemeForEnergyBand(data.energyBand as EnergyBand),
                    uprn: data.uprn,
                    uprnSource: data.uprnSource,
                    totalFloorArea: data.totalFloorArea,
                    propertyType: data.propertyType,
                })
            })
        })

        return items
    }
}
