<template>
    <matter-side-panel-add-titles-option import-method="pdf"
                                         :current-selected="modelValue"
                                         :title="$t('matter.addTitlesPanel.uploadTitleRegisters.heading')"
                                         data-test="matter-add-titles-upload-registers"
                                         @select="$emit('update:modelValue', 'pdf')">
        <span v-t="'matter.addTitlesPanel.uploadTitleRegisters.prompt'"
              class="caption-regular" />

        <add-to-group v-model="selectedGroup"
                      select-data-test-attribute="matter-side-panel-add-titles-add-to-group-upload" />

        <app-upload-documents-button :current-matter-id="store.state.matter.currentMatter.id"
                                     button-data-test-attribute="matter-titles-list-upload-documents-btn"
                                     button-data-track-attribute="ADD TITLES - Upload documents"
                                     full-width-button
                                     small-button
                                     :matter-group-id="matterGroupId"
                                     @upload-started="onUploadStarted" />
    </matter-side-panel-add-titles-option>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
    } from 'vue'
    import { useStore } from "vuex"

    import AppUploadDocumentsButton from '@/components/shared/upload-documents-button.vue'
    import { MATTER_REQUEST_SHOW_TITLE_LIST } from '@/store/modules/matter/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import { TITLE_MUTATE_ACTIVE_TITLE_PANEL } from '@/store/modules/titles/types'

    import AddToGroup, { GroupItem } from './add-to-group.vue'
    import MatterSidePanelAddTitlesOption from './matter-side-panel-add-titles-option.vue'

    const store = useStore()
    const selectedGroup = ref<GroupItem | undefined>()

    defineProps<{ modelValue: string }>()
    defineEmits<{
        (e: 'update:modelValue', importMethod: string)
    }>()

    const matterGroupId = computed(
        () => {
            return selectedGroup.value?.id === null || selectedGroup.value?.id === -1
                ? null
                : selectedGroup.value?.id
        })

    const onUploadStarted = async () => {
        await store.dispatch(MATTER_REQUEST_SHOW_TITLE_LIST)
        store.commit(TITLE_MUTATE_ACTIVE_TITLE_PANEL, TitlePanels.LIST)
    }
</script>
