export const pricingPlans = {
    DEFAULT_SUBSCRIPTION: 1,
    PER_MATTER_PRICING_MODEL: 2,
    PAY_PER_CLICK: 3,
}

export const pricingPlanNames = {
    1: 'Default Subscription',
    2: 'Per Matter Charge',
    3: 'Pay Per Click',
}

export const copilotPricingPlanNames = [
    "Credit based",
    "Seat based",
    "Non-paying",
    "None",
]
