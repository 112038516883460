import {
    UploadFileExtensions,
    UploadFileTypes,
} from '@/enums/upload-file-types.enum'

export const SKETCH_COMPLEX_GEOJSON_MIN_LENGTH = 20_000
/***
 * Supports GeoJSON, KML, Shapefile, and zipped Shapefile.
 */
export const SKETCH_IMPORT_SUPPORTED_FILE_EXTENSIONS = [
    UploadFileExtensions.GEOJSON,
    UploadFileExtensions.JSON,
    UploadFileExtensions.KML,
    UploadFileExtensions.SHP,
    UploadFileExtensions.KMZ,
]
/***
 * Supports GeoJSON, KML, Shapefile, and zipped Shapefile.
 */
export const SKETCH_IMPORT_SUPPORTED_FILE_TYPES = [
    UploadFileTypes.GEOJSON,
    UploadFileTypes.KML,
    UploadFileTypes.SHP,
    UploadFileTypes.KMZ,
]
