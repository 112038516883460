<template>
    <div class="add-titles-prompt">
        <h1 v-t="'matter.addTitlesPrompt'"
            class="content__heading" />
        <p v-t="'matter.addTitlesPromptSubtitle'"
           class="add-titles-prompt__subtitle" />
        <a v-t="'matter.addTitlesPromptFAQ'"
           href="https://intercom.help/orbital-witness/en/articles/3346774-how-do-i-add-titles-to-my-matter"
           target="_blank" />
        <ow-button v-if="showAddTitlesButton"
                   full-width
                   is-primary
                   data-test="matter-add-titles-button"
                   @click="addTitlesClicked">
            <span v-t="'matter.addTitles'" />
        </ow-button>
    </div>
</template>

<script lang="ts">
    import OwButton from '@/components/core/ow-button-ds.vue'

    export default {
        components: { OwButton },
        props: {
            showAddTitlesButton: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['add-titles'],
        methods: {
            addTitlesClicked() {
                this.$emit('add-titles')
            },
        },
    }
</script>

<style lang="scss">
    @import 'add-titles-prompt.scss';
</style>
