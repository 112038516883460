<template>
    <div id="titleAnalysisHeader">
        <header class="d-flex">
            <div class="d-flex flex-row align-center flex-grow-1 title-anaysis-header">
                <h1 class="content__heading hidden-sm title-analysis-header__title-wrapper">
                    <ow-icon-tam />
                    Analysis
                </h1>

                <div v-dompurify-html="tipText"
                     class="body-regular tip-text hidden-md" />
            </div>

            <ow-search v-model="search"
                       data-test-id="title-analysis-searchbox"
                       data-track-id="TAM - Search by keywords"
                       prev-button-data-test-id="btn-title-analysis-prev-search-result"
                       next-button-data-test-id="btn-title-analysis-next-search-result"
                       :search-result-index="searchMoveCounter"
                       :total-search-result-count="search && searchResultCount ? searchResultCount : 0"
                       search-context="by keywords"
                       parent-class="title-anaysis-header"
                       location="left"
                       auto-width
                       :height="65"
                       @clear="onSearchType"
                       @next-button-click="nextResult"
                       @previous-button-click="prevResult" />
            <ow-dropdown-menu :hide-button-text-on-small-screens="true"
                              :is-button-disabled="allTitlesPurchased || isOrderInProgressForVisibleRegisters"
                              :is-loading="loadingData"
                              :is-menu-disabled="allTitlesPurchased || isOrderInProgressForVisibleRegisters"
                              :tooltip-text="menuButtonTooltip"
                              alignment="bottom"
                              button-data-test="title-analysis-header-purchase-button"
                              button-text="Order unpurchased titles"
                              activator-class-name="purchase-btn"
                              data-track="TAM - Order unpurchased titles"
                              icon="$buy"
                              size="fullHeight"
                              theme="secondary"
                              tooltip-alignment="left">
                <ow-dropdown-menu-item :count="visibleUnpurchasedTitlesCount"
                                       :disabled="disableOrderVisibleTitlesOption"
                                       :tooltip-text="orderVisibleTitlesTooltipText"
                                       data-test-attribute="title-analysis-header-purchase-button-visible"
                                       data-track="TAM - Order unpurchased visible titles"
                                       text="Order all visible unpurchased titles"
                                       @click="openConfirmationDialog(purchaseType.VISIBLE)" />
                <ow-dropdown-menu-item :count="allUnpurchasedTitles.length"
                                       data-test-attribute="title-analysis-header-purchase-button-all"
                                       data-track="TAM - Order all unpurchased titles"
                                       text="Order all unpurchased titles in your matter"
                                       @click="openConfirmationDialog(purchaseType.ALL)" />
            </ow-dropdown-menu>

            <div class="export-button-menu">
                <ow-button-menu v-model="showExportMenu"
                                :button-text="$t('report.action.export')"
                                class="export-button-menu__button"
                                data-test="title-analysis-header-export-button"
                                data-track="TAM - Export data button"
                                full-height
                                is-primary>
                    <template #links>
                        <ul class="export-button-menu__links">
                            <li v-for="template in exportOptions">
                                <a class="export-button-menu__links--item"
                                   :data-test="template.dataTest"
                                   :class="{ 'export-button-menu__links--disabled-item': template.disabled }"
                                   :title="template.disabledTooltip"
                                   href="#"
                                   @click.prevent="template.clickHandler">
                                    <v-icon class="export-button-menu__links--icon">$download</v-icon>
                                    <div class="content">
                                        <h3 class="caption-highlight">{{ template.name }}</h3>
                                        <p class="accents-small">{{ template.description }}</p>
                                    </div>
                                </a>
                            </li>
                            <li v-if="isHighQExportEnabled">
                                <a class="export-button-menu__links--item"
                                   :class="{ 'export-button-menu__links--disabled-item': !hasPurchasedTitles }"
                                   href="#"
                                   data-test="title-analysis-header-export-button-high-q"
                                   data-track="TAM - Export data button - TAR High Q"
                                   @click.prevent="hasPurchasedTitles && $emit('click', 'downloadHighQTAR')">
                                    <v-icon class="export-button-menu__links--icon">$download</v-icon>
                                    <div class="content">
                                        <h3 v-t="'quickActions.highQExport.title'"
                                            class="caption-highlight" />
                                        <p v-t="'quickActions.highQExport.description'"
                                           class="accents-small" />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </template>
                </ow-button-menu>
            </div>
        </header>
    </div>
</template>
<script>
    import pluralize from 'pluralize'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwIconTam from '@/components/core/icons/ow-icon-tam.vue'
    import OwButtonMenu from '@/components/core/ow-button-menu.vue'
    import OwDropdownMenu from '@/components/core/ow-dropdown-menu.vue'
    import OwDropdownMenuItem from '@/components/core/ow-dropdown-menu-item.vue'
    import OwSearch from '@/components/core/ow-search.vue'
    import { purchaseTitleType } from '@/consts/purchase-title-types.js'
    import featureFlagsMixin from '@/feature-flags/feature-flags-mixin.js'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { CORE_MUTATE_CONFIRMATION_DIALOG } from '@/store/modules/core/types'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import { ANALYSIS_GET_SOURCE_DATA_LOADING_STATUS } from '@/store/modules/title-analysis/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'TitleAnalysisHeader',

        components: {
            OwButtonMenu,
            OwSearch,
            OwDropdownMenu,
            OwDropdownMenuItem,
            OwIconTam,
        },

        mixins: [
            featureFlagsMixin,
        ],

        props: {
            searchResultCount: {
                type: Number,
                default: 0,
            },
            hasFilterApplied: {
                type: Boolean,
                default: false,
            },
            hasTemplateApplied: {
                type: Boolean,
                default: false,
            },
            allUnpurchasedTitles: {
                type: Array,
                required: true,
            },
            visibleUnpurchasedTitles: {
                type: Array,
                required: true,
            },
            hasPurchasedTitles: {
                type: Boolean,
                required: true,
            },
            allTitlesPurchased: {
                type: Boolean,
                required: true,
            },
            isOrderInProgressForVisibleRegisters: {
                type: Boolean,
                required: true,
            },
        },

        emits: [
            'search',
            'prev-result',
            'next-result',
            'export',
            'click',
        ],

        data() {
            return {
                search: '',
                searchMoveCounter: 1,
                selectedPurchaseType: null,
                purchaseType: purchaseTitleType,
                showExportMenu: false,
            }
        },

        computed: {
            ...mapState({
                titleSummaryCharge: state => state.user.titleSummaryCharge,
                currentMatterId: state => state.matter.currentMatter.id,
            }),

            ...mapGetters({
                loadingData: ANALYSIS_GET_SOURCE_DATA_LOADING_STATUS,
            }),

            tipText() {
                return `Read more details in our
                   <a href="https://intercom.help/orbital-witness/en/articles/4291653-title-analysis-mode"
                      data-track="TAM - Open help centre article"
                      target="_blank">
                       Help Centre
                   </a>
                   article.`
            },

            visibleUnpurchasedTitlesCount() {
                if (this.hasFilterApplied || this.hasTemplateApplied) {
                    return this.visibleUnpurchasedTitles.length
                }
                return 0
            },

            orderVisibleTitlesTooltipText() {
                if (this.visibleUnpurchasedTitles.length === 0) {
                    return 'All visible titles have been purchased.'
                }
                if (!this.hasTemplateApplied && !this.hasFilterApplied) {
                    return 'Apply a template or filter to order a subset of unpurchased titles.'
                }

                return ''
            },

            menuButtonTooltip() {
                if (this.allTitlesPurchased) {
                    return 'All titles in your matter have been purchased'
                }
                return ''
            },

            disableOrderVisibleTitlesOption() {
                return this.visibleUnpurchasedTitles.length === 0 || !(this.hasTemplateApplied || this.hasFilterApplied)
            },

            exportOptions() {
                return [
                    {
                        name: this.$t('quickActions.exportCurrentView.title'),
                        description: this.$t('quickActions.exportCurrentView.description'),
                        clickHandler: () => this.$emit('click', 'exportCurrentView'),
                        dataTest: 'title-analysis-header-export-button-current-view',
                        dataTrack: 'TAM - Export data button - Current view',
                        disabled: false,
                    },
                    {
                        name: this.$t('quickActions.downloadTAR.title'),
                        description: this.$t('quickActions.downloadTAR.description'),
                        clickHandler: () => {
                            if (this.hasPurchasedTitles) {
                                this.$emit('click', 'downloadTAR')
                            }
                        },
                        dataTest: 'title-analysis-header-export-button-tar',
                        dataTrack: 'TAM - Export data button - TAR',
                        disabled: !this.hasPurchasedTitles,
                        disabledTooltip: this.$t('quickActions.downloadTAR.disabled'),
                    },
                ]
            },
        },
        watch: {
            search: {
                handler() {
                    this.onSearchType({
                        target: {
                            value: this.search,
                        },
                    })
                },
            },
        },

        methods: {
            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            ...mapMutations({
                setConfirmationDialog: CORE_MUTATE_CONFIRMATION_DIALOG,
            }),

            openConfirmationDialog(type) {
                this.selectedPurchaseType = type
                let countOfTitlesToPurchase = 0

                switch (this.selectedPurchaseType) {
                    case purchaseTitleType.VISIBLE:
                        countOfTitlesToPurchase = this.visibleUnpurchasedTitles.length
                        break

                    case purchaseTitleType.ALL:
                        countOfTitlesToPurchase = this.allUnpurchasedTitles.length
                        break
                }

                const totalCost = countOfTitlesToPurchase * (this.titleSummaryCharge || 3)

                this.setConfirmationDialog({
                    showDialog: true,
                    title: 'Confirm your order',
                    subtitle: `You are ordering ${ countOfTitlesToPurchase } ${ pluralize('title', countOfTitlesToPurchase) } for £${ totalCost }. This will populate the analysis table and store the Official Copies in your document library.`,
                    submitButtonText: `Confirm and pay £${ totalCost }`,
                    submit: this.orderTitles,
                    successTitle: null,
                })
            },

            async orderTitles() {
                let arrayOfTitleNumbers = []
                switch (this.selectedPurchaseType) {
                    case purchaseTitleType.VISIBLE:
                        arrayOfTitleNumbers = this.visibleUnpurchasedTitles.map(title => title.titleNumber)
                        break

                    case purchaseTitleType.ALL:
                        arrayOfTitleNumbers = this.allUnpurchasedTitles.map(title => title.titleNumber)
                        break
                }
                // Remove any title duplicates
                const titleNumbersOfInterest = [ ...new Set(arrayOfTitleNumbers) ]

                // Order the registers.
                const orderRequests = titleNumbersOfInterest.map(titleNumber => DocumentOrderRequest.createRegisterRequest(titleNumber, this.currentMatterId))
                await this.orderDocuments(orderRequests)
            },

            onSearchType(event) {
                let searchValue = event.target.value
                if (isNullOrWhitespace(searchValue)) {
                    searchValue = null
                }
                this.$emit('search', searchValue)
                this.searchMoveCounter = 1
            },

            clearSearch() { // eslint-disable-line vue/no-unused-properties
                this.search = null
                this.$emit('search', null)
            },

            prevResult() {
                if (this.searchMoveCounter > 1) {
                    this.searchMoveCounter--
                } else {
                    this.searchMoveCounter = this.searchResultCount
                }
                this.$emit('prev-result', this.searchMoveCounter)
            },

            nextResult() {
                if (this.searchMoveCounter < this.searchResultCount) {
                    this.searchMoveCounter++
                } else {
                    this.searchMoveCounter = 1
                }
                this.$emit('next-result', this.searchMoveCounter)
            },
        },
    }
</script>
<style lang="scss"
        scoped>
    @import 'title-analysis-header.scss';
</style>
