<template>
    <v-select class="ow-multiselect"
              :model-value="modelValue"
              :items="items"
              multiple
              return-object
              :variant="variant"
              :label="label"
              @update:model-value="onInput">
        <template #selection="{ item, index }">
            <span v-if="index === 0"
                  class="ow-multiselect__selection-text"
                  data-test="ow-multiselect-selection-text">
                {{ getSelectionText(item) }}
            </span>
        </template>
    </v-select>
</template>

<script lang="ts">
    export default {
        props: {
            modelValue: { // selected items
                type: Array,
                required: false,
                default: () => [],
            },
            items: { // should be an array of objects in format [{text, value}, ...]
                type: Array,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            variant: {
                type: String,
                required: false,
                default: 'underlined',
            },
        },

        emits: ['update:modelValue'],

        methods: {
            onInput(val: Array<any>) {
                this.$emit('update:modelValue', val)
            },

            getSelectionText(item: any): string {
                const selectedItems = this.modelValue
                const isAllSelected = selectedItems.length === this.items.length
                const countSuffix = selectedItems.length > 1 ? ` (+${ selectedItems.length - 1 })` : ''
                const itemTitle = item.raw.text ? item.raw.text : item.title
                return isAllSelected ? 'All' : `${ itemTitle }${ countSuffix }`
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-multiselect.scss';
</style>
