import {
    MUTATE_EXPORT_OPTIONS,
    MUTATE_EXPORT_OPTIONS_LOADING,
    MUTATE_SELECTED_EXPORT_OPTION,
    MUTATE_SELECTED_TITLES,
    MUTATE_TITLE_AVAILABILITY,
    MUTATE_TITLE_AVAILABILITY_LOADING,
} from './types'
import { IExportTemplateOptions } from '@/interfaces/export-template-options.interface'
import { IReportsState } from '@/interfaces/store/reports/reports-state.interface'

export default {
    [MUTATE_EXPORT_OPTIONS](state: IReportsState, payload: Array<IExportTemplateOptions>) {
        state.exportOptions = payload
    },

    [MUTATE_EXPORT_OPTIONS_LOADING](state: IReportsState, isLoading: boolean) {
        state.exportOptionsLoading = isLoading
    },

    [MUTATE_SELECTED_EXPORT_OPTION](state: IReportsState, exportOption: IExportTemplateOptions) {
        state.selectedExportOption = exportOption
    },

    [MUTATE_SELECTED_TITLES](state: IReportsState, selectedTitles: string[]) {
        state.selectedTitles = selectedTitles
    },

    [MUTATE_TITLE_AVAILABILITY](state: IReportsState, payload: any) {
        state.titleAvailability = payload
    },

    [MUTATE_TITLE_AVAILABILITY_LOADING](state: IReportsState, isLoading: boolean) {
        state.titleAvailabilityLoading = isLoading
    },
}
