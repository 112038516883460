<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 24 24"
                  desc="Copy icon">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 3.57141V10.6878C15.5381 10.727 15.0916 10.82 14.6667 10.9606V3.55062L9.46723 5.61807C9.4231 5.63757 9.37843 5.65537 9.33333 5.67147V17.7827L10.7124 17.2343C10.7695 17.6883 10.8787 18.1261 11.0338 18.5414L9.46723 19.1643C8.95173 19.3919 8.36487 19.3896 7.8508 19.1575L3.18413 17.0503C2.45972 16.7232 2 15.9977 2 15.2048V4.62337C2 3.17572 3.4777 2.17364 4.81587 2.77788L8.3995 4.39603C8.56983 4.47297 8.7635 4.47297 8.93383 4.39603C8.94307 4.39187 8.95243 4.3879 8.96187 4.38413L14.5328 2.16899C15.0483 1.94142 15.6351 1.94369 16.1492 2.17582L20.8159 4.28302C21.5403 4.61013 22 5.33563 22 6.1285V14.5516C21.7137 13.7435 21.2544 13.0173 20.6667 12.4175V6.1285C20.6667 5.85067 20.5058 5.60597 20.2672 5.4982L16 3.57141ZM8 17.7619V5.67147C7.9497 5.6535 7.8999 5.6334 7.8508 5.61123L4.26716 3.99307C3.8419 3.80105 3.33333 4.1104 3.33333 4.62337V15.2048C3.33333 15.4827 3.49424 15.7274 3.73284 15.8351L8 17.7619ZM18.3333 17.3333C18.7015 17.3333 19 17.0349 19 16.6667C19 16.2985 18.7015 16 18.3333 16H17.3333V15C17.3333 14.6318 17.0349 14.3333 16.6667 14.3333C16.2985 14.3333 16 14.6318 16 15V16H15C14.6318 16 14.3333 16.2985 14.3333 16.6667C14.3333 17.0349 14.6318 17.3333 15 17.3333H16V18.3333C16 18.7015 16.2985 19 16.6667 19C17.0349 19 17.3333 18.7015 17.3333 18.3333V17.3333H18.3333ZM12 16.6667C12 14.0893 14.0893 12 16.6667 12C19.244 12 21.3333 14.0893 21.3333 16.6667C21.3333 19.244 19.244 21.3333 16.6667 21.3333C14.0893 21.3333 12 19.244 12 16.6667ZM16.6667 13.3333C14.8257 13.3333 13.3333 14.8257 13.3333 16.6667C13.3333 18.5076 14.8257 20 16.6667 20C18.5076 20 20 18.5076 20 16.6667C20 14.8257 18.5076 13.3333 16.6667 13.3333Z"
              :fill="isPrimary ? Colors.Primary: Colors.Darks700" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'
    import { Colors } from '@/enums/colors.enum'

    export default {
        name: 'OwOverlayMapIcon',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '24px',
            },
            height: {
                type: String,
                required: false,
                default: '24px',
            },
            isPrimary: {
                type: Boolean,
                required: false,
            },
        },
        data() {
            return {
                Colors,
            }
        },
    }
</script>
