<template>
    <div v-show="!overlayLoading"
         class="fixed-header">
        <header class="header-content">
            <div v-if="documentMetadata != null"
                 class="d-flex flex-column shrink"
                 style="padding-left: 10px">
                <div class="document-title">
                    <strong v-if="documentMetadata.titleNo"
                            data-test="ocr-pdf-viewer-titlenumber">
                        {{ documentMetadata.titleNo }} -
                    </strong>
                    <span data-test="ocr-pdf-viewer-doctype">{{ displayDocumentType }}</span>
                </div>
                <div style="font-size: 12px;">
                    <span v-if="documentMetadata.filedUnderField != null">
                        <span>Filed under </span><span data-test="ocr-pdf-viewer-filedunder">
                            {{ documentMetadata.filedUnderField }}
                        </span>,
                    </span>
                    <span data-test="ocr-pdf-viewer-docdate">{{ documentMetadata.documentDate }}</span>
                </div>
                <div v-if="numPages"
                     style="font-size: 12px;">
                    <span data-test="ocr-pdf-viewer-numpages">{{ numPages }}</span> pages
                </div>
            </div>
            <div id="documentViewerSearch">
                <form data-test="ocr-pdf-viewer-search-form"
                      @submit.prevent="onSearchFormSubmitted">
                    <div class="d-flex flex-row">
                        <v-text-field v-model="searchTextValue"
                                      :loading="searchLoading"
                                      autocomplete="new-password"
                                      clearable
                                      data-test="ocr-pdf-viewer-controls-search-input"
                                      data-track="DOCS - Document viewer search"
                                      hint="Search for a term of interest"
                                      label="Search document"
                                      prepend-inner-icon="$search"
                                      @click:clear="$emit('search-clear-clicked')" />
                        <span v-if="loading && searchText != null"
                              style="padding-top: 17px; padding-left: 9px;">Loading, please wait...</span>
                    </div>
                </form>
            </div>
            <div style="margin-left: 12px;">
                <div v-if="hasSearchResults">
                    <div v-if="searchResultCount > 0 && !loading"
                         data-test="ocr-pdf-viewer-search-nav-container">
                        <v-btn :disabled="searchResultCount < 2"
                               data-test="ocr-pdf-viewer-controls-search-result-previous"
                               data-track="DOCS - Document viewer search previous result"
                               icon
                               variant="text"
                               @click="$emit('show-next-search-result', true)">
                            <v-icon>$menu-left</v-icon>
                        </v-btn>
                        <span v-if="selectedSearchResultNumber != null"
                              :class="{'search-loading': searchLoading}">
                            <span class="hidden-md-and-down">Showing </span>
                            <span data-test="ocr-pdf-viewer-controls-search-result-number">{{ selectedSearchResultNumber }}</span>
                        </span>
                        <span v-if="selectedSearchResultNumber != null"> / </span>
                        <span data-test="ocr-pdf-viewer-controls-search-result-count">{{ searchResultCount }}</span>
                        <v-btn :disabled="searchResultCount < 2"
                               data-test="ocr-pdf-viewer-controls-search-result-next"
                               data-track="DOCS - Document viewer search previous result"
                               icon
                               variant="text"
                               @click="$emit('show-next-search-result', false)">
                            <v-icon>$menu-right</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div v-if="noSearchResultsFound">
                    No results found
                </div>
            </div>
            <v-spacer />

            <v-divider vertical />
            <!-- Zoom controls -->
            <div id="documentViewerZoomControls"
                 class="d-flex flex-row align-center shrink">
                <v-btn color="light blue"
                       data-test="ocr-pdf-viewer-controls-fit-to-height"
                       data-track="DOCS - Document viewer fit to height"
                       icon
                       size="small"
                       variant="text"
                       @click="$emit('fit-to-height')">
                    <v-icon>$fit-to-height</v-icon>
                    <ow-tooltip activator="parent"
                                :position="OwTooltipPosition.Bottom">
                        <span v-t="'documents.viewer.fitToHeight'" />
                    </ow-tooltip>
                </v-btn>
                <v-btn color="light blue"
                       data-test="ocr-pdf-viewer-controls-fit-to-width"
                       data-track="DOCS - Document viewer fit to width"
                       icon
                       size="small"
                       variant="text"
                       @click="$emit('fit-to-width')">
                    <v-icon style="transform:rotate(90deg)">
                        $fit-to-height
                    </v-icon>
                    <ow-tooltip activator="parent"
                                :position="OwTooltipPosition.Bottom">
                        <span v-t="'documents.viewer.fitToWidth'" />
                    </ow-tooltip>
                </v-btn>
                <v-btn :disabled="currentZoom <= minZoom"
                       color="light blue"
                       data-test="ocr-pdf-viewer-controls-zoom-out"
                       data-track="DOCS - Document viewer zoom out"
                       icon
                       size="small"
                       variant="text"
                       @click="$emit('zoom-out')">
                    <v-icon>$minus</v-icon>
                    <ow-tooltip activator="parent"
                                :position="OwTooltipPosition.Bottom">
                        <span v-t="'action.zoomOut'" />
                    </ow-tooltip>
                </v-btn>
                <v-btn :disabled="currentZoom >= maxZoom"
                       color="light blue"
                       data-test="ocr-pdf-viewer-controls-zoom-in"
                       data-track="DOCS - Document viewer zoom in"
                       icon
                       size="small"
                       variant="text"
                       @click="$emit('zoom-in')">
                    <v-icon>$add</v-icon>
                    <ow-tooltip activator="parent"
                                :position="OwTooltipPosition.Bottom">
                        <span v-t="'action.zoomIn'" />
                    </ow-tooltip>
                </v-btn>
                <v-btn color="light blue"
                       data-test="ocr-pdf-viewer-controls-reset-zoom"
                       data-track="DOCS - Document viewer reset zoom"
                       icon
                       size="small"
                       variant="text"
                       @click="$emit('reset-zoom')">
                    <v-icon>$autorenew</v-icon>
                    <ow-tooltip activator="parent"
                                :position="OwTooltipPosition.Bottom">
                        <span v-t="'action.resetZoom'" />
                    </ow-tooltip>
                </v-btn>
            </div>
            <!-- Right buttons -->
            <v-divider vertical />
            <div v-if="currentMatterId"
                 id="documentViewerActions"
                 class="d-flex flex-row align-center">
                <div class="d-inline-block">
                    <v-btn class="hidden-lg-and-up"
                           data-test="ocr-pdf-viewer-overlays-icon"
                           data-track="DOCUMENT-VIEWER - Overlay Plan"
                           size="small"
                           variant="text"
                           @click="onOverlayPlansClicked">
                        <ow-overlay-map-icon />
                    </v-btn>

                    <v-btn class="hidden-md-and-down"
                           :data-test="existingOverlay ? 'ocr-pdf-viewer-overlays-view-button' : 'ocr-pdf-viewer-overlays-create-button'"
                           :data-track="existingOverlay ? 'DOCUMENT-VIEWER - Overlay Plan' : 'DOCUMENT-VIEWER - Overlay Plan'"
                           size="large"
                           variant="text"
                           @click="onOverlayPlansClicked">
                        <ow-overlay-map-icon class="mr-1" />
                        <span>{{ existingOverlay ? $t('documents.viewer.viewOverlay') : $t('documents.viewer.overlayPlan') }} </span>
                    </v-btn>
                </div>
                <div class="d-inline-block">
                    <v-btn class="hidden-lg-and-up"
                           data-test="ocr-pdf-viewer-controls-icon-download"
                           data-track="DOCS - Document viewer download document"
                           icon
                           size="small"
                           variant="text"
                           @click="$emit('on-download-click')">
                        <v-icon>$download</v-icon>
                    </v-btn>

                    <v-btn class="hidden-md-and-down"
                           data-test="ocr-pdf-viewer-controls-button-download"
                           data-track="DOCS - Document viewer download document"
                           size="large"
                           variant="text"
                           @click="$emit('on-download-click')">
                        <span v-t="'action.download'" />
                    </v-btn>
                    <ow-tooltip activator="parent"
                                :position="OwTooltipPosition.Bottom">
                        <span v-t="'documents.viewer.downloadBtnTooltip'" />
                    </ow-tooltip>
                </div>

                <v-divider v-if="isExportButtonVisible"
                           vertical />

                <ow-export-button-menu v-if="isExportButtonVisible"
                                       :current-matter-id="currentMatterId"
                                       :selected-title-numbers="[documentMetadata.titleNo]"
                                       button-height="48px"
                                       is-full-height
                                       is-small
                                       heap-tracker-prefix="DOCUMENT_VIEWER" />

                <v-divider vertical />
                <v-btn class="hidden-lg-and-up"
                       data-test="ocr-pdf-viewer-close"
                       data-track="DOCS - Close document viewer"
                       icon
                       size="small"
                       variant="text"
                       @click="$emit('on-close-click')">
                    <v-icon>$close</v-icon>
                </v-btn>
                <v-btn class="hidden-md-and-down"
                       data-test="ocr-pdf-viewer-controls-button-close"
                       data-track="DOCS - Close document viewer"
                       size="small"
                       variant="text"
                       @click="$emit('on-close-click')">
                    <span v-t="'action.close'" />
                </v-btn>
            </div>
        </header>
    </div>
</template>
<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapGetters,
    } from 'vuex'

    import OverlaysApi from '@/api/overlays.api'
    import OwOverlayMapIcon from '@/components/core/icons/ow-icon-overlay-map.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import OwExportButtonMenu from '@/components/reporting/export-button-menu.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { Route } from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IDocumentMetadata } from '@/interfaces/documents/document-metadata.interface'
    import { GET_LIBRARY_UPLOADED_DOCUMENTS } from '@/store/modules/documents/documents-types'
    import {
        capitalise,
        isNullOrWhitespace,
    } from '@/utils/string-utils'

    export default {
        name: 'OcrPdfViewerControls',

        components: {
            OwExportButtonMenu,
            OwTooltip,
            OwOverlayMapIcon,
        },

        mixins: [
            flagsMixin,
        ],

        props: {
            documentMetadata: {
                type: Object as PropType<IDocumentMetadata>,
                required: false,
            },
            numPages: {
                type: Number,
                required: false,
            },
            searchText: {
                type: String,
            },
            loading: {
                type: Boolean,
                required: true,
            },
            searchResultCount: {
                type: Number,
                required: true,
            },
            selectedSearchResultNumber: {
                type: Number,
                required: false,
            },
            searchLoading: {
                type: Boolean,
                required: true,
            },
            minZoom: {
                type: Number,
                required: true,
            },
            maxZoom: {
                type: Number,
                required: true,
            },
            currentZoom: {
                type: Number,
                required: true,
            },
            currentMatterId: {
                type: [Number, String],
                required: false,
            },
        },

        emits: [
            'fit-to-height',
            'fit-to-width',
            'on-close-click',
            'on-download-click',
            'on-export-click',
            'reset-zoom',
            'search-clear-clicked',
            'set-search-text',
            'show-next-search-result',
            'zoom-in',
            'zoom-out',
        ],

        data() {
            return {
                OwTooltipPosition,
                existingOverlay: null,
                overlayLoading: false,
            }
        },

        computed: {
            ...mapGetters({
                uploadedDocuments: GET_LIBRARY_UPLOADED_DOCUMENTS,
            }),

            searchTextValue: {
                get(): string {
                    return this.searchText
                },
                set(val: string) {
                    this.$emit('set-search-text', val)
                },
            },

            displayDocumentType(): string {
                let documentType = this.documentMetadata.documentType
                if (documentType === HighLevelDocumentType.UploadedOC2) {
                    documentType = HighLevelDocumentType.Uploaded
                }
                return capitalise(documentType)
            },

            hasSearchResults(): boolean {
                return this.searchResultCount > 0 && this.selectedSearchResultNumber > 0
            },

            noSearchResultsFound(): boolean {
                return !isNullOrWhitespace(this.searchText) && !this.loading && !this.hasSearchResults
            },

            isRegisterDocument(): boolean {
                return this.documentMetadata.documentType.toLocaleUpperCase() === 'REGISTER'
            },

            isExportButtonVisible(): boolean {
                return this.isRegisterDocument &&
                    Boolean(this.currentMatterId) &&
                    !this.isUploadedDocument
            },
            isUploadedDocument(): boolean {
                return this.documentMetadata.documentType === HighLevelDocumentType.Uploaded
            },
        },

        async mounted() {
            if (this.currentMatterId) {
                await this.loadOverlays()
            }
        },

        methods: {
            onSearchFormSubmitted(): void {
                this.$emit('set-search-text', this.searchTextValue)
            },
            async loadOverlays(): Promise<void> {
                this.overlayLoading = true
                const overlays = await OverlaysApi.getOverlays(this.currentMatterId)

                this.existingOverlay = overlays.data.find(x => this.isUploadedDocument
                    ? x.documentUploadId === this.documentMetadata.documentId
                    : (x.bgOc2DocumentId === this.documentMetadata.documentId ||
                        x.bgTitlePlanId === this.documentMetadata.documentId))

                if (!this.existingOverlay &&
                    this.documentMetadata.documentType === HighLevelDocumentType.UploadedOC2) {
                    const uploadedOc2 = this.uploadedDocuments.find(x => x.externalId === this.documentMetadata.documentId)
                    this.existingOverlay = overlays.data.find(x => x.documentUploadId === uploadedOc2?.id)
                }

                this.overlayLoading = false
            },
            async onOverlayPlansClicked(): Promise<void> {
                if (this.existingOverlay) {
                    this.$router.push({
                        name: Route.OverlaysList,
                        params: { matterId: this.currentMatterId },
                        query: { overlayId: this.existingOverlay.overlayId },
                    })
                } else {
                    this.$router.push({
                        name: Route.OverlaysCreate,
                        params: { matterId: this.currentMatterId },
                        query: { documentId: this.documentMetadata.documentId },
                    })
                }
            },
        },

    }
</script>
<style lang="scss">
    @import './ocr-pdf-viewer-controls.scss';
</style>
