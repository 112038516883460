<template>
    <div class="ow-coloured-chevron-label"
         :class="`${ theme }${ isLarge ? ' is-large' : '' }${ hasOnlyBorder ? ' has-only-border' : '' }`"
         data-test="ow-coloured-chevron-label">
        <div class="ow-coloured-chevron-label__triangle" />
        <div class="ow-coloured-chevron-label__label"
             data-test="ow-coloured-chevron-label-label">
            {{ label }}
        </div>
    </div>
</template>

<script lang="ts">
    import { EPCColours } from '@/enums/epc.enum'

    export default {
        name: 'OwColouredChevronLabel',
        props: {
            label: {
                type: [String, Number],
                required: true,
            },
            isLarge: {
                type: Boolean,
                required: false,
            },
            theme: {
                type: String,
                required: true,
                validator: (value: string) => {
                    const colours = Object.values(EPCColours) as string[]
                    return colours.includes(value)
                },
            },
        },
        computed: {
            hasOnlyBorder() {
                return this.theme === 'green-a-plus'
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-coloured-chevron-label.scss'
</style>
