import { BoundaryLayer } from '@/store/modules/map/layers/title-boundary-layer/boundary-layer'
import debounce from 'lodash.debounce'
import { Extent } from 'ol/extent'
import {
    Group as LayerGroup,
} from 'ol/layer'
import { MapEvent } from 'ol'
import MatterApi from '@/api/matter.api'
import { PointLayer } from '@/store/modules/map/layers/title-boundary-layer/point-layer'
import { TitleBoundaryLayerSettings } from '@/store/modules/map/layers/title-boundary-layer/settings'
import { LayerNames } from '@/consts/map-layers'
import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { IOwStyle } from '@/store/modules/sketches/types/style'
import olMap from 'ol/Map'
import { getExtendedMapExtentForSnapshot } from '@/components/snapshots/map-snapshots/map-snapshot-utils'
import { unique } from '@/utils/array-utils'
import { ISnapshotPageLayout } from '@/components/snapshots/common/snapshot-interfaces'
import { toRaw } from "vue"

export type MatterTitleBoundaryLayerGroupArgs = {
    useIncreasedPointRenderingLimit: boolean
    getTitlesDataFn: () => any
    onTitleBoundaryClickFn: (titleNumber: string[]) => void,
    getMatterIdFn: () => number // may be used in requests for context.
    snapshotConfig?: LayerSnapshotModel
}

type SnapshotTitleData = Partial<IOwStyle> & {
    titleNumber: string
}

/** Controls the visibility of title boundaries and points for titles within a matter on the target map. The concept of a layer group is common to
 * a lot of map APIs, applications etc. and it is being used in a similar way here. The group is acting as a wrapper layer for the boundary and point
 * layer, controlling which ones should be visible, when they should load data, and so on.
 */
export class MatterTitleBoundaryLayerGroup implements IMapSnapshotLayer {
    public i18nNameRef = 'titles.titles'
    public name:string
    // The layer group will be added to the map, within it various layers that will be turned
    // on and off depending on context e.g. zoom level, number of titles.
    private readonly olLayerGroup: LayerGroup

    // The boundary layer loads title boundaries in situations where it would be useful to see them i.e.
    // they would be on screen, likely one of only a small number, or at an appropriate scale.
    public boundaryLayer: BoundaryLayer

    // The point layer is used to provide a rough indication as to the location of a title, intended to be fast,
    // and displayed at scales where showing the full detailed boundary would provide no benefit.
    public pointLayer: PointLayer

    // A function that will return title data for consideration by the layer.
    public getTitlesDataFn: () => any

    // Returns the matter Id for use in requests.
    private readonly getMatterIdFn: () => number

    public titleNumbersPendingStyleUpdate: Set<string> = new Set<string>()
    public readonly debounceTitleStyleUpdate: () => any
    private visible: boolean
    private readonly interactive: boolean

    // The map that the layer group is added to.
    private map: olMap

    constructor(data: MatterTitleBoundaryLayerGroupArgs) {
        this.name = LayerNames.MatterTitles
        if (data.snapshotConfig) {
            const titleData:SnapshotTitleData = JSON.parse(data.snapshotConfig.configJson)
            this.getTitlesDataFn = () => titleData
            this.debounceTitleStyleUpdate = null
            this.getMatterIdFn = () => null
            this.interactive = false
        } else {
            this.interactive = true
            this.getTitlesDataFn = data.getTitlesDataFn
            this.debounceTitleStyleUpdate = debounce(this._updateTitleStyle, 1500)
            this.getMatterIdFn = data.getMatterIdFn
        }

        if (data.useIncreasedPointRenderingLimit) {
            TitleBoundaryLayerSettings.SMALL_NUMBER_OF_TOTAL_TITLES = TitleBoundaryLayerSettings.INCREASED_NUMBER_OF_TOTAL_TITLES
        }
        this.pointLayer = new PointLayer({
            interactive: this.interactive,
            getTitlesDataFn: this.getTitlesDataFn,
            onPointClickFn: data.onTitleBoundaryClickFn,
        })

        this.boundaryLayer = new BoundaryLayer({
            interactive: this.interactive,
            getTitlesDataFn: this.getTitlesDataFn,
            onTitleBoundaryClickFn: data.onTitleBoundaryClickFn,
            onTitleBoundariesLoadedFn: (titleNumbers) => {
                this.pointLayer.addExcludedTitleNumbers(titleNumbers)
            },
        })

        // The layer group is added to the map
        this.olLayerGroup = new LayerGroup({
            layers: [
                this.pointLayer.layer,
                this.boundaryLayer.layer,
            ],
            zIndex: 15,
        })
        this.olLayerGroup.set('getOwLayer', () => this)
    }

    public async addToMap(map: olMap): Promise<void> {
        this.map = map
        if (this.map) {
            if (!this.map.getLayers().getArray().includes(this.olLayerGroup)) {
                map.addLayer(this.olLayerGroup)
            }
            this.pointLayer.setMap(map)
            this.boundaryLayer.setMap(map)

            // Any follow-up initialisation
            // When the map moves, reevaluate what needs doing with the layers.
            map.getView().on('change', async (e: MapEvent) => {
                await this.onMapMove(e)
            })
            await this.reload()
        }
    }

    public highlightFeaturesByTitleNumber(titleNumber: string):void {
        this.boundaryLayer?.highlightFeaturesByTitleNumber(titleNumber)
        if (!this.boundaryLayer.layer.getVisible()) {
            this.pointLayer?.highlightFeaturesByTitleNumber(titleNumber)
        }
    }

    // Reloads the layer based on new data rather than just changes to styles (consider merging with refresh if possible).
    public async reload(resetZoom: boolean = true): Promise<void> {
        await this.pointLayer.reload()
        await this.boundaryLayer.reload()
        if (this.interactive && resetZoom) {
            this.zoomToExtent()
        }
    }

    // Redraw the layer based on the current title styles,
    public refresh(titleNumbers:Array<string> = []): void {
        this.pointLayer.refresh(titleNumbers)
        this.boundaryLayer.refresh(titleNumbers)

        if (titleNumbers.length > 0) {
            this.persistStyleChanges(titleNumbers)
        }
    }

    public updateLayersDataFn(newFunction : () => any): void {
        this.getTitlesDataFn = newFunction
        this.boundaryLayer.updateTitlesDataFn(newFunction)
        this.pointLayer.updateTitlesDataFn(newFunction)
    }

    public async addBoundaryForTitleNumbers(titleNumbers: string[], skipStyleUpdate = false): Promise<void> {
        await this.boundaryLayer.addBoundaryForTitleNumbers(titleNumbers)
        await this.pointLayer.addPointForTitleNumbers(titleNumbers)
        if (!skipStyleUpdate) {
            this.persistStyleChanges(titleNumbers)
        }
    }

    public removeBoundaryForTitleNumbers(titleNumbers: string[], skipStyleUpdate = false) {
        this.boundaryLayer.removeBoundaryForTitleNumbers(titleNumbers)
        this.pointLayer.removePointForTitleNumbers(titleNumbers)
        if (!skipStyleUpdate) {
            this.persistStyleChanges(titleNumbers)
        }
    }

    public zoomToExtent():void {
        if (this.boundaryLayer.layer.getSource().getFeatures().length > 0) {
            this.boundaryLayer.zoomToExtent()
        } else {
            this.pointLayer.zoomToExtent()
        }
    }

    public async setVisible(visible: boolean, targetMap?: olMap): Promise<void> {
        if (targetMap) {
            await this.addToMap(targetMap)
        }
        this.visible = visible
        this.pointLayer?.setVisible(visible)
        this.boundaryLayer?.layer.setVisible(visible)
        this.refresh()
    }

    public getVisible(): boolean {
        return this.visible
    }

    private async onMapMove(e:MapEvent): Promise<void> {
        // If zoom is too far out, don't show boundaries and show points irrespective of number of titles.
        if (this.map.getView().getZoom() <= 11) {
            this.boundaryLayer.layer.setVisible(false)
            this.pointLayer.setVisible(true)
            return
        }

        // No need to load based on map extent if there isn't many titles in total.
        if (this.isSmallNumberOfTitles()) {
            this.boundaryLayer.layer.setVisible(this.visible)
            this.pointLayer.setVisible(!this.visible)
            return
        }

        // NOTE: There's a lot of titles so check how many we have in the current extent.
        const extent: Extent = e.target.calculateExtent()
        const titleNumbersInExtent: string[] = this.pointLayer?.getTitleNumbersInExtent(extent) ?? []

        // Based on criteria below, decide whether to load boundaries or not.
        if (this.shouldLoadBoundaries(titleNumbersInExtent.length, this.getTitlesDataFn().length)) {
            await this.boundaryLayer.loadBoundariesForTitles(titleNumbersInExtent)
        }

        // Based on criteria below, decide whether to SHOW boundaries or not.
        const showBoundaries: boolean = this.shouldShowBoundaries(this.map.getView().getZoom(), titleNumbersInExtent.length)
        this.boundaryLayer.layer.setVisible(showBoundaries && this.visible)

        // Re-display points that may have been hidden in favour of boundaries
        this.pointLayer.setVisible(!showBoundaries && this.visible)
    }

    private shouldLoadBoundaries(numTitlesInView: number, numTitlesEverywhere: number): boolean {
        // All title boundaries are loaded, no need to load anything else.
        if (this.boundaryLayer.getTitleNumbersWithLoadedBoundariesCount() === numTitlesEverywhere) {
            return false
        }

        if (numTitlesInView === 0) {
            return false
        }

        // Check that there isn't a small number of titles in total anyway, in which case load everything.
        if (numTitlesEverywhere <= TitleBoundaryLayerSettings.SMALL_NUMBER_OF_TOTAL_TITLES) {
            return true
        }

        return this.shouldShowBoundaries(this.map.getView().getZoom(), numTitlesInView)
    }

    private shouldShowBoundaries(zoom: number, titleNumbersInExtent:number): boolean {
        if (titleNumbersInExtent < TitleBoundaryLayerSettings.MAX_BOUNDARIES_TO_LOAD_AT_ONCE) {
            return true
        }
        return zoom >= TitleBoundaryLayerSettings.ZOOM_LEVEL_BEYOND_WHICH_BOUNDARIES_SHOULD_ALWAYS_BE_SHOWN
    }

    // Posts style changes to the API, debounced to reduce the number of requests.
    private persistStyleChanges(titleNumbers: string[]):void { // TODO: create a type for the title data.
        titleNumbers.forEach(x => this.titleNumbersPendingStyleUpdate.add(x))
        if (this.debounceTitleStyleUpdate) {
            this.debounceTitleStyleUpdate()
        }
    }

    private async _updateTitleStyle(): Promise<void> {
        const filteredTitles = this.getTitlesDataFn()?.filter(x => this.titleNumbersPendingStyleUpdate.has(x.titleNumber))
        const updateData = {
            matterId: this.getMatterIdFn(),
            titles: filteredTitles.map(t => toRaw(t)),
        }
        this.titleNumbersPendingStyleUpdate.clear()
        await MatterApi.setMatterTitles(updateData)
    }

    private isSmallNumberOfTitles(): boolean {
        return this.getTitlesDataFn().length < TitleBoundaryLayerSettings.SMALL_NUMBER_OF_TOTAL_TITLES
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        const extent = this.map.getView().calculateExtent(this.map.getSize())
        // Get title numbers in the current extent.
        let titleNumbersInExtent: string[] = []
        titleNumbersInExtent.push(...this.pointLayer?.getTitleNumbersInExtent(extent) ?? [])
        titleNumbersInExtent.push(...this.boundaryLayer?.layer.getSource().getFeaturesInExtent(extent)
            .map(x => x.getProperties().titleNumber) ?? [])
        titleNumbersInExtent = unique(titleNumbersInExtent)

        // Get corresponding title data
        const titleData = this.getTitlesDataFn()?.filter(x => titleNumbersInExtent.includes(x.titleNumber) && x.show)
        const result = titleData.map(x => {
            const label = x.label ? `${ x.label } (${ x.titleNumber })` : x.titleNumber
            return {
                id: 'title-' + x.titleNumber,
                label,
                originalLabel: label,
                style: {
                    strokeColour: x.colour,
                    strokeWidth: x.strokeWidth,
                    fillOpacity: x.fillOpacity,
                    hatch: x.hatch,
                    dashed: x.dashed,
                    showLabel: x.showLabel,
                },
            }
        })
        return result
    }

    getLayer(): LayerGroup {
        return this.olLayerGroup
    }

    getMapSnapshotConfig(targetMap: olMap): LayerSnapshotModel {
        const renderData = [] as Array<SnapshotTitleData>
        const result = {
            name: LayerNames.MatterTitles,
            configJson: null,
            restrictedExtent: null,
        }
        let titleData = this.getTitlesDataFn()
        // If there's a large number of titles in the matter, don't add every single one to the snapshot.
        if (this.getTitlesDataFn().length > TitleBoundaryLayerSettings.SMALL_NUMBER_OF_TOTAL_TITLES_FOR_SNAPSHOT) {
            const extendedExtent = getExtendedMapExtentForSnapshot(targetMap)
            result.restrictedExtent = extendedExtent
            const titleNumbersInExtent: string[] = this.pointLayer?.getTitleNumbersInExtent(extendedExtent)
            titleData = this.getTitlesDataFn()
                .filter(x => titleNumbersInExtent.includes(x.titleNumber) && x.show)
        }
        renderData.push(...titleData.map(x => {
            return {
                titleNumber: x.titleNumber,
                colour: x.colour,
                dashed: x.dashed,
                fill: x.fill,
                fillOpacity: x.fillOpacity,
                hatch: x.hatch,
                strokeWidth: x.strokeWidth,
                sortOrder: x.sortOrder,
                show: x.show,
                label: x.label,
                labelColour: x.labelColour,
                showLabel: x.showLabel,
                showTitleNumber: x.showTitleNumber,
            }
        }))
        result.configJson = JSON.stringify(renderData)
        return result
    }

    getIsLoading(): boolean {
        return this.boundaryLayer.titleNumbersPendingBoundaryLoad.size > 0 && this.boundaryLayer.hasLoadedSomeTitleBoundaries
    }

    public hasVisibleTitleNumber(titleNumber: string): boolean {
        return this.getTitlesDataFn().some(x => x.titleNumber === titleNumber && x.show)
    }

    public setSnapShotLayout(layout: ISnapshotPageLayout) {
        this.boundaryLayer.setSnapshotLayout(layout)
    }
}
