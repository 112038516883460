<template>
    <div class="expanding-map-search"
         data-test="expanding-map-search">
        <div class="icon-search-container">
            <div :class="{'search-box--open': searchIsActive}"
                 class="icon-search-box"
                 data-test="icon-search-container">
                <v-text-field ref="iconSearchInput"
                              v-model="searchText"
                              :loading="isLoading"
                              :placeholder="placeholder"
                              class="icon-search-box__input body-copy label-primary dd-privacy-allow"
                              :clearable="true"
                              data-test="icon-search-box-text-field"
                              data-track="SEARCH - Search box on small devices"
                              hide-details
                              required
                              :single-line="true"
                              text
                              type="search"
                              @change="keyUpHandler"
                              @keyup="doSearch"
                              @keyup.enter="$emit('search-for', trimmedSearchText)"
                              @click:clear="clearHandler" />
                <span v-if="!showIcon"
                      class="icon-search-box__submit"
                      data-test="icon-search-box-submit-btn"
                      data-track="SEARCH - Submit search on small devices"
                      @click="doSearch">
                    <v-icon>$search</v-icon>
                </span>
                <span v-if="showIcon"
                      class="icon-search-box__icon"
                      data-test="icon-search-box-open-btn"
                      data-track="SEARCH - Open search box on small devices"
                      @click="submitIconClicked">
                    <v-icon>$search</v-icon>
                </span>
            </div>
        </div>

        <div class="big-search-container">
            <div :class="{'search-box--open': searchIsActive}"
                 class="big-search-box"
                 data-test="expanding-search-box-container">
                <v-text-field ref="bigSearchInput"
                              v-model="searchText"
                              :loading="isLoading"
                              :placeholder="placeholder"
                              class="big-search-box__input body-copy label-primary dd-privacy-allow"
                              :clearable="true"
                              data-test="expanding-search-box-text-field"
                              data-track="SEARCH - Search box"
                              hide-details
                              required
                              return-object
                              :single-line="true"
                              text
                              type="search"
                              @blur="blurHandler"
                              @change="keyUpHandler"
                              @focusin="focusHandler"
                              @focusout="focusOutHandler"
                              @keyup="doSearch"
                              @keyup.enter="$emit('search-for', trimmedSearchText)"
                              @click:clear="clearHandler">
                    <template #prepend-inner>
                        <v-icon :style="{ color: iconColor }">
                            $search
                        </v-icon>
                    </template>
                </v-text-field>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce'
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import {
        SEARCH_MUTATE_IS_ACTIVE,
        SEARCH_MUTATE_SEARCH_TEXT,
    } from '@/store/modules/search/types'
    import { isScreenMediumAndDown } from '@/utils/breakpoint-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'ExpandingSearchBox',

        props: {
            placeholder: {
                type: String,
                default: 'Search...',
            },

            reset: {
                type: Boolean,
            },
        },

        emits: [
            'clear',
            'is-open',
            'search-for',
        ],

        data() {
            return {
                showIcon: true,
                debouncedSearch: debounce(() => {
                    this.$emit('search-for', this.trimmedSearchText)
                }, 500),
                iconColor: '',
            }
        },

        computed: {
            ...mapState('search', {
                searchIsActive: state => state.isActive,
                isLoading: state => state.loading,
                text: state => state.searchText,
            }),

            trimmedSearchText() {
                return this.searchText?.trim()
            },

            searchText: {
                get() {
                    return this.text
                },
                set(val) {
                    this.mutateSearchText(val)
                },
            },
        },

        watch: {
            searchText() {
                if (isNullOrWhitespace(this.trimmedSearchText)) {
                    this.clearHandler()
                }
            },

            reset() {
                this.showIcon = true
            },

            searchIsActive(isActive) {
                if (isActive) {
                    if (isScreenMediumAndDown()) {
                        this.$refs.iconSearchInput.focus()
                    } else {
                        this.$refs.bigSearchInput.focus()
                    }
                } else {
                    this.showIcon = true
                }
            },
        },

        methods: {
            ...mapMutations('search', {
                mutateIsActive: SEARCH_MUTATE_IS_ACTIVE,
                mutateSearchText: SEARCH_MUTATE_SEARCH_TEXT,
            }),

            /**
             * Handles the click event on the search icon.
             * Opens and closes the search box.
             */
            submitIconClicked() {
                if (this.searchIsActive === false) {
                    this.mutateIsActive(true)
                    this.$refs.iconSearchInput.focus()
                } else {
                    this.mutateIsActive(false)
                    this.$refs.iconSearchInput.blur()
                }

                this.$emit('is-open', this.searchIsActive)
            },

            /**
             * Handles the key up event on the search box.
             * If there is any text in the box, then display the submit button.
             * Else display the search icon.
             */
            keyUpHandler() {
                if (!isNullOrWhitespace(this.trimmedSearchText) && this.trimmedSearchText.length > 0) {
                    this.showIcon = false
                } else {
                    this.showIcon = true
                    this.searchText = ''
                }
            },

            /**
             * Handles the focus event on the input box.
             */
            focusHandler() {
                this.iconColor = '#1d73ad' // colour-primary
                this.$emit('is-open', true)
            },

            /**
             * Handles the blur event on the input box.
             */
            blurHandler() {
                if (isNullOrWhitespace(this.searchText)) {
                    this.$emit('is-open', false)
                }
            },

            /**
             * Triggers the search if there are 3 or more characters in the search box.
             */
            doSearch() {
                if (!isNullOrWhitespace(this.trimmedSearchText) && this.trimmedSearchText.length >= 3) {
                    this.debouncedSearch()
                }
            },

            /**
             * Handles the clear input box event.
             */
            clearHandler() {
                this.showIcon = true
                this.searchText = ''
                this.$emit('clear', '')
            },

            focusOutHandler() {
                this.iconColor = ''
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './expanding-search-box';
</style>
