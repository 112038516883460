import { mapState } from 'vuex'

import { isNullOrWhitespace } from '@/utils/string-utils'

export default {

    data() {
        return {
            documentTitle: '',
        }
    },

    computed: {
        ...mapState({
            matterName: state => state.matter.currentMatter.name,
            selectedTitleNumber: state => state.title.selectedTitleNumber,
        }),
    },

    watch: {

        documentTitle() {
            this.setDocumentTitle()
        },

        matterName() {
            this.setDocumentTitle()
        },

        selectedTitleNumber() {
            this.setDocumentTitle()
        },
    },

    created() {
        this.$router.afterEach(() => {
            this.setDocumentTitle()
        })
    },

    methods: {

        setDocumentTitle(title = null) {
            if (!isNullOrWhitespace(this.$route.meta?.title)) {
                let documentTitle = title != null
                    ? title
                    : this.$route.meta?.title
                        ? `${ this.$route.meta.title } - Orbital Witness`
                        : 'Orbital Witness'
                if (documentTitle.includes('{{matterName}}')) {
                    documentTitle = documentTitle.replace('{{matterName}}', this.matterName)
                }
                if (documentTitle.includes('{{titleNumber}}')) {
                    documentTitle = documentTitle.replace('{{titleNumber}}', this.$route.params?.titleNumber)
                }
                if (documentTitle?.trim().length > 0) {
                    document.title = documentTitle
                }
            }
        },
    },
}
