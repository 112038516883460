// Getters
export const SEARCH_GET_ALL_RESULTS = 'SEARCH_GET_ALL_RESULTS'

// Mutations
export const SEARCH_MUTATE_ALL_RESULTS = 'SEARCH_MUTATE_ALL_RESULTS'
export const SEARCH_MUTATE_CLEAR_ALL_RESULTS = 'SEARCH_MUTATE_CLEAR_ALL_RESULTS'
export const SEARCH_MUTATE_IS_ACTIVE = 'SEARCH_MUTATE_IS_ACTIVE'
export const SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN = 'SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN'
export const SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN = 'SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN'
export const SEARCH_MUTATE_LOADING = 'MUTATE_LOADING'
export const SEARCH_MUTATE_LOADING_TITLES = 'MUTATE_LOADING_TITLES'
export const SEARCH_MUTATE_MAP_SEARCH_INPUT = 'MUTATE_MAP_SEARCH_INPUT'
export const SEARCH_MUTATE_OWNER_TITLES = 'SEARCH_MUTATE_OWNER_TITLES'
export const SEARCH_MUTATE_SHOW_OWNER_SEARCH = 'SEARCH_MUTATE_SHOW_OWNER_SEARCH'
export const SEARCH_MUTATE_SHOW_POPUP = 'MUTATE_SHOW_POPUP'
export const SEARCH_MUTATE_POPUP = 'SEARCH_MUTATE_POPUP'
export const SEARCH_MUTATE_SEARCH_TEXT = 'SEARCH_MUTATE_SEARCH_TEXT'
export const SEARCH_MUTATE_SELECTED_TITLE_NUMBERS = 'SEARCH_MUTATE_SELECTED_TITLE_NUMBERS'
export const SEARCH_MUTATE_SEARCH_LAYER_VISIBILITY = 'SEARCH_MUTATE_SEARCH_LAYER_VISIBILITY'
export const SEARCH_MUTATE_SET_MAP_SELECTED_TITLE_NUMBER = 'SEARCH_MUTATE_SET_MAP_SELECTED_TITLE_NUMBER'

// Actions
export const SEARCH_ALL = 'SEARCH_ALL'
export const SEARCH_CLEAR_ALL = 'SEARCH_CLEAR_ALL'
export const SEARCH_OWNER_TITLES = 'SEARCH_OWNER_TITLES'
export const SELECT_SEARCH_RESULT = 'SEARCH_RESULT' // with search result
export const SEARCH_INITIALISE_SEARCH_RESULTS_LAYER = 'SEARCH_INITIALISE_SEARCH_RESULTS_LAYER'

export const SEARCH_ADD_OWNER_TITLES_SHORTLIST_TO_MAP = 'SEARCH_ADD_OWNER_TITLES_SHORTLIST_TO_MAP'
export const SEARCH_HIGHLIGHT_TITLE_NUMBERS_ON_MAP = 'SEARCH_HIGHLIGHT_TITLE_NUMBERS_ON_MAP'
