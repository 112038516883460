<template>
    <div class="planning-actions">
        <!-- The export button is not needed for the time being, and will be re-introduced shortly as a fake door test or as working. -->
        <!--<ow-dropdown-menu class="planning-actions__button-export"
                              button-text="Export list"
                              icon="file_download"
                              alignment="bottom"
                              :flat="true"
                              tooltip-text="Download the list of planning applications"
                              tooltip-alignment="top"
                              :is-button-disabled="false"
                              :is-loading="false"
                              :is-tooltip-disabled="false"
                              :is-menu-disabled="false"
                              button-data-test="planning-actions-dropdown-button-export">
                <ow-dropdown-menu-item text="As a .docx Word Document"
                                       data-test="planning-actions-export-as-docx"
                                       data-track="planning-actions-export-as-docx"
                                       @click="exportAsDOCX" />
                <ow-dropdown-menu-item text="As a .xlxs Excel Spreadsheet"
                                       data-test="planning-actions-export-as-xlxs"
                                       data-track="planning-actions-export-as-xlxs"
                                       @click="exportAsXLXS" />
            </ow-dropdown-menu>-->
        <div class="planning-actions__basic">
            <v-text-field v-model="textFilter"
                          autocomplete="new-password"
                          class="planning-actions__search"
                          clearable
                          data-test="planning-actions-search"
                          data-track="TITLE-DETAILS-PANEL - Search planning applications"
                          hide-details
                          label="Keywords search"
                          prepend-inner-icon="$search"
                          @click:clear="textFilter = null" />
            <v-select v-model="selectedSortOptionValue"
                      class="planning-actions__sort"
                      :items="sortOptions"
                      data-test="planning-actions-sort"
                      data-track="TITLE-DETAILS-PANEL - Sort planning applications"
                      hide-details
                      item-title="text"
                      item-value="id"
                      label="Sort by" />

            <ow-button :disabled="disableExport"
                       class="planning-actions__button-export"
                       data-test="planning-actions-export-as-csv"
                       data-track="planning-actions-export-as-csv"
                       outlined
                       @click="exportAsCSV">
                <img alt="Click to download the original register as a PDF"
                     class="planning-actions__button-export__icon"
                     height="16"
                     width="16"
                     src="../../media/icon-excel.png"
                     title="Export as CSV" />
                Export to Excel
            </ow-button>
        </div>

        <app-planning-actions-advanced data-test="planning-advanced-options-component" />
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button.vue'
    import appPlanningActionsAdvanced from '@/components/planning/planning-actions-advanced.vue'
    import { planningSortingOptionsNames } from '@/consts/planning'
    import {
        PLANNING_EXPORT_CSV,
        PLANNING_MUTATE_SELECTED_SORT_OPTION,
        PLANNING_MUTATE_TEXT_FILTER,
        PLANNING_UPDATE_FILTERED_RESULTS,
    } from '@/store/modules/planning/types'

    export default {
        components: {
            appPlanningActionsAdvanced,
            OwButton,
        },

        props: {},

        data() {
            return {
                sortOptions: Object.entries(planningSortingOptionsNames).map(([id, text]) => {
                    return {
                        id,
                        text,
                    }
                }),
            }
        },

        computed: {

            ...mapState({
                textFilterState: state => state.planning.inputs.textFilter,
                selectedSortOption: state => state.planning.selectedSortOption,
                filteredResultsCount: state => state.planning.filteredResultsCount,
            }),

            selectedSortOptionValue: {
                get() {
                    return this.selectedSortOption
                },
                set(val) {
                    this.setSortOption(val)
                },
            },

            textFilter: {
                get() {
                    return this.textFilterState
                },
                set(val) {
                    this.mutateTextFilter(val)
                },
            },

            disableExport() {
                return this.filteredResultsCount === 0
            },

        },

        watch: {
            selectedSortOptionValue() {
                this.updateFilteredResults()
            },
        },

        methods: {

            ...mapActions({
                updateFilteredResults: PLANNING_UPDATE_FILTERED_RESULTS,
                exportAsCSV: PLANNING_EXPORT_CSV,
            }),

            ...mapMutations({
                mutateTextFilter: PLANNING_MUTATE_TEXT_FILTER,
                setSortOption: PLANNING_MUTATE_SELECTED_SORT_OPTION,
            }),
        },
    }

</script>
<style lang="scss">
    @import 'planning-actions';
</style>
