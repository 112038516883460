// Getters
export const ANALYSIS_GET_SOURCE_DATA_LOADING_STATUS = 'ANALYSIS_GET_SOURCE_DATA_LOADING_STATUS'

// Mutations
export const ANALYSIS_MUTATE_ADDRESSES = 'ANALYSIS_MUTATE_ADDRESSES'
export const ANALYSIS_MUTATE_SOURCE_DATA = 'ANALYSIS_MUTATE_SOURCE_DATA'
export const ANALYSIS_MUTATE_DERIVED_DATA = 'ANALYSIS_MUTATE_DERIVED_DATA'
export const ANALYSIS_MUTATE_DERIVED_DATA_REGISTER_ENTRIES = 'ANALYSIS_MUTATE_DERIVED_DATA_REGISTER_ENTRIES'
export const ANALYSIS_MUTATE_LOADING_STATUS = 'ANALYSIS_MUTATE_LOADING_STATUS'
export const ANALYSIS_MUTATE_SELECTED_COLUMNS = 'ANALYSIS_MUTATE_SELECTED_COLUMNS'
export const ANALYSIS_MUTATE_INITIAL_GRID_CONFIG = 'ANALYSIS_MUTATE_INITIAL_GRID_CONFIG'
export const ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION = 'ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION'
export const ANALYSIS_MUTATE_HAS_LOADED_INITIAL_GRID_STATE = 'ANALYSIS_MUTATE_HAS_LOADED_INITIAL_GRID_STATE'
export const ANALYSIS_MUTATE_GRID_INSTANCE = 'ANALYSIS_MUTATE_GRID_INSTANCE'
export const ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION = 'ANALYSIS_MUTATE_SHOW_TEMPLATE_SELECTION'
export const ANALYSIS_MUTATE_SELECTED_FILTERS = 'ANALYSIS_MUTATE_SELECTED_FILTERS'
export const ANALYSIS_MUTATE_SORT = 'ANALYSIS_MUTATE_SORT'
export const ANALYSIS_MUTATE_GROUPING = 'ANALYSIS_MUTATE_GROUPING'
export const ANALYSIS_MUTATE_APPLYING_TEMPLATE = 'ANALYSIS_MUTATE_APPLYING_TEMPLATE'
export const ANALYSIS_MUTATE_TEMPLATE_APPLIED = 'ANALYSIS_MUTATE_TEMPLATE_APPLIED'
export const ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE = 'ANALYSIS_MUTATE_IS_FILTER_BAR_VISIBLE'
export const ANALYSIS_MUTATE_ORDER_STATUS = 'ANALYSIS_MUTATE_ORDER_STATUS'

// actions
export const ANALYSIS_FETCH_ALL_TITLES_FOR_MATTER = 'ANALYSIS_FETCH_ALL_TITLE_FOR_MATTER'
export const ANALYSIS_RESET_GRID_CONFIGURATION = 'ANALYSIS_RESET_GRID_CONFIGURATION'
export const ANALYSIS_UPDATE_FILTER_OPTIONS = 'ANALYSIS_UPDATE_FILTER_OPTIONS'
export const ANALYSIS_UPDATE_TITLE_IN_DERIVED_DATA = 'ANALYSIS_UPDATE_TITLE_IN_DERIVED_DATA'

// The key for local storage of the grid configuration
export const STATE_STORAGE_KEY = 'analysis-grid-state'
