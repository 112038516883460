<template>
    <div class="base-row">
        <div class="d-flex justify-space-between align-center w-100">
            <div class="d-flex justify-space-between align-center w-100">
                <span class="caption-highlight ml-4">{{ title }}</span>
                <ow-document-availability-label class="mr-4"
                                                :is-ordered="false"
                                                :is-loading="documentAvailabilityLoading"
                                                :hmlr-availability-code="documentAvailabilityCode" />
            </div>
            <v-divider vertical />
            <order-register-button button-class="base-row__action"
                                   :current-matter-id="currentMatterId"
                                   :order-with-checkout="orderWithCheckout"
                                   :is-ordering-allowed="isOrderingAllowed"
                                   :selected-title-number="selectedTitleNumber"
                                   hide-back-dated-states
                                   full-height
                                   button-data-test-attribute="order-register-button-document" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'

    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OrderRegisterButton from '@/components/title-panel/order-register-button.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    const props = defineProps<{
        title: string
        documentAvailabilityLoading?: boolean
        currentMatterId?: number
        selectedTitleNumber?: string
        selectedTitle?: any
        orderWithCheckout: boolean
        isOrderingAllowed: boolean
    }>()

    const registerAvailability = computed(() => props.selectedTitle?.officialCopiesAvailability?.results?.titleRegister)
    const documentAvailabilityCode = computed(() => {
        if (props?.selectedTitle?.record?.source === DOCUMENT_SOURCE.SCOTLAND) {
            return HmlrDocumentAvailabilityCode.Available
        }
        return registerAvailability.value?.hmlrAvailabilityCode ? registerAvailability.value?.hmlrAvailabilityCode : null
    })
</script>

<style lang="scss">
    @import './base-row.scss';
</style>
