<template>
    <ow-card :loading="(!selectedTitle?.record || selectedTitle?.loadingDaylist)"
             :loading-skeleton="{ rows: 1 }"
             :info-content="owCardInfoContent"
             class="day-list"
             :title="$t('titlePanel.tabs.dayList')">
        <div class="d-flex flex-column">
            <div class="d-flex flex-row day-list__disclaimer">
                <div class="d-flex flex-row align-center justify-space-between w-100">
                    <div>
                        <p class="day-list__text">
                            {{ content.main }}
                        </p>
                        <p class="hide-in-percy day-list__accuracy-info-text">
                            {{ dateText }}
                        </p>
                    </div>

                    <div class="d-flex">
                        <ow-button color="primary"
                                   data-track="TITLE-DETAILS-PANEL - Refresh daylist from summary panel"
                                   is-borderless
                                   is-text
                                   @click="refreshDayList()">
                            <template #iconPrefix>
                                <v-icon>$refresh</v-icon>
                            </template>
                            {{ $t('action.refresh') }}
                        </ow-button>

                        <!-- View daylist button -->
                        <ow-button v-if="showViewButton"
                                   is-borderless
                                   is-primary
                                   is-secondary
                                   is-text
                                   data-track="TITLE-DETAILS-PANEL - View daylist from summary panel"
                                   @click="showDayList()">
                            <template #iconSuffix>
                                <v-icon>$chevron-right</v-icon>
                            </template>
                            {{ $t('action.view') }}
                        </ow-button>
                    </div>
                </div>
            </div>
        </div>
    </ow-card>
</template>

<script lang="ts">
    import { parse } from 'date-fns'
    import formatDistance from 'date-fns/formatDistance'
    import { mapActions } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import { ColorClass } from '@/enums/color-class.enum'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { IInfoContentItem } from '@/interfaces/core-components/ow-card-info-content-item.interface'
    import { TITLE_LOOKUP_TITLE_DAYLIST } from '@/store/modules/titles/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    interface IDayListText {
        main: string,
        flag?: {
            text: string,
            colorClass: ColorClass | null,
            icon: string,
        },
    }

    export default {
        name: 'DayList',

        components: {
            OwCard,
            OwButton,
        },

        props: {
            selectedTitle: {
                type: Object,
                required: true,
            },

            showViewLink: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        emits: ['set-selected-tab'],

        data() {
            return {
                dateText: '',
                responseDate: '',
                currentTimeUpdateInterval: 60000,
                timeUpdateInterval: null,
            }
        },

        computed: {
            showViewButton(): boolean {
                return this.showViewLink &&
                    this.selectedTitle.daylist.data?.length &&
                    !this.selectedTitle.daylist.outsideOfLROperatingHours
            },

            owCardInfoContent(): IInfoContentItem[] {
                return [{
                    icon: this.content.flag.icon,
                    text: this.content.flag.text,
                    class: this.content.flag.colorClass,
                }]
            },

            content(): IDayListText {
                if (this.selectedTitle.daylist.outsideOfLROperatingHours) {
                    return {
                        main: this.$t('titlePanel.cards.daylist.noApplications'),
                        flag: {
                            text: this.$t('hmlr.outOfHours'),
                            colorClass: ColorClass.Danger,
                            icon: '$warning',
                        },
                    }
                }

                if (this.selectedTitle.daylist.didHMLRTimeout) {
                    return {
                        main: this.$t('titlePanel.cards.daylist.hmlrTimeout'),
                        flag: {
                            text: null,
                            colorClass: null,
                            icon: null,
                        },
                    }
                }

                if (this.selectedTitle.daylist?.data === undefined || this.selectedTitle.daylist?.data === null) {
                    return {
                        main: this.$t('titlePanel.cards.daylist.noApplicationsError'),
                        flag: {
                            text: null,
                            colorClass: null,
                            icon: null,
                        },
                    }
                }

                if (!this.selectedTitle.daylist.data.length) {
                    return {
                        main: this.$t('titlePanel.cards.daylist.noApplications'),
                        flag: {
                            text: null,
                            colorClass: null,
                            icon: null,
                        },
                    }
                }

                return this.selectedTitle.daylist.data.length === 1
                    ? {
                        main: this.$t('titlePanel.cards.daylist.singleApplication'),
                        flag: {
                            text: this.$t('titlePanel.cards.daylist.singleApplicationFlag'),
                            colorClass: ColorClass.Warning,
                            icon: '$flag',
                        },
                    }
                    : {
                        main: this.$t('titlePanel.cards.daylist.multipleApplications', {
                            total: this.selectedTitle.daylist.data.length,
                        }),
                        flag: {
                            text: this.$t('titlePanel.cards.daylist.multipleApplicationsFlag', {
                                total: this.selectedTitle.daylist.data.length,
                            }),
                            colorClass: ColorClass.Warning,
                            icon: '$flag',
                        },
                    }
            },
        },

        watch: {
            // On initial load or refresh, will need to wait for the daylist to load
            'selectedTitle.daylist.responseDate'(newVal: string) {
                if (!isNullOrWhitespace(newVal)) {
                    this.initialiseTimeString(newVal)
                }
            },
        },

        mounted() {
            // when mounting the card in the other tab, this is already set, so the watcher would never fire.
            if (!isNullOrWhitespace(this.selectedTitle.daylist.responseDate)) {
                this.initialiseTimeString(this.selectedTitle.daylist.responseDate)
            }
        },

        methods: {
            ...mapActions({
                lookupTitleDaylist: TITLE_LOOKUP_TITLE_DAYLIST,
            }),

            showDayList(): void {
                this.$emit('set-selected-tab', TitlePanelTabName.DayList)
            },

            refreshDayList(): void {
                this.lookupTitleDaylist()
            },

            initialiseTimeString(responseTime: string): void {
                this.responseDate = parse(responseTime, 'dd-MM-yyyy HH:mm', new Date())
                this.updateTimeString()
                this.resetInterval()
            },

            resetInterval(): void {
                if (this.timeUpdateInterval) {
                    clearInterval(this.timeUpdateInterval)
                }

                this.timeUpdateInterval = setInterval(this.updateTimeString, this.currentTimeUpdateInterval)
            },

            updateTimeString(): void {
                // is response date a valid date?
                if (isNaN(this.responseDate.getTime())) {
                    return
                }
                const time = formatDistance(this.responseDate, new Date())
                this.dateText = this.$t('titlePanel.cards.daylist.accuracy', {
                    time,
                })
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './day-list-card';
</style>
