<template>
    <div v-if="showSearchComponent"
         class="map-search">
        <expanding-search-box :reset="resetSearchBox"
                              placeholder="eg. TGL50538, WC1R 4HQ or Tesco"
                              @clear="clearHandler"
                              @is-open="searchOpenHandler"
                              @search-for="doSearch" />

        <search-results-panel :loading="loading"
                              :results="searchResults"
                              :search-text="currentSearchText"
                              :show-results-panel="isResultsPanelOpen && isActive"
                              @selected="selectedHandler"
                              @selection-changed="selectedResultsUpdated" />
        <address-popup />
    </div>
</template>

<script>
    import debounce from 'lodash.debounce'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { MAP_UPDATE_SIZE } from '@/store/modules/map/types'
    import {
        SEARCH_ALL,
        SEARCH_CLEAR_ALL,
        SEARCH_GET_ALL_RESULTS,
        SEARCH_HIGHLIGHT_TITLE_NUMBERS_ON_MAP,
        SEARCH_MUTATE_IS_ACTIVE,
        SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN,
        SEARCH_OWNER_TITLES,
        SELECT_SEARCH_RESULT,
    } from '@/store/modules/search/types'
    import { TITLE_SHOW_TITLE_BOUNDARY } from '@/store/modules/titles/types'
    import { USER_MUTATE_SHOW_MAP_TITLES_NAV } from '@/store/mutation-types'
    import {
        isScreenLargeAndUp,
        isScreenMediumAndDown,
    } from '@/utils/breakpoint-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import AddressPopup from './address-popup.vue'
    import ExpandingSearchBox from './expanding-search-box.vue'
    import SearchResultsPanel from './map-search-results.vue'

    export default {
        name: 'MapSearchWithOwner',

        components: {
            AddressPopup,
            ExpandingSearchBox,
            SearchResultsPanel,
        },

        mixins: [FeatureFlagsMixin],

        data() {
            return {
                resetSearchBox: false,
                currentSearchText: '',
                debouncedToggleTitleList: debounce((isOpen) => {
                    this.toggleTitleListPanel(isOpen)
                }, 250),
            }
        },

        computed: {
            ...mapState('search', {
                loading: state => state.loading,
                isActive: state => state.isActive,
                isResultsPanelOpen: state => state.isResultsPanelOpen,
            }),

            ...mapState({
                isStreetViewEnabled: state => state.siteVisit.enabled,
                isWalkthrough: state => state.walkthrough.enabled,
                selectedTitleNumber: state => state.title.selectedTitleNumber,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            ...mapGetters('search', {
                searchResults: SEARCH_GET_ALL_RESULTS,
            }),

            hasSearchResults() {
                let resultsCount = 0
                resultsCount += this.searchResults?.companies?.length ?? 0
                resultsCount += this.searchResults?.titleNumbers?.length ?? 0
                resultsCount += this.searchResults?.places?.length ?? 0
                return resultsCount > 0
            },

            showSearchComponent() {
                return !this.isSharedLinkView && !this.isWalkthrough
            },
        },

        watch: {
            isActive(newVal) {
                this.searchOpenHandler(newVal)
            },
            selectedTitleNumber(newVal) {
                if (newVal) {
                    this.showSelectedTitleBoundary(newVal)
                }
            },
        },

        methods: {
            ...mapMutations({
                mutateTitleListState: USER_MUTATE_SHOW_MAP_TITLES_NAV,
            }),

            ...mapMutations('search', {
                mutateIsActive: SEARCH_MUTATE_IS_ACTIVE,
                toggleResultsPanel: SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN,
            }),

            ...mapActions({
                updateMapSize: MAP_UPDATE_SIZE,
                showSelectedTitleBoundary: TITLE_SHOW_TITLE_BOUNDARY,
            }),

            ...mapActions('search', {
                clearSearch: SEARCH_CLEAR_ALL,
                selectSearchResult: SELECT_SEARCH_RESULT,
                search: SEARCH_ALL,
                searchForOwnerTitles: SEARCH_OWNER_TITLES,
                showSelectedTitleNumbers: SEARCH_HIGHLIGHT_TITLE_NUMBERS_ON_MAP,
            }),

            /**
             * Handles the search event from the expanding search box component
             * @param searchText {String}
             */
            doSearch(searchText) {
                if (!isNullOrWhitespace(searchText) && searchText !== this.currentSearchText) {
                    this.search(searchText)
                    this.currentSearchText = searchText
                    this.toggleResultsPanel(true)
                }
            },

            /**
             * Handles the search open event from the expanding search box component
             * When the search is open/active, then want the title list panel to collapse.
             * @param isOpen {Boolean}
             */
            searchOpenHandler(isOpen) {
                // Collapse the title list panel if:
                //     Not in street view mode
                //     The don't collapse flag is set to false and it's a large screen
                //     If it's a small sized screen
                if (!this.isStreetViewEnabled) {
                    if (isScreenMediumAndDown() || isScreenLargeAndUp()) {
                        this.debouncedToggleTitleList(!isOpen)
                    }
                }

                this.mutateIsActive(isOpen)
            },

            /**
             * Hide/show the title list panel, if no search results
             * If there are search results, then don't want to change the title list panel
             * @param collapsePanel {Boolean}
             **/
            toggleTitleListPanel(collapsePanel) {
                if (!this.hasSearchResults) {
                    this.mutateTitleListState(collapsePanel)
                    this.updateMapSize()
                }
            },

            /**
             * Handles the clear search event.
             */
            clearHandler() {
                this.clearSearch()
                this.currentSearchText = ''
            },

            /**
             * Handles the selected search result item.
             * @param item {Object}
             */
            selectedHandler(item) {
                if (!item) {
                    console.error('ERROR: search select handler needs an item!')
                    return
                }

                const notCompanyTypes = ['address', 'titlenumber', 'postcode', 'place']
                if (notCompanyTypes.includes(item.type)) {
                    this.selectSearchResult(item)
                    this.resetSearchBox = true
                } else {
                    this.searchForOwnerTitles(item)
                }
                this.mutateIsActive(false)
            },

            selectedResultsUpdated(items) {
                const selectedTitleNumbers = []
                items.forEach(item => {
                    switch (item.type) {
                        case 'company':
                            selectedTitleNumbers.push(...item.titleData.map(d => d.titleNumber))
                            break
                    }
                })

                this.showSelectedTitleNumbers(selectedTitleNumbers)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './map-search-with-owner';
</style>
