import {
    MUTATE_FEATURE_FLAGS,
    MUTATE_LOADED,
    MUTATE_SETTINGS,
} from './types'

export default {
    [MUTATE_SETTINGS](state, config) {
        state.settings = config
    },

    [MUTATE_LOADED](state, isLoaded) {
        state.loaded = isLoaded
    },

    [MUTATE_FEATURE_FLAGS](state, flags) {
        state.featureFlags = flags
    },
}
