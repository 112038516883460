export const DocumentCode = {
    Abstract: 0,
    Agreement: 1,
    Assent: 2,
    Assignment: 3,
    Charge: 4,
    Conveyance: 5,
    Deed: 6,
    Indenture: 7,
    Lease: 8,
    Licence: 9,
    Plan: 10,
    SubCharge: 11,
    Transfer: 12,
    Other: 13,
}
