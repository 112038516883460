<template>
    <div v-if="!openDocument"
         class="pa-4">
        <searches-documents-summary :documents-count="documentsCount"
                                    :groups-count="groupsCount"
                                    :matter-id="matterId"
                                    data-test="searches-documents-summary"
                                    @group-by-products-changed="groupByProductsChanged" />
        <searches-documents-table v-if="!groupByProducts"
                                  :documents="searchesDocuments"
                                  :is-loading="isLoading"
                                  data-test="searches-documents-table"
                                  @download-document="downloadDocument"
                                  @view-document="viewDocument"
                                  @view-order="model => {viewOrder(model.orderId)}" />
        <searches-grouped-table v-else
                                :grouped-documents="groupedDocuments"
                                data-test="searches-grouped-table"
                                @download-zip="downloadZip"
                                @view-order="model => {viewOrder(model.documents[0].orderId)}" />
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
    } from 'vue'
    import {              useRoute,
                          useRouter } from 'vue-router'

    import SearchesApi, { IDownloadSearchesDocumentsRequest } from '@/api/searches.api'
    import {
        mapDocumentsToGroupedStore,
    } from '@/components/documents/library/searches-documents/search-document-to-grouped-product-mapper'
    import SearchesDocumentsSummary from '@/components/documents/library/searches-documents/searches-documents-summary.vue'
    import SearchesDocumentsTable from '@/components/documents/library/searches-documents/searches-documents-table.vue'
    import SearchesGroupedTable from '@/components/documents/library/searches-documents/searches-grouped-table.vue'
    import { Route } from '@/enums/route.enum'
    import {
        IDocLibrarySearchesRow,
        ISearchesDocument,
    } from '@/interfaces/searches-document.interface'

    const router = useRouter()
    const route = useRoute()

    const props = defineProps<{
        searchesDocuments: Array<ISearchesDocument>
        matterId: number
        openDocument?: string,
        isLoading?: boolean
    }>()

    const orderId = computed(() => route.params.orderId)
    const documentsCount = computed(() => props.searchesDocuments.length)

    // Defined by searches document summary component
    const groupByProducts = ref<boolean>(false)
    const groupByProductsChanged = (newVal: boolean) => {
        groupByProducts.value = newVal
    }
    const groupedDocuments = computed(() => mapDocumentsToGroupedStore(props.searchesDocuments))
    const groupsCount = computed(() => groupedDocuments.value.length)

    const viewDocument = (searchesDocument: ISearchesDocument, fromSearchesOrder = false) => {
        router.push({
            name: Route.SearchesDocument,
            params: {
                matterId: props.matterId.toString(),
                orderId: searchesDocument.orderId,
                itemId: orderId.value,
            },
            query: {
                fileName: searchesDocument.fileName,
                fromSearchesOrder: fromSearchesOrder.toString(),
            },
        })
    }

    const downloadDocument = (model: ISearchesDocument) => {
        const request: IDownloadSearchesDocumentsRequest = {
            documents: { [model.orderId]: [model.fileName] },
        }
        SearchesApi.downloadDocuments(request)
    }

    const downloadZip = (model: IDocLibrarySearchesRow) => {
        const request: IDownloadSearchesDocumentsRequest = {
            documents: { [model.documents[0].orderId]: model.documents.map(x => x.fileName) },
        }
        SearchesApi.downloadDocuments(request)
    }

    const viewOrder = (orderId: string) => {
        router.push({
            name: Route.MatterSearchesDetails,
            params: {
                matterId: props.matterId.toString(),
                id: orderId,
            },
        })
    }

    defineExpose({
        groupsCount,
        groupByProducts,
    })

</script>

<style lang="scss">
    @import "searches-documents";
</style>
