<template>
    <div class="ow-icon-text"
         data-test="ow-icon-text">
        <v-icon :class="{ 'small-icon': isSmallIcon }"
                class="ow-icon-text__icon"
                data-test="ow-icon-text-icon">
            {{ icon }}
        </v-icon>
        <div :class="{'is-single-line': isSingleLine}"
             :style="{
                 fontStyle: isItalicText ? 'italic' : 'normal',
             }"
             :title="title"
             class="ow-icon-text__text body-copy"
             data-test="ow-icon-text-text">
            {{ text }}
        </div>
    </div>
</template>

<script lang="ts">
    export default {
        name: 'OwIconText',

        props: {
            icon: {
                type: String,
                required: true,
            },
            text: {
                type: String,
                default: '',
                required: false,
            },
            title: {
                type: String,
                default: undefined,
                required: false,
            },
            isSingleLine: {
                type: Boolean,
                required: false,
                default: true,
            },
            isSmallIcon: {
                type: Boolean,
                required: false,
            },
            isItalicText: {
                type: Boolean,
                required: false,
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-icon-text.scss';
</style>
