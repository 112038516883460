<template>
    <ow-card :loading="isLoading"
             :loading-skeleton="{ rows: 1, columns: 1, expandRows: false, reverse: true }"
             class="title-summary-flags"
             title="Flags">
        <p style="margin-bottom: 0;color: grey;">
            Flags indicate the number of register entries in each category
            <span v-if="isRegisterUploaded">
                <ow-colored-label :is-beta-label="true"
                                  :is-small="true"
                                  margin="0 0 0 12" />
                <ow-tooltip activator="parent"
                            :position="OwTooltipPosition.Right">
                    {{ i18n.global.t('titlePanel.cards.flags.uploadBetaTooltip') }}
                </ow-tooltip>
            </span>
            <br />
            (Source: {{ getDocumentSource }})
        </p>
        <ul class="summary-list">
            <li v-for="indicator in linkedIndicators">
                <div :class="{
                         'disabled-link' : indicator.codes.length === 0,
                         'enabled-link': indicator.codes.length > 0
                     }"
                     :data-test="'title-summary-flag-' + indicator.label"
                     @click="selectIndicator(indicator)">
                    <a data-track="TITLE-DETAILS-PANEL - View flag from summary panel"
                       style="color: black; font-weight: bold;">
                        {{ indicator.label }} <span v-if="indicator.codes.length > 0">({{ indicator.codes.length }})</span>
                        <span class="title-panel-view-link">View </span>
                        <v-icon class="body-copy">$chevron-right</v-icon>
                    </a>
                </div>
            </li>
        </ul>
    </ow-card>
</template>

<script lang="ts">
    import { mapMutations } from 'vuex'

    import OwCard from '@/components/core/ow-card.vue'
    import OwColoredLabel from "@/components/core/ow-colored-label.vue"
    import OwTooltip from "@/components/core/ow-tooltip.vue"
    import {DOCUMENT_SOURCE} from "@/consts/document-source"
    import {OwTooltipPosition} from "@/enums/ow-tooltip-position"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import i18n from "@/plugins/i18n"
    import {
        TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
        TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN,
    } from '@/store/modules/titles/types'

    export default {
        name: 'Flags',

        components: {
            OwTooltip,
            OwColoredLabel,
            OwCard,
        },

        props: {
            selectedTitle: {
                type: Object,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
        },

        emits: [
            'set-selected-tab',
        ],

        computed: {
            i18n() {
                return i18n
            },
            OwTooltipPosition() {
                return OwTooltipPosition
            },
            linkedIndicators() {
                return this.selectedTitle?.record?.bgProprietorshipData?.linkedIndicators
            },
            isRegisterUploaded(): boolean {

                return this.selectedTitle?.officialCopiesAvailability?.results?.titleRegister?.documentSource === DOCUMENT_SOURCE.UPLOADED
            },
            getDocumentSource(): string {
                if(this.isRegisterUploaded){
                    return "HMLR - Parsed by Orbital Witness"
                }
                return "HMLR"
            },
        },

        methods: {
            ...mapMutations({
                setRegisterIndicatorSelection: TITLE_MUTATE_REGISTER_INDICATOR_SELECTION,
                setRegisterIndicatorSelectedTextSpan: TITLE_MUTATE_REGISTER_INDICATOR_TEXT_SELECTION_SPAN,
            }),

            selectIndicator(indicator) {
                this.setRegisterIndicatorSelectedTextSpan([])
                this.$emit('set-selected-tab', TitlePanelTabName.Register)
                this.setRegisterIndicatorSelection(indicator)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import "./flags-card";
</style>
