import {
    computed,
    onMounted,
    ref,
} from 'vue'
import { useStore } from 'vuex'

import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
import { unique } from '@/utils/array-utils'
import { getCopilotURL } from '@/utils/environment.utils'

export function useCopilot({
    clientCode = null,
    documentIds = [],
    documentType = null,
    matterCode = null,
    matterId = null,
    position = null,
    report = null,
    titleNumbers = null,
} : {
    clientCode?: string,
    documentIds?: number[],
    documentType?: string,
    matterCode?: string,
    matterId?: number,
    position?: string,
    report?: string,
    titleNumbers?: string[],
} = {}) {
    const copilotCtaKey = 'cta-copilot'
    const isCtaVisible = ref<boolean>(true)
    const store = useStore()
    const internalDocIds = ref<number[]>(documentIds)
    const internalReport = ref<string>(report)
    const internalTitleNumbers = ref<string[]>(titleNumbers)

    const disableCta = () => {
        sessionStorage.setItem(copilotCtaKey, 'false')
        isCtaVisible.value = false
    }

    const setDocumentIds = (docIds: number[]) => {
        internalDocIds.value = unique(docIds)
    }

    const setReport = (newReport: string) => {
        internalReport.value = newReport
    }

    onMounted(() => {
        // Only hide/show if the close button is included, otherwise it will always be visible.
        isCtaVisible.value = sessionStorage.getItem(copilotCtaKey) !== 'false'
    })

    const conversationName = computed(() => {
        const conversationElems = [
            internalTitleNumbers?.value?.join(',') ?? '',
            report = internalReport.value,
            matterCode,
            clientCode ?? '',
        ]
        return conversationElems.filter(c => c).join('-').replace(/-$/, '')
    })

    const copilotUrl = computed(() => {
        const host = getCopilotURL()
        const reportParam = internalReport.value ? `report=${ internalReport.value }` : null
        const matterIdParam = matterId ? `matterId=${ matterId }` : null
        const documentIdsParam = internalDocIds.value && internalDocIds.value.length ? `documentIds=${ internalDocIds.value.join(',') }` : null
        let titleNumbersParam = null
        if (internalTitleNumbers?.value?.length) {
            if (internalTitleNumbers.value.length > 1) {
                titleNumbersParam = `titleNumbers=${ internalTitleNumbers.value.join(',') }`
            } else {
                titleNumbersParam = `titleNumber=${ internalTitleNumbers.value[0] }`
            }
        }
        const sourceParam = 'source=witness'
        const nameParam = conversationName.value.length > 0 ? `name=${ conversationName.value }` : null
        const params = [titleNumbersParam, matterIdParam, sourceParam, reportParam, nameParam, documentIdsParam].filter(p => p).join('&')
        return `${ host }/new-conversation?${ params }`
    })

    const trackClick = async () => {
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Copilot CTA: Go to Copilot',
            metadata: {
                matterId: matterId,
                position: position,
                titleNumbers: internalTitleNumbers?.value?.join(', ') ?? '',
                documentType: documentType,
                documentCount: internalDocIds.value.length,
                report: report,
            },
        })
    }

    const setTitleNumbers = (titleNos: string[]): void => {
        internalTitleNumbers.value = titleNos
    }

    const setMatterId = (newMatterId: number): void => {
        matterId = newMatterId
    }

    return {
        copilotUrl,
        disableCta,
        isCtaVisible,
        trackClick,
        setReport,
        setDocumentIds,
        setTitleNumbers,
        setMatterId,
    }
}
