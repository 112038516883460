<template>
    <div class="results-list"
         data-test="results-list">
        <div v-if="results.length"
             class="results-list__list">
            <result-item v-for="result in results"
                         :key="result.id"
                         :title-number="result.titleNumber"
                         :tenure="result.tenure"
                         :address="result.address"
                         :owner="result.owner"
                         @add-to-matter="addToMatter"
                         @click="onResultClick(result)" />
        </div>
        <div v-else
             class="results-list__empty-state">
            <ow-empty-state :empty-state-icon="emptyStateIcon"
                            :empty-state-title="emptyStateTitle"
                            :empty-state-subtitle="emptyStateSubtitle" />
        </div>
    </div>
</template>

<script lang="ts">
    import OwEmptyState from '@/components/core/ow-empty-state.vue'
    import ResultItem from '@/components/search/result-item.vue'

    export default {
        name: 'ResultsList',

        components: {
            OwEmptyState,
            ResultItem,
        },

        props: {
            results: {
                type: Array,
                required: false,
                default: () => [],
            },
            emptyStateIcon: {
                type: String,
                required: false,
                default: 'ow-icon-search-empty-state',
            },
            emptyStateTitle: {
                type: String,
                required: false,
                default: 'We didn\'t find anything that matches this search.',
            },
            emptyStateSubtitle: {
                type: String,
                required: false,
                default: 'Maybe try checking for typos, searching different titles, or adjusting your filters.',
            },
        },

        emits: [
            'on-result-click',
            'add-to-matter',
        ],

        methods: {
            onResultClick(result: any): void {
                this.$emit('on-result-click', result)
            },

            addToMatter(titleNumber: string): void {
                this.$emit('add-to-matter', titleNumber)
            },
        },
    }
</script>

<style lang="scss">
    @import './results-list.scss';
</style>
