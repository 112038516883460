import { IShapshotRenderData } from '@/components/snapshots/common/snapshot-interfaces'
import {
    mmPerInch,
    PageLayoutId,
    PageLayouts,
    SnapshotOrientation,
    SnapshotPrintSizeMarginsMm,
    SnapshotScreenSizeMarginsPx,
} from '@/components/snapshots/common/snapshot-enums-consts'
import OlMap from 'ol/Map'
import { getPointResolution } from 'ol/proj'

export const mmToPixels = (mm: number, dpi: number) => {
    return (mm / mmPerInch) * dpi
}

/**
 * Returns the CSS style for the layout div representing the 'page'.
 * Takes into account real world page sizes, assumed DPI, and margins.
 * @param layoutDiv the div representing the page layout.
 * @param renderData the render data for the snapshot.
 */
export const getLayoutStyleForPageLayout = (layoutDiv: HTMLElement, renderData: IShapshotRenderData): Partial<CSSStyleDeclaration> => {
    const result = {} as Partial<CSSStyleDeclaration>
    const pageLayout = PageLayouts[renderData.layoutId]

    // Height and width.
    if (renderData.customWidthPx && renderData.customHeightPx && renderData.layoutId === PageLayoutId.Custom) {
        result.width = `${ renderData.customWidthPx }px`
        result.height = `${ renderData.customHeightPx }px`
        result.padding = `${ SnapshotScreenSizeMarginsPx }px`
    } else {
        const width = renderData.orientation === SnapshotOrientation.Portrait ? pageLayout.width : pageLayout.height
        const height = renderData.orientation === SnapshotOrientation.Portrait ? pageLayout.height : pageLayout.width
        if (pageLayout.usesRealWorldUnitsMm) {
            const marginPx = mmToPixels(SnapshotPrintSizeMarginsMm, pageLayout.dpi)
            const widthInPixels = mmToPixels(width, pageLayout.dpi)
            const heightInPixels = mmToPixels(height, pageLayout.dpi)
            result.width = `${ widthInPixels }px`
            result.height = `${ heightInPixels }px`
            result.padding = `${ marginPx }px`
        } else {
            result.width = result.maxWidth = `${ width }px`
            result.height = result.maxHeight = `${ height }px`
            result.padding = `${ SnapshotScreenSizeMarginsPx }px`
        }
    }
    return result
}

/**
 * Returns the scaling factor to apply to the preview image/page to fit it within the viewport.
 * @param viewport the available space.
 * @param preview the preview image/page.
 */
export const getScalingFactorForPageLayout = (viewport: HTMLElement, preview: HTMLElement): number => {
    const viewportStyle = getComputedStyle(viewport)
    let viewportHeight = viewport.clientHeight // height with padding
    let viewportWidth = viewport.clientWidth // width with padding
    viewportHeight -= parseFloat(viewportStyle.paddingTop) + parseFloat(viewportStyle.paddingBottom)
    viewportWidth -= parseFloat(viewportStyle.paddingLeft) + parseFloat(viewportStyle.paddingRight)

    const previewStyle = getComputedStyle(preview)
    let previewHeight = preview.clientHeight // height with padding
    let previewWidth = preview.clientWidth // width with padding

    previewHeight += parseFloat(previewStyle.paddingTop) + parseFloat(previewStyle.paddingBottom)
    previewWidth += parseFloat(previewStyle.paddingLeft) + parseFloat(previewStyle.paddingRight)

    // Calculate the scaling factors for width and height separately
    const widthScale = viewportWidth / previewWidth
    const heightScale = viewportHeight / previewHeight

    // Use the smaller scaling factor to ensure the preview fits within the viewport
    const scale = Math.min(widthScale, heightScale)

    // If the scale is greater than 1, that means the preview is smaller than the viewport
    // so no need to actually scale it down.
    return Math.min(1, scale)
}

/**
 * Sets the map to the given scale and DPI.
 * @param map - the OpenLayers map to set the scale for.
 * @param scale - the desired scale to set.
 * @param dpi - the DPI to use for the scale calculation.
 */
export const setScaleForMap = (map: OlMap, scale: number | string, dpi: number) => {
    if (typeof scale === 'string') {
        scale = parseInt(scale)
    }
    const centre = map.getView().getCenter()
    const inchesPerMeter = 1000 / mmPerInch
    const desiredResolution = scale / (inchesPerMeter * dpi)

    if (desiredResolution &&
        desiredResolution > map.getView().getMinResolution() &&
        desiredResolution < map.getView().getMaxResolution()) {
        const currentResolution = getPointResolution(map.getView().getProjection(), map.getView().getResolution(), centre, 'm')
        map.getView().adjustResolution(desiredResolution / currentResolution, centre)
    }
}

/**
 * Returns the scale for the given map and DPI.
 * @param map - the OpenLayers map to get the scale for.
 * @param dpi - the DPI to use for the scale calculation.
 */
export const getScaleForMap = (map:OlMap, dpi: number) => {
    const resolution = getPointResolution(
        map.getView().getProjection(),
        map.getView().getResolution(),
        map.getView().getCenter(),
        'm',
    )

    const inchesPerMeter = 1000 / mmPerInch
    return Math.round(resolution * inchesPerMeter * dpi)
}
