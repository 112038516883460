<template>
    <div class="planning-actions-container">
        <div class="planning-actions-container__basic">
            <v-text-field v-model="textFilter"
                          autocomplete="new-password"
                          class="planning-actions-container__search"
                          clearable
                          data-test="planning-actions-search"
                          data-track="TITLE-DETAILS-PANEL - Search planning applications"
                          hide-details
                          variant="outlined"
                          label="Filter..."
                          prepend-inner-icon="$search"
                          append-inner-icon="$filter"
                          @click:clear="textFilter = null" />
            <v-select v-model="selectedSortOptionValue"
                      class="planning-actions-container__sort"
                      :items="sortOptions"
                      variant="outlined"
                      data-test="planning-actions-sort"
                      data-track="TITLE-DETAILS-PANEL - Sort planning applications"
                      hide-details
                      item-title="text"
                      item-value="id"
                      label="Sort by" />
        </div>

        <app-planning-actions-advanced data-test="planning-advanced-options-component" />
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import appPlanningActionsAdvanced from '@/components/title-panel/v2/planning/planning-actions-advanced.vue'
    import { planningSortingOptionsNames } from '@/consts/planning'
    import {
        PLANNING_MUTATE_SELECTED_SORT_OPTION,
        PLANNING_MUTATE_TEXT_FILTER,
        PLANNING_UPDATE_FILTERED_RESULTS,
    } from '@/store/modules/planning/types'

    export default {
        components: {
            appPlanningActionsAdvanced,
        },

        props: {},

        data() {
            return {
                sortOptions: Object.entries(planningSortingOptionsNames).map(([id, text]) => {
                    return {
                        id,
                        text,
                    }
                }),
            }
        },

        computed: {

            ...mapState({
                textFilterState: state => state.planning.inputs.textFilter,
                selectedSortOption: state => state.planning.selectedSortOption,
            }),

            selectedSortOptionValue: {
                get() {
                    return this.selectedSortOption
                },
                set(val) {
                    this.setSortOption(val)
                },
            },

            textFilter: {
                get() {
                    return this.textFilterState
                },
                set(val) {
                    this.mutateTextFilter(val)
                },
            },



        },

        watch: {
            selectedSortOptionValue() {
                this.updateFilteredResults()
            },
        },

        methods: {

            ...mapActions({
                updateFilteredResults: PLANNING_UPDATE_FILTERED_RESULTS,
            }),

            ...mapMutations({
                mutateTextFilter: PLANNING_MUTATE_TEXT_FILTER,
                setSortOption: PLANNING_MUTATE_SELECTED_SORT_OPTION,
            }),
        },
    }

</script>
<style lang="scss">
    @import 'planning-actions';
</style>
