<template>
    <v-card class="basic-info">
        <div class="avatar-container hide-in-percy">
            <v-avatar size="167">
                <span class="text-white text-h3">{{ initials }}</span>
            </v-avatar>
        </div>
        <v-card-title primary-title
                      class="d-flex flex-column justify-center">
            <div class="content__heading name hide-in-percy"
                 data-test="settings-account-user-name">
                {{ name }}
            </div>
            <div class="content__subheading type">
                {{ userType }}
            </div>
            <div class="hide-in-percy body-copy organisation">
                {{ organisation }} | Member since: {{ formatDate(joinDate) }}
            </div>
        </v-card-title>
    </v-card>
</template>

<script lang="ts">
    import useDates from '@/composables/use-dates'

    export default {
        name: 'Name',

        props: {
            name: {
                type: String,
                required: true,
            },
            userType: {
                type: String,
                required: true,
            },
            organisation: {
                type: String,
                required: true,
            },
            joinDate: {
                type: Date,
                required: true,
            },
        },

        computed: {
            initials() {
                return this.name.split(' ').map((n) => n[0]).join(' ')
            },
        },

        methods: {
            formatDate(value: Date): string {
                const { formatAsMonthYear } = useDates()
                return formatAsMonthYear(value)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './name'
</style>
