import { ISnapshotPageLayout } from '@/components/snapshots/common/snapshot-interfaces'

export const SnapshotMapMaxZoom = 19
export const SnapshotPrintSizeMarginsMm = 12.7
export const SnapshotScreenSizeMarginsPx = 16
export const SnapshotFontSizeBaseline = 12
export const SnapshotDefaultBrowserDpi = 72
export const mmPerInch = 25.4

export enum SnapshotType {
    Siteplan = 'siteplan',
}

export enum SnapshotOrientation {
    Landscape = 'landscape',
    Portrait = 'portrait',
}

export enum PageLayoutId {
    A3 = 'a3',
    A4 = 'a4',
    HD = 'hd',
    Custom = 'custom',
}

type PageLayoutsType = {
    [key: string]: ISnapshotPageLayout;
}

/**
 * Page layouts for snapshots. Note dimensions assume a portrait orientation.
 */
export const PageLayouts: PageLayoutsType = {
    [PageLayoutId.A4]: {
        id: PageLayoutId.A4,
        usesRealWorldUnitsMm: true,
        width: 210,
        height: 297,
        i18nLabel: 'snapshots.layout.a4',
        dpi: 220,
        graphicSizeMultiplier: 1.7,
    },

    [PageLayoutId.A3]: {
        id: PageLayoutId.A3,
        usesRealWorldUnitsMm: true,
        width: 297,
        height: 420,
        i18nLabel: 'snapshots.layout.a3',
        dpi: 220,
        graphicSizeMultiplier: 2.4,
    },

    [PageLayoutId.HD]: {
        id: PageLayoutId.HD,
        usesRealWorldUnitsMm: false,
        width: 1080,
        height: 1920,
        i18nLabel: 'snapshots.layout.hd',
        graphicSizeMultiplier: 1,
    },

    [PageLayoutId.Custom]: {
        id: PageLayoutId.Custom,
        usesRealWorldUnitsMm: false,
        width: 1080,
        height: 1920,
        i18nLabel: 'snapshots.layout.custom',
        graphicSizeMultiplier: 1,
    },
}

export enum SnapshotExportFormat {
    PDF = 'pdf',
    JPEG = 'jpg',
}

// Used to render a marker graphic
export const getMarkerKeyGraphicSvg = (colour: string) => `url('data:image/svg+xml,%3Csvg viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg" fill="none" class="sketches-list-item__icon" style="width: 24px; height: 24px;"%3E%3Cdesc%3Esketches marker icon%3C/desc%3E%3Cpath d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.66304 14.2366 10.2989 13.7678 10.7678C13.2989 11.2366 12.663 11.5 12 11.5Z" fill="${ encodeURIComponent(colour) }"%3E%3C/path%3E%3C/svg%3E')`

// The following are the properties we want to include in snapshots, the others can be discarded.
export const sketchSnapshotAttributes = [
    'arrowhead',
    'comment',
    'dash',
    'fillColour',
    'fillOpacity',
    'geometryType',
    'hatch',
    'id',
    'matterGroupId',
    'name',
    'showLabel',
    'sketchType',
    'sortOrder',
    'strokeColour',
    'strokeWidth',
]
