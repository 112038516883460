import { getCenter } from 'ol/extent'
import GeoJSON from 'ol/format/GeoJSON'
import {
    transform,
    transformExtent,
} from 'ol/proj'

import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import {
    NPS_GET_FEATURES_BY_TITLE_NUMBERS,
    NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
} from '@/store/modules/nps/types'
import { TITLE_PANEL_COLLAPSE } from '@/store/modules/titles/types'
import { LOGGING_LOG_FEATURE_USAGE } from '@/store/mutation-types'
import {
    getGoogleMapsStyleForTitle,
    runGoogleStreetViewFixes,
} from '@/utils/site-visit-utils'

import {
    SITE_VISIT_INIT_GOOGLE_MAPS,
    SITE_VISIT_LOOK_AT_SELECTED_TITLE,
    SITE_VISIT_MUTATE_BOUNDS,
    SITE_VISIT_MUTATE_ENABLED,
    SITE_VISIT_MUTATE_PANORAMA_HEADING,
    SITE_VISIT_MUTATE_PANORAMA_LOCATION,
    SITE_VISIT_MUTATE_POINTER_LOCATION,
    SITE_VISIT_MUTATE_STARTING_LOCATION,
    SITE_VISIT_SET_ENABLED,
    SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER,
} from './types'

export default {
    async [SITE_VISIT_SET_ENABLED]({
        commit,
        dispatch,
        rootState,
        state,
    }, enabled) {
        if (state.initialized === false && enabled === true) {
            dispatch(SITE_VISIT_INIT_GOOGLE_MAPS)

            // log usage
            dispatch(LOGGING_LOG_FEATURE_USAGE, {
                type: 'street-view',
                description: null,
            })
        } else {
            if (enabled === true) {
                const bounds = transformExtent(rootState.map.map.getView().calculateExtent(), CoordinateSystemCode.EPSG27700, CoordinateSystemCode.EPSG4326)
                commit(SITE_VISIT_MUTATE_BOUNDS, bounds)
                state.panorama.setVisible(true)
                dispatch('_updateFromPanorama')
                dispatch(SITE_VISIT_LOOK_AT_SELECTED_TITLE)
            } else {
                commit(SITE_VISIT_MUTATE_PANORAMA_LOCATION, null)
            }
            commit(SITE_VISIT_MUTATE_ENABLED, enabled)
        }

        commit(SITE_VISIT_MUTATE_STARTING_LOCATION, enabled)

        dispatch(SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER)

        if (enabled === true) {
            dispatch(TITLE_PANEL_COLLAPSE)
        }

        if (state.panorama !== null) {
            setTimeout(() => {
                window.google.maps.event.trigger(state.map, 'resize')
            }, 500)
        }
    },

    async [SITE_VISIT_INIT_GOOGLE_MAPS]({
        commit,
        dispatch,
        rootState,
        state,
    }) {
        const script = document.createElement('script')
        script.onload = async () => {
            state.initialized = true

            const centre = transform(rootState.map.map.getView().getCenter(), CoordinateSystemCode.EPSG27700, CoordinateSystemCode.EPSG4326)
            state.mapDiv = document.getElementById('googleMap')

            runGoogleStreetViewFixes()

            state.map = window.siteVisitMap = new window.google.maps.Map(state.mapDiv, {
                center: new window.google.maps.LatLng(centre[1], centre[0]),
                zoom: 18,
                streetViewControlOptions: {
                    position: window.google.maps.ControlPosition.LEFT_TOP,
                },
                mapTypeControlOptions: {
                    position: window.google.maps.ControlPosition.TOP_CENTER,
                },
                rotateControlOptions: {
                    position: window.google.maps.ControlPosition.BOTTOM_LEFT,
                },
                keyboardShortcuts: false,
                zoomControl: false,
                fullscreenControl: false,
                mapTypeId: window.google.maps.MapTypeId.HYBRID,
            })
            state.map.setOptions({ draggableCursor: 'pointer' })

            state.panorama = new window.google.maps.StreetViewPanorama(
                document.getElementById('googleStreetViewPanorama'), {
                    position: new window.google.maps.LatLng(centre[1], centre[0]),
                    fullscreenControl: true,
                    keyboardShortcuts: false,
                    mode: 'html4',
                    pov: {
                        heading: 34,
                        pitch: 0,
                    },
                })
            state.map.setStreetView(state.panorama)
            const streetViewLayer = new window.google.maps.StreetViewCoverageLayer()
            streetViewLayer.setMap(state.map)

            // map event listeners
            window.google.maps.event.addListener(state.map, 'mousemove', function(event) {
                commit(
                    SITE_VISIT_MUTATE_POINTER_LOCATION,
                    transform([event.latLng.lng(), event.latLng.lat()], CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700))
            })

            window.google.maps.event.addListener(state.map, 'dragend', function() {
                const centre = state.map.getCenter()
                rootState.map.map.getView().setCenter(
                    transform([centre.lng(), centre.lat()], CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700))
            })

            window.google.maps.event.addListener(state.map, 'mouseout', function() {
                commit(SITE_VISIT_MUTATE_POINTER_LOCATION, null)
            })

            window.google.maps.event.addListener(state.map, 'click', function(event) {
                state.panorama.setPosition(event.latLng)
                if (state.selectingStartLocation === true) {
                    commit(SITE_VISIT_MUTATE_STARTING_LOCATION, false)
                    state.map.setCenter(event.latLng)
                    rootState.map.map.getView().setCenter(
                        transform([event.latLng.lng(), event.latLng.lat()], CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700))
                    dispatch('_updateFromPanorama')
                }
            })

            // panorama event listeners
            state.panorama.addListener('position_changed', function() {
                dispatch('_updateFromPanorama')
            })
            state.panorama.addListener('pov_changed', function() {
                dispatch('_updateFromPanorama')
            })

            commit(SITE_VISIT_MUTATE_ENABLED, true)

            state.map.data.setStyle((feature) => {
                return feature.getProperty('googleMapStyle')
            })

            await dispatch(SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER)

            // Set initial view to the same bounds as the main map
            const mainMapExtent = transformExtent(
                rootState.map.map.getView().calculateExtent(), CoordinateSystemCode.EPSG27700, CoordinateSystemCode.EPSG4326)
            commit(SITE_VISIT_MUTATE_BOUNDS, mainMapExtent)
        }
        script.type = 'text/javascript'
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDMzjhNWq0iJXhEXcQG8HyQYDiv9ZGVzTk&v=3'
        document.body.appendChild(script)
    },

    // TODO: Should be using type as the name
    // TODO: Is this really a mutation?
    _updateFromPanorama({
        commit,
        state,
    }) {
        const location = state.panorama.getPosition()
        commit(SITE_VISIT_MUTATE_PANORAMA_LOCATION,
            transform([location.lng(), location.lat()], CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700))

        const heading = state.panorama.getPov().heading
        commit(SITE_VISIT_MUTATE_PANORAMA_HEADING, heading)

        state.panorama.setVisible(true)
    },

    async [SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER]({
        rootState,
        state,
        dispatch,
        getters,
    }) {
        // For visible titles within a matter, make sure they are displayed on Google Maps as well.
        if (state.initialized && state.enabled) {
            // Get an array of title numbers that should have visible boundaries on the map.
            const visibleTitles = rootState.matter.currentMatter.selectedTitles
                .filter(t => t.show)
            const selectedTitleNumber = rootState.title.selectedTitleNumber
            if (selectedTitleNumber) {
                visibleTitles.push({ titleNumber: selectedTitleNumber })
            }
            const expandedTitleNumber = rootState.title.expandedTitleNumber
            if (expandedTitleNumber) {
                visibleTitles.push({ titleNumber: expandedTitleNumber })
            }
            const visibleTitleNumbers = visibleTitles.map(t => t.titleNumber)

            // Make sure the boundaries are loaded.
            await dispatch(NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS, visibleTitleNumbers)
            const features = getters[NPS_GET_FEATURES_BY_TITLE_NUMBERS](visibleTitleNumbers)

            // Determine title boundaries that need adding.
            const featuresToAdd = features.filter(feature => !state.map.data.getFeatureById(feature.getId()))

            // Add them to the map
            featuresToAdd.forEach(feature => {
                const reader = new GeoJSON()
                const titleFeature4326JSON = reader.writeFeature(feature, {
                    dataProjection: CoordinateSystemCode.EPSG4326,
                    featureProjection: CoordinateSystemCode.EPSG27700,
                })

                const data = JSON.parse(titleFeature4326JSON)
                state.map.data.addGeoJson(data)
            })

            state.map.data.forEach(feature => {
                const title = visibleTitles.find(t => t.titleNumber === feature.getProperty('title_no'))

                if (!title) {
                    // Remove title feature from the map that is not required.
                    state.map.data.remove(feature)
                } else {
                    // Apply styling.
                    feature.setProperty(
                        'googleMapStyle',
                        getGoogleMapsStyleForTitle(title, selectedTitleNumber === title.titleNumber || expandedTitleNumber === title.titleNumber),
                    )
                }
            })
        }
    },

    [SITE_VISIT_LOOK_AT_SELECTED_TITLE]({
        rootState,
        state,
    }) {
        if (rootState.title.selectedTitleNumber !== null && state.enabled === true) {
            const title = rootState.title.selectedTitle
            if (title.record) {
                const geom = (new GeoJSON()).readFeature(title.record.bbox)
                const centre = getCenter(geom.getGeometry().getExtent())
                const p1Loc = transform(centre, CoordinateSystemCode.EPSG27700, CoordinateSystemCode.EPSG4326)
                const panoLocation = state.panorama.getPosition()

                const point2 = new window.google.maps.LatLng(p1Loc[1], p1Loc[0])
                const point1 = new window.google.maps.LatLng(panoLocation.lat(), panoLocation.lng())
                const heading = window.google.maps.geometry.spherical.computeHeading(point1, point2)

                state.panorama.setPov({
                    pitch: state.panorama.getPov().pitch,
                    heading,
                })
            }
        }
    },
}
