<template>
    <div>
        <ow-page-layout v-if="!orders || !draftOrders || !completedOrders || loading"
                        page-name="matter-searches">
            <matter-searches-loading-skeleton />
        </ow-page-layout>
        <ow-page-layout v-else-if="orders?.length || draftOrders?.length || completedOrders?.length"
                        page-name="matter-searches"
                        :header-svg-icon="OwIconSearches"
                        :class="{
                            'matter-searches--loading': loading,
                        }"
                        :header-title="$t('searches.title')"
                        :header-description="$t('searches.description')">
            <template #headerActions>
                <div class="matter-searches__actions d-flex pl-3">
                    <ow-button is-primary
                               data-test="order-search-button"
                               size="large"
                               @click="onOrderSearch">
                        {{ $t('searches.orderSearches') }}
                    </ow-button>
                </div>
            </template>
            <div class="mt-4">
                <div v-if="draftOrders?.length">
                    <ow-grid-header :collapsed="draftOrdersCollapsed"
                                    collapsible
                                    sticky
                                    show-expand-icon
                                    divider
                                    :link="{
                                        label: 'Delete All Drafts',
                                        theme: 'danger',
                                        show: false,
                                        handler: () => {},
                                    }"
                                    :title="`Draft Orders (${ draftOrders?.length })`"
                                    description="Orders that have been saved as drafts"
                                    @click="draftOrdersCollapsed = !draftOrdersCollapsed">
                        <matter-searches-card-list v-if="draftOrders?.length"
                                                   :key="listKey"
                                                   class="mx-4">
                            <matter-searches-card v-for="(item, index) in draftOrders"
                                                  :key="index"
                                                  class="matter-searches__card matter-searches__card--draft"
                                                  v-bind="item"
                                                  :title-numbers="item?.associatedTitleNumbers ? item?.associatedTitleNumbers : [item?.titleNumber]"
                                                  :geo-json="item?.geoJson"
                                                  :map-options="mapOptions"
                                                  :address="item?.address"
                                                  @click="onDraftCardClick(item)" />
                        </matter-searches-card-list>
                    </ow-grid-header>
                </div>
                <div v-if="completedOrders?.length">
                    <ow-grid-header :collapsed="completedOrdersCollapsed"
                                    collapsible
                                    sticky
                                    show-expand-icon
                                    divider
                                    :title="`Completed (${ completedOrders?.length })`"
                                    description="Orders that have been completed"
                                    @click="completedOrdersCollapsed = !completedOrdersCollapsed">
                        <matter-searches-card-list v-if="completedOrders?.length"
                                                   :key="listKey"
                                                   class="mx-4">
                            <matter-searches-card v-for="(item, index) in completedOrders"
                                                  :key="index"
                                                  class="matter-searches__card matter-searches__card--completed"
                                                  v-bind="item"
                                                  :title-numbers="item?.associatedTitleNumbers ? item?.associatedTitleNumbers : [item?.titleNumber]"
                                                  :geo-json="item?.geoJson"
                                                  :map-options="mapOptions"
                                                  :address="item?.address"
                                                  @click="onCardClick(item)" />
                        </matter-searches-card-list>
                    </ow-grid-header>
                </div>
                <div v-if="orders?.length">
                    <ow-grid-header :collapsed="ordersCollapsed"
                                    collapsible
                                    sticky
                                    show-expand-icon
                                    divider
                                    :title="`Orders (${ orders?.length })`"
                                    description="Orders that are in progress"
                                    @click="ordersCollapsed = !ordersCollapsed">
                        <matter-searches-card-list v-if="orders?.length"
                                                   :key="listKey"
                                                   class="mx-4">
                            <matter-searches-card v-for="(item, index) in orders"
                                                  :key="index"
                                                  class="matter-searches__card matter-searches__card--submitted"
                                                  v-bind="item"
                                                  :title-numbers="item?.associatedTitleNumbers ? item?.associatedTitleNumbers : [item?.titleNumber]"
                                                  :geo-json="item?.geoJson"
                                                  :map-options="mapOptions"
                                                  :address="item?.address"
                                                  @click="onCardClick(item)" />
                        </matter-searches-card-list>
                    </ow-grid-header>
                </div>
            </div>
        </ow-page-layout>
        <div v-else
             class="d-flex flex-column justify-center align-center mx-15 matter-searches">
            <matter-searches-intro @order-search="onOrderSearch"
                                   @searches-register-interest="onRegisterInterest" />
        </div>
        <ow-modal v-model="showSearchesRegisterInterestSuccess"
                  class="matter-searches-details__notifications-alert mb-4"
                  title="Register interest"
                  @close="showSearchesRegisterInterestSuccess = false">
            <div class="w-100">
                {{ $t('searches.intro.registerInterestCtaThanks') }}
            </div>
            <template #actions />
        </ow-modal>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        ref,
        watch,
    } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import OwIconSearches from '@/components/core/icons/ow-icon-searches.vue'
    import OwPageLayout from '@/components/core/layout/full-width.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwGridHeader from '@/components/core/ow-grid/ow-grid-header.vue'
    import OwModal from "@/components/core/ow-modal.vue"
    import MatterSearchesLoadingSkeleton from '@/components/loading-skeletons/matter-searches-loading-skeleton.vue'
    import MatterSearchesCard from '@/components/matter-searches/matter-searches-card.vue'
    import MatterSearchesCardList from '@/components/matter-searches/matter-searches-card-list.vue'
    import MatterSearchesIntro from '@/components/matter-searches/matter-searches-intro.vue'
    import { useMapOptions } from '@/composables/use-map-options'
    import { Route } from '@/enums/route.enum'
    import {
        getStepName,
        OrderState,
        SearchesSteps,
    } from '@/enums/searches.enum'
    import {
        SEARCHES_FETCH_ORDERS,
        SEARCHES_GET_LOADING,
        SEARCHES_GET_ORDERS,
        SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING,
        SEARCHES_MUTATE_CLEAR_QUOTE,
        SEARCHES_MUTATE_LOADING,
        SEARCHES_MUTATE_RESET,
        SearchOrder,
    } from '@/store/modules/searches/types'
    import {LOGGING_HEAP_TRACK_EVENT} from "@/store/mutation-types"

    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const mapOptions = useMapOptions()
    // store
    const matterId = computed(() => route.params.matterId)
    const orderSearchEnabled = computed(() => store.state.config.featureFlags?.orderSearch)
    const loading = computed(() => store.getters[SEARCHES_GET_LOADING])
    const orders = computed<SearchOrder[]>(() => store.getters[SEARCHES_GET_ORDERS]?.filter((order: SearchOrder) => order.state !== OrderState.Draft && order.state !== OrderState.Completed ) ?? null)
    const draftOrders = computed<SearchOrder[]>(() => store.getters[SEARCHES_GET_ORDERS]?.filter((order: SearchOrder) => order.state === OrderState.Draft) ?? null)
    const completedOrders = computed<SearchOrder[]>(() => store.getters[SEARCHES_GET_ORDERS]?.filter((order: SearchOrder) => order.state === OrderState.Completed) ?? null)
    const address = computed<string>(() => store.getters[SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING])
    const listKey = ref(`list-${ Date.now() }`)
    const draftOrdersCollapsed = ref(false)
    const ordersCollapsed = ref(false)
    const completedOrdersCollapsed = ref(false)
    const showSearchesRegisterInterestSuccess = ref(false)

    watch(() => route.name, async (val) => {
        if (val === Route.MatterSearches) {
            store.commit(SEARCHES_MUTATE_LOADING, true)
            try {
                await store.dispatch(SEARCHES_FETCH_ORDERS, {
                    matterId: matterId?.value,
                })
                listKey.value = `list-${ Date.now() }`
            } finally {
                store.commit(SEARCHES_MUTATE_LOADING, false)
            }
        }
    }, {
        immediate: true,
    })

    const redirectToSearchesIntroPage = () => {
        router.push({
            name: Route.MatterSearches,
            params: {
                matterId: matterId?.value,
            },
        })
    }

    onBeforeMount(() => {
        if (orderSearchEnabled.value !== undefined && !orderSearchEnabled.value) {
            redirectToSearchesIntroPage()
        }
    })

    const onOrderSearch = async () => {
        router.push({
            name: Route.MatterSearchesCreate,
            params: {
                step: getStepName(SearchesSteps.DefinePolygon),
            },
        })
    }

    const onCardClick = async (item) => {
        router.push({
            name: Route.MatterSearchesDetails,
            params: {
                matterId: matterId?.value,
                id: item?.id,
            },
            query: {
                tab: 0,
            },
        })
    }

    const onDraftCardClick = async (order: SearchOrder) => {
        router.push({
            name: Route.MatterSearchesCreate,
            params: {
                args: order.id,
                matterId: matterId?.value,
                step: getStepName(SearchesSteps.Draft),
            },
        })
    }

    const onRegisterInterest = async () => {
        showSearchesRegisterInterestSuccess.value = true
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Searches Register Interest',
            metadata: {
                userEmail: store.state.user.email,
                orgId: store.state.user.organisationId,
            },
        })
    }

    defineExpose({
        router,
    })
</script>

<style lang="scss">
    @import './matter-searches';
</style>
