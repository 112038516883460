import {
    BaseMvtDataLayer,
    BaseMvtDataLayerParams,
} from './base-mvt-data-layer'
import { LayerNames } from '@/consts/map-layers'
import Style, {
    StyleFunction,
    StyleLike,
} from 'ol/style/Style'
import {
    Fill,
    Stroke,
} from 'ol/style'
import { FeatureLike } from 'ol/Feature'
import i18n from '@/plugins/i18n'
import {
    IMapRolloverOptions,
} from '@/components/map-rollover/common/map-rollover-interfaces'
import { isNullOrEmpty } from '@/utils/array-utils'
import { MapBrowserEvent } from 'ol'
import { layerEquals } from '@/utils/map-utils'

export type ScotlandInspireFeatureType = {
    inspireid: string,
    label: string,
    nationalca: string,
    county: string,
}

export class ScotlandInspireLayer extends BaseMvtDataLayer {
    public maxResolution = 3
    private scotlandCadastralRegions = {
        ABN: "Aberdeen",
        ANG: "Angus",
        ARG: "Argyll",
        AYR: "Ayr",
        BER: "Berwick",
        BNF: "Banff",
        BUT: "Bute",
        CLK: "Clackmannan",
        CTH: "Caithness",
        DMB: "Dumbarton",
        DMF: "Dumfries",
        ELN: "East Lothian",
        FFE: "Fife",
        GLA: "Glasgow",
        INV: "Inverness",
        KNC: "Kincardine",
        KNR: "Kinross",
        KRK: "Kirkcudbright",
        LAN: "Lanark",
        MID: "Midlothian",
        MOR: "Moray",
        NRN: "Nairn",
        OAZ: "Orkney and Zetland",
        PBL: "Peebles",
        PTH: "Perth",
        REN: "Renfrew",
        ROS: "Ross and Cromarty",
        ROX: "Roxburgh",
        SEL: "Selkirk",
        STG: "Stirling",
        STH: "Sutherland",
        WGN: "Wigtown",
        WLN: "West Lothian",
    }

    constructor(params: BaseMvtDataLayerParams, settings: any) {
        super(params, settings)
        this.targetMap = params.targetMap ?? null
        this.interactive = !params.snapshotConfig
        this.layerName = 'ow:scotland'
        this.i18nNameRef = 'map.options.scotland'
        this.attributionText = ['© Crown copyright. Reproduced with the permission of Registers of Scotland.']
        this.name = LayerNames.ScotlandInspireLayer
        this.rolloverOptions = this.getRolloverOptions(this.style)
        this.initialiseLayer()
    }

    public style:StyleLike = (feature: FeatureLike) => {
        return new Style({
            zIndex: 300,
            fill: new Fill({
                color: 'rgba(127, 62, 207, 0.7)',
            }),
            stroke: new Stroke({
                color: 'rgba(127, 62, 207, 1)',
            }),
        })
    }

    protected handleClick(event: MapBrowserEvent<any>):void {
        if (!this.layer.getVisible()) {
            return
        }
        const pixel = this.targetMap.getEventPixel(event.originalEvent)
        const clickedFeatures: Array<FeatureLike> = this.targetMap.getFeaturesAtPixel(pixel, {
            layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
        })
        const coordinate = this.targetMap.getCoordinateFromPixel(pixel).map(c => Math.trunc(c))
        this.onClickFn({
            clickCoordinate: coordinate,
            clickFeatures: clickedFeatures,
        })
    }

    private getRolloverOptions(style: StyleLike): IMapRolloverOptions {
        return {
            showPrimary: true,
            showExtended: true,
            sortOrder: 0,
            category: i18n.global.t(`map.rollover.scotland.category`),
            source: i18n.global.t(`map.rollover.scotland.source`),
            getStyle: (feature) => (style as StyleFunction)(feature, null) as Style,
            getPrimary: (features) => {
                if (!isNullOrEmpty(features)) {
                    const properties = features[0].getProperties() as ScotlandInspireFeatureType
                    return `County:  ${ this.scotlandCadastralRegions[properties.county] }`
                }
                return 'County not available'
            },
            getExtended(features) {
                const labels = features.map(f => {
                    const properties = f.getProperties() as ScotlandInspireFeatureType
                    return properties.label
                })
                return [{
                    name: labels.length > 1 ? `IDs(${ labels.length })` : 'ID',
                    value: labels.length > 1 ? labels.length > 2 ? `${ labels.slice(0,2).join(', ') } ...` : `${ labels.join(', ') }` : labels[0],
                },
                {
                    name: 'Info',
                    value: 'Layer is not interactive',
                }]
            },
        }
    }
}
