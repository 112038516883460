<template>
    <div class="title-number-group-item"
         :data-document-id="document.documentId"
         data-test="document-selection-title-number-group-item">
        <ow-checkbox :id="`select-group-${titleNumber}-${listPosition}`"
                     v-model="isDocSelected"
                     :data-test="`document-selection-title-number-group-item-checkbox-${document.documentId}`" />
        <div class="title-number-group-item__cell caption-highlight">
            <ow-icon-hmlr width="16px" />
            <div class="title-number-group-item__cell--name"
                 :title="document.name">
                {{ document.name }}
            </div>
        </div>
        <div class="title-number-group-item__cell caption-regular">
            {{ documentTypeLabel(document.documentType) }}
        </div>
        <div class="title-number-group-item__cell caption-regular">
            {{ titleNumber }}
        </div>
        <div class="title-number-group-item__cell title-number-group-item__cell--group caption-regular"
             :title="document.group ?? ''">
            {{ document.group ?? '-' }}
        </div>
        <div class="title-number-group-item__cell caption-regular">
            {{ document.docDate }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed,
             PropType } from 'vue'

    import { IDocumentSelectionGroupDocument } from '@/components/copilot/document-selection/document-selection-group-document.interface'
    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'

    const emit = defineEmits<{
        (e: 'change', value: boolean, documentId: number),
    }>()

    const props = defineProps({
        document: {
            type: Object as PropType<IDocumentSelectionGroupDocument>,
            required: true,
        },
        titleNumber: {
            type: String,
            required: true,
        },
        listPosition: {
            type: Number,
            required: true,
        },
    })

    const isDocSelected = computed({
        get: () => props.document.selected,
        set: (val: boolean) => {
            emit('change', val, props.document.documentId)
        },
    })

    const documentTypeLabel = (documentType: string): string => {
        switch(documentType.toLowerCase()) {
            case 'titleregister':
                return ' Register'

            case 'titleplan':
                return 'Plan'

            case 'officialcopy':
                return 'Official Copy'

            default:
                return documentType
        }
    }
</script>

<style scoped lang="scss">
    @import './document-selection-title-num-group-item.scss';
</style>
