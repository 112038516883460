import { debounce } from 'lodash'

import DocumentOrderingApi from '@/api/document-ordering.api'
import MatterApi from '@/api/matter.api'
import { HighLevelDocumentType as HighLevelDocType } from '@/consts/document-high-level-type'
import { DocumentOrderStatus } from '@/consts/document-order-status'
import { DocumentType } from '@/consts/document-type'
import { apolloClient } from '@/graphql/apollo'
import { GET_TITLES_QUERY_BY_COLUMN_SELECTION } from '@/graphql/queries/titles.gql.js'
import { TrackedTitleListener } from '@/interfaces/store/organisation-hub/tracked-title'
import i18n from '@/plugins/i18n'
import {
    ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE,
    ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE,
    ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER,
} from '@/store/modules/organisation-hub/types'
import {
    createDefaultTAMEntryForTitle,
    createGenericRegisterEntriesForTitle,
} from '@/store/modules/title-analysis/utils'
import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
import { unique } from '@/utils/array-utils'
import { getHighLevelDocumentTypeFromDocumentType } from '@/utils/document-ordering-utils'
import { isNullOrWhitespace } from '@/utils/string-utils.js'

import {
    ANALYSIS_FETCH_ALL_TITLES_FOR_MATTER,
    ANALYSIS_MUTATE_ADDRESSES,
    ANALYSIS_MUTATE_DERIVED_DATA,
    ANALYSIS_MUTATE_DERIVED_DATA_REGISTER_ENTRIES,
    ANALYSIS_MUTATE_LOADING_STATUS,
    ANALYSIS_MUTATE_ORDER_STATUS,
    ANALYSIS_MUTATE_SOURCE_DATA,
    ANALYSIS_RESET_GRID_CONFIGURATION,
    ANALYSIS_UPDATE_FILTER_OPTIONS,
    ANALYSIS_UPDATE_TITLE_IN_DERIVED_DATA,
    STATE_STORAGE_KEY,
} from './types.js'

export default {
    async [ANALYSIS_FETCH_ALL_TITLES_FOR_MATTER]({
        commit,
        state,
        dispatch,
        rootState,
    }, { matter }) {
        if (!matter?.id || state.selectedColumns.length === 0) {
            return
        }

        commit(ANALYSIS_MUTATE_LOADING_STATUS, true)

        if (state.debouncedFetchData === null) {
            state.debouncedFetchData = debounce(async (matter) => {
                const response = await apolloClient.query({
                    query: GET_TITLES_QUERY_BY_COLUMN_SELECTION(state.selectedColumns),
                    variables: {
                        matterId: matter.id,
                    },
                    // Disables the cache so always goes back to the server
                    fetchPolicy: 'network-only',
                })

                const addressData = await MatterApi.getTitleAddressesForMatter(matter.id)

                const orderStatuses = await DocumentOrderingApi.getDocumentOrderStatus({
                    matterId: rootState.matter.currentMatter.id,
                    documentTypes: [DocumentType.REGISTER],
                })

                // append document order status to every titleRegister object ... ugly
                const titleRegisters = response.data.titleRegisters.map(titleRegister => {
                    const documentOrderStatus = (orderStatuses || []).find(orderStatus => orderStatus.keyValue === titleRegister.titleNumber)?.documentOrderStatus
                    return { ...titleRegister, documentOrderStatus }
                })

                // Update source data being used to render rows in the grid
                commit(ANALYSIS_MUTATE_SOURCE_DATA, titleRegisters)
                commit(ANALYSIS_MUTATE_ADDRESSES, addressData)
                if (state.appliedTemplate?.name === i18n.global.t('tam.templates.allRegisterEntries.name')) {
                    commit(ANALYSIS_MUTATE_DERIVED_DATA_REGISTER_ENTRIES, {
                        data: state.sourceData,
                        matter,
                    })
                } else {
                    commit(ANALYSIS_MUTATE_DERIVED_DATA, {
                        data: state.sourceData,
                        matter,
                        addressData,
                    })
                }

                // Update filters that provide values based on the available data
                dispatch(ANALYSIS_UPDATE_FILTER_OPTIONS)

                commit(ANALYSIS_MUTATE_LOADING_STATUS, false)

                // Listen for notification hub updates related to the titles in TAM.
                commit(`organisationHub/${ ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER }`, TrackedTitleListener.TitleAnalysis, { root: true })
                response.data.titleRegisters.forEach(x => {
                    commit(`organisationHub/${ ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE }`, {
                        titleNumber: x.titleNumber,
                        listener: TrackedTitleListener.TitleAnalysis,
                    }, { root: true })
                })
            }, 500)
        }
        state.debouncedFetchData(matter)
    },

    async [ANALYSIS_UPDATE_TITLE_IN_DERIVED_DATA]({
        state,
    }, data) {
        const {
            titleNumber,
            matterId,
        } = data

        // If query is undefined, then TAM hasn't been loaded yet or user not in a matter context
        const query = GET_TITLES_QUERY_BY_COLUMN_SELECTION(state.selectedColumns)
        if (query) {
            const response = await apolloClient.query({
                query,
                variables: {
                    matterId,
                    titleNumbers: [titleNumber],
                },
                // Disables the cache so always goes back to the server
                fetchPolicy: 'network-only',
            })

            const recordsToUpdate = state.gridInstance.store.query(titleRegister => titleRegister.titleNumber === titleNumber)
            const updatedRegister = response?.data?.titleRegisters[0]
            const addressData = await MatterApi.getTitleAddressesForMatter(matterId)

            if (recordsToUpdate.length > 0 && updatedRegister) {
                const scrollLeft = state.gridInstance.element.querySelector('.b-grid-body-container').scrollLeft
                const scrollTop = state.gridInstance.element.querySelector('.b-grid-body-container').scrollTop

                recordsToUpdate.forEach(record => {
                    const documentOrderStatus = record.documentOrderStatus
                    const matterGroup = record.matterGroup

                    const newData = {
                        documentOrderStatus,
                        matterGroup,
                        ...(state.appliedTemplate?.name === i18n.global.t('tam.templates.allRegisterEntries.name')
                            ? createGenericRegisterEntriesForTitle(updatedRegister, matterGroup)
                            : createDefaultTAMEntryForTitle(updatedRegister, addressData.find((addressTitle) => addressTitle.titleNumber === updatedRegister.titleNumber ), matterGroup)),
                    }
                    record.set(newData)
                })
                state.gridInstance.store.refreshData()
                state.gridInstance.element.querySelector('.b-grid-body-container').scrollTo(scrollLeft, scrollTop)
            }
        }
    },

    [ANALYSIS_RESET_GRID_CONFIGURATION]({
        dispatch,
        state,
    }) {
        // Log the current configuration before resetting
        const heapEvent = {
            type: 'Title Analysis - Reset Grid Configuration',
            metadata: {},
        }
        state.selectedColumns.forEach(column => {
            heapEvent.metadata[`selected_col_${ column.field }`] = true
        })
        dispatch(LOGGING_HEAP_TRACK_EVENT, heapEvent, { root: true })

        localStorage.removeItem(STATE_STORAGE_KEY)
    },

    [ANALYSIS_UPDATE_FILTER_OPTIONS]({ state }) {
        // Based on the available data, populate filter options where combo boxes are used
        const data = state.derivedData
        if (state.gridInstance) {
            state.selectedColumns
                .filter(c => c.useDefaultComboFilter === true)
                .forEach(s => {
                    const column = state.gridInstance.columns.allRecords.find(c => c.field === s.field)
                    const existingFilterField = state.gridInstance.features.filterBar.getColumnFilterField(column)
                    const uniqueDataItems = unique(data.map(d => d[s.field]))
                        .filter(d => !isNullOrWhitespace(d))
                        .sort()
                        .map(d => {
                            return {
                                name: d,
                            }
                        })

                    if (existingFilterField) {
                        existingFilterField.changeItems(uniqueDataItems)
                    }
                })
        }
    },

    async [ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE]({ dispatch, commit, rootState }, orderMessage) {
        const update = orderMessage.message
        const highLevelDocType = getHighLevelDocumentTypeFromDocumentType(update.notificationData.documentType)
        // Only title register updates are relevant to TAM.
        if (highLevelDocType === HighLevelDocType.Register && update?.status) {
            commit(ANALYSIS_MUTATE_ORDER_STATUS, { titleNumber: update.titleNumber, documentOrderStatus: update.status })
            if (update.status === DocumentOrderStatus.DERIVED) {
                dispatch(ANALYSIS_UPDATE_TITLE_IN_DERIVED_DATA, {
                    titleNumber: update.titleNumber,
                    matterId: rootState.matter.currentMatter.id,
                })
            }
        }
    },
}
