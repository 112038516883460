<template>
    <div class="corporate-owner-results">
        <ow-data-grid ref="grid"
                      :headers="dataGridHeaders"
                      :is-loading="isLoading"
                      :items="records"
                      :sort-by="sortBy"
                      narrow
                      theme="light"
                      hover
                      item-key="ownerName"
                      @items-selected="onItemsSelected">
            <template #item.ownerName="{ item }">
                <td class="find-nearby-results-corporate-owners__title"
                    :class="{ highlight: shouldHighlightItem(item)}"
                    @mouseover="$emit('mouse-over-item', item)">
                    {{ item.ownerName }}
                    <a v-if="hasCompanyNumbers"
                       :href="getCompaniesHouseLink(item)"
                       target="_blank">
                        {{ item.companyRegistrationNumbers.join(', ') }}
                    </a>
                </td>
            </template>
            <template #item.titlesCount="{ item }">
                <td class="find-nearby-results-corporate-owners__title"
                    :class="{ highlight: shouldHighlightItem(item)}"
                    @mouseover="$emit('mouse-over-item', item)">
                    {{ item.titlesCount }}
                </td>
            </template>
        </ow-data-grid>
    </div>
</template>

<script lang="ts">
    import {
        PropType,
        ref,
    } from 'vue'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import { INearbyCorporateOwner } from '@/components/title-panel/v2/find-nearby/implementations/corporate-owners/nearby-corporate-owner.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { getCompaniesHouseLinkFromCompanyRegistrationNumber } from '@/utils/link-utils'

    export default {
        name: 'MatterTitleResults',

        components: {
            OwDataGrid,
        },

        props: {
            records: {
                type: Array as PropType<INearbyCorporateOwner[]>,
                required: false,
                default: () => [],
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
            highlightResults: {
                type: Array as PropType<INearbyCorporateOwner[]>,
                required: false,
            },
        },

        emits: ['mouse-over-item', 'selected-owners-updated'],

        data() {
            return {
                dataGridHeaders: [
                    { title: 'Owner', key: 'ownerName', width: '350px' },
                    { title: 'Titles', key: 'titlesCount', width: '65px' },
                ],
                selectedItems: [],
                sortBy: ref([{key: 'ownerName', order: 'asc'}]),
            }
        },

        watch: {
            records() {
                this.clearSelection()
            },
        },

        methods: {
            shouldHighlightItem(item: INearbyCorporateOwner): boolean {
                return this.highlightResults?.map(x => x.ownerName).includes(item.ownerName)
            },

            getCompaniesHouseLink(item) {
                return getCompaniesHouseLinkFromCompanyRegistrationNumber(item.companyRegistrationNumbers[0])
            },

            hasCompanyNumbers(item) {
                return !isNullOrEmpty(item.companyRegistrationNumbers)
            },

            onItemsSelected(items: Array<any>) {
                this.selectedItems = this.records.filter(obj => items.includes(obj.ownerName))
                this.$emit('selected-owners-updated', this.selectedItems)
            },

            clearSelection() {
                this.$refs.grid.clearSelection()
            },
        },
    }
</script>

<style scoped lang="scss">
    @import './corporate-owners-results.scss';
</style>
