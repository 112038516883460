import {createStore} from 'vuex'

// Modules
import AuthOidcModule from '@/store/modules/auth-oidc'
import CheckoutModule from '@/store/modules/checkout'
import ComplexityModule from '@/store/modules/complexity'
import ConfigModule from '@/store/modules/config'
import CoreModule from '@/store/modules/core'
import DiagnosticsModule from '@/store/modules/diagnostics'
import DocumentOrderingModule from '@/store/modules/document-ordering'
import DocumentsModule from '@/store/modules/documents/index'
import GlobalSearchModule from '@/store/modules/global-search'
import LinkShareClientModule from '@/store/modules/link-share-client'
import LoggingModule from '@/store/modules/logging'
import MapModule from '@/store/modules/map'
import MatterModule from '@/store/modules/matter'
import MatterDrawModule from '@/store/modules/matter-drawing'
import MatterHubModule from '@/store/modules/matter-hub'
import NotificationsModule from '@/store/modules/notifications'
import NPSModule from '@/store/modules/nps'
import organisationHubModule from '@/store/modules/organisation-hub'
import OrganisationModule from '@/store/modules/organisation'
import OverlaysModule from '@/store/modules/overlays'
import PlanningModule from '@/store/modules/planning'
import ReportsModule from '@/store/modules/reports'
import ReviewAssistantModule from '@/store/modules/review-assistant'
import RouteModule from '@/store/modules/route'
import SearchModule from '@/store/modules/search'
import SearchesModule from '@/store/modules/searches'
import SiteVisitModule from '@/store/modules/site-visit'
import SketchesModule from '@/store/modules/sketches'
import TitleAnalysisModule from '@/store/modules/title-analysis'
import TitleModule from '@/store/modules/titles'
import UserModule from '@/store/modules/user'
import WalkthroughModule from '@/store/modules/walkthrough'

// STATE
const state = {}

// MUTATIONS
const mutations = ({})

// ACTIONS
const actions = ({})

// GETTERS
const getters = ({})

// MODULES
const modules = {
    authOidc: AuthOidcModule,
    checkout: CheckoutModule,
    complexity: ComplexityModule,
    config: ConfigModule,
    core: CoreModule,
    diagnostics: DiagnosticsModule,
    documentOrdering: DocumentOrderingModule,
    documents: DocumentsModule,
    globalSearch: GlobalSearchModule,
    linkShareClient: LinkShareClientModule,
    log: LoggingModule,
    map: MapModule,
    matter: MatterModule,
    matterDraw: MatterDrawModule, // Used for 'draw on map' search
    matterHub: MatterHubModule,
    nps: NPSModule,
    notifications: NotificationsModule,
    organisation: OrganisationModule,
    organisationHub: organisationHubModule,
    overlays: OverlaysModule,
    planning: PlanningModule,
    reports: ReportsModule,
    reviewAssistant: ReviewAssistantModule,
    route: RouteModule,
    search: SearchModule,
    searches: SearchesModule,
    siteVisit: SiteVisitModule,
    sketches: SketchesModule,
    title: TitleModule,
    titleAnalysis: TitleAnalysisModule,
    user: UserModule,
    walkthrough: WalkthroughModule,
}

const store = createStore({
    state,
    mutations,
    actions,
    getters,
    // @ts-ignore
    modules,
})

export default store
