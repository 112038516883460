<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 40 34"
                  desc="OW Logo black and white">
        <path style="stroke:none;fill-rule:evenodd;fill:#ccc;fill-opacity:1"
              d="M4.445 7.79v5.19l8.887-5.19V2.597ZM26.668 10.383l-4.445 2.597V7.79l4.445-2.599ZM13.332 18.172l-4.441 2.594v-5.192l4.441-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#000;fill-opacity:1"
              d="m0 10.383 4.445 2.597V7.79L0 5.19ZM13.332 18.172l4.445 2.594v-5.192l-4.445-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="M8.89 0 0 5.191 4.445 7.79l8.887-5.191ZM17.777 0l-4.445 2.598 8.89 5.191 4.446-2.598Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="m0 10.383 8.89 5.191 4.442-2.594-8.887-5.19Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#000;fill-opacity:1"
              d="m0 15.574 8.89 5.192v-5.192L0 10.383ZM13.332 2.598v5.191l8.89 5.191V7.79Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="m22.223 7.79-8.891 5.19 4.445 2.594 8.891-5.191Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#ccc;fill-opacity:1"
              d="M17.777 15.574v5.192l8.891-5.192v-5.191ZM22.223 23.363l-4.446 2.598v-5.195l4.446-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="m17.777 20.766-4.445-2.594 4.445-2.598 4.446 2.598Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#ccc;fill-opacity:1"
              d="m31.11 23.363-4.442 2.598v-5.195l4.441-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#000;fill-opacity:1"
              d="m22.223 23.363 4.445 2.598v-5.195l-4.445-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="m26.668 20.766-4.445-2.594 4.445-2.598 4.441 2.598Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#000;fill-opacity:1"
              d="m31.11 23.363 4.445 2.598v-5.195l-4.446-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="m31.11 18.172 4.445 2.594L40 18.172l-4.445-2.598Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#ccc;fill-opacity:1"
              d="M22.223 28.555v5.191l4.445-2.594v-5.191Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#000;fill-opacity:1"
              d="m13.332 28.555 8.89 5.191v-5.191l-4.445-2.594v-5.195l-4.445-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="m22.223 28.555-4.446-2.594 4.446-2.598 4.445 2.598Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#ccc;fill-opacity:1"
              d="M35.555 20.766v5.195l-4.446 2.594v5.191L40 28.555V18.172Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#000;fill-opacity:1"
              d="m26.668 31.152 4.441 2.594v-5.191l-4.441-2.594Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#999;fill-opacity:1"
              d="m31.11 28.555-4.442-2.594 4.441-2.598 4.446 2.598Zm0 0" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconOwLogoBw',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '23px',
            },
            height: {
                type: String,
                required: false,
                default: '20px',
            },
        },
    }
</script>
