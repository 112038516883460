<template>
    <v-card class="information-card">
        <v-card-title class="information-card__title content__subheading">
            Get Help
        </v-card-title>
        <v-card-text class="information-card__text">
            <p class="body-copy">
                Our help center has video tutorials and answers to FAQs.
                If your question hasn't been answered yet or you've spotted
                a bug, please reach out via our live chat (messenger button, bottom-right).
            </p>
        </v-card-text>
        <ow-card-actions justify="start"
                         class="information-card__actions"
                         primary-button-data-test="btn-open-help-centre"
                         primary-button-data-track="ACCOUNT - Open help centre"
                         primary-button-text="Open help centre"
                         @primary-button-click="onPrimaryClick" />
    </v-card>
</template>

<script>
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    export default {
        name: 'HelpCenter',
        components: {
            OwCardActions,
        },
        methods: {
            onPrimaryClick() {
                window.open('https://intercom.help/orbital-witness/en', '_blank')
            },
        },
    }
</script>
