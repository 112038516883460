import { Coordinate } from 'ol/coordinate'

import httpClient from '@/api/http-client'
import { checkFlag } from '@/feature-flags'
import { TITLE_INFORMATION_V2_FF } from '@/feature-flags/keys'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { ITitleInformation } from '@/interfaces/title-information.interface'
import { isNullOrWhitespace } from '@/utils/string-utils'

export default class TitlesApi {
    static controller: AbortController = null

    static END_POINT = 'title'
    static END_POINT_V2 = 'v2/title'

    /**
     * Get the data for a specific title using the title number
     */
    static getByTitleNumber(titleNumber: string, matterId: string, abortController?: AbortController): Promise<ITitleInformation> {
        const useV2Endpoint = checkFlag(TITLE_INFORMATION_V2_FF, false)

        let uri = useV2Endpoint
            ? `${ this.END_POINT_V2 }`
            : `${ this.END_POINT }`

        uri = isNullOrWhitespace(matterId)
            ? `${ uri }/${ titleNumber }`
            : `/${ uri }/${ matterId }/${ titleNumber }`

        return httpClient
            .get(uri, {
                signal: abortController?.signal,
            })
            .then(response => response?.data)
    }

    // NOTE: (DOBO) Temporary solution until querying multiple titles in a single request is supported by the Titles API
    /**
     * Get the data for multiple titles using their title numbers
     */
    static getByTitleNumbers(titleNumbers: string[], matterId: string, abortController?: AbortController): Promise<ITitleInformation[]> {
        if (!abortController) {
            if (this.controller) {
                this.controller.abort()
            }

            this.controller = new AbortController()
            abortController = this.controller
        }

        const titleInformationPromises = titleNumbers.map(titleNumber => this.getByTitleNumber(titleNumber, matterId, abortController))
        return Promise
            .all(titleInformationPromises)
            .then(response => response.includes(undefined) ? [] : response)
            .finally(() => {
                this.controller = null
            })
    }

    static async matchByScottishInspireId(inspireIds: string[], abortController?: AbortController): Promise<IHttpClientResponse<string[]>>{
        const uri = `/scottish-data/match-inspire-ids`
        const requestBody = inspireIds.filter(id => !isNullOrWhitespace(id))
        return httpClient
            .post(uri, requestBody, {
                signal: abortController?.signal,
            })
    }

    static async matchByCoordinate(coordinate: Coordinate, abortController?: AbortController): Promise<IHttpClientResponse<string[]>>{
        const uri = `/scottish-data/match-coordinates`
        const requestBody = {
            easting: coordinate[0],
            northing: coordinate[1],
        }
        return httpClient
            .post(uri, requestBody, {
                signal: abortController?.signal,
            })
    }

    /**
     * Aborts all ongoing requests
     */
    static abortAllRequests() {
        if (this.controller) {
            this.controller.abort()
        }
    }
}