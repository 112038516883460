<template>
    <title-panel :matter-id="matterId"
                 :refresh-tam-on-register-order="true"
                 :selected-title-number="titleNumber"
                 :show-add-to-matter-action="false"
                 :show-title-visibility-action="false"
                 class="asset-monitoring__title-details-panel--v2"
                 is-pinned="true"
                 @clear-titles="clearSelectedTitle" />
</template>

<script lang="ts" setup>
    import {
        computed,
        watch,
    } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from 'vuex'

    import TitlePanel from '@/components/title-panel/v2/title-panel.vue'
    import {
        TITLE_CLEAR,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const matterId = computed<number>(() => store.state.matter?.currentMatter?.id)

    const titleNumber = computed<string>(() => {
        const titleParam: string = Array.isArray(route.params.titleNumber) ? route.params.titleNumber[0] : route.params.titleNumber
        return typeof titleParam === 'string' ? titleParam : ''
    })
    watch(() => titleNumber.value, async (newTitleNumber: string) => {
        if (!isNullOrWhitespace(newTitleNumber)) {
            await store.dispatch(TITLE_LOOKUP_TITLE, newTitleNumber)
        }
    }, { immediate: true })

    const clearSelectedTitle = async () => {
        await store.dispatch(TITLE_CLEAR)
        router.back()
    }
</script>

<style lang="scss" scoped>
@import './title-details';
</style>
