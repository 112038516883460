import {
    ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE,
    INITIALISE_ORGANISATION_HUB_LISTENERS,
    ORGANISATION_HUB_ACTION_ORDER_MESSAGE,
    ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE_UPDATE,
    ORGANISATION_HUB_MUTATE_ERROR,
    ORGANISATION_HUB_MUTATE_HUB,
    ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED,
    ORGANISATION_HUB_MUTATE_MESSAGE,
    SUBSCRIBE_TO_ORGANISATION_HUB,
    UNSUBSCRIBE_FROM_ORGANISATION_HUB,
} from '@/store/modules/organisation-hub/types'
import { getTitleNumberFromKeyValue } from '@/utils/document-ordering-utils'
import { IDocumentOrder } from '@/interfaces/store/document-ordering/document-order.interface'
import { IOrganisationHubMessage } from '@/interfaces/store/organisation-hub/organisation-hub-message.interface'
import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
import { OrganisationHubEvents } from '@/consts/organisation-hub'
import { UPDATE_DOCUMENT_ORDER_STATUS } from '@/store/modules/document-ordering/types'

export default {
    async [SUBSCRIBE_TO_ORGANISATION_HUB]({ commit, dispatch }, {
        hub,
        organisationId,
    }) {
        commit(ORGANISATION_HUB_MUTATE_HUB, hub)
        try {
            await hub.subscribeToOrganisation(organisationId)
            commit(ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED, true)
            dispatch(INITIALISE_ORGANISATION_HUB_LISTENERS)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'ORGANISATION HUB: subscribed',
                metadata: {
                    organisationId,
                },
            }, { root: true })
        } catch (error) {
            console.error(error)
            commit(ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED, false)
            commit(ORGANISATION_HUB_MUTATE_ERROR, error)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'ORGANISATION HUB: error subscribing',
                metadata: {
                    organisationId,
                    error,
                },
            }, { root: true })
        }
    },
    async [UNSUBSCRIBE_FROM_ORGANISATION_HUB]({ commit, dispatch }, {
        hub,
        organisationId,
    }) {
        commit(ORGANISATION_HUB_MUTATE_HUB, hub)
        try {
            await hub.unsubscribeFromOrganisation(organisationId)
            commit(ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED, false)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'ORGANISATION HUB: unsubscribed',
                metadata: {
                    organisationId,
                },
            }, { root: true })
        } catch (error) {
            console.error(error)
            commit(ORGANISATION_HUB_MUTATE_IS_SUBSCRIBED, false)
            commit(ORGANISATION_HUB_MUTATE_ERROR, error)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'ORGANISATION HUB: error unsubscribing',
                metadata: {
                    organisationId,
                    error,
                },
            }, { root: true })
        }
    },
    async [INITIALISE_ORGANISATION_HUB_LISTENERS]({ dispatch, state }) {
        await state.hub.on(OrganisationHubEvents.DocumentOrderStatusUpdated, (organisationId: Number, documentInfo: IDocumentOrder) => {
            dispatch(ORGANISATION_HUB_ACTION_ORDER_MESSAGE, {
                organisationId,
                message: documentInfo,
                method: OrganisationHubEvents.DocumentOrderStatusUpdated,
                timestamp: Date.now(),
            })
            dispatch(`documentOrdering/${ UPDATE_DOCUMENT_ORDER_STATUS }`, documentInfo, { root: true }) // TODO: retire this in favor of trackedTitles and getters
        })
    },

    /**
     * After a message is received from the hub, we need to perform various actions, which is handled here.
     */
    [ORGANISATION_HUB_ACTION_ORDER_MESSAGE]: ({ commit, dispatch, state }, message: IOrganisationHubMessage) => {
        commit(ORGANISATION_HUB_MUTATE_MESSAGE, message) // TODO: retire this in favor of trackedTitles and getters
        message.message.titleNumber = getTitleNumberFromKeyValue(message.message?.keyValue)
        if (state.trackedTitles.find(t => t.titleNumber === message.message.titleNumber)) {
            // Update the tracked title
            commit(ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE_UPDATE, message)

            // Let any modules that care know that a relevant title has received an order update.
            dispatch(ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE, message, { root: true })
        }
    },
}
