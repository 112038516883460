<template>
    <ow-modal v-model="show"
              :title="$store.state.user.popup.title"
              content-class="app-popup__content"
              :icon="$store.state.user.popup.icon"
              :data-test="$store.state.user.popup.testName"
              :persistent="$store.state.user.popup.persistent">
        <div v-if="show"
             class="d-flex align-center">
            <div v-dompurify-html="$store.state.user.popup.contentHTML"
                 class="body-regular" />
        </div>
        <template #actions>
            <ow-card-actions v-if="$store.state.user.popup.showButtons"
                             no-padding
                             primary-button-data-test="popup-button-ok"
                             secondary-button-data-test="popup-button-cancel"
                             :primary-button="settings.okText"
                             :has-secondary-button="settings.showCancelButton"
                             :secondary-button-text="settings.cancelText"
                             :primary-button-text="settings.okText"
                             @primary-button-click="onOKClick"
                             @secondary-button-click="onCancelClick" />
        </template>
    </ow-modal>
</template>
<script>
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import * as types from '../store/mutation-types'
    import OwCardActions from './core/ow-card-actions.vue'
    import OwModal from './core/ow-modal.vue'

    export default {
        name: 'AppPopup',
        components: { OwCardActions, OwModal },
        computed: {
            ...mapState({
                settings: (state) => state.user.popup,
            }),

            show: {
                get: function() {
                    return this.settings.show
                },
                set: function(val) {
                    if (val == false) {
                        this.hidePopup()
                    }
                },
            },
        },

        methods: {
            ...mapActions({
                hidePopup: types.USER_HIDE_POPUP,
            }),

            onCancelClick() {
                if (this.settings.cancelCallback !== null) {
                    this.settings.cancelCallback()
                }
                this.hidePopup()
            },

            onOKClick() {
                if (this.settings.okCallback !== null) {
                    this.settings.okCallback()
                }
                this.hidePopup()
            },

        },
    }
</script>
<style lang="scss">
    @import '../css/_variables.scss';
    .app-popup {
        &__content {
            width: 550px;
            max-width: 550px;
        }
    }
</style>
