/**
 * Formats a number value into a GBP currency including the pound sign.
 * @type {Intl.NumberFormat}
 * @return {string}
 * @example
 *      import { gbpCurrencyFormatter } from '@/utils/number-utils'
 *      ...
 *      gbpCurrencyFormatter.format(1000)
 *      // Will return £1,000.00
 */
export const gbpCurrencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
})

/**
 * Formats a number value
 * @param value - the number to format
 * @param locale - the locale to use for formatting
 */
export const formatNumber = (value: number, locale = 'en-GB') => {
    return new Intl.NumberFormat(locale).format(value)
}
