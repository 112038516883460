<template>
    <div class="d-flex">
        <div :class="{'ownership-card-field__warning': props.warning}">
            <span class="ownership-card-field__title mb-1">{{ props.title.toUpperCase() }}</span>
            <ow-colored-label v-if="tag"
                              :theme="props.tagTheme"
                              data-test="title-summary-companies-house-status">
                {{ componentDescription }}
            </ow-colored-label>
            <a v-else-if="link"
               :href="props.href"
               target="_blank">
                <span class="ownership-card-field__subtitle">
                    {{ componentDescription }}
                    <span v-if="props.warning"
                          class="ownership-card-field__warning-text label-caps-small">
                        {{ props.warningText.toUpperCase() }}
                    </span>
                </span>
            </a>
            <div v-else
                 class="ownership-card-field__subtitle">
                <OwClipboard v-if="props.clipboardEnabled"
                             :text="componentDescription">
                    <span class="body-copy">{{ componentDescription }}</span>
                </OwClipboard>
                <span v-else
                      class="body-copy">{{ componentDescription }}</span>
                <span v-if="props.warning"
                      class="ownership-card-field__warning-text label-caps-small">
                    {{ props.warningText.toUpperCase() }}
                </span>
            </div>
        </div>
        <div v-if="props.matchIconExists"
             class="ownership-card-field__icon">
            <div v-if="props.matches">
                <v-icon color="success"
                        data-test="title-summary-companies-house-match-icon"
                        size="large">
                    $success-circle
                </v-icon>
                <ow-tooltip v-if="matchIconTooltip !== ''"
                            activator="parent"
                            :position="'top'">
                    <span>{{ matchIconTooltip }}</span>
                </ow-tooltip>
            </div>
            <div v-else>
                <v-icon color="warning"
                        data-test="title-summary-companies-house-mismatch-icon"
                        size="large">
                    $warning
                </v-icon>
                <ow-tooltip v-if="matchIconTooltip !== ''"
                            activator="parent"
                            :position="'top'">
                    <span>{{ matchIconTooltip }}</span>
                </ow-tooltip>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from "vue"

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwColoredLabel from "@/components/core/ow-colored-label.vue"
    import OwTooltip from "@/components/core/ow-tooltip.vue"

    const props = withDefaults(defineProps<{
        description: string
        title: string // eslint-disable-line vue/no-unused-properties
        matchIconExists?: boolean, // eslint-disable-line vue/no-unused-properties
        matches?: boolean // eslint-disable-line vue/no-unused-properties
        matchIconTooltip?: string,
        tag?: boolean,
        tagTheme?: string, // eslint-disable-line vue/no-unused-properties
        href?: string, // eslint-disable-line vue/no-unused-properties
        link?: boolean,
        allCaps?: boolean,
        clipboardEnabled?: boolean, // eslint-disable-line vue/no-unused-properties
        warning?: boolean, // eslint-disable-line vue/no-unused-properties
        warningText?: string, // eslint-disable-line vue/no-unused-properties
    }>(), {
        description: undefined,
        title: undefined,
        matchIconExists: false,
        matches: false,
        matchIconTooltip: '',
        tag: false,
        tagTheme: '',
        link: false,
        href: '',
        allCaps: false,
        clipboardEnabled: true,
        warning: false,
        warningText: '',
    })
    const componentDescription = computed(() => props.allCaps ? props.description.toUpperCase() : props.description)
</script>

<style lang="scss">
@import './ownership-card-field';
</style>
