<template>
    <matter-side-panel-add-titles-option import-method="spreadsheet"
                                         :current-selected="modelValue"
                                         :title="$t('matter.addTitlesPanel.uploadSpreadsheet.heading')"
                                         data-test="matter-add-titles-spreadsheet"
                                         @select="$emit('update:modelValue', 'spreadsheet')">
        <span v-t="'matter.addTitlesPanel.uploadSpreadsheet.prompt'"
              class="caption-regular" />

        <ow-button full-width
                   is-secondary
                   small
                   data-test="upload-spreadsheet-upload-file-button"
                   @click="setUploadModalVisible(true)">
            {{ $t('matter.addTitlesPanel.uploadSpreadsheet.uploadButton') }}
        </ow-button>
        <ow-file-uploader :accepted-file-types="[UploadFileTypes.CSV,
                                                 UploadFileTypes.XLS,
                                                 UploadFileTypes.XLSX]"
                          :accepted-file-extensions="[UploadFileExtensions.CSV,
                                                      UploadFileExtensions.XLS,
                                                      UploadFileExtensions.XLSX]"
                          :min-file-size="1"
                          :data-test-attribute="'sketches-list-import-modal'"
                          i18n-path-for-accepted-file-types-error="matter.addTitlesPanel.uploadSpreadsheet.import.acceptedFileTypeError"
                          i18n-path-for-drop-zone-text="matter.addTitlesPanel.uploadSpreadsheet.import.dropZoneText"
                          i18n-path-for-text="matter.addTitlesPanel.uploadSpreadsheet.import.instructions"
                          i18n-path-for-title="matter.addTitlesPanel.uploadSpreadsheet.import.uploadFiles"
                          @files="handleFileUpload" />

        <span v-if="filename"
              class="caption-regular">
            Uploaded File: {{ filename }}
        </span>

        <add-to-group v-model="selectedGroup" />

        <ow-button v-t="'matter.addTitlesPanel.uploadSpreadsheet.addTitles'"
                   is-primary
                   small
                   :disabled="!uploadData"
                   data-test="upload-spreadsheet-add-titles-button"
                   @click="importTitles" />
    </matter-side-panel-add-titles-option>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
    } from 'vue'
    import { useStore } from "vuex"

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwFileUploader from '@/components/core/ow-file-uploader.vue'
    import { IOwFileUploaderEvent } from '@/components/core/types/ow-file-uploader-event.interface'
    import {
        UploadFileExtensions,
        UploadFileTypes,
    } from '@/enums/upload-file-types.enum'
    import {SET_UPLOAD_DOCUMENTS_DIALOG_STATE} from "@/store/modules/documents/documents-types"
    import {
        MATTER_IMPORT_FROM_SPREADSHEET,
        MATTER_REQUEST_SHOW_TITLE_LIST,
    } from '@/store/modules/matter/types'
    import { TitlePanels } from '@/store/modules/title-panels'
    import { TITLE_MUTATE_ACTIVE_TITLE_PANEL } from '@/store/modules/titles/types'

    import AddToGroup, { GroupItem } from './add-to-group.vue'
    import MatterSidePanelAddTitlesOption from './matter-side-panel-add-titles-option.vue'

    defineProps<{modelValue: string}>()
    defineEmits<{
        (e: 'update:modelValue', importMethod: string)
    }>()

    const selectedGroup = ref<GroupItem | undefined>()
    const text = ref<string>()

    const uploadData = ref<FormData>(null)
    const filename = ref<string>(null)

    const store = useStore()

    const titlesFound = computed(() => {
        const items = text.value?.split(/\W+/)
        const filteredItems = items.filter((item) => {
            return item.trim().length > 0
        })

        return filteredItems
    })

    const handleFileUpload = async (filesToUpload: IOwFileUploaderEvent) => {
        const files = filesToUpload.formData.getAll('files') as Array<File>
        if (files.length !== 1) return

        const data = new FormData()

        data.append('MatterGroupId', selectedGroup.value?.id?.toString() ?? null)
        data.append('file', files[0])
        data.append('matterId', store.state.matter.currentMatter.id)

        setUploadModalVisible(false)
        uploadData.value = data
        filename.value = files[0].name
    }

    const importTitles = async () => {
        await store.dispatch(MATTER_IMPORT_FROM_SPREADSHEET, { matterId: store.state.matter.currentMatter.id, request: uploadData.value })
        await store.dispatch(MATTER_REQUEST_SHOW_TITLE_LIST)

        store.commit(TITLE_MUTATE_ACTIVE_TITLE_PANEL, TitlePanels.LIST)

        uploadData.value = null
        filename.value = null
    }

    const setUploadModalVisible = (value) => store.commit(SET_UPLOAD_DOCUMENTS_DIALOG_STATE, value)

    defineExpose({
        handleFileUpload,
        titlesFound,
    })
</script>
