<template>
    <section class="uploaded-documents-table">
        <ow-data-grid :allow-selection="false"
                      :headers="headers"
                      :is-loading="isLoading"
                      :initial-items-per-page="Number.MAX_SAFE_INTEGER"
                      :items="documents"
                      :sort-by="sortBy"
                      calculate-widths
                      hover
                      item-key="id">
            <template #[`item`]="{ item }">
                <tr>
                    <td class="body-regular uploaded-documents-table__item"
                        :class="{ 'selected': item.id === selectedDocumentId }"
                        data-test="uploaded-document-table-selection"
                        data-track="OVERLAY PLANS - Select document"
                        @click="onRowSelected(item)">
                        <ow-radio :id="item.id.toString()"
                                  v-model="selected"
                                  name="rowSelector" />
                    </td>
                    <td :title="item.fileName"
                        class="body-regular uploaded-documents-table__item uploaded-documents-table__filename"
                        :class="{ 'selected': item.id === selectedDocumentId }"
                        data-test="uploaded-documents-table-document-name"
                        @click="onRowSelected(item)">
                        <v-icon>$uploaded-file</v-icon>
                        <span class="uploaded-documents-table__filename--text">{{ item.fileName }}</span>
                    </td>
                    <td class="caption-regular uploaded-documents-table__item"
                        :class="{ 'selected': item.id === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ documentTypeText(item) }}
                    </td>
                    <td class="caption-regular uploaded-documents-table__item"
                        :class="{ 'selected': item.id === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ formatDateShort(item.updatedOn) }}
                    </td>
                    <td class="caption-regular uploaded-documents-table__item"
                        :class="{ 'selected': item.id === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ formatDateShort(item.updatedOn) }}
                    </td>
                    <td :title="item.userName"
                        class="body-regular uploaded-documents-table__item uploaded-documents-table__item--user"
                        :class="{ 'selected': item.id === selectedDocumentId }"
                        @click="onRowSelected(item)">
                        {{ item.userName }}
                    </td>
                </tr>
            </template>
        </ow-data-grid>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        WritableComputedRef,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwRadio from '@/components/core/ow-radio.vue'
    import useDates from '@/composables/use-dates'
    import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    const { formatDateShort } = useDates()
    const { t } = useI18n()
    const selectedDocumentId = computed(() => props.selectedDocument?.id ?? 0)

    interface Props {
        documents: Array<IUploadedDocument>,
        isLoading?: boolean,
        selectedDocument?: IUploadedDocument,
    }

    const props = withDefaults(defineProps<Props>(), {
        isLoading: true,
        clearAll: 0,
        selectedDocument: null,
    })

    const emit = defineEmits<{
        (e: 'change', item: IUploadedDocument)
    }>()

    const selected: WritableComputedRef<string> = computed({
        get(): string {
            return props?.selectedDocument?.id.toString() ?? ''
        },
        set(docId: string): void {
            const documentId = Number(docId)
            const selectedItem = props.documents.find((item: IUploadedDocument) => item.id === documentId)
            emit('change', selectedItem)
        },
    })

    const headers = [
        {
            align: 'left',
            key: 'selection',
            class: 'label-caps-small',
            width: '40',
        },
        {
            title: t('documents.upload.table.headings.document').toUpperCase(),
            align: 'left',
            key: 'fileName',
        },
        {
            title: t('table.label.type').toUpperCase(),
            align: 'left',
            key: 'documentType',
            width: '120',
        },
        {
            title: t('table.label.title').toUpperCase(),
            align: 'left',
            key: 'titleNumber',
            width: '120',
        },
        {
            title: t('documents.upload.table.headings.date').toUpperCase(),
            align: 'left',
            key: 'updatedOn',
            width: '160',
        },
        {
            title: t('documents.upload.table.headings.user').toUpperCase(),
            align: 'left',
            key: 'userName',
            width: '200',
        },
    ]

    const sortBy = ref([{key: 'fileName', order: 'asc'}])

    const documentTypeText = (item: IUploadedDocument): string => {
        switch (item.type) {
            case UploadedDocumentType.TitleRegister:
                return t('documents.upload.documentType.titleRegister')
            case UploadedDocumentType.TitlePlan:
                return t('documents.upload.documentType.titlePlan')
            case UploadedDocumentType.OfficialCopy:
                return t('documents.upload.documentType.officialCopy')
            case UploadedDocumentType.Other:
                return t('documents.upload.documentType.other')
            case UploadedDocumentType.CautionTitle:
                return t('documents.upload.documentType.cautionTitle')
            case UploadedDocumentType.ScotLISTitleSheet:
                return this.$t('documents.upload.documentType.scotLISTitleSheet')
        }
    }

    const onRowSelected = (item: IUploadedDocument) => {
        selected.value = item.id.toString()
    }
</script>

<style lang="scss" scoped>
@import './uploaded-documents-table';
</style>
