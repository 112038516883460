/**
 * Constants for known coordinate systems used by the web map and/or related spatial data.
 * A coordinate system relates locations on a map to locations on the earth. This includes projection
 * information which describes how the curvature of the earth is reflected on the flat surface of a map.
 * These codes are typically used to transform data between coordinate systems to one used on a map,
 * or expected by some API.
 *
 * OW typically uses the EPSG:27700 (British National Grid) coordinate system to visualise data when possible,
 * as most of the data in this area (UK land and property) tends to be captured using it or rendered with that in mind. Therefore less data
 * is re-projected, and more likely to be presented as close to how it was intended as possible.
 * @enum {String}
 */
export enum CoordinateSystemCode {
    /**
     * Also known as British National Grid, or OSGB36.
     * A lot of UK data is provided in this format, e.g. HMLR data.
     * See {@link https://epsg.io/27700} for definition.
     */
    EPSG27700 = 'EPSG:27700',

    /**
     * Also known as World Geodetic System 1984, or WGS84.
     * Coordinates are Longitudes and Latitudes, most common coordinate system for non-UK based data.
     * See {@link https://epsg.io/4326} for definition.
     */
    EPSG4326 = 'EPSG:4326',

    /**
     * Also known as Spherical Mercator, or Google Maps Global Mercator.
     * See {@link https://epsg.io/900913} for definition.
     * Unofficial 'Google' maps (hence the numbers) coordinate system, used for most online web maps that don't rely on national coordiante systems.
     * Note this is the same as {@link CoordinateSystemCode.EPSG3857}.
     */
    EPSG900913 = 'EPSG:900913',

    /**
     * Also known as Spherical Mercator, or Google Maps Global Mercator.
     * See {@link https://epsg.io/3857} for definition.
     * Note this is the same as {@link CoordinateSystemCode.900913}.
     */
    EPSG3857 = 'EPSG:3857'
}
