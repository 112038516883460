<template>
    <ow-button-ds class="top-nav-button"
                  :is-primary="primary"
                  :is-secondary="!primary"
                  :disabled="disabled"
                  is-borderless
                  small
                  full-height
                  full-width
                  :data-track="dataTrackId"
                  :data-test="dataTestId"
                  @click="emit('click')">
        <template #iconPrefix>
            <v-icon v-if="icon">
                {{ icon }}
            </v-icon>
        </template>
        <span v-if="showTitle">
            {{ title }}
        </span>
    </ow-button-ds>
</template>

<script setup lang="ts">
    import OwButtonDs from '@/components/core/ow-button-ds.vue'

    defineProps<{
        primary?: boolean
        icon?: string
        title?: string
        disabled?: boolean
        showTitle?: boolean
        dataTrackId?: string
        dataTestId?: string
    }>()

    const emit = defineEmits<{
        (e: 'click'): void
    }>()
</script>
