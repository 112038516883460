<template>
    <div v-if="isLoading && ((smAndDown || isCollapsed) || !hasSections || (hasSections && !selected))"
         class="side-nav-tab-button side-nav-tab-button--skeleton d-flex pa-0"
         :class="{
             'side-nav-tab-button--collapsed': isCollapsed,
             'side-nav-tab-button--fullscreen': !isCollapsed,
         }">
        <ow-loading-skeleton border-radius
                             :class="{
                                 'mx-1': (smAndDown || isCollapsed),
                                 'mx-6': !(smAndDown || isCollapsed),
                             }"
                             class="w-100" />
    </div>
    <template v-else>
        <ow-lock v-model="showLock"
                 :class="{
                     'side-nav-tab-button--selected': selected
                 }"
                 :feature-id="featureId"
                 class="side-nav-tab-button"
                 hide-tooltip
                 tabindex="0"
                 :is-primary="selected || isHovered"
                 @mouseenter="isHovered = true"
                 @mouseleave="isHovered = false"
                 @click="onClick">
            <template #label>
                <div class="caption-highlight d-flex align-center text-no-wrap">
                    {{ title }}
                </div>
            </template>
        </ow-lock>
        <div v-if="!showLock && ((smAndDown || isCollapsed) || !hasSections || (hasSections && !selected))"
             :class="{
                 'side-nav-tab-button--selected': selected,
                 'side-nav-tab-button--collapsed': isCollapsed,
                 'side-nav-tab-button--fullscreen': !isCollapsed,
             }"
             class="side-nav-tab-button d-flex align-center"
             tabindex="0"
             @click="onClick">
            <div class="caption-highlight d-flex align-center text-no-wrap">
                {{ title }}
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
    import {
        computed,
        inject,
        onBeforeMount,
        ref,
    } from "vue"
    import {
        useDisplay,
        useLayout,
    } from "vuetify"

    import OwLoadingSkeleton from "@/components/core/ow-loading-skeleton.vue"
    import OwLock from "@/components/core/ow-lock.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import { inject as userProvider } from "@/composables/use-user"

    const props = defineProps<{
        title?: string
        hasSections: boolean
        selected?: boolean
        featureId?: FeatureId
        isLoading?: boolean
        isCollapsed?: boolean
    }>()

    defineOptions({
        inheritAttrs: false,
    })

    const emit = defineEmits<{
        (e: 'click'): void,
    }>()

    const showLock = defineModel()
    const { smAndDown } = useDisplay()
    const { hasAccessToFeature } = userProvider()

    const isHovered = ref(false)

    onBeforeMount(async () => {
        if (props.featureId) {
            const hasAccess = await hasAccessToFeature(props.featureId)
            showLock.value = !hasAccess
        }
    })

    const onClick = () => {
        emit('click')
    }
</script>

<style scoped lang="scss">
@import './side-nav-tab-button';
</style>
