import { IBasicTitleInformation } from '@/interfaces/basic-title-information.interface'

export class BasicTitleInformation implements IBasicTitleInformation {
    readonly titleNumber: string
    readonly tenure: string
    readonly address: string

    constructor(
        titleNumber: string,
        tenure: string,
        address: string) {
        this.titleNumber = titleNumber
        this.tenure = tenure
        this.address = address
    }

    /**
     * The appropriate theme to use with the ow-label component when displaying the tenure.
     **/
    get tenureLabelTheme() {
        return `tenure-${ this.simpleTenure.toLowerCase() }`
    }

    /**
     * Tenure can be more tha Freehold/Leasehold/Other, but this returns one of those 3 options.
     */
    get simpleTenure() {
        const match = this.tenure.match(/(freehold|leasehold)/i)
        return match ? match[0] : 'Other'
    }
}
