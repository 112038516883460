import { CheckoutItemBase } from '@/models/checkout/checkout-item-base.model'
import { CheckoutItemType } from '@/enums/checkout-item-type'
import { ICheckoutItemReportOrder } from '@/interfaces/checkout/checkout-item-report-order.interface'

/**
 * Implementation for a document order checkout item.
 * @extends CheckoutItemBase
 * @implements ICheckoutItemReportOrder
 */
export class CheckoutItemReportOrder extends CheckoutItemBase implements ICheckoutItemReportOrder {
    readonly selectedTitleNumbers: string[]
    readonly template: any

    constructor(
        displayName: string,
        cost: number,
        selectedTitleNumbers: string[],
        template: any) {
        super(displayName, cost, CheckoutItemType.Report)

        this.selectedTitleNumbers = selectedTitleNumbers
        this.template = template
    }

    /**
     * Just for debugging purposes
     */
    toJSON(): ICheckoutItemReportOrder {
        return {
            ...super.toJSON(),
            selectedTitleNumbers: this.selectedTitleNumbers,
            template: this.template,
        }
    }
}
