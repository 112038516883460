<template>
    <li class="sketches-list-item"
        :class="{'alt-background': altBackground, visible: sketch.visible, active: active}"
        @mouseleave="mouseOver = false"
        @mouseover="mouseOver = true">
        <div class="sketches-list-item__check-area"
             @click="toggleSelection">
            <ow-checkbox :id="`sketch-item-${sketch.id}`"
                         v-model="selectedInternal" />
        </div>
        <component :is="sketchIcon"
                   v-if="sketchIcon !== ''"
                   class="sketches-list-item__icon" />

        <div class="sketches-list-item__text"
             :class="{ 'visible': sketch.visible }"
             @click="gotoSketch">
            <div :style="nameStyle"
                 class="sketch-name"
                 :title="sketch.name">
                {{ sketch.name }}
            </div>
            <div v-if="sketch.comment"
                 class="sketches-list-item__description">
                {{ sketch.comment }}
            </div>
        </div>
        <div data-test="sketches-list-item-area"
             class="sketches-list-item__area"
             @click="gotoSketch">
            <div v-if="sketchMeasurement">
                {{ sketchMeasurement }}
            </div>
            <div v-if="sketchUnit"
                 class="sketches-list-item__unit">
                {{ sketchUnit }}
            </div>
            <div v-if="!sketchMeasurement && !sketchUnit"
                 v-t="'sketches.empty'" />
        </div>
        <ow-button icon
                   class="sketches-list-item__visibility"
                   :class="{ 'visible': sketch.visible }"
                   color=""
                   data-test="sketches-list-item-visibility"
                   variant="text"
                   @click="onVisibilityButtonClick">
            <v-icon class="visibility-off-icon">
                {{ '$visibility-off' }}
            </v-icon>
            <v-icon class="visibility-on-icon">
                {{ '$visibility' }}
            </v-icon>
        </ow-button>
    </li>
</template>

<script lang="ts">
    import {
        computed,
        PropType,
        ref,
        shallowRef,
        watch,
    } from 'vue'

    import OwButton from '@/components/core/ow-button.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import { useSketchIcon } from '@/composables/use-sketch-icon'
    import { SketchesUnit } from '@/enums/sketches-enums'
    import { ISketch } from '@/store/modules/sketches/types/sketch'

    export default {
        components: {
            OwButton,
            OwCheckbox,
        },
        props: {
            sketch: {
                default: () => null,
                type: Object as PropType<ISketch>,
                required: true,
            },
            selected: {
                default: () => false,
                type: Boolean as PropType<boolean>,
                required: true,
            },
            altBackground: {
                default: () => false,
                type: Boolean as PropType<boolean>,
            },
            active: {
                default: () => false,
                type: Boolean as PropType<boolean>,
            },
            unitOfMeasurement: {
                default: () => SketchesUnit.Metric,
                type: Number as PropType<SketchesUnit>,
            },
        },
        emits: [
            'select',
            'deselect',
            'toggle-visibility',
            'goto-sketch',
            'highlight',
        ],
        setup(props, { emit }) {
            const sketchIcon = shallowRef('')
            const mouseOver = shallowRef(false)
            useSketchIcon(props.sketch.sketchType).then((icon) => {
                sketchIcon.value = icon
            })

            const toggleSelection = () => {
                if (props.selected) {
                    emit('deselect', props.sketch)
                } else {
                    emit('select', props.sketch)
                }
            }

            // Label style
            const nameStyle = computed(() => {
                return {
                    background: props.sketch.visible ? props.sketch.strokeColour : 'transparent',
                }
            })

            const updateMeasurement = () => {
                const measurement = (props.sketch as ISketch).getMeasurement(props.unitOfMeasurement)
                if (!measurement) {
                    sketchUnit.value = ''
                    sketchMeasurement.value = ''
                    return
                }
                if (typeof measurement === 'object') {
                    sketchUnit.value = measurement.unit
                    sketchMeasurement.value = measurement.value
                } else {
                    sketchUnit.value = ''
                    sketchMeasurement.value = measurement
                }
            }

            // watch props unitOfMeasurement
            const sketchUnit = ref('')
            const sketchMeasurement = ref('')
            watch(() => props.unitOfMeasurement, () => {
                updateMeasurement()
            }, { immediate: true })

            watch(() => props.sketch, () => {
                updateMeasurement()
            }, { immediate: true, deep: true })

            const onVisibilityButtonClick = () => {
                emit('toggle-visibility', props.sketch)
            }

            const gotoSketch = () => {
                emit('goto-sketch', props.sketch)
            }

            watch((mouseOver), (value) => {
                if (value) {
                    emit('highlight', props.sketch)
                } else {
                    emit('highlight', null)
                }
            })

            return {
                sketchIcon,
                mouseOver,
                toggleSelection,
                nameStyle,
                sketchMeasurement,
                sketchUnit,
                onVisibilityButtonClick,
                gotoSketch,
            }
        },
        data() {
            return {
                selectedInternal: this.selected,
            }
        },
    }
</script>

<style lang="scss">
    @import 'sketches-list-item.scss';
</style>
