import {
    MAP_GET_NPS_IS_AVAILABLE_AT_CURRENT_ZOOM,
    MAP_GET_NPS_IS_IN_SOME_WAY_VISIBLE,
    MAP_GET_VIEW,
    MAP_NPS_IS_LOADING,
} from './types'

export default {
    [MAP_GET_VIEW](state) {
        if (state.map != null) {
            return state.map.getView()
        }
        return null
    },

    [MAP_NPS_IS_LOADING](state) {
        if (state.nps.layer == null) {
            return false
        } else {
            return (state.nps.layer.getVisible() && state.nps.loadingTiles !== 0)
        }
    },

    [MAP_GET_NPS_IS_AVAILABLE_AT_CURRENT_ZOOM](state) {
        if (state.nps.layer != null) {
            return state.map.getView().getResolution() <= state.nps.maxResolution
        }
        return false
    },

    [MAP_GET_NPS_IS_IN_SOME_WAY_VISIBLE](state) {
        return (state.nps.showNPSFreeholds || state.nps.showNPSLeaseholds || state.nps.showNPSTitleNumbers)
    },
}
