<template>
    <div class="matter-search-card-list">
        <slot />
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss">
    @import './matter-searches-card-list.scss';
</style>
