<template>
    <section class="config-section snapshot-layout-options">
        <h3 v-t="'snapshots.layout.title'"
            class="body-highlight" />
        <ow-select :selected-value="selectedLayoutInternal"
                   data-track="Snapshot - layout options"
                   data-test="snapshot-layout-options"
                   :items="layoutOptions"
                   is-dense
                   hide-details
                   is-outlined
                   item-text="label"
                   item-value="id"
                   @change="onLayoutIdChange" />
        <ow-select v-if="showOrientationOptions"
                   :selected-value="selectedOrientationInternal"
                   data-track="Snapshot - orientation options"
                   data-test="snapshot-orientation-options"
                   :items="orientationOptions"
                   :label="$t('snapshots.orientation.title')"
                   is-dense
                   hide-details
                   is-outlined
                   item-text="label"
                   item-value="value"
                   @change="onOrientationChange" />
    </section>
</template>
<script setup lang="ts">
    import {
        computed,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwSelect from '@/components/core/ow-select.vue'
    import {
        PageLayoutId,
        PageLayouts,
        SnapshotOrientation,
    } from '@/components/snapshots/common/snapshot-enums-consts'
    import {
        ISnapshotPageLayout,
        ISnapshotService,
    } from '@/components/snapshots/common/snapshot-interfaces'

    type PageOrientationType = {
        value: SnapshotOrientation;
        label: string
    }
    interface Props {
        service: ISnapshotService
    }

    const props = defineProps<Props>()
    const { t } = useI18n()

    // Layout.
    const layoutOptions = Object.keys(PageLayouts)
        .map(layout => ({
            ...PageLayouts[layout],
            label: t(PageLayouts[layout].i18nLabel),
        }))
    const onLayoutIdChange = (layout: ISnapshotPageLayout) => {
        props.service.snapshotModel.renderData.layoutId = layout.id
    }
    const selectedLayoutInternal = computed(() => {
        return layoutOptions.find(opt => opt.id === props.service.snapshotModel.renderData.layoutId)
    })

    // Orientation.
    const orientationOptions:Array<PageOrientationType> = [
        {
            label: t('snapshots.orientation.portrait'),
            value: SnapshotOrientation.Portrait,
        },
        {
            label: t('snapshots.orientation.landscape'),
            value: SnapshotOrientation.Landscape,
        },
    ]
    const selectedOrientationInternal = computed(() =>
        orientationOptions.find(x => x.value === props.service.snapshotModel.renderData.orientation))
    const showOrientationOptions = computed(() =>
        props.service.snapshotModel.renderData.layoutId !== PageLayoutId.Custom)
    const onOrientationChange = (orientation: PageOrientationType) => {
        props.service.snapshotModel.renderData.orientation = orientation.value
    }

    defineExpose({
        selectedLayoutInternal,
        selectedOrientationInternal,
        layoutOptions,
        orientationOptions,
        showOrientationOptions,
        onLayoutIdChange,
        onOrientationChange,
    })

</script>

<style scoped lang="scss">
    @import "snapshot-layout-options";
</style>
