import {
    SEARCHES_GET_ORDERS,
    SEARCHES_GET_CATEGORIES,
    SEARCHES_GET_PRODUCTS,
    SEARCHES_GET_HAZARDS,
    SEARCHES_GET_ACTIVE_HAZARDS,
    SEARCHES_GET_PRODUCT_TOTALS,
    SEARCHES_GET_PRODUCT_QUOTE,
    SEARCHES_GET_PROPERTY_TYPES,
    SEARCHES_GET_QUOTE_CALCULATING,
    SEARCHES_GET_FILTER,
    SEARCHES_GET_SUBMITTING_ORDER,
    SEARCHES_GET_ORDER_DETAILS,
    SEARCHES_GET_DRAFT_ORDER_DETAILS,
    SEARCHES_GET_SEARCH_PROVIDERS,
    SEARCHES_GET_BASKET,
    SEARCHES_GET_PROPERTY_TYPE,
    SEARCHES_GET_ADDRESS,
    SEARCHES_GET_FILTERED_TITLE_NUMBERS,
    SEARCHES_GET_AREA_SQM,
    SEARCHES_GET_GEOJSON,
    SEARCHES_GET_SELECTED_TITLE_NUMBERS,
    SEARCHES_GET_SKETCH_OPTIONS,
    SEARCHES_GET_SEARCHES_LAYER,
    SEARCHES_GET_SEARCH_PROVIDER,
    SEARCHES_GET_MAP_KEY,
    SEARCHES_GET_PRODUCT_PREFERENCES,
    SEARCHES_GET_BASKET_CALCULATING,
    SEARCHES_GET_BASKET_COUNT,
    SEARCHES_GET_BASKET_EMPTY,
    SEARCHES_GET_BASKET_PRODUCT_IDS,
    SEARCHES_GET_BASKET_ITEMS,
    SEARCHES_GET_RECOMMENDED_CATEGORIES,
    SearchHazardDescription,
    SEARCHES_GET_UNMAPPED_HAZARDS,
    SEARCHES_GET_AVAILABLE_CATEGORIES,
    SearchCategoryWithProducts,
    Product,
    SEARCHES_GET_SELECTED_CATEGORY,
    SEARCHES_GET_SELECTED_BASKET_ITEM,
    SEARCHES_GET_AVAILABLE_PRODUCTS,
    SEARCHES_GET_OTHER_PRODUCTS,
    SEARCHES_GET_ORDER_REQUEST,
    SEARCHES_GET_TMG_CON29_CONFIG,
    SEARCHES_GET_TMG_CON29_CONFIG_SHOW,
    SEARCHES_GET_TMG_LLC1_CONFIG,
    SEARCHES_GET_TMG_LLC1_CONFIG_SHOW,
    SEARCHES_GET_CON29_DWC_CONFIG,
    SEARCHES_GET_CON29_DWC_CONFIG_SHOW,
    SEARCHES_GET_HIGHWAYS_RA_CONFIG,
    SEARCHES_GET_HIGHWAYS_RA_CONFIG_SHOW,
    SEARCHES_GET_ARG_SS_COMBO_CONFIG,
    SEARCHES_GET_ARG_SS_COMBO_CONFIG_SHOW,
    SEARCHES_GET_LOADING,
    SEARCHES_GET_BASKET_DIRTY,
    SEARCHES_GET_SAVING_DRAFT_ORDER,
    SEARCHES_GET_DRAFT_ORDER_ID,
    SEARCHES_GET_STRUCTURED_ADDRESS,
    SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING,
    SEARCHES_GET_NOTIFICATION_USERS,
    SEARCHES_GET_SELECTED_NOTIFICATION_USERS,
    SEARCHES_GET_GEOJSON_HAS_MULTIPLE_GEOMETRIES,
    SEARCHES_GET_ERROR,
    SEARCHES_GET_HAS_ERRORS,
    SEARCHES_GET_EPSG4326_GEOJSON,
    SEARCHES_GET_GEO_LOCATION,
    SEARCHES_GET_INIT_CONTEXT_REQUEST,
    SEARCHES_GET_SEARCHES_FETCH_CATEGORIES_REQUEST,
    SEARCHES_GET_ORGANISATION_SETTINGS,
    SEARCHES_GET_PROJECT_NAME,
    SEARCHES_GET_PROJECT_NAME_VALID,
} from '@/store/modules/searches/types'

import { ISearchesState } from '@/store/modules/searches'
import BasketItemModel from '@/components/matter-searches/models/BasketItemModel'
import CategoryItemModel from '@/components/matter-searches/models/CategoryItemModel'
import { dynamicSort,
    dynamicSortMultipleFields } from '@/utils/array-utils'
import {ICreateSearchOrderRequest,
    IGetOrganisationSettingsResponse} from '@/api/searches.api'
import { PropertyType,
    ProjectType,
    Provider,
    OrderState} from '@/enums/searches.enum'

import {CoordinateSystemCode} from "@/enums/coordinate-systems"
import GeoJSON from "ol/format/GeoJSON"
import {Feature} from "ol"
import {Geometry} from "ol/geom"
import Polygon from 'ol/geom/Polygon'
import {FeatureLike} from "ol/Feature"
import { isNullOrWhitespace } from '@/utils/string-utils'
import { getCenter } from 'ol/extent'
import { Coordinate } from 'ol/coordinate'

export default {
    [SEARCHES_GET_STRUCTURED_ADDRESS](state: ISearchesState) {
        return state?.address?.address
    },

    [SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING](state: ISearchesState) {
        const addressDetails = [
            state?.address?.address?.organisationName,
            `${ state?.address?.address?.buildingNumber }${ state?.address?.address?.thoroughfareName ? ' ' + state?.address?.address?.thoroughfareName : '' }`,
            state?.address?.address?.postTown,
            state?.address?.address?.postcode,
        ]
        const filteredAddressDetails = addressDetails.filter(item => !isNullOrWhitespace(item))
        const oneLineAddress = filteredAddressDetails.join(', ')
        return oneLineAddress.trim()
    },

    [SEARCHES_GET_ORDERS](state: ISearchesState) {
        const orders = state.orders
        const result = []
        if (orders?.length) {
            // TODO: this is a temporary fix to remove draft orders if there is a submitted or completed order for the same contextId
            orders.forEach(order => {
                if (order.state === OrderState.Draft && orders.some(o => (o.state !== OrderState.Draft) && o.contextId === order.contextId)) {
                    // do nothing
                } else {
                    result.push(order)
                }
            })
            // sort by date
            return result.sort((a, b) => new Date(b.submittedDate).getTime() - new Date(a.submittedDate).getTime())
        }
        return []
    },

    [SEARCHES_GET_CATEGORIES](state: ISearchesState) {
        return state.categories
    },

    [SEARCHES_GET_PRODUCTS](state: ISearchesState) {
        return state?.products ?? []
    },

    [SEARCHES_GET_HAZARDS](state: ISearchesState) {
        return state?.hazards ?? []
    },

    [SEARCHES_GET_ACTIVE_HAZARDS](state: ISearchesState) {
        return state.hazards?.filter(hazard => hazard.isActive) ?? []
    },

    [SEARCHES_GET_PROPERTY_TYPES](state: ISearchesState) {
        return state.propertyTypes
    },

    [SEARCHES_GET_SEARCH_PROVIDER](state: ISearchesState) {
        return state.filter.searchProviderId
    },

    [SEARCHES_GET_LOADING](state: ISearchesState) {
        return state.loading
    },

    [SEARCHES_GET_QUOTE_CALCULATING](state: ISearchesState) {
        return state.quoteCalculating
    },

    [SEARCHES_GET_SUBMITTING_ORDER](state: ISearchesState) {
        return state.submittingOrder
    },

    [SEARCHES_GET_PRODUCT_TOTALS](state: ISearchesState) {
        return state?.quote?.totals ?? {
            totalNet: 0,
            totalVat: 0,
            totalGross: 0,
        }
    },

    [SEARCHES_GET_PRODUCT_QUOTE](state: ISearchesState) {
        return state?.quote
    },

    [SEARCHES_GET_FILTER](state: ISearchesState) {
        return state?.filter
    },

    [SEARCHES_GET_ORDER_DETAILS](state: ISearchesState) {
        return state?.orderDetails
    },

    [SEARCHES_GET_DRAFT_ORDER_DETAILS](state: ISearchesState) {
        return state?.draftOrderDetails
    },

    [SEARCHES_GET_SEARCH_PROVIDERS](state: ISearchesState) {
        return state?.searchProviders
    },

    [SEARCHES_GET_ADDRESS](state: ISearchesState) {
        return state?.address
    },

    [SEARCHES_GET_FILTERED_TITLE_NUMBERS](state: ISearchesState) {
        return state?.filteredTitleNumbers
    },

    [SEARCHES_GET_AREA_SQM](state: ISearchesState) {
        return state?.areaSqMetres
    },

    [SEARCHES_GET_GEOJSON](state: ISearchesState) {
        return state?.geoJSON
    },

    [SEARCHES_GET_EPSG4326_GEOJSON](state: ISearchesState) {
        const converted = new GeoJSON().writeFeatures(new GeoJSON().readFeatures(state.geoJSON, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        }) as Feature<Geometry>[])
        return converted
    },

    [SEARCHES_GET_GEO_LOCATION](_state: ISearchesState, getters) {
        const bngGeojson = getters[SEARCHES_GET_EPSG4326_GEOJSON]
        const feature: FeatureLike = new GeoJSON().readFeatures(bngGeojson)[0]
        const geometry: Polygon = feature.getGeometry() as Polygon
        const centrePoint: Coordinate = getCenter(geometry.getExtent())
        const closestPointInside: number[] = geometry.getClosestPoint(centrePoint)
        const intersectsGeometry = geometry.intersectsCoordinate(centrePoint)
        const creationPoint = intersectsGeometry ? centrePoint : closestPointInside

        return {
            easting: creationPoint[0].toString(),
            northing: creationPoint[1].toString(),
        }
    },


    [SEARCHES_GET_GEOJSON_HAS_MULTIPLE_GEOMETRIES](state: ISearchesState) {
        // check if the geoJSON is disconnected
        if (!state?.geoJSON) {
            return null
        }

        // get the number of features
        const geoJSON = JSON.parse(state.geoJSON)
        const features = geoJSON.features
        if (features.length === 0) {
            return null
        }

        // get the first feature and its geometry
        const firstFeature = features?.[0]
        const geometry = firstFeature.geometry
        if (!geometry) {
            return null
        }

        // get the coordinates
        const coords = geometry.coordinates
        if (coords.length > 1) {
            const firstCoords = coords[0]
            const lastCoords = coords[coords.length - 1]
            if (firstCoords[0][0] !== lastCoords[0][0] || firstCoords[0][1] !== lastCoords[0][1]) {
                coords.push(firstCoords)
            }

            // return true if multiple geometries
            return coords.length > 1
        }

        // return false if single geometry
        return false
    },

    [SEARCHES_GET_SELECTED_TITLE_NUMBERS](state: ISearchesState) {
        return state?.selectedTitleNumbers
    },

    [SEARCHES_GET_SKETCH_OPTIONS](state: ISearchesState) {
        return state?.sketch
    },

    [SEARCHES_GET_SEARCHES_LAYER](state: ISearchesState) {
        return state?.searchesLayer
    },

    [SEARCHES_GET_MAP_KEY](state: ISearchesState) {
        return state?.mapKey
    },

    [SEARCHES_GET_PRODUCT_PREFERENCES](state: ISearchesState) {
        return state?.productPreferences
    },

    // Basket
    [SEARCHES_GET_BASKET_CALCULATING](state: ISearchesState) {
        return state.basket.some((basketItem: BasketItemModel) => basketItem.calculating)
    },

    [SEARCHES_GET_BASKET](state: ISearchesState) {
        return state.basket
    },

    [SEARCHES_GET_OTHER_PRODUCTS](state: ISearchesState) {
        return state.otherProducts
    },

    [SEARCHES_GET_BASKET_ITEMS](state: ISearchesState) {
        return state.basket.map((basketItem: BasketItemModel) => basketItem)
    },

    [SEARCHES_GET_BASKET_COUNT](state: ISearchesState) {
        return state.basket.count
    },

    [SEARCHES_GET_BASKET_EMPTY](state: ISearchesState) {
        return state.basket.count === 0
    },

    [SEARCHES_GET_BASKET_PRODUCT_IDS](state: ISearchesState) {
        return state.basket.map((item: BasketItemModel) => item.productId)
    },

    // Recommended
    [SEARCHES_GET_RECOMMENDED_CATEGORIES](state: ISearchesState) {
        return state.recommended
    },

    // Hazards
    [SEARCHES_GET_UNMAPPED_HAZARDS](state: ISearchesState) {
        const basketHasCategory = (categoryId: string) => state.basket.allRecords.some((basketItem: BasketItemModel) => basketItem.categoryIds.includes(categoryId))
        const hazards: SearchHazardDescription[] = []
        state.categories.allRecords.forEach((category: CategoryItemModel) => {
            if (!category.isActive) {
                return
            }
            // has it been added to the basket?
            const hasCategory = basketHasCategory(category.categoryId)
            if (hasCategory) {
                return
            }

            hazards.push({
                name: category.shortName,
                code: category.categoryId,
            })
        })
        return hazards.sort(dynamicSort('shortName'))
    },

    [SEARCHES_GET_SELECTED_CATEGORY](state: ISearchesState) {
        return state.selectedCategory
    },

    [SEARCHES_GET_SELECTED_BASKET_ITEM](state: ISearchesState) {
        return state.selectedBasketItem
    },

    // categories
    [SEARCHES_GET_AVAILABLE_CATEGORIES](state: ISearchesState) {
        const basketHasProduct = (productId: string) => {
            return state.basket.some((basketItem: BasketItemModel) => basketItem.productId === productId)
        }
        const result: SearchCategoryWithProducts[] = []
        state.categories.forEach((category: CategoryItemModel) => {
            if (category.isActive) {
                return
            }
            const products = category.products.filter((product: Product) => {
                // is it already in the basket?
                const inBasket = basketHasProduct(product.productId)
                if (inBasket) {
                    return false
                }
                return true
            })
            if (products.length === 0) {
                return
            }
            if (!result.some((item) => item.categoryId === category.categoryId)) {
                result.push({
                    categoryId: category.categoryId,
                    isActive: category.isActive,
                    description: category.description,
                    products,
                    name: category.name,
                    shortName: category.shortName,
                })
            }
        })

        return result.sort(dynamicSort('shortName'))
    },

    [SEARCHES_GET_AVAILABLE_PRODUCTS](state: ISearchesState) {
        const basketHasProduct = (productId: string) => {
            return state.basket.some((basketItem: BasketItemModel) => basketItem.productId === productId)
        }
        const products: Product[] = []
        state.categories.forEach((category: CategoryItemModel) => {
            if (category.isActive) {
                return
            }
            category.products.forEach((product: Product) => {
                // is it already in the basket?
                const inBasket = basketHasProduct(product.productId)
                if (inBasket) {
                    return
                }
                if (!products.some((item) => item.productId === product.productId)) {
                    products.push(product)
                }
            })
        })
        return products.filter(product => product.description.toLowerCase().includes(state.filter.searchText.toLowerCase())).sort(dynamicSort('description'))
    },

    [SEARCHES_GET_ORDER_REQUEST](state: ISearchesState, getters , rootState) {
        const title = state.filteredTitleNumbers.find((titleNumber: any) => titleNumber.titleNumber === state.selectedTitleNumbers[0])
        const createOrderRequest: ICreateSearchOrderRequest = {
            contextId: state.contextId,
            titleNumber: title?.titleNumber,
            matterId: rootState.matter.currentMatter.id,
            providerId: state.filter?.searchProviderId ?? Provider.TmGroup,
            uprn: state?.address?.uprn?.toString() ?? '',
            siteType: PropertyType[getters[SEARCHES_GET_PROPERTY_TYPE]].toString(),
            numberOfParcels: 0,
            address: {
                organisationName: state?.address?.address?.organisationName,
                postcode: state?.address?.address?.postcode,
                postTown: state?.address?.address?.postTown,
                buildingNumber: state?.address?.address?.buildingNumber,
                thoroughfareName: state?.address?.address?.thoroughfareName,
            },
            products: state.basket.map((basketModel: BasketItemModel) => ({
                productId: basketModel.productId,
                productName: basketModel.description,
                supplierName: basketModel.supplierDescription,
                numberOfParcels: 1,
                totalGrossFee: basketModel.totalGrossFee,
                totalVAT: basketModel.totalVat,
                estimatedCompletionDate: basketModel.expectedDate,
                productOptions: [basketModel.productOptionsToJson()],
            })),
            geoJson: state.geoJSON,
            associatedTitles: state.selectedTitleNumbers,
            projectType: ProjectType[0].toString(),
            templateId: state.filter?.productPreferenceId,
        }
        return createOrderRequest
    },

    [SEARCHES_GET_INIT_CONTEXT_REQUEST](state: ISearchesState, getters, rootState) {
        const request = {
            providerId: state.filter?.searchProviderId,
            locationInformation: {
                address: {
                    organisationName: state?.address?.address?.organisationName,
                    postcode: state?.address?.address?.postcode,
                    postTown: state?.address?.address?.postTown,
                    buildingNumber: state?.address?.address?.buildingNumber,
                    thoroughfareName: state?.address?.address?.thoroughfareName,
                },
                uprn: getters[SEARCHES_GET_STRUCTURED_ADDRESS]?.uprn,
                titleNumber: state.selectedTitleNumbers?.[0],
                geojson: getters[SEARCHES_GET_EPSG4326_GEOJSON],
                geoLoc: getters[SEARCHES_GET_GEO_LOCATION],
            },
            matterDetails: {
                matterName: rootState.matter.currentMatter.name,
                clientCode: rootState.matter.currentMatter.clientCode,
                matterCode: rootState.matter.currentMatter.code,
            },
            projectType: ProjectType.Other,      //NOTE: this is not yet exposed on the FE
            sectorType: PropertyType[getters[SEARCHES_GET_PROPERTY_TYPE]],
            draftSearchOrderId: state.draftOrderId,
            projectReference: state.projectName,
        }
        return request
    },

    [SEARCHES_GET_SEARCHES_FETCH_CATEGORIES_REQUEST](state: ISearchesState, getters) {
        const title = state.filteredTitleNumbers.find((title) => title.titleNumber === state.selectedTitleNumbers[0])

        return {
            titleNumber: title?.titleNumber,
            propertySectorType: getters[SEARCHES_GET_PROPERTY_TYPE],
            providerId: state.filter.searchProviderId,
            address: getters[SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING],
            uprn: getters[SEARCHES_GET_STRUCTURED_ADDRESS]?.uprn,
            contextId: state.contextId,
        }
    },

    [SEARCHES_GET_TMG_CON29_CONFIG](state: ISearchesState) {
        return state.productConfig.tmgCon29.config
    },

    [SEARCHES_GET_TMG_CON29_CONFIG_SHOW](state: ISearchesState) {
        return state.productConfig.tmgCon29.show
    },

    [SEARCHES_GET_TMG_LLC1_CONFIG](state: ISearchesState) {
        return state.productConfig.tmgLLC1.config
    },

    [SEARCHES_GET_TMG_LLC1_CONFIG_SHOW](state: ISearchesState) {
        return state.productConfig.tmgLLC1.show
    },

    [SEARCHES_GET_CON29_DWC_CONFIG](state: ISearchesState) {
        return state.productConfig.con29Dwc.config
    },

    [SEARCHES_GET_CON29_DWC_CONFIG_SHOW](state: ISearchesState) {
        return state.productConfig.con29Dwc.show
    },

    [SEARCHES_GET_HIGHWAYS_RA_CONFIG](state: ISearchesState) {
        return state.productConfig.highwaysRa.config
    },

    [SEARCHES_GET_HIGHWAYS_RA_CONFIG_SHOW](state: ISearchesState) {
        return state.productConfig.highwaysRa.show
    },

    [SEARCHES_GET_ARG_SS_COMBO_CONFIG](state: ISearchesState) {
        return state.productConfig.argSSCombo.config
    },

    [SEARCHES_GET_ARG_SS_COMBO_CONFIG_SHOW](state: ISearchesState) {
        return state.productConfig.argSSCombo.show
    },

    [SEARCHES_GET_BASKET_DIRTY](state: ISearchesState) {
        return state.basketDirty
    },

    [SEARCHES_GET_SAVING_DRAFT_ORDER](state: ISearchesState) {
        return state.savingDraftOrder
    },

    [SEARCHES_GET_DRAFT_ORDER_ID](state: ISearchesState) {
        return state.draftOrderId
    },

    [SEARCHES_GET_PROPERTY_TYPE](state: ISearchesState) {
        const productPreference = state.filter.productPreferenceId
        return state.productPreferences?.find(p => p.id === productPreference)?.isResidential
            ? PropertyType.Residential
            : PropertyType.Commercial
    },

    [SEARCHES_GET_NOTIFICATION_USERS](state: ISearchesState) {
        return state.notificationUsers.sort(dynamicSortMultipleFields(['-isSubscribedToProviderEmailNotification', 'name']))
    },

    [SEARCHES_GET_SELECTED_NOTIFICATION_USERS](state: ISearchesState) {
        return state.notificationUsers.filter((user) => user.isSubscribedToProviderEmailNotification)
    },

    [SEARCHES_GET_ERROR](state: ISearchesState) {
        return state.error
    },

    [SEARCHES_GET_HAS_ERRORS](state: ISearchesState) {
        return state.error.size > 0
    },

    [SEARCHES_GET_ORGANISATION_SETTINGS](state: ISearchesState): IGetOrganisationSettingsResponse {
        return state.organisationSettings
    },

    [SEARCHES_GET_PROJECT_NAME](state: ISearchesState): string {
        return state.projectName
    },

    [SEARCHES_GET_PROJECT_NAME_VALID](state: ISearchesState): boolean {
        return state.projectNameValid
    },
}
