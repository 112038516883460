<template>
    <section class="bulk-options-panel"
             data-test="bulk-options-panel"
             :style="componentStyle">
        <header class="bulk-options-panel__panel-header">
            <h3 v-t="'matter.selection.selectionOptions'"
                class="content__subheading" />
            <v-icon class="bulk-options-panel__panel-header--close-icon"
                    data-test="bulk-options-panel-close-btn"
                    @click="$emit('dismiss')">
                $close
            </v-icon>
        </header>
        <div class="bulk-options-panel__divider" />

        <!-- Count of selected titles -->
        <div class="bulk-options-panel__item bulk-options-panel__item--info caption-highlight"
             data-test="bulk-options-panel-selection-text">
            {{ $t('matter.selection.titlesSelected', titleSelection.length) }}
        </div>

        <!-- Actions on titles -->
        <!-- Add to group -->
        <div class="bulk-options-panel__item bulk-options-panel__item--with-menu"
             :class="{'bulk-options-panel__item--active': showAddToGroupMenuValue}"
             data-test="bulk-options-panel-group-menu-btn"
             @mouseenter="showAddToGroupMenu"
             @mouseleave="hideAddToGroupMenu">
            <label v-t="'matter.selection.addToGroup'" />
            <ow-icon icon="ow-icon-chevron-right" />
        </div>

        <!-- Add to group -->
        <section v-if="showAddToGroupMenuValue"
                 data-test="bulk-options-panel-group-menu"
                 class="bulk-options-panel__sub-menu bulk-options-panel__sub-menu--add-to-group"
                 @mouseenter="showAddToGroupMenu"
                 @mouseleave="hideAddToGroupMenu">
            <div class="bulk-options-panel__item bulk-options-panel__item"
                 data-track="MAT-TITLE-LIST - Bulk actions add title to a group"
                 data-test="bulk-options-panel-new-group-btn"
                 @click="$emit('add-to-new-group')">
                <label v-t="'matter.selection.addToNewGroup'" />
            </div>
            <div v-if="availableGroups.length > 0"
                 class="bulk-options-panel-group-menu__other">
                <div class="bulk-options-panel__divider mx-4 mt-0 mr-2" />
                <div v-for="group in availableGroups"
                     class="bulk-options-panel__item"
                     data-track="MAT-TITLE-LIST - Bulk actions add title to a group"
                     data-test="bulk-options-panel-group-btn"
                     @click="$emit('add-to-group', group)">
                    <label>{{ group.name }}</label>
                </div>
            </div>
        </section>

        <!-- Other actions -->
        <div class="bulk-options-panel__item"
             data-test="bulk-options-panel-change-style-btn"
             data-track="MAT-TITLE-LIST - Bulk action title styling button"
             @click="$emit('change-style')">
            <label v-t="'matter.selection.changeStyleAndName'" />
        </div>
        <div class="bulk-options-panel__item"
             data-track="MAT-TITLE-LIST - Bulk action purchase official copies"
             data-test="bulk-options-panel-purchase-official-copies-btn"
             @click="$emit('purchase-official-copies')">
            <label v-t="'matter.selection.purchaseOrRefreshOfficialCopies'" />
        </div>

        <div v-if="supportsTitleSelectionDataProvider"
             class="w-100">
            <!-- Hide / show boundaries -->
            <div class="bulk-options-panel__item"
                 :class="{disabled: !canShowSelectedBoundaries}"
                 data-track="MAT-TITLE-LIST - Bulk action show title boundaries"
                 data-test="bulk-options-panel-show-boundaries-btn"
                 @click="$emit('show-boundaries')">
                <label v-t="'matter.selection.showSelectedBoundaries'" />
            </div>
            <div :class="{disabled: !canHideSelectedBoundaries}"
                 data-test="bulk-options-panel-hide-boundaries-btn"
                 data-track="MAT-TITLE-LIST - Bulk action hide title boundaries"
                 class="bulk-options-panel__item"
                 @click="$emit('hide-boundaries')">
                <label v-t="'matter.selection.hideSelectedBoundaries'" />
            </div>
            <!-- Export as -->
            <div v-if="showExportOptions"
                 :class="{'bulk-options-panel__item--active': showExportAsMenuValue}"
                 class="bulk-options-panel__item bulk-options-panel__item--with-menu"
                 data-test="bulk-options-panel-export-menu-btn"
                 @mouseenter="showExportAsMenu"
                 @mouseleave="hideExportAsMenu">
                <label v-t="'matter.selection.exportAs'" />
                <ow-icon icon="ow-icon-chevron-right" />
            </div>
        </div>

        <section v-if="showExportAsMenuValue"
                 class="bulk-options-panel__sub-menu bulk-options-panel__sub-menu--export-as"
                 data-test="bulk-options-panel-export-as-menu"
                 @mouseenter="showExportAsMenu"
                 @mouseleave="hideExportAsMenu">
            <div class="bulk-options-panel-group-menu">
                <div v-for="template in availableExportOptions.filter(t => !t?.hideInSubMenu)"
                     v-if="availableExportOptions?.length"
                     :class="{ disabled: !areAllRegistersOrderedOrUploaded }"
                     :data-track-value="template.name"
                     class="bulk-options-panel__item"
                     data-test="bulk-options-panel-export-as-menu-link"
                     data-track="MAT-TITLE-LIST - Bulk actions export report"
                     @click="$emit('export-as', template)">
                    <v-icon :class="{ disabled: !areAllRegistersOrderedOrUploaded }"
                            class="bulk-options-panel__item--link-icon">
                        $download
                    </v-icon>
                    <label>{{ template.name }}</label>
                </div>
            </div>
            <div class="bulk-options-panel__divider export-as-divider" />
            <div class="bulk-options-panel-group-menu__actions">
                <ow-button data-test="bulk-options-panel-export-as-menu-view-all-templates-btn"
                           data-track="MAT-TITLE-LIST - Bulk actions view all reports"
                           full-width
                           is-secondary
                           small
                           @click="$emit('view-all-templates')">
                    <span v-t="'report.action.viewMoreTemplates'" />
                </ow-button>
            </div>
        </section>

        <div class="bulk-options-panel__item"
             data-test="bulk-options-panel-export-pending-applications-btn"
             data-track="MAT-TITLE-LIST - Bulk action export pending applications"
             @click="$emit('export-pending-applications')">
            <label v-t="'matter.selection.exportPendingApplications'" />
        </div>

        <!-- Other -->
        <ow-lock v-if="canExportShapefile"
                 v-model="shapefileExportLocked"
                 :class="{locked: shapefileExportLocked}"
                 :feature-id="FeatureId.ShapefileExport"
                 :tooltip-position="OwTooltipPosition.Right"
                 class="bulk-options-panel__item"
                 hide-background>
            <template #label>
                <label v-t="'matter.selection.exportTitleBoundaries'" />
            </template>
            <div class="bulk-options-panel__item"
                 data-test="bulk-options-panel-export-title-boundaries"
                 data-track="MAT-TITLE-LIST - Bulk actions export title boundaries"
                 @click="$emit('export-title-boundaries')">
                <label v-t="'matter.selection.exportTitleBoundaries'" />
            </div>
        </ow-lock>

        <div class="bulk-options-panel__divider my-3 mx-4" />

        <!-- Removal actions -->
        <div class="bulk-options-panel__item bulk-options-panel__item--danger"
             data-test="bulk-options-panel-remove-from-group-btn"
             data-track="MAT-TITLE-LIST - Bulk actions remove title from a group"
             :class="{disabled: !canRemoveFromGroup}"
             @click="$emit('remove-from-group')">
            <label v-t="'matter.selection.removeFromGroup'" />
        </div>
        <div class="bulk-options-panel__item bulk-options-panel__item--danger"
             data-test="bulk-options-panel-remove-from-matter-btn"
             data-track="MAT-TITLE-LIST - Bulk actions remove titles from matter"
             @click="$emit('remove-from-matter')">
            <label v-t="'matter.selection.removeTitlesFromMatter'" />
        </div>

        <div class="bulk-options-panel__divider my-3 mx-4" />
        <!-- Send to Copilot -->
        <open-copilot-button data-test="bulk-options-panel-send-to-copilot-btn"
                             data-track="MAT-TITLE-LIST - Send to Orbital Copilot"
                             track-position="Bulk Options Panel"
                             class="w-100"
                             :selected-title-numbers="titleSelection.map(t => t.titleNumber)"
                             show-all-documents
                             show-empty-titles>
            <template #default="{ disabled }">
                <div id="bulk-options-send-to-copilot"
                     class="bulk-options-panel__item d-flex justify-space-between align-center"
                     :class="{'disabled': disabled}">
                    <label v-t="'action.sendToOrbitalCopilot'" />
                    <ow-icon-copilot height="24px"
                                     width="24px" />
                </div>
            </template>
        </open-copilot-button>
    </section>
</template>

<script lang="ts">
    import {
        inject,
        onMounted,
        PropType,
        ref,
    } from 'vue'

    import OpenCopilotButton from '@/components/copilot/open-copilot-button.vue'
    import OwIconCopilot from '@/components/core/icons/ow-icon-copilot.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwIcon from '@/components/core/ow-icon.vue'
    import OwLock from "@/components/core/ow-lock.vue"
    import { FeatureId } from "@/composables/use-licence-controller"
    import { inject as userProvider } from "@/composables/use-user"
    import { DATA_PROVIDER } from '@/consts/data-provider'
    import { OwTooltipPosition } from "@/enums/ow-tooltip-position"
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IExportTemplateOptions } from '@/interfaces/export-template-options.interface'
    import { IMatterGroup } from '@/interfaces/matter-group.interface'
    import { isNullOrEmpty } from '@/utils/array-utils'

    export default {
        name: 'BulkOptionsPanel',
        components: {
            OwLock,
            OwButton,
            OwIcon,
            OpenCopilotButton,
            OwIconCopilot,
        },
        mixins: [
            FeatureFlagsMixin,
        ],
        props: {
            titleSelection: {
                type: Array,
                default: () => [],
            },
            availableGroups: {
                type: Array as PropType<Array<IMatterGroup>>,
                default: () => [],
                required: true,
            },
            anchorElementSelector: {
                type: String,
                required: false,
                default: () => {},
            },
            anchorOffset: {
                type: Array as PropType<Array<number>>,
                required: false,
                default: () => [0, 0, 0, 0],
            },
            availableExportOptions: {
                type: Array as PropType<Array<IExportTemplateOptions>>,
                default: () => [{ name: 'the 1st' }],
                required: true,
            },
        },
        emits: [
            'add-to-new-group',
            'add-to-group',
            'change-style',
            'dismiss',
            'export-as',
            'export-pending-applications',
            'hide-boundaries',
            'purchase-official-copies',
            'remove-from-group',
            'remove-from-matter',
            'show-boundaries',
            'view-all-templates',
            'export-title-boundaries',
            'export-title-analysis-report',
        ],
        setup() {
            const { hasAccessToShapefileExport } = userProvider()
            const shapefileExportLocked = ref()

            onMounted(async () => {
                const hasAccess = await hasAccessToShapefileExport()
                shapefileExportLocked.value = !hasAccess

            })
            return {
                shapefileExportLocked,
            }
        },
        data() {
            return {
                showAddToGroupMenuValue: false,
                showExportAsMenuValue: false,
                showExportMenuTimeout: null,
            }
        },
        computed: {
            OwTooltipPosition() {
                return OwTooltipPosition
            },
            FeatureId() {
                return FeatureId
            },
            componentStyle(): Partial<CSSStyleDeclaration> {
                if (!this.anchorElementSelector) {
                    return null
                }
                const anchorElementRect = document.querySelector(this.anchorElementSelector)?.getBoundingClientRect()
                if (!anchorElementRect) {
                    return null
                }
                const left = anchorElementRect.left + anchorElementRect.width
                let style = {} as any
                if (this.anchorOffset?.length == 4) {
                    style = {
                        marginTop: `${ this.anchorOffset[0] }px`,
                        marginRight: `${ this.anchorOffset[1] }px`,
                        marginBottom: `${ this.anchorOffset[2] }px`,
                        marginLeft: `${ this.anchorOffset[3] }px`,
                    }
                }
                style.left = `${ left }px`
                return style
            },
            canShowSelectedBoundaries(): boolean {
                return this.titleSelection.some(x => {
                    return !x.show
                })
            },
            canHideSelectedBoundaries():boolean {
                return this.titleSelection.some(x => {
                    return x.show
                })
            },
            canRemoveFromGroup():boolean {
                return this.titleSelection.some(x => {
                    return x.matterGroupId !== null
                })
            },
            showExportOptions(): boolean {
                return !isNullOrEmpty(this.availableExportOptions)
            },
            areAllRegistersOrderedOrUploaded(): boolean {
                return this.titleSelection.every(title => title.isRegisterOrdered || title.isRegisterUploaded)
            },
            canExportShapefile(): boolean {
                return this.titleSelection.some(title => title.boundaryAvailable)
            },
            supportsTitleSelectionDataProvider(): boolean {
                return !isNullOrEmpty(this.titleSelection.filter(title => [DATA_PROVIDER.HMLR].includes(title.dataProvider)))
            },
        },

        methods: {
            showAddToGroupMenu() {
                clearTimeout(this.showAddToGroupMenuTimeout)
                this.showAddToGroupMenuValue = true
            },
            hideAddToGroupMenu() {
                this.showAddToGroupMenuTimeout = setTimeout(() => {
                    this.showAddToGroupMenuValue = false
                }, 500)
            },
            hideExportAsMenu() {
                this.showExportMenuTimeout = setTimeout(() => {
                    this.showExportAsMenuValue = false
                }, 500)
            },
            showExportAsMenu() {
                clearTimeout(this.showExportMenuTimeout)
                this.showExportAsMenuValue = true
            },
        },
    }
</script>

<style lang="scss">
    @import './bulk-options-panel';
</style>
