<template>
    <header class="uploaded-documents-summary">
        <div v-if="!isLoading"
             class="body-copy uploaded-documents-summary__label">
            <span class="body-regular uploaded-documents-summary__label--text">
                <span>{{ $t('label.showing') }}</span>
                <!-- Filtered document count-->
                <span v-if="hasFilter"
                      data-test="uploaded-documents--summary-filtered-count">{{ filteredDocumentsCount }} of </span>
                <!-- Total document count -->
                <span data-test="uploaded-documents-summary-total-count">
                    {{ $t('documents.library.documentsCount', totalDocumentsCount) }},
                </span>
                <span data-test="uploaded-documents-summary-successful-count">
                    {{ $t('documents.library.successfullyUploadedDocumentsCount', successfullyUploadedDocumentsCount) }},
                </span>
                <span data-test="uploaded-documents-summary-failed-count">
                    {{ $t('documents.library.failedToUploadDocumentsCount', failedToUploadDocumentsCount) }}
                </span>
            </span>
        </div>
        <div class="uploaded-documents-summary__actions">
            <ow-button v-t="'action.refresh'"
                       is-secondary
                       data-test="uploaded-documents-summary-refresh-uploads-btn"
                       data-track="DOCUMENTS - Refresh uploaded documents"
                       @click="refreshDocuments" />

            <ow-button is-primary
                       :disabled="!hasSelectedDocuments"
                       data-test="upload-documents-summary-see-reporting-options-btn"
                       data-track="DOCUMENTS - Go to reporting options from uploaded tab"
                       @click="seeReportingOptions">
                {{ $t('report.action.seeReportingOptions') }}
                <v-icon>$chevron-right</v-icon>
            </ow-button>
        </div>
    </header>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import {
        uploadDocumentFailureResponses,
        uploadDocumentSuccessResponses,
    } from '@/enums/uploaded-document-status.enum'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'

    const EVENTS = {
        refreshDocuments: 'refresh-documents',
        seeReportingOptions: 'see-reporting-options-clicked',
    }

    export default {
        name: 'UploadedDocumentsSummary',

        components: {
            OwButton,
        },

        props: {
            filteredDocumentsCount: {
                type: Number,
                required: true,
            },
            documents: {
                type: Array as PropType<Array<IUploadedDocument>>,
                required: true,
            },
            selectedDocumentsCount: {
                type: Number,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
        },

        emits: Object.values(EVENTS),

        computed: {
            hasFilter(): boolean {
                return this.filteredDocumentsCount > 0
            },
            hasSelectedDocuments(): boolean {
                return this.selectedDocumentsCount > 0
            },
            totalDocumentsCount(): number {
                return this.documents.length
            },
            successfullyUploadedDocumentsCount(): number {
                return this.documents.filter(document => uploadDocumentSuccessResponses.includes(document.status)).length
            },
            failedToUploadDocumentsCount(): number {
                return this.documents.filter(document => uploadDocumentFailureResponses.includes(document.status)).length
            },
        },

        methods: {
            refreshDocuments(): void {
                this.$emit(EVENTS.refreshDocuments)
            },
            seeReportingOptions() {
                this.$emit(EVENTS.seeReportingOptions)
            },
        },
    }
</script>

<style lang="scss">
    @import './uploaded-documents-summary';
</style>
