<template>
    <div>
        <ow-tooltip :position="OwTooltipPosition.Left"
                    activator="parent">
            <span>{{ label }}</span>
            <template #activator>
                <v-switch v-model="isSwitchOn"
                          class="matter-activity-toggle--switch"
                          color="primary"
                          data-test="matter-activity-toggle"
                          hide-details
                          @click.prevent.stop="onToggleMatterActivityChange" />
            </template>
        </ow-tooltip>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { MatterState } from '@/consts/matter'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { IState } from '@/interfaces/store/state.interface'
    import { CORE_MUTATE_CONFIRMATION_DIALOG } from '@/store/modules/core/types'
    import {
        MATTER_MUTATE_UPDATE_STATE_ERROR,
        MATTER_UPDATE_MATTER_STATE,
    } from '@/store/modules/matter/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
        USER_SHOW_POPUP,
    } from '@/store/mutation-types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'MatterActivityToggle',

        components: {
            OwTooltip,
        },

        props: {
            matter: {
                type: Object,
                required: true,
            },
        },

        emits: ['toggled' ],

        data() {
            return {
                isSwitchOn: false,
                switchToggleConfirmed: false,
            }
        },

        computed: {
            OwTooltipPosition() {
                return OwTooltipPosition
            },
            ...mapState({
                updateMatterStateError: (state: IState) => state.matter.updateMatterStateError,
            }),

            isMatterOpen() {
                return this.matter.state === MatterState.Open
            },

            label() {
                return this.isSwitchOn
                    ? this.$t('matter.prompts.close.title')
                    : this.$t('matter.prompts.reopen.title')
            },
        },

        created() {
            this.isSwitchOn = this.isMatterOpen
        },

        methods: {
            ...mapActions({
                updateMatterState: MATTER_UPDATE_MATTER_STATE,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                showUserPopup: USER_SHOW_POPUP,
            }),

            ...mapMutations({
                setConfirmationDialog: CORE_MUTATE_CONFIRMATION_DIALOG,
                setUpdateMatterStateError: MATTER_MUTATE_UPDATE_STATE_ERROR,
            }),

            async toggleMatterActivity() {
                await this.updateMatterState({
                    matterId: this.matter.id,
                    state: this.isSwitchOn ? MatterState.Open : MatterState.Closed,
                })

                if (isNullOrWhitespace(this.updateMatterStateError)) {
                    this.logHeapEvent({
                        type: 'MAT-DASH - Toggle matter state',
                        metadata: {
                            matterId: this.matter.id,
                            matterState: this.isSwitchOn ? 'Reopening' : 'Closing',
                        },
                    })
                } else {
                    this.showUserPopup({
                        title: this.$t('message.errorOccurred'),
                        contentHTML: this.updateMatterStateError,
                    })
                }

                this.setUpdateMatterStateError(null)
                this.$emit('toggled', this.isSwitchOn)
                this.switchToggleConfirmed = true
            },

            onToggleMatterActivityChange() {
                this.switchToggleConfirmed = false
                this.setConfirmationDialog({
                    showDialog: true,
                    title: `${ this.label }?`,
                    subtitle: this.isSwitchOn ? this.$t('matter.prompts.close.body') : this.$t('matter.prompts.reopen.body'),
                    submit: this.toggleMatterActivity,
                    reset: this.resetSwitch,
                    submitButtonText: this.$t('action.confirm'),
                    successTitle: null,
                })
                this.isSwitchOn = !this.isSwitchOn
            },

            resetSwitch() {
                // TODO: the modal 'submit' calls 'reset' as well as close
                // presumably to reset the state of any forms
                // but it just resets the toggle incorrectly here
                if (!this.switchToggleConfirmed) {
                    this.isSwitchOn = !this.isSwitchOn
                }
            },
        },
    }
</script>

<style lang="scss">
    @import 'matter-activity-toggle';
</style>
