import { useI18n } from 'vue-i18n'
import { Product } from '@/store/modules/searches/types'
import {
    Model,
    TemplateColumnConfig,
} from '@bryntum/grid'
import { useActionColumn } from '@/components/core/ow-grid/columns/use-action-column'
import CategoryItemModel from '../models/CategoryItemModel'
import { CostColumn,
    ExpectedDateColumn,
    HazardsColumn } from '.'

export const useColumns = ({
    on,
}: {
    on: (event: string, column: string, record: CategoryItemModel) => void
}) => {
    const { t } = useI18n()
    const Category = ({
        field: 'name',
        text: t('searches.columns.name') as string,
        flex: 1,
        type: 'template',
        template: ({ record }: { record: Model}) => {
            let productDetail = null
            const categoryItem = record as CategoryItemModel
            if (categoryItem.selectedProductId !== null) {
                if (!categoryItem.products) {
                    return
                }
                const product: Product = categoryItem.products.find(item => item.productId === categoryItem.selectedProductId)
                if (product) {
                    productDetail = `
                    <div class="d-flex flex-row">
                        <span class="product-icon mdi mdi-information-outline mr-2"></span>
                        <div class="product-column__container">
                            <span class="body-highlight product-column" title="${ product.description }">${ product.description }</span>
                            <div class="caption-regular" title="${ product.productSupplierType }">${ product.productSupplierType }</div>
                        <div>
                    </div>                    `
                }
            } else {
                productDetail = `
                    <span class="caption-regular no-product">No product selected</span>
                `
            }

            return `
                <div class='d-flex flex-column product-column__container'>
                    ${ productDetail ? `<div>${ productDetail }</div>` : '' }
                </div>
            `
        },
    }) as Partial<TemplateColumnConfig>

    const ActionColumn = useActionColumn({
        onEvent: on,
        width: 175,
        actions: [
            {
                id: 'swap',
                visible: () => true,
                style: 'link',
                primary: true,
                title(record) {
                    const categoryItem = record as CategoryItemModel
                    return categoryItem.selectedProductId ? t('searches.swapProduct') : t('searches.selectProduct')
                },
            },
            {
                id: 'add',
                visible: () => true,
                disabled: (record: CategoryItemModel) => (record.selectedProductId === undefined || record.selectedProductId === null),
                style: 'icon',
                className: 'add-button mdi mdi-plus-circle',
            }],
    })

    return {
        gridColumns: [
            Category,
            HazardsColumn,
            ExpectedDateColumn,
            CostColumn,
            ActionColumn,
        ],
    }
}
