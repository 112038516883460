<template>
    <div class="search-page ow-page-container">
        <div class="search-page__header">
            <title-search-box />
        </div>
        <div :class="{'is-empty-state': !visibleResults.length}"
             class="search-page__content">
            <div v-if="visibleResults.length"
                 class="content__heading">
                Results ({{ allResults.length }})
            </div>
            <results-list v-if="visibleResults.length"
                          :results="visibleResults"
                          @on-result-click="onResultClick"
                          @add-to-matter="addToMatter" />
            <ow-pagination v-if="visibleResults.length"
                           :result-count="allResults.length"
                           @page-change="setActivePage"
                           @page-size-change="setItemsPerPage" />
            <div v-if="!visibleResults.length"
                 class="search-page__empty-state">
                <h3 class="search-page__title">
                    Hello, what are you searching for today?
                </h3>
                <div class="search-page__empty-state-inner">
                    <v-icon class="search-page__icon-search">
                        $search
                    </v-icon>
                    <div class="search-page__empty-state-title body-copy">
                        Begin your title search journey above
                    </div>
                    <div class="content__label search-page__empty-state-subtitle">
                        You can search for titles by providing a title number
                    </div>
                </div>
            </div>
        </div>
        <add-to-matter-dialog v-if="titleToAddToMatter"
                              v-model="isAddToMatterDialogVisible"
                              :title-number="titleToAddToMatter"
                              :matter="currentMatter" />
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import SearchApi from '@/api/search.api'
    import OwPagination from '@/components/core/ow-pagination.vue'
    import AddToMatterDialog from '@/components/search/add-to-matter-dialog.vue'
    import ResultsList from '@/components/search/results-list.vue'
    import TitleSearchBox from '@/components/search/title-search-box.vue'
    import { INITIAL_ITEMS_PER_PAGE } from '@/consts/pagination'
    import { Route } from '@/enums/route.enum'
    import { ResultItemInterface } from '@/interfaces/search-result-item.interface'
    import {
        TITLE_CLEAR,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'

    export default {
        components: {
            OwPagination,
            ResultsList,
            TitleSearchBox,
            AddToMatterDialog,
        },
        data() {
            return {
                activePage: 1,
                itemsPerPage: INITIAL_ITEMS_PER_PAGE,
                titleToAddToMatter: null,
                isAddToMatterDialogVisible: false,
            }
        },
        computed: {
            ...mapState({
                currentMatter: (state: any) => state.matter.currentMatter,
                allResults: (state: any) => state.globalSearch.allResults,
            }),
            visibleResults() {
                return this.allResults.slice((this.activePage - 1) * this.itemsPerPage, this.activePage * this.itemsPerPage) || []
            },
        },
        watch: {
            $route(to, from) {
                if (from.name === Route.Search && to.name !== Route.TitleDetails) {
                    this.clearTitle()
                }
            },
        },
        methods: {
            ...mapActions({
                clearTitle: TITLE_CLEAR,
                lookupTitle: TITLE_LOOKUP_TITLE,
            }),
            setActivePage(page: number): void {
                this.activePage = page
            },
            setItemsPerPage(items: number): void {
                this.itemsPerPage = items
            },
            addToMatter(titleNumber: string): void {
                this.titleToAddToMatter = titleNumber
                this.isAddToMatterDialogVisible = true
            },
            async onResultClick(result: ResultItemInterface) {
                const titleNumber = result.titleNumber
                this.lookupTitle(titleNumber)
                await this.goToTitleDetails(titleNumber)
            },
            async goToTitleDetails(titleNumber: string): Promise<void> {
                await SearchApi.addSearchHistory(titleNumber)
                await this.$router.push({
                    name: Route.TitleDetails,
                    params: {
                        titleNumber,
                    },
                })
            },
        },
    }
</script>

<style lang="scss">
    @import 'search.scss';
</style>
