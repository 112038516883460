import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
import { HighLevelDocumentType } from '@/consts/document-high-level-type'
import { DocumentOrderStatus } from '@/consts/document-order-status'
import { isNullOrWhitespace } from '@/utils/string-utils'

/**
 * Returns a simple string to be used for sorting based on a dd-mm-yyyy date
 * string
 * @param str - the date string to format
 * @example
 *      getSortableDocumentDateString('29-02-2016')
 *      return '20160229'
 * @returns {string|*}
 */
const getSortableDocumentDateString = (str) => `${ str.substr(6, 4) }-${ str.substr(3, 2) }-${ str.substr(0, 2) }`

// NOTE: HighLevelDocumentType includes the definitions below, but in lowercase
//       consts/document-type.js includes another definition of DocumentType, unrelated to this
/* eslint-disable */
class DocumentTypes {
    static NO_VIEW_IN_APP = new Set(['Spreadsheet']) // does not open within the application
    static DOWNLOAD_ONLY = new Set(['Spreadsheet'])
    static NOT_COPY_FILED = new Set(['Spreadsheet', 'Register', 'Title Plan'])
    static NO_ENHANCED_DOCUMENT_AVAILABLE = new Set(['Spreadsheet', 'Register', 'Title Plan', HighLevelDocumentType.Uploaded])
}
/* eslint-enable */

/**
 * Returns a string to describe the document status based on available metadata
 * @param {any} document
 */
const getDocumentStatus = (document) => {
    if (document.documentError === true) {
        return 'Error'
    } else if (document.documentStatus === 'Unavailable') {
        return 'Unavailable'
    } else if (document.documentStatus === 'Sent in post') {
        return 'Sent in post'
    } else if (document.documentPending === true) {
        return 'Pending with HMLR...'
    } else if (document.loading === true && document.filename === null && !(document.downloadUrl || document.documentDownloadUrl)) {
        return 'Requesting from HMLR...'
    } else if (!(DocumentTypes.NO_VIEW_IN_APP.has(document.documentType)) &&
        (document.augmentedFilename == null && document.filename != null && document.documentPending !== true)) {
        return 'Making searchable...'
    } else {
        return null
    }
}

/**
 * Returns a an array of (selectable) document type options for a given array
 * of document metadata and an existing array of results representing the
 * selection
 * @param documents - the document metadata array
 * @param existingSelections - an existing result reflecting and existing
 *     selection of document types
 * @returns {Array|*}
 */
const getDocumentTypeOptions = (documents, existingSelections) => {
    return [...new Set(documents.map(doc => doc.documentType))]
        .sort()
        .map(docType => {
            const existingSelection = existingSelections
                ? existingSelections.some(option => option.value === docType)
                : false
            return {
                title: docType,
                value: docType,
                selected: existingSelection,
            }
        })
}

/**
 * Returns a an array of (selectable) document type options for a given array
 * of document metadata and an existing array of results representing the
 * selection
 * @param documents - the document metadata array
 * @param existingSelections - an existing result reflecting and existing
 *     selection of document types
 * @returns {Array|*}
 */
const getDocumentStatusOptions = (documents, existingSelections) => {
    return ['Available']
        .concat([...new Set(documents.map(doc => getDocumentStatus(doc)))]
            .sort()
            .filter(status => status !== null))
        .map(status => {
            const existingSelection = existingSelections
                ? existingSelections.some(option => option.value === status)
                : false
            return {
                title: status.replace('...', ''),
                value: status,
                selected: existingSelection,
            }
        })
}

/**
 * Returns a boolean if given document metadata indicates document can be
 * downloaded
 * @param {any} document
 * @returns {Boolean|*}
 */
const getDocumentDownloadAvailability = (document) => {
    if (document.documentStatus === 'Unavailable') {
        return false
    } else if (DocumentTypes.NOT_COPY_FILED.has(document.documentType)) {
        return (document.documentDownloadUrl || document.downloadUrl) &&
            document.showDownload === true
    } else {
        return document.filename !== null &&
            (document.documentPending === false || document.currentStatusDescription === 'Available')
    }
}

/**
 * Returns true if the order button should be shown for the document, false otherwise
 * @param document {Object}
 * @return {boolean}
 */
const showOrderButton = (document) => {
    if (document.documentError === true) {
        return false
    } else if (DocumentTypes.NOT_COPY_FILED.has(document.documentType)) {
        return (isNullOrWhitespace(document.documentDownloadUrl) || isNullOrWhitespace(document.documentDownloadUrl)) &&
            isNullOrWhitespace(document.documentDate)
    } else {
        return document.loading !== true &&
            document.documentPending !== true &&
            isNullOrWhitespace(document.filename) &&
            !(document.downloadUrl || document.documentDownloadUrl)
    }
}

/**
 * Returns true if the view button should be show for the document, false otherwise
 * @param document {Object}
 * @return {boolean|null}
 */
const showViewButton = (document) => {
    if (document.documentError === true) {
        return false
    } else if (DocumentTypes.DOWNLOAD_ONLY.has(document.documentType)) {
        return false
    } else {
        if (DocumentTypes.NOT_COPY_FILED.has(document.documentType)) {
            return (document.downloadUrl || document.documentDownloadUrl) &&
                isNullOrWhitespace(getDocumentStatus(document))
        } else {
            return !isNullOrWhitespace(document.augmentedFilename) &&
                isNullOrWhitespace(getDocumentStatus(document))
        }
    }
}

/**
 * Returns true if the download button should be shown for the document, false otherwise
 * @param document {Object}
 * @return {boolean|Boolean|*}
 */
const showDownloadButton = (document) => {
    if (document.documentError === true) {
        return false
    } else {
        return getDocumentDownloadAvailability(document)
    }
}

/**
 * Returns true if the order status for a document gets into any of the error states
 * @param document {Object}
 * @return {boolean|Boolean|*}
 */
export const isErrorState = (document) => {
    const { orderStatus, hmlrAvailabilityCode } = document
    const erroredDocumentUnavailableState = orderStatus === DocumentOrderStatus.UNAVAILABLE && hmlrAvailabilityCode === HmlrDocumentAvailabilityCode.Available

    return (
        [DocumentOrderStatus.FAILURE, DocumentOrderStatus.REMOTE_FAILURE].includes(orderStatus) ||
        erroredDocumentUnavailableState
    )
}

/**
 * Returns true if official copies document has been ordered, false otherwise
 * @param document
 * @returns {boolean}
 */
const hasDocumentBeenOrdered = (document) => {
    return !isNullOrWhitespace(document.filename) ||
        document.pendingWithHmlr === true ||
        (!isNullOrWhitespace(document.downloadUrl) && !isNullOrWhitespace(document.documentDownloadUrl))
}

/**
 * Returns the file name of a purchased register
 * @param document
 * @returns {string}
 */
const getPurchasedOC2DocumentName = (document) => {
    if (isNullOrWhitespace(document.titleNo) ||
         isNullOrWhitespace(document.documentType) ||
         isNullOrWhitespace(document.documentDate)) {
        return '-'
    }
    return `Official Copy (${ document.titleNo } - ${ document.documentType } - ${ document.documentDate }).pdf`
}

/**
 * Returns the file name of a purchased document
 * @param document
 * @returns {string}
 */
const getPurchasedDocumentName = (document) => {
    switch (document.documentType) {
        case 'Title Plan':
            return `Official_Copy_Plan_${ document.titleNo }.pdf`

        case 'Register':
            return `Official_Copy_Register_${ document.titleNo }.pdf`

        default:
            return getPurchasedOC2DocumentName(document)
    }
}

export {
    DocumentTypes,
    getDocumentDownloadAvailability,
    getDocumentStatus,
    getDocumentStatusOptions,
    getDocumentTypeOptions,
    getPurchasedDocumentName,
    getSortableDocumentDateString,
    hasDocumentBeenOrdered,
    showDownloadButton,
    showOrderButton,
    showViewButton,
}
