<template>
    <div class="d-flex align-center justify-space-between">
        <span class="flex-grow-1 caption-highlight">{{ title }}</span>
        <v-switch :model-value="modelValue"
                  color="primary"
                  class="mr-3 flex-shrink-1 flex-grow-0"
                  :data-test="`${dataTestId}-switch`"
                  hide-details
                  @update:model-value="$v => modelValue = $v" />
    </div>
    <div v-if="modelValue">
        <slot />
    </div>
</template>

<script setup lang="ts">
    const modelValue = defineModel<boolean>()
    defineProps<{
        title: string
        dataTestId?: string
    }>()
</script>

<style scoped>

</style>
