import { CreateOverlayRequest } from '@/components/map/overlays/overlays-types'
import MapApi from '@/api/map.api'
import {
    extendCoordinate,
    getCenter,
} from 'ol/extent'
import { calculateCentrePoint,
    getRectangleFeatureFromCoordinates,
    rotateExtentAroundFixedCentre } from './map-utils'
import { degreesToRadians } from '@turf/helpers'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import GeoJSON from 'ol/format/GeoJSON'
import { Fill,
    Style } from 'ol/style'
import { getVectorContext } from 'ol/render'
import { Feature } from 'ol'
import { GeoImage } from '@/store/modules/map/layers/geo-image/geo-image-layer'
import { Polygon } from 'ol/geom'
import { ISearchesDocument } from '@/interfaces/searches-document.interface'
import { OverlayDocumentType } from '@/enums/overlay-document-type'


export const getOverlayDocumentType = (document?: any): OverlayDocumentType  => {
    if (!document) {
        return null
    } else if (document?.documentUploadRequestId) {
        return OverlayDocumentType.Uploaded
    } else if (document?.orderId) {
        return OverlayDocumentType.Searches
    } else {
        return OverlayDocumentType.Purchased
    }
}

export const getNameForOverlay = (selectedDocument: any): string => {
    let baseString = ''
    const overlayDocumentType = getOverlayDocumentType(selectedDocument)

    if (overlayDocumentType === OverlayDocumentType.Uploaded) {
        baseString = selectedDocument.fileName
    } else if (overlayDocumentType === OverlayDocumentType.Searches) {
        const searchesDocument = selectedDocument as ISearchesDocument
        baseString = `${ searchesDocument.location } - ${ searchesDocument.productName } - ${ searchesDocument.createdOn }`
    } else {
        baseString = `${ selectedDocument.titleNo } - ${ selectedDocument.documentType }`
    }``

    return baseString
}

/**
 * Some initial basic steps prior to back-end automation for determining a suggested bbox for an overlay.
 * @param request The request to create an overlay.
 */
export const getSuggestedCentrePointForOverlayRequest = async (request: CreateOverlayRequest): Promise<number[]> => {
    if (request.associatedTitles.length) {
        // If title numbers are provided, use the bounding box of the titles to centre the overlay.
        const response = await MapApi.getPointsForTitleNumbers(request.associatedTitles)
        const extent = [Number.MAX_VALUE, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE]
        if (response.length) {
            response.forEach((point) => {
                extendCoordinate(extent, [point.x, point.y])
            })
            return getCenter(extent)
        }
    }
    return null
}

export const MaxResolutionToInitialiseOverlayBbox = 100

export const setImagePostRender = (
    imageLayer: GeoImage,
    rotation: number,
    boundingBox: number[],
    geoJson: string) => {
    if(!geoJson) {
        return
    }
    imageLayer.on('postrender', (e) => {
        if (geoJson) {
            let feat = new GeoJSON().readFeature(geoJson, {
                featureProjection: CoordinateSystemCode.EPSG27700,
                dataProjection: CoordinateSystemCode.EPSG4326,
            }) as Feature<Polygon>

            let rotatedImageExtent = rotateExtentAroundFixedCentre(
                feat.getGeometry().getExtent(),
                calculateCentrePoint(boundingBox),
                -degreesToRadians(rotation))
            imageLayer.getSource().setCropExtent(feat.getGeometry().getExtent(), rotation)
            imageLayer.getSource().setUseCropExtent(true)

            feat = getRectangleFeatureFromCoordinates(rotatedImageExtent)

            drawImageMask(e, feat)
        }
    })
}


export const maskStyle = new Style({
    fill: new Fill({
        color: 'black',
    }),
})

export const drawImageMask = (e: any, feat: Feature) => {
    const vectorContext = getVectorContext(e)
    e.context.globalCompositeOperation =  'destination-in'

    vectorContext.drawFeature(feat, maskStyle)

    e.context.globalCompositeOperation = 'source-over'
}
