<template>
    <div :class="{
             selected: isSelectedTitle,
             freehold: isFreehold,
             leasehold: isLeasehold,
             'is-sublease': depth > 0,
             [`depth-${ hierarchyDepth }`]: true,
         }"
         class="d-flex flex-column mb-2 hierarchy-item"
         data-test="lease-hierarchy-panel">
        <div class="d-flex flex-column item-content pa-3 gr-2"
             @click="onItemClick">
            <!-- Title number and address -->
            <header>
                <ow-checkbox :id="`title-panel-lease-hierarchy-select-title-${ item.titleNumber }`"
                             v-model="selected"
                             data-test="lease-hierarchy-item-select"
                             data-track="TITLE-DETAILS-PANEL - Select title item from lease hierarchy" />
                <router-link :data-test="`title-panel-leases-select-title-${ item.titleNumber }`"
                             :to="titleNumberLinkTo(item)"
                             class="hierarchy-item__check-label title-panel-leases-select-title accents-highlight"
                             data-track="TITLE-DETAILS-PANEL - Open title from lease hierarchy">
                    {{ item.titleNumber }}
                </router-link>
                <span v-if="
                          item.addresses.length"
                      class="hierarchy-item__address">-</span>
                <span v-for="address in item.addresses"
                      v-if="item.addresses.length"
                      class="hierarchy-item__address accents-small text-no-wrap">
                    {{ address }}
                </span>
            </header>

            <p v-if="item.skipped"
               v-t="'titlePanel.leaseHierarchy.appearsElsewhere'"
               class="accents-highlight" />

            <!-- Owner(s) -->
            <div v-if="item.ownerNames.length > 0"
                 class="hierarchy-item__owner accents-small text-no-wrap">
                <span class="mr-2"> {{ t('titlePanel.leaseHierarchy.owner', { count: item.ownerNames.length }) }} </span>
                <span v-for="name in item.ownerNames">{{ name }}</span>
            </div>
        </div>

        <!-- Expand hierarchy toggle-->
        <div v-if="item.items.length > 0"
             class="d-flex hierarchy-toggle"
             data-test="lease-hierarchy-expander"
             data-track="TITLE-DETAILS-PANEL - Expand lease hierarchy"
             @click="toggleHierarchy()">
            <div class="d-flex align-center w-100">
                <v-icon v-if="!showHierarchy"
                        class="expand-icon">
                    $plus-box
                </v-icon>
                <v-icon v-if="showHierarchy"
                        class="expand-icon">
                    $minus-box
                </v-icon>
                <div class="leasehold-count accents-highlight"
                     data-test="lease-hierarchy-count">
                    <!-- eslint-disable-next-line vue/no-unused-vars -->
                    <span v-for="i in depth">Sub-</span><span>Leaseholds</span> ({{ item.items.length }})
                </div>
            </div>
        </div>
    </div>

    <!-- Hierarchy -->
    <div v-if="hasHierarchy"
         v-show="showHierarchy">
        <lease-hierarchy-item v-for="(child, i) in item.items"
                              :key="getUniqueKey(child, i)"
                              :depth="hierarchyDepth"
                              :item="child"
                              :selected-title-numbers="selectedTitleNumbers"
                              class="hierarchy-item"
                              @title-selected="$emit('title-selected', $event)" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useI18n } from "vue-i18n"
    import { useRoute } from 'vue-router'
    import { useStore } from 'vuex'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import type { LeaseHierarchyItem } from '@/components/lease-hierarchy/lease-hierarchy-types'
    import { Route } from '@/enums/route.enum'
    import { isNullOrEmpty } from "@/utils/array-utils"

    interface Props {
        item: LeaseHierarchyItem,
        depth: number,
        selectedTitleNumbers: string[],
    }

    const props = defineProps<Props>()
    const emit = defineEmits<{
        (e: 'title-selected', value: string): void
    }>()
    const store = useStore()
    const route = useRoute()
    const showHierarchy = ref(true)
    const { t } = useI18n()

    const currentMatterId = computed(() => store.state.matter.currentMatter.id)
    const selectedTitleNumber = computed(() => store.state.title.selectedTitleNumber)
    const isSelectedTitle = computed(() => props.item.titleNumber === selectedTitleNumber.value)
    const selected = computed({
        get() {
            return props.selectedTitleNumbers.includes(props.item.titleNumber)
        },
        set() {
            emit('title-selected', props.item.titleNumber)
        },
    })
    const isFreehold = computed(() => {
        if (props.item.tenure == null) {
            return false
        }
        return props.item.tenure.includes('Freehold')
    })
    const isLeasehold = computed(() => {
        if (props.item.tenure == null) {
            return false
        }
        return props.item.tenure.includes('Leasehold')
    })
    const hasHierarchy = computed(() => {
        return !isNullOrEmpty(props.item.items)
    })
    const hierarchyDepth = computed(() => props.depth + 1)
    const getUniqueKey = (child: LeaseHierarchyItem, index: number) => {
        return `${ child.titleNumber }-${ index }-${ child.parentTitleNumber }-${ Date.now().toString() }`
    }
    const toggleHierarchy = () => {
        if (hasHierarchy.value) {
            showHierarchy.value = !showHierarchy.value
        }
    }
    const titleNumberLinkTo = (item) => {
        const titleDetailsRoute = {
            name: Route.TitleDetails,
            params: {
                titleNumber: item.titleNumber,
            },
        }
        const mapTitleRoute = {
            name: Route.MatterMapTitle,
            params: {
                matterId: currentMatterId.value,
                titleNumber: item.titleNumber,
            },
        }

        return route.name === Route.TitleDetails ? titleDetailsRoute : mapTitleRoute
    }

    const onItemClick = (event) => {
        if ([ 'INPUT', 'A' ].includes(event.target.tagName)) {
            return
        }
        emit('title-selected', props.item.titleNumber)
    }

</script>

<style lang="scss"
       scoped>
@import 'lease-hierarchy-item.scss';
</style>
