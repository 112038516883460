<template>
    <div :class="{collapsed: isPanelCollapsed}"
         class="overlays-panel"
         @click="onPanelClick">
        <ow-sidebar-header :title="matterName"
                           :collapsed="isPanelCollapsed"
                           header-data-test="overlays-panel-header"
                           button-data-test="overlays-panel-collapse-button"
                           button-data-track="overlays-panel-collapse-button"
                           @toggle="togglePanel" />
        <!-- Hint text -->
        <section v-if="!isPanelCollapsed && !selectedOverlay"
                 :class="{'hide-hint': hideHint}"
                 class="overlays-panel__hint">
            <div class="d-flex align-center">
                <p v-t="'mapOverlays.panel.hintText'"
                   class="caption-regular" />
                <ow-button data-test="overlays-panel-hide-hint-button"
                           data-track="overlays-panel-hide-hint-button"
                           icon
                           is-borderless
                           @click="hideHint = !hideHint">
                    <v-icon v-if="!hideHint">
                        $chevron-up
                    </v-icon>
                    <v-icon v-if="hideHint">
                        $chevron-down
                    </v-icon>
                </ow-button>
            </div>
            <!-- TODO replace with intended gif -->
            <img v-if="!hideHint"
                 src="@/media/overlays-example.gif"
                 :alt="t('mapOverlays.panel.hintText').toString()" />

            <!-- TODO: uncomment and update this once a help centre article is available -->
            <!--            <i18n v-if="!hideHint"-->
            <!--                  path="mapOverlays.panel.hintText2"-->
            <!--                  tag="p"-->
            <!--                  class="accents-small"-->
            <!--                  :for="t('mapOverlays.panel.hintText2')">-->
            <!--                <template #link>-->
            <!--                    <a :href="t('mapOverlays.panel.hintText2Link')"-->
            <!--                       class="accents-link-text"-->
            <!--                       target="_blank">{{ t('mapOverlays.panel.hintText2LinkLabel') }}</a>-->
            <!--                </template>-->
            <!--            </i18n>-->
        </section>
        <section v-if="!isPanelCollapsed && !selectedOverlay"
                 class="overlays-panel__new-plan">
            <ow-button data-test="overlays-panel-new-plan-button"
                       data-track="OVERLAY PLANS - New plan"
                       full-width
                       is-primary
                       @click="onNewClick">
                {{ t('mapOverlays.panel.newOverlay') }}
            </ow-button>
        </section>
        <!-- Main content -->
        <section v-if="!isPanelCollapsed"
                 class="overlays-panel__list">
            <!-- TODO: List of overlays - create a new component -->
            <overlays-list v-if="!selectedOverlay"
                           :key="overlayListComponentKey"
                           :overlays="overlays"
                           :target-map="props.targetMap"
                           :pre-expanded-overlay="preExpandedOverlay"
                           @remove="removeOverlaysByIds"
                           @highlight="highlightOverlayById"
                           @select="setSelectedOverlay"
                           @show="showOverlays"
                           @hide="hideOverlays" />

            <overlaying-plans-container v-if="selectedOverlay && targetMap"
                                        :matter-id="matterId"
                                        :matter-name="matterName"
                                        :target-map="targetMap"
                                        :overlay="selectedOverlay"
                                        :is-updating="isUpdating"
                                        :updated-on="getUpdatedOn()"
                                        @remove="removeOverlaysByIds([selectedOverlay.id])"
                                        @dismiss="selectedOverlay = null"
                                        @done="onUpdatingOverlayingPlanCompleted"
                                        @updated="onOverlayingPlanUpdated" />
        </section>

        <section v-if="!isPanelCollapsed && !hideSketchesHintCompletely"
                 class="overlays-panel__bottom-actions">
            <div class="sketch-hints">
                <!-- Sketch icon, not used anywhere else -->
                <ow-sketch-hint-icon height="32px"
                                     width="32px" />

                <div class="sketch-hints-text">
                    <p v-t="'mapOverlays.panel.sketchHint'"
                       class="accents-highlight" />
                    <p v-if="!hideSketchHint"
                       v-t="'mapOverlays.panel.sketchHintDescription'"
                       class="accents-smallest" />
                </div>
                <ow-button data-test="overlays-panel-hide-sketch-hint-button"
                           data-track="overlays-panel-hide-sketch-hint-button"
                           icon
                           is-borderless
                           @click="hideSketchHint = !hideSketchHint">
                    <v-icon v-if="!hideSketchHint">
                        chevron-down
                    </v-icon>
                    <v-icon v-if="hideSketchHint">
                        chevron-up
                    </v-icon>
                </ow-button>
            </div>
            <ow-button v-if="!hideSketchHint"
                       data-test="overlays-panel-add-sketch-button"
                       data-track="OVERLAY PLANS - Add Sketch"
                       full-width
                       is-secondary
                       @click="onAddSketchClick">
                {{ t('mapOverlays.panel.sketchHintButtonLabel') }}
            </ow-button>
        </section>
    </div>
</template>
<script lang="ts" setup>
    import olMap from 'ol/Map'
    import { onActivated,
             onDeactivated,
             onMounted,
             ref,
             watch } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRoute,
             useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OverlaysApi from '@/api/overlays.api'
    import OwSketchHintIcon from '@/components/core/icons/ow-sketch-hint-icon.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwSidebarHeader from '@/components/core/ow-sidebar-header.vue'
    import OverlayingPlansContainer from '@/components/map/overlays/components/overlaying-plans-container.vue'
    import OverlaysList from '@/components/map/overlays/components/overlays-list.vue'
    import { OverlaysLayer } from '@/components/map/overlays/overlays-layer'
    import { OverlayListItemModel,
             OverlayModel,
             UpdateOverlayRequest } from '@/components/map/overlays/overlays-types'
    import { LayerNames } from '@/consts/map-layers'
    import { Route } from '@/enums/route.enum'
    import { MAP_PREVENT_TITLE_BOUNDARY_LAYER_DESELECTION } from '@/store/modules/map/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()

    interface Props {
        // eslint-disable-next-line vue/no-unused-properties
        targetMap: olMap | null,
        matterName: string,
        matterId: number,
        collapsed: boolean,
    }

    const props = withDefaults(defineProps<Props>(), {
        targetMap: null,
        matterName: null,
        matterId: null,
        collapsed: false,
    })
    const emit = defineEmits<{
        (e: 'collapse', value: boolean): void
    }>()

    // UI - Toggle panel visibility
    const isPanelCollapsed = ref(props.collapsed)
    const togglePanel = (value) => {
        isPanelCollapsed.value = value
    }
    const onPanelClick = () => {
        if (isPanelCollapsed.value) {
            togglePanel(false)
        }
    }
    watch(() => isPanelCollapsed.value, (val: boolean) => {
        emit('collapse', val)
    })

    watch(() => props.collapsed, (val: boolean) => {
        isPanelCollapsed.value = val
    })

    // UI - hide hint text.
    const hideHint = ref(false)
    const hideSketchHint = ref(false)
    const overlayListComponentKey = ref(0)

    // Data
    let layer: OverlaysLayer
    let isUpdateInProgress = false
    const overlays = ref<Array<OverlayListItemModel>>([])
    const selectedOverlay = ref<OverlayModel>(null)
    const preExpandedOverlay = ref<OverlayListItemModel>(null)
    const isUpdating = ref<boolean>(false)
    const updatedOn = ref<string>(null)
    const hideSketchesHintCompletely = ref<boolean>(false)

    watch(() => selectedOverlay.value, (val) => {
        if (layer && val) {
            layer.updateSelectedOverlay(val)
        }
    }, { deep: true })

    const getOverlaysList = async () => {
        if (isUpdateInProgress) {
            return
        }

        isUpdateInProgress = true

        try {
            const response = await OverlaysApi.getOverlays(props.matterId)

            overlays.value = response?.data ?? []

            if (!layer &&
                props.targetMap) {
                // Already loaded on the map?
                const existingLayer = props.targetMap.getLayers().getArray().find(x => x.get('name') === LayerNames.Overlays)
                if (existingLayer) {
                    layer = (existingLayer.get('getOwLayer'))()
                } else {
                    // Create a new one
                    layer = new OverlaysLayer()
                    await layer.initialiseIfRequired({
                        targetMap: props.targetMap,
                        overlays,
                        snapshotConfig: null,
                        overlayLoadingCb: setIsOverlayLoading,
                    })
                }
            }
            await layer.setOverlays(overlays)

            if (selectedOverlay.value) {
                layer.hideOverlay(selectedOverlay.value.id)
            }

            isUpdateInProgress = false
        } catch (e) {
            console.error('Unable to load overlays list', e)
        }
    }

    const setSelectedOverlay = async (overlayId: string): Promise<void> => {
        if (!isNullOrWhitespace(overlayId)) {
            try {
                // If an overlay was selected previously, show it again on the map layer
                if (selectedOverlay.value) {
                    layer.showOverlay(selectedOverlay.value.id)
                }
                const response = await OverlaysApi.getOverlay(overlayId, props.matterId ?? null)
                selectedOverlay.value = response?.data ?? null
                if (layer) {
                    layer.hideOverlay(overlayId)
                }
            } catch (err) {
                console.error('Unable to load overlay', err)
            }
        }
    }

    const onUpdatingOverlayingPlanCompleted = async (updated: OverlayModel) => {
        if (updated) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            await updateOverlay(updated)
            await router.push({ name: Route.OverlaysList })
            layer.invalidateOverlay(updated.id)
            await getOverlaysList()
        }
    }

    const onOverlayingPlanUpdated = async (updated: OverlayModel) => {
        if (updated) {
            await updateOverlay(updated)
            updatedOn.value = new Date().toString()
        }
    }

    const updateOverlay = async (updated: OverlayModel) => {
        if (!updated) {
            return
        }
        isUpdating.value = true
        const request: UpdateOverlayRequest = {
            name: updated.name,
            scale: updated.scale,
            centrePoint: updated.centrePoint,
            boundingBox: updated.boundingBox,
            visible: true,
            opacity: updated.opacity,
            matterId: props.matterId,
            associatedTitles: updated.titleNumbers,
            rotation: updated.rotation,
            visibleAreaGeoJson: updated.visibleAreaGeoJson,
        }
        await OverlaysApi.updateOverlay(updated.id, request)
        isUpdating.value = false
    }

    const resetListView = async () => {
        if (selectedOverlay.value) {
            layer?.invalidateOverlay(selectedOverlay.value.id)
            selectedOverlay.value = null
        }

        await getOverlaysList()
        preExpandedOverlay.value = overlays.value.find(x => x.overlayId === route.query.overlayId) ?? null
    }

    // Edit overlay
    watch(() => route.name, async () => {
        if (route.name === Route.Overlay && route.params?.overlayId) {
            hideSketchesHintCompletely.value = true
            await setSelectedOverlay(route.params?.overlayId)
        } else {
            hideSketchesHintCompletely.value = false
            await updateOverlay(selectedOverlay.value)
            await resetListView()
        }

        store.commit(MAP_PREVENT_TITLE_BOUNDARY_LAYER_DESELECTION,
                     route.name === Route.Overlay || route.name === Route.OverlaysList)
    }, { immediate: true })

    // CRUD
    const removeOverlaysByIds = async (overlayIdsToRemove: Array<string>) => {
        overlays.value = overlays.value.filter(x => !overlayIdsToRemove.includes(x.overlayId))
        for (const overlayId of overlayIdsToRemove) {
            await OverlaysApi.removeOverlay(overlayId)
            layer.invalidateOverlay(overlayId)
        }
        await router.push({ name: Route.OverlaysList })
    }

    // Rollover
    const highlightOverlayById = (overlayId: string) => {
        // console.info('Highlight overlay', overlays.value.find(x => x.overlayId === overlayId))
    }

    // Misc links and actions
    const onAddSketchClick = async () => {
        await router.push({ name: Route.MatterSketches })
    }

    const showOverlays = async (overlayIds: Array<string>) => await toggleOverlays(overlayIds, true)

    const hideOverlays = async (overlayIds: Array<string>) => await toggleOverlays(overlayIds, false)

    const toggleOverlays = async (overlayIds: Array<string>, visible: boolean) => {
        const updates = []
        for (const overlay of overlays.value.filter(x => overlayIds.includes(x.overlayId))) {
            overlay.visible = visible
            updates.push(updateOverlaysVisibility(overlay.overlayId, visible))
        }
        await Promise.all(updates)

        if (layer) {
            await layer.updateOverlays()
        }
    }

    const updateOverlaysVisibility = async (overlayId: string, isVisible: boolean) => {
        const request: UpdateOverlayRequest = {
            visible: isVisible,
            matterId: props.matterId,
        }
        await OverlaysApi.updateOverlay(overlayId, request)
    }

    const onNewClick = async () => {
        await router.push({
            name: Route.OverlaysCreate,
            params: {
                matterId: props.matterId.toString(),
            },
        })
    }

    const setIsOverlayLoading = (overlayId: string, isOverlayLoading: boolean) => {
        const overlay = overlays.value.find(x => x.overlayId == overlayId)
        if (overlay) {
            overlay.loading = isOverlayLoading
        }
        overlayListComponentKey.value++
    }

    const getUpdatedOn = () => {
        return updatedOn.value ?? selectedOverlay.value.updatedOn
    }

    onMounted(async () => {
        await getOverlaysList()
    })

    onActivated(async () => {
        await getOverlaysList()
    })

    onDeactivated(() => {
        if (layer) {
            layer.clearOverlays()
        }
    })

    defineExpose({
        isPanelCollapsed,
        hideHint,
        hideSketchHint,
        overlays,
    })

</script>
<style lang="scss" scoped>
    @import 'overlays-panel.scss';
</style>
