<template>
    <section class="d-flex align-center justify-center">
        <ow-loading-text text="Logging out" />
    </section>
</template>

<script lang="ts" setup>
    import { onMounted } from 'vue'
    import { useStore } from 'vuex'

    import OwLoadingText from '@/components/core/ow-loading-text.vue'
    import { AUTH_LOGOUT } from '@/store/modules/auth-oidc/types'

    const store = useStore()

    onMounted(() => {
        store.dispatch(AUTH_LOGOUT)
    })
</script>
