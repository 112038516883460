import Feature, { FeatureLike } from 'ol/Feature'
import MVT from 'ol/format/MVT'
import {
    Layer,
} from 'ol/layer'
import LayerGroup from 'ol/layer/Group'
import VectorTileLayer from 'ol/layer/VectorTile'
import olMap from 'ol/Map'
import VectorTileSource from 'ol/source/VectorTile'
import {
    Circle as CircleStyle,
    Fill,
    Stroke,
    Style,
} from 'ol/style'

import {
    IMapRolloverOptions,
    IMapRolloverSectionItem,
} from '@/components/map-rollover/common/map-rollover-interfaces'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { LayerNames } from '@/consts/map-layers'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { SketchType } from '@/enums/sketches-enums'
import i18n from '@/plugins/i18n'
import { BaseMvtDataLayerParams } from '@/store/modules/map/layers/base-mvt-data-layer'
import { geoserver27700TileGrid } from '@/store/modules/map/layers/geoserver-27700-tilegrid'
import { IOwSketchOrStyleProps } from '@/store/modules/sketches/types/style'
import { unique } from '@/utils/array-utils'
import { format } from '@/utils/date-utils'
import { getGeoserverApiUri } from '@/utils/environment.utils'
import { layerEquals } from '@/utils/map-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

import { BaseMvtDataLayer } from './base-mvt-data-layer'

type HoverFnType = (textArray: string[], event: any) => void

type LogToggleEventFnType = (visible: boolean) => void

type LogClickEventFnType = (externalLink: string) => void

export enum ListedBuildingColours {
    'I' = '115, 197, 230',
    'II*' = '29, 115, 173',
    'II' = '5, 33, 83',
    'A' = '115, 197, 230',
    'B' = '29, 115, 173',
    'C' = '5, 33, 83',
}

export const ListedBuildingSortOrder = new Map<string, number>(Object.entries({
    I: 1,
    'II*': 2,
    II: 3,
    A: 1,
    B: 2,
    C: 3,
}))

export type ListedBuildingsLayerParams = BaseMvtDataLayerParams & {
    onHoverTextChangeFn?: HoverFnType
    logToggleEvent?: LogToggleEventFnType
    logClickEvent?: LogClickEventFnType
}

type GenericKeyValuePairType = {
    [x: string]: any,
}

export type ListedBuildingsFeaturesType = {
    name: string,
    england: boolean,
    wales: boolean,
    // eslint-disable-next-line camelcase
    entry_number: string,
    grade: string,
    // eslint-disable-next-line camelcase
    listed_date: string,
    layer: string,
} | GenericKeyValuePairType

export class ListedBuildingsLayer implements IMapSnapshotLayer {
    private overFeatureClassName = 'ow-over-layer-listed-buildings'
    public name:string = LayerNames.ListedBuildings
    private readonly settings: any
    private readonly interactive: boolean
    public targetMap: olMap
    private initialised = false
    public pointsLayer: VectorTileLayer
    public boundaryLayer: VectorTileLayer
    public layerGroup: LayerGroup

    private readonly onHoverTextChangeFn: HoverFnType
    private readonly logToggleEvent: LogToggleEventFnType
    private readonly logClickEvent: LogClickEventFnType

    public readonly pointLayerName = 'ow:vlisted_buildings_points_combined'
    public readonly boundaryLayerName = 'ow:listed_buildings_polygons_england'

    private loadingTiles = 0
    private overFeatures: Array<FeatureLike> = []

    constructor(params: ListedBuildingsLayerParams, settings: any) {
        this.settings = settings
        this.interactive = !params.snapshotConfig
        this.targetMap = params.targetMap
        this.onHoverTextChangeFn = params.onHoverTextChangeFn
        this.logToggleEvent = params.logToggleEvent
        this.logClickEvent = params.logClickEvent
    }

    public async setVisible(visible: boolean, targetMap?: olMap): Promise<void> {
        this.targetMap = targetMap ?? this.targetMap
        this.initialiseIfRequired()
        this.layerGroup.setVisible(visible)
        if (this.interactive) {
            this.logToggleEvent(visible)
        }
    }

    public getVisible(): boolean {
        return this.initialised && this.layerGroup?.getVisible()
    }

    public pointStyleCache = new Map()

    public getPointStyleForFeature(feature: Feature, resolution: number): Style {
        const properties: ListedBuildingsFeaturesType = feature.getProperties()
        const colour = `rgb(${ ListedBuildingColours[properties.grade] })`
        let radius = 0
        if (this.overFeatures.includes(feature)) {
            radius = 9
        } else {
            radius = resolution > 1 ? 6 : 8
        }

        const styleHash = `${ colour }-${ radius }`
        const result = this.pointStyleCache.get(styleHash)
        if (result) {
            return result
        }
        const style = new Style({
            zIndex: 300,
            image: new CircleStyle({
                radius,
                fill: new Fill({
                    color: colour,
                }),
                stroke: new Stroke({
                    color: '#ffffff',
                    width: 2,
                }),
            }),
        })
        this.pointStyleCache.set(styleHash, style)
        return style
    }

    private boundaryStyleCache = new Map()

    private getBoundaryStyleForFeature(feature: Feature): Style {
        const properties: ListedBuildingsFeaturesType = feature.getProperties()
        const colour = `rgba(${ ListedBuildingColours[properties.grade] }, 0.8)`

        const styleHash = properties.grade
        const result = this.boundaryStyleCache.get(styleHash)
        if (result) {
            return result
        }
        const style = new Style({
            fill: new Fill({ color: colour }),
            stroke: new Stroke({
                color: '#ffffff',
                width: 3,
            }),
        })
        this.boundaryStyleCache.set(styleHash, style)
        return style
    }

    public initialiseIfRequired():void {
        if (!this.initialised) {
            this.pointsLayer = new VectorTileLayer({
                maxResolution: 6,
                zIndex: 18,
                source: new VectorTileSource({
                    format: new MVT(),
                    attributions: [`Listed Building data - © Historic England, Cadw, Scotland - released ${ this.settings.listedBuildingsReleaseDate }`],
                    url: `${ getGeoserverApiUri() }/gwc/service/tms/1.0.0/${ this.pointLayerName }@${ CoordinateSystemCode.EPSG27700 }@pbf/{z}/{x}/{-y}.pbf`,
                    tileGrid: geoserver27700TileGrid,
                    projection: CoordinateSystemCode.EPSG27700,
                }),
                style: (feature: Feature, resolution:number) => {
                    return this.getPointStyleForFeature(feature, resolution)
                },
            })
            this.boundaryLayer = new VectorTileLayer({
                maxResolution: 6,
                zIndex: 12,
                source: new VectorTileSource({
                    format: new MVT(),
                    attributions: [`Listed Building data - © Historic England & Cadw, Scotland - released ${ this.settings.listedBuildingsReleaseDate }`],
                    url: `${ getGeoserverApiUri() }/gwc/service/tms/1.0.0/${ this.boundaryLayerName }@${ CoordinateSystemCode.EPSG27700 }@pbf/{z}/{x}/{-y}.pbf`,
                    tileGrid: geoserver27700TileGrid,
                    projection: CoordinateSystemCode.EPSG27700,
                }),
                style: (feature: Feature) => {
                    return this.getBoundaryStyleForFeature(feature)
                },
            })

            this.layerGroup = new LayerGroup({
                zIndex: 10,
            })
            this.targetMap.addLayer(this.layerGroup)
            this.layerGroup.getLayers().push(this.pointsLayer)
            this.layerGroup.getLayers().push(this.boundaryLayer)
            this.layerGroup.set('name', LayerNames.ListedBuildings)
            this.layerGroup.set('getOwLayer', () => this)

            this.layerGroup.set('getRolloverTextForPoint', BaseMvtDataLayer.getRolloverTextForPoint.bind(this, this.layerGroup, this.getRolloverOptions(), null))
            this.initialiseMapEvents()
            this.initialised = true
        }
    }

    public getRolloverOptions(): IMapRolloverOptions {
        return {
            category: i18n.global.t(`map.rollover.${ LayerNames.ListedBuildings }.category`),
            allowClick: true,
            getFeatures: (targetMap, pixel): Record<string, FeatureLike[]> => {
                const features = targetMap.getFeaturesAtPixel(pixel, {
                    layerFilter: currentLayer => {
                        return currentLayer === this.pointsLayer || currentLayer === this.boundaryLayer
                    },
                })
                if (!features?.length) return null

                return features
            },
            getSource: (features) => {
                const properties = features[0].getProperties() as ListedBuildingsFeaturesType
                return properties.england ? i18n.global.t('map.rollover.listed-buildings.source.england') : i18n.global.t('map.rollover.listed-buildings.source.wales')
            },
            addItems: (features): IMapRolloverSectionItem[] => {
                const result: IMapRolloverSectionItem[] = []
                let featureArray = features as FeatureLike[]
                if (!featureArray.length) return result

                featureArray = featureArray.sort((a, b) => ListedBuildingSortOrder.get(b.getProperties().grade) - ListedBuildingSortOrder.get(a.getProperties().grade))
                featureArray.forEach(feature => {
                    const properties: ListedBuildingsFeaturesType = feature.getProperties()
                    const { grade, name } = properties

                    const colour = `rgb(${ ListedBuildingColours[grade] })`
                    const style: Partial<IOwSketchOrStyleProps> = {
                        fillColour: colour,
                        strokeColour: '#ffffff',
                    }

                    result.push({
                        primary: name,
                        extended: [
                            {
                                name: i18n.global.t(`map.rollover.${ LayerNames.ListedBuildings }.extended.grade`),
                                value: properties.grade,
                            },
                            {
                                name: i18n.global.t(`map.rollover.${ LayerNames.ListedBuildings }.extended.entryNumber`),
                                value: properties.entry_number,
                            },
                            {
                                name: i18n.global.t(`map.rollover.${ LayerNames.ListedBuildings }.extended.listedDate`),
                                value: properties.listed_date ? format(properties.listed_date, 'dd LLL yyyy') : '-',
                            },
                        ],
                        style,
                    })
                })
                return result
            },
        }
    }

    public static getHoverTextArray(properties: ListedBuildingsFeaturesType):string[] {
        const listedDate = properties.listed_date ? format(properties.listed_date, 'dd LLL yyyy') : '-'
        const source = properties.england ? 'Historic England' : 'Cadw'
        const textArray = [
            `${ i18n.global.t('map.options.listedBuildings.hover.name') }: ${ properties.name }`,
            `${ i18n.global.t('map.options.listedBuildings.hover.grade') }: ${ properties.grade }`,
            `${ i18n.global.t('map.options.listedBuildings.hover.entryNumber') }: ${ properties.entry_number }`,
            `${ i18n.global.t('map.options.listedBuildings.hover.dateFirstListed') }: ${ listedDate }`,
            `${ i18n.global.t('map.options.listedBuildings.hover.source') }: ${ source }`,
        ]
        return textArray
    }

    public initialiseMapEvents(): void {
        if (this.interactive) {
            this.targetMap.on('singleclick', (event) => {
                const pixel = this.targetMap.getEventPixel(event.originalEvent)
                this.targetMap.forEachFeatureAtPixel(pixel, (feature) => {
                    const properties = feature.getProperties()
                    if (!isNullOrWhitespace(properties?.external_link)) {
                        window.open(properties.external_link, '_blank')
                        this.logClickEvent(properties.external_link)
                    }
                }, {
                    layerFilter: layer => {
                        return layer === this.pointsLayer || layer === this.boundaryLayer
                    },
                })
                this.targetMap.getTargetElement().classList.remove(this.overFeatureClassName)
            })

            this.targetMap.on('pointermove', (event) => {
                const pixel = this.targetMap.getEventPixel(event.originalEvent)
                let textArray = []
                this.overFeatures = []
                this.targetMap.forEachFeatureAtPixel(pixel, (feature) => {
                    const properties: ListedBuildingsFeaturesType = feature.getProperties()
                    textArray = ListedBuildingsLayer.getHoverTextArray(properties)
                    this.overFeatures.push(feature)
                }, {
                    layerFilter: layer => {
                        return layer === this.pointsLayer || layer === this.boundaryLayer
                    },
                })
                if (this.overFeatures.length) {
                    this.targetMap.getTargetElement().classList.add(this.overFeatureClassName)
                } else {
                    this.targetMap.getTargetElement().classList.remove(this.overFeatureClassName)
                }
                this.onHoverTextChangeFn(textArray, event)
                this.layerGroup.changed()
            })

            this.targetMap?.getTargetElement()?.addEventListener('mouseout', () => {
                this.onHoverTextChangeFn([], null)
                this.targetMap.getTargetElement().classList.remove(this.overFeatureClassName)
            })
        }

        this.pointsLayer.getSource().on('tileloadstart', () => {
            this.loadingTiles++
        })
        this.pointsLayer.getSource().on(['tileloadend', 'tileloaderror'], () => {
            this.loadingTiles--
        })

        this.boundaryLayer.getSource().on('tileloadstart', () => {
            this.loadingTiles++
        })
        this.boundaryLayer.getSource().on(['tileloadend', 'tileloaderror'], () => {
            this.loadingTiles--
        })
    }

    public getLayers():VectorTileLayer[] {
        return [this.pointsLayer, this.boundaryLayer]
    }

    getLayer(): Layer | LayerGroup {
        return this.layerGroup
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: LayerNames.ListedBuildings,
        }
    }

    getIsLoading(): boolean {
        return this.loadingTiles > 0
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        const result = []
        const pointsInExtent = this.pointsLayer.getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))
        const boundariesInExtent = this.boundaryLayer.getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))

        const gradesInExtent = unique(
            pointsInExtent.concat(boundariesInExtent)
                .map(x => x.get('grade')))
            .sort((a, b) => ListedBuildingSortOrder.get(b) - ListedBuildingSortOrder.get(a))

        if (!gradesInExtent.length) return result

        gradesInExtent.forEach(grade => {
            const colour = `rgb(${ ListedBuildingColours[grade] })`
            const style: Partial<IOwSketchOrStyleProps> = {
                fillColour: colour,
                strokeColour: '#ffffff',
            }
            if (!boundariesInExtent.length) {
                style.sketchType = SketchType.Point
            }
            result.push({
                id: `listed-buildings-${ grade }`,
                label: `${ i18n.global.t('map.options.listedBuildings.text') } - ${ i18n.global.t('map.options.listedBuildings.hover.grade') } ${ grade }`,
                style,
                sketchType: SketchType.Point,
            })
        })

        return result
    }
}
