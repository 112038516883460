<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 48 48"
                  desc="Copilot icon">
        <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
              fill="#26DEEB" />
        <path d="M23.9998 7.52539L6.90039 13.7745L23.9998 20.9164L41.0968 13.7745L23.9998 7.52539Z"
              fill="#FFEAE6" />
        <path d="M23.9998 43.6216V20.9172L6.90039 13.7754V35.9055L23.9998 43.6216Z"
              fill="#FFA493" />
        <path d="M24 43.6216V20.9172L41.097 13.7754V35.9055L24 43.6216Z"
              fill="#FF6C50" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconSearchesIntro',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '48px',
            },
            height: {
                type: String,
                required: false,
                default: '48px',
            },
        },
    }
</script>
