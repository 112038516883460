import { Vector as VectorLayer } from 'ol/layer'
import { Vector as VectorSource } from 'ol/source'

import { getOLStyleForOWStyleDefinition } from '@/utils/style-utils'

const WalkthroughUtils = {

    /**
     * Initialises and returns an OL layer for the given page and target map.
     * @param page - the page within the walkthrough.
     * @param targetMap - the target OL map to add the resulting layer to.
     * @param onChangeCallback - method to call when features are added/removed.
     */
    getNewPageLayer: (page, targetMap, onChangeCallback, attribution) => {
        const resultLayer = new VectorLayer({
            zIndex: 14,
            updateWhileAnimating: true,
            updateWhileInteracting: true,
            source: new VectorSource(),
            style: (feature) => {
                // Revise to include look-up of sketch style metadata as needed.
                const owStyle = page.contentItems.find(item => item.titleNumber === feature.get('title_no'))
                if (owStyle) {
                    return getOLStyleForOWStyleDefinition(owStyle)
                }
                return null
            },
        })

        if (targetMap) {
            targetMap.addLayer(resultLayer)
        }

        if (onChangeCallback) {
            resultLayer.getSource().on(['addfeature', 'removefeature'], (event) => {
                onChangeCallback(event)
            })
        }

        if (attribution) {
            resultLayer.getSource().setAttributions([attribution])
        }

        return resultLayer
    },

    /**
     * Gets a suitable name for a page; either the one provided,
     * or one based on the page number.
     */
    getNameForPage: (page) => {
        if (page) {
            return page.name ?? `Page ${ page.pageNumber }`
        }
        return 'N/A'
    },

    /**
     * Returns a simplified object for the walkthrough that can be used to detect unsaved changes.
     * */
    getSimplifiedWalkthroughObject(walkthrough) {
        return {
            description: walkthrough.description,
            isEnabled: walkthrough.isEnabled,
            name: walkthrough.name,
            pages: walkthrough.pages.map(page => {
                return {
                    baseMapLayer: page.baseMapLayer,
                    description: page.description,
                    name: page.name,
                    pageNumber: page.pageNumber,
                    contentItems: page.contentItems.map(item => {
                        return {
                            colour: item.colour,
                            comment: item.comment,
                            dashed: item.dashed,
                            fill: item.fill,
                            fillOpacity: item.fillOpacity,
                            hatch: item.hatch,
                            label: item.label,
                            show: item.show,
                            showTitleNumber: item.showTitleNumber,
                            sortOrder: item.sortOrder,
                            strokeWidth: item.strokeWidth,
                            titleNumber: item.titleNumber,
                        }
                    }),
                }
            }),
        }
    },

}
export default WalkthroughUtils
