// Getters

// Mutations
export const PLANNING_MUTATE_DISTANCE = 'PLANNING_MUTATE_DISTANCE'
export const PLANNING_MUTATE_TEXT_FILTER = 'PLANNING_MUTATE_TEXT_FILTER'
export const PLANNING_MUTATE_INIT_LAYERS = 'PLANNING_MUTATE_INIT_LAYERS'
export const PLANNING_MUTATE_SELECTED_TITLE_FEATURES = 'PLANNING_MUTATE_SELECTED_TITLE_FEATURES'
export const PLANNING_MUTATE_SELECTED_SORT_OPTION = 'PLANNING_MUTATE_SELECTED_SORT_OPTION'
export const PLANNING_MUTATE_RESULTS = 'PLANNING_MUTATE_RESULTS'
export const PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS = 'PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS'
export const PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS = 'PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS'
export const PLANNING_MUTATE_LOADING_RESULTS = 'PLANNING_MUTATE_LOADING_RESULTS'
export const PLANNING_MUTATE_FILTERED_RESULTS = 'PLANNING_MUTATE_FILTERED_RESULTS'
export const PLANNING_MUTATE_HIGHLIGHT_FEATURES = 'PLANNING_MUTATE_HIGHLIGHT_FEATURES'
export const PLANNING_MUTATE_MAX_DISTANCE = 'PLANNING_MUTATE_MAX_DISTANCE'
export const PLANNING_MUTATE_TEXT_FILTER_KEYWORDS = 'PLANNING_MUTATE_TEXT_FILTER_KEYWORDS'
export const PLANNING_MUTATE_RATE_LIMITED_SECONDS = 'PLANNING_MUTATE_RATE_LIMITED_SECONDS'
export const PLANNING_MUTATE_PROMPT_FOR_RETRY = 'PLANNING_MUTATE_PROMPT_FOR_RETRY'
export const PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS = 'PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS'
export const PLANNING_MUTATE_FORCE_PLANNING_PROVIDER = 'PLANNING_MUTATE_FORCE_PLANNING_PROVIDER'

export const PLANNING_MUTATE_DISTANCE_LAYER = 'PLANNING_MUTATE_DISTANCE_LAYER'

// Actions
export const PLANNING_SEARCH_BY_TITLE_NUMBERS = 'PLANNING_SEARCH_BY_TITLE_NUMBERS'

export const PLANNING_V2_SEARCH_BY_TITLE_NUMBERS = 'PLANNING_V2_SEARCH_BY_TITLE_NUMBERS'
export const PLANNING_CLEAR = 'PLANNING_CLEAR'
export const PLANNING_HIDE_LAYERS = 'PLANNING_HIDE_LAYERS'
export const PLANNING_UPDATE_FILTERED_RESULTS = 'PLANNING_UPDATE_FILTERED_RESULTS'
export const PLANNING_RETRY_CURRENT_SEARCH = 'PLANNING_RETRY_CURRENT_SEARCH'
export const PLANNING_EXPORT_CSV = 'PLANNING_EXPORT_CSV'
