<template>
    <div v-show="show"
         class="matter-searches-define-polygon-step d-flex flex-column"
         :class="{
             '--show': show,
         }">
        <matter-searches-titles-list class="matter-searches-define-polygon-step__title-list"
                                     :collapsed="collapsed"
                                     :area="area"
                                     :filtered-titles="filteredTitles"
                                     :groups="matterContents?.groups"
                                     :disabled="titlesListDisabled"
                                     :loading="loading"
                                     :is-drawing="sketch.showSketchModeOptions"
                                     :has-features="hasFeatures"
                                     @titles-changed="onTitlesChanged"
                                     @title-click="onTitleClick"
                                     @draw-boundary-click="onDrawBoundaryClick" />
        <matter-searches-map v-if="filteredTitles?.length"
                             :key="mapKey"
                             class="matter-searches-define-polygon-step__map"
                             :active-title-number="activeTitleNumber"
                             :filtered-titles="filteredTitles"
                             :loading="loading"
                             @update-area="onUpdateArea"
                             @update-geojson="onUpdateGeojson"
                             @update-title="onUpdateTitle"
                             @update-titles="onUpdateTitles"
                             @map-initialised="onMapInitialised" />
        <sketches-tool-actions v-if="sketch.showSketchModeOptions"
                               class="matter-searches-define-polygon-step__tool-actions"
                               :can-clear="canClear"
                               :can-undo="canUndo"
                               :is-drawing="sketch.isDrawing"
                               :has-features="hasFeatures"
                               :sketch="sketch.currentSketch"
                               hide-style-select
                               hide-sketches-snapping
                               :snapping-mode="sketch.snappingMode"
                               :key-map="keyMap"
                               @action="onSketchesToolAction">
            <div v-if="hintText"
                 v-dompurify-html="hintText"
                 class="sketches-tool-panel__hint-text" />
        </sketches-tool-actions>
    </div>
</template>

<script setup lang="ts">
    import OlMap from 'ol/Map'
    import {
        computed,
        nextTick,
        ref,
        watch,
        WritableComputedRef,
    } from 'vue'
    import { useRoute,
             useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import MatterSearchesMap from '@/components/matter-searches/matter-searches-map.vue'
    import MatterSearchesTitlesList from '@/components/matter-searches/matter-searches-titles-list.vue'
    import SketchesToolActions from '@/components/sketches/sketches-tool-actions.vue'
    import { Route } from '@/enums/route.enum'
    import { getStepName,
             SearchesSteps } from '@/enums/searches.enum'
    import {
        SketchMode,
        ToolActionType,
    } from '@/enums/sketches-enums'
    import i18n from '@/plugins/i18n'
    import { SearchesLayer } from '@/store/modules/map/layers/searches-layer'
    import { BoundaryLayer } from '@/store/modules/map/layers/title-boundary-layer/boundary-layer'
    import { MATTER_GET_MATTER_CONTENTS } from '@/store/modules/matter/types'
    import {
        ISearchesState,
    } from '@/store/modules/searches'
    import {
        SEARCHES_CLEAR_CURRENT_SKETCH,
        SEARCHES_GET_AREA_SQM,
        SEARCHES_GET_FILTERED_TITLE_NUMBERS,
        SEARCHES_GET_LOADING,
        SEARCHES_GET_MAP_KEY,
        SEARCHES_GET_SELECTED_TITLE_NUMBERS,
        SEARCHES_GET_SKETCH_OPTIONS,
        SEARCHES_MUTATE_BOUNDARY_LAYER,
        SEARCHES_MUTATE_FILTERED_TITLE_NUMBER,
        SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS,
        SEARCHES_MUTATE_GEOJSON,
        SEARCHES_MUTATE_LOADING,
        SEARCHES_MUTATE_MAP,
        SEARCHES_MUTATE_SEARCHES_LAYER,
        SEARCHES_SET_FILTERED_TITLES,
        SEARCHES_SET_SNAPPING_MODE,
        SEARCHES_SHOW_DRAW_BOUNDARY,
        SEARCHES_UNDO,
        SEARCHES_UPDATE_AREA_SQM,
    } from '@/store/modules/searches/types'

    const props = defineProps({
        show: {
            type: Boolean,
            default: false,
        },
    })
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const mapKey = computed<string>(() => {
        return store.getters[SEARCHES_GET_MAP_KEY]
    })

    const filteredTitles = computed<any[]>(() => store.getters[SEARCHES_GET_FILTERED_TITLE_NUMBERS])
    const area = computed<string>(() => store.getters[SEARCHES_GET_AREA_SQM])
    const matterContents = computed(() => store.getters[MATTER_GET_MATTER_CONTENTS])
    const selectedTitleNumbers = computed<string[]>(() => store.getters[SEARCHES_GET_SELECTED_TITLE_NUMBERS])
    const sketch = computed<ISearchesState['sketch']>(() => store.getters[SEARCHES_GET_SKETCH_OPTIONS])
    const canClear = computed(() => sketch.value.drawLayer?.getSource().getFeatures().length > 0 || sketch.value.isDrawing || sketch.value.currentSketch !== null)
    const hasFeatures = computed(() => sketch.value.drawLayer?.getSource()?.getFeatures()?.length > 0)
    const canUndo = computed(() => {
        const inProgressSketchFeatures = sketch.value.drawInteraction?.getOverlay().getSource().getFeatures() ?? []
        const hasGeometryChangeHistory = sketch.value.history.currentSketchGeoJson.length > 0 &&
            sketch.value.history.currentSketchGeoJsonIndex > 0
        return inProgressSketchFeatures.length > 1 || hasGeometryChangeHistory
    })
    const titlesListDisabled = computed(() =>
        loading.value ||
        sketch.value.showSketchModeOptions ||
        sketch.value.currentSketch !== null &&
        sketch.value.currentSketch?.features?.length > 0)

    const currentMatterSelectedTitles = computed(() => store.state.matter.currentMatter.selectedTitles)

    const loading: WritableComputedRef<boolean> = computed({
        get(): boolean {
            return store.getters[SEARCHES_GET_LOADING]
        },
        set(loading: boolean): void {
            store.commit(SEARCHES_MUTATE_LOADING, loading)
        },
    })

    const keyMap = computed(() => {
        return {
            'ctrl+z': onUndoClick,
            'command+z': onUndoClick,
            esc: onDrawBoundaryClick,
            enter: () => {
                sketch.value.drawInteraction?.finishDrawing()
            },
        }
    })

    const hintText = computed(() => {
        if (sketch.value.currentMode === SketchMode.None || !sketch.value.currentSketch) {
            return null
        }
        let i18nKey = ''
        switch (sketch.value.currentMode) {
            case SketchMode.Area:
                i18nKey = 'sketches.areaHint'
                break
        }
        return i18n.global.t(i18nKey, { sketchName: sketch.value.currentSketch?.value?.name })
    })

    const intialising = ref<boolean>(true)
    const collapsed = ref<boolean>(false)
    const activeTitleNumber = ref<string>(selectedTitleNumbers.value ? selectedTitleNumbers.value[0] : undefined)

    const onUpdateArea = () => {
        store.dispatch(SEARCHES_UPDATE_AREA_SQM)
    }

    const onUpdateGeojson = (geojson: any) => {
        store.commit(SEARCHES_MUTATE_GEOJSON, geojson)
    }

    const onUpdateTitle = (title: any) => {
        store.commit(SEARCHES_MUTATE_FILTERED_TITLE_NUMBER, title)
    }

    const onUpdateTitles = (titles: any[]) => {
        store.commit(SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS, titles)
    }

    const onMapInitialised = (args: {
        targetMap: OlMap,
        searchesLayer: SearchesLayer
        boundaryLayer: BoundaryLayer
    }) => {
        store.commit(SEARCHES_MUTATE_MAP, args.targetMap)
        store.commit(SEARCHES_MUTATE_BOUNDARY_LAYER, args.boundaryLayer)
        store.commit(SEARCHES_MUTATE_SEARCHES_LAYER, args.searchesLayer)
        // small timeout to cover the map initialising once all boundaries loaded
        setTimeout(() => {
            loading.value = false
        }, 500)
    }

    const updateRoute = () => {
        if (!filteredTitles.value.length) {
            return
        }
        // replace the route with the new title number
        const selectedTitles = filteredTitles.value.filter((title) => title.selected)
        const titleNumbers = selectedTitles.map((title) => `${ title.titleNumber }`).join(',')
        if (route.params.args === titleNumbers) {
            return
        }
        router.replace({
            name: Route.MatterSearchesCreate,
            params: {
                step: getStepName(SearchesSteps.DefinePolygon),
                args: titleNumbers,
            },
        })
    }

    const onTitlesChanged = (titles: any[]) => {
        if (intialising.value) {
            return
        }
        store.commit(SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS, titles)
        updateRoute()
    }

    const onTitleClick = (titleNumber: string) => {
        const title = filteredTitles.value.find(t => t.titleNumber === titleNumber)

        // if the titles list is disabled, don't allow the user to select a title
        if (!titlesListDisabled.value) {
            title.selected = !title.selected
            updateRoute()
            store.commit(SEARCHES_MUTATE_FILTERED_TITLE_NUMBER, title)
        }

        // set the maps active title number to trigger the title highlight
        activeTitleNumber.value =  null
        setTimeout(() => {
            activeTitleNumber.value = titleNumber
        }, 100)
    }

    const onSketchesToolAction = async (action: ToolActionType, data: any) => {
        switch (action) {
            case ToolActionType.Done:
                onDrawBoundaryClick()
                break
            case ToolActionType.Clear:
                await store.dispatch(SEARCHES_CLEAR_CURRENT_SKETCH)
                break
            case ToolActionType.SnapTo:
                store.dispatch(SEARCHES_SET_SNAPPING_MODE, data)
                break
            case ToolActionType.Undo:
                await onUndoClick()
                break
        }
    }

    const onUndoClick = async () => {
        if (canUndo.value) {
            await store.dispatch(SEARCHES_UNDO)
            await nextTick()
        }
    }

    const onDrawBoundaryClick = () => {
        store.dispatch(SEARCHES_SHOW_DRAW_BOUNDARY)
    }

    const initialise = async () => {
        if (!props.show) {
            return
        }
        loading.value = true
    }

    watch(() => mapKey.value, () => {
        initialise()
    })

    watch(() => currentMatterSelectedTitles.value, async (val) => {
        initialise()
        try {
            await store.dispatch(SEARCHES_SET_FILTERED_TITLES, val)
        } finally {
            intialising.value = false
        }
    }, {
        immediate: true,
        deep: true,
    })

</script>

<style scoped lang="scss">
    @import './matter-searches-define-polygon-step';
</style>
