<template>
    <ow-modal v-model="promptDelete.show"
              content-class="dialog-group"
              :title="$t('matter.prompts.deleteGroup.title')"
              persistent>
        <div class="dialog-group__content">
            <span>
                {{ $t('matter.prompts.deleteGroup.body') }}
            </span>
            <span class="body-highlight">
                {{ selectedGroupName }}
            </span>
            <ow-checkbox id="dialog-group-checkbox"
                         v-model="deleteTitles"
                         data-test="matter-group-dialogs-checkbox-remove-titles-within-group"
                         class="dialog-group__content--checkbox"
                         :label="$t('matter.prompts.deleteGroup.query')" />
        </div>
        <template #actions>
            <div class="dialog-group__actions">
                <ow-button data-test="matter-group-dialogs-button-cancel"
                           @click="handleCancel">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button data-test="matter-group-dialogs-button-confirm-delete-group"
                           is-danger
                           @click="handleDelete">
                    {{ $t('action.delete') }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script lang="ts">
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import { IState } from '@/interfaces/store/state.interface'
    import { MATTER_REMOVE_GROUP } from '@/store/modules/matter/types'

    export default {
        name: 'DialogGroupDelete',
        components: {
            OwButton,
            OwCheckbox,
            OwModal,
        },
        data() {
            return {
                deleteTitles: false,
            }
        },
        computed: {
            ...mapState({
                promptDelete: (state: IState) => state.matter.prompts.deleteGroup,
            }),
            selectedGroupName() {
                return this.promptDelete.group?.name ?? ''
            },
        },
        methods: {
            ...mapActions({
                removeGroup: MATTER_REMOVE_GROUP,
            }),

            async handleDelete() {
                this.promptDelete.show = false
                await this.removeGroup({
                    group: this.promptDelete.group,
                    removeTitles: this.deleteTitles,
                })
                this.deleteTitles = false
            },

            handleCancel() {
                this.promptDelete.show = false
                this.deleteTitles = false
            },
        },
    }
</script>

<style lang="scss">
    @import './dialog-group';
</style>
