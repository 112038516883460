import {
    getDocumentManagerUri,
    getReportingApiUri,
} from '@/utils/environment.utils'
import {
    IExtractExportRequestBody,
    IReportExportRequestBody,
} from '@/interfaces/report-request.interface'
import { format } from '@/utils/date-utils'
import httpClient from '@/api/http-client'

export default class ReportApi {
    private static controller: AbortController

    /**
     * Get a list of export options.
     * @returns {Promise<T | *>}
     */
    static getExportOptions() {
        const requestUrl = `${ getReportingApiUri() }/export-options`
        return httpClient.get(requestUrl)
    }

    /**
     * Get a Simple Title Report DOCX for the given title numbers.
     * @param {IExtractExportRequestBody} request
     * @returns {Promise<T | *>}
     */
    static generateDOCXAndDownload({ titleNumbers }: IExtractExportRequestBody) {
        this.cancelLastRequest()
        this.controller = new AbortController()

        const resultUrl = `${ getDocumentManagerUri() }/Report/TitleRegister/GenerateReportDocx`
        return httpClient
            .post(resultUrl, titleNumbers, {
                signal: this.controller.signal,
                responseType: 'blob',
            })
    }

    /**
     * Get a Title Report DOCX for the given matter.
     * @param {IReportExportRequestBody} request
     * @returns {Promise<T | *>}
     */
    static generateReportDOCXAndDownload(request: IReportExportRequestBody) {
        this.cancelLastRequest()
        this.controller = new AbortController()

        const requestUrl = `${ getReportingApiUri() }/matters/${ request.matterId }/export`
        const requestBody = {
            created_at: format(Date(), 'yyyy-MM-dd'),
            template: request.template,
            disabled_features: request.disabledFeatures ?? [],
            inputs: {
                title_numbers: request.titleNumbers,
                minimum_unexpired_lease_term_years: 80,
            },
        }

        return httpClient
            .post(requestUrl, requestBody, {
                signal: this.controller.signal,
                responseType: 'blob',
            })
    }

    /**
     * Abort the last request.
     * @returns {void}
     */
    static cancelLastRequest() {
        if (this.controller) {
            this.controller.abort()
        }
    }
}
