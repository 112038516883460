<template>
    <div :class="{'is-analysis-page': page === 'Analysis'}"
         class="table-page-loading-skeleton">
        <div class="table-page-loading-skeleton__header-wrapper">
            <div class="table-page-loading-skeleton__header">
                <h1 v-if="page === 'Analysis'"
                    class="content__heading">
                    <ow-icon-tam />
                    Analysis
                </h1>
                <h1 v-else
                    class="content__heading">
                    <v-icon>$documents.document</v-icon>
                    Documents
                </h1>
                <div class="actions d-flex align-center px-3 gc-3">
                    <ow-loading-skeleton height="44px"
                                         width="52"
                                         border-radius />
                    <ow-loading-skeleton height="44px"
                                         width="52"
                                         border-radius />
                    <ow-loading-skeleton height="44px"
                                         width="94"
                                         border-radius />
                </div>
            </div>
        </div>

        <div class="table-page-loading-skeleton__quick-actions px-3 gc-3 py-3">
            <ow-loading-skeleton v-for="i in 3"
                                 :key="i"
                                 class="table-page-loading-skeleton__quick-actions--card"
                                 height="80"
                                 border-radius
                                 width="344" />
        </div>

        <div class="table-page-loading-skeleton__table-wrapper px-3">
            <div class="table-page-loading-skeleton__table">
                <div class="table-page-loading-skeleton__filters d-flex justify-space-between pt-3">
                    <ow-loading-skeleton border-radius
                                         height="32px"
                                         width="200" />
                    <div class="d-flex gc-3">
                        <ow-loading-skeleton v-for="i in 4"
                                             :key="i"
                                             border-radius
                                             height="32px"
                                             width="100" />
                    </div>
                </div>
                <div class="table-page-loading-skeleton__table-items d-flex flex-row gc-3 mt-3">
                    <ow-loading-skeleton v-for="i in 5"
                                         :key="i"
                                         height="32px"
                                         border-radius />
                </div>
                <div class="table-page-loading-skeleton__table-items d-flex flex-column gr-3 mt-3">
                    <ow-loading-skeleton v-for="i in 10"
                                         :key="i"
                                         height="32px"
                                         border-radius />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import OwIconTam from '@/components/core/icons/ow-icon-tam.vue'
    import owLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'

    export default {
        name: 'TablePageLoadingSkeleton',

        components: {
            OwIconTam,
            owLoadingSkeleton,
        },

        props: {
            page: {
                type: String,
                required: false,
                default: 'Documents',
            },
        },

    }
</script>

<style lang="scss">
    @import './table-page-loading-skeleton.scss';
</style>
