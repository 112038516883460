<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 15 16"
                  desc="Key icon">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 .67h9.33a2 2 0 0 1 2 2V12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2.67c0-1.1.9-2 2-2Zm-2.67 2C.33 1.19 1.53 0 3 0h9.33C13.81 0 15 1.2 15 2.67v11c0 1.47-1.2 2.33-2.67 2.33H3c-1.47 0-2.67-.86-2.67-2.33v-11Z"
              fill="#CFD2D5" />
        <text x="50%"
              y="54%"
              class="caption-highlight"
              dominant-baseline="middle"
              text-anchor="middle"
              fill="#B5B9BD">
            {{ label }}
        </text>
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconDoc',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '15px',
            },
            height: {
                type: String,
                required: false,
                default: '16px',
            },
            label: {
                type: String,
                required: true,
            },
        },
    }
</script>
