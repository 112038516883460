<template>
    <v-navigation-drawer v-model="show"
                         :absolute="true"
                         class="title-analysis-column-selection"
                         data-test="title-analysis-column-selection"
                         location="right"
                         :temporary="true"
                         width="325">
        <div class="d-flex flex-column">
            <!-- Header -->
            <div class="d-flex flex-row align-center justify-space-between">
                <h3>Column Options</h3>
                <v-btn data-test="title-analysis-columns-close"
                       data-track="TAM - Close column selection panel"
                       icon
                       size="small"
                       variant="text"
                       @click="show = false">
                    <v-icon>$close</v-icon>
                </v-btn>
            </div>

            <!-- Filter -->
            <div class="layout row">
                <v-text-field ref="filterInput"
                              v-model="textFilter"
                              :clearable="true"
                              data-test="title-analysis-column-filter"
                              data-track="TAM - Filter columns"
                              hide-details
                              label="Search available columns"
                              prepend-inner-icon="$search"
                              variant="solo" />
            </div>
            <!-- Selection area -->
            <div class="d-flex flex-column column-selection-container">
                <div v-for="group in filteredColumnGroups"
                     :key="group.id"
                     class="d-flex flex-column justify-start align-start column-selection-group">
                    <div class="d-flex flex-row justify-space-between column-selection-group-header">
                        <h4>{{ group.label }}</h4>
                        <v-spacer />
                        <div class="column-selection-group-header-actions">
                            <v-btn :disabled="allColumnsInGroupAreSelected(group)"
                                   color="primary"
                                   data-test="title-analysis-column-group-select-all"
                                   data-track="TAM - Select all columns in group"
                                   variant="text"
                                   @click="selectAllColumnsInGroup(group)">
                                All
                            </v-btn>
                            <span>/</span>
                            <v-btn :disabled="allColumnsInGroupAreDeselected(group)"
                                   color="primary"
                                   data-track="TAM - Select no columns in group"
                                   variant="text"
                                   @click="deSelectAllColumnsInGroup(group)">
                                None
                            </v-btn>
                        </div>
                    </div>
                    <v-checkbox v-for="(column, index) in getFilteredColumnsForGroup(group)"
                                :key="index"
                                v-model="column.visible"
                                :data-column-selection-field="column.field"
                                :disabled="column.mandatory"
                                :label="column.text"
                                class="title-analysis-column-selection-checkbox"
                                color="primary"
                                data-test="tam-column-selection-checkbox"
                                data-track="TAM - select column"
                                hide-details />
                </div>

                <!-- No filter results text-->
                <p v-if="filteredColumnGroups.length === 0"
                   class="no-filter-results ma-3">
                    No columns found for '{{ textFilter }}'
                </p>

                <!-- Feedback link-->
                <p class="column-selection-feedback">
                    Can't find what you're looking for? Apply the "All register entries" template or
                    <a :href="feedbackURL"
                       target="_blank">Provide feedback</a>
                </p>
            </div>

            <!-- Actions -->
            <div class="d-flex flex-row justify-space-between column-selection-actions mt-4">
                <v-btn :disabled="!hasChanges || !isValidColumnSelection"
                       color="primary"
                       data-test="title-analysis-columns-update"
                       data-track="TAM - Update columns"
                       size="large"
                       @click="updateColumns()">
                    Update
                </v-btn>
                <v-btn color="primary"
                       data-test="title-analysis-columns-cancel"
                       data-track="TAM - Close column selection panel"
                       size="large"
                       variant="outlined"
                       @click="show = false">
                    Cancel
                </v-btn>
            </div>
        </div>
    </v-navigation-drawer>
</template>
<script>
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import { getColumnGroups } from '@/components/title-analysis/configuration/gridConfig.js'
    import { TitleAnalysisFeedbackURL } from '@/consts/urls'
    import {
        ANALYSIS_MUTATE_SELECTED_COLUMNS,
        ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION,
    } from '@/store/modules/title-analysis/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'TitleAnalysisColumnSelection',

        data() {
            return {
                feedbackURL: TitleAnalysisFeedbackURL,
                data: [],
                textFilter: null,
            }
        },

        computed: {
            ...mapState({
                getShowColumnSelection: state => state.titleAnalysis.showColumnSelection,
                currentSelectedColumns: state => state.titleAnalysis.selectedColumns,
                gridConfig: state => state.titleAnalysis.initialGridConfig,
                gridInstance: state => state.titleAnalysis.gridInstance,
            }),

            show: {
                get() {
                    return this.getShowColumnSelection
                },
                set(val) {
                    if (val) {
                        this.refresh()

                        // Focus on the filter after a moment, otherwise it changes the positioning of the whole dialog.
                        setTimeout(() => {
                            this.$refs.filterInput.focus()
                        }, 500)
                    }
                    this.showColumnSelection(val)
                },
            },

            filteredColumnGroups() {
                if (isNullOrWhitespace(this.textFilter)) {
                    return this.data
                }
                return this.data.filter(group => {
                    if (group.label.toUpperCase().includes(this.textFilter.toUpperCase())) {
                        return true
                    }
                    const filteredColumns = this.getFilteredColumnsForGroup(group)
                    return filteredColumns.length > 0
                })
            },

            newColumnSelection() {
                return this.data.map(group => group.columns.filter(column => column.visible)).flat()
            },

            hasChanges() {
                return this.currentSelectedColumns.map(column => column.field).toString() !==
                    this.newColumnSelection.map(column => column.field).toString()
            },

            isValidColumnSelection() {
                return this.newColumnSelection.length > 0
            },
        },

        watch: {
            gridConfig() {
                this.refresh()
            },

            currentSelectedColumns() {
                this.refresh()
            },

            textFilter() {
                this.refresh()
            },
        },

        mounted() {
            this.showColumnSelection(false)
            this.refresh()
        },

        methods: {
            ...mapMutations({
                showColumnSelection: ANALYSIS_MUTATE_SHOW_COLUMN_SELECTION,
                mutateSelectedColumns: ANALYSIS_MUTATE_SELECTED_COLUMNS,
            }),

            refresh() {
                if (this.gridInstance.columns) {
                    // Builds a local data structure representing the currently selected columns within groups
                    const selectedFields = this.gridInstance.columns.allRecords
                        .filter(c => !c.hidden)
                        .map(c => c.field)

                    const columnGroups = getColumnGroups()

                    this.data = columnGroups.map(group => {
                        return {
                            ...group,
                            columns: this.gridConfig.columns
                                .filter(c => c.group === group.id)
                                .filter(c => c.excludeFromColumnSelectionPanel !== true)
                                .map(column => {
                                    return {
                                        ...column,
                                        visible: selectedFields.includes(column.field),
                                    }
                                }),
                        }
                    }).filter(group => group.columns.length > 0)
                }
            },

            getFilteredColumnsForGroup(group) {
                if (!isNullOrWhitespace(this.textFilter)) {
                    return group.columns.filter(column => column.text.toUpperCase()
                        .includes(this.textFilter.toUpperCase()))
                }
                return group.columns
            },

            allColumnsInGroupAreSelected(group) {
                return !this.getFilteredColumnsForGroup(group).some(c => !c.visible)
            },

            allColumnsInGroupAreDeselected(group) {
                return !this.getFilteredColumnsForGroup(group).some(c => c.visible && !c.mandatory)
            },

            selectAllColumnsInGroup(group) {
                this.getFilteredColumnsForGroup(group).forEach(c => {
                    c.visible = true
                })
            },

            deSelectAllColumnsInGroup(group) {
                this.getFilteredColumnsForGroup(group).forEach(c => {
                    if (!c.mandatory) {
                        c.visible = false
                    }
                })
            },

            updateColumns() {
                const newSelectedFields = this.newColumnSelection.map(c => c.field)
                this.mutateSelectedColumns(this.gridConfig.columns.filter(c => {
                    return newSelectedFields.includes(c.field)
                }))
                this.show = false
            },
        },
    }
</script>
<style lang="scss">
    @import 'title-analysis-column-selection.scss';
</style>
