export enum TitlePanelTabName {
    Summary = 'summary',
    Register = 'register',
    CopiesFiled = 'copies-filed',
    Leases = 'leases',
    DayList = 'daylist',
    Planning = 'planning',
    FindNearby = 'nearby',
    Epc = 'epc',
}
