<template>
    <title-panel-card data-section="registerAndPlan">
        <section class="py-3">
            <order-documents-card v-bind="$props"
                                  @view-document="$emit('view-document')" />
        </section>
    </title-panel-card>
</template>

<script lang="ts" setup>
    import OrderDocumentsCard from '@/components/title-panel/v2/cards/order-documents-card/order-documents-card.vue'
    import TitlePanelCard from "@/components/title-panel/v2/cards/title-panel-card.vue"
    import { ITitlePanelTabController } from "@/composables/use-title-panel"
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"

    const props = defineProps<{
        selectedTitle: any,
        selectedTitleNumber?: string,
        userHasVtsCharge?: boolean,
        currentMatterId?: number,
        isOrderingAllowed: boolean,
        orderWithCheckout?: boolean,
        officialCopies: any,
        noPadding?: boolean,
        hideTitlePlan?: boolean,
        hideRegister?: boolean,
        isLoading: boolean,
        tabController: ITitlePanelTabController
    }>()

    props.tabController.addTabSection(TitlePanelTabName.Summary, {
        id: 'registerAndPlan',
        order: 1000,
    })

    defineEmits(['view-document'])
</script>

<style scoped>

</style>
