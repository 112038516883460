<template>
    <div class="recent-matters-list-loading-skeleton">
        <ow-loading-skeleton height="60px"
                             margin-bottom="8" />
        <ow-loading-skeleton height="60px"
                             margin-bottom="8" />
        <ow-loading-skeleton height="60px"
                             margin-bottom="8" />
    </div>
</template>

<script lang="ts">
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'

    export default {
        components: {
            OwLoadingSkeleton,
        },
    }
</script>
