import {
    NOTIFICATIONS_DISPLAY_NOTIFICATION,
    NOTIFICATIONS_MUTATE_ADD_NOTIFICATION,
} from '@/store/modules/notifications/types'
import { INotification } from '@/store/modules/notifications/types/notification.interface'

export default {
    [NOTIFICATIONS_DISPLAY_NOTIFICATION]({ commit }, notification: INotification): void {
        commit(NOTIFICATIONS_MUTATE_ADD_NOTIFICATION, notification)
    },
}
