<template>
    <div id="admin-organisations-page">
        <div class="fixed-header">
            <header>
                <h1 class="content__heading">
                    Organisations
                </h1>
            </header>
        </div>
        <app-organisations />
    </div>
</template>

<script>
    import Organisations from '@/components/admin/organisations/admin-organisations.vue'

    export default {
        name: 'AdminOrgansations',

        components: {
            appOrganisations: Organisations,
        },
    }
</script>

<style lang="scss" scoped>
    @import './admin-organisations';
</style>
