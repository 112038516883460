import httpClient from '@/api/http-client'
import { IBasicTitleInformation } from '@/interfaces/basic-title-information.interface'
import qs from 'qs'

export default class TitlesApi {
    static controller: AbortController = null

    static END_POINT = 'titles'

    static async getInformationByTitleNumbers(titleNumbers: string[], abortController?: AbortController): Promise<IBasicTitleInformation[]> {
        if (!abortController) {
            if (this.controller) {
                this.controller.abort()
            }

            this.controller = new AbortController()
            abortController = this.controller
        }
        const uri = `${ this.END_POINT }/information`
        const data = qs.stringify({ titleNumbers })
        const response = await httpClient.post(uri, data)
        // TODO: why does error handler return undefined here when the api returns an error?
        return response == undefined ? [] : response.data
    }

    /**
     * Aborts all ongoing requests
     */
    static abortAllRequests() {
        if (this.controller) {
            this.controller.abort()
        }
    }
}
