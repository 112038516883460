<template>
    <ow-base-icon :height="height"
                  :width="width"
                  desc="Padlock icon"
                  viewBox="0 0 80 80">
        <g clip-path="url(#clip0_1404_55014)">
            <path d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                  fill="#84DBFF" />
            <path d="M32.063 32.567V23.7481C32.063 19.4016 35.5906 15.8111 40 15.8111C44.4095 15.8111 47.937 19.3386 47.937 23.7481V32.567H52.2835V23.7481C52.2835 16.9449 46.7402 11.4646 40 11.4646C33.2599 11.4646 27.7166 17.0079 27.7166 23.7481V32.567H32.063Z"
                  fill="#324A5E" />
            <path d="M56.3778 31.811H23.6219C22.6771 31.811 21.8582 32.5669 21.8582 33.5748V61.1024C21.8582 62.0473 22.6141 62.8662 23.6219 62.8662H56.3778C57.3227 62.8662 58.1416 62.1102 58.1416 61.1024V33.5118C58.1416 32.5669 57.3227 31.811 56.3778 31.811Z"
                  fill="white" />
            <path d="M53.4804 36.2834H26.5198V58.3307H53.4804V36.2834Z"
                  fill="#E6E9EE" />
            <path d="M42.9606 45.2913C42.9606 43.6535 41.6377 42.3307 39.9999 42.3307C38.3621 42.3307 37.0393 43.6535 37.0393 45.2913C37.0393 46.3622 37.6062 47.2441 38.4251 47.811L37.7322 52.2834H42.2047L41.5117 47.811C42.3936 47.3071 42.9606 46.3622 42.9606 45.2913Z"
                  fill="#324A5E" />
        </g>
        <defs>
            <clipPath id="clip0_1404_55014">
                <rect fill="white"
                      height="80"
                      width="80" />
            </clipPath>
        </defs>
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconPadlock',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '80px',
            },
            height: {
                type: String,
                required: false,
                default: '80px',
            },
        },
    }
</script>
