import { getAppUri } from '@/utils/environment.utils'
import { pad } from '@/utils/string-utils'

/**
 * Returns a link to an assumed Companies House page given a company registration number.
 * @param {number} number
 */
export const getCompaniesHouseLinkFromCompanyRegistrationNumber = (number) => {
    const baseUrl = 'https://find-and-update.company-information.service.gov.uk'
    if (!number) {
        return baseUrl
    }
    return `${ baseUrl }/company/${ pad(number, 8) }`
}

/**
 * Returns a link to the map page for the chosen matter
 * @param matterId {number}
 * @returns {`${string}/matters/${string}/map`}
 */
export const getLinkToMatter = (matterId) => {
    return `${ getAppUri() }/matters/${ matterId }/map`
}
