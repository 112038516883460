<template>
    <div class="map-layers-legend">
        <div v-for="item in items"
             :key="item.label"
             class="map-layers-legend__item">
            <ow-checkbox v-if="checkbox"
                         :id="`map-legend-${ item.label.replaceAll(' ', '-').toLowerCase() }`"
                         v-model="item.selected"
                         :disabled="item.disabled" />
            <div class="map-layers-legend__item--color"
                 :class="{ circle, square }"
                 :style="{ background: item.color }" />
            <label :for="`map-legend-${ item.label.replaceAll(' ', '-').toLowerCase() }`"
                   class="map-layers-legend__item--label caption-regular"
                   @click="toggleCheckbox">
                {{ item.label }}
            </label>
        </div>
        <a v-if="infoUrl"
           class="map-layers-legend__info caption-regular">
            <v-icon class="map-layers-legend__info--icon"
                    size="small">
                $info
            </v-icon>
            {{ $t('action.learnMoreAboutMapLayer') }}
        </a>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import { IDisplayLayer } from '@/consts/map'

    export default {
        name: 'MapLayersLegend',

        components: {
            OwCheckbox,
        },

        props: {
            modelValue: {
                type: Array as PropType<IDisplayLayer>,
                required: true,
            },
            infoUrl: {
                type: String,
                required: false,
            },
            circle: {
                type: Boolean,
                default: false,
            },
            square: {
                type: Boolean,
                default: false,
            },
            checkbox: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['update:modelValue'],
        data() {
            return {
                isChecked: false,
            }
        },

        computed: {
            items: {
                get(): IDisplayLayer[] {
                    return this.modelValue
                },
                set(value) {
                    this.$emit('update:modelValue', value)
                },
            },
        },
        methods: {
            toggleCheckbox() {
                this.isChecked = !this.isChecked
            },
        },
    }
</script>

<style lang="scss">
    @import './map-layers-legend';
</style>
