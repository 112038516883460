<template>
    <title-panel-card :title="$t('titlePanel.cards.epc.title')"
                      :title-action="{
                          label: $t('action.exportToExcel'),
                          onClick: download,
                      }"
                      class="epc">
        <template #subtitle>
            <i18n-t class="caption-regular"
                    keypath="titlePanel.cards.epc.subtitle"
                    tag="label">
                <template #link>
                    <a data-track="TITLE-DETAILS-PANEL - EPC - gov.uk link click"
                       href="https://www.gov.uk/find-energy-certificate"
                       rel="noopener noreferrer"
                       target="_blank">gov.uk</a>
                </template>
                <template #length>
                    <span class="caption-highlight">{{ gridItems.length }}</span>
                </template>
            </i18n-t>
        </template>
        <div :class="{
                 'epc-tab-empty': gridItems.length === 0,
                 'epc-tab': gridItems.length > 0,
             }"
             class="gc-8">
            <div v-if="isLoading"
                 class="epc-tab__table--loading"
                 data-test="title-details-panel-loading">
                <ow-loading-logo />
            </div>
            <epc-table v-else
                       v-model="selectedRow"
                       v-model:items="gridItems"
                       v-model:loading="tableLoading"
                       :is-loading="isLoading"
                       class="epc-tab__table"
                       @view-chart="onViewChart" />

            <div v-if="selectedRow"
                 class="epc-tab__chart mt-4">
                <div v-if="tableLoading"
                     class="epc-tab__loading"
                     data-test="title-details-panel-loading">
                    <ow-loading-logo />
                </div>
                <div v-else
                     ref="chartContainerRef">
                    <epc-table-chart :selected-item="selectedRow" />
                    <epc-table-additional :selected-item="selectedRow" />
                </div>
            </div>
        </div>
    </title-panel-card>
</template>

<script lang="ts" setup>
    import {
        nextTick,
        ref,
        watch,
    } from 'vue'

    import OwLoadingLogo from '@/components/core/ow-loading-logo.vue'
    import TitlePanelCard from '@/components/title-panel/v2/cards/title-panel-card.vue'
    import EpcTable from '@/components/title-panel/v2/epc/epc-table.vue'
    import EpcTableAdditional from '@/components/title-panel/v2/epc/epc-table-additional.vue'
    import EpcTableChart from '@/components/title-panel/v2/epc/epc-table-chart.vue'
    import { getExpiryDate } from '@/components/title-panel/v2/epc/util'
    import { useSelectedTitle } from '@/composables/use-selected-title'
    import { EPCSource } from '@/enums/epc.enum'
    import { IEpcData } from '@/interfaces/epc.interface'
    import { exportAsCsv } from '@/utils/csv-utils'
    import { format } from '@/utils/date-utils'

    const { selectedTitleNumber } = useSelectedTitle()

    const tableLoading = ref<boolean>(false)
    const gridItems = ref<any[]>([])
    const selectedRow = ref<any>()
    const chartContainerRef = ref<HTMLElement>()

    const getDataForCsvExport = (tableRows: Array<IEpcData>): Array<Array<string | number>> => {
        return tableRows.map((item: IEpcData) => {
            const formattedAddress = item.address.replace(/,/g, ' ')
            const formattedLodgementDateTime = format(new Date(item.lodgementDateTime), 'dd-MM-yyyy')
            const formattedExpiryDate = getExpiryDate(item.expiryDate)
            const formattedPotentialEnergyBand = item.source !== EPCSource.Domestic ? 'N/A' : item.potentialEnergyBand
            const formattedPotentialEnergyRating = item.source !== EPCSource.Domestic ? 'N/A' : item.potentialEnergyRating

            return [ formattedAddress, item.source, item.energyBand, item.energyRating, formattedPotentialEnergyBand, formattedPotentialEnergyRating, formattedLodgementDateTime, formattedExpiryDate, item.totalFloorArea, item.propertyType, item.uprn ]
        })
    }

    const download = (): Array<Array<string | number>> => {
        const rows = []
        const headers = [ 'EPC Address',
                          'EPC Type',
                          'Energy Rating',
                          'Energy Score',
                          'Potential Energy Rating',
                          'Potential Energy Score',
                          'Issued on',
                          'Expiry date',
                          'Floor Area (m2)',
                          'Property Type', 'UPRN' ]
        const data = getDataForCsvExport(gridItems.value)

        rows.push(headers, ...data)
        const filename = `epc-data-export-${ format(new Date(), 'dd-MM-yyyy') }-${ selectedTitleNumber }.csv`
        exportAsCsv(rows, filename)

        return rows
    }

    watch(() => gridItems.value, () => {
        if (gridItems.value.length > 0) {
            gridItems.value.sort((a, b) => b.address.localeCompare(a.address))
            selectedRow.value = gridItems.value[0]
        }
    })

    const onViewChart = () => {
        nextTick(() => {
            chartContainerRef.value?.scrollIntoView({ behavior: 'smooth' })
        })
    }

    defineProps<{
        isLoading: boolean
    }>()
</script>

<style lang="scss">
    @import './epc-tab.scss';
</style>
