import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

const state = {
    addresses: [],
    sourceData: [],
    derivedData: [],
    loading: false,
    initialGridConfig: null,
    gridInstance: null,
    hasLoadedInitialGridState: false,
    debouncedFetchData: null,
    selectedColumns: [],
    showColumnSelection: false,
    showTemplateSelection: false,
    applyingTemplate: false,
    appliedTemplate: null,
    isFilterBarVisible: false,
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
