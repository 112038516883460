// /enums/step-flow-options.enums.ts

/**
 * Enum for the different steps in the overlay plans step flow.
 */
export enum OverlaySteps {
    DocumentSelection,
    PageSelection,
    OverlayPlan,
}
