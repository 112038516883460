<template>
    <ow-loading-skeleton height="100%" />
</template>

<script lang="ts">
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'

    export default {
        components: {
            OwLoadingSkeleton,
        },
    }
</script>
