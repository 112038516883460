import {
    LayerSnapshotModel,
    MapSnapshotRenderData,
} from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { SnapshotBaseService } from '@/components/snapshots/common/_snapshot-service'
import {
    PageLayoutId,
    PageLayouts,
    SnapshotOrientation,
    SnapshotType,
} from '@/components/snapshots/common/snapshot-enums-consts'
import { SnapshotApiModel } from '@/components/snapshots/common/snapshot-models'
import olMap from 'ol/Map'
import {
    IMapSnapshotLayer,
    IMapSnapshotLayerConstructable,
} from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { layerTypeMapping } from '@/components/snapshots/map-snapshots/map-snapshot-utils'
import {
    reactive,
    ref,
} from 'vue'
import { KeyConfigModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerNames } from '@/consts/map-layers'
import { TitleSelectionLayer } from '@/store/modules/map/layers/title-selection-layer'
import { MatterTitleBoundaryLayerGroup } from '@/store/modules/map/layers/title-boundary-layer/layer-group'
import i18n from '@/plugins/i18n'

/**
 * Service controlling the creation and rendering of 'site plan' snapshots.
 * The service stores the current snapshot configuration which is manipulated by the UI components.
 */
export class MapSnapshotService extends SnapshotBaseService {
    public keyConfig: KeyConfigModel
    public targetMap: olMap

    // A user may change the labels for the key items, but they may get added again
    // as the user pans the map; this cache is used to store the user's labels and re-apply them as needed.
    public keyConfigLabelCache: Map<string, string> = new Map<string, string>()

    // A user may remove some items from the key, but they may get added again, in which case, ignore them.
    public ignoreKeyItemIds: Array<string> = []

    constructor(config: SnapshotApiModel) {
        super(config)
        this.snapshotModel.renderData = reactive(this.parseRenderDataJson(config.renderDataJson))
        this.keyConfig = reactive({
            title: null,
            items: [],
            position: {
                x: 0,
                y: 0,
            },
        })
        this.keyConfigLabelCache = new Map<string, string>()

        // TODO: Vue3 upgrade - Avoid using Array as root value for reactive() as it cannot be tracked in watch() or watchEffect(). Use ref() instead. This is a Vue-2-only limitation.
        this.ignoreKeyItemIds = ref([]).value
    }

    public setTargetMap(targetMap: olMap) {
        this.targetMap = targetMap
    }

    private parseRenderDataJson(renderDataJson: string): MapSnapshotRenderData {
        return JSON.parse(renderDataJson) as MapSnapshotRenderData
    }

    static getRenderDataJson(data: MapSnapshotRenderData): string {
        return JSON.stringify(data)
    }

    /**
     * Creates an API request for a new snapshot.
     */
    public getSnapshotApiRequest(): SnapshotApiModel {
        return {
            configId: this.snapshotModel.configId,
            configVersion: this.snapshotModel.configVersion,
            type: SnapshotType.Siteplan,
            renderDataJson: MapSnapshotService.getRenderDataJson(this.snapshotModel.renderData as MapSnapshotRenderData),
        }
    }

    /**
     * Creates a new snapshot model for a map state.
     * @param targetMap an OpenLayers map.
     * @param matterId a matter ID to associate with the snapshot.
     */
    public static createNewSnapshotApiModel(targetMap: olMap, matterId: number) {
        const useMinimumScreenSizeLayout = PageLayouts[PageLayoutId.HD]
        const mapElement = targetMap.getTargetElement()
        const useWidth = mapElement?.clientWidth ?? useMinimumScreenSizeLayout.width
        const useHeight = mapElement?.clientHeight ?? useMinimumScreenSizeLayout.height
        let layoutId = PageLayoutId.Custom
        // (Minimum layout size is based on portrait orientation.)
        if (useWidth < useMinimumScreenSizeLayout.height || useHeight < useMinimumScreenSizeLayout.width) {
            layoutId = PageLayoutId.HD
        }

        const renderData:MapSnapshotRenderData = {
            layoutId,
            orientation: useHeight > useWidth ? SnapshotOrientation.Portrait : SnapshotOrientation.Landscape,
            customWidthPx: useWidth,
            customHeightPx: useHeight,
            mapCentre: targetMap.getView().getCenter(),
            extent: targetMap.getView().calculateExtent(targetMap.getSize()),
            layers: MapSnapshotService.getSnapshotLayers(targetMap),
            showTitle: false,
            showSubtitle: false,
            title: null,
            subtitle: null,
            includeScaleBar: true,
            includeToScale: null,
            includeKey: false,
        }
        return {
            configId: 'current', // Placeholder until this is actually handled by the API.
            configVersion: '1.0',
            type: SnapshotType.Siteplan,
            renderDataJson: this.getRenderDataJson(renderData),
            matterId,
        }
    }

    /**
     * Gets the layers configuration to be rendered in a snapshot.
     * @param targetMap the map to get the layers from.
     */
    public static getSnapshotLayers(targetMap: olMap): Array<LayerSnapshotModel> {
        // Get OL map layers, and for those with an owLayer property, get the layer config
        let layers: Array<IMapSnapshotLayer> = targetMap.getLayers().getArray()
            .filter(x => x.getVisible())
            .filter(x => Boolean(x.get('getOwLayer')))
            .map(x => x.get('getOwLayer')())

        // The same title boundary may be included in both the matter title layer and the title selection layer.
        // For the purposes of the map snapshot, we only need one, so remove the title selection layer (and in the future, combine the two).
        const matterTitlesLayer = layers.find(x => x.name === LayerNames.MatterTitles)
        const titleSelectionLayer = layers.find(x => x.name === LayerNames.TitleSelectionLayer)
        if (matterTitlesLayer && titleSelectionLayer) {
            const selectedTitleNumber = (titleSelectionLayer as TitleSelectionLayer).getSelectedTitleFeaturesFn().map(feature => feature.get('title_no')).pop()
            if (selectedTitleNumber) {
                const matchingTitle = (matterTitlesLayer as MatterTitleBoundaryLayerGroup).getTitlesDataFn().find(x => x.titleNumber === selectedTitleNumber && x.show)
                if (matchingTitle) {
                    layers = layers.filter(x => x.name !== LayerNames.TitleSelectionLayer)
                }
            }
        }

        return layers.map(layer => layer.getMapSnapshotConfig(targetMap))
            .filter(x => Boolean(x))
    }

    /**
     * Gets the layers configuration to be rendered in a map snapshot.
     * @param config the snapshot configuration.
     * @param targetMap the OpenLayers map to get the layers from.
     * @param settings the settings object. This is currently the output of the /configuration endpoint. Considering refactoring to limit dependency on this.
     */
    public static getOWMapLayersFromSnapshotConfig(
        config: Array<LayerSnapshotModel>,
        targetMap: olMap,
        settings: any): Array<IMapSnapshotLayer> {
        const result:Array<IMapSnapshotLayer> = []
        config.forEach(layerConfig => {
            const layerType: IMapSnapshotLayerConstructable = layerTypeMapping[layerConfig.name]
            if (layerType !== null) {
                // eslint-disable-next-line new-cap
                const layerInstance = new layerType({
                    snapshotConfig: layerConfig,
                    targetMap,
                }, settings)
                result.push(layerInstance)
            }
        })
        return result
    }

    /**
     * Returns key items applicable for the current snapshot.
     * @returns the updated KeyConfigModel containing the key items.
     * */
    public updateKey(targetMap: olMap):KeyConfigModel {
        const layers: Array<IMapSnapshotLayer> = targetMap.getLayers().getArray()
            .filter(x => x.getVisible() && x.get('getOwLayer'))
            .map(x => x.get('getOwLayer')())
        const result = {
            title: null,
            items: [],
            position: {
                x: 0,
                y: 0,
            },
        }
        // Cache any user labels for key items.
        this.keyConfig.items.forEach(keyItem => {
            if (keyItem.label !== keyItem.originalLabel) {
                this.keyConfigLabelCache.set(keyItem.id, keyItem.label)
            }
        })

        // Generate key items from each layer.
        layers.forEach(layer => {
            const layerKeyItems = (layer.getKeyItems(targetMap) ?? [])
                .filter(x => !this.ignoreKeyItemIds.includes(x.id))

            if (layerKeyItems) {
                // Re-apply labels the user may have set previously.
                layerKeyItems.forEach(keyItem => {
                    const cachedLabel = this.keyConfigLabelCache.get(keyItem.id)
                    if (cachedLabel) {
                        keyItem.label = cachedLabel
                    }
                })
                result.items.push(...layerKeyItems)
            }
        })

        // Consolidate key items that have the same style.
        const consolidatedItems = []
        result.items.forEach(item => {
            const existingItem = consolidatedItems.find(x => JSON.stringify(x.style) === JSON.stringify(item.style))
            if (existingItem) {
                existingItem.id = `${ existingItem.id }, ${ item.id }`
                // Get the user-defined label for the existing item, if any, and use that.
                const userDefinedLabel = this.keyConfigLabelCache.get(existingItem.id)
                existingItem.label = userDefinedLabel ?? `${ existingItem.label }, ${ item.label }`
            } else {
                consolidatedItems.push(item)
            }
        })
        result.items = consolidatedItems.filter(x => this.ignoreKeyItemIds.includes(x.id) === false)
        this.keyConfig = reactive(result)

        return result
    }

    public resetKey() {
        this.keyConfigLabelCache.clear()
        this.ignoreKeyItemIds = ref([]).value
        this.updateKey(this.targetMap)
    }

    getContentHint(): string {
        return i18n.global.t('snapshots.map.preview.hint') as string
    }
}
