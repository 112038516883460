import {
    SEARCHES_FETCH_ORDERS,
    SEARCHES_MUTATE_ORDERS,
    SEARCHES_MUTATE_ADD_ERROR,
    SEARCHES_FETCH_CATEGORIES,
    SEARCHES_FETCH_ORDER_DETAILS,
    SEARCHES_FETCH_DRAFT_ORDER_DETAILS,
    SEARCHES_MUTATE_CATEGORIES,
    SEARCHES_MUTATE_PRODUCT_QUOTE,
    SEARCHES_FETCH_PRODUCT_QUOTE,
    SEARCHES_MUTATE_QUOTE_CALCULATING,
    SEARCHES_CREATE_ORDER,
    SEARCHES_MUTATE_ORDER_DETAILS,
    SEARCHES_FETCH_SEARCH_PROVIDERS,
    SEARCHES_MUTATE_SEARCH_PROVIDERS,
    SEARCHES_UPDATE_SKETCH,
    SEARCHES_NEW_SKETCH,
    SEARCHES_SKETCH_INITIALISE,
    SEARCHES_MUTATE_DRAW_LAYER_WITH_SKETCH,
    SEARCHES_SET_SNAPPING_MODE,
    SEARCHES_MUTATE_SNAPPING_MODE,
    SEARCHES_SET_MODE,
    SEARCHES_INIT_DRAW_INTERACTION,
    SEARCHES_MUTATE_CURRENT_MODE,
    SEARCHES_RESET,
    SEARCHES_SET_FILTERED_TITLES,
    SEARCHES_MUTATE_IS_DRAWING,
    SEARCHES_CLEAR_CURRENT_SKETCH,
    SEARCHES_UPDATE_AREA_SQM,
    SEARCHES_MUTATE_AREA_SQM,
    SEARCHES_MUTATE_GEOJSON,
    SEARCHES_UPDATE_BOUNDARY,
    SEARCHES_MUTATE_NEW_SKETCH,
    SEARCHES_UNDO,
    SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD,
    SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX,
    SEARCHES_MUTATE_MAKING_UNDO_REDO_CHANGES,
    SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL,
    SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS,
    SEARCHES_MUTATE_HAZARDS,
    SEARCHES_FETCH_PRODUCT_PREFERENCES,
    SEARCHES_MUTATE_PRODUCT_PREFERENCES,
    SEARCHES_MUTATE_PRODUCTS,
    SEARCHES_PREPOPULATE_BASKET,
    SEARCHES_MUTATE_RECOMMENDED_CLEAR,
    ProductPreferenceBehaviour,
    SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM,
    SEARCHES_ADD_PRODUCTS_TO_BASKET,
    Product,
    SEARCHES_MUTATE_ADD_BASKET_ITEM,
    SEARCHES_MUTATE_REMOVE_BASKET_ITEM,
    SEARCHES_MUTATE_REMOVE_RECOMMENDED_ITEM,
    SEARCHES_QUOTE,
    SEARCHES_ADD_TO_BASKET,
    SEARCHES_REMOVE_FROM_BASKET,
    SEARCHES_MUTATE_RECOMMENDED_SORT,
    SEARCHES_MUTATE_CLEAR_BASKET,
    SEARCHES_MUTATE_CLEAR_QUOTE,
    SEARCHES_MUTATE_BASKET_ITEMS_WITH_QUOTE,
    SEARCHES_MUTATE_CLEAR_PRODUCT_QUOTE,
    SEARCHES_MUTATE_OTHER_PRODUCTS,
    SEARCHES_GET_AVAILABLE_PRODUCTS,
    SEARCHES_ADD_PRODUCT_TO_BASKET,
    SEARCHES_MUTATE_OTHER_FILTER,
    SEARCHES_GET_ORDER_REQUEST,
    SEARCHES_INIT_CONTEXT,
    SEARCHES_MUTATE_CONTEXTID,
    SEARCHES_MUTATE_QUOTE_TOTALS,
    SEARCHES_MUTATE_DRAFT_ORDER_DETAILS,
    SEARCHES_MUTATE_BASKET_DIRTY,
    SEARCHES_MUTATE_SAVING_DRAFT_ORDER,
    SEARCHES_CREATE_DRAFT_ORDER,
    SEARCHES_MUTATE_DRAFT_ORDER_ID,
    SEARCHES_UPDATE_DRAFT_ORDER,
    SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER,
    SearchOrder,
    SEARCHES_GET_PROPERTY_TYPE,
    SEARCHES_MUTATE_NOTIFICATION_USERS,
    SEARCHES_UPDATE_TITLES_IN_BOUNDARY,
    SEARCHES_SHOW_DRAW_BOUNDARY,
    SEARCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS,
    SEARCHES_GET_SELECTED_TITLE_NUMBERS,
    SEARCHES_INIT_MODIFY_INTERACTION,
    SEARCHES_SUBMIT_DRAFT_ORDER,
} from '@/store/modules/searches/types'
import SearchesService, {
    ICreateSearchOrderRequest,
    IGetAllSearchesOrdersRequest,
    IGetSearchOrderDetailsRequest,
    IGetSearchesProductsRequest,
    IGetSearchesQuoteRequest,
    IInitialiseContextRequest,
    IGetSearchDraftOrderDetailsRequest,
    IGetNotificationUsersResponse,
} from '@/api/searches.api'
import { ISketch } from '../sketches/types/sketch'
import { ISearchesState } from '.'
import { SketchesFactory } from '../sketches/types/sketches-factory'
import {
    SketchGeometryType,
    SketchMode,
    SketchType,
    SnappingMode,
} from '@/enums/sketches-enums'
import olMap from 'ol/Map'
import { SketchesLayer } from '../map/layers/sketches-layer'
import {
    clickFirstPolygonPointCondition,
    getDrawSketchStyle,
    getHighlightStyle,
    getStyleTargetForSketchType,
    sketchInProgressStyleFn,
} from '@/utils/sketch-utils'
import {
    bufferFeatures,
    getOLStyleForOWStyleDefinition,
} from '@/utils/map-utils'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import debounce from 'lodash.debounce'
import { areEqualExcludingWhitespace } from '@/utils/string-utils'
import {
    Draw,
    Modify,
    Snap,
} from 'ol/interaction'
import {
    shiftKeyOnly,
    singleClick,
    never,
} from 'ol/events/condition'
import {
    Extent,
    buffer,
    containsExtent,
} from 'ol/extent'
import { getArea } from 'ol/sphere'
import GeoJSON from 'ol/format/GeoJSON'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import {
    Collection,
    Feature,
} from 'ol'
import { Geometry } from 'ol/geom'
import BasketItemModel from '@/components/matter-searches/models/BasketItemModel'
import { Commit } from 'vuex'
import CategoryItemModel from '@/components/matter-searches/models/CategoryItemModel'
import { PropertyType,
    Provider } from '@/enums/searches.enum'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'

export default {
    async [SEARCHES_INIT_CONTEXT]({ commit }, request: IInitialiseContextRequest ) {
        try {
            const response = await SearchesService.initialiseContext(request)
            if (!response) {
                return
            }
            if (response?.ok) {
                commit(SEARCHES_MUTATE_CONTEXTID, response.data.toString())
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_INIT_CONTEXT }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_FETCH_ORDERS]({ commit }, request: IGetAllSearchesOrdersRequest) {
        try {
            const response = await SearchesService.getAllSearchesOrders(request)
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }

            if (response?.ok) {
                commit(SEARCHES_MUTATE_ORDERS, response.data.orders)
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_FETCH_ORDERS }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_FETCH_PRODUCT_QUOTE]({ commit }, request: IGetSearchesQuoteRequest) {
        commit(SEARCHES_MUTATE_QUOTE_CALCULATING, true)
        try {
            const response = await SearchesService.getSearchesQuote(request)
            if (!response) {
                return
            }
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                commit(SEARCHES_MUTATE_PRODUCT_QUOTE, response.data)
                commit(SEARCHES_MUTATE_QUOTE_CALCULATING, false)
                return response.data
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_FETCH_PRODUCT_QUOTE }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
            commit(SEARCHES_MUTATE_QUOTE_CALCULATING, false)
        }
    },

    async [SEARCHES_FETCH_SEARCH_PROVIDERS]({ commit }) {
        try {
            const response = await SearchesService.getSearchProviders()
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                commit(SEARCHES_MUTATE_SEARCH_PROVIDERS, response.data)
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_FETCH_SEARCH_PROVIDERS }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_FETCH_PRODUCT_PREFERENCES]({ commit }, orgId: string) {
        try {
            const response = await SearchesService.getProductPreference(orgId)
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                commit(SEARCHES_MUTATE_PRODUCT_PREFERENCES, response.data)
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_FETCH_PRODUCT_PREFERENCES }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request: orgId,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_SET_FILTERED_TITLES]({ commit, getters, rootState }) {
        const titles = rootState.matter.currentMatter.selectedTitles
            .map((title) => ({
                ...title,
                show: true,
                highlight: false,
                selected: getters[SEARCHES_GET_SELECTED_TITLE_NUMBERS].includes(title.titleNumber),
            }))

        commit(SEARCHES_MUTATE_FILTERED_TITLE_NUMBERS, titles)
    },

    async [SEARCHES_FETCH_CATEGORIES]({ commit, getters }, request: IGetSearchesProductsRequest) {
        try {
            commit(SEARCHES_MUTATE_HAZARDS, [])
            commit(SEARCHES_MUTATE_PRODUCTS, [])
            commit(SEARCHES_MUTATE_CATEGORIES, [])
            const response = await SearchesService.getSearchesProducts(request)
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                commit(SEARCHES_MUTATE_HAZARDS, response.data?.hazards ?? [])
                commit(SEARCHES_MUTATE_PRODUCTS, response.data?.products ?? [])

                // set up the categories
                const categories: Partial<CategoryItemModel>[] = response.data?.hazards?.map((hazard) => {
                    const category: Partial<CategoryItemModel> = {
                        categoryId: hazard.id,
                        selectedProductId: null,
                        selectedProductCategoryIds: [],
                        selectedProductHazards: [],
                        name: hazard.name,
                        shortName: hazard.shortName,
                        description: hazard.description,
                        isActive: hazard.isActive,
                        products: [],
                    }
                    return category
                })
                let otherCategory = categories.find((category) => category.categoryId === 'Other')
                if (!otherCategory) {
                    otherCategory = {
                        categoryId: 'Other',
                        selectedProductId: null,
                        selectedProductCategoryIds: [],
                        selectedProductHazards: [],
                        name: 'Other',
                        shortName: 'Other',
                        description: 'Other',
                        isActive: false,
                        products: [],
                    }
                }
                categories.push(otherCategory)
                if (response.data?.products?.length) {
                    response.data?.products?.forEach((product) => {
                        if (!product.categoryIds || !product.categoryIds.length) {
                            otherCategory.products.push({
                                ...product,
                                hazards: ['Other'],
                            })
                        } else {
                            product.categoryIds.forEach((categoryId) => {
                                const category = categories.find((category) => category.categoryId === categoryId)
                                if (category) {
                                    category.products.push({
                                        ...product,
                                        hazards: product.categoryIds.map((categoryId) => {
                                            const hazard = response.data.hazards.find((hazard) => hazard.id === categoryId)
                                            return hazard?.shortName ?? categoryId
                                        }),
                                    })
                                }
                            })
                        }
                    })
                    commit(SEARCHES_MUTATE_CATEGORIES, categories)
                    commit(SEARCHES_MUTATE_OTHER_PRODUCTS, getters[SEARCHES_GET_AVAILABLE_PRODUCTS])
                }
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_FETCH_CATEGORIES }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_ADD_PRODUCT_TO_BASKET]({ state, commit, dispatch }: { state: ISearchesState, commit: Commit, dispatch: any}, product: Product) {
        const category = state.categories.find((category) => category.categoryId === product.categoryIds[0])
        commit(SEARCHES_MUTATE_ADD_BASKET_ITEM, {
            category,
            product,
        })
        commit(SEARCHES_MUTATE_OTHER_FILTER)
        dispatch(SEARCHES_QUOTE)
    },

    async [SEARCHES_ADD_PRODUCTS_TO_BASKET]({ state, commit, dispatch } : { state: ISearchesState, commit: Commit, dispatch: any}, data: {
        category: CategoryItemModel,
        products: Product[],
    }) {
        const basketHasProduct = (productId: string) => {
            return state.basket.some((basketItem: BasketItemModel) => basketItem.productId === productId)
        }

        const { category, products } = data
        products.forEach((product) => {
            // is product already in the basket?
            // if so, don't add it again
            if (basketHasProduct(product.productId)) {
                return
            }

            // remove the selected category or basket item
            if (state.selectedCategory) {
                if (product.categoryIds.includes(state.selectedCategory.categoryId)) {
                    commit(SEARCHES_MUTATE_REMOVE_RECOMMENDED_ITEM, state.selectedCategory)
                }
            }

            // remove it from the basket
            if (state.selectedBasketItem) {
                // if model is a hazard, add it back to the recommended categories
                if (state.selectedBasketItem?.category?.isActive) {
                    commit(SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM, {category: state.selectedBasketItem.category, productId: state.selectedBasketItem.productId})
                }
                commit(SEARCHES_MUTATE_REMOVE_BASKET_ITEM, state.selectedBasketItem.id)
            }

            commit(SEARCHES_MUTATE_ADD_BASKET_ITEM, {
                category,
                product,
            })
        })

        // update other products
        commit(SEARCHES_MUTATE_OTHER_FILTER)

        dispatch(SEARCHES_QUOTE)
    },

    async [SEARCHES_ADD_TO_BASKET]({ commit, state, dispatch } : { commit: Commit, state: ISearchesState, dispatch: any}, category: CategoryItemModel) {
        const product = category.products.find((product: Product) => product.productId === category.selectedProductId)
        if (product) {
            await dispatch(SEARCHES_ADD_PRODUCTS_TO_BASKET, {
                category,
                products: [product],
            })

            // remove it from the recommended categories
            commit(SEARCHES_MUTATE_REMOVE_RECOMMENDED_ITEM, category)

            // update other products
            commit(SEARCHES_MUTATE_OTHER_FILTER)
        }
    },

    async [SEARCHES_REMOVE_FROM_BASKET]({ commit, state, dispatch }: { commit: Commit, state: ISearchesState, dispatch: any}, basketItem: BasketItemModel) {
        commit(SEARCHES_MUTATE_REMOVE_BASKET_ITEM, basketItem.id)

        // if model is a hazard, add it back to the recommended categories
        // or product is in the product preferences
        if (basketItem.category?.isActive
            && state.productPreferences?.some((productPreferenceList) =>
                productPreferenceList.productPreferences.some((productPreference) =>
                    productPreference.providerProductId === basketItem.productId))) {
            commit(SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM, { category: basketItem.category, productId: basketItem.productId })
        }

        // update other products
        commit(SEARCHES_MUTATE_OTHER_FILTER)

        // update quote totals
        commit(SEARCHES_MUTATE_QUOTE_TOTALS)

        commit(SEARCHES_MUTATE_RECOMMENDED_SORT)
    },

    async [SEARCHES_QUOTE]({ state, commit, getters, dispatch } : { state: ISearchesState, commit: Commit, getters: any, dispatch: any}) {
        if (state.basket.count === 0) {
            commit(SEARCHES_MUTATE_CLEAR_QUOTE)
            return
        }

        if (!state.filteredTitleNumbers || !state.filteredTitleNumbers.length) {
            return
        }

        // set calculating to true
        if (state.basket?.allRecords?.length) {
            state.basket.allRecords.forEach((item: BasketItemModel) => {
                item.calculating = true
            })
        }

        const title = state.filteredTitleNumbers.find((title) => title.titleNumber === state.selectedTitleNumbers[0])
        const request: IGetSearchesQuoteRequest = {
            contextId: state.contextId,
            numberOfParcels: 1,
            providerId: state.filter.searchProviderId,
            projectType: 0,
            propertyType: getters[SEARCHES_GET_PROPERTY_TYPE],
            productIds: state.basket?.allRecords?.map((item: BasketItemModel) => item.productId) ?? [],
            products: state.basket?.allRecords?.map((item: BasketItemModel) => {
                return {
                    productId: item.productId,
                    productOptions: item.productOptionsToJson(),
                }
            }) ?? [],
        }
        let quote
        try {
            quote = await dispatch(SEARCHES_FETCH_PRODUCT_QUOTE, request)
        } catch (err) {
            // errored product ids
            commit(SEARCHES_MUTATE_BASKET_ITEMS_WITH_QUOTE, {
                quote: state.quote,
                erroredProducts: err?.response?.data?.errors,
            })

            // ensure calculating is set to false
            state.basket.allRecords.forEach((item: BasketItemModel) => {
                item.calculating = false
                item.errored = !item.totalGrossFee || !item.expectedDate
            })

            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_QUOTE }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
        if (!quote) {
            return
        }
        if (state.basket.count === 0) {
            return commit(SEARCHES_MUTATE_CLEAR_PRODUCT_QUOTE)
        }
        commit(SEARCHES_MUTATE_BASKET_ITEMS_WITH_QUOTE, {
            quote,
            erroredProducts: [],
        })
    },

    async [SEARCHES_PREPOPULATE_BASKET]({ state, commit, getters, dispatch } : { state: ISearchesState, commit: Commit, getters: any, dispatch: any}, args: {
        addPPToBasket?: boolean,
        addDraftOrdersToBasket?: boolean,
    } = {
        addPPToBasket: true,
        addDraftOrdersToBasket: false,
    }) {
        // local helpers
        const basketHasProduct = (productId: string) => {
            return state.basket.some((basketItem: BasketItemModel) => basketItem.productId === productId)
        }
        const basketHasCategory = (categoryId: string) => state.basket.some((basketItem: BasketItemModel) => basketItem.categoryIds.includes(categoryId))
        const recommendedHasCategory = (categoryId: string) =>
            state.recommended.some((category: CategoryItemModel) => category.categoryId === categoryId)
        const recommendedHasProduct = (productId: string) =>
            state.recommended.some((category: CategoryItemModel) => category.products.some((product: Product) => product.productId === productId))

        if (state.productPreferences?.length === 0) {
            return
        }

        // clear the basket
        commit(SEARCHES_MUTATE_CLEAR_BASKET)

        // add draft order products to the basket
        if (args.addDraftOrdersToBasket && state.draftOrderDetails?.draftOrderItems?.length) {
            state.draftOrderDetails.draftOrderItems.forEach(async (draftOrder) => {
                const category = state.categories.find((category: CategoryItemModel) => {
                    return category.products.find((product) => product.description === draftOrder.productName)
                })
                if (category) {
                    const categoryItem = category as CategoryItemModel
                    categoryItem.selectedProductId = categoryItem.products.find((product) => product.description === draftOrder.productName).productId
                    await dispatch(SEARCHES_ADD_TO_BASKET, category)

                    // last basket item
                    const basketItem = state.basket.allRecords[state.basket.count - 1]
                    if (basketItem) {
                        const basketItemModel = basketItem as BasketItemModel
                        // basketItemModel.config = basketItemModel.jsonToProductOptions()
                    }
                }
            })
        }

        // clear recommended categories
        commit(SEARCHES_MUTATE_RECOMMENDED_CLEAR)

        // add product preferences to the basket
        const productPreferenceData = state.productPreferences?.find((productPreference) => productPreference.id === state.filter.productPreferenceId)
        if (productPreferenceData) {
            productPreferenceData.productPreferences.forEach((productPreference) => {
                const { productPreferenceBehaviour, providerProductId } = productPreference
                state.categories.forEach(async (category: CategoryItemModel) => {
                    const product = category.products.find((product) => product.productId === providerProductId)
                    if (product) {
                        if (productPreferenceBehaviour === ProductPreferenceBehaviour.Required && args.addPPToBasket) {
                            // check if it the product is already in the basket
                            if (!basketHasProduct(product.productId) && !basketHasCategory(category.categoryId)) {
                                await dispatch(SEARCHES_ADD_PRODUCTS_TO_BASKET, { category, products: [product] })
                            }
                        } else if (productPreferenceBehaviour === ProductPreferenceBehaviour.Recommended) {
                            // check if it's already in the basket
                            if (!basketHasProduct(product.productId) && !recommendedHasProduct(product.productId) && !recommendedHasCategory(category.categoryId)) {
                                commit(SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM, {
                                    category,
                                    productId: product.productId,
                                })
                            }
                        }
                    }
                })
            })
        }

        // add all recommended categories back
        state.categories.forEach((categoryItem: CategoryItemModel) => {
            const hasCategory = basketHasCategory(categoryItem.categoryId)
            if (categoryItem.isActive && !hasCategory){
                commit(SEARCHES_MUTATE_ADD_RECOMMENDED_ITEM, {category: categoryItem})
            }
        })

        // refresh totals
        commit(SEARCHES_MUTATE_QUOTE_TOTALS)

        // sort recommended categories
        commit(SEARCHES_MUTATE_RECOMMENDED_SORT)
    },

    async [SEARCHES_CREATE_DRAFT_ORDER]({ commit, getters, state }) {
        const request: ICreateSearchOrderRequest = getters[SEARCHES_GET_ORDER_REQUEST]
        try {
            commit(SEARCHES_MUTATE_SAVING_DRAFT_ORDER, true)
            const response = await SearchesService.createDraftOrder(request)
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                commit(SEARCHES_MUTATE_BASKET_DIRTY, false)
                commit(SEARCHES_MUTATE_DRAFT_ORDER_ID, response.data)
                return response
            }
        } catch (err) {
            commit(SEARCHES_MUTATE_SAVING_DRAFT_ORDER, false)
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_CREATE_DRAFT_ORDER }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        } finally {
            commit(SEARCHES_MUTATE_SAVING_DRAFT_ORDER, false)
        }
    },

    async [SEARCHES_UPDATE_DRAFT_ORDER]({ commit, getters, state }) {
        const request: ICreateSearchOrderRequest = getters[SEARCHES_GET_ORDER_REQUEST]
        try {
            commit(SEARCHES_MUTATE_SAVING_DRAFT_ORDER, true)
            const response = await SearchesService.updateDraftOrder({
                ...request,
                id: state.draftOrderId,

            })
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                commit(SEARCHES_MUTATE_BASKET_DIRTY, false)
                return response
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_SAVING_DRAFT_ORDER, false)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_UPDATE_DRAFT_ORDER }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        } finally {
            commit(SEARCHES_MUTATE_SAVING_DRAFT_ORDER, false)
        }
    },

    async [SEARCHES_SUBMIT_DRAFT_ORDER]({ commit, getters, state }) {
        const request: ICreateSearchOrderRequest = getters[SEARCHES_GET_ORDER_REQUEST]
        try {
            const response = await SearchesService.submitDraftOrder({
                ...request,
                id: state.draftOrderId,
            })
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                return response
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_UPDATE_DRAFT_ORDER }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_CREATE_ORDER]({ commit, getters }) {
        const request: ICreateSearchOrderRequest = getters[SEARCHES_GET_ORDER_REQUEST]
        try {
            const response = await SearchesService.createSearchOrder(request)
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            if (response?.ok) {
                return response
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_CREATE_ORDER }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_FETCH_ORDER_DETAILS]({ commit, dispatch }, request: IGetSearchOrderDetailsRequest) {
        try {
            const response = await SearchesService.getSearchOrderDetails(request)
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }
            let notificationUsers: IHttpClientResponse<IGetNotificationUsersResponse>
            try {
                notificationUsers = await SearchesService.getNotificationUsers(request.id)
            } catch (error) {
                if (error?.response?.status === 500) {
                    notificationUsers = null
                }
            }

            if (response?.ok) {
                commit(SEARCHES_MUTATE_ORDER_DETAILS, {
                    ...response.data,
                })
                commit(SEARCHES_MUTATE_NOTIFICATION_USERS, notificationUsers?.data?.users ?? [])
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_FETCH_ORDER_DETAILS }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_FETCH_DRAFT_ORDER_DETAILS]({ commit }, request: IGetSearchDraftOrderDetailsRequest) {
        try {
            const response = await SearchesService.getDraftOrderDetails(request)
            if (!response || response?.status === 500) {
                throw new Error('Internal server error')
            }

            if (response?.ok) {
                commit(SEARCHES_MUTATE_DRAFT_ORDER_DETAILS, {
                    ...response.data,
                })
            }
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_FETCH_DRAFT_ORDER_DETAILS }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }
    },

    async [SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER]({ commit, state, rootState, dispatch }: { commit: any, state: ISearchesState, rootState: any, dispatch: any}, searchOrder: SearchOrder) {
        // reset
        commit(SEARCHES_MUTATE_CLEAR_QUOTE)

        // get the search order details
        try {
            await dispatch(SEARCHES_FETCH_DRAFT_ORDER_DETAILS, { id: searchOrder.id })
        } catch (err) {
            console.error(err)
            commit(SEARCHES_MUTATE_ADD_ERROR, {
                key: `${ SEARCHES_POPULATE_BASKET_FROM_DRAFT_ORDER }`,
                value: {
                    traceId: err?.response?.data?.traceId,
                    detail: err?.response?.data?.detail,
                    request: searchOrder,
                    errors: err?.message ? [err.message] : [err],
                },
            })
        }

        // set the address and draft order details
        const draftOrder = state.draftOrderDetails
        state.draftOrderId = draftOrder.orderId
        state.address = {
            address: {
                ...draftOrder.address,
                sector: draftOrder?.propertySectorType ?? PropertyType[PropertyType.Commercial].toString(),
            },
            uprn: "",
        }
        state.draftOrderDetails.draftOrderItems = draftOrder.draftOrderItems
        state.selectedTitleNumbers = draftOrder.associatedTitleNumbers
        state.filteredTitleNumbers = rootState.matter.currentMatter.selectedTitles.filter((title) => draftOrder.associatedTitleNumbers.includes(title.titleNumber))
        state.filter.searchProviderId = Provider.TmGroup
        commit(SEARCHES_MUTATE_GEOJSON, draftOrder?.geoJson)

        // set the template id to the product preference id
        if (state.productPreferences?.length === 0) {
            const productPreference = state.productPreferences.find((productPreference) => productPreference.id === draftOrder?.templateId)
            if (productPreference) {
                state.filter.productPreferenceId = draftOrder?.templateId
            } else {
                state.filter.productPreferenceId = state.productPreferences[0]?.id
            }
        }
    },

    async [SEARCHES_UPDATE_SKETCH]({ state }: { state: ISearchesState}, sketch: ISketch): Promise<void> {
        state.sketch.styleCache.delete(sketch.id)
        state.sketch.highlightStyleCache.delete(sketch.id)
        state.sketch.debouncedOnSketchChange()

        if (state.sketch.layer) {
            state.sketch.layer.getSource().changed()
        }
        if (state.sketch.drawLayer) {
            state.sketch.drawLayer.getSource().changed()
        }
    },

    [SEARCHES_NEW_SKETCH]({ commit } : { commit: any }) {
        commit(SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL)
        const sketchType = SketchType.Area
        const newSketch = SketchesFactory.newSketch(sketchType)
        newSketch.id = 0
        newSketch.visible = true

        commit(SEARCHES_MUTATE_NEW_SKETCH, newSketch)
    },

    async [SEARCHES_SKETCH_INITIALISE]({ state, commit, dispatch }: { state: ISearchesState, commit: any, dispatch: any}, { targetMap }:{ targetMap: olMap }): Promise<void> {
        if (!state.sketch.initialising) {
            // Everything needs initialising.
            state.sketch.targetMap = targetMap

            // Sketches layer, where the existing sketches will be displayed.
            state.sketch.layer = new SketchesLayer({
                targetMap,
                getSketchesFn: () => state.sketch.currentSketch,
                getSketchesStyleFn: () => {
                    const sketch = state.sketch.currentSketch
                    if (sketch) {
                        if (sketch === state.sketch.highlightedSketch) {
                            const cachedStyle = state.sketch.highlightStyleCache.get(sketch.id)
                            if (cachedStyle) {
                                return cachedStyle
                            }
                            const result = getHighlightStyle(sketch)
                            state.sketch.highlightStyleCache.set(sketch.id, result)
                            return result
                        }
                        if (sketch === state.sketch.currentSketch) {
                            return null
                        }
                        if (!sketch.visible) {
                            return null
                        }

                        const cachedStyle = state.sketch.styleCache.get(sketch.id)
                        if (cachedStyle) {
                            return cachedStyle
                        }
                        const styleTarget = getStyleTargetForSketchType(sketch.sketchType)
                        const result = getOLStyleForOWStyleDefinition(sketch, styleTarget)
                        state.sketch.styleCache.set(sketch.id, result)
                        return result
                    }
                    return null
                },
            }).getLayer()
            state.sketch.targetMap.addLayer(state.sketch.layer)

            // Drawing layer where the creating/editing will happen.
            state.sketch.drawLayer = new VectorLayer({
                source: new VectorSource(),
                visible: true,
                zIndex: 400,
                updateWhileAnimating: true,
                updateWhileInteracting: true,
                style: (feature) => {
                    const sketch = state.sketch.currentSketch
                    const drawLayerActive = state.sketch.drawInteraction.getActive()
                    return getDrawSketchStyle(feature, sketch, drawLayerActive ? 5 : Number.MAX_SAFE_INTEGER)
                },
            })
            state.sketch.drawLayer.setProperties({ name: 'searches-draw-layer' })
            state.sketch.targetMap.addLayer(state.sketch.drawLayer)
            state.sketch.debouncedOnSketchChange = debounce(async (addToHistory = true) => {
                if (!state.sketch?.currentSketch) {
                    return
                }
                const drawFeatures = state.sketch.drawLayer.getSource().getFeatures()
                const allFeaturesRemoved = drawFeatures.length === 0 && state.sketch.currentSketch.features.length > 0
                if (allFeaturesRemoved || (drawFeatures.length &&
                    (drawFeatures[0].getProperties().sketchId === state.sketch.currentSketch.id || !drawFeatures[0].getProperties().sketchId))) {
                    const geoJson = SketchesFactory.geoJsonFromFeatures(drawFeatures)
                    if (!areEqualExcludingWhitespace(state.sketch.currentSketch.geoJson, geoJson)) {
                        drawFeatures.forEach(x => x.setProperties({ sketchId: state.sketch.currentSketch.id }))
                        const geoJson = SketchesFactory.geoJsonFromFeatures(drawFeatures)

                        if (!state.sketch.makingChangesWithUndoRedo && addToHistory) {
                            commit(SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD,
                                state.sketch.currentSketch.features.length ? state.sketch.currentSketch.geoJson : null)
                        }
                        state.sketch.currentSketch.geoJson = geoJson
                        state.sketch.currentSketch.features = drawFeatures
                        commit(SEARCHES_MUTATE_DRAW_LAYER_WITH_SKETCH, state.sketch.currentSketch)
                        await dispatch(SEARCHES_UPDATE_SKETCH, { sketch: state.sketch.currentSketch, addToHistory })
                    }
                }
                state.sketch.styleCache.delete(state.sketch.currentSketch.id)
                state.sketch.highlightStyleCache.delete(state.sketch.currentSketch.id)
            }, 100)

            // When a feature is added or removed, update the current sketch.
            state.sketch.drawLayer.getSource().on('addfeature', async () => await state.sketch.debouncedOnSketchChange())
            state.sketch.drawLayer.getSource().on('removefeature', async () => await state.sketch.debouncedOnSketchChange())


            dispatch(SEARCHES_INIT_MODIFY_INTERACTION)
        }
    },

    [SEARCHES_SHOW_DRAW_BOUNDARY]({ state, commit, dispatch }: {state: ISearchesState, commit: any, dispatch: any }): void {
        if (state.sketch.showSketchModeOptions) {
            commit(SEARCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS, false)
            dispatch(SEARCHES_UPDATE_BOUNDARY)
            return
        }
        if (!state.sketch.currentSketch || state.sketch.currentSketch?.features?.length === 0) {
            dispatch(SEARCHES_NEW_SKETCH)
            dispatch(SEARCHES_SET_MODE, SketchMode.Area)
        }
        commit(SEARCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS, true)
    },

    [SEARCHES_SET_SNAPPING_MODE]({ state, commit }: {state: ISearchesState, commit: any }, mode: SnappingMode) {
        // Reset.
        if (state.sketch.snapInteraction) {
            state.sketch.snapInteraction.setActive(false)
            state.sketch.targetMap.removeInteraction(state.sketch.snapInteraction)
        }
        commit(SEARCHES_MUTATE_SNAPPING_MODE, mode)
        // Initialise.
        if (mode === SnappingMode.None) {
            return
        }
        if (mode === SnappingMode.TitleBoundaries) {
            const titleNumberProperty = 'titleNumber'
            const traceSource = state.boundaryLayer.layer.getSource()
            const titlesToShow = state.selectedTitleNumbers
            const snappingFeatures: Collection<Feature<Geometry>> = new Collection<Feature<Geometry>>()


            traceSource.getFeatures()
                .filter(x => titlesToShow.includes(x.get(titleNumberProperty)))
                .forEach(x => snappingFeatures.push(x))

            // Add selected title as it may not exist in the matter
            if (state.filteredTitleNumbers) {
                state.boundaryLayer.layer.getSource().getFeatures()
                    .filter(x => x.get(titleNumberProperty) && !titlesToShow.includes(x.get(titleNumberProperty)))
                    .forEach(x => snappingFeatures.push(x))
            }

            state.sketch.snapInteractionFeatures = snappingFeatures
            state.sketch.snapInteraction = new Snap({
                features: snappingFeatures,
            })
            state.sketch.targetMap.addInteraction(state.sketch.snapInteraction)
            state.sketch.snapInteraction.setActive(true)

            // Enable tracing on the same layer as snapping.
            state.sketch.drawInteraction?.setProperties({
                trace: true,
                traceSource: new VectorSource({ features: snappingFeatures }),
            })
        }
    },

    [SEARCHES_SET_MODE]({ state, dispatch, commit } : { state: ISearchesState, dispatch: any, commit: any}, mode:SketchMode) {
        // Stop any drawings in progress.
        state.sketch.drawInteraction?.finishDrawing()
        state.sketch.drawLayer?.setVisible(false)
        state.sketch.snapInteraction?.setActive(false)
        state.sketch.modifyInteraction?.setActive(false)

        if (mode !== SketchMode.None) {
            const drawType = SketchGeometryType.POLYGON
            dispatch(SEARCHES_INIT_DRAW_INTERACTION, drawType)
            state.sketch.modifyInteraction.setActive(true)
            state.sketch.drawLayer.setVisible(true)
            state.sketch.drawInteraction.setActive(true)
            state.sketch.modifyInteraction.setActive(true)
            // (re)-apply snapping
            dispatch(SEARCHES_SET_SNAPPING_MODE, state.sketch.snappingMode)
        } else {
            state.sketch.drawInteraction?.setActive(false)
        }
        commit(SEARCHES_MUTATE_CURRENT_MODE, mode)
        dispatch(SEARCHES_UPDATE_AREA_SQM)
    },

    async [SEARCHES_CLEAR_CURRENT_SKETCH]({ state, commit, dispatch } : {state: ISearchesState, commit: any, dispatch: any}): Promise<void> {
        state.sketch.drawInteraction?.finishDrawing()
        state.sketch.drawLayer?.getSource().clear()
        state.searchesLayer?.updateSearchesLayer(state.boundaryLayer.layer.getSource().getFeatures())
        commit(SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL)
        commit(SEARCHES_MUTATE_DRAW_LAYER_WITH_SKETCH, state.sketch?.currentSketch)
        state.selectedTitleNumbers = []
        state.filteredTitleNumbers.forEach((titleNumber) => {
            titleNumber.selected = false
        })
        state.sketch.currentSketch.features = []

        dispatch(SEARCHES_UPDATE_AREA_SQM)
        dispatch(SEARCHES_UPDATE_BOUNDARY, true)
        dispatch(SEARCHES_SET_SNAPPING_MODE, state.sketch.snappingMode)
        await dispatch(SEARCHES_INIT_MODIFY_INTERACTION)

        if (state.boundaryLayer.layer) {
            state.boundaryLayer.layer.getSource().changed()
        }

        if (state.sketch.drawLayer) {
            state.sketch.drawLayer.getSource().changed()
        }
    },

    async [SEARCHES_RESET]({ dispatch, state }: { dispatch: any, state: ISearchesState}): Promise<void> {
        dispatch(SEARCHES_SET_MODE, SketchMode.None)
        state.sketch.drawLayer?.getSource().clear()
        state.sketch.layer?.getSource().clear()
        state.sketch.styleCache.clear()
        state.sketch.highlightStyleCache.clear()
    },

    async [SEARCHES_INIT_MODIFY_INTERACTION]({ state, commit, dispatch }: {state: ISearchesState, commit: any, dispatch: any }): Promise<void> {
        // When changing the type of sketch (i.e. point, line, polygon) we need to re-initialise the draw interaction.
        if (state.sketch.modifyInteraction) {
            state.sketch.modifyInteraction.setActive(false)
            state.sketch.targetMap.removeInteraction(state.sketch.modifyInteraction)
            delete state.sketch.modifyInteraction
        }

        // Support modifying existing sketches.
        state.sketch.modifyInteraction = new Modify({
            source: state.sketch.drawLayer.getSource(),
            pixelTolerance: 20,
            deleteCondition: (e) => {
                return shiftKeyOnly(e) && singleClick(e)
            },
        })

        // insert before the draw interaction so that the draw interaction can take precedence.
        const drawInteractionIndex = state.sketch.targetMap.getInteractions().getArray().findIndex((interaction) => interaction === state.sketch.drawInteraction)
        if (drawInteractionIndex > -1) {
            state.sketch.targetMap.getInteractions().insertAt(drawInteractionIndex, state.sketch.modifyInteraction)
        } else {
            state.sketch.targetMap.addInteraction(state.sketch.modifyInteraction)
        }

        // When a feature is modified, update the search boundary.
        state.sketch.modifyInteraction.on('modifyend', async () => {
            await state.sketch.debouncedOnSketchChange()
            commit(SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD, state.sketch.currentSketch.geoJson)
            setTimeout(() => {
                dispatch(SEARCHES_UPDATE_BOUNDARY)
                dispatch(SEARCHES_UPDATE_AREA_SQM)
                dispatch(SEARCHES_UPDATE_TITLES_IN_BOUNDARY)
                dispatch(SEARCHES_INIT_MODIFY_INTERACTION)

                state.boundaryLayer.layer.getSource().changed()
                state.sketch.drawLayer.getSource().changed()
            }, 500)
        })
    },

    [SEARCHES_INIT_DRAW_INTERACTION]({ state, commit, dispatch }: {state: ISearchesState, commit: any, dispatch: any }, type :any) {
        // When changing the type of sketch (i.e. point, line, polygon) we need to re-initialise the draw interaction.
        if (state.sketch.drawInteraction) {
            state.sketch.drawInteraction.setActive(false)
            state.sketch.targetMap.removeInteraction(state.sketch.drawInteraction)
            delete state.sketch.drawInteraction
        }

        const traceSource = state.boundaryLayer.layer.getSource()
        state.sketch.drawInteraction = new Draw({
            source: state.sketch.drawLayer.getSource(),
            type,
            style: (feature) => {
                return sketchInProgressStyleFn(feature, SketchType.Area)
            },
            trace: true,
            stopClick: true,
            freehandCondition: never,
            snapTolerance: 6,
            traceSource,
            finishCondition: e => clickFirstPolygonPointCondition(e, state.sketch.drawInteraction, state?.sketch.targetMap),
        })
        state.sketch.drawInteraction.on('drawstart', async () => {
            commit(SEARCHES_MUTATE_IS_DRAWING, true)
        })
        state.sketch.drawInteraction.on('drawend', async () => {
            commit(SEARCHES_MUTATE_IS_DRAWING, false)
            state.sketch.modifyInteraction.setActive(true)
            await state.sketch.debouncedOnSketchChange()

            setTimeout(() => {
                dispatch(SEARCHES_UPDATE_BOUNDARY)
                dispatch(SEARCHES_UPDATE_AREA_SQM)
                dispatch(SEARCHES_UPDATE_TITLES_IN_BOUNDARY)

                if (state.boundaryLayer.layer) {
                    state.boundaryLayer.layer.getSource().changed()
                }

                if (state.sketch.drawLayer) {
                    state.sketch.drawLayer.getSource().changed()
                }
            }, 500)
        })

        state.sketch.targetMap.addInteraction(state.sketch.drawInteraction)
    },

    [SEARCHES_UPDATE_TITLES_IN_BOUNDARY]({ state }: {state: ISearchesState}): void {
        const selectedTitleNumbers = new Set()
        const boundaryFeatures = state.boundaryLayer.layer.getSource().getFeatures()
        const filteredTitleNumbersSet = new Set(state.filteredTitleNumbers.map(titleNumber => titleNumber.titleNumber))

        // create a set of features by title number
        const boundaryFeatureSet = new Map()
        boundaryFeatures.forEach((feature) => {
            const titleNumber = feature.get('titleNumber')
            boundaryFeatureSet.set(titleNumber, feature)
        })

        for (const feature of state.sketch.currentSketch.features) {
            const extent: Extent = feature.getGeometry().getExtent()
            for (const titleNumber of state.filteredTitleNumbers) {
                if (filteredTitleNumbersSet.has(titleNumber.titleNumber)) {
                    const matchingFeature = boundaryFeatureSet.get(titleNumber.titleNumber)

                    if (matchingFeature) {
                        let featureExtent = matchingFeature.getGeometry().getExtent()
                        featureExtent = buffer(featureExtent, -1)
                        const isContainingExtent = containsExtent(extent, featureExtent) || selectedTitleNumbers.has(titleNumber.titleNumber)
                        if (isContainingExtent) {
                            titleNumber.selected = true
                            selectedTitleNumbers.add(titleNumber.titleNumber)
                        } else if (!selectedTitleNumbers.has(titleNumber.titleNumber)) {
                            titleNumber.selected = false
                        }
                    }
                }
            }
        }
    },

    [SEARCHES_UPDATE_BOUNDARY]({ state, commit, dispatch }: {state: ISearchesState, commit: any, dispatch: any}, forceClear?: boolean): void {
        let features = state.sketch.drawLayer.getSource().getFeatures()
        if (!features.length) {
            features = state.searchesLayer.getLayer()?.getSource()?.getFeatures()
        }
        if (!features.length || forceClear) {
            commit(SEARCHES_MUTATE_GEOJSON, null)
            return
        }
        const unionSelectedFeature: any = (bufferFeatures(features, 0) as any).getGeometry().transform(CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700)
        const unionFeature = new Feature(unionSelectedFeature)
        unionFeature.setProperties({ sketchId: state.sketch.currentSketch.id })
        if (!unionFeature) {
            return
        }
        state.sketch.drawLayer.getSource().clear()
        state.sketch.drawLayer.getSource().addFeature(unionFeature)
        state.sketch.drawLayer.getSource().changed()

        const geoJson:string = new GeoJSON().writeFeatures([unionFeature], {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG4326,
        })
        commit(SEARCHES_MUTATE_GEOJSON, geoJson)
    },

    [SEARCHES_UPDATE_AREA_SQM]({ commit, state }) {
        // update area in sqm
        let value
        let unit
        const locale = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

        const calcKmSqFromMetres = (metres: number) => {
            return metres / 1000000
        }

        let features
        if (state.sketch.currentSketch && state.sketch.drawLayer.getSource().getFeatures().length > 0) {
            features = state.sketch.drawLayer.getSource().getFeatures() ?? []
        } else if (state.searchesLayer) {
            features = state.searchesLayer.getLayer()?.getSource()?.getFeatures() ?? []
        }

        if (!features?.length) {
            commit(SEARCHES_MUTATE_AREA_SQM, '0m²')
            return
        }
        const totalMetres = features.reduce((total: number, feature) => {
            return total + getArea(feature.getGeometry() as any)
        }, 0)
        if (totalMetres < 100_0000) {
            value = (totalMetres).toLocaleString(undefined, locale)
            unit = 'm²'
        } else {
            // round to 2 decimal places
            value = calcKmSqFromMetres(totalMetres).toLocaleString(undefined, locale)
            unit = 'km²'
        }
        commit(SEARCHES_MUTATE_AREA_SQM, value + unit)
    },

    async [SEARCHES_UNDO]({ state, commit, dispatch }: { state: ISearchesState, commit: any, dispatch: any}): Promise<void> {
        // Depending on the context, the implementation of 'undo' is different.

        // If we are in the middle of drawing a sketch, we want to undo the last point.
        if (state.sketch.currentSketch && state.sketch.drawInteraction.getOverlay().getSource().getFeatures().length > 1) {
            state.sketch.drawInteraction.removeLastPoint()
        } else if (state.sketch.currentSketch && state.sketch.modifyInteraction.getActive() === true) {
            // If we are editing a sketch, we want to undo the last change
            commit(SEARCHES_MUTATE_MAKING_UNDO_REDO_CHANGES, true)
            commit(SEARCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX, state.sketch.history.currentSketchGeoJsonIndex - 1)
            commit(SEARCHES_MUTATE_MAKING_UNDO_REDO_CHANGES, false)
            await state.sketch.debouncedOnSketchChange(false)
        }
    },
}
