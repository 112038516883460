<template>
    <div class="d-flex flex-column flex-wrap matter-details">
        <div class="matter-title__wrapper">
            <h2 v-if="!editMatterName"
                class="content__heading matter-title__title">
                {{ matterName }}
            </h2>

            <span v-if="!editMatterName"
                  class="action-icons">
                <v-icon class="matter-title__icon-edit"
                        data-test="matter-title-edit-icon"
                        @click="editMatterName = true">$edit</v-icon>
                <v-dialog v-model="showDeleteDialog"
                          max-width="50%"
                          persistent>
                    <template #activator="{ props }">
                        <v-icon v-if="details.createdByMe"
                                class="matter-title__icon-delete"
                                data-test="matter-title-delete-icon"
                                v-bind="props">$delete</v-icon>
                    </template>
                    <v-card>
                        <v-card-title class="content__heading">Delete matter: <span>{{ details.name }}</span></v-card-title>
                        <v-card-text class="body-copy">
                            Once deleted, a matter cannot be re-instated.
                            Are you sure you want to delete <span>{{ details.name }}</span>?
                        </v-card-text>
                        <ow-card-actions has-secondary-button
                                         :primary-button-text="$t('action.delete')"
                                         :secondary-button-text="$t('action.cancel')"
                                         primary-button-data-test="matter-title-edit-delete-btn"
                                         secondary-button-data-test="matter-title-edit-cancel-dialog-btn"
                                         primary-button-colour="danger"
                                         @secondary-button-click="showDeleteDialog = false"
                                         @primary-button-click="deleteMatter" />

                    </v-card>
                </v-dialog>
            </span>

            <matter-activity-toggle v-if="!editMatterName"
                                    :matter="details"
                                    :tooltip-position="OwTooltipPosition.Right" />
        </div>

        <div class="flex xs-12">
            <form v-if="editMatterName"
                  data-test="matter-details-form"
                  class="matter-details__form"
                  @submit.prevent
                  @submit="updateMatterName">
                <v-text-field id="editMatterName"
                              v-model="matterName"
                              :autofocus="true"
                              type="text"
                              :clearable="true"
                              clear-icon="$close"
                              :counter="maxNameLength"
                              data-test="matter-title-edit-name-input"
                              label="Matter name" />
                <ow-button :disabled="loadingState.updateMatterName"
                           class="matter-details__form--btn"
                           is-secondary
                           data-test="matter-title-edit-cancel-btn"
                           @click="cancelEdit">
                    Cancel
                </ow-button>
                <ow-button :disabled="!isValidUpdate"
                           class="matter-details__form--btn"
                           is-primary
                           :loading="loadingState.updateMatterName"
                           data-test="matter-title-edit-update-btn"
                           @click="updateMatterName">
                    Update
                </ow-button>
            </form>
        </div>

        <div class="flex xs-12">
            <p class="flex content__subheading--disabled matter-title__created-by">
                <span v-if="details.createdByMe"
                      data-test="matter-title-created-text">Created by me </span>
                <span v-if="!details.createdByMe"
                      data-test="matter-title-created-text">Created by {{ details.createdByUserEmail }} </span>
                <span class="hide-in-percy">| Created on: {{ formatDate(details.created) }}</span>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
    import { mapState } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import matterActivityToggle from '@/components/matter/matter-activity-toggle.vue'
    import useDates from '@/composables/use-dates'
    import { MATTER_MAX_NAME_LENGTH } from '@/consts/matter'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'MatterTitle',

        components: {
            OwButton,
            matterActivityToggle,
            OwCardActions,
        },

        mixins: [flagsMixin],

        props: {
            details: {
                type: Object,
                require: true,
            },
            loading: {
                type: Boolean,
                require: false,
            },
        },

        emits: [
            'update',
            'delete',
        ],

        data() {
            return {
                OwTooltipPosition,
                editMatterName: false,
                matterName: '',
                showDeleteDialog: false,
                maxNameLength: MATTER_MAX_NAME_LENGTH,
            }
        },

        computed: {
            ...mapState({
                loadingState: state => state.matter.loadingState,
            }),

            isValidUpdate() {
                const hasName = !isNullOrWhitespace(this.matterName)
                const nameLengthIsValid = this.matterName?.length <= MATTER_MAX_NAME_LENGTH
                const hasChanges = this.matterName !== this.details.name

                return hasName && hasChanges && nameLengthIsValid
            },

        },

        watch: {
            loading() {
                this.editMatterName = this.loading ? this.editMatterName : false
            },

            'loadingState.updateMatterName'() {
                this.editMatterName = this.loadingState.updateMatterName || false
            },

            details: {
                handler(val) {
                    if (this.name !== val.name) {
                        this.name = val.name
                    }
                },
                deep: true,
            },
        },

        mounted() {
            this.matterName = this.details.name
        },

        methods: {
            updateMatterName() {
                this.$emit('update', this.matterName)
            },

            deleteMatter() {
                this.$emit('delete', this.details.id)
                this.showDeleteDialog = false
            },

            cancelEdit() {
                this.editMatterName = false
                this.matterName = this.details.name
            },

            formatDate(value: Date): string {
                const { formatDate } = useDates()
                return formatDate(value)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import './matter-title';
</style>
