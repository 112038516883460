<template>
    <article :class="{
                 'ow-card__has-outline': hasOutline,
                 'ow-card--no-padding': noPadding,
             }"
             class="ow-card">
        <div v-if="title"
             class="ow-card__title-wrapper d-flex justify-space-between">
            <div class="d-flex align-center gc-2">
                <div>
                    <h6 v-dompurify-html="title"
                        class="ow-card__title"
                        data-test="ow-card-title" />
                    <slot name="title-suffix" />
                    <ow-colored-label v-if="label || hasBetaLabel"
                                      :is-beta-label="hasBetaLabel"
                                      :is-small="true"
                                      margin="0 0 0 12">
                        {{ label }}
                    </ow-colored-label>
                </div>
                <slot name="title-toggle" />
            </div>
        </div>
        <ow-card-skeleton v-if="loading && loadingSkeleton && !loadingSkeleton?.displayInline"
                          :circle="loadingSkeleton?.circle"
                          :columns="loadingSkeleton?.columns"
                          :expand-rows="loadingSkeleton?.expandRows"
                          :ctas="loadingSkeleton?.ctas"
                          :reverse="loadingSkeleton?.reverse"
                          :rows="loadingSkeleton?.rows" />
        <template v-else>
            <div v-if="subtitle"
                 v-dompurify-html="subtitle"
                 class="ow-card__subtitle"
                 data-test="ow-card-subtitle" />
            <img v-if="hasHmlrBadge"
                 alt="Logo of Her Majesty's Land Registry"
                 class="ow-card__hmlr-badge"
                 src="../../media/HMLR.jpg" />

            <div v-if="isActionsSlotValid"
                 class="ow-card__actions-wrapper"
                 data-test="ow-card-actions-wrapper">
                <slot name="actions" />
            </div>

            <div v-if="loading && loadingSkeleton && loadingSkeleton?.displayInline"
                 class="mb-3">
                <ow-card-skeleton :circle="loadingSkeleton?.circle"
                                  :columns="loadingSkeleton?.columns"
                                  :expand-rows="loadingSkeleton?.expandRows"
                                  :ctas="loadingSkeleton?.ctas"
                                  :reverse="loadingSkeleton?.reverse"
                                  :rows="loadingSkeleton?.rows" />
            </div>
            <slot name="default" />

            <div v-if="hasInfoContent"
                 class="ow-card__info-content-wrapper">
                <div v-for="(infoContentData, i) in computedInfoContent"
                     :key="i"
                     class="ow-card__info-content"
                     :data-track="infoContentDataTrack"
                     :class="`${ infoContentData.class }`">
                    <ow-icon-text :icon="infoContentData.icon"
                                  :is-italic-text="true"
                                  :is-single-line="false"
                                  :text="infoContentData.text" />
                </div>
            </div>
        </template>
    </article>
</template>

<script lang="ts">
    import { useSlots } from "vue"

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import OwIconText from '@/components/core/ow-icon-text.vue'
    import OwCardSkeleton from '@/components/loading-skeletons/ow-card-skeleton.vue'
    import { ColorClass } from '@/enums/color-class.enum'
    import { IInfoContentItem } from '@/interfaces/core-components/ow-card-info-content-item.interface'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import { LoadingSkeletonOptions } from './types/ow-card'

    export default {
        name: 'OwCard',

        components: {
            OwColoredLabel,
            OwIconText,
            OwCardSkeleton,
        },

        props: {
            title: {
                type: String,
                required: false,
                default: null,
            },
            subtitle: {
                type: String,
                required: false,
                default: null,
            },
            hasOutline: {
                type: Boolean,
                required: false,
                default: false,
            },
            hasHmlrBadge: {
                type: Boolean,
                required: false,
            },
            label: {
                type: String,
                required: false,
                default: null,
            },
            hasBetaLabel: {
                type: Boolean,
                required: false,
            },
            infoContent: {
                type: Array as () => IInfoContentItem[],
                required: false,
                default: () => [],
            },
            infoContentDataTrack: {
                type: String,
                required: false,
                default: 'ow-card-info-content',
            },
            loading: {
                type: Boolean,
                required: false,
                default: undefined,
            },
            loadingSkeleton: {
                type: Object as () => LoadingSkeletonOptions,
                required: false,
                default: null,
            },
            noPadding: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                slots: useSlots(),
            }
        },

        computed: {
            hasInfoContent(): boolean {
                return !isNullOrWhitespace(this.infoContent) &&
                    !isNullOrWhitespace(this.computedInfoContent[0]?.text)
            },
            computedInfoContent(): IInfoContentItem[] {
                return (this.infoContent || []).map((infoContentData: IInfoContentItem) => {
                    return {
                        ...infoContentData,
                        class: infoContentData.class || ColorClass.Warning,
                        icon: infoContentData.icon || '$flag',
                    }
                })
            },
            isActionsSlotValid(): boolean {
                // only allow ow-button components in the actions slot
                const actionSlot = this.slots.actions
                if(!actionSlot) {
                    return false
                }

                return this.slots.actions()
                    .every((slot: any) => slot?.type?.name === 'OwButton')
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-card.scss';
</style>
