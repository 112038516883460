// Getters

// Mutations
export const SITE_VISIT_MUTATE_ENABLED = 'SITE_VISIT_MUTATE_ENABLED'
export const SITE_VISIT_MUTATE_BOUNDS = 'SITE_VISIT_MUTATE_BOUNDS'
export const SITE_VISIT_MUTATE_STARTING_LOCATION = 'SITE_VISIT_MUTATE_STARTING_LOCATION'
export const SITE_VISIT_MUTATE_PANORAMA_HEADING = 'SITE_VISIT_MUTATE_PANORAMA_HEADING'
export const SITE_VISIT_MUTATE_PANORAMA_LOCATION = 'SITE_VISIT_MUTATE_PANORAMA_LOCATION'
export const SITE_VISIT_MUTATE_POINTER_LOCATION = 'SITE_VISIT_MUTATE_POINTER_LOCATION'

// Actions
export const SITE_VISIT_INIT_GOOGLE_MAPS = 'SITE_VISIT_INIT_GOOGLE_MAPS'
export const SITE_VISIT_SET_ENABLED = 'SITE_VISIT_SET_ENABLED'
export const SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER = 'SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER'
export const SITE_VISIT_LOOK_AT_SELECTED_TITLE = 'SITE_VISIT_LOOK_AT_SELECTED_TITLE'
