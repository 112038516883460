<template>
    <div id="organisation-charges-page">
        <div class="fixed-header">
            <header>
                <h1 class="content__heading">
                    Charges
                </h1>
            </header>
        </div>
        <app-charges />
    </div>
</template>

<script>
    import Charges from '@/components/organisation/organisation-charges.vue'

    export default {
        name: 'OrganisationCharges',

        components: {
            appCharges: Charges,
        },
    }
</script>

<style lang="scss">
    @import './organisation-charges';
</style>
