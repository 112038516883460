<template>
    <div v-if="chart"
         class="d-flex flex-column epc-chart">
        <p class="body-copy--greyout epc-table-chart__address">
            {{ $t('titlePanel.cards.epc.epcAddress') }}
        </p>
        <p>{{ chart.address }}</p>
        <div class="epc-table-chart__dates">
            <div class="epc-table-chart__date-wrapper">
                <p class="body-copy--greyout epc-table-chart__label">
                    {{ $t('titlePanel.cards.epc.issuedOn') }}
                </p>
                <p>{{ format(chart.lodgementDateTime) }}</p>
            </div>
            <div class="epc-table-chart__date-wrapper">
                <p class="body-copy--greyout epc-table-chart__label">
                    {{ $t('titlePanel.cards.epc.expiryDate') }}
                </p>
                <div v-if="showExpiryInformation"
                     class="epc-table-chart__expiry"
                     data-test="epc-chart-expiry">
                    <p data-test="epc-chart-expiry-value">
                        {{ getExpiryDate(chart.expiryDate) }}
                    </p>
                    <ow-colored-label v-if="chart.expired"
                                      data-test="epc-chart-expiry-expired"
                                      is-small
                                      theme="expired">
                        {{ $t('titlePanel.cards.epc.expired') }}
                    </ow-colored-label>
                </div>
            </div>
        </div>
        <domestic-epc-chart v-if="chart.source === EPCSource.Domestic"
                            :energy-band="chart.energyBand"
                            :energy-rating="chart.energyRating"
                            :potential-energy-band="chart.potentialEnergyBand"
                            :potential-energy-rating="chart.potentialEnergyRating" />
        <non-domestic-epc-chart v-else-if="chart.source === EPCSource.NonDomestic"
                                :energy-band="chart.energyBand"
                                :energy-rating="chart.energyRating" />
        <dec-epc-chart v-else-if="chart.source === EPCSource.Display"
                       :energy-band="chart.energyBand"
                       :energy-rating="chart.energyRating" />
        <p v-else
           v-t="'titlePanel.cards.epc.noEpcChart'" />
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from "vue"

    import OwColoredLabel from "@/components/core/ow-colored-label.vue"
    import DecEpcChart from "@/components/epc/dec-epc-chart.vue"
    import DomesticEpcChart from "@/components/epc/domestic-epc-chart.vue"
    import NonDomesticEpcChart from "@/components/epc/non-domestic-epc-chart.vue"
    import { IEpcTableRow } from "@/components/title-panel/v2/epc/epc-table.vue"
    import { EPCSource } from "@/enums/epc.enum"
    import { isNullOrWhitespace } from "@/utils/string-utils"

    import { format } from "../../../../utils/date-utils"
    import { getExpiryDate } from "./util"

    export interface IEpcTableChart {
        source: string
        energyBand: string
        energyRating: string
        expiryDate: string
        lodgementDateTime: Date
        expired: boolean
        potentialEnergyBand: string
        potentialEnergyRating: string
        address: string
    }

    const props = defineProps<{
        selectedItem: IEpcTableRow
    }>()

    const chart = ref<IEpcTableChart>()

    const showExpiryInformation = computed(() => !isNullOrWhitespace(chart.value?.expiryDate))

    watch(() => props.selectedItem, (newValue) => {
        if (!newValue) {
            return
        }
        chart.value = {
            address: newValue.address,
            energyBand: newValue.energyBand,
            expiryDate: newValue.expiryDate,
            energyRating: newValue.energyRating,
            expired: newValue.expired,
            lodgementDateTime: newValue.lodgementDateTime,
            potentialEnergyBand: newValue.potentialEnergyBand,
            source: newValue.source,
            potentialEnergyRating: newValue.potentialEnergyRating,
        }
    }, {
        immediate: true,
        deep: true,
    })
</script>

<style lang="scss">
@import './epc-table-chart.scss';
</style>

