<template>
    <ow-page-layout class="reporting-select-titles"
                    header-icon="$reporting"
                    header-title="Reporting"
                    page-name="reporting-select-titles">
        <template #navigation>
            <router-link :to="{ name: Route.Reports }"
                         class="content__subheading"
                         data-track="REPORTING-SELECT-TITLES - Navigate back">
                <v-icon>$chevron-left</v-icon>
                <span>Back</span>
            </router-link>
        </template>

        <section v-if="selectedExportOption"
                 class="reporting-select-titles__content">
            <div class="reporting-select-titles__content--title-select">
                <template-card :interactive="false"
                               :template="selectedExportOption">
                    <span v-dompurify-html="selectedExportOption.description" />
                </template-card>
                <title-select-card :matters="openMatters"
                                   @items-selected="handleSelectTitles" />
                <ow-card class="sidebar"
                         has-outline>
                    <h4>
                        <span>{{ titleSelectedText }}</span>
                        <span data-test="selected-titles-count">{{ selectedTitles.length }}</span>
                    </h4>
                    <ow-button :disabled="selectedTitles.length === 0"
                               data-test="continue-button"
                               data-track="REPORTING - Confirm selected titles"
                               large
                               @click="handleConfirmTitles">
                        {{ confirmText }}
                    </ow-button>
                </ow-card>
            </div>
        </section>
    </ow-page-layout>
</template>

<script lang="ts">
    import pluralize from 'pluralize'
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwPageLayout from '@/components/core/layout/page.vue'
    import OwButton from '@/components/core/ow-button.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import TemplateCard from '@/components/reporting/template-card.vue'
    import TitleSelectCard from '@/components/reporting/title-select-card.vue'
    import { MatterState } from '@/consts/matter'
    import { Route } from '@/enums/route.enum'
    import { IDataGridItem } from '@/interfaces/core-components/data-grid-item.interface'
    import { IReportsState } from '@/interfaces/store/reports/reports-state.interface'
    import { BasicTitleInformation } from '@/models/basic-title-information.model'
    import { CheckoutItemDocumentOrder } from '@/models/checkout/checkout-item-document-order.model'
    import { CheckoutItemReportOrder } from '@/models/checkout/checkout-item-report-order.model'
    import { DocumentOrder } from '@/models/store/document-ordering/document-order.model'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ADD_ITEMS_TO_CART } from '@/store/modules/checkout/types'

    export default {
        name: 'ReportingSelectTitles',

        components: {
            OwPageLayout,
            OwButton,
            OwCard,
            TemplateCard,
            TitleSelectCard,
        },

        data() {
            return {
                selectedTitles: [],
                Route,
            } as {
                selectedTitles: IDataGridItem[],
                Route: typeof Route
            }
        },

        computed: {
            ...mapState({
                matters: (state: any) => state.matter.matters || [],
            }),

            ...mapState('reports', {
                selectedExportOption: (state: IReportsState) => state.selectedExportOption,
            }),

            openMatters() {
                return this.matters.filter(matter => matter.state === MatterState.open)
            },

            titleSelectedText() {
                return `Selected ${ pluralize('title', this.selectedTitles.length) }`
            },

            confirmText() {
                return `Confirm ${ pluralize('title', this.selectedTitles.length) }`
            },
        },

        async mounted() {
            if (!this.selectedExportOption) {
                await this.$router.push({ name: Route.Reports })
            }
        },

        methods: {
            ...mapActions('checkout', {
                addItemsToCart: ADD_ITEMS_TO_CART,
            }),

            handleSelectTitles(titles: IDataGridItem[]) {
                this.selectedTitles = titles
            },

            async handleConfirmTitles() {
                const reportOrderItem = new CheckoutItemReportOrder(
                    this.selectedExportOption.name,
                    0,
                    this.selectedTitles.map(title => title.titleNumber),
                    this.selectedExportOption)

                const registerOrderItems = this.selectedTitles
                    .filter(title => !title.isOrdered)
                    .map(title =>
                        new CheckoutItemDocumentOrder(
                            title.titleNumber,
                            new BasicTitleInformation(
                                title.titleNumber,
                                title.titleInformation.tenure,
                                title.titleInformation.addresses[0]?.address),
                            new DocumentOrder(
                                title.titleNumber,
                                DocumentOrderRequest.createRegisterRequest(
                                    title.titleNumber,
                                ),
                                title.hmlrAvailabilityCode),
                            true))

                await this.addItemsToCart([
                    reportOrderItem,
                    ...registerOrderItems,
                ])

                await this.$router.push({ name: Route.CheckoutReview })
            },
        },

    }
</script>

<style lang="scss">
    @import './reporting-select-titles';
</style>
