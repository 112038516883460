<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 36 44"
                  desc="Title register icon">
        <path style="stroke:none;fill-rule:evenodd;fill:#185d8c;fill-opacity:1"
              d="M0 0h28.46l7.302 7.328V43H.004Zm3.633 3.582.004 35.836h28.492V8.797l-5.191-5.215Zm0 0" />
        <path style="stroke:none;fill-rule:evenodd;fill:#62b0e4;fill-opacity:1"
              d="M9.219 17.605h17.09v3.586H9.219Zm0 6.813h17.09v3.586H9.219Zm0 0" />
        <path style="fill:none;stroke-width:9.079;stroke-linecap:butt;stroke-linejoin:miter;stroke:#185d8c;stroke-opacity:1;stroke-miterlimit:4"
              d="M67.012.01v22.705h22.304"
              transform="scale(.4 .3945)" />
        <path style="stroke:none;fill-rule:nonzero;fill:#185d8c;fill-opacity:1"
              d="M16.887 7.016H9.219v7.125h4.844l2.828-2.79V7.017Zm0 0" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'OwIconTitleRegister',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                required: false,
                default: '36px',
            },
            height: {
                type: String,
                required: false,
                default: '44px',
            },
        },
    }
</script>
