<template>
    <div class="matter-titles-loading-skeleton">
        <ow-loading-skeleton class="matter-titles-loading-skeleton__add-titles"
                             width="372px"
                             height="40px"
                             pill />
        <div class="matter-titles-loading-skeleton__filters">
            <div class="matter-titles-loading-skeleton__filters--select-all">
                <ow-loading-skeleton width="110"
                                     height="16px"
                                     pill />
            </div>
            <ow-loading-skeleton width="92"
                                 height="32px"
                                 pill />
            <ow-loading-skeleton width="114"
                                 height="32px"
                                 pill />
        </div>
        <div class="matter-titles-loading-skeleton__titles">
            <div v-for="i in 10"
                 :key="i"
                 class="matter-titles-loading-skeleton__title-item">
                <div class="matter-titles-loading-skeleton__title-item-left">
                    <ow-loading-skeleton width="80"
                                         height="16px"
                                         pill />
                    <ow-loading-skeleton width="168"
                                         height="16px"
                                         margin-top="5"
                                         pill />
                </div>
                <div class="matter-titles-loading-skeleton__title-item-right">
                    <ow-loading-skeleton width="24"
                                         margin-right="8"
                                         :circle="true" />
                    <ow-loading-skeleton width="24"
                                         margin-right="8"
                                         :circle="true" />
                    <ow-loading-skeleton width="24"
                                         :circle="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import owLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'

    export default {
        components: {
            owLoadingSkeleton,
        },
    }
</script>

<style lang="scss">
    @import './matter-titles-loading-skeleton.scss'
</style>
