import httpClient from '@/api/http-client'

export default class FeaturesApi {
    static END_POINT = 'features'

    /**
     * Enables a feature for the current user
     * @param key {String} The key of the feature
     * @returns {Promise<T | *>}
     */
    static async enableFeature(key: string) {
        const uri = `features/${ key }/enable`
        await httpClient.post(uri, {})
    }

    /**
     * Disables a feature for the current user
     * @param key {String} The key of the feature
     * @returns {Promise<T | *>}
     */
    static async disableFeature(key: string) {
        const uri = `features/${ key }/disable`
        await httpClient.post(uri, {})
    }
}
