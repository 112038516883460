import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
import { isNullOrWhitespace } from "@/utils/string-utils"
import { computed } from "vue"
import { useRoute,
    useRouter } from "vue-router"
import { useStore } from "vuex"

export const useTitlePanelTabs = () => {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const selectedTitleNumber = computed(() => store.state.title.selectedTitleNumber)
    const activeTab = computed({
        get() {
            return convertHashToTabName(route.hash) || TitlePanelTabName.Summary
        },
        set(val) {
            if (val !== activeTab.value) {
                setSelectedTab(val)
            }
        },
    })

    const convertHashToTabName = (hash) => {
        if (isNullOrWhitespace(hash)) {
            return ''
        }
        const tabValue = hash.replace('#', '').toLowerCase()
        return Object.values(TitlePanelTabName).find(tab => tab.toLowerCase() === tabValue)
    }

    const convertTabToHash = (tab) => {
        if (tab === TitlePanelTabName.Summary) {
            return ''
        }
        return `#${ tab }`
    }

    const setSelectedTab = (tab) => {
        const hash = convertTabToHash(tab)
        if (!route.params?.titleNumber || route.params?.titleNumber === selectedTitleNumber.value) {
            router.push({ hash })
        }
    }

    return {
        activeTab,
        setSelectedTab,
    }
}
