<template>
    <div class="side-nav-container"
         :class="{
             'side-nav-container--collapsed': isCollapsed,
             'side-nav-container--fullscreen': !isCollapsed,
         }">
        <div class="side-nav d-flex">
            <div class="d-flex flex-column justify-space-between w-100">
                <div class="side-nav__tabs-container w-100">
                    <ow-button v-if="isCollapsed && !isLoading && scrollLeft > 0"
                               class="side-nav__tabs__scroll-button side-nav__tabs__scroll-button--left"
                               small
                               is-flat
                               @click="onScrollLeft">
                        <template #iconPrefix>
                            <v-icon>$menu-left</v-icon>
                        </template>
                    </ow-button>
                    <div ref="tabsElement"
                         class="side-nav__tabs d-flex"
                         data-test="side-nav-tabs">
                        <side-nav-tab v-for="tab in items"
                                      :key="tab.key"
                                      v-model="tab.isExpanded"
                                      :feature-id="tab.featureId"
                                      :data-test="tab?.dataTestId ?? `side-nav-section-${tab.title}`"
                                      :data-track="tab?.dataTrackId ?? `${dataTrackPrefix} - Click tab ${tab.title}`"
                                      :has-sections="!isNullOrEmpty(tab?.sections)"
                                      :is-collapsed="isCollapsed"
                                      :title="tab.title"
                                      :is-loading="isLoading"
                                      :class="{
                                          'side-nav__tab': (isNullOrEmpty(tab?.sections) && !tab.isExpanded) || !tab.isExpanded,
                                      }"
                                      @click="onTabClick(tab, null)">
                            <side-nav-section v-for="tabSection in tab.sections"
                                              v-if="!isNullOrEmpty(tab?.sections)"
                                              :key="tabSection.id"
                                              :data-test="`side-nav-section-${tabSection.title}`"
                                              :data-track="tabSection?.dataTrackId ?? `${dataTrackPrefix} - Click section ${tabSection.title}`"
                                              class="side-nav__section"
                                              :selected="tabSection.id === sectionModel"
                                              :title="tabSection.title"
                                              :is-loading="isLoading"
                                              @click="onTabSectionClick(tab, tabSection)" />
                        </side-nav-tab>
                    </div>
                    <ow-button v-if="isCollapsed && !isLoading && scrollLeft <= scrollMax"
                               class="side-nav__tabs__scroll-button"
                               small
                               is-flat
                               @click="onScrollRight">
                        <template #iconPrefix>
                            <v-icon>$menu-right</v-icon>
                        </template>
                    </ow-button>
                </div>
                <div class="side-nav__empty flex-grow-1" />
                <side-nav-actions v-if="!isNullOrEmpty(actions)"
                                  class="side-nav__actions py-6"
                                  data-test="side-nav-actions"
                                  :is-loading="isLoading"
                                  :has-drop-shadow="sideNavScrolling"
                                  :data-track-prefix="dataTrackPrefix"
                                  :actions="actions"
                                  @click="onActionClick" />
            </div>
        </div>
        <div :id="scrollElementId"
             class="side-nav-container__slot"
             :class="{
                 [contentClass]: contentClass,
             }">
            <slot v-bind="{
                tab: model,
                section: sectionModel,
            }" />
        </div>
    </div>
</template>


<script setup lang="ts">

    import { debounce } from "lodash"
    import {
        computed,
        nextTick,
        onBeforeUnmount,
        onMounted,
        onUpdated,
        ref,
        watch,
    } from "vue"

    import OwButton from "@/components/core/ow-button-ds.vue"
    import {
        ISideNavAction,
        ISideNavSection,
        ISideNavTab,
    } from "@/components/side-nav"
    import SideNavActions from "@/components/side-nav/side-nav-actions.vue"
    import SideNavSection from "@/components/side-nav/side-nav-section.vue"
    import SideNavTab from "@/components/side-nav/side-nav-tab.vue"
    import useWindowResize from "@/composables/use-window-resize"
    import { isNullOrEmpty } from "@/utils/array-utils"

    const props = withDefaults(defineProps<{
        contentClass?: string;
        scrollElementId?: string;
        items: ISideNavTab[]
        actions?: ISideNavAction[]
        dataTrackPrefix?: string
        isLoading?: boolean
        disableScrollToSection?: boolean
        isCollapsed?: boolean
    }>(), {
        actions: () => [],
        dataTrackPrefix: 'Sidebar',
        scrollElementId: 'sideNavContent',
        disableScrollToSection: false,
        isLoading: false,
        isCollapsed: false,
    })

    const sideNavScrolling = ref(false)
    const sectionClicked = ref(false)

    const model = defineModel<string>()
    const sectionModel = defineModel<string>('section')

    const { debouncedResize } = useWindowResize({
        onResize: () => {
            const sideNav = document.querySelector('.side-nav__tabs-container')
            sideNavScrolling.value = sideNav?.scrollHeight > sideNav?.clientHeight

            scrollMax.value = Math.round(tabsElement.value?.scrollWidth - tabsElement.value?.clientWidth)
        },
    })

    const tabsElement = ref()
    const scrollLeft = ref(0)
    const scrollMax = ref(0)

    const onScrollLeft = () => {
        if (tabsElement.value) {
            tabsElement.value.scrollLeft = 0
            scrollLeft.value = 0
        }
    }

    const onScrollRight = () => {
        if (tabsElement.value) {

            // scroll to the end
            tabsElement.value.scrollLeft = tabsElement.value.scrollWidth
            scrollLeft.value = tabsElement.value.scrollLeft
        }
    }

    const emit = defineEmits<{
        (e: 'click', args: { tabId: string, sectionId: string }): void,
        (e: 'action', actionId: string): void,
        (e: 'scroll', scrollTop: number): void
    }>()

    const onTabClick = (tab: ISideNavTab, sectionId?: string, emitEvent = true) => {
        // Collapse all the tabs
        deselectAllTabs()
        deselectAllSections()

        // Toggle the clicked tab
        tab.isExpanded = true
        model.value = tab.key

        // If the tab has sections, select the first section
        // Otherwise, select the tab
        if (tab.sections && tab.sections.length > 0) {
            sectionModel.value = sectionId ?? tab.sections[0].id
        } else {
            sectionModel.value = tab.sections?.[0]?.id ?? null
        }

        // Emit the select event
        if (emitEvent) {
            emit('click', { tabId: tab.key, sectionId: sectionModel.value })
        }

        // scroll to top
        const elem = document.getElementById(props.scrollElementId)
        if (elem) {
            elem.scrollTop = 0
        }
    }

    const onTabSectionClick = (tab: ISideNavTab, section: ISideNavSection, flash: boolean = true) => {
        model.value = tab.key
        deselectAllSections()
        sectionModel.value = section.id
        sectionClicked.value = true

        emit('click', { tabId: tab.key, sectionId: section.id })
        if (section.onClick) {
            section.onClick()
        } else {
            scrollToSection(section.id)
            if (flash) {
                nextTick(() => {
                    flashSection(section)
                })
            }
        }

        // we need to reset the sectionClicked flag after a timeout
        // so that the scroll event does not deselect the section
        setTimeout(() => {
            sectionClicked.value = false
        }, 250)
    }

    const flashSection = (section: ISideNavSection) => {
        const sectionElement = document.querySelector(`[data-section=${ section.id }]`)
        if (sectionElement) {
            // flash the section element
            sectionElement.classList.add('--flash-section')
            setTimeout(() => {
                sectionElement.classList.remove('--flash-section')
            }, 1000)
        }
    }

    const onActionClick = (action: ISideNavAction) => {
        emit('action', action.id)

        if (action.onClick) {
            action.onClick()
        }
    }

    onMounted(() => {
        debouncedResize()
        const elem = document.getElementById(props.scrollElementId)
        if (elem) {
            elem.addEventListener('scroll', debouncedScrollHandler)
        }
    })

    onBeforeUnmount(() => {
        const elem = document.getElementById(props.scrollElementId)
        if (elem) {
            elem.removeEventListener('scroll', debouncedScrollHandler)
        }
    })

    const debouncedScrollHandler = debounce((e) => {
        handleScroll(e)
    }, 50)

    const handleScroll = (e: any) => {
        // Get the scroll position of the content element
        const elem = e.target
        const scrollPosition = elem.scrollTop
        emit('scroll', scrollPosition)

        if (props.disableScrollToSection) {
            return
        }

        // If a section was clicked, do not handle the scroll event
        // This is to prevent the section from being deselected
        if (sectionClicked.value) {
            return
        }

        // If the scroll position is greater than or equal to 0, find the section in view
        let sectionInView: ISideNavSection
        if (scrollPosition > 0) {

            // Get the section ids of the selected tab
            sectionInView = getSections().find(section => {
                const elem = document.querySelector(`[data-section=${ section.id }]`)
                if (elem) {
                    const rect = elem.getBoundingClientRect()
                    return rect.top >= 0 && rect.bottom <= window.innerHeight
                }
                return false
            })

            // If the last section is in view, select the last section
            // If the first section is in view, select the first section
            if (scrollPosition >= (elem.scrollHeight - elem.clientHeight - 1)) {
                sectionInView = getSections()[getSections().length - 1]
            }
        } else {
            sectionInView = getSections()[0]
        }

        // If a section is in view, select that section
        if (sectionInView) {
            sectionModel.value = sectionInView.id
        }
    }

    const getTab = () => {
        return props.items.find(tab => tab.key === model.value)
    }

    const getSections = () => {
        return getTab()?.sections ?? []
    }

    const deselectAllSections = () => {
        getSections().forEach(item => item.selected = false)
    }

    const deselectAllTabs = () => {
        props.items.forEach(tab => tab.isExpanded = false)
    }
    const scrollToSection = (sectionId: string) => {
        if (props.disableScrollToSection) {
            const elem = document.getElementById(props.scrollElementId)
            if (elem) {
                elem.scrollTop = 0
            }
            return
        }
        const section = document.querySelector(`[data-section=${ sectionId }]`)
        if (section) {
            section.scrollIntoView({
                behavior: 'instant',
                block: 'nearest',
            })
        }
    }

    watch(() => model.value, () => {
        const tab = props.items.find(tab => tab.key === model.value)
        if (tab) {
            onTabClick(tab, sectionModel?.value, false)
        }
    })

    watch(() => sectionModel.value, () => {
        const tab = props.items.find(tab => tab.key === model.value)
        const section = tab?.sections?.find(section => section.id === sectionModel.value)
        if (section) {
            onTabSectionClick(tab, section, false)
        }
    })

    watch(() => getTab()?.sections, async (sections) => {
        if (isNullOrEmpty(sections)) {
            return
        }
        await nextTick()
        const tab = props.items.find(tab => tab.key === model.value)
        onTabClick(tab, sectionModel?.value, false)
        const section = sections.find(section => section.id === sectionModel.value)
        if (!section) {
            return
        }
        onTabSectionClick(tab, section, false)
    }, {
        immediate: true,
        deep: true,
    })

    onUpdated(async () => {
        scrollMax.value = (tabsElement.value?.scrollWidth - tabsElement.value?.clientWidth) - 1
        scrollLeft.value = tabsElement.value?.scrollLeft
    })

</script>

<style lang="scss">
    @import './side-nav';
</style>
