import {
    NPS_GET_FEATURES_BY_TITLE_NUMBER,
    NPS_GET_FEATURES_BY_TITLE_NUMBERS,
} from './types.js'

export default {

    [NPS_GET_FEATURES_BY_TITLE_NUMBERS](state) {
        return (titleNumbers) => {
            if (titleNumbers) {
                const result = state.features
                    .filter(feature => titleNumbers.includes(feature.get('title_no')))
                    .map(f => f.clone())
                return result
            }
        }
    },

    [NPS_GET_FEATURES_BY_TITLE_NUMBER](state) {
        return (titleNumber) => {
            if (titleNumber) {
                const result = state.features
                    .filter(feature => titleNumber === feature.get('title_no'))
                    .map(f => f.clone())
                return result
            }
        }
    },

}
