import SearchApi from '@/api/search.api.ts'
import SecurityService from '@/api/security'
import {
    AUTH_FETCH_USER,
    AUTH_GET_NEW_TOKEN,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_MUTATE_ERROR,
    AUTH_MUTATE_LOADING,
    AUTH_MUTATE_USER,
    AUTH_SIGN_IN,
} from '@/store/modules/auth-oidc/types'

export default {
    async [AUTH_LOGIN]({ commit, dispatch }) {
        commit(AUTH_MUTATE_LOADING, true)
        try {
            const user = await dispatch(AUTH_FETCH_USER)
            if (!user) {
                await dispatch(AUTH_SIGN_IN)
            }
        } catch (err) {
            console.error(err)
            commit(AUTH_MUTATE_ERROR, err.message ? err.message : err)
        }

        commit(AUTH_MUTATE_LOADING, false)
    },

    async [AUTH_FETCH_USER]({ commit }) {
        try {
            const user = await SecurityService.getUser()

            if (user?.expired) {
                await SecurityService.signInSilent()
            }

            commit(AUTH_MUTATE_USER, user)
            return user
        } catch (err) {
            console.error(err)
            commit(AUTH_MUTATE_ERROR, err.message ? err.message : err)
        }
    },

    async [AUTH_SIGN_IN]({ commit, dispatch }) {
        try {
            await SecurityService.signIn()
            await dispatch(AUTH_FETCH_USER)
        } catch (err) {
            console.error(err)
            commit(AUTH_MUTATE_ERROR, err.message ? err.message : err)
        }
    },

    async [AUTH_LOGOUT]({ commit }) {
        try {
            await SearchApi.clearSearchHistory()
            await SecurityService.signOutRedirect()

            commit(AUTH_MUTATE_USER, null)
        } catch (err) {
            console.error(err)
            commit(AUTH_MUTATE_ERROR, err.message ? err.message : err)
        }
    },

    async [AUTH_GET_NEW_TOKEN]({ commit }) {
        try {
            await SecurityService.signInSilent()
        } catch (err) {
            console.error(err)
            commit(AUTH_MUTATE_ERROR, err.message ? err.message : err)
        }
    },

}
