// @/stores/asset-monitoring/actions.ts
import { HttpStatusCode } from 'axios'
import { not } from "ol/format/filter"

import NotificationsApi, {
    IGetNotificationResponseItem,
    IGetNotificationsRequest,
    INotificationMessageArguments,
    INotificationSettings,
} from '@/api/notifications.api'
import UserEmailPreferencesApi, { IUserEmailPreferences } from "@/api/user-email-preferences.api"
import { NotificationEntityType } from "@/components/asset-monitoring/notification-entity-type.enum"
import { ReadStatus } from "@/components/asset-monitoring/read-status.enum"
import {
    IAssetMonitoringNotificationType,
} from "@/stores/asset-monitoring/index"
import { convertToNotificationSubType } from '@/stores/asset-monitoring/utils'
import { isNullOrEmpty } from '@/utils/array-utils'
import { format } from "@/utils/date-utils"

async function getMappedNotifications(matterId: number, options: IGetNotificationsRequest): Promise<[Array<IGetNotificationResponseItem>, number]> {
    const response = await NotificationsApi.getNotificationsByMatter(matterId, options)

    let notifications: Map<string, IGetNotificationResponseItem> = new Map(response.data.userNotifications.map((notification: IGetNotificationResponseItem) => {
        return [
            notification.notificationId,
            notification,
        ]
    }))
    let totalNotifications = response.data.totalResults
    return [Array.from(notifications.values()), totalNotifications]
}

export default {

    getNotificationRequestModel(): IGetNotificationsRequest {
        const notificationType = this.notificationTypes[this.entityType]
        const { selectedEntityValues, selectedNotificationTypes, selectedNotificationSubTypes, filterText, sortBy, pageSize, pageIndex } = notificationType

        const model = {
            pageIndex,
            pageSize,
            sortBy,
            filters: filterText,
            types: selectedNotificationTypes,
            subTypes: selectedNotificationSubTypes,
            readStatus: this.showOnlyUnread ? ReadStatus[ReadStatus.UNREAD] : null,
            entityType: this.entityType,
            titleNumbers: this.entityType === NotificationEntityType.TitleNumber ? selectedEntityValues : [],
            companyNumbers: this.entityType === NotificationEntityType.CompanyNumber ? selectedEntityValues : [],
        }

        return model
    },

    /**
     * Retrieves notifications for a given matter.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     */
    async getNotifications(matterId: number): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]
        this.isLoading = true

        try {
            let [notifications, totalResults] = await getMappedNotifications(matterId, this.getNotificationRequestModel())

            notificationType.notifications = notifications
            notificationType.totalResults = totalResults
            notificationType.lastCheckedDate = format(new Date(), 'dd-MM-yyyy HH:mm')

            await this.getUnreadNotificationCounts(matterId)
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        } finally {
            this.isLoading = false
        }
    },

    /**
     * Retrieves the unread notification counts for a given matter.
     * @param matterId - The ID of the matter to retrieve notifications for.
     */
    async getUnreadNotificationCounts(matterId: number): Promise<void> {
        this.isLoading = true

        try {
            const response = await NotificationsApi.getUnreadNotificationsCount(matterId)
            this.unreadTitleNotifications = response.data.unreadTitleNotifications
            this.unreadCompanyNotifications = response.data.unreadCompanyNotifications
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        } finally {
            this.isLoading = false
        }
    },

    /**
     * Retrieves next notifications page for a given matter.
     * @param {number} matterId - The ID of the matter to retrieve notifications for.
     */
    async getNextNotificationsPage(matterId: number): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        try {
            notificationType.currentPageIndex++
            let [notifications, totalResults] = await getMappedNotifications(matterId, this.getNotificationRequestModel())

            notificationType.notifications.push(...notifications)
            notificationType.totalResults = totalResults
        } catch (error) {
            console.error('Error retrieving notifications:', error)
        }
    },

    /**
     * Updates the sort order for the notifications and retrieves the notifications for the specified matter.
     *
     * @param {string} sortBy - The sort order to be applied to the notifications.
     * @param {number} currentMatterId - The ID of the matter for which the notifications should be retrieved.
     *
     * @return {Promise<void>} - A promise that resolves once the sort order is updated and the notifications are retrieved.
     */
    async updateSortBy(sortBy: string, currentMatterId: number): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        notificationType.sortBy = sortBy
        notificationType.currentPageIndex = 0
        await this.getNotifications(currentMatterId)
    },

    /**
     * Updates the selected notification types and retrieves the notifications for that match.
     * @param matterId
     * @param types
     */
    async updateSelectedNotificationTypes(matterId: number, types: Array<string>): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        notificationType.selectedNotificationTypes = types
        notificationType.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Updates the selected notification subtypes and retrieves the notifications for that match.
     * @param matterId
     * @param values
     */
    async updateSelectedEntityValues(matterId: number, values: string[]): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        notificationType.selectedEntityValues  = values
        notificationType.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Updates the selected notification subtypes and retrieves the notifications for that match.
     * @param matterId
     * @param subTypes
     */
    async updateSelectedNotificationSubTypes(matterId: number, subTypes: string[]): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        notificationType.selectedNotificationSubTypes = subTypes
        notificationType.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Updates the notification messages for the current entity type.
     * @param messages - The notification messages to display.
     */
    async updateNotificationMessages(messages: Array<INotificationMessageArguments>): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        notificationType.notificationMessages = messages
    },

    /**
     * Clears the selected entity values and notification subtypes.
     * Without reloading the notifications.
     */
    clear() {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        notificationType.selectedEntityValues = []
        notificationType.selectedNotificationSubTypes = []
        notificationType.currentPageIndex = 0
    },

    /**
     * Adds a received notification to the `notifications` array.
     *
     * @param notification - The notification to be added.
     */
    addReceivedNotification(notification: INotificationMessageArguments) {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        if (isNullOrEmpty(notificationType.notificationMessages)) {
            notificationType.notificationMessages = new Array<INotificationMessageArguments>()
        }
        notificationType.notificationMessages.push({
            ...notification,
            type: convertToNotificationSubType(notification.type.toString()),
        })
    },

    /**
     * Dismisses a single user notification from the list of notifications.
     *
     * @param {number} matterId - The ID of the matter for which to dismiss the notification.
     * @param {string} notificationId - The ID of the notification to dismiss.
     */
    async dismissNotification(matterId: number, notificationId: string): Promise<void> {
        await this.dismissNotifications(matterId, [ notificationId ])
    },

    /**
     * Dismisses multiple user notifications from the list of notifications.
     *
     * @param {number} matterId - The ID of the matter for which to dismiss the notifications.
     * @param {string} notificationIds - The IDs of the notifications to dismiss.
     */
    async dismissNotifications(matterId: number, notificationIds: Array<string>): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        try {
            const response = await NotificationsApi.dismissNotifications(notificationIds, true)
            if (response.status === HttpStatusCode.Ok) {
                notificationIds.forEach((notificationId: string) => {
                    const notification = notificationType.notifications.find(n => n.notificationId === notificationId)
                    if (notification) {
                        notification.isRead = true
                    }
                })
            }
            await this.getUnreadNotificationCounts(matterId)
        } catch (e) {
            console.error('Error dismissing notifications:', e, notificationIds)
        }
    },

    /**
     * Retrieves the notification settings for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the notification settings.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of notification settings.
     * @throws {Error} - If an error occurs while retrieving the notification settings.
     */
    async getNotificationSettings(matterId: number): Promise<void> {
        try {
            this.isLoadingSettings = true
            const response = await NotificationsApi.getNotificationSettings(matterId)
            this.notificationSettings = {
                ...response.data,
                isActive: Object.values(response.data).some((value: boolean) => value === true),
            }
            this.areNotificationSettingsLoaded = true
        } catch (error) {
            if (error?.response?.status === HttpStatusCode.NotFound) {
                // If monitoring hasn't been setup for this matter, then get not found back from the API
                this.notificationSettings = {
                    trackDaylist: false,
                    trackOcda: false,
                    trackOwnership: false,
                    trackBoundary: false,
                    isActive: false,
                }
            } else{
                console.error('Error retrieving notification settings:', error)
            }
        } finally {
            this.isLoadingSettings = false
        }
    },

    /**
     * Updates the notification settings for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {INotificationSettings} request - The notification settings to be updated.
     * @return {Promise<void>} A promise that resolves when the notification settings are updated successfully.
     */
    async updateNotificationSettings(matterId: number, request: INotificationSettings): Promise<void> {
        try {
            // Doesn't return any data, so fire and forget
            this.isUpdatingSettings = true
            await NotificationsApi.updateNotificationSettings(matterId, request)
            this.notificationSettings = request

            // Reload the notification settings after updating
            await this.getNotificationSettings(matterId)

        } catch (error) {
            console.error('Error updating notification settings:', error)
        } finally {
            this.isUpdatingSettings = false
        }
    },

    /**
     * Updates the selected read status subtypes and retrieves the notifications for that match.
     * @param matterId
     * @param readStatus
     */
    async updateShowUnreadOnly(matterId: number, readStatus: boolean): Promise<void> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[this.entityType]

        notificationType.showOnlyUnread = readStatus
        notificationType.currentPageIndex = 0
        await this.getNotifications(matterId)
    },

    /**
     * Retrieves the user's email preferences for a given matter.
     *
     * @param {number} matterId - The ID of the matter for which to retrieve the preferences.
     * @return {Promise<void>} - A promise that resolves with no value upon successful retrieval of the preferences.
     * @throws {Error} - If an error occurs while retrieving the preferences.
     */
    async getUserEmailPreferences(matterId: number): Promise<void> {
        try {
            this.isLoadingUserEmailPreferences = true
            const response = await UserEmailPreferencesApi.getUserEmailPreferences(matterId)
            this.userEmailPreferences = {
                ...response.data,
            }
            this.areUserEmailPreferencesLoaded = true
        } catch (error) {
            console.error('Error retrieving notification settings:', error)
        } finally {
            this.isLoadingUserEmailPreferences = false
        }
    },

    /**
     * Updates the user email preferences for a specific matter.
     *
     * @param {number} matterId - The ID of the matter.
     * @param {IUserEmailPreferences} request - The user email preferences to be updated.
     * @return {Promise<void>} A promise that resolves when the email preferences are updated successfully.
     */
    async updateUserEmailPreferences(matterId: number, request: IUserEmailPreferences): Promise<void> {
        try {
            this.isUpdatingUserEmailPreferences = true
            await UserEmailPreferencesApi.updateUserEmailPreferences(matterId, request)
            this.userEmailPreferences = request
        } catch (error) {
            console.error('Error updating user email preferences:', error)
        } finally {
            this.isUpdatingUserEmailPreferences = false
        }
    },

    initialise(matterId: number) {
        this.clear()
        this.unreadTitleNotifications = 0
        this.unreadCompanyNotifications = 0

        this.getNotifications(matterId)
        this.getNotificationSettings(matterId)
        this.getUserEmailPreferences(matterId)
    },

}
