import OrganisationsApi from '@/api/organisations.api'
import PricingApi from '@/api/pricing.api'
import { LOGGING_LOG_AXIOS_ERROR } from '@/store/mutation-types'

import {
    ADD_ORGANISATION_SECTION,
    CREATE_ORGANISATION,
    DELETE_ORGANISATION_SECTION,
    LOAD_LIST,
    LOAD_PRICING_MODELS,
    LOAD_PROFILE,
    MUTATE_AVAILABLE_PRODUCTS,
    MUTATE_LIST,
    MUTATE_PRICING_MODELS,
    MUTATE_PROFILE,
    RELOAD_PRODUCTS,
    UPDATE_ORGANISATION,
    UPDATE_ORGANISATION_SECTION,
} from './types'

export default {
    async [LOAD_LIST]({ commit }) {
        const data = await OrganisationsApi.get()
        commit(MUTATE_LIST, data)
    },

    async [LOAD_PROFILE]({ commit }) {
        const data = await OrganisationsApi.getProfile()
        commit(MUTATE_PROFILE, data)
    },

    async [UPDATE_ORGANISATION]({ dispatch }, organisation) {
        const response = await OrganisationsApi.update({ model: organisation })

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.data)
            return null
        }

        return response
    },

    async [LOAD_PRICING_MODELS]({ dispatch, commit }) {
        const response = await PricingApi.loadPricingModels()

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.data)
            return null
        }

        const pricingModels = response.data

        commit(MUTATE_PRICING_MODELS, pricingModels)

        return pricingModels
    },

    async [RELOAD_PRODUCTS]({dispatch, commit}) {
        const response = await OrganisationsApi.getProducts()
        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, 'Failed to load available products')
            return null
        }
        commit(MUTATE_AVAILABLE_PRODUCTS, response)
        return response
    },

    async [CREATE_ORGANISATION]({ dispatch }, organisation) {
        const response = await OrganisationsApi.create({ model: organisation })

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.data)
            return null
        }

        return response
    },

    async [ADD_ORGANISATION_SECTION]({ dispatch }, request) {
        const response = await OrganisationsApi.addOrganisationSection({ model: request })

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.data)
            return null
        }

        return response
    },

    async [UPDATE_ORGANISATION_SECTION]({ dispatch }, request) {
        const response = await OrganisationsApi.updateOrganisationSection({ model: request })

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.data)
            return null
        }

        return response
    },

    async [DELETE_ORGANISATION_SECTION]({ dispatch }, id) {
        const response = await OrganisationsApi.deleteOrganisationSection({
            request: {
                id,
            },
        })

        if (response?.ok === false) {
            dispatch(LOGGING_LOG_AXIOS_ERROR, response.data)
            return null
        }

        return response
    },

}
