// Getters
export const GET_LIST = 'GET_LIST'
export const GET_PROFILE = 'GET_PROFILE'

// Mutations
export const MUTATE_LIST = 'MUTATE_LIST'
export const MUTATE_PROFILE = 'MUTATE_PROFILE'
export const MUTATE_PRICING_MODELS = 'MUTATE_PRICING_MODELS'
export const MUTATE_AVAILABLE_PRODUCTS = 'MUTATE_AVAILABLE_PRODUCTS'
export const MUTATE_SELECTED_ORGANISATION = 'MUTATE_SELECTED_ORGANISATION'
export const MUTATE_SELECTED_ORGANISATION_SECTION = 'MUTATE_SELECTED_ORGANISATION_SECTION'
export const MUTATE_INITIALISE_SELECTED_ORGANISATION = 'MUTATE_INITIALISE_SELECTED_ORGANISATION'

// Actions
export const LOAD_LIST = 'LOAD_LIST' // retrieves a list of organisations, only used by system admin functions
export const LOAD_PROFILE = 'LOAD_PROFILE' // gets the profile for the users assigned organisation
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION'
export const LOAD_PRICING_MODELS = 'LOAD_PRICING_MODELS'
export const RELOAD_PRODUCTS = 'RELOAD_PRODUCTS'
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION'
export const ADD_ORGANISATION_SECTION = 'ADD_ORGANISATION_SECTION'
export const UPDATE_ORGANISATION_SECTION = 'UPDATE_ORGANISATION_SECTION'
export const DELETE_ORGANISATION_SECTION = 'DELETE_ORGANISATION_SECTION'
