import i18n from '@/plugins/i18n'
import { IMatterGroup } from '@/interfaces/matter-group.interface'
import { ITitleInMatterReference } from '@/interfaces/title-in-matter.reference'
import TitleInformationApi from '@/api/title-information.api'
import { TitleOrganiserBaseService } from '@/store/modules/matter/title-organiser/services/_title-organiser-base'
import { TitleOrganiserResult } from '@/store/modules/matter/title-organiser/title-organiser-result.type'

export class TitleOrganiserOwnerService extends TitleOrganiserBaseService {
    id = 'owner'

    public async getMatterContents(matterId: string, currentTitles: Array<ITitleInMatterReference>, currentGroups: Array<IMatterGroup>,
        applyStyling: boolean): Promise<TitleOrganiserResult> {
        this.isLoading = true
        // Create owner groups, accounting for the possibility that there are no/multiple owners per title.
        const ownerData = await TitleInformationApi.getOwnerNamesByTitleNumbers(matterId, currentTitles.map(t => t.titleNumber))
        const boundaryAvailable = (titleNumber: string) => currentTitles.find(t => t.titleNumber === titleNumber)?.boundaryAvailable ?? false
        const hasUnknownOwner = ownerData.some(t => t.ownerKey === null)

        // Allocate titles to an 'Unknown' group if there is no owner data.
        const unknownGroupName = i18n.global.t('matter.titleOrganiser.unknownGroupName') as string

        // Create a list of unique owner keys, and sort them, then create groups from them and assign titles.
        const ownerKeys = [...new Set(ownerData.map(t => t.ownerKey).filter(t => t !== null))]
        ownerKeys.sort()
        if (hasUnknownOwner) {
            ownerKeys.push(unknownGroupName)
        }

        const groups = []
        const titles = []

        let runningSortOrder = 100
        let runningPaletteIndex = 0
        ownerKeys.forEach((ownerKey: string, i) => {
            let useGroupName
            const groupNameArr = ownerData.find(t => t.ownerKey === ownerKey)?.owners ?? []
            if (groupNameArr.length > 1) {
                useGroupName = groupNameArr
                    .map((name, nameIndex) => `(${ nameIndex + 1 }) ${ name }`)
                    .join('. ')
            } else if (groupNameArr.length === 1) {
                useGroupName = groupNameArr[0]
            } else {
                useGroupName = unknownGroupName
            }
            groups.push({
                id: i,
                name: useGroupName,
                sortOrder: runningSortOrder,
                expanded: true,
            })
            const titlesInGroup: Array<string> = ownerData
                .filter(t => (t.ownerKey === ownerKey || (ownerKey === unknownGroupName && t.ownerKey === null)))
                .map(t => t.titleNumber)
            titlesInGroup.sort()
            titlesInGroup.forEach((titleNumber: string, titleIndex) => {
                const titleItem = currentTitles.find(t => t.titleNumber === titleNumber)
                titles.push({
                    titleNumber: titleItem.titleNumber,
                    boundaryStyle: applyStyling && boundaryAvailable(titleItem.titleNumber) ? this.colourPalette.getEntryForIndex(runningPaletteIndex) : null,
                    sortOrder: runningSortOrder,
                    matterGroupId: i,
                })
                runningSortOrder = runningSortOrder + ((titleIndex + 1) * 100)
            })
            runningPaletteIndex++
        })
        this.isLoading = false
        return Promise.resolve(new TitleOrganiserResult(titles, groups))
    }

    i18nReference = 'matter.titleOrganiser.owner'
    hasAppliedChanges = true
    quickStylingByDefault = true
    isLoading = false
}
