<template>
    <section class="map-layers-panel-expandable-item">
        <div class="map-layers-panel-expandable-item--header">
            <ow-checkbox :id="`map-layers-panel-${label}`"
                         v-model="model"
                         :label="label"
                         :indeterminate="indeterminate" />
            <v-progress-circular v-if="loading"
                                 class="map-layers-panel-expandable-item--loader"
                                 indeterminate
                                 :width="2"
                                 :size="16"
                                 color="primary" />
            <v-icon v-if="!expanded"
                    @click="expanded = true">
                $chevron-down
            </v-icon>
            <v-icon v-else
                    @click="expanded = false">
                $chevron-up
            </v-icon>
        </div>
        <slot v-if="expanded" />
    </section>
</template>

<script lang="ts">
    import OwCheckbox from '@/components/core/ow-checkbox.vue'

    export default {
        name: 'MapLayersPanelExpandableItem',

        components: {
            OwCheckbox,
        },

        props: {
            modelValue: {
                type: Boolean,
                required: true,
                default: false,
            },
            label: {
                type: String,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
            indeterminate: {
                type: Boolean,
                default: false,
            },
        },

        emits: ['update:modelValue'],

        data() {
            return {
                expanded: false,
            }
        },

        computed: {
            model: {
                get(): boolean {
                    return this.modelValue
                },
                set(value: boolean) {
                    this.$emit('update:modelValue', value)
                },
            },
        },
    }
</script>

<style lang="scss">
    @import './map-layers-panel-expandable-item';
</style>
