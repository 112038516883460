import { getEpcAPIUri } from '@/utils/environment.utils'
import httpClient from '@/api/http-client'
import { IEpcResponse } from '@/interfaces/epc.interface'
import { isNullOrEmpty } from '@/utils/array-utils'

export default class EpcApi {
    /**
     * Get epc bands for title numbers
     * @static
     * @param titleNumbers
     * @returns {Promise<T | *>}
     */
    static byTitleNumbers(titleNumbers: string[]): Promise<Array<IEpcResponse>> {
        const uri = `${ getEpcAPIUri() }/by-title-numbers`

        if (isNullOrEmpty(titleNumbers)) {
            throw new Error(`Title number array was null or empty in api call: ${ uri }`)
        }

        return httpClient
            .post(uri, titleNumbers)
            .then(response => response.data)
    }
}
