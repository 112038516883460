import {
    ActionColumnConfig,
    ActionConfig,
    Column,
    ColumnConfig,
} from '@bryntum/grid'
import { TranslateResult } from 'vue-i18n'

export interface IActionColumnAction {
    id: string
    title?: TranslateResult | ((record) => TranslateResult)
    className?: string
    icon?: string
    visible?: (record) => boolean
    disabled?: (record) => boolean
    primary?: boolean
    style: 'link' | 'button' | 'icon',
}

export interface IActionColumnProps extends Partial<ColumnConfig> {
    onEvent: (id: string, type: string, record) => void
    actions: IActionColumnAction[],
    width?: number
}

export const useActionColumn = (args: IActionColumnProps): Partial<Column> => ({
    ...args,
    text: '',
    type: 'action',
    align: 'right',
    cellCls: 'action-buttons',
    actions: args.actions.map(action => ({
        renderer: ({ record }) => {
            const disabled = action?.disabled && action.disabled(record)

            let title
            if (typeof action.title === 'function') {
                title = action.title(record)
            } else {
                title = action.title
            }
            return `<a data-test="action-${ action?.id }" class="b-action-item d-block ${ action?.primary ? '--primary' : '' } ${ disabled ? '--disabled' : '' } action-${ action?.style ?? 'link' } ${ action?.className ?? '' } mr-2">${ title ?? '' }</a>`
        },
        visible: action.visible,
        onClick: ({ record }) => args.onEvent(action.id, 'action', record),
    } as ActionConfig)),
} as Partial<ActionColumnConfig>)
