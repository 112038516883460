// set "isWatchersLoggingEnabled": true in config/*.json to enable watchers logging
import { isWatchersLoggingEnabled } from '@/utils/environment.utils'

export default {
    beforeCreate() {
        if (this.$options.watch && isWatchersLoggingEnabled()) {
            Object.entries(this.$options.watch).forEach(([watcherKey, func]) => {
                this.$options.watch[watcherKey] = new Proxy(func, {
                    apply(target, thisArg, argumentsList) {
                        console.info(`Called watcher ${ target.name } on component ${ thisArg.$options.name }`)
                        return target.call(thisArg, ...argumentsList)
                    },
                })
            })
        }
    },
}
