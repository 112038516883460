<template>
    <div ref="el"
         class="map-key-renderer drag-handle-disable">
        <h2 v-if="!isNullOrWhitespace(config.title)">
            {{ config.title }}
        </h2>
        <ul>
            <li v-for="(item, index) in config.items.filter(i => !isNullOrWhitespace(i?.label))"
                :key="index"
                class="accents-small">
                <span class="color-box"
                      :style="getSymbolStyle(item.style)" />
                <span>{{ item.label }}</span>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
    import { ref,
             watch } from 'vue'

    import { KeyConfigModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
    import { IOwSketchOrStyleProps } from '@/store/modules/sketches/types/style'
    import { getCSSStyleForOWStyleDefinition } from '@/utils/map-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    interface Props {
        config: KeyConfigModel,
        // eslint-disable-next-line vue/no-unused-properties
        parentElementSelector: string, // TODO: restrict drag actions to parent element.
        scalingMultiplier?: number,
    }
    const el = ref<HTMLElement>(null)
    const props = defineProps<Props>()

    const getSymbolStyle = (owStyle: Partial<IOwSketchOrStyleProps>) => {
        return getCSSStyleForOWStyleDefinition(owStyle, props.scalingMultiplier ?? 1)
    }
</script>

<style scoped lang="scss">
@import './map-key-renderer.scss';
</style>
