<template>
    <div id="titlePanelSummary"
         class="title-panel">
        <component :is="row.component"
                   v-for="(row, index) in rows"
                   :key="index"
                   :selected-title="selectedTitle"
                   :selected-title-number="selectedTitleNumber"
                   :matter-id="currentMatterId"
                   :title-number="selectedTitleNumber"
                   :current-matter-id="currentMatterId"
                   :is-ordering-allowed="isOrderingAllowed"
                   :order-with-checkout="orderWithCheckout"
                   :user-has-vts-charge="titleSummaryCharge !== null"
                   :is-loading="isLoading"
                   :official-copies="officialCopies"
                   :tab-controller="tabController"
                   @set-selected-tab="setSelectedTab" />
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        markRaw,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useStore } from 'vuex'

    import DayListCard from '@/components/title-panel/tabs/day-list-card.vue'
    import OrderRegisterCard from '@/components/title-panel/tabs/order-register-card.vue'
    import OrderTitlePlanCard from '@/components/title-panel/tabs/order-title-plan-card.vue'
    import BasicInformationCard from '@/components/title-panel/tabs/summary-tab/basic-information-card.vue'
    import BoundaryDataUnavailable from '@/components/title-panel/tabs/summary-tab/boundary-data-unavailable.vue'
    import CopiesFiledCard from '@/components/title-panel/tabs/summary-tab/copies-filed-card.vue'
    import EpcCard from '@/components/title-panel/tabs/summary-tab/epc-card.vue'
    import FlagsCard from '@/components/title-panel/tabs/summary-tab/flags-card.vue'
    import MlTagsCard from '@/components/title-panel/tabs/summary-tab/ml-tags-card.vue'
    import OrderDocumentsCard from '@/components/title-panel/tabs/summary-tab/order-documents-card/order-documents-card.vue'
    import OwnershipCard from '@/components/title-panel/tabs/summary-tab/ownership-card.vue'
    import PropertySearchesCard from '@/components/title-panel/tabs/summary-tab/property-searches-card.vue'
    import TitleComplexityCard from '@/components/title-panel/tabs/summary-tab/title-complexity-card.vue'
    import { ITitlePanelTabController } from "@/composables/use-title-panel"
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { TitlePanelTabName } from '@/enums/title-panel-tab-name'
    import {
        TITLE_GET_SELECTED_TITLE,
        TITLE_GET_SELECTED_TITLE_NUMBER,
        TITLE_LOOKUP_TITLE,
    } from '@/store/modules/titles/types'

    const rows = ref<{
        component,
    }[]>([])
    const store = useStore()
    const selectedTitle = computed(() => store.getters[TITLE_GET_SELECTED_TITLE])
    const selectedTitleNumber = computed({
        get: () => store.getters[TITLE_GET_SELECTED_TITLE_NUMBER],
        set: (value) => store.dispatch(TITLE_LOOKUP_TITLE, value),
    })
    const bgDataLoaded = computed(() => selectedTitle.value.record?.titleMetadata?.bgDataLoaded)
    const isOrderSearchEnabled = computed(() => store.state.config.featureFlags?.orderSearch)
    const isMlTagsEnabled = computed(() => store.state.config.featureFlags?.mlFlags)
    const currentMatterId = computed(() => store.state.matter?.currentMatter?.id)
    const titleSummaryCharge = computed(() =>store.state.user.titleSummaryCharge)
    const documentSource = computed(() => selectedTitle?.value?.record?.source)
    const officialCopies = computed(() => selectedTitle?.value?.officialCopiesAvailability?.results)

    const props = withDefaults(defineProps<{
        showTitleComplexity?: boolean,
        isOrderingAllowed?: boolean,
        orderWithCheckout?: boolean,
        tabController: ITitlePanelTabController,
        isLoading?: boolean,
    }>(), {
        showTitleComplexity: true,
        isOrderingAllowed: true,
        orderWithCheckout: false,
        isLoading: false,

    })

    const emit = defineEmits<{
        (e: 'set-selected-tab', tabIndex: TitlePanelTabName),
    }>()

    const setSelectedTab = (tabIndex: TitlePanelTabName) => {
        emit('set-selected-tab', tabIndex)
    }

    const componentsToRender = (documentSource?: string) => {
        const scottishSpike = store.state.config.featureFlags?.scottishSpike
        const components = []
        if (!documentSource) {
            return scottishSpike
                ? [BasicInformationCard,
                   OwnershipCard,
                   DayListCard,
                   OrderDocumentsCard]
                : [BasicInformationCard,
                   OwnershipCard,
                   OrderRegisterCard,
                   OrderTitlePlanCard]
        }
        switch (documentSource) {
            case DOCUMENT_SOURCE.SCOTLAND:
                components.push(BoundaryDataUnavailable, BasicInformationCard, OwnershipCard, DayListCard)
                break
            default:
                components.push(
                    BoundaryDataUnavailable,
                    BasicInformationCard,
                    (props.showTitleComplexity && bgDataLoaded.value && TitleComplexityCard) || null,
                    OwnershipCard,
                    DayListCard,
                    (isOrderSearchEnabled.value && PropertySearchesCard) || null,
                    (bgDataLoaded.value && FlagsCard) || null,
                    (isMlTagsEnabled.value && MlTagsCard) || null,
                    CopiesFiledCard,
                    EpcCard,
                )
        }

        if (scottishSpike) {
            components.push(OrderDocumentsCard)
        } else {
            components.push(OrderRegisterCard, OrderTitlePlanCard)
        }
        return components
    }
    const renderComponents = (documentSource?: string) => {
        rows.value = componentsToRender(documentSource)
            .filter(component => component)
            .map((component) => {
                return {
                    component: markRaw(component),
                }
            })
    }

    watch(() => documentSource.value, (val, old) => {
        if (val !== old) {
            if (val) {
                renderComponents(val)
            }
        }
    })

    watch(() => selectedTitleNumber.value, () => {
        renderComponents(documentSource.value)
    }, {
        immediate: true,
    })

    onMounted(() => {
        renderComponents(documentSource.value)
    })

</script>

<style lang="scss">
    @import './summary-tab-renderer.scss';
</style>
