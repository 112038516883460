<template>
    <ow-modal v-model="showDuplicateMatterDialog"
              persistent
              content-class="ow-duplicate-matter-dialog"
              data-test-attribute="ow-duplicate-matter-dialog"
              :title="$t('matterSettings.duplicateMatterDialog.title')">
        <div v-if="!duplicatedMatterId"
             disabled="loading">
            <div class="d-flex flex-column">
                <label class="caption-regular">
                    {{ $t('matterSettings.duplicateMatterDialog.content') }}
                </label>
                <ul class="duplicate-matter-description-list">
                    <li v-for="item in items"
                        class="duplicate-matter-description-list-item">
                        <span class="body-copy">{{ item }}</span>
                    </li>
                </ul>
            </div>
            <label class="caption-regular"
                   for="new-matter-name-textfield">
                {{ $t('matterSettings.duplicateMatterDialog.newMatterNameCaption') }}
            </label>
            <v-text-field id="new-matter-name-textfield"
                          v-model="newMatterName"
                          data-test="new-matter-name-textfield"
                          type="string"
                          variant="outlined"
                          :rules="[rules.required]" />
            <label class="caption-regular"
                   for="new-matter-code-textfield">
                {{ $t('matterSettings.duplicateMatterDialog.newMatterCodeCaption') }}
            </label>
            <v-text-field id="new-matter-code-textfield"
                          v-model="newMatterCode"
                          data-test="new-matter-code-textfield"
                          type="string"
                          variant="outlined"
                          :rules="[rules.required]" />
        </div>
        <div v-if="!loading && duplicatedMatterId">
            <v-label>
                {{ $t('matterSettings.duplicateMatterDialog.duplicationFinishedMessage') }}
            </v-label>
            <v-label v-if="errorText"
                     class="mt-2 error-text"
                     data-test="duplicate-matter-error-text">
                {{ errorText }}
            </v-label>
        </div>
        <template #actions>
            <ow-card-actions v-if="!duplicatedMatterId"
                             :primary-button-loading="loading"
                             :primary-button-disabled="duplicatedMatterId !== null || !isValid"
                             has-secondary-button
                             no-padding
                             primary-button-data-test="duplicate-matter-confirm"
                             secondary-button-data-test="duplicate-matter-cancel"
                             :primary-button-text="$t('matterSettings.duplicateMatterDialog.confirm')"
                             @primary-button-click="handleConfirm"
                             @secondary-button-click="handleCancel" />
            <ow-card-actions v-if="duplicatedMatterId"
                             no-padding
                             has-secondary-button
                             primary-button-data-test="duplicate-matter-gotonewmatter"
                             secondary-button-data-test="duplicate-matter-exit"
                             :primary-button-text="$t('matterSettings.duplicateMatterDialog.goToNewMatter')"
                             @primary-button-click="handleGoToNewMatter"
                             @secondary-button-click="handleCancel" />
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        computed,
        reactive,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { Route } from '@/enums/route.enum'
    import {
        MATTER_DUPLICATE,
        MATTER_MUTATE_DUPLICATE_MATTER_ID,
        MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG,
    } from '@/store/modules/matter/types'
    import {isNullOrWhitespace} from "@/utils/string-utils"

    const { t } = useI18n()

    const loading = ref<boolean>(false)
    const props = defineProps<{
        currentMatterId?: number,
        defaultMatterName: string,
        defaultMatterCode: string
    }>()
    const newMatterName = ref<string>(props.defaultMatterName)
    const newMatterCode = ref<string>(props.defaultMatterCode)
    const errorText = ref<string>('')

    const items = computed(() => [
        t('matterSettings.duplicateMatterDialog.duplicatedItems.titles'),
        t('matterSettings.duplicateMatterDialog.duplicatedItems.groups'),
        t('matterSettings.duplicateMatterDialog.duplicatedItems.sketches'),
        t('matterSettings.duplicateMatterDialog.duplicatedItems.userAccess'),
    ])
    const isValid = computed(() => newMatterName.value && newMatterCode.value)

    const rules = reactive({
        required: (v: string) => !isNullOrWhitespace(v) ||  t('matterSettings.duplicateMatterDialog.fieldIsRequired'),
    })

    const showDuplicateMatterDialog = computed<boolean>({
        get: () => store.state.matter.showDuplicateMatterDialog,
        set: (value) => {
            store.commit(MATTER_MUTATE_DUPLICATE_MATTER_ID, null)
            store.commit(MATTER_MUTATE_SHOW_DUPLICATE_MATTER_DIALOG, value)
        },
    })
    const duplicatedMatterId = computed<number>(() => store.state.matter.duplicateMatterId)
    const store = useStore()
    const router = useRouter()

    const handleConfirm = async () => {
        loading.value = true
        try {
            await store.dispatch(MATTER_DUPLICATE, {
                matterId: props.currentMatterId,
                newMatterName: newMatterName.value,
                newMatterCode: newMatterCode.value,
            })
        } catch (e) {
            errorText.value = e.message
        } finally {
            loading.value = false
        }
    }

    const handleCancel = () => {
        showDuplicateMatterDialog.value = false
    }

    const handleGoToNewMatter = async () => {
        await router.push({
            name: Route.MatterMap,
            params: {
                matterId: duplicatedMatterId.value,
            },
        })
        showDuplicateMatterDialog.value = false
    }
</script>

<style lang="scss">
@import './duplicate-matter-dialog.scss';
</style>
