<template>
    <v-expansion-panel v-if="register.entries.length > 0"
                       :value="register.prefix"
                       :elevation="0"
                       rounded="false"
                       class="register-panel"
                       :class="{
                           'register-panel--disabled': disableUnmatchedItems && !registerEntriesToShow.length,
                       }">
        <v-expansion-panel-title hide-actions>
            <div class="d-flex flex-row justify-space-between w-100 caption-highlight">
                <div class="d-flex flex align-center">
                    <div class="d-flex flex xs1 pr-2">
                        {{ register.prefix }}
                    </div>
                    <div class="d-flex">
                        {{ register.displayName }}
                    </div>
                </div>
                <div class="d-flex flex-row align-center gc-1">
                    <!-- Count of matches against each filter -->
                    <div v-for="filterCount in register.filterCounts"
                         :style="{background: filterCount.type.colour}"
                         class="register-filter-indicator"
                         data-test="register-item-filter-indicator">
                        {{ filterCount.count }}
                    </div>

                    <!-- Count of matches against each search term  -->
                    <div v-if="register.searchCount"
                         class="d-flex shrink register-search-count">
                        <v-icon>$search</v-icon>
                        <div data-test="register-search-count">
                            {{ register.searchCount }}
                        </div>
                    </div>

                    <div v-if="!isExpanded"
                         class="d-flex shrink align-center"
                         data-track="TITLE-DETAILS-PANEL - Show the register entries">
                        {{ registerEntriesToShow.length || !disableUnmatchedItems ? 'View' : 'No matches' }}
                    </div>
                </div>
            </div>
        </v-expansion-panel-title>
        <!-- Heading -->

        <v-expansion-panel-text>
            <!-- Main content -->
            <div v-for="entry in registerEntriesToShow"
                 :key="entry.code"
                 :data-test="`register-entry-${entry.code}`"
                 class="d-flex flex-column register-item__item-content">
                <div class="d-flex flex-row gc-3">
                    <div :style="getRegisterEntryStyle(entry)" />
                    <!-- Entry number -->
                    <div v-dompurify-html="getEntryNumberDisplayHTML(entry.nr)"

                         class="d-flex flex xs1 title-panel-register-item-number pr-5" />
                    <!-- Entry Text -->
                    <div class="d-flex flex xs11 title-panel-register-item-text">
                        <OwClipboard :text="entry.displayString"
                                     @clicked="() => logClip(entry.nr)">
                            <span v-dompurify-html="getRegisterDisplayHTML(entry)" />
                        </OwClipboard>
                    </div>
                </div>

                <!-- Optional referenced document and/or title -->
                <div v-for="(document) in getDocumentsForEntry(entry)"
                     :key="`${ document.documentType }-${ document.documentDate }-${ entry.code }`"
                     class="d-flex flex-column">
                    <div class="d-flex flex-row register-item__document-order">
                        <div class="d-flex flex xs1" />

                        <!-- Document details -->
                        <span class="body-copy--bold">{{ document.documentType }}</span>
                        <span :class="{
                                  'register-highlight-text': highlightDates
                              }"
                              class="body-copy--bold">
                            {{ document.documentDate }}
                        </span>
                        <ow-document-availability-label :hmlr-availability-code="getHmlrAvailabilityCodeForDocument(document)"
                                                        :is-loading="availabilityLoading"
                                                        :is-ordered="isDocumentOrdered(document)" />
                        <document-ordering v-if="getReferredToDocument(document)"
                                           :document="getReferredToDocument(document)"
                                           :document-type="oc2DocumentType"
                                           :heap-metadata="{
                                               type: 'DOC-ORDERING - Order copies filed document from register tab',
                                               metadata: {
                                                   matterId: currentMatterId,
                                                   hasOrderedRegister: isRegisterOrdered,
                                               },
                                           }"
                                           :index="entry.code"
                                           :is-small="true"
                                           :title-number="selectedTitleNumber" />
                    </div>

                    <!-- Link to associated title -->
                    <div v-for="(title) in getReferencedTitlesForEntry(entry)"
                         :key="`${ title }-${ entry.code }`"
                         class="d-flex flex-row">
                        <div class="d-flex flex xs1" />
                        <div>
                            <a data-track="TITLE-DETAILS-PANEL - Open title from register entry"
                               href="#"
                               @click.prevent="goToTitle(title)">
                                View title {{ title }}
                            </a>
                        </div>
                    </div>
                </div>
                <p v-if="!register.show"
                   class="register-no-results">
                    <i>
                        No matches found.
                    </i>
                </p>
            </div>
        </v-expansion-panel-text>
    </v-expansion-panel>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwClipboard from '@/components/core/ow-clipboard.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import DocumentOrdering from '@/components/documents/document-ordering.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'
    import { IRegisterLinkedIndicator } from '@/interfaces/documents/entry-type.interface'
    import { IReferencedDocument } from '@/interfaces/documents/referenced-document.interface'
    import { IRegister } from '@/interfaces/documents/register.interface'
    import { IRegisterEntry } from '@/interfaces/documents/register-entry.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import { TITLE_LOOKUP_TITLE } from '@/store/modules/titles/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { generateOrderKeyValueForOC2Document } from '@/utils/document-ordering-utils'

    export default {
        name: 'TitlePanelRegisterItem',

        components: {
            OwDocumentAvailabilityLabel,
            DocumentOrdering,
            OwClipboard,
        },

        mixins: [FeatureFlagsMixin],

        props: {
            register: {
                type: Object as PropType<IRegister>,
                required: true,
            },
            showEntryTypes: {
                type: Array as PropType<IRegisterLinkedIndicator[]>,
                required: true,
            },
            highlightDates: {
                type: Boolean,
                required: false,
            },
            isExpanded: {
                type: Boolean,
                required: false,
            },
            disableUnmatchedItems: {
                type: Boolean,
                required: false,
            },
        },

        emits: ['title-number-selected'],

        data() {
            return {
                oc2DocumentType: HighLevelDocumentType.OC2Document,
            }
        },

        computed: {
            ...mapState({
                currentMatterId: (state: IState) => state.matter.currentMatter.id,
                currentTitleNumber: (state: IState) => state.title.selectedTitleNumber,
                selectedTitle: (state: IState) => state.title.selectedTitle,
                referredToDocsAvailability: (state: IState) =>
                    state.title?.selectedTitle?.officialCopiesAvailability?.results?.referredToDocuments,
                registerAvailability: (state: IState) =>
                    state.title?.selectedTitle?.officialCopiesAvailability?.results?.titleRegister,
                availabilityLoading: (state: IState) =>
                    state.title?.selectedTitle?.officialCopiesAvailability?.loading,
                selectedEntryTypes: (state: IState) => state.title.selectedEntryTypes,
                pendingRegisterIndicatorTextSelectionSpan: (state: IState) => state.title.pendingRegisterIndicatorTextSelectionSpan,
            }),

            selectedTitleNumber: {
                get() {
                    return this.currentTitleNumber
                },
                set(val) {
                    this.lookupTitle(val)
                },
            },

            registerEntriesToShow() {
                if (!this.register.show) {
                    return []
                }

                return this.register.entries.filter(this.showRegisterEntry)
            },

            isRegisterOrdered(): boolean {
                return Boolean(this.registerAvailability?.downloadUrl)
            },
        },

        watch: {
            showEntryTypes(val: IRegisterLinkedIndicator[]): void {
                if (val.length > 0) {
                    if (this.register.showEntries.length > 0) {
                        this.expandPanel = 0
                    }
                }
            },
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                lookupTitle: TITLE_LOOKUP_TITLE,
            }),

            getDocumentsForEntry(entry: IRegisterEntry) {
                if (!this.selectedTitle?.record?.bgProprietorshipData?.documentDetails) {
                    return []
                }
                return this.selectedTitle.record.bgProprietorshipData.documentDetails.filter((document: IReferencedDocument) => {
                    return document?.entryNumberField?.indexOf(entry.code) !== -1
                })
            },

            showRegisterEntry(entry: IRegisterEntry) {
                if (this.register.showAllEntries === true) {
                    return true
                } else {
                    return this.register.showEntries.indexOf(entry.code) !== -1
                }
            },

            getRegisterEntryStyle(entry: IRegisterEntry) {
                const selectedEntryType = this.getSelectedEntryType(entry)

                if (selectedEntryType) {
                    return {
                        background: selectedEntryType.colour,
                        padding: '0 5px',
                    }
                } else {
                    return {}
                }
            },

            getSelectedEntryType(entry: IRegisterEntry) {
                return this.showEntryTypes.find((t: IRegisterLinkedIndicator) => {
                    // Get matching codes
                    const codes = t.codes.map(code => code.code)
                    const subCodes = t.codes.map(code => code.subCodes).flat()

                    return codes.includes(entry.code) || subCodes.includes(entry.code)
                })
            },

            getRegisterDisplayHTML(entry: IRegisterEntry) {
                let result = entry.displayString
                const highlightText = [...this.register.highlightText]

                if (!isNullOrEmpty(this.selectedEntryTypes) && !isNullOrEmpty(this.pendingRegisterIndicatorTextSelectionSpan)) {
                    const spanShouldApplyToEntryCodes = this.selectedEntryTypes.map(x => x.codes[0].code)
                    if (spanShouldApplyToEntryCodes.includes(entry.code)) {
                        highlightText.push(entry.displayString.substring(this.pendingRegisterIndicatorTextSelectionSpan[0], this.pendingRegisterIndicatorTextSelectionSpan[1]))
                    }
                }

                highlightText.forEach(text => {
                    result = result.replace(new RegExp(text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi'), match => {
                        return '!!!' + match + '---'
                    })
                })

                result = result.replace(new RegExp('!!!', 'g'), '<span class="register-highlight-text">')
                result = result.replace(new RegExp('---', 'g'), '</span>')
                return result
            },

            getEntryNumberDisplayHTML(entryNumberText: string) {
                let result = entryNumberText
                this.register.highlightEntryNumbers.forEach(text => {
                    result = result.replace(new RegExp(text, 'gi'), match => {
                        return '<span class="register-highlight-text">' + match + '</span>'
                    })
                })
                return result
            },

            goToTitle(titleNumber: string) {
                this.$emit('title-number-selected', titleNumber)
            },

            getReferencedTitlesForEntry(entry: IRegisterEntry): Array<string> {
                const filedUnder: Array<string> = this.getDocumentsForEntry(entry)
                    .map((document: IReferencedDocument) => document.filedUnderField)

                return Array.from(new Set(filedUnder)).filter(title => title != null)
            },

            /**
             * Find the new OCDA document from the old register doc to display
             * @param entryDocument - found from the old register data
             */
            getReferredToDocument(entryDocument: IReferencedDocument): ICopiesFiledDocument {
                if (this.availabilityLoading) {
                    // The referred to docs availability can take a little while to load initially
                    return {} as ICopiesFiledDocument
                }

                const filedUnder = entryDocument.filedUnderField ?? this.currentTitleNumber
                const keyValueOfEntryDoc = generateOrderKeyValueForOC2Document(
                    this.currentTitleNumber,
                    filedUnder,
                    entryDocument.documentDate,
                    entryDocument.entryNumberField,
                )

                return this.referredToDocsAvailability.find(doc => doc.keyValue === keyValueOfEntryDoc)
            },

            isDocumentOrdered(entryDocument: IReferencedDocument): boolean {
                if (this.availabilityLoading) {
                    return false
                }
                const ocdaDoc: ICopiesFiledDocument = this.getReferredToDocument(entryDocument)

                return Boolean(ocdaDoc?.downloadAvailable)
            },

            getHmlrAvailabilityCodeForDocument(entryDocument: IReferencedDocument): string {
                if (this.availabilityLoading) {
                    return ''
                }
                const ocdaDoc: ICopiesFiledDocument = this.getReferredToDocument(entryDocument)
                if (ocdaDoc) {
                    return ocdaDoc.hmlrAvailabilityCode
                }

                // If the document cannot be found in the OCDA response, then mark it as unavailable
                return HmlrDocumentAvailabilityCode.Unavailable
            },

            async logClip(entryNr: string) {
                await this.logHeapEvent({
                    type: 'TITLE-DETAILS-PANEL - Copy Register Entry',
                    metadata: { content: entryNr },
                })
            },
        },
    }
</script>

<style lang="scss">
    @import './register-item';
</style>
