import {
    GET_EXPORT_OPTIONS,
    GET_EXPORT_OPTIONS_LOADING,
    GET_SELECTED_EXPORT_OPTION,
    GET_TITLE_AVAILABILITY,
    GET_TITLE_AVAILABILITY_LOADING,
} from './types'
import { IReportsState } from '@/interfaces/store/reports/reports-state.interface'

export default {
    [GET_EXPORT_OPTIONS]: (state: IReportsState) => state.exportOptions,
    [GET_EXPORT_OPTIONS_LOADING]: (state: IReportsState) => state.exportOptionsLoading,
    [GET_SELECTED_EXPORT_OPTION]: (state: IReportsState) => state.selectedExportOption,
    [GET_TITLE_AVAILABILITY]: (state: IReportsState) => state.titleAvailability,
    [GET_TITLE_AVAILABILITY_LOADING]: (state: IReportsState) => state.titleAvailabilityLoading,
}
