

import {
    DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS,
    DOCUMENT_ORDERING_MUTATE_ERROR,
    DOCUMENT_ORDERING_MUTATE_ERROR_ORDERS,
    DOCUMENT_ORDERING_MUTATE_ORDER_STATUS,
    DOCUMENT_ORDERING_MUTATE_PROCESSING_ORDER,
    DOCUMENT_ORDERING_MUTATE_SUCCESSFUL_ORDERS,
    DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS,
    DOCUMENT_ORDERING_REMOVE_ORDER_FROM_ERROR_ORDERS,
} from '@/store/modules/document-ordering/types'
import { DocumentOrderStatus } from '@/consts/document-order-status'
import { IDocumentOrder } from '@/interfaces/store/document-ordering/document-order.interface'
import { IDocumentOrderingState } from '@/interfaces/store/document-ordering/document-ordering-state.interface'
import { isNullOrEmpty } from '@/utils/array-utils'

export default {
    [DOCUMENT_ORDERING_MUTATE_ERROR](state: IDocumentOrderingState, error) {
        state.error = error
    },

    [DOCUMENT_ORDERING_MUTATE_PROCESSING_ORDER](state: IDocumentOrderingState, isProcessing: boolean) {
        state.isProcessingOrders = isProcessing
    },

    [DOCUMENT_ORDERING_MUTATE_SUCCESSFUL_ORDERS](state: IDocumentOrderingState, order: IDocumentOrder) {
        if (!order) {
            throw new Error('DOCUMENT_ORDERING_MUTATE_SUCCESSFUL_ORDERS::Order not provided')
        }

        const orderIdx = state.successfulOrders.findIndex(o => o.orderId === order.orderId)
        if (orderIdx === -1) {
            state.successfulOrders.push(order)
        } else if (order?.status) {
            state.successfulOrders[orderIdx].status = order.status
        }
    },

    [DOCUMENT_ORDERING_MUTATE_ERROR_ORDERS](state: IDocumentOrderingState, order: IDocumentOrder) {
        if (!order) {
            throw new Error('DOCUMENT_ORDERING_MUTATE_ERROR_ORDERS::Order not provided')
        }

        const orderIdx = state.errorOrders.findIndex(o => o.orderId === order.orderId)
        if (orderIdx === -1) {
            state.errorOrders.push(order)
        } else if (order?.status) {
            state.errorOrders[orderIdx].status = order.status
        }
    },

    [DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS](state: IDocumentOrderingState, order: IDocumentOrder) {
        if (!order) {
            throw new Error('DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS::Order not provided')
        }

        state.currentOrders = state.currentOrders.filter(o => o.orderId !== order.orderId)
    },

    [DOCUMENT_ORDERING_REMOVE_ORDER_FROM_ERROR_ORDERS](state: IDocumentOrderingState, order: IDocumentOrder) {
        if (!order) {
            throw new Error('DOCUMENT_ORDERING_REMOVE_ORDER_FROM_ERROR_ORDERS::Order not provided')
        }

        state.errorOrders = state.errorOrders.filter(o => o.orderId !== order.orderId)
    },

    [DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS](state: IDocumentOrderingState, orders: IDocumentOrder[]) {
        if (isNullOrEmpty(orders)) {
            throw new Error('DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS:Orders must be provided')
        }

        orders.forEach(order => {
            const orderIdx = state.currentOrders.findIndex(o => o.orderId === order.orderId)
            if (orderIdx === -1) {
                state.currentOrders.push({
                    ...order,
                    status: DocumentOrderStatus.ORDERING,
                })
            } else if (order?.status) {
                state.currentOrders[orderIdx].status = order.status
            }
        })
    },

    [DOCUMENT_ORDERING_MUTATE_ORDER_STATUS](state: IDocumentOrderingState, {
        order,
        status,
    }) {
        if (!order) {
            throw new Error('DOCUMENT_ORDERING_MUTATE_ORDER_STATUS::Order not provided')
        }
        if (!status) {
            throw new Error('DOCUMENT_ORDERING_MUTATE_ORDER_STATUS::Status not provided')
        }

        const orderIdx = state.currentOrders.findIndex(o => o.orderId === order.orderId)

        if (orderIdx >= 0 && orderIdx < state.currentOrders.length) {
            state.currentOrders[orderIdx].status = status
        } else {
            throw new Error(`DOCUMENT_ORDERING_MUTATE_ORDER_STATUS::Order with id ${ order.orderId } not found`)
        }
    },
}
