<template>
    <ow-overlay v-hotkey="keymap"
                class="ow-modal"
                :class="{ shake: disabled }"
                :show="modelValue"
                @click="handleClickOut">
        <div class="ow-modal__card"
             :class="{ [contentClass]: Boolean(contentClass) }"
             :data-test="dataTestAttribute">
            <div v-if="title"
                 class="headers-h1-page-title ow-modal__card--title ow-modal__card--header-container pa-4">
                <div class="headers-h1-page-title ow-modal__card--header">
                    <h2 data-test="ow-modal-title"
                        class="headers-h1-page-title ow-modal__card--header">
                        {{ title }}
                    </h2>
                    <div v-if="hasSubtitleSlot()">
                        <slot name="subtitleSlot" />
                    </div>
                </div>
                <div v-if="hasTooltipText"
                     class="ow-modal__card--iconInfo header-h1-page-title">
                    <i aria-hidden="true"
                       class="material-icons">info_outline
                        <ow-tooltip v-if="showTooltip()"
                                    activator="parent"
                                    :position="OwTooltipPosition.Right"
                                    nudge-right="4px"
                                    nudge-top="5px">
                            <span> {{ tooltipText }} </span>
                        </ow-tooltip>
                        <div v-else-if="icon"
                             class="ow-modal__card--iconInfo header-h1-page-title">
                            <v-icon aria-hidden="true"
                                    class="material-icons">
                                {{ icon }}
                            </v-icon>
                        </div>
                    </i>
                </div>

                <v-icon class="ow-modal__card--iconClose float-right ml-2"
                        aria-label="close"
                        @click="close">
                    $close
                </v-icon>
            </div>
            <div v-if="subtitle"
                 class="ow-modal__card--subtitle"
                 data-test="ow-modal-subtitle">
                {{ subtitle }}
            </div>
            <div class="ow-modal__card--content pa-4"
                 :class="{
                     'no-padding': noPadding,
                 }">
                <slot />
            </div>
            <div v-if="hasActionsSlot()"
                 class="ow-modal__card--actions pa-4">
                <slot name="actions" />
            </div>
        </div>
    </ow-overlay>
</template>

<script lang="ts">
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    import OwOverlay from './ow-overlay.vue'

    export default {
        name: 'OwModal',
        components: {
            OwOverlay,
            OwTooltip,
        },
        props: {
            modelValue: {
                type: Boolean,
                required: false,
            },
            tooltipText: {
                type: String,
                default: '',
            },
            title: {
                type: String,
                default: '',
            },
            persistent: {
                type: Boolean,
                required: false,
            },
            contentClass: {
                type: String,
                required: false,
            },
            dataTestAttribute: {
                type: String,
                default: null,
            },
            icon: {
                type: String,
                default: null,
            },
            subtitle: {
                type: String,
                default: null,
            },
            noPadding: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            'update:modelValue',
        ],
        data() {
            return {
                disabled: false,
                keymap: {
                    esc: () => {
                        if (!this.persistent) {
                            this.close()
                        }
                    },
                },
                OwTooltipPosition,
            }
        },
        computed: {
            hasTooltipText(): boolean {
                return !isNullOrWhitespace(this.tooltipText)
            },
        },
        methods: {
            close() {
                this.$emit('update:modelValue', false)
            },
            triggerErrorAnimation() {
                this.disabled = true

                setTimeout(() => {
                    this.disabled = false
                }, 100)
            },
            handleClickOut() {
                if (this.persistent) {
                    this.triggerErrorAnimation()
                } else {
                    this.close()
                }
            },
            showTooltip(): boolean {
                return Boolean(this.tooltipText)
            },
            hasActionsSlot(): boolean {
                return Boolean(this.$slots.actions)
            },
            hasSubtitleSlot(): boolean {
                return Boolean(this.$slots.subtitleSlot)
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './ow-modal';
</style>
