import { Coordinate } from 'ol/coordinate'
import * as shpWrite from '@mapbox/shp-write'

export const writeShapeFile = (
    rows: Array<any>,
    geometryType: any,
    geometries: Array<Coordinate[][][]> | Array<Coordinate[][]> | Array<Coordinate[]> | Coordinate[],
    callback: Function) => {
    shpWrite.write(rows, geometryType, geometries, callback)
}
