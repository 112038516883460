<template>
    <v-container v-if="documentMetadataLoaded"
                 id="documentViewerPage">
        <div class="document-viewer">
            <viewer :document-metadata="documentMetadata"
                    @downloading-document="navigatingToDownloadDocument = true" />

            <copilot-cta v-if="showCopilotCta"
                         :client-code="clientCode"
                         :description="$t('copilot.cta.documentViewer.description')"
                         :document-ids="[documentId]"
                         :document-type="documentMetadata?.documentType"
                         :matter-code="matterCode"
                         :matter-id="matterId"
                         :title="$t('copilot.cta.documentViewer.title')"
                         :title-number="documentMetadata?.titleNo"
                         class="document-viewer__copilot-cta"
                         is-clickable
                         is-small
                         position="Document Viewer" />
        </div>

        <div v-if="documentNotFound"
             class="d-flex flex"
             style="padding:20px">
            <h1>Unable to retrieve document</h1>
        </div>
    </v-container>
</template>

<script lang="ts">
    import { useRoute } from 'vue-router'
    import { mapActions,
             mapState,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import CopilotCta from '@/components/copilot/copilot-cta.vue'
    import OCRPDFViewer from '@/components/documents/viewer/ocr-pdf-viewer.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { ApplicationRoles } from '@/enums/application-roles.enum'
    import { Route } from '@/enums/route.enum'
    import FlagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from '@/interfaces/store/state.interface'
    import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
    import { DOCUMENTS_FETCH_UPLOADED_DOCUMENTS } from '@/store/modules/documents/documents-types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { format } from '@/utils/date-utils'

    export default {
        name: 'DocumentViewer',

        components: {
            CopilotCta,
            viewer: OCRPDFViewer,
        },

        mixins: [ FlagsMixin ],

        beforeRouteLeave(to, from, next) {
            this.logDocumentUsage()
            next()
        },

        data() {
            return {
                documentMetadata: null,
                documentMetadataLoaded: false,
                documentSessionStartTime: null,
                navigatingToDownloadDocument: false,
                documentNotFound: false,
                route: useRoute(),
            }
        },

        computed: {
            ...mapState({
                uploadedDocuments: (state: IState) => state.documents.library.uploadedDocuments,
            }),

            documentId(): number {
                return this.route.params.documentId
            },

            documentType(): string {
                return this.route.params.documentType
            },

            matterId(): number {
                return parseInt(this.route.query.fromMatterId)
            },

            ...mapState({
                hasScrolledDocument: (state: IState) => state.documents.viewer.logging.hasScrolledDocument,
                hasInteractedWithDocument: (state: IState) => state.documents.viewer.logging.hasInteractedWithDocument,
                hasDownloadedDocument: (state: IState) => state.documents.viewer.logging.hasDownloadedDocument,
                organisationId: (state: IState) => state.user.organisationId,
                userId: (state: IState) => state.user.id,
                matterCode: (state: IState) => state.matter.currentMatter.code,
                clientCode: (state: IState) => state.matter.currentMatter.clientCode,
                hasCopilotRole: (state: IState) => state.user.roles.includes(ApplicationRoles.Copilot),
            }),

            hasUploadedDocuments(): boolean {
                return !isNullOrEmpty(this.uploadedDocuments)
            },

            showCopilotCta(): boolean {
                return !this.documentNotFound &&
                    this.documentMetadata?.documentType === 'Lease' &&
                    this.isCopilotEnabled &&
                    this.hasCopilotRole
            },
        },

        watch: {
            async documentId(val) {
                if (val) {
                    await this.setDocumentFromRoute()
                }
            },
        },

        created() {
            window.onbeforeunload = () => {
                if (this.navigatingToDownloadDocument === false) {
                    if (this.route.name == Route.DocumentViewer) {
                        this.logDocumentUsage()
                    }
                } else {
                    this.navigatingToDownloadDocument = false
                }
            }
        },

        async mounted() {
            // Request document
            await this.setDocumentFromRoute()
        },

        methods: {
            ...mapActions({
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                refreshUploadedDocuments: DOCUMENTS_FETCH_UPLOADED_DOCUMENTS,
            }),

            async setDocumentFromRoute() {
                this.documentMetadataLoaded = false
                // Reset state
                this.documentMetadata = null
                this.documentNotFound = false

                if (!this.hasUploadedDocuments) {
                    await this.refreshUploadedDocuments(this.matterId)
                }

                if (this.documentType === HighLevelDocumentType.Uploaded &&
                    this.matterId > 0 &&
                    this.documentId > 0) {
                    const document: IUploadedDocument = this.uploadedDocuments.find(d => d.id.toString() === this.documentId?.toString())
                    this.documentNotFound = !document
                    if (!this.documentNotFound) {
                        this.documentMetadata = {
                            augmentedFilename: null,
                            filename: document.fileName,
                            documentId: document.id,
                            documentDownloadUrl: null,
                            documentDate: format(document.updatedOn, 'dd-MM-yyyy'),
                            documentType: HighLevelDocumentType.Uploaded,
                            highLevelDocumentType: HighLevelDocumentType.Uploaded,
                            titleNo: null,
                        }
                    }
                } else if (this.documentType !== HighLevelDocumentType.Uploaded) {
                    // Get the document metadata from the API
                    let dType = this.documentType
                    if (dType === HighLevelDocumentType.UploadedOC2) {
                        dType = HighLevelDocumentType.OC2Document
                    }
                    const documentMetadata = await DocumentsApi.getDocumentMetadataByTypeAndId(dType, this.documentId)
                    this.documentNotFound = documentMetadata === null
                    if (!this.documentNotFound) {
                        this.documentMetadata = documentMetadata
                    }

                    const uploadedDocument: IUploadedDocument = this.uploadedDocuments.find(d => d.externalId?.toString() === this.documentId?.toString())

                    // uploaded oc2 has different properties to purchased & uses document.fileName when downloading
                    if (uploadedDocument) {
                        this.documentMetadata.documentDate = format(uploadedDocument.updatedOn, 'dd-MM-yyyy')
                        this.documentMetadata.documentType = HighLevelDocumentType.UploadedOC2
                        this.documentMetadata.documentId = uploadedDocument.externalId
                        this.documentMetadata.uploadedOc2FileName = uploadedDocument.fileName
                    }
                }

                this.documentSessionStartTime = new Date()
                this.updatePageTitle()
                this.documentMetadataLoaded = true
            },

            updatePageTitle() {
                if (this.documentMetadata && this.route.name === Route.DocumentViewer) {
                    const metadata = this.documentMetadata
                    if (metadata.documentType === HighLevelDocumentType.Uploaded) {
                        document.title = `${ metadata.filename } - ${ metadata.documentDate }`
                    } else if (metadata.documentType === HighLevelDocumentType.UploadedOC2) {
                        document.title = this.documentMetadata.uploadedOc2FileName
                    } else {
                        document.title = `${ metadata.titleNo } -  ${ metadata.documentType } - ${ metadata.documentDate }`
                    }
                } else if (this.documentNotFound === true) {
                    document.title = 'Document not found'
                    // eslint-disable-next-line max-len
                    this.logDocumentError(`Document viewer unable to retrieve document for user with Document type: ${ this.documentType } Document ID: ${ this.documentId } for User ID: ${ this.userId } in Organisation ID ${ this.organisationId }`)
                }
            },

            async logDocumentUsage() {
                if (!this.documentNotFound) {
                    const documentSessionEndTime = new Date()
                    const documentSessionDurationSeconds = Math.round((documentSessionEndTime.valueOf() - this.documentSessionStartTime) / 1000)
                    await this.logHeapEvent({
                        type: 'Close pdf viewer',
                        metadata: {
                            documentId: this.documentId,
                            titleNumber: this.documentMetadata.titleNo,
                            documentType: this.documentType,
                            documentDate: this.documentMetadata.documentDate,
                            filedUnder: this.documentMetadata.filedUnderField,
                            hasScrolledDocument: this.hasScrolledDocument,
                            hasInteractedWithDocument: this.hasInteractedWithDocument,
                            hasDownloadedDocument: this.hasDownloadedDocument,
                            documentSessionDurationSeconds,
                        },
                    })
                }
            },

            logDocumentError(error) {
                console.error(error)
            },
        },
    }
</script>

<style lang="scss">
    @import 'document.scss';
</style>
