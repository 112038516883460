<template>
    <div class="search-box"
         data-test="search-box">
        <ow-autocomplete :auto-select-first="true"
                         :autofocus="autofocus"
                         :include-search-icon="true"
                         :is-input-visible-in-data-dog="true"
                         :is-loading="isLoading"
                         :items="items"
                         :label="label"
                         :using-async-data="true"
                         data-test-attribute="search-box-input"
                         data-track-attribute="SEARCH - input box"
                         item-text="titleNumber"
                         @clear="onClearHandler"
                         @focus="onFocusHandler"
                         @enter-pressed="onEnterPressedHandler"
                         @search-input-update="onSearchInputUpdateHandler">
            <template #item="{ item }">
                <v-list-item v-if="showHeaderItem(item.props)"
                             class="wrap-list-item-text">
                    <div data-test="search-box-header-item">
                        <span class="search-box__header-text">{{ item.props.header }}</span>
                        <div v-if="showInfoItem(item.props)"
                             class="search-box__info"
                             data-test="search-box-info-item">
                            <v-divider class="mt-2 mb-1" />
                            <ow-icon-text is-small-icon
                                          :is-single-line="false"
                                          is-italic-text
                                          :text="item.props.infoText"
                                          data-test="search-box-info-item-text"
                                          icon="$info" />
                        </div>
                    </div>
                </v-list-item>

                <v-list-item v-if="showSearchTitleItem(item.props)"
                             @click="onItemClick(item.props)">
                    <div class="search-box__result"
                         data-test="search-box-result-item">
                        <span class="search-box__result-title caption-highlight mr-2">
                            {{ item.props.titleNumber }}
                        </span>
                        <span class="search-box__result-tenure">
                            <ow-colored-label :background-color="tenureBackgroundColor(item.props.tenure)"
                                              :is-small="true"
                                              data-test="search-box-item-tenure-type">
                                {{ item.props.tenure }}
                            </ow-colored-label>
                        </span>
                    </div>
                </v-list-item>

                <v-list-item v-if="showSearchHistoryItem(item.props)"
                             @click="onItemClick(item.props)">
                    <div class="search-box__history"
                         data-test="search-box-result-item">
                        <ow-icon-text :is-single-line="true"
                                      :is-small-icon="true"
                                      :text="item.props.titleNumber"
                                      data-test="search-box-result-search-history-item"
                                      icon="$history" />
                    </div>
                </v-list-item>

                <v-list-item v-if="showAllResultsItem(item.props)"
                             class="search-box__result search-box__search-more"
                             data-test="search-box-search-more-item"
                             @click="showAllResults">
                    <span class="caption-regular">{{ item.props.message }}</span>
                </v-list-item>

                <v-list-item v-if="showClearHistoryItem(item.props)"
                             class="search-box__result search-box__search-more"
                             data-test="search-box-clear-search-history"
                             @click="clearSearchHistory">
                    <span class="caption-regular">Clear recent search history</span>
                </v-list-item>
            </template>
        </ow-autocomplete>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'

    import owAutocomplete from '@/components/core/ow-autocomplete.vue'
    import OwColoredLabel from "@/components/core/ow-colored-label.vue"
    import OwIconText from "@/components/core/ow-icon-text.vue"
    import { SearchResultType } from "@/enums/search-result-type"
    import { ResultItemInterface } from '@/interfaces/search-result-item.interface'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'SearchBox',

        components: {
            owAutocomplete,
            OwColoredLabel,
            OwIconText,
        },

        props: {
            items: {
                type: Array as PropType<Array<ResultItemInterface>>,
                required: true,
            },
            label: {
                type: String,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: true,
            },
            autofocus: {
                type: Boolean,
                default: false,
            },
        },

        emits: [
            'change',
            'enter-pressed',
            'search-input-update',
            'show-all-results',
            'item-click',
            'clear-search-history',
            'clear',
            'focus',
        ],

        methods: {
            showSearchTitleItem(item: ResultItemInterface): boolean {
                return item.type === SearchResultType.title
            },

            showSearchHistoryItem(item: ResultItemInterface): boolean {
                return item.type === SearchResultType.history
            },

            showClearHistoryItem(item: ResultItemInterface): boolean {
                return item.type === SearchResultType.clearHistory
            },

            showAllResultsItem(item: ResultItemInterface): boolean {
                return item.type === SearchResultType.allResults
            },

            showHeaderItem(item: any): boolean {
                return !isNullOrWhitespace(item.header)
            },

            showInfoItem(item: any): boolean {
                return !isNullOrWhitespace(item.infoText)
            },

            onEnterPressedHandler(selectedItem: ResultItemInterface): void {
                this.$emit('enter-pressed', selectedItem)
            },

            onItemClick(item: ResultItemInterface): void {
                this.$emit('change', item)
            },

            onSearchInputUpdateHandler(searchString: string): void {
                const stringToEmit = !isNullOrWhitespace(searchString) ? searchString.toLowerCase() : ''
                this.$emit('search-input-update', stringToEmit)
            },

            clearSearchHistory(): void {
                this.$emit('clear-search-history')
            },

            onClearHandler(): void {
                this.$emit('clear')
            },

            onFocusHandler(): void {
                this.$emit('focus')
            },

            showAllResults(): void {
                this.$emit('show-all-results')
            },

            tenureBackgroundColor(itemTenure: string): string {
                if (isNullOrWhitespace(itemTenure)) {
                    return 'transparent'
                }
                switch (itemTenure) {
                    case 'Freehold':
                        return '#f7882f'
                    case 'Leasehold':
                        return '#2bb0e5'
                    case 'Other':
                        return '#5a5a5a'
                    default:
                        return '#000000'
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './search-box';
</style>
