import {
    Column,
    ColumnConfig,
    Model,
} from '@bryntum/grid'
import { pillMarkup } from '.'

export interface IPillColumnProps extends Partial<ColumnConfig> {
    mapPillClass?: (item: string) => string
    mapPillItems?: (record?: Model) => string[]
}

export const usePillsColumn = (args: IPillColumnProps): Partial<Column> => ({
    ...args,
    field: args.field,
    text: args.text,
    width: args.width,
    type: 'template',

    template: ({ record }: any) => {
        return pillMarkup({
            record,
            mapPillClass: args.mapPillClass,
            mapPillItems: args.mapPillItems,
        })
    },
} as Partial<Column>)
