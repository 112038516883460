const currentUrl = window.location.origin
const baseSettings = {
    client_id: 'client_app',
    response_type: 'code',
    scope: 'openid consumer_api offline_access',
    post_logout_redirect_uri: 'https://www.orbitalwitness.com',
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTimeInSeconds: 120,
    filterProtocolClaims: true,
    loadUserInfo: false,
    authority: 'https://localhost:44306',
    redirect_uri: currentUrl + '/callback.html',
    silent_redirect_uri: currentUrl + '/silent-renew.html',
}

export const localAuthSettings = {
    ...baseSettings,
    authority: 'https://auth-dev.intow.tech',
}

export const devAuthSettings = {
    ...baseSettings,
    authority: 'https://auth-dev.intow.tech',
}

export const prodAuthSettings = {
    ...baseSettings,
    authority: 'https://auth.orbitalwitness.com',
}

export const getAuthSettings = () => {
    if (currentUrl.includes('dev.intow.tech') || currentUrl.includes('jolly-beach-')) {
        return devAuthSettings
    }
    if (currentUrl.includes('app.orbitalwitness.com') || currentUrl.includes('purple-bay-')) {
        return prodAuthSettings
    }

    return localAuthSettings
}
