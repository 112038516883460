import { DocumentOrderStatus } from '@/consts/document-order-status'
import { DocumentType } from '@/consts/document-type'
import { HighLevelDocumentType } from '@/consts/document-high-level-type'
import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
import { ICopiesFiledDocument } from '@/interfaces/copies-filed-document.interface'

/**
 * Generate a key value for ordering a document.
 * This is returned from the server with the orderId, so needed to tie the order and request together.
 * @param titleNumber
 * @param documentType
 * @param document
 * @returns {string}
 */
export const generateOrderKeyValue = (titleNumber: string, documentType: number, document: ICopiesFiledDocument = null): string => {
    if (documentType === DocumentType.REGISTER || documentType === DocumentType.PLAN) {
        return titleNumber
    }
    return generateOrderKeyValueForOC2Document(titleNumber, document.filedUnder, document.documentDate, document.entryNumbers)
}

export const generateOrderKeyValueForOC2Document = (titleNumber:string, filedUnder:string, documentDate: string, entryNumbers: string[]): string => {
    const entryNumbersText = entryNumbers ? entryNumbers.join(',') : ''
    return `${ titleNumber }:${ filedUnder ?? '' }:${ documentDate ?? '' }:${ entryNumbersText }`
}

/**
 * Returns true if the status is one of the ones we recognise as being a downloadable state, false otherwise. Should be replaced with property on API.
 * @param status the document order status
 */
export const isDocumentDownloadable = (status: number): boolean => {
    return [
        DocumentOrderStatus.ORDERED,
        DocumentOrderStatus.COMPLETED,
        DocumentOrderStatus.DERIVING,
        DocumentOrderStatus.DERIVED,
        DocumentOrderStatus.AUGMENTING,
        DocumentOrderStatus.AUGMENTED,
        DocumentOrderStatus.AUGMENTATION_FAILED,
    ].includes(status)
}

/**
 * Statuses that are not considered to be in progress from the perspective of blocking the UI.
 * @param status the document order status
 */
export const isDocumentOrderDoneForNowButNotDownloadable = (status: number): boolean => {
    return [
        DocumentOrderStatus.SENT_IN_POST,
        DocumentOrderStatus.UNDER_INVESTIGATION,
    ].includes(status)
}

/***
 * Returns true is the status is one of the ones we recognise as being a successfully ordered state, false otherwise. Should be replaced with property on API.
 * @param status the document order status.
 * @param hmlrAvailabilityCode the hmlr availability code.
 */
export const isDocumentOrderable = (status: number, hmlrAvailabilityCode: string): boolean => {
    if (status != null) { // if there is a document status, then we have a record of it in the system.
        return !([
            DocumentOrderStatus.ORDERING,
            DocumentOrderStatus.ORDERED,
            DocumentOrderStatus.DERIVED,
            DocumentOrderStatus.AUGMENTED,
            DocumentOrderStatus.AUGMENTING,
            DocumentOrderStatus.DERIVED,
            DocumentOrderStatus.DERIVING,
            DocumentOrderStatus.UNDER_INVESTIGATION,
            DocumentOrderStatus.AUGMENTATION_FAILED,
        ].includes(status))
    }
    return hmlrAvailabilityCode !== HmlrDocumentAvailabilityCode.Unavailable
}

/**
 * Indicates whether the document order status is considered an overall failure.
 * @param {DocumentOrderStatus} status
 * @returns {boolean}
 */
export const isDocumentOrderFailure = (status: number): boolean => {
    switch (status) {
        case DocumentOrderStatus.FAILURE:
        case DocumentOrderStatus.REMOTE_FAILURE:
        case DocumentOrderStatus.DERIVATION_FAILED:
        case DocumentOrderStatus.UNAVAILABLE:
            return true
        default:
            return false
    }
}

/**
 * Indicates whether the document order status implies that the document has been posted to the user as a physical copy.
 * @param {DocumentOrderStatus} status
 * @returns {boolean}
 */
export const isDocumentOrderSentInPost = (status: number): boolean => {
    switch (status) {
        case DocumentOrderStatus.SENT_IN_POST:
            return true
        default:
            return false
    }
}

/**
 * Indicates whether the document order status implies that the document is being investigated.
 * @param {DocumentOrderStatus} status
 * @returns {boolean}
 */
export const isDocumentOrderBeingInvestigated = (status: number): boolean => {
    switch (status) {
        case DocumentOrderStatus.UNDER_INVESTIGATION:
            return true
        default:
            return false
    }
}

/***
 * Returns the title number associated with a keyValue.
 * @param keyValue - the keyValue associated with a document order and status update.
 */
export const getTitleNumberFromKeyValue = (keyValue: string): string => {
    return keyValue?.split(':')[0]
}

/***
 * Returns a high level document type used for UX considerations based on a document type.
 * @param documentType
 */
export const getHighLevelDocumentTypeFromDocumentType = (documentType: number): string => {
    switch (documentType) {
        case DocumentType.REGISTER:
            return HighLevelDocumentType.Register
        case DocumentType.PLAN:
            return HighLevelDocumentType.TitlePlan
        default:
            return HighLevelDocumentType.OC2Document
    }
}

/**
 * Indicates whether the document order status is considered to be in a state likely to change
 * imminently such that a loading indicator may be applicable.
 * @param {DocumentOrderStatus} status - the document order status.
 * @returns {boolean} - true if some loading animation is applicable, false if not.
 */
export const showLoadingIndicatorForOrderStatus = (status: number): boolean => {
    switch (status) {
        case DocumentOrderStatus.ORDERED:
        case DocumentOrderStatus.ORDERING:
        case DocumentOrderStatus.RETRYING:
        case DocumentOrderStatus.DERIVING:
        case DocumentOrderStatus.AUGMENTING:
            return true
        default:
            return false
    }
}
