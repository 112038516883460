<template>
    <epc-chart :energy-band="energyBand"
               :energy-rating="energyRating"
               divider-position="d-e"
               divider-text="100 would be typical">
        <template #aboveDividerBands>
            <epc-band range="0-25"
                      :band="EnergyBand.A" />
            <epc-band range="26-50"
                      :band="EnergyBand.B" />
            <epc-band range="51-75"
                      :band="EnergyBand.C" />
            <epc-band range="76-100"
                      :band="EnergyBand.D" />
        </template>
        <template #belowDividerBands>
            <epc-band range="101-125"
                      :band="EnergyBand.E" />
            <epc-band range="126-150"
                      :band="EnergyBand.F" />
            <epc-band range="150+"
                      :band="EnergyBand.G" />
        </template>
    </epc-chart>
</template>

<script lang="ts">
    import EpcBand from '@/components/epc/epc-band.vue'
    import EpcChart from '@/components/epc/epc-chart.vue'
    import { EnergyBand } from '@/enums/epc.enum'

    export default {
        name: 'DecEpcChart',

        components: {
            EpcChart,
            EpcBand,
        },

        props: {
            energyBand: {
                type: String,
                required: true,
            },
            energyRating: {
                type: Number,
                required: true,
            },
        },

        data() {
            return {
                EnergyBand,
            }
        },
    }
</script>
