<template>
    <v-card class="information-card">
        <v-card-title class="information-card__title content__subheading">
            Debug Mode
        </v-card-title>
        <v-card-text class="information-card__text">
            <p class="body-copy">
                Enable this option to display additional diagnostic features and information
                while using the application.
            </p>
        </v-card-text>
        <ow-card-actions class="information-card__actions">
            <v-checkbox v-model="debugModeEnabled"
                        hide-details
                        color="primary"
                        label="Enable debug mode" />
        </ow-card-actions>
    </v-card>
</template>

<script>
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import OwCardActions from '@/components/core/ow-card-actions.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import * as types from '@/store/modules/diagnostics/types'

    export default {
        name: 'DebugModeOption',
        components: {
            OwCardActions,
        },
        mixins: [FeatureFlagsMixin],

        computed: {

            ...mapState('diagnostics', {
                getDebugModeEnabled: state => state.debugModeEnabled,
            }),

            debugModeEnabled: {
                get() {
                    return this.shouldAllowDebugMode &&
                        this.getDebugModeEnabled
                },

                set(val) {
                    this.mutateDebugMode(val)
                },
            },
        },

        methods: {

            ...mapMutations('diagnostics', {
                mutateDebugMode: types.DIAGNOSTICS_MUTATE_DEBUG_MODE,
            }),

        },
    }
</script>
