// Actions
export const SKETCHES_LOAD_BY_MATTER_ID = 'SKETCHES_LOAD_BY_MATTER_ID'
export const SKETCHES_TOGGLE_SKETCH_VISIBILITY = 'SKETCHES_TOGGLE_SKETCH_VISIBILITY'
export const SKETCHES_SET_SNAPPING_MODE = 'SKETCHES_SET_SNAPPING_MODE'
export const SKETCHES_NEW_SKETCH = 'SKETCHES_NEW_SKETCH'
export const SKETCHES_INITIALISE = 'SKETCHES_INITIALISE'
export const SKETCHES_REMOVE_SKETCH = 'SKETCHES_REMOVE_SKETCH'
export const SKETCHES_CLEAR_CURRENT_SKETCH = 'SKETCHES_CLEAR_CURRENT_SKETCH'
export const SKETCHES_SAVE_CHANGES = 'SKETCHES_SAVE_CHANGES'
export const SKETCHES_SET_CURRENT_SKETCH_STYLE = 'SKETCHES_SET_CURRENT_SKETCH_STYLE'
export const SKETCHES_SET_SKETCHES_VISIBILITY = 'SKETCHES_SET_SKETCHES_VISIBILITY'
export const SKETCHES_SET_SKETCHES_LABEL_VISIBILITY = 'SKETCHES_SET_SKETCHES_LABEL_VISIBILITY'
export const SKETCHES_RESET = 'SKETCHES_RESET'
export const SKETCHES_INIT_DRAW_INTERACTION = 'SKETCHES_INIT_DRAW_INTERACTION'
export const SKETCHES_ACTIVATE_SKETCH = 'SKETCHES_ACTIVATE_SKETCH'
export const SKETCHES_SELECT_BY_PIXEL = 'SKETCHES_SELECT_BY_PIXEL'
export const SKETCHES_UNDO = 'SKETCHES_UNDO'
export const SKETCHES_EXPORT_SPATIAL_FILE = 'SKETCHES_EXPORT_SPATIAL_FILE'
export const SKETCHES_UPDATE_SKETCHES = 'SKETCHES_UPDATE_SKETCHES'
export const SKETCHES_IMPORT_TITLES = 'SKETCHES_IMPORT_TITLES'

// Mutations
export const SKETCHES_MUTATE_LOADING_SKETCHES = 'SKETCHES_MUTATE_LOADING_SKETCHES'
export const SKETCHES_MUTATE_SKETCHES = 'SKETCHES_MUTATE_SKETCHES'
export const SKETCHES_MUTATE_COLLAPSE_LIST = 'SKETCHES_MUTATE_COLLAPSE_LIST'
export const SKETCHES_SET_MODE = 'SKETCHES_MUTATE_MODE'
export const SKETCHES_MUTATE_SELECT_SKETCHES = 'SKETCHES_MUTATE_SELECT_SKETCHES'
export const SKETCHES_MUTATE_DESELECT_SKETCHES = 'SKETCHES_MUTATE_DESELECT_SKETCHES'
export const SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES = 'SKETCHES_MUTATE_CLEAR_SELECTED_SKETCHES'
export const SKETCHES_MUTATE_SKETCH = 'SKETCHES_MUTATE_SKETCH'
export const SKETCHES_MUTATE_SHOW_BULK_OPTIONS = 'SKETCHES_MUTATE_SHOW_BULK_OPTIONS'
export const SKETCHES_MUTATE_CURRENT_SKETCH = 'SKETCHES_MUTATE_CURRENT_SKETCH'
export const SKETCHES_MUTATE_SNAPPING_MODE = 'SKETCHES_MUTATE_SNAPPING_MODE'
export const SKETCHES_MUTATE_TARGET_MAP = 'SKETCHES_MUTATE_TARGET_MAP'
export const SKETCHES_MUTATE_ADD_SKETCH = 'SKETCHES_MUTATE_ADD_SKETCH'
export const SKETCHES_MUTATE_REMOVE_SKETCH = 'SKETCHES_MUTATE_REMOVE_SKETCH'
export const SKETCHES_MUTATE_IS_DRAWING = 'SKETCHES_MUTATE_IS_DRAWING'
export const SKETCHES_MUTATE_HIGHLIGHT_SKETCH = 'SKETCHES_MUTATE_HIGHLIGHT_SKETCH'
export const SKETCHES_MUTATE_SKETCH_TO_ACTIVATE_ON_LOAD = 'SKETCHES_MUTATE_SKETCH_TO_ACTIVATE_ON_LOAD'
export const SKETCHES_MUTATE_SHOW_SETTING_MODAL = 'SKETCHES_MUTATE_SHOW_SETTING_MODAL'

export const SKETCHES_MUTATE_ADD_SKETCH_ID_PENDING_SAVE = 'SKETCHES_MUTATE_ADD_SKETCH_ID_PENDING_SAVE'
export const SKETCHES_MUTATE_REMOVE_SKETCH_ID_PENDING_SAVE = 'SKETCHES_MUTATE_REMOVE_SKETCH_ID_PENDING_SAVE'
export const SKETCHES_MUTATE_INITIALISING = 'SKETCHES_MUTATE_INITIALISING'
export const SKETCHES_MUTATE_ZOOM_TO_SKETCH = 'SKETCHES_MUTATE_ZOOM_TO_SKETCH'
export const SKETCHES_MUTATE_CURRENT_MODE = 'SKETCHES_MUTATE_CURRENT_MODE'
export const SKETCHES_MUTATE_DRAW_LAYER_WITH_SKETCH = 'SKETCHES_MUTATE_DRAW_LAYER_WITH_SKETCH'
export const SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS = 'SKETCHES_MUTATE_SHOW_SKETCH_MODE_OPTIONS'
export const SKETCHES_MUTATE_CURRENT_SKETCH_STYLE = 'SKETCHES_MUTATE_CURRENT_SKETCH_STYLE'
export const SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL = 'SKETCHES_MUTATE_SHOW_NAME_DESCRIPTION_MODAL'
export const SKETCHES_MUTATE_ENABLE_MAP_SELECTION = 'SKETCHES_MUTATE_ENABLE_MAP_SELECTION'
export const SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX = 'SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_INDEX'
export const SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD = 'SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_ADD'
export const SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL = 'SKETCHES_MUTATE_HISTORY_CURRENT_SKETCH_GEOJSON_REMOVE_ALL'
export const SKETCHES_MUTATE_MAKING_UNDO_REDO_CHANGES = 'SKETCHES_MUTATE_MAKING_UNDO_REDO_CHANGES'
export const SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL = 'SKETCHES_MUTATE_SHOW_IMPORT_TITLES_MODAL'
