<template>
    <ow-page-layout header-icon="$buy"
                    header-title="Checkout"
                    page-name="checkout-review">
        <template #navigation>
            <router-link :to="backLink"
                         class="content__subheading"
                         data-track="CHECKOUT-HOMEPAGE - Navigate back">
                <v-icon>$chevron-left</v-icon>
                <span>Back</span>
            </router-link>
        </template>
        <section v-if="hasCartItems"
                 class="checkout-review__content">
            <checkout-review-order :client-code="currentMatter.clientCode"
                                   :items="cartItems"
                                   :matter-code="currentMatter.matterCode"
                                   @confirm="confirmOrder"
                                   @update-matter="updateOrderMatter" />
        </section>
        <section v-else
                 class="checkout-review__empty">
            <ow-card class="checkout-review__empty--card"
                     has-outline>
                <h2 class="content__heading">
                    Review order
                </h2>
                <p class="body-copy">
                    Please find titles <router-link :to="{ name: Route.Homepage }">
                        here
                    </router-link> in order to add documents and reports to your basket.
                </p>
            </ow-card>
            <ow-card class="checkout-review__empty--card"
                     has-outline>
                <h2 class="content__heading">
                    Summary
                </h2>
                <ow-button large
                           disabled>
                    Your cart is empty
                </ow-button>
            </ow-card>
        </section>
    </ow-page-layout>
</template>

<script lang="ts">
    import {
        mapMutations,
        mapState,
    } from 'vuex'

    import CheckoutReviewOrder from '@/components/checkout/checkout-review-order.vue'
    import OwPageLayout from '@/components/core/layout/page.vue'
    import OwButton from '@/components/core/ow-button.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import { Route } from '@/enums/route.enum'
    import { IBasicMatter } from '@/interfaces/basic-matter.interface'
    import { IState } from '@/interfaces/store/state.interface'
    import {
        CHECKOUT_MUTATE_BASIC_MATTER_CODES,
        CHECKOUT_MUTATE_ENTRY_POINT,
        CHECKOUT_MUTATE_ORDER_CONFIRMED,
    } from '@/store/modules/checkout/types'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'CheckoutReview',

        components: {
            CheckoutReviewOrder,
            OwCard,
            OwButton,
            OwPageLayout,
        },

        beforeRouteEnter(_, from, next) {
            next((vm:any) => {
                if (!from.path.includes('checkout/processing')) {
                    // Set where the user has come from for the back link when the page loads.
                    // If coming from the processing page, then don't reset it
                    vm.entryPoint = from.fullPath
                }
            })
        },

        data() {
            return {
                orderMatter: null,
                Route,
                entryPoint: '',
            } as {
                orderMatter: { clientCode?: string, matterCode?: string },
                Route: typeof Route,
                entryPoint: string,
            }
        },

        computed: {
            ...mapState({
                cartItems: (state: IState) => state.checkout.items,
                currentMatter: (state: IState) => state.matter.currentMatter,
                entryPoint: (state: IState) => state.checkout.entryPoint,
            }),

            backLink(): string {
                return isNullOrWhitespace(this.entryPoint) ? '/' : this.entryPoint
            },

            hasCartItems(): boolean {
                return !isNullOrEmpty(this.cartItems)
            },
        },

        watch: {
            entryPoint: {
                handler() {
                    this.setEntryPoint(this.entryPoint)
                },
                immediate: true,
            },
        },

        methods: {
            ...mapMutations('checkout', {
                setOrderConfirmed: CHECKOUT_MUTATE_ORDER_CONFIRMED,
                setBasicMatterDetails: CHECKOUT_MUTATE_BASIC_MATTER_CODES,
                setEntryPoint: CHECKOUT_MUTATE_ENTRY_POINT,
            }),

            confirmOrder(matterDetails: IBasicMatter): void {
                this.setBasicMatterDetails(matterDetails)
                this.setOrderConfirmed(true)
                this.$router.push({ name: Route.CheckoutProcessing })
            },

            updateOrderMatter(matter: IBasicMatter): void {
                this.orderMatter = matter
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './checkout-review';
</style>
