// @/stores/scotland/index.ts
import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'

import amActions from './actions'

/**
 * Represents the state of the scotland store.
 */
export interface IScotlandState {
    titleNumbers: string[],
    selectedScotlandsTitles: boolean,
    selectedTitleNumbers: Array<string>,
    isLoading: boolean
    testTitleNumbers: string[]
}

/**
 * The `useScotlandStore` variable is a store created using the `defineStore` function.
 * It is used to manage the state, getters, and actions related to asset monitoring.
 */
export const useScotlandStore = defineStore('scotland', {
    state: (): IScotlandState => {
        return {
            titleNumbers: [],
            selectedScotlandsTitles: false,
            isLoading: false,
            selectedTitleNumbers: [],
            testTitleNumbers: [
                'MID1000', 'MID1001', 'MID1002', 'MID1003', 'MID1004', 'MID1005', 'MID1006',
                'MID1007', 'MID1008', 'FFE7853', 'MID1007', 'GLA219243', 'MID74835', 'GLA225755',
                'MID106063', 'FFE7853', 'GLA225755', 'GLA245747', 'GLA48510', 'ABN122690',
                'REN157395', 'LAN230774', 'GLA219198', 'MID74835', 'ABN834', 'ANG17791',
                'ABN122690', 'FFE7853', 'ABN834', 'ELN21778', 'INV14014', 'MID74835', 'MID52965',
                'MID65218', 'FFE7853', 'ABN834', 'WLN12445', 'ELN27303', 'MID77579', 'GLA166528',
                'MID1001', 'GLA69306', 'GLA48510', 'GLA166528', 'MID77579', 'GLA16008',
                'MID106063', 'MID106065', 'GLA222219', 'GLA219182', 'GLA219198', 'GLA219240',
                'GLA219243', 'GLA219244', 'GLA225755', 'ANG17791', 'GLA166528', 'GLA48510',
                'ANG17791', 'GLA48510', 'GLA166528', 'GLA166528', 'GLA16008', 'ANG17791',
                'WLN12445', 'ABN122690', 'GLA36389', 'GLA69306', 'LAN49427', 'GLA126936',
                'GLA222219', 'WLN12445', 'MID77579', 'ELN27303', 'MID1001', 'ABN834', 'ELN21778',
                'ELN27303', 'ABN834', 'ELN21778', 'ELN27303', 'MID9000', 'MID9010', 'MID9020',
                'MID9030', 'MID9000', 'FFE7853', 'MID52965',
            ],
        }
    },
    actions: { ...amActions },
})

// Enables Hot Module Replacement in Pinia
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useScotlandStore, import.meta.hot))
}
