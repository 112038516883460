import Feature from 'ol/Feature'
import Geometry from 'ol/geom/Geometry'

import { IMapState } from '@/store/modules/map/index'

import {
    MAP_ANCIENT_WOODLAND_MUTATE_OVER_TEXT,
    MAP_AONB_MUTATE_OVER_TEXT,
    MAP_COMMON_LAND_MUTATE_OVER_TEXT,
    MAP_CONSERVATION_AREAS_MUTATE_OVER_TEXT,
    MAP_DNO_MUTATE_OVER_TEXT,
    MAP_FLOOD_RISKS_SEA_AND_RIVERS_MUTATE_OVER_TEXT,
    MAP_FLOOD_RISKS_SURFACE_WATER_MUTATE_OVER_TEXT,
    MAP_FLOOD_ZONE_2_MUTATE_OVER_TEXT,
    MAP_FLOOD_ZONE_3_MUTATE_OVER_TEXT,
    MAP_HIGHWAYS_MUTATE_OVER_MAINTENANCE_FEATURES,
    MAP_HIGHWAYS_MUTATE_OVER_PROW_FEATURES,
    MAP_LISTED_BUILDINGS_MUTATE_OVER_TEXT,
    MAP_MUTATE_DISABLED,
    MAP_MUTATE_INITIAL_TITLE,
    MAP_MUTATE_LAUNCH_SEARCH_RESULT,
    MAP_MUTATE_MARKER_LAYER_ADD,
    MAP_MUTATE_NPS_OVER_IDS,
    MAP_MUTATE_OVER_TITLE_NUMBERS,
    MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS,
    MAP_MUTATE_TAKING_SNAPSHOT,
    MAP_PREVENT_TITLE_BOUNDARY_LAYER_DESELECTION,
    MAP_SCHEDULED_MONUMENTS_MUTATE_OVER_TEXT,
    MAP_SET_NPS_LAYER_CLICKABLE,
    MAP_SSSI_MUTATE_OVER_TEXT,
    MAP_UNREGISTERED_MUTATE_OVER_TEXT,
} from './types'

export default {
    [MAP_MUTATE_MARKER_LAYER_ADD](state: IMapState, layer: any): void {
        state.markerLayers.push(layer)
    },

    _setCurrentZoomLevel: function(state: IMapState, zoom: number): void {
        state.currentZoomLevel = zoom
    },

    _setShowNPSTitleNumbers: function(state: IMapState, show: boolean): void {
        state.nps.showNPSTitleNumbers = show
    },

    _setShowNPSFreeholds: function(state: IMapState, show: boolean): void {
        state.nps.showNPSFreeholds = show
    },

    _setShowNPSLeaseholds: function(state: IMapState, show: boolean): void {
        state.nps.showNPSLeaseholds = show
    },

    _setShowNPSStreet: function(state: IMapState, show: boolean): void {
        state.dataLayers.highways.showHighwayMaintenance = show
    },

    _setShowRadon: function(state: IMapState, show: boolean): void {
        state.dataLayers.radon.showRadonLayer = show
    },

    _setShowScotland: function(state: IMapState, show: boolean): void {
        state.dataLayers.scotland.showScotlandLayer = show
    },

    _setShowPublicRightsOfWayLayer: function(state: IMapState, show: boolean): void {
        state.dataLayers.highways.showPublicRightsOfWayLayer = show
    },

    _setShowUnregisteredLandLayer: function(state: IMapState, show: boolean): void {
        state.dataLayers.unregisteredLand.showUnregisteredLandlayer = show
    },

    _setNPSLoadingTiles: function(state: IMapState, numTilesLoading: number): void {
        state.nps.loadingTiles = numTilesLoading
    },

    _setOverTitles: function(state: IMapState, titles: any): void {
        state.nps.overTitles = titles
    },

    [MAP_MUTATE_OVER_TITLE_NUMBERS]: (state: IMapState, titleNumbers: Array<string>): void => {
        state.nps.overTitleNumbers = titleNumbers
        state.nps.highlightLayer.changed()
    },

    _setMapIsMoving: function(state: IMapState, moving: boolean): void {
        state.events.mapIsMoving = moving
    },

    _setCurrentMapCentre: function(state: IMapState, centre: Array<number>): void {
        state.currentMapCentre = centre
    },

    [MAP_MUTATE_LAUNCH_SEARCH_RESULT](state: IMapState, val: any): void {
        state.launchSearchResult = val
    },

    [MAP_MUTATE_NPS_OVER_IDS](state: IMapState, val: Array<string>): void {
        state.nps.overIds = val
    },

    [MAP_MUTATE_INITIAL_TITLE](state: IMapState, titleNumber: string): void {
        state.initialTitle = titleNumber
    },

    [MAP_HIGHWAYS_MUTATE_OVER_PROW_FEATURES](state: IMapState, features: Array<Feature<Geometry>>): void {
        const changed = state.dataLayers.highways.overPublicRightsOfWayFeatures.map(x => x.get('ogc_fid')).join(',') !==
            features.map(x => x.get('ogc_fid')).join(',')

        if (changed) {
            state.dataLayers.highways.overPublicRightsOfWayFeatures = features
            state.dataLayers.highways.publicRightsOfWayLayer.changed()
        }
    },

    [MAP_HIGHWAYS_MUTATE_OVER_MAINTENANCE_FEATURES](state: IMapState, features: Array<Feature<Geometry>>): void {
        const changed = state.dataLayers.highways.overMaintenanceFeatures.map(x => x.get('ogc_fid')).join(',') !==
            features.map(x => x.get('ogc_fid')).join(',')

        if (changed) {
            state.dataLayers.highways.overMaintenanceFeatures = features
            state.dataLayers.highways.highwayMaintenanceLayer.changed()
        }
    },

    [MAP_LISTED_BUILDINGS_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.listedBuildings.overListedBuildingsText = textArray
        state.dataLayers.listedBuildings.event = event
    },

    [MAP_CONSERVATION_AREAS_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.conservationAreas.overConservationAreasText = textArray
        state.dataLayers.conservationAreas.event = event
    },

    [MAP_SCHEDULED_MONUMENTS_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.scheduledMonuments.overScheduledMonumentsText = textArray
        state.dataLayers.scheduledMonuments.event = event
    },

    [MAP_DNO_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.dno.overDNOText = textArray
        state.dataLayers.dno.event = event
    },

    [MAP_FLOOD_ZONE_3_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.floodZone3.overFloodZone3Text = textArray
        state.dataLayers.floodZone3.event = event
    },

    [MAP_FLOOD_ZONE_2_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.floodZone2.overFloodZone2Text = textArray
        state.dataLayers.floodZone2.event = event
    },

    [MAP_FLOOD_RISKS_SEA_AND_RIVERS_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.floodRisksSeaAndRivers.overFloodRisksSeaAndRiversText = textArray
        state.dataLayers.floodRisksSeaAndRivers.event = event
    },

    [MAP_FLOOD_RISKS_SURFACE_WATER_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.floodRisksSurfaceWater.overFloodRisksSurfaceWaterText = textArray
        state.dataLayers.floodRisksSurfaceWater.event = event
    },

    [MAP_AONB_MUTATE_OVER_TEXT](state: IMapState, { features, event }: { features: any, event: any }): void {
        state.dataLayers.aonb.overAonbFeatures = features
        state.dataLayers.aonb.event = event
    },

    [MAP_COMMON_LAND_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.commonLand.overCommonLandText = textArray
        state.dataLayers.commonLand.event = event
    },

    [MAP_ANCIENT_WOODLAND_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.ancientWoodland.overAncientWoodlandText = textArray
        state.dataLayers.ancientWoodland.event = event
    },

    [MAP_SSSI_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.sssi.overSssiText = textArray
        state.dataLayers.sssi.event = event
    },

    [MAP_UNREGISTERED_MUTATE_OVER_TEXT](state: IMapState, { textArray, event }: { textArray: Array<string>, event: any }): void {
        state.dataLayers.unregisteredLand.overUnregisteredLand = textArray
        state.dataLayers.unregisteredLand.event = event
    },

    [MAP_MUTATE_TAKING_SNAPSHOT]: (state: IMapState, val: boolean): void => {
        state.snapshot.takingSnapshot = val
    },

    [MAP_MUTATE_SET_CLICKED_TITLE_NUMBERS](state: IMapState, titleNumbers: Array<string>): void {
        state.nps.clickedTitleNumbers = titleNumbers
    },

    [MAP_MUTATE_DISABLED]: (state: IMapState, val: boolean): void => {
        state.isMapDisabled = val
    },

    [MAP_PREVENT_TITLE_BOUNDARY_LAYER_DESELECTION]: (state: IMapState, val: boolean): void => {
        state.preventTitleBoundaryDeselection = val
    },

    [MAP_SET_NPS_LAYER_CLICKABLE]: (state: IMapState, val: boolean): void => {
        state.nps.allowLayerClick = val
    },
}
