import gql from 'graphql-tag'

const GET_TITLES_COMPLEXITY_QUERY = (withTitles = false, debugMode = false) => {
    let debugModeQueryAdditions = ''
    if (debugMode === true) {
        debugModeQueryAdditions = `titleComplexityRegisterIndicators {
                  documentsNotLeasesCount
                  leaseCount
                  allFlagsCount
                  qualityOfTenure
                  registersEntryCount
                  tenureType
                  yearOfOldestEntry
                }`
    }

    const query = `query titleRegisters(
            $matterId: Long!
            ${ withTitles === true ? '$titleNumbers: [String!]' : '' }
        ) {
            titleRegisters(
                matterId: $matterId
                ${ withTitles === true ? 'titleNumbers: $titleNumbers' : '' }
            ) {
                titleNumber
                ${ debugModeQueryAdditions }
                complexity {
                    category
                    value
                }
            }
        }`
    return gql`${ query }`
}

export { GET_TITLES_COMPLEXITY_QUERY }
