export enum UploadFileTypes {
    CSV = 'text/csv',
    PDF = 'application/pdf',
    SHP = 'application/zip',
    GEOJSON = 'application/geo+json',
    KML = 'application/vnd.google-earth.kml+xml',
    KMZ = 'application/vnd.google-earth.kmz',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}
export enum UploadFileExtensions {
    CSV = '.csv',
    PDF = '.pdf',
    SHP = '.zip',
    JSON = '.json',
    GEOJSON = '.geojson',
    KML = '.kml',
    KMZ = '.kmz',
    XLS = '.xls',
    XLSX = '.xlsx'
}
