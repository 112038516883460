<template>
    <vue-editor v-model="content"
                :class="{
                    'is-read-only': isReadOnly,
                    'hide-toolbar': hideToolbar,
                }"
                :disabled="isReadOnly"
                :editor-toolbar="customToolbar"
                :placeholder="isReadOnly ? null : placeholder"
                class="ow-rich-text-editor"
                data-test="ow-rich-text-editor"
                prepend-links-https
                use-markdown-shortcuts
                @blur="onBlur"
                @focus="onFocus" />
</template>

<script>
    import {
        Quill,
        VueEditor,
    } from 'vue3-editor'

    const Link = Quill.import('formats/link')
    Link.sanitize = (url) => {
        if (url.substring(0, 4) !== 'http') {
            return `https://${ url }`
        }

        return url
    }

    export default {
        components: {
            VueEditor,
        },

        props: {
            customToolbar: {
                type: Array,
                required: false,
                default: () => [
                    [ 'bold', 'italic', 'underline', 'link' ],
                    [ { list: 'ordered' }, { list: 'bullet' } ],
                    [ { color: [] } ],
                ],
            },
            modelValue: {
                type: String,
                required: false,
            },
            isReadOnly: {
                type: Boolean,
                required: false,
            },
            placeholder: {
                type: String,
                required: false,
                default: null,
            },
        },

        emits: [ 'update:modelValue' ],

        data() {
            return {
                hideToolbar: true,
            }
        },

        computed: {
            content: {
                get() {
                    return this.modelValue
                },
                set(val) {
                    this.$emit('update:modelValue', val)
                },
            },
        },

        methods: {
            onFocus() {
                this.hideToolbar = false
            },

            onBlur() {
                const toolbarOptions = document.querySelectorAll('.ql-picker-options')
                const styleDisplay = Array.from(toolbarOptions).map(optionEl => window.getComputedStyle(optionEl).display)
                const isToolbarOptionActive = styleDisplay.find(display => display !== 'none')

                if (!isToolbarOptionActive) {
                    this.hideToolbar = true
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-rich-text-editor.scss';
</style>
