<template>
    <div class="px-4">
        <ow-checkbox id="road-search-additional-roads"
                     v-model="checkboxModel"
                     :label="$t('searches.productConfig.con29.standardEnquiries.roadSearch.searchAdditionalRoads')"
                     hide-details />
        <div v-if="checkboxModel"
             class="pa-3 pt-4">
            <div class="v-row pa-0">
                <div v-t="'searches.productConfig.con29.standardEnquiries.roadSearch.roadName'"
                     class="caption-regular v-col pa-0" />
                <div v-t="'searches.productConfig.con29.standardEnquiries.roadSearch.usrn'"
                     class="caption-regular v-col-4 pa-0" />
            </div>
            <div v-for="(road, index) in roadConfig"
                 :key="index"
                 class="v-row pa-0 gc-2 pb-2"
                 :data-test="`Road-${index}`">
                <ow-textfield v-model="road.name"
                              class="v-col pa-0"
                              :data-test="`Road-${index}-text-field`"
                              :placeholder="$t('searches.productConfig.con29.standardEnquiries.roadSearch.roadPlaceholder', {
                                  index: index + 1,
                              })"
                              @blur="checkForEmptyRoads" />
                <ow-textfield v-model="road.usrn"
                              class="v-col-4 pa-0"
                              :data-test="`USRN-${index}-text-field`"
                              @blur="checkForEmptyRoads" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted,
             watch,
             watchEffect } from 'vue'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import owTextfield from '@/components/core/ow-textfield.vue'
    import { RoadConfig } from '@/components/matter-searches/types'

    const checkboxModel = defineModel<boolean>('checked')

    const props = defineProps<{
        roadConfig: RoadConfig[]
    }>()

    onMounted(() => {
        const roadData = props.roadConfig
        // check if the additional roads checkbox should be checked
        checkboxModel.value = (roadData.length > 0 && !!roadData[0]?.name)
    })

    // add a new road if the last road has been filled
    const addRoad = () => {
        props.roadConfig.push({
            name: '',
            usrn: '',
        })
    }

    // remove the last road if the last two roads are empty
    const removeRoad = (index: number) => {
        props.roadConfig.splice(index, 1)
    }

    // check if the last road is empty and add a new road if it is
    // also remove the last road if the last two roads are empty
    const checkForEmptyRoads = () => {
        const roadData = props.roadConfig
        const lastRoad = roadData[roadData.length - 1]

        if (!roadData.length || lastRoad.name) {
            addRoad()
        } else if (roadData.length > 1 && !lastRoad.name && !roadData[roadData.length - 2].name) {
            removeRoad(roadData.length - 1)
        }
    }

    watch(() => props.roadConfig, (val) => {
        checkForEmptyRoads()
        checkboxModel.value = (val.length > 0 && !!val[0]?.name && !!val[0]?.usrn)
    }, {
        once: true,
        immediate: true,
    })

    watchEffect(() => {
        checkForEmptyRoads()
    })


</script>
