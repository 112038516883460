export const getGeometryForTitleNumberResult = {
    type: 'FeatureCollection',
    features: [{
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1788',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526081.223, 187076.205],
                        [526081.324, 187076.089],
                        [526082.3, 187077],
                        [526087, 187081],
                        [526093.699, 187086.65],
                        [526095.25, 187087.95],
                        [526097.732, 187084.925],
                        [526098.859, 187083.673],
                        [526097.3, 187082.4],
                        [526089.9, 187076],
                        [526087.15, 187073.65],
                        [526086.699, 187073.65],
                        [526086.4, 187073.5],
                        [526086.05, 187073.35],
                        [526085.5, 187073.25],
                        [526084.949, 187073.2],
                        [526084.35, 187073.2],
                        [526083.699, 187073.3],
                        [526083.05, 187074.1],
                        [526081.223, 187076.205],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36080053,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1787',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526074.9, 187083.6],
                        [526071.36, 187087.73],
                        [526071.3, 187087.8],
                        [526071.55, 187088],
                        [526072.891, 187089.141],
                        [526076.25, 187092],
                        [526083.5, 187098.55],
                        [526084.449, 187099.45],
                        [526084.878, 187099.858],
                        [526085.852, 187098.799],
                        [526088.4, 187095.82],
                        [526086.949, 187094.5],
                        [526080.449, 187088.6],
                        [526075.75, 187084.45],
                        [526074.9, 187083.6],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36077891,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1786',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526081.324, 187076.089],
                        [526077.8, 187080.15],
                        [526077.992, 187079.929],
                        [526078.949, 187080.8],
                        [526081.05, 187082.7],
                        [526082.9, 187084.15],
                        [526090.4, 187090.5],
                        [526091.927, 187091.795],
                        [526092.809, 187090.845],
                        [526095.25, 187087.95],
                        [526093.699, 187086.65],
                        [526087, 187081],
                        [526082.3, 187077],
                        [526081.324, 187076.089],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36079387,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1785',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526074.9, 187083.6],
                        [526075.75, 187084.45],
                        [526080.449, 187088.6],
                        [526086.949, 187094.5],
                        [526088.4, 187095.82],
                        [526090.882, 187092.919],
                        [526091.927, 187091.795],
                        [526091.65, 187091.55],
                        [526090.4, 187090.5],
                        [526082.9, 187084.15],
                        [526081.05, 187082.7],
                        [526078.949, 187080.8],
                        [526077.992, 187079.929],
                        [526077.8, 187080.15],
                        [526074.9, 187083.6],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36078722,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1784',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526137.028, 187034.081],
                        [526136.949, 187034.05],
                        [526137.3, 187033.45],
                        [526137.699, 187032.55],
                        [526137.85, 187032],
                        [526138, 187031.5],
                        [526138.05, 187031],
                        [526138.446, 187029.967],
                        [526138.092, 187029.812],
                        [526133.089, 187027.644],
                        [526131.801, 187030.388],
                        [526130.796, 187032.695],
                        [526129.882, 187034.34],
                        [526128.716, 187036.795],
                        [526128.008, 187038.259],
                        [526127.197, 187039.956],
                        [526126.364, 187041.725],
                        [526125.161, 187044.207],
                        [526124.977, 187044.592],
                        [526122.48, 187043.112],
                        [526120.681, 187042.046],
                        [526119.034, 187044.539],
                        [526101.208, 187032.903],
                        [526094.1, 187043.1],
                        [526093.9, 187043.4],
                        [526093.75, 187043.7],
                        [526093.55, 187044],
                        [526093.4, 187044.35],
                        [526093.199, 187044.7],
                        [526093.1, 187045],
                        [526091.05, 187050.8],
                        [526090.85, 187051.05],
                        [526084.5, 187059.4],
                        [526082.699, 187061.6],
                        [526082.237, 187062.456],
                        [526081.59, 187063.139],
                        [526083.001, 187064.363],
                        [526089.513, 187070.049],
                        [526095.365, 187075.279],
                        [526098.141, 187077.693],
                        [526099.939, 187075.555],
                        [526101.053, 187076.399],
                        [526099.47, 187078.862],
                        [526099.31, 187079.113],
                        [526098.331, 187080.712],
                        [526098.144, 187081.018],
                        [526097.3, 187082.4],
                        [526098.859, 187083.673],
                        [526099.722, 187084.38],
                        [526100.989, 187085.646],
                        [526103.15, 187087.45],
                        [526118.276, 187067.75],
                        [526118.438, 187067.509],
                        [526118.546, 187067.609],
                        [526127.666, 187051.57],
                        [526137.028, 187034.081],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36103057,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1783',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526083.55, 186994.8],
                        [526083.85, 186995.05],
                        [526079.9, 187000],
                        [526074.8, 187006.3],
                        [526072.92, 187008.65],
                        [526072.885, 187008.693],
                        [526081.236, 187015.645],
                        [526083.3, 187019.8],
                        [526098.706, 187033.195],
                        [526100.15, 187034.45],
                        [526104.6, 187027.85],
                        [526108.65, 187021.9],
                        [526108.75, 187021.7],
                        [526110.85, 187018.6],
                        [526112.55, 187015.55],
                        [526115.4, 187010.6],
                        [526116, 187009.55],
                        [526117.98, 187010.35],
                        [526118.9, 187008.2],
                        [526120.3, 187008.85],
                        [526120.6, 187007.9],
                        [526119.65, 187007.3],
                        [526122.699, 187002],
                        [526122.85, 187001.8],
                        [526123.5, 187000.85],
                        [526123.85, 187000],
                        [526124, 186999.35],
                        [526126.8, 186993.6],
                        [526129.4, 186988.35],
                        [526130, 186987.15],
                        [526132.25, 186982.45],
                        [526128.85, 186980.8],
                        [526125.797, 186979.37],
                        [526125.525, 186979.243],
                        [526124.9, 186978.95],
                        [526118.8, 186976.05],
                        [526118.35, 186975.85],
                        [526117.85, 186975.65],
                        [526116.75, 186975.25],
                        [526116.4, 186975],
                        [526116.05, 186974.8],
                        [526115.4, 186974.45],
                        [526114.65, 186974.2],
                        [526113.75, 186973.85],
                        [526112.9, 186973.5],
                        [526109.25, 186972.1],
                        [526104.435, 186970.334],
                        [526104.376, 186970.31],
                        [526103.481, 186969.955],
                        [526101.771, 186969.275],
                        [526101.318, 186970.462],
                        [526100.123, 186970.091],
                        [526099.699, 186971.15],
                        [526096.426, 186980.714],
                        [526096.241, 186980.651],
                        [526091.096, 186985.711],
                        [526086.983, 186989.566],
                        [526083.55, 186994.8],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36101710,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1782',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526067.109, 187015.704],
                        [526068.598, 187017.084],
                        [526074.046, 187021.769],
                        [526069.548, 187026.997],
                        [526073.75, 187030.8],
                        [526073.85, 187031.05],
                        [526073.9, 187031.35],
                        [526073.9, 187031.95],
                        [526073.699, 187032.65],
                        [526073.65, 187033.2],
                        [526073.65, 187033.6],
                        [526073.699, 187034],
                        [526073.9, 187034.65],
                        [526074.05, 187035],
                        [526074.449, 187035.7],
                        [526074.949, 187036.3],
                        [526075.25, 187036.55],
                        [526071.75, 187040.8],
                        [526073.3, 187056.1],
                        [526080.4, 187062.15],
                        [526081.59, 187063.139],
                        [526082.237, 187062.456],
                        [526082.699, 187061.6],
                        [526084.5, 187059.4],
                        [526090.85, 187051.05],
                        [526091.05, 187050.8],
                        [526093.1, 187045],
                        [526093.199, 187044.7],
                        [526093.4, 187044.35],
                        [526093.55, 187044],
                        [526093.75, 187043.7],
                        [526093.9, 187043.4],
                        [526094.1, 187043.1],
                        [526100.15, 187034.45],
                        [526100.466, 187033.982],
                        [526100.143, 187034.444],
                        [526083.274, 187019.765],
                        [526081.236, 187015.645],
                        [526072.885, 187008.693],
                        [526071.627, 187010.237],
                        [526070.642, 187011.465],
                        [526069.64, 187012.675],
                        [526068.534, 187014.075],
                        [526067.109, 187015.704],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36080397,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1781',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526149.113, 186931.651],
                        [526132.598, 186967.747],
                        [526147.512, 186974.765],
                        [526147.6, 186974.65],
                        [526154, 186960.65],
                        [526154.9, 186958],
                        [526155.4, 186956.1],
                        [526155.65, 186955],
                        [526157.1, 186948.95],
                        [526157.4, 186947.55],
                        [526157.6, 186946.8],
                        [526157.699, 186945.8],
                        [526157.65, 186944.35],
                        [526157.449, 186943.3],
                        [526156.9, 186940.7],
                        [526156.6, 186939.85],
                        [526156.3, 186938.95],
                        [526156, 186938.3],
                        [526155.65, 186937.6],
                        [526155.199, 186936.95],
                        [526154.699, 186936.45],
                        [526154.1, 186935.75],
                        [526153.55, 186935.05],
                        [526149.113, 186931.651],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36106874,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-1780',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526004.619, 187014.844],
                        [526004.392, 187015.008],
                        [526002.533, 187016.951],
                        [526001.475, 187018.057],
                        [526000, 187019.6],
                        [526004.65, 187022.35],
                        [526014.65, 187028.85],
                        [526015.15, 187029.25],
                        [526029.85, 187041.2],
                        [526036, 187034.1],
                        [526036.55, 187033.3],
                        [526036.699, 187033.15],
                        [526036.9, 187033.3],
                        [526040.15, 187036.05],
                        [526043.85, 187039.15],
                        [526047.5, 187042.25],
                        [526051.1, 187045.25],
                        [526053.1, 187046.95],
                        [526054.8, 187048.4],
                        [526058.25, 187051.4],
                        [526062.15, 187054.8],
                        [526065.699, 187057.75],
                        [526069, 187060.65],
                        [526072.75, 187063.9],
                        [526076.35, 187067.05],
                        [526078.3, 187068.75],
                        [526079.9, 187070.1],
                        [526083.699, 187073.3],
                        [526084.35, 187073.2],
                        [526084.949, 187073.2],
                        [526085.5, 187073.25],
                        [526086.05, 187073.35],
                        [526086.4, 187073.5],
                        [526086.699, 187073.65],
                        [526087.15, 187073.65],
                        [526089.9, 187076],
                        [526097.3, 187082.4],
                        [526098.144, 187081.018],
                        [526098.331, 187080.712],
                        [526099.463, 187078.856],
                        [526099.4, 187078.8],
                        [526098.15, 187077.7],
                        [526095.75, 187075.6],
                        [526091.15, 187071.5],
                        [526083.01, 187064.37],
                        [526081.6, 187063.15],
                        [526080.4, 187062.15],
                        [526073.3, 187056.1],
                        [526066.3, 187050.15],
                        [526050, 187036.2],
                        [526049.55, 187035.85],
                        [526036.43, 187024.674],
                        [526034.85, 187026.6],
                        [526034.5, 187027],
                        [526030.85, 187031.45],
                        [526029.477, 187033.135],
                        [526028.35, 187032.3],
                        [526028.15, 187032.15],
                        [526020.1, 187026.15],
                        [526004.619, 187014.844],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36080164,
            title_no: '101759',
            tenure: 'PF',
        },
    }, {
        type: 'Feature',
        id: 'nps202112.fid-73418dcb_17e25b6cf1d_-177f',
        geometry: {
            type: 'MultiPolygon',
            coordinates: [
                [
                    [
                        [526067.722, 187084.372],
                        [526068.53, 187085.119],
                        [526070.196, 187086.657],
                        [526071.36, 187087.73],
                        [526074.9, 187083.6],
                        [526077.8, 187080.15],
                        [526083.05, 187074.1],
                        [526083.699, 187073.3],
                        [526079.9, 187070.1],
                        [526079.3, 187070.9],
                        [526071.65, 187079.75],
                        [526070.4, 187081.15],
                        [526067.722, 187084.372],
                    ],
                ],
            ],
        },
        geometry_name: 'geom',
        properties: {
            ogc_fid: 36076881,
            title_no: '101759',
            tenure: 'PF',
        },
    }],
    totalFeatures: 10,
    numberMatched: 10,
    numberReturned: 10,
    timeStamp: '2022-01-04T17:53:31.943Z',
    crs: {
        type: 'name',
        properties: {
            name: 'urn:ogc:def:crs:EPSG::27700',
        },
    },
}
