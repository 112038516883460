import {
    computed,
    Ref,
    ref,
} from 'vue'

import { useCompromise } from './use-compromise'

/**
 * A composable to search on text input and variations of it
 * @param inputValue - The input text to search on
 * @param highlightDates - Whether to search on detected dates
 * @returns searchText - The text to search on
 */
export const useNlpSearch = ({
    inputValue,
    highlightDates,
}: {
    inputValue: Ref<string>,
    highlightDates: Ref<boolean>,
}) => {
    const likeTerms = ref<Record<string, string>>({
        ' and': ' &',
        '&': 'and',
        s: '\'s',
        '\'s': 's',
        '-': ' ',
        ' ': '-',
    })

    const nlp = ref({
        dates: [],
        ignoreDateValues: [ ' now', ' may', 'set' ],
    })

    const { compromise } = useCompromise()

    const setTerms = (combinedText: string) => {
        // Extract dates / periods
        const doc: any = compromise(combinedText)
        const dateTerms: any = doc.dates().json()
        const filteredDateTerms = dateTerms.filter(term => nlp.value.ignoreDateValues.indexOf(term.text) === -1)
            .map(term => term.text)
        nlp.value.dates = [ ...new Set(filteredDateTerms) ]
        // Remove unhelpful terms
        nlp.value.dates = [ ...new Set(dateTerms.filter(term => {
            return nlp.value.ignoreDateValues.indexOf(term.text) === -1
        }).map(term => {
            return term.text
        })) ]
    }

    const searchText = computed<any[]>(() => {
        /* We may want to tidy the text (searchInput) before attempting to search on it
         * and variations on it */
        const results = []

        // Search on detected date strings if option selected
        if (highlightDates.value === true) {
            results.push(...nlp.value.dates)
        }

        if (inputValue.value != null) {
            if (inputValue.value.trim().length > 1) {
                results.push(inputValue.value.trim())
            }

            // Get similar text to also search on that
            const text = inputValue.value.toLowerCase()
            for (const term in likeTerms) {
                if (text.indexOf(term.toLowerCase()) > -1) {
                    results.push(text.replace(term, likeTerms[term]))
                }
            }
        }

        // Tidy up the text strings
        return results.filter(item => {
            return (item && item.trim().length > 0)
        })
    })

    return {
        searchText,
        setTerms,
    }
}
