export const SCREEN_SIZE = Object.freeze({
    XS: 0,
    SM: 1,
    MD: 2,
    LG: 3,
    XL: 4,
})

/**
 * Gets the current screen breakpoint, based on the vuetify breakpoints
 * @ see https://v15.vuetifyjs.com/en/framework/display
 * @return SCREEN_SIZE {number}
 */
export const getScreenBreakpoint = () => {
    const width = window.innerWidth
    if (width < 600) {
        return SCREEN_SIZE.XS
    }

    if (width >= 600 && width < 960) {
        return SCREEN_SIZE.SM
    }

    if (width >= 960 && width < 1264) {
        return SCREEN_SIZE.MD
    }

    if (width >= 1264 && width < 1904) {
        return SCREEN_SIZE.LG
    }

    return SCREEN_SIZE.XL
}

/**
 * Returns true if screen size is XS/SM/MD, false otherwise
 * @return {boolean}
 */
export const isScreenMediumAndDown = () => {
    const breakpoint = getScreenBreakpoint()

    return breakpoint === SCREEN_SIZE.XS ||
        breakpoint === SCREEN_SIZE.SM ||
        breakpoint === SCREEN_SIZE.MD
}

/**
 * Returns true if screen size is LG/XL, false otherwise
 * @return {boolean}
 */
export const isScreenLargeAndUp = () => {
    const breakpoint = getScreenBreakpoint()

    return breakpoint === SCREEN_SIZE.LG ||
        breakpoint === SCREEN_SIZE.XL
}
