import AppVersionApi from '@/api/app-version.api'
import { isNullOrWhitespace } from '@/utils/string-utils'

export class AppVersion {
    private static appVersionKey = 'appVersion'

    public static get currentAppVersion(): string {
        return localStorage.getItem(this.appVersionKey)
    }

    public static async getLatestAppVersion(): Promise<string | null> {
        return await AppVersionApi.getLatestAppVersion()
    }

    public static hasAppVersionChanged(appVersion: string): boolean {
        return this.currentAppVersion !== appVersion
    }

    public static setLatestAppVersion(appVersion: string): void {
        if (isNullOrWhitespace(appVersion)) {
            localStorage.removeItem(this.appVersionKey)
        } else {
            localStorage.setItem(this.appVersionKey, appVersion)
        }
    }

    public static async reloadToLatestAppVersion(): Promise<void> {
        const latestAppVersion = await this.getLatestAppVersion()
        if (this.currentAppVersion !== latestAppVersion) {
            this.setLatestAppVersion(latestAppVersion)
            window.location.reload()
        }
    }
}
