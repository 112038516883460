import { AppVersion } from '@/models/app-version.model'
import {
    LOGGING_INTERCOM_INITIALISE,
    LOGGING_INTERCOM_TRACK_EVENT,
    LOGGING_INTERCOM_UPDATE,
} from '@/store/mutation-types'
import { getUnixTime } from '@/utils/date-utils'

import { getDatadogSessionUrl } from './datadog'

export const actions = {
    [LOGGING_INTERCOM_INITIALISE]({
        state,
        rootState,
    }) {
        if (state.initialised.intercom === false) {
            state.initialised.intercom = true

            if (window?.owvars?.underTest !== true) {
                // Load Intercom script and setup onload.
                const s = document.createElement('script')
                s.type = 'text/javascript'
                s.async = true
                s.onload = function() {
                    const parsedUnixTime = getUnixTime(rootState.user.accountCreatedAt)
                    window.Intercom('boot', {
                        app_id: rootState.config.settings.intercomAppId,
                        name: rootState.user.name,
                        email: rootState.user.email,
                        created_at: parsedUnixTime,
                        uses_client_codes: rootState.user.usesClientCodes,
                        mandatory_matter_codes: rootState.user.mandatoryMatterCodes,
                        pricing_model_id: rootState.user.pricingModelId,
                        pricing_model_name: rootState.user.pricingModelName,
                        title_summary_charge: rootState.user.titleSummaryCharge,
                        is_matter_link_share_user: rootState.linkShareClient.isMatterLinkShareUser,
                        organisation: rootState.user.organisation,
                        organisation_industry: rootState.user.organisationIndustry,
                        organisation_status: rootState.user.organisationStatus,
                        last_seen: rootState.user.lastSeen,
                        user_hash: rootState.user.intercomHMAC,
                        app_version: AppVersion.currentAppVersion,
                        action_color: '#1D73AD', // Update background to $colour-primary after reset
                    })
                }
                s.src = 'https://widget.intercom.io/widget/' + rootState.config.settings.intercomAppId
                const x = document.getElementsByTagName('script')[0]
                x.parentNode.insertBefore(s, x)
                state.active.intercom = true
            }
        }
    },

    [LOGGING_INTERCOM_TRACK_EVENT](context, request) {
        const dataDogSession = getDatadogSessionUrl()
        // request - {type: 'e.g. matter-shared', metadata: {some: 'object'} }
        const metaData = {
            ...request.metadata,
            appVersion: AppVersion.currentAppVersion,
            session_url: dataDogSession,
        }
        if (context.state.active.intercom === true && window.Intercom) {
            window.Intercom('trackEvent', request.type, metaData)
        }
    },

    [LOGGING_INTERCOM_UPDATE](context, { marginRight }) {
        if (context.state.active.intercom === true && window.Intercom) {
            window.Intercom('update', {
                horizontal_padding: marginRight,
            })
        }
    },
}
