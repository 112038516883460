<template>
    <div class="title-panel-register d-flex gr-4 flex-column">
        <order-documents-card :current-matter-id="currentMatterId"
                              :is-ordering-allowed="isOrderingAllowed"
                              :selected-title="selectedTitle"
                              :selected-title-number="selectedTitleNumber"
                              :official-copies="officialCopies"
                              :user-has-vts-charge="userHasVTSCharge"
                              :is-loading="isLoading"
                              hide-title-plan
                              no-padding />

        <div v-if="hasBackdatedInfo"
             class="title-panel-register__container--backdated">
            <div>
                <ow-icon-text :is-italic-text="true"
                              :is-single-line="false"
                              :text="backdatedText"
                              icon="$info" />
            </div>
        </div>

        <ow-register-card :hide-flags="hideFlags"
                          :loading="isLoading"
                          @title-number-selected="goToTitle" />
    </div>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {
        useStore,
    } from 'vuex'

    import OwIconText from '@/components/core/ow-icon-text.vue'
    import OwRegisterCard from '@/components/title-panel/tabs/register/register-card.vue'
    import OrderDocumentsCard from '@/components/title-panel/tabs/summary-tab/order-documents-card/order-documents-card.vue'

    withDefaults(defineProps<{
        isLoading: boolean,
        isOrderingAllowed: boolean,
        hideFlags: boolean,
    }>(), {
        isOrderingAllowed: true,
    })

    const emit = defineEmits([
        'title-number-selected',
    ])

    const store = useStore()
    const { t } = useI18n()

    const currentMatterId = computed(() => store.state.matter.currentMatter.id)
    const titleSummaryCharge = computed(() => store.state.user.titleSummaryCharge)
    const selectedTitle = computed(() => store.state.title.selectedTitle)
    const selectedTitleNumber = computed(() => store.state.title.selectedTitleNumber)
    const officialCopies = computed(() => store.state.title.selectedTitle?.officialCopiesAvailability?.results)
    const userHasVTSCharge = computed(() => titleSummaryCharge.value !== null)
    const hasBackdatedInfo = computed(() => selectedTitle?.value?.record?.titleMetadata?.isBackOrderCopy)
    const backdatedText = computed(() => t('backdatedInfoFlag', { date: selectedTitle?.value.record?.bgProprietorshipData?.dateDataOC }))

    const goToTitle = (titleNumber: string) => {
        emit('title-number-selected', titleNumber)
    }
</script>

<style lang="scss">
    @import './register-v2';
</style>
