<template>
    <div class="snapshot-assistant">
        <header class="snapshot-assistant__header">
            <h2 v-t="'snapshots.preview.title'"
                class="header-title" />
            <i class="snapshot-content-hint">
                {{ contentHint }}
            </i>
            <v-icon class="header-icon-close close-icon"
                    data-track="Export Assistant - close button"
                    aria-label="close"
                    @click="close">
                $close
            </v-icon>
        </header>
        <section v-if="configLoaded"
                 class="snapshot-assistant__container">
            <snapshot-renderer-preview :key="renderKey"
                                       :config="config"
                                       :service="service" />
            <snapshot-assistant-options :config="config"
                                        :service="service"
                                        class="snapshot-options"
                                        @cancel-requested="close" />
        </section>
    </div>
</template>
<script lang="ts" setup>
    import {
        computed,
        onMounted,
        ref,
    } from 'vue'
    import { onActivated } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'
    import { useStore } from "vuex"

    import SnapshotApi from '@/api/snapshot.api'
    import { SnapshotType } from '@/components/snapshots/common/snapshot-enums-consts'
    import {
        ISnapshotModel,
        ISnapshotService,
    } from '@/components/snapshots/common/snapshot-interfaces'
    import { MapSnapshotService } from '@/components/snapshots/map-snapshots/map-snapshot-service'
    import SnapshotAssistantOptions from '@/components/snapshots/snapshot-assistant-options.vue'
    import SnapshotRendererPreview from '@/components/snapshots/snapshot-renderer-preview.vue'
    import { Route } from '@/enums/route.enum'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const store = useStore()// TODO: store currently used to determine layer names and endpoints; move to config
    const config = computed<ISnapshotModel>(() => {
        if (service.value) {
            return service.value.snapshotModel
        }
        return null
    })
    const route = useRoute()
    const router = useRouter()
    const service = ref<ISnapshotService>()
    const isLoading = ref<Boolean>(false)
    const renderKey = ref<number>(0)

    onMounted(() => initialise())

    const initialise = () => {
        if (isLoading.value) {
            return
        }

        isLoading.value = true
        const configId = route.params.configId as string
        if (isNullOrWhitespace(configId)) {
            console.error('No config id provided in URL')
            close()
        }

        // Get snapshot config - currently from local storage. TODO: pass the config id to the API.
        const configData = SnapshotApi.getSnapshot()

        switch (configData.type) {
            case SnapshotType.Siteplan:
                service.value = new MapSnapshotService(configData)
                break
            default:
                console.error(`Unknown snapshot type ${ config.value.type }`)
                close()
        }
        isLoading.value = false
        renderKey.value++
    }

    const contentHint = computed(() => {
        return service.value?.getContentHint() ?? ''
    })

    const configLoaded = computed(() => {
        return Boolean(config.value !== null && service.value !== null)
    })

    const close = () => {
        router.push({
            name: Route.MatterMap,
            params: {
                matterId: store.state.matter.currentMatter.id,
            },
        })
    }

    onActivated(() => initialise())

    defineExpose({

    })

</script>
<script lang="ts">
    export default {
        name: 'SnapshotAssistant',
        inheritAttrs: false,
        customOptions: {},
    }
</script>
<style lang="scss">
@import 'snapshot-assistant';
</style>
