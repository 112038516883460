<template>
    <div :class="{ disabled: disabled }"
         class="ow-split-button">
        <ow-button :data-test="buttonDataTest"
                   :data-track="buttonDataTrack"
                   :disabled="disabled"
                   :is-loading="isButtonLoading"
                   class="ow-split-button__button"
                   large
                   is-borderless
                   @click="$emit('button-click', label)">
            <span class="ow-split-button__label">{{ label }}</span>
        </ow-button>
        <div class="ow-split-button__separator" />
        <ow-dropdown-menu :button-data-test="menuDataTest"
                          :button-data-track="menuDataTrack"
                          :is-button-disabled="disabled"
                          :is-loading="isMenuItemLoading"
                          :is-tooltip-disabled="true"
                          :tile="true"
                          activator-class-name="ow-split-button__menu--activator"
                          alignment="bottom"
                          color="primary"
                          icon="$menu-more"
                          menu-items-class-name="ow-split-button__menu--items"
                          offset="offset-x"
                          theme="iconButton">
            <ow-dropdown-menu-item v-for="item in menuItems"
                                   :key="item.text"
                                   :disabled="item.disabled"
                                   :tooltip-text="item.tooltipText"
                                   :data-test-attribute="item.dataTest"
                                   :data-track-attribute="item.dataTrack"
                                   :text="item.text"
                                   class="ow-split-button__menu--item"
                                   @click="$emit('menu-item-click', item)" />
        </ow-dropdown-menu>
    </div>
</template>

<script>
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDropdownMenu from '@/components/core/ow-dropdown-menu.vue'
    import OwDropdownMenuItem from '@/components/core/ow-dropdown-menu-item.vue'

    export default {
        name: 'OwSplitButton',

        components: {
            OwButton,
            OwDropdownMenu,
            OwDropdownMenuItem,
        },

        props: {
            buttonDataTest: {
                type: String,
                default: 'ow-split-button',
            },

            buttonDataTrack: {
                type: String,
                default: 'ow-split-button',
            },

            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },

            isButtonLoading: {
                type: Boolean,
                default: false,
            },

            isMenuItemLoading: {
                type: Boolean,
                default: false,
            },

            label: {
                type: String,
                required: true,
            },

            menuDataTest: {
                type: String,
                default: 'ow-split-button-menu',
            },

            menuDataTrack: {
                type: String,
                default: 'ow-split-button-menu',
            },

            // every array item can have text, disabled, dataTest, and dataTrack properties
            menuItems: {
                type: Array,
                required: true,
                default: () => [],
            },
        },

        emits: ['menu-item-click', 'button-click'],
    }
</script>

<style lang="scss">
    @import './ow-split-button';
</style>
