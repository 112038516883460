import * as ExcelJS from "exceljs"
import { ExcelExporter } from '@bryntum/grid'
import { Colors } from "@/enums/colors.enum"

/**
 * Represents the options for exporting data to Excel.
 */
export interface ExcelExportOptions {
    /**
     * An array of column definitions.
     */
    columns: {
        /**
         * The field name of the column.
         */
        field: string;
        /**
         * The data type of the column.
         */
        type?: string;
        /**
         * The value of the column.
         */
        value?: any;
        /**
         * The width of the column.
         */
        width?: number;
    }[];
    /**
     * The default row height in pixels.
     */
    defaultRowHeight?: number;
    /**
     * The name of the Excel sheet.
     */
    sheetName?: string;
    /**
     * The data to be exported.
     */
    data: ({
        value: string;
        type: string;
    } | {
        value: number;
        type: string;
    })[][]
    /**
     * A callback function to customize the cell style.
     * @param cell - The ExcelJS.Cell object representing the cell.
     * @param rowIndex - The index of the row.
     * @param colIndex - The index of the column.
     */
    onGetCellStyle?: (cell: ExcelJS.Cell, rowIndex: number, colIndex: number, rowValues?: any) => void;
    /**
     * A callback function to customize the cell value.
     * @param cell - The ExcelJS.Cell object representing the cell.
     * @param rowIndex - The index of the row.
     * @param colIndex - The index of the column.
     * @returns The customized cell value.
     */
    onGetCellValue?: (cell: ExcelJS.Cell, rowIndex: number, colIndex: number, rowValues?: any) => any;
}

const rowHeight = (worksheet: ExcelJS.Worksheet, rowIndex: number, cellValue: any) => {
    const defaultRowHeight = worksheet?.properties?.defaultRowHeight ?? 20
    if (rowIndex <= 1) {
        return defaultRowHeight * 1.5
    }
    if (typeof cellValue === 'string') {
        const lines = cellValue.split('\n')
        const height = lines.length * defaultRowHeight
        if (height > worksheet.getRow(rowIndex).height) {
            return height
        }
    }
    return worksheet.getRow(rowIndex).height
}

const cellAlignment = (cell: ExcelJS.Cell): Partial<ExcelJS.Alignment> => {
    if (typeof cell.value === 'string' && (cell.value !== 'Yes' && cell.value !== 'No')) {
        return { vertical: 'middle', horizontal: 'left', shrinkToFit : true }
    }
    return { vertical: 'middle', horizontal: 'center' }
}

const styleCell = (
    cell: ExcelJS.Cell,
    rowIndex: number, colIndex: number,
    onGetCellStyle?: (cell: ExcelJS.Cell, rowIndex: number, colIndex: number, rowValues?: any) => void) => {
    const style = cell.style
    if (rowIndex <= 1) {
        style.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f2f7ff' },
        }
        style.border = {
            bottom: { style: 'medium', color: { argb: Colors.Darks200.replace('#', '') } },
            right: { style: 'thin', color: { argb: Colors.Darks200.replace('#', '') } },
        }
        style.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
        // column styling
        if (onGetCellStyle) {
            onGetCellStyle(cell, rowIndex, colIndex, cell.worksheet.getRow(rowIndex).values)
        }
        return
    }
    style.alignment = cellAlignment(cell)
    style.border = {
        bottom: { style: 'thin', color: { argb: Colors.Darks100.replace('#', '') } },
        right: { style: 'thin', color: { argb: Colors.Darks200.replace('#', '') } },
    }

    // column styling
    if (onGetCellStyle) {
        onGetCellStyle(cell, rowIndex, colIndex, cell.worksheet.getRow(rowIndex).values)
    }
}

/**
 * Exports data to an Excel file and returns the file buffer.
 * @param filename - The name of the Excel file.
 * @param options - The options for Excel export.
 * @returns A Promise that resolves to the file buffer.
 */
export const excelExportToBuffer = async (filename: string, options: ExcelExportOptions)  => {
    const { columns, data } = options

    // flatten rows
    const rows = data.flatMap((row) => {
        const newRow: { [key: string]: any } = {}
        columns.forEach((column, index) => {
            newRow[column.field] = row[index].value
        })
        return newRow
    })

    // create workbook
    const workbook = new ExcelJS.Workbook()

    // create worksheet
    const worksheet = workbook.addWorksheet(options?.sheetName ?? 'Sheet 1', {
        views: [{ state: 'frozen', xSplit: 1 }],
    })
    worksheet.properties.defaultRowHeight = options?.defaultRowHeight ?? 20

    // add columns
    worksheet.columns = columns.map((column) => {
        let columnWidth = column?.width ?? 50
        if (columnWidth > 150) {
            columnWidth = 150
        }
        return {
            header: column.value,
            key: column.field,
            width: columnWidth,
        }
    })

    // add rows to worksheet and style cells
    worksheet.addRows(rows)
    worksheet.eachRow((row, rowIndex) => {
        row.eachCell((cell, colIndex) => {
            styleCell(cell, rowIndex, colIndex, options?.onGetCellStyle)
            let cellValue = cell.value

            // custom formatting
            if (options?.onGetCellValue) {
                cellValue = options.onGetCellValue(cell, rowIndex, colIndex, cell.worksheet.getRow(rowIndex).values)
            }
            cell.value = cellValue
        })
    })

    // auto size rows
    worksheet.eachRow((row, rowIndex) => {
        row.height = rowHeight(worksheet, rowIndex, row.values)
    })

    return await workbook.xlsx.writeBuffer()
}

/**
 * Exports data from a grid instance to an Excel file.
 *
 * @param gridInstance The grid instance containing the data to export.
 * @param filename The name of the Excel file to be generated.
 * @param options Optional configuration options for the export.
 *
 * @returns A Promise that resolves when the export is complete.
 */
export const excelExportFromGrid = async (gridInstance: any, filename: string, options?: ExcelExportOptions) => {
    // generate export data from grid instance using the included bryntum grid ExcelExporter feature
    const exportData = gridInstance.features.excelExporter.generateExportData(
        ExcelExporter.defaultConfig,
    )

    // export data to Excel file
    const result = await excelExportToBuffer(filename, {
        ...options,
        columns: exportData.columns.map(c => ({
            ...c,
            width: (c.width / 10) * 1.5, // convert from pixels to Excel column width units
        })),
        data: exportData.rows,
    })


    // save file
    const blob = new Blob([result], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()
}
