import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'
import { Layer } from 'ol/layer'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import olMap from 'ol/Map'
import Feature, { FeatureLike } from 'ol/Feature'
import VectorTileLayer from 'ol/layer/VectorTile'
import { StyleFunction,
    StyleLike } from 'ol/style/Style'
import VectorTileSource from 'ol/source/VectorTile'
import MVT from 'ol/format/MVT'
import { getGeoserverApiUri } from '@/utils/environment.utils'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { geoserver27700TileGrid } from '@/store/modules/map/layers/geoserver-27700-tilegrid'
import LayerGroup from 'ol/layer/Group'
import { MapBrowserEvent } from 'ol'
import { IEventLogger } from '@/interfaces/logging.interfaces'
import { getOWStyleFromOLStyle,
    layerEquals } from '@/utils/map-utils'
import i18n from '@/plugins/i18n'
import { Style } from 'ol/style'
import { IOwStyle } from '@/store/modules/sketches/types/style'
import { ISnapshotPageLayout } from '@/components/snapshots/common/snapshot-interfaces'
import {
    IMapRollover,
    IMapRolloverOptions,
    IMapRolloverSection,
} from '@/components/map-rollover/common/map-rollover-interfaces'
import { ref } from 'vue'

export type BaseMvtDataLayerParams = {
    snapshotConfig?: LayerSnapshotModel
    targetMap?: olMap
    onClickFn?: (features: any) => void
    onOverFeaturesChangedFn?: (features: Array<FeatureLike>) => void
    onVisibilityChangedFn?: (visible:boolean) => void
    eventLogger?: IEventLogger
    vectorTileSource?: VectorTileSource
}
export abstract class BaseMvtDataLayer implements IMapSnapshotLayer, IMapRollover {
    // Will be appended to the map element if over features and a click handler is
    // specified, override per layer if required.
    public overFeaturesClassName = 'ow-over-layer'
    public featuresAreClickable: (features: Array<FeatureLike>) => boolean = (features: Array<FeatureLike>) => Boolean(features.length)

    private snapshotConfig: LayerSnapshotModel
    public styleCache
    private loadingTilesCount = ref<number>(0)
    private initialLoadComplete = ref<boolean>(false)
    public i18nNameRef: string
    public name: string
    public rolloverOptions: IMapRolloverOptions
    public layerName: string
    public layer: VectorTileLayer
    public overFeatures: Array<FeatureLike> = []
    public maxResolution = 50
    public attributionText: Array<string> = []
    public interactive: boolean
    public style:StyleLike
    public targetMap: olMap
    private settings: any
    private snapshotPageLayout: ISnapshotPageLayout
    protected vectorTileSource: VectorTileSource = undefined

    // Event handlers
    private initialisedEventHandlers = false
    public onClickFn?: (params: any) => void
    public onOverFeaturesChangedFn?: (features: Array<FeatureLike>, event?: MapBrowserEvent<any>) => void
    public onVisibilityChangedFn?: (visible:boolean) => void
    public eventLogger?:IEventLogger

    protected constructor(params: BaseMvtDataLayerParams, settings: any) {
        this.interactive = !params.snapshotConfig
        this.snapshotConfig = params.snapshotConfig
        this.targetMap = params.targetMap
        this.settings = settings
        this.onClickFn = params.onClickFn ?? this.onClickFn
        this.onOverFeaturesChangedFn = params.onOverFeaturesChangedFn ?? this.onOverFeaturesChangedFn
        this.onVisibilityChangedFn = params.onVisibilityChangedFn ?? this.onVisibilityChangedFn
        this.eventLogger = params.eventLogger
        this.vectorTileSource = params.vectorTileSource
        this.styleCache = new Map()
        this.rolloverOptions = {}
    }

    public initialiseLayer() {
        if (!this.layer) {
            this.layer = new VectorTileLayer({
                visible: false,
                maxResolution: this.maxResolution,
                preload: 0,
                zIndex: 10,
                style: this.style,
                source: this.vectorTileSource || new VectorTileSource({
                    tileSize: 512,
                    format: new MVT(),
                    attributions: this.attributionText,
                    url: `${ getGeoserverApiUri() }/gwc/service/tms/1.0.0/${ this.layerName }@${ CoordinateSystemCode.EPSG27700 }@pbf/{z}/{x}/{-y}.pbf`,
                    transition: 0,
                    projection: CoordinateSystemCode.EPSG27700,
                    tileGrid: geoserver27700TileGrid,
                    overlaps: true,
                }),
                renderBuffer: 400,
                updateWhileInteracting: false,
                updateWhileAnimating: false,
            })
            this.layer.set('getOwLayer', () => this)
            this.layer.set('name', this.name)
            this.layer.set('getRolloverTextForPoint', BaseMvtDataLayer.getRolloverTextForPoint.bind(this, this.layer, this.rolloverOptions, this.style))
        }

        // Add to a map if specified and not already added.
        if (!this.targetMap?.getLayers().getArray().includes(this.layer)) {
            this.targetMap?.addLayer(this.layer)
        }

        // Initialise event handlers if required.
        if (!this.initialisedEventHandlers) {
            // Events to track loading state.
            this.layer.getSource().on('tileloadstart', () => {
                this.loadingTilesCount.value++
            })
            this.layer.getSource().on(['tileloadend', 'tileloaderror'], () => {
                this.loadingTilesCount.value--
                if (this.loadingTilesCount.value === 0) {
                    this.initialLoadComplete.value = true
                    this.getIsLoading()
                }
            })
            if (this.interactive) {
                // Optional events to track interactions.
                if (this.getVisible()) {
                    this.addEventListeners()
                }
                this.targetMap?.getTargetElement()?.addEventListener('mouseout', () => {
                    this.targetMap.getTargetElement().style.cursor = '' // eventually retire in favour of removing the 'ow-over-...' classes once, globally.
                    this.overFeatures = []
                    if (this.onOverFeaturesChangedFn &&
                        this.onOverFeaturesChangedFn instanceof Function) {
                        this.onOverFeaturesChangedFn(this.overFeatures)
                    }
                })
            }
            this.initialisedEventHandlers = true
        }
    }

    protected handleClick(event: MapBrowserEvent<any>):void {
        if (!this.layer.getVisible()) {
            return
        }
        const pixel = this.targetMap.getEventPixel(event.originalEvent)
        const clickedFeatures: Array<FeatureLike> = this.targetMap.getFeaturesAtPixel(pixel, {
            layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
        })
        this.onClickFn(clickedFeatures)
    }

    private handlePointerMove(event: MapBrowserEvent<any>):void {
        if (!this.layer.getVisible()) {
            return
        }
        const pixel = this.targetMap.getEventPixel(event.originalEvent)
        const overFeatures: Array<FeatureLike> = this.targetMap.getFeaturesAtPixel(pixel, {
            layerFilter: currentLayer => layerEquals(this.layer, currentLayer),
        })

        if (this.overFeatures !== overFeatures) {
            this.onOverFeaturesChangedFn(this.overFeatures, event)
            if (this.onClickFn && this.featuresAreClickable(this.overFeatures)) {
                this.targetMap.getTargetElement().classList.add(this.overFeaturesClassName)
            } else if (this.onClickFn) {
                this.targetMap.getTargetElement().classList.remove(this.overFeaturesClassName)
            }
        }
        this.overFeatures = overFeatures
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        const featuresInExtent = this.layer
            .getFeaturesInExtent(this.targetMap.getView().calculateExtent(this.targetMap.getSize()))

        if (typeof this.style === 'function') {
            // Style is derived from a function.
            // Iterate through the features and get styles for them as a unique set.
            const limitStyles = 10
            const styles = new Map<string, Partial<IOwStyle>>()
            featuresInExtent.every(feature => {
                const owStyle: Partial<IOwStyle> = getOWStyleFromOLStyle(
                    (this.style as StyleFunction)(feature, this.targetMap.getView().getResolution()) as Style)
                const styleHash = JSON.stringify(owStyle)
                if (!styles.has(styleHash)) {
                    styles.set(styleHash, owStyle)
                }
                if (styles.size > limitStyles) {
                    return false
                }
                return true
            })
            return Array.from(styles.entries()).map(([styleHash, owStyle]) => {
                return {
                    id: styleHash,
                    label: i18n.global.t(this.i18nNameRef).toString(),
                    style: owStyle,
                }
            })
        } else {
            // Style is static.
            if (featuresInExtent.length) {
                const owStyle: Partial<IOwStyle> = getOWStyleFromOLStyle(this.style as Style)
                return [{
                    id: this.name,
                    label: i18n.global.t(this.i18nNameRef).toString(),
                    style: owStyle,
                }]
            } else {
                return []
            }
        }
    }

    getLayer(): Layer | LayerGroup {
        return this.layer
    }

    getVisible(): boolean {
        return this.layer?.getVisible()
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: this.name,
        }
    }

    setVisible(visible: boolean, targetMap?: olMap): Promise<void> {
        if (this.layer.getVisible() !== visible && this.onVisibilityChangedFn) {
            this.onVisibilityChangedFn(visible)
        }
        this.targetMap = targetMap ?? this.targetMap
        this.initialiseLayer()
        this.layer.setVisible(visible)

        // Remove / re-enable event handlers.
        if (visible) {
            this.addEventListeners()
        } else {
            this.removeEventListeners()
        }
        return Promise.resolve()
    }

    private addEventListeners() {
        this.removeEventListeners()
        if (this.onClickFn) {
            this.targetMap.on('singleclick', this.handleClick.bind(this))
        }
        if (this.onOverFeaturesChangedFn) {
            this.targetMap.on('pointermove', this.handlePointerMove.bind(this))
        }
    }

    private removeEventListeners() {
        if (this.onClickFn) {
            this.targetMap.un('singleclick', this.handleClick.bind(this))
        }
        if (this.onOverFeaturesChangedFn) {
            this.targetMap.un('pointermove', this.handlePointerMove.bind(this))
        }
    }

    public getIsLoading(): boolean {
        return (this.loadingTilesCount > 0) || (!this.initialLoadComplete && this.getVisible())
    }

    public setSnapshotLayout(layout: ISnapshotPageLayout) {
        this.snapshotPageLayout = layout
        this.styleCache.clear()
        this.layer.changed()
    }

    public static getRolloverTextForPoint(layer, rolloverOptions: IMapRolloverOptions, style: StyleLike, targetMap, pixel): IMapRolloverSection {
        let features: Record<string, FeatureLike[]> | FeatureLike[] = []

        // if we have no map, we can't do anything.
        if (!targetMap) {
            return null
        }

        // Set up the result.
        const result: IMapRolloverSection = {
            category: rolloverOptions?.category,
            allowClick: rolloverOptions?.allowClick ?? false,
            clickText: rolloverOptions?.clickText ?? '',
            source: '',
            sortOrder: rolloverOptions?.sortOrder ?? 0,
            items: [],
        }

        try {
            // Get the features.
            // If a custom function is specified, use that.
            if (rolloverOptions?.getFeatures) {
                features = rolloverOptions.getFeatures(targetMap, pixel)
            } else {
                targetMap.getFeaturesAtPixel(pixel, {
                    layerFilter: currentLayer => layerEquals(layer, currentLayer),
                }).forEach(feature => {
                    (features as Feature[]).push(feature)
                })
            }

            if (!features || !Object.keys(features).length) {
                return null
            }

            // Get the source
            // If a custom function is specified, use that.
            if (rolloverOptions?.getSource) {
                result.source = rolloverOptions.getSource(features).toString()
            } else {
                result.source = rolloverOptions?.source ?? ''
            }

            // Get the items
            // If a custom function is specified, use that.
            if (rolloverOptions?.addItems) {
                result.items = rolloverOptions.addItems(features)
            } else {
                const featureArray = (features as Feature[])

                // get the primary value.
                // If a custom function is specified, use that.
                let primary
                if (rolloverOptions?.showPrimary) {
                    if (rolloverOptions?.getPrimary) {
                        primary = rolloverOptions.getPrimary(featureArray)
                    } else {
                        primary = rolloverOptions?.primary ?? null
                    }
                }

                // get the extended value.
                // If a custom function is specified, use that.
                let extended: Record<string, string>[] | string = null
                if (rolloverOptions?.getExtended) {
                    extended = rolloverOptions.getExtended(featureArray)
                }

                const currentStyle = style
                // Construct the visible items
                result.items = [{
                    primary,
                    extended,
                    style: featureArray.map((feature) => {
                        let result: Partial<StyleLike>

                        // If a custom function is specified, use that to get the style.
                        if (rolloverOptions?.getStyle) {
                            const style = rolloverOptions.getStyle(feature)
                            result = getOWStyleFromOLStyle((style as Style))
                        } else if (typeof currentStyle === 'function') {
                            result = getOWStyleFromOLStyle(
                        (currentStyle as StyleFunction)(feature, targetMap?.getView()?.getResolution()) as Style)
                        } else {
                            result = getOWStyleFromOLStyle(style as Style)
                        }

                        return result
                    })[0], // only use the first style.
                }]
            }
        } catch (e) {
            console.error('Error getting rollover text for point', e)
        }

        return result
    }
}
