// @/stores/asset-monitoring/getters.ts

import {
    IGetNotificationResponseItem,
    INotificationMessageArguments,
} from '@/api/notifications.api'
import { IAssetMonitoringNotification } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
import { NotificationEntityType } from "@/components/asset-monitoring/notification-entity-type.enum"
import store from '@/store'
import {
    IAssetMonitoringNotificationType,
    IAssetMonitoringState,
    useAssetMonitoringStore,
} from '@/stores/asset-monitoring/index'
import {
    convertToNotificationSubType,
    convertToNotificationType,
} from '@/stores/asset-monitoring/utils'
import { isNullOrEmpty } from '@/utils/array-utils'

export default {
    userNotifications(state: IAssetMonitoringState): Array<IAssetMonitoringNotification> {
        const notificationType: IAssetMonitoringNotificationType = this.notificationTypes[state.entityType]

        return [...notificationType.notifications?.values()].map((notification: IGetNotificationResponseItem) => {
            return {
                notificationId: notification.notificationId,
                userNotificationId: notification.userNotificationId,
                message: notification.message,
                createdOn: new Date(notification.createdOn),
                isRead: notification.isRead,
                titleNumber: notification.titleNumber,
                organisationId: notification.organisationId,
                notificationType: convertToNotificationType(notification.type),
                notificationDetails: notification.notificationDetails?.map(x => ({
                    difference: x.difference,
                    notificationSubType: convertToNotificationSubType(x.notificationSubType),
                })),
            } as IAssetMonitoringNotification
        })
    },

    /**
     * Checks if the given state has any unread notifications.
     *
     * @param {IAssetMonitoringState} state - The state object containing notifications.
     * @return {boolean} - True if there are unread notifications, false otherwise.
     */
    hasUnreadNotifications(state: IAssetMonitoringState): boolean {
        return state.unreadCompanyNotifications > 0 || state.unreadTitleNotifications > 0
    },

    showOnlyUnread(state: IAssetMonitoringState): boolean {
        return state.notificationTypes[state.entityType].showOnlyUnread
    },

    lastCheckedDate(state: IAssetMonitoringState): string {
        return state.notificationTypes[state.entityType].lastCheckedDate
    },

    filterText(state: IAssetMonitoringState): string {
        return state.notificationTypes[state.entityType].filterText
    },

    sortBy(state: IAssetMonitoringState): string {
        return state.notificationTypes[state.entityType].sortBy
    },

    pageSize(state: IAssetMonitoringState): number {
        return state.notificationTypes[state.entityType].pageSize
    },

    currentPageIndex(state: IAssetMonitoringState): number {
        return state.notificationTypes[state.entityType].currentPageIndex
    },

    totalResults(state: IAssetMonitoringState): number {
        return state.notificationTypes[state.entityType].totalResults
    },

    notificationMessages(state: IAssetMonitoringState): Array<INotificationMessageArguments> {
        return state.notificationTypes[state.entityType].notificationMessages
    },

    selectedNotificationTypes(state: IAssetMonitoringState): string[] {
        return state.notificationTypes[state.entityType].selectedNotificationTypes
    },

    selectedNotificationSubTypes(state: IAssetMonitoringState): string[] {
        return state.notificationTypes[state.entityType].selectedNotificationSubTypes
    },

    selectedEntityValues(state: IAssetMonitoringState): string[] {
        return state.notificationTypes[state.entityType].selectedEntityValues
    },

    /**
     * Retrieves a notification by its title number.
     *
     * @returns {Function} Function that takes a title number as input and returns the corresponding notification.
     */
    getNotificationByTitleNumber(): Function {
        // To use a getter inside a getter we need a reference to the store again
        const assetMonitoringStore = useAssetMonitoringStore()
        return (titleNumber: string) => assetMonitoringStore.userNotifications.find(notification => notification.titleNumber === titleNumber)
    },
}
