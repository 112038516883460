<template>
    <section class="base-sub-type-item daylist-sub-type-item">
        <h3 class="caption-highlight expansion-panel__toggle"
            data-test="pending-applications-toggle"
            data-track="Asset monitoring: toggle details panel"
            data-track-type="Pending Application"
            @click="isShowingDetailsPanel = !isShowingDetailsPanel">
            {{ isShowingDetailsPanel ? t('label.hideDetails') : t('label.showDetails') }}
            <v-icon>{{ isShowingDetailsPanel ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
        </h3>
        <div v-if="isShowingDetailsPanel"
             class="expansion-panel__content">
            <table class="base-sub-type-item__table">
                <thead>
                    <tr>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('label.type') }}
                        </th>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('assetMonitoring.subType.pendingApplicationsUpdated.applicationReference') }}
                        </th>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('assetMonitoring.subType.pendingApplicationsUpdated.customerReference') }}
                        </th>
                        <th class="label-caps-small base-sub-type-item__table--heading">
                            {{ t('label.status') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="itm in allItems"
                        data-test="pending-applications-change-row">
                        <td class="caption-regular"
                            data-test="pending-applications-change-row-type">
                            {{ itm.type }}
                        </td>
                        <td class="caption-regular"
                            data-test="pending-applications-change-row-application-reference">
                            {{ itm?.applicationReference ?? '-' }}
                        </td>
                        <td class="caption-regular"
                            data-test="pending-applications-change-row-customer-reference">
                            {{ itm?.customerReference ?? '-' }}
                        </td>
                        <td class="caption-regular new-owner"
                            data-test="pending-applications-change-row-status">
                            {{ itm.status ?? '-' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>

<script lang="ts" setup>
    import {
        computed,
        PropType,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { DaylistDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const props = defineProps({
        item: {
            type: Object as PropType<IAssetMonitoringNotificationDetail>,
            required: true,
        },
    })

    const emit = defineEmits<{
        (e: 'count-of-updates', value: number),
    }>()

    const isShowingDetailsPanel = ref(false)
    const { t } = useI18n()

    const diff = computed<Difference<DaylistDatapoint>>(() => {
        return JSON.parse(props.item?.difference)
    })

    // Get the added, removed, and updated items
    const addedItems = ref<Array<DaylistDatapoint>>([])
    const hasAddedItems = ref<boolean>(false)
    const removedItems = ref<Array<DaylistDatapoint>>([])
    const hasRemovedItems = ref<boolean>(false)
    const updatedItems = ref<Array<UpdatedProperty<DaylistDatapoint>>>([])
    const hasUpdatedItems = ref<boolean>(false)
    const allItems = computed<Array<any>>(() => {
        return [].concat(
            addedItems.value.map((item) => ({
                applicationReference: item.applicationReference,
                customerReference: item.customerReference,
                status: item.status,
                type: t('label.added'),
            })),
            removedItems.value.map((item) => ({
                applicationReference: item.applicationReference,
                customerReference: item.customerReference,
                status: item.status,
                type: t('label.removed'),
            })),
            updatedItems.value.map((item) => ({
                applicationReference: item.after?.applicationReference,
                customerReference: item.after?.customerReference,
                status: item.after?.status,
                type: t('label.updated'),
            })),
        )
    })

    watch(allItems, () => {
        emit('count-of-updates', allItems.value.length)
    }, { immediate: true })

    watch(diff, (newVal: Difference<DaylistDatapoint>) => {
        addedItems.value = newVal?.added ?? newVal?.added
        hasAddedItems.value = !isNullOrEmpty(newVal?.added)
        removedItems.value = newVal?.removed
        hasRemovedItems.value = !isNullOrEmpty(newVal?.removed)
        updatedItems.value = newVal?.updated
        hasUpdatedItems.value = !isNullOrEmpty(newVal?.updated)
    }, { immediate: true })
</script>

<style lang="scss" scoped>
    @import "completed-pending-application.scss";
</style>
