import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export const preventTransitionInitialState = {
    showDialog: false,
    isFormDirty: false,
    transitionRoute: null,
    resetFormOnModalClose: false,
    resetForm: () => {},
    submitForm: () => {},
    title: 'You have unsaved changes.',
    text: 'You can save your changes, discard your changes, or cancel to continue editing.',
}

export const confirmationDialogInitialState = {
    showDialog: false,
    title: 'Are you sure you want to proceed?',
    subtitle: null,
    submitButtonText: 'Proceed',
    successTitle: 'Success!',
    successSubtitle: null,
    submit: () => {},
    reset: () => {},
}

const state = {
    appElement: document.getElementById('ow-app'),
    preventTransition: preventTransitionInitialState,
    confirmationDialog: confirmationDialogInitialState,
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
