<template>
    <div class="ow-empty-state"
         data-test="ow-empty-state">
        <ow-icon :icon="emptyStateIcon" />
        <div class="ow-empty-state__title body-copy"
             data-test="ow-empty-state-title">
            {{ emptyStateTitle }}
        </div>
        <div class="ow-empty-state__subtitle body-copy"
             data-test="ow-empty-state-subtitle">
            {{ emptyStateSubtitle }}
        </div>
    </div>
</template>

<script lang="ts">
    import OwIcon from '@/components/core/ow-icon.vue'

    export default {
        name: 'OwEmptyState',
        components: {
            OwIcon,
        },
        props: {
            emptyStateIcon: {
                type: String,
                required: false,
                default: 'ow-icon-search-empty-state',
            },
            emptyStateTitle: {
                type: String,
                required: false,
                default: 'We didn\'t find anything that matches this search.',
            },
            emptyStateSubtitle: {
                type: String,
                required: false,
                default: 'Maybe try checking for typos, searching different titles, or adjusting your filters.',
            },
        },
    }
</script>

<style lang="scss">
    @import './ow-empty-state.scss';
</style>
