import { IMapSnapshotLayer } from '@/components/snapshots/map-snapshots/map-snapshot-interfaces'
import {
    Layer,
    Tile as TileLayer,
} from 'ol/layer'
import XYZ from 'ol/source/XYZ'
import { LayerNames } from '@/consts/map-layers'
import { getGeoserverApiUri } from '@/utils/environment.utils'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { geoserver27700TileGrid } from '@/store/modules/map/layers/geoserver-27700-tilegrid'
import { KeyConfigItemModel } from '@/components/snapshots/map-snapshots/config-components/key-config-models'
import { LayerSnapshotModel } from '@/components/snapshots/map-snapshots/map-snapshot-models'

export class TitleNumbersLayer implements IMapSnapshotLayer {
    public name: string = LayerNames.TitleNumbers
    private readonly layer: TileLayer<XYZ>
    private loadingTilesCount = 0

    constructor() {
        this.layer = new TileLayer({
            maxResolution: 6,
            zIndex: 19,
            visible: false,
            source: new XYZ({
                url: `${ getGeoserverApiUri() }/gwc/service/tms/1.0.0/ow:title_index@${ CoordinateSystemCode.EPSG27700 }@png/{z}/{x}/{-y}.png`,
                tileGrid: geoserver27700TileGrid,
                projection: CoordinateSystemCode.EPSG27700,
                crossOrigin: 'anonymous',
            }),
        })
        this.layer.set('getOwLayer', () => this)

        this.layer.getSource().on('tileloadstart', () => {
            this.loadingTilesCount++
        })
        this.layer.getSource().on(['tileloadend', 'tileloaderror'], () => {
            this.loadingTilesCount--
        })
    }

    getKeyItems(): Array<KeyConfigItemModel> {
        return undefined
    }

    getLayer(): Layer {
        return this.layer
    }

    getMapSnapshotConfig(): LayerSnapshotModel {
        return {
            name: LayerNames.TitleNumbers,
        }
    }

    setVisible(visible: boolean): Promise<void> {
        this.layer.setVisible(visible)
        return Promise.resolve()
    }

    getVisible(): boolean {
        return this.layer.getVisible()
    }

    getIsLoading(): boolean {
        return this.loadingTilesCount > 0
    }
}
