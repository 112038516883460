import { IExportTemplateOptions } from "@/interfaces/export-template-options.interface"
import i18n from "@/plugins/i18n"
import { MAIN_API_REPORT_ENUM } from "@/enums/reporting-api.enum"
import { CopilotReport } from "@/enums/copilot-report.enum"

const getTitleAnalysisExportOption: IExportTemplateOptions = {
    category: 'STANDARD',
    description: /*html*/`<ul><li>Export title features organised in separate sheets </li><li>Gain instant insight into multiple titles at once</li></ul>`,
    extraFeatures: [],
    name: i18n.global.t('matter.selection.exportTitleAnalysisReport'),
    is_available: true,
    organization_enabled: true,
    user_enabled: false,
    user_forbidden: false,
    registered_interest_at: null,
    preview_url: null,
    reportingAPIEnum: MAIN_API_REPORT_ENUM.TAR,
    enabled: (flags) => flags.titleAnalysisExcelReport,
}

const getUnmergedTitleAnalysisExportOption: IExportTemplateOptions = {
    category: 'STANDARD',
    description: /*html*/`<ul><li>Export title features organised in separate sheets</li><li>Gain instant insight into multiple titles at once</li><li>Report compatible with HighQ (rows are not merged by title number)</li></ul>`,
    extraFeatures: [],
    name: i18n.global.t('matter.selection.unmergedTitleAnalysisReport'),
    is_available: true,
    organization_enabled: true,
    user_enabled: false,
    user_forbidden: false,
    registered_interest_at: null,
    preview_url: null,
    reportingAPIEnum: MAIN_API_REPORT_ENUM.TAR_UNMERGED,
    enabled: (flags) => flags.titleAnalysisExcelReport && flags.unmergedTitleAnalysisExcelReport,
}

const copilotExportOption: IExportTemplateOptions = {
    category: 'STANDARD',
    description: i18n.global.t('report.card.copilotDescription'),
    extraFeatures: [],
    name: i18n.global.t('matter.selection.exportCopilotReport'),
    is_available: true,
    organization_enabled: true,
    user_enabled: false,
    user_forbidden: false,
    registered_interest_at: null,
    preview_url: null,
    reportingAPIEnum: MAIN_API_REPORT_ENUM.TAR,
    copilotBanner: true,
    copilot: true,
    enabled: (flags) => flags.commercialAppCoPilotIntegration,
    hideInSubMenu: true,
}

const copilotLeaseReportExportOption: IExportTemplateOptions = {
    category: 'STANDARD',
    description: i18n.global.t('report.card.copilotLeaseReportDescription'),
    extraFeatures: [],
    name: i18n.global.t('matter.selection.exportCopilotLeaseReport'),
    is_available: true,
    organization_enabled: true,
    user_enabled: false,
    user_forbidden: false,
    registered_interest_at: null,
    preview_url: null,
    reportingAPIEnum: MAIN_API_REPORT_ENUM.TAR,
    customIcon: 'ow-icon-copilot-long-lease-report',
    copilot: true,
    copilotReport: CopilotReport.LongFormLeaseReport,
    enabled: (flags) => flags.commercialAppCoPilotIntegration,
    hideInSubMenu: true,
}

const copilotLocalAuthorityExportOption: IExportTemplateOptions = {
    category: 'STANDARD',
    description: i18n.global.t('report.card.copilotLocalAuthorityReportDescription'),
    extraFeatures: [],
    name: i18n.global.t('matter.selection.exportCopilotLocalAuthorityReport'),
    is_available: true,
    organization_enabled: true,
    user_enabled: false,
    user_forbidden: false,
    registered_interest_at: null,
    preview_url: null,
    reportingAPIEnum: MAIN_API_REPORT_ENUM.TAR,
    customIcon: 'ow-icon-copilot-local-authority-search-report',
    copilot: true,
    copilotReport: CopilotReport.LocalAuthorityReport,
    enabled: (flags) => flags.commercialAppCoPilotIntegration && flags.copilotLocalAuthoritySearchReport,
    hideInSubMenu: true,
}

export const customReportOptions = [
    copilotExportOption,
    copilotLeaseReportExportOption,
    copilotLocalAuthorityExportOption,
    getTitleAnalysisExportOption,
    getUnmergedTitleAnalysisExportOption,
]
