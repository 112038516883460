import {
    SnapshotApiModel,
} from '@/components/snapshots/common/snapshot-models'

export default class SnapshotApi {
    static getSnapshot(): SnapshotApiModel {
        // Currently this is just stored globally / local storage (limited to 5mb ish)
        const configString = global['snapshot-config'] ?? localStorage.getItem('snapshot-config')
        if (configString) {
            return JSON.parse(configString) as SnapshotApiModel
        }
    }

    static createSnapshot(request: SnapshotApiModel): Promise<string> {
        if (request == null) {
            throw new Error('Create snapshot request param was null or empty')
        }
        // const uri = `${ getSnapshotApiUri() }/config`

        // If running in a PR environment, override the url to the jolly-beach url
        if (window.location.hostname.includes('jolly-beach')) {
            request.urlOverride = window.location.hostname
        }
        const requestStr = JSON.stringify(request)
        try {
            localStorage.setItem('snapshot-config', requestStr)
        } catch {
            global['snapshot-config'] = requestStr
        }
        return Promise.resolve(request.configId)

        // return httpClient.post(uri, request, { responseType: 'blob' })
        //     .then(response => {
        //         if (response.status === 200) {
        //             const blob = new Blob([response.data], { type: 'image/png' })
        //             window.saveAs(blob, 'snapshot.png')
        //         }
        //     })
    }
}
