/** Increment to use between sort order values. */
export const sortIntervalIncrement = 1000

/**
 * Returns an array of items with updated sort order values based on an existing array,
 * a new item to be inserted/moved within it, and the proposed index for the new item.
 * @param {any} items an array of items with sortOrder properties.
 * @param {any} newItem an item with a sortOrder property, which may or may not already be part of items.
 * @param {any} proposedIndex - the index within items that the newItem should be placed at.
 */
export const applyNewSortOrderToItems = (items, newItem, proposedIndex) => {
    const adjacentItems = items.filter(x => x !== newItem)
    newItem.sortOrder = proposedIndex

    // Determine if there are items before and after the intended position.
    const itemBefore = adjacentItems[proposedIndex - 1]
    const itemAfter = adjacentItems[proposedIndex]

    // If there's an item before and after, propose the midpoint as the new sort order
    if (itemBefore && itemAfter) {
        newItem.sortOrder = Math.round((itemAfter.sortOrder + itemBefore.sortOrder) / 2)
    } else if (itemBefore) {
        // If it's going to be the last item, take the item before it and add sortIntervalIncrement.
        newItem.sortOrder = itemBefore.sortOrder + sortIntervalIncrement
    } else if (itemAfter) {
        newItem.sortOrder = Math.round(itemAfter.sortOrder / 2)
    } else {
        newItem.sortOrder = sortIntervalIncrement
    }

    // If there are items with the same sort order, re-index all the items.
    const existingSortOrders = items.filter(x => x !== newItem).map(x => x.sortOrder)
    // eslint-disable-next-line no-unused-vars
    if (items.some(x => existingSortOrders.includes(newItem.sortOrder))) {
        items = items.filter(x => x !== newItem)
        items.splice(proposedIndex, 0, newItem)
        for (let i = 0; i < items.length; i++) {
            items[i].sortOrder = (i + 1) * sortIntervalIncrement
        }
        return items
    } else {
        return [newItem]
    }
}
