export const runGoogleStreetViewFixes = () => {
    setTimeout(() => {
        // keypress fix to workaround google maps issue - https://issuetracker.google.com/issues/73644075
        let element = document.querySelector('#txtNoteContents .ql-editor')
        if (element !== null) {
            document.querySelector('#txtNoteContents .ql-editor').addEventListener('keypress', function(e) {
                e.stopImmediatePropagation()
            })
        }
        element = document.querySelector('#txtNoteContents')
        if (element !== null) {
            element.addEventListener('keypress', function(e) {
                e.stopImmediatePropagation()
            })
        }
    }, 1000)
}

/**
 * Returns a style usable by Google maps features based on the style properties
 * assigned to a title within a matter, and whether or not it is selected.
 * See usage in store/modules/site-visit/actions.js
 */
export const getGoogleMapsStyleForTitle = (title, isSelected) => {
    if (isSelected) {
        return {
            visible: true,
            strokeColor: '#ff0000',
            strokeWeight: 4,
            fillColor: null,
            fillOpacity: 0,
            zIndex: 999,
            clickable: false,
        }
    }

    return {
        visible: true,
        strokeColor: title.colour,
        strokeWeight: title.strokeWidth,
        fillColor: title.fill === true ? title.colour : null,
        fillOpacity: title.fill === true ? title.fillOpacity : 0,
        zIndex: title.zIndex,
        clickable: false,
    }
}
