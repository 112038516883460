<template>
    <title-details-list-item :key="titleNumber"
                             :is-selected="isSelected"
                             :is-selectable="isSelectable"
                             :is-visible="isVisible"
                             uses-visibility-variations
                             class="matter-title-details-list-item new-title-visibility-button"
                             :tenure="tenure"
                             :title-number="titleNumber"
                             :is-address-visible="isAddressVisible"
                             :clickable-area="clickableArea"
                             :custom-name="customName"
                             :label-colour="labelColour"
                             :address="address"
                             :is-highlighted="isHighlighted"
                             :is-register-ordered="isRegisterOrdered"
                             :is-register-uploaded="isRegisterUploaded"
                             :register-response-date="registerResponseDate"
                             :has-light-background="hasLightBackground"
                             :is-matter-link-share-user="isMatterLinkShareUser"
                             :disabled="disabled"
                             @click="onClick"
                             @selection-change="onSelectionChange">
        <template #afterContent>
            <div class="matter-title-details-list-item__actions">
                <ow-dropdown-menu v-if="hasDropdownMenu"
                                  button-data-test="matter-title-details-dropdown-menu"
                                  button-data-track="MAT-TITLE-LIST - Title actions menu button"
                                  icon="$menu-more"
                                  theme="icon"
                                  size="small">
                    <slot name="dropdownMenuItems" />
                </ow-dropdown-menu>
                <ow-tooltip v-if="hasVisibilityButton"
                            :disabled="!visibilityButtonTooltip"
                            :position="OwTooltipPosition.Right">
                    <template #activator="{ props }">
                        <div class="matter-title-details-list-item__eye-icon-wrapper"
                             :class="{ 'visible': isVisible }"
                             v-bind="props">
                            <ow-button :disabled="disableVisibilityButton"
                                       icon
                                       variant="text"
                                       data-track="MAT-TITLE-LIST - Title visibility button"
                                       data-test="matter-title-details-list-item-eye-icon"
                                       @click="onVisibilityButtonClick()">
                                <v-icon class="visibility-off-icon">
                                    {{ '$visibility-off' }}
                                </v-icon>
                                <v-icon class="visibility-on-icon">
                                    {{ '$visibility' }}
                                </v-icon>
                            </ow-button>
                        </div>
                    </template>
                    <span>{{ visibilityButtonTooltip }}</span>
                </ow-tooltip>
            </div>
        </template>
        <template #belowContent>
            <slot name="belowContent" />
        </template>
        <template #actions>
            <slot name="actions" />
        </template>
        <template #afterLabel>
            <slot name="afterLabel" />
        </template>
    </title-details-list-item>
</template>

<script lang="ts">
    import OwButton from '@/components/core/ow-button.vue'
    import OwDropdownMenu from '@/components/core/ow-dropdown-menu.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import TitleDetailsListItem from '@/components/matter/titles/title-details-list-item.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'

    export default {
        components: {
            TitleDetailsListItem,
            OwButton,
            OwDropdownMenu,
            OwTooltip,
        },
        mixins: [FeatureFlagsMixin],
        props: {
            tenure: {
                type: String,
                required: true,
            },
            titleNumber: {
                type: String,
                required: true,
            },
            address: {
                type: String,
                default: null,
                required: false,
            },
            isVisible: {
                type: Boolean,
                required: false,
            },
            visibilityButtonTooltip: {
                type: String,
                required: false,
                default: null,
            },
            isSelected: {
                type: Boolean,
                required: false,
                default: false,
            },
            isAddressVisible: {
                type: Boolean,
                required: false,
                default: true,
            },
            isSelectable: {
                type: Boolean,
                required: false,
                default: true,
            },
            hasDropdownMenu: {
                type: Boolean,
                required: false,
                default: true,
            },
            isRegisterOrdered: {
                type: Boolean,
                required: false,
            },
            isRegisterUploaded: {
                type: Boolean,
                required: false,
            },
            registerResponseDate: {
                type: String,
                required: false,
            },
            clickableArea: {
                type: String,
                default: 'content',
                required: false,
                validator(val: string) {
                    return ['content', 'whole-item', 'none'].includes(val)
                },
            },
            customName: {
                type: String,
                required: false,
                default: null,
            },
            labelColour: {
                type: String,
                required: false,
                default: null,
            },
            disableVisibilityButton: {
                type: Boolean,
                required: false,
            },
            isHighlighted: {
                type: Boolean,
                required: false,
            },
            hasLightBackground: {
                type: Boolean,
                default: false,
            },
            isMatterLinkShareUser: {
                type: Boolean,
                default: false,
            },
            hasVisibilityButton: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        emits: [
            'selection-change',
            'click',
            'visibility-button-click',
        ],
        data() {
            return {
                OwTooltipPosition,
            }
        },
        methods: {
            onSelectionChange(val: boolean) {
                this.$emit('selection-change', val)
            },
            onClick() {
                this.$emit('click', this.titleNumber)
            },
            onVisibilityButtonClick() {
                this.$emit('visibility-button-click', this.titleNumber)
            },
        },
    }
</script>

<style lang="scss">
    @import 'matter-title-details-list-item';
</style>
