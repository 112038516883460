<template>
    <am-alerts ref="base"
               v-model:items="items"
               v-model:selected-items="selectedItems"
               context="company"
               :splash-page="companyGroups?.length === 0"
               :disabled="!isCompanyMonitorsEnabled || !isAssetMonitoringEnabled"
               :matter-id="matterId"
               @splash-button-click="onAddToGroup"
               @clear="onClear"
               @item-check="onAlertFilterCheck"
               @refresh="refreshNotificationsHandler"
               @sort-by="sortItemsByHandler" />
</template>

<script setup lang="ts">
    import { debounce } from "lodash"
    import { isNull } from "ol/format/filter"
    import {
        computed,
        onActivated,
        onMounted,
        ref,
        toRaw,
    } from "vue"
    import { useI18n } from "vue-i18n"
    import {
        useRoute,
        useRouter,
    } from "vue-router"
    import { useStore } from "vuex"

    import CompanyGroupsApi from "@/api/company-groups.api"
    import { NotificationSorting } from "@/api/notifications.api"
    import AmAlerts from "@/components/asset-monitoring/am-alerts.vue"
    import { NotificationFilterType } from "@/components/asset-monitoring/monitoring-preferences/notification-filter-type.enum"
    import { NotificationEntityType } from "@/components/asset-monitoring/notification-entity-type.enum"
    import { NotificationSubType } from "@/components/asset-monitoring/notification-sub-type.enum"
    import { Route } from "@/enums/route.enum"
    import { checkFlag } from "@/feature-flags"
    import { IOptionLinkItem } from "@/interfaces/option-item.interface"
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    import {
        dynamicSort,
        isNullOrEmpty,
    } from "@/utils/array-utils"

    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const assetMonitoringStore = useAssetMonitoringStore()

    const companyGroups = ref<any[]>()
    const items = ref<IOptionLinkItem[]>([])
    const selectedItems = ref<IOptionLinkItem[]>([])
    const base = ref<typeof AmAlerts>()
    const matterId = computed<number>(() => parseInt(route.params?.matterId?.toString()))
    const isCompanyMonitorsEnabled = computed<boolean>(() => checkFlag('asset-monitoring-company-monitors-tabs', false))
    const isAssetMonitoringEnabled = computed(() => checkFlag('asset-monitoring', false))

    const fetch = async () => {
        if(!isCompanyMonitorsEnabled.value){
            await router.push({
                name: Route.Homepage,
            })
        }

        await base.value?.fetch()

        // check for company groups
        const resp = await CompanyGroupsApi.getCompanyGroups(matterId.value)
        companyGroups.value = resp.data?.companyGroups
    }

    const onAddToGroup = async (active: boolean) => {
        if (!active) {
            return await router.push({
                name: Route.AssetMonitoringCompanyAlertsPreferences,
                params: {
                    matterId: matterId.value,
                },
            })
        }
        await router.push({
            name: Route.AssetMonitoringCompanyGroupsCreate,
            params: {
                matterId: matterId.value,
            },
        })
    }
    const populateFilterItems = () => {
        let result: IOptionLinkItem[] = []
        // add types

        result.push(
            {
                title: t('assetMonitoring.filters.types'),
                value: NotificationFilterType.Type,
                disableCheck: true,
                items: [{
                            title: t('assetMonitoring.subType.boundaryUpdated.title'),
                            value: NotificationSubType.BoundaryChange,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.EditionDateDoesntMatchOcdaResponse.title'),
                            value: NotificationSubType.EditionDateDoesntMatchOcdaResponse,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.oc2DocumentUpdated.title'),
                            value: NotificationSubType.NewDocumentInOCDA,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.ownershipUpdated.title'),
                            value: NotificationSubType.OwnershipChange,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.pendingApplicationsUpdated.title'),
                            value: NotificationSubType.CompletedPendingApplication,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        },
                        {
                            title: t('assetMonitoring.subType.companiesHouseUpdated.title'),
                            value: NotificationSubType.CompaniesHouse,
                            checked: false,
                            tag: 'type',
                            parentId: NotificationFilterType.Type,
                        }].sort(dynamicSort('title'))})

        // add groups
        result.push(
            {
                title: t('assetMonitoring.filters.groups'),
                value: NotificationFilterType.Group,
                disableFilter: true,
                disableCheck: true,
                items: companyGroups.value.filter(group => group.isEnabled).map((group) => {
                    return {
                        title: group.groupName,
                        value: group.id,
                        checked: false,
                        parentId: NotificationFilterType.Group,
                        linkedIds: group.companies.map((c) => c.companyNumber),
                        linkedParentId: NotificationFilterType.Company,
                    } as IOptionLinkItem
                }).sort(dynamicSort('title')),
            },
        )

        // add companies
        result.push(
            {
                title: t('assetMonitoring.filters.companies'),
                value: NotificationFilterType.Company,
                disableCheck: true,
                items: companyGroups.value.flatMap((group) => {
                    if (!group.isEnabled) {
                        return []
                    }
                    return group.companies.map((company) => {
                        return {
                            title: company.name,
                            value: company.companyNumber,
                            checked: false,
                            parentId: NotificationFilterType.Company,
                            tag: 'company',
                            linkedIds: [group.id],
                            linkedParentId: NotificationFilterType.Group,
                        } as IOptionLinkItem
                    })
                }).sort(dynamicSort('title')),
            } as IOptionLinkItem)

        // filter out empty groups
        result = result.filter((item) => item.items.length > 0)

        // clear and replace
        items.value = result
    }

    const onAlertFilterCheck = async (item: IOptionLinkItem) => {
        let id: number = item.parentId
        if (isNaN(id)) {
            id = parseInt(item.value.toString())
        }
        switch (id) {
            case NotificationFilterType.Type:
                await debounceUpdateSelectedNotificationSubTypes()
                break
            case NotificationFilterType.Group:
            case NotificationFilterType.Company:
                await debounceUpdateCompanies()
                break
        }
    }

    const updateCompanies = async (): Promise<void> => {
        const companyNumbers = companies.value.map((item) => item.value.toString())
        await assetMonitoringStore.updateSelectedEntityValues(matterId.value, companyNumbers)
    }

    const updateSelectedNotificationSubTypes = async (): Promise<void> => {
        await assetMonitoringStore.updateSelectedNotificationSubTypes(matterId.value, enabledFilters.value.map(t => t.value.toString()))
    }

    const debounceUpdateCompanies = debounce(updateCompanies, 500)
    const debounceUpdateSelectedNotificationSubTypes = debounce(updateSelectedNotificationSubTypes, 500)

    const companies = computed<IOptionLinkItem[]>(() => {
        return items.value.find((item) => item.value === NotificationFilterType.Company)?.items.filter((item) => item.checked) || []
    })

    const enabledFilters = computed<IOptionLinkItem[]>(() => {
        return items.value.find((item) => item.value === NotificationFilterType.Type)?.items.filter((item) => item.checked) || []
    })

    const initialise = async () => {
        assetMonitoringStore.entityType = NotificationEntityType.CompanyNumber
        await fetch()
    }

    onMounted(async () => {
        await initialise()
        populateFilterItems()
    })

    onActivated(async () => {
        // check if groups have been updated
        assetMonitoringStore.isLoading = true
        try {
            const resp = await CompanyGroupsApi.getCompanyGroups(matterId.value)
            const newGroups = resp.data?.companyGroups

            // check if equal to current groups
            if (JSON.stringify(toRaw(companyGroups.value)) !== JSON.stringify(toRaw(newGroups))) {
                await onClear()
            } else {
                await initialise()
            }
        } finally {
            assetMonitoringStore.isLoading = false
        }

    })

    const onClear = async () => {
        await assetMonitoringStore.clear()
        selectedItems.value = []
        items.value = []

        await initialise()
        populateFilterItems()
    }

    const refreshNotificationsHandler = async () => {
        await onClear()
    }

    const sortItemsByHandler = (value: string) => {
        switch (value) {
            case 'asc':
                assetMonitoringStore.updateSortBy(NotificationSorting.dateAscending, matterId.value)
                break
            default:
                assetMonitoringStore.updateSortBy(NotificationSorting.dateDescending, matterId.value)
                break
        }
    }

</script>