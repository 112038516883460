<template>
    <v-card v-if="showTitlePanel"
            id="titlePanel"
            :class="{
                active: active,
                collapsed: isPanelCollapsed,
                'docked-left': isMatterPanelHidden,
                'full-height': showFullHeight,
                'is-full-screen': isFullScreen,
                'top-nav-visible': isTopNavVisible,
            }"
            data-test="title-panel">
        <title-panel-header :current-matter-id="currentMatterId"
                            :is-downloading-register="isDownloadingRegister"
                            :is-loading="isLoading"
                            :is-ordering-allowed="isOrderingAllowed"
                            :is-panel-collapsed="isPanelCollapsed"
                            :is-register-ordering="isRegisterOrdering"
                            :is-register-purchased="isRegisterPurchased"
                            :is-register-uploaded="isRegisterUploaded"
                            :is-register-viewable="isRegisterViewable"
                            :response-date-value="selectedTitle?.officialCopiesAvailability?.results?.titleRegister?.responseDateValue"
                            :selected-title-number="selectedTitleNumber"
                            :selected-title="selectedTitle"
                            :title-in-matter="isPinned"
                            @close="onClearClick"
                            @collapse="onTogglePanelCollapseClick"
                            @pin="pinTitle"
                            @open-register="onOpenRegisterClick"
                            @download-register="onDownloadRegisterClick" />

        <ow-uploaded-document-warning v-if="isRegisterUploaded"
                                      class="title-panel__uploaded-doc-warning"
                                      :matter-id="currentMatterId"
                                      :title-number="selectedTitleNumber"
                                      :map="map" />

        <!-- Temporarily disabled while we revisit the functionality for this -->

        <!--        <asset-monitoring-notification-panel v-if="showAssetMonitoringWarning"-->
        <!--                                             :notification="notificationForTitle">-->
        <!--            <template #actions>-->
        <!--                <a href="#"-->
        <!--                   @click.prevent="dismissNotificationHandler">-->
        <!--                    <span v-t="'action.dismiss'" />-->
        <!--                </a>-->
        <!--                <a href="#">Refresh Register (£3)</a>-->
        <!--            </template>-->
        <!--        </asset-monitoring-notification-panel>-->

        <div v-if="isRegisterOutOfSync"
             class="ow-document-out-of-sync-warning caption-regular">
            <span v-t="'titlePanel.outOfSyncDocs'" />
        </div>

        <v-card-text v-show="!isPanelCollapsed"
                     id="titlePanelContent"
                     ref="titlePanelContent"
                     data-test="title-panel-content">
            <v-container style="padding:0">
                <div>
                    <div v-if="selectedTitle.record == null && !isLoading"
                         class="d-flex flex-column ma-4 mb-0">
                        <h6>Title number not found</h6>
                    </div>

                    <div class="d-flex flex-column"
                         column>
                        <title-panel-tabs v-model="activeTab"
                                          :is-full-screen="false"
                                          :is-ordering-allowed="isOrderingAllowed"
                                          :is-loading="isLoading"
                                          :map="map"
                                          scroll-element-container="#titlePanelContent"
                                          @title-number-selected="titleNumber => $emit('title-number-selected', titleNumber)"
                                          @set-selected-tab="setSelectedTab" />
                    </div>
                </div>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script lang="ts">
    import {
        computed,
        defineComponent,
    } from 'vue'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
    } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import { IAssetMonitoringNotification } from "@/components/asset-monitoring/asset-monitoring-notification.interface"
    import OwUploadedDocumentWarning from '@/components/documents/ow-uploaded-document-warning.vue'
    import AssetMonitoringNotificationPanel from "@/components/title-panel/asset-monitoring-notification-panel.vue"
    import TitlePanelTabs from "@/components/title-panel/tabs/title-panel-tabs.vue"
    import TitlePanelHeader from '@/components/title-panel/title-panel-header.vue'
    import { useMapTopNav } from '@/composables/use-map-top-nav'
    import { useTitlePanelTabs } from "@/composables/use-title-panel-tabs"
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DOCUMENT_SOURCE,
             getDataProviderText } from '@/consts/document-source'
    import { Route } from '@/enums/route.enum'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IState } from '@/interfaces/store/state.interface'
    import WindowResize from '@/mixins/page-resize.mixin'
    import { GET_TITLE_COMPLEXITY } from '@/store/modules/complexity/types'
    import { ADD_DOCUMENT_TO_MATTER } from '@/store/modules/documents/documents-types'
    import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
    import { MAP_UPDATE_SIZE } from '@/store/modules/map/types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import {
        PLANNING_CLEAR,
        PLANNING_HIDE_LAYERS,
        PLANNING_SEARCH_BY_TITLE_NUMBERS,
    } from '@/store/modules/planning/types'
    import {
        TITLE_GET_IS_REGISTER_PURCHASED,
        TITLE_MUTATE_COLLAPSE_PANEL,
        TITLE_SHOW_TITLE_BOUNDARY,
    } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
    } from '@/store/mutation-types'
    import { useAssetMonitoringStore } from "@/stores/asset-monitoring"
    import { showOrderButton } from '@/utils/document-utils'

    export default defineComponent({
        name: 'TitlePanel',

        components: {
            //AssetMonitoringNotificationPanel,
            OwUploadedDocumentWarning,
            TitlePanelHeader,
            TitlePanelTabs,
        },

        mixins: [
            WindowResize,
            flagsMixin,
        ],

        props: {
            isPinned: {
                required: false,
                type: Boolean,
                default: false,
            },
            isOrderingAllowed: {
                type: Boolean,
                required: false,
                default: true,
            },
            isFullScreen: {
                type: Boolean,
                required: false,
            },
            selectedTitleNumber: {
                required: false,
                type: String,
            },
        },

        emits: ['change-tab', 'title-number-selected', 'pin-title', 'clear-titles'],

        setup(props) {
            const { isTopNavVisible } = useMapTopNav()
            //const assetMonitoringStore = useAssetMonitoringStore()
            //const notificationForTitle = computed((): IAssetMonitoringNotification => assetMonitoringStore.getNotificationByTitleNumber(props.selectedTitleNumber))
            const { activeTab, setSelectedTab } = useTitlePanelTabs()
            return {
                isTopNavVisible,
                //notificationForTitle,
                //assetMonitoringStore,

                activeTab,
                setSelectedTab,
            }
        },

        data() {
            return {
                isDownloadingRegister: false,
            }
        },

        computed: {
            ...mapState({
                isPanelCollapsed: (state: IState) => state.title.collapsePanel,
                map: (state: IState) => state.map.map,
                currentMatterId: (state: IState) => state.matter.currentMatter.id,
                isMatterPanelHidden: (state: IState) => state.user.preferences.hiddenMatterPanel,
                loading: (state: IState) => state.title.selectedTitle.loading,
                isLookupTitleLoading: (state: IState) => state.title.loadingState.lookupTitle,
                registerAvailability: (state: IState) => state.title?.selectedTitle?.officialCopiesAvailability?.results?.titleRegister,
                selectedTitle: (state: IState) => state.title.selectedTitle,
                isWalkthrough: (state: IState) => state.walkthrough.enabled,
                expandedTitleNumber: (state: IState) => state.title.expandedTitleNumber,
            }),

            ...mapState('search', {
                isOwnerTitlesPanelOpen: state => state.isOwnerTitlesPanelOpen,
            }),

            ...mapGetters('linkShareClient', {
                isSharedLinkView: LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW,
            }),

            ...mapGetters('complexity', {
                getTitleComplexity: GET_TITLE_COMPLEXITY,
            }),

            ...mapGetters({
                isRegisterPurchasedGetter: TITLE_GET_IS_REGISTER_PURCHASED,
            }),

            isRegisterPurchased(): boolean {
                return this.isRegisterPurchasedGetter
            },

            isRegisterUploaded(): boolean {
                return this.selectedTitle?.officialCopiesAvailability?.results?.titleRegister?.documentSource === DOCUMENT_SOURCE.UPLOADED
            },

            isLoading(): boolean {
                return this.isLookupTitleLoading || this.loading
            },

            active(): boolean {
                return this.selectedTitleNumber != null &&
                    ![Route.MatterSketches, Route.OverlaysList, Route.Overlay].includes(this.$route.name)
            },

            showTitlePanel(): boolean {
                return !this.isSharedLinkView &&
                    !this.isWalkthrough &&
                    !this.isOwnerTitlesPanelOpen &&
                    Boolean(this.selectedTitle)
            },

            showingPlanningApplications(): boolean {
                return this.activeTab === TitlePanelTabName.Planning &&
                    this.showTitlePanel === true &&
                    (Boolean(this.map) || this.$route.meta.globalNavName !== 'map')
            },

            showFullHeight(): boolean {
                return this.activeTab === TitlePanelTabName.Planning &&
                    !this.isPanelCollapsed &&
                    this.$route.meta.globalNavName === 'map'
            },

            isRegisterViewable(): boolean {
                return Boolean(this.registerAvailability?.documentId)
            },

            isRegisterOrdering() {
                return Boolean(this.registerAvailability?.loadingOrder)
            },

            hasTitleComplexity(): boolean {
                return Boolean(this.getTitleComplexity(this.selectedTitleNumber))
            },

            isRegisterOutOfSync() {
                return showOrderButton(this.selectedTitle?.officialCopiesAvailability?.results?.titleRegister) &&
                    this.hasTitleComplexity &&
                    !this.isLoading
            },

            // showAssetMonitoringWarning() {
            //     if (this.notificationForTitle) {
            //         return !this.notificationForTitle.isRead &&
            //             this.isAssetMonitoringEnabled
            //     }
            //     return false
            // },
        },

        watch: {
            activeTab(val: string): void {
                if (this.$route.globalNavName === 'document-library' ||
                    this.$route.globalNavName === 'analysis' ||
                    this.$route.globalNavName === 'map') {
                    const hash = this.convertTabIndexToHash(val)
                    this.$router.push({ hash })
                }

                if (this.$refs.titlePanelContent) {
                    this.$refs.titlePanelContent.scrollTop = 0
                }

                this.$emit('change-tab', val)
            },

            selectedTitleNumber(newTitleNumber: string, oldTitleNumber: string): void {
                if (oldTitleNumber && newTitleNumber !== oldTitleNumber) {
                    this.setSelectedTab(TitlePanelTabName.Summary)
                }
            },

            async showingPlanningApplications(val: boolean): Promise<void> {
                if (val) {
                    await this.loadPlanningApplications()
                } else {
                    await this.hidePlanningLayers()
                }

                await this.updateMapSize()
            },

            isLoading(val: boolean): void {
                if (!val) {
                    this.logHeapEvent({
                        type: 'Title Panel - view title panel',
                        metadata: {
                            titleNumber: this.selectedTitleNumber,
                            matterId: this.currentMatterId,
                            documentSource: getDataProviderText(this.selectedTitle?.record?.source),
                        },
                    })
                }
            },
        },

        async mounted(): Promise<void> {
            this.$emit('change-tab', this.activeTab)

            if (this.$route.meta.globalNavName !== 'map' && this.activeTab === TitlePanelTabName.Planning) {
                await this.loadPlanningApplications()
                await this.updateMapSize()
            }
        },

        methods: {
            ...mapActions({
                addDocumentToMatter: ADD_DOCUMENT_TO_MATTER,
                addTitleToMatter: MATTER_ADD_TITLE,
                loadPlanningApplicationsForTitleNumbers: PLANNING_SEARCH_BY_TITLE_NUMBERS,
                updateMapSize: MAP_UPDATE_SIZE,
                clearPlanningApplications: PLANNING_CLEAR,
                hidePlanningLayers: PLANNING_HIDE_LAYERS,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                showTitleBoundary: TITLE_SHOW_TITLE_BOUNDARY,
            }),

            ...mapMutations({
                collapseTitlePanel: TITLE_MUTATE_COLLAPSE_PANEL,
            }),

            async onTogglePanelCollapseClick(): Promise<void> {
                this.collapseTitlePanel(!this.isPanelCollapsed)
                await this.$nextTick()
                await this.updateMapSize()
            },

            async onOpenRegisterClick(): Promise<void> {
                if (this.isReviewAssistantEnabled) {
                    await this.$router.push({
                        name: Route.ReviewAssistant,
                        params: {
                            matterId: this.currentMatterId,
                            titleNumber: this.selectedTitleNumber,
                        },
                    })
                } else {
                    const documentId = this.registerAvailability.documentId
                    if (documentId) {
                        await this.addTitleToMatter({
                            titleNumber: this.selectedTitleNumber,
                            show: true,
                        })
                        await this.addDocumentToMatter({
                            documentType: HighLevelDocumentType.Register,
                            documentId,
                            matterId: this.currentMatterId,
                        })

                        await this.$router.push({
                            name: Route.DocumentViewer,
                            params: {
                                documentType: HighLevelDocumentType.Register,
                                documentId,
                            },
                            query: {
                                fromMatterId: this.currentMatterId,
                            },
                        })
                    } else {
                        console.error('Unable to view document as no documentId in the store', this.registerAvailability)
                    }
                }
            },

            async onDownloadRegisterClick(): Promise<void> {
                this.isDownloadingRegister = true

                const documentId = this.registerAvailability.documentId
                await DocumentsApi.downloadDocumentByTypeAndId(HighLevelDocumentType.Register, documentId)

                await this.logHeapEvent({
                    type: 'Register - Export document',
                    metadata: {
                        titleNumber: this.selectedTitleNumber,
                        matterId: this.currentMatterId,
                        docFormat: '.pdf',
                        documentSource: getDataProviderText(this.selectedTitle?.record?.source),
                    },
                })

                this.isDownloadingRegister = false
            },

            onClearClick(): void {
                this.clearPlanningApplications()
                this.showTitleBoundary(this.expandedTitleNumber)
                this.$emit('clear-titles', this.selectedTitleNumber)
            },

            pinTitle(): void {
                this.$emit('pin-title', this.selectedTitleNumber)
            },

            async loadPlanningApplications(): Promise<void> {
                await this.loadPlanningApplicationsForTitleNumbers(
                    [this.selectedTitleNumber || this.$route.params.titleNumber])
            },

            // async dismissNotificationHandler(): Promise<void> {
            //     await this.assetMonitoringStore.dismissNotification(this.notificationForTitle?.notificationId)
            // },
        },
    })
</script>

<style lang="scss">
@import './title-panel';
</style>
