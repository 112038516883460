import actions from './actions.js'
import getters from './getters.js'
import mutations from './mutations.js'

const state = {
    enabled: false, // Is walkthrough mode currently enabled?
    loading: false, // Is the walkthrough currently loading?
    sourceWalkthrough: null, // Store a copy of a walkthrough to check for changes made to it.
    walkthrough: null, // The active walkthrough.
    selectedPage: null, // The selected page within the walkthrough.
    showPageReorderPrompt: false, // Should the page re-ordering prompt be visible?
    highlightBoundariesForTitleNumber: null, // A title number for which the corresponding boundaries should be highlighted, not yet implemented.
    debounceLayerRedraw: null, // Used to reduce the amount of layer redrawing.
    targetMap: null, // Reference to the OL map being used to page content and location.
    featureChangeTrackingRevisionCounter: 0, // Incremented when features are added/removed from the walkthrough (generally the selected page).
    hasZoomedToInitialPageExtent: false, // Indicates whether the walkthrough has initialised and zoomed to the first page extent.
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
